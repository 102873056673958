export enum FieldVisibility {
  PUBLIC = "public",
  PRIVATE = "private",
  TEAMS = "teams",
  FULL_ACCESS = "full-access",
}

export enum FieldVisibilityFilter {
  ALL = "all",
}

export type FieldVisibilityValues = FieldVisibilityFilter | FieldVisibility;

export type FieldTeamVisibility =
  | FieldVisibility.FULL_ACCESS
  | FieldVisibility.PRIVATE
  | FieldVisibility.PUBLIC;

export const fieldVisibilityOptions = [
  FieldVisibility.FULL_ACCESS,
  FieldVisibility.PUBLIC,
  FieldVisibility.PRIVATE,
  FieldVisibility.TEAMS,
];

export const fieldVisibilityFilterOptions = [FieldVisibilityFilter.ALL, ...fieldVisibilityOptions];

export default FieldVisibility;

import { Flow, Frigade, PropertyPayload } from "@frigade/js";
import configService from "config/ConfigService";
import Iam from "types/Iam";

type VisibilitiesCallback = (visibilities: Record<Flow["id"], boolean>) => void;

export class FrigadeService {
  protected _frigade: Frigade | undefined;
  protected _flowsVisibility: Record<Flow["id"], boolean> = {};
  protected _flowsVisibilitySubscribers: VisibilitiesCallback[] = [];
  protected _reactReload: undefined | (() => void) = undefined;
  protected _trackFn: undefined | ((event: string, properties?: PropertyPayload) => void) =
    undefined;

  initialize = (me: Iam) => {
    const frigade = new Frigade(configService.getFrigadeApiKey(), {
      userId: String(me.id),
      groupId: String(me.organization.id),
    });
    console.debug("frigade: init", me, frigade, this);
    this._frigade = frigade;
  };

  get frigade() {
    return this._frigade;
  }

  get flowsVisibility() {
    return this._flowsVisibility;
  }

  set reactReload(reactReload: () => void) {
    this._reactReload = reactReload;
  }

  set trackFn(trackFn: (event: string, properties?: PropertyPayload) => void) {
    this._trackFn = trackFn;
  }

  trackEvent(event: string, properties: Record<string, any>) {
    console.debug("frigade: trackEvent", !!this._frigade, event, properties);

    if (!this._frigade) {
      return;
    }

    if (this._trackFn) {
      this._trackFn(event);
    } else {
      // fallback to js sdk
      this._frigade.track(event);
    }
    if (this._reactReload) {
      console.debug("fridage: trackEvent, calling refresh...");
      this._reactReload();
    }
  }

  async showFlow(flowId: Flow["id"]) {
    this._flowsVisibility[flowId] = true;
    this.notifySubscribers();
  }

  hideFlow(flowId: Flow["id"]) {
    this._flowsVisibility[flowId] = false;
    this.notifySubscribers();
  }

  subscribeOnVisibilityChanges(callback: VisibilitiesCallback) {
    this._flowsVisibilitySubscribers.push(callback);
  }

  unsubscribeFromVisibilityChanges(callback: VisibilitiesCallback) {
    this._flowsVisibilitySubscribers.filter((subscriber) => subscriber !== callback);
  }

  protected notifySubscribers() {
    this._flowsVisibilitySubscribers.forEach((callback) => callback(this._flowsVisibility));
  }
}

const frigadeService = new FrigadeService();

// @ts-ignore
window.frigadeService = frigadeService;

export default frigadeService;

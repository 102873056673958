.color {
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  border-width: 2px;
  border-style: solid;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    & > .icon {
      display: inline-block;
    }
  }
}

.icon {
  display: none;
  font-size: 0.5rem;
}

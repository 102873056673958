import {
  Actions,
  bulkAddEntitiesToGroups,
  bulkAddEntitiesToRoutes,
  bulkRemoveEntitiesFromGroups,
  bulkRemoveEntitiesFromRoutes,
  deleteEntities,
  filterRoutes,
  initializeRouteModal,
  updateEntities,
  updateRoutes,
} from "./actions";
import { createReducer } from "typesafe-actions";
import { Route } from "@mapmycustomers/shared/types/entity";

export interface SelectBarState {
  deleteEntitiesLoading: boolean;
  existingRoutesIds: Route["id"][];
  routeModalInitializing: boolean;
  routes: Route[];
  updateEntitiesLoading: boolean;
  totalRoutes: number;
}

const initialState: SelectBarState = {
  deleteEntitiesLoading: false,
  existingRoutesIds: [],
  routeModalInitializing: false,
  routes: [],
  updateEntitiesLoading: false,
  totalRoutes: 0,
};

const selectBar = createReducer<SelectBarState, Actions>(initialState)
  .handleAction(deleteEntities.request, (state) => ({
    ...state,
    deleteEntitiesLoading: true,
  }))
  .handleAction([deleteEntities.success, deleteEntities.failure], (state) => ({
    ...state,
    deleteEntitiesLoading: false,
  }))
  .handleAction(
    [
      bulkAddEntitiesToGroups.request,
      bulkRemoveEntitiesFromGroups.request,
      bulkAddEntitiesToRoutes.request,
      bulkRemoveEntitiesFromRoutes.request,
    ],
    (state) => ({
      ...state,
      updateEntitiesLoading: true,
    })
  )
  .handleAction(
    [
      bulkAddEntitiesToGroups.success,
      bulkRemoveEntitiesFromGroups.success,
      bulkAddEntitiesToRoutes.success,
      bulkRemoveEntitiesFromRoutes.success,
      bulkAddEntitiesToGroups.failure,
      bulkRemoveEntitiesFromGroups.failure,
      bulkAddEntitiesToRoutes.failure,
      bulkRemoveEntitiesFromRoutes.failure,
    ],
    (state) => ({
      ...state,
      updateEntitiesLoading: false,
    })
  )
  .handleAction(updateEntities.request, (state) => ({
    ...state,
    updateEntitiesLoading: true,
  }))
  .handleAction([updateEntities.success, updateEntities.failure], (state) => ({
    ...state,
    updateEntitiesLoading: false,
  }))
  .handleAction(initializeRouteModal.request, (state) => ({
    ...state,
    routeModalInitializing: true,
  }))
  .handleAction(
    initializeRouteModal.success,
    (state, { payload: { existingRoutesIds, routes, totalRoutes } }) => ({
      ...state,
      existingRoutesIds,
      routes,
      routeModalInitializing: false,
      totalRoutes,
    })
  )
  .handleAction(initializeRouteModal.failure, (state) => ({
    ...state,
    routeModalInitializing: false,
  }))
  .handleAction(filterRoutes.success, (state, { payload }) => ({
    ...state,
    routes: payload,
  }))
  .handleAction(updateRoutes, (state, { payload }) => ({
    ...state,
    routes: [...state.routes, payload],
  }));

export * from "./selectors";
export type SelectBarActions = Actions;
export default selectBar;

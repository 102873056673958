import React, { useCallback, useState } from "react";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import { Alert } from "@mapmycustomers/ui";
import { FormattedMessage, useIntl } from "react-intl";
import commonStyles from "../AuthCommon.module.scss";
import styles from "./ResetPasswordForm.module.scss";
import { Link } from "react-router-dom";
import Path from "enum/Path";
import validatePasswords, { NOTHING_VALID, PasswordValidationResult } from "util/validatePasswords";
import {
  useConfirmPasswordFieldValidationRules,
  usePasswordFieldValidationRules,
} from "../Register/utils/validation-hooks";
import PasswordValidation from "component/auth/PasswordValidation";
import SignupField from "../Register/enum/SignupField";

interface Props {
  errorMessage?: string;
  isResetPasswordLoading: boolean;
  onResetPassword: (payload: { code: string; password: string; username: string }) => void;
}

const ResetPasswordForm: React.FC<Props> = ({
  errorMessage,
  isResetPasswordLoading,
  onResetPassword,
}) => {
  const intl = useIntl();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const username = urlParams.get("username");
  const isValidLink = !!code && !!username;

  const [form] = Form.useForm();

  const passwordFieldValidationRules = usePasswordFieldValidationRules(intl);
  const confirmPasswordFieldValidationRules = useConfirmPasswordFieldValidationRules(intl, form);

  const [passwordValidationResult, setPasswordValidationResult] =
    useState<PasswordValidationResult>(NOTHING_VALID);
  const handleFormFieldsChange = useCallback(() => {
    setPasswordValidationResult(
      validatePasswords(
        form.getFieldValue(SignupField.PASSWORD),
        form.getFieldValue(SignupField.PASSWORD_CONFIRMATION)
      )
    );
  }, [form]);

  const handleResetPassword = useCallback(() => {
    const { password } = form.getFieldsValue();
    if (code && username) {
      onResetPassword({ code, password, username });
    }
  }, [form, onResetPassword, code, username]);

  return (
    <div className={commonStyles.container}>
      {errorMessage && <Alert message={errorMessage} type="error" />}
      {isValidLink ? (
        <Form
          form={form}
          name="forgotPassword"
          onFinish={handleResetPassword}
          onFieldsChange={handleFormFieldsChange}
          preserve={false}
          layout="vertical"
        >
          <p className={commonStyles.title}>
            <FormattedMessage
              id="auth.resetPassword.form.title"
              defaultMessage="Set New Password"
              description="Title of reset new password form"
            />
          </p>
          <p className={commonStyles.description}>
            <FormattedMessage
              id="auth.resetPassword.form.description"
              defaultMessage="Enter your new password"
              description="Title of username field of reset password form"
            />
          </p>
          <Form.Item
            colon={false}
            label={intl.formatMessage({
              id: "auth.resetPassword.form.newPassword",
              defaultMessage: "Set Password",
              description: "Title of the New Password field in the reset password form",
            })}
            name={SignupField.PASSWORD}
            requiredMark="optional"
            rules={passwordFieldValidationRules}
          >
            <Input.Password autoComplete="new-password" size="large" spellCheck="false" />
          </Form.Item>
          <Form.Item
            dependencies={[SignupField.PASSWORD]}
            label={intl.formatMessage({
              id: "auth.resetPassword.form.confirmPassword",
              defaultMessage: "Confirm Password",
              description: "Title of the confirm Password field in the reset password form",
            })}
            name={SignupField.PASSWORD_CONFIRMATION}
            requiredMark="optional"
            rules={confirmPasswordFieldValidationRules}
          >
            <Input.Password autoComplete="new-password" size="large" spellCheck="false" />
          </Form.Item>
          <Form.Item>
            <PasswordValidation validationResult={passwordValidationResult} />
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.setPasswordButton}
              disabled={
                !passwordValidationResult.isValid ||
                !passwordValidationResult.passwordsMatch ||
                isResetPasswordLoading
              }
              htmlType="submit"
              loading={isResetPasswordLoading}
              type="primary"
            >
              {isResetPasswordLoading
                ? intl.formatMessage({
                    id: "auth.resetPassword.form.submit.loading",
                    defaultMessage: "Updating...",
                    description:
                      "Title of the submit resetPassword form button after it has been pressed",
                  })
                : intl.formatMessage({
                    id: "auth.resetPassword.form.submit",
                    defaultMessage: "Set Password",
                    description: "Title of the submit forgot password form button",
                  })}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <div className={styles.wrongLinkCard}>
          <p className={commonStyles.title}>
            {intl.formatMessage({
              id: "auth.resetPassword.error.title",
              defaultMessage: "Something went wrong",
              description: "An invalid link error message text",
            })}
          </p>
          <p className={commonStyles.description}>
            {intl.formatMessage({
              id: "auth.resetPassword.error.invalidLink",
              defaultMessage:
                "It seems you’ve clicked on a bad reset password link. Please click directly on the link in the email.",
              description: "An invalid link error message text",
            })}
          </p>
          <Link className={styles.returnLink} to={Path.LOGIN}>
            <Button className={styles.returnButton} type="primary">
              <FormattedMessage
                id="auth.resetPassword.form.loginLink"
                defaultMessage="Return to Login"
                description="Title of the Back to Login Link"
              />
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ResetPasswordForm;

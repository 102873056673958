import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "auth.login.form.title",
    defaultMessage: "Welcome back!",
    description: "Title of the Login Page",
  },
  email: {
    id: "auth.login.form.email",
    defaultMessage: "Email",
    description: "Title of the username field on the login form",
  },
  emailRequired: {
    id: "auth.login.form.email.required",
    defaultMessage: "Please input your email",
    description: "Field is required error message for the email field",
  },
  password: {
    id: "auth.login.form.password",
    defaultMessage: "Password",
    description: "Title of the password field on the login form",
  },
  passwordRequired: {
    id: "auth.login.form.password.required",
    defaultMessage: "Please input your password",
    description: "Field is required error message for the password field",
  },
  forgotPasswordLink: {
    id: "auth.login.form.forgotPasswordLink",
    defaultMessage: "Forgot password?",
    description: "Title of the Forgot password Link",
  },
  loading: {
    id: "auth.login.form.submit.loading",
    defaultMessage: "Signing in...",
    description: "Title of the submit login form button after it has been pressed",
  },
  submit: {
    id: "auth.login.form.submit",
    defaultMessage: "Log in",
    description: "Title of the submit login form button",
  },
  ssoLogin: {
    id: "auth.login.form.button.ssoLogin",
    defaultMessage: "SSO",
    description: "Title of the SSO Login",
  },
  divider: {
    id: "auth.login.form.divider",
    defaultMessage: "or sign in with",
    description: "Title of divider text",
  },
  inquire: {
    id: "auth.login.form.inquire",
    defaultMessage: "Inquire about SSO for your team",
    description: "Inquiry text",
  },
});

export default messages;

import { RefCallback, useLayoutEffect, useState } from "react";
import loggingService from "util/logging";

export const useRawSizeObserver = <T extends HTMLElement>(
  element?: T
): [width: number, height: number] => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    if (!element) {
      return;
    }

    try {
      const observer = new ResizeObserver(() => {
        setWidth(element?.offsetWidth ?? 0);
        setHeight(element?.offsetHeight ?? 0);
      });
      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    } catch (e) {
      loggingService.error("Error in useSizeObserver", e);
    }
  }, [element]);

  return [width, height];
};

const useSizeObserver = <T extends HTMLElement>(): [
  width: number,
  height: number,
  ref: RefCallback<T | null>
] => {
  const [ref, setRef] = useState<T | null>(null);
  const [width, height] = useRawSizeObserver(ref || undefined);
  return [width, height, setRef];
};

export default useSizeObserver;

import React, { useCallback } from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { NumberField, NumberFieldComponent } from "@mapmycustomers/ui";
import { useIntl } from "react-intl";
import styles from "./DurationFilter.module.scss";

export const DURATION_FILTER_OPERATORS = [
  FilterOperator.EQUALS,
  FilterOperator.NOT_EQUAL,
  FilterOperator.GREATER_THAN,
  FilterOperator.GREATER_THAN_OR_EQUAL,
  FilterOperator.LESS_THAN,
  FilterOperator.LESS_THAN_OR_EQUAL,
];

interface DurationFilterProps extends IFilterComponentProps {}

const DurationFilter: IFilterInstance = {
  doesSupportValue: (value: any, operator: FilterOperator) => {
    return typeof value === "number" && DURATION_FILTER_OPERATORS.includes(operator);
  },
  getComponent:
    (): React.FC<DurationFilterProps> =>
    ({ className, focus, onChange, value }) => {
      const intl = useIntl();
      const setRef = useCallback(
        (ref: NumberFieldComponent | null) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );
      return (
        <div className={styles.container}>
          <NumberField
            inputClassName={className}
            onChange={(number) => onChange?.({ ...value, value: number })}
            ref={setRef}
            value={value?.value}
          />
          <span className={styles.suffix}>
            {intl.formatMessage({
              id: "filters.duration.suffix",
              defaultMessage: "mins",
              description: "Mins suffix in duration field filter",
            })}
          </span>
        </div>
      );
    },
};

export default DurationFilter;

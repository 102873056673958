import { ActionType, createAsyncAction } from "typesafe-actions";
import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import SchemaField from "@mapmycustomers/shared/types/schema/SchemaField";

// used on app loading to fetch all schemas
export const fetchAllSchemas = createAsyncAction(
  "@schema/fetchAllSchemas/request",
  "@schema/fetchAllSchemas/success",
  "@schema/fetchAllSchemas/failure"
)<void, void, void>();

export const fetchSchemaForEntityType = createAsyncAction(
  "@schema/fetchSchemaForEntityType/request",
  "@schema/fetchSchemaForEntityType/success",
  "@schema/fetchSchemaForEntityType/failure"
)<
  EntityTypesSupportingFieldCustomization,
  {
    entityType: EntityTypesSupportingFieldCustomization;
    schema: SchemaField[];
  },
  unknown
>();

export type Actions = ActionType<typeof fetchAllSchemas | typeof fetchSchemaForEntityType>;

import React from "react";
import { RegistryProps } from "component/FieldGrid/utils/ComponentRegistry";
import { AvatarWithName } from "@mapmycustomers/ui";
import { UserRef } from "@mapmycustomers/shared/types/User";
import styles from "./OwnerField.module.scss";
import UserPreviewCard from "component/UserPreviewCard";

const OwnerField: React.FC<RegistryProps> = ({ entity, field }) => {
  const value = field.getValueFor(entity) as UserRef;

  return (
    <UserPreviewCard userId={value.id}>
      <div>
        <AvatarWithName className={styles.avatar} user={value} />
      </div>
    </UserPreviewCard>
  );
};

export default OwnerField;

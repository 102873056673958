import { Company, EntityType, Lead, Person } from "@mapmycustomers/shared/types/entity";
import { GeoManagementState } from "@mapmycustomers/shared/types/base/Located";
import { GeoPoint } from "@mapmycustomers/shared/types/shapes";
import isFunction from "lodash-es/isFunction";
import unescape from "lodash-es/unescape";

type PlaceResult = google.maps.places.PlaceResult;

function parseLeadAddressComponents(place: Lead | PlaceResult) {
  const components = new Map<string, { long_name?: string; short_name?: string }>();
  const addressDetails = place.address_components ?? [];

  addressDetails.forEach((addressComponent) => {
    if (Array.isArray(addressComponent.types)) {
      const value = {
        long_name: addressComponent.long_name,
        short_name: addressComponent.short_name,
      };

      addressComponent.types.forEach((addressType) => {
        components.set(addressType, value);
      });
    }
  });

  const getLongName = (key: string) => components.get(key)?.long_name;
  const getShortName = (key: string) => components.get(key)?.short_name;

  const admin2 = getLongName("administrative_area_level_2");
  const city = getLongName("locality") ?? getLongName("sublocality") ?? getLongName("postal_town");
  const country = getLongName("country");
  const countryCode = getShortName("country");
  const postalCode = getLongName("postal_code");
  const state = getLongName("administrative_area_level_1");
  const stateCode = getShortName("administrative_area_level_1");
  const subLocality = getLongName("sublocality_level_1");

  let address = "";
  try {
    address = unescape(
      (place.adr_address ?? "")
        .split(/<\/\w+>(,\s*)?/) // split by closing tag
        .find((s) => s.includes('class="street-address"')) // find tag with street-address class name
        ?.split(/<\w+[^>]*>/)?.[1] ?? "" // remove opening tag or use empty string as a result
    );
  } catch (e) {
    // nevermind
  }
  if (!address) {
    const nonStreetAddressValues = addressDetails
      .filter((item) => !item.types.includes("route") && !item.types.includes("street_number"))
      .flatMap((item) => [item.long_name, item.short_name]);
    address = nonStreetAddressValues
      .reduce(
        (result, name) => result.replace(new RegExp(`(,\\s*)?${name}`, "g"), ""),
        place.formatted_address || ""
      )
      .replace(/(^\P{L}+)|(\P{L}+$)/gu, ""); // remove any leading/trailing non-alphanumeric chars

    address = address
      .replace(/,/g, "")
      .replace(/\s{2,}/g, " ")
      .trim();
  }
  // Remove this conversion because we use coordinates in such cases
  // if (!address) {
  //   address =
  //     Array.from({ length: 4 }, (_, i) => `administrative_area_level_${7 - i}`)
  //       .map((type) => addressDetails.find((item) => item.types.includes(type))?.long_name)
  //       ?.find((name) => !!name) ?? "";
  // }

  return {
    address,
    admin2,
    city,
    country,
    countryCode,
    postalCode,
    state,
    stateCode,
    subLocality,
  };
}

interface PlaceConverter {
  (entityType: EntityType.COMPANY, place: Lead | PlaceResult): Partial<Company>;
  (entityType: EntityType.PERSON, place: Lead | PlaceResult): Partial<Person>;
  (entityType: EntityType.COMPANY | EntityType.PERSON, place: Lead | PlaceResult): Partial<
    Company | Person
  >;
}

export const convertPlaceToEntity: PlaceConverter = (entityType, place): any => {
  const [firstName, lastName] = (place.name ?? "").trim().split(/\s(.*)/);

  const result: Partial<Company | Person> = {
    name: place.name,
    firstName,
    lastName,
    address: place.formatted_address,
    entity: entityType,
    geoManagementState: GeoManagementState.MANUAL,
    website: place.website,
    phone: place.international_phone_number,
  };

  if (place.geometry?.location) {
    const { lat, lng } =
      isFunction(place.geometry.location.lat) || isFunction(place.geometry.location.lng)
        ? (place as PlaceResult).geometry!.location!.toJSON()
        : (place as Lead).geometry.location;
    result.geoPoint = { type: "Point", coordinates: [lng, lat] } as GeoPoint;
  }

  if (Array.isArray(place.address_components) && place.address_components.length > 0) {
    const {
      address,
      admin2,
      city,
      country,
      countryCode,
      postalCode,
      state,
      stateCode,
      subLocality,
    } = parseLeadAddressComponents(place);

    Object.assign(result, {
      address: address,
      city: city,
      country: country ?? countryCode,
      countryCode: countryCode,
      geoAddress: {
        address: address,
        admin2: admin2,
        city: city,
        country: country || countryCode,
        countryCode: countryCode,
        postalCode: postalCode,
        region: state,
        regionCode: stateCode,
        subLocality: subLocality,
      },
      postalCode: postalCode,
      region: state,
      regionCode: stateCode,
    });
  }

  return result;
};

import { useMemo } from "react";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import Named from "@mapmycustomers/shared/types/base/Named";

const useEntitySorter = <T extends Identified & Named>(
  entities: T[],
  createdEntities: T[],
  isFilterApplied: boolean
) =>
  useMemo(() => {
    // take all new entities and existing entities (avoid duplicates though) and sort them
    // however if filter is applied, do not include createdEntities (and do not sort, since entities are
    // assumed to be sorted by name already)
    return isFilterApplied
      ? entities
      : [
          ...createdEntities,
          ...entities.filter((entity) => !createdEntities.includes(entity)),
        ].sort((a, b) => {
          const nameComparisonResult = (a.name ?? "").localeCompare(b.name ?? "");
          if (createdEntities.includes(a)) {
            return createdEntities.includes(b) ? nameComparisonResult : -1;
          }
          return createdEntities.includes(b) ? 1 : nameComparisonResult;
        });
  }, [entities, createdEntities, isFilterApplied]);

export default useEntitySorter;

import React, { useEffect, useMemo, useState } from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { NumberField } from "@mapmycustomers/ui";
import IntervalUnit, { getIntervalUnitDisplayName } from "enum/IntervalUnit";
import { useIntl } from "react-intl";
import Select from "antd/es/select";
import Row from "antd/lib/grid/row";
import cn from "classnames";
import styles from "./IntervalFilter.module.scss";

export const INTERVAL_FILTER_OPERATORS = [
  FilterOperator.INTERVAL_BEFORE,
  FilterOperator.INTERVAL_AFTER,
];

interface IntervalFilterProps extends IFilterComponentProps {}

const IntervalFilter: IFilterInstance = {
  doesSupportValue: (value: any, operator: FilterOperator) => {
    return (
      INTERVAL_FILTER_OPERATORS.includes(operator) &&
      typeof value === "object" &&
      value.unit &&
      value.from &&
      value.to
    );
  },
  getComponent:
    (): React.FC<IntervalFilterProps> =>
    ({ className, onChange, value }) => {
      const intl = useIntl();

      const [unit, setUnit] = useState<IntervalUnit>(value.value?.unit ?? IntervalUnit.DAY);
      const [count, setCount] = useState<number | undefined>(
        value.operator === FilterOperator.INTERVAL_BEFORE
          ? value.value?.from !== undefined
            ? -value.value.from
            : 1
          : value.value?.to !== undefined
          ? value.value.to
          : 1
      );

      useEffect(() => {
        if (count === undefined) {
          return; // do not notify
        }

        onChange?.({
          operator: value.operator,
          value: {
            from: value.operator === FilterOperator.INTERVAL_BEFORE ? -count : 0,
            to: value.operator === FilterOperator.INTERVAL_BEFORE ? 0 : count,
            unit,
          },
        });
      }, [count, onChange, unit, value.operator]);

      const intervalOptions = useMemo(
        () =>
          [
            IntervalUnit.HOUR,
            IntervalUnit.DAY,
            IntervalUnit.WEEK,
            IntervalUnit.MONTH,
            IntervalUnit.YEAR,
          ].map((unit) => ({
            label: getIntervalUnitDisplayName(intl, unit, count),
            value: unit,
          })),
        [count, intl]
      );

      return (
        <Row className={cn(styles.container, className)}>
          <div>
            <NumberField
              inputClassName={styles.unitCounter}
              min={0}
              onChange={setCount}
              value={count}
            />
          </div>
          <div>
            <Select<IntervalUnit>
              className={styles.unitSelector}
              onChange={setUnit}
              options={intervalOptions}
              value={unit}
            />
          </div>
        </Row>
      );
    },
};

export default IntervalFilter;

import { NumberField } from "@mapmycustomers/ui";
import Select from "antd/es/select";
import React, { useCallback, useMemo, useState } from "react";
import messages from "./messages";
import { useIntl } from "react-intl";
import styles from "./NotificationItem.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faMobile } from "@fortawesome/pro-solid-svg-icons/faMobile";
import Tooltip from "antd/es/tooltip";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { MINUTES_IN_DAY, MINUTES_IN_HOUR, MINUTES_IN_WEEK } from "util/consts";
import ActivityRemindType from "@mapmycustomers/shared/enum/activity/ActivityRemindType";
import UnitType, { getUnitTypeDisplayName } from "./UnitType";
import { Reminder } from "@mapmycustomers/shared/types/entity/Activity";

const multiplierByUnitType: Record<UnitType, number> = {
  [UnitType.DAYS]: MINUTES_IN_DAY,
  [UnitType.HOURS]: MINUTES_IN_HOUR,
  [UnitType.MINUTES]: 1,
  [UnitType.WEEKS]: MINUTES_IN_WEEK,
};

interface Props {
  index: number;
  onChange?: (index: number, reminder: Reminder) => void;
  onRemove?: (index: number) => void;
  reminder: Reminder;
}

const NotificationItem: React.FC<Props> = ({ index, onChange, onRemove, reminder }) => {
  const intl = useIntl();
  const [unitType, setUnitType] = useState<UnitType>(UnitType.MINUTES);
  const getParentElement = useCallback((trigger: HTMLElement) => trigger.parentElement!, []);
  const handleRemove = useCallback(() => onRemove?.(index), [index, onRemove]);
  const handleSetMethod = useCallback(
    (value: ActivityRemindType) => onChange?.(index, { ...reminder, remindVia: value }),
    [index, onChange, reminder]
  );

  const remindBeforeMinutes = useMemo(() => {
    if (reminder.remindBeforeMinutes % MINUTES_IN_WEEK === 0) {
      setUnitType(UnitType.WEEKS);
      return Math.round(reminder.remindBeforeMinutes / MINUTES_IN_WEEK);
    } else if (reminder.remindBeforeMinutes % MINUTES_IN_DAY === 0) {
      setUnitType(UnitType.DAYS);
      return Math.round(reminder.remindBeforeMinutes / MINUTES_IN_DAY);
    } else if (reminder.remindBeforeMinutes % MINUTES_IN_HOUR === 0) {
      setUnitType(UnitType.HOURS);
      return Math.round(reminder.remindBeforeMinutes / MINUTES_IN_HOUR);
    } else {
      return reminder.remindBeforeMinutes;
    }
  }, [reminder]);

  const handleUnitChange = useCallback(
    (value: UnitType) => {
      setUnitType(value);
      onChange?.(index, {
        ...reminder,
        remindBeforeMinutes: remindBeforeMinutes * multiplierByUnitType[value],
      });
    },
    [index, onChange, reminder, remindBeforeMinutes]
  );

  const handleSetMinutes = useCallback(
    (value: number | undefined) => {
      if (value) {
        onChange?.(index, {
          ...reminder,
          remindBeforeMinutes: value * multiplierByUnitType[unitType],
        });
      }
    },
    [index, onChange, reminder, unitType]
  );

  const options = useMemo(
    () =>
      [UnitType.MINUTES, UnitType.HOURS, UnitType.DAYS, UnitType.WEEKS].map((value) => ({
        label: getUnitTypeDisplayName(intl, value),
        value,
      })),
    [intl]
  );

  const reminderTypeOptions = useMemo(() => {
    return [
      {
        label: (
          <span className={styles.option}>
            <FontAwesomeIcon className={styles.icon} icon={faMobile} />
            {intl.formatMessage(messages.push)}
          </span>
        ),
        value: ActivityRemindType.PUSH,
      },
      {
        label: (
          <span className={styles.option}>
            <FontAwesomeIcon className={styles.icon} icon={faEnvelope} />
            {intl.formatMessage(messages.email)}
          </span>
        ),
        value: ActivityRemindType.EMAIL,
      },
    ];
  }, [intl]);

  return (
    <div className={styles.item}>
      <Select
        className={styles.method}
        dropdownMatchSelectWidth={false}
        onChange={handleSetMethod}
        options={reminderTypeOptions}
        value={reminder.remindVia}
      />
      <NumberField min={1} onChange={handleSetMinutes} value={remindBeforeMinutes} />
      <Select
        className={styles.unit}
        dropdownMatchSelectWidth={false}
        onChange={handleUnitChange}
        options={options}
        value={unitType}
      />
      <div className={styles.position}>{intl.formatMessage(messages.before)}</div>
      <div>
        <Tooltip
          arrowPointAtCenter
          getTooltipContainer={getParentElement}
          placement="topRight"
          title={intl.formatMessage(messages.remove)}
        >
          <FontAwesomeIcon className={styles.deleteIcon} icon={faXmark} onClick={handleRemove} />
        </Tooltip>
      </div>
    </div>
  );
};

export default NotificationItem;

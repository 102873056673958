import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import Color from "@mapmycustomers/shared/enum/Color";
import { getColorDisplayName } from "util/ui";
import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import colorNameToHex from "util/colors/colorNameToHex";
import { getColors } from "store/iam";
import { Labeled } from "@mapmycustomers/ui";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import capitalize from "lodash-es/capitalize";
import styles from "./CircleColorPicker.module.scss";
import { useIntl } from "react-intl";
import mmcColors from "styles/_colors.module.scss";

interface Props {
  allowNoColor?: boolean;
  className?: string;
  colors: Color[];
  disabled?: boolean;
  label?: ReactNode;
  onChange?: (color?: AnyColor) => void;
  readOnly?: boolean;
  value?: AnyColor;
}

const CircleColorPicker: React.FC<Props> = ({
  allowNoColor = false,
  className,
  colors,
  disabled,
  label,
  onChange,
  readOnly,
  value,
}) => {
  const intl = useIntl();

  return (
    <Labeled className={className} label={label} labelClassName={styles.label}>
      <div className={styles.container}>
        {[...colors, ...(allowNoColor ? [undefined] : [])].map(
          (color: Color | undefined, index: number) => {
            const isDisabled = disabled || readOnly;
            const hexColor = color ? colorNameToHex(color) : mmcColors.disabledGray;
            const isSelected = value === color;
            const selectedDotColor: string = color === "white" ? "rgba(0, 0, 0, 0.05)" : "#fff";
            let backgroundColor: string = hexColor;
            if (!color) {
              backgroundColor = "inherit";
            } else if (isSelected) {
              backgroundColor = selectedDotColor;
            }

            return (
              <Tooltip
                key={index}
                title={
                  color
                    ? capitalize(getColorDisplayName(intl, color))
                    : intl.formatMessage({
                        id: "component.circleColorPicker.noColor",
                        defaultMessage: "No color",
                        description: "No color tooltip for color picker",
                      })
                }
              >
                <div className={styles.itemContainer}>
                  <div
                    className={cn(styles.item, {
                      [styles.inactive]: isDisabled,
                      [styles.noColor]: !color,
                      [styles.selected]: isSelected,
                    })}
                    key={`select-color-${color}`}
                    onClick={() => !isDisabled && onChange?.(color)}
                    style={{
                      backgroundColor,
                      borderColor: color !== "white" ? hexColor : undefined,
                      boxShadow: isSelected ? `${hexColor} 0px 0px 0px 3px inset` : undefined,
                    }}
                  >
                    &nbsp;
                  </div>
                </div>
              </Tooltip>
            );
          }
        )}
      </div>
    </Labeled>
  );
};

const mapStateToProps = (state: RootState) => ({
  colors: getColors(state)!, // assuming we do have org and colors at this point
});

export default connect(mapStateToProps)(CircleColorPicker);

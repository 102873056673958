import React from "react";
import AntdCollapse, { CollapseProps } from "antd/es/collapse";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import styles from "./ModernCollapse.module.scss";

export const CollapsePanel = AntdCollapse.Panel;

interface Props extends CollapseProps {
  action?: React.ReactNode;
}

const ModernCollapse: React.FC<Props> = ({ action, children, className, ...restProps }) => {
  return (
    <AntdCollapse
      className={cn(styles.container, className)}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <FontAwesomeIcon
          className={cn(styles.icon, { [styles.rotatedIcon]: isActive })}
          icon={faAngleRight}
        />
      )}
      {...restProps}
    >
      {children}
    </AntdCollapse>
  );
};

export default ModernCollapse;

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import Deal from "@mapmycustomers/shared/types/entity/Deal";
import ApiService from "store/api/ApiService";

export const fetchFunnels = createAsyncAction(
  "@deal/fetchFunnels/request",
  "@deal/fetchFunnels/success",
  "@deal/fetchFunnels/failure"
)<void, Funnel[], unknown>();

export const createFunnel = createAsyncAction(
  "@deal/createFunnel/request",
  "@deal/createFunnel/success",
  "@deal/createFunnel/failure"
)<{ funnel: Partial<Omit<Funnel, "id">>; callback?: (funnel: Funnel) => void }, Funnel, void>();

export const updateFunnel = createAsyncAction(
  "@deal/updateFunnel/request",
  "@deal/updateFunnel/success",
  "@deal/updateFunnel/failure"
)<{ funnel: Funnel; favorite?: boolean }, Funnel, void>();

export const deleteFunnel = createAsyncAction(
  "@deal/deleteFunnel/request",
  "@deal/deleteFunnel/success",
  "@deal/deleteFunnel/failure"
)<Funnel, Funnel, void>();

export const ensureStagesFetched = createAsyncAction(
  "@deal/ensureStagesFetched/request",
  "@deal/ensureStagesFetched/success",
  "@deal/ensureStagesFetched/failure"
)<Funnel["id"][] | undefined, Record<Funnel["id"], Stage[]>, void>();

export const createStage = createAsyncAction(
  "@deal/createStage/request",
  "@deal/createStage/success",
  "@deal/createStage/failure"
)<{ funnelId: Funnel["id"]; stage: Partial<Omit<Stage, "id">> }, Stage, void>();

export const updateStages = createAsyncAction(
  "@deal/updateStages/request",
  "@deal/updateStages/success",
  "@deal/updateStages/failure"
)<{ funnelId: Funnel["id"]; stages: Stage[] }, { funnelId: Funnel["id"]; stages: Stage[] }, void>();

export const deleteStage = createAsyncAction(
  "@deal/deleteStage/request",
  "@deal/deleteStage/success",
  "@deal/deleteStage/failure"
)<Stage, Stage, void>();

export const updateDeal = createAsyncAction(
  "@deal/update/request",
  "@deal/update/success",
  "@deal/update/failure"
)<Deal, Deal, void>();

// WARNING! Only use this action when you can't get deal from the store
export const fetchDeal = createAction("@deal/fetchDeal")<{
  id: Deal["id"];
  options?: Parameters<ApiService["fetchDeal"]>[2];
  callback: (deal: Deal) => void;
  failureCallback?: () => void;
}>();

export type Actions = ActionType<
  | typeof fetchFunnels
  | typeof createFunnel
  | typeof updateFunnel
  | typeof deleteFunnel
  | typeof ensureStagesFetched
  | typeof createStage
  | typeof updateStages
  | typeof deleteStage
  | typeof updateDeal
  | typeof fetchDeal
>;

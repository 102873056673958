import isEqual from "lodash-es/isEqual";
import cloneDeep from "lodash-es/cloneDeep";
import { createReducer } from "typesafe-actions";
import {
  Actions,
  cancelEditLayout,
  cancelLayoutRolesAssociation,
  dismissActivityTypePreviewWarning,
  dismissDealStagePreviewWarning,
  initiateEditLayout,
  reloadLayoutRoles,
  reloadLayouts,
  saveLayout,
  saveLayoutsRoles,
  selectChildLayout,
  setActiveEntityType,
  showLayoutRolesAssociation,
  updateLayout,
} from "./actions";
import {
  EntityType,
  EntityTypesSupportingFieldCustomization,
} from "@mapmycustomers/shared/types/entity";
import FormLayout, { ChildLayout } from "@mapmycustomers/shared/types/layout/FormLayout";
import FormLayoutRoleAssociation from "@mapmycustomers/shared/types/layout/FormLayoutRoleAssociation";
import { FormLayoutRoleMap } from "../type/FormLayoutRoleMap";

export interface FormLayoutsEditedLayoutFormState {
  hasChanges?: boolean;
  isCreating?: boolean;
  layout?: FormLayout;
  loading: boolean;
  originalLayout?: FormLayout;
  saving?: boolean;
}

export interface FormLayoutsRolesState {
  editing: boolean;
  loading: boolean;
  roles: Array<FormLayoutRoleAssociation>;
  rolesMap: FormLayoutRoleMap;
  saving: boolean;
}

export interface FormLayoutsState {
  activeEntityType: EntityTypesSupportingFieldCustomization;
  editedChildLayout?: ChildLayout["id"];
  editedLayout?: FormLayoutsEditedLayoutFormState;
  isActivityTypeWarningDismissed: boolean;
  isDealStageWarningDismissed: boolean;
  layouts: FormLayout[];
  loading: boolean;
  roles: FormLayoutsRolesState;
  total: number;
}

const initialState: FormLayoutsState = {
  activeEntityType: EntityType.COMPANY,
  editedChildLayout: undefined,
  editedLayout: undefined,
  isActivityTypeWarningDismissed: false,
  isDealStageWarningDismissed: false,
  layouts: [],
  loading: false,
  roles: {
    editing: false,
    loading: false,
    roles: [],
    rolesMap: new Map(),
    saving: false,
  },
  total: 0,
};

export * from "./selectors";

export type FormLayoutsActions = Actions;

const formLayoutsStore = createReducer<FormLayoutsState, Actions>(initialState)
  .handleAction(setActiveEntityType, (state, { payload }) => ({
    ...state,
    activeEntityType: payload,
  }))
  .handleAction(initiateEditLayout.request, (state) => ({
    ...state,
    editedChildLayout: undefined,
    editedLayout: {
      ...state.editedLayout,
      hasChanges: false,
      isCreating: false,
      layout: undefined,
      loading: true,
    },
  }))
  .handleAction(initiateEditLayout.success, (state, { payload }) => ({
    ...state,
    editedLayout: {
      ...state.editedLayout,
      hasChanges: !payload?.id,
      isCreating: !payload?.id,
      layout: cloneDeep(payload) as FormLayout,
      loading: false,
      originalLayout: payload as FormLayout,
    },
  }))
  .handleAction(initiateEditLayout.failure, (state) => ({
    ...state,
    editedLayout: {
      ...state.editedLayout,
      loading: false,
    },
  }))
  .handleAction(updateLayout, (state, { payload }) => ({
    ...state,
    editedLayout: {
      ...state.editedLayout,
      hasChanges:
        !!state.editedLayout?.isCreating ||
        !isEqual(
          {
            ...(state.editedLayout?.layout as FormLayout),
            ...payload,
          },
          state.editedLayout?.originalLayout
        ),
      isCreating: !!state.editedLayout?.isCreating,
      layout: {
        ...(state.editedLayout?.layout as FormLayout),
        ...payload,
      },
      loading: !!state.editedLayout?.loading,
    },
  }))
  .handleAction(cancelEditLayout, (state) => ({
    ...state,
    editedChildLayout: undefined,
    editedLayout: undefined,
  }))
  .handleAction(saveLayout.request, (state) => ({
    ...state,
    editedLayout: {
      ...state.editedLayout,
      loading: false,
      saving: true,
    },
  }))
  .handleAction(saveLayout.success, (state, { payload }) => ({
    ...state,
    editedLayout: {
      ...state.editedLayout,
      hasChanges: false,
      isCreating: !payload?.id,
      layout: payload,
      loading: false,
      originalLayout: cloneDeep(payload),
      saving: false,
    },
  }))
  .handleAction(saveLayout.failure, (state) => ({
    ...state,
    editedLayout: {
      ...state.editedLayout,
      loading: false,
      saving: false,
    },
  }))
  .handleAction(reloadLayouts.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(reloadLayouts.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(reloadLayouts.success, (state, { payload }) => ({
    ...state,
    layouts: payload.layouts,
    loading: false,
    total: payload.total,
  }))
  .handleAction(reloadLayoutRoles.request, (state) => ({
    ...state,
    roles: {
      ...state.roles,
      loading: true,
    },
  }))
  .handleAction(reloadLayoutRoles.success, (state, { payload }) => ({
    ...state,
    roles: {
      ...state.roles,
      loading: false,
      roles: payload.data,
      rolesMap: payload.map,
    },
  }))
  .handleAction(reloadLayoutRoles.failure, (state) => ({
    ...state,
    roles: {
      ...state.roles,
      loading: false,
    },
  }))
  .handleAction(showLayoutRolesAssociation, (state) => ({
    ...state,
    roles: {
      ...state.roles,
      editing: true,
    },
  }))
  .handleAction(cancelLayoutRolesAssociation, (state) => ({
    ...state,
    roles: {
      ...state.roles,
      editing: false,
    },
  }))
  .handleAction(selectChildLayout, (state, { payload }) => ({
    ...state,
    editedChildLayout: payload,
  }))
  .handleAction(saveLayoutsRoles.request, (state) => ({
    ...state,
    roles: {
      ...state.roles,
      saving: true,
    },
  }))
  .handleAction([saveLayoutsRoles.failure, saveLayoutsRoles.success], (state) => ({
    ...state,
    roles: {
      ...state.roles,
      saving: false,
    },
  }))
  .handleAction(dismissActivityTypePreviewWarning, (state) => ({
    ...state,
    isActivityTypeWarningDismissed: true,
  }))
  .handleAction(dismissDealStagePreviewWarning, (state) => ({
    ...state,
    isDealStageWarningDismissed: true,
  }));

export default formLayoutsStore;

import React, { useMemo } from "react";
import AntdAlert, { AlertProps } from "antd/es/alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons/faCircleCheck";
import { faCircleExclamation } from "@fortawesome/pro-solid-svg-icons/faCircleExclamation";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import styles from "./Alert.module.scss";
import cn from "classnames";

const Alert: React.FC<AlertProps> = ({ className, type, ...restProps }) => {
  const icon = useMemo(() => {
    switch (type) {
      case "error":
        return faCircleXmark;
      case "success":
        return faCircleCheck;
      case "warning":
        return faCircleExclamation;
      default:
        return faCircleInfo;
    }
  }, [type]);

  return (
    <AntdAlert
      className={cn(styles.container, className)}
      icon={<FontAwesomeIcon className={styles.icon} icon={icon} />}
      type={type}
      {...restProps}
    />
  );
};

export default Alert;

import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { isCustomField } from "../fieldModel/impl/assert";
import { ActivityFieldName } from "../fieldModel/ActivityFieldModel";
import IntegrationService from "@mapmycustomers/shared/enum/integrations/IntegrationService";

const isFieldRequiredForIntegration = (field: IField, service?: IntegrationService): boolean =>
  field.hasFeature(FieldFeature.REQUIRED) &&
  !isCustomField(field) &&
  ((service !== IntegrationService.DYNAMICS && service !== IntegrationService.SALESFORCE) ||
    (!field.hasFeature(FieldFeature.STAGE_FIELD) && field.name !== ActivityFieldName.ASSIGNEE));

export default isFieldRequiredForIntegration;

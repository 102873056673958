import { KeyboardEvent, MouseEvent, SyntheticEvent } from "react";
import { v4 as uuidv4 } from "uuid";

export const PREVIEW_ENTITY_ATTR = "preview-entity-id";

function getPlatform() {
  // 2022 way of detecting. Note: userAgentData feature is available only in secure contexts (HTTPS)
  // @ts-ignore
  if (typeof navigator.userAgentData !== "undefined" && navigator.userAgentData != null) {
    // @ts-ignore
    return navigator.userAgentData.platform.toLowerCase();
  }
  // Deprecated but still works for most of the browsers
  if (typeof navigator.platform !== "undefined") {
    if (
      typeof navigator.userAgent !== "undefined" &&
      /android/.test(navigator.userAgent.toLowerCase())
    ) {
      // android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
      return "android";
    }
    return navigator.platform.toLowerCase();
  }
  return "unknown";
}

export const isMacOS = () => /mac/.test(getPlatform());

export const stopEvents = (event?: SyntheticEvent<HTMLElement> | MouseEvent | KeyboardEvent) => {
  event?.preventDefault();
  event?.stopPropagation();
};

export const stopEventPropagation = (
  event?: SyntheticEvent<HTMLElement> | MouseEvent | KeyboardEvent
) => {
  event?.stopPropagation();
};

export const getPreviewEntityUniqueElementId = (element?: HTMLElement): string | undefined => {
  if (!element) {
    return undefined;
  }
  const id = uuidv4();
  element.setAttribute(PREVIEW_ENTITY_ATTR, id);
  return id;
};

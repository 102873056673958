/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from "react";
import { PickerTimeProps } from "antd/es/date-picker/generatePicker";
import Labeled, { LabeledFieldProps } from "../Labeled";
import { bem } from "@react-md/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import ErrorRow from "../ErrorRow";
import "./TimePicker.scss";
import cn from "classnames";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import useDatePicker from "../util/useDatePicker";

const block = bem("mmc-time-picker");

export interface TimePickerProps
  extends Omit<PickerTimeProps<Date>, "picker">,
    Omit<LabeledFieldProps, "children" | "suffix"> {
  caption?: string;
  className?: string;
  error?: ReactNode;
  locked?: boolean;
  use12Hours?: boolean;
}

const TimePicker = React.forwardRef<any, TimePickerProps>(
  (
    {
      caption,
      className,
      disabled,
      error,
      label,
      labelClassName,
      labelPosition,
      locked,
      required,
      rowProps,
      sideLabelSpan,
      size = "large",
      ...props
    },
    ref
  ) => {
    const DatePickerComponent = useDatePicker();

    return (
      <Labeled
        className={cn(block({ disabled: disabled || locked }), className)}
        label={label}
        labelClassName={cn(block("label"), labelClassName)}
        labelPosition={labelPosition}
        required={required}
        rowProps={rowProps}
        sideLabelSpan={sideLabelSpan}
      >
        <div className={block("input")}>
          <DatePickerComponent
            disabled={disabled || locked}
            mode={undefined}
            picker="time"
            ref={ref}
            size={size}
            suffixIcon={
              locked ? (
                <div className={block("lock")}>
                  <FontAwesomeIcon icon={faLock} size="xs" />
                </div>
              ) : (
                <FontAwesomeIcon className={block("clock")} icon={faClock} />
              )
            }
            {...props}
          />
          {error ? (
            <ErrorRow>{error}</ErrorRow>
          ) : caption ? (
            <div className={block("caption")}>{caption}</div>
          ) : null}
        </div>
      </Labeled>
    );
  }
);

TimePicker.displayName = "TimePicker";

export default TimePicker;

import ApiService, { ApiMethodName } from "./ApiService";
import { call, select } from "redux-saga/effects";
import { getApiService, getReadonlyApiService } from "./index";
import { UnPromisify } from "@mapmycustomers/shared/util/ts";

type ExtractApiServiceMethodParameters<T> = T extends ApiMethodName
  ? Parameters<ApiService[T]>
  : never;

export function* callApi<T extends ApiMethodName>(
  apiMethodName: T,
  ...args: ExtractApiServiceMethodParameters<T>
) {
  const api: ApiService | undefined = yield select(getApiService);
  if (!api) {
    throw new Error("Api not defined");
  }
  const method = api[apiMethodName];
  if (!method) {
    throw new Error(`ApiService should have method ${apiMethodName}`);
  }
  const result: UnPromisify<ReturnType<ApiService[ApiMethodName]>> = yield call(
    [api, method],
    ...args
  );
  return result;
}

export function* callReadonlyApi<T extends ApiMethodName>(
  apiMethodName: T,
  ...args: ExtractApiServiceMethodParameters<T>
) {
  const api: ApiService | undefined = yield select(getReadonlyApiService);
  if (!api) {
    throw new Error("Readonly Api not defined");
  }
  const method = api[apiMethodName];
  if (!method) {
    throw new Error(`ReadonlyApiService should have method ${apiMethodName}`);
  }
  const result: UnPromisify<ReturnType<ApiService[ApiMethodName]>> = yield call(
    [api, method],
    ...args
  );
  return result;
}

import React from "react";
import FieldModel from "util/fieldModel/impl/FieldModel";
import IViewContext from "../types/IViewContext";

// Create view context with some default values
const ViewContext = React.createContext<IViewContext>({
  entityType: undefined,
  fieldModel: new FieldModel([]),
  viewState: {
    sort: [],
    range: { startRow: 0, endRow: 0 },
    filter: {},
    columns: [],
    viewAs: undefined,
  },
  updateFilterModel: () => {},
  updateColumnVisibility: () => {},
  updateEntity: () => {},
  updateViewAs: () => {},
  register: () => {},
  totalRecords: 0,
  unregister: () => {},
  updateViewState: () => {},
  updateViewStateAndFetch: () => {},
});

export default ViewContext;

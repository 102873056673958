import React, { useCallback, useEffect, useState } from "react";
import Button from "antd/es/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { RawFile } from "@mapmycustomers/shared/types/File";
import FileThumbnail from "../FileThumbnail";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import Pagination from "antd/es/pagination";
import useSizeObserver from "util/hook/useSizeObserver";
import chunk from "lodash-es/chunk";
import thumbnail from "../styles/_thumbnail.module.scss";
import Preview from "../Preview";
import AccessStatus from "@mapmycustomers/shared/types/entity/common/AccessStatus";
import cn from "classnames";
import styles from "./ViewableFileItems.module.scss";

const MOUSE_SCROLL_THRESHOLD = 5;

interface Props {
  accessStatus?: AccessStatus;
  canDelete?: boolean;
  files: RawFile[];
  onDelete?: (file: RawFile) => void;
  onDownload: (file: RawFile) => void;
  onFetchThumbnail?: (payload: { fileId: RawFile["id"]; callback: (blob: Blob) => void }) => void;
  size?: "default" | "small";
}

const ViewableFileItems: React.FC<Props> = ({
  accessStatus,
  canDelete = true,
  files,
  onDelete,
  onDownload,
  onFetchThumbnail,
  size,
}) => {
  const [width, , ref] = useSizeObserver<HTMLDivElement>();

  const itemsPerPage = width
    ? Math.floor(
        width /
          parseInt(size === "small" ? thumbnail.smallThumbnailWidth : thumbnail.thumbnailWidth)
      )
    : 0;
  const totalPages = itemsPerPage ? Math.ceil(files.length / itemsPerPage) : 1;

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Avoid having currentPage bigger than totalPages when the width of the panel changes.
    // Between going to `totalPages` and `1` I decided to do the latter, it looked more natural.
    setCurrentPage((page) => (page <= totalPages ? page : 1));
  }, [totalPages]);

  const handlePrevPageClick = useCallback(() => {
    setCurrentPage((page) => (page > 1 ? page - 1 : page));
  }, []);

  const handleNextPageClick = useCallback(() => {
    setCurrentPage((page) => (page < totalPages ? page + 1 : page));
  }, [totalPages]);

  const handleMouseWheel = useCallback(
    (event) => {
      if (event.deltaX > MOUSE_SCROLL_THRESHOLD) {
        handleNextPageClick();
      } else if (event.deltaX < -MOUSE_SCROLL_THRESHOLD) {
        handlePrevPageClick();
      }
    },
    [handleNextPageClick, handlePrevPageClick]
  );

  const handlePaginationRender = useCallback((_, type, originalElement) => {
    // remove prev/next buttons
    if (type === "prev" || type === "next") {
      return null;
    }
    return originalElement;
  }, []);

  const [selectedFile, setSelectedFile] = useState<RawFile | undefined>();
  const handleHidePreview = useCallback(() => {
    setSelectedFile(undefined);
  }, []);

  const buttonSize = size === "small" ? size : "middle";

  return (
    <div className={styles.container}>
      <div className={styles.gallery}>
        <Button
          className={styles.prevButton}
          disabled={currentPage <= 1}
          icon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={handlePrevPageClick}
          size={buttonSize}
          type="text"
        />
        <div className={cn(styles.pages, { [styles.smallPages]: size === "small" })} ref={ref}>
          {chunk(files, itemsPerPage).map((pageFiles, pageIndex) => (
            <div
              className={styles.thumbnails}
              key={pageIndex}
              onWheel={handleMouseWheel}
              style={{
                left: (pageIndex - currentPage + 1) * width,
                right: (currentPage - pageIndex - 1) * width,
              }}
            >
              {pageFiles.map((file: RawFile) => (
                <FileThumbnail
                  accessStatus={accessStatus}
                  canDelete={canDelete}
                  className={styles.thumbnail}
                  file={file}
                  key={file.id}
                  onClick={setSelectedFile}
                  onDelete={onDelete}
                  onDownload={onDownload}
                  onFetchThumbnail={onFetchThumbnail}
                  visible={currentPage - 1 === pageIndex}
                />
              ))}
            </div>
          ))}
        </div>
        <Button
          className={styles.nextButton}
          disabled={currentPage >= totalPages}
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          onClick={handleNextPageClick}
          size={buttonSize}
          type="text"
        />
      </div>
      <Pagination
        className={styles.pagination}
        current={currentPage}
        hideOnSinglePage
        itemRender={handlePaginationRender}
        onChange={setCurrentPage}
        pageSize={itemsPerPage}
        showSizeChanger={false}
        size="small"
        total={totalPages * itemsPerPage}
      />
      <Preview
        files={files}
        onHide={handleHidePreview}
        // TODO: use fetchPreview instead of fetchThumbnail (although, it seem to have no difference...)
        onFetchPreview={onFetchThumbnail}
        selectedFile={selectedFile}
      />
    </div>
  );
};

export default ViewableFileItems;

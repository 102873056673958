import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const recordsPastDueState = (state: RootState) => state.scene.dashboard.recordsPastDueState;

export const getRecordsPastDueDrillDownViewState = createSelector(
  recordsPastDueState,
  ({ viewState }) => viewState
);

export const getRecordsPastDueDrillDownTotalFilteredRecords = createSelector(
  recordsPastDueState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getRecordsPastDueDrillDownTotalRecords = createSelector(
  recordsPastDueState,
  ({ totalRecords }) => totalRecords
);

export const getRecordsPastDueDrillDownConfiguration = createSelector(
  recordsPastDueState,
  ({ configuration }) => configuration
);

export const isRecordsPastDueDrillDownLoading = createSelector(
  recordsPastDueState,
  ({ loading }) => loading
);

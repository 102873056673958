import React from "react";
import { useIntl } from "react-intl";
import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import formatCountryName from "util/countries/formatCountryName";
import getCountryFlag from "util/countries/getCountryFlag";
import styles from "./CountryWithFlag.module.scss";

interface Props {
  countryCode: CountryCode;
}

const CountryWithFlag: React.FC<Props> = ({ countryCode }) => {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      <div className={styles.flag}>{getCountryFlag(countryCode)}</div>
      <div>{formatCountryName(intl, countryCode)}</div>
    </div>
  );
};

export default CountryWithFlag;

import React from "react";
import cn from "classnames";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import { FormattedMessage, useIntl } from "react-intl";
import {
  useAcceptAgreementValidation,
  useConfirmPasswordFieldValidationRules,
  usePasswordFieldValidationRules,
} from "../../../utils/validation-hooks";
import BaseStepProps from "../../../types/BaseStepProps";
import SignupField from "../../../enum/SignupField";
import { PasswordValidationResult } from "util/validatePasswords";
import commonStyles from "../../../../AuthCommon.module.scss";
import styles from "../../Steps.module.scss";
import PasswordValidation from "component/auth/PasswordValidation";
import Checkbox from "component/input/Checkbox";
import Tooltip from "antd/es/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";

interface Props extends BaseStepProps {
  passwordValidationResult: PasswordValidationResult;
  signingUp: boolean;
}

const PasswordStep: React.FC<Props> = ({
  className,
  form,
  isValid,
  onBackClick,
  passwordValidationResult,
  signingUp,
}) => {
  const intl = useIntl();

  const passwordFieldValidationRules = usePasswordFieldValidationRules(intl);
  const confirmPasswordFieldValidationRules = useConfirmPasswordFieldValidationRules(intl, form);
  const tncValidation = useAcceptAgreementValidation(intl);

  return (
    <div className={cn(styles.stepContainer, className)}>
      <p className={commonStyles.description}>
        <FormattedMessage
          id="auth.registerMember.passwordStep.description"
          defaultMessage="Set your password to protect your account and data"
          description="Brief description of use of setting password"
        />
      </p>
      <Form.Item
        label={intl.formatMessage({
          id: "auth.registerMember.passwordStep.password",
          defaultMessage: "Set Password",
          description: "Title of the password field on the register form",
        })}
        name={SignupField.PASSWORD}
        requiredMark="optional"
        rules={passwordFieldValidationRules}
      >
        <Input.Password autoComplete="new-password" size="large" spellCheck="false" />
      </Form.Item>
      <Form.Item
        dependencies={[SignupField.PASSWORD]}
        label={intl.formatMessage({
          id: "auth.registerMember.passwordStep.confirmPassword",
          defaultMessage: "Confirm Password",
          description: "Title of the confirm Password field on the register form",
        })}
        name={SignupField.PASSWORD_CONFIRMATION}
        requiredMark="optional"
        rules={confirmPasswordFieldValidationRules}
      >
        <Input.Password autoComplete="new-password" size="large" spellCheck="false" />
      </Form.Item>
      <Form.Item>
        <PasswordValidation validationResult={passwordValidationResult} />
      </Form.Item>
      <Form.Item
        className={styles.tncField}
        name={SignupField.AGREE_WITH_TNC}
        rules={tncValidation}
        valuePropName="checked"
      >
        <Checkbox>
          <FormattedMessage
            id="auth.registerMember.orgDetailsStep.tncLinkTitle"
            defaultMessage="I agree to the <link>Terms & Conditions</link>"
            description="Title of the tnc Link"
            values={{
              link: (linkText: string) => (
                <a
                  href="https://mapmycustomers.com/terms/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {linkText}
                </a>
              ),
            }}
          />
        </Checkbox>
      </Form.Item>
      <Form.Item name={SignupField.PROMO_EMAILS} valuePropName="checked">
        <Checkbox>
          <FormattedMessage
            id="auth.registerMember.orgDetailsStep.promoEmails"
            defaultMessage="I’d like to receive promotional emails {tooltipIcon}"
            description="Title of the promotional email checkbox on the 3rd step of Registration page"
            values={{
              tooltipIcon: (
                <Tooltip
                  placement="topLeft"
                  title={intl.formatMessage({
                    id: "auth.registerMember.orgDetailsStep.promoEmails.tooltip",
                    defaultMessage:
                      "Select to receive promotional emails from Map My Customers about app updates, improvements and other content that may be of interest to me",
                    description:
                      "Tooltip for the promotional email checkbox on the 3rd step of Registration page",
                  })}
                >
                  <FontAwesomeIcon className={styles.tooltipIcon} icon={faInfoCircle} />
                </Tooltip>
              ),
            }}
          />
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <div className={cn(styles.buttons, styles.verticalButtons)}>
          <Button
            className={styles.submitButton}
            disabled={!isValid || signingUp}
            htmlType="submit"
            loading={signingUp}
            type="primary"
          >
            {signingUp
              ? intl.formatMessage({
                  id: "auth.register.submitButton.loading",
                  defaultMessage: "Signing up...",
                  description:
                    "Title of the submiting current details of first step of the register form",
                })
              : intl.formatMessage({
                  id: "auth.register.submitButton.register",
                  defaultMessage: "Let's dive in!",
                  description: "Title of the register button for third step of the register form",
                })}
          </Button>
          <Button htmlType="button" onClick={onBackClick} type="link">
            <FormattedMessage
              id="auth.register.previousStepButton"
              defaultMessage="Go back"
              description="Title of the Go Back button for the Register Form"
            />
          </Button>
        </div>
      </Form.Item>
    </div>
  );
};

export default PasswordStep;

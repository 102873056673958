import { createReducer, getType } from "typesafe-actions";
import {
  AlertBadgesActions,
  HeatMapActions,
  LayersActions,
  BoundariesActions,
  toggleLayerLegendExpandedState,
  toggleLayerVisibility,
  UserLocationActions,
} from "./actions";
import AlertBadgesState from "scene/map/store/layers/alerts/AlertBadgesState";
import HeatMapLayerData from "scene/map/store/layers/heatMap/HeatMapLayerData";
import BoundariesLayerData from "./boundaries/types/BoundariesLayerData";
import UserLocationsState from "scene/map/store/layers/userLocations/UserLocationsState";
import alertBadgesLayer, { alertBadgesInitialState } from "./alerts/reducers";
import heatMapLayer, { heatMapLayerInitialState } from "./heatMap/reducers";
import boundariesLayer, { boundariesLayerInitialState } from "./boundaries/reducers";
import userLocationsLayer, { userLocationInitialState } from "./userLocations/reducers";
import TrafficLayerData from "./traffic/TrafficLayerData";
import trafficLayer, { trafficInitialState } from "./traffic/reducers";
import { TrafficActions } from "./traffic/actions";

export interface LayersState {
  alerts: AlertBadgesState;
  boundaries: BoundariesLayerData;
  heatMap: HeatMapLayerData;
  traffic: TrafficLayerData;
  userLocations: UserLocationsState;
}

export type MapLayer = keyof LayersState;

export const layersInitialState: LayersState = {
  alerts: alertBadgesInitialState,
  boundaries: boundariesLayerInitialState,
  heatMap: heatMapLayerInitialState,
  userLocations: userLocationInitialState,
  traffic: trafficInitialState,
};

const layers = createReducer<LayersState, LayersActions>(layersInitialState)
  .handleType(getType(toggleLayerVisibility), (state, { payload }) => ({
    ...state,
    [payload.name]: {
      ...state[payload.name],
      visible: payload.visible,
    },
  }))
  .handleType(getType(toggleLayerLegendExpandedState), (state, { payload }) => ({
    ...state,
    [payload.name]: {
      ...state[payload.name],
      legendExpanded: payload.expanded,
    },
  }))
  .handleType(
    Object.keys(heatMapLayer.handlers) as HeatMapActions["type"][],
    (state: LayersState, action: HeatMapActions) => ({
      ...state,
      heatMap: heatMapLayer(state.heatMap, action),
    })
  )
  .handleType(
    Object.keys(alertBadgesLayer.handlers) as AlertBadgesActions["type"][],
    (state: LayersState, action: AlertBadgesActions) => ({
      ...state,
      alerts: alertBadgesLayer(state.alerts, action),
    })
  )
  .handleType(
    Object.keys(boundariesLayer.handlers) as BoundariesActions["type"][],
    (state: LayersState, action: BoundariesActions) => ({
      ...state,
      boundaries: boundariesLayer(state.boundaries, action),
    })
  )
  .handleType(
    Object.keys(userLocationsLayer.handlers) as UserLocationActions["type"][],
    (state: LayersState, action: UserLocationActions) => ({
      ...state,
      userLocations: userLocationsLayer(state.userLocations, action),
    })
  )
  .handleType(
    Object.keys(trafficLayer.handlers) as TrafficActions["type"][],
    (state: LayersState, action: TrafficActions) => ({
      ...state,
      traffic: trafficLayer(state.traffic, action),
    })
  );

export default layers;

import React from "react";
import EntityTag from "component/EntityTag";
import { RegistryProps } from "component/FieldGrid/utils/ComponentRegistry";
import { Group } from "@mapmycustomers/shared/types/entity";
import Tooltip from "antd/es/tooltip";

const GroupField: React.FC<RegistryProps> = ({ entity, field }) => {
  const groups = (field.getValueFor(entity) as Group[]) ?? [];

  return (
    <div>
      <Tooltip title={field.getFormattedValueFor(entity)}>
        {groups.map((group) => (
          <EntityTag entity={group} key={group.id} />
        ))}
      </Tooltip>
    </div>
  );
};

export default GroupField;

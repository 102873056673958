import { select, takeLatest } from "redux-saga/effects";
import { notifyAboutChanges, UiSyncListener } from "./actions";
import { getListeners } from "./selectors";
import { EntityType } from "@mapmycustomers/shared/enum";

export function* onNotify({ payload }: ReturnType<typeof notifyAboutChanges>) {
  const allListeners: Partial<Record<EntityType, UiSyncListener[]>> = yield select(getListeners);
  const targetListeners = allListeners[payload.entityType] ?? [];
  if (!targetListeners.length) {
    return;
  }

  targetListeners.forEach((listener) => {
    payload.added && listener.onAdd?.(payload.entityType, payload.added);
    (payload.updated || payload.updatedIds) &&
      listener.onUpdate?.(payload.entityType, payload.updated ?? []);
    payload.deletedIds && listener.onDelete?.(payload.entityType, payload.deletedIds);
  });
}

export function* uiSyncSaga() {
  yield takeLatest(notifyAboutChanges, onNotify);
}

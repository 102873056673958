import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import uniqBy from "lodash-es/uniqBy";
import { push } from "connected-react-router";
import IntegrationService from "@mapmycustomers/shared/enum/integrations/IntegrationService";
import Path from "enum/Path";
import IntegrationField from "types/integrations/IntegrationField";
import IntegrationUser from "types/integrations/IntegrationUser";
import Integration from "types/integrations/Integration";
import IntegrationFieldResponse from "types/integrations/IntegrationFieldResponse";
import IntegrationSync from "types/integrations/IntegrationSync";
import IntegrationRaw from "@mapmycustomers/shared/types/integrations/IntegrationRaw";
import CreateIntegrationPayload from "types/integrations/CreateIntegrationPayload";
import PlatformIntegrationField from "types/integrations/PlatformIntegrationField";
import {
  EntityType,
  EntityTypesSupportedByIntegrations,
} from "@mapmycustomers/shared/types/entity";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getOrganization, getOrganizationId, getOrganizationSettingValue } from "store/iam";
import { handleError } from "store/errors/actions";
import { callApi } from "store/api/callApi";
import { fetchCustomFields } from "store/customFields/actions";
import { isCustomField } from "util/fieldModel/impl/assert";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import { findServiceByType } from "util/integrations/useServiceDescription";
import parseIntegrationRecord from "util/integrations/parseIntegrationRecord";
import SettingPath from "enum/settings/SettingPath";
import {
  changeOrgUrl,
  createIntegration,
  deleteIntegration,
  fetchIntegration,
  initializeEditPage,
  initializeHomePage,
  initializeMappingStep,
  saveIntegration,
  selectService,
  setCurrentIntegration,
  setCustomFieldIntegrationResponse,
  updateIntegrationSyncsViewState,
} from "./actions";
import {
  CreateNewCustomField,
  EmptyIntegrationFieldsMappingState,
  IntegrationFieldsMapping,
} from "./index";
import {
  getCurrentIntegration,
  getIntegrationFields,
  getIntegrationUsers,
  getIntegrationViewState,
} from "./selectors";
import IntegrationViewState from "types/viewModel/IntegrationSyncsViewState";
import integrationFieldModel from "../../util/fieldModel/IntegrationFieldModel";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { PAGE_LIMIT } from "scene/settings/component/Integrations/utils/consts";
import StatusOption from "scene/settings/component/Integrations/enum/StatusOption";
import { addMinutes, isBefore } from "date-fns/esm";
import OrganizationSetting from "enum/OrganizationSetting";
import getSchedule from "./getSchedule";

const redirectTo = (url: string) => {
  window.location.href = url;
};

const processMmcFieldName = (integrationField: IntegrationField): IntegrationField => {
  if (!integrationField.mmcField) {
    return integrationField;
  }

  const fieldModel = getFieldModelByEntityType(integrationField.entityType);
  const field = fieldModel.getByIntegrationName(integrationField.mmcField);
  if (field) {
    return { ...integrationField, mmcField: field.name };
  }
  return integrationField;
};

const convertMmcFieldToSave = (
  entityType: EntityTypesSupportedByIntegrations,
  mmcField: string | null
): string | null => {
  if (!mmcField) {
    return mmcField;
  }
  const fieldModel = getFieldModelByEntityType(entityType);
  const field = fieldModel.getByName(mmcField);
  if (field) {
    return isCustomField(field) ? field.customFieldData.crmPropertyKey : field.name;
  }
  return mmcField;
};

function* onSelectService(action: ReturnType<typeof selectService>) {
  try {
    const service = findServiceByType(action.payload.integrationService);
    const url = action.payload.isSandbox ? service?.sandboxUrl : service?.url;
    if (url) {
      yield call(redirectTo, url);
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onCreateIntegration(action: ReturnType<typeof createIntegration.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    let serviceName = action.payload.get("serviceName");
    const state = action.payload.get("state");
    if (state === "dynamics-production") {
      serviceName = IntegrationService.DYNAMICS;
    }
    const code = action.payload.get("code");
    const envType = action.payload.get("envType");
    const data: CreateIntegrationPayload = {
      code,
      envType: serviceName === IntegrationService.DYNAMICS ? "production" : envType,
      type: serviceName as IntegrationService,
    };
    if (serviceName === IntegrationService.ZOHO) {
      data.accountsUrl = action.payload.get("accounts-server");
    }
    if (!code) {
      throw new Error(`Invalid ${serviceName} authorization, please try again`);
    }
    const response: Integration = yield callApi("createIntegration", org.id, data);
    yield put(createIntegration.success(response));
    yield put(
      push(`${Path.SETTINGS}/${SettingPath.ORGANIZATION_INTEGRATIONS}/edit/${response.id}`)
    );
  } catch (error) {
    yield put(handleError({ error }));
    yield put(createIntegration.failure(error));
    yield put(push(`${Path.SETTINGS}/${SettingPath.ORGANIZATION_INTEGRATIONS}`));
  }
}

function* onInitializeHomePage(action: ReturnType<typeof initializeHomePage.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const response: ListResponse<IntegrationRaw> = yield callApi("fetchIntegrations", org.id);
    const integrations: Integration[] = response.data.map(parseIntegrationRecord);
    if (integrations.length) {
      const integration: Integration = integrations[0];
      const integrationSyncsResponse: ListResponse<IntegrationSync> = yield callApi(
        "fetchIntegrationSyncs",
        org.id,
        integration.id,
        {
          $limit: 1,
          $order: "-createdAt",
        }
      );
      yield put(
        initializeHomePage.success({
          integration,
          lastIntegrationSync:
            integrationSyncsResponse.data.length > 0 ? integrationSyncsResponse.data[0] : undefined,
        })
      );
      const viewState: IntegrationViewState = yield select(getIntegrationViewState);
      yield put(updateIntegrationSyncsViewState.request({ viewState }));
    } else {
      yield put(initializeHomePage.success({ integration: undefined }));
    }
  } catch (error) {
    yield put(handleError({ error }));
    yield put(initializeHomePage.failure(error));
  }
}

function* onUpdateIntegrationViewState({
  payload: { page },
}: ReturnType<typeof updateIntegrationSyncsViewState.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const viewState: IntegrationViewState = yield select(getIntegrationViewState);
    const integration: Integration = yield select(getCurrentIntegration);
    const filters =
      viewState.statusOption === StatusOption.COMPLETED_WITH_ERRORS
        ? {
            $and: [
              {
                $or: [
                  {
                    "errored.incoming": {
                      $gt: 0,
                    },
                  },
                  {
                    "errored.outgoing": {
                      $gt: 0,
                    },
                  },
                ],
              },
              {
                status: "errored",
              },
              {
                createdAt: {
                  $gte: viewState.dateRange?.startDate.toISOString(),
                  $lte: viewState.dateRange?.endDate.toISOString(),
                },
              },
            ],
          }
        : {
            $and: [
              {
                status: viewState.statusOption,
              },
              {
                createdAt: {
                  $gte: viewState.dateRange?.startDate.toISOString(),
                  $lte: viewState.dateRange?.endDate.toISOString(),
                },
              },
            ],
          };

    if (integration) {
      let order = "-createdAt";
      if (viewState.sort) {
        const field = integrationFieldModel.getByName(viewState.sort);
        if (field) {
          order = field.platformFilterName;
          if (viewState.sortOrder === SortOrder.DESC) {
            order = `-${order}`;
          }
        }
      }
      const integrationSyncsResponse: ListResponse<IntegrationSync> = yield callApi(
        "fetchIntegrationSyncs",
        orgId,
        integration.id,
        {
          $limit: PAGE_LIMIT,
          $offset: ((page ?? 1) - 1) * PAGE_LIMIT,
          $order: order,
          $filters: filters,
        }
      );
      yield put(
        updateIntegrationSyncsViewState.success({
          integrationSyncs: integrationSyncsResponse.data,
          total: integrationSyncsResponse.total,
        })
      );
    } else {
      yield put(updateIntegrationSyncsViewState.success({ integrationSyncs: [], total: 0 }));
    }
  } catch (error) {
    yield put(handleError({ error }));
    yield put(updateIntegrationSyncsViewState.failure(error));
  }
}

function* onInitializeEditPage(action: ReturnType<typeof initializeEditPage.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const getOrganizationSettings: <T = any>(settingName: string, defaultValue?: T) => T =
      yield select(getOrganizationSettingValue);
    const orgTimeZone = getOrganizationSettings<string>(OrganizationSetting.TIMEZONE);
    const integrationId = action.payload;
    const rawIntegration: IntegrationRaw = yield callApi("fetchIntegration", org.id, integrationId);
    const integration = parseIntegrationRecord(rawIntegration);
    const defaultSchedule = getSchedule(orgTimeZone);
    if (
      (integration?.metadata?.instances ?? []).length > 1 &&
      !integration?.metadata?.instanceSelected
    ) {
      yield put(
        initializeEditPage.success({
          integration: { ...integration, schedule: integration?.schedule ?? defaultSchedule },
          needToSelectDynamicsInstance: true,
          defaultSchedule: defaultSchedule,
        })
      );
      return;
    }

    const [integrationUsersResponse, integrationSyncsResponse]: [
      ListResponse<IntegrationUser>,
      ListResponse<IntegrationSync>
    ] = yield all([
      callApi("fetchIntegrationUsers", org.id, integrationId, {
        $limit: 10000,
        $order: ["firstName", "lastName"],
      }),
      callApi("fetchIntegrationSyncs", org.id, integrationId, {
        $limit: 1000,
        $order: "-createdAt",
      }),
    ]);

    // note syncing should be enabled by default
    if (!integration.metadata?.noteSyncing) {
      integration.metadata = {
        ...(integration.metadata ?? {}),
        noteSyncing: {
          [EntityType.ACTIVITY]: false,
          [EntityType.COMPANY]: true,
          [EntityType.DEAL]: true,
          [EntityType.PERSON]: true,
        },
      };
    }

    if (!integration.metadata?.fileSyncing) {
      integration.metadata = {
        ...(integration.metadata ?? {}),
        fileSyncing: {
          [EntityType.ACTIVITY]: false,
          [EntityType.COMPANY]: true,
          [EntityType.DEAL]: true,
          [EntityType.PERSON]: true,
        },
      };
    }

    yield put(
      initializeEditPage.success({
        integration: { ...integration, schedule: integration?.schedule ?? defaultSchedule },
        integrationUsers: integrationUsersResponse.data,
        integrationSyncs: integrationSyncsResponse.data,
        integrationFields: { ...EmptyIntegrationFieldsMappingState },
        defaultSchedule,
      })
    );
  } catch (error) {
    yield put(handleError({ error }));
    yield put(initializeEditPage.failure(error));
  }
}

function* onInitializeMappingStep() {
  try {
    const org: Organization = yield select(getOrganization);
    const integration: Integration = yield select(getCurrentIntegration);
    const syncedEntityTypes = (
      Object.keys(integration.syncOptions) as EntityTypesSupportedByIntegrations[]
    ).filter(
      (entityType) =>
        integration.syncOptions[entityType].incoming || integration.syncOptions[entityType].outgoing
    );

    const responses: ListResponse<IntegrationField>[] = yield all(
      syncedEntityTypes.map((entityType) =>
        callApi("fetchIntegrationFields", org.id, integration.id, {
          $filters: { entityType },
          $limit: 1000,
          $order: "crmDisplayName",
        })
      )
    );

    const currentIntegrationFields: IntegrationFieldsMapping = yield select(getIntegrationFields);
    yield put(
      initializeMappingStep.success({
        integrationFields: {
          ...EmptyIntegrationFieldsMappingState,
          ...syncedEntityTypes.reduce<Partial<IntegrationFieldsMapping>>(
            (result, entityType, i) => ({
              ...result,
              [entityType]: uniqBy(
                [
                  ...currentIntegrationFields[entityType], // current mapping overrides loaded
                  ...responses[i].data.map(processMmcFieldName),
                ],
                "id"
              ),
            }),
            {}
          ),
        },
      })
    );
  } catch (error) {
    yield put(initializeMappingStep.failure(error));
    yield put(handleError({ error }));
  }
}

function* onFetchIntegration(action: ReturnType<typeof fetchIntegration.request>) {
  const org: Organization = yield select(getOrganization);
  try {
    const response: IntegrationRaw = yield callApi("fetchIntegration", org.id, action.payload);
    const integration = parseIntegrationRecord(response);
    yield put(fetchIntegration.success(integration));
    yield put(setCurrentIntegration({ currentIntegration: integration }));
  } catch (error) {
    yield put(fetchIntegration.failure(error));
    yield put(handleError({ error }));
  }
}

function* onDeleteIntegration(action: ReturnType<typeof deleteIntegration.request>) {
  const org: Organization = yield select(getOrganization);
  try {
    yield callApi("deleteIntegration", org.id, action.payload);
    yield put(deleteIntegration.success());
    yield put(initializeHomePage.request());
  } catch (error) {
    yield put(deleteIntegration.failure(error));
    yield put(handleError({ error }));
  }
}

function* onSaveIntegration() {
  try {
    const integration: Integration | undefined = yield select(getCurrentIntegration);
    if (!integration || !integration.id) {
      return;
    }
    const org: Organization = yield select(getOrganization);
    const schedule = { ...integration.schedule };
    const oneMinuteInFuture: Date = addMinutes(Date.now(), 1);
    if (!schedule.nextRunAt || isBefore(schedule.nextRunAt, oneMinuteInFuture)) {
      schedule.nextRunAt = oneMinuteInFuture;
    }
    const payload = {
      id: integration.id,
      syncOptions: integration.syncOptions,
      isLocked: integration.isLocked,
      metadata: integration.metadata,
      schedule,
    };
    yield callApi("updateIntegration", org.id, integration.id, payload);

    const integrationUsers: IntegrationUser[] = yield select(getIntegrationUsers);
    yield callApi(
      "updateIntegrationUsers",
      org.id,
      integration.id,
      integrationUsers.map(({ id, syncing, userId }) => ({ id, syncing, userId }))
    );

    const integrationFields: IntegrationFieldsMapping = yield select(getIntegrationFields);

    const updateIntegrationFieldsPayload: Partial<
      Record<EntityTypesSupportedByIntegrations, PlatformIntegrationField[]>
    > = {};

    (Object.keys(integrationFields) as EntityTypesSupportedByIntegrations[])
      .filter(
        (entityType) =>
          integration.syncOptions[entityType] &&
          (integration.syncOptions[entityType].incoming ||
            integration.syncOptions[entityType].outgoing)
      )
      .forEach((entityType) => {
        updateIntegrationFieldsPayload[entityType] = integrationFields[entityType].map(
          ({ id, mmcField, mmcGroupField, customField }) => ({
            id,
            mmcField:
              mmcField === CreateNewCustomField
                ? null
                : convertMmcFieldToSave(entityType, mmcField),
            mmcGroupField,
            syncing: mmcField === CreateNewCustomField || !!mmcField,
            customField: !!customField,
          })
        );
      });

    const integrationFieldsResponses: Partial<
      Record<EntityTypesSupportedByIntegrations, IntegrationFieldResponse[]>
    > = yield callApi(
      "updateIntegrationFields",
      org.id,
      integration.id,
      updateIntegrationFieldsPayload
    );
    const customFieldRes: IntegrationFieldResponse[] =
      Object.values(integrationFieldsResponses).reduce(
        (result: IntegrationFieldResponse[], response?: IntegrationFieldResponse[]) =>
          response
            ? result.concat(
                response.filter(
                  (mmcFieldItem: IntegrationFieldResponse) => mmcFieldItem.customField
                )
              )
            : result,
        []
      ) || [];

    yield all(
      (Object.keys(integrationFields) as EntityTypesSupportedByIntegrations[])
        .filter((entityType) =>
          integrationFields[entityType].some(({ customField }) => customField)
        )
        .map((entityType) => put(fetchCustomFields.request({ entityType })))
    );

    yield put(setCustomFieldIntegrationResponse(customFieldRes));
    yield put(saveIntegration.success(payload as Integration));
  } catch (error) {
    yield put(saveIntegration.failure(error));
    yield put(handleError({ error }));
  }
}

function* onChangeOrgUrl({ payload: orgUrl }: ReturnType<typeof changeOrgUrl.request>) {
  try {
    const integration: Integration | undefined = yield select(getCurrentIntegration);
    if (!integration || !integration.id) {
      return;
    }
    const org: Organization = yield select(getOrganization);
    const payload = {
      id: integration.id,
      syncOptions: integration.syncOptions,
      isLocked: integration.isLocked,
      metadata: {
        ...integration.metadata,
        orgUrl,
        instanceSelected: true,
      },
      schedule: integration.schedule,
    };
    yield callApi("updateIntegration", org.id, integration.id, payload);
    yield put(changeOrgUrl.success());
    yield put(initializeEditPage.request(integration.id));
  } catch (error) {
    yield put(changeOrgUrl.failure(error));
    yield put(handleError({ error }));
  }
}

export function* integrationsSaga() {
  yield takeLatest(selectService, onSelectService);
  yield takeLatest(createIntegration.request, onCreateIntegration);
  yield takeLatest(initializeEditPage.request, onInitializeEditPage);
  yield takeLatest(initializeHomePage.request, onInitializeHomePage);
  yield takeLatest(initializeMappingStep.request, onInitializeMappingStep);
  yield takeLatest(fetchIntegration.request, onFetchIntegration);
  yield takeLatest(deleteIntegration.request, onDeleteIntegration);
  yield takeLatest(saveIntegration.request, onSaveIntegration);
  yield takeLatest(changeOrgUrl.request, onChangeOrgUrl);
  yield takeEvery(updateIntegrationSyncsViewState.request, onUpdateIntegrationViewState);
}

import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";

const billingState = (state: RootState) => state.scene.billing;

export const isLoading = createSelector(billingState, ({ loading }) => loading);

export const isAnnual = createSelector(billingState, ({ annual }) => annual);

export const isCheckoutModalVisible = createSelector(
  billingState,
  ({ checkoutModalVisible }) => checkoutModalVisible
);

export const isUpgradeLoading = createSelector(
  billingState,
  ({ upgradeLoading }) => upgradeLoading
);

export const isSuccessState = createSelector(
  billingState,
  ({ showSuccessState }) => showSuccessState
);

export const getStripe = createSelector(billingState, ({ stripe }) => stripe);

export const getTiers = createSelector(billingState, ({ tiers }) => tiers);

export const getCurrentPlan = createSelector(billingState, ({ currentPlan }) => currentPlan);
export const getSelectedTier = createSelector(billingState, ({ selectedTier }) => selectedTier);
export const getLicenses = createSelector(billingState, ({ licenses }) => licenses);
export const getPriceInfo = createSelector(billingState, ({ priceInfo }) => priceInfo);

import {
  applyOutOfFrequencyDrillDownListViewSettings,
  fetchOutOfFrequencyCardDrillDownData,
  hideOutOfFrequencyDrillDown,
  OutOfFrequencyCardActions,
  showOutOfFrequencyDrillDown,
} from "./actions";
import { createReducer } from "typesafe-actions";
import { DEFAULT_PAGE_SIZE } from "util/consts";
import OutOfFrequencyCardConfiguration from "types/dashboard/cards/outOfFrequency/OutOfFrequencyCardConfiguration";
import DrillDownViewState from "scene/dashboard/types/DrillDownViewState";
import entityTypesSupportedInOutOfFrequency from "types/dashboard/cards/outOfFrequency/EntityTypesSupportedInOutOfFrequency";
import { EntityType } from "@mapmycustomers/shared/enum";
import EntityTypesSupportedInOutOfFrequency from "types/dashboard/cards/outOfFrequency/EntityTypesSupportedInOutOfFrequency";

export interface OutOfFrequencyCardState {
  configuration?: OutOfFrequencyCardConfiguration; // also works as a "visible" trigger, visible if set
  loading?: boolean;
  totalFilteredRecords: Record<EntityTypesSupportedInOutOfFrequency, number>;
  totalRecords: Record<EntityTypesSupportedInOutOfFrequency, number>;
  viewStates: Record<entityTypesSupportedInOutOfFrequency, DrillDownViewState>;
}

export const outOfFrequencyInitialState: OutOfFrequencyCardState = {
  totalFilteredRecords: ([EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL] as const).reduce(
    (result, entityType) => ({ ...result, [entityType]: 0 }),
    {} as Record<EntityTypesSupportedInOutOfFrequency, number>
  ),
  totalRecords: ([EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL] as const).reduce(
    (result, entityType) => ({ ...result, [entityType]: 0 }),
    {} as Record<EntityTypesSupportedInOutOfFrequency, number>
  ),
  viewStates: ([EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL] as const).reduce(
    (result, entityType) => {
      result[entityType] = {
        columns: [],
        filter: {},
        range: { startRow: 0, endRow: DEFAULT_PAGE_SIZE },
        sort: [],
        viewAs: undefined,
      };
      return result;
    },
    {} as Record<entityTypesSupportedInOutOfFrequency, DrillDownViewState>
  ),
};

const outOfFrequencyState = createReducer<OutOfFrequencyCardState, OutOfFrequencyCardActions>(
  outOfFrequencyInitialState
)
  .handleAction(showOutOfFrequencyDrillDown.request, (state, { payload }) => ({
    ...state,
    configuration: payload.configuration,
  }))
  .handleAction(showOutOfFrequencyDrillDown.success, (state, { payload }) => ({
    ...state,
    viewStates: payload.viewStates,
  }))
  .handleAction(hideOutOfFrequencyDrillDown, (state) => ({ ...state, configuration: undefined }))
  .handleAction(fetchOutOfFrequencyCardDrillDownData.request, (state, { payload }) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchOutOfFrequencyCardDrillDownData.success, (state, { payload }) => ({
    ...state,
    loading: false,
    totalFilteredRecords: {
      ...state.totalFilteredRecords,
      [payload.entityType]: payload.totalFilteredRecords,
    },
    totalRecords: {
      ...state.totalRecords,
      [payload.entityType]: payload.totalRecords,
    },
  }))
  .handleAction(fetchOutOfFrequencyCardDrillDownData.failure, (state, { payload }) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyOutOfFrequencyDrillDownListViewSettings, (state, { payload }) => {
    const viewState = state.viewStates[payload.entityType];
    return {
      ...state,
      viewStates: {
        ...state.viewStates,
        [payload.entityType]: {
          ...viewState,
          range: payload.range ?? viewState.range,
          sort: payload.sort ?? viewState.sort,
          filter: payload.filter ?? viewState.filter,
          columns: payload.columns ?? viewState.columns,
          // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
          viewAs: "viewAs" in payload ? payload.viewAs : viewState.viewAs,
        },
      },
    };
  });

export default outOfFrequencyState;

import Chip from "../../component/Chip";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

const activityTypeTagRender = ({ label, closable, onClose }: CustomTagProps) => (
  <Chip closable={closable} onClose={onClose} type="closable">
    {label}
  </Chip>
);

export default activityTypeTagRender;

import { createReducer } from "typesafe-actions";
import {
  Actions,
  setCreateEditCustomField,
  setCreateEditEntityType,
  setCustomFieldEditStep,
} from "./actions";
import CustomField, {
  CustomFieldPayload,
} from "@mapmycustomers/shared/types/customField/CustomField";
import { EntityType, EntityTypeSupportingCustomFields } from "@mapmycustomers/shared/types/entity";
import CreateEditCustomFieldStep from "scene/settings/enum/CreateEditCustomFieldStep";
import { NEW_CUSTOM_FIELD } from "../../../util/const";

export interface CustomFieldsState {
  createEdit: {
    customField: CustomFieldPayload | CustomField;
    entityType: EntityTypeSupportingCustomFields;
    step: CreateEditCustomFieldStep;
  };
}

const initialState: CustomFieldsState = {
  createEdit: {
    customField: NEW_CUSTOM_FIELD,
    entityType: EntityType.COMPANY,
    step: CreateEditCustomFieldStep.BASIC_INFO,
  },
};

const customFields = createReducer<CustomFieldsState, Actions>(initialState)
  .handleAction(setCreateEditEntityType, (state, { payload }) => ({
    ...state,
    createEdit: {
      ...state.createEdit,
      entityType: payload,
    },
  }))
  .handleAction(setCreateEditCustomField, (state, { payload }) => ({
    ...state,
    createEdit: {
      ...state.createEdit,
      customField: payload,
      step: CreateEditCustomFieldStep.BASIC_INFO,
    },
  }))
  .handleAction(setCustomFieldEditStep, (state, { payload }) => ({
    ...state,
    createEdit: {
      ...state.createEdit,
      step: payload,
    },
  }));
export * from "./selectors";
export type CustomFieldsActions = Actions;
export default customFields;

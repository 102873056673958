import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { METERS_IN_MILE } from "util/consts";
import { AreaCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { AreaFilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { isEntityAreaCondition } from "util/viewModel/converters/utils/asserts";

const parseAreaCondition = (platformCondition: AreaCondition): AreaFilterCondition => {
  if (isEntityAreaCondition(platformCondition)) {
    return {
      operator: FilterOperator.IN_AREA,
      value: {
        distanceInMeters: platformCondition.radius,
        entity: platformCondition.entity,
      },
    };
  } else {
    const isMeters =
      !("uom" in platformCondition) ||
      ("uom" in platformCondition && platformCondition.uom === "meter");
    const [longitude, latitude] = platformCondition.point;

    return {
      operator: FilterOperator.IN_AREA,
      value: {
        distanceInMeters: isMeters
          ? platformCondition.radius
          : METERS_IN_MILE * platformCondition.radius,
        longitude,
        latitude,
        location: platformCondition.location ?? "",
      },
    };
  }
};

export default parseAreaCondition;

import React from "react";
import Dropdown from "antd/es/dropdown";
import { DropdownButtonProps } from "antd/es/dropdown/dropdown-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import styles from "./DropdownButton.module.scss";
import cn from "classnames";

const DropdownButton: React.FC<DropdownButtonProps> = ({ className, ...props }) => {
  return (
    <Dropdown.Button
      {...props}
      className={cn(styles.container, className)}
      icon={<FontAwesomeIcon className={styles.icon} icon={faChevronDown} />}
    />
  );
};

export default DropdownButton;

import { MultiPin } from "types/map";
import colors from "styles/_colors.module.scss";
import anyColorToHex from "util/colors/anyColorToHex";
import getContrastColor from "util/colors/getContrastColor";
import ColorDefinition from "util/map/markerStyles/types/ColorDefinition";

const defaultColorGetter = (multiPin: MultiPin): ColorDefinition => {
  const fillColor = multiPin.data.color ? anyColorToHex(multiPin.data.color) : colors.slate;
  return {
    color: getContrastColor(fillColor) === "black" ? colors.slate : colors.white,
    fillColor,
  };
};

export default defaultColorGetter;

// See https://date-fns.org/v2.16.1/docs/parseJSON docs
import { parseJSON } from "date-fns/esm";
import isValidDate from "../isValidDate";
import { getLocalTimeZone } from "../dates";
import utcToZonedTime from "date-fns-tz/esm/utcToZonedTime";

export const parseApiDate = (date: string | number | Date): Date => {
  if (typeof date === "string" && date.length === 10) {
    date = `${date}T00:00:00Z`;
  }
  return parseJSON(date);
};

export const parseApiDateAsNumber = (date: string | number | Date): number => {
  const result = parseJSON(date);
  return isValidDate(result) ? result.getTime() : 0;
};

export const parseApiDateWithTz = (date: string | number | Date, timeZone?: string): Date => {
  if (!date) {
    return new Date(Number.NaN); // Invalid Date
  }
  return utcToZonedTime(date, timeZone ?? getLocalTimeZone());
};

export const parseApiDateWithUtcTz = (date: string | number | Date): Date => {
  if (!date) {
    return new Date(Number.NaN); // Invalid Date
  }
  return parseApiDateWithTz(date, "UTC");
};

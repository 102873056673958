import { ActionType, createAction } from "typesafe-actions";
import { EntityTypeSupportingCustomFields } from "@mapmycustomers/shared/types/entity";
import CustomField, {
  CustomFieldPayload,
} from "@mapmycustomers/shared/types/customField/CustomField";
import CreateEditCustomFieldStep from "scene/settings/enum/CreateEditCustomFieldStep";

export const setCreateEditEntityType = createAction(
  "settings/setCreateEditEntityType"
)<EntityTypeSupportingCustomFields>();

export const setCreateEditCustomField = createAction("settings/setCreateEditCustomField")<
  CustomField | CustomFieldPayload
>();

export const setCustomFieldEditStep = createAction(
  "settings/CreateEditCustomFieldStep"
)<CreateEditCustomFieldStep>();

export type Actions = ActionType<
  typeof setCreateEditCustomField | typeof setCreateEditEntityType | typeof setCustomFieldEditStep
>;

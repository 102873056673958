import {
  donePendingActivity,
  fetchLoggedActivities,
  fetchPreviousActivities,
  initFrequencyModal,
  initFrequencyPanel,
  updateEntityFrequency,
} from "./actions";
import { delay, put, select, takeEvery } from "redux-saga/effects";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getOrganizationId } from "store/iam";
import { handleError } from "store/errors/actions";
import { callApi } from "../api/callApi";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import {
  Activity,
  AnyEntityId,
  Company,
  Deal,
  EntityType,
  EntityTypesSupportedByActivities,
  EntityTypeSupportingGroups,
  Group,
  Person,
} from "@mapmycustomers/shared/types/entity";
import { ACTIVITIES_PORTION_SIZE } from "../../component/frequency/util/const";
import deepmerge from "deepmerge";
import getActivityDayKey from "../../component/frequency/util/getActivityDayKey";
import { LoggedActivityMapPayload } from "../../component/frequency/type/LoggedActivityMap";
import {
  getFrequencyModalConfig,
  getFrequencyPanelConfigActivityTypeIds,
  getFrequencyPanelConfigEntity,
} from "./selectors";
import FrequencyEntity from "../../component/frequency/type/FrequencyEntity";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { addDays, endOfToday } from "date-fns/esm";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { notifyAboutChanges } from "../uiSync/actions";
import { getFrequencyPreviewConfig } from "../../component/frequency/FrequencyPanel/util/useFrequencyPreviewConfig";
import FrequencyPreviewConfig from "../../component/frequency/type/FrequencyPreviewConfig";
import { formatDate } from "../../util/formatters";
import { doesEntitySupportActivity } from "../../util/assert";
import { notifyActivityRelatedEntities } from "../activity/actions";

const DEBOUNCE_DELAY = 1000;

const getEntityFilter = (entityType: EntityTypesSupportedByActivities, entityId: AnyEntityId) => ({
  $and: [
    ...(entityType === EntityType.COMPANY ? [{ accountId: entityId }] : []),
    ...(entityType === EntityType.PERSON ? [{ contactId: entityId }] : []),
    ...(entityType === EntityType.DEAL ? [{ dealId: entityId }] : []),
  ],
});

function* onFetchLoggedActivities({
  payload: { dates },
}: ReturnType<typeof fetchLoggedActivities.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entity: FrequencyEntity | undefined = yield select(getFrequencyPanelConfigEntity);
    const entityType = entity?.entity;
    let loggedActivities: LoggedActivityMapPayload = {};
    if (entity && doesEntitySupportActivity(entityType)) {
      const response: ListResponse<Activity> = yield callApi("fetchActivities", orgId, {
        $filters: deepmerge(getEntityFilter(entityType, entity.id), {
          $and: [{ $or: dates.map((startAt) => ({ startAt: formatDate(startAt, "yyyy-MM-dd") })) }],
        }),
        $limit: ACTIVITIES_PORTION_SIZE,
      });

      loggedActivities = response.data.reduce<LoggedActivityMapPayload>(
        (result, activity) => ({
          ...result,
          [getActivityDayKey(activity.startAt)]: [
            ...(result[getActivityDayKey(activity.startAt)] ?? []),
            activity.crmActivityType.id,
          ],
        }),
        {}
      );
    }

    yield put(
      fetchLoggedActivities.success({
        loggedActivities,
      })
    );
  } catch (error) {
    yield put(fetchLoggedActivities.failure());
    yield put(handleError({ error }));
  }
}

function* onFetchPreviousActivities({
  payload: { offset },
}: ReturnType<typeof fetchPreviousActivities.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const config: FrequencyPreviewConfig = yield select(getFrequencyModalConfig);
    const entity: FrequencyEntity | undefined = config.entity;
    const entityType = entity?.entity;

    let previousActivities: Activity[] = [],
      previousActivitiesTotal = 0;
    if (entity && doesEntitySupportActivity(entityType)) {
      const response: ListResponse<Activity> = yield callApi("fetchActivities", orgId, {
        $filters: deepmerge(getEntityFilter(entityType, entity.id), {
          $and: [{ startAt: { operator: FilterOperator.BEFORE, value: new Date().toISOString() } }],
        }),
        $limit: ACTIVITIES_PORTION_SIZE,
        $offset: offset,
      });
      previousActivities = response.data;
      previousActivitiesTotal = response.total;
    }

    yield put(
      fetchPreviousActivities.success({
        previousActivities,
        previousActivitiesTotal,
      })
    );
  } catch (error) {
    yield put(fetchPreviousActivities.failure());
    yield put(handleError({ error }));
  }
}

function* onInitFrequencyPanel() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entity: FrequencyEntity | undefined = yield select(getFrequencyPanelConfigEntity);
    const entityType = entity?.entity;
    let pendingActivity: Activity | undefined = undefined;
    let activityTypeIds: ActivityType["id"][] | undefined = yield select(
      getFrequencyPanelConfigActivityTypeIds
    );

    if (entity && doesEntitySupportActivity(entityType)) {
      const pendingActivityResponse: ListResponse<Activity> = yield callApi(
        "fetchActivities",
        orgId,
        {
          $filters: deepmerge(getEntityFilter(entityType, entity.id), {
            $and: [
              ...((activityTypeIds?.length ?? 0) > 1
                ? [
                    {
                      "crmActivityType.id": { $in: activityTypeIds },
                    },
                  ]
                : []),
              {
                completed: false,
                startAt: {
                  operator: FilterOperator.GREATER_THAN_OR_EQUAL,
                  value: addDays(endOfToday(), -5).toISOString(),
                },
              },
            ],
          }),
          $order: "createdAt",
          $limit: 1,
        }
      );

      pendingActivity = pendingActivityResponse.data[0];
    }

    yield put(
      initFrequencyPanel.success({
        pendingActivity,
      })
    );
  } catch (error) {
    yield put(initFrequencyPanel.failure());
    yield put(handleError({ error }));
  }
}

function* onInitFrequencyModal({ payload }: ReturnType<typeof initFrequencyModal.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityType = payload.entity.entity;

    const entity: Company | Person | Deal = yield callApi(
      payload.entity.entity === EntityType.COMPANY
        ? "fetchCompany"
        : payload.entity.entity === EntityType.PERSON
        ? "fetchPerson"
        : "fetchDeal",
      orgId,
      payload.entity.id,
      {
        includeGroups: true,
      }
    );
    // Frequency modal doesn't support groups
    const config = getFrequencyPreviewConfig(entity, false, entity.groups);
    let lastFrequencyActivity: Activity | undefined = undefined;
    const { activityTypeIds } = config;
    if (entity && doesEntitySupportActivity(entityType)) {
      const lastFrequencyActivityResponse: ListResponse<Activity> = yield callApi(
        "fetchActivities",
        orgId,
        {
          $filters: deepmerge(getEntityFilter(entityType, entity.id), {
            $and: [
              ...(activityTypeIds
                ? [
                    {
                      "crmActivityType.id": { $in: activityTypeIds },
                    },
                  ]
                : []),
            ],
          }),
          $order: "-completedAt",
          $limit: 1,
        }
      );
      lastFrequencyActivity = lastFrequencyActivityResponse.data[0];
    }

    yield put(
      initFrequencyModal.success({
        config,
        lastFrequencyActivity,
      })
    );
  } catch (error) {
    yield put(initFrequencyPanel.failure());
    yield put(handleError({ error }));
  }
}

function* onDonePendingActivity({ payload }: ReturnType<typeof donePendingActivity.request>) {
  try {
    if (payload) {
      const orgId: Organization["id"] = yield select(getOrganizationId);
      const updatedActivity: Activity = yield callApi("updateActivity", orgId, undefined, {
        id: payload.id,
        completed: true,
        startAt: payload.startAt,
        endAt: payload.endAt,
      });
      notifyAboutChanges({ entityType: EntityType.ACTIVITY, updated: [updatedActivity] });
      yield put(notifyActivityRelatedEntities(updatedActivity));
    }
    yield put(donePendingActivity.success());
    yield put(initFrequencyPanel.request());
  } catch (error) {
    yield put(donePendingActivity.failure());
    yield put(handleError({ error }));
  }
}
function* onUpdateEntityFrequency({
  payload: { entity, callback },
}: ReturnType<typeof updateEntityFrequency.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const payload = {
      id: entity.id,
      cadenceInterval: entity.cadenceInterval,
      crmActivityTypeId: entity.crmActivityTypeId,
    };
    let updatedEntity: Company | Person | Deal | undefined;
    if (
      [EntityType.COMPANY_GROUP, EntityType.PEOPLE_GROUP, EntityType.DEAL_GROUP].includes(
        entity.entity
      )
    ) {
      yield callApi(
        "updateGroup",
        orgId,
        (entity.entity === EntityType.COMPANY_GROUP
          ? EntityType.COMPANY
          : entity.entity === EntityType.PEOPLE_GROUP
          ? EntityType.PERSON
          : EntityType.DEAL) as EntityTypeSupportingGroups,
        entity as Group
      );
    } else if (entity.entity === EntityType.COMPANY) {
      updatedEntity = yield callApi("updateCompany", orgId, undefined, payload);
    } else if (entity.entity === EntityType.PERSON) {
      updatedEntity = yield callApi("updatePerson", orgId, undefined, payload);
    } else if (entity.entity === EntityType.DEAL) {
      updatedEntity = yield callApi("updateDeal", orgId, undefined, payload);
    }
    yield delay(DEBOUNCE_DELAY);

    yield put(updateEntityFrequency.success());
    if (updatedEntity) {
      yield put(notifyAboutChanges({ entityType: entity.entity, updated: [updatedEntity] }));
    }
    callback?.();
  } catch (error) {
    yield put(updateEntityFrequency.failure());
    yield put(handleError({ error }));
  }
}

export function* frequencySaga() {
  yield takeEvery(fetchLoggedActivities.request, onFetchLoggedActivities);
  yield takeEvery(fetchPreviousActivities.request, onFetchPreviousActivities);
  yield takeEvery(initFrequencyPanel.request, onInitFrequencyPanel);
  yield takeEvery(initFrequencyModal.request, onInitFrequencyModal);
  yield takeEvery(donePendingActivity.request, onDonePendingActivity);
  yield takeEvery(updateEntityFrequency.request, onUpdateEntityFrequency);
}

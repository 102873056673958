import { call, put, select, takeEvery } from "redux-saga/effects";
import { setRecordsPreviewConfiguration, updateRecordsPreviewConfiguration } from "./actions";
import isEqual from "lodash-es/isEqual";
import uniq from "lodash-es/uniq";
import { MAP_ENTITY_TYPES } from "util/map/consts";
import { MapPersistedEntityColumns } from "@mapmycustomers/shared/types/map/types";
import Organization, { OrganizationMetaData } from "@mapmycustomers/shared/types/Organization";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import {
  EntityType,
  EntityTypesSupportingFieldCustomization,
} from "@mapmycustomers/shared/types/entity";
import User from "@mapmycustomers/shared/types/User";
import Team from "@mapmycustomers/shared/types/Team";
import {
  getCurrentUser,
  getOrganization,
  getOrganizationId,
  getOrganizationMetaData,
  isCurrentUserManager,
  isCurrentUserOwner,
} from "store/iam";
import { updateMetadata, updateOrganizationMetadata } from "store/iam/actions";
import { getTeams } from "store/members";
import { fetchTeams } from "store/members/actions";
import { callApi } from "store/api/callApi";
import { handleError } from "store/errors/actions";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import { Origin } from "enum/Inheritance";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import EntityColumnsConfiguration from "./EntityColumnsConfiguration";
import { getRecordPreviewConfiguration } from "./selectors";
import RecordPreviewConfiguration from "./RecordPreviewConfiguration";
import SchemaField from "@mapmycustomers/shared/types/schema/SchemaField";
import { getEntitySchemaFields } from "store/schema";
import { PersonFieldName } from "util/fieldModel/PersonFieldModel";
import SchemaFieldCategory from "@mapmycustomers/shared/enum/SchemaFieldCategory";

export function* onUpdateRecordsPreviewConfiguration({
  payload,
}: ReturnType<typeof updateRecordsPreviewConfiguration.request>) {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    const owner: boolean = yield select(isCurrentUserOwner);
    const manager: boolean = yield select(isCurrentUserManager);
    const configuration: RecordPreviewConfiguration = yield select(getRecordPreviewConfiguration);

    const current = [Origin.ORGANIZATION, Origin.TEAM, Origin.PRIVATE].reduce<
      Partial<Record<Origin, MapPersistedEntityColumns>>
    >(
      (originResult, originValue) => ({
        ...originResult,
        [originValue]: MAP_ENTITY_TYPES.reduce(
          (result, value) => ({
            ...result,
            actions: {
              ...result.actions,
              [value]: configuration[originValue].actions?.[value],
            },
            engagement: {
              ...result.engagement,
              [value]: configuration[originValue].engagement?.[value],
            },
            [value]: (configuration[originValue][value] ?? []).map((field: IField) => field.name),
          }),
          {
            actions: {},
            engagement: {},
          }
        ),
      }),
      {}
    );

    const updated = [Origin.ORGANIZATION, Origin.TEAM, Origin.PRIVATE].reduce<
      Partial<Record<Origin, MapPersistedEntityColumns>>
    >(
      (originResult, originValue) => ({
        ...originResult,
        [originValue]: MAP_ENTITY_TYPES.reduce(
          (result, value) => ({
            ...result,
            actions: {
              ...result.actions,
              [value]: (payload[originValue] ?? configuration[originValue]).actions?.[value],
            },
            engagement: {
              ...result.engagement,
              [value]: (payload[originValue] ?? configuration[originValue]).engagement?.[value],
            },
            [value]: ((payload[originValue] ?? configuration[originValue])[value] ?? []).map(
              (field: IField) => field.name
            ),
          }),
          {
            actions: {},
            engagement: {},
          }
        ),
      }),
      {}
    );

    // Perform optimistic update first
    if (owner) {
      if (!isEqual(current[Origin.ORGANIZATION], updated[Origin.ORGANIZATION])) {
        const organization: Organization = yield select(getOrganization);
        const updatedOrganization: Organization = {
          ...organization,
          metaData: {
            ...organization.metaData,
            mapConfiguration: updated[Origin.ORGANIZATION],
          },
        };
        yield put(updateOrganizationMetadata.success(updatedOrganization));
      }
    }

    const hasTeamChanges =
      !isEqual(current[Origin.TEAM], updated[Origin.TEAM]) && (owner || manager);
    if (hasTeamChanges) {
      const user: User = yield select(getCurrentUser);
      const teams: Team[] = yield select(getTeams);

      const userTeamId = user?.metaData?.mapConfiguration?.teamId;
      const team: Team | undefined = teams?.find(
        (team) => team.id === (userTeamId ?? teams[0]?.id)
      );

      if (team) {
        const updatedTeam: Team = {
          ...team,
          metaData: {
            ...team.metaData,
            mapConfiguration: updated[Origin.TEAM],
          },
        };
        const updatedTeams = teams.map((team) => (team.id === updatedTeam.id ? updatedTeam : team));
        yield put(fetchTeams.success(updatedTeams));
      }
    }

    const hasCurrentTeamChanges = configuration.teamId !== payload.teamId;
    const hasUserChanges = !isEqual(current[Origin.PRIVATE], updated[Origin.PRIVATE]);
    if (hasUserChanges || hasCurrentTeamChanges) {
      const user: User = yield select(getCurrentUser);
      const updatedUser: User = {
        ...user,
        metaData: {
          ...user.metaData,
          mapConfiguration: {
            ...updated[Origin.PRIVATE],
            teamId: payload.teamId ?? configuration.teamId,
          },
        },
      };
      yield put(updateMetadata.success(updatedUser.metaData));
    }

    // Update list configuration to reflect changes on display
    yield call(buildRecordsPreviewConfiguration);

    // Perform actual update of metadata records in all affected places - organization, team and user
    // Then trigger one more re-generation of the visible columns configuration to reflect actual stored
    // state after interacting with API
    if (owner) {
      if (!isEqual(current[Origin.ORGANIZATION], updated[Origin.ORGANIZATION])) {
        const organization: Organization = yield select(getOrganization);

        const organizationUpdatePayload: Pick<Organization, "id" | "metaData"> = {
          id: organization.id,
          metaData: organization.metaData,
        };
        const updatedOrganization: Organization = yield callApi(
          "updateOrganization",
          organizationUpdatePayload
        );

        yield put(updateOrganizationMetadata.success(updatedOrganization));
      }
    }

    if (hasTeamChanges) {
      const user: User = yield select(getCurrentUser);
      const teams: Team[] = yield select(getTeams);

      const userTeamId = user?.metaData?.mapConfiguration?.teamId;
      const teamId = userTeamId ?? teams[0]?.id;
      const team: Team | undefined = teams?.find((team) => team.id === teamId);

      if (team) {
        const updatedTeam: Team = yield callApi("updateTeam", organizationId, team);
        const updatedTeams = teams.map((team) => (team.id === updatedTeam.id ? updatedTeam : team));
        yield put(fetchTeams.success(updatedTeams));
      }
    }

    if (hasUserChanges || hasCurrentTeamChanges) {
      const user: User = yield select(getCurrentUser);
      const updatedUser: User = yield callApi("updateMe", {
        id: user.id,
        metaData: {
          mapConfiguration: user.metaData.mapConfiguration,
        },
      });
      yield put(updateMetadata.success(updatedUser.metaData));
    }

    yield call(buildRecordsPreviewConfiguration);

    yield put(updateRecordsPreviewConfiguration.success());
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* buildRecordsPreviewConfiguration() {
  try {
    const orgMetadata: OrganizationMetaData = yield select(getOrganizationMetaData);
    const orgMetadataMapConfiguration = orgMetadata?.mapConfiguration ?? {};
    const currentUser: User = yield select(getCurrentUser);
    const userMetadataMapConfiguration = currentUser?.metaData?.mapConfiguration ?? {};
    const userTeamId = userMetadataMapConfiguration?.teamId;
    const teams: Team[] = yield select(getTeams);
    // Try to find team by teamId from user profile. If such team not found - fall back to first team available
    const teamId = userTeamId ?? teams?.[0]?.id;
    const team: Team | undefined = teams?.find((team) => team.id === teamId);
    const teamMetadataMapConfiguration = team?.metaData?.mapConfiguration ?? {};

    const fieldsGetter: (entityType: EntityTypesSupportingFieldCustomization) => SchemaField[] =
      yield select(getEntitySchemaFields);

    const pinnedFields = MAP_ENTITY_TYPES.reduce<MapPersistedEntityColumns>(
      (result, entityType) => ({
        ...result,
        [entityType]: getFieldModelByEntityType(entityType)
          .sortedFields.filter((modelField) => {
            if (entityType === EntityType.PERSON && modelField.name === PersonFieldName.NAME) {
              return false;
            }

            if (modelField.hasFeature(FieldFeature.MAP_PINNED_FIELD)) {
              return !(fieldsGetter?.(entityType) ?? []).find(
                (schemaField) =>
                  schemaField.category === SchemaFieldCategory.SYSTEM_REQUIRED &&
                  modelField.platformName === schemaField.field
              );
            }

            return false;
          })
          .map((field) => field.name),
      }),
      {
        [EntityType.COMPANY]: [],
        [EntityType.PERSON]: [],
        [EntityType.DEAL]: [],
      }
    );

    const orgConfiguration: EntityColumnsConfiguration = {
      actions: {
        [EntityType.COMPANY]: false,
        [EntityType.PERSON]: false,
        [EntityType.DEAL]: false,
        ...orgMetadataMapConfiguration?.actions,
      },
      engagement: {
        [EntityType.COMPANY]: false,
        [EntityType.PERSON]: false,
        [EntityType.DEAL]: false,
        ...orgMetadataMapConfiguration?.engagement,
      },
      [EntityType.COMPANY]: [],
      [EntityType.PERSON]: [],
      [EntityType.DEAL]: [],
      ...MAP_ENTITY_TYPES.reduce(
        (result, entityType) => ({
          ...result,
          [entityType]: (Array.isArray(orgMetadataMapConfiguration?.[entityType])
            ? uniq([
                ...pinnedFields[entityType],
                ...(orgMetadataMapConfiguration[entityType] ?? []),
              ])
            : pinnedFields[entityType]
          )
            .filter(
              (fieldName: string) => !!getFieldModelByEntityType(entityType).getByName(fieldName)
            )
            .map((fieldName: string) => getFieldModelByEntityType(entityType).getByName(fieldName)),
        }),
        {}
      ),
    };

    const teamConfiguration: EntityColumnsConfiguration = {
      actions: {
        ...MAP_ENTITY_TYPES.reduce(
          (result, entityType) => ({
            ...result,
            [entityType]:
              (teamMetadataMapConfiguration?.actions?.[entityType] ||
                orgConfiguration.actions[entityType]) ??
              orgConfiguration.actions[entityType],
          }),
          {
            [EntityType.COMPANY]: false,
            [EntityType.PERSON]: false,
            [EntityType.DEAL]: false,
          }
        ),
      },
      engagement: {
        ...MAP_ENTITY_TYPES.reduce(
          (result, entityType) => ({
            ...result,
            [entityType]:
              (teamMetadataMapConfiguration?.engagement?.[entityType] ||
                orgConfiguration.engagement[entityType]) ??
              orgConfiguration.engagement[entityType],
          }),
          {
            [EntityType.COMPANY]: false,
            [EntityType.PERSON]: false,
            [EntityType.DEAL]: false,
          }
        ),
      },
      [EntityType.COMPANY]: [],
      [EntityType.PERSON]: [],
      [EntityType.DEAL]: [],
      ...MAP_ENTITY_TYPES.reduce(
        (result, entityType) => ({
          ...result,
          [entityType]: (Array.isArray(teamMetadataMapConfiguration?.[entityType])
            ? uniq(teamMetadataMapConfiguration[entityType] ?? [])
            : []
          )
            .filter((fieldName: string) => {
              const field = getFieldModelByEntityType(entityType).getByName(fieldName);

              if (field) {
                return !orgConfiguration[entityType]?.includes(field);
              }

              return false;
            })
            .map((fieldName: string) => getFieldModelByEntityType(entityType).getByName(fieldName)),
        }),
        {}
      ),
    };

    const userConfiguration: EntityColumnsConfiguration = {
      actions: {
        ...MAP_ENTITY_TYPES.reduce(
          (result, entityType) => ({
            ...result,
            [entityType]:
              (userMetadataMapConfiguration?.actions?.[entityType] ||
                teamConfiguration.actions[entityType] ||
                orgConfiguration.actions[entityType]) ??
              orgConfiguration.actions[entityType],
          }),
          {
            [EntityType.COMPANY]: false,
            [EntityType.PERSON]: false,
            [EntityType.DEAL]: false,
          }
        ),
      },
      engagement: {
        ...MAP_ENTITY_TYPES.reduce(
          (result, entityType) => ({
            ...result,
            [entityType]:
              (userMetadataMapConfiguration?.engagement?.[entityType] ||
                teamConfiguration.engagement[entityType] ||
                orgConfiguration.engagement[entityType]) ??
              orgConfiguration.engagement[entityType],
          }),
          {
            [EntityType.COMPANY]: false,
            [EntityType.PERSON]: false,
            [EntityType.DEAL]: false,
          }
        ),
      },
      [EntityType.COMPANY]: [],
      [EntityType.PERSON]: [],
      [EntityType.DEAL]: [],
      ...MAP_ENTITY_TYPES.reduce(
        (result, entityType) => ({
          ...result,
          [entityType]: (Array.isArray(userMetadataMapConfiguration?.[entityType])
            ? uniq(userMetadataMapConfiguration[entityType] ?? [])
            : []
          )
            .filter((fieldName: string) => {
              const field = getFieldModelByEntityType(entityType).getByName(fieldName);

              if (field) {
                return !(
                  orgConfiguration[entityType]?.includes(field) ||
                  teamConfiguration[entityType]?.includes(field)
                );
              }

              return false;
            })
            .map((fieldName: string) => getFieldModelByEntityType(entityType).getByName(fieldName)),
        }),
        {}
      ),
    };

    const result: RecordPreviewConfiguration = {
      [Origin.ORGANIZATION]: orgConfiguration,
      [Origin.TEAM]: teamConfiguration,
      [Origin.PRIVATE]: userConfiguration,
      teamId,
    };

    yield put(setRecordsPreviewConfiguration(result));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* recordPreviewSaga() {
  yield takeEvery(updateRecordsPreviewConfiguration.request, onUpdateRecordsPreviewConfiguration);
}

import { defineMessages } from "react-intl";

const messages = defineMessages({
  addMetric: {
    id: "userPreviewCard.chartSelectionModal.addMetric",
    defaultMessage: "Add Metric",
    description: "Add Metric button label in Add Card Wizard on User Preview",
  },
  title: {
    id: "userPreviewCard.chartSelectionModal.title",
    defaultMessage: "Select Metric from Existing Charts {scope}",
    description: "Select Type step title in Add Card Wizard on User Preview",
  },
  preview: {
    id: "userPreviewCard.chartSelectionModal.preview",
    defaultMessage: "Preview",
    description: "Preview in Add Card Wizard on User Preview",
  },
  previewDescription: {
    id: "userPreviewCard.chartSelectionModal.preview.description",
    defaultMessage: "Select a chart to see preview",
    description: "Preview description in Add Card Wizard on User Preview",
  },
  placeholder: {
    id: "userPreviewCard.chartSelectionModal.preview.placeholder",
    defaultMessage: "Search Charts",
    description: "Search Placeholder in Add Card Wizard on User Preview",
  },
  infoText: {
    id: "userPreviewCard.chartSelectionModal.preview.infoText",
    defaultMessage:
      "This metric will appear on {noTeam, select, true {only {userName}'s preview} other {user previews for all Team Members in “{teamName}”}}",
    description: "Info text in Add Card Wizard on User Preview",
  },
  selfPreviewAlert: {
    id: "userPreviewCard.chartSelectionModal.preview.selfPreviewAlert",
    defaultMessage: "This metric will appear only on your user preview",
    description: "Info text in Add Card Wizard on User Self Preview",
  },
  defaultChart: {
    id: "userPreviewCard.chartSelectionModal.preview.defaultChart",
    defaultMessage: "Default chart",
    description: "Default chart text",
  },
  lastModified: {
    id: "userPreviewCard.chartSelectionModal.preview.lastModified",
    defaultMessage: "Last modified on {lastModified}",
    description: "Last modified on text",
  },
  noChartsTitle: {
    id: "userPreviewCard.chartSelectionModal.preview.noCharts.title",
    defaultMessage: "No charts available",
    description: "No charts available title",
  },
  noChartsDescription: {
    id: "userPreviewCard.chartSelectionModal.preview.noCharts.description",
    defaultMessage: "Add a chart to the dashboard for this team or user",
    description: "No charts available description",
  },
});

export default messages;

import { Route } from "@mapmycustomers/shared/types/entity";
import { ActionType, createAsyncAction } from "typesafe-actions";

export const fetchCompanyRoutes = createAsyncAction(
  "@routes/fetchCompanyRoutes/request",
  "@routes/fetchCompanyRoutes/success",
  "@routes/fetchCompanyRoutes/failure"
)<{ query?: string }, Route[], void>();

export const fetchPeopleRoutes = createAsyncAction(
  "@routes/fetchPeopleRoutes/request",
  "@routes/fetchPeopleRoutes/success",
  "@routes/fetchPeopleRoutes/failure"
)<{ query?: string }, Route[], void>();

export type Actions = ActionType<typeof fetchCompanyRoutes | typeof fetchPeopleRoutes>;

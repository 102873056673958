import { put, select, takeLatest } from "redux-saga/effects";
import { updateActivity } from "./actions";
import { getOrganization } from "store/iam";
import { handleError } from "store/errors/actions";
import { callApi } from "store/api/callApi";
import { Activity, EntityType } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import { activityLayoutModel } from "util/layout/impl";
import { notifyActivityRelatedEntities } from "../../../../store/activity/actions";
import { notifyAboutChanges } from "store/uiSync/actions";

export function* onUpdateActivity({
  payload: { activity, callback },
}: ReturnType<typeof updateActivity.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(activity).id;

    const updatedActivity: Activity = yield callApi("updateActivity", org.id, layoutId, activity);
    notifyAboutChanges({ entityType: EntityType.ACTIVITY, updated: [updatedActivity] });
    yield put(notifyActivityRelatedEntities(updatedActivity));

    callback(updatedActivity);
    yield put(updateActivity.success());
  } catch (error) {
    yield put(updateActivity.failure());
    yield put(handleError({ error }));
  }
}

export function* activityInfoModalSaga() {
  yield takeLatest(updateActivity.request, onUpdateActivity);
}

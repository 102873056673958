import { createReducer } from "typesafe-actions";
import {
  fetchHeatMapData,
  HeatMapActions,
  setHeatMapSettings,
  updateHeatMapActivityTypes,
  updateHeatMapDateRange,
  updateHeatMapFunnels,
  updateHeatMapOpenStages,
  updateHeatMapRange,
  updateHeatMapStageTypes,
  updateHeatMapType,
} from "scene/map/store/layers/heatMap/actions";
import { EntityType } from "@mapmycustomers/shared/enum";
import HeatMapLayerData from "scene/map/store/layers/heatMap/HeatMapLayerData";
import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import { parseApiDate } from "../../../../../util/parsers";

export const heatMapLayerInitialState: HeatMapLayerData = {
  activityTypeIds: [],
  funnelIds: [],
  openStageIds: [],
  pins: [],
  range: { min: 0, max: 0 },
  stageTypes: [DealStageType.WON, DealStageType.LOST, DealStageType.DEFAULT],
  type: EntityType.ACTIVITY,
  visible: false,
};

const heatMapLayer = createReducer<HeatMapLayerData, HeatMapActions>(heatMapLayerInitialState)
  .handleAction(fetchHeatMapData.success, (state, { payload }) => ({
    ...state,
    pins: payload,
  }))
  .handleAction(updateHeatMapDateRange, (state, { payload }) => ({
    ...state,
    ...payload,
    pins: [],
  }))
  .handleAction(updateHeatMapType, (state, { payload }) => ({
    ...state,
    type: payload,
    pins: [],
  }))
  .handleAction(updateHeatMapActivityTypes, (state, { payload }) => ({
    ...state,
    activityTypeIds: payload,
    // do not reset pins if current heat map type is not activities
    pins: state.type === EntityType.ACTIVITY ? [] : state.pins,
  }))
  .handleAction(updateHeatMapFunnels, (state, { payload }) => ({
    ...state,
    funnelIds: payload,
    // do not reset pins if current heat map type is not deals
    pins: state.type === EntityType.DEAL ? [] : state.pins,
  }))
  .handleAction(updateHeatMapStageTypes, (state, { payload }) => ({
    ...state,
    stageTypes: payload,
    // reset selected open stages when Default Stage type is unselected
    openStageIds: !payload.includes(DealStageType.DEFAULT) ? [] : state.openStageIds,
    // do not reset pins if current heat map type is not deals
    pins: state.type === EntityType.DEAL ? [] : state.pins,
  }))
  .handleAction(updateHeatMapOpenStages, (state, { payload }) => ({
    ...state,
    openStageIds: payload,
    // do not reset pins if current heat map type is not deals
    pins: state.type === EntityType.DEAL ? [] : state.pins,
  }))
  .handleAction(updateHeatMapRange, (state, { payload }) => ({
    ...state,
    range: payload,
  }))
  .handleAction(setHeatMapSettings, (state, { payload }) => ({
    ...state,
    ...payload,
    ...(payload?.dateRange
      ? {
          dateRange: {
            startDate: parseApiDate(payload.dateRange.startDate),
            endDate: parseApiDate(payload.dateRange.endDate),
          },
        }
      : {}),
  }));

export default heatMapLayer;

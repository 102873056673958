import React, { MouseEvent, useCallback } from "react";
import DealLossReason from "@mapmycustomers/shared/types/entity/deals/DealLossReason";
import Popconfirm from "antd/es/popconfirm";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons/faMinusCircle";
import { deleteDealLossReason } from "store/dealLossReasons/actions";
import { connect } from "react-redux";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import MultilineText from "component/typography/MultilineText";
import cn from "classnames";
import { stopEvents } from "util/browser";
import styles from "./DeleteLossReasonButton.module.scss";

interface Props {
  className?: string;
  deleteDealLossReason: typeof deleteDealLossReason.request;
  lossReason: DealLossReason;
}

const DeleteLossReasonButton: React.FC<Props> = ({
  className,
  deleteDealLossReason,
  lossReason,
}) => {
  const intl = useIntl();
  const [deleteConfirmationVisible, showDeleteConfirmation, hideDeleteConfirmation] = useBoolean();

  const handleDeleteReasonClick = useCallback(
    (e?: MouseEvent) => {
      stopEvents(e);
      hideDeleteConfirmation();
      deleteDealLossReason(lossReason.id);
    },
    [deleteDealLossReason, hideDeleteConfirmation, lossReason]
  );

  const handleCancelClick = useCallback(
    (e?: MouseEvent) => {
      stopEvents(e);
      hideDeleteConfirmation();
    },
    [hideDeleteConfirmation]
  );

  const handleIconClick = useCallback(
    (e: MouseEvent) => {
      stopEvents(e);
      showDeleteConfirmation();
    },
    [showDeleteConfirmation]
  );

  return (
    <Popconfirm
      className={cn(styles.container, className)}
      overlayClassName={styles.overlay}
      cancelText={intl.formatMessage({
        id: "dealLostModal.deleteConfirmation.cancel",
        defaultMessage: "Cancel",
        description: "Cancel button in delete loss reason confirmation",
      })}
      destroyTooltipOnHide
      okText={intl.formatMessage({
        id: "dealLostModal.deleteConfirmation.ok",
        defaultMessage: "Delete",
        description: "Ok button in delete loss reason confirmation",
      })}
      onCancel={handleCancelClick}
      onConfirm={handleDeleteReasonClick}
      placement="right"
      title={
        <MultilineText>
          {intl.formatMessage({
            id: "dealLostModal.deleteConfirmation.title",
            defaultMessage:
              "Are you sure to delete this reason?\nYou will not be able to recover this if deleted.",
            description: "Text in delete loss reason confirmation",
          })}
        </MultilineText>
      }
      visible={deleteConfirmationVisible}
    >
      <FontAwesomeIcon
        className={styles.deleteIcon}
        icon={faMinusCircle}
        onClick={handleIconClick}
      />
    </Popconfirm>
  );
};

const mapDispatchToProps = {
  deleteDealLossReason: deleteDealLossReason.request,
};

export default connect(null, mapDispatchToProps)(DeleteLossReasonButton);

import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";
import { getUserMetaData } from "store/iam";
import { getGroupModeBasePath } from "./groupMode/selectors";
import { getFilteredLeadFinderRecords, getLeadFinderModeBasePath } from "./leadFinder/selectors";
import Path from "enum/Path";
import MapMode from "scene/map/enums/MapMode";
import {
  LEAD_FINDER_DEFAULT_DISTANCE_KM,
  LEAD_FINDER_DEFAULT_DISTANCE_MILES,
} from "scene/map/utils/consts";
import { parseApiDate } from "util/parsers";
import { getTerritoryModeBasePath } from "./territoryMode/selectors";
import localSettings from "config/LocalSettings";
import { getSelectedRecords } from "./selectors";
import { leadIdGetter } from "util/map/idGetters";

const mapState = (state: RootState) => state.scene.map;

// basic state
export const isInitializing = createSelector(mapState, ({ initializing }) => initializing);
export const getMapViewport = createSelector(mapState, ({ viewport }) => viewport);
export const getMapStyle = createSelector(mapState, ({ style }) => style);

export const getMapMode = createSelector(mapState, ({ mode }) => mode);
export const getMapModeBasePath = createSelector(
  mapState,
  getGroupModeBasePath,
  getTerritoryModeBasePath,
  getLeadFinderModeBasePath,
  ({ mode }, groupModeBasePath, territoryModeBasePath, leadFinderModeBasePath) => {
    switch (mode) {
      case MapMode.GROUPS:
        return groupModeBasePath;

      case MapMode.TERRITORIES:
        return territoryModeBasePath;

      case MapMode.LEAD_FINDER:
        return leadFinderModeBasePath;
    }

    return `${Path.MAP}`;
  }
);

// pin drop
export const getMapPinDrop = createSelector(mapState, ({ pinDrop }) => pinDrop);

// lasso
export const getMapLasso = createSelector(mapState, ({ lasso }) => lasso);
export const getMapLassoAllowAdd = createSelector(getMapLasso, (lasso) => !!lasso?.allowAdd);
export const getMapLassoPaths = createSelector(getMapLasso, (lasso) =>
  lasso?.paths && Array.isArray(lasso.paths) ? lasso.paths : []
);
export const getMapLassoPathsCount = createSelector(getMapLassoPaths, (paths) => paths.length);
export const isLassoLoading = createSelector(getMapLasso, ({ loading }) => loading);
export const isLassoContainsClusters = createSelector(
  getMapLasso,
  ({ containsClusters }) => containsClusters
);

export const getLassoOptions = createSelector(getMapLasso, ({ options }) => options);
export const getLassoMode = createSelector(getLassoOptions, (options) => options.mode);
export const getVisibleLassoEntityTypes = createSelector(
  getLassoOptions,
  (options) => options.visibleEntityTypes
);

// filters
export const getOfferedFilters = createSelector(mapState, ({ offerFilters }) => offerFilters);

// metaData settings

export const getLeadFinderSettings = createSelector(getUserMetaData, (metaData) => {
  const settings = metaData.leadFinderSettings;

  const doesUseSiUnits = localSettings.doesUseSiUnits();
  const defaultSearchDistance = doesUseSiUnits
    ? LEAD_FINDER_DEFAULT_DISTANCE_KM
    : LEAD_FINDER_DEFAULT_DISTANCE_MILES;

  if (!settings) {
    return {
      customDistances: [],
      recentSearches: [],
      savedSearches: [],
      searchDistance: defaultSearchDistance,
    };
  }

  return {
    recentSearches: Array.isArray(settings.recentSearches) ? settings.recentSearches : [],
    savedSearches: Array.isArray(settings.savedSearches) ? settings.savedSearches : [],
    customDistances: Array.isArray(settings.customDistances) ? settings.customDistances : [],
    searchDistance:
      !settings.searchDistance || isNaN(settings.searchDistance)
        ? defaultSearchDistance
        : Math.max(Math.min(settings.searchDistance, defaultSearchDistance), 0.1),
  };
});

export const getHeatMapSettings = createSelector(getUserMetaData, (metaData) => {
  const settings = metaData.heatMapSettings;
  if (!settings) {
    return;
  }
  // it's also good to clean up the list of activity, funnel, and openStage ids, but
  // for now, we just rely on the fact that these will rarely change
  return Object.entries(settings).reduce(
    (result, [key, value]) => ({
      ...result,
      [key]: ["startDate", "endDate"].includes(key) ? parseApiDate(value) : value,
    }),
    {}
  );
});

export const getBoundariesSettings = createSelector(
  getUserMetaData,
  (metaData) => metaData.boundariesSettings ?? metaData.territoriesSettings
);

export const getUserLocationsSettings = createSelector(
  getUserMetaData,
  (metaData) => metaData.userLocationsSettings
);

export const getAlertsSettings = createSelector(
  getUserMetaData,
  (metaData) => metaData.alertsSettings
);

export const getTrafficSettings = createSelector(
  getUserMetaData,
  (metaData) => metaData.trafficSettings
);

export const getFrequencyActivityAction = createSelector(
  getUserMetaData,
  (metaData) => metaData.lastFrequencyActivityAction
);

export const getLegendsSettings = createSelector(
  getUserMetaData,
  (metaData) => metaData.legendsSettings
);

export const getFrequencyCustomInterval = createSelector(
  getUserMetaData,
  (metaData) => metaData.frequencyCustomInterval
);

export const getFilteredSelectedRecords = createSelector(
  getFilteredLeadFinderRecords,
  getSelectedRecords,
  (records, selection) => records.filter((lead) => selection.has(leadIdGetter(lead)))
);

export const getFilteredSelectedRecordsCount = createSelector(
  getFilteredSelectedRecords,
  (records) => records.length
);

export const isLocationSearchVisible = createSelector(
  mapState,
  ({ locationSearchVisible }) => locationSearchVisible
);

export const getLookUpLocation = createSelector(mapState, ({ lookUpLocation }) => lookUpLocation);

// other selectors
export * from "./mapMode/selectors";
export * from "./groupMode/selectors";
export * from "./layers/selectors";
export * from "./leadFinder/selectors";
export * from "./legends/selectors";
export * from "./pinLegends/selectors";
export * from "./pinLocation/selectors";
export * from "./territoryMode/selectors";

import React from "react";
import { TextField } from "@mapmycustomers/ui";
import CircleColorPicker from "component/CircleColorPicker";
import CadenceFields from "component/createEditEntity/Group/components/CadenceFields";
import SharingFields from "component/createEditEntity/Group/components/SharingFields";
import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import User from "@mapmycustomers/shared/types/User";
import { useIntl } from "react-intl";

interface Props {
  cadence?: false | number;
  cadenceActivityTypes?: ActivityType["id"][];
  color?: AnyColor;
  name?: string;
  onCadenceActivityTypesChange?: (activityTypeIds: ActivityType["id"][]) => void;
  onCadenceChange?: (value: false | number) => void;
  onColorChange?: (color?: AnyColor) => void;
  onNameChange: (name: string) => void;
  onSharedUsersChange?: (userIds: User["id"][]) => void;
  userIds?: User["id"][];
}

const Form: React.FC<Props> = ({
  cadence,
  cadenceActivityTypes,
  color,
  name,
  onCadenceActivityTypesChange,
  onCadenceChange,
  onColorChange,
  onNameChange,
  onSharedUsersChange,
  userIds,
}) => {
  const intl = useIntl();
  return (
    <>
      <TextField
        label={intl.formatMessage({
          id: "createGroupModal.field.name",
          defaultMessage: "Group Name",
          description: "Name field label in the Create Group modal",
        })}
        onChange={onNameChange}
        value={name}
      />

      <CircleColorPicker
        allowNoColor
        label={intl.formatMessage({
          id: "createGroupModal.field.color",
          defaultMessage: "Select Group Color",
          description: "Color field label in the Create Group modal",
        })}
        onChange={onColorChange}
        value={color}
      />

      <CadenceFields
        cadence={cadence}
        cadenceActivityTypeIds={cadenceActivityTypes}
        onCadenceActivityTypesChange={onCadenceActivityTypesChange}
        onCadenceChange={onCadenceChange}
      />

      {onSharedUsersChange && <SharingFields onChange={onSharedUsersChange} value={userIds} />}
    </>
  );
};

export default Form;

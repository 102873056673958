import DateRangeType from "../enums/DateRangeType";
import {
  endOfMonth,
  endOfYesterday,
  startOfMonth,
  startOfYesterday,
  subMonths,
  subWeeks,
} from "date-fns/esm";
import DateRange from "../types/DateRange";
import { getWeekEnd, getWeekStart } from "util/dates";

const dateRangeTypeToDateRange = (dateRangeType: DateRangeType): DateRange | undefined => {
  let defaultRange = {
    startDate: startOfYesterday(),
    endDate: endOfYesterday(),
  };

  switch (dateRangeType) {
    case DateRangeType.YESTERDAY:
      return defaultRange;
    case DateRangeType.LAST_WEEK:
      return {
        startDate: getWeekStart(subWeeks(Date.now(), 1)),
        endDate: getWeekEnd(subWeeks(Date.now(), 1)),
      };
    case DateRangeType.THIS_WEEK:
      return { ...defaultRange, startDate: getWeekStart(defaultRange.startDate) };
    case DateRangeType.THIS_MONTH:
      return { ...defaultRange, startDate: startOfMonth(defaultRange.startDate) };
    case DateRangeType.PREVIOUS_MONTH:
      return {
        startDate: startOfMonth(subMonths(Date.now(), 1)),
        endDate: endOfMonth(subMonths(Date.now(), 1)),
      };
  }
};

export default dateRangeTypeToDateRange;

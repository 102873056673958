import CompanyAssociation from "component/associations/CompanyAssociation";
import {
  addCompanyParentCompany,
  addDealParentCompany,
  addPersonCompanies,
} from "../store/actions";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import {
  Company,
  Deal,
  EntityType,
  EntityTypeSupportingPostCreationModal,
  Person,
} from "@mapmycustomers/shared/types/entity";

interface Props {
  addCompanyParentCompany: typeof addCompanyParentCompany.request;
  addDealParentCompany: typeof addDealParentCompany.request;
  addPersonCompanies: typeof addPersonCompanies.request;
  createdRecord: Company | Person | Deal;
  entityType: EntityTypeSupportingPostCreationModal;
  onHide: (updated?: boolean) => void;
}

const ParentCompany: React.FC<Props> = ({
  addCompanyParentCompany,
  addDealParentCompany,
  addPersonCompanies,
  createdRecord,
  entityType,
  onHide,
}) => {
  const intl = useIntl();
  const handleSelectParent = useCallback(
    (companyIds: Company["id"][]) => {
      if (companyIds.length > 0) {
        if (entityType === EntityType.COMPANY) {
          addCompanyParentCompany({ id: createdRecord.id, parentCompanyId: companyIds[0] });
        } else if (entityType === EntityType.PERSON) {
          addPersonCompanies({ id: createdRecord.id, companyIds });
        } else if (entityType === EntityType.DEAL) {
          addDealParentCompany({ id: createdRecord.id, parentCompanyId: companyIds[0] });
        }
        onHide();
      }
    },
    [
      addCompanyParentCompany,
      addDealParentCompany,
      addPersonCompanies,
      createdRecord,
      entityType,
      onHide,
    ]
  );
  const companyParentAccount =
    entityType === EntityType.COMPANY ? (createdRecord as Company)?.parentAccount : undefined;
  const personCompanies =
    entityType === EntityType.PERSON ? (createdRecord as Person)?.accounts : undefined;

  return (
    <CompanyAssociation
      assignedCompanies={companyParentAccount ? [companyParentAccount] : personCompanies}
      associateWith={createdRecord.id}
      entityType={entityType}
      multiselect={entityType === EntityType.PERSON}
      onHide={onHide}
      onSelect={handleSelectParent}
      title={
        entityType === EntityType.COMPANY
          ? intl.formatMessage({
              id: "companyRecordPane.relationships.parentCompany.modal.title",
              defaultMessage: "Select Parent Company",
              description: "Title of a Select Parent modal in the Preview Record pane",
            })
          : intl.formatMessage({
              id: "companyRecordPane.relationships.companies.modal.title",
              defaultMessage: "Select Companies",
              description: "Title of a Select Companies modal in the Preview Record pane",
            })
      }
    />
  );
};

const mapDispatchToProps = {
  addCompanyParentCompany: addCompanyParentCompany.request,
  addPersonCompanies: addPersonCompanies.request,
  addDealParentCompany: addDealParentCompany.request,
};

export default connect(null, mapDispatchToProps)(ParentCompany);

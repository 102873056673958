import {
  applyCheckInsDrillDownListViewSettings,
  fetchCheckInsCardDrillDownData,
  hideCheckInsDrillDown,
  CheckInsCardActions,
  showCheckInsDrillDown,
} from "./actions";
import { createReducer } from "typesafe-actions";
import { DEFAULT_PAGE_SIZE } from "util/consts";
import CheckInsCardConfiguration from "types/dashboard/cards/checkIns/CheckInsCardConfiguration";
import DrillDownViewState from "scene/dashboard/types/DrillDownViewState";
import DateRange from "@mapmycustomers/shared/types/range/DateRange";

export interface CheckInsCardState {
  configuration?: CheckInsCardConfiguration; // also works as a "visible" trigger, visible if set
  drillDownCustomDateRange?: DateRange;
  loading?: boolean;
  totalFilteredRecords: number;
  totalRecords: number;
  viewState: DrillDownViewState;
}

export const checkInsInitialState: CheckInsCardState = {
  totalFilteredRecords: 0,
  totalRecords: 0,
  viewState: {
    columns: [],
    filter: {},
    range: { startRow: 0, endRow: DEFAULT_PAGE_SIZE },
    sort: [],
    viewAs: undefined,
  },
};

const checkInsState = createReducer<CheckInsCardState, CheckInsCardActions>(checkInsInitialState)
  .handleAction(showCheckInsDrillDown.request, (state, { payload }) => ({
    ...state,
    configuration: payload.configuration,
    drillDownCustomDateRange: payload.customDateRange,
  }))
  .handleAction(showCheckInsDrillDown.success, (state, { payload }) => ({
    ...state,
    viewState: payload.viewState,
  }))
  .handleAction(hideCheckInsDrillDown, (state) => ({
    ...state,
    configuration: undefined,
    drillDownCustomDateRange: undefined,
  }))
  .handleAction(fetchCheckInsCardDrillDownData.request, (state, { payload }) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchCheckInsCardDrillDownData.success, (state, { payload }) => ({
    ...state,
    loading: false,
    totalFilteredRecords: payload.totalFilteredRecords,
    totalRecords: payload.totalRecords,
  }))
  .handleAction(fetchCheckInsCardDrillDownData.failure, (state, { payload }) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyCheckInsDrillDownListViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      range: payload.range ?? state.viewState.range,
      sort: payload.sort ?? state.viewState.sort,
      filter: payload.filter ?? state.viewState.filter,
      columns: payload.columns ?? state.viewState.columns,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
    },
  }));

export default checkInsState;

import {
  Company,
  Deal,
  EntityTypeSupportingPostCreationModal,
  Person,
} from "@mapmycustomers/shared/types/entity";
import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

export const showGlobalPostCreationModal = createAction("@component/globalPostCreationModal/show")<{
  entity: Company | Person | Deal;
  entityType: EntityTypeSupportingPostCreationModal;
}>();

export const hideGlobalPostCreationModal = createAction(
  "@component/globalPostCreateCompanyModal/hide"
)<void>();

export const addCompanyChildCompanies = createAsyncAction(
  "postCreationModal/addCompanyChildCompanies/request",
  "postCreationModal/addCompanyChildCompanies/success",
  "postCreationModal/addCompanyChildCompanies/failure"
)<{ id: Company["id"]; companiesIds: Array<Company["id"]> }, Company[], void>();

export const addCompanyDeals = createAsyncAction(
  "postCreationModal/addCompanyDeals/request",
  "postCreationModal/addCompanyDeals/success",
  "postCreationModal/addCompanyDeals/failure"
)<{ id: Company["id"]; dealIds: Array<Deal["id"]> }, Deal[], void>();

export const addPersonDeals = createAsyncAction(
  "postCreationModal/addPersonDeals/request",
  "postCreationModal/addPersonDeals/success",
  "postCreationModal/addPersonDeals/failure"
)<{ id: Person["id"]; dealIds: Array<Deal["id"]> }, Deal[], void>();

export const addCompanyParentCompany = createAsyncAction(
  "postCreationModal/addCompanyParentCompany/request",
  "postCreationModal/addCompanyParentCompany/success",
  "postCreationModal/addCompanyParentCompany/failure"
)<{ id: Company["id"]; parentCompanyId: Company["id"] }, Company, void>();

export const addPersonCompanies = createAsyncAction(
  "postCreationModal/addPersonCompanies/request",
  "postCreationModal/addPersonCompanies/success",
  "postCreationModal/addPersonCompanies/failure"
)<{ id: Person["id"]; companyIds: Company["id"][] }, Company[], void>();

export const addDealParentCompany = createAsyncAction(
  "postCreationModal/addDealParentCompany/request",
  "postCreationModal/addDealParentCompany/success",
  "postCreationModal/addDealParentDeal/failure"
)<{ id: Deal["id"]; parentCompanyId: Company["id"] }, Company, void>();

export const addCompanyPeople = createAsyncAction(
  "postCreationModal/addCompanyPeople/request",
  "postCreationModal/addCompanyPeople/success",
  "postCreationModal/addCompanyPeople/failure"
)<{ id: Company["id"]; peopleIds: Array<Person["id"]> }, Person[], void>();

export const addDealParentPerson = createAsyncAction(
  "dealRecord/addDealParentPerson/request",
  "dealRecord/addDealParentPerson/success",
  "dealRecord/addDealParentPerson/failure"
)<{ id: Deal["id"]; parentPersonId: Person["id"] }, Person, void>();

export type Actions = ActionType<
  | typeof addCompanyChildCompanies
  | typeof addCompanyDeals
  | typeof addCompanyParentCompany
  | typeof addCompanyPeople
  | typeof addDealParentCompany
  | typeof addDealParentPerson
  | typeof addPersonCompanies
  | typeof addPersonDeals
  | typeof hideGlobalPostCreationModal
  | typeof showGlobalPostCreationModal
>;

import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import React, { ReactChildren, ReactElement } from "react";
import ConditionalFormField, { ConditionalFormFieldProps } from "component/ConditionalFormField";
import { isDefined } from "util/assert";

const getFieldMatcher = (field: IField) => {
  return (
    node: ReturnType<ReactChildren["toArray"]>[number]
  ): node is ReactElement<ConditionalFormFieldProps> => {
    if (!React.isValidElement(node)) {
      return false;
    }

    if (
      node.type === ConditionalFormField &&
      (typeof node.props?.children === "function" ||
        React.Children.count(node.props?.children) === 1)
    ) {
      const { fieldName, feature } = (node as ReactElement<ConditionalFormFieldProps>).props;
      const matchesName = isDefined(fieldName) ? fieldName === field.name : true;
      const matchesFeature = isDefined(feature) ? field.hasFeature(feature) : true;
      return matchesName && matchesFeature;
    }

    return false;
  };
};

export default getFieldMatcher;

import React, { useCallback } from "react";
import Menu from "antd/es/menu";
import { logout } from "store/auth/actions";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import { getMe } from "store/iam";
import { FormattedMessage } from "react-intl";
import styles from "./UserMenu.module.scss";
import { Avatar } from "@mapmycustomers/ui";
import UserProfileInfo from "./UserProfileInfo";
import UserRef from "@mapmycustomers/shared/types/User";
import configService from "config/ConfigService";
import { getLegacyLogoutAppUrl } from "util/appUrl";
import { Link } from "react-router-dom";
import Path from "enum/Path";
import Dropdown from "antd/es/dropdown";
import { navbarUserMenuClick } from "util/analytic/navbarAnalytics";
import Environment from "enum/Environment";

interface Props {
  logout: typeof logout;
  me: UserRef;
}

const UserMenu: React.FC<Props> = ({ logout, me }) => {
  const baseOldAppUrl = configService.getBaseOldAppUrl();

  // Logout wrapped in useCallback to avoid click event getting into its arguments
  const handleLogoutClick = useCallback(
    () => logout(getLegacyLogoutAppUrl(baseOldAppUrl)),
    [baseOldAppUrl, logout]
  );

  const userMenuOptions = (
    <Menu className={styles.userMenuItem}>
      <Menu.Item className={styles.userProfileInfo} key="userProfileInfo">
        <UserProfileInfo user={me} />
      </Menu.Item>
      <Menu.Divider />
      {configService.getCurrentEnvironment() !== Environment.SANDBOX && (
        <Menu.Item key="manageSubscription">
          <Link to={Path.BILLING} onClick={() => navbarUserMenuClick("manageSubscription")}>
            <FormattedMessage
              id="navbar.userMenu.manageSubscription"
              defaultMessage="Manage Your Subscription"
              description="Title of manage subscription option"
            />
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="support">
        <a
          href="https://support.mapmycustomers.com/hc/en-us"
          onClick={() => navbarUserMenuClick("support")}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FormattedMessage
            id="navbar.userMenu.support"
            defaultMessage="Support"
            description="Title of support option"
          />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <div className={styles.logout} onClick={handleLogoutClick}>
          <FormattedMessage
            id="navbar.userMenu.logout"
            defaultMessage="Logout"
            description="Title of logout option"
          />
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={userMenuOptions} placement="bottomRight" trigger={["click"]}>
      <div className={styles.container}>
        <Avatar size={36} user={me} />
      </div>
    </Dropdown>
  );
};

const mapStateToProps = (state: RootState) => ({
  me: getMe(state)!, // profile must be loaded beforehand
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);

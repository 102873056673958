import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import FilePreview from "types/FilePreview";
import Import from "@mapmycustomers/shared/types/imports/Import";
import ImportHistoryRow from "scene/settings/component/Imports/type/ImportHistoryRow";
import { EntityTypesSupportedByImport } from "@mapmycustomers/shared/types/entity";
import { NewImportState } from "./index";
import ImportConfig from "@mapmycustomers/shared/types/imports/ImportConfig";
import TimeZone from "@mapmycustomers/shared/types/TimeZone";
import StepsEnum from "scene/settings/component/Imports/enum/Steps";
import ImportMappingOptions from "../type/ImportMappingOption";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";

export const selectEntityType =
  createAction("import/selectEntityType")<EntityTypesSupportedByImport>();

export const setEntityType = createAction("import/setEntityType")<{
  entityType: EntityTypesSupportedByImport;
  mappingOptions: ImportMappingOptions[];
}>();

export const selectFile = createAsyncAction(
  "import/selectFile/request",
  "import/selectFile/success",
  "import/selectFile/failure"
)<File, FilePreview, unknown>();

export const clearFilePreview = createAction("import/clearFilePreview")<void>();

export const setMappingField = createAction("import/setMappingField")<{
  fileField: string;
  mmcField: string;
}>();

export const setTimezone = createAction("import/setTimezone")<TimeZone>();

export const startImport = createAsyncAction(
  "import/startImport/request",
  "import/startImport/success",
  "import/startImport/failure"
)<void, Import, unknown>();

export const updateStartedImport = createAsyncAction(
  "import/updateStartedImport/request",
  "import/updateStartedImport/success",
  "import/updateStartedImport/failure"
)<void, Import, unknown>();

export const initializeMainPage = createAsyncAction(
  "import/initializeMainPage/request",
  "import/initializeMainPage/success",
  "import/initializeMainPage/failure"
)<void, { history: ImportHistoryRow[] }, unknown>();

export const initializeAddPage = createAsyncAction(
  "import/initializeAddPage/request",
  "import/initializeAddPage/success",
  "import/initializeAddPage/failure"
)<void, { newImportState: NewImportState }, unknown>();

export const fetchImportConfigs = createAsyncAction(
  "import/fetchImportConfigs/request",
  "import/fetchImportConfigs/success",
  "import/fetchImportConfigs/failure"
)<void, ImportConfig[], unknown>();

export const createConfig = createAsyncAction(
  "import/createConfig/request",
  "import/createConfig/success",
  "import/createConfig/failure"
)<{ callback?: () => void; name: string }, ImportConfig, unknown>();

export const updateConfig = createAsyncAction(
  "import/updateConfig/request",
  "import/updateConfig/success",
  "import/updateConfig/failure"
)<{ callback?: () => void; importConfig: ImportConfig }, ImportConfig, unknown>();

export const deleteConfig = createAsyncAction(
  "import/deleteConfig/request",
  "import/deleteConfig/success",
  "import/deleteConfig/failure"
)<ImportConfig["id"], void, unknown>();

export const selectConfig = createAction("import/selectConfig")<ImportConfig>();

export const selectLayoutId = createAction("import/selectLayoutId")<FormLayout["id"]>();

export const updateCurrentConfigMatching = createAsyncAction(
  "import/updateCurrentConfig/request",
  "import/updateCurrentConfig/success",
  "import/updateCurrentConfig/failure"
)<void, ImportConfig, unknown>();

export const initMatchFieldsStep = createAction("import/initMatchFieldsStep")<void>();

export const setStep = createAction("import/setStep")<StepsEnum>();

export type Actions = ActionType<
  | typeof clearFilePreview
  | typeof createConfig
  | typeof deleteConfig
  | typeof fetchImportConfigs
  | typeof initMatchFieldsStep
  | typeof initializeAddPage
  | typeof initializeMainPage
  | typeof selectConfig
  | typeof selectEntityType
  | typeof selectFile
  | typeof selectLayoutId
  | typeof setEntityType
  | typeof setMappingField
  | typeof setStep
  | typeof setTimezone
  | typeof startImport
  | typeof updateConfig
  | typeof updateCurrentConfigMatching
  | typeof updateStartedImport
>;

import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import styles from "./PreviousActivities.module.scss";
import { parseApiDate } from "util/parsers";
import { Col, Row } from "antd/es/grid";
import ActivityTypeIcon from "component/activity/ActivityTypeIcon";
import Tag from "antd/es/tag";
import layout from "styles/layout";
import { formatDate } from "util/formatters";
import { Avatar, LoadingSpinner } from "@mapmycustomers/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import cn from "classnames";
import ButtonLink from "component/ButtonLink";
import { Activity } from "@mapmycustomers/shared/types/entity";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import {
  clearPreviousActivities,
  fetchPreviousActivities,
} from "../../../../../../store/frequency/actions";
import {
  getFrequencyModalConfig,
  getPreviousActivities,
  getPreviousActivitiesTotal,
  isPreviousActivitiesLoading,
} from "../../../../../../store/frequency";
import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";
import { ACTIVITIES_PORTION_SIZE } from "../../../../util/const";
import FrequencyCollapsePanel from "component/frequency/FrequencyCollapsePanel";
import Tooltip from "antd/es/tooltip";
import Chip from "../../../../../Chip";
import { subscribe, unsubscribe } from "../../../../../../store/uiSync/actions";
import { EntityType } from "@mapmycustomers/shared/types/entity";
import getOverdueStatus from "../../../../../../util/activity/getOverdueStatus";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import FrequencyEntity from "../../../../type/FrequencyEntity";
import useAnalytics from "../../../../../../util/contexts/useAnalytics";
import { showEntityView } from "store/entityView/actions";
import { getPreviewEntityUniqueElementId } from "../../../../../../util/browser";

interface Props {
  clearPreviousActivities: typeof clearPreviousActivities;
  entity: FrequencyEntity;
  loading: boolean;
  onFetchPreviousActivities: typeof fetchPreviousActivities.request;
  previousActivities: Activity[];
  previousActivitiesTotal: number;
  showEntityView: typeof showEntityView;
  subscribe: typeof subscribe;
  unsubscribe: typeof unsubscribe;
}

const PreviousActivities: React.FC<Props> = ({
  clearPreviousActivities,
  loading,
  entity,
  onFetchPreviousActivities,
  previousActivities,
  previousActivitiesTotal,
  subscribe,
  unsubscribe,
  showEntityView,
}) => {
  const intl = useIntl();
  const [offset, setOffset] = useState<number>(0);
  const analytics = useAnalytics();

  const increaseOffset = useDebouncedCallback(
    [
      () => {
        setOffset((value) => value + ACTIVITIES_PORTION_SIZE);
      },
      100,
    ],
    [setOffset]
  );

  const resetOffset = useCallback(() => {
    clearPreviousActivities();
    if (offset === 0) {
      onFetchPreviousActivities({
        offset: 0,
      });
    } else {
      setOffset(0);
    }
  }, [clearPreviousActivities, offset, onFetchPreviousActivities, setOffset]);

  useEffect(() => {
    onFetchPreviousActivities({
      offset,
    });
  }, [offset, onFetchPreviousActivities]);

  useEffect(() => {
    const listener = {
      onAdd: resetOffset,
      onUpdate: resetOffset,
    };

    subscribe({ entityTypes: [entity.entity, EntityType.ACTIVITY], listener });

    return () => {
      unsubscribe({ entityTypes: [entity.entity, EntityType.ACTIVITY], listener });
    };
  }, [entity, resetOffset, subscribe, unsubscribe]);

  const handleOpenPanel = useCallback(() => {
    analytics.clicked(["Previous Activities"]);
  }, [analytics]);

  const handleActivityClick = useCallback(
    (event, activityId: Activity["id"]) => {
      showEntityView({
        entityId: activityId,
        entityType: EntityType.ACTIVITY,
        elementId: event?.target ? getPreviewEntityUniqueElementId(event.target) : undefined,
      });
    },
    [showEntityView]
  );

  return (
    <div className={styles.container}>
      <FrequencyCollapsePanel
        header={intl.formatMessage({
          id: "frequency.frequencyModal.activities.previous.title",
          defaultMessage: "previous activities",
          description: "frequency component - frequency modal - previous activities - title",
        })}
        onOpen={handleOpenPanel}
      >
        {previousActivitiesTotal > previousActivities.length && (
          <ButtonLink onClick={increaseOffset}>
            {intl.formatMessage({
              id: "frequency.frequencyModal.activities.previous.showEarlier",
              defaultMessage: "Show earlier activities",
              description:
                "frequency component - frequency modal - previous activities - Show earlier activities",
            })}
          </ButtonLink>
        )}
        <div className={styles.activityList}>
          {previousActivities.map((activity) => {
            const { assignee, crmActivityType, name, completed, id, reliability, note, startAt } =
              activity;
            return (
              <div
                className={styles.activity}
                key={id}
                onClick={(e) => handleActivityClick(e, activity.id)}
              >
                <Row justify="space-between">
                  <Col>
                    <Row align="middle" gutter={layout.spacerXS}>
                      <Col>
                        <Tooltip
                          title={intl.formatMessage(
                            {
                              id: "frequency.frequencyModal.activities.previous.tooltip",
                              defaultMessage: "{activityName} with {companyName}",
                              description:
                                "frequency component - frequency modal - previous activities - tooltip",
                            },
                            { activityName: name, companyName: entity.name }
                          )}
                        >
                          <Chip
                            className={styles.tag}
                            icon={
                              <ActivityTypeIcon
                                activityType={crmActivityType}
                                size="sm"
                                tooltipProps={false}
                              />
                            }
                          >
                            {crmActivityType.name}
                          </Chip>
                        </Tooltip>
                      </Col>
                      <Col>
                        <Tag className={styles.tag}>
                          {formatDate(parseApiDate(startAt ?? ""), "PPPppp")}
                        </Tag>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row align="middle" gutter={layout.spacerS}>
                      <Col>
                        {getOverdueStatus(activity) ? (
                          <Chip
                            icon={
                              <FontAwesomeIcon
                                className={styles.overdueIcon}
                                icon={faExclamationTriangle}
                              />
                            }
                            iconPosition="end"
                            type="light"
                          >
                            {intl.formatMessage({
                              id: "frequency.frequencyModal.activities.previous.activityOverdue",
                              defaultMessage: "Overdue",
                              description:
                                "frequency component - frequency modal - previous activities - Overdue",
                            })}
                          </Chip>
                        ) : completed ? (
                          <Chip
                            icon={
                              <FontAwesomeIcon className={styles.iconDone} icon={faCheckCircle} />
                            }
                            type="light"
                            iconPosition="end"
                          >
                            {intl.formatMessage({
                              id: "frequency.frequencyModal.activities.previous.activityDone",
                              defaultMessage: "Done",
                              description:
                                "frequency component - frequency modal - previous activities - done",
                            })}
                          </Chip>
                        ) : reliability ? (
                          <Chip
                            icon={
                              <FontAwesomeIcon
                                className={styles.iconVerified}
                                icon={faCheckCircle}
                              />
                            }
                            iconPosition="end"
                            type="light"
                          >
                            {intl.formatMessage({
                              id: "frequency.frequencyModal.activities.previous.activityVerified",
                              defaultMessage: "Verified",
                              description:
                                "frequency component - frequency modal - previous activities - Verified",
                            })}
                          </Chip>
                        ) : (
                          <div />
                        )}
                      </Col>
                      <Col>{assignee && <Avatar size={16} user={assignee} />}</Col>
                    </Row>
                  </Col>
                </Row>
                <div className={cn(styles.note, { [styles.noNote]: !note })}>
                  {note ??
                    intl.formatMessage({
                      id: "frequency.frequencyModal.activities.previous.noNote",
                      defaultMessage: "No note logged",
                      description:
                        "frequency component - frequency modal - previous activities - done",
                    })}
                </div>
              </div>
            );
          })}
        </div>
        {loading && <LoadingSpinner />}
        {!loading && !previousActivities.length && (
          <div className={styles.noActivities}>
            {intl.formatMessage({
              id: "frequency.frequencyModal.activities.previous.noActivities",
              defaultMessage: "no previous activities",
              description:
                "frequency component - frequency modal - previous activities - no previous activities",
            })}
          </div>
        )}
      </FrequencyCollapsePanel>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  entity: getFrequencyModalConfig(state).entity,
  loading: isPreviousActivitiesLoading(state),
  previousActivities: getPreviousActivities(state),
  previousActivitiesTotal: getPreviousActivitiesTotal(state),
});

const mapDispatchToProps = {
  clearPreviousActivities,
  onFetchPreviousActivities: fetchPreviousActivities.request,
  showEntityView,
  subscribe,
  unsubscribe,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviousActivities);

import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { RawFile } from "@mapmycustomers/shared/types/File";
import { faCirclePlay } from "@fortawesome/pro-regular-svg-icons/faCirclePlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import hasMimeType, { VIDEO_ANY } from "util/mimeType";
import { LoadingSpinner } from "@mapmycustomers/ui";
import Menu from "antd/es/menu";
import Tooltip from "antd/es/tooltip";
import Dropdown from "antd/es/dropdown";
import { useIntl } from "react-intl";
import AccessStatus from "@mapmycustomers/shared/types/entity/common/AccessStatus";
import cn from "classnames";
import MoreButton from "component/MoreButton";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import styles from "./FileThumbnail.module.scss";
import { MenuInfo } from "rc-menu/lib/interface";

interface Props {
  accessStatus?: AccessStatus;
  canDelete?: boolean;
  className?: string;
  file: RawFile;
  onClick?: (file: RawFile) => void;
  onDelete?: (file: RawFile) => void;
  onDownload: (file: RawFile) => void;
  onFetchThumbnail?: (payload: { fileId: RawFile["id"]; callback: (blob: Blob) => void }) => void;
  visible?: boolean;
}

const FileThumbnail: React.FC<Props> = ({
  accessStatus,
  canDelete = true,
  className,
  file,
  onClick,
  onDelete,
  onDownload,
  onFetchThumbnail,
  visible,
}) => {
  const intl = useIntl();
  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>();
  const [isLoading, setLoading] = useState(false);
  const isVideo = hasMimeType(file, VIDEO_ANY);
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    // don't load thumbnail if:
    // - it's invisible
    // - it's a video
    // - loaded it already
    if (!visible || thumbnailUrl || isVideo || !onFetchThumbnail) {
      return;
    }

    setLoading(true);
    onFetchThumbnail({
      fileId: file.id,
      callback: (blob) => {
        setThumbnailUrl(URL.createObjectURL(blob));
        setLoading(false);
      },
    });
  }, [visible, onFetchThumbnail, thumbnailUrl, file.id, isVideo]);

  useEffect(
    () => () => {
      if (thumbnailUrl) {
        URL.revokeObjectURL(thumbnailUrl);
      }
    },
    [thumbnailUrl]
  );

  const handleClick = useCallback(() => {
    onClick?.(file);
    setMenuVisible(false);
  }, [file, onClick]);

  const handleDelete = useCallback(
    (e: MenuInfo) => {
      e.domEvent.stopPropagation();
      onDelete?.(file);
      setMenuVisible(false);
    },
    [file, onDelete]
  );

  const handleDownload = useCallback(
    (e: MenuInfo) => {
      e.domEvent.stopPropagation();
      onDownload?.(file);
      setMenuVisible(false);
    },
    [file, onDownload]
  );

  const handleMenuClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMenuVisible((visible) => !visible);
  }, []);

  return (
    <div
      className={cn(styles.container, { [styles.videoContainer]: isVideo }, className)}
      onClick={handleClick}
    >
      {isLoading ? (
        <LoadingSpinner className={styles.loader} mini />
      ) : isVideo ? (
        <div className={styles.video}>
          <FontAwesomeIcon icon={faCirclePlay} size="3x" />
        </div>
      ) : (
        <img alt={file.name} className={styles.image} src={thumbnailUrl} />
      )}
      <Tooltip title={file.name}>
        <div className={styles.overlay}>
          <Dropdown
            onVisibleChange={setMenuVisible}
            overlay={
              <Menu>
                <Menu.Item
                  icon={<FontAwesomeIcon className={styles.downloadIcon} icon={faDownload} />}
                  onClick={handleDownload}
                >
                  {intl.formatMessage({
                    id: "fileContent.files.section.media.menu.download",
                    defaultMessage: "Download",
                    description: "Download file item label in Files Carousel on the Preview Pane",
                  })}
                </Menu.Item>
                <Menu.Item
                  disabled={!accessStatus?.delete || !canDelete}
                  icon={<FontAwesomeIcon className={styles.deleteIcon} icon={faTrash} />}
                  onClick={handleDelete}
                >
                  {intl.formatMessage({
                    id: "fileContent.files.section.media.menu.delete",
                    defaultMessage: "Delete",
                    description: "Delete file item label in Files Carousel on the Preview Pane",
                  })}
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
            trigger={["hover"]}
            visible={menuVisible}
          >
            <MoreButton className={styles.moreButton} onClick={handleMenuClick} />
          </Dropdown>
        </div>
      </Tooltip>
    </div>
  );
};

export default FileThumbnail;

import PostCreationAssociationTypes from "./util/PostCreationAssociationTypes";
import ChildCompanies from "./components/ChildCompanies";
import Deals from "./components/Deals";
import Groups from "./components/Groups";
import ParentCompany from "./components/ParentCompany";
import People from "./components/People";
import Routes from "./components/Routes";
import {
  Company,
  Deal,
  EntityType,
  EntityTypeSupportingPostCreationModal,
  Person,
} from "@mapmycustomers/shared/types/entity";

interface Props {
  createdRecord: Company | Person | Deal;
  entityType: EntityTypeSupportingPostCreationModal;
  handleHideModal: (updated?: boolean) => void;
  selectedModal?: PostCreationAssociationTypes;
}

const PostCreationModalSelection: React.FC<Props> = ({
  createdRecord,
  entityType,
  handleHideModal,
  selectedModal,
}) => {
  return (
    <>
      {(selectedModal === PostCreationAssociationTypes.PARENT_COMPANY ||
        selectedModal === PostCreationAssociationTypes.COMPANY ||
        selectedModal === PostCreationAssociationTypes.COMPANIES ||
        selectedModal === PostCreationAssociationTypes.CHANGE_PARENT_COMPANY ||
        selectedModal === PostCreationAssociationTypes.CHANGE_COMPANY ||
        selectedModal === PostCreationAssociationTypes.CHANGE_COMPANIES) && (
        <ParentCompany
          createdRecord={createdRecord}
          entityType={entityType}
          onHide={handleHideModal}
        />
      )}
      {selectedModal === PostCreationAssociationTypes.CHILD_COMPANY && (
        <ChildCompanies createdRecord={createdRecord} onHide={handleHideModal} />
      )}
      {(selectedModal === PostCreationAssociationTypes.PERSON ||
        selectedModal === PostCreationAssociationTypes.CHANGE_PERSON) && (
        <People createdRecord={createdRecord} entityType={entityType} onHide={handleHideModal} />
      )}
      {selectedModal === PostCreationAssociationTypes.DEAL && (
        <Deals createdRecord={createdRecord} entityType={entityType} onHide={handleHideModal} />
      )}
      {selectedModal === PostCreationAssociationTypes.ROUTE && (
        <Routes
          createdRecord={createdRecord as Company | Person}
          entityType={entityType as EntityType.COMPANY | EntityType.PERSON}
          onHide={handleHideModal}
        />
      )}
      {selectedModal === PostCreationAssociationTypes.GROUP && (
        <Groups entity={createdRecord} entityType={entityType} onHide={handleHideModal} />
      )}
    </>
  );
};

export default PostCreationModalSelection;

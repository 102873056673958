import React from "react";
import Form from "antd/es/form";
import useNameRequiredValidationRules from "util/form/useNameRequiredValidationRules";
import { useIntl } from "react-intl";
import styles from "./NameField.module.scss";
import { TextField } from "@mapmycustomers/ui";
import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";
import useFirstLastNameRequirement from "../../../../util/hook/entityForm/useFirstLastNameRequirement";

interface Props {
  actualSchema: LayoutSchemaField[];
  disabled?: boolean;
  required?: boolean;
}

const PersonNameField: React.FC<Props> = ({ actualSchema, disabled, required }) => {
  const intl = useIntl();

  const [firstNameRequired, lastNameRequired] = useFirstLastNameRequirement(actualSchema);

  const someRuleAreRequired = firstNameRequired || lastNameRequired;

  const nameRules = useNameRequiredValidationRules(intl);

  return (
    <div className={styles.container}>
      <Form.Item
        className={styles.fullWidth}
        label={intl.formatMessage({
          id: "formFields.personName.firstName",
          defaultMessage: "First Name",
          description: "First name field on the Create Person modal",
        })}
        name="firstName"
        rules={someRuleAreRequired ? [{ required: firstNameRequired }] : nameRules}
      >
        <TextField disabled={disabled} />
      </Form.Item>
      <Form.Item
        className={styles.fullWidth}
        label={intl.formatMessage({
          id: "formFields.personName.lastName",
          defaultMessage: "Last Name",
          description: "Last name field on the Create Person modal",
        })}
        name="lastName"
        rules={someRuleAreRequired ? [{ required: lastNameRequired }] : nameRules}
      >
        <TextField disabled={disabled} />
      </Form.Item>
    </div>
  );
};

export default PersonNameField;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FeatureType } from "../types";
import useMap from "../utils/useMap";
import Feature from "./Feature";
import Cluster from "util/clustering/types/Cluster";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { Algorithm } from "util/clustering/types/algorithm";
import GridAlgorithm from "util/clustering/algorithms/GridAlgorithm";
import { convertLongLatToLatLngLiteral } from "util/geo/GeoService";
import ClusterImpl from "util/clustering/ClusterImpl";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import defaultIdGetter from "util/defaultIdGetter";
import shallowComparison from "util/shallowComparison";
import loggingService from "util/logging";
import { useMapEventHandler } from "component/map/FeaturedMap";

const clusterGeometryGetter = (
  cluster: Cluster<any>
): Exclude<google.maps.Data.FeatureOptions["geometry"], undefined> =>
  new google.maps.Data.Point(convertLongLatToLatLngLiteral(cluster.position));

class FeatureCluster<T> extends ClusterImpl<T> implements Identified<string> {
  public readonly id: string;
  protected idGetter: (item: T, type?: string) => string | number;

  constructor(cluster: Cluster<T>, idGetter: (item: T, type?: string) => string | number) {
    super(
      // sort items by their id
      // we don't care about specific order, we just wanna have some order
      cluster.items.sort((a, b) => String(idGetter(a)).localeCompare(String(idGetter(b)))),
      cluster.coordinateGetter,
      cluster.position
    );
    this.idGetter = idGetter;
    // now when the items were sort, this id is also "organized"
    this.id = this.items.map((item) => idGetter(item)).join("_");
  }
}

const areClustersDifferent = <T,>(
  a: FeatureCluster<T>,
  b: FeatureCluster<T>,
  isDifferent: (a: T, b: T) => boolean
): boolean => a.id !== b.id || a.items.some((item, index) => isDifferent(item, b.items[index]));

const getClusterComparator =
  <T,>(isDifferent: (a: T, b: T) => boolean) =>
  (a: FeatureCluster<T>, b: FeatureCluster<T>): boolean =>
    areClustersDifferent(a, b, isDifferent);

interface Props<T> {
  algorithm?: Algorithm<T>;
  coordinateGetter: (item: T) => LongLat;
  idGetter: (item: T, type?: string) => string | number;
  isDifferent?: (a: T, b: T) => boolean;
  items: Iterable<T>;
  onClick?: (item: T, event: google.maps.MapMouseEvent) => void;
  onDoubleClick?: (item: T, event: google.maps.MapMouseEvent) => void;
  onMouseDown?: (item: T, event: google.maps.MapMouseEvent) => void;
  onMouseLeave?: (item: T, event: google.maps.MapMouseEvent) => void;
  onMouseOver?: (item: T, event: google.maps.MapMouseEvent) => void;
  styleGetter?: (item: Cluster<T>) => google.maps.Data.StyleOptions;
  type: FeatureType;
}

const ClusteredFeature = <T,>({
  algorithm: providedAlgorithm,
  coordinateGetter,
  idGetter,
  isDifferent = shallowComparison,
  items,
  onClick,
  onDoubleClick,
  onMouseDown,
  onMouseLeave,
  onMouseOver,
  styleGetter,
  type,
}: Props<T>) => {
  const { map } = useMap();
  const algorithm = useMemo(
    () => providedAlgorithm ?? new GridAlgorithm<T>({}),
    [providedAlgorithm]
  );

  const [zoom, setZoom] = useState(map.getZoom()!);
  useMapEventHandler("zoom_changed", () => {
    setZoom(map.getZoom()!);
  });

  // not using memo because was thinking to extract clustering into a worker
  const [clusters, setClusters] = useState<FeatureCluster<T>[]>([]);
  useEffect(() => {
    const result = algorithm.calculate({
      map,
      coordinateGetter,
      items,
    });
    setClusters(result.clusters.map((cluster) => new FeatureCluster(cluster, idGetter)));
  }, [algorithm, idGetter, items, coordinateGetter, map, zoom]);

  const clusterComparator = useMemo(() => getClusterComparator(isDifferent), [isDifferent]);

  const handleClick = useCallback(
    (cluster: FeatureCluster<T>, event: google.maps.MapMouseEvent) => {
      loggingService.debug("feature cluster clicked", cluster);
      if (cluster.count === 1) {
        onClick?.(cluster.items[0], event);
      } else {
        map.fitBounds(cluster.bounds);
      }
    },
    [map, onClick]
  );

  const handleDoubleClick = useCallback(
    (cluster: FeatureCluster<T>, event: google.maps.MapMouseEvent) => {
      if (cluster.count === 1) {
        onDoubleClick?.(cluster.items[0], event);
      }
    },
    [onDoubleClick]
  );

  const handleMouseDown = useCallback(
    (cluster: FeatureCluster<T>, event: google.maps.MapMouseEvent) => {
      if (cluster.count === 1) {
        onMouseDown?.(cluster.items[0], event);
      }
    },
    [onMouseDown]
  );

  const handleMouseLeave = useCallback(
    (cluster: FeatureCluster<T>, event: google.maps.MapMouseEvent) => {
      if (cluster.count === 1) {
        onMouseLeave?.(cluster.items[0], event);
      }
    },
    [onMouseLeave]
  );

  const handleMouseOver = useCallback(
    (cluster: FeatureCluster<T>, event: google.maps.MapMouseEvent) => {
      if (cluster.count === 1) {
        onMouseOver?.(cluster.items[0], event);
      }
    },
    [onMouseOver]
  );

  return (
    <Feature<FeatureCluster<T>>
      geometryGetter={clusterGeometryGetter}
      idGetter={defaultIdGetter}
      isDifferent={clusterComparator}
      items={clusters}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      styleGetter={styleGetter}
      type={type}
    />
  );
};

export default ClusteredFeature;

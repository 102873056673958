import { defineMessages, IntlShape } from "react-intl";

enum ViewMode {
  MAP = "map",
  LIST = "list",
}

const messages = defineMessages<ViewMode>({
  [ViewMode.MAP]: {
    id: "dashboard.viewMode.map",
    defaultMessage: "Map",
    description: "Map view mode display name",
  },
  [ViewMode.LIST]: {
    id: "dashboard.viewMode.list",
    defaultMessage: "List",
    description: "List view mode display name",
  },
});

export const getViewModeDisplayName = (intl: IntlShape, viewMode: ViewMode) =>
  intl.formatMessage(messages[viewMode]);

export default ViewMode;

import { useMemo } from "react";
import Cadenced from "@mapmycustomers/shared/types/base/Cadenced";

const DEFAULT_INTERVALS = [14, 30, 45, 60, 90, 180];

const useFrequencyIntervalOptions = (
  savedFrequencyInterval?: Cadenced["cadenceInterval"],
  frequencyIntervalValue?: Cadenced["cadenceInterval"]
) =>
  useMemo(() => {
    let intervals = [...DEFAULT_INTERVALS];
    if (frequencyIntervalValue && !intervals.includes(frequencyIntervalValue)) {
      intervals = intervals.slice(0, intervals.length - 1);
      intervals.push(frequencyIntervalValue);
    }
    if (
      savedFrequencyInterval &&
      savedFrequencyInterval !== frequencyIntervalValue &&
      !intervals.includes(savedFrequencyInterval)
    ) {
      intervals = intervals.slice(0, intervals.length - 1);
      intervals.push(savedFrequencyInterval);
    }
    // if saved in metadata interval (savedFrequencyInterval) is defined we throw out maximum of original sequence.
    // Same for manual interval (frequencyIntervalValue)
    return intervals.sort((a, b) => a - b);
  }, [savedFrequencyInterval, frequencyIntervalValue]);

export default useFrequencyIntervalOptions;

import React, { useCallback } from "react";
import Slider from "antd/es/slider";
import Labeled from "../input/Labeled";
import cn from "classnames";
import styles from "./SliderWithNumberField.module.scss";
import NumberField from "../input/NumberField";

interface Props {
  className?: string;
  label?: string;
  min: number;
  max: number;
  onChange?: (value: number) => void;
  step: number;
  value?: number;
  valueFormatter?: (value?: string | number) => string;
}

const SliderWithNumberField: React.FC<Props> = ({
  className,
  label,
  min,
  max,
  onChange,
  step,
  value,
  valueFormatter,
}) => {
  const handleNumberFieldChange = useCallback(
    (value?: number) => value !== undefined && onChange?.(value),
    [onChange]
  );

  return (
    <Labeled className={cn(styles.label, className)} label={label}>
      <div className={styles.container}>
        <Slider
          className={styles.slider}
          min={min}
          max={max}
          onChange={onChange}
          step={step}
          tipFormatter={valueFormatter}
          value={value}
        />
        <NumberField
          className={styles.numberField}
          formatter={valueFormatter}
          min={min}
          max={max}
          onChange={handleNumberFieldChange}
          step={step}
          value={value}
        />
      </div>
    </Labeled>
  );
};

export default SliderWithNumberField;

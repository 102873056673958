import FieldModel from "./impl/FieldModel";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import { createField } from "./impl/fieldModelUtil";
import NameField from "./impl/NameField";
import { defineMessages } from "react-intl";
import DateTimeField from "util/fieldModel/impl/DateTimeField";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";
import UserField from "util/fieldModel/impl/UserField";

export enum RouteFieldName {
  NAME = "name",
  COUNT = "items",
  START_ADDRESS = "routeDetail.startAddress",
  USER = "user",
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
}

export const displayNames = defineMessages<RouteFieldName>({
  [RouteFieldName.NAME]: {
    id: "route.field.name",
    defaultMessage: "Route Name",
    description: "Name field of route",
  },
  [RouteFieldName.COUNT]: {
    id: "route.field.count",
    defaultMessage: "Number of stops",
    description: "Items field of route",
  },
  [RouteFieldName.START_ADDRESS]: {
    id: "route.field.routeDetail.startAddress",
    defaultMessage: "Start Address",
    description: "Start Address field of route",
  },
  [RouteFieldName.USER]: {
    id: "route.field.user",
    defaultMessage: "Creator",
    description: "User field of route",
  },
  [RouteFieldName.CREATED_AT]: {
    id: "route.field.createdAt",
    defaultMessage: "Date Created",
    description: "Date Created field of route",
  },
  [RouteFieldName.UPDATED_AT]: {
    id: "route.field.updatedAt",
    defaultMessage: "Date Last Modified",
    description: "Date Modified field of route",
  },
});

const routeFieldModel = new FieldModel(
  [
    new NameField({
      name: RouteFieldName.NAME,
      displayName: displayNames[RouteFieldName.NAME],
    }),
    {
      name: RouteFieldName.COUNT,
      displayName: displayNames[RouteFieldName.COUNT],
      type: FieldType.NUMBER,
      filterType: FieldType.NUMBER,
    },
    {
      name: RouteFieldName.START_ADDRESS,
      displayName: displayNames[RouteFieldName.START_ADDRESS],
      type: FieldType.STRING,
      filterType: FieldType.STRING,
      features: [],
    },
    new UserField({
      name: RouteFieldName.USER,
      displayName: displayNames[RouteFieldName.USER],
      platformFilterName: "userId",
      platformName: "userId",
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.OWNER_FIELD,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
    }),
    new DateTimeField({
      name: ActivityFieldName.CREATED_AT,
      displayName: displayNames[ActivityFieldName.CREATED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
      ],
    }),
    new DateTimeField({
      name: ActivityFieldName.UPDATED_AT,
      displayName: displayNames[ActivityFieldName.UPDATED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
      ],
    }),
  ].map(createField)
);

export default routeFieldModel;

import { useMemo } from "react";
import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";
import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";

const useAddedFieldsSchema = (
  entityType: EntityTypesSupportingFieldCustomization,
  schema: LayoutSchemaField[],
  addedFields: LayoutSchemaField[],
  filterFields: (schemaField: LayoutSchemaField) => boolean,
  isCreateForm?: boolean
): LayoutSchemaField[] =>
  useMemo(() => {
    const fieldModel = getFieldModelByEntityType(entityType);
    const startDisplayOrder = schema.reduce(
      (result, schemaField) => Math.max(result, schemaField.displayOrder),
      1
    );
    const schemaFields = new Set(schema.map((schemaField) => schemaField.field));
    return addedFields
      .filter((added) => !schemaFields.has(added.field))
      .filter(filterFields)
      .filter((schemaField) => {
        const field = fieldModel.getByPlatformName(schemaField.field);
        return isCreateForm || field?.isEditable || field?.isReadable;
      })
      .map((schemaField, index) => ({ ...schemaField, displayOrder: startDisplayOrder + index }));
  }, [entityType, isCreateForm, schema, addedFields, filterFields]);

export default useAddedFieldsSchema;

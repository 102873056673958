import { createSelector } from "reselect";
import { MapLayer } from "scene/map/store/layers/reducers";
import { RootState } from "store/rootReducer";
import Legend from "scene/map/enums/Legend";
import LegendState from "scene/map/types/LegendState";

const layersState = (state: RootState) => state.scene.map.layers;

export const getLayersVisibility = createSelector(layersState, (layers) => {
  return Object.fromEntries(
    Object.entries(layers).map(([layerName, layer]) => [layerName, layer.visible])
  ) as Record<MapLayer, boolean>;
});

export const getLayerLegendsState = createSelector(
  layersState,
  (layers) =>
    Object.fromEntries(
      Object.entries(layers).map(([layerName, layer]) => [
        layerName,
        { visible: layer.visible, expanded: layer.legendExpanded ?? true, isLayer: true },
      ])
    ) as Record<Legend, LegendState>
);

export const getAlertsData = createSelector(layersState, (layers) => layers.alerts);
export const getHeatMapLayerData = createSelector(layersState, (layers) => layers.heatMap);
export const getTrafficLayerData = createSelector(layersState, (layers) => layers.traffic);
export const getBoundariesLayerData = createSelector(layersState, (layers) => layers.boundaries);
export const getUserLocationsData = createSelector(layersState, (layers) => layers.userLocations);

export * from "./alerts/selectors";
export * from "./userLocations/selectors";

import { Condition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import IntervalUnit from "enum/IntervalUnit";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";

export const roundToIntervalUnit: Record<string, IntervalUnit> = {
  hours: IntervalUnit.HOUR,
  days: IntervalUnit.DAY,
  weeks: IntervalUnit.WEEK,
  months: IntervalUnit.MONTH,
  years: IntervalUnit.YEAR,
};

const VALUE_REGEX = /^(?<amount>-?\d+)\s*(?<unit>(hours|days|weeks|months|years))$/;
const parseValue = (value: string): { amount: number; unit: IntervalUnit } | undefined => {
  const match = VALUE_REGEX.exec(value);
  return match
    ? {
        amount: parseInt(match.groups!.amount, 10),
        unit: roundToIntervalUnit[match.groups!.unit] as IntervalUnit,
      }
    : undefined;
};

const parseIntervalCondition = (platformCondition: Condition) => {
  const filterObject = platformCondition[PlatformFilterOperator.INTERVAL] as {
    [PlatformFilterOperator.GREATER_THAN]?: string;
    [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]?: string;
    [PlatformFilterOperator.LESS_THAN]?: string;
    [PlatformFilterOperator.LESS_THAN_OR_EQUAL]?: string;
  };

  let parsedValue;
  let unit;
  let from = 0;

  // we know that one of these must be defined - either $gt or $gte
  parsedValue = parseValue(
    (filterObject[PlatformFilterOperator.GREATER_THAN] ??
      filterObject[PlatformFilterOperator.GREATER_THAN_OR_EQUAL]) as string
  );
  if (parsedValue) {
    from = parsedValue.amount;
    unit = parsedValue.unit;
  }

  let to = 0;
  // we know that one of these must be defined - either $lt or $lte
  parsedValue = parseValue(
    (filterObject[PlatformFilterOperator.LESS_THAN] ??
      filterObject[PlatformFilterOperator.LESS_THAN_OR_EQUAL]) as string
  );
  if (parsedValue) {
    to = parsedValue.amount;
    unit = unit ?? parsedValue.unit;
  }

  return {
    operator: from < 0 ? FilterOperator.INTERVAL_BEFORE : FilterOperator.INTERVAL_AFTER,
    value: { unit, from, to },
  };
};

export default parseIntervalCondition;

import { all, fork } from "redux-saga/effects";
import { activityAnnotationSaga } from "./activity/ActivityAnnotation/store/saga";
import { activityInfoModalSaga } from "./activity/ActivityInfoModal/store/saga";
import { addNoteModalSaga } from "./createEditEntity/AddNoteModal/store/saga";
import { addToRouteModalSaga } from "./AddToRouteModal/store/saga";
import { companyRecordSaga } from "./preview/CompanyRecordPane/store/saga";
import { createCompanyModalSaga } from "./createEditEntity/CreateCompanyModal/store/saga";
import { createDealModalSaga } from "./createEditEntity/CreateDealModal/store/saga";
import { createEditActivityModalSaga } from "./createEditEntity/Activity/store/saga";
import { createPersonModalSaga } from "./createEditEntity/CreatePersonModal/store/saga";
import { dataViewSaga } from "./DataView/store/saga";
import { dealRecordSaga } from "./preview/DealRecordPane/store/saga";
import { dealsSaga } from "./PreviewDealsListModal/store/saga";
import { editStageModalSaga } from "./createEditEntity/EditStageModal/store/saga";
import { entityAnnotationSagas } from "./EntityAnnotation/store/sagas";
import { entityAssociationsSaga } from "./associations/store/saga";
import { entityHistorySaga } from "./EntityHistoryModal/store/saga";
import { entitySearchSaga } from "./input/EntitySearch/store/saga";
import { filePreviewSaga } from "./FilePreview/store/saga";
import { multiPinSagas } from "./map/MultiPinRecordListDrawer/store/sagas";
import { navigationBarSaga } from "./NavigationBar/store/saga";
import { personRecordSaga } from "./preview/PersonRecordPane/store/saga";
import { postCreationSaga } from "./PostCreationModal/store/saga";
import { selectBarSaga } from "./view/SelectBar/store/saga";
import { userLocationRequestSaga } from "./Layout/components/UserLocationRequest/saga";
import { userPreviewCardSaga } from "./UserPreviewCard/store/saga";

export function* componentSaga() {
  yield all([
    fork(activityAnnotationSaga),
    fork(activityInfoModalSaga),
    fork(addNoteModalSaga),
    fork(addToRouteModalSaga),
    fork(companyRecordSaga),
    fork(createCompanyModalSaga),
    fork(createDealModalSaga),
    fork(createEditActivityModalSaga),
    fork(createPersonModalSaga),
    fork(dataViewSaga),
    fork(dealRecordSaga),
    fork(dealsSaga),
    fork(editStageModalSaga),
    fork(entityAnnotationSagas),
    fork(entityAssociationsSaga),
    fork(entityHistorySaga),
    fork(entitySearchSaga),
    fork(filePreviewSaga),
    fork(multiPinSagas),
    fork(navigationBarSaga),
    fork(personRecordSaga),
    fork(postCreationSaga),
    fork(selectBarSaga),
    fork(userLocationRequestSaga),
    fork(userPreviewCardSaga),
  ]);
}

import ImportHistoryRow from "../type/ImportHistoryRow";
import Import from "@mapmycustomers/shared/types/imports/Import";

const convertToHistoryRow = (importRecord: Import): ImportHistoryRow => {
  const { metadata } = importRecord;
  const { errors, warnings } = metadata;
  const errorsCount: number = Array.isArray(errors) ? errors.length : errors || 0;
  const warningsCount: number = Array.isArray(warnings) ? warnings.length : warnings || 0;
  return {
    ...importRecord,
    errorsCount,
    warningsCount,
  };
};

export default convertToHistoryRow;

import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import styles from "./Frequencies.module.scss";
import FrequencyBlock from "./FrequencyBlock";
import { Col, Row } from "antd/es/grid";
import layout from "styles/layout";
import EntityBadge from "../../../../EntityBadge";
import hasFrequency from "../../../util/hasFrequency";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import Button from "antd/es/button";
import NewFrequency from "./NewFrequency";
import Tooltip from "antd/es/tooltip";
import { Group } from "@mapmycustomers/shared/types/entity";
import Path from "../../../../../enum/Path";
import ProTip from "./ProTip";
import { RootState } from "../../../../../store/rootReducer";
import { getFrequencyModalConfig } from "../../../../../store/frequency";
import FrequencyPreviewConfig from "../../../type/FrequencyPreviewConfig";
import { connect } from "react-redux";
import FrequencyIcon from "../../../FrequencyIcon";
import Chip from "../../../../Chip";
import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";

interface Props {
  config: FrequencyPreviewConfig;
}

const Frequencies: React.FC<Props> = ({ config }) => {
  const intl = useIntl();
  const { isSomeGroupFrequencied, isEntityFrequencied, entity, groups } = config;
  const [newFrequencyVisible, showNewFrequency, hideNewFrequency] = useBoolean();

  const handleGroupClick = useCallback(
    (id: Group["id"]) => {
      const url = `${Path.MAP}/groups/${entity.entity}/${id}`;
      window.open(`${window.location.origin}${url}`, "_blank");
    },
    [entity]
  );
  return (
    <div className={styles.container}>
      {!isSomeGroupFrequencied && !isEntityFrequencied ? (
        <div className={styles.noFrequency}>
          <FrequencyIcon status={FrequencyStatus.UNKNOWN} size="xl" />
          <span className={styles.noFrequencyText}>
            {intl.formatMessage({
              id: "frequency.frequencyModal.frequencies.noFrequency",
              defaultMessage: "Create a frequency to see them listed here",
              description: "frequency component - frequency modal - frequencies - no frequency",
            })}
          </span>
        </div>
      ) : (
        <div className={styles.list}>
          <div className={styles.title}>
            {intl.formatMessage({
              id: "frequency.frequencyModal.frequencies.title",
              defaultMessage: "active frequencies",
              description: "frequency component - frequency modal - frequencies - title",
            })}
          </div>
          {groups?.map((group) => (
            <FrequencyBlock
              header={
                <div className={styles.groupHeader}>
                  <Row align="middle" gutter={layout.spacerM}>
                    <Col>
                      {intl.formatMessage(
                        {
                          id: "frequency.frequencyModal.frequencies.groups.setViaGroup",
                          defaultMessage: "Set via Group",
                          description:
                            "frequency component - frequency modal - frequencies - Set via Group",
                        },
                        { count: group.cadenceInterval ?? 0 }
                      )}
                    </Col>
                    <Col>
                      <Tooltip
                        title={intl.formatMessage({
                          id: "frequency.frequencyModal.frequencies.groups.tooltip",
                          defaultMessage: "Open Group in New Tab",
                          description:
                            "frequency component - frequency modal - frequencies - Group tooltip",
                        })}
                      >
                        <Chip
                          type="light"
                          onClick={() => handleGroupClick(group.id)}
                          icon={<EntityBadge entity={group} hideName />}
                        >
                          {group.name}
                        </Chip>
                      </Tooltip>
                    </Col>
                  </Row>
                </div>
              }
              key={group.id}
              entity={group}
              isGroup
              readonly
            />
          ))}
          {hasFrequency(entity) ? (
            <FrequencyBlock
              entity={entity}
              isOpenByDefault
              lastCompletedActivity={entity.lastCompletedActivity}
            />
          ) : newFrequencyVisible ? (
            <NewFrequency onCancel={hideNewFrequency} />
          ) : (
            <Button className={styles.addFrequency} onClick={showNewFrequency}>
              {intl.formatMessage({
                id: "frequency.frequencyModal.frequencies.groups.addFrequency",
                defaultMessage: "+ Add Frequency",
                description: "frequency component - frequency modal - frequencies - Add Frequency",
              })}
            </Button>
          )}
        </div>
      )}
      <ProTip />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  config: getFrequencyModalConfig(state),
});

export default connect(mapStateToProps)(Frequencies);

import React, { useCallback } from "react";
import { RawFile } from "@mapmycustomers/shared/types/File";
import Section from "component/activity/ActivityAnnotation/Section";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { Activity, EntityType } from "@mapmycustomers/shared/types/entity";
import { downloadFile } from "component/FilePreview/store/actions";
import { connect } from "react-redux";
import styles from "./FilesSection.module.scss";
import { deleteActivityFile, fetchThumbnail, uploadActivityFiles } from "../store/actions";
import { useIntl } from "react-intl";
import { RECORD_FILE_UPLOAD_LIMIT } from "util/consts";
import FileUploaderModal from "component/FileUploaderModal";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { RootState } from "store/rootReducer";
import { areFilesUploading } from "../store";
import AddOrCreateButton from "component/preview/components/AddOrCreateButton";
import TextWithInfo from "component/typography/TextWithInfo";
import ExtendedAnalytics from "component/analytics/ExtendedAnalytics";
import FileContent from "component/FileContent/FilesContent";

interface Props {
  activity: Activity;
  files?: RawFile[];
  fileUploading: boolean;
  onDeleteFile: typeof deleteActivityFile.request;
  onDownload: typeof downloadFile;
  onFetchThumbnail: typeof fetchThumbnail;
  onUpload: typeof uploadActivityFiles.request;
}

const FilesSection: React.FC<Props> = ({
  activity,
  files,
  fileUploading,
  onDeleteFile,
  onDownload,
  onFetchThumbnail,
  onUpload,
}) => {
  const intl = useIntl();
  const [isAddingFile, startAddingFileProcess, stopAddingFileProcess] = useBoolean(false, true);

  const handleDownload = useCallback(
    (file: RawFile) => {
      onDownload({ file, entityId: activity.id, entityType: EntityType.ACTIVITY });
    },
    [activity, onDownload]
  );

  return (
    <Section
      icon={faFile}
      title={
        <div className={styles.title}>
          <TextWithInfo
            info={intl.formatMessage(
              {
                id: "component.activityAnnotation.filesSection.tooltip",
                defaultMessage:
                  "We support most images, pdfs, documents and videos up to {fileLimit, number, ::K}MB",
                description: "Files info tooltip text",
              },
              {
                fileLimit: RECORD_FILE_UPLOAD_LIMIT / 1024 / 1024,
              }
            )}
          >
            {intl.formatMessage({
              id: "component.activityAnnotation.filesSection.title",
              defaultMessage: "Files",
              description: "Files - Section Title",
            })}
          </TextWithInfo>
          <AddOrCreateButton
            disabled={!activity.accessStatus?.update}
            label={intl.formatMessage({
              id: "component.activityAnnotation.filesSection.addButton",
              defaultMessage: "Add",
              description: "Title of a button to add a file to a activity",
            })}
            onClick={startAddingFileProcess}
            tooltip={
              !activity.accessStatus?.update
                ? intl.formatMessage({
                    id: "component.activityAnnotation.filesSection.addButton.uneditable",
                    defaultMessage:
                      "You do not have permission to use this feature. Please contact your admin to request a change.",
                    description: "Tooltip for an Add file Button when user has no edit rights",
                  })
                : undefined
            }
          />
        </div>
      }
    >
      {files && files.length > 0 ? (
        <div>
          <FileContent
            accessStatus={activity.accessStatus}
            files={files}
            onDelete={onDeleteFile}
            onDownload={handleDownload}
            onFetchThumbnail={onFetchThumbnail}
            size="small"
          />
        </div>
      ) : (
        <span className={styles.noFiles}>
          {intl.formatMessage({
            id: "component.activityAnnotation.filesSection.noFiles",
            defaultMessage: "No Files Yet",
            description: "No files yet",
          })}
        </span>
      )}
      {isAddingFile && (
        <ExtendedAnalytics keyPath="Activity Annotation">
          <FileUploaderModal
            fileUploading={fileUploading}
            onDelete={onDeleteFile}
            onHide={stopAddingFileProcess}
            onUpload={onUpload}
          />
        </ExtendedAnalytics>
      )}
    </Section>
  );
};

const mapStateToProps = (state: RootState) => ({
  fileUploading: areFilesUploading(state),
});

const mapDispatchToProps = {
  onDeleteFile: deleteActivityFile.request,
  onDownload: downloadFile,
  onFetchThumbnail: fetchThumbnail,
  onUpload: uploadActivityFiles.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilesSection);

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import Iam from "types/Iam";
import User from "@mapmycustomers/shared/types/User";
import Organization, { OrganizationMetaData } from "@mapmycustomers/shared/types/Organization";
import Setting from "@mapmycustomers/shared/types/Setting";
import SavedFilter from "@mapmycustomers/shared/types/viewModel/SavedFilter";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import { OnlyRequiredFields } from "@mapmycustomers/shared/util/ts";
import CumulDashboard from "@mapmycustomers/shared/types/cumul/CumulDashboard";

export const fetchMe = createAsyncAction(
  "@iam/fetchMe/request",
  "@iam/fetchMe/success",
  "@iam/fetchMe/failure"
)<undefined, Iam | undefined, unknown>();

export const findMe = createAsyncAction(
  "@iam/findMe/request",
  "@iam/findMe/success",
  "@iam/findMe/failure"
)<void, GeolocationPosition, void>();

export const changePassword = createAsyncAction(
  "@iam/changePassword/request",
  "@iam/changePassword/success",
  "@iam/changePassword/failure"
)<{ password: string; currentPassword: string }, void, unknown>();
export const showChangePasswordModal = createAction("@iam/changePassword/showModal")<void>();
export const hideChangePasswordModal = createAction("@iam/changePassword/hideModal")<void>();

export const uploadProfilePhoto = createAsyncAction(
  "@iam/uploadProfilePhoto/request",
  "@iam/uploadProfilePhoto/success",
  "@iam/uploadProfilePhoto/failure"
)<File | Blob, User, unknown>();

export const updateMe = createAsyncAction(
  "@iam/updateMe/request",
  "@iam/updateMe/success",
  "@iam/updateMe/failure"
)<{ me: Iam; callback?: (me: Iam) => void }, Iam, unknown>();

export const updateOrganization = createAsyncAction(
  "@iam/updateOrganization/request",
  "@iam/updateOrganization/success",
  "@iam/updateOrganization/failure"
)<
  {
    organization: OnlyRequiredFields<Organization, "id">;
    settings?: Setting[];
    callback?: () => void;
  },
  Iam,
  unknown
>();

// if you need to change many settings at once, use updateOrganization action instead
export const updateOrganizationSetting = createAsyncAction(
  "@iam/updateOrganizationSetting/request",
  "@iam/updateOrganizationSetting/success",
  "@iam/updateOrganizationSetting/failure"
)<{ setting: Setting; callback?: () => void }, Iam, unknown>();

export const deleteProfilePhoto = createAsyncAction(
  "@iam/deleteProfilePhoto/request",
  "@iam/deleteProfilePhoto/success",
  "@iam/deleteProfilePhoto/failure"
)<void, User, unknown>();

export const showDeleteProfilePhotoModal = createAction("@iam/deleteProfilePhotoModal/show")();
export const hideDeleteProfilePhotoModal = createAction("@iam/deleteProfilePhotoModal/hide")();

export const updateSetting = createAsyncAction(
  "@iam/updateSetting/request",
  "@iam/updateSetting/success",
  "@iam/updateSetting/failure"
)<
  {
    orgId: Organization["id"];
    userId: User["id"];
    setting: Setting;
    callback?: (setting: Setting) => void;
  },
  Setting,
  unknown
>();

export const updateOrganizationMetadata = createAsyncAction(
  "@iam/updateOrganizationMetadata/request",
  "@iam/updateOrganizationMetadata/success",
  "@iam/updateOrganizationMetadata/failure"
)<
  {
    metaData: Partial<OrganizationMetaData>;
    callback?: (updatedMetaData?: OrganizationMetaData) => void;
  },
  Organization,
  unknown
>();

export const updateMetadata = createAsyncAction(
  "@iam/updateUserMetadata/request",
  "@iam/updateUserMetadata/success",
  "@iam/updateUserMetadata/failure"
)<Partial<User["metaData"]>, User["metaData"], unknown>();

export const addFavoriteFilter = createAsyncAction(
  "@iam/addFavoriteFilter/request",
  "@iam/addFavoriteFilter/success",
  "@iam/addFavoriteFilter/failure"
)<SavedFilter["id"], User, void>();

export const removeFavoriteFilter = createAsyncAction(
  "@iam/removeFavoriteFilter/request",
  "@iam/removeFavoriteFilter/success",
  "@iam/removeFavoriteFilter/failure"
)<SavedFilter["id"], User, void>();

export const toggleFavoriteFunnel = createAsyncAction(
  "@iam/updateFavoriteFunnel/request",
  "@iam/updateFavoriteFunnel/success",
  "@iam/updateFavoriteFunnel/failure"
)<{ funnelId: Funnel["id"]; deletingFunnel?: boolean }, Iam, unknown>();

export const togglePinnedDashboard = createAsyncAction(
  "@iam/togglePinnedDashboard/request",
  "@iam/togglePinnedDashboard/success",
  "@iam/togglePinnedDashboard/failure"
)<CumulDashboard["id"], User, unknown>();

export const setCumulPromotionalUiVisited = createAsyncAction(
  "@iam/setCumulPromotionalUiVisited/request",
  "@iam/setCumulPromotionalUiVisited/success",
  "@iam/setCumulPromotionalUiVisited/failure"
)<void, User, unknown>();

export const fetchGeocodingLimit = createAsyncAction(
  "@iam/fetchGeocodingLimit/request",
  "@iam/fetchGeocodingLimit/success",
  "@iam/fetchGeocodingLimit/failure"
)<
  void,
  {
    geocodingOrgLimit: number;
    geocodingMmcLimitReached: boolean;
    geocodingOrgLimitReached: boolean;
  },
  unknown
>();

export const updateLastUsedLayout = createAction("@iam/updateLastUsedLayout")<{
  entityType: EntityTypesSupportingFieldCustomization;
  layoutId: FormLayout["id"];
}>();

export const setBigOrganization = createAction("@iam/setBigOrganization")<boolean>();

export type Actions = ActionType<
  | typeof addFavoriteFilter
  | typeof changePassword
  | typeof deleteProfilePhoto
  | typeof fetchGeocodingLimit
  | typeof fetchMe
  | typeof findMe
  | typeof hideChangePasswordModal
  | typeof hideDeleteProfilePhotoModal
  | typeof removeFavoriteFilter
  | typeof setBigOrganization
  | typeof setCumulPromotionalUiVisited
  | typeof showChangePasswordModal
  | typeof showDeleteProfilePhotoModal
  | typeof toggleFavoriteFunnel
  | typeof togglePinnedDashboard
  | typeof updateLastUsedLayout
  | typeof updateMe
  | typeof updateMetadata
  | typeof updateOrganization
  | typeof updateOrganizationMetadata
  | typeof updateOrganizationSetting
  | typeof updateSetting
  | typeof uploadProfilePhoto
>;

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { ListFetcherPayload } from "types/viewModel/ListFetcher";
import ListRequest from "@mapmycustomers/shared/types/viewModel/internalModel/ListRequest";
import { Person } from "@mapmycustomers/shared/types/entity";
import ColumnModel from "@mapmycustomers/shared/types/viewModel/internalModel/ColumnModel";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

export const initializeListView = createAsyncAction(
  "peopleList/initialize/request",
  "peopleList/initialize/success",
  "peopleList/initialize/failure"
)<void, void, unknown>();

export const fetchList = createAsyncAction(
  "peopleList/fetch/request",
  "peopleList/fetch/success",
  "peopleList/fetch/failure"
)<ListFetcherPayload<Person>, { totalFilteredRecords: number; totalRecords: number }, unknown>();

export const applyListViewSettings = createAction("peopleList/applyListViewSettings")<
  Partial<ListRequest>
>();

export const downloadList = createAction("peopleList/downloadList")<{
  columns: ColumnModel<IField>;
}>();

export const setOfferedFilters = createAction("peopleList/setOfferedFilter")<
  Partial<FilterModel> | undefined
>();

export type Actions = ActionType<
  | typeof downloadList
  | typeof initializeListView
  | typeof fetchList
  | typeof applyListViewSettings
  | typeof setOfferedFilters
>;

import React, { FC } from "react";
import { Company } from "@mapmycustomers/shared/types/entity";
import useChangeTracking from "@mapmycustomers/shared/util/hook/useChangeTracking";
import Input from "antd/es/input";

interface NotifyInputProps {
  companyId?: Company["id"] | null;
  onChange?: (companyId?: Company["id"]) => void;
  onReset?: () => void;
  value?: Company["id"];
}

// This is a tricky way to notify form about changes in the company field.
// When user picks new company, we set its value using form.setFieldsValue.
// However, this method doesn't trigger "form changed" event. So we can't show the Save Changes
// buttons.
// This NotifyInput is used to notify form about change differently - using
// Form.Item's natural way to do that - via calling input's onChange event.
//
// Hence the flow is the following:
// - when user changes a company, we set companyId in a parent component to a new
//   value - either Company["id"] or undefined.
// - NotifyInput is re-rendered and hence companyId prop has changed, useEffect is called
// - In useEffect we check if the value is meaningful (not null) and call onChange to
//   notify form about changes
// - Then we call onReset to reset companyId value back to null. I.e. we mark it as "applied".
//   This is necessary because if we don't do that the following may happen:
//     - user picks a company, that company is saved in companyId
//     - user resets the form, companyId is not affected by that
//     - user picks same company again. Nothing changes because it's the same companyId
//   That's why we always reset companyId back to a neutral state - null.
const NotifyInput: FC<NotifyInputProps> = ({
  companyId,
  onChange,
  onReset,
  value,
}: NotifyInputProps) => {
  useChangeTracking(
    () => {
      if (companyId !== null) {
        onChange?.(companyId);
        onReset?.();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyId]
  );

  return <Input value={value} />;
};

export default NotifyInput;

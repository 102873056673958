import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldModel from "./FieldModel";
import Field, { FieldProperties } from "./Field";

/**
 * Returns a number that represents field order according to its type. Each type has its own order number.
 * @param {IField} field
 * @returns {number}
 */
export const getDefaultFieldTypeOrder = (field: IField) => {
  if (field.hasFeature(FieldFeature.STANDARD)) {
    return 0;
  }
  if (field.hasFeature(FieldFeature.CUSTOM_FIELD)) {
    return 3;
  }
  if (field.hasFeature(FieldFeature.ADDRESS)) {
    return 2;
  }
  if (field.hasFeature(FieldFeature.ENGAGEMENT)) {
    return 1;
  }
  return 0;
};

/**
 * Fields comparator.
 * Name field is always the first. Address Detail fields are put at the end of list.
 */
export const compareFields = (a: IField, b: IField) => {
  // name field is always the first
  if (a.name === FieldModel.NAME_FIELD_NAME) {
    return b.name === FieldModel.NAME_FIELD_NAME ? 0 : -1;
  }
  if (b.name === FieldModel.NAME_FIELD_NAME) {
    return 1;
  }

  const aTypeOrder = getDefaultFieldTypeOrder(a);
  const bTypeOrder = getDefaultFieldTypeOrder(b);
  if (aTypeOrder !== bTypeOrder) {
    return aTypeOrder - bTypeOrder;
  }

  if (a.displayOrder !== undefined && b.displayOrder !== undefined) {
    return a.displayOrder - b.displayOrder;
  }

  // if fields are of one type sort them by displayName
  return a.displayName.localeCompare(b.displayName);
};

/**
 * Sorts given list of fields in-place.
 * @param {IField[]} fields
 * @returns {IField[]}
 */
export const sortFields = (fields: IField[]) => fields.sort(compareFields);

export const createField = (data: IField | FieldProperties) => {
  if (data instanceof Field) {
    return data as IField;
  }

  return new Field(data as FieldProperties);
};

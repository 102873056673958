import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { RawFile } from "@mapmycustomers/shared/types/File";
import FileUploader from "component/FileUploader";
import FileListItem from "types/FileListItem";
import Modal from "antd/es/modal";
import useAnalytics from "util/contexts/useAnalytics";

interface Props {
  clearAllUploadedFiles?: () => void;
  fileUploading?: boolean;
  onDelete?: (fileId: RawFile) => void;
  onHide: () => void;
  onUpload?: (payload: {
    files: (Blob | File)[];
    callback?: (filesList: FileListItem[]) => void;
  }) => void;
}

const FileUploaderModal: React.FC<Props> = ({
  clearAllUploadedFiles,
  fileUploading,
  onDelete,
  onHide,
  onUpload,
}) => {
  const intl = useIntl();
  const analytics = useAnalytics();
  const handleCancelAddingFile = useCallback(() => {
    onHide();
    clearAllUploadedFiles?.();
  }, [clearAllUploadedFiles, onHide]);

  const handleDelete = useCallback(
    (file: RawFile) => {
      analytics?.clicked(["Delete File"]);
      onDelete && onDelete(file);
    },
    [analytics, onDelete]
  );

  const handleFileAdd = useCallback(
    (payload: { files: (Blob | File)[]; callback?: (filesList: FileListItem[]) => void }) => {
      analytics?.completed(["Add File", "Upload File"]);
      onUpload?.(payload);
    },
    [analytics, onUpload]
  );

  return (
    <Modal
      footer={null}
      onCancel={handleCancelAddingFile}
      open
      title={intl.formatMessage({
        id: "component.fileUploaderModal.title",
        description: "File uploading modal title",
        defaultMessage: "File upload",
      })}
      zIndex={1030} // Higher than activity annotation
    >
      <FileUploader
        fileUploading={fileUploading}
        onFileUpload={handleFileAdd}
        onRemoveFile={handleDelete}
      />
    </Modal>
  );
};

export default FileUploaderModal;

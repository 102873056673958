@use "../../../../../styles/colors";
@use "../../../../../styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .mention {
        color: colors.$mmc-primary;
        font-size: 1rem;
        font-weight: 500;
        background-color: colors.$mmc-soft-gray;
        border-radius: 0.75rem;
        padding: 0.2rem 0.5rem;
      }
      .mentionDisabled {
        color: colors.$mmc-disabled-gray;
      }
    }
  }
}

import { EntityTypeSupportingDataView } from "@mapmycustomers/shared/types/entity";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldCategory from "@mapmycustomers/shared/enum/fieldModel/FieldCategory";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

export const getDefaultField = (entityType: EntityTypeSupportingDataView): IField => {
  const fieldModel = getFieldModelByEntityType(entityType);
  const sourceField = fieldModel.fields.find((field) =>
    field.hasFeature(FieldFeature.SOURCE_FIELD)
  );
  const categorizedFields = fieldModel.categorizedFieldsForDataView;
  // in case if field model hasn't source field, which shouldn't happened
  return sourceField ?? categorizedFields[Object.keys(categorizedFields)[0] as FieldCategory][0];
};

const getDefaultFieldName = (entityType: EntityTypeSupportingDataView): string => {
  return getDefaultField(entityType).name;
};

export default getDefaultFieldName;

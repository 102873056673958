import User from "@mapmycustomers/shared/types/User";
import { useMemo } from "react";
import { isManager, isMember, isOwner } from "store/iam/util";

const useUserAccess = (currentUser: User, user: User | undefined) => {
  const [viewOnly, viewOnlyNoAccess] = useMemo(() => {
    const userTeamIds = user?.teams.map(({ id }) => id) ?? [];
    const sameTeam = currentUser.teams.some(({ id }) => userTeamIds.includes(id));

    const viewOnly = isMember(currentUser) || (isManager(currentUser) && !sameTeam);
    const viewOnlyNoAccess =
      (!isOwner(currentUser) && !sameTeam) || (!!user && isMember(currentUser) && isManager(user));
    return [viewOnly, viewOnlyNoAccess];
  }, [currentUser, user]);

  return [viewOnly, viewOnlyNoAccess];
};

export default useUserAccess;

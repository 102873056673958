import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import sortBy from "lodash-es/sortBy";
import User from "@mapmycustomers/shared/types/User";
import { userDisplayName } from "util/formatters";
import Select, { SelectProps } from "antd/es/select";
import cn from "classnames";
import defaultFilterOption from "../utils/defaultFilterOption";
import { AvatarWithName } from "@mapmycustomers/ui";
import styles from "./UserMultiSelectField.module.scss";
import { getUserTag } from "./UserTag";

export interface UserMultiSelectFieldProps
  extends Omit<
    SelectProps<Array<User["id"]>>,
    "filterOption" | "maxTagCount" | "mode" | "tagRender"
  > {
  oneLiner?: boolean;
  users: User[];
}

const UserMultiSelectField: React.FC<UserMultiSelectFieldProps> = ({
  className,
  oneLiner,
  placeholder,
  users,
  ...props
}) => {
  const intl = useIntl();

  const sortedUsers = useMemo(() => sortBy(users, [userDisplayName]), [users]);

  return (
    <Select<Array<User["id"]>>
      {...props}
      className={cn(styles.container, className)}
      filterOption={defaultFilterOption}
      maxTagCount={oneLiner ? "responsive" : undefined}
      mode="multiple"
      tagRender={getUserTag}
      placeholder={
        placeholder ??
        intl.formatMessage({
          id: "userMultiSelect.placeholder",
          defaultMessage: "Click or type to select users",
          description: "Placeholder displayed in a UserMultiSelectField component",
        })
      }
    >
      {sortedUsers.map((user) => (
        <Select.Option key={user.id} label={userDisplayName(user)} value={user.id}>
          <AvatarWithName className={styles.avatar} user={user} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default UserMultiSelectField;

import Size from "types/map/Size";

const getSvgForCluster = (
  fillColor: string,
  strokeColor: string
): {
  svg: string;
  size: Size;
  anchor: Size;
} => {
  const size = [100, 100] as Size;
  return {
    svg: `<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle cx="50" cy="50" r="50" fill="${fillColor}"/>
        <circle cx="50" cy="50" r="47" stroke="${strokeColor}" stroke-width="6"/>
      </g>
    </svg>`,
    size,
    anchor: [size[0] / 2, size[1] / 2], // center of icon
  };
};

export default getSvgForCluster;

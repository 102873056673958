:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        align-items: start;
      }
      .icon {
        margin-top: 0.25rem;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

import React, { MouseEvent, useCallback } from "react";
import { parsePhoneNumber } from "util/parsers";
import { AnalyticsService } from "util/analytic/AnalyticsService";

interface Props {
  analyticService?: AnalyticsService;
  className?: string;
  value: string | null | undefined;
}

const PhoneNumber: React.FC<Props> = ({ analyticService, className, value }) => {
  // To avoid bubbling up
  // It's not a rare event when this component is used inside some table with a Row click
  // handler. And we don't want the table to catch clicking on the row when user is actually
  // clicking on this link.
  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      analyticService?.clicked(["Phone Click"], { phone: value });
    },
    [analyticService, value]
  );

  if (!value) {
    return <>{""}</>;
  }

  const parsedPhoneNumber = parsePhoneNumber(value);
  if (!parsedPhoneNumber) {
    return <div>{value}</div>; // return value as-is
  }

  return (
    <div className={className}>
      {parsedPhoneNumber.lineStart}
      <a href={parsedPhoneNumber.link} onClick={handleClick}>
        {parsedPhoneNumber.label}
      </a>
      {parsedPhoneNumber.lineEnd}
    </div>
  );
};

export default PhoneNumber;

import React, { useMemo } from "react";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { getUsers } from "store/members";
import styles from "./UserTag.module.scss";
import { connect } from "react-redux";
import Tag from "antd/es/tag";
import User from "@mapmycustomers/shared/types/User";
import { RootState } from "store/rootReducer";
import cn from "classnames";
import { getUserColorClassNameGetter, UserColorClassNameGetter } from "store/userColor/selectors";

interface Props extends CustomTagProps {
  getUserColorClassName: UserColorClassNameGetter;
  user?: User;
}

const UserTag = ({ getUserColorClassName, label, closable, onClose, user }: Props) => {
  const userSelectedClass = useMemo(() => {
    const userColor = user ? getUserColorClassName(user) : undefined;
    return userColor ? styles[userColor] : "";
  }, [getUserColorClassName, user]);

  return (
    <Tag
      className={cn(styles.container, userSelectedClass, "ant-tag-custom-color")}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  );
};

const mapStateToProps = (state: RootState, { value }: CustomTagProps) => ({
  getUserColorClassName: getUserColorClassNameGetter(state),
  user: getUsers(state).find(({ id }) => id === value),
});

const ConnectedUserTag = connect(mapStateToProps)(UserTag);

export const getUserTag = (props: CustomTagProps) => {
  return <ConnectedUserTag {...props} />;
};

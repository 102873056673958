import { defineMessages } from "react-intl";

export const messages = defineMessages({
  companyPersonWrongRelation: {
    id: "relatedEntitiesMatching.companyPersonWrongRelation",
    defaultMessage:
      "The company and person you’ve selected {error, select, true {couldn't be} other {are not}} related to each other.",
    description:
      "Error message when the selected person is not associated with the selected company",
  },
  companyPersonWrongRelationRelated: {
    id: "relatedEntitiesMatching.companyPersonWrongRelationRelated",
    defaultMessage: "The company and person you’ve selected are now related to each other.",
    description:
      "Related message when the selected person is not associated with the selected company",
  },
  relateRecords: {
    id: "relatedEntitiesMatching.relateRecords",
    defaultMessage: "Relate records",
    description: "Button label to relate records",
  },
  tryAgain: {
    id: "relatedEntitiesMatching.tryAgain",
    defaultMessage: "Try again",
    description: "Button label to try again",
  },
});

import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";

export const alertBadgesState = (state: RootState) => state.scene.map.layers.alerts;

export const getVisibleBadges = createSelector(
  alertBadgesState,
  ({ visibleBadges }) => visibleBadges
);

export const getAlertDismissed = createSelector(
  alertBadgesState,
  ({ alertDismissed }) => alertDismissed
);

import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";
import Path from "enum/Path";
import isValidLongLat from "util/geo/isValidLongLat";

const leadFinderState = (state: RootState) => state.scene.map.leadFinder;

export const isAddRecordsVisible = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.addRecords.visible
);

export const isAddRecordsResultLoading = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.addRecords.loading
);

export const isAddRecordsInitializing = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.addRecords.initializing
);

export const isResultModalVisible = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.addRecords.resultsVisible
);

export const isConfigurationVisible = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.configurationVisible
);

export const areDuplicatesVisible = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.duplicates.visible
);

export const getDuplicatesRecords = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.duplicates.records
);

export const hasCenterPoint = createSelector(leadFinderState, (leadFinder) =>
  isValidLongLat(leadFinder.centerPoint)
);

export const getCenterPoint = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.centerPoint
);

export const getAddRecordsEntityType = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.addRecords.entityType
);

export const getAddRecordsAvailableRoutes = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.addRecords.availableRoutes
);

export const getAddRecordsProgress = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.addRecords.progress
);

export const getLeadFinderProcessing = createSelector(
  leadFinderState,
  (leadFinder) => !!leadFinder.processing
);

export const getLeadFinderQuery = createSelector(leadFinderState, (leadFinder) => leadFinder.query);

export const getLeadFinderAddress = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.query.address
);

export const getLeadFinderEntity = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.query.entity
);

export const getLeadFinderLocation = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.query.location
);

export const getLeadFinderMode = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.query.mode
);

export const getLeadFinderSearch = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.query.search
);

export const getLeadFinderCurrentBounds = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.currentBounds
);

export const getLeadFinderRecentSearches = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.recentSearches
);
export const getLeadFinderSavedSearches = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.savedSearches
);
export const isSavedSearchesModalVisible = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.savedSearchesModal
);
export const getLeadFinderCustomDistances = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.customDistances
);
export const getLeadFinderSearchDistance = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.searchDistance
);

export const getLeadFinderRecords = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.results.records
);

export const getLeadFinderOtherPins = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.otherPins
);

export const getLeadFinderModeBasePath = createSelector(leadFinderState, () => {
  return `${Path.MAP}/leads`;
});

export const getPrimaryRecord = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.duplicates.primaryRecord
);

export const isHiddenLeadsVisible = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.hiddenLeads.visible
);

export const isPotentialDuplicateLeadsVisible = createSelector(
  leadFinderState,
  (leadFinder) => leadFinder.potentialDuplicateLeads.visible
);

export const getFilteredLeadFinderRecords = createSelector(
  getLeadFinderRecords,
  isHiddenLeadsVisible,
  isPotentialDuplicateLeadsVisible,
  (records, hiddenLeadsVisible, potentialDuplicateLeadsVisible) => {
    const filterHiddenRecords = hiddenLeadsVisible
      ? records
      : records.filter(({ blackListed }) => !blackListed);
    return potentialDuplicateLeadsVisible
      ? filterHiddenRecords
      : filterHiddenRecords.filter(
          ({ mmcRecords, blackListed }) => !mmcRecords?.length || blackListed
        );
  }
);

export const getLeadFinderRecordsCount = createSelector(
  getFilteredLeadFinderRecords,
  (records) => records.length
);

export const getLeadFinderConfiguration = createSelector(leadFinderState, (leadFinder) => ({
  customDistances: leadFinder.customDistances ?? [],
  recentSearches: leadFinder.recentSearches ?? [],
  savedSearches: leadFinder.savedSearches ?? [],
  searchDistance: leadFinder.searchDistance,
}));

export const getLeadAnnotation = createSelector(leadFinderState, ({ annotation }) => annotation);

import { createReducer } from "typesafe-actions";
import { Actions, filterCompanies, filterDeals, filterPeople } from "./actions";
import Person from "@mapmycustomers/shared/types/entity/Person";
import Company from "@mapmycustomers/shared/types/entity/Company";
import Deal from "@mapmycustomers/shared/types/entity/Deal";

export interface EntitySearchState {
  companies: Company[];
  companiesLoading: boolean;
  deals: Deal[];
  dealsLoading: boolean;
  people: Person[];
  peopleLoading: boolean;
}

const initialState: EntitySearchState = {
  companies: [],
  companiesLoading: false,
  deals: [],
  dealsLoading: false,
  people: [],
  peopleLoading: false,
};

const entitySearch = createReducer<EntitySearchState, Actions>(initialState)
  .handleAction(filterCompanies.request, (state) => ({
    ...state,
    companiesLoading: true,
  }))
  .handleAction(filterCompanies.success, (state, { payload }) => ({
    ...state,
    companies: payload,
    companiesLoading: false,
  }))
  .handleAction(filterCompanies.failure, (state) => ({
    ...state,
    companiesLoading: false,
  }))
  .handleAction(filterDeals.request, (state) => ({
    ...state,
    dealsLoading: true,
  }))
  .handleAction(filterDeals.success, (state, { payload }) => ({
    ...state,
    deals: payload,
    dealsLoading: false,
  }))
  .handleAction(filterDeals.failure, (state) => ({
    ...state,
    dealsLoading: false,
  }))
  .handleAction(filterPeople.request, (state) => ({
    ...state,
    peopleLoading: true,
  }))
  .handleAction(filterPeople.success, (state, { payload }) => ({
    ...state,
    people: payload,
    peopleLoading: false,
  }))
  .handleAction(filterPeople.failure, (state) => ({
    ...state,
    peopleLoading: false,
  }));

export type EntitySearchActions = Actions;
export default entitySearch;

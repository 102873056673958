@use "../styles/colors";
@use "sass:color";

.container {
  background-color: colors.$mmc-white;
  border-radius: 0.25rem;
  border: 1px solid #{colors.$mmc-disabled-gray};

  :global(.react-colorful) {
    width: auto;
  }

  :global(.react-colorful__hue-pointer),
  :global(.react-colorful__alpha-pointer) {
    width: 1rem;
    border-radius: 0.25rem;
  }
}

.inputContainer {
  margin: 0.75rem 0.75rem 0;
  width: 7rem;
}

.namedColors {
  display: grid;
  padding: 0.75rem;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(auto-fill, 1rem);
  align-items: center;
  justify-content: center;
}

.colorItem {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px #{color.adjust(colors.$mmc-slate, $alpha: -0.8)};
}

.opacity {
  padding: 0.75rem;
}

import Visibility from "../Visibility";
import pick from "lodash-es/pick";

type ActivityVisibility =
  | Visibility.PRIVATE
  | Visibility.SHARED_WITH_TEAM
  | Visibility.SHARED_WITH_ORGANIZATION;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ActivityVisibility = pick(Visibility, [
  "SHARED_WITH_ORGANIZATION",
  "SHARED_WITH_TEAM",
  "PRIVATE",
]);

export default ActivityVisibility;

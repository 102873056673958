import React, { useState } from "react";
import { useIntl } from "react-intl";
import Button from "antd/es/button";
import Popover, { PopoverProps } from "antd/es/popover";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./EntityAddressIssueWarning.module.scss";

interface Props {
  placement?: PopoverProps["placement"];
}

const EntityAddressIssueWarning: React.FC<Props> = ({ placement }) => {
  const intl = useIntl();

  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  return (
    <Popover
      arrowPointAtCenter
      content={
        <div className={styles.content}>
          <div className={styles.title}>
            <FontAwesomeIcon className={styles.icon} icon={faExclamationTriangle} />
            {intl.formatMessage({
              id: "entity.addressIssueWarning.popover.title",
              defaultMessage: "Potential address issue",
              description: "Address Issue warning popover - title",
            })}
          </div>
          <span>
            {intl.formatMessage({
              id: "entity.addressIssueWarning.popover.description",
              defaultMessage:
                "This record has an invalid address due to an issue with our API, or that data was provided incorrectly via Import or Integrations.",
              description: "Address Issue warning popover - description",
            })}
          </span>
          <span>
            {intl.formatMessage({
              id: "entity.addressIssueWarning.popover.supportText",
              defaultMessage:
                "Please reach out to support for diagnosis and to ensure this record’s location is correct.",
              description: "Address Issue warning popover - support text",
            })}
          </span>
          <Button className={styles.okBtn} onClick={() => setPopoverVisible(false)} type="primary">
            {intl.formatMessage({
              id: "entity.addressIssueWarning.popover.action.ok",
              defaultMessage: "Got it",
              description: "Incomplete address warning popover - Got it button",
            })}
          </Button>
        </div>
      }
      destroyTooltipOnHide
      onOpenChange={setPopoverVisible}
      open={popoverVisible}
      overlayClassName={styles.overlay}
      placement={placement ?? "top"}
      trigger={["hover"]}
    >
      <Button className={styles.btn}>
        <FontAwesomeIcon className={styles.icon} icon={faExclamationTriangle} />
      </Button>
    </Popover>
  );
};

export default EntityAddressIssueWarning;

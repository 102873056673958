import userColors from "./_userColors.module.scss";
import get from "lodash-es/get";

const baseUserColors = JSON.parse(
  userColors.baseUserColors.substring(1, userColors.baseUserColors.length - 1)
);
const selectedUserColors = JSON.parse(
  userColors.selectedUserColors.substring(
    1,
    userColors.selectedUserColors.length - 1
  )
);

const getUserColor = (userColorClassName?: string): [string, string] => {
  if (!userColorClassName) {
    return ["", ""];
  }

  let colorArray = get(selectedUserColors, userColorClassName);
  if (colorArray) {
    return [colorArray[0], colorArray[2]];
  }
  colorArray = get(baseUserColors, userColorClassName);
  return colorArray ?? ["", ""];
};

export default getUserColor;

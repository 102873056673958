import React from "react";
import styles from "./LeadFinderSearch.module.scss";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight as faArrowAltCircleRightSolid } from "@fortawesome/free-solid-svg-icons/faArrowAltCircleRight";
import { faArrowAltCircleRight } from "@fortawesome/pro-regular-svg-icons/faArrowAltCircleRight";
import { faArrowTurnDownLeft } from "@fortawesome/pro-solid-svg-icons/faArrowTurnDownLeft";

interface Props {
  isSelected: boolean;
  onClick: () => void;
  onMouseMove: () => void;
}

const LeadFinderSearch: React.FC<Props> = ({ isSelected, onClick, onMouseMove }) => {
  const intl = useIntl();

  return (
    <div className={styles.container} onClick={onClick} onMouseMove={onMouseMove}>
      <div className={styles.title}>
        <div>
          {intl.formatMessage({
            id: "component.globalSearch.leadFinder.text",
            defaultMessage: "Find new leads based on this search",
            description: "Lead finder on global search item",
          })}
        </div>
        <FontAwesomeIcon icon={isSelected ? faArrowAltCircleRightSolid : faArrowAltCircleRight} />
      </div>
      {isSelected && (
        <div>
          <FontAwesomeIcon className={styles.enterIcon} icon={faArrowTurnDownLeft} />
        </div>
      )}
    </div>
  );
};

export default LeadFinderSearch;

import React, { useCallback, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import cn from "classnames";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import Form from "antd/es/form";
import Menu from "antd/es/menu";
import { MenuInfo } from "rc-menu/lib/interface";
import Tooltip from "antd/es/tooltip";
import { FormInstance } from "antd/es/form/hooks/useForm";
import TimePicker from "component/input/TimePicker";
import FormValues from "../../../../types/FormValues";
import { DEFAULT_ACTIVITY_PERIOD_DURATIONS, SHOULD_USE_12_HOUR_FORMAT } from "util/consts";
import styles from "../../ActivityTime.module.scss";
import { addDays, addMinutes, differenceInMinutes } from "date-fns/esm";
import { formatDate } from "util/formatters";
import isValidDate from "util/isValidDate";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";

interface Props {
  defaultValue?: Date;
  form: FormInstance<FormValues>;
  onChange?: () => void;
  onOpenChange?: (value: boolean) => void;
}

const messages = defineMessages({
  changeEndTime: {
    id: "createActivityModal.footer.scheduleActivity.changeEndTime",
    defaultMessage: "Change End Time",
    description: "Change End Time",
  },
  noEndTime: {
    id: "createActivityModal.footer.scheduleActivity.noEndTime",
    defaultMessage: "No End Time",
    description: "No End Time",
  },
  removeEndTime: {
    id: "createActivityModal.footer.scheduleActivity.removeEndTime",
    defaultMessage: "Remove End Time",
    description: "Remove End Time",
  },
  selectAnotherTime: {
    id: "createActivityModal.footer.scheduleActivity.selectAnotherTime",
    defaultMessage: "Select Another Time",
    description: "Select Another Time",
  },
  selectEndTime: {
    id: "createActivityModal.footer.scheduleActivity.selectEndTime",
    defaultMessage: "Select End Time",
    description: "Select End Time",
  },
  time: {
    id: "createActivityModal.footer.scheduleActivity.time",
    defaultMessage: "<b>{time}</b> ({mins} mins)",
    description: "Default period time label",
  },
});

const ActivityEndTime: React.FC<Props> = ({ defaultValue, form, onChange, onOpenChange }) => {
  const intl = useIntl();

  const [open, setOpen] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [customEndTime, setCustomEndTime] = useState(false);

  const startDate = form.getFieldValue(ActivityFieldName.START_AT);
  const isValidStartDate = isValidDate(startDate);
  const startDateValue = isValidStartDate ? startDate : undefined;
  const endDate = form.getFieldValue(ActivityFieldName.END_AT);
  const isValidEndDate = isValidDate(endDate);
  const endDateValue = isValidEndDate ? endDate : undefined;
  const duration =
    endDateValue && startDateValue ? differenceInMinutes(endDateValue, startDateValue) : undefined;

  const handleEndOpenChanged = useCallback(
    (open: boolean) => {
      if (open) {
        if (duration) {
          setCustomEndTime(!DEFAULT_ACTIVITY_PERIOD_DURATIONS.includes(duration));
        } else {
          setCustomEndTime(false);
        }
        setTooltipVisible(false);
      }

      setOpen(open);
      onOpenChange?.(open);
    },
    [duration, onOpenChange]
  );

  const handleClick = useCallback(() => {
    if (duration) {
      setCustomEndTime(!DEFAULT_ACTIVITY_PERIOD_DURATIONS.includes(duration));
    } else {
      setCustomEndTime(false);
    }

    setTooltipVisible(false);

    setOpen(true);
  }, [duration]);

  const handleMenuClick = useCallback(
    (item: MenuInfo) => {
      switch (item.key) {
        case "custom":
          setCustomEndTime(true);
          break;

        default:
          const value = parseInt(String(item.key), 10);
          if (!isNaN(value)) {
            setCustomEndTime(false);

            form.setFieldsValue({ endAt: addMinutes(startDate, value) });

            setOpen(false);
            onOpenChange?.(false);
            onChange?.();
          }
      }
    },
    [form, onChange, onOpenChange, startDate]
  );

  const handleChange = useCallback(
    (value) => {
      const isValid = value && Date.parse(value.toString());
      if (isValid && value < startDate) {
        form.setFieldsValue({ endAt: addDays(value, 1) });
      }
      onChange?.();
    },
    [form, onChange, startDate]
  );

  const timeFormat = SHOULD_USE_12_HOUR_FORMAT ? "hh:mm a" : "HH:mm";
  const timeFormatPicker = SHOULD_USE_12_HOUR_FORMAT ? "hh:mm" : "HH:mm";

  const endDateFormatted = isValidEndDate
    ? formatDate(endDate, timeFormat)
    : intl.formatMessage(messages.noEndTime);

  return (
    <>
      <span className={styles.separator}>&ndash;</span>

      <div>
        <Tooltip
          destroyTooltipOnHide
          mouseLeaveDelay={0}
          onVisibleChange={setTooltipVisible}
          overlayClassName={styles.tooltip}
          placement="top"
          title={
            isValidEndDate
              ? intl.formatMessage(messages.changeEndTime)
              : intl.formatMessage(messages.selectEndTime)
          }
          trigger={open ? [] : ["hover"]}
          visible={tooltipVisible}
        >
          {customEndTime ? (
            <>
              <Form.Item name={ActivityFieldName.END_AT} noStyle>
                <TimePicker
                  allowClear={false}
                  bordered={false}
                  defaultValue={defaultValue}
                  dropdownClassName={cn(styles.panel, styles.panelEnd)}
                  format={timeFormatPicker}
                  inputReadOnly
                  minuteStep={5}
                  onChange={handleChange}
                  onOpenChange={handleEndOpenChanged}
                  open={open}
                  placeholder=""
                  showNow={false}
                  showSecond={false}
                  suffixIcon={<></>}
                  use12Hours={SHOULD_USE_12_HOUR_FORMAT}
                />
              </Form.Item>

              <Button
                className={cn(styles.btn, {
                  [styles.active]: open,
                  [styles.empty]: !open && !isValidEndDate,
                })}
                onClick={handleClick}
              >
                {endDateFormatted}
              </Button>
            </>
          ) : (
            <Dropdown
              onVisibleChange={handleEndOpenChanged}
              overlay={
                <Menu
                  className={styles.menu}
                  mode="vertical"
                  onClick={handleMenuClick}
                  selectedKeys={duration ? [String(duration)] : undefined}
                >
                  {DEFAULT_ACTIVITY_PERIOD_DURATIONS.map((value) => (
                    <Menu.Item key={String(value)}>
                      {intl.formatMessage(messages.time, {
                        b: (text) => <strong>{text}</strong>,
                        time: formatDate(addMinutes(startDate, value), timeFormat),
                        mins: value,
                      })}
                    </Menu.Item>
                  ))}
                  <Menu.Item key="custom">
                    {intl.formatMessage(messages.selectAnotherTime)}
                  </Menu.Item>
                </Menu>
              }
              overlayClassName={styles.overlay}
              placement="topCenter"
              trigger={["click"]}
              visible={open}
            >
              <Button
                className={cn(styles.btn, {
                  [styles.active]: open,
                  [styles.empty]: !open && !isValidEndDate,
                })}
                onClick={handleClick}
              >
                {endDateFormatted}
              </Button>
            </Dropdown>
          )}
        </Tooltip>
      </div>
    </>
  );
};

export default ActivityEndTime;

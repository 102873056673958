import {
  Company,
  Deal,
  EntityTypeSupportingPostCreationModal,
  Person,
} from "@mapmycustomers/shared/types/entity";
import { createReducer } from "typesafe-actions";
import {
  Actions,
  addCompanyChildCompanies,
  addCompanyDeals,
  addCompanyParentCompany,
  addCompanyPeople,
  addDealParentCompany,
  addDealParentPerson,
  addPersonCompanies,
  addPersonDeals,
  hideGlobalPostCreationModal,
  showGlobalPostCreationModal,
} from "./actions";

export interface PostCreationModalState {
  childCompanies?: Company[];
  companyDeals: Deal[];
  companyParentCompany?: Company;
  companyPeople?: Person[];
  dealParentCompany?: Company;
  dealParentPerson?: Person;
  entityType?: EntityTypeSupportingPostCreationModal;
  loading: boolean;
  personCompanies: Company[];
  personDeals: Deal[];
  personParentCompany?: Company;
  prefilledValues?: Company | Person | Deal;
  visible: boolean;
}

const initialState: PostCreationModalState = {
  loading: false,
  companyDeals: [],
  personCompanies: [],
  personDeals: [],
  visible: false,
};

const postCreationModal = createReducer<PostCreationModalState, Actions>(initialState)
  .handleAction(showGlobalPostCreationModal, (state, action) => ({
    ...state,
    entityType: action.payload?.entityType,
    prefilledValues: action.payload?.entity,
    visible: true,
  }))
  .handleAction(hideGlobalPostCreationModal, (state) => ({
    ...state,
    entityType: undefined,
    prefilledValues: undefined,
    visible: false,
  }))
  .handleAction(addCompanyChildCompanies.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addCompanyChildCompanies.success, (state, action) => ({
    ...state,
    loading: false,
    childCompanies: action.payload,
  }))
  .handleAction(addCompanyChildCompanies.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(addCompanyDeals.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addCompanyDeals.success, (state, action) => ({
    ...state,
    loading: false,
    companyDeals: action.payload,
  }))
  .handleAction(addCompanyDeals.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(addPersonDeals.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addPersonDeals.success, (state, action) => ({
    ...state,
    loading: false,
    personDeals: action.payload,
  }))
  .handleAction(addPersonDeals.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(addCompanyParentCompany.request, (state, action) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addCompanyParentCompany.success, (state, action) => ({
    ...state,
    loading: false,
    companyParentCompany: action.payload,
  }))
  .handleAction(addCompanyParentCompany.failure, (state, action) => ({
    ...state,
    loading: false,
  }))
  .handleAction(addPersonCompanies.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addPersonCompanies.success, (state, { payload }) => ({
    ...state,
    loading: false,
    personCompanies: payload,
  }))
  .handleAction(addPersonCompanies.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(addDealParentCompany.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addDealParentCompany.success, (state, action) => ({
    ...state,
    loading: false,
    dealParentCompany: action.payload,
  }))
  .handleAction(addDealParentCompany.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(addCompanyPeople.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addCompanyPeople.success, (state, action) => ({
    ...state,
    loading: false,
    companyPeople: action.payload,
  }))
  .handleAction(addCompanyPeople.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(addDealParentPerson.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(addDealParentPerson.success, (state, action) => ({
    ...state,
    loading: false,
    dealParentPerson: action.payload,
  }))
  .handleAction(addDealParentPerson.failure, (state) => ({
    ...state,
    loading: false,
  }));

export * from "./selectors";
export type PostCreationModalActions = Actions;
export default postCreationModal;

import { EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";
import MapFilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/MapFilterModel";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

const convertMapFilterModelToFilterModelForEntity = (
  entityType: EntityTypesSupportedByMapsPage,
  mapFilterModel: MapFilterModel
) => {
  return {
    ...(mapFilterModel.universal ?? {}),
    ...(mapFilterModel[entityType] ?? {}),
  } as FilterModel;
};

export default convertMapFilterModelToFilterModelForEntity;

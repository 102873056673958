import DateRangeType from "../enums/DateRangeType";
import DateRange from "../types/DateRange";
import { formatISO } from "date-fns/esm";

const dateRangeFilterToRange = (
  dateRange: DateRange | undefined,
  dateRangeType: DateRangeType
): string => {
  if (dateRangeType !== DateRangeType.CUSTOM) {
    return dateRangeType;
  }

  if (dateRange) {
    return `${formatISO(dateRange.startDate, { representation: "date" })}_${formatISO(
      dateRange.endDate,
      {
        representation: "date",
      }
    )}`;
  }

  return DateRangeType.YESTERDAY;
};

export default dateRangeFilterToRange;

import { Condition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";

const getStartRange = (
  recapInterval: Interval | undefined,
  filter: ActivityStatusOption
): Condition | undefined => {
  if (filter === ActivityStatusOption.NOT_SCHEDULED) {
    return {
      [PlatformFilterOperator.EQUALS]: null,
    };
  }
  if (recapInterval) {
    if (filter === ActivityStatusOption.OVERDUE) {
      return {
        [PlatformFilterOperator.LESS_THAN]: new Date().toISOString(),
        [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: (
          recapInterval.start as Date
        )?.toISOString(),
      };
    } else if (filter === ActivityStatusOption.UPCOMING) {
      return {
        [PlatformFilterOperator.LESS_THAN]: (recapInterval.end as Date)?.toISOString(),
        [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: new Date().toISOString(),
      };
    } else
      return {
        [PlatformFilterOperator.LESS_THAN]: (recapInterval.end as Date)?.toISOString(),
        [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: (
          recapInterval.start as Date
        )?.toISOString(),
      };
  }
};

export default getStartRange;

import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import DropZone from "component/Dropzone";
import ButtonLink from "component/ButtonLink";
import TextWithInfo from "component/typography/TextWithInfo";
import { RawFile } from "@mapmycustomers/shared/types/File";
import { RECORD_FILE_UPLOAD_LIMIT } from "util/consts";
import cn from "classnames";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import FileListItem from "types/FileListItem";
import canPreview from "component/FilePreview/canPreview";
import PreviewFile from "component/FilePreview/PreviewFile";
import FileItem from "./FileItem";
import useAnalytics from "../../util/contexts/useAnalytics";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import useChangeTracking from "@mapmycustomers/shared/util/hook/useChangeTracking";
import styles from "./FileUploader.module.scss";

interface Props {
  associatedFiles?: RawFile[];
  disabled?: boolean;
  filePreview?: Blob;
  filePreviewId?: RawFile["id"];
  filePreviewLoading?: boolean;
  fileUploading?: boolean;
  onChange?: (uploadedFiles: RawFile[]) => void;
  onDownload?: (file: RawFile) => void;
  onFetchFilePreview?: (fileId: RawFile["id"]) => void;
  onFileUpload: (payload: {
    files: (Blob | File)[];
    callback: (filesList: FileListItem[]) => void;
  }) => void;
  onRemoveFile: (uploadedFile: RawFile) => void;
  required?: boolean;
}

const FileUploader: React.FC<Props> = ({
  associatedFiles,
  disabled,
  filePreview,
  filePreviewId,
  filePreviewLoading,
  fileUploading,
  onChange,
  onDownload,
  onFetchFilePreview,
  onFileUpload,
  onRemoveFile,
  required,
}) => {
  const intl = useIntl();
  const analyticsIssuer = useAnalytics();
  const [isDragging, startDragging, stopDragging] = useBoolean();
  const [attemptedFiles, setAttemptedFiles] = useState<FileListItem[]>([]);
  const [selectedFile, selectFile] = useState<RawFile | undefined>(undefined);

  // We use useDynamicCallback instead of direct onChange usage because of some weirdness in Form.Item.
  // Its onChange handler changes all the time causing useChangeTracking to be executed again and again.
  // It's not good that we're modifying this component for its possible usage, but no big harm either.
  const handleChange = useDynamicCallback((uploadedFiles: RawFile[]) => onChange?.(uploadedFiles));

  useChangeTracking(() => {
    const uploadedFiles = attemptedFiles.reduce((result, { uploadedFile }) => {
      if (uploadedFile) {
        result.push(uploadedFile);
      }
      return result;
    }, [] as RawFile[]);
    handleChange(uploadedFiles);
  }, [attemptedFiles, handleChange]);

  const handleSelectFile = useCallback(
    (files: File[]) => {
      analyticsIssuer.clicked(["Upload File"]);

      setAttemptedFiles((attemptedFiles) => [
        ...attemptedFiles,
        ...files.map((file) => ({
          errored: false,
          exceedsLimit: file.size >= RECORD_FILE_UPLOAD_LIMIT,
          file,
          uploading: file.size < RECORD_FILE_UPLOAD_LIMIT,
        })),
      ]);

      onFileUpload({
        files: files.filter(({ size }) => size < RECORD_FILE_UPLOAD_LIMIT),
        callback: (filesList) => {
          setAttemptedFiles((attemptedFiles) => {
            return [...attemptedFiles.filter(({ uploading }) => !uploading), ...filesList];
          });
        },
      });
    },
    [analyticsIssuer, onFileUpload]
  );

  const isAnyFileErrored = attemptedFiles.some(({ errored }) => errored);
  const fileExceedsLimit = attemptedFiles.some(({ exceedsLimit }) => exceedsLimit);

  const handleRemove = useCallback(
    (index) => {
      const uploadedFile = attemptedFiles[index].uploadedFile;
      if (uploadedFile) {
        onRemoveFile(uploadedFile);
      }
      setAttemptedFiles([...attemptedFiles.slice(0, index), ...attemptedFiles.slice(index + 1)]);
    },
    [attemptedFiles, onRemoveFile, setAttemptedFiles]
  );

  const handleFileClick = useCallback(
    (file: RawFile) => {
      if (canPreview(file)) {
        selectFile(file);
      } else {
        onDownload?.(file);
      }
    },
    [onDownload, selectFile]
  );

  return (
    <div>
      <div className={styles.filesTitleDiv}>
        <TextWithInfo
          info={intl.formatMessage(
            {
              id: "files.fileUploader.label.infoTooltip",
              defaultMessage:
                "We support most images, pdfs, documents and videos up to {fileLimit, number, ::K}MB",
              description: "Files uploader label tooltip for create modals",
            },
            {
              fileLimit: RECORD_FILE_UPLOAD_LIMIT / 1024 / 1024,
            }
          )}
        >
          <div className={cn({ [styles.required]: required })}>
            {intl.formatMessage({
              id: "files.fileUploader.label",
              defaultMessage: "Files",
              description: "Files uploader label for create modals",
            })}
          </div>
        </TextWithInfo>
      </div>
      {!disabled && (
        <DropZone onSelect={handleSelectFile}>
          <div
            className={cn(styles.dropzone, {
              [styles.draggedInDropZone]: isDragging && !isAnyFileErrored && !fileExceedsLimit,
              [styles.dropzoneErrored]: (isAnyFileErrored && !fileUploading) || fileExceedsLimit,
            })}
            onDragEnter={startDragging}
            onDragLeave={stopDragging}
          >
            <p className={styles.dropzoneTitle}>
              {intl.formatMessage(
                {
                  id: "files.fileUploader.dropZone.title",
                  defaultMessage: "Drag and drop a file or <a>upload</a>",
                  description: "Title for file uploader on create modals",
                },
                {
                  a: (text: string) => <ButtonLink>{text}</ButtonLink>,
                }
              )}
            </p>
          </div>
        </DropZone>
      )}
      {isAnyFileErrored && !fileUploading && (
        <span className={styles.errorMessage}>
          {intl.formatMessage({
            id: "files.fileUploader.erroredFiles.title",
            defaultMessage: "There was a problem uploading one or more files.",
            description: "Error file title for file uploader",
          })}
        </span>
      )}
      {fileExceedsLimit && (
        <span className={styles.errorMessage}>
          {intl.formatMessage(
            {
              id: "files.fileUploader.error.exceededSizeLimit",
              defaultMessage:
                "One or more file is too large. Please select files that are {fileLimit, number, ::K}MB or smaller.",
              description: "Error for file uploader when uploaded file exceeds the limit",
            },
            { fileLimit: RECORD_FILE_UPLOAD_LIMIT / 1024 / 1024 }
          )}
        </span>
      )}
      <ul className={cn(styles.filesList)}>
        {attemptedFiles.map((fileItem, index) => (
          <FileItem
            className={
              (!fileItem.uploading && fileItem.errored) || fileItem.exceedsLimit
                ? styles.erroredFileRow
                : styles.fileRow
            }
            disabled={disabled}
            fileName={
              fileItem.uploadedFile?.name ??
              (fileItem.file instanceof File ? fileItem.file.name : undefined)
            }
            key={fileItem?.uploadedFile?.id ?? index}
            onRemove={() => handleRemove(index)}
            uploading={fileItem.uploading}
            errored={fileItem.errored || fileItem.exceedsLimit}
          />
        ))}
        {associatedFiles?.map((file) => (
          <FileItem
            className={cn(styles.fileRow, styles.clickableFileRow)}
            disabled={disabled}
            file={file}
            fileName={file.name}
            key={file.id}
            onClick={handleFileClick}
            onRemove={() => onRemoveFile(file)}
          />
        ))}
      </ul>
      {selectedFile && (
        <PreviewFile
          disabled={disabled}
          file={selectedFile}
          loading={filePreviewLoading}
          onCancel={() => {
            selectFile(undefined);
          }}
          onDelete={(file: RawFile) => {
            onRemoveFile(file);
            selectFile(undefined);
          }}
          onDownload={onDownload}
          onFetchFilePreview={onFetchFilePreview}
          preview={filePreview}
          previewId={filePreviewId}
        />
      )}
    </div>
  );
};

export default FileUploader;

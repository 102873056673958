import { defineMessages, useIntl } from "react-intl";
import React from "react";
import { RecurringActionType } from "./enum/RecurringActionType";
import Dropdown from "antd/es/dropdown";
import styles from "./CreateEditActivityModal.module.scss";
import Menu from "antd/es/menu";

const messages = defineMessages({
  deleteMenuTitle: {
    id: "editActivityModal.footer.delete.popover.title",
    defaultMessage: "Delete repeating activity?",
    description: "Delete activity from edit modal popover title",
  },
  deleteMenuAssigneeNotCreatorTitle: {
    id: "editActivityModal.footer.delete.popover.assigneeNotCreator.title",
    defaultMessage:
      "Please note, updates will only apply to activity instances you are the creator or assignee of.",
    description:
      "Delete activity from edit modal popover title where assignee is not the creator of activity",
  },
  deleteMenuOnlyThis: {
    id: "editActivityModal.footer.delete.popover.menu.onlyThis",
    defaultMessage: "Delete only this activity",
    description: "Deletee only this option from edit modal popover menu",
  },
  deleteMenuThisAndFuture: {
    id: "editActivityModal.footer.delete.popover.menu.thisAndFuture",
    defaultMessage: "Delete This and future activities",
    description: "Delete this and future option from edit modal popover menu",
  },
  deleteMenuAll: {
    id: "editActivityModal.footer.delete.popover.menu.all",
    defaultMessage: "Delete all activities (past and future)",
    description: "Delete all option from edit modal popover menu",
  },
});

interface Props {
  onDelete: (deleteType?: RecurringActionType) => void;
  children?: React.ReactNode;
  isAssigneeNotCreator: boolean;
}

export const RecurringDeleteDropdown: React.FC<Props> = ({
  children,
  isAssigneeNotCreator,
  onDelete,
}) => {
  const intl = useIntl();

  return (
    <Dropdown
      overlayClassName={styles.actionDropdown}
      overlay={
        <Menu>
          <Menu.ItemGroup
            title={
              isAssigneeNotCreator
                ? intl.formatMessage(messages.deleteMenuAssigneeNotCreatorTitle)
                : intl.formatMessage(messages.deleteMenuTitle)
            }
          >
            <Menu.Item onClick={() => onDelete()}>
              {intl.formatMessage(messages.deleteMenuOnlyThis)}
            </Menu.Item>

            <Menu.Item onClick={() => onDelete(RecurringActionType.Future)}>
              {intl.formatMessage(messages.deleteMenuThisAndFuture)}
            </Menu.Item>

            <Menu.Item onClick={() => onDelete(RecurringActionType.All)}>
              {intl.formatMessage(messages.deleteMenuAll)}
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu>
      }
      trigger={["click"]}
    >
      {children}
    </Dropdown>
  );
};

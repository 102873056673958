import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Tooltip from "antd/es/tooltip";
import { LoadingSpinner, TextField } from "@mapmycustomers/ui";
import SelectField from "component/input/SelectField";
import RegionField from "component/input/RegionField";
import { reverseGeocodeAddress } from "store/location/actions";
import Address from "@mapmycustomers/shared/types/Address";
import Region from "@mapmycustomers/shared/types/Region";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import useFindMyLocation from "util/hook/useFindMyLocation";
import useCountryListOptions from "util/countries/useCountryListOptions";
import { isEmptyString } from "@mapmycustomers/shared/util/stringUtils";
import { findMeTooltipMessage } from "./utils/messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/pro-light-svg-icons/faDotCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import layout from "styles/layout";
import styles from "./ManualAddress.module.scss";

interface Props {
  disabled?: boolean;
  editMode?: boolean;
  onChange?: (address?: Address) => void;
  onReverseGeocodeAddress: typeof reverseGeocodeAddress;
  value?: Address;
}

const ManualAddress: React.FC<Props> = ({
  disabled,
  editMode,
  onChange,
  onReverseGeocodeAddress,
  value,
}) => {
  const intl = useIntl();

  const handleAddressChange = useCallback(
    (address) => onChange?.({ ...value, address }),
    [onChange, value]
  );

  const handleCountryCodeChange = useCallback(
    (countryCode = null, row) => {
      onChange?.({
        ...value,
        country: row ? row.text : null,
        countryCode,
        region: undefined,
        regionCode: undefined,
      });
    },
    [onChange, value]
  );

  const handleCityChange = useCallback((city) => onChange?.({ ...value, city }), [onChange, value]);

  const handleRegionChange = useCallback(
    (region: Region) => onChange?.({ ...value, ...region }),
    [onChange, value]
  );

  const handlePostalCodeChange = useCallback(
    (postalCode) => onChange?.({ ...value, postalCode }),
    [onChange, value]
  );

  // Find Me functionality:
  const [, , findMeLoading, findMeError, handleFindMyLocation, geocodingResult, resetError] =
    useFindMyLocation(intl, onReverseGeocodeAddress);
  const lastGeocodingResult = useRef<GeocodeResult>();

  const canClearCountry = useMemo(
    () =>
      !isEmptyString(value?.countryCode) &&
      isEmptyString(value?.address) &&
      isEmptyString(value?.city) &&
      isEmptyString(value?.region) &&
      isEmptyString(value?.postalCode),
    [value]
  );

  useEffect(() => {
    resetError();
  }, [value, resetError]);

  useEffect(() => {
    if (lastGeocodingResult.current !== geocodingResult) {
      lastGeocodingResult.current = geocodingResult;
      onChange?.(geocodingResult?.address);
    }
  }, [geocodingResult, onChange]);

  const countryListOptions = useCountryListOptions(intl);

  return (
    <Row gutter={[layout.spacerS, layout.spacerS]}>
      <Col span={24}>
        <TextField
          disabled={disabled}
          error={findMeError}
          onChange={handleAddressChange}
          placeholder={intl.formatMessage({
            id: "createCompanyModal.addressFields.street",
            defaultMessage: "Street",
            description: "Street Address field on the Create Company modal",
          })}
          suffix={
            findMeLoading ? (
              <LoadingSpinner mini />
            ) : (
              <Tooltip title={intl.formatMessage(findMeTooltipMessage)}>
                <FontAwesomeIcon icon={faDotCircle} onClick={handleFindMyLocation} />
              </Tooltip>
            )
          }
          value={value?.address}
        />
      </Col>
      <Col span={24}>
        <SelectField
          allowClear={canClearCountry}
          className={styles.countryDropdown}
          clearIcon={<FontAwesomeIcon icon={faTimesCircle} />}
          disabled={disabled}
          dropdownMatchSelectWidth
          onChange={handleCountryCodeChange}
          optionFilterProp="text"
          options={countryListOptions}
          placeholder={intl.formatMessage({
            id: "autoComplete.addressFields.country",
            defaultMessage: "Country",
            description: "Country field on the Create Company modal",
          })}
          showSearch
          value={value?.countryCode}
        />
      </Col>
      <Col span={editMode ? 9 : 12}>
        <TextField
          disabled={disabled}
          onChange={handleCityChange}
          placeholder={intl.formatMessage({
            id: "autoComplete.addressFields.city",
            defaultMessage: "City",
            description: "City field on the Create Company modal",
          })}
          value={value?.city}
        />
      </Col>
      <Col span={editMode ? 9 : 12}>
        <RegionField
          countryCode={value?.countryCode}
          disabled={disabled}
          onChange={handleRegionChange}
          value={value}
        />
      </Col>
      <Col span={editMode ? 6 : 24}>
        <TextField
          disabled={disabled}
          onChange={handlePostalCodeChange}
          placeholder={intl.formatMessage({
            id: "autoComplete.addressFields.postalCode",
            defaultMessage: "Postal Code",
            description: "Postal code field on the Create Company modal",
          })}
          value={value?.postalCode}
        />
      </Col>
    </Row>
  );
};

const mapDispatchToProps = {
  onReverseGeocodeAddress: reverseGeocodeAddress,
};
export default connect(null, mapDispatchToProps)(ManualAddress);

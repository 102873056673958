import { combineReducers } from "redux";
import manageFields, { ManageFieldsActions } from "./component/ManageFields/store";
import formLayouts, { FormLayoutsActions } from "./component/FormLayouts/store";
import mergeDuplicates, { MergeDuplicatesActions } from "./component/MergeDuplicates/store";
import customFields, { CustomFieldsActions } from "./component/CustomFields/store";
import sandbox, { SandboxActions } from "./component/Sandbox/store";
import imports, { ImportActions } from "./component/Imports/store";

const settingsReducer = combineReducers({
  customFields,
  formLayouts,
  imports,
  manageFields,
  mergeDuplicates,
  sandbox,
});

export type SettingsActions =
  | CustomFieldsActions
  | FormLayoutsActions
  | ImportActions
  | ManageFieldsActions
  | MergeDuplicatesActions
  | SandboxActions;

export default settingsReducer;

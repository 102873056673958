import { defineMessages, IntlShape } from "react-intl";

enum CalendarViewMode {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export const AllCalendarViewModes = Object.values(CalendarViewMode);

const messages = defineMessages({
  [CalendarViewMode.DAY]: {
    id: "calendarViewMode.day",
    defaultMessage: "Day",
    description: "Day Calendar View mode's title",
  },
  [CalendarViewMode.WEEK]: {
    id: "calendarViewMode.week",
    defaultMessage: "Week",
    description: "Week Calendar View mode's title",
  },
  [CalendarViewMode.MONTH]: {
    id: "calendarViewMode.month",
    defaultMessage: "Month",
    description: "Month Calendar View mode's title",
  },
});

export const getCalendarViewModeDisplayName = (intl: IntlShape, viewMode: CalendarViewMode) =>
  intl.formatMessage(messages[viewMode]);

export default CalendarViewMode;

import { MultiPin } from "types/map";
import MarkerEffect from "enum/MarkerEffect";
import defaultColorGetter from "./defaultColorGetter";
import getSvgForShape from "util/map/markerStyles/getSvgForShape";
import getSvgForIcon from "util/map/markerStyles/getSvgForIcon";
import svgToDataUrl from "util/map/svgToDataUrl";
import ColorDefinition from "util/map/markerStyles/types/ColorDefinition";
import { getScaledSizeByMarker } from "util/map/markerStyles/getScaledSize";
import { PIN_ZINDEX } from "util/map/consts";
import isFunction from "lodash-es/isFunction";
import getResultingSvg from "util/map/markerStyles/getResultingSvg";
import getSvgEffect from "util/map/markerStyles/getSvgEffect";
import MarkerSize from "@mapmycustomers/shared/enum/MarkerSize";
import MarkerShape from "@mapmycustomers/shared/enum/MarkerShape";

interface StyleArguments {
  colorGetter?: (multiPin: MultiPin) => ColorDefinition;
  effect?: MarkerEffect | ((entityPin: MultiPin) => MarkerEffect);
  markerSize: MarkerSize;
  shape: MarkerShape | ((multiPin: MultiPin) => MarkerShape);
  zIndex?: number;
}

const defaultMultiPinStyleGetter = ({
  colorGetter = defaultColorGetter,
  effect = MarkerEffect.SHADOW,
  markerSize,
  shape,
  zIndex = PIN_ZINDEX,
}: StyleArguments): ((item: MultiPin) => google.maps.Data.StyleOptions) => {
  return (multiPin: MultiPin) => {
    const colorDef = colorGetter(multiPin);

    const markerShape = isFunction(shape) ? shape(multiPin) : shape;
    const markerEffect = isFunction(effect) ? effect(multiPin) : effect;
    const iconSvg = getSvgForIcon(markerShape, "multipin", colorDef.color);

    const { shapeSvg, shapeSize, shapeAnchor } = getSvgForShape(
      markerShape,
      colorDef.fillColor,
      colorDef.color
    );

    const shapeEffect = getSvgEffect(markerEffect, shapeSize);

    const { svg, size, anchor } = getResultingSvg(
      shapeSvg,
      shapeSize,
      shapeAnchor,
      shapeEffect,
      iconSvg
    );

    return {
      icon: {
        url: svgToDataUrl(svg),
        scaledSize: new google.maps.Size(...getScaledSizeByMarker(size, markerSize)),
        anchor: new google.maps.Point(...getScaledSizeByMarker(anchor, markerSize)),
      },
      zIndex,
    };
  };
};

export default defaultMultiPinStyleGetter;

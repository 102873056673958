import React, { FC } from "react";
import useMap from "component/map/FeaturedMap/utils/useMap";
import OverlayView from "component/map/FeaturedMap/essentials/OverlayView";
import { BASE_TOOLTIP_ZINDEX } from "util/map/consts";
import styles from "./MapTooltip.module.scss";

interface Props {
  position?: google.maps.LatLng | google.maps.LatLngLiteral;
  showArrow?: boolean;
  tooltip?: string;
}

const MapTooltip: FC<Props> = ({ position, showArrow = true, tooltip }) => {
  const { map } = useMap();

  const isVisible = map && position && tooltip;

  return isVisible ? (
    <OverlayView pane="markerLayer" position={position} map={map} zIndex={BASE_TOOLTIP_ZINDEX}>
      <div className={styles.container}>
        {showArrow && <div className={styles.arrow} />}
        <div className={styles.text}>{tooltip}</div>
      </div>
    </OverlayView>
  ) : null;
};

export default MapTooltip;

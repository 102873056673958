import React from "react";
import Row, { RowProps } from "antd/es/row";
import Col from "antd/es/col";
import cn from "classnames";
import styles from "./Labeled.module.scss";
import TestProps from "../../type/TestProps";

export interface LabeledFieldProps extends TestProps {
  children: React.ReactNode;
  className?: string;
  extra?: React.ReactNode;
  label?: React.ReactNode;
  labelClassName?: string;
  labelPosition?: "top" | "side";
  required?: boolean;
  rowProps?: RowProps;
  sideLabelSpan?: number;
}

const Labeled: React.FC<LabeledFieldProps> = ({
  children,
  className,
  extra,
  label,
  labelClassName,
  labelPosition = "top",
  required,
  rowProps,
  sideLabelSpan = 6,
}) => {
  if (labelPosition === "top") {
    return (
      <Col className={className}>
        <Row
          className={cn(styles.label, labelClassName)}
          justify="space-between"
        >
          <div className={cn({ [styles.required]: required })}>{label}</div>
          {extra && <span>{extra}</span>}
        </Row>
        <Row>{children}</Row>
      </Col>
    );
  }

  return (
    <Row className={className} {...rowProps}>
      <Col className={cn(styles.label, labelClassName)} span={sideLabelSpan}>
        <div className={cn({ [styles.required]: required })}>{label}</div>
        {extra && <span>{extra}</span>}
      </Col>
      <Col span={24 - sideLabelSpan}>{children}</Col>
    </Row>
  );
};

export default Labeled;

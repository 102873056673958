import MmcNotification from "types/MmcNotification";
import { formatDate, getRelativeMessagePrefix } from "../formatters";
import { IntlShape } from "react-intl";
import { parseApiDate } from "../parsers";

const getFormattedDate = (intl: IntlShape, notification: MmcNotification) => {
  const updatedAtAsDate = parseApiDate(notification.updatedAt);

  return intl.formatMessage(
    {
      defaultMessage: "{start} at {time}",
      description: "Util  - notification - date format",
      id: "util.notifications.date",
    },
    {
      start: formatDate(
        updatedAtAsDate,
        intl.formatMessage(getRelativeMessagePrefix(updatedAtAsDate))
      ),
      time: formatDate(updatedAtAsDate, "p"),
    }
  );
};

export default getFormattedDate;

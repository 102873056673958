import React from "react";
import { RegistryProps } from "component/FieldGrid/utils/ComponentRegistry";
import Tooltip from "antd/es/tooltip";
import Team from "@mapmycustomers/shared/types/Team";

const TeamField: React.FC<RegistryProps> = ({ entity, field }) => {
  const teams = (field.getValueFor(entity) as Team[]) ?? [];

  return (
    <div>
      <Tooltip title={field.getFormattedValueFor(entity)}>
        {teams.map((team) => (
          <span key={team.id}>{team.name}</span>
        ))}
      </Tooltip>
    </div>
  );
};

export default TeamField;

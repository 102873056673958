import React, { ReactNode } from "react";
import cn from "classnames";
import styles from "./SectionLayout.module.scss";

interface Props {
  children: ReactNode;
  className?: string;
  left: ReactNode;
}

const SectionLayout: React.FC<Props> = ({ children, className, left }) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.left}>{left}</div>
      <div className={styles.right}>{children}</div>
    </div>
  );
};

export default SectionLayout;

import Divider from "antd/es/divider";
import React, { useCallback, useMemo, useState } from "react";
import { showGlobalCreateActivityModal } from "component/createEditEntity/Activity/store/actions";
import Modal from "component/modal";
import RoundButton from "component/RoundButton";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import {
  Company,
  Deal,
  EntityType,
  EntityTypeSupportingPostCreationModal,
  Person,
} from "@mapmycustomers/shared/types/entity";
import styles from "./PostCreationModal.module.scss";
import { getEntityType, getPrefilledValues } from "./store";
import { showEntityView } from "store/entityView/actions";
import Button from "antd/es/button";
import usePostCreationModalState from "./util/usePostCreationModalState";
import PostCreationAssociationTypes from "./util/PostCreationAssociationTypes";
import messages from "./messages";
import PostCreationModalSelection from "./PostCreationModalSelection";

interface Props {
  entityType?: EntityTypeSupportingPostCreationModal;
  onCreateActivity: typeof showGlobalCreateActivityModal;
  onHide: () => void;
  prefilledValues?: Company | Person | Deal;
  showEntityView: typeof showEntityView;
}

const PostCreationModal: React.FC<Props> = ({
  entityType,
  onCreateActivity,
  onHide,
  prefilledValues,
  showEntityView,
}) => {
  const intl = useIntl();
  const [selectedModal, setSelectedModal] = useState<PostCreationAssociationTypes | undefined>(
    undefined
  );

  const handleHideModal = useCallback(
    (updated?: boolean) => {
      if (updated) {
        onHide();
      } else {
        setSelectedModal(undefined);
      }
    },
    [onHide, setSelectedModal]
  );

  const handleVisibility = useCallback(
    (type: PostCreationAssociationTypes) => {
      if (type === PostCreationAssociationTypes.ACTIVITY) {
        let activity = {
          startAt: new Date().toISOString(),
          ...(entityType === EntityType.COMPANY ? { account: prefilledValues as Company } : {}),
          ...(entityType === EntityType.PERSON ? { contact: prefilledValues as Person } : {}),
          ...(entityType === EntityType.DEAL ? { deal: prefilledValues as Deal } : {}),
        };
        activity && onCreateActivity({ activity });
      }
      setSelectedModal(type);
    },
    [entityType, onCreateActivity, prefilledValues, setSelectedModal]
  );

  const { imageSource, imageIllustration, cards } = usePostCreationModalState(
    intl,
    // EntityType do exist here as this modal will open only on some record creation of some entity type
    entityType!,
    prefilledValues!,
    handleVisibility
  );

  let createdRecord: Company | Person | Deal = useMemo(() => {
    if (entityType === EntityType.COMPANY) {
      return prefilledValues as Company;
    } else if (entityType === EntityType.PERSON) {
      return prefilledValues as Person;
    } else if (entityType === EntityType.DEAL) {
      return prefilledValues as Deal;
    } else return prefilledValues as Company;
  }, [entityType, prefilledValues]);

  const handleEdit = useCallback(() => {
    if (prefilledValues && entityType) {
      showEntityView({
        entityId: prefilledValues?.id,
        entityType: entityType,
      });
    }
  }, [prefilledValues, entityType, showEntityView]);

  return (
    <div>
      <Modal
        className={styles.modal}
        closable={false}
        footer={null}
        visible={true}
        width="clamp(750px, 50vw, 1024px)"
      >
        <>
          <section className={styles.top}>
            <img alt="background" className={styles.backgroundImage} src={imageSource} />
            <div>
              <h3>{intl.formatMessage(messages.title)}</h3>
              <div>
                {intl.formatMessage(messages.subTitle, {
                  recordname: prefilledValues?.name,
                  link: (text: string) => (
                    <Button className={styles.recordName} onClick={handleEdit} type="link">
                      {text}
                    </Button>
                  ),
                })}
              </div>
            </div>
            <div className={styles.image}>
              <img alt="No Data" src={imageIllustration} />
            </div>
          </section>
          <Divider className={styles.divider} />
          <div className={styles.cards}></div>
          <section>
            <h3>{intl.formatMessage(messages.associationTitle)}</h3>
            <div className={styles.cards}>
              {cards.map((card) => {
                return card;
              })}
            </div>
          </section>
          <section className={styles.footer}>
            <RoundButton className={styles.okButton} onClick={onHide}>
              {intl.formatMessage(messages.okText)}
            </RoundButton>
          </section>
        </>
      </Modal>
      {entityType && (
        <PostCreationModalSelection
          createdRecord={createdRecord}
          entityType={entityType}
          handleHideModal={handleHideModal}
          selectedModal={selectedModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  prefilledValues: getPrefilledValues(state),
  entityType: getEntityType(state),
});

const mapDispatchToProps = {
  onCreateActivity: showGlobalCreateActivityModal,
  showEntityView,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostCreationModal);

import { ActionType, createAsyncAction } from "typesafe-actions";
import Sandbox, { SandboxConfig } from "@mapmycustomers/shared/types/Sandbox";

export const createSandboxConfig = createAsyncAction(
  "settings/sandbox/create/request",
  "settings/sandbox/create/success",
  "settings/sandbox/create/failure"
)<{ name: string; callback?: () => void }, void, unknown>();

export const updateSandboxConfig = createAsyncAction(
  "settings/sandbox/update/request",
  "settings/sandbox/update/success",
  "settings/sandbox/update/failure"
)<SandboxConfig, void, unknown>();

export const deleteSandboxConfig = createAsyncAction(
  "settings/sandbox/delete/request",
  "settings/sandbox/delete/success",
  "settings/sandbox/delete/failure"
)<void, void, unknown>();

export const initSandboxPage = createAsyncAction(
  "settings/sandbox/init/request",
  "settings/sandbox/init/success",
  "settings/sandbox/init/failure"
)<void, { hasIntegration: boolean; sandbox: Sandbox | undefined }, unknown>();

export const fetchSandbox = createAsyncAction(
  "settings/sandbox/fetch/request",
  "settings/sandbox/fetch/success",
  "settings/sandbox/fetch/failure"
)<void, { sandbox: Sandbox | undefined }, unknown>();

export type Actions = ActionType<
  | typeof createSandboxConfig
  | typeof updateSandboxConfig
  | typeof deleteSandboxConfig
  | typeof fetchSandbox
  | typeof initSandboxPage
>;

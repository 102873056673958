import React, { memo, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Labeled, LabeledFieldProps } from "@mapmycustomers/ui";
import { NumberField } from "@mapmycustomers/ui";
import SelectField from "component/input/SelectField";
import { RootState } from "store/rootReducer";
import { getCurrencies } from "store/referenceData";
import Currency from "@mapmycustomers/shared/types/Currency";
import MonetaryValue from "@mapmycustomers/shared/types/customField/MonetaryValue";
import { isDefined } from "util/assert";
import styles from "./MonetaryInput.module.scss";

interface Props extends Omit<LabeledFieldProps, "children"> {
  currencies: Currency[];
  currencyId: Currency["id"];
  disabled?: boolean;
  onChange?: (value: MonetaryValue) => void;
  placeholder?: string;
  value?: MonetaryValue;
}

const MonetaryInput = memo<Props>(
  ({
    className,
    currencies,
    currencyId,
    disabled,
    onChange,
    placeholder,
    value,
    ...labeledProps
  }) => {
    const [amount, setAmount] = useState<number | undefined>(value?.value);
    useEffect(() => {
      setAmount(value?.value);
    }, [value?.currencyId, value?.value]);

    const handleChange = useCallback(
      (currencyId?: Currency["id"], amount?: number) => {
        if (isDefined(currencyId) && isDefined(amount) && onChange) {
          onChange({ currencyId, value: amount });
        }
      },
      [onChange]
    );

    const handleCurrencyChange = useCallback(
      (currencyId: Currency["id"]) => {
        handleChange(currencyId, amount);
      },
      [amount, handleChange]
    );

    const handleAmountChange = useCallback(
      (amount?: number) => {
        setAmount(amount);
        handleChange(currencyId, amount);
      },
      [currencyId, handleChange, setAmount]
    );

    const currencyOptions = useMemo(
      () => currencies.map(({ id, code }) => ({ label: code, value: id })),
      [currencies]
    );

    const handleFilterOption = useCallback((inputValue: string, option?: { label?: ReactNode }) => {
      return (
        (option?.label as string)?.toLowerCase().includes(inputValue.toLowerCase().trim()) ?? false
      );
    }, []);

    const MAX_MONEY_NUMERIC_LIMIT = 9223372036854775807; // platform limit

    return (
      <Labeled {...labeledProps}>
        <div className={styles.inputContainer}>
          <SelectField
            className={styles.currencyField}
            disabled
            dropdownMatchSelectWidth={false}
            filterOption={handleFilterOption}
            onChange={handleCurrencyChange}
            options={currencyOptions}
            showSearch
            value={currencyId}
          />

          <NumberField
            className={styles.amountContainer}
            disabled={disabled}
            inputClassName={styles.amountField}
            max={MAX_MONEY_NUMERIC_LIMIT}
            onChange={handleAmountChange}
            placeholder={placeholder}
            value={amount}
          />
        </div>
      </Labeled>
    );
  }
);

const mapStateToProps = (state: RootState) => ({
  currencies: getCurrencies(state),
});

export default connect(mapStateToProps)(MonetaryInput);

import { useMemo } from "react";
import memoize from "fast-memoize";

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

const usePlacePredictionsGetter = (
  types?: string[]
): [(place: string) => Promise<AutocompletePrediction[]>] => {
  const autoCompleteService = useMemo(() => new google.maps.places.AutocompleteService(), []);
  const getPredictions = useMemo(
    () =>
      memoize(
        (place: string) =>
          new Promise<AutocompletePrediction[]>((resolve) => {
            if (place && place.trim() !== "") {
              autoCompleteService.getPlacePredictions(
                { input: place, types: types ?? ["address"] },
                (result, status) => {
                  resolve(
                    status === google.maps.places.PlacesServiceStatus.OK && result
                      ? result
                      : ([] as AutocompletePrediction[])
                  );
                }
              );
            } else {
              resolve([] as AutocompletePrediction[]);
            }
          })
      ),
    [autoCompleteService, types]
  );

  return [getPredictions];
};

export default usePlacePredictionsGetter;

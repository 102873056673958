import React, { ReactNode, useCallback } from "react";
import { useIntl } from "react-intl";
import styles from "./FrequencyBlock.module.scss";
import FrequencyPreview from "../../../FrequencyPreview";
import { Activity } from "@mapmycustomers/shared/types/entity";
import FrequencyCollapsePanel from "../../../FrequencyCollapsePanel";
import { RootState } from "../../../../../store/rootReducer";
import { isFrequencyModalLoading } from "../../../../../store/frequency";
import { connect } from "react-redux";
import FrequencyEntity from "../../../type/FrequencyEntity";
import { useExtendedAnalytics } from "../../../../../util/analytic/AnalyticsService";
import Col from "antd/es/col";
import Row from "antd/es/row";
import FrequencyIcon from "../../../FrequencyIcon";
import layout from "../../../../../styles/layout";
import useToggleBooleanAfterTimeout from "../../../../../util/hook/useToggleBooleanAfterTimeout";
import useAnalytics from "../../../../../util/contexts/useAnalytics";

interface Props {
  entity: FrequencyEntity;
  header?: ReactNode;
  isGroup?: boolean;
  isOpenByDefault?: boolean;
  lastCompletedActivity?: Activity;
  loading: boolean;
  readonly?: boolean;
}

const FrequencyBlock: React.FC<Props> = ({
  entity,
  header,
  isGroup,
  isOpenByDefault,
  lastCompletedActivity,
  loading,
  readonly,
}) => {
  const intl = useIntl();
  const analytics = useExtendedAnalytics(["Active Frequencies", "Open Frequency"], useAnalytics());
  const showLoading = useToggleBooleanAfterTimeout(1000, false, loading, true);

  const handleOpenPanel = useCallback(() => {
    analytics.clicked([]);
  }, [analytics]);

  return (
    <div className={styles.container}>
      <FrequencyCollapsePanel
        className={styles.collapse}
        header={
          <Row align="middle" gutter={layout.spacerS}>
            <Col>
              <FrequencyIcon status={entity.cadenceStatus} size="tiny" />
            </Col>
            <Col>
              {intl.formatMessage(
                {
                  id: "frequency.frequencyModal.frequencies.groups.title",
                  defaultMessage: "{count} Day Frequency",
                  description: "frequency component - frequency modal - frequencies - title",
                },
                { count: entity.cadenceInterval ?? 0 }
              )}
            </Col>
          </Row>
        }
        isOpenByDefault={isOpenByDefault}
        onOpen={handleOpenPanel}
      >
        <FrequencyPreview
          entity={entity}
          header={header}
          isGroup={isGroup}
          loading={showLoading}
          lastCompletedActivity={lastCompletedActivity}
          readonly={readonly}
          saveButtonText={intl.formatMessage({
            id: "frequency.frequencyModal.frequencies.saveText",
            defaultMessage: "Save Changes",
            description: "frequency component - frequency modal - frequencies - save Text",
          })}
          showSaveButtonsOnlyAfterChanges
        />
      </FrequencyCollapsePanel>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: isFrequencyModalLoading(state),
});

export default connect(mapStateToProps)(FrequencyBlock);

import React, { useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";
import Form from "antd/es/form";
import { FormInstance } from "antd/es/form/hooks/useForm";
import Menu from "antd/es/menu";
import { MenuInfo } from "rc-menu/lib/interface";
import ActivityRecurringUnit from "@mapmycustomers/shared/enum/activity/ActivityRecurringUnit";
import RecurringIntervalType from "../../enum/RecurringIntervalType";
import FormValues from "../../types/FormValues";
import isAnnualInterval from "../../utils/isAnnualInterval";
import { parseActivityInterval } from "../../utils/activityInterval";
import { formatDate } from "util/formatters";
import styles from "./ActivityRepeatMenu.module.scss";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";

interface Props {
  form: FormInstance<FormValues>;
  onChange?: (changed: Partial<FormValues>) => void;
}

const messages = defineMessages({
  annually: {
    id: "createActivityModal.footer.scheduleActivity.repeat.annually",
    defaultMessage: "Annually on {date}",
    description: "Annually",
  },
  [RecurringIntervalType.CUSTOM]: {
    id: "createActivityModal.footer.scheduleActivity.repeat.custom",
    defaultMessage: "Custom...",
    description: "Custom",
  },
  [RecurringIntervalType.DAILY]: {
    id: "createActivityModal.footer.scheduleActivity.repeat.daily",
    defaultMessage: "Daily (Weekdays Only)",
    description: "Daily (Weekdays Only)",
  },
  [RecurringIntervalType.NEVER]: {
    id: "createActivityModal.footer.scheduleActivity.repeat.doesNotRepeat",
    defaultMessage: "Does not repeat",
    description: "Does not repeat",
  },
  [RecurringIntervalType.MONTHLY]: {
    id: "createActivityModal.footer.scheduleActivity.repeat.monthly",
    defaultMessage: "Monthly on {date}",
    description: "Monthly",
  },
  [RecurringIntervalType.WEEKLY]: {
    id: "createActivityModal.footer.scheduleActivity.repeat.weekly",
    defaultMessage: "Weekly on {date}",
    description: "Weekly",
  },
});

const ActivityRepeatMenu: React.FC<Props> = ({ form, onChange }) => {
  const intl = useIntl();

  const handleClick = useCallback(
    (item: MenuInfo) => {
      const intervalType = item.key as RecurringIntervalType;
      const formRecurInterval: FormValues["recurInterval"] = form.getFieldValue("recurInterval");

      let change = {};

      if (item.key === "annually") {
        change = {
          isRecurrent: true,
          recurInterval: {
            value: 12,
            unit: ActivityRecurringUnit.MONTH,
          },
          recurIntervalType: RecurringIntervalType.CUSTOM,
        };
      } else if (intervalType === RecurringIntervalType.CUSTOM) {
        change = {
          isRecurrent: true,
          recurInterval: {
            value: formRecurInterval?.value ?? 1,
            unit: formRecurInterval?.unit ?? ActivityRecurringUnit.MONTH,
          },
          recurIntervalType: intervalType,
        };
      } else if (intervalType === RecurringIntervalType.NEVER) {
        change = {
          isRecurrent: false,
          recurInterval: undefined,
          recurIntervalType: intervalType,
        };
      } else {
        const parsed = parseActivityInterval(intervalType);
        if (parsed) {
          const recurInterval = {
            ...parsed,
            ...(intervalType === RecurringIntervalType.DAILY ? { excludeWeekends: true } : {}),
          };

          change = {
            isRecurrent: true,
            recurInterval,
            recurIntervalType: intervalType,
          };
        }
      }

      form.setFieldsValue(change);

      onChange?.(change);
    },
    [form, onChange]
  );

  const dateValue = form.getFieldValue(ActivityFieldName.START_AT);
  const isValidDate = dateValue && Date.parse(dateValue.toString());

  if (!isValidDate) {
    return null;
  }

  const dateAnnually = formatDate(dateValue, "MMMM, do");
  const dateMonthly = formatDate(dateValue, "do");
  const dateWeekly = formatDate(dateValue, "EEEE");

  return (
    <Form.Item
      shouldUpdate={({ isRecurrent, recurIntervalType, recurInterval }, curValues) =>
        isRecurrent !== curValues.isRecurrent ||
        recurIntervalType !== curValues.recurIntervalType ||
        recurInterval?.value !== curValues.recurInterval?.value ||
        recurInterval?.unit !== curValues.recurInterval?.unit ||
        recurInterval?.excludeWeekends !== curValues.recurInterval?.excludeWeekends
      }
      noStyle
    >
      {({ getFieldValue }) => {
        const recurIntervalType = getFieldValue("recurIntervalType");
        const recurInterval = getFieldValue("recurInterval");
        const isAnnual = isAnnualInterval({
          recurInterval,
          recurIntervalType,
        });

        const selectedKeys = isAnnual
          ? ["annually"]
          : recurIntervalType
          ? [recurIntervalType]
          : undefined;

        return (
          <Menu className={styles.menu} onClick={handleClick} selectedKeys={selectedKeys}>
            <Menu.Item key={RecurringIntervalType.CUSTOM}>
              {intl.formatMessage(messages[RecurringIntervalType.CUSTOM])}
            </Menu.Item>
            <Menu.Item key="annually">
              {intl.formatMessage(messages.annually, { date: dateAnnually })}
            </Menu.Item>
            <Menu.Item key={RecurringIntervalType.MONTHLY}>
              {intl.formatMessage(messages[RecurringIntervalType.MONTHLY], { date: dateMonthly })}
            </Menu.Item>
            <Menu.Item key={RecurringIntervalType.WEEKLY}>
              {intl.formatMessage(messages[RecurringIntervalType.WEEKLY], { date: dateWeekly })}
            </Menu.Item>
            <Menu.Item key={RecurringIntervalType.DAILY}>
              {intl.formatMessage(messages[RecurringIntervalType.DAILY])}
            </Menu.Item>
            <Menu.Item key={RecurringIntervalType.NEVER}>
              {intl.formatMessage(messages[RecurringIntervalType.NEVER])}
            </Menu.Item>
          </Menu>
        );
      }}
    </Form.Item>
  );
};

export default ActivityRepeatMenu;

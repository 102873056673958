@import "./colors";

// Need to export from other file because of this CRA 4 issue:
// https://github.com/facebook/create-react-app/issues/10047
:export {
  primary100: $mmc-primary-100;
  primary075: $mmc-primary-075;
  primary050: $mmc-primary-050;
  primary025: $mmc-primary-025;
  primary010: $mmc-primary-010;
  primary005: $mmc-primary-005;
  red100: $mmc-red-100;
  red075: $mmc-red-075;
  red050: $mmc-red-050;
  red025: $mmc-red-025;
  red010: $mmc-red-010;
  yellow100: $mmc-yellow-100;
  yellow075: $mmc-yellow-075;
  yellow050: $mmc-yellow-050;
  yellow025: $mmc-yellow-025;
  yellow010: $mmc-yellow-010;
  green100: $mmc-green-100;
  green075: $mmc-green-075;
  green050: $mmc-green-050;
  green025: $mmc-green-025;
  green010: $mmc-green-010;
  gold100: $mmc-gold-100;
  silver100: $mmc-silver-100;
  bronze100: $mmc-bronze-100;
  softSlate: $mmc-soft-slate;
  accentGray: $mmc-accent-gray;
  disabledGray: $mmc-disabled-gray;
  gray: $mmc-gray;
  slate: $mmc-slate;
  softGray: $mmc-soft-gray;
  white: $mmc-white;
}

import { applyMiddleware, createStore, Store } from "redux";
import createSagaMiddleware, { END } from "redux-saga";
import Axios from "axios";
import qs from "query-string";
import createRootReducer, { RootState } from "./rootReducer";
import { rootSaga } from "./rootSaga";
import { routerMiddleware } from "connected-react-router";
import ApiService from "./api/ApiService";
import { setApi, setReadonlyApi } from "./api/actions";
import { History } from "history";
import { changeLocale } from "./locale/actions";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import { changeTheme } from "./theme/actions";
import localSettings from "../config/LocalSettings";
import configService from "../config/ConfigService";
import Environment from "../enum/Environment";

const apiHttpClient = Axios.create({
  baseURL: configService.getBaseApiUrl(),
  timeout: configService.getDefaultApiTimeout(),
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "none" }),
});

const readonlyApiHttpClient = Axios.create({
  baseURL: configService.getBaseReadonlyApiUrl(),
  timeout: configService.getDefaultApiTimeout(),
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "none" }),
});

const createApiService = (ApiServiceClass: typeof ApiService) => {
  return new ApiServiceClass(apiHttpClient);
};

const createReadonlyApiService = (ApiServiceClass: typeof ApiService) => {
  return new ApiServiceClass(readonlyApiHttpClient);
};

// @ts-ignore
const logger = (store) => (next) => (action) => {
  console.group(action.type);
  console.log("dispatching", action);
  let result: any = next(action);
  console.log("next state", store.getState());
  console.groupEnd();
  return result;
};

const configureReduxStore = (history: History): Store<RootState> => {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers = composeWithDevTools({
    trace:
      !!process.env.REACT_APP_REDUX_TRACE &&
      process.env.REACT_APP_ENVIRONMENT !== Environment.PRODUCTION,
    traceLimit: 30, // default 10 is sometimes not enough
  });

  const store = createStore(
    createRootReducer(history),
    undefined,
    composeEnhancers(
      process.env.REACT_APP_REDUX_USE_CONSOLE_LOGGER
        ? applyMiddleware(logger, sagaMiddleware, routerMiddleware(history))
        : applyMiddleware(sagaMiddleware, routerMiddleware(history))
    )
  );

  sagaMiddleware.run(rootSaga);
  store.dispatch(setApi(createApiService(ApiService)));
  store.dispatch(setReadonlyApi(createReadonlyApiService(ApiService)));

  // Switch locale and theme. We read values from LocalSettings so that user can have a language and
  // a theme of his preference even before he signs in.
  store.dispatch(changeLocale.request(localSettings.getLocale())); // update locale
  store.dispatch(changeTheme.request(localSettings.getTheme())); // update theme

  if (module.hot) {
    module.hot.accept("./rootReducer", () => {
      store.replaceReducer(require("./rootReducer").default(history));
    });
    module.hot.accept("./rootSaga", () => {
      store.dispatch(END);
      sagaMiddleware.run(require("./rootSaga").default);
    });
    module.hot.accept("./api/ApiService", () => {
      store.dispatch(setApi(createApiService(require("./api/ApiService").default)));
      store.dispatch(setReadonlyApi(createReadonlyApiService(require("./api/ApiService").default)));
    });
  }

  return store;
};

export type AppDispatch = ReturnType<typeof configureReduxStore>["dispatch"];
export default configureReduxStore;

import React, { useMemo } from "react";
import EntityIncompleteAddressWarning from "component/EntityIncompleteAddressWarning";
import {
  EntitySupportingCard,
  EntityType,
  EntityWithLocation,
} from "@mapmycustomers/shared/types/entity";
import { getFormattedAddressForUi } from "util/formatters";
import locatedToAddress from "util/locatedToAddress";
import { PopoverProps } from "antd/es/popover";
import Located from "@mapmycustomers/shared/types/base/Located";
import styles from "./EntityAddress.module.scss";
import EntityAddressIssueWarning from "component/EntityAddressIssue";

interface Props {
  entity: EntitySupportingCard;
  popoverPlacement?: PopoverProps["placement"];
  showWarning?: boolean;
}

const EntityAddress: React.FC<Props> = ({ entity, popoverPlacement, showWarning = true }) => {
  const address = useMemo(() => {
    const isLocated = [EntityType.COMPANY, EntityType.PERSON].includes(entity.entity);
    if (!isLocated) {
      return;
    }

    let address = getFormattedAddressForUi(locatedToAddress(entity as Located));

    if (!address && entity.geoPoint && Array.isArray(entity.geoPoint.coordinates)) {
      address = entity.geoPoint.coordinates.join(", ");
    }

    return address;
  }, [entity]);

  const locatedEntity = entity as EntityWithLocation;

  return address ? (
    <div className={styles.address}>
      {address}
      {showWarning && locatedEntity.showInvalidAddressBanner && (
        <EntityIncompleteAddressWarning entity={locatedEntity} placement={popoverPlacement} />
      )}
      {showWarning && locatedEntity.showInvalidGeoAddressBanner && (
        <EntityAddressIssueWarning placement={popoverPlacement} />
      )}
    </div>
  ) : null;
};

export default EntityAddress;

import React, { ReactNode, useCallback } from "react";
import Space from "antd/es/space";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalProps } from "antd/es/modal";
import Modal from "component/modal/index";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styles from "./BaseModal.module.scss";
import cn from "classnames";
import Popconfirm from "antd/es/popconfirm";
import { useIntl } from "react-intl";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { stopEvents } from "util/browser";
import { extendNavbarAnalyticWithMenuKey } from "util/analytic/navbarAnalytics";

const getEntityKeyName: Record<string, string> = {
  Company: "addCompany",
  Person: "addPerson",
  Deal: "addDeal",
  Activity: "addActivity",
};

const getEntityEventName: Record<string, string> = {
  Company: "Create Company",
  Person: "Create Person",
  Deal: "Create Deal",
  Activity: "Create Activity",
};

interface Props extends ModalProps {
  borderedHeader?: boolean;
  confirmationText?: ReactNode;
  entityTypeName?: string;
  noContentScroll?: boolean;
  showConfirm?: boolean;
  subtitle?: ReactNode;
  title?: ReactNode;
  titleIcon?: IconProp;
}

const BaseModal: React.FC<Props> = ({
  borderedHeader,
  children,
  className,
  confirmationText,
  entityTypeName,
  noContentScroll,
  showConfirm,
  subtitle,
  title,
  titleIcon,
  ...props
}) => {
  const intl = useIntl();
  const confirmationMessage =
    confirmationText ??
    intl.formatMessage(
      {
        id: "createEditEntity.baseModal.close.popconfirm.title",
        defaultMessage: "Are you sure you want to {br}discard this record without {br}saving?",
        description: "Confirmation text when closing Base Modal",
      },
      {
        br: <br />,
      }
    );

  const handlePopupCancelAction = useCallback(
    (e) => {
      stopEvents(e);
      entityTypeName &&
        extendNavbarAnalyticWithMenuKey(getEntityKeyName[entityTypeName]).clicked([
          getEntityEventName[entityTypeName],
          "Discard Pop Up",
          "Cancel",
        ]);
    },
    [entityTypeName]
  );

  const handlePopupDiscardAction = useCallback(
    (e) => {
      e && props.onCancel?.(e);
      entityTypeName &&
        extendNavbarAnalyticWithMenuKey(getEntityKeyName[entityTypeName]).clicked([
          getEntityEventName[entityTypeName],
          "Discard Pop Up",
          "Discard",
        ]);
    },
    [entityTypeName, props]
  );

  return (
    <Modal
      cancelButtonProps={{ hidden: true }}
      centered
      className={cn(
        styles.container,
        { [styles.bordered]: borderedHeader, [styles.noScroll]: noContentScroll },
        className
      )}
      closeIcon={
        <p onClick={showConfirm ? stopEvents : undefined}>
          <Popconfirm
            cancelText="Cancel"
            disabled={!showConfirm}
            okText="Discard"
            onCancel={handlePopupCancelAction}
            onConfirm={handlePopupDiscardAction}
            placement="rightTop"
            title={confirmationMessage}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Popconfirm>
        </p>
      }
      maskClosable={false}
      open
      title={
        <div className={styles.header}>
          <Space>
            {titleIcon && <FontAwesomeIcon icon={titleIcon} />}

            <div className={styles.title}>{title}</div>
          </Space>

          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
      }
      width="clamp(100vw, 100vw, 750px)"
      {...props}
    >
      <section className={styles.content}>{children}</section>
    </Modal>
  );
};

export default BaseModal;

import { defineMessages, IntlShape } from "react-intl";
import CountryCode from "@mapmycustomers/shared/enum/CountryCode";

const messages = defineMessages({
  [CountryCode["AFGHANISTAN"]]: {
    id: "countryName.af",
    description: "Country name of Afghanistan",
    defaultMessage: "Afghanistan",
  },
  [CountryCode["ÅLAND ISLANDS"]]: {
    id: "countryName.ax",
    description: "Country name of Åland Islands",
    defaultMessage: "Åland Islands",
  },
  [CountryCode["ALBANIA"]]: {
    id: "countryName.al",
    description: "Country name of Albania",
    defaultMessage: "Albania",
  },
  [CountryCode["ALGERIA"]]: {
    id: "countryName.dz",
    description: "Country name of Algeria",
    defaultMessage: "Algeria",
  },
  [CountryCode["AMERICAN SAMOA"]]: {
    id: "countryName.as",
    description: "Country name of American Samoa",
    defaultMessage: "American Samoa",
  },
  [CountryCode["ANDORRA"]]: {
    id: "countryName.ad",
    description: "Country name of Andorra",
    defaultMessage: "Andorra",
  },
  [CountryCode["ANGOLA"]]: {
    id: "countryName.ao",
    description: "Country name of Angola",
    defaultMessage: "Angola",
  },
  [CountryCode["ANGUILLA"]]: {
    id: "countryName.ai",
    description: "Country name of Anguilla",
    defaultMessage: "Anguilla",
  },
  [CountryCode["ANTARCTICA"]]: {
    id: "countryName.aq",
    description: "Country name of Antarctica",
    defaultMessage: "Antarctica",
  },
  [CountryCode["ANTIGUA AND BARBUDA"]]: {
    id: "countryName.ag",
    description: "Country name of Antigua and Barbuda",
    defaultMessage: "Antigua and Barbuda",
  },
  [CountryCode["ARGENTINA"]]: {
    id: "countryName.ar",
    description: "Country name of Argentina",
    defaultMessage: "Argentina",
  },
  [CountryCode["ARMENIA"]]: {
    id: "countryName.am",
    description: "Country name of Armenia",
    defaultMessage: "Armenia",
  },
  [CountryCode["ARUBA"]]: {
    id: "countryName.aw",
    description: "Country name of Aruba",
    defaultMessage: "Aruba",
  },
  [CountryCode["AUSTRALIA"]]: {
    id: "countryName.au",
    description: "Country name of Australia",
    defaultMessage: "Australia",
  },
  [CountryCode["AUSTRIA"]]: {
    id: "countryName.at",
    description: "Country name of Austria",
    defaultMessage: "Austria",
  },
  [CountryCode["AZERBAIJAN"]]: {
    id: "countryName.az",
    description: "Country name of Azerbaijan",
    defaultMessage: "Azerbaijan",
  },
  [CountryCode["BAHAMAS"]]: {
    id: "countryName.bs",
    description: "Country name of Bahamas",
    defaultMessage: "Bahamas",
  },
  [CountryCode["BAHRAIN"]]: {
    id: "countryName.bh",
    description: "Country name of Bahrain",
    defaultMessage: "Bahrain",
  },
  [CountryCode["BANGLADESH"]]: {
    id: "countryName.bd",
    description: "Country name of Bangladesh",
    defaultMessage: "Bangladesh",
  },
  [CountryCode["BARBADOS"]]: {
    id: "countryName.bb",
    description: "Country name of Barbados",
    defaultMessage: "Barbados",
  },
  [CountryCode["BELARUS"]]: {
    id: "countryName.by",
    description: "Country name of Belarus",
    defaultMessage: "Belarus",
  },
  [CountryCode["BELGIUM"]]: {
    id: "countryName.be",
    description: "Country name of Belgium",
    defaultMessage: "Belgium",
  },
  [CountryCode["BELIZE"]]: {
    id: "countryName.bz",
    description: "Country name of Belize",
    defaultMessage: "Belize",
  },
  [CountryCode["BENIN"]]: {
    id: "countryName.bj",
    description: "Country name of Benin",
    defaultMessage: "Benin",
  },
  [CountryCode["BERMUDA"]]: {
    id: "countryName.bm",
    description: "Country name of Bermuda",
    defaultMessage: "Bermuda",
  },
  [CountryCode["BHUTAN"]]: {
    id: "countryName.bt",
    description: "Country name of Bhutan",
    defaultMessage: "Bhutan",
  },
  [CountryCode["BOLIVIA (PLURINATIONAL STATE OF)"]]: {
    id: "countryName.bo",
    description: "Country name of Bolivia (Plurinational State of)",
    defaultMessage: "Bolivia (Plurinational State of)",
  },
  [CountryCode["BONAIRE, SINT EUSTATIUS AND SABA"]]: {
    id: "countryName.bq",
    description: "Country name of Bonaire, Sint Eustatius and Saba",
    defaultMessage: "Bonaire, Sint Eustatius and Saba",
  },
  [CountryCode["BOSNIA AND HERZEGOVINA"]]: {
    id: "countryName.ba",
    description: "Country name of Bosnia and Herzegovina",
    defaultMessage: "Bosnia and Herzegovina",
  },
  [CountryCode["BOTSWANA"]]: {
    id: "countryName.bw",
    description: "Country name of Botswana",
    defaultMessage: "Botswana",
  },
  [CountryCode["BOUVET ISLAND"]]: {
    id: "countryName.bv",
    description: "Country name of Bouvet Island",
    defaultMessage: "Bouvet Island",
  },
  [CountryCode["BRAZIL"]]: {
    id: "countryName.br",
    description: "Country name of Brazil",
    defaultMessage: "Brazil",
  },
  [CountryCode["BRITISH INDIAN OCEAN TERRITORY"]]: {
    id: "countryName.io",
    description: "Country name of British Indian Ocean Territory",
    defaultMessage: "British Indian Ocean Territory",
  },
  [CountryCode["BRUNEI DARUSSALAM"]]: {
    id: "countryName.bn",
    description: "Country name of Brunei Darussalam",
    defaultMessage: "Brunei Darussalam",
  },
  [CountryCode["BULGARIA"]]: {
    id: "countryName.bg",
    description: "Country name of Bulgaria",
    defaultMessage: "Bulgaria",
  },
  [CountryCode["BURKINA FASO"]]: {
    id: "countryName.bf",
    description: "Country name of Burkina Faso",
    defaultMessage: "Burkina Faso",
  },
  [CountryCode["BURUNDI"]]: {
    id: "countryName.bi",
    description: "Country name of Burundi",
    defaultMessage: "Burundi",
  },
  [CountryCode["CABO VERDE"]]: {
    id: "countryName.cv",
    description: "Country name of Cabo Verde",
    defaultMessage: "Cabo Verde",
  },
  [CountryCode["CAMBODIA"]]: {
    id: "countryName.kh",
    description: "Country name of Cambodia",
    defaultMessage: "Cambodia",
  },
  [CountryCode["CAMEROON"]]: {
    id: "countryName.cm",
    description: "Country name of Cameroon",
    defaultMessage: "Cameroon",
  },
  [CountryCode["CANADA"]]: {
    id: "countryName.ca",
    description: "Country name of Canada",
    defaultMessage: "Canada",
  },
  [CountryCode["CAYMAN ISLANDS"]]: {
    id: "countryName.ky",
    description: "Country name of Cayman Islands",
    defaultMessage: "Cayman Islands",
  },
  [CountryCode["CENTRAL AFRICAN REPUBLIC"]]: {
    id: "countryName.cf",
    description: "Country name of Central African Republic",
    defaultMessage: "Central African Republic",
  },
  [CountryCode["CHAD"]]: {
    id: "countryName.td",
    description: "Country name of Chad",
    defaultMessage: "Chad",
  },
  [CountryCode["CHILE"]]: {
    id: "countryName.cl",
    description: "Country name of Chile",
    defaultMessage: "Chile",
  },
  [CountryCode["CHINA"]]: {
    id: "countryName.cn",
    description: "Country name of China",
    defaultMessage: "China",
  },
  [CountryCode["CHRISTMAS ISLAND"]]: {
    id: "countryName.cx",
    description: "Country name of Christmas Island",
    defaultMessage: "Christmas Island",
  },
  [CountryCode["COCOS (KEELING) ISLANDS"]]: {
    id: "countryName.cc",
    description: "Country name of Cocos (Keeling) Islands",
    defaultMessage: "Cocos (Keeling) Islands",
  },
  [CountryCode["COLOMBIA"]]: {
    id: "countryName.co",
    description: "Country name of Colombia",
    defaultMessage: "Colombia",
  },
  [CountryCode["COMOROS"]]: {
    id: "countryName.km",
    description: "Country name of Comoros",
    defaultMessage: "Comoros",
  },
  [CountryCode["CONGO"]]: {
    id: "countryName.cg",
    description: "Country name of Congo",
    defaultMessage: "Congo",
  },
  [CountryCode["CONGO, DEMOCRATIC REPUBLIC OF THE"]]: {
    id: "countryName.cd",
    description: "Country name of Congo, Democratic Republic of the",
    defaultMessage: "Congo, Democratic Republic of the",
  },
  [CountryCode["COOK ISLANDS"]]: {
    id: "countryName.ck",
    description: "Country name of Cook Islands",
    defaultMessage: "Cook Islands",
  },
  [CountryCode["COSTA RICA"]]: {
    id: "countryName.cr",
    description: "Country name of Costa Rica",
    defaultMessage: "Costa Rica",
  },
  [CountryCode["CÔTE D'IVOIRE"]]: {
    id: "countryName.ci",
    description: "Country name of Côte d'Ivoire",
    defaultMessage: "Côte d'Ivoire",
  },
  [CountryCode["CROATIA"]]: {
    id: "countryName.hr",
    description: "Country name of Croatia",
    defaultMessage: "Croatia",
  },
  [CountryCode["CUBA"]]: {
    id: "countryName.cu",
    description: "Country name of Cuba",
    defaultMessage: "Cuba",
  },
  [CountryCode["CURAÇAO"]]: {
    id: "countryName.cw",
    description: "Country name of Curaçao",
    defaultMessage: "Curaçao",
  },
  [CountryCode["CYPRUS"]]: {
    id: "countryName.cy",
    description: "Country name of Cyprus",
    defaultMessage: "Cyprus",
  },
  [CountryCode["CZECHIA"]]: {
    id: "countryName.cz",
    description: "Country name of Czechia",
    defaultMessage: "Czechia",
  },
  [CountryCode["DENMARK"]]: {
    id: "countryName.dk",
    description: "Country name of Denmark",
    defaultMessage: "Denmark",
  },
  [CountryCode["DJIBOUTI"]]: {
    id: "countryName.dj",
    description: "Country name of Djibouti",
    defaultMessage: "Djibouti",
  },
  [CountryCode["DOMINICA"]]: {
    id: "countryName.dm",
    description: "Country name of Dominica",
    defaultMessage: "Dominica",
  },
  [CountryCode["DOMINICAN REPUBLIC"]]: {
    id: "countryName.do",
    description: "Country name of Dominican Republic",
    defaultMessage: "Dominican Republic",
  },
  [CountryCode["ECUADOR"]]: {
    id: "countryName.ec",
    description: "Country name of Ecuador",
    defaultMessage: "Ecuador",
  },
  [CountryCode["EGYPT"]]: {
    id: "countryName.eg",
    description: "Country name of Egypt",
    defaultMessage: "Egypt",
  },
  [CountryCode["EL SALVADOR"]]: {
    id: "countryName.sv",
    description: "Country name of El Salvador",
    defaultMessage: "El Salvador",
  },
  [CountryCode["EQUATORIAL GUINEA"]]: {
    id: "countryName.gq",
    description: "Country name of Equatorial Guinea",
    defaultMessage: "Equatorial Guinea",
  },
  [CountryCode["ERITREA"]]: {
    id: "countryName.er",
    description: "Country name of Eritrea",
    defaultMessage: "Eritrea",
  },
  [CountryCode["ESTONIA"]]: {
    id: "countryName.ee",
    description: "Country name of Estonia",
    defaultMessage: "Estonia",
  },
  [CountryCode["ESWATINI"]]: {
    id: "countryName.sz",
    description: "Country name of Eswatini",
    defaultMessage: "Eswatini",
  },
  [CountryCode["ETHIOPIA"]]: {
    id: "countryName.et",
    description: "Country name of Ethiopia",
    defaultMessage: "Ethiopia",
  },
  [CountryCode["FALKLAND ISLANDS (MALVINAS)"]]: {
    id: "countryName.fk",
    description: "Country name of Falkland Islands (Malvinas)",
    defaultMessage: "Falkland Islands (Malvinas)",
  },
  [CountryCode["FAROE ISLANDS"]]: {
    id: "countryName.fo",
    description: "Country name of Faroe Islands",
    defaultMessage: "Faroe Islands",
  },
  [CountryCode["FIJI"]]: {
    id: "countryName.fj",
    description: "Country name of Fiji",
    defaultMessage: "Fiji",
  },
  [CountryCode["FINLAND"]]: {
    id: "countryName.fi",
    description: "Country name of Finland",
    defaultMessage: "Finland",
  },
  [CountryCode["FRANCE"]]: {
    id: "countryName.fr",
    description: "Country name of France",
    defaultMessage: "France",
  },
  [CountryCode["FRENCH GUIANA"]]: {
    id: "countryName.gf",
    description: "Country name of French Guiana",
    defaultMessage: "French Guiana",
  },
  [CountryCode["FRENCH POLYNESIA"]]: {
    id: "countryName.pf",
    description: "Country name of French Polynesia",
    defaultMessage: "French Polynesia",
  },
  [CountryCode["FRENCH SOUTHERN TERRITORIES"]]: {
    id: "countryName.tf",
    description: "Country name of French Southern Territories",
    defaultMessage: "French Southern Territories",
  },
  [CountryCode["GABON"]]: {
    id: "countryName.ga",
    description: "Country name of Gabon",
    defaultMessage: "Gabon",
  },
  [CountryCode["GAMBIA"]]: {
    id: "countryName.gm",
    description: "Country name of Gambia",
    defaultMessage: "Gambia",
  },
  [CountryCode["GEORGIA"]]: {
    id: "countryName.ge",
    description: "Country name of Georgia",
    defaultMessage: "Georgia",
  },
  [CountryCode["GERMANY"]]: {
    id: "countryName.de",
    description: "Country name of Germany",
    defaultMessage: "Germany",
  },
  [CountryCode["GHANA"]]: {
    id: "countryName.gh",
    description: "Country name of Ghana",
    defaultMessage: "Ghana",
  },
  [CountryCode["GIBRALTAR"]]: {
    id: "countryName.gi",
    description: "Country name of Gibraltar",
    defaultMessage: "Gibraltar",
  },
  [CountryCode["GREECE"]]: {
    id: "countryName.gr",
    description: "Country name of Greece",
    defaultMessage: "Greece",
  },
  [CountryCode["GREENLAND"]]: {
    id: "countryName.gl",
    description: "Country name of Greenland",
    defaultMessage: "Greenland",
  },
  [CountryCode["GRENADA"]]: {
    id: "countryName.gd",
    description: "Country name of Grenada",
    defaultMessage: "Grenada",
  },
  [CountryCode["GUADELOUPE"]]: {
    id: "countryName.gp",
    description: "Country name of Guadeloupe",
    defaultMessage: "Guadeloupe",
  },
  [CountryCode["GUAM"]]: {
    id: "countryName.gu",
    description: "Country name of Guam",
    defaultMessage: "Guam",
  },
  [CountryCode["GUATEMALA"]]: {
    id: "countryName.gt",
    description: "Country name of Guatemala",
    defaultMessage: "Guatemala",
  },
  [CountryCode["GUERNSEY"]]: {
    id: "countryName.gg",
    description: "Country name of Guernsey",
    defaultMessage: "Guernsey",
  },
  [CountryCode["GUINEA"]]: {
    id: "countryName.gn",
    description: "Country name of Guinea",
    defaultMessage: "Guinea",
  },
  [CountryCode["GUINEA-BISSAU"]]: {
    id: "countryName.gw",
    description: "Country name of Guinea-Bissau",
    defaultMessage: "Guinea-Bissau",
  },
  [CountryCode["GUYANA"]]: {
    id: "countryName.gy",
    description: "Country name of Guyana",
    defaultMessage: "Guyana",
  },
  [CountryCode["HAITI"]]: {
    id: "countryName.ht",
    description: "Country name of Haiti",
    defaultMessage: "Haiti",
  },
  [CountryCode["HEARD ISLAND AND MCDONALD ISLANDS"]]: {
    id: "countryName.hm",
    description: "Country name of Heard Island and McDonald Islands",
    defaultMessage: "Heard Island and McDonald Islands",
  },
  [CountryCode["HOLY SEE"]]: {
    id: "countryName.va",
    description: "Country name of Holy See",
    defaultMessage: "Holy See",
  },
  [CountryCode["HONDURAS"]]: {
    id: "countryName.hn",
    description: "Country name of Honduras",
    defaultMessage: "Honduras",
  },
  [CountryCode["HONG KONG"]]: {
    id: "countryName.hk",
    description: "Country name of Hong Kong",
    defaultMessage: "Hong Kong",
  },
  [CountryCode["HUNGARY"]]: {
    id: "countryName.hu",
    description: "Country name of Hungary",
    defaultMessage: "Hungary",
  },
  [CountryCode["ICELAND"]]: {
    id: "countryName.is",
    description: "Country name of Iceland",
    defaultMessage: "Iceland",
  },
  [CountryCode["INDIA"]]: {
    id: "countryName.in",
    description: "Country name of India",
    defaultMessage: "India",
  },
  [CountryCode["INDONESIA"]]: {
    id: "countryName.id",
    description: "Country name of Indonesia",
    defaultMessage: "Indonesia",
  },
  [CountryCode["IRAN (ISLAMIC REPUBLIC OF)"]]: {
    id: "countryName.ir",
    description: "Country name of Iran (Islamic Republic of)",
    defaultMessage: "Iran (Islamic Republic of)",
  },
  [CountryCode["IRAQ"]]: {
    id: "countryName.iq",
    description: "Country name of Iraq",
    defaultMessage: "Iraq",
  },
  [CountryCode["IRELAND"]]: {
    id: "countryName.ie",
    description: "Country name of Ireland",
    defaultMessage: "Ireland",
  },
  [CountryCode["ISLE OF MAN"]]: {
    id: "countryName.im",
    description: "Country name of Isle of Man",
    defaultMessage: "Isle of Man",
  },
  [CountryCode["ISRAEL"]]: {
    id: "countryName.il",
    description: "Country name of Israel",
    defaultMessage: "Israel",
  },
  [CountryCode["ITALY"]]: {
    id: "countryName.it",
    description: "Country name of Italy",
    defaultMessage: "Italy",
  },
  [CountryCode["JAMAICA"]]: {
    id: "countryName.jm",
    description: "Country name of Jamaica",
    defaultMessage: "Jamaica",
  },
  [CountryCode["JAPAN"]]: {
    id: "countryName.jp",
    description: "Country name of Japan",
    defaultMessage: "Japan",
  },
  [CountryCode["JERSEY"]]: {
    id: "countryName.je",
    description: "Country name of Jersey",
    defaultMessage: "Jersey",
  },
  [CountryCode["JORDAN"]]: {
    id: "countryName.jo",
    description: "Country name of Jordan",
    defaultMessage: "Jordan",
  },
  [CountryCode["KAZAKHSTAN"]]: {
    id: "countryName.kz",
    description: "Country name of Kazakhstan",
    defaultMessage: "Kazakhstan",
  },
  [CountryCode["KENYA"]]: {
    id: "countryName.ke",
    description: "Country name of Kenya",
    defaultMessage: "Kenya",
  },
  [CountryCode["KIRIBATI"]]: {
    id: "countryName.ki",
    description: "Country name of Kiribati",
    defaultMessage: "Kiribati",
  },
  [CountryCode["KOREA (DEMOCRATIC PEOPLE'S REPUBLIC OF)"]]: {
    id: "countryName.kp",
    description: "Country name of Korea (Democratic People's Republic of)",
    defaultMessage: "Korea (Democratic People's Republic of)",
  },
  [CountryCode["KOREA, REPUBLIC OF"]]: {
    id: "countryName.kr",
    description: "Country name of Korea, Republic of",
    defaultMessage: "Korea, Republic of",
  },
  [CountryCode["KUWAIT"]]: {
    id: "countryName.kw",
    description: "Country name of Kuwait",
    defaultMessage: "Kuwait",
  },
  [CountryCode["KYRGYZSTAN"]]: {
    id: "countryName.kg",
    description: "Country name of Kyrgyzstan",
    defaultMessage: "Kyrgyzstan",
  },
  [CountryCode["LAO PEOPLE'S DEMOCRATIC REPUBLIC"]]: {
    id: "countryName.la",
    description: "Country name of Lao People's Democratic Republic",
    defaultMessage: "Lao People's Democratic Republic",
  },
  [CountryCode["LATVIA"]]: {
    id: "countryName.lv",
    description: "Country name of Latvia",
    defaultMessage: "Latvia",
  },
  [CountryCode["LEBANON"]]: {
    id: "countryName.lb",
    description: "Country name of Lebanon",
    defaultMessage: "Lebanon",
  },
  [CountryCode["LESOTHO"]]: {
    id: "countryName.ls",
    description: "Country name of Lesotho",
    defaultMessage: "Lesotho",
  },
  [CountryCode["LIBERIA"]]: {
    id: "countryName.lr",
    description: "Country name of Liberia",
    defaultMessage: "Liberia",
  },
  [CountryCode["LIBYA"]]: {
    id: "countryName.ly",
    description: "Country name of Libya",
    defaultMessage: "Libya",
  },
  [CountryCode["LIECHTENSTEIN"]]: {
    id: "countryName.li",
    description: "Country name of Liechtenstein",
    defaultMessage: "Liechtenstein",
  },
  [CountryCode["LITHUANIA"]]: {
    id: "countryName.lt",
    description: "Country name of Lithuania",
    defaultMessage: "Lithuania",
  },
  [CountryCode["LUXEMBOURG"]]: {
    id: "countryName.lu",
    description: "Country name of Luxembourg",
    defaultMessage: "Luxembourg",
  },
  [CountryCode["MACAO"]]: {
    id: "countryName.mo",
    description: "Country name of Macao",
    defaultMessage: "Macao",
  },
  [CountryCode["MADAGASCAR"]]: {
    id: "countryName.mg",
    description: "Country name of Madagascar",
    defaultMessage: "Madagascar",
  },
  [CountryCode["MALAWI"]]: {
    id: "countryName.mw",
    description: "Country name of Malawi",
    defaultMessage: "Malawi",
  },
  [CountryCode["MALAYSIA"]]: {
    id: "countryName.my",
    description: "Country name of Malaysia",
    defaultMessage: "Malaysia",
  },
  [CountryCode["MALDIVES"]]: {
    id: "countryName.mv",
    description: "Country name of Maldives",
    defaultMessage: "Maldives",
  },
  [CountryCode["MALI"]]: {
    id: "countryName.ml",
    description: "Country name of Mali",
    defaultMessage: "Mali",
  },
  [CountryCode["MALTA"]]: {
    id: "countryName.mt",
    description: "Country name of Malta",
    defaultMessage: "Malta",
  },
  [CountryCode["MARSHALL ISLANDS"]]: {
    id: "countryName.mh",
    description: "Country name of Marshall Islands",
    defaultMessage: "Marshall Islands",
  },
  [CountryCode["MARTINIQUE"]]: {
    id: "countryName.mq",
    description: "Country name of Martinique",
    defaultMessage: "Martinique",
  },
  [CountryCode["MAURITANIA"]]: {
    id: "countryName.mr",
    description: "Country name of Mauritania",
    defaultMessage: "Mauritania",
  },
  [CountryCode["MAURITIUS"]]: {
    id: "countryName.mu",
    description: "Country name of Mauritius",
    defaultMessage: "Mauritius",
  },
  [CountryCode["MAYOTTE"]]: {
    id: "countryName.yt",
    description: "Country name of Mayotte",
    defaultMessage: "Mayotte",
  },
  [CountryCode["MEXICO"]]: {
    id: "countryName.mx",
    description: "Country name of Mexico",
    defaultMessage: "Mexico",
  },
  [CountryCode["MICRONESIA (FEDERATED STATES OF)"]]: {
    id: "countryName.fm",
    description: "Country name of Micronesia (Federated States of)",
    defaultMessage: "Micronesia (Federated States of)",
  },
  [CountryCode["MOLDOVA, REPUBLIC OF"]]: {
    id: "countryName.md",
    description: "Country name of Moldova, Republic of",
    defaultMessage: "Moldova, Republic of",
  },
  [CountryCode["MONACO"]]: {
    id: "countryName.mc",
    description: "Country name of Monaco",
    defaultMessage: "Monaco",
  },
  [CountryCode["MONGOLIA"]]: {
    id: "countryName.mn",
    description: "Country name of Mongolia",
    defaultMessage: "Mongolia",
  },
  [CountryCode["MONTENEGRO"]]: {
    id: "countryName.me",
    description: "Country name of Montenegro",
    defaultMessage: "Montenegro",
  },
  [CountryCode["MONTSERRAT"]]: {
    id: "countryName.ms",
    description: "Country name of Montserrat",
    defaultMessage: "Montserrat",
  },
  [CountryCode["MOROCCO"]]: {
    id: "countryName.ma",
    description: "Country name of Morocco",
    defaultMessage: "Morocco",
  },
  [CountryCode["MOZAMBIQUE"]]: {
    id: "countryName.mz",
    description: "Country name of Mozambique",
    defaultMessage: "Mozambique",
  },
  [CountryCode["MYANMAR"]]: {
    id: "countryName.mm",
    description: "Country name of Myanmar",
    defaultMessage: "Myanmar",
  },
  [CountryCode["NAMIBIA"]]: {
    id: "countryName.na",
    description: "Country name of Namibia",
    defaultMessage: "Namibia",
  },
  [CountryCode["NAURU"]]: {
    id: "countryName.nr",
    description: "Country name of Nauru",
    defaultMessage: "Nauru",
  },
  [CountryCode["NEPAL"]]: {
    id: "countryName.np",
    description: "Country name of Nepal",
    defaultMessage: "Nepal",
  },
  [CountryCode["NETHERLANDS"]]: {
    id: "countryName.nl",
    description: "Country name of Netherlands",
    defaultMessage: "Netherlands",
  },
  [CountryCode["NEW CALEDONIA"]]: {
    id: "countryName.nc",
    description: "Country name of New Caledonia",
    defaultMessage: "New Caledonia",
  },
  [CountryCode["NEW ZEALAND"]]: {
    id: "countryName.nz",
    description: "Country name of New Zealand",
    defaultMessage: "New Zealand",
  },
  [CountryCode["NICARAGUA"]]: {
    id: "countryName.ni",
    description: "Country name of Nicaragua",
    defaultMessage: "Nicaragua",
  },
  [CountryCode["NIGER"]]: {
    id: "countryName.ne",
    description: "Country name of Niger",
    defaultMessage: "Niger",
  },
  [CountryCode["NIGERIA"]]: {
    id: "countryName.ng",
    description: "Country name of Nigeria",
    defaultMessage: "Nigeria",
  },
  [CountryCode["NIUE"]]: {
    id: "countryName.nu",
    description: "Country name of Niue",
    defaultMessage: "Niue",
  },
  [CountryCode["NORFOLK ISLAND"]]: {
    id: "countryName.nf",
    description: "Country name of Norfolk Island",
    defaultMessage: "Norfolk Island",
  },
  [CountryCode["NORTH MACEDONIA"]]: {
    id: "countryName.mk",
    description: "Country name of North Macedonia",
    defaultMessage: "North Macedonia",
  },
  [CountryCode["NORTHERN MARIANA ISLANDS"]]: {
    id: "countryName.mp",
    description: "Country name of Northern Mariana Islands",
    defaultMessage: "Northern Mariana Islands",
  },
  [CountryCode["NORWAY"]]: {
    id: "countryName.no",
    description: "Country name of Norway",
    defaultMessage: "Norway",
  },
  [CountryCode["OMAN"]]: {
    id: "countryName.om",
    description: "Country name of Oman",
    defaultMessage: "Oman",
  },
  [CountryCode["PAKISTAN"]]: {
    id: "countryName.pk",
    description: "Country name of Pakistan",
    defaultMessage: "Pakistan",
  },
  [CountryCode["PALAU"]]: {
    id: "countryName.pw",
    description: "Country name of Palau",
    defaultMessage: "Palau",
  },
  [CountryCode["PALESTINE, STATE OF"]]: {
    id: "countryName.ps",
    description: "Country name of Palestine, State of",
    defaultMessage: "Palestine, State of",
  },
  [CountryCode["PANAMA"]]: {
    id: "countryName.pa",
    description: "Country name of Panama",
    defaultMessage: "Panama",
  },
  [CountryCode["PAPUA NEW GUINEA"]]: {
    id: "countryName.pg",
    description: "Country name of Papua New Guinea",
    defaultMessage: "Papua New Guinea",
  },
  [CountryCode["PARAGUAY"]]: {
    id: "countryName.py",
    description: "Country name of Paraguay",
    defaultMessage: "Paraguay",
  },
  [CountryCode["PERU"]]: {
    id: "countryName.pe",
    description: "Country name of Peru",
    defaultMessage: "Peru",
  },
  [CountryCode["PHILIPPINES"]]: {
    id: "countryName.ph",
    description: "Country name of Philippines",
    defaultMessage: "Philippines",
  },
  [CountryCode["PITCAIRN"]]: {
    id: "countryName.pn",
    description: "Country name of Pitcairn",
    defaultMessage: "Pitcairn",
  },
  [CountryCode["POLAND"]]: {
    id: "countryName.pl",
    description: "Country name of Poland",
    defaultMessage: "Poland",
  },
  [CountryCode["PORTUGAL"]]: {
    id: "countryName.pt",
    description: "Country name of Portugal",
    defaultMessage: "Portugal",
  },
  [CountryCode["PUERTO RICO"]]: {
    id: "countryName.pr",
    description: "Country name of Puerto Rico",
    defaultMessage: "Puerto Rico",
  },
  [CountryCode["QATAR"]]: {
    id: "countryName.qa",
    description: "Country name of Qatar",
    defaultMessage: "Qatar",
  },
  [CountryCode["RÉUNION"]]: {
    id: "countryName.re",
    description: "Country name of Réunion",
    defaultMessage: "Réunion",
  },
  [CountryCode["ROMANIA"]]: {
    id: "countryName.ro",
    description: "Country name of Romania",
    defaultMessage: "Romania",
  },
  [CountryCode["RUSSIAN FEDERATION"]]: {
    id: "countryName.ru",
    description: "Country name of Russian Federation",
    defaultMessage: "Russian Federation",
  },
  [CountryCode["RWANDA"]]: {
    id: "countryName.rw",
    description: "Country name of Rwanda",
    defaultMessage: "Rwanda",
  },
  [CountryCode["SAINT BARTHÉLEMY"]]: {
    id: "countryName.bl",
    description: "Country name of Saint Barthélemy",
    defaultMessage: "Saint Barthélemy",
  },
  [CountryCode["SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA"]]: {
    id: "countryName.sh",
    description: "Country name of Saint Helena, Ascension and Tristan da Cunha",
    defaultMessage: "Saint Helena, Ascension and Tristan da Cunha",
  },
  [CountryCode["SAINT KITTS AND NEVIS"]]: {
    id: "countryName.kn",
    description: "Country name of Saint Kitts and Nevis",
    defaultMessage: "Saint Kitts and Nevis",
  },
  [CountryCode["SAINT LUCIA"]]: {
    id: "countryName.lc",
    description: "Country name of Saint Lucia",
    defaultMessage: "Saint Lucia",
  },
  [CountryCode["SAINT MARTIN (FRENCH PART)"]]: {
    id: "countryName.mf",
    description: "Country name of Saint Martin (French part)",
    defaultMessage: "Saint Martin (French part)",
  },
  [CountryCode["SAINT PIERRE AND MIQUELON"]]: {
    id: "countryName.pm",
    description: "Country name of Saint Pierre and Miquelon",
    defaultMessage: "Saint Pierre and Miquelon",
  },
  [CountryCode["SAINT VINCENT AND THE GRENADINES"]]: {
    id: "countryName.vc",
    description: "Country name of Saint Vincent and the Grenadines",
    defaultMessage: "Saint Vincent and the Grenadines",
  },
  [CountryCode["SAMOA"]]: {
    id: "countryName.ws",
    description: "Country name of Samoa",
    defaultMessage: "Samoa",
  },
  [CountryCode["SAN MARINO"]]: {
    id: "countryName.sm",
    description: "Country name of San Marino",
    defaultMessage: "San Marino",
  },
  [CountryCode["SAO TOME AND PRINCIPE"]]: {
    id: "countryName.st",
    description: "Country name of Sao Tome and Principe",
    defaultMessage: "Sao Tome and Principe",
  },
  [CountryCode["SAUDI ARABIA"]]: {
    id: "countryName.sa",
    description: "Country name of Saudi Arabia",
    defaultMessage: "Saudi Arabia",
  },
  [CountryCode["SENEGAL"]]: {
    id: "countryName.sn",
    description: "Country name of Senegal",
    defaultMessage: "Senegal",
  },
  [CountryCode["SERBIA"]]: {
    id: "countryName.rs",
    description: "Country name of Serbia",
    defaultMessage: "Serbia",
  },
  [CountryCode["SEYCHELLES"]]: {
    id: "countryName.sc",
    description: "Country name of Seychelles",
    defaultMessage: "Seychelles",
  },
  [CountryCode["SIERRA LEONE"]]: {
    id: "countryName.sl",
    description: "Country name of Sierra Leone",
    defaultMessage: "Sierra Leone",
  },
  [CountryCode["SINGAPORE"]]: {
    id: "countryName.sg",
    description: "Country name of Singapore",
    defaultMessage: "Singapore",
  },
  [CountryCode["SINT MAARTEN (DUTCH PART)"]]: {
    id: "countryName.sx",
    description: "Country name of Sint Maarten (Dutch part)",
    defaultMessage: "Sint Maarten (Dutch part)",
  },
  [CountryCode["SLOVAKIA"]]: {
    id: "countryName.sk",
    description: "Country name of Slovakia",
    defaultMessage: "Slovakia",
  },
  [CountryCode["SLOVENIA"]]: {
    id: "countryName.si",
    description: "Country name of Slovenia",
    defaultMessage: "Slovenia",
  },
  [CountryCode["SOLOMON ISLANDS"]]: {
    id: "countryName.sb",
    description: "Country name of Solomon Islands",
    defaultMessage: "Solomon Islands",
  },
  [CountryCode["SOMALIA"]]: {
    id: "countryName.so",
    description: "Country name of Somalia",
    defaultMessage: "Somalia",
  },
  [CountryCode["SOUTH AFRICA"]]: {
    id: "countryName.za",
    description: "Country name of South Africa",
    defaultMessage: "South Africa",
  },
  [CountryCode["SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"]]: {
    id: "countryName.gs",
    description: "Country name of South Georgia and the South Sandwich Islands",
    defaultMessage: "South Georgia and the South Sandwich Islands",
  },
  [CountryCode["SOUTH SUDAN"]]: {
    id: "countryName.ss",
    description: "Country name of South Sudan",
    defaultMessage: "South Sudan",
  },
  [CountryCode["SPAIN"]]: {
    id: "countryName.es",
    description: "Country name of Spain",
    defaultMessage: "Spain",
  },
  [CountryCode["SRI LANKA"]]: {
    id: "countryName.lk",
    description: "Country name of Sri Lanka",
    defaultMessage: "Sri Lanka",
  },
  [CountryCode["SUDAN"]]: {
    id: "countryName.sd",
    description: "Country name of Sudan",
    defaultMessage: "Sudan",
  },
  [CountryCode["SURINAME"]]: {
    id: "countryName.sr",
    description: "Country name of Suriname",
    defaultMessage: "Suriname",
  },
  [CountryCode["SVALBARD AND JAN MAYEN"]]: {
    id: "countryName.sj",
    description: "Country name of Svalbard and Jan Mayen",
    defaultMessage: "Svalbard and Jan Mayen",
  },
  [CountryCode["SWEDEN"]]: {
    id: "countryName.se",
    description: "Country name of Sweden",
    defaultMessage: "Sweden",
  },
  [CountryCode["SWITZERLAND"]]: {
    id: "countryName.ch",
    description: "Country name of Switzerland",
    defaultMessage: "Switzerland",
  },
  [CountryCode["SYRIAN ARAB REPUBLIC"]]: {
    id: "countryName.sy",
    description: "Country name of Syrian Arab Republic",
    defaultMessage: "Syrian Arab Republic",
  },
  [CountryCode["TAIWAN, PROVINCE OF CHINA"]]: {
    id: "countryName.tw",
    description: "Country name of Taiwan, Province of China",
    defaultMessage: "Taiwan, Province of China",
  },
  [CountryCode["TAJIKISTAN"]]: {
    id: "countryName.tj",
    description: "Country name of Tajikistan",
    defaultMessage: "Tajikistan",
  },
  [CountryCode["TANZANIA, UNITED REPUBLIC OF"]]: {
    id: "countryName.tz",
    description: "Country name of Tanzania, United Republic of",
    defaultMessage: "Tanzania, United Republic of",
  },
  [CountryCode["THAILAND"]]: {
    id: "countryName.th",
    description: "Country name of Thailand",
    defaultMessage: "Thailand",
  },
  [CountryCode["TIMOR-LESTE"]]: {
    id: "countryName.tl",
    description: "Country name of Timor-Leste",
    defaultMessage: "Timor-Leste",
  },
  [CountryCode["TOGO"]]: {
    id: "countryName.tg",
    description: "Country name of Togo",
    defaultMessage: "Togo",
  },
  [CountryCode["TOKELAU"]]: {
    id: "countryName.tk",
    description: "Country name of Tokelau",
    defaultMessage: "Tokelau",
  },
  [CountryCode["TONGA"]]: {
    id: "countryName.to",
    description: "Country name of Tonga",
    defaultMessage: "Tonga",
  },
  [CountryCode["TRINIDAD AND TOBAGO"]]: {
    id: "countryName.tt",
    description: "Country name of Trinidad and Tobago",
    defaultMessage: "Trinidad and Tobago",
  },
  [CountryCode["TUNISIA"]]: {
    id: "countryName.tn",
    description: "Country name of Tunisia",
    defaultMessage: "Tunisia",
  },
  [CountryCode["TURKEY"]]: {
    id: "countryName.tr",
    description: "Country name of Turkey",
    defaultMessage: "Turkey",
  },
  [CountryCode["TURKMENISTAN"]]: {
    id: "countryName.tm",
    description: "Country name of Turkmenistan",
    defaultMessage: "Turkmenistan",
  },
  [CountryCode["TURKS AND CAICOS ISLANDS"]]: {
    id: "countryName.tc",
    description: "Country name of Turks and Caicos Islands",
    defaultMessage: "Turks and Caicos Islands",
  },
  [CountryCode["TUVALU"]]: {
    id: "countryName.tv",
    description: "Country name of Tuvalu",
    defaultMessage: "Tuvalu",
  },
  [CountryCode["UGANDA"]]: {
    id: "countryName.ug",
    description: "Country name of Uganda",
    defaultMessage: "Uganda",
  },
  [CountryCode["UKRAINE"]]: {
    id: "countryName.ua",
    description: "Country name of Ukraine",
    defaultMessage: "Ukraine",
  },
  [CountryCode["UNITED ARAB EMIRATES"]]: {
    id: "countryName.ae",
    description: "Country name of United Arab Emirates",
    defaultMessage: "United Arab Emirates",
  },
  [CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]]: {
    id: "countryName.gb",
    description: "Country name of United Kingdom of Great Britain and Northern Ireland",
    defaultMessage: "United Kingdom of Great Britain and Northern Ireland",
  },
  [CountryCode["UNITED STATES OF AMERICA"]]: {
    id: "countryName.us",
    description: "Country name of United States of America",
    defaultMessage: "United States",
  },
  [CountryCode["UNITED STATES MINOR OUTLYING ISLANDS"]]: {
    id: "countryName.um",
    description: "Country name of United States Minor Outlying Islands",
    defaultMessage: "United States Minor Outlying Islands",
  },
  [CountryCode["URUGUAY"]]: {
    id: "countryName.uy",
    description: "Country name of Uruguay",
    defaultMessage: "Uruguay",
  },
  [CountryCode["UZBEKISTAN"]]: {
    id: "countryName.uz",
    description: "Country name of Uzbekistan",
    defaultMessage: "Uzbekistan",
  },
  [CountryCode["VANUATU"]]: {
    id: "countryName.vu",
    description: "Country name of Vanuatu",
    defaultMessage: "Vanuatu",
  },
  [CountryCode["VENEZUELA (BOLIVARIAN REPUBLIC OF)"]]: {
    id: "countryName.ve",
    description: "Country name of Venezuela (Bolivarian Republic of)",
    defaultMessage: "Venezuela (Bolivarian Republic of)",
  },
  [CountryCode["VIET NAM"]]: {
    id: "countryName.vn",
    description: "Country name of Viet Nam",
    defaultMessage: "Viet Nam",
  },
  [CountryCode["VIRGIN ISLANDS (BRITISH)"]]: {
    id: "countryName.vg",
    description: "Country name of Virgin Islands (British)",
    defaultMessage: "Virgin Islands (British)",
  },
  [CountryCode["VIRGIN ISLANDS (U.S.)"]]: {
    id: "countryName.vi",
    description: "Country name of Virgin Islands (U.S.)",
    defaultMessage: "Virgin Islands (U.S.)",
  },
  [CountryCode["WALLIS AND FUTUNA"]]: {
    id: "countryName.wf",
    description: "Country name of Wallis and Futuna",
    defaultMessage: "Wallis and Futuna",
  },
  [CountryCode["WESTERN SAHARA"]]: {
    id: "countryName.eh",
    description: "Country name of Western Sahara",
    defaultMessage: "Western Sahara",
  },
  [CountryCode["YEMEN"]]: {
    id: "countryName.ye",
    description: "Country name of Yemen",
    defaultMessage: "Yemen",
  },
  [CountryCode["ZAMBIA"]]: {
    id: "countryName.zm",
    description: "Country name of Zambia",
    defaultMessage: "Zambia",
  },
  [CountryCode["ZIMBABWE"]]: {
    id: "countryName.zw",
    description: "Country name of Zimbabwe",
    defaultMessage: "Zimbabwe",
  },
});

const formatCountryName = (intl: IntlShape, countryCode: CountryCode): string =>
  // this tertiary is used because sometimes we call this function with country code returned
  // by google, hence it may not be present in our CountryCode enum
  messages[countryCode] ? intl.formatMessage(messages[countryCode]) : "";

export default formatCountryName;

import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";
import memoize from "fast-memoize";
import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import FormLayout, {
  ChildLayout,
  LayoutSchemaField,
} from "@mapmycustomers/shared/types/layout/FormLayout";

const layoutState = (state: RootState) => state.layout;

export const getLayouts = createSelector(layoutState, ({ layouts }) => layouts);

export type LayoutsGetter = (entityType: EntityTypesSupportingFieldCustomization) => FormLayout[];

export const getLayoutsForEntity = createSelector(
  getLayouts,
  (layoutsMap: Record<EntityTypesSupportingFieldCustomization, FormLayout[]>): LayoutsGetter =>
    memoize(
      (entityType: EntityTypesSupportingFieldCustomization): FormLayout[] => layoutsMap[entityType]
    )
);

export type LayoutSchemaGetter = (
  entityType: EntityTypesSupportingFieldCustomization,
  layoutId: FormLayout["id"],
  variantProperties?: Omit<ChildLayout, "id" | "schema">
) => LayoutSchemaField[];

import React, { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import UserSelectField from "component/input/UserSelectField";
import User from "@mapmycustomers/shared/types/User";
import styles from "./AssigneeSearch.module.scss";

interface Props {
  allowedUserEmails?: string[];
  assignee?: User;
  disabled?: boolean;
  onChange?: (assignee?: User) => void;
  users: User[];
}

const AssigneeSearch: React.FC<Props> = ({
  allowedUserEmails,
  assignee,
  disabled,
  onChange,
  users,
}) => {
  const intl = useIntl();

  const userFilterOption = (inputValue: string, option: any) => {
    const user = users.find((type) => type.id === parseInt(option?.value));
    return ((user?.fullName ?? "") + (user?.username ?? ""))
      .toLowerCase()
      .includes(inputValue.toLowerCase().trim());
  };

  const filteredUsers = useMemo(
    () =>
      allowedUserEmails
        ? users.filter(({ username }) => allowedUserEmails.includes(username))
        : users,
    [allowedUserEmails, users]
  );

  useEffect(() => {
    assignee &&
      allowedUserEmails &&
      !allowedUserEmails.includes(assignee.username) &&
      onChange?.(undefined);
  }, [allowedUserEmails, assignee, onChange]);

  const handleUserSelect = useCallback(
    (value: number) => {
      const user = users.find(({ id }) => id === value);
      if (user) {
        onChange?.(user);
      }
    },
    [onChange, users]
  );

  return (
    <UserSelectField
      className={styles.associatedEntitySelect}
      defaultUsersOption={false}
      disabled={disabled}
      dropdownMatchSelectWidth={false}
      filterOption={userFilterOption}
      label={false}
      onSelect={handleUserSelect}
      placeholder={intl.formatMessage({
        id: "createEditActivityModal.field.assignee.placeholder",
        defaultMessage: "Type to find user by name or email",
        description: "Placeholder of the Assignee field on the Create Activity modal",
      })}
      showSearch
      users={filteredUsers}
      value={assignee}
    />
  );
};

export default AssigneeSearch;

import React from "react";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import styles from "./NoNotification.module.scss";
import { useIntl } from "react-intl";
import { getUserSettingValue } from "store/iam";
import Button from "antd/es/button";

interface Props {
  // need to use settings to figure out should we change UI
  onGoToSettings: () => void;
  settingsExists: boolean;
}

const NoNotification: React.FC<Props> = ({ onGoToSettings, settingsExists }) => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div>
        {settingsExists
          ? intl.formatMessage({
              defaultMessage: "No notifications yet",
              description: "Navbar Notifications - list - no notification - setting exists",
              id: "navbar.notifications.list.noNotification.settingExists",
            })
          : intl.formatMessage({
              defaultMessage: "You haven’t opted in to Notifications yet",
              description: "Navbar Notifications - list - no notification - setting doesn't exist",
              id: "navbar.notifications.list.noNotification.settingDoesntExists",
            })}
      </div>
      {!settingsExists && (
        <Button onClick={onGoToSettings} type="primary">
          {intl.formatMessage({
            defaultMessage: "Go to Notification Preferences",
            description:
              "Navbar Notifications - list - no notification - Go to Notification Preferences",
            id: "navbar.notifications.list.noNotification.goToSettings",
          })}
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  settingsExists: !!getUserSettingValue(state)("emailNotificationSettings"),
});

export default connect(mapStateToProps)(NoNotification);

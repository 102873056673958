import React, { useCallback, useState } from "react";
import cn from "classnames";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import Button from "antd/es/button";
import Popover, { PopoverProps } from "antd/es/popover";
import Tooltip from "antd/es/tooltip";
import AddressWarning from "./components/AddressWarning";
import { updateEntityAddress } from "store/entityAddress/actions";
import { EntityWithLocation } from "@mapmycustomers/shared/types/entity";
import Address from "@mapmycustomers/shared/types/Address";
import { stopEvents } from "util/browser";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./EntityIncompleteAddressWarning.module.scss";

interface Props {
  autoAdjustOverflow?: PopoverProps["autoAdjustOverflow"];
  className?: string;
  entity: EntityWithLocation;
  loading?: boolean;
  placement?: PopoverProps["placement"];
  updateEntityAddress: typeof updateEntityAddress;
}

const EntityIncompleteAddressWarning: React.FC<Props> = ({
  autoAdjustOverflow = true,
  className,
  entity,
  loading,
  placement,
  updateEntityAddress,
}) => {
  const intl = useIntl();

  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

  const handleSetPopoverVisible = useCallback((visible: boolean) => {
    setPopoverVisible(visible);
    setTooltipVisible(false);
  }, []);

  const handleOk = useCallback(
    (address: Address) => {
      updateEntityAddress({
        address,
        entity,
      });

      setTooltipVisible(false);
      setPopoverVisible(false);
    },
    [entity, updateEntityAddress]
  );

  const handleCancel = useCallback(() => {
    setTooltipVisible(false);
    setPopoverVisible(false);
  }, []);

  return (
    <span className={cn(styles.container, className)} onClick={stopEvents}>
      <Tooltip
        autoAdjustOverflow
        title={intl.formatMessage({
          id: "entity.incompleteAddressWarning.tooltip",
          defaultMessage:
            "Address is partially invalid and may not show on map as expected. Click for details.",
          description: "Incomplete address warning popover - tooltip",
        })}
        onVisibleChange={setTooltipVisible}
        trigger={popoverVisible ? [] : ["hover"]}
        visible={tooltipVisible}
      >
        <Popover
          arrowPointAtCenter
          autoAdjustOverflow={autoAdjustOverflow}
          content={
            <div className={styles.content}>
              <div className={styles.title}>
                <FontAwesomeIcon className={styles.icon} icon={faExclamationTriangle} />
                {intl.formatMessage({
                  id: "entity.incompleteAddressWarning.popover.title",
                  defaultMessage: "Partially Invalid Address",
                  description: "Incomplete address warning popover - title",
                })}
              </div>

              <AddressWarning
                loading={loading}
                entity={entity}
                onCancel={handleCancel}
                onOk={handleOk}
              />
            </div>
          }
          onVisibleChange={handleSetPopoverVisible}
          overlayClassName={styles.overlay}
          placement={placement ?? "top"}
          trigger={["click"]}
          visible={popoverVisible}
        >
          <Button className={styles.btn}>
            <FontAwesomeIcon className={styles.icon} icon={faExclamationTriangle} />
          </Button>
        </Popover>
      </Tooltip>
    </span>
  );
};

const mapDispatchToProps = {
  updateEntityAddress,
};

export default connect(null, mapDispatchToProps)(EntityIncompleteAddressWarning);

import React from "react";
import { useIntl } from "react-intl";
import styles from "./Info.module.scss";
import info from "assets/frequency/info.svg";
import ButtonLink from "component/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import HelpText from "../../../../typography/HelpText";

interface Props {
  hideText?: boolean;
}

const FrequencyInfo: React.FC<Props> = ({ hideText }) => {
  const intl = useIntl();

  return (
    <HelpText
      info={
        <div className={styles.popover}>
          <div>
            <div className={styles.title}>
              {intl.formatMessage({
                id: "frequency.cadenceSelector.popover.title",
                defaultMessage: "Frequencies",
                description: "frequency component - info - Frequencies - popover",
              })}
            </div>
            <div className={styles.text}>
              {intl.formatMessage(
                {
                  id: "frequency.cadenceSelector.popover.text",
                  defaultMessage:
                    "Opt into Frequencies to <b>prevent any accounts from being overlooked.</b> The frequency status will be highlighted on each record, and we'll provide clear guidance on how to get an account back on track.",
                  description: "frequency component - Frequencies - text - text",
                },
                { b: (text) => <b>{text}</b> }
              )}
            </div>
            <ButtonLink
              className={styles.link}
              href="https://support.mapmycustomers.com/hc/en-us/articles/10740075429399-Groups-and-Cadences"
              target="_blank"
            >
              {intl.formatMessage(
                {
                  id: "frequency.cadenceSelector.popover.link",
                  defaultMessage: "More about Frequencies",
                  description: "frequency component - Frequencies - link",
                },
                { b: (text) => <b>{text}</b> }
              )}
              <FontAwesomeIcon className={styles.externalLinkIcon} icon={faExternalLinkAlt} />
            </ButtonLink>
          </div>
          <img alt="info" src={info} />
        </div>
      }
      text={
        hideText
          ? undefined
          : intl.formatMessage({
              id: "frequency.cadenceSelector.info.text",
              defaultMessage: "What are frequencies?",
              description: "frequency component - info - text",
            })
      }
    />
  );
};

export default FrequencyInfo;

import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const activitiesLoggedState = (state: RootState) => state.scene.dashboard.activitiesLoggedState;

export const getActivitiesLoggedDrillDownViewState = createSelector(
  activitiesLoggedState,
  ({ viewState }) => viewState
);

export const getActivitiesLoggedDrillDownTotalFilteredRecords = createSelector(
  activitiesLoggedState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getActivitiesLoggedDrillDownTotalRecords = createSelector(
  activitiesLoggedState,
  ({ totalRecords }) => totalRecords
);

export const getActivitiesLoggedDrillDownCustomDateRange = createSelector(
  activitiesLoggedState,
  ({ drillDownCustomDateRange }) => drillDownCustomDateRange
);

export const getActivitiesLoggedDrillDownConfiguration = createSelector(
  activitiesLoggedState,
  ({ configuration }) => configuration
);

export const isActivitiesLoggedDrillDownLoading = createSelector(
  activitiesLoggedState,
  ({ loading }) => loading
);

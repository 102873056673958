import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const componentState = (state: RootState) => state.component.createDealModal;

export const isCreating = createSelector(componentState, ({ createLoading }) => createLoading);
export const isInitializing = createSelector(
  componentState,
  ({ initializeLoading }) => initializeLoading
);
export const getAssociationsState = createSelector(
  componentState,
  ({ associations }) => associations
);

export const isGlobalAddDeal = createSelector(
  componentState,
  ({ isGlobalAddDeal }) => isGlobalAddDeal
);

export const isCreateDealModalVisible = createSelector(componentState, ({ visible }) => visible);
export const getCreateError = createSelector(componentState, ({ createError }) => createError);

export const getUploadedFilesList = createSelector(componentState, ({ filesAdded }) => filesAdded);

export const getUploadedFileListIds = createSelector(getUploadedFilesList, (filesAdded) =>
  filesAdded
    .filter(({ uploadedFile }) => !!uploadedFile)
    .map(({ uploadedFile }) => uploadedFile!.id)
);

export const isFilesUploading = createSelector(
  componentState,
  ({ filesUploading }) => filesUploading
);

export const isFilesDeleting = createSelector(componentState, ({ filesDeleting }) => filesDeleting);

export const isEntityRelating = createSelector(
  componentState,
  ({ entityRelating }) => entityRelating
);

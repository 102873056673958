import {
  Actions,
  createCustomField,
  deleteCustomField,
  fetchCustomFields,
  updateCustomField,
  updateCustomFieldBulk,
} from "./actions";
import { createReducer } from "typesafe-actions";
import { stateFieldBaseName } from "./util";
import CustomFieldsSate from "./CustomFieldsState";

const initialState: CustomFieldsSate = {
  activityCustomFields: [],
  activityCustomFieldsError: undefined,
  activityCustomFieldsLoading: false,
  companyCustomFields: [],
  companyCustomFieldsError: undefined,
  companyCustomFieldsLoading: false,
  dealCustomFields: [],
  dealCustomFieldsError: undefined,
  dealCustomFieldsLoading: false,
  peopleCustomFields: [],
  peopleCustomFieldsError: undefined,
  peopleCustomFieldsLoading: false,
  customFieldDeleting: false,
  customFieldCreating: false,
  customFieldUpdating: false,
};

const customFields = createReducer<CustomFieldsSate, Actions>(initialState)
  .handleAction(fetchCustomFields.request, (state, { payload: { entityType } }) => ({
    ...state,
    [`${stateFieldBaseName[entityType]}`]: [],
    [`${stateFieldBaseName[entityType]}Error`]: undefined,
    [`${stateFieldBaseName[entityType]}Loading`]: true,
  }))
  .handleAction(fetchCustomFields.success, (state, { payload: { entityType, fields } }) => ({
    ...state,
    [`${stateFieldBaseName[entityType]}`]: fields,
    [`${stateFieldBaseName[entityType]}Loading`]: false,
  }))
  .handleAction(fetchCustomFields.failure, (state, { payload: { entityType, error } }) => ({
    ...state,
    [`${stateFieldBaseName[entityType]}Error`]: error,
    [`${stateFieldBaseName[entityType]}Loading`]: false,
  }))
  .handleAction(updateCustomFieldBulk.request, (state, { payload: { entityType, fields } }) => ({
    ...state,
    [`${stateFieldBaseName[entityType]}`]: fields,
  }))
  .handleAction(deleteCustomField.request, (state) => ({
    ...state,
    customFieldDeleting: true,
  }))
  .handleAction(deleteCustomField.success, (state, { payload }) => ({
    ...state,
    customFieldDeleting: false,
    [`${stateFieldBaseName[payload.entityType]}`]: state[
      `${stateFieldBaseName[payload.entityType]}`
    ].filter(({ id }) => id !== payload.id),
  }))
  .handleAction(deleteCustomField.failure, (state) => ({
    ...state,
    customFieldDeleting: false,
  }))
  .handleAction(createCustomField.request, (state) => ({
    ...state,
    customFieldCreating: true,
  }))
  .handleAction(createCustomField.success, (state) => ({
    ...state,
    customFieldCreating: false,
  }))
  .handleAction(createCustomField.failure, (state) => ({
    ...state,
    customFieldCreating: false,
  }))
  .handleAction(updateCustomField.request, (state) => ({
    ...state,
    customFieldUpdating: true,
  }))
  .handleAction(updateCustomField.success, (state) => ({
    ...state,
    customFieldUpdating: false,
  }))
  .handleAction(updateCustomField.failure, (state) => ({
    ...state,
    customFieldUpdating: false,
  }));

export * from "./selectors";
export type CustomFieldsActions = Actions;
export default customFields;

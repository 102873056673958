import NotificationSettingValue from "types/notifications/NotificationSetting";
import MmcNotificationAction from "enum/MmcNotificationAction";

const switchOffNotificationSetting = (
  settings: NotificationSettingValue,
  action: MmcNotificationAction
): NotificationSettingValue => {
  if (action === MmcNotificationAction.MENTION) {
    return { ...settings, mentions: false };
  }
  if (
    [
      MmcNotificationAction.COMPANY_GROUP_SHARED,
      MmcNotificationAction.PEOPLE_GROUP_SHARED,
      MmcNotificationAction.DEAL_GROUP_SHARED,
    ].includes(action)
  ) {
    return { ...settings, sharing: { ...settings.sharing, groups: false } };
  }
  if (
    [
      MmcNotificationAction.PEOPLE_ROUTE_SHARED,
      MmcNotificationAction.COMPANY_ROUTE_SHARED,
    ].includes(action)
  ) {
    return { ...settings, sharing: { ...settings.sharing, routes: false } };
  }
  if (MmcNotificationAction.OWNERSHIP_CHANGED === action) {
    return { ...settings, sharing: { ...settings.sharing, ownership: false } };
  }
  if (MmcNotificationAction.DEALS_MARKED_LOST === action) {
    return {
      ...settings,
      recordUpdates: {
        ...settings.recordUpdates,
        owned: {
          ...settings.recordUpdates.owned,
          dealStages: { ...settings.recordUpdates.owned.dealStages, closedLost: false },
        },
        shared: {
          ...settings.recordUpdates.shared,
          dealStages: { ...settings.recordUpdates.shared.dealStages, closedLost: false },
        },
      },
    };
  }
  if (MmcNotificationAction.DEALS_MARKED_WON === action) {
    return {
      ...settings,
      recordUpdates: {
        ...settings.recordUpdates,
        owned: {
          ...settings.recordUpdates.owned,
          dealStages: { ...settings.recordUpdates.owned.dealStages, closedWon: false },
        },
        shared: {
          ...settings.recordUpdates.shared,
          dealStages: { ...settings.recordUpdates.shared.dealStages, closedWon: false },
        },
      },
    };
  }
  if (MmcNotificationAction.DEALS_STAGE_CHANGED === action) {
    return {
      ...settings,
      recordUpdates: {
        ...settings.recordUpdates,
        owned: {
          ...settings.recordUpdates.owned,
          dealStages: { ...settings.recordUpdates.owned.dealStages, openStage: false },
        },
        shared: {
          ...settings.recordUpdates.shared,
          dealStages: { ...settings.recordUpdates.shared.dealStages, openStage: false },
        },
      },
    };
  }
  if (MmcNotificationAction.OUT_OF_CADENCE === action) {
    return {
      ...settings,
      recordUpdates: {
        ...settings.recordUpdates,
        owned: {
          ...settings.recordUpdates.owned,
          outOfCadence: false,
        },
        shared: {
          ...settings.recordUpdates.shared,
          outOfCadence: false,
        },
      },
    };
  }
  if (MmcNotificationAction.OVERDUE_ACTIVITIES === action) {
    return {
      ...settings,
      recordUpdates: {
        ...settings.recordUpdates,
        owned: {
          ...settings.recordUpdates.owned,
          overdueActivities: false,
        },
        shared: {
          ...settings.recordUpdates.shared,
          overdueActivities: false,
        },
      },
    };
  }
  if (MmcNotificationAction.DEALS_ROTTING === action) {
    return {
      ...settings,
      recordUpdates: {
        ...settings.recordUpdates,
        owned: {
          ...settings.recordUpdates.owned,
          rottedDeals: false,
        },
        shared: {
          ...settings.recordUpdates.shared,
          rottedDeals: false,
        },
      },
    };
  }
  if (MmcNotificationAction.ACTIVITY_LOGGED === action) {
    return {
      ...settings,
      notesAndActivities: {
        ...settings.notesAndActivities,
        ownedRecord: {
          enabled: false,
          noteOnOwnedRecord: false,
          activityOnOwnedRecord: false,
        },
      },
    };
  }
  if (MmcNotificationAction.NOTE_ADDED === action) {
    return {
      ...settings,
      notesAndActivities: {
        ...settings.notesAndActivities,
        activityOwned: {
          enabled: false,
          activityTimeChanged: false,
          activityCompleted: false,
        },
      },
    };
  }
  if (MmcNotificationAction.ACTIVITY_ASSIGNED === action) {
    return {
      ...settings,
      notesAndActivities: {
        ...settings.notesAndActivities,
        activityAssigned: {
          enabled: false,
          activityTimeChanged: false,
          activityCompleted: false,
          incompleteActivityEndReminder: false,
        },
      },
      sharing: {
        ...settings.sharing,
        activityAssigned: false,
      },
    };
  }
  return settings;
};

export default switchOffNotificationSetting;

import {
  Actions,
  changeAssociatedEntities,
  clearAllUploadedDealFiles,
  initializeEditStageModal,
  removeDealFile,
  updateDeal,
  uploadDealFiles,
} from "./actions";
import { createReducer } from "typesafe-actions";
import AssociationsState from "store/associations/AssociationsState";
import { DEFAULT_ASSOCIATIONS_STATE } from "store/associations/const";
import { Deal } from "@mapmycustomers/shared/types/entity";
import FileListItem from "types/FileListItem";

export interface EditStageModalState {
  associations: AssociationsState;
  filesAdded: FileListItem[];
  filesDeleting: boolean;
  filesUploading: boolean;
  loading: boolean;
  originalDeal?: Deal;
}

const initialState: EditStageModalState = {
  associations: DEFAULT_ASSOCIATIONS_STATE,
  filesAdded: [],
  filesDeleting: false,
  filesUploading: false,
  loading: false,
};

const editStageModal = createReducer<EditStageModalState, Actions>(initialState)
  .handleAction(initializeEditStageModal, (state, { payload }) => ({
    ...state,
    associations: {
      ...state.associations,
      associatedCompany: payload.account,
      associatedPerson: payload.contact,
    },
    filesAdded: [],
    originalDeal: payload,
  }))
  .handleAction(updateDeal.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(updateDeal.success, (state, action) => ({
    ...state,
    ...action.payload,
    loading: false,
  }))
  .handleAction(updateDeal.failure, (state, action) => ({ ...state, loading: false }))
  .handleAction(changeAssociatedEntities.request, (state, { payload }) => ({
    ...state,
    associations: {
      ...state.associations,
      associatedCompany: payload.company,
      associatedPerson: payload.person,
      availableLoading: true,
    },
  }))
  .handleAction(changeAssociatedEntities.success, (state, { payload }) => ({
    ...state,
    associations: { ...state.associations, ...payload, availableLoading: false },
  }))
  .handleAction(changeAssociatedEntities.failure, (state) => ({
    ...state,
    associations: { ...state.associations, availableLoading: false },
  }))
  .handleAction(uploadDealFiles.request, (state, action) => ({
    ...state,
    filesUploading: true,
  }))
  .handleAction(uploadDealFiles.success, (state, { payload }) => ({
    ...state,
    filesAdded: [...state.filesAdded, ...payload],
    filesUploading: false,
  }))
  .handleAction(uploadDealFiles.failure, (state) => ({ ...state, filesUploading: false }))
  .handleAction(removeDealFile.request, (state) => ({ ...state, filesDeleting: true }))
  .handleAction(removeDealFile.success, (state, action) => ({
    ...state,
    filesDeleting: false,
    //Removing the deleted file from store
    filesAdded: state.filesAdded.filter((file) => file.uploadedFile?.id !== action.payload),
  }))
  .handleAction(removeDealFile.failure, (state) => ({
    ...state,
    filesDeleting: false,
  }))
  .handleAction(clearAllUploadedDealFiles, (state) => ({
    ...state,
    filesAdded: [],
    filesUploading: false,
  }));

export * from "./selectors";
export type EditStageModalActions = Actions;

export default editStageModal;

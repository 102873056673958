import DuplicateReason from "@mapmycustomers/shared/enum/DuplicateReason";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  [DuplicateReason.ADDRESS]: {
    id: "duplicateReason.address",
    defaultMessage: "Address",
    description: "Name of Duplicate reason - address",
  },
  [DuplicateReason.DOMAIN]: {
    id: "duplicateReason.domain",
    defaultMessage: "Website Domain",
    description: "Name of Duplicate reason - domain",
  },
  [DuplicateReason.EMAIL]: {
    id: "duplicateReason.email",
    defaultMessage: "Email",
    description: "Name of Duplicate reason - email",
  },
  [DuplicateReason.NAME]: {
    id: "duplicateReason.name",
    defaultMessage: "Name",
    description: "Name of Duplicate reason - name",
  },
  [DuplicateReason.NAME_IN_RADIUS]: {
    id: "duplicateReason.nameInRadius",
    defaultMessage: "Name within 100m",
    description: "Name of Duplicate reason - Name within 100m",
  },
  [DuplicateReason.PHONE]: {
    id: "duplicateReason.phone",
    defaultMessage: "Phone Number",
    description: "Name of Duplicate reason - phone",
  },
  [DuplicateReason.WEBSITE]: {
    id: "duplicateReason.website",
    defaultMessage: "Website Domain",
    description: "Name of Duplicate reason - website",
  },
});

const getDuplicateReasonDisplayName = (intl: IntlShape, reason: DuplicateReason): string =>
  intl.formatMessage(messages[reason]);

export default getDuplicateReasonDisplayName;

import inputMessages from "../input/messages";
import attachedFilesMessages from "../EmailBody/component/AttachedFiles/messages";
import colorComponentsMessages from "../colorComponents/messages";
import emailBodyMessages from "../EmailBody/messages";
import { messages as userDisplayNameWithInfoMessages } from "../Avatar/util/getUserDisplayNameWithInfo";

const messages = {
  ...attachedFilesMessages,
  ...colorComponentsMessages,
  ...emailBodyMessages,
  ...inputMessages,
  ...userDisplayNameWithInfoMessages,
} as const;

export type MessageKey = keyof typeof messages;

export default messages;

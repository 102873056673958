import { useMemo } from "react";
import Integration from "types/integrations/Integration";
import SUPPORTED_SERVICES, { ServiceDescription } from "store/integrations/supported-services";

export const findServiceByType = (serviceType?: string) =>
  SUPPORTED_SERVICES.find(({ type }) => type === serviceType);

const useServiceDescription = (
  integrationType?: Integration["type"]
): ServiceDescription | undefined =>
  useMemo(() => findServiceByType(integrationType), [integrationType]);

export default useServiceDescription;

import Located from "@mapmycustomers/shared/types/base/Located";
import Address from "@mapmycustomers/shared/types/Address";

const locatedToAddress = ({
  address,
  city,
  countryCode,
  region,
  regionCode,
  postalCode,
}: Located): Address => ({
  address: address ?? undefined,
  city: city ?? undefined,
  region: region ?? undefined,
  regionCode: regionCode ?? undefined,
  postalCode: postalCode ?? undefined,
  countryCode: countryCode ?? undefined,
});

export default locatedToAddress;

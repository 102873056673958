import { call, put, select, takeEvery, takeLeading } from "redux-saga/effects";
import mimeTypes from "mime-types";
import { createFile, downloadFile, fetchFile } from "./actions";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getOrganization } from "store/iam";
import { callApi } from "store/api/callApi";
import { RawFile } from "@mapmycustomers/shared/types/File";
import { downloadFileByUrl } from "util/file";
import { handleError } from "../errors/actions";
import FileCache from "store/files/FileCache";

export function* onDownloadFile(action: ReturnType<typeof downloadFile>) {
  const { fileId, fileName, entityType, entityId } = action.payload;
  const organization: Organization = yield select(getOrganization);

  const file: RawFile = yield callApi(
    "fetchFile",
    organization.id,
    fileId,
    false,
    false,
    entityType,
    entityId
  );

  const fileBlob: Blob = yield callApi(
    "fetchFile",
    organization.id,
    fileId,
    true,
    false,
    entityType,
    entityId,
    { responseType: "blob" }
  );

  const fileExtension: string = mimeTypes.extension(file.contentType) || "";

  downloadFileByUrl(
    window.URL.createObjectURL(fileBlob),
    fileName ? `${fileName}.${fileExtension}` : file.name
  );
}

export function* onCreateFile({
  payload: { file, callback },
}: ReturnType<typeof createFile.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const uploadedFile: RawFile = yield callApi("createFile", organization.id, file, true);
    callback?.(uploadedFile);
    yield put(createFile.success(uploadedFile));
  } catch (error) {
    yield put(createFile.failure(error));
    yield put(handleError({ error }));
  }
}

const fileCache = new FileCache(100 * 1024 * 1024); // 100MB cache

export function* onFetchFile({ payload }: ReturnType<typeof fetchFile>) {
  const { fileId, entityType, entityId, onSuccess, onFailure } = payload;
  try {
    const organization: Organization = yield select(getOrganization);

    if (!fileCache.hasFile(fileId)) {
      const file: RawFile = yield callApi(
        "fetchFile",
        organization.id,
        fileId,
        false,
        false,
        entityType,
        entityId
      );

      const fileBlob: Blob = yield callApi(
        "fetchFile",
        organization.id,
        fileId,
        true,
        false,
        entityType,
        entityId,
        { responseType: "blob" }
      );

      fileCache.addFile(file, fileBlob);
    }

    const { file, fileBlob } = fileCache.getFile(fileId)!;

    yield call(onSuccess, file, fileBlob);
  } catch (error) {
    if (onFailure) {
      yield call(onFailure);
    } else {
      yield put(handleError({ error }));
    }
  }
}

export function* filesSaga() {
  yield takeLeading(downloadFile, onDownloadFile);
  yield takeEvery(createFile.request, onCreateFile);
  yield takeEvery(fetchFile, onFetchFile);
}

import { defineMessages, IntlShape } from "react-intl";

enum TimelineEventType {
  ACTIVITIES_COMPLETED = "activitiesCompleted",
  ACTIVITIES_SCHEDULED = "activitiesScheduled",
  DEAL_STAGE_CHANGE = "dealStageChange",
  DEALS_LOST = "dealsLost",
  DEALS_WON = "dealsWon",
  FILES_ADDED = "filesAdded",
  MENTIONS = "mentions",
  NEW_COMPANIES = "newCompanies",
  NEW_DEALS = "newDeals",
  NEW_PEOPLE = "newPeople",
  NEW_ROUTES = "newRoutes",
  NOTES_LOGGED = "notesLogged",
  VERIFIED_CHECK_INS = "verifiedCheckIns",
  UNVERIFIED_CHECK_INS = "unverifiedCheckIns",
}

const messages = defineMessages({
  [TimelineEventType.ACTIVITIES_COMPLETED]: {
    id: "dashboard.timelineEventType.activitiesCompleted",
    defaultMessage: "Activities Completed",
    description: "Timeline event type: Activities Completed",
  },
  [TimelineEventType.ACTIVITIES_SCHEDULED]: {
    id: "dashboard.timelineEventType.activitiesScheduled",
    defaultMessage: "Activities Scheduled",
    description: "Timeline event type: Activities Scheduled",
  },
  [TimelineEventType.DEAL_STAGE_CHANGE]: {
    id: "dashboard.timelineEventType.dealStageChange",
    defaultMessage: "Deal Stage Change",
    description: "Timeline event type: Deal Stage Change",
  },
  [TimelineEventType.DEALS_LOST]: {
    id: "dashboard.timelineEventType.dealsLost",
    defaultMessage: "Deals Lost",
    description: "Timeline event type: Deals Lost",
  },
  [TimelineEventType.DEALS_WON]: {
    id: "dashboard.timelineEventType.dealsWon",
    defaultMessage: "Deals Won",
    description: "Timeline event type: Deals Won",
  },
  [TimelineEventType.FILES_ADDED]: {
    id: "dashboard.timelineEventType.filesAdded",
    defaultMessage: "Files added",
    description: "Timeline event type: Files added",
  },
  [TimelineEventType.MENTIONS]: {
    id: "dashboard.timelineEventType.mentions",
    defaultMessage: "Mentions",
    description: "Timeline event type: Mentions",
  },
  [TimelineEventType.NEW_COMPANIES]: {
    id: "dashboard.timelineEventType.newCompanies",
    defaultMessage: "New Companies",
    description: "Timeline event type: NewCompanies",
  },
  [TimelineEventType.NEW_DEALS]: {
    id: "dashboard.timelineEventType.newDeals",
    defaultMessage: "New Deals",
    description: "Timeline event type: New Deals",
  },
  [TimelineEventType.NEW_PEOPLE]: {
    id: "dashboard.timelineEventType.newPeople",
    defaultMessage: "New People",
    description: "Timeline event type: New People",
  },
  [TimelineEventType.NEW_ROUTES]: {
    id: "dashboard.timelineEventType.newRoutes",
    defaultMessage: "New Routes",
    description: "Timeline event type: New Routes",
  },
  [TimelineEventType.NOTES_LOGGED]: {
    id: "dashboard.timelineEventType.notesLogged",
    defaultMessage: "Notes logged",
    description: "Timeline event type: Notes logged",
  },
  [TimelineEventType.VERIFIED_CHECK_INS]: {
    id: "dashboard.timelineEventType.verifiedCheckIns",
    defaultMessage: "Verified Check-ins",
    description: "Timeline event type: Verified Check-ins",
  },
  [TimelineEventType.UNVERIFIED_CHECK_INS]: {
    id: "dashboard.timelineEventType.unverifiedCheckIns",
    defaultMessage: "Unverified Check-ins",
    description: "Timeline event type: Unverified Check-ins",
  },
});

export const getTimelineEventTypeDisplayName = (intl: IntlShape, filter: TimelineEventType) =>
  intl.formatMessage(messages[filter]);

export default TimelineEventType;

import { Actions, createFile } from "./actions";
import { createReducer } from "typesafe-actions";

export interface FileState {
  fileCreating: boolean;
}

const initialState: FileState = {
  fileCreating: false,
};

const fileHandler = createReducer<FileState, Actions>(initialState)
  .handleAction(createFile.request, (state) => ({
    ...state,
    fileCreating: true,
  }))
  .handleAction(createFile.success, (state) => ({
    ...state,
    fileCreating: false,
  }))
  .handleAction(createFile.failure, (state) => ({
    ...state,
    fileCreating: false,
  }));

export type FilesActions = Actions;
export default fileHandler;

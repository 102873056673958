import { put, select, take, takeLatest } from "redux-saga/effects";
import { getOrganization, getOrganizationSettings } from "store/iam";
import { callApi } from "store/api/callApi";
import { isApiError } from "util/assert";
import {
  createConfig,
  deleteConfig,
  fetchImportConfigs,
  initializeAddPage,
  initializeMainPage,
  initMatchFieldsStep,
  selectEntityType,
  selectFile,
  setEntityType,
  setStep,
  startImport,
  updateConfig,
  updateCurrentConfigMatching,
  updateStartedImport,
} from "./actions";
import FilePreview from "types/FilePreview";
import {
  getEntityType,
  getFilePreview,
  getLayoutId,
  getMappedColumns,
  getSelectedConfig,
  getStartedImport,
  getTimezone,
  isConfigModified,
} from "./selectors";
import { GeoManagementState } from "@mapmycustomers/shared/types/base/Located";
import ImportPayloadMapping from "@mapmycustomers/shared/types/imports/ImportPayloadMapping";
import Import from "@mapmycustomers/shared/types/imports/Import";
import Organization from "@mapmycustomers/shared/types/Organization";
import ImportMapping from "@mapmycustomers/shared/types/imports/ImportMapping";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import convertToHistoryRow from "../util/convertToHistoryRow";
import { MAPPING_SKIP_KEY } from "../util/consts";
import { EntityTypesSupportedByImport } from "@mapmycustomers/shared/types/entity";
import { defaultNewImportState } from "./index";
import getImportMappingOptions from "../util/getImportMappingOptions";
import ImportConfig from "@mapmycustomers/shared/types/imports/ImportConfig";
import notification from "antd/es/notification";
import i18nService from "config/I18nService";
import { defineMessage } from "react-intl";
import TIMEZONE_ENTITIES from "../enum/TIMEZONE_ENTITIES";
import { isActionOf } from "typesafe-actions";
import { importAddAnalytics } from "../NewImport/importsAnalytics";
import StepsEnum from "../enum/Steps";
import Steps from "../enum/Steps";
import { handleError } from "store/errors/actions";
import OrganizationSetting from "enum/OrganizationSetting";
import Setting from "@mapmycustomers/shared/types/Setting";
import getValidationErrors from "util/errorHandling/getValidationErrors";
import TimeZone from "@mapmycustomers/shared/types/TimeZone";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import getLayoutModelByEntityType from "util/layout/impl";
import omit from "lodash-es/omit";

const mappingCreateSuccessfullyMessage = defineMessage({
  id: "imports.config.saveMessage.success",
  defaultMessage: "New saved import matching has been saved successfully",
  description: "Success message for saving mapping",
});

const createImportMappingPayload = (
  entityType: EntityTypesSupportedByImport,
  mapping: ImportMapping,
  timezone: TimeZone,
  appliedImportConfig?: Pick<ImportConfig, "id" | "name">,
  layoutId?: FormLayout["id"]
): ImportPayloadMapping => {
  const uid: string | undefined = mapping.importId;

  const mappingPayload: ImportPayloadMapping = {
    layoutId,
    options: {
      geoManagementState: GeoManagementState.AUTOMATIC_PRESERVE_ADDRESS,
      uniqueColumn: uid,
      updateExistingCustomers: !!uid,
    },
    properties: omit(mapping, MAPPING_SKIP_KEY),
    type: entityType,
  };
  if (TIMEZONE_ENTITIES.has(entityType)) {
    mappingPayload.timezone = timezone;
  }
  if (uid) {
    mappingPayload.identification = ["importId"];
  }
  if (appliedImportConfig) {
    mappingPayload.appliedImportConfig = appliedImportConfig;
  }

  return mappingPayload;
};

function* onSelectFile({ payload: file }: ReturnType<typeof selectFile.request>) {
  const org: Organization = yield select(getOrganization);
  try {
    const filePreview: FilePreview = yield callApi("fetchFilePreview", org.id, file);
    yield put(selectFile.success(filePreview));
  } catch (error) {
    if (isApiError(error)) {
      const errors = (getValidationErrors(error) ?? []).map((error) => error.code);
      if (errors.length) {
        // Do not display API error notification for validation failures, because they are actually displayed in modal
        yield put(
          selectFile.failure({
            errors,
          })
        );
      } else {
        yield put(handleError({ error }));
      }
    }
  }
}

function* onStartImport() {
  const org: Organization = yield select(getOrganization);
  const filePreview: FilePreview = yield select(getFilePreview);
  const mappedColumns: ImportMapping = yield select(getMappedColumns);
  const entityType: EntityTypesSupportedByImport = yield select(getEntityType);
  const isModified: boolean = yield select(isConfigModified);
  const selectedConfig: ImportConfig | undefined = yield select(getSelectedConfig);

  const mappingToSend = { ...mappedColumns };
  delete mappingToSend[MAPPING_SKIP_KEY];

  let mapping: ImportPayloadMapping = createImportMappingPayload(
    entityType,
    mappingToSend,
    yield select(getTimezone),
    selectedConfig && !isModified
      ? { id: selectedConfig.id, name: selectedConfig.name }
      : undefined,
    yield select(getLayoutId)
  );

  try {
    const importRecord: Import = yield callApi("startImport", org.id, {
      file: { id: filePreview.file.id },
      mapping,
    });
    yield put(startImport.success(importRecord));
    importAddAnalytics.completed();
  } catch (err) {
    yield put(startImport.failure(err));
    importAddAnalytics.failed();
  }
}

function* onUpdateStartedImport() {
  const org: Organization = yield select(getOrganization);
  const startedImport: Organization = yield select(getStartedImport)!;
  try {
    const importRecord: Import = yield callApi("fetchImport", org.id, startedImport.id);
    yield put(updateStartedImport.success(importRecord));
  } catch (err) {
    yield put(updateStartedImport.failure(err));
  }
}

function* onInitializeMainPage() {
  const org: Organization = yield select(getOrganization);
  try {
    const historyHistory: ListResponse<Import> = yield callApi("fetchImports", org.id);
    yield put(
      initializeMainPage.success({
        history: historyHistory.data.map(convertToHistoryRow),
      })
    );
  } catch (err) {
    yield put(updateStartedImport.failure(err));
  }
}

function* onInitializeAddPage() {
  yield put(setStep(Steps.UPLOAD));
  const entityType: EntityTypesSupportedByImport = yield select(getEntityType);

  const organizationSettings: Organization["settings"] = yield select(getOrganizationSettings);
  const orgTimezone: Setting | undefined = organizationSettings?.find(
    (s) => s.key === OrganizationSetting.TIMEZONE
  );

  try {
    yield put(fetchImportConfigs.request());
    const result: ReturnType<
      typeof fetchImportConfigs.failure | typeof fetchImportConfigs.success
    > = yield take([fetchImportConfigs.success, fetchImportConfigs.failure]);

    if (isActionOf(fetchImportConfigs.success, result)) {
      yield put(
        initializeAddPage.success({
          newImportState: {
            ...defaultNewImportState,
            layoutId: getLayoutModelByEntityType(entityType).defaultFormLayout?.id,
            mappingOptions: getImportMappingOptions(entityType),
            timezone: orgTimezone?.value ?? defaultNewImportState.timezone,
          },
        })
      );
    } else {
      yield put(initializeAddPage.failure(result.payload));
    }
  } catch (err) {
    yield put(initializeAddPage.failure(err));
  }
}

function* onCreateConfig(action: ReturnType<typeof createConfig.request>) {
  const org: Organization = yield select(getOrganization);
  const entityType: EntityTypesSupportedByImport = yield select(getEntityType);
  const importMapping: ImportMapping = yield select(getMappedColumns);
  const { callback, name } = action.payload;
  try {
    const response: ImportConfig = yield callApi("createImportConfig", org.id, {
      mapping: createImportMappingPayload(
        entityType,
        importMapping,
        yield select(getTimezone),
        undefined,
        yield select(getLayoutId)
      ),
      name,
    });
    notification.success({
      message: i18nService.getIntl()?.formatMessage(mappingCreateSuccessfullyMessage),
    });
    if (callback) {
      callback();
    }
    yield put(createConfig.success(response));
    yield put(fetchImportConfigs.request());
  } catch (err) {
    yield put(createConfig.failure(err));
  }
}

function* onUpdateConfig(action: ReturnType<typeof updateConfig.request>) {
  const org: Organization = yield select(getOrganization);
  const { callback, importConfig } = action.payload;

  try {
    const response: ImportConfig = yield callApi("updateImportConfig", org.id, importConfig);
    yield put(updateConfig.success(response));
    if (callback) {
      callback();
    }
    yield put(fetchImportConfigs.request());
  } catch (err) {
    yield put(updateConfig.failure(err));
  }
}

function* onDeleteConfig(action: ReturnType<typeof deleteConfig.request>) {
  const org: Organization = yield select(getOrganization);
  try {
    yield callApi("deleteImportConfig", org.id, action.payload);
    yield put(deleteConfig.success());
    yield put(fetchImportConfigs.request());
  } catch (err) {
    yield put(deleteConfig.failure(err));
  }
}

function* onUpdateCurrentConfigMatching() {
  const org: Organization = yield select(getOrganization);
  const config: ImportConfig = yield select(getSelectedConfig);
  if (!config) {
    return;
  }

  const mappedColumns: ImportMapping = yield select(getMappedColumns);
  try {
    const response: ImportConfig = yield callApi("updateImportConfig", org.id, {
      ...config,
      mapping: { ...config.mapping, properties: omit(mappedColumns, MAPPING_SKIP_KEY) },
    });
    yield put(updateCurrentConfigMatching.success(response));
    yield put(fetchImportConfigs.request());
  } catch (err) {
    yield put(updateCurrentConfigMatching.failure(err));
  }
}

function* onFetchImportConfigs() {
  const org: Organization = yield select(getOrganization);
  const entityType: EntityTypesSupportedByImport = yield select(getEntityType);
  try {
    const configs: ListResponse<ImportConfig> = yield callApi(
      "fetchImportConfigs",
      org.id,
      entityType
    );
    yield put(fetchImportConfigs.success(configs.data));
  } catch (err) {
    yield put(fetchImportConfigs.failure(err));
  }
}
function* onSetStep(action: ReturnType<typeof setStep>) {
  if (action.payload === StepsEnum.MATCH_FIELDS) {
    yield put(initMatchFieldsStep());
  } else if (action.payload === StepsEnum.FINALIZE) {
    yield put(startImport.request());
  }
}

function* onSelectEntityType({ payload }: ReturnType<typeof selectEntityType>) {
  try {
    const entityType: EntityTypesSupportedByImport = payload;
    const mappingOptions = getImportMappingOptions(payload);

    yield put(
      setEntityType({
        entityType,
        mappingOptions,
      })
    );
    yield put(fetchImportConfigs.request());
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* importsSaga() {
  yield takeLatest(selectFile.request, onSelectFile);
  yield takeLatest(startImport.request, onStartImport);
  yield takeLatest(updateStartedImport.request, onUpdateStartedImport);
  yield takeLatest(initializeMainPage.request, onInitializeMainPage);
  yield takeLatest(initializeAddPage.request, onInitializeAddPage);
  yield takeLatest(createConfig.request, onCreateConfig);
  yield takeLatest(updateConfig.request, onUpdateConfig);
  yield takeLatest(deleteConfig.request, onDeleteConfig);
  yield takeLatest(fetchImportConfigs.request, onFetchImportConfigs);
  yield takeLatest(updateCurrentConfigMatching.request, onUpdateCurrentConfigMatching);
  yield takeLatest(setStep, onSetStep);
  yield takeLatest(selectEntityType, onSelectEntityType);
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import Button from "antd/es/button";
import Modal from "component/modal";
import CreateDealModal from "component/createEditEntity/CreateDealModal";
import Associations from "./components/Associations";
import { RootState } from "store/rootReducer";
import { filterDeals, getFilteredDeals, isLoading } from "./store";
import { Company, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useEntitySorter from "./utils/useEntitySorter";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import noRecordsImageMap from "component/associations/utils/noRecordsImages";
import styles from "./Association.module.scss";
import { isEqual } from "lodash-es";

interface Props {
  associateWith?: Deal["id"];
  deals?: Deal[];
  defaultQuery?: string;
  fixedCompany?: Company;
  fixedPerson?: Person;
  loading?: boolean;
  multiselect?: boolean;
  onHide: (updated?: boolean) => void;
  onSearch: (payload: { query: string }) => void;
  onSelect?: (selectedDealsIds: Deal["id"][], removedDealsIds?: Deal["id"][]) => void;
  title?: string;
}

const DealAssociation: React.FC<Props> = ({
  associateWith,
  deals,
  defaultQuery = "",
  fixedCompany,
  fixedPerson,
  loading,
  multiselect = true,
  onHide,
  onSearch,
  onSelect,
  title,
}) => {
  const intl = useIntl();
  const assignedDeals = useMemo(() => {
    return (
      deals?.filter(
        (deal) =>
          (!!fixedCompany && deal.account?.id === fixedCompany.id) ||
          (!!fixedPerson && deal.contact?.id === fixedPerson.id)
      ) ?? []
    );
  }, [deals, fixedCompany, fixedPerson]);

  const [query, setQuery] = useState<string>(defaultQuery);
  const [selectedDealsIds, setSelectedDealsIds] = useState<Deal["id"][]>(
    assignedDeals.map(({ id }) => id)
  );

  useEffect(() => {
    setSelectedDealsIds(assignedDeals.map(({ id }) => id));
  }, [assignedDeals]);

  const removedDealsIds = useMemo(
    () =>
      assignedDeals?.filter(({ id }) => !selectedDealsIds.includes(id)).map(({ id }) => id) ?? [],
    [assignedDeals, selectedDealsIds]
  );

  const [createdDeals, setCreatedDeals] = useState<Deal[]>([]);
  const sortedDeals = useEntitySorter(deals ?? [], createdDeals, query.trim().length > 0);

  const handleOkClick = useCallback(() => {
    onSelect?.(selectedDealsIds, removedDealsIds);
    onHide(true);
  }, [onHide, onSelect, removedDealsIds, selectedDealsIds]);

  useEffect(() => {
    onSearch({ query: defaultQuery });
  }, [defaultQuery, onSearch]);

  const handleSearch = useCallback((query: string) => onSearch({ query }), [onSearch]);

  const [createNewDealVisible, showCreateDealModal, hideCreateDealModal] = useBoolean();
  const handleHideCreateDealModal = useDynamicCallback((newDeal?: Deal) => {
    const alreadyAssignedDealsIds = new Set(
      [...assignedDeals, ...createdDeals].map(({ id }) => id)
    );
    const hadAnySelectedDealsAlready = selectedDealsIds.some(
      (id) => !alreadyAssignedDealsIds.has(id)
    );
    if (newDeal) {
      setSelectedDealsIds((ids) => [...ids, newDeal.id]);
      // we also store created deals, just to show then in the top of the list, to not confuse user
      // because otherwise, newly added record could get filtered out according to current filter
      // that's also the reason why we reset the filter here
      setCreatedDeals((deals) => [...deals, newDeal]);
      setQuery("");
      onSearch({ query: "" });
    }
    hideCreateDealModal();

    // if user selected no deals in the modal before adding new deal, then just hide both modals
    if (!hadAnySelectedDealsAlready && newDeal) {
      onSelect?.([newDeal.id], removedDealsIds);
      onHide(true);
    }
  });

  const dealsUpdated = useMemo(
    () =>
      multiselect
        ? !isEqual(selectedDealsIds.sort(), assignedDeals.map(({ id }) => id).sort())
        : selectedDealsIds.length > 0,
    [assignedDeals, multiselect, selectedDealsIds]
  );

  const alreadyAssignedEntities = useMemo(
    () => [...assignedDeals, ...createdDeals],
    [assignedDeals, createdDeals]
  );

  return (
    <Modal
      className={styles.modal}
      okButtonProps={{ disabled: !dealsUpdated }}
      okText={intl.formatMessage(
        {
          id: "associations.deals.footer.okButton",
          defaultMessage:
            "{addDeals, select, true {Add} other {Update}} {multiselect, select, true {Deals} other {Deal}}",
          description: "Add button title on Deal Associations modal",
        },
        { addDeals: assignedDeals.length === 0, multiselect }
      )}
      onCancel={() => onHide(false)}
      onOk={handleOkClick}
      title={
        title ??
        intl.formatMessage(
          {
            id: "associations.deals.header",
            defaultMessage: "Select {multiselect, select, true {Deals} other {Deal}}",
            description: "Header of Deal Associations modal",
          },
          { multiselect }
        )
      }
      visible
      width="clamp(500px, 50vw, 750px)"
    >
      <Associations<Deal>
        alreadyAssociatedMessage={intl.formatMessage({
          id: "associations.deals.alreadyAssociated",
          defaultMessage:
            "This deal is already associated with this record. To remove the association, click the “Remove” icon from the edit panel.",
          description:
            "A tooltip over the checkbox when deal is already assigned to a target record",
        })}
        assignedRecords={alreadyAssignedEntities}
        associateWith={associateWith}
        cantAssociateWithSelfMessage={intl.formatMessage({
          id: "associations.deals.cantAssociateWithSelf",
          defaultMessage: "Assigning deal to itself is not allowed",
          description: "A tooltip over the checkbox when deal is already a target company",
        })}
        entities={sortedDeals}
        extras={
          <Button onClick={showCreateDealModal} type="link">
            {intl.formatMessage({
              id: "associations.deals.addNewDealButton",
              defaultMessage: "+ Add New Deal",
              description: "Add new deal button on Deal association modal",
            })}
          </Button>
        }
        loading={loading}
        multiselect={multiselect}
        noDataImageSrc={noRecordsImageMap[EntityType.DEAL]}
        onChange={setSelectedDealsIds}
        onChangeQuery={setQuery}
        onSearch={handleSearch}
        query={query}
        textFieldProps={{
          placeholder: intl.formatMessage({
            id: "associations.deals.search.placeholder",
            defaultMessage: "Search for a deal",
            description: "Search bar placeholder for deal association modal",
          }),
        }}
        value={selectedDealsIds}
      />
      {createNewDealVisible && (
        <CreateDealModal
          fixedCompany={fixedPerson ? (fixedPerson.accounts?.[0] as Company) : fixedCompany}
          fixedPerson={fixedPerson}
          okText={intl.formatMessage({
            id: "associations.deals.addNewDealModal.okButton",
            defaultMessage: "Create and Add Deal",
            description:
              "Create button title on Create Deal modal when called from Select Deals modal",
          })}
          onHide={handleHideCreateDealModal}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  deals: getFilteredDeals(state),
  loading: isLoading(state),
});

const mapDispatchToProps = {
  onSearch: filterDeals.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealAssociation);

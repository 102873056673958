import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const noContactInState = (state: RootState) => state.scene.dashboard.noContactInState;

export const getNoContactInDrillDownViewStates = createSelector(
  noContactInState,
  ({ viewStates }) => viewStates
);

export const getNoContactInDrillDownTotalFilteredRecords = createSelector(
  noContactInState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getNoContactInDrillDownTotalRecords = createSelector(
  noContactInState,
  ({ totalRecords }) => totalRecords
);

export const getNoContactInDrillDownConfiguration = createSelector(
  noContactInState,
  ({ configuration }) => configuration
);

export const isNoContactInDrillDownLoading = createSelector(
  noContactInState,
  ({ loading }) => loading
);

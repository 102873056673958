import { PASSWORD_MINIMUM_LENGTH } from "./consts";

export type PasswordValidationResult = {
  isValid: boolean;
  hasGoodLength: boolean;
  containsUppercaseCharacters: boolean;
  containsSpecialCharacters: boolean;
  passwordsMatch: boolean;
};

export const NOTHING_VALID: PasswordValidationResult = {
  isValid: false,
  passwordsMatch: false,
  containsSpecialCharacters: false,
  containsUppercaseCharacters: false,
  hasGoodLength: false,
};

const isSpecialCharacter = (char: string) => {
  const code = char.charCodeAt(0);
  return (
    (code > 32 && code < 48) ||
    (code > 57 && code < 65) ||
    (code > 90 && code < 97) ||
    (code > 122 && code < 127)
  );
};

const validatePasswords = (
  password: string | undefined,
  confirmationPassword: string | undefined
): PasswordValidationResult => {
  if (!password) {
    return {
      ...NOTHING_VALID,
      passwordsMatch: password === confirmationPassword,
    };
  }
  const hasGoodLength = password.length >= PASSWORD_MINIMUM_LENGTH;
  const containsUppercaseCharacters = password !== password.toLowerCase();
  const containsSpecialCharacters = password.split("").some(isSpecialCharacter);
  const isValid = hasGoodLength && containsUppercaseCharacters && containsSpecialCharacters;
  return {
    isValid,
    hasGoodLength,
    containsUppercaseCharacters,
    containsSpecialCharacters,
    passwordsMatch: password === confirmationPassword,
  };
};

export default validatePasswords;

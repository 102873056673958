import { ActionType, createAsyncAction } from "typesafe-actions";
import {
  EntitiesSupportingHistory,
  EntityTypeSupportingHistory,
} from "@mapmycustomers/shared/types/entity";
import { EntityHistoryRowWithRecord } from "@mapmycustomers/shared/types/entity/EntityHistoryRow";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

export const fetchHistory = createAsyncAction(
  "@component/entityHistory/fetchHistory/request",
  "@component/entityHistory/fetchHistory/success",
  "@component/entityHistory/fetchHistory/failure"
)<
  {
    entityType: EntityTypeSupportingHistory;
    entityId: EntitiesSupportingHistory["id"];
    filter: FilterModel;
    page: number;
    sortFieldName: string;
    sortOrder: SortOrder;
  },
  { history: EntityHistoryRowWithRecord[]; pageCount: number },
  void
>();

export type Actions = ActionType<typeof fetchHistory>;

import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import SelectField from "component/input/SelectField";
import { TextField } from "@mapmycustomers/ui";
import defaultFilterOption from "component/input/utils/defaultFilterOption";
import Region from "@mapmycustomers/shared/types/Region";
import useRegionsListOptions from "util/regions/useRegionsListOptions";

interface Props {
  countryCode?: string;
  disabled?: boolean;
  onChange?: (region: Region) => void;
  value?: Region;
}

const RegionField: React.FC<Props> = ({ countryCode, disabled, onChange, value }) => {
  const intl = useIntl();
  const regions = useRegionsListOptions(countryCode);
  const hasRegions = regions?.length > 0;

  const handleChange = useCallback(
    (value: string | undefined, item) => {
      onChange?.({
        region: value !== undefined ? item.label : undefined,
        regionCode: value,
      });
    },
    [onChange]
  );

  const handleTextChange = useCallback(
    (value) => {
      onChange?.({
        region: value,
        regionCode: undefined,
      });
    },
    [onChange]
  );

  return hasRegions ? (
    <SelectField<string | undefined>
      allowClear
      disabled={disabled}
      dropdownMatchSelectWidth
      filterOption={defaultFilterOption}
      onChange={handleChange}
      options={regions}
      showSearch
      value={value?.regionCode}
    />
  ) : (
    <TextField
      disabled={disabled}
      onChange={handleTextChange}
      placeholder={intl.formatMessage({
        id: "autoComplete.addressFields.regionProvince",
        defaultMessage: "State/Province",
        description: "State/Province field on the Create Company modal",
      })}
      value={value?.region}
    />
  );
};

export default RegionField;

import React from "react";
import cn from "classnames";
import AntdModal, { ModalProps as AntdModalProps } from "antd/es/modal";
import styles from "./Modal.module.scss";

export interface ModalProps extends AntdModalProps {
  modalStyle?: "fancy";
  type?: "default" | "warning" | "error" | "success" | "none" | "disabled";
}

const Modal: React.FC<ModalProps> = ({ type = "default", className, modalStyle, ...props }) => (
  <AntdModal
    className={cn(
      className,
      { [styles.bordered]: type !== "none" },
      { [styles.error]: type === "error" },
      { [styles.warning]: type === "warning" },
      { [styles.disabled]: type === "disabled" },
      { [styles.success]: type === "success" },
      { [styles.fancyModalStyle]: modalStyle === "fancy" }
    )}
    centered
    {...props}
  />
);

export default Modal;

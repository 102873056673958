import { put, select, takeLatest } from "redux-saga/effects";
import { fetchRoles, fetchTeams, fetchUsers, updateTeam, updateUser } from "./actions";
import { callApi } from "../api/callApi";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { getOrganization, getOrganizationId } from "../iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import Role from "@mapmycustomers/shared/types/Role";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";

export function* onFetchRoles() {
  try {
    const organization: Organization = yield select(getOrganization);
    const response: ListResponse<Role> = yield callApi("fetchRoles", organization.id);
    yield put(fetchRoles.success(response.data));
  } catch (error) {
    yield put(fetchRoles.failure());
    // error is handled in the onInitializeApp saga
  }
}

export function* onFetchTeams() {
  try {
    const organization: Organization = yield select(getOrganization);
    const response: ListResponse<Team> = yield callApi("fetchTeams", organization.id);
    yield put(fetchTeams.success(response.data));
  } catch (error) {
    yield put(fetchTeams.failure());
    // error is handled in the onInitializeApp saga
  }
}

export function* onFetchUsers() {
  try {
    const organization: Organization = yield select(getOrganization);
    const response: ListResponse<User> = yield callApi("fetchUsers", organization.id);
    yield put(fetchUsers.success(response.data));
  } catch (error) {
    yield put(fetchUsers.failure());
    // error is handled in the onInitializeApp saga
  }
}

export function* onUpdateUser({ payload }: ReturnType<typeof updateUser.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: User = yield callApi("updateUser", orgId, payload);
    yield put(updateUser.success(response));
  } catch (error) {
    yield put(updateUser.failure());
  }
}

export function* onUpdateTeam({ payload }: ReturnType<typeof updateTeam.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: Team = yield callApi("updateTeam", orgId, payload);
    yield put(updateTeam.success(response));
  } catch (error) {
    yield put(updateTeam.failure());
  }
}

export function* membersSaga() {
  yield takeLatest(fetchRoles.request, onFetchRoles);
  yield takeLatest(fetchTeams.request, onFetchTeams);
  yield takeLatest(fetchUsers.request, onFetchUsers);
  yield takeLatest(updateUser.request, onUpdateUser);
  yield takeLatest(updateTeam.request, onUpdateTeam);
}

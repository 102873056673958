import ILoggingService from "./ILoggingService";
import LogLevel from "./LogLevel";
import ILogger from "./ILogger";

export default class LoggingService implements ILoggingService, ILogger {
  private loggers: Partial<Record<LogLevel, ILogger[]>>;

  constructor(loggers: Partial<Record<LogLevel, ILogger[]>>) {
    this.loggers = loggers;
  }

  log(logLevel: LogLevel, message: string, payload?: any) {
    (this.loggers[logLevel] ?? []).forEach((logger) => {
      logger.log(logLevel, message, payload);
    });
  }

  debug(message: string, payload?: any) {
    this.log(LogLevel.DEBUG, message, payload);
  }

  error(message: string, payload?: any) {
    this.log(LogLevel.ERROR, message, payload);
  }

  info(message: string, payload?: any) {
    this.log(LogLevel.INFO, message, payload);
  }

  warning(message: string, payload?: any) {
    this.log(LogLevel.WARNING, message, payload);
  }
}

import {
  applyRecordsPastDueDrillDownListViewSettings,
  fetchRecordsPastDueCardDrillDownData,
  hideRecordsPastDueDrillDown,
  RecordsPastDueCardActions,
  showRecordsPastDueDrillDown,
} from "./actions";
import { createReducer } from "typesafe-actions";
import { DEFAULT_PAGE_SIZE } from "util/consts";
import RecordsPastDueCardConfiguration from "types/dashboard/cards/recordsPastDue/RecordsPastDueCardConfiguration";
import DrillDownViewState from "scene/dashboard/types/DrillDownViewState";

export interface RecordsPastDueCardState {
  configuration?: RecordsPastDueCardConfiguration; // also works as a "visible" trigger, visible if set
  loading?: boolean;
  totalFilteredRecords: number;
  totalRecords: number;
  viewState: DrillDownViewState;
}

export const recordsPastDueInitialState: RecordsPastDueCardState = {
  // data: [],
  totalFilteredRecords: 0,
  totalRecords: 0,
  viewState: {
    columns: [],
    filter: {},
    range: { startRow: 0, endRow: DEFAULT_PAGE_SIZE },
    sort: [],
    viewAs: undefined,
  },
};

const recordsPastDueState = createReducer<RecordsPastDueCardState, RecordsPastDueCardActions>(
  recordsPastDueInitialState
)
  .handleAction(showRecordsPastDueDrillDown.request, (state, { payload }) => ({
    ...state,
    configuration: payload.configuration,
  }))
  .handleAction(showRecordsPastDueDrillDown.success, (state, { payload }) => ({
    ...state,
    viewState: payload.viewState,
  }))
  .handleAction(hideRecordsPastDueDrillDown, (state) => ({ ...state, configuration: undefined }))
  .handleAction(fetchRecordsPastDueCardDrillDownData.request, (state, { payload }) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchRecordsPastDueCardDrillDownData.success, (state, { payload }) => ({
    ...state,
    // data: payload.data,
    loading: false,
    totalFilteredRecords: payload.totalFilteredRecords,
    totalRecords: payload.totalRecords,
  }))
  .handleAction(fetchRecordsPastDueCardDrillDownData.failure, (state, { payload }) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyRecordsPastDueDrillDownListViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      range: payload.range ?? state.viewState.range,
      sort: payload.sort ?? state.viewState.sort,
      filter: payload.filter ?? state.viewState.filter,
      columns: payload.columns ?? state.viewState.columns,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
    },
  }));

export default recordsPastDueState;

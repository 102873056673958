import React, { useCallback } from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { NumberField, NumberFieldComponent } from "@mapmycustomers/ui";
import Row from "antd/es/row";
import styles from "./FrequencyIntervalFilter.module.scss";
import { Col } from "antd/es/grid";
import { useIntl } from "react-intl";
import layout from "../../styles/layout";

const handleUpdateFrequency = (valueStr?: string) => parseInt(valueStr ?? "", 10);

export const FREQUENCY_NUMBER_FILTER_OPERATORS = [
  FilterOperator.EQUALS,
  FilterOperator.GREATER_THAN,
  FilterOperator.GREATER_THAN_OR_EQUAL,
  FilterOperator.LESS_THAN,
  FilterOperator.LESS_THAN_OR_EQUAL,
  FilterOperator.EMPTY,
  FilterOperator.NOT_EMPTY,
];

interface FrequencyIntervalFilterProps extends IFilterComponentProps {}

const FrequencyIntervalFilter: IFilterInstance = {
  doesSupportValue: (value: any, operator: FilterOperator) => {
    return typeof value === "number" && FREQUENCY_NUMBER_FILTER_OPERATORS.includes(operator);
  },
  getComponent:
    (): React.FC<FrequencyIntervalFilterProps> =>
    ({ className, focus, onChange, value }) => {
      const intl = useIntl();

      const setRef = useCallback(
        (ref: NumberFieldComponent | null) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      if ([FilterOperator.EMPTY, FilterOperator.NOT_EMPTY].includes(value.operator)) {
        return null;
      }

      return (
        <Row align="middle" gutter={layout.spacerXS}>
          <Col>
            <NumberField
              inputClassName={className}
              min={1}
              onChange={(number) => onChange?.({ ...value, value: number })}
              parser={handleUpdateFrequency}
              ref={setRef}
              value={value?.value}
            />
          </Col>
          <Col className={styles.text}>
            {intl.formatMessage({
              id: "filters.frequencyInterval.text",
              defaultMessage: "days",
              description: "text in frequency Interval field filter",
            })}
          </Col>
        </Row>
      );
    },
};

export default FrequencyIntervalFilter;

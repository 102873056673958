import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import RecordsPastDueCardConfiguration from "types/dashboard/cards/recordsPastDue/RecordsPastDueCardConfiguration";
import RecordsPastDueCardData from "./RecordsPastDueCardData";
import Scope from "types/dashboard/Scope";
import DrillDownViewState from "scene/dashboard/types/DrillDownViewState";
import { DrillDownListFetcherPayload } from "scene/dashboard/types/DrillDownListFetcher";
import { AnyEntity } from "@mapmycustomers/shared/types/entity";

export const fetchRecordsPastDueCardData = createAction("@dashboard/fetchRecordsPastDueCardData")<{
  callback: (data: RecordsPastDueCardData) => void;
  configuration: RecordsPastDueCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
}>();

export const showRecordsPastDueDrillDown = createAsyncAction(
  "@dashboard/showRecordsPastDueCardDrillDownModal/request",
  "@dashboard/showRecordsPastDueCardDrillDownModal/success",
  "@dashboard/showRecordsPastDueCardDrillDownModal/failure"
)<
  {
    configuration: RecordsPastDueCardConfiguration;
    scope: Scope;
  },
  { viewState: DrillDownViewState },
  unknown
>();

export const hideRecordsPastDueDrillDown = createAction(
  "@dashboard/hideRecordsPastDueCardDrillDownModal"
)<void>();

export const fetchRecordsPastDueCardDrillDownData = createAsyncAction(
  "@dashboard/fetchRecordsPastDueCardDrillDownData/request",
  "@dashboard/fetchRecordsPastDueCardDrillDownData/success",
  "@dashboard/fetchRecordsPastDueCardDrillDownData/failure"
)<
  DrillDownListFetcherPayload<AnyEntity>,
  {
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyRecordsPastDueDrillDownListViewSettings = createAction(
  "@dashboard/applyRecordsPastDueDrillDownListViewSettings"
)<Partial<DrillDownViewState>>();

export const exportRecordsPastDueCardDrillDownData = createAction(
  "@dashboard/exportRecordsPastDueCardData"
)<{
  configuration: RecordsPastDueCardConfiguration;
  scope: Scope;
}>();

export type RecordsPastDueCardActions = ActionType<
  | typeof fetchRecordsPastDueCardData
  | typeof showRecordsPastDueDrillDown
  | typeof hideRecordsPastDueDrillDown
  | typeof fetchRecordsPastDueCardDrillDownData
  | typeof applyRecordsPastDueDrillDownListViewSettings
  | typeof exportRecordsPastDueCardDrillDownData
>;

import { call, put, select, takeEvery } from "redux-saga/effects";
import { initializeUserColors, setSelectedUserIds } from "./actions";
import { getMe } from "../iam";
import User from "@mapmycustomers/shared/types/User";
import Iam from "../../types/Iam";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";
import activityFieldModel from "util/fieldModel/ActivityFieldModel";
import { CALENDAR_VIEW_STATE } from "scene/activity/component/ActivityCalendarPage/store/saga";
import localSettings from "../../config/LocalSettings";
import { isSimpleCondition } from "util/viewModel/assert";
import { getUserColorSelectedUserIds } from "./selectors";
import { isDefined } from "util/assert";

export function* onSetUserColors(viewState: Partial<ViewState>) {
  if (viewState.filter?.assignee && isSimpleCondition(viewState.filter.assignee)) {
    const me: Iam = yield select(getMe);
    let restUserIds: User["id"][] = [];
    const updated: User["id"][] = (viewState.filter.assignee as SimpleCondition).value.filter(
      (id: User["id"]) => id !== me.id
    );
    const updatedSet = new Set(updated);
    let existing: User["id"][] = yield select(getUserColorSelectedUserIds);
    existing = existing.slice(1);

    const newUserIds = updated.filter((id) => !existing.includes(id));
    let numberOfIdsToInsertAtSamePosition = updated.length - newUserIds.length;

    restUserIds = existing.reduce<User["id"][]>((result, userId) => {
      let idToInsert;
      if (updatedSet.has(userId)) {
        numberOfIdsToInsertAtSamePosition--;
        idToInsert = userId;
      } else if (newUserIds.length) {
        idToInsert = newUserIds.shift();
      } else if (numberOfIdsToInsertAtSamePosition > 0) {
        idToInsert = -1;
      }
      if (isDefined(idToInsert)) {
        result.push(idToInsert);
      }
      return result;
    }, []);
    restUserIds = [...restUserIds, ...newUserIds];
    // we don't update user colors if provided view state doesn't have assignee filter
    // this needs to avoid dropping colors after any view state changing.
    yield put(
      setSelectedUserIds([
        // Put current user id to first place, independently does current user in filter of view state OR not
        // current user should have color with index 0
        me.id,
        ...restUserIds,
      ])
    );
  }
}

export function* onInitializeUserColors() {
  const viewState: ViewState | undefined = localSettings.getViewSettings(
    CALENDAR_VIEW_STATE,
    activityFieldModel
  );
  const me: Iam = yield select(getMe);
  // initially we put current user id in first place of user colors
  // current user should have color with index 0
  yield put(setSelectedUserIds([me.id]));
  // if viewState is available we call onSetUserColors action to set colors for all selected users
  if (viewState) {
    yield call(onSetUserColors, viewState);
  }
}

export function* userColorSaga() {
  yield takeEvery(initializeUserColors, onInitializeUserColors);
}

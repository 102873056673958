import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";

const entityHistoryState = (state: RootState) => state.component.entityHistory;

export const isEntityHistoryLoading = createSelector(entityHistoryState, ({ loading }) => loading);

export const getEntityHistory = createSelector(entityHistoryState, ({ history }) => history);

export const getEntityHistoryPageCount = createSelector(
  entityHistoryState,
  ({ pageCount }) => pageCount
);

import { ActionType, createAction } from "typesafe-actions";
import { MapEntity } from "@mapmycustomers/shared/types/entity";

export const fetchAnnotationData = createAction("@component/entityAnnotation/fetchAnnotationData")<{
  entityId: MapEntity["id"];
  entityType: MapEntity["entity"];
  callback: (entity: MapEntity) => void;
  failureCallback: () => void;
}>();

export type Actions = ActionType<typeof fetchAnnotationData>;

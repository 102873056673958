import React, { useCallback, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import cn from "classnames";
import Button from "antd/es/button";
import Form from "antd/es/form";
import { FormInstance } from "antd/es/form/hooks/useForm";
import Dropdown from "antd/es/dropdown";
import InputNumber from "antd/es/input-number";
import Popover from "antd/es/popover";
import Select from "antd/es/select";
import Tooltip from "antd/es/tooltip";
import Checkbox from "component/input/Checkbox";
import ActivityRepeatMenu from "../ActivityRepeatMenu";
import IntervalUnit from "enum/IntervalUnit";
import FormValues from "../../types/FormValues";
import RecurringIntervalType from "../../enum/RecurringIntervalType";
import isAnnualInterval from "../../utils/isAnnualInterval";
import useIntervalUnitOptions from "../../utils/useIntervalUnitOptions";
import { formatDate } from "util/formatters";
import { SHOULD_USE_12_HOUR_FORMAT } from "util/consts";
import styles from "./ActivityRepeat.module.scss";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";

interface Props {
  form: FormInstance<FormValues>;
  onChange?: () => void;
}

const messages = defineMessages({
  annually: {
    id: "createActivityModal.footer.scheduleActivity.repeat.annually",
    defaultMessage: "Annually on {date}",
    description: "Annually",
  },
  custom: {
    id: "createActivityModal.footer.scheduleActivity.repeat.custom",
    defaultMessage: "Custom...",
    description: "Custom",
  },
  daily: {
    id: "createActivityModal.footer.scheduleActivity.repeat.daily",
    defaultMessage: "Daily (Weekdays Only)",
    description: "Daily (Weekdays Only)",
  },
  doesNotRepeat: {
    id: "createActivityModal.footer.scheduleActivity.repeat.doesNotRepeat",
    defaultMessage: "Does not repeat",
    description: "Does not repeat",
  },
  everyOn: {
    id: "createActivityModal.footer.scheduleActivity.repeat.everyOn",
    defaultMessage: "Every {number, plural, one {} other {{number} }}{units} on {on}",
    description: "Repeat every number of time units with on",
  },
  every: {
    id: "createActivityModal.footer.scheduleActivity.repeat.every",
    defaultMessage: "Every {number, plural, one {} other {{number} }}{units}",
    description: "Repeat every number of time units",
  },
  excludeWeekends: {
    id: "createActivityModal.footer.scheduleActivity.repeat.excludeWeekends",
    defaultMessage: "Exclude Weekends",
    description: "Exclude Weekends",
  },
  monthly: {
    id: "createActivityModal.footer.scheduleActivity.repeat.monthly",
    defaultMessage: "Monthly on {date}",
    description: "Monthly",
  },
  repeatEvery: {
    id: "createActivityModal.footer.scheduleActivity.repeat.repeatEvery",
    defaultMessage: "Repeat every",
    description: "Repeat every",
  },
  title: {
    id: "createActivityModal.footer.scheduleActivity.repeat.title",
    defaultMessage: "Custom Interval",
    description: "Custom Interval",
  },
  tooltip: {
    id: "createActivityModal.footer.scheduleActivity.repeat.tooltip",
    defaultMessage: "Repeat Activity Options",
    description: "Repeat Activity Options",
  },
  weekly: {
    id: "createActivityModal.footer.scheduleActivity.repeat.weekly",
    defaultMessage: "Weekly on {date}",
    description: "Weekly",
  },
});

const ActivityRepeat: React.FC<Props> = ({ form, onChange }) => {
  const intl = useIntl();

  const recurInterval: FormValues["recurInterval"] = form.getFieldValue("recurInterval");
  const options = useIntervalUnitOptions(recurInterval?.value ?? 1);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleDropdownVisible = useCallback(
    (visible) => {
      if (visible) {
        setPopoverVisible(false);
        setTooltipVisible(false);
      }
      setDropdownVisible(visible);
    },
    [setDropdownVisible, setPopoverVisible, setTooltipVisible]
  );

  const handleRepeatChange = useCallback(
    (changed) => {
      setDropdownVisible(false);

      if (changed.recurIntervalType === RecurringIntervalType.CUSTOM) {
        setPopoverVisible(true);
      }

      onChange?.();
    },
    [onChange, setPopoverVisible]
  );

  const dateValue = form.getFieldValue(ActivityFieldName.START_AT);
  const isValidDate = dateValue && Date.parse(dateValue.toString());

  if (!isValidDate) {
    return null;
  }

  const dateWeekly = formatDate(dateValue, "EEEE");
  const dateMonthly = formatDate(dateValue, "do");
  const dateAnnually = formatDate(dateValue, "MMMM, do");
  const dateDaily = formatDate(dateValue, SHOULD_USE_12_HOUR_FORMAT ? "hh:mm aa" : "HH:mm");

  return (
    <div className={styles.container}>
      <Form.Item
        shouldUpdate={({ isRecurrent, recurIntervalType, recurInterval }, curValues) =>
          isRecurrent !== curValues.isRecurrent ||
          recurIntervalType !== curValues.recurIntervalType ||
          recurInterval?.value !== curValues.recurInterval?.value ||
          recurInterval?.unit !== curValues.recurInterval?.unit ||
          recurInterval?.excludeWeekends !== curValues.recurInterval?.excludeWeekends
        }
        noStyle
      >
        {({ getFieldValue }) => {
          const recurIntervalType = getFieldValue("recurIntervalType");
          const recurInterval = getFieldValue("recurInterval");
          const recurUnit = String(recurInterval?.unit);

          let intervalDescription: string = intl.formatMessage(messages.doesNotRepeat);

          switch (recurIntervalType) {
            case RecurringIntervalType.DAILY:
              intervalDescription = intl.formatMessage(messages.daily);
              break;
            case RecurringIntervalType.WEEKLY:
              intervalDescription = intl.formatMessage(messages.weekly, { date: dateWeekly });
              break;
            case RecurringIntervalType.MONTHLY:
              intervalDescription = intl.formatMessage(messages.monthly, { date: dateMonthly });
              break;
            case RecurringIntervalType.CUSTOM:
              intervalDescription = intl.formatMessage(messages.custom);

              if (recurUnit && recurInterval?.value) {
                if (isAnnualInterval({ recurInterval, recurIntervalType })) {
                  intervalDescription = intl.formatMessage(messages.annually, {
                    date: dateAnnually,
                  });
                } else {
                  const unit = options.find((unit) => unit.key === recurUnit);
                  if (unit?.label && !isNaN(recurInterval.value)) {
                    switch (recurUnit) {
                      case IntervalUnit.HOUR:
                        intervalDescription = String(
                          intl.formatMessage(messages.every, {
                            number: recurInterval.value,
                            units: unit.label,
                          })
                        );
                        break;

                      case IntervalUnit.DAY:
                        intervalDescription = String(
                          intl.formatMessage(messages.everyOn, {
                            number: recurInterval.value,
                            on: dateDaily,
                            units: unit.label,
                          })
                        );
                        break;

                      case IntervalUnit.WEEK:
                        intervalDescription = String(
                          intl.formatMessage(messages.everyOn, {
                            number: recurInterval.value,
                            on: dateWeekly,
                            units: unit.label,
                          })
                        );
                        break;

                      case IntervalUnit.MONTH:
                        intervalDescription = String(
                          intl.formatMessage(messages.everyOn, {
                            number: recurInterval.value,
                            on: dateMonthly,
                            units: unit.label,
                          })
                        );
                        break;

                      case IntervalUnit.YEAR:
                        intervalDescription = String(
                          intl.formatMessage(messages.everyOn, {
                            number: recurInterval.value,
                            on: dateAnnually,
                            units: unit.label,
                          })
                        );
                        break;
                    }
                  }
                }
              }

              break;
          }

          return (
            <>
              <div className={styles.icon} />

              <Popover
                content={
                  <div className={styles.body}>
                    <div className={styles.title}>{intl.formatMessage(messages.title)}</div>
                    <div className={styles.content}>
                      <div className={styles.settings}>
                        <div className={styles.label}>
                          {intl.formatMessage(messages.repeatEvery)}
                        </div>

                        <Form.Item name={["recurInterval", "value"]} noStyle>
                          <InputNumber className={styles.number} min={1} max={36} />
                        </Form.Item>

                        <Form.Item name={["recurInterval", "unit"]} noStyle>
                          <Select
                            className={styles.unit}
                            dropdownMatchSelectWidth
                            options={options}
                          />
                        </Form.Item>
                      </div>

                      {recurUnit === IntervalUnit.DAY && (
                        <Form.Item name={["recurInterval", "excludeWeekends"]} noStyle>
                          <Checkbox className={styles.checkbox}>
                            {intl.formatMessage(messages.excludeWeekends)}
                          </Checkbox>
                        </Form.Item>
                      )}
                    </div>
                  </div>
                }
                onOpenChange={setPopoverVisible}
                open={popoverVisible ? popoverVisible : undefined}
                overlayClassName={styles.popover}
                placement={"topLeft"}
                trigger={popoverVisible ? "click" : []}
              >
                <div>
                  <Dropdown
                    arrow={false}
                    dropdownRender={() => (
                      <ActivityRepeatMenu form={form} onChange={handleRepeatChange} />
                    )}
                    onOpenChange={handleDropdownVisible}
                    open={dropdownVisible}
                    placement="topLeft"
                    trigger={["click"]}
                  >
                    <Tooltip
                      onOpenChange={setTooltipVisible}
                      open={tooltipVisible}
                      overlayClassName={styles.tooltip}
                      placement="top"
                      title={intl.formatMessage(messages.tooltip)}
                      trigger={dropdownVisible ? [] : ["hover"]}
                    >
                      <Button className={cn(styles.btn, { [styles.active]: dropdownVisible })}>
                        {intervalDescription}
                      </Button>
                    </Tooltip>
                  </Dropdown>
                </div>
              </Popover>
            </>
          );
        }}
      </Form.Item>
    </div>
  );
};

export default ActivityRepeat;

import React from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import cn from "classnames";
import styles from "./FrequencyStatusFilter.module.scss";
import { AnyEntity, EntityTypesSupportingFilteringBy } from "@mapmycustomers/shared/types/entity";
import EntityMultiSelectField from "component/input/EntityMultiSelectField";

export const ENTITY_FILTER_OPERATORS = [FilterOperator.IN_ANY];

interface EntityFilterProps extends IFilterComponentProps {}

const getEntityFilter = (
  entityType: EntityTypesSupportingFilteringBy,
  supportedOperators: FilterOperator[] = ENTITY_FILTER_OPERATORS
): IFilterInstance => {
  return {
    doesSupportValue: (value: any, operator: FilterOperator) =>
      Array.isArray(value) &&
      value.every((item) => typeof item === "number") &&
      supportedOperators.includes(operator),
    getComponent:
      (): React.FC<EntityFilterProps> =>
      ({ className, onChange, value }) => {
        return (
          <div className={cn(styles.container)}>
            <EntityMultiSelectField
              className={className}
              onChange={(entityIds: AnyEntity["id"][]) =>
                onChange?.({ ...value, value: entityIds })
              }
              value={Array.isArray(value.value) ? value.value : []}
              entityType={entityType}
            />
          </div>
        );
      },
  };
};

export default getEntityFilter;

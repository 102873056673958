import CheckInVisibility from "@mapmycustomers/shared/enum/CheckInVisibility";
import { defineMessages, IntlShape } from "react-intl";

export const messages = defineMessages<CheckInVisibility>({
  [CheckInVisibility.PRIVATE]: {
    id: "checkInVisibility.private",
    defaultMessage: "Organization owner only",
    description: "Owner option for the check in visibility for organization activities settings",
  },
  [CheckInVisibility.SHARED_WITH_MANAGERS]: {
    id: "checkInVisibility.sharedWithManagers",
    defaultMessage: "Team managers and owner",
    description:
      "Share with managers option for the check in visibility for organization activities settings",
  },
  [CheckInVisibility.SHARED_WITH_ORGANIZATION]: {
    id: "checkInVisibility.sharedWithOrganization",
    defaultMessage: "All users",
    description: "Public option for the check in visibility for organization activities settings",
  },
});

const getCheckInVisibilityDisplayName = (intl: IntlShape, checkInVisibility: CheckInVisibility) =>
  intl.formatMessage(messages[checkInVisibility]);

export default getCheckInVisibilityDisplayName;

import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import UserRef from "@mapmycustomers/shared/types/User";
import Button from "antd/es/button";
import Modal from "component/modal";
import EditProfilePhoto from "../../../EditProfilePhoto";
import styles from "./UserProfileInfo.module.scss";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { Avatar } from "@mapmycustomers/ui";
import navbarAnalytics from "../../../../util/analytic/navbarAnalytics";

interface Props {
  user: UserRef;
}

const UserProfileInfo: React.FC<Props> = ({ user }) => {
  const intl = useIntl();
  const [isEditProfilePhotoModalVisible, showEditProfilePhotoModal, hideEditProfilePhotoModal] =
    useBoolean();

  const handleAvatarClick = useCallback(() => {
    showEditProfilePhotoModal();
    navbarAnalytics.clicked(["Profile Picture"]);
  }, [showEditProfilePhotoModal]);

  return (
    <div className={styles.userProfile}>
      <Avatar user={user} />
      <div className={styles.userProfileOverlay} onClick={handleAvatarClick}>
        <FormattedMessage
          id="navbar.userMenu.editProfilePhoto.overlay"
          defaultMessage="Edit"
          description="Action name in the profile photo overlay inside the user menu."
        />
      </div>

      <section className={styles.userDetails}>
        <div>{user.fullName}</div>
        <div>{user.username}</div>
      </section>

      <Modal
        title={intl.formatMessage({
          id: "navbar.userMenu.editProfilePhoto.modal",
          defaultMessage: "Edit Profile Photo",
          description: "Title of the edit profile photo modal.",
        })}
        onCancel={hideEditProfilePhotoModal}
        visible={isEditProfilePhotoModalVisible}
        footer={
          <Button onClick={hideEditProfilePhotoModal}>
            <FormattedMessage
              id="navbar.userMenu.editProfilePhoto.modal.close"
              defaultMessage="Close"
              description="Close button title in the edit profile photo modal."
            />
          </Button>
        }
      >
        <div className={styles.editProfilePhotoModalContainer}>
          <EditProfilePhoto user={user} />
        </div>
      </Modal>
    </div>
  );
};

export default UserProfileInfo;

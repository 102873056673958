import React from "react";
import Modal from "component/modal";
import Title from "./Title";
import EntitiesTable from "./EntitiesTable";
import styles from "./CadenceModal.module.scss";
import { OutOfCadenceEntity } from "@mapmycustomers/shared/types/entity/activities/OutOfCadenceEntity";

interface Props {
  cadencedAt: string;
  entities: OutOfCadenceEntity[];
  loading?: boolean;
  onHide: () => void;
  title?: string;
}

const CadenceModal: React.FC<Props> = ({ cadencedAt, entities, loading, onHide, title }) => (
  <Modal
    footer={null}
    onCancel={onHide}
    title={<Title cadencedAt={cadencedAt} title={title} />}
    type="none"
    width="clamp(500px, 80vw, 1280px)"
    visible
  >
    <EntitiesTable
      cadencedAt={cadencedAt}
      className={styles.table}
      entities={entities}
      loading={loading}
    />
  </Modal>
);

export default CadenceModal;

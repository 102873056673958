import { defineMessages, IntlShape } from "react-intl";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import loggingService from "util/logging";

const lowercaseSingularMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.activity",
    description: "Name of the Activity entity type, lowercase singular",
    defaultMessage: "activity",
  },
  [EntityType.COMPANY]: {
    id: "entityType.company",
    description: "Name of the Company entity type, lowercase singular",
    defaultMessage: "company",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.companyGroup",
    description: "Name of the Company Group entity type, lowercase singular",
    defaultMessage: "company group",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.companyRoute",
    description: "Name of the Company Route entity type, lowercase singular",
    defaultMessage: "company route",
  },
  [EntityType.DEAL]: {
    id: "entityType.deal",
    description: "Name of the Deal entity type, lowercase singular",
    defaultMessage: "deal",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.dealGroup",
    description: "Name of the Deal Group entity type, lowercase singular",
    defaultMessage: "deal group",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.integration",
    description: "Name of the Integration entity type, lowercase singular",
    defaultMessage: "integration",
  },
  [EntityType.LEAD]: {
    id: "entityType.lead",
    description: "Name of the Lead entity type, lowercase singular",
    defaultMessage: "lead",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.leaderboard",
    description: "Name of the Leaderboard entity type, lowercase singular",
    defaultMessage: "stack rank",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.peopleGroup",
    description: "Name of the People Group entity type, lowercase singular",
    defaultMessage: "people group",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.peopleRoute",
    description: "Name of the People Route entity type, lowercase singular",
    defaultMessage: "people route",
  },
  [EntityType.PERSON]: {
    id: "entityType.person",
    description: "Name of the Person entity type, lowercase singular",
    defaultMessage: "person",
  },
  [EntityType.PIN]: {
    id: "entityType.pin",
    description: "Name of the Pin entity type, lowercase singular",
    defaultMessage: "pin",
  },
  [EntityType.ROUTE]: {
    id: "entityType.route",
    description: "Name of the Route entity type, lowercase singular",
    defaultMessage: "route",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.territory",
    description: "Name of the Territory entity type, lowercase singular",
    defaultMessage: "territory",
  },
  [EntityType.HISTORY]: {
    id: "entityType.history",
    description: "Name of the history entity type, lowercase singular",
    defaultMessage: "history",
  },
});

const lowercasePluralMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.activities",
    description: "Name of the Activity entity type, lowercase plural",
    defaultMessage: "activities",
  },
  [EntityType.COMPANY]: {
    id: "entityType.companies",
    description: "Name of the Company entity type, lowercase plural",
    defaultMessage: "companies",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.companyGroups",
    description: "Name of the Company Group entity type, lowercase plural",
    defaultMessage: "company groups",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.companyRoutes",
    description: "Name of the Company Route entity type, lowercase plural",
    defaultMessage: "company routes",
  },
  [EntityType.DEAL]: {
    id: "entityType.deals",
    description: "Name of the Deal entity type, lowercase plural",
    defaultMessage: "deals",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.dealGroups",
    description: "Name of the Deal Group entity type, lowercase plural",
    defaultMessage: "deal groups",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.integrations",
    description: "Name of the Integration entity type, lowercase plural",
    defaultMessage: "integrations",
  },
  [EntityType.LEAD]: {
    id: "entityType.leads",
    description: "Name of the Lead entity type, lowercase plural",
    defaultMessage: "leads",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.leaderboards",
    description: "Name of the Leaderboard entity type, lowercase plural",
    defaultMessage: "stack ranks",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.peopleGroups",
    description: "Name of the People Group entity type, lowercase plural",
    defaultMessage: "people groups",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.peopleRoutes",
    description: "Name of the People Route entity type, lowercase plural",
    defaultMessage: "people routes",
  },
  [EntityType.PERSON]: {
    id: "entityType.people",
    description: "Name of the Person entity type, lowercase plural",
    defaultMessage: "people",
  },
  [EntityType.PIN]: {
    id: "entityType.pins",
    description: "Name of the Pin entity type, lowercase plural",
    defaultMessage: "pins",
  },
  [EntityType.ROUTE]: {
    id: "entityType.routes",
    description: "Name of the Route entity type, lowercase plural",
    defaultMessage: "routes",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.territories",
    description: "Name of the Territory entity type, lowercase plural",
    defaultMessage: "territories",
  },
  [EntityType.HISTORY]: {
    id: "entityType.histories",
    description: "Name of the history entity type, lowercase plural",
    defaultMessage: "history rows",
  },
});

const capitalizedSingularMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.Activity",
    description: "Name of the Activity entity type, capitalized singular",
    defaultMessage: "Activity",
  },
  [EntityType.COMPANY]: {
    id: "entityType.Company",
    description: "Name of the Company entity type, capitalized singular",
    defaultMessage: "Company",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.CompanyGroup",
    description: "Name of the Company Group entity type, capitalized singular",
    defaultMessage: "Company group",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.CompanyRoute",
    description: "Name of the Company Route entity type, capitalized singular",
    defaultMessage: "Company route",
  },
  [EntityType.DEAL]: {
    id: "entityType.Deal",
    description: "Name of the Deal entity type, capitalized singular",
    defaultMessage: "Deal",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.DealGroup",
    description: "Name of the Deal Group entity type, capitalized singular",
    defaultMessage: "Deal group",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.Integration",
    description: "Name of the Integration entity type, capitalized singular",
    defaultMessage: "Integration",
  },
  [EntityType.LEAD]: {
    id: "entityType.Lead",
    description: "Name of the Lead entity type, capitalized singular",
    defaultMessage: "Lead",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.Leaderboard",
    description: "Name of the Leaderboard entity type, capitalized singular",
    defaultMessage: "Stack Rank",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.PeopleGroup",
    description: "Name of the People Group entity type, capitalized singular",
    defaultMessage: "People group",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.PeopleRoute",
    description: "Name of the People Route entity type, capitalized singular",
    defaultMessage: "People route",
  },
  [EntityType.PERSON]: {
    id: "entityType.Person",
    description: "Name of the Person entity type, capitalized singular",
    defaultMessage: "Person",
  },
  [EntityType.PIN]: {
    id: "entityType.Pin",
    description: "Name of the Pin entity type, capitalized singular",
    defaultMessage: "Pin",
  },
  [EntityType.ROUTE]: {
    id: "entityType.Route",
    description: "Name of the Route entity type, capitalized singular",
    defaultMessage: "Route",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.Territory",
    description: "Name of the Territory entity type, capitalized singular",
    defaultMessage: "Territory",
  },
  [EntityType.HISTORY]: {
    id: "entityType.History",
    description: "Name of the history entity type, capitalized singular",
    defaultMessage: "History",
  },
});

const capitalizedPluralMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.Activities",
    description: "Name of the Activity entity type, capitalized plural",
    defaultMessage: "Activities",
  },
  [EntityType.COMPANY]: {
    id: "entityType.Companies",
    description: "Name of the Company entity type, capitalized plural",
    defaultMessage: "Companies",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.CompanyGroups",
    description: "Name of the Company Group entity type, capitalized plural",
    defaultMessage: "Company groups",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.CompanyRoutes",
    description: "Name of the Company Route entity type, capitalized plural",
    defaultMessage: "Company routes",
  },
  [EntityType.DEAL]: {
    id: "entityType.Deals",
    description: "Name of the Deal entity type, capitalized plural",
    defaultMessage: "Deals",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.DealGroups",
    description: "Name of the Deal Group entity type, capitalized plural",
    defaultMessage: "Deal groups",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.Integrations",
    description: "Name of the Integration entity type, capitalized plural",
    defaultMessage: "Integrations",
  },
  [EntityType.LEAD]: {
    id: "entityType.Leads",
    description: "Name of the Lead entity type, capitalized plural",
    defaultMessage: "Leads",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.Leaderboards",
    description: "Name of the Leaderboard entity type, capitalized plural",
    defaultMessage: "Stack Ranks",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.PeopleGroups",
    description: "Name of the People Group entity type, capitalized plural",
    defaultMessage: "People groups",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.PeopleRoutes",
    description: "Name of the People Route entity type, capitalized plural",
    defaultMessage: "People routes",
  },
  [EntityType.PERSON]: {
    id: "entityType.People",
    description: "Name of the Person entity type, capitalized plural",
    defaultMessage: "People",
  },
  [EntityType.PIN]: {
    id: "entityType.Pins",
    description: "Name of the Pin entity type, capitalized plural",
    defaultMessage: "Pins",
  },
  [EntityType.ROUTE]: {
    id: "entityType.Routes",
    description: "Name of the Route entity type, capitalized plural",
    defaultMessage: "Routes",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.Territories",
    description: "Name of the Territory entity type, capitalized plural",
    defaultMessage: "Territories",
  },
  [EntityType.HISTORY]: {
    id: "entityType.HistoryRows",
    description: "Name of the history entity type, capitalized singular",
    defaultMessage: "History Rows",
  },
});

const lowercaseGenitiveMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.activities.genitive",
    description: "Name of the Activity entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {activity} other {activities}}",
  },
  [EntityType.COMPANY]: {
    id: "entityType.companies.genitive",
    description: "Name of the Company entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {company} other {companies}}",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.companyGroups.genitive",
    description: "Name of the Company Group entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {company group} other {company groups}}",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.companyRoutes.genitive",
    description: "Name of the Company Route entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {company route} other {company routes}}",
  },
  [EntityType.DEAL]: {
    id: "entityType.deals.genitive",
    description: "Name of the Deal entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {deal} other {deals}}",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.dealGroups.genitive",
    description: "Name of the Deal Group entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {deal group} other {deal groups}}",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.integrations.genitive",
    description: "Name of the Integration entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {integration} other {integrations}}",
  },
  [EntityType.LEAD]: {
    id: "entityType.leads.genitive",
    description: "Name of the Lead entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {lead} other {leads}}",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.leaderboards.genitive",
    description: "Name of the Leaderboard entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {stack rank} other {stack ranks}}",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.peopleGroups.genitive",
    description: "Name of the People Group entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {people group} other {people groups}}",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.peopleRoutes.genitive",
    description: "Name of the People Route entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {people route} other {people routes}}",
  },
  [EntityType.PERSON]: {
    id: "entityType.people.genitive",
    description: "Name of the Person entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {person} other {people}}",
  },
  [EntityType.PIN]: {
    id: "entityType.pin.genitive",
    description: "Name of the Pin entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {pin} other {pins}}",
  },
  [EntityType.ROUTE]: {
    id: "entityType.routes.genitive",
    description: "Name of the Route entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {route} other {routes}}",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.territories.genitive",
    description: "Name of the Territory entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {territory} other {territories}}",
  },
  [EntityType.HISTORY]: {
    id: "entityType.historyRows.genitive",
    description: "Name of the history entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {history row} other {history rows}}",
  },
});

const capitalizedGenitiveMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.Activities.genitive",
    description: "Name of the Activity entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Activity} other {Activities}}",
  },
  [EntityType.COMPANY]: {
    id: "entityType.Companies.genitive",
    description: "Name of the Company entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Company} other {Companies}}",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.CompanyGroups.genitive",
    description: "Name of the Company Group entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Company group} other {Company groups}}",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.CompanyRoutes.genitive",
    description: "Name of the Company Route entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Company route} other {Company routes}}",
  },
  [EntityType.DEAL]: {
    id: "entityType.Deals.genitive",
    description: "Name of the Deal entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Deal} other {Deals}}",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.DealGroups.genitive",
    description: "Name of the Deal Group entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Deal group} other {Deal groups}}",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.Integrations.genitive",
    description: "Name of the Integration entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Integration} other {Integrations}}",
  },
  [EntityType.LEAD]: {
    id: "entityType.Leads.genitive",
    description: "Name of the Lead entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Lead} other {Leads}}",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.Leaderboards.genitive",
    description: "Name of the Leaderboard entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Stack Rank} other {Stack Ranks}}",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.PeopleGroups.genitive",
    description: "Name of the People Group entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {People group} other {People groups}}",
  },
  [EntityType.PERSON]: {
    id: "entityType.People.genitive",
    description: "Name of the Person entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Person} other {People}}",
  },
  [EntityType.PIN]: {
    id: "entityType.Pin.genitive",
    description: "Name of the Pin entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Pin} other {Pins}}",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.PeopleRoutes.genitive",
    description: "Name of the People Route entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {People route} other {People routes}}",
  },
  [EntityType.ROUTE]: {
    id: "entityType.Routes.genitive",
    description: "Name of the Route entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Route} other {Routes}}",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.Territories.genitive",
    description: "Name of the Territory entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {Territory} other {Territories}}",
  },
  [EntityType.HISTORY]: {
    id: "entityType.HistoryRows.genitive",
    description: "Name of the history entity type, capitalized genitive",
    defaultMessage: "{count, plural, one {History Row} other {History Hows}}",
  },
});

const lowercaseAccusativeMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.activities.accusative",
    description: "Name of the Activity entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {activity} other {activities}}",
  },
  [EntityType.COMPANY]: {
    id: "entityType.companies.accusative",
    description: "Name of the Company entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {company} other {companies}}",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.companyGroups.accusative",
    description: "Name of the Company Group entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {company group} other {company groups}}",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.companyRoutes.accusative",
    description: "Name of the Company Route entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {company route} other {company routes}}",
  },
  [EntityType.DEAL]: {
    id: "entityType.deals.accusative",
    description: "Name of the Deal entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {deal} other {deals}}",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.dealGroups.accusative",
    description: "Name of the Deal Group entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {deal group} other {deal groups}}",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.integrations.accusative",
    description: "Name of the Integration entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {integration} other {integrations}}",
  },
  [EntityType.LEAD]: {
    id: "entityType.leads.accusative",
    description: "Name of the Lead entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {lead} other {leads}}",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.leaderboards.accusative",
    description: "Name of the Leaderboard entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {stack rank} other {stack ranks}}",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.peopleGroups.accusative",
    description: "Name of the People Group entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {people group} other {people groups}}",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.peopleRoutes.accusative",
    description: "Name of the People Route entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {people route} other {people routes}}",
  },
  [EntityType.PERSON]: {
    id: "entityType.people.accusative",
    description: "Name of the Person entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {person} other {people}}",
  },
  [EntityType.PIN]: {
    id: "entityType.pin.accusative",
    description: "Name of the Pin entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {pin} other {pins}}",
  },
  [EntityType.ROUTE]: {
    id: "entityType.routes.accusative",
    description: "Name of the Route entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {route} other {routes}}",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.territories.accusative",
    description: "Name of the Territory entity type, lowercase accusative",
    defaultMessage: "{count, plural, one {territory} other {territories}}",
  },
  [EntityType.HISTORY]: {
    id: "entityType.historyRows.accusative",
    description: "Name of the history entity type, lowercase genitive",
    defaultMessage: "{count, plural, one {history row} other {history rows}}",
  },
});

const capitalizedAccusativeMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.Activities.accusative",
    description: "Name of the Activity entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Activity} other {Activities}}",
  },
  [EntityType.COMPANY]: {
    id: "entityType.Companies.accusative",
    description: "Name of the Company entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Company} other {Companies}}",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.CompanyGroups.accusative",
    description: "Name of the Company Group entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Company group} other {Company groups}}",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.CompanyRoutes.accusative",
    description: "Name of the Company Route entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Company route} other {Company routes}}",
  },
  [EntityType.DEAL]: {
    id: "entityType.Deals.accusative",
    description: "Name of the Deal entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Deal} other {Deals}}",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.DealGroups.accusative",
    description: "Name of the Deal Group entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Deal group} other {Deal groups}}",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.Integrations.accusative",
    description: "Name of the Integration entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Integration} other {Integrations}}",
  },
  [EntityType.LEAD]: {
    id: "entityType.Leads.accusative",
    description: "Name of the Lead entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Lead} other {Leads}}",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.Leaderboards.accusative",
    description: "Name of the Leaderboard entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Stack Rank} other {Stack Ranks}}",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.PeopleGroups.accusative",
    description: "Name of the People Group entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {People group} other {People groups}}",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.PeopleRoutes.accusative",
    description: "Name of the People Route entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {People route} other {People routes}}",
  },
  [EntityType.PERSON]: {
    id: "entityType.People.accusative",
    description: "Name of the Person entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Person} other {People}}",
  },
  [EntityType.PIN]: {
    id: "entityType.Pin.accusative",
    description: "Name of the Pin entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Pin} other {Pins}}",
  },
  [EntityType.ROUTE]: {
    id: "entityType.Routes.accusative",
    description: "Name of the Route entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Route} other {Routes}}",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.Territories.accusative",
    description: "Name of the Territory entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {Territory} other {Territories}}",
  },
  [EntityType.HISTORY]: {
    id: "entityType.HistoryRows.accusative",
    description: "Name of the history entity type, capitalized accusative",
    defaultMessage: "{count, plural, one {History Row} other {History Rows}}",
  },
});

const lowercaseDativeMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.activities.dative",
    description: "Name of the Activity entity type, lowercase dative",
    defaultMessage: "{count, plural, one {activity} other {activities}}",
  },
  [EntityType.COMPANY]: {
    id: "entityType.companies.dative",
    description: "Name of the Company entity type, lowercase dative",
    defaultMessage: "{count, plural, one {company} other {companies}}",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.companyGroups.dative",
    description: "Name of the Company Group entity type, lowercase dative",
    defaultMessage: "{count, plural, one {company group} other {company groups}}",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.companyRoutes.dative",
    description: "Name of the Company Route entity type, lowercase dative",
    defaultMessage: "{count, plural, one {company route} other {company routes}}",
  },
  [EntityType.DEAL]: {
    id: "entityType.deals.dative",
    description: "Name of the Deal entity type, lowercase dative",
    defaultMessage: "{count, plural, one {deal} other {deals}}",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.dealGroups.dative",
    description: "Name of the Deal Group entity type, lowercase dative",
    defaultMessage: "{count, plural, one {deal group} other {deal groups}}",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.integrations.dative",
    description: "Name of the Integration entity type, lowercase dative",
    defaultMessage: "{count, plural, one {integration} other {integrations}}",
  },
  [EntityType.LEAD]: {
    id: "entityType.leads.dative",
    description: "Name of the Lead entity type, lowercase dative",
    defaultMessage: "{count, plural, one {lead} other {leads}}",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.leaderboards.dative",
    description: "Name of the Leaderboard entity type, lowercase dative",
    defaultMessage: "{count, plural, one {stack rank} other {stack ranks}}",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.peopleGroups.dative",
    description: "Name of the People Group entity type, lowercase dative",
    defaultMessage: "{count, plural, one {people group} other {people groups}}",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.peopleRoutes.dative",
    description: "Name of the People Route entity type, lowercase dative",
    defaultMessage: "{count, plural, one {people route} other {people routes}}",
  },
  [EntityType.PERSON]: {
    id: "entityType.people.dative",
    description: "Name of the Person entity type, lowercase dative",
    defaultMessage: "{count, plural, one {person} other {people}}",
  },
  [EntityType.PIN]: {
    id: "entityType.pin.dative",
    description: "Name of the Pin entity type, lowercase dative",
    defaultMessage: "{count, plural, one {pin} other {pins}}",
  },
  [EntityType.ROUTE]: {
    id: "entityType.routes.dative",
    description: "Name of the Route entity type, lowercase dative",
    defaultMessage: "{count, plural, one {route} other {routes}}",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.territories.dative",
    description: "Name of the Territory entity type, lowercase dative",
    defaultMessage: "{count, plural, one {territory} other {territories}}",
  },
  [EntityType.HISTORY]: {
    id: "entityType.historyRows.dative",
    description: "Name of the history entity type, lowercase dative",
    defaultMessage: "{count, plural, one {history row} other {history rows}}",
  },
});

const capitalizedDativeMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.Activities.dative",
    description: "Name of the Activity entity type, capitalized dative",
    defaultMessage: "{count, plural, one {Activity} other {Activities}}",
  },
  [EntityType.COMPANY]: {
    id: "entityType.Companies.dative",
    description: "Name of the Company entity type, capitalized dative",
    defaultMessage: "{count, plural, one {Company} other {Companies}}",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.CompanyGroups.dative",
    description: "Name of the Company Group entity type, capitalized dative",
    defaultMessage: "{count, plural, one {Company group} other {Company groups}}",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.CompanyRoutes.dative",
    description: "Name of the Company Route entity type, capitalized dative",
    defaultMessage: "{count, plural, one {Company route} other {Company routes}}",
  },
  [EntityType.DEAL]: {
    id: "entityType.Deals.dative",
    description: "Name of the Deal entity type, capitalized dative",
    defaultMessage: "{count, plural, one {Deal} other {Deals}}",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.DealGroups.dative",
    description: "Name of the Deal Group entity type, capitalized dative",
    defaultMessage: "{count, plural, one {Deal group} other {Deal groups}}",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.Integrations.dative",
    description: "Name of the Integration entity type, capitalized dative",
    defaultMessage: "{count, plural, one {Integration} other {Integrations}}",
  },
  [EntityType.LEAD]: {
    id: "entityType.Leads.dative",
    description: "Name of the Lead entity type, capitalized dative",
    defaultMessage: "{count, plural, one {Lead} other {Leads}}",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.Leaderboards.dative",
    description: "Name of the Leaderboard entity type, capitalized dative",
    defaultMessage: "{count, plural, one {Stack Rank} other {Stack Ranks}}",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.PeopleGroups.dative",
    description: "Name of the People Group entity type, capitalized dative",
    defaultMessage: "{count, plural, one {People group} other {People groups}}",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.PeopleRoutes.dative",
    description: "Name of the People Route entity type, capitalized dative",
    defaultMessage: "{count, plural, one {People route} other {People routes}}",
  },
  [EntityType.PERSON]: {
    id: "entityType.People.dative",
    description: "Name of the Person entity type, capitalized dative",
    defaultMessage: "{count, plural, one {Person} other {People}}",
  },
  [EntityType.PIN]: {
    id: "entityType.Pin.dative",
    description: "Name of the Pin entity type, lowercase dative",
    defaultMessage: "{count, plural, one {Pin} other {Pins}}",
  },
  [EntityType.ROUTE]: {
    id: "entityType.Routes.dative",
    description: "Name of the Route entity type, capitalized dative",
    defaultMessage: "{count, plural, one {Route} other {Routes}}",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.Territories.dative",
    description: "Name of the Territory entity type, capitalized dative",
    defaultMessage: "{count, plural, one {Territory} other {Territories}}",
  },
  [EntityType.HISTORY]: {
    id: "entityType.HistoryRows.dative",
    description: "Name of the history entity type, capitalized dative",
    defaultMessage: "{count, plural, one {History Row} other {History Rows}}",
  },
});

/**
 * Try to avoid using this function as it is stupid and doesn't work very well.
 * Two main reasons:
 * 1) it works good when you need lowercase or uppercase plural or singular form of a word,
 * but when used in sentence, you most of a time need more than that. You need different
 * cases, hence simple logic only works when you need a standalone entity display name.
 * 2) Though it kinda supports cases now, it does that incorrectly, because case depends
 * on count _and_ word itself. It is not obvious in English and other languages with
 * simpler grammar, but is clearly visible in other ones, e.g. Russian. So you don't know in
 * advance which case you need in this or that sentence.
 *     For those who speaks Russian, some examples:
 *       Company, Companies -> Компания, Компании (именительный)
 *       Delete Company -> Удалить компанию (винительный)
 *       Delete Companies -> Удалить компании (винительный)
 *       Delete 1 company, Delete 2 companies -> Удалить 1 компанию (винительный), Удалить 2 компании (винительный), Удалить 5 компаний (родительный)
 *       Person, People -> Контакт, Контакты (именительный)
 *       Delete Person -> Удалить контакт (винительный)
 *       Delete People -> Удалить контакты (винительный)
 *       Delete 1 Person, Delete 2 People -> Удалить 1 контакт (винительный), Удалить 2 контакта (родительный), Удалить 5 контактов (родительный)
 *     Looking at cases in parentheses it is clearly visible that there's no universal way to determine case
 *     depending on the count. It differs per word, which is especially clear in examples when removing 2
 *     companies or 2 people.
 *
 * What should I use instead? Well, since FormatJS doesn't support references in translations,
 * you will need to describe all possible variation in each message. On a bright side, usually we
 * only support a limited set of entity types in messages, hence you don't need to add texts for
 * all of them. For example, if it is a "Delete N Records" button somewhere in bulk editing,
 * and we know that we only support companies and people there, you can do the following:
 *
 *     Delete {entityType, select, accounts {{count, plural, one {Company} other {{count} Companies}}} contacts {{count, plural, one {Person} other {{count} People}}} other {{count, plural, one {Record} other {{count} Records}}}}
 *
 * See that "other" case in select is always required, and it's a good way to handle all other
 * entity types in case when bulk editing will support more. In that case we at least will get a
 * readable message.
 *
 * Of course, you can do grouping by count first and get the same result after all, up to you
 * (in this example this approach creates a shorter message):
 *
 *     Delete {count, select, one {{entityType, select, accounts {Company} contacts {Person} other {Record}}} other {{count} {entityType, select, accounts {Companies} contacts {People} other {Records}}}}
 *
 * And obviously, this message can be different for other languages which, for example,
 * support more than 2 form of plurals:
 *
 *     Удалить {count, select, one {{entityType, select, accounts {компанию} contacts {контакт} other {запись}}} many {{count} {entityType, select, accounts {компаний} contacts {контактов} other {записей}}} other {{count} {entityType, select, accounts {компании} contacts {контакта} other {записи}}}}
 *
 * @deprecated
 */
export const getEntityTypeDisplayName = (
  intl: IntlShape,
  entityType: EntityType,
  options:
    | { lowercase: boolean; genitive: number }
    | { lowercase: boolean; accusative: number }
    | { lowercase: boolean; accusativePlural: boolean }
    | { lowercase: boolean; dative: number }
    | { lowercase: boolean; dativePlural: boolean }
    | { lowercase: boolean; plural?: boolean }
): string => {
  // ts-ignore in lines below because these options do not exist simultaneously, but that's ok
  const {
    // @ts-ignore
    accusative,
    // @ts-ignore
    accusativePlural,
    // @ts-ignore
    dative,
    // @ts-ignore
    dativePlural,
    // @ts-ignore
    genitive,
    lowercase,
    // @ts-ignore
    plural,
  } = options;

  let messages;
  let count = 0;
  if (genitive !== undefined) {
    messages = lowercase ? lowercaseGenitiveMessages : capitalizedGenitiveMessages;
    count = genitive;
  } else if (accusative !== undefined || accusativePlural !== undefined) {
    messages = lowercase ? lowercaseAccusativeMessages : capitalizedAccusativeMessages;
    // for accusativePlural it's like accusative, but always plural
    // using arbitrary value 2 to imitate plural
    count = accusative ?? (accusativePlural ? 2 : 1);
  } else if (dative !== undefined || dativePlural !== undefined) {
    messages = lowercase ? lowercaseDativeMessages : capitalizedDativeMessages;
    // for dativePlural it's like dative, but always plural
    // using arbitrary value 2 to imitate plural
    count = dative ?? (dativePlural ? 2 : 1);
  } else if (plural) {
    messages = lowercase ? lowercasePluralMessages : capitalizedPluralMessages;
  } else {
    messages = lowercase ? lowercaseSingularMessages : capitalizedSingularMessages;
  }

  try {
    return intl.formatMessage(messages[entityType], { count });
  } catch (e) {
    loggingService.error("failed entity type format", { e, entityType, messages });
    return "";
  }
};

const analyticsNamesSingular: Record<EntityType, string> = {
  [EntityType.ACTIVITY]: "Activity",
  [EntityType.COMPANY]: "Company",
  [EntityType.COMPANY_GROUP]: "Company Group",
  [EntityType.COMPANY_ROUTE]: "Company Route",
  [EntityType.DEAL]: "Deal",
  [EntityType.DEAL_GROUP]: "Deal Group",
  [EntityType.INTEGRATION]: "Integration",
  [EntityType.LEAD]: "Lead",
  [EntityType.LEADERBOARD]: "Leaderboard Item",
  [EntityType.PEOPLE_GROUP]: "People Group",
  [EntityType.PEOPLE_ROUTE]: "People Route",
  [EntityType.PERSON]: "Person",
  [EntityType.PIN]: "Pin",
  [EntityType.ROUTE]: "Route",
  [EntityType.TERRITORY]: "Territory",
  [EntityType.HISTORY]: "History",
};

const analyticsNamesPlural: Record<EntityType, string> = {
  [EntityType.ACTIVITY]: "Activities",
  [EntityType.COMPANY]: "Companies",
  [EntityType.COMPANY_GROUP]: "Company Groups",
  [EntityType.COMPANY_ROUTE]: "Company Routes",
  [EntityType.DEAL]: "Deals",
  [EntityType.DEAL_GROUP]: "Deal Groups",
  [EntityType.INTEGRATION]: "Integrations",
  [EntityType.LEAD]: "Leads",
  [EntityType.LEADERBOARD]: "Leaderboard Items",
  [EntityType.PEOPLE_GROUP]: "People Groups",
  [EntityType.PEOPLE_ROUTE]: "People Routes",
  [EntityType.PERSON]: "People",
  [EntityType.PIN]: "Pins",
  [EntityType.ROUTE]: "Routes",
  [EntityType.TERRITORY]: "Territories",
  [EntityType.HISTORY]: "History",
};

export const getEntityTypeAnalyticsName = (
  entityType: EntityType,
  lowercase: boolean,
  plural: boolean
): string => {
  const name = (plural ? analyticsNamesPlural : analyticsNamesSingular)[entityType];
  return lowercase ? name.toLowerCase() : name;
};

export default getEntityTypeDisplayName;

import React, { useCallback, useMemo } from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import defaultSelectOptionMapper from "util/mappers/defaultSelectOptionMapper";
import defaultFilterOption from "component/input/utils/defaultFilterOption";
import SelectField from "component/input/SelectField";

export const FUNNEL_FILTER_OPERATORS = [FilterOperator.EQUALS, FilterOperator.IN_ANY];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  (Array.isArray(value) &&
    value.every((item) => typeof item === "number") &&
    operator === FilterOperator.IN_ANY) ||
  (typeof value === "number" && operator === FilterOperator.EQUALS);

interface FunnelFilterProps extends IFilterComponentProps {}

const FunnelFilter: IFilterInstance = {
  doesSupportValue,
  doesSupportOption: (option: FilterOption) => option === FilterOption.AVAILABLE_FUNNELS,
  getComponent:
    (): React.FC<FunnelFilterProps> =>
    ({ className, focus, onChange, options, value }) => {
      const selectOptions = useMemo(
        () => ((options?.availableFunnels ?? []) as Funnel[]).map(defaultSelectOptionMapper),
        [options?.availableFunnels]
      );

      const setRef = useCallback(
        (ref: SelectField<any> | null) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      return value.operator === FilterOperator.EQUALS ? (
        <SelectField<Funnel["id"]>
          className={className}
          filterOption={defaultFilterOption}
          onChange={(funnelId: Funnel["id"]) => onChange?.({ ...value, value: funnelId })}
          options={selectOptions}
          ref={setRef}
          value={typeof value.value === "number" ? value.value : undefined}
        />
      ) : (
        <SelectField<Funnel["id"][]>
          className={className}
          filterOption={defaultFilterOption}
          maxTagCount="responsive"
          mode="multiple"
          onChange={(funnelIds: Funnel["id"][]) => onChange?.({ ...value, value: funnelIds })}
          options={selectOptions}
          ref={setRef}
          value={Array.isArray(value.value) ? value.value : undefined}
        />
      );
    },
};

export default FunnelFilter;

import { ActionType, createAction } from "typesafe-actions";
import EntityViewPayload from "./EntityViewPayload";
import { EntityType } from "@mapmycustomers/shared/enum";

export const showEntityView = createAction("@viewRecord/showEntityView")<EntityViewPayload>();

export const hideEntityView = createAction("@viewRecord/hideEntityView")<void>();

export const setEntityTypesWithExternalPreview = createAction(
  "@viewRecord/setEntityTypesWithExternalPreview"
)<EntityType[]>();

export const showRandomRecord = createAction("@viewRecord/showRandomRecord")<void>();

export type Actions = ActionType<
  | typeof hideEntityView
  | typeof showEntityView
  | typeof setEntityTypesWithExternalPreview
  | typeof showRandomRecord
>;

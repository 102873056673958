import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";
import Path from "enum/Path";

const territoryModeState = (state: RootState) => state.scene.map.territoryMode;

export const getTerritoryMode = createSelector(territoryModeState, ({ mode }) => mode);

export const getTerritory = createSelector(territoryModeState, ({ territory }) => territory);
export const getTerritoryCandidate = createSelector(
  territoryModeState,
  ({ territoryCandidate }) => territoryCandidate
);
export const getTerritoryId = createSelector(getTerritory, (territory) => territory?.id);

export const getTerritoryModeBasePath = createSelector(getTerritoryId, (territoryId) => {
  return `${Path.MAP}/territories/${territoryId}`;
});

export const getTerritoryPinsInBoundsCount = createSelector(
  territoryModeState,
  ({ pinsInBoundsCount, pinsInBoundsCountLoading }) =>
    pinsInBoundsCountLoading ? undefined : pinsInBoundsCount
);

export const getTerritoryMapViewState = createSelector(
  territoryModeState,
  ({ viewState }) => viewState
);

export const getTerritoryVisibleEntities = createSelector(
  getTerritoryMapViewState,
  ({ visibleEntities }) => visibleEntities
);

export const getTerritoryEntries = createSelector(
  territoryModeState,
  ({ categorizedMapEntries }) => categorizedMapEntries
);

export const getExternalTerritoryEntries = createSelector(
  territoryModeState,
  ({ categorizedExternalMapEntries }) => categorizedExternalMapEntries
);

export const getTerritoryRecords = createSelector(territoryModeState, ({ records }) => records);
export const areTerritoryRecordsLoading = createSelector(
  territoryModeState,
  ({ recordsLoading }) => recordsLoading
);
export const getTerritoryRecordsTotalCount = createSelector(
  territoryModeState,
  ({ recordsCount }) => recordsCount
);

export const getTerritoryLassoRecords = createSelector(
  territoryModeState,
  ({ lassoRecords }) => lassoRecords
);
export const areTerritoryLassoRecordsLoading = createSelector(
  territoryModeState,
  ({ lassoRecordsLoading }) => lassoRecordsLoading
);

export const isUpdatingTerritory = createSelector(
  territoryModeState,
  ({ updateLoading }) => updateLoading
);

export const doesTerritoryEditFormHaveChanges = createSelector(
  territoryModeState,
  ({ editFormHasChanges }) => editFormHasChanges
);

export const isTerritoryDataViewVisible = createSelector(
  territoryModeState,
  ({ dataView }) => dataView.visible
);

export const isTerritoryDataViewLoading = createSelector(
  territoryModeState,
  ({ dataView }) => dataView.loading
);

export const getTerritoryDataViewData = createSelector(
  territoryModeState,
  ({ dataView }) => dataView.data
);

export const getTerritoryDataViewEntityTypeCount = createSelector(
  territoryModeState,
  ({ dataView }) => dataView.entityTypeCount
);

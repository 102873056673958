import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Field, { FieldProperties } from "./Field";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FunnelFilter, { FUNNEL_FILTER_OPERATORS } from "util/filters/FunnelFilter";

class FunnelField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      platformFilterName: "funnelId",
      valueGetter: ["funnel", "name"],
      type: FieldType.STRING,
      filterType: FieldType.ID,
      features: [
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.BULK_EDIT,
        FieldFeature.FUNNEL_FIELD,
        FieldFeature.DATA_VIEW,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.REQUIRED,
      ],
      importProperties: { required: true },
      customFilterConfig: {
        defaultInstance: "funnel",
        filterInstances: {
          funnel: FunnelFilter,
        },
        operators: FUNNEL_FILTER_OPERATORS,
      },
      formProperties: {
        fullWidth: true,
      },
      ...data,
    });
  }
}

export default FunnelField;

import { ActionType, createAction } from "typesafe-actions";
import AlertBadgesType from "enum/map/AlertBadgesType";
import PersistentBaseMapLayerData from "@mapmycustomers/shared/types/persistent/PersistentBaseMapLayerData";

export const toggleAlertBadgeVisibility = createAction(
  "map/alertBadges/toggleAlertBadgeVisibility"
)<AlertBadgesType>();

export const setAlertsSettings = createAction("map/alertBadges/setAlertsSettings")<
  PersistentBaseMapLayerData | undefined
>();

export const setAlertDismissed = createAction("map/alertBadges/setAlertDismissed")<{
  alertDismissed: boolean;
}>();

export type AlertBadgesActions = ActionType<
  typeof setAlertsSettings | typeof toggleAlertBadgeVisibility | typeof setAlertDismissed
>;

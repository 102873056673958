import { createReducer } from "typesafe-actions";
import {
  Actions,
  createRoute,
  initializeRouteBuilder,
  removeActivity,
  setActivities,
  setActivityTypes,
  setDateRange,
  setEntityType,
} from "./actions";
import {
  Activity,
  EntityType,
  EntityTypeSupportingRoutes,
} from "@mapmycustomers/shared/types/entity";
import DateRangeType from "scene/activity/component/ActivityCalendarPage/components/Sidebar/RouteBuilder/enum/DateRangeType";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";

const DEFAULT_STATE: RouteBuilderState = {
  activities: [],
  activitiesLoading: false,
  activitiesTotal: 0,
  createLoading: false,
  dateRangeType: DateRangeType.TODAY,
  dateRange: undefined,
  entityType: EntityType.COMPANY,
  removedStops: [],
  selectedActivityTypes: [],
};

export interface RouteBuilderState {
  activities: Activity[];
  activitiesLoading: boolean;
  activitiesTotal: number;
  createLoading: boolean;
  dateRangeType: DateRangeType;
  dateRange?: {
    start: Date;
    end: Date;
  };
  entityType: EntityTypeSupportingRoutes;
  // we store removed stops instead of just removing them from the activities list because activities list
  // might be reloaded. E.g. when user edits invalid stops adding an entity or address to them.
  removedStops: Activity["id"][];
  selectedActivityTypes: ActivityType[];
}

const initialState: RouteBuilderState = {
  ...DEFAULT_STATE,
};

const routeBuilder = createReducer<RouteBuilderState, Actions>(initialState)
  .handleAction(initializeRouteBuilder.request, (state) => ({
    ...state,
    ...DEFAULT_STATE,
  }))
  .handleAction(initializeRouteBuilder.success, (state, { payload }) => ({
    ...state,
    selectedActivityTypes: payload,
  }))
  .handleAction(setActivities, (state, { payload }) => ({
    ...state,
    activities: payload?.activities ?? state.activities,
    activitiesLoading: false,
    activitiesTotal: payload?.total ?? state.activitiesTotal,
  }))
  .handleAction(setEntityType, (state, { payload }) => ({
    ...state,
    activitiesLoading: state.dateRangeType !== DateRangeType.CUSTOM || !!state.dateRange, // only load when date range is defined
    entityType: payload,
  }))
  .handleAction(setDateRange, (state, { payload }) => ({
    ...state,
    activitiesLoading: payload.rangeType !== DateRangeType.CUSTOM || !!payload.range, // only load when date range is defined
    dateRangeType: payload.rangeType,
    dateRange: payload.range,
  }))
  .handleAction(setActivityTypes, (state, { payload }) => ({
    ...state,
    activitiesLoading: state.dateRangeType !== DateRangeType.CUSTOM || !!state.dateRange, // only load when date range is defined
    selectedActivityTypes: payload,
  }))
  .handleAction(removeActivity, (state, { payload }) => {
    const entity = state.entityType === EntityType.COMPANY ? payload.account : payload.contact;
    // if this was one of invalid stops, then just remove it
    if (!entity?.geoPoint) {
      return { ...state, removedStops: [...state.removedStops, payload.id] };
    }
    // otherwise, remove all activities which have the same entity assigned to them
    return {
      ...state,
      removedStops: [
        ...state.removedStops,
        ...state.activities
          .filter(
            ({ account, contact }) =>
              (state.entityType === EntityType.COMPANY ? account : contact)?.id === entity.id
          )
          .map(({ id }) => id),
      ],
    };
  })
  .handleAction(createRoute.request, (state) => ({
    ...state,
    createLoading: true,
  }))
  .handleAction([createRoute.success, createRoute.failure], (state) => ({
    ...state,
    createLoading: false,
  }));

export * from "./selectors";
export type RouteBuilderActions = Actions;
export default routeBuilder;

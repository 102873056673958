import React, { useMemo } from "react";
import EntityTypeShapedIcon from "component/EntityTypeIcon/EntityTypeShapedIcon";
import styles from "./Item.module.scss";
import { EntityType } from "@mapmycustomers/shared/enum";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import GlobalSearchItem, { GlobalSearchActivity } from "types/globalSearch/GlobalSearchItem";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons/faRedoAlt";
import GlobalSearchItemType from "enum/GlobalSearchItemType";
import ActivityTypeIcon from "component/activity/ActivityTypeIcon";
import getContrastColor from "util/colors/getContrastColor";
import AnyColor, { HexColor } from "@mapmycustomers/shared/types/AnyColor";
import anyColorToHex from "util/colors/anyColorToHex";
import { isSearchGroup, isSearchTerritory } from "../../util/assert";

interface Props {
  item: GlobalSearchItem;
}

const Icon: React.FC<Props> = ({ item }) => {
  const entityType: EntityType | undefined = item.entityType;

  const backgroundColor: HexColor | undefined = useMemo(() => {
    let result = undefined;
    if (item.entity) {
      let color;
      if (isSearchGroup(item.entity)) {
        color = item.entity.color ?? "grey";
      } else if (isSearchTerritory(item.entity)) {
        color = item.entity.color ?? "grey";
      }
      if (color) {
        result = anyColorToHex(color as AnyColor);
      }
    }
    return result;
  }, [item]);

  if (entityType === EntityType.ACTIVITY) {
    return (
      <ActivityTypeIcon activityType={(item.entity as GlobalSearchActivity).crmActivityType} />
    );
  }
  if (entityType) {
    return (
      <EntityTypeShapedIcon
        containerClassName={cn(styles.icon, {
          [styles.blackIcon]: backgroundColor && getContrastColor(backgroundColor) === "black",
        })}
        entityType={entityType}
        style={backgroundColor ? { backgroundColor } : undefined}
      />
    );
  }

  return (
    <div className={cn(styles.icon, styles.queryIcon)}>
      <FontAwesomeIcon icon={item.type === GlobalSearchItemType.QUERY ? faClock : faRedoAlt} />
    </div>
  );
};

export default Icon;

import React, { useEffect, useState } from "react";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import { findMe } from "store/iam/actions";
import { getPosition } from "store/iam";
import { convertCoordinatesToLatLngLiteral } from "util/geo/GeoService";
import { useMap, useMapEventHandler } from "../../../utils";
import colors from "styles/_colors.module.scss";
import { USER_LOCATION_ZINDEX } from "util/map/consts";

const REFRESH_INTERVAL = 60 * 1000; // ms, every minute
const ACCURACY_CIRCLE_THRESHOLD = 10; // see usage in the code below

interface Props {
  onRefreshPosition: () => void;
  position?: GeolocationPosition;
}

const UserLocation: React.FC<Props> = ({ onRefreshPosition, position }) => {
  const { map } = useMap();

  useEffect(() => {
    onRefreshPosition();
    const intervalId = setInterval(() => {
      onRefreshPosition();
    }, REFRESH_INTERVAL);
    return () => {
      clearInterval(intervalId);
    };
  }, [onRefreshPosition]);

  const [zoom, setZoom] = useState(map.getZoom() ?? 0);
  useMapEventHandler("zoom_changed", () => {
    setZoom(map.getZoom() ?? 0);
  });

  useEffect(() => {
    if (!position) {
      return;
    }

    const accuracyCircleRadius = position.coords.accuracy; // in meters
    const center = convertCoordinatesToLatLngLiteral(position.coords);

    // when user is on a zoom levels <= than ACCURACY_CIRCLE_THRESHOLD, we just display
    // a circle around user marker. Which looks like an accuracy circle, but doesn't really
    // represent it. Because Google does a similar thing, hence this is familiar pattern
    // for users :( Also because that accuracy circle can be too large sometimes.
    const accuracyCircle =
      zoom > ACCURACY_CIRCLE_THRESHOLD
        ? new google.maps.Circle({
            center,
            clickable: false,
            fillColor: colors.primary100,
            fillOpacity: 0.2,
            strokeWeight: 0,
            map,
            radius: accuracyCircleRadius,
            zIndex: USER_LOCATION_ZINDEX,
          })
        : new google.maps.Marker({
            clickable: false,
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scale: 13,
              fillColor: colors.primary100,
              fillOpacity: 0.2,
              strokeWeight: 0,
            },
            map,
            position: center,
            zIndex: USER_LOCATION_ZINDEX,
          });

    const userMarker = new google.maps.Marker({
      clickable: false,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8.5,
        fillColor: colors.primary100,
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: colors.white,
      },
      map,
      position: center,
      zIndex: USER_LOCATION_ZINDEX,
    });

    return () => {
      userMarker.setMap(null);
      accuracyCircle.setMap(null);
    };
  }, [map, position, zoom]);

  return null;
};

const mapStateToProps = (state: RootState) => ({
  position: getPosition(state),
});

const mapDispatchToProps = {
  onRefreshPosition: findMe.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLocation);

import React from "react";
import BaseStepProps from "../../../types/BaseStepProps";
import InvitationInfo from "types/auth/InvitationInfo";
import Card from "antd/es/card";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/pro-light-svg-icons/faGlobe";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import Button from "antd/es/button";
import cn from "classnames";
import styles from "./InvitationInfoStep.module.scss";

interface Props extends BaseStepProps {
  className?: string;
  info: InvitationInfo;
}

const InvitationInfoStep: React.FC<Props> = ({ className, info, onNextClick }) => {
  const intl = useIntl();
  return (
    <div className={cn(styles.container, className)}>
      <Card>
        <p className={styles.line1}>
          {intl.formatMessage(
            {
              id: "auth.registerMember.invitationInfoStep.line1",
              defaultMessage: "{owner} has invited you to Map My Customers",
              description: "First line of info in Invitation Info step of Register Member page",
            },
            {
              owner: info.ownerName,
            }
          )}
        </p>
        <p className={styles.line2}>
          <FontAwesomeIcon className={styles.icon} icon={faGlobe} />
          {info.organizationName}
        </p>
        {info.teamNames.map((teamName, index) => (
          <p className={styles.line3} key={index}>
            <FontAwesomeIcon className={styles.icon} icon={faUsers} />
            {teamName}
          </p>
        ))}
      </Card>
      <Button block onClick={onNextClick} type="primary">
        {intl.formatMessage({
          id: "auth.registerMember.invitationInfoStep.buttonNext",
          defaultMessage: "Set up my account",
          description: "Next button in Invitation Info step of Register Member page",
        })}
      </Button>
    </div>
  );
};

export default InvitationInfoStep;

import { put, select, takeLatest } from "redux-saga/effects";
import { toggleLayerLegendExpandedState, toggleLayerVisibility } from "../actions";
import { getTrafficLayerData } from "scene/map/store/selectors";
import BaseLayerData from "scene/map/store/layers/BaseLayerData";
import { isActionOf } from "typesafe-actions";
import { Action } from "redux";
import { updateMetadata } from "../../../../../store/iam/actions";

export function* onPersistTrafficSettings(sd: any) {
  const layerData: BaseLayerData = yield select(getTrafficLayerData);

  yield put(updateMetadata.request({ trafficSettings: layerData }));
}

const isToggleVisibilityOrLegendAction = isActionOf([
  toggleLayerVisibility,
  toggleLayerLegendExpandedState,
]);

export function* trafficLayerSagas() {
  yield takeLatest(
    (action: Action) =>
      isToggleVisibilityOrLegendAction(action) && action.payload.name === "traffic",
    onPersistTrafficSettings
  );
}

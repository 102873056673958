import React, { ReactNode, RefObject } from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Select, { RefSelectProps, SelectProps, SelectValue } from "antd/es/select";
import { Labeled, LabeledFieldProps } from "@mapmycustomers/ui";
import { ErrorRow } from "@mapmycustomers/ui";
import cn from "classnames";
import styles from "./SelectField.module.scss";
import { TestProps } from "@mapmycustomers/ui";

export interface SelectFieldProps<T extends SelectValue = SelectValue>
  extends Omit<SelectProps<T>, "className" | "ref">,
    Omit<LabeledFieldProps, "children">,
    TestProps {
  error?: ReactNode;
  selectClassName?: string;
}

class SelectField<T extends SelectValue = SelectValue> extends React.PureComponent<
  SelectFieldProps<T>
> {
  private readonly selectorRef: RefObject<RefSelectProps>;

  constructor(props: SelectFieldProps<T>, context: any) {
    super(props, context);
    this.selectorRef = React.createRef<RefSelectProps>();
  }

  focus() {
    this.selectorRef.current?.focus();
  }

  blur() {
    this.selectorRef.current?.blur();
  }

  render() {
    const {
      className,
      cypressId,
      error,
      label,
      labelClassName,
      labelPosition,
      required,
      selectClassName,
      sideLabelSpan,
      ...props
    } = this.props;

    return (
      <Labeled
        className={className}
        cypressId={cypressId}
        label={label}
        labelClassName={labelClassName}
        labelPosition={labelPosition}
        required={required}
        sideLabelSpan={sideLabelSpan}
      >
        <Col span={24}>
          <Row>
            <Select
              className={cn(styles.select, selectClassName)}
              ref={this.selectorRef}
              {...props}
            />
          </Row>
          {error && <ErrorRow>{error}</ErrorRow>}
        </Col>
      </Labeled>
    );
  }
}

export default SelectField;

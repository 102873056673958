import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";
import memoize from "fast-memoize";
import { ColDef } from "@ag-grid-community/core";
import dateRangeTypeToDateRange from "../utils/dateRangeTypeToDateRange";
import DateRangeType from "../enums/DateRangeType";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import {
  getOrganizationSettings,
  isCurrentUserManager,
  isCurrentUserMember,
  isCurrentUserOwner,
} from "../../../store/iam";
import OrganizationSetting from "enum/OrganizationSetting";
import Setting from "@mapmycustomers/shared/types/Setting";
import LeaderboardVisibilitySettingValue from "enum/LeaderboardVisibilitySettingValue";

const reportState = (state: RootState) => state.scene.report;

export const isLoading = createSelector(reportState, ({ loading }) => loading);
export const getExtraInfo = createSelector(reportState, ({ extraInfo }) => extraInfo);
export const getOverviewData = createSelector(reportState, ({ overviewData }) => overviewData);
export const getOverviewMapData = createSelector(
  reportState,
  ({ overviewMapData }) => overviewMapData
);

export const getFunnelsStatsData = createSelector(
  reportState,
  ({ funnelStatsData }) => funnelStatsData
);
export const getActivityCardData = createSelector(
  reportState,
  ({ activityCardData }) => activityCardData
);

export const getDealsMapData = createSelector(reportState, ({ dealsMapData }) => dealsMapData);

export const getDealsProgressionChartData = createSelector(
  reportState,
  ({ dealsProgressionChartData }) => dealsProgressionChartData
);
export const getDealsGeoChartData = createSelector(
  reportState,
  ({ dealsGeoChartData }) => dealsGeoChartData
);

export const getStageName = createSelector(getFunnelsStatsData, ({ stageSummary }) =>
  memoize(
    (stageId: Stage["id"]) => stageSummary.find(({ stage: { id } }) => id === stageId)?.stage?.name
  )
);
export const getUsageCardData = createSelector(reportState, ({ usageCardData }) => usageCardData);
export const getExportCardData = createSelector(
  reportState,
  ({ exportCardData }) => exportCardData
);

export const getCurrentReport = createSelector(
  getExportCardData,
  ({ currentReport }) => currentReport
);
export const isReportPreviewLoading = createSelector(
  getExportCardData,
  ({ reportPreviewLoading }) => reportPreviewLoading
);
export const getReportTotalRowsCount = createSelector(
  getExportCardData,
  ({ reportTotalRowsCount }) => reportTotalRowsCount
);
export const getReportListViewState = createSelector(
  getExportCardData,
  ({ reportListViewState }) => reportListViewState
);
export const getPreviewViewState = createSelector(getExportCardData, ({ viewState }) => viewState);
export const isSavingReport = createSelector(getExportCardData, ({ reportSaving }) => reportSaving);
export const canSaveReport = createSelector(getExportCardData, ({ currentReport, viewState }) => {
  return !!currentReport && currentReport.name!.trim().length > 0;
});
export const getPreviewColumnDefs = createSelector(getPreviewViewState, ({ columns }) =>
  memoize((forClientSideGrid?: boolean) => {
    // get field's gridProperties && also add width/pinned/hide props from the column model
    return columns
      .filter(({ field }) => field.isReadable || field.isSystemRequired)
      .map<ColDef>(({ field, pinned, visible, width }) => ({
        ...(field.gridProperties(forClientSideGrid) ?? {}),
        hide: !visible,
        pinned,
        width,
      }));
  })
);

// Date range filter selectors
export const getDateRange = createSelector(reportState, ({ dateRange, dateRangeType }) => {
  if (dateRangeType === DateRangeType.CUSTOM) {
    return dateRange ?? dateRangeTypeToDateRange(DateRangeType.YESTERDAY)!;
  }
  return dateRangeTypeToDateRange(dateRangeType)!;
});
export const getDateRangeType = createSelector(reportState, ({ dateRangeType }) => dateRangeType);

//User for Reports
export const getUserIds = createSelector(reportState, ({ userIds }) => userIds);

// Weekly report page settings
export const isSavingWeeklyReport = createSelector(
  reportState,
  ({ weeklyReportSaving }) => weeklyReportSaving
);

// Leaderboard selectors
export const getLeaderboardState = createSelector(reportState, ({ leaderboard }) => leaderboard);
export const getLeaderboardTableData = createSelector(
  getLeaderboardState,
  ({ tableItems }) => tableItems
);
export const getLeaderboardMetricsData = createSelector(
  getLeaderboardState,
  ({ metricsItems }) => metricsItems
);
export const isLeaderboardDataLoading = createSelector(
  getLeaderboardState,
  ({ loading }) => loading
);
export const isLeaderboardMetricsDataLoading = createSelector(
  getLeaderboardState,
  ({ metricsLoading }) => metricsLoading
);
export const getLeaderboardViewState = createSelector(
  getLeaderboardState,
  ({ viewState }) => viewState
);
export const getLeaderboardMetricsViewState = createSelector(
  getLeaderboardState,
  ({ metricsViewState }) => metricsViewState
);

export const isReportRecordsCountLoading = createSelector(
  reportState,
  ({ reportRecordsCountLoading }) => reportRecordsCountLoading
);

export const isStackRankAvailable = createSelector(
  isCurrentUserOwner,
  isCurrentUserManager,
  isCurrentUserMember,
  getOrganizationSettings,
  (isOwner, isManager, isMember, orgSettings: Setting[] | undefined) => {
    const leaderboardVisibility: LeaderboardVisibilitySettingValue | undefined =
      orgSettings?.find(({ key }) => key === OrganizationSetting.LEADERBOARD_VISIBILITY)?.value ??
      false;
    if (!leaderboardVisibility) {
      return false;
    }
    return (
      leaderboardVisibility === LeaderboardVisibilitySettingValue.PUBLIC ||
      ((isManager || isOwner) &&
        leaderboardVisibility === LeaderboardVisibilitySettingValue.OWNER_MANAGER) ||
      (isOwner && leaderboardVisibility === LeaderboardVisibilitySettingValue.OWNER)
    );
  }
);

export const getActivityCardCriteria = createSelector(
  getActivityCardData,
  ({ bounds, dateRange, mapFilter, userIds, zoom }) => ({
    bounds,
    dateRange,
    mapFilter,
    userIds,
    zoom,
  })
);

import { createSelector } from "reselect";
import { isCompanyRecordChanged } from "component/preview/CompanyRecordPane/store/selectors";
import { isDealRecordChanged } from "component/preview/DealRecordPane/store/selectors";
import { isPersonRecordChanged } from "component/preview/PersonRecordPane/store/selectors";
import { RootState } from "store/rootReducer";
import { getEntityView } from "store/entityView";
import { EntityType } from "@mapmycustomers/shared/enum";
import LassoedEntitiesState from "scene/map/types/LassoedEntitiesState";
import { UniversalFieldName } from "util/fieldModel/universalFieldsFieldModel";

const mapModeState = (state: RootState) => state.scene.map.mapMode;

export const isLoading = createSelector(mapModeState, ({ loading }) => loading);
export const getMapViewState = createSelector(mapModeState, ({ viewState }) => viewState);
export const isSidebarVisible = createSelector(
  getMapViewState,
  ({ sidebarVisible }) => sidebarVisible
);
export const getMapViewTool = createSelector(getMapViewState, ({ tool }) => tool);
export const getMapEntries = createSelector(
  mapModeState,
  ({ categorizedMapEntries }) => categorizedMapEntries
);
export const getTotalFilteredRecordsCount = createSelector(
  mapModeState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);
export const getTotalRecordsCount = createSelector(
  mapModeState,
  ({ totalRecords }) => totalRecords
);

export const getAnnotation = createSelector(mapModeState, ({ annotation }) => annotation);

export const getPinHover = createSelector(mapModeState, ({ pinHover }) => pinHover);

export const getRecordsListState = createSelector(mapModeState, ({ recordsList }) => recordsList);
export const isRecordsListLoading = createSelector(getRecordsListState, ({ loading }) => loading);
export const getRecordsListFilter = createSelector(getRecordsListState, ({ search }) =>
  search?.trim()
);
export const isRecordsListFiltered = createSelector(
  getRecordsListFilter,
  (value) => !!value?.length
);
export const getRecords = createSelector(getRecordsListState, ({ records }) => records);
export const getRecordsListRange = createSelector(getRecordsListState, ({ range }) => range);
export const getRecordsListOffset = createSelector(
  getRecordsListState,
  ({ range }) => range.startRow
);
export const getRecordsListTotal = createSelector(
  getRecordsListState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);
export const getRecordsListTotalBeforeFilter = createSelector(
  getRecordsListState,
  ({ totalRecords }) => totalRecords
);

export const getMultiPin = createSelector(getRecordsListState, ({ multiPin }) => multiPin?.entity);
export const getMultiPinId = createSelector(
  getRecordsListState,
  ({ multiPin }) => multiPin?.entity?.id
);
export const getMultiPinAddress = createSelector(
  getRecordsListState,
  ({ multiPin }) => multiPin?.address
);
export const getMultiPinGroupMode = createSelector(
  getRecordsListState,
  ({ multiPin }) => multiPin?.groupMode
);

export const getSelectedState = createSelector(mapModeState, ({ selected }) => selected);
export const getSelectedRecords = createSelector(getSelectedState, ({ items }) => items);
export const getSelectedEntities = createSelector(getSelectedState, ({ entities }) => entities);
export const getLassoedEntitiesState = createSelector(getSelectedEntities, (entities) => {
  let containsRestrictedEditItems = false;
  let containsRestrictedDeleteItems = false;
  let noAccessToEntities = entities.length > 0;
  for (const item of entities) {
    containsRestrictedEditItems ||= item.accessStatus?.update === false;
    containsRestrictedDeleteItems ||= item.accessStatus?.delete === false;
    if (item.accessStatus?.update || item.accessStatus?.delete) {
      noAccessToEntities = false;
    }
  }
  return {
    noAccessToEntities,
    containsRestrictedEditItems,
    containsRestrictedDeleteItems,
  } as LassoedEntitiesState;
});

export const getSelectedRecordsCount = createSelector(getSelectedState, ({ items }) => items.size);
export const isRecordSelected = (state: RootState, hash: string) =>
  getSelectedRecords(state).has(hash);
export const getExcludedRecords = createSelector(getSelectedState, ({ excluded }) => excluded);
export const getIncludedRecords = createSelector(getSelectedState, ({ included }) => included);

export const getHighlightedRecords = createSelector(
  getRecordsListState,
  ({ highlight }) => highlight
);
export const getHightlightedRecordsCount = createSelector(
  getRecordsListState,
  ({ highlight }) => highlight.size
);
export const isRecordHighlighted = (state: RootState, hash: string) =>
  getHighlightedRecords(state).has(hash);

export const getMapRecordsListSortOrder = createSelector(getRecordsListState, ({ sort }) => sort);
export const isRecordsListSortedByDistance = createSelector(getMapRecordsListSortOrder, (sort) =>
  sort?.length > 0 ? sort[0].field?.sortName === UniversalFieldName.GEOPOINT : false
);

export const getFocusedEntity = createSelector(mapModeState, ({ focusedEntity }) => focusedEntity);
export const getSelectedPin = createSelector(mapModeState, ({ selectedPin }) => selectedPin);

export const doesEntityViewHasChanges = createSelector(
  mapModeState,
  getEntityView,
  isCompanyRecordChanged,
  isDealRecordChanged,
  isPersonRecordChanged,
  (
    { viewState },
    entityView,
    isCompanyRecordChanged,
    isDealRecordChanged,
    isPersonRecordChanged
  ) => {
    if (entityView?.entityId && entityView?.entityType && viewState.sidebarVisible) {
      switch (entityView.entityType) {
        case EntityType.COMPANY:
          return isCompanyRecordChanged;
        case EntityType.DEAL:
          return isDealRecordChanged;
        case EntityType.PERSON:
          return isPersonRecordChanged;
      }
    }

    return false;
  }
);

export const getListViewLocationQuery = createSelector(mapModeState, ({ query }) => query);

import { EntityType, EntityTypeSupportingCustomFields } from "@mapmycustomers/shared/types/entity";
import CustomFieldsState from "./CustomFieldsState";
import CustomField from "@mapmycustomers/shared/types/customField/CustomField";
import { PickWithType } from "@mapmycustomers/shared/util/ts";

export const stateFieldBaseName: {
  [key in EntityTypeSupportingCustomFields]: keyof PickWithType<CustomFieldsState, CustomField[]>;
} = {
  [EntityType.ACTIVITY]: "activityCustomFields",
  [EntityType.COMPANY]: "companyCustomFields",
  [EntityType.DEAL]: "dealCustomFields",
  [EntityType.PERSON]: "peopleCustomFields",
};

import React from "react";
import noAccessImage from "assets/activity/annotation/no-access.svg";
import styles from "./NoAccess.module.scss";
import { useIntl } from "react-intl";

interface Props {}

const NoAccess: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <img alt="No access icon" className={styles.image} src={noAccessImage} />
      <div className={styles.title}>
        {intl.formatMessage({
          id: "component.activityAnnotation.notFound.title",
          defaultMessage: "Activity not found",
          description: "Title for the NotFound state of activity annotation",
        })}
      </div>
      <div className={styles.description}>
        {intl.formatMessage({
          id: "component.activityAnnotation.notFound.description",
          defaultMessage: "You don’t have access to this activity or it may have been deleted",
          description: "Description for the NotFound state of activity annotation",
        })}
      </div>
    </div>
  );
};

export default NoAccess;

import React from "react";
import styles from "./TypeSelector.module.scss";
import RoundButton from "../../../RoundButton";
import { useIntl } from "react-intl";
import GlobalSearchFilter, {
  getGlobalSearchFilterDisplayName,
} from "../../enum/GlobalSearchFilter";

interface Props {
  onSelect: (filter: GlobalSearchFilter) => void;
  value: GlobalSearchFilter;
}

const TypeSelector: React.FC<Props> = ({ onSelect, value }) => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      {[
        GlobalSearchFilter.ALL,
        GlobalSearchFilter.COMPANIES,
        GlobalSearchFilter.PEOPLE,
        GlobalSearchFilter.DEALS,
        GlobalSearchFilter.GROUPS,
        GlobalSearchFilter.ACTIVITIES,
        GlobalSearchFilter.ROUTES,
        GlobalSearchFilter.TERRITORIES,
      ].map((filter) => (
        <RoundButton
          className={styles.button}
          key={filter}
          active={filter === value}
          onClick={() => onSelect(filter)}
        >
          {getGlobalSearchFilterDisplayName(intl, filter)}
        </RoundButton>
      ))}
    </div>
  );
};

export default TypeSelector;

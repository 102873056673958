import { createReducer } from "typesafe-actions";
import { hide, hideInSession, show, UserLocationRequestActions } from "./actions";

export interface UserLocationRequestState {
  visible: boolean;
}

export default createReducer<UserLocationRequestState, UserLocationRequestActions>({
  visible: false,
})
  .handleAction(show, (state) => ({ ...state, visible: true }))
  .handleAction([hide, hideInSession], (state) => ({ ...state, visible: false }));

import { EntityType } from "@mapmycustomers/shared/enum";
import getFieldModelByEntityType from "./getByEntityType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";

const getColumnsVisibleByDefault = (
  entityType: EntityType.ACTIVITY | EntityType.COMPANY | EntityType.DEAL | EntityType.PERSON
) =>
  getFieldModelByEntityType(entityType).sortedFields.filter((field) =>
    field.hasFeature(FieldFeature.VISIBLE_BY_DEFAULT)
  );

export default getColumnsVisibleByDefault;

import Visibility from "./Visibility";
import pick from "lodash-es/pick";

type CheckInVisibility =
  | Visibility.PRIVATE
  | Visibility.SHARED_WITH_MANAGERS
  | Visibility.SHARED_WITH_ORGANIZATION;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const CheckInVisibility = pick(Visibility, [
  "PRIVATE",
  "SHARED_WITH_MANAGERS",
  "SHARED_WITH_ORGANIZATION",
]);

export default CheckInVisibility;

export const checkInVisibilityToPlatformValue: Record<
  CheckInVisibility,
  string
> = {
  [CheckInVisibility.PRIVATE]: "owner",
  [CheckInVisibility.SHARED_WITH_MANAGERS]: "owner-manager",
  [CheckInVisibility.SHARED_WITH_ORGANIZATION]: "public",
};
export const platformCheckInVisibilityToEnum: Record<
  string,
  CheckInVisibility
> = Object.fromEntries(
  Object.entries(checkInVisibilityToPlatformValue).map((item) => item.reverse())
);

import React, { useMemo } from "react";
import TextArea from "component/input/TextArea";
import Form from "antd/es/form";
import { RootState } from "store/rootReducer";
import { getFunnelStages } from "store/deal";
import { connect } from "react-redux";
import { useWatch } from "antd/es/form/Form";
import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import { ConditionalFormFieldRenderProps } from "component/ConditionalFormField";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";

interface OwnProps extends ConditionalFormFieldRenderProps {}

interface Props extends OwnProps {
  funnelStages: Record<Funnel["id"], Stage[]>;
}

const DealLossCommentField: React.FC<Props> = ({
  disabled,
  funnelStages,
  label,
  required,
  variant,
}) => {
  const funnelId = useWatch("funnelId");
  const stageId = useWatch("stageId");
  const isLostStage = useMemo(
    () => funnelStages[funnelId]?.find(({ id }) => id === stageId)?.type === DealStageType.LOST,
    [funnelStages, funnelId, stageId]
  );

  // hide this field for non-lost stages, but only if we're not using a variant (i.e. for parent layout)
  const hideForNonLostStages = !variant;
  // if we hide field for non-lost stages and this is a non-lost stage, return null
  if (hideForNonLostStages && !isLostStage) {
    return null;
  }

  return (
    <Form.Item label={label} name="dealLossComment" required={required} rules={[{ required }]}>
      <TextArea disabled={disabled} />
    </Form.Item>
  );
};

const mapStateToProps = (state: RootState) => ({
  funnelStages: getFunnelStages(state),
});

export default connect(mapStateToProps)(DealLossCommentField);

import { delay, put, select, takeLatest } from "redux-saga/effects";
import { filterCompanies, filterDeals, filterPeople } from "./actions";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { callApi } from "store/api/callApi";
import { getOrganization } from "store/iam";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import { handleError } from "store/errors/actions";

const DEBOUNCE_DELAY = 200; // ms

export function* onFilterCompanies({ payload: query }: ReturnType<typeof filterCompanies.request>) {
  try {
    yield delay(DEBOUNCE_DELAY);
    const organization: Organization = yield select(getOrganization);

    const response: ListResponse<Company> = yield callApi("fetchCompanies", organization.id, {
      $filters: {
        name: query ? { $in: query } : undefined,
      },
    });

    yield put(filterCompanies.success(response.data));
  } catch (error) {
    yield put(filterCompanies.failure());
    yield put(handleError({ error }));
  }
}

export function* onFilterDeals({ payload: query }: ReturnType<typeof filterDeals.request>) {
  try {
    yield delay(DEBOUNCE_DELAY);
    const organization: Organization = yield select(getOrganization);

    const response: ListResponse<Deal> = yield callApi("fetchDeals", organization.id, {
      $filters: {
        name: query ? { $in: query } : undefined,
      },
    });

    yield put(filterDeals.success(response.data));
  } catch (error) {
    yield put(filterDeals.failure());
    yield put(handleError({ error }));
  }
}

export function* onFilterPeople({ payload: query }: ReturnType<typeof filterPeople.request>) {
  try {
    yield delay(DEBOUNCE_DELAY);
    const organization: Organization = yield select(getOrganization);

    const response: ListResponse<Person> = yield callApi("fetchPeople", organization.id, {
      $filters: {
        name: query ? { $in: query } : undefined,
      },
    });

    yield put(filterPeople.success(response.data));
  } catch (error) {
    yield put(filterPeople.failure());
    yield put(handleError({ error }));
  }
}

export function* entitySearchSaga() {
  yield takeLatest(filterCompanies.request, onFilterCompanies);
  yield takeLatest(filterDeals.request, onFilterDeals);
  yield takeLatest(filterPeople.request, onFilterPeople);
}

import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import CardType from "enum/dashboard/CardType";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { isOwner } from "store/iam/util";
import Dashboard from "types/dashboard/Dashboard";
import DashboardCard from "types/dashboard/DashboardCard";
import DataCard from "../components/DataCard";
import Button from "antd/es/button";
import styles from "../UserPreviewCard.module.scss";

const usePreviewCards = (
  user: User | undefined,
  cardIds: Record<number, DashboardCard["id"] | undefined>,
  orgDashboard: Dashboard | undefined,
  selectedTeamId: Team["id"] | undefined,
  teamDashboard: Dashboard | undefined,
  userDashboard: Dashboard | undefined,
  viewOnlyNoAccess: boolean,
  viewOnly: boolean,
  setIndex: (index: number) => void,
  setModalOpen: (open: boolean) => void
) => {
  const intl = useIntl();

  const previewCards = useMemo(() => {
    const elements = [];
    const dashboard =
      user && isOwner(user) ? orgDashboard : selectedTeamId ? teamDashboard : userDashboard;

    if (!viewOnlyNoAccess) {
      for (let i = 0; i < 2; i++) {
        const cardId = cardIds[i];
        const card = dashboard?.settings.cards.find(({ id }) => id === cardId);
        if (
          card &&
          card.type !== CardType.OUT_OF_FREQUENCY &&
          card.type !== CardType.NO_CONTACT_IN
        ) {
          elements.push(
            <DataCard
              allowRemove={!viewOnly}
              card={card}
              index={i}
              key={i}
              scope={{ teamId: selectedTeamId, userId: user?.id }}
            />
          );
        } else if (!viewOnly) {
          elements.push(
            <Button
              className={styles.addMetric}
              key={i}
              onClick={() => {
                setIndex(i);
                setModalOpen(true);
              }}
            >
              {intl.formatMessage({
                id: "userPreviewCard.addMetric",
                defaultMessage: "+ Add Metric",
                description: "Add metric button title",
              })}
            </Button>
          );
        }
      }
    }
    return elements;
  }, [
    user,
    orgDashboard,
    selectedTeamId,
    teamDashboard,
    userDashboard,
    viewOnlyNoAccess,
    cardIds,
    viewOnly,
    intl,
    setIndex,
    setModalOpen,
  ]);

  return previewCards;
};

export default usePreviewCards;

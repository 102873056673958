import { createNote } from "./actions";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getOrganization } from "store/iam";
import { put, select, takeLatest } from "redux-saga/effects";
import { callApi } from "store/api/callApi";
import { handleError } from "../../../../store/errors/actions";

export function* onCreateNote({ payload }: ReturnType<typeof createNote.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const { callback, entityId, entityType, note } = payload;
    yield callApi("createNote", org.id, entityType, entityId, note);
    callback();
    yield put(createNote.success());
  } catch (error) {
    yield put(createNote.failure());
    yield put(handleError({ error }));
  }
}

export function* addNoteModalSaga() {
  yield takeLatest(createNote.request, onCreateNote);
}

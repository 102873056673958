import { useMemo } from "react";
import Dashboard from "types/dashboard/Dashboard";
import useImagePalette from "util/hook/useImagePalette";

const useInnerColors = (logoUrl: string | undefined, userDashboard: Dashboard | undefined) => {
  const [logoPalette, logoPaletteLoading] = useImagePalette(logoUrl);

  const auto = userDashboard?.settings.gradientFromImage ?? true;
  const colors = useMemo(
    () => userDashboard?.settings.gradient ?? [],
    [userDashboard?.settings.gradient]
  );

  const innerColors = useMemo(() => {
    if (auto && logoPaletteLoading) {
      return ["transparent"]; // do not display anything until we know the final colors
    }
    return auto && logoPalette.length ? logoPalette : colors;
  }, [auto, logoPalette, logoPaletteLoading, colors]);

  return innerColors;
};

export default useInnerColors;

import React from "react";
import cn from "classnames";
import Tooltip from "antd/es/tooltip";
import Team from "@mapmycustomers/shared/types/Team";
import TeamAvatar from "./TeamAvatar";
import styles from "./AvatarCommon.module.scss";

interface Props {
  avatarClassName?: string;
  className?: string;
  team: Pick<Team, "id" | "name">;
}

const TeamAvatarWithName: React.FC<Props> = ({
  avatarClassName,
  className,
  team,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <TeamAvatar
        className={cn(styles.avatar, avatarClassName)}
        randomColor
        team={team}
        tooltip
      />{" "}
      <Tooltip title={team.name}>
        <span className={styles.name}>{team.name}</span>
      </Tooltip>
    </div>
  );
};

export default TeamAvatarWithName;

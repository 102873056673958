import { FieldProperties } from "./Field";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { FilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import PlatformFilterModel, {
  PlatformFilterCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { isCombinedCondition, isCombinedPlatformCondition } from "util/viewModel/assert";
import { getRegularFieldPlatformConditionValue } from "util/viewModel/convertToPlatformFilterModel";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { getRegularFieldConditionValue } from "util/viewModel/convertFromPlatformFilterModel";
import invariant from "tiny-invariant";
import BaseLocationField from "util/fieldModel/impl/BaseLocationField";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { isNotEmpty } from "util/assert";

class CheckInLocationField extends BaseLocationField {
  constructor(data: Omit<FieldProperties, "type">) {
    if ("platformAreaFieldName" in data) {
      invariant(
        isNotEmpty(data.platformAreaFieldName),
        "platformAreaFieldName must be defined for CheckInLocationField"
      );
    }

    super({
      platformFilterName: "area",
      platformAreaFieldName: "geoPointCheckin.coordinates",
      valueGetter: ["geoPointCheckin", "coordinates"],
      features: [
        FieldFeature.CHECK_IN,
        FieldFeature.GEO_POINT_FIELD,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      ...data,
    });
  }

  convertToPlatformCondition(filterCondition: FilterCondition): PlatformFilterCondition {
    if (isCombinedCondition(filterCondition)) {
      return {}; // combined condition is not supported
    } else {
      if (
        filterCondition.operator === FilterOperator.EMPTY ||
        filterCondition.operator === FilterOperator.NOT_EMPTY
      ) {
        return {
          [this._platformAreaFieldName!]: {
            [filterCondition.operator === FilterOperator.EMPTY
              ? PlatformFilterOperator.EQUALS
              : PlatformFilterOperator.NOT_EQUAL]: null,
          },
        };
      } else {
        return {
          [this._platformFilterName]: getRegularFieldPlatformConditionValue(this, filterCondition),
        };
      }
    }
  }

  convertFromPlatformCondition(
    filterCondition: PlatformFilterCondition
  ): FilterCondition | undefined {
    if (isCombinedPlatformCondition(filterCondition)) {
      return super.convertFromPlatformCondition(filterCondition);
    } else {
      if (this._platformAreaFieldName! in filterCondition) {
        const keys = Object.keys(filterCondition[this._platformAreaFieldName!] as object);
        if (!keys.length) {
          return undefined;
        }
        return {
          operator: keys.includes(PlatformFilterOperator.EQUALS)
            ? FilterOperator.EMPTY
            : FilterOperator.NOT_EMPTY,
          value: undefined,
        };
      }

      // wrapping condition into an object with filterName is a little hack to help getRegularFieldConditionValue
      // because this is what it normally expects. But since Location is a meta-field, condition for it comes
      // unwrapped, so we need to wrap it.
      return getRegularFieldConditionValue(this, { [this.platformFilterName]: filterCondition });
    }
  }

  isPresentInPlatformCondition(condition: Record<string, unknown>): boolean {
    return this.platformFilterName in condition || this._platformAreaFieldName! in condition;
  }

  getFilterConditionForMetaField(
    platformFilterModel: PlatformFilterModel
  ): PlatformFilterCondition {
    if (this.platformFilterName in platformFilterModel) {
      return super.getFilterConditionForMetaField(platformFilterModel);
    } else if (this._platformAreaFieldName! in platformFilterModel) {
      // we know [this._platformAreaFieldName] field can be in PlatformFilterModel because we put it there ourselves
      // @ts-ignore
      return { [this._platformAreaFieldName!]: platformFilterModel[this._platformAreaFieldName!] };
    }
    invariant(false, `Invalid filter condition for ${this.name} field.`);
  }
}

export default CheckInLocationField;

import { useMemo } from "react";
import Address from "@mapmycustomers/shared/types/Address";
import { convertPlaceToAddress } from "util/geo/GeoService";
import memoize from "fast-memoize";
import loggingService from "util/logging";
import { useIntl } from "react-intl";

// Use LeadFinder from the Platform, see ApiService.fetchLeads
// @deprecated
const usePlaceDetailsGetter = (): [(placeId: string) => Promise<Address | undefined>] => {
  const intl = useIntl();

  // Get address by place
  const placesService = useMemo(
    () => new google.maps.places.PlacesService(document.createElement("div")),
    []
  );
  const selectPlace = useMemo(
    () =>
      memoize(
        (placeId: string) =>
          new Promise<Address | undefined>((resolve) => {
            placesService.getDetails({ placeId }, (result, status) => {
              if (status === google.maps.places.PlacesServiceStatus.OK && result) {
                const address = convertPlaceToAddress(intl, result);
                loggingService.debug("converted place", { result, address });
                resolve(address);
              } else {
                resolve(undefined);
              }
            });
          })
      ),
    [intl, placesService]
  );
  return [selectPlace];
};

export default usePlaceDetailsGetter;

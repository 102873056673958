import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import { isSimpleCondition } from "util/viewModel/assert";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { OPERATORS_ALLOWING_EMPTY_VALUE } from "./consts";

const cleanUpFilterModel = (filterModel: Partial<FilterModel>): Partial<FilterModel> => {
  const processedModel = { ...filterModel };
  // iterate over all filters and remove those which have no value
  // except when operator is one of OPERATORS_ALLOWING_EMPTY_VALUE since these operators support no value
  Object.keys(processedModel).forEach((fieldFilterName) => {
    // delete a fake viewAs filter
    if (fieldFilterName === "viewAs") {
      delete processedModel[fieldFilterName];
      return;
    }
    if (
      processedModel[fieldFilterName] &&
      isSimpleCondition(processedModel[fieldFilterName]!) &&
      !OPERATORS_ALLOWING_EMPTY_VALUE.includes(
        processedModel[fieldFilterName]!.operator as FilterOperator
      )
    ) {
      // we already checked that processedModel[fieldFilterName] is defined and is SimpleCondition
      // @ts-ignore
      if (processedModel[fieldFilterName]!.value === undefined) {
        delete processedModel[fieldFilterName];
      }
    }
  });
  return processedModel;
};

export default cleanUpFilterModel;

import Visibility from "./Visibility";
import pick from "lodash-es/pick";

type PinLegendCreationVisibility =
  | Visibility.PRIVATE
  | Visibility.SHARED_WITH_MANAGERS
  | Visibility.SHARED_WITH_ORGANIZATION;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const PinLegendCreationVisibility = pick(Visibility, [
  "PRIVATE",
  "SHARED_WITH_MANAGERS",
  "SHARED_WITH_ORGANIZATION",
]);

export default PinLegendCreationVisibility;

import { ActionType, createAsyncAction } from "typesafe-actions";
import Role from "@mapmycustomers/shared/types/Role";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";

export const fetchRoles = createAsyncAction(
  "@members/fetchRoles/request",
  "@members/fetchRoles/success",
  "@members/fetchRoles/failure"
)<undefined, Role[], void>();

export const fetchTeams = createAsyncAction(
  "@members/fetchTeams/request",
  "@members/fetchTeams/success",
  "@members/fetchTeams/failure"
)<undefined, Team[], void>();

export const fetchUsers = createAsyncAction(
  "@members/fetchUsers/request",
  "@members/fetchUsers/success",
  "@members/fetchUsers/failure"
)<undefined, User[], void>();

export const updateUser = createAsyncAction(
  "@members/updateUser/request",
  "@members/updateUser/success",
  "@members/updateUser/failure"
)<User, User, void>();

export const updateTeam = createAsyncAction(
  "@members/updateTeam/request",
  "@members/updateTeam/success",
  "@members/updateTeam/failure"
)<Team, Team, void>();

export type Actions = ActionType<
  typeof fetchRoles | typeof fetchTeams | typeof fetchUsers | typeof updateUser | typeof updateTeam
>;

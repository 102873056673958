import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import EntitySearch from "./EntitySearch";
import TextWithInfo from "component/typography/TextWithInfo";
import { RootState } from "store/rootReducer";
import { arePeopleLoading, getFilteredPeople } from "./store/selectors";
import { filterPeople } from "./store/actions";
import { EntityType } from "@mapmycustomers/shared/enum";
import Person from "@mapmycustomers/shared/types/entity/Person";
import Deal from "@mapmycustomers/shared/types/entity/Deal";
import Company from "@mapmycustomers/shared/types/entity/Company";
import { fetchPerson } from "store/person/actions";
import ChangeAssociationsPayload from "store/associations/ChangeAssociationsPayload";

export interface PersonEntitySearchProps<T = Person> {
  allowAdd?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  label?: React.ReactNode;
  loading?: boolean;
  onAddClick?: () => void;
  onChange?: (personId?: Person["id"]) => void;
  onChangeAssociatedEntities: (
    payload: Pick<ChangeAssociationsPayload, "company" | "deal" | "person">
  ) => void;
  people?: T[];
  placeholder?: string;
  required?: boolean;
  selectedCompany?: Company;
  selectedDeal?: Deal;
  selectedPerson?: T;
}

interface Props<T = Person> extends PersonEntitySearchProps<T> {
  filteredPeople: T[];
  isFiltering: boolean;
  onFetchPerson: typeof fetchPerson;
  onFilterPeople: typeof filterPeople.request;
}

const PersonEntitySearch: React.FC<Props> = ({
  allowAdd,
  disabled,
  filteredPeople,
  hideLabel,
  isFiltering,
  label,
  loading,
  onAddClick,
  onChange,
  onChangeAssociatedEntities,
  onFetchPerson,
  onFilterPeople,
  people,
  placeholder,
  required,
  selectedCompany,
  selectedDeal,
  selectedPerson,
}) => {
  const intl = useIntl();

  const [query, setQuery] = useState("");
  const handleSearch = useCallback(
    (query: string) => {
      setQuery(query);
      onFilterPeople(query);
    },
    [onFilterPeople, setQuery]
  );

  const handleSelect = useCallback(
    (person: Person) => {
      onChange?.(person.id);
      onFetchPerson({
        id: person.id,
        callback: (person: Person) => {
          setQuery("");
          onChangeAssociatedEntities({
            company: selectedCompany ?? (person.accounts?.[0] as Company),
            deal: selectedDeal,
            person,
          });
        },
        options: { includeUsersWithAccess: true },
      });
    },
    [onChange, selectedCompany, onFetchPerson, selectedDeal, onChangeAssociatedEntities]
  );

  const primaryEntities = useMemo(() => {
    const searchText = query.toLowerCase().trim();
    if (searchText.length === 0) {
      return people;
    }
    return people?.filter(({ name }) => name.toLowerCase().includes(searchText));
  }, [query, people]);

  const handleRemove = useCallback(() => {
    onChange?.(undefined);
    onChangeAssociatedEntities({
      company: selectedCompany,
      deal: selectedDeal,
      person: undefined,
    });
  }, [onChange, selectedCompany, selectedDeal, onChangeAssociatedEntities]);

  return (
    <EntitySearch<Person>
      allowAdd={allowAdd}
      disabled={disabled}
      entities={filteredPeople}
      entityType={EntityType.PERSON}
      label={
        hideLabel ? null : (
          <TextWithInfo
            info={
              disabled
                ? intl.formatMessage({
                    id: "entitySearch.person.disabledTooltip",
                    defaultMessage: "Person cannot be changed",
                    description: "Tooltip for Person Search field when it is disabled",
                  })
                : selectedCompany
                ? intl.formatMessage({
                    id: "entitySearch.person.enabledTooltip",
                    defaultMessage: "You can only select a Person associated with this Company",
                    description: "Tooltip for Person Search field when it is enabled",
                  })
                : intl.formatMessage({
                    id: "entitySearch.person.defaultTooltip",
                    defaultMessage:
                      "If Company is selected, you can only select a Person associated with that Company",
                    description: "Tooltip for Person Search field default state",
                  })
            }
          >
            {label ??
              intl.formatMessage({
                id: "entitySearch.person",
                defaultMessage: "Person",
                description: "Title of the Person Search field",
              })}
          </TextWithInfo>
        )
      }
      loading={loading || isFiltering}
      onAddClick={onAddClick}
      onRemove={handleRemove}
      onSearch={handleSearch}
      onSelect={handleSelect}
      placeholder={
        placeholder ??
        intl.formatMessage({
          id: "entitySearch.person.placeholder",
          defaultMessage: "Type to find person",
          description: "Placeholder of the Person Search field",
        })
      }
      primaryEntities={primaryEntities}
      required={required}
      selectedEntity={selectedPerson}
      value={query}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  filteredPeople: getFilteredPeople(state),
  isFiltering: arePeopleLoading(state),
});

const mapDispatchToProps = {
  onFetchPerson: fetchPerson,
  onFilterPeople: filterPeople.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonEntitySearch);

import { RenderElementProps } from "slate-react";
import React from "react";
import Mention from "../Mention";
import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case ElementType.MENTION:
      return <Mention {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default Element;

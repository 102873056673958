import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";
import { Origin } from "enum/Inheritance";
import EntityColumnsConfiguration from "./EntityColumnsConfiguration";
import { EntityType } from "@mapmycustomers/shared/types/entity";

const recordPreviewState = (state: RootState) => state.recordPreview;

export const getRecordPreviewConfiguration = createSelector(
  recordPreviewState,
  ({ configuration }) => configuration
);

export const isRecordPreviewConfigurationLoading = createSelector(
  getRecordPreviewConfiguration,
  ({ loading }) => loading
);

export const getRecordPreviewConfigurationTeamId = createSelector(
  getRecordPreviewConfiguration,
  ({ teamId }) => teamId
);

export const getRecordPreviewConfigurationOrgColumns = createSelector(
  getRecordPreviewConfiguration,
  (configuration) => configuration[Origin.ORGANIZATION]
);

export const getRecordPreviewConfigurationTeamColumns = createSelector(
  getRecordPreviewConfiguration,
  (configuration) => configuration[Origin.TEAM]
);

export const getRecordPreviewConfigurationUserColumns = createSelector(
  getRecordPreviewConfiguration,
  (configuration) => configuration[Origin.PRIVATE]
);

export const getRecordPreviewConfigurationColumns = createSelector(
  getRecordPreviewConfiguration,
  (configuration) => {
    const orgConfiguration = configuration[Origin.ORGANIZATION];
    const teamConfiguration = configuration[Origin.TEAM];
    const userConfiguration = configuration[Origin.PRIVATE];

    const result: EntityColumnsConfiguration = {
      actions: {
        [EntityType.COMPANY]: userConfiguration.actions[EntityType.COMPANY],
        [EntityType.PERSON]: userConfiguration.actions[EntityType.PERSON],
        [EntityType.DEAL]: userConfiguration.actions[EntityType.DEAL],
      },
      engagement: {
        [EntityType.COMPANY]: userConfiguration.engagement[EntityType.COMPANY],
        [EntityType.PERSON]: userConfiguration.engagement[EntityType.PERSON],
        [EntityType.DEAL]: userConfiguration.engagement[EntityType.DEAL],
      },
      [EntityType.COMPANY]: [
        ...orgConfiguration[EntityType.COMPANY],
        ...teamConfiguration[EntityType.COMPANY],
        ...userConfiguration[EntityType.COMPANY],
      ],
      [EntityType.PERSON]: [
        ...orgConfiguration[EntityType.PERSON],
        ...teamConfiguration[EntityType.PERSON],
        ...userConfiguration[EntityType.PERSON],
      ],
      [EntityType.DEAL]: [
        ...orgConfiguration[EntityType.DEAL],
        ...teamConfiguration[EntityType.DEAL],
        ...userConfiguration[EntityType.DEAL],
      ],
    };

    return result;
  }
);

import React, { useCallback, useEffect, useState } from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { isValid } from "date-fns/esm";
import TimePicker from "component/input/TimePicker";
import { parseTime } from "util/parsers";
import { SHOULD_USE_12_HOUR_FORMAT } from "util/consts";
import { formatDate } from "util/formatters";
import keepValidDateOrReturnUndefined from "util/parsers/keepValidDateOrReturnUndefined";

export const TIME_FILTER_OPERATORS = [
  FilterOperator.ON,
  FilterOperator.NOT_ON,
  FilterOperator.AFTER,
  FilterOperator.ON_OR_AFTER,
  FilterOperator.BEFORE,
  FilterOperator.ON_OR_BEFORE,
];

interface TimeFilterProps extends IFilterComponentProps {}

const TimeFilter: IFilterInstance = {
  doesSupportValue: (value: any, operator: FilterOperator) => {
    const date = typeof value === "string" ? parseTime(value) : undefined;
    return isValid(date) && TIME_FILTER_OPERATORS.includes(operator);
  },
  getComponent:
    (): React.FC<TimeFilterProps> =>
    ({ className, focus, onChange, value }) => {
      const [date, setDate] = useState(
        value?.value ? keepValidDateOrReturnUndefined(parseTime(value.value)) : undefined
      );

      // using useEffect instead of "useCallback" for handleTimeChange because we also
      // need to listen to operator changes and transform date accordingly
      useEffect(() => {
        const operator = value.operator;

        onChange?.({
          operator,
          value: date ? formatDate(date, "HH:mm") : undefined,
        });
      }, [date, onChange, value.operator]);

      const setRef = useCallback(
        (ref) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      return (
        <TimePicker
          allowClear
          className={className}
          format={SHOULD_USE_12_HOUR_FORMAT ? "hh:mm a" : "HH:mm"}
          onChange={(date: Date | null) => setDate(date || undefined)}
          ref={setRef}
          use12Hours={SHOULD_USE_12_HOUR_FORMAT}
          value={date}
        />
      );
    },
};

export default TimeFilter;

import React, { useCallback, useMemo } from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { NumberField, NumberFieldComponent } from "@mapmycustomers/ui";
import { useIntl } from "react-intl";
import cn from "classnames";
import styles from "./FrequencyStatusFilter.module.scss";
import SelectField from "../../component/input/SelectField";
import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";
import Select from "antd/es/select";

const Option = Select.Option;

enum NotYetOverdueVariant {
  UP_TO_DATE_AND_UPCOMING,
  UPCOMING,
  UP_TO_DATE,
}

export const FREQUENCY_STATUS_FILTER_OPERATORS = [
  FilterOperator.IS_OVERDUE,
  FilterOperator.IS_UPCOMING,
  FilterOperator.IS_OVERDUE_BY_MORE_THAN,
  FilterOperator.IS_OVERDUE_BY_LESS_THAN,
];

interface CadenceStatusFilterProps extends IFilterComponentProps {}

const FrequencyStatusFilter: IFilterInstance = {
  doesSupportValue: (value: any, operator: FilterOperator) => {
    return typeof value === "number" && FREQUENCY_STATUS_FILTER_OPERATORS.includes(operator);
  },
  getComponent:
    (): React.FC<CadenceStatusFilterProps> =>
    ({ className, focus, onChange, value }) => {
      const intl = useIntl();

      const setRef = useCallback(
        (ref: NumberFieldComponent | null) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      const notYetOverdueValue = useMemo(() => {
        let result = NotYetOverdueVariant.UP_TO_DATE_AND_UPCOMING;
        if (value.value === FrequencyStatus.UPCOMING) {
          result = NotYetOverdueVariant.UPCOMING;
        } else if (value.value === FrequencyStatus.UP_TO_DATE) {
          result = NotYetOverdueVariant.UP_TO_DATE;
        }

        return result;
      }, [value.value]);

      const getFrequencyStatusFromNotYetOverdueValue = useCallback(
        (value: NotYetOverdueVariant) => {
          if (value === NotYetOverdueVariant.UP_TO_DATE_AND_UPCOMING) {
            return undefined;
          }
          if (value === NotYetOverdueVariant.UPCOMING) {
            return FrequencyStatus.UPCOMING;
          }
          return FrequencyStatus.UP_TO_DATE;
        },
        []
      );

      if (FilterOperator.IS_OVERDUE === value.operator) {
        return null;
      }
      if (value.operator === FilterOperator.IS_UPCOMING) {
        return (
          <SelectField<NotYetOverdueVariant>
            dropdownMatchSelectWidth={false}
            onChange={(selectedValue) =>
              onChange?.({
                ...value,
                value: getFrequencyStatusFromNotYetOverdueValue(selectedValue),
              })
            }
            showSearch
            value={notYetOverdueValue}
          >
            <Option value={NotYetOverdueVariant.UP_TO_DATE_AND_UPCOMING}>
              {intl.formatMessage({
                id: "filters.cadenceStatus.notYetOverdue.both",
                defaultMessage: "Show both upcoming and up to date",
                description: "Frequency status filter - not yet overdue - both",
              })}
            </Option>
            <Option value={NotYetOverdueVariant.UPCOMING}>
              {intl.formatMessage({
                id: "filters.cadenceStatus.notYetOverdue.upcoming",
                defaultMessage: "Show only upcoming",
                description: "Frequency status filter - not yet overdue - upcoming",
              })}
            </Option>
            <Option value={NotYetOverdueVariant.UP_TO_DATE}>
              {intl.formatMessage({
                id: "filters.cadenceStatus.notYetOverdue.upToDate",
                defaultMessage: "Show only up to date",
                description: "Frequency status filter - not yet overdue - up To Date",
              })}
            </Option>
          </SelectField>
        );
      }
      return (
        <div className={cn(styles.container)}>
          <NumberField
            inputClassName={className}
            onChange={(number) => onChange?.({ ...value, value: number })}
            ref={setRef}
            value={value?.value ?? ""}
          />
          <span className={styles.suffix}>
            {intl.formatMessage({
              id: "filters.cadenceStatus.suffix",
              defaultMessage: "days",
              description: "Days suffix in cadence status field filter",
            })}
          </span>
        </div>
      );
    },
};

export default FrequencyStatusFilter;

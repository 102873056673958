import React from "react";
import { PasswordValidationResult } from "../../../util/validatePasswords";
import ValidationItem from "./ValidationItem";
import { useIntl } from "react-intl";
import styles from "./PasswordValidation.module.scss";
import { PASSWORD_MINIMUM_LENGTH } from "util/consts";

interface Props {
  validationResult: PasswordValidationResult;
}

const PasswordValidation: React.FC<Props> = ({ validationResult }) => {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      <ValidationItem
        isValid={validationResult.hasGoodLength}
        name={intl.formatMessage(
          {
            id: "auth.passwordValidation.length",
            defaultMessage:
              "Minimum of {count} {count, plural, one {character} other {characters}} ",
            description: "Password length validation item",
          },
          { count: PASSWORD_MINIMUM_LENGTH }
        )}
      />
      <ValidationItem
        isValid={validationResult.containsUppercaseCharacters}
        name={intl.formatMessage({
          id: "auth.passwordValidation.case",
          defaultMessage: "At least one capital letter",
          description: "Password contains lower & uppercase items validation item",
        })}
      />
      <ValidationItem
        isValid={validationResult.containsSpecialCharacters}
        name={intl.formatMessage({
          id: "auth.passwordValidation.special",
          defaultMessage: "At least one special character",
          description: "Password contains special characters validation item",
        })}
      />
      <ValidationItem
        isValid={validationResult.passwordsMatch}
        name={intl.formatMessage({
          id: "auth.passwordValidation.match",
          defaultMessage: "Passwords must be equal",
          description: "Passwords should match validation item",
        })}
      />
    </div>
  );
};

export default PasswordValidation;

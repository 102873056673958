import React, { useMemo } from "react";
import { RawFile } from "@mapmycustomers/shared/types/File";
import { faFileAlt } from "@fortawesome/pro-light-svg-icons/faFileAlt";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons/faFilePdf";
import { faFileExcel } from "@fortawesome/pro-light-svg-icons/faFileExcel";
import { faFileWord } from "@fortawesome/pro-light-svg-icons/faFileWord";
import { faFileAudio } from "@fortawesome/pro-light-svg-icons/faFileAudio";
import { faFileImage } from "@fortawesome/pro-light-svg-icons/faFileImage";
import { faFileVideo } from "@fortawesome/pro-light-svg-icons/faFileVideo";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import Avatar from "antd/es/avatar/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { AvatarProps } from "antd/es/avatar";
import styles from "./FileIcon.module.scss";

enum FileType {
  AUDIO = "audio",
  DOCUMENT = "document",
  IMAGE = "image",
  OTHER = "other",
  PDF = "pdf",
  SPREADSHEET = "spreadsheet",
  VIDEO = "video",
}

const fileTypeToIcon: Record<FileType, IconDefinition> = {
  [FileType.AUDIO]: faFileAudio,
  [FileType.DOCUMENT]: faFileWord,
  [FileType.IMAGE]: faFileImage,
  [FileType.OTHER]: faFileAlt,
  [FileType.PDF]: faFilePdf,
  [FileType.SPREADSHEET]: faFileExcel,
  [FileType.VIDEO]: faFileVideo,
};

const mimeTypeToIcon: Record<string, FileType> = {
  "/^audio/": FileType.AUDIO,
  "/^image/": FileType.IMAGE,
  "/^video/": FileType.VIDEO,
  "application/pdf": FileType.PDF,
  "application/vnd.ms-excel": FileType.SPREADSHEET,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    FileType.SPREADSHEET,
  "application/msword": FileType.DOCUMENT,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    FileType.DOCUMENT,
};

const nameToIcon: Record<string, FileType> = {
  "/.mp3$/": FileType.AUDIO,
  "/.(jpeg|jpg|gif|png)$/": FileType.IMAGE,
  "/.(mp4|mov)$/": FileType.VIDEO,
  "/.pdf$/": FileType.PDF,
  "/.xlsx?$/": FileType.SPREADSHEET,
  "/.docx?$/": FileType.DOCUMENT,
};

const getFileType = (file: RawFile): FileType => {
  let fileType: FileType | undefined;

  const fileMimeType = Object.keys(mimeTypeToIcon).find((mimeType) => {
    if (mimeType.startsWith("/") && mimeType.endsWith("/")) {
      return new RegExp(mimeType.slice(1, -1)).test(file.contentType);
    }
    return mimeType === file.contentType;
  });
  if (fileMimeType) {
    fileType = mimeTypeToIcon[fileMimeType];
  }

  if (!fileType) {
    const fileName = Object.keys(nameToIcon).find((name) => {
      if (name.startsWith("/") && name.endsWith("/")) {
        return new RegExp(name.slice(1, -1)).test(file.name);
      }
      return file.name === name;
    });
    if (fileName) {
      fileType = nameToIcon[fileName];
    }
  }

  return fileType || FileType.OTHER;
};

interface FileIconProps extends Omit<AvatarProps, "className" | "icon"> {
  className?: string;
  file: RawFile;
}

const FileIcon: React.FC<FileIconProps> = ({ className, file, ...props }) => {
  const fileType = useMemo(() => getFileType(file), [file]);
  return (
    <Avatar
      className={cn(styles.container, styles[fileType], className)}
      icon={<FontAwesomeIcon icon={fileTypeToIcon[fileType]} />}
      {...props}
    />
  );
};

export default FileIcon;

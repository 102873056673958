import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import { Alert } from "@mapmycustomers/ui";
import { FormattedMessage, useIntl } from "react-intl";
import commonStyles from "../AuthCommon.module.scss";
import styles from "./ForgotPassword.module.scss";
import analyticsService from "../../../../util/analytic/AnalyticsService";
import Path from "../../../../enum/Path";

interface Props {
  errorMessage?: string;
  isLoading: boolean;
  onResetPassword: (email: string) => void;
}

const ForgotPasswordForm: React.FC<Props> = ({ errorMessage, isLoading, onResetPassword }) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const handleFormFieldsChange = useCallback(
    () => setCanSubmit(!form.getFieldsError().some((field) => field.errors.length > 0)),
    [form, setCanSubmit]
  );

  const handleSubmit = useCallback(
    (values: { email: string }) => {
      onResetPassword(values.email);
      analyticsService.clicked(["Forgot password", "Send Link"]);
    },
    [onResetPassword]
  );

  const handleGoBack = useCallback(
    () => analyticsService.clicked(["Forgot password", "Go back"]),
    []
  );

  return (
    <div className={commonStyles.container}>
      {errorMessage && <Alert message={errorMessage} type="error" />}
      <Form
        form={form}
        initialValues={{
          remember: true,
        }}
        name="forgotPassword"
        onFinish={handleSubmit}
        onFieldsChange={handleFormFieldsChange}
        preserve={false}
        layout="vertical"
      >
        <h1 className={commonStyles.title}>
          <FormattedMessage
            id="auth.forgotPassword.form.title"
            defaultMessage="Get Password reset link"
            description="Title of forgot password card"
          />
        </h1>
        <p className={commonStyles.description}>
          <FormattedMessage
            id="auth.forgotPassword.form.description"
            defaultMessage="Enter your email address and we’ll send you a password reset link there"
            description="Description of the forgot password card"
          />
        </p>
        <Form.Item
          colon={false}
          label={intl.formatMessage({
            id: "auth.forgotPassword.form.email",
            defaultMessage: "Email",
            description: "Title of the email field on the forgotPassword form",
          })}
          name="email"
          requiredMark="optional"
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: "auth.forgotPassword.form.email.required",
                defaultMessage: "Please input your email!",
                description: "Field is email error message for the username field",
              }),
            },
            { type: "email" },
          ]}
        >
          <Input autoComplete="username" name="username" size="large" type="email" />
        </Form.Item>
        <Form.Item>
          <Button
            className={styles.resetButton}
            disabled={!canSubmit || isLoading}
            htmlType="submit"
            loading={isLoading}
            type="primary"
          >
            {isLoading
              ? intl.formatMessage({
                  id: "auth.forgotPassword.form.submit.loading",
                  defaultMessage: "Sending reset Link...",
                  description: "Title of the submit login form button after it has been pressed",
                })
              : intl.formatMessage({
                  id: "auth.forgotPassword.form.submit",
                  defaultMessage: "Get Link",
                  description: "Title of the submit forgot password form button",
                })}
          </Button>
        </Form.Item>
        <div className={styles.backToLoginLink}>
          <Link onClick={handleGoBack} to={Path.LOGIN}>
            <FormattedMessage
              id="auth.forgotPassword.form.loginLink"
              defaultMessage="I remembered my password"
              description="Title of the Back to Login Link"
            />
          </Link>
        </div>
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;

import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";
import { UniversalFieldName } from "util/fieldModel/universalFieldsFieldModel";

const multiPinState = (state: RootState) => state.component.multiPin;

export const areMultiPinRecordsLoading = createSelector(multiPinState, ({ loading }) => loading);

export const getMultiPinRecordsListFilter = createSelector(multiPinState, ({ search }) =>
  search?.trim()
);
export const getMultiPinRecords = createSelector(multiPinState, ({ records }) => records);
export const getMultiPinRecordsListState = createSelector(multiPinState, ({ range, sort }) => ({
  range,
  sort,
}));
export const getMultiPinRecordsListTotal = createSelector(
  multiPinState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);
export const getMultiPinRecordsListTotalBeforeFilter = createSelector(
  multiPinState,
  ({ totalRecords }) => totalRecords
);

export const getMultiPin = createSelector(multiPinState, ({ multiPin }) => multiPin);
export const getVisibleMultiPinEntities = createSelector(
  multiPinState,
  ({ visibleEntities }) => visibleEntities
);
export const getMultiPinAddress = createSelector(
  multiPinState,
  ({ multiPinAddress }) => multiPinAddress
);

export const getMultiPinRecordsListSortOrder = createSelector(multiPinState, ({ sort }) => sort);

export const isMultiPinRecordsListSortedByDistance = createSelector(
  getMultiPinRecordsListSortOrder,
  (sort) => (sort?.length > 0 ? sort[0].field?.sortName === UniversalFieldName.GEOPOINT : false)
);

export const getMultiPinLocationQuery = createSelector(multiPinState, ({ query }) => query);

export const getPlatformFilterModel = createSelector(
  multiPinState,
  ({ platformFilterModel }) => platformFilterModel
);

import React, { useCallback, useEffect, useState } from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { endOfDay, isValid, parseISO, startOfDay } from "date-fns/esm";
import RangePicker from "component/input/RangePicker";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { defineMessage } from "react-intl";
import i18nService from "config/I18nService";
import { formatRawDate } from "util/formatters";

const doesSupportValue = (value: any, operator: FilterOperator) => {
  if (
    !Array.isArray(value) ||
    value.length !== 2 ||
    !DATE_RANGE_FILTER_OPERATORS.includes(operator)
  ) {
    return false;
  }

  const dateFrom = typeof value[0] === "string" ? parseISO(value[0]) : undefined;
  const dateTo = typeof value[1] === "string" ? parseISO(value[1]) : undefined;
  return isValid(dateFrom) && isValid(dateTo);
};

const descriptionMessage = defineMessage({
  id: "filters.dateRange.description",
  defaultMessage: "{fieldName} is in range from {from} to {to}",
  description: "DateRangeFilter human-readable description",
});

export const DATE_RANGE_FILTER_OPERATORS = [FilterOperator.IN_RANGE];

interface DateRangeFilterProps extends IFilterComponentProps {}

const DateRangeFilter: IFilterInstance = {
  doesSupportValue,
  getHumanReadableDescription: (value: SimpleCondition, field: IField) => {
    if (!doesSupportValue(value.value, value.operator)) {
      return undefined;
    }

    const dateFrom = formatRawDate(value.value[0], "PPP");
    const dateTo = formatRawDate(value.value[1], "PPP");

    return i18nService.formatMessage(
      descriptionMessage,
      `${field.displayName} is in range from ${dateFrom} to ${dateTo}`,
      {
        fieldName: field.displayName,
        from: dateFrom,
        to: dateTo,
      }
    );
  },
  getComponent:
    (): React.FC<DateRangeFilterProps> =>
    ({ className, focus, onChange, value }) => {
      const [dateFrom, setDateFrom] = useState(
        Array.isArray(value?.value) && value.value.length >= 1
          ? parseISO(value.value[0])
          : undefined
      );
      const [dateTo, setDateTo] = useState(
        Array.isArray(value?.value) && value.value.length === 2
          ? parseISO(value.value[1])
          : undefined
      );

      // using useEffect instead of "useCallback" for handleDateChange because we also
      // need to listen to operator changes and transform date accordingly
      useEffect(() => {
        const operator = value.operator;
        if (operator === FilterOperator.IN_RANGE && dateFrom && dateTo) {
          onChange?.({
            operator,
            value: [startOfDay(dateFrom).toISOString(), endOfDay(dateTo).toISOString()],
          });
        }
      }, [dateFrom, dateTo, onChange, value.operator]);

      const setRef = useCallback(
        (ref) => {
          if (ref && focus) {
            ref.focus();
          }
        },
        [focus]
      );

      return (
        <RangePicker
          allowClear
          className={className}
          format="PP"
          onChange={(dates: [Date | null, Date | null] | null) => {
            setDateFrom((dates && dates[0]) || undefined);
            setDateTo((dates && dates[1]) || undefined);
          }}
          ref={setRef}
          value={[dateFrom ?? null, dateTo ?? null]}
        />
      );
    },
};

export default DateRangeFilter;

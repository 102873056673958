import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import cn from "classnames";
import { LoadingSpinner, TextField, TextFieldProps } from "@mapmycustomers/ui";
import NoData from "component/preview/components/NoData";
import AssociationRow from "./AssociationRow";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Associations.module.scss";

interface Props<T extends Identified> {
  alreadyAssociatedMessage: ReactNode;
  assignedRecords?: T[];
  associateWith?: T["id"];
  cantAssociateWithSelfMessage: ReactNode;
  className?: string;
  entities?: T[];
  extras?: ReactNode;
  loading?: boolean;
  multiselect?: boolean;
  noDataImageSrc?: string;
  onChange?: (selectedRows: Identified["id"][]) => void;
  onChangeQuery?: (query: string) => void;
  onSearch?: (text: string) => void;
  query: string;
  textFieldProps?: TextFieldProps;
  value?: T["id"][];
}

type SupportedEntities = Company | Deal | Person;

const Associations = <T extends SupportedEntities>({
  alreadyAssociatedMessage,
  assignedRecords,
  associateWith,
  cantAssociateWithSelfMessage,
  className,
  entities,
  extras,
  loading,
  multiselect,
  noDataImageSrc,
  onChange,
  onChangeQuery,
  onSearch,
  query,
  textFieldProps,
  value,
}: Props<T>) => {
  const intl = useIntl();
  const [selectedRows, setSelectedRows] = useState<Set<Identified["id"]>>(new Set(value ?? []));

  useEffect(() => {
    setSelectedRows(new Set(value ?? []));
  }, [value]);

  const assignedRows = useMemo(
    () => new Set(assignedRecords?.map(({ id }) => id) ?? []),
    [assignedRecords]
  );

  const notifyAboutSearch = useDebouncedCallback(
    [
      useCallback(
        (searchText: string) => {
          onSearch?.(searchText.trim());
        },
        [onSearch]
      ),
      500,
    ],
    [onSearch]
  );

  const handleSetQuery = useCallback(
    (query: string) => {
      onChangeQuery?.(query);
      notifyAboutSearch(query);
    },
    [notifyAboutSearch, onChangeQuery]
  );

  const handleChange = useCallback(
    (entityId: Identified["id"], value: boolean) => {
      const updatedSelection = new Set(multiselect ? selectedRows : []);

      if (value) {
        updatedSelection.add(entityId);
      } else {
        updatedSelection.delete(entityId);
      }
      setSelectedRows(updatedSelection);

      onChange?.(Array.from(updatedSelection));
    },
    [multiselect, onChange, selectedRows, setSelectedRows]
  );

  const hasQuery = query?.trim().length > 0;

  return (
    <section className={cn(styles.container, className)}>
      <div className={styles.searchBar}>
        <TextField
          {...textFieldProps}
          allowClear={hasQuery}
          autoFocus
          className={cn(styles.searchField, textFieldProps?.className)}
          onChange={handleSetQuery}
          size="middle"
          suffix={<FontAwesomeIcon className={styles.searchIcon} icon={faSearch} />}
          value={query}
        />

        {extras && <div>{extras}</div>}
      </div>

      <div className={styles.sortOrder}>
        {intl.formatMessage({
          id: "associations.recentlyModified",
          defaultMessage: "Recently Modified",
          description: "Text for sort order for association list",
        })}
      </div>

      <div className={styles.listContainer}>
        <div className={styles.list}>
          {loading ? (
            <LoadingSpinner />
          ) : entities?.length ? (
            <>
              {entities.map((entity) => {
                return (
                  <AssociationRow
                    entity={entity}
                    key={entity.id}
                    multiselect={multiselect}
                    onChange={handleChange}
                    selected={selectedRows.has(entity.id)}
                    tooltipMessage={
                      entity.id === associateWith
                        ? cantAssociateWithSelfMessage
                        : assignedRows.has(entity.id)
                        ? alreadyAssociatedMessage
                        : null
                    }
                  />
                );
              })}
            </>
          ) : (
            <NoData
              imageSrc={noDataImageSrc ?? ""}
              text={intl.formatMessage({
                id: "associations.noRecords",
                defaultMessage: "No matching records found",
                description: "Text for no matching records found",
              })}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Associations;

import React from "react";
import { RootState } from "store/rootReducer";
import { getOrganization } from "../../../store/iam";
import { connect } from "react-redux";
import Path from "../../../enum/Path";
import { Link } from "react-router-dom";
import Organization from "@mapmycustomers/shared/types/Organization";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import styles from "./TrialExpiration.module.scss";
import hasTrialExpired from "../../../util/hasTrialExpired";

interface Props {
  organization: Organization | undefined;
}

const TrialExpiration: React.FC<Props> = ({ organization }) => {
  const intl = useIntl();
  if (!hasTrialExpired(organization)) {
    return null;
  }

  return (
    <Link className={styles.link} to={Path.BILLING}>
      <section className={styles.container}>
        <FontAwesomeIcon className={styles.icon} icon={faExclamationCircle} />
        <div className={styles.text}>
          {intl.formatMessage(
            {
              id: "trial.expired",
              defaultMessage:
                "<span>Your 14-day free trial has expired.</span><span>Please purchase a subscription to continue using Map My Customers.</span>",
              description: "Trial message to appear under nav bar when trial expires",
            },
            { span: (text: string) => <span>{text}</span> }
          )}
        </div>
      </section>
    </Link>
  );
};

const mapStateToProps = (state: RootState) => ({
  organization: getOrganization(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TrialExpiration);

import Field, { FieldProperties } from "./Field";
import { listFormatter } from "./fieldUtil";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import SchemaFieldCategory from "@mapmycustomers/shared/enum/SchemaFieldCategory";

class NotesField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customGridProperties: {
        cellRenderer: "notesCellRenderer",
        // disable tooltips for this field, since we use custom renderer with a popover
        tooltipValueGetter: undefined,
      },
      extraPlatformFiltersGetter: () => ({ includeNotes: true }),
      features: [
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      type: FieldType.LIST,
      valueFormatter: listFormatter("note"),
      ...data,
    });
  }

  get platformFieldCategory() {
    return SchemaFieldCategory.NOTE;
  }
}

export default NotesField;

import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";
import { getPinnedDashboardIds } from "store/iam";

const cumulState = (state: RootState) => state.cumul;
export const getCumulAuth = createSelector(cumulState, ({ cumulAuth }) => cumulAuth);

export const getCumulDashboardList = createSelector(
  cumulState,
  ({ cumulDashboardList }) => cumulDashboardList ?? []
);

export const getVisibleDashboardId = createSelector(
  cumulState,
  ({ dashboardIdToOpen }) => dashboardIdToOpen
);

export const getPinnedDashboards = createSelector(
  getPinnedDashboardIds,
  getCumulDashboardList,
  (pinnedIds, dashboards) => {
    const pinnedIdsSet = new Set(pinnedIds);
    return (dashboards ?? [])
      .filter(({ id }) => pinnedIdsSet.has(id))
      .sort((a, b) => (a.name.en ?? "").localeCompare(b.name.en ?? ""));
  }
);

import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const componentState = (state: RootState) => state.component.selectBar;

export const isDeleting = createSelector(
  componentState,
  ({ deleteEntitiesLoading }) => deleteEntitiesLoading
);

export const isUpdating = createSelector(
  componentState,
  ({ updateEntitiesLoading }) => updateEntitiesLoading
);

export const getExistingRoutesIds = createSelector(
  componentState,
  ({ existingRoutesIds }) => existingRoutesIds
);

export const getRoutes = createSelector(componentState, ({ routes }) => routes);

export const isRouteModalInitializing = createSelector(
  componentState,
  ({ routeModalInitializing }) => routeModalInitializing
);

export const getTotalRoutes = createSelector(componentState, ({ totalRoutes }) => totalRoutes);

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import {
  EntityTypesSupportingFieldCustomization,
  EntityTypeSupportingCustomFields,
} from "@mapmycustomers/shared/types/entity";
import SchemaField from "@mapmycustomers/shared/types/schema/SchemaField";
import CustomField, {
  CustomFieldPayload,
} from "@mapmycustomers/shared/types/customField/CustomField";
import FieldUpdatePayload from "../type/FieldUpdatePayload";
import { ManageFieldsViewState } from "./index";

export const enterArchiveMode = createAction("settings/formsAndFields/enterArchiveMode")<void>();
export const exitArchiveMode = createAction("settings/formsAndFields/exitArchiveMode")<void>();

export const updateViewState = createAction(
  "settings/formsAndFields/updateViewState"
)<ManageFieldsViewState>();

export const setActiveEntityType = createAction(
  "settings/formsAndFields/setActiveEntityType"
)<EntityTypesSupportingFieldCustomization>();

export const createField = createAsyncAction(
  "settings/formsAndFields/createField/request",
  "settings/formsAndFields/createField/success",
  "settings/formsAndFields/createField/failure"
)<
  {
    callback?: () => void;
    customField: CustomFieldPayload;
    entityType: EntityTypeSupportingCustomFields;
    field: Partial<SchemaField>;
  },
  void,
  unknown
>();

export const editField = createAsyncAction(
  "settings/formsAndFields/editField/request",
  "settings/formsAndFields/editField/success",
  "settings/formsAndFields/editField/failure"
)<
  SchemaField["id"],
  {
    customField?: CustomField;
    field: SchemaField;
  },
  void
>();
export const cancelEditField = createAction("settings/formsAndFields/cancelEditField")<
  boolean | void
>();

export const updateField = createAsyncAction(
  "settings/formsAndFields/updateField/request",
  "settings/formsAndFields/updateField/success",
  "settings/formsAndFields/updateField/failure"
)<FieldUpdatePayload, void, unknown>();

export const bulkUpdateField = createAsyncAction(
  "settings/formsAndFields/bulkUpdateField/request",
  "settings/formsAndFields/bulkUpdateField/success",
  "settings/formsAndFields/bulkUpdateField/failure"
)<SchemaField[], void, unknown>();

export const archiveField = createAsyncAction(
  "settings/formsAndFields/archiveField/request",
  "settings/formsAndFields/archiveField/success",
  "settings/formsAndFields/archiveField/failure"
)<SchemaField, void, unknown>();

export const unarchiveField = createAsyncAction(
  "settings/formsAndFields/unarchiveField/request",
  "settings/formsAndFields/unarchiveField/success",
  "settings/formsAndFields/unarchiveField/failure"
)<SchemaField, void, unknown>();

export const updateSchema = createAsyncAction(
  "settings/formsAndFields/updateSchema/request",
  "settings/formsAndFields/updateSchema/success",
  "settings/formsAndFields/updateSchema/failure"
)<
  void,
  {
    fields: SchemaField[];
    total: number;
  },
  unknown
>();

export type Actions = ActionType<
  | typeof updateViewState
  | typeof updateSchema
  | typeof createField
  | typeof editField
  | typeof cancelEditField
  | typeof updateField
  | typeof archiveField
  | typeof unarchiveField
  | typeof enterArchiveMode
  | typeof exitArchiveMode
  | typeof setActiveEntityType
  | typeof bulkUpdateField
>;

import notification from "antd/es/notification";
import i18nService from "config/I18nService";
import localSettings from "config/LocalSettings";
import { getLocation, push, replace, RouterLocation } from "connected-react-router";
import Role from "@mapmycustomers/shared/enum/Role";
import { defineMessage, defineMessages } from "react-intl";
import { all, call, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { StageSummary } from "@mapmycustomers/shared/types/entity/deals/StageSummary";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import { isCustomField } from "util/fieldModel/impl/assert";
import CustomField from "util/fieldModel/impl/CustomField";
import WeeklyReportSetting from "types/report/WeeklyReportCardSettings";
import { convertToPlatformSortModel } from "util/viewModel/convertSort";
import DateRangeType from "../enums/DateRangeType";
import DateRange from "../types/DateRange";
import dateRangeFilterToRange from "../utils/dateRangeFilterToRange";
import {
  applyPreviewViewSettings,
  cloneReport,
  createReport,
  deleteReport,
  downloadActivityReportData,
  downloadLeaderboardTableData,
  downloadModalData,
  downloadReport,
  downloadReportEntities,
  editReport,
  exportModalEntities,
  fetchActivityCardData,
  fetchActivityCardListData,
  fetchDealsByStage,
  fetchDealsGeoChartData,
  fetchDealsMapData,
  fetchExportCardData,
  fetchExtraInfo,
  fetchFunnelsStats,
  fetchLeaderboardData,
  fetchLeaderboardMetricsData,
  fetchModalPreviewData,
  FetchModalPreviewPayload,
  fetchOverviewCardData,
  fetchOverviewMapDeals,
  fetchPreviewData,
  fetchReport,
  fetchReportRecordsCountInfo,
  initializeLeaderboard,
  saveReport,
  saveWeeklyReport,
  scheduleReport,
  setDateRange,
  setDateRangeType,
  setUserIds,
  toggleWeeklyReport,
} from "./actions";
import { handleError } from "store/errors/actions";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import Company from "@mapmycustomers/shared/types/entity/Company";
import { callApi, callReadonlyApi } from "store/api/callApi";
import {
  getCurrentUser,
  getMe,
  getOrganization,
  getOrganizationId,
  getUserSettings,
  isBigOrganization,
  isCurrentUserManager,
  isCurrentUserOwner,
} from "store/iam";
import { getTeams, getUsers } from "store/members";
import {
  Activity,
  AnyEntity,
  Deal,
  EntityType,
  EntityTypeSupportingReports,
  Person,
  Route,
} from "@mapmycustomers/shared/types/entity";
import { ApiMethodName } from "store/api/ApiService";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Report from "types/Report";
import Iam from "types/Iam";
import Path from "enum/Path";
import { convertToPlatformFilterModel } from "util/viewModel/convertToPlatformFilterModel";
import ExportCardData from "./ExportCardData";
import {
  getActivityCardCriteria,
  getCurrentReport,
  getDateRange,
  getDateRangeType,
  getExportCardData,
  getLeaderboardMetricsData,
  getLeaderboardMetricsViewState,
  getLeaderboardTableData,
  getLeaderboardViewState,
  getPreviewViewState,
  getReportListViewState,
  getUserIds,
} from "./selectors";
import getDefaultReportPreviewViewState from "./getDefaultReportPreviewViewState";
import { PREVIEW_LIMIT } from "./consts";
import User from "@mapmycustomers/shared/types/User";
import PlatformFilterModel, {
  PlatformFilterCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import MapFilter from "../enums/MapFilter";
import invariant from "tiny-invariant";
import Organization from "@mapmycustomers/shared/types/Organization";
import Statistics from "types/Statistics";
import downloadStackRankDataAsCsv from "util/stackRank/downloadStackRankDataAsCsv";
import ActivityCardData from "./ActivityCardData";
import { formatISODate } from "../utils/date";
import { updateSetting } from "store/iam/actions";
import { isActionOf } from "typesafe-actions";
import Setting from "@mapmycustomers/shared/types/Setting";
import downloadEntityPreviewsAsCsv from "../utils/csv/downloadEntityPreviewsAsCsv";
import PlatformListRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformListRequest";
import OverviewData, { ClosedWonDeals, PreviewData } from "./OverviewData";
import {
  EntitiesSupportedInOverviewPreview,
  EntityTypeSupportedInOverviewPreview,
} from "scene/reports/types/EntityTypeSupportedInOverviewPreview";
import { getLocalTimeZone, getLocalTimeZoneFormattedOffset } from "util/dates";
import LeaderboardItem from "@mapmycustomers/shared/types/entity/LeaderboardItem";
import LeaderboardViewState from "types/viewModel/LeaderboardViewState";
import Team from "@mapmycustomers/shared/types/Team";
import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import AggregatedListResponse from "../../../types/viewModel/AggregatedListResponse";
import ListResponseAggregation from "../../../types/viewModel/ListResponseAggregation";
import { getFunnels } from "../../../store/deal";
import { exportEntities } from "../../../store/exportEntities/actions";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { getActivityTypes } from "store/activity";
import getActivityTypeWithCount from "util/activity/getActivityTypeWithCount";
import reportFieldModel from "util/fieldModel/ReportFieldModel";
import { pickBy } from "lodash-es";
import groupRecordsByKey from "store/api/utils/groupRecordsByKey";
import LeaderboardMetricsData from "../types/LeaderboardMetricsData";
import { DEFAULT_ENTITY_LIST_PAGE_SIZE } from "scene/reports/utils/const";
import { EntityPin, MapEntry } from "../../../types/map";
import getPrecision from "../../map/utils/getPrecision";
import { MAP_ENTITY_TYPES } from "../../../util/map/consts";
import getColorShapeForEntity from "../../../util/map/markerStyles/getColorShapeForEntity";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import { getMapViewSettings } from "../../../store/map";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import { getAllColorLegends, getAllShapeLegends } from "../../../store/pinLegends";
import getReportCloneSuccessNotificationNode from "./getReportCloneSuccessNotificationNode";

const messages = defineMessages({
  weeklyReportOptedIn: {
    id: "reports.weeklyReportsCard.notification.optedIn",
    defaultMessage: "Opted in to weekly reports",
    description: "Successfully opted-in notification message on Weekly Reports page",
  },
  weeklyReportOptedOut: {
    id: "reports.weeklyReportsCard.notification.optedOut",
    defaultMessage: "Opted out of weekly reports",
    description: "Successfully opted-out notification message on Weekly Reports page",
  },
  weeklyReportOptFailed: {
    id: "reports.weeklyReportsCard.notification.optFailed",
    defaultMessage: "Error updating preferences... Please try again later.",
    description: "Failed to opted-in/out notification message on Weekly Reports page",
  },
  weeklyReportSaved: {
    id: "reports.weeklyReportsCard.notification.saved",
    defaultMessage: "Scorecard preferences updated successfully",
    description: "Successfully saved weekly report notification message on Weekly Reports page",
  },
  weeklyReportSaveFailed: {
    id: "reports.weeklyReportsCard.notification.saveFailed",
    defaultMessage: "Error updating preferences... Please try again later.",
    description: "Failed to save weekly report notification message on Weekly Reports page",
  },
});

const entityTypeToApiMethodName: { [key in EntityTypeSupportingReports]: ApiMethodName } = {
  [EntityType.ACTIVITY]: "fetchActivities",
  [EntityType.COMPANY]: "fetchCompanies",
  [EntityType.DEAL]: "fetchDeals",
  [EntityType.PERSON]: "fetchPeople",
} as const;

const getPreviewPlatformRequest = ({
  dateRange,
  entityType,
  limit,
  userIds,
}: {
  dateRange: DateRange;
  entityType: EntityType;
  limit?: number;
  userIds: User["id"][];
}): Partial<PlatformListRequest> => {
  const userFilter = getUserFilter(userIds);

  let request: Partial<PlatformListRequest> = {
    $filters: {
      ...userFilter,
      $and: [
        {
          createdAt: {
            $gte: formatISODate(dateRange.startDate) + "T00:00:00Z",
            $lte: formatISODate(dateRange.endDate) + "T23:59:59Z",
            $offset: getLocalTimeZoneFormattedOffset(),
          },
        },
      ],
      includeAccessStatus: true,
      includeCustomFields: true,
      includeGroups: true,
      includeNotes: true,
      includeRoutes: true,
      includeTerritories: true,
    },
    $order: "-createdAt",
    ...(limit ? { $limit: limit } : {}),
  };

  if (entityType === EntityType.ACTIVITY) {
    request = {
      ...request,
      $filters: {
        $and: [
          {
            assigneeId: { $in: userIds },
            completed: true,
            completedAt: {
              $gte: formatISODate(dateRange.startDate) + "T00:00:00Z",
              $lte: formatISODate(dateRange.endDate) + "T23:59:59Z",
              $offset: getLocalTimeZoneFormattedOffset(),
            },
          },
        ],
      },
      $order: "-completedAt",
    };
  } else if (entityType === EntityType.DEAL) {
    request = {
      ...request,
      $order: "-closingDate",
    };
  }
  return request;
};

const getUserFilter = (userIds: Array<User["id"]>): PlatformFilterModel => {
  if (!userIds.length) {
    return {};
  }

  // using userId for the group of users (one team or a whole organization) to
  // only fetch their own records.
  // Since records can only be shared in a scope of one team, that effectively
  // fetch both own and shared records.
  // For the org filter, we simply need all data which of course also includes
  // owned and shared records.
  // Currently we fetch owned records and not actually created by user ones
  return { userId: { $in: userIds } };
};

export function* onFetchExtraInfo({
  payload: { entityId, entityType },
}: ReturnType<typeof fetchExtraInfo.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    let apiMethod: ApiMethodName | undefined = undefined;
    switch (entityType) {
      case EntityType.COMPANY:
        apiMethod = "fetchCompany";
        break;
      case EntityType.PERSON:
        apiMethod = "fetchPerson";
        break;
      case EntityType.DEAL:
        apiMethod = "fetchDeal";
        break;
    }

    const response: AnyEntity | undefined = apiMethod
      ? yield callReadonlyApi(apiMethod, organization.id, entityId, { includeAccessStatus: true })
      : undefined;
    yield put(fetchExtraInfo.success(response));
  } catch (error) {
    yield put(fetchExtraInfo.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchOverviewMapDeals({
  payload: { filter, funnelId, stageId, userIds },
}: ReturnType<typeof fetchOverviewMapDeals.request>) {
  try {
    const userFilter = getUserFilter(userIds);
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const andCondition: PlatformFilterCondition[] = [{ amount: { $gt: 0 } }];
    const filters: PlatformFilterModel = {
      ...userFilter,
      $and: andCondition,
      includeAccessStatus: true,
      mapped: true,
    };
    if (filter === MapFilter.CLOSED_WON_REVENUE) {
      andCondition.push({
        "stage.type": {
          [PlatformFilterOperator.EQUALS]: DealStageType.WON,
        },
      });
    } else if (filter === MapFilter.CLOSED_LOST_REVENUE) {
      andCondition.push({
        "stage.type": {
          [PlatformFilterOperator.EQUALS]: DealStageType.LOST,
        },
      });
    } else if (filter === MapFilter.GROSS_OPEN_REVENUE) {
      andCondition.push({
        "stage.type": {
          [PlatformFilterOperator.NOT_CONTAINS]: [DealStageType.WON, DealStageType.LOST],
        },
      });
      if (funnelId) {
        andCondition.push({ funnelId });
      }
      if (stageId) {
        andCondition.push({ dealStageId: stageId });
      }
    }

    const dealResponse: ListResponse<Deal> = yield callReadonlyApi("fetchDeals", orgId, {
      $columns: [
        "id",
        "name",
        "amount",
        "userId",
        "funnelId",
        "accountId",
        "contactId",
        "dealStageId",
      ],
      $filters: filters,
      $limit: 10000,
    });
    yield put(fetchOverviewMapDeals.success(dealResponse.data));
  } catch (error) {
    yield put(fetchOverviewMapDeals.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchOverviewCardData({
  payload: { dateRange, parentUserIds, userIds },
}: ReturnType<typeof fetchOverviewCardData.request>) {
  try {
    yield call(syncUserIdQueryParamWithCurrentUserId);
    yield call(syncRangeQueryParamWithCurrentDateRangeFilter);

    const organization: Organization = yield select(getOrganization);
    const funnels: Funnel[] = yield select(getFunnels);

    const parentUserFilter = {
      ...getUserFilter(parentUserIds),
      $and: [
        {
          closingDate: {
            $gte: dateRange.startDate.toISOString(),
            $lte: dateRange.endDate.toISOString(),
            $offset: getLocalTimeZoneFormattedOffset(),
          },
        },
      ],
    };
    const userFilter = getUserFilter(userIds);
    const closingDateAndCondition = [
      {
        closingDate: {
          $gte: dateRange.startDate.toISOString(),
          $lte: dateRange.endDate.toISOString(),
          $offset: getLocalTimeZoneFormattedOffset(),
        },
      },
    ];

    const [
      parentStatisticsResponse,
      statisticsResponse,
      statisticsByDateResponse,
      activityResponse,
      mappedDealStages,
      wonParentDealsSummaryResponse,
      lostParentDealsSummaryResponse,
      wonDealsSummaryResponse,
      lostDealsSummaryResponse,
      maxAmountByStageTypeResponse,
      closedWonDealsResponse,
    ]: [
      ListResponse<Statistics>,
      ListResponse<Statistics>,
      ListResponse<Statistics>,
      AggregatedListResponse<Activity, ListResponseAggregation<Activity>[]>,
      AggregatedListResponse<Deal, ListResponseAggregation<Deal>[]>,
      AggregatedListResponse<Deal>,
      AggregatedListResponse<Deal>,
      AggregatedListResponse<Deal>,
      AggregatedListResponse<Deal>,
      AggregatedListResponse<Deal, ListResponseAggregation<Deal>[]>,
      AggregatedListResponse<Deal, ListResponseAggregation<Deal>[]>
    ] = yield all([
      //parentStatisticsResponse
      callReadonlyApi("fetchStatistics", organization.id, {
        date: {
          $gte: formatISODate(dateRange.startDate),
          $lte: formatISODate(dateRange.endDate),
          $offset: getLocalTimeZoneFormattedOffset(),
        },
        summary: "all",
        userId: { $in: parentUserIds },
      }),
      //statisticsResponse
      callReadonlyApi("fetchStatistics", organization.id, {
        date: {
          $gte: formatISODate(dateRange.startDate),
          $lte: formatISODate(dateRange.endDate),
          $offset: getLocalTimeZoneFormattedOffset(),
        },
        summary: "all",
        userId: { $in: userIds },
      }),
      //statisticsByDateResponse
      callReadonlyApi("fetchStatistics", organization.id, {
        date: {
          $gte: formatISODate(dateRange.startDate),
          $lte: formatISODate(dateRange.endDate),
          $offset: getLocalTimeZoneFormattedOffset(),
        },
        summary: "date",
        userId: { $in: userIds },
      }),
      //activityResponse
      callReadonlyApi("fetchActivities", organization.id, {
        $aggs: { primaryGroup: { field: "crmActivityTypeId" } },
        $filters: {
          ...userFilter,
          $and: [
            {
              createdAt: {
                $gte: dateRange.startDate.toISOString(),
                $lte: dateRange.endDate.toISOString(),
                $offset: getLocalTimeZoneFormattedOffset(),
              },
            },
          ],
          completed: true,
        },
        $limit: 0,
      }),
      // mapped stages
      callReadonlyApi("fetchDeals", organization.id, {
        $aggs: {
          primaryGroup: { field: "dealStageId" },
        },
        $filters: {
          ...userFilter,
          mapped: true,
        },
        $limit: 0,
      }),
      // Parent deals statistics
      callReadonlyApi("fetchDeals", organization.id, {
        $aggs: {
          sum: ["amount"],
        },
        $filters: {
          ...parentUserFilter,
          $and: [
            {
              "stage.type": {
                [PlatformFilterOperator.EQUALS]: DealStageType.WON,
              },
            },
          ],
        },
        $limit: 0,
      }),

      callReadonlyApi("fetchDeals", organization.id, {
        $aggs: {
          sum: ["amount"],
        },
        $filters: {
          ...parentUserFilter,
          $and: [
            {
              "stage.type": {
                [PlatformFilterOperator.EQUALS]: DealStageType.LOST,
              },
            },
          ],
        },
        $limit: 0,
      }),

      // Deal statistics
      callReadonlyApi("fetchDeals", organization.id, {
        $aggs: {
          sum: ["amount"],
        },
        $filters: {
          ...userFilter,
          $and: [
            ...closingDateAndCondition,
            {
              "stage.type": {
                [PlatformFilterOperator.EQUALS]: DealStageType.WON,
              },
            },
          ],
        },
        $limit: 0,
      }),
      callReadonlyApi("fetchDeals", organization.id, {
        $aggs: {
          sum: ["amount"],
        },
        $filters: {
          ...userFilter,
          $and: [
            ...closingDateAndCondition,
            {
              "stage.type": {
                [PlatformFilterOperator.EQUALS]: DealStageType.LOST,
              },
            },
          ],
        },
        $limit: 0,
      }),

      // Max amount
      callReadonlyApi("fetchDeals", organization.id, {
        $aggs: {
          max: ["amount"],
          primaryGroup: { field: "stage.type" },
        },
        $filters: {
          ...userFilter,
          $and: [...closingDateAndCondition],
        },
        $limit: 0,
      }),
      // aggregated closed won deals by day

      callReadonlyApi("fetchDeals", organization.id, {
        $aggs: {
          primaryGroup: { field: "closingDate", interval: "day", timezone: getLocalTimeZone() },
          sum: ["amount"],
        },
        $filters: {
          ...userFilter,
          $and: [
            ...closingDateAndCondition,
            {
              "stage.type": {
                [PlatformFilterOperator.EQUALS]: DealStageType.WON,
              },
            },
          ],
        },
        $limit: 0,
      }),
    ]);

    const activityTypes: ActivityType[] = yield select(getActivityTypes);
    const activityTypesWithCount = getActivityTypeWithCount(
      activityTypes,
      activityResponse.aggregations
    );

    const funnelsSummaries: Array<ListResponse<StageSummary>> = yield all(
      funnels.map((funnel) =>
        callReadonlyApi("fetchAllFunnelSummary", organization.id, funnel.id, {
          $filters: {
            ...userFilter,
            // ignoring because this endpoint doesn't support $and filters, and we have to specify
            // "closingDate" at the top level
            // @ts-ignore
            closingDate: {
              $gte: dateRange.startDate.toISOString(),
              $lte: dateRange.endDate.toISOString(),
              $offset: getLocalTimeZoneFormattedOffset(),
            },
          },
        })
      )
    );

    const mappedDealStageIds = new Set(mappedDealStages.aggregations.map(({ key }) => key));

    yield put(
      fetchOverviewCardData.success({
        activityTypesWithCount: activityTypesWithCount,
        closedWonDeals: closedWonDealsResponse.aggregations
          .filter(({ doc_count }) => doc_count && doc_count > 0)
          .reduce<ClosedWonDeals>(
            (result, aggregation) =>
              result.set(aggregation.key as number, {
                amount: aggregation.sum_amount.value as number,
                count: aggregation.doc_count ?? 0,
              }),
            new Map()
          ),
        dealsPerFunnel: funnelsSummaries.map((funnelSummary, index) => ({
          funnelName: funnels[index].name,
          numberOfDeals: funnelSummary.data.reduce(
            (result, { totalDeals }) => result + totalDeals,
            0
          ),
        })),
        mapFunnelStages: funnels.reduce<Record<Funnel["id"], Funnel>>((result, funnel) => {
          const stages = funnel.stages.filter(({ id }) => mappedDealStageIds.has(id));
          if (stages.length > 0) {
            if (funnel) {
              return { ...result, [funnel.id]: { ...funnel, stages } };
            }
          }
          return result;
        }, {}),
        maxAmountByStageType: maxAmountByStageTypeResponse.aggregations.reduce<
          Partial<Record<DealStageType, number>>
        >(
          (result, aggregation) => ({
            ...result,
            [aggregation.key as DealStageType]: aggregation.max_amount.value ?? 0,
          }),
          {}
        ),
        modalPreviews: {
          downloading: false,
          [EntityType.ACTIVITY]: { items: [], total: 0 },
          [EntityType.COMPANY]: { items: [], total: 0 },
          [EntityType.DEAL]: { items: [], total: 0 },
          [EntityType.PERSON]: { items: [], total: 0 },
          [EntityType.ROUTE]: { items: [], total: 0 },
          error: undefined,
          loading: false,
        },
        numDealsLost: lostDealsSummaryResponse.total,
        numDealsWon: wonDealsSummaryResponse.total,
        parentNumDealsLost: lostParentDealsSummaryResponse.total,
        parentNumDealsWon: wonParentDealsSummaryResponse.total,
        parentRevenueLost: lostParentDealsSummaryResponse.aggregations.sum_amount.value,
        parentRevenueWon: wonParentDealsSummaryResponse.aggregations.sum_amount.value,
        parentStatistics: parentStatisticsResponse.data.length
          ? parentStatisticsResponse.data[0].values
          : {},
        recordsOverTime: statisticsByDateResponse.data,
        revenueLost: lostDealsSummaryResponse.aggregations.sum_amount.value,
        revenueWon: wonDealsSummaryResponse.aggregations.sum_amount.value,
        statistics: statisticsResponse.data.length ? statisticsResponse.data[0].values : {},
      })
    );
  } catch (error) {
    yield put(fetchOverviewCardData.failure(error));
    yield put(handleError({ error }));
  }
}

function* fetchPreview<T extends EntityTypeSupportedInOverviewPreview>({
  dateRange,
  entityType,
  limit = 25,
  userIds,
}: FetchModalPreviewPayload<T>): Generator<
  any,
  PreviewData<EntitiesSupportedInOverviewPreview>,
  any
> {
  const org: Organization = yield select(getOrganization);

  const request = getPreviewPlatformRequest({ dateRange, entityType, limit, userIds });

  if (entityType === EntityType.ACTIVITY) {
    const result: ListResponse<Activity> = yield callReadonlyApi(
      "fetchActivities",
      org.id,
      request
    );
    return { items: result.data, total: result.total };
  } else if (entityType === EntityType.DEAL) {
    const result: ListResponse<Deal> = yield callReadonlyApi("fetchDeals", org.id, request);
    return { items: result.data, total: result.total };
  } else if (entityType === EntityType.COMPANY) {
    const result: ListResponse<Company> = yield callReadonlyApi("fetchCompanies", org.id, request);
    return { items: result.data, total: result.total };
  } else if (entityType === EntityType.PERSON) {
    const result: ListResponse<Person> = yield callReadonlyApi("fetchPeople", org.id, request);
    return { items: result.data, total: result.total };
  } else if (entityType === EntityType.ROUTE) {
    const result: ListResponse<Route>[] = yield all([
      callReadonlyApi("fetchCompanyRoutes", org.id, request),
      callReadonlyApi("fetchPeopleRoutes", org.id, request),
    ]);

    return {
      items: result.flatMap(({ data }, index) =>
        data.map((route) => ({
          ...route,
          itemType: !index ? EntityType.COMPANY_ROUTE : EntityType.PEOPLE_ROUTE,
        }))
      ),
      total: result.reduce((acc, { total }) => acc + total, 0),
    };
  }
  throw new Error("Unsupported EntityType");
}

export function* onFetchModalPreviewData({
  payload,
}: ReturnType<typeof fetchModalPreviewData.request>) {
  try {
    const preview: OverviewData["modalPreviews"][EntityTypeSupportedInOverviewPreview] =
      yield fetchPreview(payload);
    yield put(
      fetchModalPreviewData.success({
        [payload.entityType]: preview,
      })
    );
  } catch (error) {
    yield put(fetchModalPreviewData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onDownloadModalData({
  payload: { dateRange, entityType, limit = -1, reportType, userIds },
}: ReturnType<typeof downloadModalData.request>) {
  try {
    const users: User[] = yield select(getUsers);
    const preview: OverviewData["modalPreviews"][EntityTypeSupportedInOverviewPreview] =
      yield fetchPreview({ dateRange, entityType, limit, userIds });
    downloadEntityPreviewsAsCsv(preview.items, reportType, users);

    yield put(downloadModalData.success({}));
  } catch (error) {
    yield put(downloadModalData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onExportModalEntities({
  payload: { columns, dateRange, entityType, userIds },
}: ReturnType<typeof exportModalEntities>) {
  const platformRequest = getPreviewPlatformRequest({ dateRange, entityType, userIds });

  yield put(
    exportEntities.request({
      entityType,
      platformRequest,
      viewState: { columns },
    })
  );
}

export const getActivityCardRequestFilters = (
  mapFilter: MapFilter,
  dateRange: DateRange,
  userIds: User["id"][]
): PlatformFilterModel => {
  const userFilter = getUserFilter(userIds);
  const assigneeFilter = { assigneeId: { $in: userIds } };

  if (
    mapFilter === MapFilter.COMPLETED_ACTIVITIES ||
    mapFilter === MapFilter.AUTO_ACTIVITIES ||
    mapFilter === MapFilter.OVERDUE_ACTIVITIES ||
    mapFilter === MapFilter.UPCOMING_ACTIVITIES
  ) {
    const completedOnly =
      mapFilter !== MapFilter.OVERDUE_ACTIVITIES && mapFilter !== MapFilter.UPCOMING_ACTIVITIES;

    const dateFilter: PlatformFilterCondition =
      mapFilter === MapFilter.OVERDUE_ACTIVITIES
        ? // everything that should have been started already, but not yet completed
          { startAt: { $lte: new Date().toISOString() } }
        : {
            [completedOnly ? "completedAt" : "startAt"]: {
              $gte: dateRange.startDate.toISOString(),
              $lte: dateRange.endDate.toISOString(),
            },
          };

    const additionalFilters: PlatformFilterCondition[] = [assigneeFilter, dateFilter];

    if (mapFilter === MapFilter.AUTO_ACTIVITIES) {
      additionalFilters.push({ reliability: { $ne: null } });
    }

    return {
      $and: additionalFilters,
      completed: completedOnly,
      includeAccessStatus: true,
    };
  } else if (
    mapFilter === MapFilter.COMPANIES_OVER_CADENCE ||
    mapFilter === MapFilter.COMPANIES_30_DAYS_NO_CONTACT
  ) {
    return {
      ...userFilter,
      includeAccessStatus: true,
      includeGroups: true, // required if we wanna get cadence data
      mapped: true,
      ...(mapFilter === MapFilter.COMPANIES_30_DAYS_NO_CONTACT ? { noContactDaysOut: 30 } : {}),
    };
  } else if (
    mapFilter === MapFilter.PEOPLE_OVER_CADENCE ||
    mapFilter === MapFilter.PEOPLE_30_DAYS_NO_CONTACT
  ) {
    return {
      ...userFilter,
      includeAccessStatus: true,
      includeGroups: true, // required if we wanna get cadence data
      mapped: true,
      ...(mapFilter === MapFilter.PEOPLE_30_DAYS_NO_CONTACT ? { noContactDaysOut: 30 } : {}),
    };
  } else {
    invariant(false, `Invalid map filter provided on the activities report page: ${mapFilter}`);
  }
};

export function* onFetchActivityCardData() {
  const { bounds, dateRange, mapFilter, userIds, zoom } = yield select(getActivityCardCriteria);

  if (mapFilter && dateRange && userIds) {
    try {
      yield call(syncUserIdQueryParamWithCurrentUserId);
      yield call(syncRangeQueryParamWithCurrentDateRangeFilter);

      const orgId: Organization["id"] = yield select(getOrganizationId);
      const $filters = getActivityCardRequestFilters(mapFilter, dateRange, userIds);
      const bigOrganization: boolean = yield select(isBigOrganization);

      if (
        mapFilter === MapFilter.COMPLETED_ACTIVITIES ||
        mapFilter === MapFilter.AUTO_ACTIVITIES ||
        mapFilter === MapFilter.OVERDUE_ACTIVITIES ||
        mapFilter === MapFilter.UPCOMING_ACTIVITIES
      ) {
        const activityResponse: ListResponse<MapEntry> = yield callApi(
          "fetchMappedActivities",
          orgId,
          {
            $filters: {
              ...$filters,
              bounds,
              precision: getPrecision(zoom ?? 1, bigOrganization) * 4,
              precisionThreshold: 1000,
            },
          }
        );
        yield put(
          fetchActivityCardData.success({
            pins: activityResponse.data,
          })
        );
      } else if (
        mapFilter === MapFilter.COMPANIES_OVER_CADENCE ||
        mapFilter === MapFilter.COMPANIES_30_DAYS_NO_CONTACT ||
        mapFilter === MapFilter.PEOPLE_OVER_CADENCE ||
        mapFilter === MapFilter.PEOPLE_30_DAYS_NO_CONTACT
      ) {
        const mapViewState: MapViewState | undefined = yield select(getMapViewSettings);
        const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
        const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);

        const entityType =
          mapFilter === MapFilter.COMPANIES_OVER_CADENCE ||
          mapFilter === MapFilter.COMPANIES_30_DAYS_NO_CONTACT
            ? EntityType.COMPANY
            : EntityType.PERSON;

        const pinsResponse: ListResponse<EntityPin> = yield callApi("fetchMapPins", orgId, {
          $filters: {
            bounds,
            entities: { [entityType]: $filters },
            pinLegends: MAP_ENTITY_TYPES.reduce(
              (result, entityType) => ({
                ...result,
                [entityType]: getColorShapeForEntity(
                  mapViewState,
                  colorPinLegends,
                  shapePinLegends,
                  entityType
                ),
              }),
              {}
            ),
            precision: getPrecision(zoom ?? 1, bigOrganization),
            precisionThreshold: 1000,
          },
        });

        yield put(
          fetchActivityCardData.success({
            pins: pinsResponse.data,
          })
        );
      } else {
        invariant(false, `Invalid map filter provided on the activities report page: ${mapFilter}`);
      }
    } catch (error) {
      yield put(fetchActivityCardData.failure(error));
      yield put(handleError({ error }));
    }
  }
}

export function* onFetchActivityCardListData({
  payload: pageNumber,
}: ReturnType<typeof fetchActivityCardListData.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const {
      dateRange,
      mapFilter,
      userIds,
    }: Pick<ActivityCardData, "dateRange" | "mapFilter" | "userIds"> = yield select(
      getActivityCardCriteria
    );
    const $filters = getActivityCardRequestFilters(mapFilter, dateRange, userIds);

    if (
      mapFilter === MapFilter.COMPLETED_ACTIVITIES ||
      mapFilter === MapFilter.AUTO_ACTIVITIES ||
      mapFilter === MapFilter.OVERDUE_ACTIVITIES ||
      mapFilter === MapFilter.UPCOMING_ACTIVITIES
    ) {
      const activityResponse: ListResponse<Activity> = yield callApi("fetchActivities", orgId, {
        $filters,
        $limit: DEFAULT_ENTITY_LIST_PAGE_SIZE,
        $offset: pageNumber * DEFAULT_ENTITY_LIST_PAGE_SIZE,
      });

      yield put(
        fetchActivityCardListData.success({
          activities: activityResponse.data,
          totalActivities: activityResponse.total,
        })
      );
    } else if (
      mapFilter === MapFilter.COMPANIES_OVER_CADENCE ||
      mapFilter === MapFilter.COMPANIES_30_DAYS_NO_CONTACT
    ) {
      const companyResponse: ListResponse<Company> = yield callApi("fetchCompanies", orgId, {
        $filters,
        $limit: DEFAULT_ENTITY_LIST_PAGE_SIZE,
        $offset: pageNumber * DEFAULT_ENTITY_LIST_PAGE_SIZE,
      });
      yield put(
        fetchActivityCardListData.success({
          companies: companyResponse.data,
          totalCompanies: companyResponse.total,
        })
      );
    } else if (
      mapFilter === MapFilter.PEOPLE_OVER_CADENCE ||
      mapFilter === MapFilter.PEOPLE_30_DAYS_NO_CONTACT
    ) {
      const peopleResponse: ListResponse<Person> = yield callApi("fetchPeople", orgId, {
        $filters,
        $limit: DEFAULT_ENTITY_LIST_PAGE_SIZE,
        $offset: pageNumber * DEFAULT_ENTITY_LIST_PAGE_SIZE,
      });
      yield put(
        fetchActivityCardListData.success({
          people: peopleResponse.data,
          totalPeople: peopleResponse.total,
        })
      );
    } else {
      invariant(false, `Invalid map filter provided on the activities report page: ${mapFilter}`);
    }
  } catch (error) {
    yield put(fetchActivityCardListData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchDealsMapData({
  payload: { filter, funnelId, stageId, userIds },
}: ReturnType<typeof fetchDealsMapData.request>) {
  try {
    const userFilter = getUserFilter(userIds);
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const andCondition: PlatformFilterCondition[] = [{ funnelId }];
    const filters: PlatformFilterModel = {
      ...userFilter,
      $and: andCondition,
      cadence: true,
      includeAccessStatus: true,
      includeGroups: true,
      mapped: true,
    };
    let limit = 10000;
    let order = "-updatedAt";

    if (stageId && filter !== MapFilter.OPEN_DEALS) {
      andCondition.push({ dealStageId: stageId });
    }

    if (filter === MapFilter.OPEN_DEALS) {
      andCondition.push({
        "stage.type": {
          [PlatformFilterOperator.NOT_CONTAINS]: [DealStageType.LOST, DealStageType.WON],
        },
      });
      limit = 1000;
    } else if (filter === MapFilter.RECENTLY_CLOSED_DEALS) {
      andCondition.push({
        "stage.type": {
          [PlatformFilterOperator.CONTAINS]: [DealStageType.LOST, DealStageType.WON],
        },
      });
    } else if (filter === MapFilter.DEALS_OVER_CADENCE) {
      andCondition.push({ outOfCadence: true });
    } else if (filter === MapFilter.DEALS_ROTTING) {
      andCondition.push({ rottingDaysOut: { $gt: 0 } });
    } else if (filter === MapFilter.DEALS_30_DAYS_NO_CONTACT) {
      andCondition.push({ noContactDaysOut: { $gt: 30 } });
    } else if (filter === MapFilter.TOP_10_DEALS) {
      limit = 10;
      order = "-amount";
    }

    const dealResponse: ListResponse<Deal> = yield callReadonlyApi("fetchDeals", orgId, {
      $columns: [
        "id",
        "name",
        "amount",
        "userId",
        "funnelId",
        "accountId",
        "contactId",
        "dealStageId",
      ],
      $filters: filters,
      $limit: limit,
      $order: order,
    });

    yield put(fetchDealsMapData.success({ deals: dealResponse.data, total: dealResponse.total }));
  } catch (error) {
    yield put(fetchDealsMapData.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchFunnelsStats({
  payload: { funnel, userIds },
}: ReturnType<typeof fetchFunnelsStats.request>) {
  try {
    const organization: Organization = yield select(getOrganization);

    const summary: ListResponse<StageSummary> = yield callReadonlyApi(
      "fetchAllFunnelSummary",
      organization.id,
      funnel.id,
      {
        $filters: {
          userId: { $in: userIds },
        },
      }
    );
    const statistics: ListResponse<Statistics> = yield callReadonlyApi(
      "fetchStatistics",
      organization.id,
      {
        funnel: funnel.name,
        summary: "all",
        userId: { $in: userIds },
      }
    );
    yield put(
      fetchFunnelsStats.success({
        orgStats: statistics.data[0]?.values ?? {},
        stageSummary: summary.data,
      })
    );
  } catch (error) {
    yield put(fetchFunnelsStats.failure());

    yield put(handleError({ error }));
  }
}

export function* onFetchExportCardData() {
  try {
    const organization: Organization = yield select(getOrganization);
    const { filter, range }: ViewState = yield select(getReportListViewState);

    const exportCardDataFilter = pickBy(filter, (v) => v !== undefined);
    const reportsResponse: ListResponse<Report> = yield callReadonlyApi(
      "fetchReports",
      organization.id,
      {
        $filters: convertToPlatformFilterModel(exportCardDataFilter, [], reportFieldModel),
        $limit: range.endRow - range.startRow,
        $offset: range.startRow,
        $order: "-updatedAt",
      }
    );
    yield put(
      fetchExportCardData.success({
        reports: reportsResponse.data,
        total: reportsResponse.total,
      })
    );
  } catch (error) {
    yield put(fetchExportCardData.failure(error));
    yield put(handleError({ error }));
  }
}

const reportGeneratedSuccessfullyMessage = defineMessage({
  id: "reports.exports.download.success",
  defaultMessage: "This export will be sent to your email shortly.",
  description: "Success message for the download report action",
});

export function* onDownloadReport({ payload: report }: ReturnType<typeof downloadReport.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const iam: Iam = yield select(getMe);
    yield callApi("generateReport", organization.id, report.id, iam.username);
    yield put(downloadReport.success());
    notification.success({
      message: i18nService.getIntl()?.formatMessage(reportGeneratedSuccessfullyMessage),
    });
    yield put(fetchExportCardData.request({}));
  } catch (error) {
    yield put(downloadReport.failure(error));
    yield put(handleError({ error }));
  }
}

const reportClonedSuccessfullyMessage = defineMessage({
  id: "reports.exports.clone.success",
  defaultMessage: "Export copied successfully",
  description: "Success message for the clone report action",
});

const reportClonedSuccessfullyDescription = defineMessage({
  id: "reports.exports.clone.success.description",
  defaultMessage: "All data has been copied to the new export.",
  description: "Success message for the clone report action",
});

export function* onCloneReport({
  payload: { report, callback },
}: ReturnType<typeof cloneReport.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const clonedReport: Report = yield callApi("cloneReport", orgId, report.id);
    yield put(cloneReport.success());
    notification.success({
      description: getReportCloneSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.getIntl()?.formatMessage(reportClonedSuccessfullyDescription) ?? "",
        () => {
          callback(clonedReport);
        }
      ),
      message: i18nService.getIntl()?.formatMessage(reportClonedSuccessfullyMessage),
    });
    yield put(fetchExportCardData.request({}));
  } catch (error) {
    yield put(cloneReport.failure(error));
    yield put(handleError({ error }));
  }
}

const reportDeletedSuccessfullyMessage = defineMessage({
  id: "reports.exports.delete.success",
  defaultMessage: `"{name}" export has been deleted.`,
  description: "Success message for the delete report action",
});

export function* onDeleteReport({ payload: report }: ReturnType<typeof deleteReport.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    yield callApi("deleteReport", organization.id, report.id);
    yield put(deleteReport.success(report.id));
    notification.success({
      message: i18nService
        .getIntl()
        ?.formatMessage(reportDeletedSuccessfullyMessage, { name: report.name }),
    });
    yield put(fetchExportCardData.request({}));
  } catch (error) {
    yield put(deleteReport.failure(error));
    yield put(handleError({ error }));
  }
}

const reportScheduledSuccessfullyMessage = defineMessage({
  id: "reports.exports.schedule.success",
  defaultMessage: `"{name}" export schedule updated.`,
  description: "Success message for the schedule report action",
});

export function* onScheduleReport({ payload: report }: ReturnType<typeof scheduleReport.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    yield callApi("updateReport", organization.id, report);
    yield put(scheduleReport.success());
    notification.success({
      message: i18nService
        .getIntl()
        ?.formatMessage(reportScheduledSuccessfullyMessage, { name: report.name }),
    });
    yield put(fetchExportCardData.request({}));
  } catch (error) {
    yield put(scheduleReport.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchReport({ payload: reportId }: ReturnType<typeof fetchReport.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const report: Report = yield callReadonlyApi("fetchReport", organization.id, reportId);
    yield put(fetchReport.success(report));
    yield put(editReport(report)); // needed to initialize viewState
  } catch (error) {
    yield put(fetchReport.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onCreateReport() {
  yield put(push(`${Path.REPORTS}/exports/create`));
}

export function* onEditReport({ payload: report }: ReturnType<typeof editReport>) {
  yield put(applyPreviewViewSettings(getDefaultReportPreviewViewState(report)));
  yield put(push(`${Path.REPORTS}/exports/${report.id}`));
}

export function* onFetchReportRecordsCountInfo({
  payload: { callback, report },
}: ReturnType<typeof fetchReportRecordsCountInfo.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const requestPayload = {
      $filters: report.selectedFilters,
      $limit: 0,
      $offset: 0,
    };

    const response: ListResponse<AnyEntity> = yield callReadonlyApi(
      entityTypeToApiMethodName[report.tableName],
      organization.id,
      requestPayload
    );
    callback?.(response.total);
    yield put(fetchReportRecordsCountInfo.success());
  } catch (error) {
    yield put(fetchReportRecordsCountInfo.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchPreviewData({ payload }: ReturnType<typeof fetchPreviewData.request>) {
  try {
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyPreviewViewSettings(payload.request));
    }

    const report: Report | undefined = yield select(getCurrentReport);
    const listViewState: ViewState = yield select(getPreviewViewState);

    if (payload.updateOnly || !report) {
      return;
    }

    const organization: Organization = yield select(getOrganization);
    const requestPayload = {
      $filters: convertToPlatformFilterModel(
        payload.fetchOnlyWithoutFilters ? {} : listViewState.filter,
        listViewState.columns,
        getFieldModelByEntityType(report.tableName),
        true,
        listViewState.viewAs
      ),
      $limit: PREVIEW_LIMIT,
      $offset: 0,
      $order: convertToPlatformSortModel(listViewState.sort),
    };

    const response: ListResponse<AnyEntity> = yield callReadonlyApi(
      entityTypeToApiMethodName[report.tableName],
      organization.id,
      requestPayload
    );
    payload.dataCallback &&
      payload.dataCallback({
        ...response,
        // cheat to make grid think we only have 100 records
        total: response.total > PREVIEW_LIMIT ? PREVIEW_LIMIT : response.total,
      });
    yield put(fetchPreviewData.success({ reportTotalRowsCount: response.total }));
  } catch (error) {
    payload.failCallback && payload.failCallback();
    yield put(fetchPreviewData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchDealsByStage({
  payload: { limit, stageId, userIds },
}: ReturnType<typeof fetchDealsByStage.request>) {
  try {
    const userFilter = getUserFilter(userIds);
    const organization: Organization = yield select(getOrganization);
    const deals: ListResponse<Deal> = yield callReadonlyApi("fetchDeals", organization.id, {
      $filters: { ...userFilter, $and: [{ dealStageId: stageId }], includeAccessStatus: true },
      $limit: limit,
    });

    yield put(fetchDealsByStage.success({ deals: deals.data, stageId, total: deals.total }));
  } catch (error) {
    yield put(fetchDealsByStage.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchDealsGeoChartData({
  payload: { filter, funnelId, userIds },
}: ReturnType<typeof fetchDealsGeoChartData.request>) {
  try {
    const userFilter = getUserFilter(userIds);
    const organization: Organization = yield select(getOrganization);
    const andCondition: PlatformFilterCondition[] = [
      { funnelId },
      {
        "hc-key": {
          [PlatformFilterOperator.NOT_EQUAL]: null,
        },
      },
    ];
    const filters = { ...userFilter, $and: andCondition, includeAccessStatus: true };
    const $aggs = { primaryGroup: { field: "hc-key" }, sum: ["amount"] };

    if ([MapFilter.CLOSED_WON_REVENUE, MapFilter.NUMBER_OF_CLOSED_WON_DEALS].includes(filter)) {
      andCondition.push({
        "stage.type": {
          [PlatformFilterOperator.EQUALS]: DealStageType.WON,
        },
      });
    } else if (
      [MapFilter.CLOSED_LOST_REVENUE, MapFilter.NUMBER_OF_CLOSED_LOST_DEALS].includes(filter)
    ) {
      andCondition.push({
        "stage.type": {
          [PlatformFilterOperator.EQUALS]: DealStageType.LOST,
        },
      });
    }
    const dealResponse: AggregatedListResponse<Deal, ListResponseAggregation<Deal>[]> =
      yield callReadonlyApi("fetchDeals", organization.id, {
        $aggs,
        $filters: filters,
        $limit: 0,
      });
    let dealsCountByHcKey: Record<string, number> | undefined;
    if ([MapFilter.CLOSED_WON_REVENUE, MapFilter.CLOSED_LOST_REVENUE].includes(filter)) {
      dealsCountByHcKey = groupRecordsByKey(dealResponse.aggregations, "key", "sum_amount.value");
    } else if (
      [MapFilter.NUMBER_OF_CLOSED_WON_DEALS, MapFilter.NUMBER_OF_CLOSED_LOST_DEALS].includes(filter)
    ) {
      dealsCountByHcKey = groupRecordsByKey(dealResponse.aggregations, "key", "doc_count");
    }
    yield put(fetchDealsGeoChartData.success(dealsCountByHcKey ?? {}));
  } catch (error) {
    yield put(fetchDealsGeoChartData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onSaveReport() {
  try {
    const organization: Organization = yield select(getOrganization);
    const exportCardData: ExportCardData = yield select(getExportCardData);

    if (!exportCardData.currentReport || !exportCardData.currentReport.name!.trim().length) {
      return;
    }

    const visibleColumns = exportCardData.viewState.columns.filter(({ visible }) => visible);
    const sortedField = exportCardData.viewState.sort[0];
    let selectedOrder = {};
    if (sortedField) {
      selectedOrder = {
        field: sortedField.field.exportColumnName,
        order: sortedField.order,
      };
    }

    const customFieldIds = visibleColumns
      .filter(({ field }) => isCustomField(field))
      .map(({ field }) => (field as CustomField).customFieldData.id);

    const columnNames = visibleColumns
      .filter(({ field }) => !isCustomField(field))
      .map(({ field }) => field.exportColumnName);

    const columnsOrder = visibleColumns.map(({ field }) => field.exportColumnName);

    const payload: Partial<Report> = {
      ...exportCardData.currentReport,
      columnsOrder,
      customFields: customFieldIds,
      selectedColumns: columnNames,
      selectedOrder,
      selectedFilters: convertToPlatformFilterModel(
        exportCardData.viewState.filter,
        exportCardData.viewState.columns,
        getFieldModelByEntityType(exportCardData.currentReport.tableName!),
        false
      ),
    };

    // check if updating existing report or creating a new one (new has id === 0)
    if (exportCardData.currentReport.id) {
      yield callApi("updateReport", organization.id, payload);
    } else {
      // creating new report
      const { id, ...createPayload } = payload;
      yield callApi("createReport", organization.id, createPayload);
    }

    yield put(saveReport.success());
    yield put(push(`${Path.REPORTS}/exports`));
  } catch (error) {
    yield put(saveReport.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onDownloadReportEntities({
  payload: { columns, entityType, ids },
}: ReturnType<typeof downloadReportEntities>) {
  yield put(
    exportEntities.request({
      entityType,
      platformRequest: {
        $filters: {
          $and: [
            {
              id: {
                $in: ids,
              },
            },
          ],
        },
      },
      viewState: { columns },
    })
  );
}

export function* onDownloadActivityReportData({
  payload: { columns },
}: ReturnType<typeof downloadActivityReportData>) {
  try {
    const {
      dateRange,
      mapFilter,
      userIds,
    }: Pick<ActivityCardData, "dateRange" | "mapFilter" | "userIds"> = yield select(
      getActivityCardCriteria
    );

    const $filters = getActivityCardRequestFilters(mapFilter, dateRange, userIds);

    if (
      mapFilter === MapFilter.COMPLETED_ACTIVITIES ||
      mapFilter === MapFilter.AUTO_ACTIVITIES ||
      mapFilter === MapFilter.OVERDUE_ACTIVITIES ||
      mapFilter === MapFilter.UPCOMING_ACTIVITIES
    ) {
      yield put(
        exportEntities.request({
          entityType: EntityType.ACTIVITY,
          platformRequest: { $filters, $limit: 10000, $order: "-createdAt" },
          viewState: { columns },
        })
      );
    } else if (
      mapFilter === MapFilter.COMPANIES_OVER_CADENCE ||
      mapFilter === MapFilter.COMPANIES_30_DAYS_NO_CONTACT
    ) {
      yield put(
        exportEntities.request({
          entityType: EntityType.COMPANY,
          platformRequest: { $filters, $limit: 10000, $order: "-createdAt" },
          viewState: { columns },
        })
      );
    } else if (
      mapFilter === MapFilter.PEOPLE_OVER_CADENCE ||
      mapFilter === MapFilter.PEOPLE_30_DAYS_NO_CONTACT
    ) {
      yield put(
        exportEntities.request({
          entityType: EntityType.PERSON,
          platformRequest: { $filters, $limit: 10000, $order: "-createdAt" },
          viewState: { columns },
        })
      );
    } else {
      invariant(false, `Invalid map filter provided on the activities report page: ${mapFilter}`);
    }
  } catch (e) {
    // do nothing
  }
}

export function* syncRangeQueryParamWithCurrentDateRangeFilter() {
  const { pathname, query }: RouterLocation<any> = yield select(getLocation);
  const dateRangeType: DateRangeType = yield select(getDateRangeType);
  const dateRange: DateRange | undefined = yield select(getDateRange);
  const range = dateRangeFilterToRange(dateRange, dateRangeType);
  yield put(replace(`${pathname}?range=${range}${query?.users ? `&users=${query.users}` : ""}`));
}

export function* syncUserIdQueryParamWithCurrentUserId() {
  const { pathname, query }: RouterLocation<any> = yield select(getLocation);
  if (!pathname.startsWith(Path.REPORTS)) {
    return;
  }
  const userIds: number[] = yield select(getUserIds);
  const updatedQuery = new URLSearchParams(query);
  updatedQuery.set("users", userIds.join(","));
  yield put(replace(`${pathname}?${updatedQuery}`));
}

export function* onToggleWeeklyReport({
  payload: { enabled },
}: ReturnType<typeof toggleWeeklyReport.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const settings: Setting[] = yield select(getUserSettings);
    let weeklyReportSetting: undefined | WeeklyReportSetting = settings.find(
      ({ key }) => key === "weeklyReport"
    );
    if (!weeklyReportSetting) {
      // we do not create new setting if it's not yet created. Platform should guarantee that all
      // settings exist already.
      return;
    }

    const user: User = yield select(getCurrentUser);
    yield put(
      updateSetting.request({
        orgId: organization.id,
        setting: { ...weeklyReportSetting, value: { ...weeklyReportSetting.value, enabled } },
        userId: user.id,
      })
    );
    const updateResult: ReturnType<typeof updateSetting.failure | typeof updateSetting.success> =
      yield take([updateSetting.success, updateSetting.failure]);
    if (isActionOf(updateSetting.failure, updateResult)) {
      yield put(toggleWeeklyReport.failure());
      notification.error({
        message: i18nService.formatMessage(
          messages.weeklyReportOptFailed,
          "Error updating preferences... Please try again later."
        ),
      });
    } else {
      yield put(toggleWeeklyReport.success());
      notification.success({
        message: enabled
          ? i18nService.formatMessage(messages.weeklyReportOptedIn, "Opted in to weekly reports")
          : i18nService.formatMessage(messages.weeklyReportOptedOut, "Opted out of weekly reports"),
      });
    }
  } catch (error) {
    yield put(toggleWeeklyReport.failure());
    yield put(handleError({ error }));
  }
}

export function* onSaveWeeklyReport({ payload }: ReturnType<typeof saveWeeklyReport.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const settings: Setting[] = yield select(getUserSettings);
    let weeklyReportSetting: undefined | WeeklyReportSetting = settings.find(
      ({ key }) => key === "weeklyReport"
    );
    if (!weeklyReportSetting) {
      // we do not create new setting if it's not yet created. Platform should guarantee that all
      // settings exist already.
      return;
    }

    // apply action payload
    weeklyReportSetting.value = { ...weeklyReportSetting.value, ...payload };

    // enforce leaderboardComparison to be "user" for team members
    const user: User = yield select(getCurrentUser);
    if (user.role.key === Role.MEMBER) {
      weeklyReportSetting = {
        ...weeklyReportSetting,
        value: {
          ...weeklyReportSetting.value,
          teamLeaderboard: {
            ...weeklyReportSetting.value.teamLeaderboard,
            leaderboardComparison: "user",
          },
        },
      };
    }

    yield put(
      updateSetting.request({
        orgId: organization.id,
        setting: weeklyReportSetting,
        userId: user.id,
      })
    );

    const updateResult: ReturnType<typeof updateSetting.failure | typeof updateSetting.success> =
      yield take([updateSetting.success, updateSetting.failure]);

    if (isActionOf(updateSetting.failure, updateResult)) {
      yield put(saveWeeklyReport.failure());
      notification.error({
        message: i18nService.formatMessage(
          messages.weeklyReportSaveFailed,
          "Error updating preferences... Please try again later."
        ),
      });
    } else {
      yield put(saveWeeklyReport.success());
      notification.success({
        message: i18nService.formatMessage(
          messages.weeklyReportSaved,
          "Opted out of weekly reports"
        ),
      });
    }
  } catch (error) {
    yield put(saveWeeklyReport.failure());
    yield put(handleError({ error }));
  }
}

export function* onInitializeLeaderboard() {
  // read data from local storage and make initial calls to fetch table and metrics data
  const localSettingsViewState: LeaderboardViewState | undefined = yield call(
    localSettings.getLeaderboardViewState
  );
  const viewState: LeaderboardViewState = yield select(getLeaderboardViewState);
  const owner: boolean = yield select(isCurrentUserOwner);
  const manager: boolean = yield select(isCurrentUserManager);
  viewState.activityTypeIds = localSettingsViewState?.activityTypeIds ?? viewState.activityTypeIds;
  viewState.dateRange = localSettingsViewState?.dateRange ?? viewState.dateRange;
  viewState.dateRangeType = localSettingsViewState?.dateRangeType ?? viewState.dateRangeType;
  viewState.rankingMetric = localSettingsViewState?.rankingMetric ?? viewState.rankingMetric;
  viewState.teamIds = localSettingsViewState?.teamIds ?? undefined;

  // changing the default state for non-owners to fetch by user's team, not by entire org
  if (!owner) {
    viewState.grouping = manager ? "team" : "user";
    // TODO: ask what should the Leaderboard display by default for reps which are not a part of any group!!!!
    // `undefined` is not good here, but there's no other choice
  }
  if (viewState.teamIds && viewState.teamIds.length > 0) {
    viewState.grouping = "user";
  }
  const savedMetricsOrder: LeaderboardMetricFieldName[] | undefined = yield call(
    localSettings.getLeaderBoardMetricsCardOrder
  );
  const selectedMetrics = savedMetricsOrder ?? [
    LeaderboardMetricFieldName.COMPANIES_CREATED,
    LeaderboardMetricFieldName.ACTIVITIES_COMPLETED,
    LeaderboardMetricFieldName.CHECK_INS_COMPLETED,
  ];

  yield put(
    fetchLeaderboardMetricsData.request({
      selectedMetrics: selectedMetrics,
      teamIds: viewState.teamIds,
    })
  );
  yield put(fetchLeaderboardData.request(viewState));
}

export function* onFetchLeaderboardData({
  payload,
}: ReturnType<typeof fetchLeaderboardData.request>) {
  try {
    // take state from the store, not from action's payload, because state
    // has a more "processed" data where all fields are set whereas in payload all
    // are optional
    const viewState: LeaderboardViewState = yield select(getLeaderboardViewState);
    localSettings.setLeaderboardViewState(viewState);

    if ("dateRange" in payload || "teamIds" in payload) {
      // if team or date range was changed, fetch new data for metrics
      yield put(fetchLeaderboardMetricsData.request({ teamIds: viewState.teamIds }));
    }

    if (Object.keys(payload).length === 1 && payload.columns) {
      // it was a request to only update columns, no need to re-fetch any data
      yield put(fetchLeaderboardData.success(undefined));
      return;
    }

    // we need to send userIds, not teamIds (for whatever reason...), so let's get userIds from teamId
    const users: User[] = yield select(getUsers);
    const userIds: undefined | User["id"][] = viewState.teamIds
      ? users
          .filter((user) => user.teams.some(({ id }) => viewState.teamIds!.includes(id)))
          .map(({ id }) => id)
      : undefined;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<LeaderboardItem, { lastRunAt?: string }> = yield callReadonlyApi(
      "fetchLeaderboardData",
      orgId,
      {
        crmActivityTypeId:
          viewState.rankingMetric === LeaderboardMetricFieldName.ACTIVITIES_COMPLETED &&
          viewState.activityTypeIds?.length
            ? { $in: viewState.activityTypeIds }
            : undefined,
        date: {
          $gte: viewState.dateRange.startDate.toISOString(),
          $lte: viewState.dateRange.endDate.toISOString(),
        },
        funnelId:
          (viewState.rankingMetric === LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE ||
            LeaderboardMetricFieldName.DEALS_CREATED ||
            LeaderboardMetricFieldName.DEALS_ROTTING ||
            LeaderboardMetricFieldName.DEALS_CLOSED_WON ||
            LeaderboardMetricFieldName.DEALS_CLOSED_LOST) &&
          viewState.funnelIds?.length
            ? { $in: viewState.funnelIds }
            : undefined,
        stageId:
          (viewState.rankingMetric === LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE ||
            LeaderboardMetricFieldName.DEALS_CREATED ||
            LeaderboardMetricFieldName.DEALS_ROTTING) &&
          viewState.funnelIds &&
          viewState.stageIds?.length
            ? { $in: viewState.stageIds }
            : undefined,
        summary: viewState.grouping,
        userId: userIds ? { $in: userIds } : undefined, // no teamId/userId filtering when requested data for the entire org,
      },
      viewState.rankingMetric
    );

    yield put(fetchLeaderboardData.success({ items: response.data, metaData: response.metaData }));
  } catch (error) {
    yield put(fetchLeaderboardData.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchLeaderboardMetricsData() {
  try {
    const viewState: LeaderboardViewState = yield select(getLeaderboardViewState);
    const metricsViewState: {
      teamIds?: Team["id"][];
      userId?: User["id"];
    } = yield select(getLeaderboardMetricsViewState);

    const metricsData: Partial<Record<LeaderboardMetricFieldName, LeaderboardMetricsData>> =
      yield select(getLeaderboardMetricsData);
    const metricsToRequest: LeaderboardMetricFieldName[] = Object.keys(
      metricsData
    ) as LeaderboardMetricFieldName[];

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const teams: Team[] = yield select(getTeams);

    const response: ListResponse<LeaderboardItem, { lastRunAt?: string }> = yield callReadonlyApi(
      "fetchLeaderboardData",
      orgId,
      {
        crmActivityTypeId:
          viewState.rankingMetric === LeaderboardMetricFieldName.ACTIVITIES_COMPLETED &&
          viewState.activityTypeIds?.length
            ? { $in: viewState.activityTypeIds }
            : undefined,
        date: {
          $gte: viewState.dateRange.startDate.toISOString(),
          $lte: viewState.dateRange.endDate.toISOString(),
        },
        funnelId:
          (viewState.rankingMetric === LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE ||
            LeaderboardMetricFieldName.DEALS_CREATED ||
            LeaderboardMetricFieldName.DEALS_ROTTING ||
            LeaderboardMetricFieldName.DEALS_CLOSED_WON ||
            LeaderboardMetricFieldName.DEALS_CLOSED_LOST) &&
          viewState.funnelIds?.length
            ? { $in: viewState.funnelIds }
            : undefined,
        includePerformanceMetric: true,
        property: { $in: metricsToRequest },
        stageId:
          (viewState.rankingMetric === LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE ||
            LeaderboardMetricFieldName.DEALS_CREATED ||
            LeaderboardMetricFieldName.DEALS_ROTTING) &&
          viewState.funnelIds &&
          viewState.stageIds?.length
            ? { $in: viewState.stageIds }
            : undefined,
        summary: metricsViewState.userId ? "user" : "all",
        teamId: metricsViewState.teamIds
          ? { $in: metricsViewState.teamIds }
          : viewState.grouping === "team"
          ? { $in: teams.map(({ id }) => id) }
          : undefined,
        userId: metricsViewState.userId ? { $in: [metricsViewState.userId] } : undefined,
      },
      undefined
    );

    const result: Partial<Record<LeaderboardMetricFieldName, LeaderboardMetricsData>> = {};
    metricsToRequest.forEach((metric) => {
      if (response.data.length) {
        result[metric] = response.data.reduce(
          (result, current) => {
            Object.keys(current.values).forEach((key) => {
              if (key.startsWith(metric)) {
                result.value +=
                  current.values[key as keyof Record<LeaderboardMetricFieldName, number>];
              }
            });
            if (current.percentChangeInValues) {
              Object.keys(current.percentChangeInValues).forEach((key) => {
                if (key.startsWith(metric)) {
                  result.percentChangeInValue +=
                    current.percentChangeInValues?.[
                      key as keyof Record<LeaderboardMetricFieldName, number>
                    ] ?? 0;
                }
              });
            }
            return result;
          },
          { percentChangeInValue: 0, value: 0 }
        );
      } else {
        result[metric] = { percentChangeInValue: 0, value: 0 };
      }
    });

    yield put(fetchLeaderboardMetricsData.success(result));
  } catch (error) {
    yield put(fetchLeaderboardMetricsData.failure());
    yield put(handleError({ error }));
  }
}

export function* onDownloadLeaderboardTableData() {
  const data: LeaderboardItem[] = yield select(getLeaderboardTableData);
  const viewState: LeaderboardViewState = yield select(getLeaderboardViewState);
  downloadStackRankDataAsCsv(
    data,
    viewState.columns.filter(({ visible }) => visible).map(({ field }) => field)
  );
}

export function* reportSaga() {
  yield takeEvery(fetchExtraInfo.request, onFetchExtraInfo);
  yield takeEvery(fetchOverviewCardData.request, onFetchOverviewCardData);
  yield takeEvery(fetchOverviewMapDeals.request, onFetchOverviewMapDeals);
  yield takeEvery(fetchModalPreviewData.request, onFetchModalPreviewData);
  yield takeEvery(downloadModalData.request, onDownloadModalData);
  yield takeEvery(fetchActivityCardData.request, onFetchActivityCardData);
  yield takeEvery(fetchActivityCardListData.request, onFetchActivityCardListData);
  yield takeEvery(fetchDealsMapData.request, onFetchDealsMapData);
  yield takeEvery(fetchExportCardData.request, onFetchExportCardData);
  yield takeEvery(downloadReport.request, onDownloadReport);
  yield takeEvery(cloneReport.request, onCloneReport);
  yield takeEvery(deleteReport.request, onDeleteReport);
  yield takeEvery(scheduleReport.request, onScheduleReport);
  yield takeEvery(fetchReport.request, onFetchReport);
  yield takeEvery(createReport, onCreateReport);
  yield takeEvery(editReport, onEditReport);
  yield takeEvery(fetchPreviewData.request, onFetchPreviewData);
  yield takeEvery(fetchFunnelsStats.request, onFetchFunnelsStats);
  yield takeEvery(fetchDealsByStage.request, onFetchDealsByStage);
  yield takeEvery(fetchDealsGeoChartData.request, onFetchDealsGeoChartData);
  yield takeEvery(saveReport.request, onSaveReport);
  yield takeEvery([setDateRange, setDateRangeType], syncRangeQueryParamWithCurrentDateRangeFilter);
  yield takeEvery([setUserIds], syncUserIdQueryParamWithCurrentUserId);
  yield takeEvery(downloadActivityReportData, onDownloadActivityReportData);
  yield takeEvery(toggleWeeklyReport.request, onToggleWeeklyReport);
  yield takeEvery(saveWeeklyReport.request, onSaveWeeklyReport);
  // leaderboard:
  yield takeLatest(initializeLeaderboard, onInitializeLeaderboard);
  yield takeLatest(fetchLeaderboardData.request, onFetchLeaderboardData);
  yield takeLatest(fetchLeaderboardMetricsData.request, onFetchLeaderboardMetricsData);
  yield takeLatest(downloadLeaderboardTableData, onDownloadLeaderboardTableData);
  yield takeEvery(fetchReportRecordsCountInfo.request, onFetchReportRecordsCountInfo);
  yield takeEvery(exportModalEntities, onExportModalEntities);
  yield takeEvery(downloadReportEntities, onDownloadReportEntities);
}

import numeral from "numeral";
import { IntlShape } from "react-intl";
import { FormatNumberOptions } from "@formatjs/intl/src/types";

const createNumberFieldFormatter =
  (intl: IntlShape, opts?: FormatNumberOptions) => (value: string | number | undefined) => {
    // let user enter a decimal separator, don't wipe it with numeral's replace
    const decimalSeparator = numeral.localeData(numeral.locale()).delimiters.decimal;
    const separator =
      typeof value === "string" && value.endsWith(decimalSeparator) ? decimalSeparator : "";

    // due to a bug in InputNumber, it provides a value with a "." decimal separator on blur.
    // numeral may fail to parse it when numeral's decimal separator is set to ","
    // that's why we have to use standard js parseFloat to parse such string value
    const parsedValue = typeof value === "string" ? parseFloat(value) : value;
    const formattedValue =
      parsedValue === undefined || isNaN(parsedValue) ? "" : intl.formatNumber(parsedValue, opts);

    return formattedValue + separator;
  };

export default createNumberFieldFormatter;

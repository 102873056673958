import { useEffect, useState } from "react";
import getRgbaPalette from "get-rgba-palette";
import color from "color";
import { HexColor } from "@mapmycustomers/shared/types/AnyColor";
import loggingService from "util/logging";

const paletteCache: Record<string, HexColor[]> = {};

const useImagePalette = (
  imageUrl?: string,
  count: number = 2
): [palette: HexColor[], loading: boolean] => {
  const [loading, setLoading] = useState(true);
  const [palette, setPalette] = useState<HexColor[]>([]);

  useEffect(() => {
    if (!imageUrl) {
      setLoading(false);
      return;
    }

    if (paletteCache[imageUrl]) {
      setLoading(false);
      setPalette(paletteCache[imageUrl]);
      return;
    }

    setLoading(true);
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.addEventListener(
      "load",
      () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d")!;
        canvas.width = image.width;
        canvas.height = image.height;
        context.clearRect(0, 0, image.width, image.height);
        context.drawImage(image, 0, 0);

        try {
          const pixels = context.getImageData(0, 0, image.width, image.height);
          // Using count*8 and then slicing the palette to just count items seems to produce a much
          // better result than if we used same count from the beginning.
          // Colors are much cleaner this way.
          const palette = getRgbaPalette(pixels.data, count * 8);
          // convert colors to hex format
          paletteCache[imageUrl] = palette.slice(0, count).map(
            (c) =>
              color
                .rgb(...c)
                .lighten(0.1)
                .saturate(0.1)
                .hex() as HexColor
          );
          setPalette(paletteCache[imageUrl]);
        } finally {
          setLoading(false);
        }
      },
      false
    );
    image.addEventListener("error", () => {
      setLoading(false);
      loggingService.warning("Failed to load image for palette", { imageUrl });
    });

    // we must use this `?<random>` part because otherwise the image from cache might be used and that
    // cached image is most probably fetched with an incorrect crossOrigin
    image.src = `${imageUrl}?${Date.now()}`;
  }, [imageUrl, count]);

  return [palette, loading];
};

export default useImagePalette;

import React, { forwardRef } from "react";
import cn from "classnames";
import Button from "antd/es/button";
import Tooltip, { TooltipProps } from "antd/es/tooltip";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ToolButton.module.scss";

interface Props {
  active?: boolean; // to force active-style
  className?: string;
  icon: IconProp;
  iconClassName?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  tooltipProps?: Omit<TooltipProps, "title">;
  title: string;
}

const ToolButton = forwardRef<HTMLDivElement, Props>(
  ({ active, className, icon, iconClassName, onClick, tooltipProps, title }, ref) => {
    return (
      <div className={cn(styles.btn, className)} ref={ref}>
        <Tooltip {...tooltipProps} placement="left" title={title}>
          <Button
            block
            className={cn({ [styles.active]: active })}
            icon={<FontAwesomeIcon className={iconClassName} icon={icon} />}
            onClick={onClick}
            size="large"
          />
        </Tooltip>
      </div>
    );
  }
);

export default ToolButton;

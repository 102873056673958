import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { Activity, Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import { notifyAboutChanges } from "store/uiSync/actions";

const applyActivityAssociationsChanges = (
  activities: Activity[],
  payload: ReturnType<typeof notifyAboutChanges>["payload"]
): Activity[] => {
  let updatedActivities = activities;

  if (Array.isArray(payload.updated)) {
    const idToEntity = new Map(payload.updated.map((entity) => [entity.id, entity]));
    updatedActivities = updatedActivities.map((activity) => {
      switch (payload.entityType) {
        case EntityType.COMPANY:
          return !activity.account || !idToEntity.has(activity.account.id)
            ? activity
            : { ...activity, account: idToEntity.get(activity.account.id) as Company };
        case EntityType.PERSON:
          return !activity.contact || !idToEntity.has(activity.contact.id)
            ? activity
            : { ...activity, contact: idToEntity.get(activity.contact.id) as Person };
        case EntityType.DEAL:
          return !activity.deal || !idToEntity.has(activity.deal.id)
            ? activity
            : { ...activity, deal: idToEntity.get(activity.deal.id) as Deal };
        default:
          return activity;
      }
    });
  }

  if (Array.isArray(payload.deletedIds)) {
    const deletedIds = new Set(payload.deletedIds);
    updatedActivities = updatedActivities.map((activity) => {
      switch (payload.entityType) {
        case EntityType.COMPANY:
          return !activity.account || !deletedIds.has(activity.account.id)
            ? activity
            : { ...activity, account: undefined };
        case EntityType.PERSON:
          return !activity.contact || !deletedIds.has(activity.contact.id)
            ? activity
            : { ...activity, contact: undefined };
        case EntityType.DEAL:
          return !activity.deal || !deletedIds.has(activity.deal.id)
            ? activity
            : { ...activity, deal: undefined };
        default:
          return activity;
      }
    });
  }

  return updatedActivities;
};

export default applyActivityAssociationsChanges;

import { defineMessages } from "react-intl";

const messages = defineMessages({
  "ui.emailBody.toolbar.actions.insertLink": {
    id: "ui.emailBody.toolbar.actions.insertLink",
    defaultMessage: "Insert LInk",
    description: "Insert Link button text",
  },
  "ui.textField.error.limit": {
    id: "ui.textField.error.limit",
    defaultMessage: "Character limit reached",
    description: "Character limit reached text on text input",
  },
  "ui.avatar.userDisplayNameWithInfo": {
    id: "ui.avatar.userDisplayNameWithInfo",
    defaultMessage:
      "{username}{isDeleted, select, true { (Deleted)} other {}}{isDeactivated, select, true { (Deactivated)} other {}}",
    description: "Full username with meta-info about its delete or deactivated status",
  },
  "ui.input.richtext.mentionsDropdown.noResult": {
    id: "ui.input.richtext.mentionsDropdown.noResult",
    defaultMessage: "No results",
    description: "text Area With Mentions - No results",
  },
  "ui.editableText.cancel.tooltip": {
    id: "ui.editableText.cancel.tooltip",
    defaultMessage: "Cancel",
    description: "Tooltip of the cancel button in EditableText field",
  },
  "ui.editableText.tooltip": {
    id: "ui.editableText.tooltip",
    defaultMessage: "Edit",
    description: "Tooltip for the EditableText field",
  },
  "ui.editableText.save.tooltip.error.required": {
    id: "ui.editableText.save.tooltip.error.required",
    defaultMessage: "Non-empty value is required",
    description:
      "Tooltip of the save button in EditableText field when non-empty value is required",
  },
  "ui.editableText.save.tooltip": {
    id: "ui.editableText.save.tooltip",
    defaultMessage: "Save",
    description: "Tooltip of the save button in EditableText field",
  },
});

export default messages;

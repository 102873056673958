import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Field, { FieldProperties } from "./Field";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import { get, isEmpty } from "lodash-es";
import { entityToAddressLines } from "../../formatters";
import { Company, Person } from "@mapmycustomers/shared/types/entity";
import { SHORT_ADDRESS } from "../../consts";
import { isDefined } from "../../assert";

const nestedAddressParser = (nestedAddressedEntityFields: string[]) => (entity: unknown) => {
  let address = "";
  nestedAddressedEntityFields.forEach((nestedAddressedEntityField) => {
    if (!address.length) {
      const nestedEntity: Company | Person | Company[] = get(entity, nestedAddressedEntityField);
      if (nestedEntity) {
        if (Array.isArray(nestedEntity)) {
          address = nestedEntity
            .map((entity) => {
              const { line1, line2, line3 } = entityToAddressLines(entity, SHORT_ADDRESS);
              return [line1, line2, line3].filter(isDefined).join(", ");
            })
            .filter((address) => !isEmpty(address))
            .join("; ");
        } else {
          const { line1, line2, line3 } = entityToAddressLines(nestedEntity, SHORT_ADDRESS);
          address = [line1, line2, line3].filter(isDefined).join(", ") || "";
        }
      }
    }
  });
  return address;
};

class NestedAddressField extends Field {
  constructor(data: Omit<FieldProperties, "type">, nestedAddressedEntityFields: string[]) {
    super({
      type: FieldType.STRING,
      valueGetter: nestedAddressParser(nestedAddressedEntityFields),
      features: [
        FieldFeature.GEO_POINT_FIELD,
        FieldFeature.ADDRESS,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      ...data,
    });
  }
}

export default NestedAddressField;

import type { IntlConfig } from "@formatjs/intl/src/types";

const loadLocaleData = async (locale: string): Promise<IntlConfig["messages"]> => {
  switch (locale) {
    case "hi":
      // @ts-ignore
      return import("./compiled/hi.json");
    case "ru":
      // @ts-ignore
      return import("./compiled/ru.json");
    case "es":
      // @ts-ignore
      return import("./compiled/es.json");
    case "pt":
    case "pt-BR":
      // @ts-ignore
      return import("./compiled/pt.json");
    default:
      // @ts-ignore
      return import("./compiled/en.json");
  }
};

export default loadLocaleData;

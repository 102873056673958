import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { RawFile } from "@mapmycustomers/shared/types/File";
import { AnyEntityId, EntityType } from "@mapmycustomers/shared/types/entity";

export const downloadFile = createAction("@files/downloadFile")<{
  entityId?: AnyEntityId;
  entityType?: EntityType;
  fileId: RawFile["id"];
  fileName?: string;
}>();

export const createFile = createAsyncAction(
  "@files/createFile/request",
  "@files/createFile/success",
  "@files/createFile/failure"
)<{ file: File | Blob; callback?: (file: RawFile) => void }, RawFile, unknown>();

export const fetchFile = createAction("@files/fetchFile")<{
  entityId?: AnyEntityId;
  entityType?: EntityType;
  fileId: RawFile["id"];
  onSuccess: (file: RawFile, fileBlob: Blob) => void;
  onFailure?: () => void;
}>();

export type Actions = ActionType<typeof downloadFile | typeof createFile | typeof fetchFile>;

import React, { SyntheticEvent, useMemo } from "react";
import Menu from "antd/es/menu";
import isNotificationEnabled from "./isNotificationEnabled";
import { IntlShape } from "react-intl";
import Setting from "@mapmycustomers/shared/types/Setting";
import MmcNotificationAction from "enum/MmcNotificationAction";

const useNotificationMenu = (
  intl: IntlShape,
  setting: Setting,
  action: MmcNotificationAction,
  onOptOut: (item: { domEvent: SyntheticEvent<HTMLElement> }) => void,
  onGoToSettings: (item: { domEvent: SyntheticEvent<HTMLElement> }) => void
) =>
  useMemo(
    () => (
      <Menu>
        {setting.value.enabled && isNotificationEnabled(action, setting.value) && (
          <Menu.Item key="optOutNotifications" onClick={onOptOut}>
            {intl.formatMessage({
              defaultMessage: "Opt out of notifications like these",
              description:
                "Navbar Notifications - list - notification - Opt out of notifications like these",
              id: "navbar.notifications.list.notification.optOutNotifications",
            })}
          </Menu.Item>
        )}
        <Menu.Item key="manageAllNotifications" onClick={onGoToSettings}>
          {intl.formatMessage({
            defaultMessage: "Manage All Notifications",
            description: "Navbar Notifications - list - notification - Manage All Notifications",
            id: "navbar.notifications.list.notification.manageAllNotifications",
          })}
        </Menu.Item>
      </Menu>
    ),
    [intl, onGoToSettings, onOptOut, action, setting.value]
  );

export default useNotificationMenu;

import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import AntDTextArea, { TextAreaProps as AntdTextAreaProps } from "antd/es/input/TextArea";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { TextAreaRef } from "antd/es/input/TextArea";
import { ErrorRow, Labeled, LabeledFieldProps } from "@mapmycustomers/ui";
import { LARGE_TEXT_LENGTH_LIMIT } from "@mapmycustomers/shared/util/consts";
import { useIntl } from "react-intl";

export interface TextAreaProps
  extends Omit<AntdTextAreaProps, "onChange">,
    Omit<LabeledFieldProps, "children"> {
  error?: ReactNode;
  onChange?: (value: string, event: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
}

const TextArea = forwardRef<TextAreaComponent, TextAreaProps>(
  (
    {
      className,
      error,
      label,
      labelClassName,
      labelPosition,
      maxLength = LARGE_TEXT_LENGTH_LIMIT,
      onChange,
      rowProps,
      sideLabelSpan,
      value,
      ...props
    },
    ref
  ) => {
    const intl = useIntl();

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(event.target.value, event);
      },
      [onChange]
    );

    const formattedError = useMemo(
      () =>
        (value ?? "").length >= maxLength
          ? intl.formatMessage({
              id: "input.textArea.textLimitReached",
              defaultMessage: "Character limit reached",
              description: "Character limit reached text on textarea input",
            })
          : error,
      [error, intl, maxLength, value]
    );

    const inputRef = useRef<TextAreaRef>(null);
    useImperativeHandle(ref, () => ({
      blur: () => inputRef.current?.blur(),
      focus: () => inputRef.current?.focus(),
    }));

    return (
      <Labeled
        className={className}
        label={label}
        labelClassName={labelClassName}
        labelPosition={labelPosition}
        rowProps={rowProps}
        sideLabelSpan={sideLabelSpan}
      >
        <Col span={24}>
          <Row>
            <AntDTextArea maxLength={maxLength} onChange={handleChange} value={value} {...props} />
          </Row>
          {formattedError && <ErrorRow>{formattedError}</ErrorRow>}
        </Col>
      </Labeled>
    );
  }
);

export interface TextAreaComponent {
  blur(): void;
  focus: () => void;
}

export default TextArea;

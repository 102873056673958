import AnyColor, { HexColor } from "@mapmycustomers/shared/types/AnyColor";
import Color, { knownColors } from "@mapmycustomers/shared/enum/Color";
import colorNameToHex from "./colorNameToHex";

const anyColorToHex = (color: AnyColor): HexColor =>
  knownColors.includes(color as Color)
    ? colorNameToHex(color as Color)
    : (color as HexColor);

export default anyColorToHex;

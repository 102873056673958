import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import cn from "classnames";
import Button from "antd/es/button";
import PopConfirm from "antd/es/popconfirm";
import Address from "@mapmycustomers/shared/types/Address";
import { EntityWithLocation } from "@mapmycustomers/shared/types/entity";
import geoAddressToAddress from "util/geoAddressToAddress";
import { getFormattedAddressForUi } from "util/formatters";
import styles from "./AddressWarning.module.scss";

interface Props {
  entity: EntityWithLocation;
  loading?: boolean;
  onCancel?: () => void;
  onOk?: (address: Address) => void;
  proposedClassName?: string;
  showCancelConfirmation?: boolean;
}

const AddressWarning: React.FC<Props> = ({
  entity,
  loading,
  onCancel,
  onOk,
  proposedClassName,
  showCancelConfirmation,
}) => {
  const intl = useIntl();

  const handleOk = useCallback(() => {
    if (entity.geoAddress) {
      onOk?.(geoAddressToAddress(entity.geoAddress));
    }
  }, [entity.geoAddress, onOk]);

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div>
          {intl.formatMessage({
            id: "entity.incompleteAddressWarning.popover.body",
            defaultMessage:
              "We are unable to accurately map this record based on the provided address. We have positioned the pin based on the best known match available:",
            description: "Incomplete address warning popover - body text",
          })}
        </div>

        <div>
          <span className={cn(styles.proposed, proposedClassName)}>
            {getFormattedAddressForUi(entity.geoAddress)}
          </span>
        </div>

        <div>
          {intl.formatMessage({
            id: "entity.incompleteAddressWarning.popover.notice",
            defaultMessage: "Would you like to update to the recommended address above?",
            description: "Incomplete address warning popover - notice",
          })}
        </div>
      </div>

      <div className={styles.actions}>
        <Button loading={loading} onClick={handleOk} type="primary">
          {intl.formatMessage({
            id: "entity.incompleteAddressWarning.popover.action.update",
            defaultMessage: "Update Address",
            description: "Incomplete address warning popover - Update Address button title",
          })}
        </Button>

        <PopConfirm
          cancelText={intl.formatMessage({
            id: "editActivityModal.footer.deleteActivity.invalidConfirm.cancel",
            defaultMessage: "Cancel",
            description: "Keep invalid address confirmation text - cancel button",
          })}
          disabled={!showCancelConfirmation}
          okText={intl.formatMessage({
            id: "editActivityModal.footer.deleteActivity.invalidConfirm.ok",
            defaultMessage: "Keep Invalid Address",
            description: "Keep invalid address confirmation text - confirm button",
          })}
          onConfirm={onCancel}
          overlayClassName={styles.overlay}
          placement="bottom"
          title={intl.formatMessage({
            id: "entity.incompleteAddressWarning.popover.invalidConfirm.text",
            defaultMessage:
              "If you opt to keep the invalid address, the pin’s location will not accurately match the provided address.",
            description: "Keep invalid address confirmation text",
          })}
        >
          <Button onClick={showCancelConfirmation ? undefined : onCancel}>
            {intl.formatMessage({
              id: "entity.incompleteAddressWarning.popover.action.keep",
              defaultMessage: "Keep Invalid Address",
              description: "Incomplete address warning popover - Keep Invalid Address button title",
            })}
          </Button>
        </PopConfirm>
      </div>
    </div>
  );
};

export default AddressWarning;

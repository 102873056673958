import { createReducer } from "typesafe-actions";
import {
  Actions,
  applyForecastViewSettings,
  applyKanbanViewSettings,
  applyListViewSettings,
  fetchForecastDateDeals,
  fetchForecastDeals,
  fetchForecastNextPeriodDeals,
  fetchFunnelStagesSummary,
  fetchKanbanDeals,
  fetchKanbanStageDeals,
  fetchList,
  initializeKanbanView,
  initializeListView,
  openUnsavedPanel,
  setActiveFunnelId,
  setChangedFunnelData,
  setChangedStagesData,
  setForecastHiddenColumnIds,
  setForecastViewMode,
  setKanbanHiddenColumnIds,
  setOfferedFilters,
  subscribeOnViewStateUpdating,
  toggleFunnelChartDisplay,
  unsubscribeOnViewStateUpdating,
  updateForecastByDealChanging,
  updateKanbanByDealChanging,
} from "./actions";
import { Actions as AppActions, initializeApp } from "store/app/actions";
import localSettings from "config/LocalSettings";
import dealFieldModel from "util/fieldModel/DealFieldModel";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import ForecastViewMode from "../component/ForecastPage/enum/ForecastViewMode";
import { FORECAST_COLUMNS_PER_PAGE } from "../util/consts";
import getClosingDateFilterByModeAndColumns from "../component/ForecastPage/util/getClosingDateFilterByModeAndColumns";
import ForecastViewState from "../component/ForecastPage/types/ForecastViewState";
import KanbanView from "./types/KanbanView";
import ForecastView from "./types/ForecastView";
import ListView from "./types/ListView";
import ViewMode from "../enum/ViewMode";
import BoardViewState from "../type/BoardViewState";
import { FORECAST_VIEW_KEY, KANBAN_VIEW_KEY, LIST_VIEW_KEY } from "./saga";
import { updateFunnel, updateStages } from "store/deal/actions";
import { DealActions } from "store/deal";
import formatDateAsColumnKey from "../component/ForecastPage/util/formatDateAsColumnKey";
import { parseApiDateWithUtcTz } from "util/parsers";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import uniq from "lodash-es/uniq";

const DEFAULT_KANBAN_VIEW_STATE: BoardViewState = {
  columns: [],
  filter: {},
  hiddenColumnIds: [],
  range: { startRow: 0, endRow: 9999 },
  selectedSavedFilterId: undefined,
  sort: [{ field: dealFieldModel.getByName("updatedAt")!, order: SortOrder.DESC }],
  viewAs: undefined,
};

const DEFAULT_FORECAST_VIEW_STATE: ForecastViewState = {
  columns: [],
  // filtering by closingDate starting from current month and including next FORECAST_COLUMNS_PER_PAGE months
  filter: {
    closingDate: getClosingDateFilterByModeAndColumns(ForecastViewMode.MONTH),
  },
  hiddenColumnIds: [0],
  selectedSavedFilterId: undefined,
  sort: [{ field: dealFieldModel.getByName("updatedAt")!, order: SortOrder.DESC }],
  range: { startRow: 0, endRow: 9999 },
  viewAs: undefined,
  viewMode: ForecastViewMode.MONTH,
};

export interface DealsState {
  activeFunnelId?: Funnel["id"];
  changedFunnelData: boolean;
  changedStagesData: boolean;
  forecastView: ForecastView;
  kanbanView: KanbanView;
  listView: ListView;
  offerFilters?: Partial<FilterModel>;
  onViewStateChanged?: (state: Partial<ViewState>) => void;
  unsavedPanelOpen: boolean;
  viewMode: ViewMode;
}

const initialState: DealsState = {
  listView: {
    funnelChartHidden: false,
    funnelSummary: { openAmount: 0, adjustedAmount: 0 },
    funnelStagesSummary: {
      data: [],
      loading: false,
    },
    // loading is true by default to avoid flashing a "no rows" overlay when the page
    // is just opened but loading is not yet started
    loading: true,
    totalFilteredRecords: 0,
    totalRecords: 0,
    viewState: dealFieldModel.getDefaultListViewState(),
  },
  kanbanView: {
    groupedDeals: {},
    funnelSummary: { openAmount: 0, adjustedAmount: 0 },
    loading: false,
    totalFilteredRecords: 0,
    totalRecords: 0,
    viewState: DEFAULT_KANBAN_VIEW_STATE,
  },
  forecastView: {
    columnCount: FORECAST_COLUMNS_PER_PAGE,
    columnLoading: [],
    groupedDeals: {},
    funnelSummary: { openAmount: 0, adjustedAmount: 0 },
    loading: false,
    totalFilteredRecords: 0,
    totalRecords: 0,
    viewState: DEFAULT_FORECAST_VIEW_STATE,
  },
  changedFunnelData: false,
  changedStagesData: false,
  unsavedPanelOpen: false,
  viewMode: ViewMode.KANBAN,
};

const deal = createReducer<DealsState, Actions | AppActions | DealActions>(initialState)
  // TODO: change this to catch fetchCustomFields.success for deal entity type
  // to update view state every time custom fields are modified
  // load view settings after app initialization to ensure custom fields are fetched
  // OR not change, but add one more action
  .handleAction(initializeApp.success, (state) => ({
    ...state,
    listView: {
      ...state.listView,
      viewState: localSettings.getViewSettings(LIST_VIEW_KEY, dealFieldModel),
    },
    kanbanView: {
      ...state.kanbanView,
      viewState: {
        ...DEFAULT_KANBAN_VIEW_STATE,
        ...(localSettings.getViewSettings(
          KANBAN_VIEW_KEY,
          dealFieldModel,
          DEFAULT_KANBAN_VIEW_STATE
        ) as BoardViewState),
      },
    },
    forecastView: {
      ...state.forecastView,
      viewState: {
        ...DEFAULT_FORECAST_VIEW_STATE,
        ...(localSettings.getViewSettings(
          FORECAST_VIEW_KEY,
          dealFieldModel,
          DEFAULT_FORECAST_VIEW_STATE
        ) as ForecastViewState),
      },
    },
  }))
  .handleAction(initializeListView.success, (state) => ({
    ...state,
    viewMode: ViewMode.LIST,
  }))
  .handleAction(applyListViewSettings, (state, { payload }) => ({
    ...state,
    listView: {
      ...state.listView,
      viewState: {
        ...state.listView.viewState,
        range: payload.range ?? state.listView.viewState.range,
        sort: payload.sort ?? state.listView.viewState.sort,
        filter: payload.filter ?? state.listView.viewState.filter,
        columns: payload.columns ?? state.listView.viewState.columns,
        // only update selectedSavedFilterId when it is explicitly present in a payload (even when it is `undefined`)
        selectedSavedFilterId:
          "selectedSavedFilterId" in payload
            ? payload.selectedSavedFilterId
            : state.listView.viewState.selectedSavedFilterId,
        // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
        viewAs: "viewAs" in payload ? payload.viewAs : state.listView.viewState.viewAs,
      },
    },
  }))
  .handleAction(fetchList.request, (state) => ({
    ...state,
    listView: {
      ...state.listView,
      loading: true,
    },
  }))
  .handleAction(fetchList.success, (state, action) => ({
    ...state,
    listView: {
      ...state.listView,
      funnelSummary: action.payload.funnelSummary,
      loading: false,
      totalFilteredRecords: action.payload.totalFilteredRecords,
      totalRecords: action.payload.totalRecords,
    },
  }))
  .handleAction(fetchList.failure, (state) => ({
    ...state,
    listView: {
      ...state.listView,
      loading: false,
    },
  }))
  .handleAction(fetchFunnelStagesSummary.request, (state) => ({
    ...state,
    listView: {
      ...state.listView,
      funnelStagesSummary: {
        ...state.listView.funnelStagesSummary,
        loading: true,
      },
    },
  }))
  .handleAction(fetchFunnelStagesSummary.success, (state, action) => ({
    ...state,
    listView: {
      ...state.listView,
      funnelStagesSummary: {
        ...state.listView.funnelStagesSummary,
        data: action.payload,
        loading: false,
      },
    },
  }))
  .handleAction(fetchFunnelStagesSummary.failure, (state) => ({
    ...state,
    listView: {
      ...state.listView,
      funnelStagesSummary: {
        ...state.listView.funnelStagesSummary,
        loading: false,
      },
    },
  }))
  .handleAction(initializeKanbanView.success, (state) => ({
    ...state,
    loading: false,
    viewMode: ViewMode.KANBAN,
  }))
  .handleAction(applyKanbanViewSettings, (state, { payload }) => ({
    ...state,
    kanbanView: {
      ...state.kanbanView,
      viewState: {
        ...state.kanbanView.viewState,
        range: payload.range ?? state.kanbanView.viewState.range,
        sort: payload.sort ?? state.kanbanView.viewState.sort,
        filter: payload.filter ?? state.kanbanView.viewState.filter,
        columns: payload.columns ?? state.kanbanView.viewState.columns,
        // only update selectedSavedFilterId when it is explicitly present in a payload (even when it is `undefined`)
        selectedSavedFilterId:
          "selectedSavedFilterId" in payload
            ? payload.selectedSavedFilterId
            : state.kanbanView.viewState.selectedSavedFilterId,
        // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
        viewAs: "viewAs" in payload ? payload.viewAs : state.kanbanView.viewState.viewAs,
      },
    },
  }))
  .handleAction(fetchKanbanDeals.request, (state) => ({
    ...state,
    kanbanView: {
      ...state.kanbanView,
      loading: true,
      groupedDeals: Object.keys(state.kanbanView.groupedDeals).reduce(
        (result, key) => ({
          ...result,
          [key]: { ...state.kanbanView.groupedDeals[parseInt(key, 10)], deals: [] },
        }),
        {}
      ),
    },
  }))
  .handleAction(fetchKanbanDeals.success, (state, { payload }) => ({
    ...state,
    kanbanView: {
      ...state.kanbanView,
      groupedDeals: payload.groupedDeals,
      funnelSummary: payload.funnelSummary,
      loading: false,
      totalFilteredRecords: payload.totalFilteredRecords,
      totalRecords: payload.totalRecords,
    },
  }))
  .handleAction(fetchKanbanDeals.failure, (state) => ({
    ...state,
    kanbanView: {
      ...state.kanbanView,
      loading: false,
    },
  }))
  .handleAction(fetchKanbanStageDeals.request, (state, { payload: { stageId } }) => ({
    ...state,
    kanbanView: {
      ...state.kanbanView,
      groupedDeals: {
        ...state.kanbanView.groupedDeals,
        [stageId]: {
          ...state.kanbanView.groupedDeals[stageId],
          loading: true,
        },
      },
    },
  }))
  .handleAction(
    fetchKanbanStageDeals.success,
    (state, { payload: { stageId, deals, total, preventUpdate } }) => ({
      ...state,
      kanbanView: {
        ...state.kanbanView,
        groupedDeals: {
          ...state.kanbanView.groupedDeals,
          [stageId]: {
            ...state.kanbanView.groupedDeals[stageId],
            deals: [
              ...state.kanbanView.groupedDeals[stageId].deals,
              ...(preventUpdate ? [] : deals),
            ],
            loading: false,
            total: preventUpdate ? state.kanbanView.groupedDeals[stageId].total : total,
          },
        },
      },
    })
  )
  .handleAction(fetchKanbanStageDeals.failure, (state, { payload: { stageId } }) => ({
    ...state,
    kanbanView: {
      ...state.kanbanView,
      groupedDeals: {
        ...state.kanbanView.groupedDeals,
        [stageId]: {
          ...state.kanbanView.groupedDeals[stageId],
          loading: false,
        },
      },
    },
  }))
  .handleAction(setKanbanHiddenColumnIds, (state, { payload }) => ({
    ...state,
    kanbanView: {
      ...state.kanbanView,
      viewState: {
        ...state.kanbanView.viewState,
        hiddenColumnIds: payload,
      },
    },
  }))
  .handleAction(updateKanbanByDealChanging.request, (state, { payload }) => {
    const stageIds = uniq(payload.map(({ stage }) => stage.id));

    return {
      ...state,
      kanbanView: {
        ...state.kanbanView,
        groupedDeals: {
          ...state.kanbanView.groupedDeals,
          ...stageIds
            // we're doing this filtering because we can get stageId from another funnel,
            // e.g. when user modifies deal in deal preview pane
            .filter((stageId) => !!state.kanbanView.groupedDeals[stageId])
            .reduce(
              (result, stageId) => ({
                ...result,
                [stageId]: { ...state.kanbanView.groupedDeals[stageId], loading: true },
              }),
              {}
            ),
        },
      },
    };
  })
  .handleAction(
    updateKanbanByDealChanging.success,
    (state, { payload: { groupedDeals, funnelSummary, totalFilteredRecords, totalRecords } }) => ({
      ...state,
      kanbanView: {
        ...state.kanbanView,
        groupedDeals: { ...state.kanbanView.groupedDeals, ...groupedDeals },
        funnelSummary: funnelSummary,
        totalFilteredRecords: totalFilteredRecords,
        totalRecords: totalRecords,
      },
    })
  )
  .handleAction(updateKanbanByDealChanging.failure, (state, { payload }) => {
    const stageIds = uniq(payload.map(({ stage }) => stage.id));
    return {
      ...state,
      kanbanView: {
        ...state.kanbanView,
        groupedDeals: {
          ...state.kanbanView.groupedDeals,
          ...stageIds.reduce(
            (result, key) => ({
              ...result,
              [key]: { ...state.kanbanView.groupedDeals[key], loading: false },
            }),
            {}
          ),
        },
      },
    };
  })
  .handleAction(applyForecastViewSettings, (state, { payload }) => ({
    ...state,
    forecastView: {
      ...state.forecastView,
      viewState: {
        ...state.forecastView.viewState,
        range: payload.range ?? state.forecastView.viewState.range,
        sort: payload.sort ?? state.forecastView.viewState.sort,
        filter: payload.filter ?? state.forecastView.viewState.filter,
        columns: payload.columns ?? state.forecastView.viewState.columns,
        // only update selectedSavedFilterId when it is explicitly present in a payload (even when it is `undefined`)
        selectedSavedFilterId:
          "selectedSavedFilterId" in payload
            ? payload.selectedSavedFilterId
            : state.forecastView.viewState.selectedSavedFilterId,
        // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
        viewAs: "viewAs" in payload ? payload.viewAs : state.forecastView.viewState.viewAs,
      },
    },
  }))
  .handleAction(fetchForecastDeals.request, (state) => ({
    ...state,
    forecastView: {
      ...state.forecastView,
      loading: true,
      groupedDeals: Object.keys(state.forecastView.groupedDeals).reduce(
        (result, key) => ({
          ...result,
          [key]: { ...state.forecastView.groupedDeals[key], deals: [] },
        }),
        {}
      ),
    },
  }))
  .handleAction(
    fetchForecastDeals.success,
    (state, { payload: { groupedDeals, funnelSummary, totalFilteredRecords, totalRecords } }) => ({
      ...state,
      forecastView: {
        ...state.forecastView,
        groupedDeals: groupedDeals,
        funnelSummary: funnelSummary ?? state.forecastView.funnelSummary,
        loading: false,
        totalFilteredRecords: totalFilteredRecords ?? state.forecastView.totalFilteredRecords,
        totalRecords: totalRecords ?? state.forecastView.totalRecords,
      },
    })
  )
  .handleAction(fetchForecastNextPeriodDeals.request, (state) => ({
    ...state,
    forecastView: {
      ...state.forecastView,
      loading: true,
    },
  }))
  .handleAction(fetchForecastNextPeriodDeals.success, (state, { payload }) => ({
    ...state,
    forecastView: {
      ...state.forecastView,
      groupedDeals: { ...state.forecastView.groupedDeals, ...payload.groupedDeals },
      columnCount: state.forecastView.columnCount + FORECAST_COLUMNS_PER_PAGE,
      loading: false,
    },
  }))
  .handleAction(fetchForecastNextPeriodDeals.failure, (state) => ({
    ...state,
    forecastView: {
      ...state.forecastView,
      loading: false,
    },
  }))
  .handleAction(updateForecastByDealChanging.request, (state, { payload }) => {
    const keys = uniq(
      payload.map(({ closingDate }) =>
        formatDateAsColumnKey(
          state.forecastView.viewState.viewMode,
          closingDate ? parseApiDateWithUtcTz(closingDate) : undefined
        )
      )
    ).filter((key) => key in state.forecastView.groupedDeals);
    return {
      ...state,
      forecastView: {
        ...state.forecastView,
        groupedDeals: {
          ...state.forecastView.groupedDeals,
          ...keys.reduce(
            (result, key) => ({
              ...result,
              [key]: { ...state.forecastView.groupedDeals[key], loading: true },
            }),
            {}
          ),
        },
      },
    };
  })
  .handleAction(
    updateForecastByDealChanging.success,
    (state, { payload: { groupedDeals, funnelSummary, totalFilteredRecords, totalRecords } }) => ({
      ...state,
      forecastView: {
        ...state.forecastView,
        groupedDeals: { ...state.forecastView.groupedDeals, ...groupedDeals },
        funnelSummary: funnelSummary,
        totalFilteredRecords: totalFilteredRecords,
        totalRecords: totalRecords,
      },
    })
  )
  .handleAction(updateForecastByDealChanging.failure, (state, { payload }) => {
    const keys = uniq(
      payload.map(({ closingDate }) =>
        formatDateAsColumnKey(
          state.forecastView.viewState.viewMode,
          closingDate ? parseApiDateWithUtcTz(closingDate) : undefined
        )
      )
    );
    return {
      ...state,
      forecastView: {
        ...state.forecastView,
        groupedDeals: {
          ...state.forecastView.groupedDeals,
          ...keys.reduce(
            (result, key) => ({
              ...result,
              [key]: { ...state.forecastView.groupedDeals[key], loading: false },
            }),
            {}
          ),
        },
      },
    };
  })
  .handleAction(fetchForecastDeals.failure, (state) => ({
    ...state,
    forecastView: {
      ...state.forecastView,
      loading: false,
    },
  }))
  .handleAction(fetchForecastDateDeals.request, (state, { payload: { date } }) => {
    const key = formatDateAsColumnKey(state.forecastView.viewState.viewMode, date);
    return {
      ...state,
      forecastView: {
        ...state.forecastView,
        groupedDeals: {
          ...state.forecastView.groupedDeals,
          [key]: {
            ...state.forecastView.groupedDeals[key],
            loading: true,
          },
        },
      },
    };
  })
  .handleAction(
    fetchForecastDateDeals.success,
    (state, { payload: { date, deals, preventUpdate } }) => {
      const key = formatDateAsColumnKey(state.forecastView.viewState.viewMode, date);
      return {
        ...state,
        forecastView: {
          ...state.forecastView,
          groupedDeals: {
            ...state.forecastView.groupedDeals,
            [key]: {
              ...state.forecastView.groupedDeals[key],
              deals: [
                ...state.forecastView.groupedDeals[key].deals,
                ...(preventUpdate ? [] : deals),
              ],
              loading: false,
            },
          },
        },
      };
    }
  )
  .handleAction(fetchForecastDateDeals.failure, (state, { payload: { date } }) => {
    const key = formatDateAsColumnKey(state.forecastView.viewState.viewMode, date);
    return {
      ...state,
      forecastView: {
        ...state.forecastView,
        groupedDeals: {
          ...state.forecastView.groupedDeals,
          [key]: {
            ...state.forecastView.groupedDeals[key],
            loading: false,
          },
        },
      },
    };
  })
  .handleAction(setForecastViewMode, (state, { payload }) => ({
    ...state,
    forecastView: {
      ...state.forecastView,
      viewState: {
        ...state.forecastView.viewState,
        hiddenColumnIds: [],
        viewMode: payload,
      },
    },
  }))
  .handleAction(setForecastHiddenColumnIds, (state, { payload }) => ({
    ...state,
    forecastView: {
      ...state.forecastView,
      viewState: {
        ...state.forecastView.viewState,
        hiddenColumnIds: payload,
      },
    },
  }))
  .handleAction(setChangedFunnelData, (state, action) => ({
    ...state,
    changedFunnelData: action.payload,
  }))
  .handleAction(setChangedStagesData, (state, action) => ({
    ...state,
    changedStagesData: action.payload,
  }))
  .handleAction(setActiveFunnelId, (state, action) => ({
    ...state,
    activeFunnelId: action.payload,
  }))
  .handleAction(setOfferedFilters, (state, action) => ({
    ...state,
    offerFilters: action.payload,
  }))
  .handleAction(toggleFunnelChartDisplay, (state, action) => ({
    ...state,
    listView: {
      ...state.listView,
      funnelChartHidden: action.payload,
    },
  }))
  .handleAction(openUnsavedPanel, (state, action) => ({
    ...state,
    unsavedPanelOpen: action.payload,
  }))
  .handleAction(updateFunnel.success, (state) => ({
    ...state,
    changedFunnelData: false,
  }))
  .handleAction(updateStages.success, (state) => ({
    ...state,
    changedStagesData: false,
  }))
  .handleAction(subscribeOnViewStateUpdating, (state, { payload: { callback } }) => ({
    ...state,
    onViewStateChanged: callback,
  }))
  .handleAction(unsubscribeOnViewStateUpdating, (state) => ({
    ...state,
    onViewStateChanged: undefined,
  }));

export * from "./selectors";
export type DealSceneActions = Actions;
export default deal;

import React from "react";
import { useIntl } from "react-intl";
import styles from "./EmptyValue.module.scss";

interface Props {}

const EmptyValue: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <span className={styles.unknown}>
      {intl.formatMessage({
        defaultMessage: "Unknown",
        description: "Unknown value in field",
        id: "map.annotation.entity.field.unknownValue",
      })}
    </span>
  );
};

export default EmptyValue;

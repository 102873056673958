import FieldCategory from "@mapmycustomers/shared/enum/fieldModel/FieldCategory";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  [FieldCategory.ADDRESS]: {
    id: "fieldCategory.address",
    defaultMessage: "Address Fields",
    description: "Address field category",
  },
  [FieldCategory.RELATIONSHIPS]: {
    id: "fieldCategory.relationships",
    defaultMessage: "Relationships",
    description: "Relationships field category",
  },
  [FieldCategory.REQUIRED]: {
    id: "fieldCategory.required",
    defaultMessage: "Required Fields",
    description: "Required field category",
  },
  [FieldCategory.NON_REQUIRED]: {
    id: "fieldCategory.nonRequired",
    defaultMessage: "Non-Required Fields",
    description: "Check-In field category",
  },
  [FieldCategory.DETAILS]: {
    id: "fieldCategory.details",
    defaultMessage: "Details",
    description: "Details field category",
  },
  [FieldCategory.PARENT_COMPANY_ADDRESS_FIELDS]: {
    id: "fieldCategory.parentCompanyAddressFields",
    defaultMessage: "Parent Company Address Fields",
    description: "Details field category",
  },
  [FieldCategory.COMPANY_ADDRESS_FIELDS]: {
    id: "fieldCategory.companyAddressFields",
    defaultMessage: "Company Address Fields",
    description: "Details field category",
  },
  [FieldCategory.PEOPLE_ADDRESS_FIELDS]: {
    id: "fieldCategory.peopleAddressFields",
    defaultMessage: "People Address Fields",
    description: "Details field category",
  },
  [FieldCategory.DEAL_ADDRESS_FIELDS]: {
    id: "fieldCategory.dealAddressFields",
    defaultMessage: "Deal Address Fields",
    description: "Details field category",
  },
});

const getFieldCategoryDisplayName = (intl: IntlShape, fieldCategory: FieldCategory) =>
  intl.formatMessage(messages[fieldCategory]);

export default getFieldCategoryDisplayName;

import { isMonday, isSunday } from "date-fns/esm";
import DateRangeType from "../enums/DateRangeType";
import DateRange from "../types/DateRange";
import rangeToDateRange from "../utils/rangeToDateRange";

const getDefaultDateRangeState = (): {
  dateRange: DateRange | undefined;
  dateRangeType: DateRangeType;
} => {
  const urlParams = new URLSearchParams(window.location.search);
  const range = urlParams.get("range");
  const rawRange =
    !range || Array.isArray(range)
      ? isMonday(Date.now()) || isSunday(Date.now())
        ? DateRangeType.LAST_WEEK
        : DateRangeType.YESTERDAY
      : range;

  if (DateRangeType.LAST_WEEK === (rawRange as DateRangeType)) {
    return { dateRange: undefined, dateRangeType: rawRange as DateRangeType };
  }
  if (
    [DateRangeType.YESTERDAY || DateRangeType.THIS_WEEK || DateRangeType.THIS_MONTH].includes(
      rawRange as DateRangeType
    )
  ) {
    return { dateRange: undefined, dateRangeType: rawRange as DateRangeType };
  }

  const dateRange = rangeToDateRange(rawRange);
  if (dateRange) {
    return { dateRange, dateRangeType: DateRangeType.CUSTOM };
  }

  return { dateRange: undefined, dateRangeType: DateRangeType.YESTERDAY };
};

export default getDefaultDateRangeState;

import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import notification from "antd/es/notification";
import { defineMessage } from "react-intl";
import { callApi } from "store/api/callApi";
import { PersonPayload } from "store/api/ApiService";
import { showEntityView } from "store/entityView/actions";
import { showEntityChannel } from "store/entityView/sagas";
import { handleError } from "store/errors/actions";
import { getOrganization, getOrganizationId } from "store/iam";
import { notifyAboutChanges } from "store/uiSync/actions";
import { getUploadedFileListIds } from "../store";
import {
  clearAllUploadedPersonFiles,
  createPerson,
  initialize,
  removePersonFile,
  uploadPersonFiles,
} from "./actions";
import { Company, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import File, { RawFile } from "@mapmycustomers/shared/types/File";
import FileListItem from "types/FileListItem";
import Organization from "@mapmycustomers/shared/types/Organization";
import { extendNavbarAnalyticWithMenuKey } from "util/analytic/navbarAnalytics";
import { NOTIFICATION_DURATION_WITH_ACTION } from "util/consts";
import { allSettled, SettleResult } from "util/effects";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { DuplicateEntity } from "@mapmycustomers/shared/types/entity/Duplicate";
import { PlatformFilterCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { DUPLICATE_SEARCH_RADIUS } from "util/consts";
import getCreationGeocodeErrorMessage from "../../util/getCreationGeocodeErrorMessage";
import getSuccessNotificationNode from "../../util/getSuccessNotificationNode";
import i18nService from "config/I18nService";

const successMessage = defineMessage({
  id: "createPersonModal.success",
  defaultMessage: "Person added successfully",
  description: "Person add success message",
});

export function* onInitialize({ payload }: ReturnType<typeof initialize.request>) {
  try {
    const { fixedCompany } = payload;
    if (!fixedCompany) {
      yield put(initialize.success());
      return;
    }

    // we need full company details because user may want to use "inherit address" feature
    const org: Organization = yield select(getOrganization);
    const company: Company = yield callApi("fetchCompany", org.id, fixedCompany.id);

    yield put(initialize.success({ parentCompany: company }));
  } catch (error) {
    yield put(initialize.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreatePerson({ payload }: ReturnType<typeof createPerson.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const { callback, person, customFieldsValues, groupsIdsToAdd, checkDuplicates, layoutId } =
      payload;

    if (checkDuplicates) {
      const { phone, email, geoPoint } = person;
      const fieldsFilter: PlatformFilterCondition[] = [
        ...((phone ?? "").trim().length ? [{ phone }] : []),
        ...((email ?? "").trim().length ? [{ email }] : []),
      ];
      if (geoPoint) {
        fieldsFilter.push({
          area: { point: geoPoint.coordinates, radius: DUPLICATE_SEARCH_RADIUS, uom: "metres" },
        });
      } else {
        const addressToCheck = {
          country: person.country ?? "",
          postalCode: person.postalCode ?? "",
          city: person.city ?? "",
          address: person.address ?? "",
          region: person.region ?? "",
          regionCode: person.regionCode ?? "",
        };
        if (Object.values(addressToCheck).some((value) => value.trim().length > 0)) {
          const response: GeocodeResult = yield callApi("geocodeAddress", orgId, addressToCheck);
          if (response.geoPoint) {
            fieldsFilter.push({
              area: {
                point: response.geoPoint.coordinates,
                radius: DUPLICATE_SEARCH_RADIUS,
                uom: "metres",
              },
            });
          }
        }
      }
      if (fieldsFilter.length) {
        const response: ListResponse<DuplicateEntity> = yield callApi(
          "fetchEntityDuplicates",
          orgId,
          EntityType.PERSON,
          {
            $filters: { $or: fieldsFilter, dupeCheck: true },
          }
        );
        if (response.data.length) {
          yield put(
            createPerson.failure({
              duplicates: response.data,
            })
          );
          return;
        }
      }
    }

    const fileIds: File["id"][] = yield select(getUploadedFileListIds);

    const personToSend: PersonPayload = {
      ...person,
      files: fileIds.length > 0 ? [...fileIds] : undefined,
    };
    const newPerson: Person = yield callApi("createPerson", orgId, layoutId, personToSend);

    const peopleIds: Array<Person["id"]> = [newPerson.id];
    yield all([
      ...groupsIdsToAdd.map((groupId) =>
        callApi("addToGroup", orgId, groupId, EntityType.PERSON, peopleIds)
      ),
    ]);

    if (customFieldsValues.length) {
      yield callApi(
        "upsertCustomFieldsValues",
        false,
        orgId,
        layoutId,
        EntityType.PERSON,
        newPerson.id,
        customFieldsValues
      );
    }

    const intl = i18nService.getIntl();
    const message = getCreationGeocodeErrorMessage(newPerson.geoStatus, intl);
    if (message) {
      notification.warning({ message });
    }

    callback?.(newPerson);
    yield put(notifyAboutChanges({ entityType: EntityType.PERSON, added: [newPerson] }));
    extendNavbarAnalyticWithMenuKey("addPerson").clicked(["Create Person"]);
    const notificationKey = "create-person-notification";

    notification.success({
      key: notificationKey,
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(successMessage, "Person added successfully"),
        () => {
          extendNavbarAnalyticWithMenuKey("addPerson").clicked(["Create Person", "View Record"]);
          showEntityChannel.put(
            showEntityView({ entityId: newPerson.id, entityType: EntityType.PERSON })
          );
          notification.close(notificationKey);
        }
      ),
      duration: NOTIFICATION_DURATION_WITH_ACTION,
    });

    yield put(clearAllUploadedPersonFiles());
    yield put(createPerson.success(newPerson));
  } catch (error) {
    yield put(createPerson.failure({}));
    yield put(handleError({ error }));
  }
}

export function* onUploadPersonFiles({ payload }: ReturnType<typeof uploadPersonFiles.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const responses: SettleResult<RawFile>[] = yield allSettled(
      payload.files.map((file) => callApi("createFile", org.id, file))
    );
    const fileList: FileListItem[] = responses.map((response, index) => ({
      file: payload.files[index],
      uploading: false,
      ...(response.error
        ? { errored: true, errorMessage: String(response.result) }
        : { errored: false, uploadedFile: response.result }),
    }));
    payload.callback?.(fileList);
    yield put(uploadPersonFiles.success(fileList));
  } catch (error) {
    payload.callback?.(payload.files.map((file) => ({ file, uploading: false, errored: true })));
    yield put(uploadPersonFiles.failure());
    yield put(handleError({ error }));
  }
}

export function* onRemovePersonFile({ payload }: ReturnType<typeof removePersonFile.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    yield callApi("deleteFile", org.id, payload.id);
    yield put(removePersonFile.success(payload.id));
  } catch (error) {
    yield put(removePersonFile.failure());
    yield put(handleError({ error }));
  }
}

export function* createPersonModalSaga() {
  yield takeLatest(initialize.request, onInitialize);
  yield takeLatest(createPerson.request, onCreatePerson);
  yield takeEvery(uploadPersonFiles.request, onUploadPersonFiles);
  yield takeEvery(removePersonFile.request, onRemovePersonFile);
}

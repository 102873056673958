import { FieldProperties } from "./Field";
import { dateFormatter } from "./fieldUtil";
import DateTimeField from "./DateTimeField";
import { csvDateFormatter } from "util/formatters";

class DateField extends DateTimeField {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      valueFormatter: dateFormatter,
      csvExportProperties: { valueFormatter: csvDateFormatter },
      ...data,
    });
  }
}

export default DateField;

import { ActionType, createAsyncAction } from "typesafe-actions";
import ReferenceData from "./ReferenceData";

export const fetchReferenceData = createAsyncAction(
  "@referenceData/fetch/request",
  "@referenceData/fetch/success",
  "@referenceData/fetch/failure"
)<undefined, ReferenceData, void>();

export type Actions = ActionType<typeof fetchReferenceData>;

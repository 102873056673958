import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const integrationsState = (state: RootState) => state.integrations;

export const getLoading = createSelector(integrationsState, ({ loading }) => loading);

export const getLoadingFields = createSelector(
  integrationsState,
  ({ loadingFields }) => loadingFields
);

export const getSaving = createSelector(integrationsState, ({ saving }) => saving);

export const getCurrentIntegration = createSelector(
  integrationsState,
  ({ currentIntegration }) => currentIntegration
);

export const getIntegrationViewState = createSelector(
  integrationsState,
  ({ viewState }) => viewState
);

export const getCurrentIntegrationFrequency = createSelector(
  integrationsState,
  ({ currentIntegration }) => currentIntegration!.schedule?.frequency
);

export const getCurrentIntegrationNextRunAt = createSelector(
  integrationsState,
  ({ currentIntegration }) => currentIntegration!.schedule?.nextRunAt
);

export const getIntegrations = createSelector(
  integrationsState,
  ({ integrations }) => integrations
);

export const getIntegrationUsers = createSelector(
  integrationsState,
  ({ integrationUsers }) => integrationUsers
);

export const getIntegrationSyncs = createSelector(
  integrationsState,
  ({ integrationSyncs }) => integrationSyncs
);

export const getLastIntegrationSync = createSelector(
  integrationsState,
  ({ lastIntegrationSync }) => lastIntegrationSync
);

export const getIntegrationFields = createSelector(
  integrationsState,
  ({ integrationFields }) => integrationFields
);

export const getCustomFieldIntegrationResponse = createSelector(
  integrationsState,
  ({ customFieldIntegrationResponse }) => customFieldIntegrationResponse
);

export const getIntegrationEmailSyncStats = createSelector(
  integrationsState,
  ({ currentIntegration }) => currentIntegration?.metadata?.emailSyncStats ?? true
);

export const getIntegrationNoteSyncing = createSelector(
  integrationsState,
  ({ currentIntegration }) => currentIntegration?.metadata?.noteSyncing
);

export const getIntegrationFileSyncing = createSelector(
  integrationsState,
  ({ currentIntegration }) => currentIntegration?.metadata?.fileSyncing
);

export const isIntegrationChanged = createSelector(
  integrationsState,
  ({ integrationChanged }) => integrationChanged
);

export const areIntegrationFieldsChanged = createSelector(
  integrationsState,
  ({ integrationFieldsChanged }) => integrationFieldsChanged
);

export const areIntegrationUsersChanged = createSelector(
  integrationsState,
  ({ integrationUsersChanged }) => integrationUsersChanged
);

export const doNeedToSelectDynamicsInstance = createSelector(
  integrationsState,
  ({ needToSelectDynamicsInstance }) => needToSelectDynamicsInstance
);

export const getLoadingSyncs = createSelector(
  integrationsState,
  ({ syncsLoading }) => syncsLoading
);

export const getLoadingSyncsTotal = createSelector(
  integrationsState,
  ({ integrationSyncsTotal }) => integrationSyncsTotal
);

export const isMatchingFieldsWarningVisible = createSelector(
  integrationsState,
  ({ matchingFieldsWarningVisibility }) => matchingFieldsWarningVisibility
);

import { createReducer } from "typesafe-actions";
import {
  Actions,
  createSandboxConfig,
  deleteSandboxConfig,
  fetchSandbox,
  initSandboxPage,
  updateSandboxConfig,
} from "./actions";
import Sandbox from "@mapmycustomers/shared/types/Sandbox";

export interface SandboxState {
  hasIntegration: boolean;
  loading: boolean;
  sandbox?: Sandbox;
}

const initialState: SandboxState = {
  hasIntegration: false,
  loading: false,
  sandbox: undefined,
};

const sandbox = createReducer<SandboxState, Actions>(initialState)
  .handleAction(fetchSandbox.success, (state, { payload: { sandbox } }) => ({
    ...state,
    sandbox,
  }))
  .handleAction(initSandboxPage.request, (state) => ({
    ...state,
    sandbox: undefined,
    loading: true,
  }))
  .handleAction(initSandboxPage.success, (state, { payload: { sandbox, hasIntegration } }) => ({
    ...state,
    hasIntegration,
    loading: false,
    sandbox,
  }))
  .handleAction(initSandboxPage.failure, (state) => ({
    ...state,
    sandbox: undefined,
    loading: false,
  }))
  .handleAction(createSandboxConfig.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(createSandboxConfig.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(createSandboxConfig.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(updateSandboxConfig.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(updateSandboxConfig.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(updateSandboxConfig.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(deleteSandboxConfig.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(deleteSandboxConfig.success, (state) => ({
    ...state,
    sandbox: undefined,
    loading: false,
  }))
  .handleAction(deleteSandboxConfig.failure, (state) => ({
    ...state,
    loading: false,
  }));

export * from "./selectors";
export type SandboxActions = Actions;
export default sandbox;

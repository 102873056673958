import {
  createDashboard,
  deleteDashboard,
  duplicateDashboard,
  fetchDashboardList,
  initializeCumul,
  cumulDemoRequest,
} from "./actions";
import i18nService from "config/I18nService";
import notification from "antd/es/notification";
import { call } from "redux-saga/effects";
import Organization from "@mapmycustomers/shared/types/Organization";
import { togglePinnedDashboard } from "store/iam/actions";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getCurrentUserId, getOrganizationId, getPinnedDashboardIds } from "store/iam";
import CumulAuth from "@mapmycustomers/shared/types/cumul/CumulAuth";
import { handleError } from "store/errors/actions";
import { callApi } from "store/api/callApi";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import CumulDashboard from "@mapmycustomers/shared/types/cumul/CumulDashboard";
import { getCumulAuth } from "store/cumul/selectors";
import User from "@mapmycustomers/shared/types/User";
import { getCaptchaToken } from "util/captcha";

export function* onFetchDashboardList() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const cumulAuthenticationData: CumulAuth = yield select(getCumulAuth);

    if (cumulAuthenticationData?.token && cumulAuthenticationData?.id) {
      const payload = {
        cumulSSOToken: cumulAuthenticationData.token,
        cumulSSOKey: cumulAuthenticationData.id,
      };
      const dasboardList: ListResponse<CumulDashboard> = yield callApi(
        "getCumulDashboardList",
        orgId,
        payload,
        {
          $order: "-modified_at",
        }
      );
      yield put(fetchDashboardList.success(dasboardList.data));
    }
  } catch (error) {
    yield put(fetchDashboardList.failure(new Error("no token")));
    yield put(handleError({ error }));
  }
}

export function* onInitializeCumul() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const cumulAuthenticationResponse: CumulAuth = yield callApi("authorizeCumul", orgId);
    yield put(initializeCumul.success(cumulAuthenticationResponse));
    yield put(fetchDashboardList.request());
  } catch (error) {
    yield put(initializeCumul.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onDeleteDashboard({ payload }: ReturnType<typeof deleteDashboard.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const cumulAuthenticationData: CumulAuth = yield select(getCumulAuth);
    const pinnedDashboardIds: CumulDashboard["id"][] = yield select(getPinnedDashboardIds);
    if (cumulAuthenticationData?.token && cumulAuthenticationData?.id) {
      const details = {
        cumulSSOToken: cumulAuthenticationData.token,
        cumulSSOKey: cumulAuthenticationData.id,
      };
      yield callApi("deleteDashboard", orgId, payload, details);
      yield put(fetchDashboardList.request());
    }
    yield put(deleteDashboard.success());
    if (pinnedDashboardIds.includes(payload)) {
      yield put(togglePinnedDashboard.request(payload));
    }
  } catch (error) {
    yield put(deleteDashboard.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onDuplicateDashboard({ payload }: ReturnType<typeof duplicateDashboard.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const cumulAuthenticationData: CumulAuth = yield select(getCumulAuth);
    const currentUserId: User["id"] = yield select(getCurrentUserId);
    if (cumulAuthenticationData?.token && cumulAuthenticationData?.id) {
      const details = {
        userId: [currentUserId],
        dashboardId: payload,
        cumulSSOToken: cumulAuthenticationData.token,
        cumulSSOKey: cumulAuthenticationData.id,
      };
      const duplicateDashboardData: CumulDashboard = yield callApi(
        "duplicateDashboard",
        orgId,
        payload,
        details
      );
      yield put(fetchDashboardList.request());
      yield put(duplicateDashboard.success(duplicateDashboardData));
    }
  } catch (error) {
    yield put(duplicateDashboard.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onCreateDashboard({ payload }: ReturnType<typeof createDashboard.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const cumulAuthenticationData: CumulAuth = yield select(getCumulAuth);
    if (cumulAuthenticationData?.token && cumulAuthenticationData?.id) {
      const details = {
        cumulSSOToken: cumulAuthenticationData.token,
        cumulSSOKey: cumulAuthenticationData.id,
      };
      const newDashboardData: CumulDashboard = yield callApi("createDashboard", orgId, details);
      yield put(fetchDashboardList.request());
      yield put(createDashboard.success(newDashboardData));
    }
  } catch (error) {
    yield put(createDashboard.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onCumulDemoRequest({ payload }: ReturnType<typeof cumulDemoRequest.request>) {
  try {
    const token: string = yield call(getCaptchaToken, "cumuldemorequest");
    yield callApi("cumulDemoRequest", token, payload.message);

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage({
          id: "cumul.demo.request.header",
          defaultMessage: "Demo requested",
          description: "Cumul demo requested success message",
        }),
        description: intl.formatMessage({
          id: "cumul.demo.request.description",
          defaultMessage:
            "A member of our team will be in touch within the next 1-2 business days with more information.",
          description: "Cumul demo requested success description",
        }),
      });
    }
    yield put(cumulDemoRequest.success());
  } catch (error) {
    yield put(cumulDemoRequest.failure());
    yield put(handleError({ error }));
  }
}

export function* cumulSaga() {
  yield takeEvery(initializeCumul.request, onInitializeCumul);
  yield takeLatest(fetchDashboardList.request, onFetchDashboardList);
  yield takeEvery(deleteDashboard.request, onDeleteDashboard);
  yield takeEvery(duplicateDashboard.request, onDuplicateDashboard);
  yield takeEvery(createDashboard.request, onCreateDashboard);
  yield takeEvery(cumulDemoRequest.request, onCumulDemoRequest);
}

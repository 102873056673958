import {
  Actions,
  createDashboard,
  duplicateDashboard,
  fetchDashboardList,
  hideDashboard,
  initializeCumul,
  showDashboard,
} from "./actions";
import { createReducer } from "typesafe-actions";
import CumulAuth from "@mapmycustomers/shared/types/cumul/CumulAuth";
import CumulDashboard from "@mapmycustomers/shared/types/cumul/CumulDashboard";

export type CumulState = {
  cumulAuth?: CumulAuth;
  cumulDashboardList?: CumulDashboard[];
  dashboardIdToOpen?: CumulDashboard["id"];
};

const initialState: CumulState = {};

const cumul = createReducer<CumulState, Actions>(initialState)
  .handleAction(initializeCumul.request, (state) => ({
    ...state,
  }))
  .handleAction(initializeCumul.success, (state, { payload }) => ({
    ...state,
    cumulAuth: payload,
  }))
  .handleAction(initializeCumul.failure, (state) => ({
    ...state,
  }))
  .handleAction(fetchDashboardList.request, (state) => ({
    ...state,
  }))
  .handleAction(fetchDashboardList.success, (state, { payload }) => ({
    ...state,
    cumulDashboardList: payload,
  }))
  .handleAction(fetchDashboardList.failure, (state) => ({
    ...state,
  }))
  .handleAction(showDashboard, (state, { payload }) => ({
    ...state,
    dashboardIdToOpen: payload,
  }))
  .handleAction(hideDashboard, (state) => ({
    ...state,
    dashboardIdToOpen: undefined,
  }))
  .handleAction(duplicateDashboard.request, (state) => ({
    ...state,
  }))
  .handleAction(duplicateDashboard.success, (state, { payload }) => ({
    ...state,
    dashboardIdToOpen: payload.id,
  }))
  .handleAction(duplicateDashboard.failure, (state) => ({
    ...state,
  }))
  .handleAction(createDashboard.request, (state) => ({
    ...state,
  }))
  .handleAction(createDashboard.success, (state, { payload }) => ({
    ...state,
    dashboardIdToOpen: payload.id,
  }))
  .handleAction(createDashboard.failure, (state) => ({
    ...state,
  }));

export * from "./selectors";
export type CumulActions = Actions;
export default cumul;

import React from "react";
import parsePhoneNumber from "@mapmycustomers/shared/util/parsers/parsePhoneNumber";
import { RenderLeafProps } from "slate-react";

const displayElements = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.decoration === "url") {
    children = (
      <a
        href={leaf.text}
        onClick={() => {
          window.open(
            leaf.text.startsWith("http") ? leaf.text : `//${leaf.text}`,
            "_blank"
          );
        }}
      >
        <u>{children}</u>
      </a>
    );
  }
  if (leaf.decoration === "email") {
    children = (
      <a
        href={`mailto:${leaf.text}`}
        onClick={() => {
          window.location.assign(`mailto:${leaf.text}`);
        }}
        {...attributes}
      >
        <u>{children}</u>
      </a>
    );
  }

  if (leaf.decoration === "phone") {
    const parsedPhoneNumber = parsePhoneNumber(leaf.text);
    const phoneNumberLink = parsedPhoneNumber?.link;
    if (phoneNumberLink) {
      children = (
        <a
          href={phoneNumberLink!}
          onClick={() => {
            window.location.href = phoneNumberLink!;
          }}
          {...attributes}
        >
          <u>{children}</u>
        </a>
      );
    }
  }

  return <span {...attributes}>{children}</span>;
};

export default displayElements;

import React from "react";
import { useIntl } from "react-intl";
import styles from "./ProTip.module.scss";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RootState } from "../../../../../store/rootReducer";
import { getFrequencyModalConfig } from "../../../../../store/frequency";
import FrequencyPreviewConfig from "../../../type/FrequencyPreviewConfig";
import { connect } from "react-redux";

interface Props {
  config: FrequencyPreviewConfig;
}

const ProTip: React.FC<Props> = ({ config }) => {
  const intl = useIntl();
  const { isSomeGroupFrequencied } = config;

  if (isSomeGroupFrequencied) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <FontAwesomeIcon className={styles.infoIcon} icon={faInfoCircle} />
        <span>
          {intl.formatMessage({
            id: "frequency.frequencyModal.frequencies.proTip",
            defaultMessage: "Pro Tip",
            description: "frequency component - frequency modal - frequencies - pro tip",
          })}
        </span>
      </div>
      <div className={styles.subTitle}>
        {intl.formatMessage({
          id: "frequency.frequencyModal.frequencies.proTip.subTitle",
          defaultMessage:
            "Additional frequencies can be set by placing this record within a Group that has an active frequency.",
          description: "frequency component - frequency modal - frequencies - pro tip - sub title",
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  config: getFrequencyModalConfig(state),
});

export default connect(mapStateToProps)(ProTip);

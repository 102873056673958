import React, { useCallback } from "react";
import RoundButton from "component/RoundButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Activity } from "@mapmycustomers/shared/types/entity";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import PostponeButton from "../../PostponeButton";
import styles from "./Footer.module.scss";
import { AnalyticsService } from "util/analytic/AnalyticsService";
import { isPostponeLoading, isToggleCompleteLoading } from "../store";
import EditActivityFocusableField from "../../type/EditActivityFocusableField";
import { faPen } from "@fortawesome/pro-solid-svg-icons/faPen";

interface Props {
  activity: Activity;
  analyticIssuer?: AnalyticsService;
  onEdit: (focusedFieldName?: EditActivityFocusableField) => void;
  onPostponeActivity: (activity: Activity) => void;
  onToggleComplete: () => void;
  postponeLoading?: boolean;
  toggleCompleteLoading?: boolean;
}

const Footer: React.FC<Props> = ({
  activity,
  analyticIssuer,
  onEdit,
  onPostponeActivity,
  onToggleComplete,
  postponeLoading,
  toggleCompleteLoading,
}) => {
  const intl = useIntl();

  const handleCompleteClick = useCallback(() => {
    onToggleComplete();
    if (!activity.completed) {
      analyticIssuer?.clicked(["Mark Complete"]);
    } else {
      analyticIssuer?.clicked(["Mark Incomplete"]);
    }
  }, [activity.completed, analyticIssuer, onToggleComplete]);

  return (
    <div className={styles.container}>
      <RoundButton leftIcon={<FontAwesomeIcon icon={faPen} />} onClick={() => onEdit()}>
        {intl.formatMessage({
          id: "component.activityAnnotation.footer.edit",
          defaultMessage: "Edit",
          description: "Label for Edit button",
        })}
      </RoundButton>
      {!activity.completed && (
        <PostponeButton
          activity={activity}
          analyticIssuer={analyticIssuer}
          loading={postponeLoading}
          onUpdate={onPostponeActivity}
          onSelectAnotherDate={() => onEdit("startAt")}
        />
      )}
      <RoundButton
        active={activity.completed}
        leftIcon={<FontAwesomeIcon icon={faCheckCircle} />}
        loading={toggleCompleteLoading}
        onClick={handleCompleteClick}
      >
        {intl.formatMessage(
          {
            id: "component.activityAnnotation.footer.completeToggle",
            defaultMessage: "{completed, select, true {Done} other {Mark Done}}",
            description: "Label for done button",
          },
          { completed: activity.completed }
        )}
      </RoundButton>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  postponeLoading: isPostponeLoading(state),
  toggleCompleteLoading: isToggleCompleteLoading(state),
});

export default connect(mapStateToProps)(Footer);

.container {
  position: absolute;
  bottom: 3rem;
  right: 1rem;
  left: 1rem;
  box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.06);
  padding: 0.25rem 0.5rem;
  display: flex;
  gap: 0.25rem;
}

import Field, { FieldProperties } from "./Field";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import { locationFormatter } from "./fieldUtil";
import LocationFilter, { LOCATION_FILTER_OPERATORS } from "util/filters/Location";
import { DEFAULT_EMPTINESS_OPERATOR } from "util/filters/defaultFilters";
import EmptyFilter from "util/filters/EmptyFilter";

class BaseLocationField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "location",
        filterInstances: {
          location: LocationFilter,
          empty: EmptyFilter,
        },
        operators: [...LOCATION_FILTER_OPERATORS, ...DEFAULT_EMPTINESS_OPERATOR],
      },
      customGridProperties: {
        cellRenderer: "locationCellRenderer",
        // disable tooltips for this field, since we use custom renderer with a popover
        tooltipValueGetter: undefined,
      },
      type: FieldType.OBJECT,
      valueFormatter: locationFormatter,
      ...data,
    });
  }
}

export default BaseLocationField;

import Field, { FieldProperties } from "./Field";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import i18nService from "config/I18nService";
import { defineMessages } from "react-intl";
import FrequencyStatusFilter, {
  FREQUENCY_STATUS_FILTER_OPERATORS,
} from "util/filters/FrequencyStatusFilter";
import EmptyFilter from "util/filters/EmptyFilter";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import Cadenced from "@mapmycustomers/shared/types/base/Cadenced";
import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";
import { FilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import {
  Condition,
  PlatformFilterCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { isCombinedCondition, isCombinedPlatformCondition } from "util/viewModel/assert";
import { getRegularFieldConditionValue } from "util/viewModel/convertFromPlatformFilterModel";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { getRegularFieldPlatformConditionValue } from "util/viewModel/convertToPlatformFilterModel";

export const messages = defineMessages({
  outSoon: {
    id: "frequencyStatusField.outSoon",
    defaultMessage: "Due in {days} {days, plural, one {day} other {days}}",
    description: "Frequency status field - out soon",
  },
  overdue: {
    id: "frequencyStatusField.overdue",
    defaultMessage: "{days} {days, plural, one {day} other {days}} past due",
    description: "Frequency status field - overdue",
  },
  overdueToday: {
    id: "frequencyStatusField.overdueToday",
    defaultMessage: "Due today",
    description: "Frequency status field - overdue Today",
  },
  upToDate: {
    id: "frequencyStatusField.daysOverdue",
    defaultMessage: "Up to date",
    description: "Frequency status field - Up to date",
  },
  pastDueDaysCount: {
    id: "frequencyIntervalField.pastDueDaysCount",
    defaultMessage: "{count} {count, plural, one {day} other {days}} past due",
    description: "Days count for Frequency Interval Field",
  },
});

class FrequencyStatusField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "frequencyStatus",
        filterInstances: { empty: EmptyFilter, frequencyStatus: FrequencyStatusFilter },
        operators: [
          ...FREQUENCY_STATUS_FILTER_OPERATORS,
          { instance: "empty", operator: FilterOperator.EMPTY },
        ],
      },
      customGridProperties: {
        cellRenderer: "frequencyCellRenderer",
      },
      exportColumnName: "cadenceStatus",
      features: [
        FieldFeature.FREQUENCY_STATUS_FIELD,
        FieldFeature.ENGAGEMENT,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.SORTABLE,
      ],
      type: FieldType.NUMBER,
      valueFormatter: (entity: unknown, value: unknown) => {
        const intl = i18nService.getIntl();
        if (!intl) {
          return String(value); // return value as is better than returning nothing
        }
        return (value as number) > 0
          ? intl.formatMessage(messages.overdue, { days: value as number })
          : intl.formatMessage(messages.outSoon, { days: -(value as number) });
      },
      ...data,
    });
  }

  getFormattedCsvValueFor(entity: unknown) {
    const status = (entity as Cadenced).cadenceStatus;
    const cadenceDaysOut = (entity as Cadenced).cadenceDaysOut ?? 0;
    return status === FrequencyStatus.UP_TO_DATE
      ? i18nService.getIntl()?.formatMessage({
          id: "frequencyStatusField.upToDate",
          defaultMessage: "Up to date",
          description: "Up to date for Frequency status Field",
        }) ?? "Up to date"
      : status === FrequencyStatus.UPCOMING
      ? i18nService.getIntl()?.formatMessage({
          id: "frequencyStatusField.upcoming",
          defaultMessage: "Upcoming",
          description: "Upcoming for Frequency status Field",
        }) ?? "Upcoming"
      : status === FrequencyStatus.PAST_DUE
      ? i18nService
          .getIntl()
          ?.formatMessage(messages.pastDueDaysCount, { count: cadenceDaysOut }) ??
        `${cadenceDaysOut} ${cadenceDaysOut > 1 ? " days " : "day"} past due`
      : "";
  }

  isPresentInPlatformCondition(condition: Record<string, unknown>): boolean {
    return this.platformFilterName in condition || "cadenceStatus" in condition;
  }

  convertToPlatformCondition(filterCondition: FilterCondition): PlatformFilterCondition {
    if (isCombinedCondition(filterCondition)) {
      return {}; // combined condition is not supported
    } else {
      if (filterCondition.operator === FilterOperator.IS_OVERDUE) {
        return {
          [this.platformFilterName]: { [PlatformFilterOperator.GREATER_THAN]: 0 },
        };
      } else if (filterCondition.operator === FilterOperator.IS_UPCOMING) {
        return {
          cadenceStatus: filterCondition.value ?? {
            [PlatformFilterOperator.CONTAINS]: [
              FrequencyStatus.UPCOMING,
              FrequencyStatus.UP_TO_DATE,
            ],
          },
        };
      }
    }
    return {
      [this.platformFilterName]: getRegularFieldPlatformConditionValue(this, filterCondition),
    };
  }

  convertFromPlatformCondition(
    filterCondition: PlatformFilterCondition
  ): FilterCondition | undefined {
    if (isCombinedPlatformCondition(filterCondition)) {
      return super.convertFromPlatformCondition(filterCondition);
    } else {
      if ("cadenceDaysOut" in filterCondition) {
        const cadenceDaysOut = filterCondition.cadenceDaysOut as Condition;
        if (PlatformFilterOperator.GREATER_THAN in cadenceDaysOut) {
          return cadenceDaysOut[PlatformFilterOperator.GREATER_THAN] === 0
            ? {
                operator: FilterOperator.IS_OVERDUE,
                value: undefined,
              }
            : {
                operator: FilterOperator.IS_OVERDUE_BY_MORE_THAN,
                value: cadenceDaysOut[PlatformFilterOperator.GREATER_THAN],
              };
        } else if (PlatformFilterOperator.GREATER_THAN_OR_EQUAL in cadenceDaysOut) {
          return {
            operator: FilterOperator.IS_OVERDUE_BY_MORE_THAN,
            value: (cadenceDaysOut[PlatformFilterOperator.GREATER_THAN_OR_EQUAL] as number) - 1,
          };
        }
      } else if ("cadenceStatus" in filterCondition) {
        if (filterCondition.cadenceStatus === FrequencyStatus.PAST_DUE) {
          return {
            operator: FilterOperator.IS_OVERDUE,
            value: undefined,
          };
        }
        if (
          [FrequencyStatus.UPCOMING, FrequencyStatus.UP_TO_DATE].includes(
            filterCondition.cadenceStatus as FrequencyStatus
          )
        ) {
          return {
            operator: FilterOperator.IS_UPCOMING,
            value: filterCondition.cadenceStatus,
          };
        }

        const cadenceStatus = filterCondition.cadenceStatus as Partial<
          Record<PlatformFilterOperator, FrequencyStatus[]>
        >;

        if (
          PlatformFilterOperator.CONTAINS in cadenceStatus &&
          Array.isArray(cadenceStatus[PlatformFilterOperator.CONTAINS]) &&
          cadenceStatus[PlatformFilterOperator.CONTAINS].length === 2
        ) {
          return {
            operator: FilterOperator.IS_UPCOMING,
            value: undefined,
          };
        }
      }
    }
    return getRegularFieldConditionValue(this, { [this.platformFilterName]: filterCondition });
  }
}

export default FrequencyStatusField;

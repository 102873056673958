import { defineMessages, IntlShape } from "react-intl";
import Color, { knownColors } from "@mapmycustomers/shared/enum/Color";
import AnyColor from "@mapmycustomers/shared/types/AnyColor";

const messages = defineMessages({
  [Color.BLACK]: {
    id: "color.black",
    defaultMessage: "black",
    description: "Black color",
  },
  [Color.BLUE]: {
    id: "color.blue",
    defaultMessage: "blue",
    description: "Blue color",
  },
  [Color.BROWN]: {
    id: "color.brown",
    defaultMessage: "brown",
    description: "Brown color",
  },
  [Color.CYAN]: {
    id: "color.cyan",
    defaultMessage: "cyan",
    description: "Cyan color",
  },
  [Color.GOLD]: {
    id: "color.gold",
    defaultMessage: "gold",
    description: "Gold color",
  },
  [Color.GREEN]: {
    id: "color.green",
    defaultMessage: "green",
    description: "Green color",
  },
  [Color.GREY]: {
    id: "color.grey",
    defaultMessage: "grey",
    description: "Grey color",
  },
  [Color.MAROON]: {
    id: "color.maroon",
    defaultMessage: "maroon",
    description: "Maroon color",
  },
  [Color.NAVY]: {
    id: "color.navy",
    defaultMessage: "navy",
    description: "Navy color",
  },
  [Color.NEON_BLUE]: {
    id: "color.neon_blue",
    defaultMessage: "neon blue",
    description: "Neon blue color",
  },
  [Color.NEON_GREEN]: {
    id: "color.neon_green",
    defaultMessage: "neon green",
    description: "Neon green color",
  },
  [Color.NEON_PURPLE]: {
    id: "color.neon_purple",
    defaultMessage: "neon purple",
    description: "Neon purple color",
  },
  [Color.NEON_RED]: {
    id: "color.neon_red",
    defaultMessage: "neon red",
    description: "Neon red color",
  },
  [Color.NEON_YELLOW]: {
    id: "color.neon_yellow",
    defaultMessage: "neon yellow",
    description: "Neon yellow color",
  },
  [Color.OLIVE]: {
    id: "color.olive",
    defaultMessage: "olive",
    description: "Olive color",
  },
  [Color.ORANGE]: {
    id: "color.orange",
    defaultMessage: "orange",
    description: "Orange color",
  },
  [Color.PEACH]: {
    id: "color.peach",
    defaultMessage: "peach",
    description: "Peach color",
  },
  [Color.PINK]: {
    id: "color.pink",
    defaultMessage: "pink",
    description: "Pink color",
  },
  [Color.PURPLE]: {
    id: "color.purple",
    defaultMessage: "purple",
    description: "Purple color",
  },
  [Color.RED]: {
    id: "color.red",
    defaultMessage: "red",
    description: "Red color",
  },
  [Color.TEAL]: {
    id: "color.teal",
    defaultMessage: "teal",
    description: "Teal color",
  },
  [Color.TURQUOISE]: {
    id: "color.turquoise",
    defaultMessage: "turquoise",
    description: "Turquoise color",
  },
  [Color.VIOLET]: {
    id: "color.violet",
    defaultMessage: "violet",
    description: "Violet color",
  },
  [Color.WHITE]: {
    id: "color.white",
    defaultMessage: "white",
    description: "White color",
  },
  [Color.YELLOW]: {
    id: "color.yellow",
    defaultMessage: "yellow",
    description: "Yellow color",
  },
});

const getColorDisplayName = (intl: IntlShape, color: AnyColor) =>
  knownColors.includes(color as Color) ? intl.formatMessage(messages[color as Color]) : color;

export default getColorDisplayName;

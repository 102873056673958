import { put, select, takeLatest } from "redux-saga/effects";
import { callApi } from "store/api/callApi";
import { getOrganization } from "store/iam";
import { fetchCompanyRoutes, fetchPeopleRoutes } from "./actions";
import { Route } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

export function* onFetchCompanyRoutes({ payload }: ReturnType<typeof fetchCompanyRoutes.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const routesResponse: ListResponse<Route> = yield callApi(
      "fetchCompanyRoutes",
      organization.id,
      {
        $limit: 100,
        $order: "name",
        $filters: {
          name: payload.query ? { $in: payload.query } : undefined,
        },
      }
    );

    yield put(fetchCompanyRoutes.success(routesResponse.data));
  } catch (error) {
    yield put(fetchCompanyRoutes.failure());
  }
}

export function* onFetchPeopleRoutes({ payload }: ReturnType<typeof fetchPeopleRoutes.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const routesResponse: ListResponse<Route> = yield callApi(
      "fetchPeopleRoutes",
      organization.id,
      {
        $limit: 100,
        $order: "name",
        $filters: {
          name: payload.query ? { $in: payload.query } : undefined,
        },
      }
    );

    yield put(fetchPeopleRoutes.success(routesResponse.data));
  } catch (error) {
    yield put(fetchPeopleRoutes.failure());
  }
}

export function* routesSaga() {
  yield takeLatest(fetchCompanyRoutes.request, onFetchCompanyRoutes);
  yield takeLatest(fetchPeopleRoutes.request, onFetchPeopleRoutes);
}

import React from "react";
import { Labeled } from "@mapmycustomers/ui";
import TextWithInfo from "component/typography/TextWithInfo";
import Switch from "antd/es/switch";
import { NumberField } from "@mapmycustomers/ui";
import cn from "classnames";
import { useIntl } from "react-intl";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import useAnalytics from "util/contexts/useAnalytics";
import styles from "./CadenceFields.module.scss";
import MultiSelectActivityTypeField from "component/input/MultiSelectActivityTypeField";

interface Props {
  cadence?: false | number;
  cadenceActivityTypeIds?: ActivityType["id"][];
  onCadenceActivityTypesChange?: (activityTypeIds: ActivityType["id"][]) => void;
  onCadenceChange?: (value: false | number) => void;
}

const CadenceFields: React.FC<Props> = ({
  cadence,
  cadenceActivityTypeIds,
  onCadenceActivityTypesChange,
  onCadenceChange,
}) => {
  const analytics = useAnalytics();
  const intl = useIntl();

  return (
    <>
      <Labeled
        label={
          <TextWithInfo
            info={intl.formatMessage({
              id: "createGroupModal.field.cadence.tooltip",
              defaultMessage:
                'By setting a frequency, records in this group will show as "Overdue" if activities are not done in the selected interval',
              description: "Cadence field tooltip in the Create Group modal",
            })}
          >
            {intl.formatMessage({
              id: "createGroupModal.field.cadenceSwitch",
              defaultMessage: "Set Frequency",
              description: "Cadence field switch label in the Create Group modal",
            })}
          </TextWithInfo>
        }
        labelPosition="side"
        sideLabelSpan={20}
      >
        <Switch
          checked={cadence !== false}
          onChange={(value) => {
            onCadenceChange?.(value && 1); // return 1 if true, but return false as is
            analytics.clicked([value ? " Assign cadence on" : " Assign cadence off"]);
          }}
        />
      </Labeled>

      <NumberField
        className={cn({ [styles.hidden]: cadence === false })}
        label={intl.formatMessage({
          id: "createGroupModal.field.cadence",
          defaultMessage: "Frequency Interval",
          description: "Cadence field label in the Create Group modal",
        })}
        max={500}
        onChange={(value) => onCadenceChange?.(value ?? 0)}
        value={(cadence as number) ?? undefined}
      />

      <MultiSelectActivityTypeField
        allowClear
        className={cn({ [styles.hidden]: !cadence })}
        dropdownClassName={styles.activityTypeDropdown}
        label={intl.formatMessage({
          id: "createGroupModal.field.activityTypes",
          defaultMessage: "Accepted activity types",
          description: "Activity types selector in the Create Group modal",
        })}
        onChange={onCadenceActivityTypesChange}
        placeholder={
          cadenceActivityTypeIds?.length
            ? undefined
            : intl.formatMessage({
                id: "createGroupModal.field.activityTypes.placeholder",
                defaultMessage: "All types",
                description: "Activity types placeholder in the Create Group modal",
              })
        }
        value={cadenceActivityTypeIds}
      />
    </>
  );
};

export default CadenceFields;

import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import FileUploader from "component/FileUploader";
import { clearAllUploadedPersonFiles, removePersonFile, uploadPersonFiles } from "../store/actions";
import { getUploadedFilesList, isFilesUploading } from "../store/selectors";

const mapStateToProps = (state: RootState) => ({
  fileUploading: isFilesUploading(state),
  uploadedFiles: getUploadedFilesList(state),
});

const mapDispatchToProps = {
  clearAllUploadedFiles: clearAllUploadedPersonFiles,
  onRemoveFile: removePersonFile.request,
  onFileUpload: uploadPersonFiles.request,
};

const File = connect(mapStateToProps, mapDispatchToProps)(FileUploader);
export default File;

import { ActionType, createAsyncAction } from "typesafe-actions";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import LayoutMap from "store/layout/LayoutMap";

export const fetchAllLayouts = createAsyncAction(
  "@layout/fetchAllLayouts/request",
  "@layout/fetchAllLayouts/success",
  "@layout/fetchAllLayouts/failure"
)<void, LayoutMap, unknown>();

export const fetchLayoutsForEntityType = createAsyncAction(
  "@layout/fetchLayoutsForEntityType/request",
  "@layout/fetchLayoutsForEntityType/success",
  "@layout/fetchLayoutsForEntityType/failure"
)<
  EntityTypesSupportingFieldCustomization,
  { entityType: EntityTypesSupportingFieldCustomization; layouts: FormLayout[] },
  unknown
>();

export type Actions = ActionType<typeof fetchAllLayouts | typeof fetchLayoutsForEntityType>;

import FieldModel from "util/fieldModel/impl/FieldModel";
import { defineMessages } from "react-intl";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import DateField from "util/fieldModel/impl/DateField";
import UserField from "util/fieldModel/impl/UserField";
import HistoryField from "./impl/HistoryField";
import HistorySource from "./impl/HistorySource";

export enum EntityHistoryFieldName {
  FIELD = "field",
  DATE = "updatedAt",
  SOURCE = "source",
  USER = "user",
}

const displayNames = defineMessages<EntityHistoryFieldName>({
  [EntityHistoryFieldName.FIELD]: {
    id: "entityHistory.field.field",
    defaultMessage: "Field",
    description: "Field field in entity history field model",
  },
  [EntityHistoryFieldName.DATE]: {
    id: "entityHistory.field.date",
    defaultMessage: "Date",
    description: "Date field in entity history field model",
  },
  [EntityHistoryFieldName.SOURCE]: {
    id: "entityHistory.field.source",
    defaultMessage: "Source",
    description: "Source field in entity history field model",
  },
  [EntityHistoryFieldName.USER]: {
    id: "entityHistory.field.user",
    defaultMessage: "User",
    description: "User field in entity history field model",
  },
});

const entityHistoryFieldModel = new FieldModel([
  new HistoryField({
    name: EntityHistoryFieldName.FIELD,
    displayName: displayNames[EntityHistoryFieldName.FIELD],
    displayOrder: 1,
  }),
  new DateField({
    name: EntityHistoryFieldName.DATE,
    displayName: displayNames[EntityHistoryFieldName.DATE],
    features: [FieldFeature.FILTERABLE],
    displayOrder: 2,
  }),
  new HistorySource({
    name: EntityHistoryFieldName.SOURCE,
    displayName: displayNames[EntityHistoryFieldName.SOURCE],
    displayOrder: 3,
  }),
  new UserField({
    name: EntityHistoryFieldName.USER,
    displayName: displayNames[EntityHistoryFieldName.USER],
    platformFilterName: "updatedBy.id",
    features: [FieldFeature.FILTERABLE, FieldFeature.SUPPORTS_SET_FILTER, FieldFeature.OWNER_FIELD],
    displayOrder: 4,
  }),
]);

export default entityHistoryFieldModel;

import Integration from "types/integrations/Integration";
import IntegrationRaw from "@mapmycustomers/shared/types/integrations/IntegrationRaw";
import { parseApiDate } from "util/parsers";

const parseIntegrationRecord = (apiRow: IntegrationRaw): Integration => ({
  ...apiRow,
  updatedAt: parseApiDate(apiRow.updatedAt),
  schedule: apiRow.schedule
    ? { ...apiRow.schedule, nextRunAt: parseApiDate(apiRow.schedule.nextRunAt) }
    : null,
});

export default parseIntegrationRecord;

import FieldModel from "./impl/FieldModel";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { createField } from "./impl/fieldModelUtil";
import { defineMessages } from "react-intl";
import IntegrationSync, { IntegrationSyncMetadata } from "types/integrations/IntegrationSync";
import getIntegrationActionValue from "./getIntegrationActionValue";

export enum IntegrationFieldName {
  DATE = "createdAt",
  STATUS = "status",
  EXPORT = "export",
  NEW_RECORDS_INCOMING = "newRecordsIncoming",
  NEW_RECORDS_OUTGOING = "newRecordsOutgoing",
  UPDATED_RECORDS_INCOMING = "updatedRecordsIncoming",
  UPDATED_RECORDS_OUTGOING = "updatedRecordsOutgoing",
  SKIPPED_RECORDS_INCOMING = "skippedRecordsIncoming",
  SKIPPED_RECORDS_OUTGOING = "skippedRecordsOutgoing",
  ERRORED_RECORDS_INCOMING = "erroredRecordsIncoming",
  ERRORED_RECORDS_OUTGOING = "erroredRecordsOutgoing",
}

const displayNames = defineMessages<IntegrationFieldName>({
  [IntegrationFieldName.DATE]: {
    id: "integration.field.name",
    defaultMessage: "Date",
    description: "date field on Integration",
  },
  [IntegrationFieldName.STATUS]: {
    id: "integration.field.status",
    defaultMessage: "Status",
    description: "Status field on Integration",
  },
  [IntegrationFieldName.EXPORT]: {
    id: "integration.field.export",
    defaultMessage: "Export",
    description: "Export field on Integration",
  },
  [IntegrationFieldName.NEW_RECORDS_INCOMING]: {
    id: "integration.field.newRecordsIncoming",
    defaultMessage: "New Records - Incoming",
    description: "New Records Incoming field on Integration",
  },
  [IntegrationFieldName.NEW_RECORDS_OUTGOING]: {
    id: "integration.field.newRecordOutgoing",
    defaultMessage: "New Records - Outgoing",
    description: "New Records Incoming field on Integration",
  },
  [IntegrationFieldName.UPDATED_RECORDS_INCOMING]: {
    id: "integration.field.updatedRecordsIncoming",
    defaultMessage: "Updated Records - Incoming",
    description: "Updated Records Incoming field on Integration",
  },
  [IntegrationFieldName.UPDATED_RECORDS_OUTGOING]: {
    id: "integration.field.updatedRecordsOutgoing",
    defaultMessage: "Updated records-Outgoing",
    description: "Updated Records Outgoing field on Integration",
  },
  [IntegrationFieldName.SKIPPED_RECORDS_INCOMING]: {
    id: "integration.field.skippedRecordsIncoming",
    defaultMessage: "Skipped Records - Incoming",
    description: "Skipped Records Incoming field on Integration",
  },
  [IntegrationFieldName.SKIPPED_RECORDS_OUTGOING]: {
    id: "integration.field.skippedRecordsOutgoing",
    defaultMessage: "Skipped Records-Outgoing",
    description: "Skipped Records Outgoing field on Integration",
  },
  [IntegrationFieldName.ERRORED_RECORDS_INCOMING]: {
    id: "integration.field.erroredRecordsIncoming",
    defaultMessage: "Errored Records - Incoming",
    description: "Errored Records Incoming field on Integration",
  },
  [IntegrationFieldName.ERRORED_RECORDS_OUTGOING]: {
    id: "integration.field.erroredRecordsOutgoing1",
    defaultMessage: "Errored Records - Outgoing",
    description: "Errored Records Outgoing field on Integration",
  },
});

const integrationFieldModel = new FieldModel(
  [
    {
      name: IntegrationFieldName.DATE,
      displayName: displayNames[IntegrationFieldName.DATE],
      displayOrder: 1,
      type: FieldType.STRING,
      features: [FieldFeature.ALWAYS_VISIBLE, FieldFeature.VISIBLE_BY_DEFAULT],
    },
    {
      name: IntegrationFieldName.STATUS,
      displayName: displayNames[IntegrationFieldName.STATUS],
      displayOrder: 2,
      features: [FieldFeature.ALWAYS_VISIBLE, FieldFeature.VISIBLE_BY_DEFAULT],
      type: FieldType.STRING,
    },
    {
      name: IntegrationFieldName.EXPORT,
      displayName: displayNames[IntegrationFieldName.EXPORT],
      displayOrder: 3,
      features: [FieldFeature.ALWAYS_VISIBLE, FieldFeature.VISIBLE_BY_DEFAULT],
      type: FieldType.STRING,
    },
    {
      name: IntegrationFieldName.NEW_RECORDS_INCOMING,
      displayName: displayNames[IntegrationFieldName.NEW_RECORDS_INCOMING],
      displayOrder: 4,
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT, FieldFeature.INCOMING],
      type: FieldType.NUMBER,
      valueGetter: (record: unknown) =>
        getIntegrationActionValue(
          (record as IntegrationSync).metadata.incoming as IntegrationSyncMetadata,
          "created"
        ),
      platformFilterName: "created.incoming",
    },
    {
      name: IntegrationFieldName.NEW_RECORDS_OUTGOING,
      displayName: displayNames[IntegrationFieldName.NEW_RECORDS_OUTGOING],
      displayOrder: 5,
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT, FieldFeature.OUTGOING],
      type: FieldType.NUMBER,
      valueGetter: (record: unknown) =>
        getIntegrationActionValue(
          (record as IntegrationSync).metadata.outgoing as IntegrationSyncMetadata,
          "created"
        ),
      platformFilterName: "created.outgoing",
    },
    {
      name: IntegrationFieldName.UPDATED_RECORDS_INCOMING,
      displayName: displayNames[IntegrationFieldName.UPDATED_RECORDS_INCOMING],
      displayOrder: 6,
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT, FieldFeature.INCOMING],
      type: FieldType.NUMBER,
      valueGetter: (record: unknown) =>
        getIntegrationActionValue(
          (record as IntegrationSync).metadata.incoming as IntegrationSyncMetadata,
          "updated"
        ),
      platformFilterName: "updated.incoming",
    },
    {
      name: IntegrationFieldName.UPDATED_RECORDS_OUTGOING,
      displayName: displayNames[IntegrationFieldName.UPDATED_RECORDS_OUTGOING],
      displayOrder: 7,
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT, FieldFeature.OUTGOING],
      type: FieldType.NUMBER,
      valueGetter: (record: unknown) =>
        getIntegrationActionValue(
          (record as IntegrationSync).metadata.outgoing as IntegrationSyncMetadata,
          "updated"
        ),
      platformFilterName: "updated.outgoing",
    },
    {
      name: IntegrationFieldName.SKIPPED_RECORDS_INCOMING,
      displayName: displayNames[IntegrationFieldName.SKIPPED_RECORDS_INCOMING],
      displayOrder: 8,
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT, FieldFeature.INCOMING],
      type: FieldType.NUMBER,
      valueGetter: (record: unknown) =>
        getIntegrationActionValue(
          (record as IntegrationSync).metadata.incoming as IntegrationSyncMetadata,
          "skipped"
        ),
      platformFilterName: "skipped.incoming",
    },
    {
      name: IntegrationFieldName.SKIPPED_RECORDS_OUTGOING,
      displayName: displayNames[IntegrationFieldName.SKIPPED_RECORDS_OUTGOING],
      displayOrder: 9,
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT, FieldFeature.OUTGOING],
      type: FieldType.NUMBER,
      valueGetter: (record: unknown) =>
        getIntegrationActionValue(
          (record as IntegrationSync).metadata.outgoing as IntegrationSyncMetadata,
          "skipped"
        ),
      platformFilterName: "skipped.outgoing",
    },
    {
      name: IntegrationFieldName.ERRORED_RECORDS_INCOMING,
      displayName: displayNames[IntegrationFieldName.ERRORED_RECORDS_INCOMING],
      displayOrder: 10,
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT, FieldFeature.INCOMING],
      type: FieldType.NUMBER,
      valueGetter: (record: unknown) =>
        getIntegrationActionValue(
          (record as IntegrationSync).metadata.incoming as IntegrationSyncMetadata,
          "errored"
        ),
      platformFilterName: "errored.incoming",
    },
    {
      name: IntegrationFieldName.ERRORED_RECORDS_OUTGOING,
      displayName: displayNames[IntegrationFieldName.ERRORED_RECORDS_OUTGOING],
      displayOrder: 11,
      features: [FieldFeature.METRIC, FieldFeature.VISIBLE_BY_DEFAULT, FieldFeature.OUTGOING],
      type: FieldType.NUMBER,
      valueGetter: (record: unknown) =>
        getIntegrationActionValue(
          (record as IntegrationSync).metadata.outgoing as IntegrationSyncMetadata,
          "errored"
        ),
      platformFilterName: "errored.outgoing",
    },
  ].map(createField)
);

export default integrationFieldModel;

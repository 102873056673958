import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";
import User, { UserRef } from "@mapmycustomers/shared/types/User";
import { SELECTABLE_USERS_COLOR_PREFIX, SELECTABLE_USERS_COLORS_COUNT } from "./colors";
import { DEFAULT_AVATAR_COLORS } from "util/colors/const";

export type UserColorClassNameGetter = (user: UserRef) => string | undefined;

const userIdToColorIndex = new Map<User["id"], number>();

const userColorState = (state: RootState) => state.userColor;

export const getUserColorSelectedUserIds = createSelector(
  userColorState,
  ({ selectedUserIds }) => selectedUserIds
);

export const getUserColorClassNameGetter = createSelector(
  userColorState,
  ({ selectedUserIds }) =>
    (user: UserRef): string | undefined => {
      if (user.deactivated || user.deletedAt) {
        return "grey";
      }

      const selectedUserIndex = selectedUserIds.indexOf(user.id);
      if (selectedUserIndex >= 0) {
        return `${SELECTABLE_USERS_COLOR_PREFIX}${
          selectedUserIndex % SELECTABLE_USERS_COLORS_COUNT
        }`;
      }

      if (!userIdToColorIndex.has(user.id)) {
        userIdToColorIndex.set(
          user.id,
          Math.round(Math.random() * (DEFAULT_AVATAR_COLORS.length - 1))
        );
      }
      return DEFAULT_AVATAR_COLORS[userIdToColorIndex.get(user.id)!];
    }
);

import { RootState } from "../rootReducer";
import { createSelector } from "reselect";

const nylasState = (state: RootState) => state.nylas;
export const isInitializing = createSelector(nylasState, ({ initializing }) => initializing);
export const getNylasInfo = createSelector(nylasState, ({ nylasInfo }) => nylasInfo);
export const getEmailNylasInfo = createSelector(nylasState, ({ emailNylasInfo }) => emailNylasInfo);

export const getCalendars = createSelector(nylasState, ({ calendars }) => calendars);
export const areCalendarsLoading = createSelector(
  nylasState,
  ({ calendarsLoading }) => calendarsLoading
);

export const isCreatingCalendarEventForDealClosingDate = createSelector(
  nylasState,
  ({ createCalendarEventForDealClosingDateLoading }) => createCalendarEventForDealClosingDateLoading
);

export const isNylasCreating = createSelector(nylasState, ({ creating }) => creating);

export const isNylasUpdating = createSelector(nylasState, ({ updating }) => updating);

export const isNylasDeleting = createSelector(nylasState, ({ deleting }) => deleting);

export const isSetupFinished = createSelector(
  nylasState,
  ({ nylasInfo }) => !!nylasInfo?.calendarId
);

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { UserRecentPayload } from "../../types/userRecent/UserRecent";
import { EntityType } from "@mapmycustomers/shared/types/entity";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import GlobalSearchItem from "../../types/globalSearch/GlobalSearchItem";

export const showGlobalSearch = createAction("@globalSearch/showGlobalSearch")<void>();

export const hideGlobalSearch = createAction("@globalSearch/hideGlobalSearch")<void>();

export const fetchUserRecents = createAsyncAction(
  "@globalSearch/fetchUserRecents/request",
  "@globalSearch/fetchUserRecents/success",
  "@globalSearch/fetchUserRecents/failure"
)<void, GlobalSearchItem[], unknown>();

export const addUserRecent = createAction("@globalSearch/addUserRecent")<UserRecentPayload>();

export const clickOnGlobalSearchItem = createAction("@globalSearch/clickOnGlobalSearchItem")<{
  entityId: Identified["id"];
  entityType: EntityType;
}>();

export const performSearch = createAsyncAction(
  "@globalSearch/performSearch/request",
  "@globalSearch/performSearch/success",
  "@globalSearch/performSearch/failure"
)<{ query: string; entityTypes?: EntityType[] }, GlobalSearchItem[], unknown>();

export type Actions = ActionType<
  | typeof addUserRecent
  | typeof clickOnGlobalSearchItem
  | typeof fetchUserRecents
  | typeof hideGlobalSearch
  | typeof performSearch
  | typeof showGlobalSearch
>;

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { FetcherPayload } from "types/viewModel/Fetcher";
import { Activity, MapEntity } from "@mapmycustomers/shared/types/entity";
import CalendarViewState from "component/view/CalendarView/types/CalendarViewState";
import ActivitySuggestion from "@mapmycustomers/shared/types/entity/ActivitySuggestion";
import GeoPoint from "@mapmycustomers/shared/types/shapes/GeoPoint";
import { OutOfCadenceEntity } from "@mapmycustomers/shared/types/entity/activities/OutOfCadenceEntity";
import MapEntityWithActivity from "../type/MapEntityWithActivity";

export const initializeCalendarView = createAsyncAction(
  "activitiesCalendar/initialize/request",
  "activitiesCalendar/initialize/success",
  "activitiesCalendar/initialize/failure"
)<void, void, void>();

export const fetchCalendarActivities = createAsyncAction(
  "activitiesCalendar/fetch/request",
  "activitiesCalendar/fetch/success",
  "activitiesCalendar/fetch/failure"
)<
  FetcherPayload<Activity>,
  {
    activities: Activity[];
    mapEntities: MapEntityWithActivity[];
    suggestions: ActivitySuggestion[];
    totalFilteredRecords: number;
    totalRecords: number;
  },
  void
>();

export const applyCalendarViewSettings = createAction("activitiesCalendar/applyViewSettings")<
  Partial<CalendarViewState>
>();

export const updateActivityInList = createAction(
  "activitiesCalendar/updateActivityInList"
)<Activity>();

export const postponeActivity = createAsyncAction(
  "activitiesCalendar/postpone/request",
  "activitiesCalendar/postpone/success",
  "activitiesCalendar/postpone/failure"
)<Activity, Activity, void>();

export const moveResizeActivity = createAsyncAction(
  "activitiesCalendar/moveResizeActivity/request",
  "activitiesCalendar/moveResizeActivity/success",
  "activitiesCalendar/moveResizeActivity/failure"
)<{ activity: Activity; failCallback?: (activity: Activity) => void }, Activity, void>();

export const fetchNearbyEntities = createAsyncAction(
  "activitiesCalendar/fetchNearbyEntities/request",
  "activitiesCalendar/fetchNearbyEntities/success",
  "activitiesCalendar/fetchNearbyEntities/failure"
)<{ geoPoint: GeoPoint; distance: number }, MapEntity[], void>();

export const fetchOutOfCadenceEntities = createAsyncAction(
  "activitiesCalendar/fetchOutOfCadenceEntities/request",
  "activitiesCalendar/fetchOutOfCadenceEntities/success",
  "activitiesCalendar/fetchOutOfCadenceEntities/failure"
)<{ startAt: string }, OutOfCadenceEntity[], void>();

export const findActivities = createAsyncAction(
  "activitiesCalendar/findActivities/request",
  "activitiesCalendar/findActivities/success",
  "activitiesCalendar/findActivities/failure"
)<{ query: string }, Activity[], void>();

export const goToActivity = createAction("activitiesCalendar/goToActivity")<{
  activity: Activity;
  callback?: () => void;
}>();

export const exportCalendarActivities = createAction("activitiesCalendar/exportActivities")<{
  viewState: CalendarViewState;
}>();

export type Actions = ActionType<
  | typeof applyCalendarViewSettings
  | typeof exportCalendarActivities
  | typeof fetchCalendarActivities
  | typeof fetchNearbyEntities
  | typeof fetchOutOfCadenceEntities
  | typeof findActivities
  | typeof goToActivity
  | typeof initializeCalendarView
  | typeof moveResizeActivity
  | typeof postponeActivity
  | typeof updateActivityInList
>;

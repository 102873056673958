import React from "react";
import Row from "antd/es/row";
import { useIntl } from "react-intl";
import Col from "antd/es/col";
import arrows from "assets/globalSearch/arrows.svg";
import enterIconSolid from "assets/globalSearch/enterIconSolid.svg";
import styles from "./BottomInfo.module.scss";
import layout from "../../../../styles/layout";

interface Props {}

const BottomInfo: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <Row className={styles.container} gutter={layout.spacerS}>
      <Col>
        <img alt="arrows" src={arrows} />
      </Col>
      <Col className={styles.text}>
        {intl.formatMessage({
          id: "component.globalSearch.bottom.select",
          defaultMessage: "Select",
          description: "select text on global search modal",
        })}
      </Col>
      <Col>
        <img alt="enter icon" src={enterIconSolid} />
      </Col>
      <Col className={styles.text}>
        {intl.formatMessage({
          id: "component.globalSearch.bottom.open",
          defaultMessage: "Open",
          description: "open text on global search modal",
        })}
      </Col>
    </Row>
  );
};

export default BottomInfo;

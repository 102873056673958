import GroupsModal from "component/actions/components/GroupsModal";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  Company,
  Deal,
  EntityTypeSupportingPostCreationModal,
  Person,
} from "@mapmycustomers/shared/types/entity";

interface Props {
  entity: Company | Person | Deal;
  entityType?: EntityTypeSupportingPostCreationModal;
  onHide: (updated?: boolean) => void;
  onUpdate?: () => void;
}

const messages = defineMessages({
  manageGroups: {
    id: "postCreateModal.addToGroupModal.title",
    defaultMessage: "Add to groups",
    description: "Label of the Manage Groups action",
  },
});

const Groups: React.FC<Props> = ({ entity, entityType, onHide, onUpdate }) => {
  const intl = useIntl();

  return (
    <GroupsModal
      entity={entity}
      entityType={entityType!}
      onHide={onHide}
      onReload={onUpdate}
      title={intl.formatMessage(messages.manageGroups)}
    />
  );
};

export default Groups;

import { ActionType, createAction } from "typesafe-actions";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";

export const openOnMapPage = createAction("map/openOnMapPage")<{
  mapViewState: Partial<MapViewState>;
  openOnNewTab?: boolean;
  viewport?: Partial<{ center: LongLat; zoom: number }>;
}>();

export type Actions = ActionType<typeof openOnMapPage>;

import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import styles from "./OverlayView.module.scss";

const MAP_CONTROLS_WIDTH = 80;
const OVERLAY_WIDTH_ADJUST = 50;
const OVERLAY_HEIGHT_ADJUST = 25;

type OverlayProps = PropsWithChildren<{
  arrowClassName?: string;
  borderClassName?: string;
  map: google.maps.Map;
  onClickOutside?: () => void;
  offset?: { x: number; y: number };
  pane?: keyof google.maps.MapPanes;
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  smartPosition?: boolean;
  zIndex?: number;
}>;

const createOverlay = (
  container: HTMLElement,
  map: google.maps.Map,
  pane: keyof google.maps.MapPanes,
  position: google.maps.LatLng | google.maps.LatLngLiteral,
  options?: {
    smartPosition?: boolean;
    arrowClassName?: string;
    borderClassName?: string;
    offset?: { x: number; y: number };
  }
) => {
  class Overlay extends google.maps.OverlayView {
    container: HTMLElement;
    pane: keyof google.maps.MapPanes;
    position: google.maps.LatLng | google.maps.LatLngLiteral;
    constructor(
      container: HTMLElement,
      pane: keyof google.maps.MapPanes,
      position: google.maps.LatLng | google.maps.LatLngLiteral
    ) {
      super();
      this.container = container;
      this.pane = pane;
      this.position = position;
    }

    onAdd(): void {
      const pane = this.getPanes()?.[this.pane];
      pane?.appendChild(this.container);
    }

    draw(): void {
      const projection = this.getProjection();
      if (!this.position || !projection) {
        return;
      }
      const pinPoint = projection.fromLatLngToDivPixel(this.position);
      if (pinPoint === null) {
        return;
      }
      const overlayWidth = this.container.offsetWidth;
      const overlayHeight = this.container.offsetHeight;

      if (
        overlayWidth > 0 &&
        options?.smartPosition &&
        options?.arrowClassName &&
        options?.borderClassName
      ) {
        const arrow = container.querySelector(`[class='${options?.arrowClassName}']`);
        const border = container.querySelector(`[class='${options?.borderClassName}']`);

        const mapContainer = map.getDiv();
        const mapWidth = mapContainer.offsetWidth / 2;
        const mapHeight = mapContainer.offsetHeight / 2;

        let offsetX = 0;
        let offsetY = 0;

        if (pinPoint.x + overlayWidth > mapWidth - MAP_CONTROLS_WIDTH) {
          offsetX = -overlayWidth - OVERLAY_WIDTH_ADJUST;
          arrow?.classList.add(styles.rightArrow);
          border?.classList.add(styles.rightBorder);
        } else {
          offsetX = 0;
          arrow?.classList.add(styles.leftArrow);
          border?.classList.add(styles.leftBorder);
        }

        if (pinPoint.y + overlayHeight > mapHeight) {
          offsetY = -overlayHeight + OVERLAY_HEIGHT_ADJUST;
          arrow?.classList.add(styles.bottomArrow);
        } else {
          offsetY = 0;
          arrow?.classList.add(styles.topArrow);
        }
        this.container.style.transform = `translate(${pinPoint.x + offsetX}px, ${
          pinPoint.y + offsetY
        }px)`;
      } else {
        this.container.style.transform = `translate(${pinPoint.x + (options?.offset?.x ?? 0)}px, ${
          pinPoint.y + (options?.offset?.y ?? 0)
        }px)`;
      }
    }

    setPosition(position: google.maps.LatLng | google.maps.LatLngLiteral) {
      this.position = position;
      this.draw();
    }

    onRemove(): void {
      if (this.container.parentNode !== null) {
        this.container.parentNode.removeChild(this.container);
      }
    }
  }

  return new Overlay(container, pane, position);
};

export default function OverlayView({
  arrowClassName,
  borderClassName,
  map,
  onClickOutside,
  pane = "floatPane",
  position,
  zIndex,
  children,
  offset,
  smartPosition = false,
}: OverlayProps) {
  const container = useMemo(() => {
    const div = document.createElement("div");
    div.style.position = "absolute";
    div.style.pointerEvents = "auto";
    return div;
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (onClickOutside && container && !container.contains(event.target as HTMLElement)) {
        // If we have modal or dropdown opened in front of overlay - do not consider those clicks
        // as outside clicks, rather direct clicks for default handling by modal/dropdown itself
        const isGlobalTarget =
          !!event.target.closest(".ant-modal-root") ||
          !!event.target.closest(".ant-popover") ||
          !!event.target.closest(".ant-select-dropdown");
        if (!isGlobalTarget) {
          event.preventDefault();
          event.stopPropagation();
          onClickOutside();
        }
      }
    },
    [container, onClickOutside]
  );

  const overlay = useMemo(
    () =>
      createOverlay(container, map, pane, position, {
        smartPosition,
        arrowClassName,
        borderClassName,
        offset,
      }),
    // Do not re-create overlay on position changes, we have another useEffect to handle that
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [container, pane]
  );

  useEffect(() => {
    overlay?.setMap(map);
    return () => overlay?.setMap(null);
  }, [map, overlay]);

  useEffect(() => overlay?.setPosition(position), [overlay, position]);

  useEffect(() => {
    container.style.zIndex = `${zIndex}`;
    google.maps.OverlayView.preventMapHitsAndGesturesFrom(container);

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [container, handleClickOutside, zIndex]);

  return createPortal(children, container);
}

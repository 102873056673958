import React, { useCallback, useMemo } from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import defaultFilterOption from "component/input/utils/defaultFilterOption";
import Select, { RefSelectProps } from "antd/es/select";
import cn from "classnames";
import { useIntl } from "react-intl";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import getHumanReadableDescriptionForOptionsFilter, {
  OptionFilterOperator,
} from "./getHumanReadableDescriptionForOptionsFilter";
import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import OptionValue from "@mapmycustomers/shared/types/customField/OptionValue";
import styles from "./OptionFilter.module.scss";

export const SINGLE_OPTION_FILTER_OPERATORS = [FilterOperator.IN_ANY];
export const MULTI_OPTION_FILTER_OPERATORS = [FilterOperator.IN_ANY]; // not having IN_ALL since it's not yet supported by Platform

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every((item) => typeof item === "number") &&
  SINGLE_OPTION_FILTER_OPERATORS.includes(operator);

interface OptionFilterProps extends IFilterComponentProps {}

const OptionFilter: IFilterInstance = {
  doesSupportValue,
  doesSupportOption: (option: FilterOption) => option === FilterOption.OPTIONS_LIST,
  getHumanReadableDescription: (value: SimpleCondition, field: IField, options) => {
    if (!doesSupportValue(value.value, value.operator)) {
      return undefined;
    }

    const possibleOptions = new Map(
      ((options?.optionsList ?? []) as OptionValue[]).map(({ value, displayName }) => [
        value,
        displayName,
      ])
    );
    const selectedValues = value.value as OptionValue["value"][];
    return getHumanReadableDescriptionForOptionsFilter(
      field,
      value.operator as OptionFilterOperator,
      selectedValues.map((value) => possibleOptions.get(value) ?? String(value))
    );
  },
  getComponent:
    (): React.FC<OptionFilterProps> =>
    ({ className, focus, onChange, options, value }) => {
      const intl = useIntl();

      const selectBoxOptions = useMemo(
        () =>
          ((options?.optionsList ?? []) as OptionValue[]).map(({ value, displayName }) => ({
            value,
            label: displayName,
          })),
        [options?.optionsList]
      );

      const setRef = useCallback(
        (ref: RefSelectProps | null) => {
          if (focus && ref) {
            ref.focus();
          }
        },
        [focus]
      );

      return (
        <Select<Array<OptionValue["value"]>>
          className={cn(styles.container, className)}
          filterOption={defaultFilterOption}
          mode="multiple"
          onChange={(optionValues: OptionValue["value"][]) =>
            onChange?.({ ...value, value: optionValues })
          }
          options={selectBoxOptions}
          placeholder={intl.formatMessage({
            id: "filters.optionFilter.select.placeholder",
            defaultMessage: "Click or type to select options",
            description: "Placeholder displayed in a custom field's OptionFilter's select field",
          })}
          ref={setRef}
          value={Array.isArray(value.value) ? value.value : []}
        />
      );
    },
};

export default OptionFilter;

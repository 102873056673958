import {
  Actions,
  setRecordsPreviewConfiguration,
  updateRecordsPreviewConfiguration,
} from "./actions";
import { createReducer } from "typesafe-actions";
import RecordPreviewConfiguration from "./RecordPreviewConfiguration";
import { EntityType } from "@mapmycustomers/shared/types/entity";
import companyFieldModel from "../../util/fieldModel/CompanyFieldModel";
import personFieldModel from "../../util/fieldModel/PersonFieldModel";
import dealFieldModel from "../../util/fieldModel/DealFieldModel";
import { isDisplayFieldOnRecordPreviewByDefault } from "../../util/fieldAssertions";
import { Origin } from "enum/Inheritance";

export interface RecordPreviewState {
  configuration: RecordPreviewConfiguration;
}

const initialState: RecordPreviewState = {
  configuration: {
    [Origin.ORGANIZATION]: {
      actions: {
        [EntityType.COMPANY]: true,
        [EntityType.PERSON]: true,
        [EntityType.DEAL]: true,
      },
      engagement: {
        [EntityType.COMPANY]: true,
        [EntityType.PERSON]: true,
        [EntityType.DEAL]: true,
      },
      [EntityType.COMPANY]: companyFieldModel.sortedFields.filter(
        isDisplayFieldOnRecordPreviewByDefault
      ),
      [EntityType.PERSON]: personFieldModel.sortedFields.filter(
        isDisplayFieldOnRecordPreviewByDefault
      ),
      [EntityType.DEAL]: dealFieldModel.sortedFields.filter(isDisplayFieldOnRecordPreviewByDefault),
    },
    [Origin.TEAM]: {
      actions: {
        [EntityType.COMPANY]: true,
        [EntityType.PERSON]: true,
        [EntityType.DEAL]: true,
      },
      engagement: {
        [EntityType.COMPANY]: true,
        [EntityType.PERSON]: true,
        [EntityType.DEAL]: true,
      },
      [EntityType.COMPANY]: [],
      [EntityType.PERSON]: [],
      [EntityType.DEAL]: [],
    },
    [Origin.PRIVATE]: {
      actions: {
        [EntityType.COMPANY]: true,
        [EntityType.PERSON]: true,
        [EntityType.DEAL]: true,
      },
      engagement: {
        [EntityType.COMPANY]: true,
        [EntityType.PERSON]: true,
        [EntityType.DEAL]: true,
      },
      [EntityType.COMPANY]: [],
      [EntityType.PERSON]: [],
      [EntityType.DEAL]: [],
    },
    loading: false,
    teamId: undefined,
  },
};

const recordPreview = createReducer<RecordPreviewState, Actions>(initialState)
  .handleAction(updateRecordsPreviewConfiguration.request, (state) => ({
    ...state,
    configuration: {
      ...state.configuration,
      loading: true,
    },
  }))
  .handleAction(updateRecordsPreviewConfiguration.success, (state) => ({
    ...state,
    configuration: {
      ...state.configuration,
      loading: false,
    },
  }))
  .handleAction(setRecordsPreviewConfiguration, (state, { payload }) => ({
    ...state,
    configuration: {
      ...state.configuration,
      [Origin.ORGANIZATION]: {
        ...(state.configuration[Origin.ORGANIZATION] ??
          initialState.configuration[Origin.ORGANIZATION]),
        ...payload[Origin.ORGANIZATION],
      },
      [Origin.TEAM]: {
        ...(state.configuration[Origin.TEAM] ?? initialState.configuration[Origin.TEAM]),
        ...payload[Origin.TEAM],
      },
      [Origin.PRIVATE]: {
        ...(state.configuration[Origin.PRIVATE] ?? initialState.configuration[Origin.PRIVATE]),
        ...payload[Origin.PRIVATE],
      },
      teamId: payload.teamId ?? state.configuration.teamId ?? initialState.configuration.teamId,
    },
  }))
  .handleAction(updateRecordsPreviewConfiguration.failure, (state) => ({
    ...state,
    configuration: {
      ...state.configuration,
      loading: false,
    },
  }));

export type RecordPreviewActions = Actions;
export default recordPreview;

import { ActionType, createAsyncAction } from "typesafe-actions";
import type Calendar from "nylas/lib/models/calendar";
import NylasInfo, { NylasInfoCreatePayload, NylasInfoUpdatePayload } from "types/nylas/NylasInfo";
import { Deal } from "@mapmycustomers/shared/types/entity";

export const initializeNylas = createAsyncAction(
  "@nylas/initialize/request",
  "@nylas/initialize/success",
  "@nylas/initialize/failure"
)<void, { info: NylasInfo | undefined }, void>();

export const createNylas = createAsyncAction(
  "@nylas/createNylas/request",
  "@nylas/createNylas/success",
  "@nylas/createNylas/failure"
)<{ nylas: NylasInfoCreatePayload; callback?: (nylas: NylasInfo) => void }, void, void>();

export const updateNylas = createAsyncAction(
  "@nylas/updateNylas/request",
  "@nylas/updateNylas/success",
  "@nylas/updateNylas/failure"
)<{ nylas: NylasInfoUpdatePayload; callback?: (nylas: NylasInfo) => void }, NylasInfo, void>();

export const deleteNylas = createAsyncAction(
  "@nylas/deleteNylas/request",
  "@nylas/deleteNylas/success",
  "@nylas/deleteNylas/failure"
)<{ nylasId: NylasInfo["id"]; callback?: () => void }, void, void>();

export const fetchCalendars = createAsyncAction(
  "@nylas/fetchCalendars/request",
  "@nylas/fetchCalendars/success",
  "@nylas/fetchCalendars/failure"
)<void, Calendar[], void>();

export const createCalendarEventForDealClosingDate = createAsyncAction(
  "@nylas/createCalendarEventForDealClosingDate/request",
  "@nylas/createCalendarEventForDealClosingDate/success",
  "@nylas/createCalendarEventForDealClosingDate/failure"
)<{ deal: Deal; eventDateTime: Date; callback?: () => void }, void, void>();

export type Actions = ActionType<
  | typeof createCalendarEventForDealClosingDate
  | typeof createNylas
  | typeof deleteNylas
  | typeof fetchCalendars
  | typeof initializeNylas
  | typeof updateNylas
>;

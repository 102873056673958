import { put, select, takeLatest } from "redux-saga/effects";
import { fetchHistory } from "./actions";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { getOrganization } from "store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import EntityHistoryRow, {
  EntityHistoryRowWithRecord,
} from "@mapmycustomers/shared/types/entity/EntityHistoryRow";
import { handleError } from "store/errors/actions";
import EntityHistoryAction from "@mapmycustomers/shared/enum/EntityHistoryAction";
import { HISTORY_ADDRESS_FIELDS } from "../util/const";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { callApi } from "store/api/callApi";
import { convertToPlatformFilterModel } from "util/viewModel/convertToPlatformFilterModel";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";

const HISTORY_ROWS_PER_PAGE = 20;

const AFTER_FILTER_FIELD_ALIAS: Record<string, string> = {
  "parentAccount.id": "parentAccountId",
  "account.id": "accountId",
  "contact.id": "contactId",
  stageId: "dealStageId",
};

const relatedRecordUpdateByActionType: Partial<Record<EntityHistoryAction, string[]>> = {
  [EntityHistoryAction.OWNERSHIP_CHANGED]: ["userId"],
};

export function* onFetchHistory({
  payload: { entityType, entityId, filter, page, sortFieldName, sortOrder },
}: ReturnType<typeof fetchHistory.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const entityHistoryFieldModel = getFieldModelByEntityType(EntityType.HISTORY);

    // we need to perform addition filtering coz platform returns all rows which includes
    // requested field, but there is can be some another fields.
    let allowedFieldNames =
      filter?.field?.operator === FilterOperator.IN_ANY
        ? (filter?.field?.value as string[])
        : undefined;
    allowedFieldNames = allowedFieldNames?.length
      ? allowedFieldNames.map((fieldName) => AFTER_FILTER_FIELD_ALIAS[fieldName] ?? fieldName)
      : undefined;

    const historyResponse: ListResponse<EntityHistoryRow> = yield callApi(
      "fetchEntityHistory",
      organization.id,
      entityType,
      entityId,
      {
        $filters: convertToPlatformFilterModel(filter, [], entityHistoryFieldModel, true),
        $limit: HISTORY_ROWS_PER_PAGE,
        $offset: (page - 1) * HISTORY_ROWS_PER_PAGE,
        $order: `${sortOrder === SortOrder.DESC ? "-" : ""}${sortFieldName}`,
      }
    );
    yield put(
      fetchHistory.success({
        // Some rows from response actually contain changes for several fields at once (several actions).
        // We split such rows so that each row only contains a single change, because this is a UI requirement.
        // Technically, that may lead to cases when one page of history table actually displays more
        // rows than HISTORY_ROWS_PER_PAGE, but that's a known issue and is ok.
        history: historyResponse.data.reduce<EntityHistoryRowWithRecord[]>((result, row) => {
          const newRows: EntityHistoryRowWithRecord[] = [];
          if (
            row.updated?.length &&
            row.action.includes(EntityHistoryAction.LOCATION_CHANGED) &&
            (!allowedFieldNames ||
              row.updated
                .map((updatedRow) => Object.keys(updatedRow)[0])
                .some(
                  (fieldName) =>
                    HISTORY_ADDRESS_FIELDS.includes(fieldName) &&
                    allowedFieldNames?.includes(fieldName)
                ))
          ) {
            newRows.push({
              ...row,
              actionType: EntityHistoryAction.LOCATION_CHANGED,
              ...row.updated.reduce(
                (result, row) => {
                  const fieldName = Object.keys(row)[0];
                  const value = Object.values(row)[0];
                  return {
                    new: { ...result.new, [fieldName]: value.new },
                    old: { ...result.old, [fieldName]: value.old },
                  };
                },
                { new: {}, old: {} }
              ),
            });
          }
          const skipRecordUpdateFields = Object.values(relatedRecordUpdateByActionType).flat();
          row.action
            .filter((actionType) => actionType !== EntityHistoryAction.LOCATION_CHANGED)
            .forEach((actionType) => {
              if (row.updated) {
                row.updated.forEach((updatedRow) => {
                  const fieldName = Object.keys(updatedRow)[0] as string;
                  if (!allowedFieldNames || allowedFieldNames.includes(fieldName)) {
                    if (
                      (([
                        EntityHistoryAction.ADDED_TO_TERRITORIES,
                        EntityHistoryAction.REMOVED_FROM_TERRITORIES,
                        EntityHistoryAction.NOTE_ADDED,
                        EntityHistoryAction.NOTE_UPDATED,
                        EntityHistoryAction.RECORD_UPDATED,
                        EntityHistoryAction.CUSTOM_FIELD_VALUE_ADDED,
                        EntityHistoryAction.CUSTOM_FIELD_VALUE_UPDATED,
                        EntityHistoryAction.CUSTOM_FIELD_VALUE_DELETED,
                      ].includes(actionType) &&
                        !skipRecordUpdateFields.includes(fieldName)) ||
                        relatedRecordUpdateByActionType[actionType]?.includes(fieldName)) &&
                      !HISTORY_ADDRESS_FIELDS.includes(fieldName)
                    ) {
                      newRows.push({
                        ...row,
                        fieldName,
                        actionType,
                        new: Object.values(updatedRow)[0].new,
                        old: Object.values(updatedRow)[0].old,
                      });
                    }
                  }
                });
              } else {
                newRows.push({
                  ...row,
                  actionType,
                });
              }
            });
          return [...result, ...newRows];
        }, []),
        pageCount: Math.ceil(historyResponse.total / HISTORY_ROWS_PER_PAGE),
      })
    );
  } catch (error) {
    yield put(fetchHistory.failure());
    yield put(handleError({ error }));
  }
}

export function* entityHistorySaga() {
  yield takeLatest(fetchHistory.request, onFetchHistory);
}

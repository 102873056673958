import Color from "@mapmycustomers/shared/enum/Color";
import { HexColor } from "@mapmycustomers/shared/types/AnyColor";

export const colorsToHex: Record<Color, string> = {
  black: "#464646",
  green: "#32cd32",
  blue: "#4a90e2",
  grey: "#acacac",
  orange: "#ff6f1b",
  pink: "#ff6f9c",
  purple: "#b549a2",
  red: "#ff5d6c",
  teal: "#008080",
  yellow: "#ffa700",
  "neon red": "#ff0000",
  "neon yellow": "#f3f315",
  "neon green": "#aeff14",
  "neon blue": "#4d4dff",
  "neon purple": "#ea6fff",
  violet: "#951ce4",
  turquoise: "#40e0d0",
  peach: "#ffdab9",
  navy: "#000080",
  olive: "#556b2f",
  cyan: "#00ffff",
  gold: "#daa520",
  maroon: "#aa133f",
  brown: "#cb0303",
  white: "#ffffff",
} as const;

const colorNameToHex = (color: Color): HexColor => colorsToHex[color] as HexColor;

export default colorNameToHex;

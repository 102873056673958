import React, {
  forwardRef,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import InputNumber, { InputNumberProps } from "antd/es/input-number";
import Labeled, { LabeledFieldProps } from "../Labeled";
import ErrorRow from "../ErrorRow";
import cn from "classnames";
import NumberFormatType from "@mapmycustomers/shared/enum/NumberFormatType";
import { useConfigProvider } from "../../ConfigProvider";
import { bem } from "@react-md/utils";
import "./NumberField.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

export const allowedKeys = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "Tab",
  "Backspace",
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "ArrowDown",
  "Enter",
  ",",
  ".",
  "-",
];

export interface NumberFieldProps
  extends Omit<InputNumberProps, "className" | "onChange" | "addonAfter">,
    Omit<LabeledFieldProps, "children"> {
  caption?: string;
  className?: string;
  error?: ReactNode;
  format?: NumberFormatType | null;
  fullWidth?: boolean;
  inputClassName?: string;
  locked?: boolean;
  onChange?: (value?: number) => void;
}

const block = bem("mmc-number-field");

const NumberField = forwardRef<NumberFieldComponent, NumberFieldProps>(
  (
    {
      caption,
      className,
      disabled,
      error,
      fullWidth,
      format,
      inputClassName,
      label,
      labelClassName,
      labelPosition,
      locked,
      onChange,
      onKeyDown,
      required,
      rowProps,
      sideLabelSpan,
      size = "middle",
      value,
      ...props
    }: NumberFieldProps,
    ref
  ) => {
    const configProvider = useConfigProvider();

    const [internalValue, setInternalValue] = useState<
      number | string | undefined | null
    >(value ?? null);

    const handleChange = useCallback(
      (value: number | string | undefined | null) => {
        setInternalValue(value);
        onChange && onChange(typeof value === "number" ? value : undefined);
      },
      [onChange, setInternalValue]
    );

    // prevent pressing any non-numeric or some other special buttons (see allowedKeys list)
    const handleKeyDown = useCallback(
      (e: KeyboardEvent<any>) => {
        onKeyDown?.(e);
        if (e.isDefaultPrevented()) {
          return;
        }
        if (["v", "c", "x"].includes(e.key) && (e.metaKey || e.ctrlKey)) {
          return; // allow ctrl+c/v/x
        }
        if (
          !allowedKeys.includes(e.key) &&
          e.key !== configProvider.getDecimalSeparator()
        ) {
          e.preventDefault();
          e.stopPropagation();
        }
      },
      [configProvider, onKeyDown]
    );

    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({
      focus: () => inputRef.current?.focus(),
      blur: () => inputRef.current?.blur(),
    }));

    const formatter = useCallback(
      (value: string | number | undefined) =>
        value === undefined || value === ""
          ? ""
          : format === NumberFormatType.PERCENTAGE
          ? configProvider.formatNumber(+value / 100, { style: "percent" })
          : configProvider.formatNumber(+value),
      [format, configProvider]
    );

    const parser = useCallback(
      (value: string | undefined) => {
        const delimiter = configProvider.getDecimalSeparator();
        return (value ?? "")
          .replace(new RegExp(`[^0-9${delimiter}-]`, "g"), "")
          .replace(delimiter, ".");
      },
      [configProvider]
    );

    useEffect(() => {
      setInternalValue(value);
    }, [value]);

    return (
      <Labeled
        className={cn(block({ disabled: disabled || locked }), className)}
        label={label}
        labelClassName={cn(block("label"), labelClassName)}
        labelPosition={labelPosition}
        required={required}
        rowProps={rowProps}
        sideLabelSpan={sideLabelSpan}
      >
        <div className={block("input", { fullWidth })}>
          <InputNumber
            addonAfter={
              locked ? (
                <div className={block("lock")}>
                  <FontAwesomeIcon icon={faLock} size="xs" />
                </div>
              ) : undefined
            }
            className={cn(block("input-field"), inputClassName)}
            defaultValue={value || undefined}
            disabled={disabled || locked}
            formatter={formatter}
            key={format}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            parser={parser}
            ref={inputRef}
            required={required}
            size={size}
            value={typeof internalValue === "number" ? internalValue : ""}
            {...props}
          />
          {error ? (
            <ErrorRow>{error}</ErrorRow>
          ) : caption ? (
            <div className={block("caption")}>{caption}</div>
          ) : null}
        </div>
      </Labeled>
    );
  }
);

export interface NumberFieldComponent {
  focus: () => void;
  blur(): void;
}

export default NumberField;

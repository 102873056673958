import { svgPathData as faBuildingPath } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { svgPathData as faHandshakePath } from "@fortawesome/free-solid-svg-icons/faHandshake";
import { svgPathData as faUserPath } from "@fortawesome/free-solid-svg-icons/faUser";
import { svgPathData as faEllipsisHPath } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
import { svgPathData as faCheckCirclePath } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";
import MarkerShape from "@mapmycustomers/shared/enum/MarkerShape";
import { EntityType } from "@mapmycustomers/shared/enum";

export type AdditionalMarkerIcons = "multipin" | "selected";

// a sequence of numbers in sums is: offsetByShape + offsetByIcon
const offsetByShapeAndIcon: Record<
  MarkerShape,
  Record<EntityTypesSupportedByMapsPage | AdditionalMarkerIcons, [number, number]>
> = {
  [MarkerShape.CIRCLE]: {
    [EntityType.COMPANY]: [2 + 14, -2 + 11],
    [EntityType.PERSON]: [2 + 13, -2 + 11],
    [EntityType.DEAL]: [-1 + 12, -2 + 12],
    multipin: [-1 + 13, -4 + 11],
    selected: [-2 + 13, -4 + 11],
  },
  [MarkerShape.PIN]: {
    [EntityType.COMPANY]: [2 + 14, 0 + 11],
    [EntityType.PERSON]: [2 + 13, 0 + 11],
    [EntityType.DEAL]: [0 + 12, 0 + 12],
    multipin: [2 + 13, 1 + 11],
    selected: [1 + 13, 0 + 11],
  },
  [MarkerShape.SQUARE]: {
    [EntityType.COMPANY]: [0 + 14, -3 + 11],
    [EntityType.PERSON]: [0 + 13, -3 + 11],
    [EntityType.DEAL]: [-2 + 12, -3 + 12],
    multipin: [0 + 13, -3 + 11],
    selected: [-1 + 13, -3 + 11],
  },
  [MarkerShape.FLAG]: {
    [EntityType.COMPANY]: [5 + 14, 2 + 11],
    [EntityType.PERSON]: [4 + 13, 2 + 11],
    [EntityType.DEAL]: [3 + 12, 2 + 12],
    multipin: [5 + 13, 3 + 11],
    selected: [4 + 13, 1 + 11],
  },
  [MarkerShape.STAR]: {
    [EntityType.COMPANY]: [6 + 14, 5 + 11],
    [EntityType.PERSON]: [6 + 13, 4 + 11],
    [EntityType.DEAL]: [4 + 12, 2 + 12],
    multipin: [6 + 13, 4 + 11],
    selected: [5 + 13, 4 + 11],
  },
  [MarkerShape.TRIANGLE]: {
    [EntityType.COMPANY]: [3 + 14, 2 + 11],
    [EntityType.PERSON]: [3 + 13, 1 + 11],
    [EntityType.DEAL]: [2 + 12, 4 + 12],
    multipin: [2 + 13, 2 + 11],
    selected: [2 + 13, 3 + 11],
  },
  [MarkerShape.HEXAGON]: {
    [EntityType.COMPANY]: [3 + 14, -2 + 11],
    [EntityType.PERSON]: [3 + 13, -2 + 11],
    [EntityType.DEAL]: [2 + 12, -2 + 12],
    multipin: [2 + 13, -2 + 11],
    selected: [2 + 13, -2 + 11],
  },
  [MarkerShape.HEART]: {
    [EntityType.COMPANY]: [3 + 14, -3 + 11],
    [EntityType.PERSON]: [4 + 13, -3 + 11],
    [EntityType.DEAL]: [1 + 12, -5 + 12],
    multipin: [4 + 13, -3 + 11],
    selected: [2 + 13, -3 + 11],
  },
  [MarkerShape.BOOKMARK]: {
    [EntityType.COMPANY]: [-1 + 14, -3 + 11],
    [EntityType.PERSON]: [-1 + 13, -3 + 11],
    [EntityType.DEAL]: [-3 + 12, -2 + 12],
    multipin: [0 + 13, -2 + 11],
    selected: [-2 + 13, -3 + 11],
  },
  [MarkerShape.LEAD]: {
    [EntityType.COMPANY]: [0 + 14, -3 + 11],
    [EntityType.PERSON]: [0 + 13, -3 + 11],
    [EntityType.DEAL]: [0 + 12, -3 + 12],
    multipin: [0 + 13, -3 + 11],
    selected: [0 + 13, -3 + 11],
  },
  [MarkerShape.LEAD_CLICKED]: {
    [EntityType.COMPANY]: [0 + 14, -3 + 11],
    [EntityType.PERSON]: [0 + 13, -3 + 11],
    [EntityType.DEAL]: [0 + 12, -3 + 12],
    multipin: [0 + 13, -3 + 11],
    selected: [0 + 13, -3 + 11],
  },
  [MarkerShape.LEAD_DUPLICATE]: {
    [EntityType.COMPANY]: [0 + 14, -3 + 11],
    [EntityType.PERSON]: [0 + 13, -3 + 11],
    [EntityType.DEAL]: [0 + 12, -3 + 12],
    multipin: [0 + 13, -3 + 11],
    selected: [0 + 13, -3 + 11],
  },
  [MarkerShape.LEAD_DUPLICATE_CLICKED]: {
    [EntityType.COMPANY]: [0 + 14, -3 + 11],
    [EntityType.PERSON]: [0 + 13, -3 + 11],
    [EntityType.DEAL]: [0 + 12, -3 + 12],
    multipin: [0 + 13, -3 + 11],
    selected: [0 + 13, -3 + 11],
  },
  [MarkerShape.LEAD_SELECTED]: {
    [EntityType.COMPANY]: [0 + 14, -3 + 11],
    [EntityType.PERSON]: [0 + 13, -3 + 11],
    [EntityType.DEAL]: [0 + 12, -3 + 12],
    multipin: [0 + 13, -3 + 11],
    selected: [0 + 13, -3 + 11],
  },
  [MarkerShape.LEAD_HIDDEN]: {
    [EntityType.COMPANY]: [0 + 14, -3 + 11],
    [EntityType.PERSON]: [0 + 13, -3 + 11],
    [EntityType.DEAL]: [0 + 12, -3 + 12],
    multipin: [0 + 13, -3 + 11],
    selected: [0 + 13, -3 + 11],
  },
  [MarkerShape.LEAD_HIDDEN_CLICKED]: {
    [EntityType.COMPANY]: [0 + 14, -3 + 11],
    [EntityType.PERSON]: [0 + 13, -3 + 11],
    [EntityType.DEAL]: [0 + 12, -3 + 12],
    multipin: [0 + 13, -3 + 11],
    selected: [0 + 13, -3 + 11],
  },
};

const scaleByShapeAndIcon: Record<
  MarkerShape,
  Record<EntityTypesSupportedByMapsPage | AdditionalMarkerIcons, number>
> = {
  [MarkerShape.CIRCLE]: {
    [EntityType.COMPANY]: 0.04,
    [EntityType.PERSON]: 0.04,
    [EntityType.DEAL]: 0.0375,
    multipin: 0.0525,
    selected: 0.05,
  },
  [MarkerShape.PIN]: {
    [EntityType.COMPANY]: 0.04,
    [EntityType.PERSON]: 0.04,
    [EntityType.DEAL]: 0.0375,
    multipin: 0.04,
    selected: 0.04,
  },
  [MarkerShape.SQUARE]: {
    [EntityType.COMPANY]: 0.035,
    [EntityType.PERSON]: 0.035,
    [EntityType.DEAL]: 0.035,
    multipin: 0.035,
    selected: 0.035,
  },
  [MarkerShape.FLAG]: {
    [EntityType.COMPANY]: 0.0325,
    [EntityType.PERSON]: 0.0325,
    [EntityType.DEAL]: 0.0325,
    multipin: 0.0325,
    selected: 0.035,
  },
  [MarkerShape.STAR]: {
    [EntityType.COMPANY]: 0.0325,
    [EntityType.PERSON]: 0.0325,
    [EntityType.DEAL]: 0.031,
    multipin: 0.0325,
    selected: 0.0325,
  },
  [MarkerShape.TRIANGLE]: {
    [EntityType.COMPANY]: 0.032,
    [EntityType.PERSON]: 0.032,
    [EntityType.DEAL]: 0.03,
    multipin: 0.0375,
    selected: 0.0325,
  },
  [MarkerShape.HEXAGON]: {
    [EntityType.COMPANY]: 0.035,
    [EntityType.PERSON]: 0.035,
    [EntityType.DEAL]: 0.03,
    multipin: 0.035,
    selected: 0.035,
  },
  [MarkerShape.HEART]: {
    [EntityType.COMPANY]: 0.0325,
    [EntityType.PERSON]: 0.03,
    [EntityType.DEAL]: 0.0325,
    multipin: 0.0285,
    selected: 0.0325,
  },
  [MarkerShape.BOOKMARK]: {
    [EntityType.COMPANY]: 0.04,
    [EntityType.PERSON]: 0.04,
    [EntityType.DEAL]: 0.0375,
    multipin: 0.035,
    selected: 0.04,
  },
  [MarkerShape.LEAD]: {
    [EntityType.COMPANY]: 0.035,
    [EntityType.PERSON]: 0.035,
    [EntityType.DEAL]: 0.03,
    multipin: 0.035,
    selected: 0.035,
  },
  [MarkerShape.LEAD_CLICKED]: {
    [EntityType.COMPANY]: 0.035,
    [EntityType.PERSON]: 0.035,
    [EntityType.DEAL]: 0.03,
    multipin: 0.035,
    selected: 0.035,
  },
  [MarkerShape.LEAD_DUPLICATE]: {
    [EntityType.COMPANY]: 0.035,
    [EntityType.PERSON]: 0.035,
    [EntityType.DEAL]: 0.03,
    multipin: 0.035,
    selected: 0.035,
  },
  [MarkerShape.LEAD_DUPLICATE_CLICKED]: {
    [EntityType.COMPANY]: 0.035,
    [EntityType.PERSON]: 0.035,
    [EntityType.DEAL]: 0.03,
    multipin: 0.035,
    selected: 0.035,
  },
  [MarkerShape.LEAD_SELECTED]: {
    [EntityType.COMPANY]: 0.035,
    [EntityType.PERSON]: 0.035,
    [EntityType.DEAL]: 0.03,
    multipin: 0.035,
    selected: 0.035,
  },
  [MarkerShape.LEAD_HIDDEN]: {
    [EntityType.COMPANY]: 0.035,
    [EntityType.PERSON]: 0.035,
    [EntityType.DEAL]: 0.03,
    multipin: 0.035,
    selected: 0.035,
  },
  [MarkerShape.LEAD_HIDDEN_CLICKED]: {
    [EntityType.COMPANY]: 0.035,
    [EntityType.PERSON]: 0.035,
    [EntityType.DEAL]: 0.03,
    multipin: 0.035,
    selected: 0.035,
  },
};

const pathByIcon: Record<EntityTypesSupportedByMapsPage | AdditionalMarkerIcons, string> = {
  [EntityType.COMPANY]: faBuildingPath,
  [EntityType.PERSON]: faUserPath,
  [EntityType.DEAL]: faHandshakePath,
  multipin: faEllipsisHPath,
  selected: faCheckCirclePath,
};

const getSvgForIcon = (
  shape: MarkerShape,
  icon: EntityTypesSupportedByMapsPage | AdditionalMarkerIcons | undefined,
  fillColor: string
): string => {
  if (!icon) {
    return "";
  }

  const path = pathByIcon[icon];

  const translate = `translate(${offsetByShapeAndIcon[shape][icon].join(" ")})`;
  const scale = `scale(${scaleByShapeAndIcon[shape][icon]})`;
  const transform = `transform="${translate} ${scale}"`;

  return `<path d="${path}" fill="${fillColor}" ${transform} stroke-linejoin="round" stroke-linecap="round" />`;
};

export default getSvgForIcon;

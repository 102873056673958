import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";

const dealRecordState = (state: RootState) => state.component.dealRecord;

export const getRecordData = createSelector(dealRecordState, ({ recordData }) => recordData);
export const isLoading = createSelector(dealRecordState, ({ loading }) => loading);
export const isDeleting = createSelector(dealRecordState, ({ deleteLoading }) => deleteLoading);
export const isDealUpdateLoading = createSelector(
  dealRecordState,
  ({ dealUpdateLoading }) => dealUpdateLoading
);
export const isActivitiesLoading = createSelector(
  dealRecordState,
  ({ activitiesLoading }) => activitiesLoading
);
export const isActivitiesCompletedByTypeLoading = createSelector(
  dealRecordState,
  ({ activitiesCompletedByTypeLoading }) => activitiesCompletedByTypeLoading
);
export const isActivitiesTotalLoading = createSelector(
  dealRecordState,
  ({ activitiesTotalLoading }) => activitiesTotalLoading
);
export const isAddDealParentCompanyLoading = createSelector(
  dealRecordState,
  ({ addDealParentCompanyLoading }) => addDealParentCompanyLoading
);

export const isAddDealParentPersonLoading = createSelector(
  dealRecordState,
  ({ addDealParentPersonLoading }) => addDealParentPersonLoading
);

export const isDealNoteCreateLoading = createSelector(
  dealRecordState,
  ({ dealNoteCreateLoading }) => dealNoteCreateLoading
);

export const areFilesUploading = createSelector(
  dealRecordState,
  ({ filesUploading }) => filesUploading
);

export const getSelectedActivityTypes = createSelector(
  dealRecordState,
  ({ activitiesSelectedActivityTypes }) => activitiesSelectedActivityTypes
);
export const getRecapRange = createSelector(
  dealRecordState,
  ({ activitiesRecapRange }) => activitiesRecapRange
);
export const getActivitiesSearchQuery = createSelector(
  dealRecordState,
  ({ activitiesSearchQuery }) => activitiesSearchQuery
);
export const getActivitiesFilter = createSelector(
  dealRecordState,
  ({ activitiesFilter }) => activitiesFilter
);
export const getRemoveDealParentCompanyLoading = createSelector(
  dealRecordState,
  ({ removeDealParentPersonLoading }) => removeDealParentPersonLoading
);
export const getRemoveDealParentPersonLoading = createSelector(
  dealRecordState,
  ({ removeDealParentPersonLoading }) => removeDealParentPersonLoading
);
export const getDealNoteDeleteLoadingIds = createSelector(
  dealRecordState,
  ({ dealNoteDeleteLoadingIds }) => dealNoteDeleteLoadingIds
);
export const getDealNoteUpdateLoadingIds = createSelector(
  dealRecordState,
  ({ dealNoteUpdateLoadingIds }) => dealNoteUpdateLoadingIds
);
export const getPostponeActivityLoadingIds = createSelector(
  dealRecordState,
  ({ postponeActivityLoadingIds }) => postponeActivityLoadingIds
);
export const getToggleCompleteActivityLoadingIds = createSelector(
  dealRecordState,
  ({ toggleCompleteActivityLoadingIds }) => toggleCompleteActivityLoadingIds
);
export const getUpdateActivityNoteLoadingIds = createSelector(
  dealRecordState,
  ({ updateActivityNoteLoadingIds }) => updateActivityNoteLoadingIds
);

export const doesNeedToScrollToActivity = createSelector(
  dealRecordState,
  ({ activityAutoScrolling }) => activityAutoScrolling
);

export const isDealRecordChanged = createSelector(dealRecordState, ({ hasChanges }) => hasChanges);

export const getDeal = createSelector(getRecordData, ({ deal }) => deal);

export const getActivitiesOrder = createSelector(
  dealRecordState,
  ({ activitiesOrder }) => activitiesOrder
);

export const getActivitiesSelectedAssignees = createSelector(
  dealRecordState,
  ({ activitiesSelectedAssignees }) => activitiesSelectedAssignees
);

export const isEntityRelating = createSelector(
  dealRecordState,
  ({ entityRelating }) => entityRelating
);

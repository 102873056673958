import { call, select } from "redux-saga/effects";
import { getOrganizationId } from "store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import { doesWeekStartFromMonday, getLocalTimeZone } from "util/dates";
import { DateRange, getDatesRange } from "enum/dashboard/dateRanges";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { Activity, AnyEntity, MapEntity } from "@mapmycustomers/shared/types/entity";
import { callApi } from "store/api/callApi";
import AggregatedListResponse from "types/viewModel/AggregatedListResponse";
import { parseApiDate } from "util/parsers";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import LeaderboardItem from "@mapmycustomers/shared/types/entity/LeaderboardItem";
import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import CheckInsCardData from "scene/dashboard/store/cards/checkIns/CheckInsCardData";
import CheckInsCardConfiguration from "types/dashboard/cards/checkIns/CheckInsCardConfiguration";
import Scope from "types/dashboard/Scope";
import NewRecordsCardData from "scene/dashboard/store/cards/newRecords/NewRecordsCardData";
import NewRecordsCardConfiguration from "types/dashboard/cards/newRecords/NewRecordsCardConfiguration";
import EntityTypesSupportedInNewRecords from "types/dashboard/cards/newRecords/EntityTypesSupportedInNewRecords";
import { EntityType } from "@mapmycustomers/shared";
import { getFilters } from "scene/dashboard/store/cards/newRecords/sagas";
import { ApiMethodName } from "store/api/ApiService";
import ActivitiesLoggedCardData from "scene/dashboard/store/cards/activitiesLogged/ActivitiesLoggedCardData";
import ActivitiesLoggedCardConfiguration from "types/dashboard/cards/activitiesLogged/ActivitiesLoggedCardConfiguration";
import ActivitiesOverdueCardData from "scene/dashboard/store/cards/activitiesOverdue/ActivitiesOverdueCardData";
import ActivitiesOverdueCardConfiguration from "types/dashboard/cards/activitiesOverdue/ActivitiesOverdueCardConfiguration";
import NoContactInCardData from "scene/dashboard/store/cards/noContactIn/NoContactInCardData";
import { NO_CONTACT_IN_ENTITY_TYPES } from "scene/dashboard/components/cards/NoContactIn/consts";
import PlatformMapRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformMapRequest";
import NoContactInCountCardConfiguration from "types/dashboard/cards/noContactInCount/NoContactInCountCardConfiguration";
import RecordsPastDueCardData from "scene/dashboard/store/cards/recordsPastDue/RecordsPastDueCardData";
import RecordsPastDueCardConfiguration from "types/dashboard/cards/recordsPastDue/RecordsPastDueCardConfiguration";

interface ReliabilityCountAggregationItem {
  key: number; // date in numeric format
  secondary: { key: number; doc_count: number }[]; // key === 1 for verified and === 0 for unverified
}

const granularityByDateRange: Record<DateRange, string> = {
  [DateRange.DAY]: "hour",
  [DateRange.WEEK]: "day",
  [DateRange.MONTH]: "day",
  [DateRange.QUARTER]: "week",
  [DateRange.YEAR]: "month",
};

interface CountAggregationItem {
  key: number; // date in numeric format
  doc_count: number;
}

const newRecordsMetricByEntityType: Record<
  EntityTypesSupportedInNewRecords,
  LeaderboardMetricFieldName
> = {
  [EntityType.COMPANY]: LeaderboardMetricFieldName.COMPANIES_CREATED,
  [EntityType.DEAL]: LeaderboardMetricFieldName.DEALS_CREATED,
  [EntityType.PERSON]: LeaderboardMetricFieldName.PEOPLE_CREATED,
};

const fetchMethodByEntityType: Record<EntityTypesSupportedInNewRecords, ApiMethodName> = {
  [EntityType.COMPANY]: "fetchCompanies",
  [EntityType.DEAL]: "fetchDeals",
  [EntityType.PERSON]: "fetchPeople",
};

export function* fetchCheckInsCardDataHelper(params: {
  callback: (data: CheckInsCardData) => void;
  configuration: CheckInsCardConfiguration;
  scope?: Scope;
}) {
  const { callback, configuration, scope } = params;
  const { criteria, dateRange, preferences } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;
  const orgId: Organization["id"] = yield select(getOrganizationId);

  const granularity = granularityByDateRange[dateRange.range];
  const range = getDatesRange(dateRange.range, dateRange.subRange);

  const filters = {
    crmActivityTypeId: criteria.activityTypeIds.length
      ? { $in: criteria.activityTypeIds }
      : undefined,
    reliability: { $ne: null },
    completedAt: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
    // TODO: instead of team/user here we should probably filter by an assigneeId field
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
    assigneeId: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
    source: criteria.sources.length ? { $in: criteria.sources } : undefined,
  };

  const payload = {
    $aggs: {
      count: ["id"],
      primaryGroup: {
        field: "completedAt",
        interval: granularity,
        timezone: getLocalTimeZone(),
        offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
      },
      secondaryGroup: {
        field: "reliability",
      },
    },
    $filters: filters,
    $limit: 0,
  };

  const response: AggregatedListResponse<Activity, ReliabilityCountAggregationItem[]> =
    yield callApi("fetchActivities", orgId, payload);

  const history: CheckInsCardData["history"] = response.aggregations.map((item) => ({
    date: parseApiDate(item.key),
    verifiedCount: item.secondary.find(({ key }) => key === 1)?.doc_count ?? 0,
    unverifiedCount: criteria.includeUnverified
      ? item.secondary.find(({ key }) => key === 0)?.doc_count
      : undefined,
  }));

  const result: CheckInsCardData = {
    verifiedCount: 0,
    history: preferences.showChart ? history : undefined,
  };

  result.verifiedCount = history.reduce((sum, item) => sum + item.verifiedCount, 0) ?? 0;
  if (criteria.includeUnverified) {
    result.unverifiedCount = history.reduce((sum, item) => sum + (item.unverifiedCount ?? 0), 0);
  }

  if (preferences.showTrend) {
    // fetch again, but for the previous range
    const previousRange = getDatesRange(dateRange.range, dateRange.subRange, true);
    const payload = {
      $filters: {
        ...filters,
        ...(criteria.includeUnverified ? {} : { reliability: { $eq: true } }),
        completedAt: {
          $gte: previousRange.startDate.toISOString(),
          $lte: previousRange.endDate.toISOString(),
        },
      },
      $limit: 0,
    };
    const response: ListResponse<Activity> = yield callApi("fetchActivities", orgId, payload);
    result.previousCount = response.total;
  }

  // if we should show the rank and scope is not the Entire Org
  if (preferences.showRankPosition && (scope?.teamId || scope?.userId)) {
    const response: ListResponse<LeaderboardItem> = yield callApi(
      "fetchLeaderboardData",
      orgId,
      {
        crmActivityTypeId: criteria.activityTypeIds.length
          ? { $in: criteria.activityTypeIds }
          : undefined,
        date: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
        summary: isTeamScope ? "team" : "user",
        includePerformanceMetric: true,
        property: { $in: [LeaderboardMetricFieldName.CHECK_INS_COMPLETED] },
      },
      undefined
    );
    const rank = isTeamScope
      ? response.data.findIndex(({ teamId }) => teamId === scope!.teamId)
      : response.data.findIndex(({ userId }) => userId === scope!.userId);
    result.rank = rank >= 0 ? rank + 1 : undefined;
  }

  yield call(callback, result);
}

export function* fetchNewRecordsCardDataHelper(params: {
  callback: (data: NewRecordsCardData) => void;
  configuration: NewRecordsCardConfiguration;
  scope?: Scope;
}) {
  const { configuration, callback, scope } = params;
  const { criteria, dateRange, preferences } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const orgId: Organization["id"] = yield select(getOrganizationId);

  const granularity = granularityByDateRange[dateRange.range];

  const filters = getFilters(scope, configuration);
  const fetchMethod = fetchMethodByEntityType[criteria.entityType];

  const payload = {
    $aggs: preferences.showChart
      ? {
          count: ["id"],
          primaryGroup: {
            field: "createdAt",
            interval: granularity,
            timezone: getLocalTimeZone(),
            offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
          },
        }
      : undefined,
    $filters: filters,
    $limit: 0,
  };

  const response: AggregatedListResponse<AnyEntity, CountAggregationItem[] | undefined> =
    yield callApi(fetchMethod, orgId, payload);

  const result: NewRecordsCardData = {
    count: response.total,
    history: response.aggregations?.map((item) => ({
      date: parseApiDate(item.key),
      count: item.doc_count,
    })),
  };

  if (preferences.showTrend) {
    // fetch again, but for the previous range
    const previousRange = getDatesRange(dateRange.range, dateRange.subRange, true);
    const payload = {
      $aggs: preferences.showChart
        ? {
            count: ["id"],
            primaryGroup: {
              field: "createdAt",
              interval: granularity,
              timezone: getLocalTimeZone(),
              offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
            },
          }
        : undefined,
      $filters: {
        ...filters,
        createdAt: {
          $gte: previousRange.startDate.toISOString(),
          $lte: previousRange.endDate.toISOString(),
        },
      },
      $limit: 0,
    };
    const response: AggregatedListResponse<AnyEntity, CountAggregationItem[] | undefined> =
      yield callApi(fetchMethod, orgId, payload);
    result.previousCount = response.total;
    if (preferences.showChart) {
      result.previousHistory = response.aggregations?.map((item) => ({
        date: parseApiDate(item.key),
        count: item.doc_count,
      }));
    }
  }

  // if we should show the rank and scope is not the Entire Org
  if (preferences.showRankPosition && (scope?.teamId || scope?.userId)) {
    const response: ListResponse<LeaderboardItem> = yield callApi(
      "fetchLeaderboardData",
      orgId,
      {
        date: filters.createdAt,
        summary: isTeamScope ? "team" : "user",
        includePerformanceMetric: true,
        property: { $in: [newRecordsMetricByEntityType[criteria.entityType]] },
      },
      undefined
    );
    const rank = isTeamScope
      ? response.data.findIndex(({ teamId }) => teamId === scope!.teamId)
      : response.data.findIndex(({ userId }) => userId === scope!.userId);
    result.rank = rank >= 0 ? rank + 1 : undefined;
  }

  yield call(callback, result);
}

export function* fetchActivitiesLoggedCardDataHelper(params: {
  callback: (data: ActivitiesLoggedCardData) => void;
  configuration: ActivitiesLoggedCardConfiguration;
  scope?: Scope;
}) {
  const { configuration, callback, scope } = params;
  const { criteria, dateRange, preferences } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const orgId: Organization["id"] = yield select(getOrganizationId);

  const granularity = granularityByDateRange[dateRange.range];
  const range = getDatesRange(dateRange.range, dateRange.subRange);

  const filters = {
    crmActivityTypeId: criteria.activityTypeIds.length
      ? { $in: criteria.activityTypeIds }
      : undefined,
    completedAt: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
    // TODO: instead of team/user here we should probably filter by an assigneeId field
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
    assigneeId: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
    source: criteria.sources.length ? { $in: criteria.sources } : undefined,
  };

  const payload = {
    $aggs: preferences.showChart
      ? {
          count: ["id"],
          primaryGroup: {
            field: "completedAt",
            interval: granularity,
            timezone: getLocalTimeZone(),
            offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
          },
        }
      : undefined,
    $filters: filters,
    $limit: 0,
  };

  const response: AggregatedListResponse<Activity, CountAggregationItem[] | undefined> =
    yield callApi("fetchActivities", orgId, payload);

  const result: ActivitiesLoggedCardData = {
    count: response.total,
    history: response.aggregations?.map((item) => ({
      date: parseApiDate(item.key),
      count: item.doc_count,
    })),
  };

  if (preferences.showTrend) {
    // fetch again, but for the previous range
    const previousRange = getDatesRange(dateRange.range, dateRange.subRange, true);
    const payload = {
      // we need to get previous period's chart data to show it when user hovers over the trend text
      $aggs: preferences.showChart
        ? {
            count: ["id"],
            primaryGroup: {
              field: "completedAt",
              interval: granularity,
              timezone: getLocalTimeZone(),
              offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
            },
          }
        : undefined,
      $filters: {
        ...filters,
        completedAt: {
          $gte: previousRange.startDate.toISOString(),
          $lte: previousRange.endDate.toISOString(),
        },
      },
      $limit: 0,
    };
    const response: AggregatedListResponse<Activity, CountAggregationItem[] | undefined> =
      yield callApi("fetchActivities", orgId, payload);
    result.previousCount = response.total;
    if (preferences.showChart) {
      result.previousHistory = response.aggregations?.map((item) => ({
        date: parseApiDate(item.key),
        count: item.doc_count,
      }));
    }
  }

  // if we should show the rank and scope is not the Entire Org
  if (preferences.showRankPosition && (scope?.teamId || scope?.userId)) {
    const response: ListResponse<LeaderboardItem> = yield callApi(
      "fetchLeaderboardData",
      orgId,
      {
        crmActivityTypeId: criteria.activityTypeIds.length
          ? { $in: criteria.activityTypeIds }
          : undefined,
        date: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
        summary: isTeamScope ? "team" : "user",
        includePerformanceMetric: true,
        property: { $in: [LeaderboardMetricFieldName.ACTIVITIES_COMPLETED] },
      },
      undefined
    );
    const rank = isTeamScope
      ? response.data.findIndex(({ teamId }) => teamId === scope!.teamId)
      : response.data.findIndex(({ userId }) => userId === scope!.userId);
    result.rank = rank >= 0 ? rank + 1 : undefined;
  }

  yield call(callback, result);
}

export function* fetchActivitiesOverdueCardDataHelper(params: {
  callback: (data: ActivitiesOverdueCardData) => void;
  configuration: ActivitiesOverdueCardConfiguration;
  scope?: Scope;
}) {
  const { configuration, callback, scope } = params;
  const { criteria } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const orgId: Organization["id"] = yield select(getOrganizationId);

  const filters = {
    crmActivityTypeId: criteria.activityTypeIds.length
      ? { $in: criteria.activityTypeIds }
      : undefined,
    completed: false,
    startAt: { [PlatformFilterOperator.LESS_THAN]: [new Date().toISOString()] },
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
    assigneeId: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
    source: criteria.sources.length ? { $in: criteria.sources } : undefined,
  };

  const payload = { $filters: filters, $limit: 0 };

  const response: ListResponse<AnyEntity> = yield callApi("fetchActivities", orgId, payload);

  const result: ActivitiesOverdueCardData = { count: response.total };

  yield call(callback, result);
}

export function* fetchNoContactInCountCardDataHelper(params: {
  callback: (data: NoContactInCardData) => void;
  configuration: NoContactInCountCardConfiguration;
  scope?: Scope;
}) {
  const { configuration, callback, scope } = params;
  const { criteria, dateRange } = configuration;
  const orgId: Organization["id"] = yield select(getOrganizationId);

  const entityTypes =
    Array.isArray(criteria.entityTypes) && criteria.entityTypes.length
      ? criteria.entityTypes
      : NO_CONTACT_IN_ENTITY_TYPES;

  const payload: PlatformMapRequest = {
    $filters: {
      $and: [
        { noContactDaysOut: dateRange.days === null ? null : { $gt: dateRange.days } },
        ...(!scope?.userId && scope?.teamId ? [{ teamId: { $in: [scope.teamId] } }] : []),
        ...(scope?.userId ? [{ userId: { $in: [scope.userId] } }] : []),
        ...(criteria.sources?.length ? [{ source: { $in: criteria.sources } }] : []),
      ],
      entities: entityTypes.reduce((result, entityType) => ({ ...result, [entityType]: {} }), {}),
    },
    $limit: 0,
  };

  const response: ListResponse<MapEntity> = yield callApi("fetchPins", orgId, payload);
  yield call(callback, { total: response.total });
}

export function* fetchRecordsPastDueCardDataHelper(params: {
  callback: (data: RecordsPastDueCardData) => void;
  configuration: RecordsPastDueCardConfiguration;
  scope?: Scope;
}) {
  const { configuration, callback, scope } = params;
  const { criteria } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const orgId: Organization["id"] = yield select(getOrganizationId);

  const fetchMethod = fetchMethodByEntityType[criteria.entityType];

  const filters = {
    cadenceDaysOut: { $gt: 0 },
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
    userId: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
    source: criteria.sources.length ? { $in: criteria.sources } : undefined,
  };

  const payload = { $filters: filters, $limit: 0 };

  const response: ListResponse<AnyEntity> = yield callApi(fetchMethod, orgId, payload);

  const result: RecordsPastDueCardData = { count: response.total };

  yield call(callback, result);
}

import React, { useCallback, useEffect } from "react";
import ConfigProvider from "antd/es/config-provider";
import { RawIntlProvider } from "react-intl";
import { Route, Switch, useHistory } from "react-router-dom";
import Path from "enum/Path";
import LoginPage from "scene/auth/component/Login";
import RegisterPage from "scene/auth/component/Register";
import ForgotPasswordPage from "scene/auth/component/ForgotPassword";
import ResetPasswordPage from "scene/auth/component/ForgotPassword/ResetPasswordPage";
import RootPage from "scene/auth/RootPage";
// import ExternalServiceLoginPage from "scene/auth/component/ExternalServiceLoginPage";
import PrivateRoute from "component/auth/PrivateRoute";
import { RootState } from "store/rootReducer";
import { getLocale, isLoading } from "store/locale";
import { connect } from "react-redux";
import { DEFAULT_NOTIFICATION_DURATION } from "util/consts";
import {
  ActivityPage,
  BillingPage,
  CompanyPage,
  DashboardPage,
  DealPage,
  MapPage,
  NotificationsPage,
  PeoplePage,
  ReportsPage,
  SettingsPage,
  Web1Page,
} from "scene";
import i18nService from "./config/I18nService";
import { isAnyThemeLoaded } from "store/theme";
import { getMe } from "store/iam";
import Iam from "types/Iam";
import analyticsService from "util/analytic/AnalyticsService";
import GlobalCreateEntityModals from "component/createEditEntity/AddNoteModal/GlobalCreateEntityModals";
import EntityLinkGetterContext from "util/contexts/EntityLinkGetterContext";
import defaultEntityLinkGetter from "util/contexts/defaultEntityLinkGetter";
import AnalyticsContext from "util/contexts/AnalyticsContext";
import AuthRoute from "component/auth/AuthRoute";
import configService from "config/ConfigService";
import Environment from "enum/Environment";
import notification from "antd/es/notification";
import ShortcutService from "./util/ShortCutService";
import { showGlobalSearch } from "store/globalSearch/actions";
import { preMessage } from "rc-util/es/warning";
import SsoLoginPage from "scene/auth/component/SsoLogin";
import ManageUserAccess from "scene/auth/component/ManageUserAccess";
import SsoLayout from "component/Layout/SsoLayout";
import { ConfigProvider as UiConfigProvider } from "@mapmycustomers/ui";
import { PrimitiveType } from "@mapmycustomers/ui/src/ConfigProvider/types/IConfigProviderContext";
import messages from "./messages";
import { FrigadeProvider } from "@frigade/react";
import { complementFileUrl } from "util/file";
import { getUserColorClassNameGetter, UserColorClassNameGetter } from "store/userColor/selectors";
import dateFnsLocaleService from "config/DateFnsLocaleService";

// lower down antd deprecation messages from error to warning
preMessage((message, type) => {
  if (type === "warning" && message) {
    console.warn(message);
    return null;
  } else {
    return message;
  }
});

interface Props {
  getUserColorClassName: UserColorClassNameGetter;
  isAnyThemeLoaded: boolean;
  locale: string;
  // localeLoading is needed to be in props to guarantee App component is re-rendered when locale
  // initialization is complete. `locale` prop is not enough because sometimes it has the same
  // value before and after initialization. This is needed to get correct intl instance from
  // the i18nService.
  localeLoading: boolean;
  me?: Iam;
  showGlobalSearch: typeof showGlobalSearch;
}

notification.config({
  duration: DEFAULT_NOTIFICATION_DURATION,
});

const App = ({ getUserColorClassName, isAnyThemeLoaded, locale, me, showGlobalSearch }: Props) => {
  const antDLocale = i18nService.getAntDLocale(locale);
  const intl = i18nService.getIntl();

  const history = useHistory();
  useEffect(() => history.listen(() => analyticsService.page()), [history]);

  useEffect(() => {
    const shortcutService = new ShortcutService(document.body);
    shortcutService.addKeyListener(
      { code: "KeyF", metaKey: true, shiftKey: true, type: "keydown" },
      () => showGlobalSearch()
    );
    return () => shortcutService.destroy();
  }, [showGlobalSearch]);

  const formatMessage = useCallback(
    (messageId: keyof typeof messages, values?: Record<string, PrimitiveType>) =>
      messageId in messages ? intl?.formatMessage(messages[messageId], values) : undefined,
    [intl]
  );

  if (!intl || !isAnyThemeLoaded) {
    return null;
  }

  return (
    <RawIntlProvider value={intl}>
      <ConfigProvider locale={antDLocale}>
        <UiConfigProvider
          dateFnsLocale={dateFnsLocaleService.locale}
          formatMessage={formatMessage}
          intl={intl}
          complementFileUrl={complementFileUrl}
          getUserColorClassName={getUserColorClassName}
        >
          <AnalyticsContext.Provider value={analyticsService}>
            <EntityLinkGetterContext.Provider value={defaultEntityLinkGetter}>
              <FrigadeProvider
                organizationId={String(me?.organization.id ?? "public")}
                publicApiKey={configService.getFrigadeApiKey()}
                userId={String(me?.id ?? "public")}
                config={{ debug: true }}
              >
                <Switch>
                  {/*<AuthRoute path={Path.EXTERNAL_LOGIN} component={ExternalServiceLoginPage} />*/}
                  <AuthRoute path={Path.LOGIN} component={LoginPage} />
                  <AuthRoute path={Path.FORGOT_PASSWORD} component={ForgotPasswordPage} />
                  <AuthRoute path={Path.RESET_PASSWORD} component={ResetPasswordPage} />
                  <AuthRoute path={Path.SSO_LOGIN} component={SsoLoginPage} />
                  <AuthRoute path={Path.REGISTER} component={RegisterPage} />
                  <PrivateRoute
                    path={Path.MANAGE_USER_ACCESS}
                    component={ManageUserAccess}
                    layoutComponent={SsoLayout}
                  />
                  <PrivateRoute path={Path.DASHBOARD} component={DashboardPage} />
                  <PrivateRoute path={Path.ACTIVITY} component={ActivityPage} />
                  {configService.getCurrentEnvironment() !== Environment.SANDBOX && (
                    <PrivateRoute path={Path.BILLING} component={BillingPage} />
                  )}
                  <PrivateRoute path={Path.COMPANY} component={CompanyPage} />
                  <PrivateRoute path={Path.MAP} component={MapPage} />
                  <PrivateRoute path={Path.PEOPLE} component={PeoplePage} />
                  <PrivateRoute path={Path.REPORTS} component={ReportsPage} />
                  <PrivateRoute path={Path.SETTINGS} component={SettingsPage} />
                  <PrivateRoute path={Path.DEAL} component={DealPage} />
                  <PrivateRoute
                    path={`${Path.NOTIFICATIONS}/:notificationId?`}
                    component={NotificationsPage}
                  />
                  <PrivateRoute layout={false} path={Path.WEB_1} component={Web1Page} />
                  <Route path={Path.ROOT} component={RootPage} /> {/* Must be the last one */}
                </Switch>
              </FrigadeProvider>
              <GlobalCreateEntityModals />
            </EntityLinkGetterContext.Provider>
          </AnalyticsContext.Provider>
        </UiConfigProvider>
      </ConfigProvider>
    </RawIntlProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  getUserColorClassName: getUserColorClassNameGetter(state),
  isAnyThemeLoaded: isAnyThemeLoaded(state),
  locale: getLocale(state),
  localeLoading: isLoading(state),
  me: getMe(state),
});

const mapDispatchToProps = {
  showGlobalSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        padding-left: 0.25rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;
      }

      .slider {
        flex: 1;
      }

      .numberField {
        flex-shrink: 0;
        min-width: 5rem;
      }
    }
  }
}

import { useCallback, useState } from "react";
import geoService, { convertCoordinatesToLongLat } from "util/geo/GeoService";
import { defineMessage, IntlShape } from "react-intl";
import { reverseGeocodeAddress } from "store/location/actions";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";

const findMeFailedMessage = defineMessage({
  id: "useFindMyLocation.error.findMeFailed",
  defaultMessage: "Failed to find your location, check browser permissions and try again please",
  description: "Find current user position failed message in Create Route Modal",
});

const failedGeocodeAddress = defineMessage({
  id: "useFindMyLocation.error.geocodingFailed",
  defaultMessage: "Failed to find location address, please try another one",
  description: "Geocoding failed message in Create Route Modal",
});

export type FindMyResultReturnValue = [
  location: string,
  setLocation: (address: string) => void,
  loading: boolean,
  error: string | undefined,
  startGeocoding: () => void,
  geocodingResult: GeocodeResult | undefined,
  resetError: () => void
];

const useFindMyLocation = (
  intl: IntlShape,
  reverseGeocode: typeof reverseGeocodeAddress
): FindMyResultReturnValue => {
  const [location, setLocation] = useState<string>("");
  const [geocoderResult, setGeocoderResult] = useState<GeocodeResult | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [geocodingError, setGeocodingError] = useState<string | undefined>();
  const handleFindMyLocation = useCallback(() => {
    setLoading(true);
    geoService
      .getCurrentPosition()
      .then((position) => {
        reverseGeocode({
          coordinates: convertCoordinatesToLongLat(position.coords),
          callback: (result) => {
            const { address, city, postalCode, country } = result.address;
            const formattedAddress = [address, city, country, postalCode]
              .filter((item) => !!item)
              .join(", ");
            setLocation(formattedAddress);
            setGeocoderResult(result);
            setLoading(false);
          },
          failureCallback: () => {
            setGeocodingError(intl.formatMessage(failedGeocodeAddress));
            setLoading(false);
          },
        });
      })
      .catch(() => {
        setGeocodingError(intl.formatMessage(findMeFailedMessage));
        setLoading(false);
      });
  }, [intl, setLocation, setGeocodingError, reverseGeocode]);

  const resetError = useCallback(() => {
    setGeocodingError(undefined);
  }, []);

  return [
    location,
    setLocation,
    loading,
    geocodingError,
    handleFindMyLocation,
    geocoderResult,
    resetError,
  ];
};

export default useFindMyLocation;

import { defineMessages, IntlShape } from "react-intl";
import { AreaFilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import localSettings from "config/LocalSettings";
import { METERS_IN_MILE } from "util/consts";
import { isEntityValue } from "util/filters/Location/assert";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { Company, Person } from "@mapmycustomers/shared/types/entity";

const messages = defineMessages({
  si: {
    id: "filters.location.previewMessage.si",
    defaultMessage:
      "{km}km from {hasLocation, select, true {{location}} other {({latitude}, {longitude})}}",
    description: "Human readable location filter preview when using SI units",
  },
  imperial: {
    id: "filters.location.previewMessage.imperial",
    defaultMessage:
      "{miles} {miles, plural, one {mile} other {miles}} from {hasLocation, select, true {{location}} other {({latitude}, {longitude})}}",
    description: "Human readable location filter preview when using imperial units",
  },
  entitySi: {
    id: "filters.location.previewMessage.entity.si",
    defaultMessage:
      "{km}km from {entityName, select, false {{entityType, select, accounts {the Company} contacts {the Person} other {the Record}}} other {{entityName}}}",
    description:
      "Human readable location filter preview when using SI units and filtering around some record",
  },
  entityImperial: {
    id: "filters.location.previewMessage.entity.imperial",
    defaultMessage:
      "{miles} {miles, plural, one {mile} other {miles}} from {entityName, select, false {{entityType, select, accounts {the Company} contacts {the Person} other {the Record}}} other {{entityName}}}",
    description:
      "Human readable location filter preview when using imperial units and filtering around some record",
  },
});

const getLocationPreview = (
  intl: IntlShape | undefined,
  value: AreaFilterCondition["value"],
  entity?: Company | Person
): string => {
  const useSiUnits = localSettings.doesUseSiUnits();
  if (!intl) {
    if (isEntityValue(value)) {
      const name =
        entity?.name ?? value.entity.type === EntityType.COMPANY
          ? "the Company"
          : value.entity.type === EntityType.PERSON
          ? "the Person"
          : "the Record";
      return useSiUnits
        ? `${value.distanceInMeters / 1000}km from ${name}`
        : `${value.distanceInMeters / METERS_IN_MILE} miles from ${name}`;
    } else {
      return useSiUnits
        ? `${value.distanceInMeters / 1000}km from ${
            value.location ?? `(${value.latitude}, ${value.longitude})`
          }`
        : `${value.distanceInMeters / METERS_IN_MILE} miles from ${
            value.location ?? `(${value.latitude}, ${value.longitude})`
          }`;
    }
  }

  if (isEntityValue(value)) {
    return intl.formatMessage(useSiUnits ? messages.entitySi : messages.entityImperial, {
      km: value.distanceInMeters / 1000,
      miles: value.distanceInMeters / METERS_IN_MILE,
      entityType: value.entity.type,
      entityName: entity?.name ?? false,
    });
  } else {
    let latitude;
    let longitude;

    if (!value.location) {
      // better format for numbers if we're gonna display then (and we're gonna since condition has no location string)
      latitude = intl.formatNumber(value.latitude, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 6,
      });
      longitude = intl.formatNumber(value.longitude, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 6,
      });
    }

    return intl.formatMessage(useSiUnits ? messages.si : messages.imperial, {
      km: value.distanceInMeters / 1000,
      miles: value.distanceInMeters / METERS_IN_MILE,
      hasLocation: !!value.location,
      location: value.location,
      latitude,
      longitude,
    });
  }
};

export default getLocationPreview;

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { Activity, Company, Deal, Group, Person, Route } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import Note from "@mapmycustomers/shared/types/entity/common/Note";
import FileListItem from "types/FileListItem";
import { RawFile } from "@mapmycustomers/shared/types/File";
import { OnlyRequiredFields } from "@mapmycustomers/shared/util/ts";
import { PersonPayload } from "store/api/ApiService";
import PersonRecordData from "./PersonRecordData";
import RecapRange from "enum/preview/RecapRange";
import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import User from "@mapmycustomers/shared/types/User";
import SortModel from "@mapmycustomers/shared/types/viewModel/internalModel/SortModel";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import CustomField from "@mapmycustomers/shared/types/customField/CustomField";

export const fetchPreviewData = createAsyncAction(
  "personRecord/fetchPreviewData/request",
  "personRecord/fetchPreviewData/success",
  "personRecord/fetchPreviewData/failure"
)<
  Person["id"],
  {
    recordData: PersonRecordData;
    selectedActivityTypes: ActivityType[];
  },
  void
>();

export const fetchPersonActivitiesCompletedByType = createAsyncAction(
  "personRecord/fetchPersonActivitiesCompletedByType/request",
  "personRecord/fetchPersonActivitiesCompletedByType/success",
  "personRecord/fetchPersonActivitiesCompletedByType/failure"
)<
  {
    activityTypes: ActivityType[];
    filter: ActivityStatusOption;
    order: SortModel;
    person: Person;
    recapRange: RecapRange;
    selectedAssignees: User["id"][];
  },
  Activity[],
  void
>();

export const fetchThumbnail = createAction("personRecord/thumbnail")<{
  fileId: RawFile["id"];
  callback: (data: Blob) => void;
}>();

export const changeActivitiesAssignees = createAction("personRecord/changeActivitiesAssignees")<
  User["id"][]
>();

export const changeActivitiesOrder = createAction(
  "personRecord/changeActivitiesOrder"
)<SortModel>();

export const fetchPersonActivities = createAsyncAction(
  "personRecord/fetchPersonActivities/request",
  "personRecord/fetchPersonActivities/success",
  "personRecord/fetchPersonActivities/failure"
)<
  {
    activityTypes: ActivityType[];
    filter: ActivityStatusOption;
    order: SortModel;
    person: Person;
    recapRange: RecapRange;
    search: string;
    selectedAssignees: User["id"][];
  },
  Activity[],
  void
>();

export const fetchPersonActivitiesTotal = createAsyncAction(
  "personRecord/fetchPersonActivitiesTotal/request",
  "personRecord/fetchPersonActivitiesTotal/success",
  "personRecord/fetchPersonActivitiesTotal/failure"
)<Person, number, void>();

export const fetchPerson = createAsyncAction(
  "personRecord/fetchPerson/request",
  "personRecord/fetchPerson/success",
  "personRecord/fetchPerson/failure"
)<Person, Person, void>();

export const fetchPersonRoutes = createAsyncAction(
  "personRecord/fetchPersonRoutes/request",
  "personRecord/fetchPersonRoutes/success",
  "personRecord/fetchPersonRoutes/failure"
)<Person["id"], Route[], void>();

export const createPersonNote = createAsyncAction(
  "personRecord/createPersonNote/request",
  "personRecord/createPersonNote/success",
  "personRecord/createPersonNote/failure"
)<string, Note, void>();

export const updatePersonNote = createAsyncAction(
  "personRecord/updatePersonNote/request",
  "personRecord/updatePersonNote/success",
  "personRecord/updatePersonNote/failure"
)<Note, Note, Note["id"]>();

export const deletePersonNote = createAsyncAction(
  "personRecord/deletePersonNote/request",
  "personRecord/deletePersonNote/success",
  "personRecord/deletePersonNote/failure"
)<Note, Note["id"], Note["id"]>();

export const uploadPersonFiles = createAsyncAction(
  "personRecord/uploadPersonFiles/request",
  "personRecord/uploadPersonFiles/success",
  "personRecord/uploadPersonFiles/failure"
)<
  {
    files: (Blob | File)[];
    callback?: (filesList: FileListItem[]) => void;
  },
  FileListItem[],
  void
>();

export const deletePersonFile = createAsyncAction(
  "personRecord/deletePersonFile/request",
  "personRecord/deletePersonFile/success",
  "personRecord/deletePersonFile/failure"
)<RawFile, { file: RawFile; removed: boolean }, RawFile>();

export const clearAllUploadedPersonFiles = createAction(
  "personRecord/clearAllUploadedPersonFiles"
)<void>();

export const downloadPersonFile = createAction("personRecord/downloadPersonFile")<RawFile>();

export const updatePerson = createAsyncAction(
  "personRecord/updatePerson/request",
  "personRecord/updatePerson/success",
  "personRecord/updatePerson/failure"
)<
  {
    person: OnlyRequiredFields<
      Pick<
        PersonPayload,
        | "id"
        | "color"
        | "email"
        | "phone"
        | "postalCode"
        | "region"
        | "address"
        | "city"
        | "countryCode"
        | "user"
        | "country"
        | "geoAddress"
        | "geoPoint"
        | "geoManagementState"
      >,
      "id"
    >;
    customFields?: Record<CustomField["esKey"], CustomFieldValue>;
    callback?: (updatedPerson: Person) => void;
    groupIdsToAdd?: Group["id"][];
    groupIdsToRemove?: Group["id"][];
    layoutId?: FormLayout["id"];
  },
  Person,
  void
>();

export const addPersonCompanies = createAsyncAction(
  "personRecord/addPersonCompanies/request",
  "personRecord/addPersonCompanies/success",
  "personRecord/addPersonCompanies/failure"
)<
  { id: Person["id"]; companyIds: Company["id"][] },
  { companies: Company[]; total: number },
  void
>();

export const removePersonCompany = createAsyncAction(
  "personRecord/removePersonCompany/request",
  "personRecord/removePersonCompany/success",
  "personRecord/removePersonCompany/failure"
)<{ id: Person["id"]; companyId: Company["id"]; layoutId: FormLayout["id"] }, Company[], void>();

export const addPersonDeals = createAsyncAction(
  "personRecord/addPersonDeals/request",
  "personRecord/addPersonDeals/success",
  "personRecord/addPersonDeals/failure"
)<
  { id: Person["id"]; dealIds?: Array<Deal["id"]>; removedDealsIds?: Deal["id"][] },
  { deals: Deal[]; total: number },
  void
>();

export const removePersonDeal = createAsyncAction(
  "personRecord/removePersonDeal/request",
  "personRecord/removePersonDeal/success",
  "personRecord/removePersonDeal/failure"
)<Deal["id"], Deal["id"], Deal["id"]>();

export const removePersonFromRoute = createAsyncAction(
  "personRecord/removePersonFromRoute/request",
  "personRecord/removePersonFromRoute/success",
  "personRecord/removePersonFromRoute/failure"
)<{ id: Person["id"]; routeId: Route["id"] }, Route["id"], Route["id"]>();

export const toggleCompleteActivity = createAsyncAction(
  "personRecord/toggleCompleteActivity/request",
  "personRecord/toggleCompleteActivity/success",
  "personRecord/toggleCompleteActivity/failure"
)<Activity, Pick<Activity, "id" | "completed" | "completedAt">, Activity["id"]>();

export const postponeActivity = createAsyncAction(
  "personRecord/postponeActivity/request",
  "personRecord/postponeActivity/success",
  "personRecord/postponeActivity/failure"
)<Activity, Activity, Activity["id"]>();

export const updateActivityNote = createAsyncAction(
  "personRecord/updateActivityNote/request",
  "personRecord/updateActivityNote/success",
  "personRecord/updateActivityNote/failure"
)<{ activity: Activity; onSuccess: () => void }, Pick<Activity, "id" | "note">, Activity["id"]>();

export const changeActivitiesSearchQuery = createAction(
  "personRecord/changeActivitiesSearchQuery"
)<string>();
export const changeActivitiesFilter = createAction(
  "personRecord/changeActivitiesFilter"
)<ActivityStatusOption>();
export const changeActivitiesRecapRange = createAction(
  "personRecord/changeActivitiesRecapRange"
)<RecapRange>();
export const changeActivitiesSelectedActivityTypes = createAction(
  "personRecord/changeActivitiesSelectedActivityTypes"
)<ActivityType[]>();

export const deletePerson = createAsyncAction(
  "personRecord/deletePerson/request",
  "personRecord/deletePerson/success",
  "personRecord/deletePerson/failure"
)<void, void, void>();

export const setActivityElementId = createAction("personRecord/setActivityElementId")<string>();

export const setActivityAutoScrolling = createAction(
  "personRecord/setActivityAutoScrolling"
)<boolean>();

export const setHasChangesFlag = createAction("personRecord/setHasChangesFlag")<boolean>();

export const updatePersonFrequency = createAsyncAction(
  "personRecord/updatePersonFrequency/request",
  "personRecord/updatePersonFrequency/success",
  "personRecord/updatePersonFrequency/failure"
)<
  {
    personId: Person["id"];
  },
  Person,
  void
>();

export const fetchMoreDeals = createAsyncAction(
  "personRecord/fetchMoreDeals/request",
  "personRecord/fetchMoreDeals/success",
  "personRecord/fetchMoreDeals/failure"
)<void, Deal[], void>();

export const fetchMoreCompanies = createAsyncAction(
  "personRecord/fetchMoreCompanies/request",
  "personRecord/fetchMoreCompanies/success",
  "personRecord/fetchMoreCompanies/failure"
)<void, Company[], void>();

export type Actions = ActionType<
  | typeof addPersonCompanies
  | typeof addPersonDeals
  | typeof changeActivitiesAssignees
  | typeof changeActivitiesFilter
  | typeof changeActivitiesOrder
  | typeof changeActivitiesRecapRange
  | typeof changeActivitiesSearchQuery
  | typeof changeActivitiesSelectedActivityTypes
  | typeof clearAllUploadedPersonFiles
  | typeof createPersonNote
  | typeof deletePerson
  | typeof deletePersonFile
  | typeof deletePersonNote
  | typeof downloadPersonFile
  | typeof fetchMoreCompanies
  | typeof fetchMoreDeals
  | typeof fetchPerson
  | typeof fetchPersonActivities
  | typeof fetchPersonActivitiesCompletedByType
  | typeof fetchPersonActivitiesTotal
  | typeof fetchPersonRoutes
  | typeof fetchPreviewData
  | typeof fetchThumbnail
  | typeof postponeActivity
  | typeof removePersonCompany
  | typeof removePersonDeal
  | typeof removePersonFromRoute
  | typeof setActivityAutoScrolling
  | typeof setActivityElementId
  | typeof setHasChangesFlag
  | typeof toggleCompleteActivity
  | typeof updateActivityNote
  | typeof updatePerson
  | typeof updatePersonFrequency
  | typeof updatePersonNote
  | typeof uploadPersonFiles
>;

import React, { useEffect, useRef } from "react";
import { Labeled, LabeledFieldProps } from "@mapmycustomers/ui";
import poweredByGoogle from "assets/google/poweredByGoogle.png";
import { useIntl } from "react-intl";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import Address from "@mapmycustomers/shared/types/Address";
import GoogleAutoCompleteAddress from "./components/GoogleAutoCompleteAddress";
import ButtonLink from "component/ButtonLink";
import ManualAddress from "./ManualAddress";
import usePlaceDetailsGetter from "./utils/usePlacesService";
import geoService, { convertCoordinatesToLongLat } from "../../../util/geo/GeoService";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import { reverseGeocodeAddress } from "store/location/actions";
import { connect } from "react-redux";
import styles from "./AutoCompleteAddress.module.scss";

interface Props extends Omit<LabeledFieldProps, "children" | "className"> {
  allowAutoCompleteClear?: boolean;
  className?: string;
  disabled?: boolean;
  onChange?: (address?: Address) => void;
  onReverseGeocodeAddress: typeof reverseGeocodeAddress;
  value?: Address;
}

const AutoCompleteAddress: React.FC<Props> = ({
  allowAutoCompleteClear,
  className,
  disabled,
  onChange,
  onReverseGeocodeAddress,
  value,
  ...labeledProps
}) => {
  const intl = useIntl();
  const [isManualAddress, switchToManualAddress, , toggleManualAddress] = useBoolean();

  const alreadyDetectedCountryCode = useRef(false);
  const [placeDetailsGetter] = usePlaceDetailsGetter();

  const updateCountry = useDynamicCallback((countryCode: string) =>
    onChange?.({ ...value, countryCode })
  );

  const handleEnterManual = useDynamicCallback((address: string) => {
    onChange?.({ ...value, address });
    switchToManualAddress();
  });

  useEffect(() => {
    if (!value?.countryCode && isManualAddress && !alreadyDetectedCountryCode.current) {
      alreadyDetectedCountryCode.current = true;
      // no catches here because we don't care if anything goes wrong

      geoService.getCurrentPosition().then((position) => {
        onReverseGeocodeAddress({
          callback: (result) => {
            if (result.address.countryCode) {
              updateCountry(result.address.countryCode);
            }
          },
          coordinates: convertCoordinatesToLongLat(position.coords),
        });
      });
    }
  }, [
    isManualAddress,
    onChange,
    placeDetailsGetter,
    updateCountry,
    onReverseGeocodeAddress,
    value,
  ]);

  return (
    <Labeled
      className={className}
      {...labeledProps}
      extra={
        isManualAddress ? (
          <ButtonLink className={styles.searchManualButton} onClick={toggleManualAddress}>
            {intl.formatMessage({
              id: "autoCompleteAddress.button.searchGoogle",
              defaultMessage: "Search with Google",
              description: "Search with Google button for auto-complete address field",
            })}
          </ButtonLink>
        ) : (
          <img alt="powered by Google" src={poweredByGoogle} />
        )
      }
    >
      {isManualAddress ? (
        <ManualAddress disabled={disabled} onChange={onChange} value={value} />
      ) : (
        <GoogleAutoCompleteAddress
          allowClear={allowAutoCompleteClear}
          disabled={disabled}
          onChange={onChange}
          onEnterManually={handleEnterManual}
          value={value}
        />
      )}
    </Labeled>
  );
};

const mapDispatchToProps = {
  onReverseGeocodeAddress: reverseGeocodeAddress,
};

export default connect(null, mapDispatchToProps)(AutoCompleteAddress);

import { put, select, takeLatest } from "redux-saga/effects";
import { doGlobalSearch } from "./actions";
import { handleError } from "../../../store/errors/actions";
import { callApi } from "../../../store/api/callApi";
import { getOrganization } from "../../../store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import TextSearchResult from "../../../types/TextSearchResult";

export function* onGlobalSearch({
  payload: searchText,
}: ReturnType<typeof doGlobalSearch.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const searchResults: ListResponse<TextSearchResult> = yield callApi(
      "search",
      org.id,
      searchText
    );
    yield put(doGlobalSearch.success(searchResults.data));
  } catch (error) {
    yield put(doGlobalSearch.failure());
    yield put(handleError({ error }));
  }
}

export function* navigationBarSaga() {
  yield takeLatest(doGlobalSearch.request, onGlobalSearch);
}

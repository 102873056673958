import React, { ElementType, Fragment } from "react";

interface Props {
  children: string;
  wrapperProps?: any;
  wrapLinesWith?: ElementType;
}

const MultilineText: React.FC<Props> = ({
  children,
  wrapLinesWith: Wrapper = Fragment,
  wrapperProps,
}) => {
  return (
    <Fragment>
      {children.split("\n").map((item, key) => (
        <Fragment key={key}>
          <Wrapper {...wrapperProps}>{item}</Wrapper>
          <br />
        </Fragment>
      ))}
    </Fragment>
  );
};

export default MultilineText;

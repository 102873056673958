import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";

const userLocationsState = (state: RootState) => state.scene.map.layers.userLocations;

export const getUserLocationGroups = createSelector(
  userLocationsState,
  ({ locationGroups }) => locationGroups
);

export const getFirstLocationId = createSelector(
  userLocationsState,
  ({ firstLocationId }) => firstLocationId
);
export const isUserLocationLoading = createSelector(userLocationsState, ({ loading }) => loading);

export const getDisabledUserIds = createSelector(
  userLocationsState,
  ({ disabledUserIds }) => disabledUserIds
);

export const getHiddenUserIds = createSelector(
  userLocationsState,
  ({ hiddenUserIds }) => hiddenUserIds
);

export const getAllHiddenUserIds = createSelector(
  getDisabledUserIds,
  getHiddenUserIds,
  (disabledUserIds, hiddenUserIds) => new Set([...hiddenUserIds, ...disabledUserIds])
);

export const getSelectedUser = createSelector(
  userLocationsState,
  ({ selectedUser }) => selectedUser
);
export const getHighlightedLocationGroup = createSelector(
  userLocationsState,
  ({ highlightedLocationGroupId }) => highlightedLocationGroupId
);
export const getLastLocationWithoutPeriod = createSelector(
  userLocationsState,
  ({ lastLocationWithoutPeriod }) => lastLocationWithoutPeriod
);
export const isSideBarInitializing = createSelector(
  userLocationsState,
  ({ initializing }) => initializing
);

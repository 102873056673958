import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { EntityTypeSupportingGroups, Group, MapEntity } from "@mapmycustomers/shared/types/entity";
import MapViewportState from "types/map/MapViewportState";
import { MapFetcherPayload } from "scene/map/types/MapFetcher";
import { CategorizedMapEntries } from "types/map";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import User from "@mapmycustomers/shared/types/User";
import DataViewEntity from "component/DataView/type/DataViewEntity";
import ColumnModel from "@mapmycustomers/shared/types/viewModel/internalModel/ColumnModel";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

export const enterGroupMode = createAction("map/group/enter")<{
  entityType: EntityTypeSupportingGroups;
  groupId: Group["id"];
  group?: Group; // useful to specify if you do have group already, helps UI to update faster
}>();

export const exitGroupMode = createAsyncAction(
  "map/group/exit/request",
  "map/group/exit/success",
  "map/group/exit/failure"
)<true | void, void, void>(); // arg for request is whether we should forceExit or not

export const initializeGroupsMode = createAsyncAction(
  "map/group/initialize/request",
  "map/group/initialize/success",
  "map/group/initialize/failure"
)<
  {
    entityType: EntityTypeSupportingGroups;
    groupId: Group["id"];
    viewport: MapViewportState;
    callback?: (bounds: google.maps.LatLngBounds) => void;
  },
  {
    entityType: EntityTypeSupportingGroups;
    group: Group;
  },
  void
>();

export const applyGroupFilters = createAction("map/group/applyGroupFilters")<void>();

export const applyGroupsMapViewSettings = createAction("map/group/applyMapViewSettings")<
  Partial<MapViewState>
>();

export const fetchGroupPins = createAsyncAction(
  "map/group/fetchPins/request",
  "map/group/fetchPins/success",
  "map/group/fetchPins/failure"
)<
  MapFetcherPayload,
  CategorizedMapEntries & {
    pinsCount: number;
  },
  unknown
>();

export const fetchGroupOtherPins = createAsyncAction(
  "map/group/fetchGroupOtherPins/request",
  "map/group/fetchGroupOtherPins/success",
  "map/group/fetchGroupOtherPins/failure"
)<unknown, CategorizedMapEntries, unknown>();

export const fetchGroupRecords = createAsyncAction(
  "map/group/fetchRecords/request",
  "map/group/fetchRecords/success",
  "map/group/fetchRecords/failure"
)<
  Partial<Pick<MapViewState, "range" | "search" | "sort">>,
  {
    records: MapEntity[];
    recordsCount: number;
  },
  unknown
>();

export const fetchGroupLassoSelection = createAsyncAction(
  "map/group/fetchGroupLassoSelection/request",
  "map/group/fetchGroupLassoSelection/success",
  "map/group/fetchGroupLassoSelection/failure"
)<
  MapFetcherPayload,
  {
    records: MapEntity[];
  },
  unknown
>();
export const exitGroupLassoMode = createAction("map/group/exitGroupLassoMode")<void>();

export const updateGroup = createAsyncAction(
  "map/group/updateGroup/request",
  "map/group/updateGroup/success",
  "map/group/updateGroup/failure"
)<
  {
    group: Group;
    onSuccess?: (updatedGroup: Group) => void;
    noNotification?: boolean;
  },
  Group,
  void
>();

export const fetchGroup = createAsyncAction(
  "map/group/fetchGroup/request",
  "map/group/fetchGroup/success",
  "map/group/fetchGroup/failure"
)<
  {
    groupId: Group["id"];
    entityType: EntityTypeSupportingGroups;
  },
  Group,
  void
>();

export const updateGroupSharing = createAsyncAction(
  "map/group/updateGroupSharing/request",
  "map/group/updateGroupSharing/success",
  "map/group/updateGroupSharing/failure"
)<
  {
    group: Group;
    onSuccess?: (updatedGroup: Group) => void;
    userIdsToShareWith: User["id"][];
  },
  Group,
  void
>();

export const bulkAddPinsToGroup = createAsyncAction(
  "map/group/bulkAddPinsToGroup/request",
  "map/group/bulkAddPinsToGroup/success",
  "map/group/bulkAddPinsToGroup/failure"
)<
  {
    entities: Array<MapEntity>;
  },
  unknown,
  unknown
>();

export const bulkRemovePinsFromGroup = createAsyncAction(
  "map/group/bulkRemovePinsFromGroup/request",
  "map/group/bulkRemovePinsFromGroup/success",
  "map/group/bulkRemovePinsFromGroup/failure"
)<
  {
    entities: Array<MapEntity>;
  },
  unknown,
  unknown
>();

export const setGroupShowOtherPins = createAction("map/group/setGroupShowOtherPins")<{
  showOtherPins: boolean;
}>();

export const setGroupEditFormHasChanges = createAction(
  "map/group/setEditFormHasChanges"
)<boolean>();

export const fetchDataViewEntities = createAsyncAction(
  "map/group/fetchDataViewEntities/request",
  "map/group/fetchDataViewEntities/success",
  "map/group/fetchDataViewEntities/failure"
)<void, DataViewEntity[], unknown>();

export const downloadGroupRecords = createAction("map/group/downloadGroupRecords")<
  ColumnModel<IField>
>();

export type GroupModeActions = ActionType<
  | typeof applyGroupFilters
  | typeof applyGroupsMapViewSettings
  | typeof bulkAddPinsToGroup
  | typeof bulkRemovePinsFromGroup
  | typeof enterGroupMode
  | typeof exitGroupLassoMode
  | typeof exitGroupMode
  | typeof fetchDataViewEntities
  | typeof fetchGroup
  | typeof fetchGroupLassoSelection
  | typeof fetchGroupOtherPins
  | typeof fetchGroupPins
  | typeof fetchGroupRecords
  | typeof initializeGroupsMode
  | typeof setGroupEditFormHasChanges
  | typeof setGroupShowOtherPins
  | typeof updateGroup
  | typeof updateGroupSharing
  | typeof downloadGroupRecords
>;

import EntityType from "@mapmycustomers/shared/enum/EntityType";
import noCompaniesImage from "assets/list/preview/no-companies.svg";
import noPeopleImage from "assets/list/preview/no-people.svg";
import noDealsImage from "assets/list/preview/no-deals.svg";

const noRecordsImageMap = {
  [EntityType.COMPANY]: noCompaniesImage,
  [EntityType.DEAL]: noDealsImage,
  [EntityType.PERSON]: noPeopleImage,
} as const;

export default noRecordsImageMap;

import { useMemo } from "react";
import ActivityVisibility from "@mapmycustomers/shared/enum/activity/ActivityVisibility";
import { useIntl } from "react-intl";
import getVisibilityDisplayName from "util/ui/getVisibilityDisplayName";

const useActivityVisibilityOptions = (
  isOwner: boolean = false,
  isManager: boolean = false
): { value: ActivityVisibility; label: string }[] => {
  const intl = useIntl();

  return useMemo(() => {
    const availableVisibilities: ActivityVisibility[] = isOwner
      ? [
          ActivityVisibility.SHARED_WITH_ORGANIZATION,
          ActivityVisibility.SHARED_WITH_TEAM,
          ActivityVisibility.PRIVATE,
        ]
      : isManager
      ? [ActivityVisibility.SHARED_WITH_TEAM, ActivityVisibility.PRIVATE]
      : [ActivityVisibility.PRIVATE];

    return availableVisibilities.map((visibility) => ({
      value: visibility,
      label: getVisibilityDisplayName(intl, visibility),
    }));
  }, [intl, isManager, isOwner]);
};

export default useActivityVisibilityOptions;

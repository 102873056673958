import { createReducer, getType } from "typesafe-actions";
import {
  LegendsActions,
  setLegendsState,
  toggleLegendExpandedState,
  toggleLegendVisibility,
} from "./actions";
import LegendState from "scene/map/types/LegendState";
import Legend from "scene/map/enums/Legend";

export interface LegendsState extends Partial<Record<Legend, LegendState>> {}

// this state doesn't include layer legends, those are managed by layers store
export const legendsInitialState: LegendsState = {
  [Legend.COLOR_KEY]: {
    visible: true,
    expanded: true,
  },
  [Legend.SHAPE_KEY]: {
    visible: true,
    expanded: true,
  },
};

const legends = createReducer<LegendsState, LegendsActions>(legendsInitialState)
  .handleType(getType(toggleLegendVisibility), (state, { payload }) => ({
    ...state,
    [payload.name]: {
      ...state[payload.name],
      visible: payload.visible,
    },
  }))
  .handleType(getType(toggleLegendExpandedState), (state, { payload }) => ({
    ...state,
    [payload.name]: {
      ...state[payload.name],
      expanded: payload.expanded,
    },
  }))
  .handleType(getType(setLegendsState), (state, { payload }) => ({
    ...state,
    ...(payload as LegendsState),
  }));

export default legends;

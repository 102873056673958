import Activity from "@mapmycustomers/shared/types/entity/Activity";
import { EventInput } from "@fullcalendar/common";
import { parseApiDate } from "util/parsers";
import isValidDate from "util/isValidDate";
import { formatDate } from "util/formatters";
import ActivitySuggestion from "@mapmycustomers/shared/types/entity/ActivitySuggestion";
import EventObject from "../type/EventObject";

const activityToEvent = (
  activity: Activity,
  nearbySuggestionsMap: Map<Activity["id"], ActivitySuggestion>
): EventInput => {
  const result: EventInput = {
    allDay: activity.allDay,
    className: `act_${activity.id}`,
    end: activity.endAt ?? undefined,
    extendedProps: {
      activity,
    } as EventObject,
    groupId: String(activity.parentId ?? activity.id),
    id: String(activity.id),
    start: activity.startAt ?? undefined,
    title: activity.name ?? activity.note,
  };

  // for allDay activity, let's ensure that end date is not defined and that start date
  // will be of a correct date, taking user's TZ into account
  if (activity.allDay) {
    const startAt = activity.startAt ? parseApiDate(activity.startAt) : undefined;
    result.start = isValidDate(startAt) ? formatDate(startAt, "yyyy-MM-dd") : undefined;
    result.end = undefined;
  }

  if (nearbySuggestionsMap.has(activity.id)) {
    result.extendedProps!.suggestion = nearbySuggestionsMap.get(activity.id);
  }

  return result;
};

export default activityToEvent;

import React, { MouseEvent, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { AnalyticsService } from "util/analytic/AnalyticsService";
import { formatEmailLink } from "util/formatters";

interface Props {
  analyticService?: AnalyticsService;
  className?: string;
  showIcon?: boolean;
  value: string | null | undefined;
}

const Email: React.FC<Props> = ({ analyticService, className, showIcon = true, value }) => {
  // To avoid bubbling up
  // It's not a rare event when this component is used inside some table with a Row click
  // handler. And we don't want the table to catch clicking on the row when user is actually
  // clicking on this link.
  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      analyticService?.clicked(["Email Click"], { email: value });
    },
    [analyticService, value]
  );

  if (!value) {
    return <>{""}</>;
  }

  return (
    <div className={className}>
      <a
        href={formatEmailLink(value as string)}
        onClick={handleClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        {value}
        {showIcon && (
          <>
            &nbsp;
            <FontAwesomeIcon icon={faEnvelope} size="xs" />
          </>
        )}
      </a>
    </div>
  );
};

export default Email;

import RecurringIntervalType from "../enum/RecurringIntervalType";
import { LabeledValue } from "antd/es/select";
import { IntlShape } from "react-intl";
import omit from "lodash/fp/omit";
import { recurringIntervalMessages } from "../component/messages";

export const predefinedIntervals = Object.values<RecurringIntervalType>(RecurringIntervalType);

export const getRecurIntervalOptions = (
  intl: IntlShape,
  recurIntervalType: RecurringIntervalType
): LabeledValue[] => {
  const availableRecurringIntervals =
    recurIntervalType === RecurringIntervalType.NEVER
      ? [[RecurringIntervalType.NEVER, RecurringIntervalType.NEVER]]
      : (Object.entries(omit(RecurringIntervalType.NEVER, RecurringIntervalType)) as [
          RecurringIntervalType,
          RecurringIntervalType
        ][]);
  return availableRecurringIntervals.map<LabeledValue>(([name, value]) => ({
    key: name,
    label: intl.formatMessage(recurringIntervalMessages[value]),
    value,
  }));
};

import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const outOfFrequencyState = (state: RootState) => state.scene.dashboard.outOfFrequencyState;

export const getOutOfFrequencyDrillDownViewStates = createSelector(
  outOfFrequencyState,
  ({ viewStates }) => viewStates
);

export const getOutOfFrequencyDrillDownTotalFilteredRecords = createSelector(
  outOfFrequencyState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getOutOfFrequencyDrillDownTotalRecords = createSelector(
  outOfFrequencyState,
  ({ totalRecords }) => totalRecords
);

export const getOutOfFrequencyDrillDownConfiguration = createSelector(
  outOfFrequencyState,
  ({ configuration }) => configuration
);

export const isOutOfFrequencyDrillDownLoading = createSelector(
  outOfFrequencyState,
  ({ loading }) => loading
);

@use "../styles/colors";

.container {
  position: relative;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.toolbar {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  left: 1rem;
  box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.06);
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.sizeSmall {
    font-size: 0.75rem;
}

.sizeMedium {
  font-size: 0.875rem;
}

.sizeLarge {
  font-size: 1.25rem;
}

.editor {
  width: 100%;
  font-size: 1rem;
  border-radius: 0.25rem;
  min-height: 400px;
  padding: 0.25rem 0.75rem;
  resize: vertical;
  overflow-y: auto;
  &:not([disabled]):focus,
  &:not(:disabled):focus {
    border-color: rgb(154, 182, 244);
    box-shadow: 0 0 0 0.125rem rgba(100, 138, 235, 0.2);
  }
  p {
    margin-bottom: 0;
  }
  &[disabled],
  &:disabled {
    background: #f5f5f5; // mimic default "disabled" background for inputs
    color: colors.$mmc-disabled-gray;
    outline: none;

    & [data-slate-placeholder] {
      opacity: 1 !important;
    }
  }
}

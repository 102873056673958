import React from "react";
import User from "@mapmycustomers/shared/types/User";
import Tooltip from "antd/es/tooltip";
import styles from "./Lexer.module.scss";
import { userDisplayName } from "util/formatters";
import cn from "classnames";

const Lexer: React.FC<{
  children: string;
  isMentionDisabled?: (user: User) => boolean;
  mentionClassName?: string;
  users?: User[];
}> = React.memo(({ children, isMentionDisabled, mentionClassName, users = [] }) => {
  let s = children;
  const tokens: React.ReactNode[] = [];

  while (s.length > 0) {
    {
      const parts = s.split(/(@\S+)/);
      const head = parts[0];
      const mention = parts[1] ?? "";
      const tail = parts.slice(2).join(" ");

      if (head === "" && mention.length > 0) {
        const user = users.find(({ username }) => username === mention.slice(1));
        tokens.push(
          <Tooltip
            className={cn(styles.mention, mentionClassName, {
              [styles.mentionDisabled]: user && isMentionDisabled ? isMentionDisabled(user) : false,
            })}
            overlayClassName={styles.mentionOverlay}
            key={`token-mention__${tail.length}`}
            title={mention.slice(1)}
          >
            {user ? userDisplayName(user) : mention}
          </Tooltip>
        );
        s = tail;

        continue;
      }
    }
    {
      const [head, phone, tail] = s.split(
        /^([\d+)(]*[\s.-]*[\d)()]{1,3}[\s.-]*[\d]{1,4}[\s.-]*[\d]{4})/,
        3
      );

      if (head === "" && phone.length > 0) {
        tokens.push(
          <a
            key={`token-phone__${tail.length}`}
            href={`tel:${phone.startsWith("+") ? phone : `+${phone}`}`}
          >
            {phone}
          </a>
        );
        s = tail;

        continue;
      }
    }

    {
      const [head, email, tail] = s.split(
        /^([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)/,
        3
      );

      if (head === "" && email.length > 3) {
        tokens.push(
          <a key={`token-email__${tail.length}`} href={`mailto:${email}`}>
            {email}
          </a>
        );
        s = tail;

        continue;
      }
    }

    {
      const [head, url, tail] = s.split(
        /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
        3
      );

      if (head === "" && url.length > 6) {
        tokens.push(
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            key={`token-url__${tail.length}`}
            href={url.includes("http") ? url : "http://" + url}
            target="_blank"
          >
            {url}
          </a>
        );
        s = tail;

        continue;
      }
    }

    if (tokens.length > 0 && typeof tokens[tokens.length - 1] === "string") {
      tokens[tokens.length - 1] += s[0];
    } else {
      tokens.push(s[0]);
    }
    s = s.substring(1);
  }

  return <React.Fragment>{tokens}</React.Fragment>;
});

export default Lexer;

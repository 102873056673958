import { useCallback, useMemo, useState } from "react";
import debounce from "lodash-es/debounce";
import { isFunction } from "lodash-es";

const useStateWithDebouncedListener = <T>(
  debounceOptions: Parameters<typeof debounce>,
  initialState: T
): [
  state: T,
  updateState: (value: T | ((prevValue: T) => T)) => void,
  updateStateWithoutListener: (value: T) => void
] => {
  const [state, setState] = useState<T>(initialState);
  const debouncedListener = useMemo(
    () => debounce(...debounceOptions),
    debounceOptions // eslint-disable-line
  );
  const updateState = useCallback(
    (value: T | ((prevValue: T) => T)) => {
      setState((previousValue) => {
        const newValue = isFunction(value) ? value(previousValue) : value;
        debouncedListener(newValue);
        return newValue;
      });
    },
    [debouncedListener]
  );

  return [state, updateState, setState];
};

export default useStateWithDebouncedListener;

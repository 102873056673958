@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        margin-top: 0.25rem;
        color: colors.$mmc-red-100;
        font-size: 0.875rem;
      }
    }
  }
}

import { isAfter } from "date-fns/esm";
import { parseApiDateAsNumber } from "./parsers";
import Organization from "@mapmycustomers/shared/types/Organization";

const hasTrialExpired = (organization?: Organization) =>
  organization &&
  organization.plan.trialing &&
  isAfter(Date.now(), parseApiDateAsNumber(organization.trialExpiresAt));

export default hasTrialExpired;

import Visibility from "@mapmycustomers/shared/enum/Visibility";
import { defineMessages, IntlShape } from "react-intl";

export const messages = defineMessages<Visibility>({
  [Visibility.PRIVATE]: {
    id: "visibility.private",
    defaultMessage: "Only me",
    description: "Private option for the pin legend visibility",
  },
  [Visibility.SHARED_WITH_TEAM]: {
    id: "visibility.sharedWithTeams",
    defaultMessage: "Specific Team(s)",
    description: "Shared With Team option for the pin legend visibility",
  },
  [Visibility.SHARED_WITH_ORGANIZATION]: {
    id: "visibility.sharedWithOrganization",
    defaultMessage: "Entire Organization",
    description: "Entire Org option for the pin legend visibility",
  },
  [Visibility.SHARED_WITH_MANAGERS]: {
    id: "visibility.sharedWithManagers",
    defaultMessage: "Team managers and owner",
    description:
      "Share with managers option for the Visibility Type field on the Create Activity modal",
  },
});

const getVisibilityDisplayName = (intl: IntlShape, visibility: Visibility) =>
  intl.formatMessage(messages[visibility]);

export default getVisibilityDisplayName;

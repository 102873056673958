import React from "react";
import Tooltip from "antd/es/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDotCircle } from "@fortawesome/pro-light-svg-icons/faDotCircle";
import { TextField } from "@mapmycustomers/ui";
import { useIntl } from "react-intl";
import messages from "./messages";
import { SizeType } from "antd/es/config-provider/SizeContext";
import styles from "./CommonStyles.module.scss";

interface Props {
  error?: string;
  onChange: (address: string) => void;
  onFindMe: () => void;
  size?: SizeType;
  value: string;
}

const StartingLocation: React.FC<Props> = ({ error, onChange, onFindMe, size, value }) => {
  const intl = useIntl();

  return (
    <TextField
      error={error}
      label={intl.formatMessage({
        id: "createRouteForm.field.startingLocation",
        defaultMessage: "* Starting Location",
        description: "Starting Location field on the Create Route form",
      })}
      onChange={onChange}
      required
      size={size}
      suffix={
        <Tooltip title={intl.formatMessage(messages.findMeTooltip)}>
          <FontAwesomeIcon className={styles.findMeIcon} icon={faDotCircle} onClick={onFindMe} />
        </Tooltip>
      }
      value={value}
    />
  );
};

export default StartingLocation;

import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { Action } from "redux";
import { getLocation, push, RouterLocation } from "connected-react-router";
import get from "lodash-es/get";
import { isActionOf } from "typesafe-actions";
import getErrorNotificationDescription from "../../utils/getErrorNotificationDescription";
import { convertToPlatformFilterModel } from "util/viewModel/convertToPlatformFilterModel";
import {
  addToSelection,
  applyMapViewSettings,
  applyRecordsListSettings,
  clearRecordsList,
  downloadMapRecords,
  fetchPins,
  fetchRecords,
  hideAnnotation,
  hideMapEntityView,
  hideSidebar,
  initializeMapMode,
  removeFromSelection,
  resetRecordsListPagination,
  setFocusedEntity,
  setPinHover,
  setSelection,
  showAnnotation,
  showMultiPinRecords,
  showSidebar,
  updateRecordsListCustomization,
} from "./actions";
import { handleError } from "store/errors/actions";
import {
  doesEntityViewHasChanges,
  getGroupMapViewState,
  getLassoMode,
  getListViewLocationQuery,
  getMapEntries,
  getMapMode,
  getMapModeBasePath,
  getMapRecordsListSortOrder,
  getMapViewport,
  getMapViewState,
  getMapViewTool,
  getMultiPin,
  getRecords,
  getRecordsListFilter,
  getRecordsListState,
  getSelectedRecords,
  getTerritoryMapViewState,
  getVisibleLassoEntityTypes,
  isRecordsListFiltered,
  isSidebarVisible,
} from "scene/map/store/selectors";
import { getAllColorLegends, getAllShapeLegends } from "store/pinLegends";
import {
  getCurrentUser,
  getFeatures,
  getOrganizationId,
  getOrganizationSettingValue,
  getPosition,
  isBigOrganization,
} from "store/iam";
import Organization, { OrganizationMetaData } from "@mapmycustomers/shared/types/Organization";
import { convertToPlatformSortModel } from "util/viewModel/convertSort";
import { callApi } from "store/api/callApi";
import {
  EntityType,
  EntityTypesSupportedByMapsPage,
  MapEntity,
} from "@mapmycustomers/shared/types/entity";
import {
  CategorizedMapEntries,
  EntityPin,
  MapPinsResponse,
  MapRecordsResponse,
  MultiPin,
} from "types/map";
import { MapPersistedRecordListConfiguration } from "@mapmycustomers/shared/types/map/types";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import MapViewportState from "types/map/MapViewportState";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import SortModel from "@mapmycustomers/shared/types/viewModel/internalModel/SortModel";
import convertViewportToPersist from "scene/map/utils/convertViewportToPersist";
import localSettings from "config/LocalSettings";
import convertMapFilterToPlatformFilterModel from "util/viewModel/convertMapFilterToPlatformFilterModel";
import {
  enterMode,
  fetchGroupLassoSelection,
  fetchLassoSelection,
  fetchTerritoryLassoSelection,
  updateLassoClusters,
  updateViewportState,
} from "scene/map/store/actions";
import categorizeMapEntries from "util/map/categorizeMapEnties";
import { mapEntityIdGetter } from "util/map/idGetters";
import { mapEntityIdParser } from "util/map/idParsers";
import getPrecision from "scene/map/utils/getPrecision";
import MapMode from "scene/map/enums/MapMode";
import MapTool from "@mapmycustomers/shared/enum/map/MapTool";
import LassoToolMode from "scene/map/enums/LassoToolMode";
import omit from "lodash-es/omit";
import { MAP_ENTITY_TYPES } from "util/map/consts";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import User from "@mapmycustomers/shared/types/User";
import { formatDate } from "util/formatters";
import Feature from "@mapmycustomers/shared/enum/Feature";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import DistanceUnit from "enum/DistanceUnit";
import OrganizationSetting from "enum/OrganizationSetting";
import { UniversalFieldName } from "util/fieldModel/universalFieldsFieldModel";
import Report from "types/Report";
import { defineMessages } from "react-intl";
import notification from "antd/es/notification";
import i18nService from "config/I18nService";
import convertMapFilterModelToFilterModelForEntity from "store/savedFilters/convertMapFilterModelToFilterModel";
import { isEntityPin } from "util/assert";
import Path from "enum/Path";
import { hideEntityView, showEntityView } from "store/entityView/actions";
import { updateMetadata } from "store/iam/actions";
import { getEntityTypesWithExternalPreview } from "store/entityView";
import getColorShapeForEntity from "util/map/markerStyles/getColorShapeForEntity";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { AreaSearchQuery } from "types/filters/AreaSearchQuery";
import {
  isAddressAreaSearchQuery,
  isCoordinatesAreaSearchQuery,
  isEntityAreaSearchQuery,
} from "component/input/AreaSearchInput/utils/assert";
import { getDefaultColorPinLegend, getDefaultShapePinLegend } from "util/pinLegend";
import { fetchPinLegends } from "store/pinLegends/actions";
import PinLegendType from "@mapmycustomers/shared/enum/PinLegendType";
import isNumber from "lodash-es/isNumber";
import { getEntityTypeDisplayName } from "util/ui";
import downloadEntitiesAsCsv from "util/file/downloadEntitiesAsCsv";
import ReportType from "enum/ReportType";
import { MAX_ITEMS_TO_DOWNLOAD_FILE } from "store/exportEntities/const";

const messages = defineMessages({
  dataFileTooLarge: {
    id: "map.recordList.exportFiles.dataFileTooLarge.warning",
    defaultMessage:
      "Data {multiple, select, true {files} other {file}} too large to download right now",
    description: "Message shown when sample companies are added",
  },
  dataFileTooLargeDescription: {
    id: "map.recordList.exportFiles.dataFileTooLarge.warning.description",
    defaultMessage:
      "Your export has been queued for processing and will be sent to your email once finished.",
    description: "Message shown when sample people are added",
  },
  success: {
    id: "map.recordList.exportFiles.success",
    defaultMessage: "{multiple, select, true {Files} other {File}} Downloaded",
    description: "Message shown when sample people are added",
  },
  error: {
    id: "map.recordList.exportFiles.error.message",
    defaultMessage: "Download Error",
    description: "Message shown when download fail",
  },
  errorDescription: {
    id: "map.recordList.exportFiles.error.description",
    defaultMessage:
      "There was a problem downloading your {entityType} data. Please try again. If this problem persists, please contact support@mapmycustomers.me.",
    description: "Description shown when download fail",
  },
});

function* onInitializeMapMode() {
  yield put(enterMode(undefined));
  yield put(initializeMapMode.success());
}

export function* onFetchPins({ payload }: ReturnType<typeof fetchPins.request>) {
  try {
    if (payload.viewport) {
      yield put(updateViewportState(payload.viewport));
      yield call(localSettings.updateViewportState, convertViewportToPersist(payload.viewport));
    }
    yield put(applyMapViewSettings(payload.request));
    const viewport: MapViewportState = yield select(getMapViewport);
    const mapViewState: MapViewState = yield select(getMapViewState);
    yield put(updateMetadata.request({ mapViewSettings: omit(mapViewState, "lasso") }));

    if (payload.updateOnly) {
      return;
    }

    const sidebarVisible: boolean = yield select(isSidebarVisible);
    const activeTool: MapTool | undefined = yield select(getMapViewTool);
    const lassoVisibleEntities: EntityTypesSupportedByMapsPage[] = yield select(
      getVisibleLassoEntityTypes
    );
    const lassoMode: LassoToolMode = yield select(getLassoMode);

    const isLassoMode = activeTool === MapTool.LASSO;
    const visibleEntityTypes =
      isLassoMode && lassoMode === LassoToolMode.ADD_GROUP_PINS
        ? lassoVisibleEntities
        : mapViewState.visibleEntities;

    const $offset = payload.request.range
      ? payload.request.range.startRow
      : mapViewState.range.startRow;
    const $limit = payload.request.range
      ? payload.request.range.endRow - payload.request.range.startRow
      : mapViewState.range.endRow - mapViewState.range.startRow;

    const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
    const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const bigOrganization: boolean = yield select(isBigOrganization);
    const requestPayload = {
      $offset,
      $limit,
      $filters: {
        pinLegends: MAP_ENTITY_TYPES.reduce(
          (result, entityType) => ({
            ...result,
            [entityType]: getColorShapeForEntity(
              mapViewState,
              colorPinLegends,
              shapePinLegends,
              entityType
            ),
          }),
          {} as Record<
            EntityTypesSupportedByMapsPage,
            { color?: PinLegend["id"]; shape?: PinLegend["id"] }
          >
        ),
        precision: getPrecision(viewport.zoom ?? 1, bigOrganization),
        precisionThreshold: 1000,
        bounds: viewport.bounds,
        cadence: true,
        includeCustomFields: true,
        includeGroups: true,
        ...convertMapFilterToPlatformFilterModel(
          mapViewState.filter,
          visibleEntityTypes,
          mapViewState.viewAs
        ),
      },
    };

    const response: MapPinsResponse = yield callApi("fetchMapPins", orgId, requestPayload);
    yield put(
      fetchPins.success({
        // if no visible entities, we still run request, but since it returns _all_ types
        // (see more about why here: https://mapmycustomers.slack.com/archives/C03PGD7BLBY/p1658160747808299?thread_ts=1658160472.031569&cid=C03PGD7BLBY
        //     This will go against the philosophy of how filters work on api today. By default,
        //     empty objects are ignored by api today. Making an exception for a certain endpoint
        //     would be problematic. I think you should not send an API request in such a case
        // )
        // we imitate like no pins were returned, however, still using total/accessible from the response
        ...categorizeMapEntries(visibleEntityTypes.length ? response.data : []),
        totalFilteredRecords: visibleEntityTypes.length ? response.total : 0,
        totalRecords: response.accessible,
      })
    );

    if (sidebarVisible) {
      yield put(resetRecordsListPagination());
      yield put(fetchRecords.request(payload));
    }

    // Refresh status of clusters which are within lasso selection
    const pins: CategorizedMapEntries = yield select(getMapEntries);
    yield put(updateLassoClusters(pins.clusters));
  } catch (error) {
    yield put(fetchPins.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchRecords({ payload }: ReturnType<typeof fetchRecords.request>) {
  try {
    yield put(applyMapViewSettings(payload.request));

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const viewport: MapViewportState = yield select(getMapViewport);
    const mapViewState: MapViewState = yield select(getMapViewState);
    const recordsListState: MapViewState = yield select(getRecordsListState);
    const activeTool: MapTool | undefined = yield select(getMapViewTool);
    const selectedRecords: Set<string> = yield select(getSelectedRecords);
    const multiPin: MultiPin | undefined = yield select(getMultiPin);
    const isFiltered: boolean = yield select(isRecordsListFiltered);
    const searchFilter: string | undefined = yield select(getRecordsListFilter);
    const mode: MapMode = yield select(getMapMode);

    const groupMapViewState: MapViewState = yield select(getGroupMapViewState);
    const territoryMapViewState: MapViewState = yield select(getTerritoryMapViewState);
    const activeMapViewState =
      mode === MapMode.GROUPS
        ? groupMapViewState
        : mode === MapMode.TERRITORIES
        ? territoryMapViewState
        : mapViewState;

    const isLassoMode = activeTool === MapTool.LASSO;
    const isMultiPin = !!multiPin?.id;

    const lassoVisibleEntities: EntityTypesSupportedByMapsPage[] = yield select(
      getVisibleLassoEntityTypes
    );
    const lassoMode: LassoToolMode = yield select(getLassoMode);

    const visibleEntityTypes =
      isLassoMode && lassoMode === LassoToolMode.ADD_GROUP_PINS
        ? lassoVisibleEntities
        : activeMapViewState.visibleEntities;

    const $offset = payload.request.range
      ? payload.request.range.startRow
      : recordsListState.range.startRow;
    const $limit = payload.request.range
      ? payload.request.range.endRow - payload.request.range.startRow
      : recordsListState.range.endRow - recordsListState.range.startRow;

    if (visibleEntityTypes.length === 0) {
      yield put(
        fetchRecords.success({
          isFiltered,
          response: {
            accessible: 0,
            count: 0,
            data: [],
            limit: $limit,
            offset: 0,
            total: 0,
          },
        })
      );
      return;
    }
    const mapFilters = convertMapFilterToPlatformFilterModel(
      mapViewState.filter,
      visibleEntityTypes,
      mapViewState.viewAs
    );

    const entities = mapFilters.entities as Record<
      EntityTypesSupportedByMapsPage,
      PlatformFilterModel
    >;
    Object.keys(entities).forEach((entityKey) => {
      const entity = get(mapFilters.entities, entityKey);
      entity.includeGroups = true;
    });

    if (searchFilter?.length) {
      // Inject filtering by name in records list
      if (mapFilters.entities && !Array.isArray(mapFilters.entities)) {
        const entities = mapFilters.entities as Record<
          EntityTypesSupportedByMapsPage,
          PlatformFilterModel
        >;
        Object.keys(entities).forEach((entityKey) => {
          const andCondition = get(mapFilters.entities, [entityKey, "$and"]);
          if (Array.isArray(andCondition)) {
            andCondition.push({
              name: {
                $in: searchFilter,
              },
            });
          }
        });
      }
    }

    const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
    const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);

    if (isLassoMode && !isMultiPin && selectedRecords.size === 0) {
      // If we're in lasso, but haven't selected any records for whatever reason - it makes no sense
      // to request detailed list of records from backend
      yield put(clearRecordsList());
      return;
    }

    if (isLassoMode && !isMultiPin) {
      // Inject filtering by selected IDs
      if (mapFilters.entities && !Array.isArray(mapFilters.entities)) {
        const entityIdFilter: Record<EntityTypesSupportedByMapsPage, Array<Identified["id"]>> = {
          [EntityType.COMPANY]: [],
          [EntityType.PERSON]: [],
          [EntityType.DEAL]: [],
        };
        for (const mapEntityId of selectedRecords.values()) {
          const parsed = mapEntityIdParser(mapEntityId);
          if (parsed) {
            entityIdFilter[parsed.entity as EntityTypesSupportedByMapsPage].push(parsed.id);
          }
        }

        const entities = mapFilters.entities as Record<
          EntityTypesSupportedByMapsPage,
          PlatformFilterModel
        >;
        Object.keys(entities).forEach((key) => {
          const entityKey = key as EntityTypesSupportedByMapsPage;
          if (visibleEntityTypes.includes(entityKey)) {
            const andCondition = get(mapFilters.entities, [entityKey, "$and"]);
            if (Array.isArray(andCondition) && Array.isArray(entityIdFilter[entityKey])) {
              andCondition.push({
                id: {
                  $in: entityIdFilter[entityKey],
                },
              });
            }
          }
        });
      }
    }

    const bounds = isMultiPin ? multiPin.bounds : isLassoMode ? undefined : viewport.bounds;

    const pinLegendFilter = {
      pinLegends: MAP_ENTITY_TYPES.reduce(
        (result, entityType) => ({
          ...result,
          [entityType]: getColorShapeForEntity(
            mapViewState,
            colorPinLegends,
            shapePinLegends,
            entityType
          ),
        }),
        {} as Record<
          EntityTypesSupportedByMapsPage,
          { color?: PinLegend["id"]; shape?: PinLegend["id"] }
        >
      ),
    };

    let order;
    if (recordsListState.sort?.length > 0) {
      const sortField = recordsListState.sort[0].field;
      const sortOrder = recordsListState.sort[0].order;

      if (sortField?.sortName === UniversalFieldName.GEOPOINT) {
        const getOrgSetting: <T = any>(settingName: string, defaultValue?: T) => T = yield select(
          getOrganizationSettingValue
        );
        const unit = getOrgSetting<DistanceUnit>(
          OrganizationSetting.DISTANCE_UNIT,
          DistanceUnit.MILE
        );
        const query: AreaSearchQuery = yield select(getListViewLocationQuery);
        let coordinates: LongLat | undefined;
        if (isCoordinatesAreaSearchQuery(query) || isAddressAreaSearchQuery(query)) {
          coordinates = query.coordinates;
        } else if (isEntityAreaSearchQuery(query) && query.entity.geoPoint?.coordinates) {
          coordinates = query.entity.geoPoint?.coordinates;
        }
        if (coordinates) {
          order = {
            order: sortOrder === SortOrder.DESC ? "desc" : "asc",
            point: coordinates,
            unit,
          };
        }
      } else {
        order = convertToPlatformSortModel(recordsListState.sort);
      }
    }

    const requestPayload = {
      $offset,
      $limit,
      $filters: {
        ...mapFilters,
        ...pinLegendFilter,
        bounds,
        cadence: true,
        includeAccessStatus: true,
        includeCustomFields: true,
      },
      $order: order,
    };

    const response: MapRecordsResponse = yield callApi("fetchMapPins", orgId, requestPayload);
    yield put(
      fetchRecords.success({
        isFiltered,
        response,
      })
    );
  } catch (error) {
    yield put(fetchRecords.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onShowMultiPinRecords({
  payload,
}: ReturnType<typeof showMultiPinRecords.request>) {
  try {
    yield put(showSidebar());
    yield put(hideMapEntityView());

    yield put(fetchRecords.request({ request: {} }));

    let address;

    if (isNumber(payload?.region?.longitude) && isNumber(payload?.region?.latitude)) {
      const orgId: Organization["id"] = yield select(getOrganizationId);

      const result: GeocodeResult = yield callApi("reverseGeocodeAddress", orgId, {
        type: "Point",
        coordinates: [payload.region!.longitude, payload.region!.latitude],
      });
      address = result.address.formattedAddress ?? "";
    }

    yield put(
      showMultiPinRecords.success({
        address,
      })
    );
  } catch (error) {
    yield put(showMultiPinRecords.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateRecordsListCustomization({
  payload,
}: ReturnType<typeof updateRecordsListCustomization.request>) {
  try {
    const recordsListSortOrder: SortModel = yield select(getMapRecordsListSortOrder);

    yield put(applyRecordsListSettings(payload));

    const recordListConfig: MapPersistedRecordListConfiguration = {
      sort: {
        field: payload.sort?.[0]?.field.name ?? recordsListSortOrder?.[0]?.field.name,
        order: payload.sort?.[0]?.order ?? recordsListSortOrder?.[0]?.order,
      },
    };
    yield put(updateMetadata.request({ mapRecordsListSettings: recordListConfig }));
    yield put(updateRecordsListCustomization.success());
  } catch (error) {
    yield put(updateRecordsListCustomization.failure());
    yield put(handleError({ error }));
  }
}

function* onShowEntityView({ payload }: ReturnType<typeof showEntityView>) {
  const entityTypesWithExternalPreview: EntityType[] = yield select(
    getEntityTypesWithExternalPreview
  );
  if (payload.entityType && !entityTypesWithExternalPreview.includes(payload.entityType)) {
    return;
  }

  const { pathname }: RouterLocation<any> = yield select(getLocation);
  const isOnMap = pathname.startsWith(Path.MAP);

  if (isOnMap) {
    const hasChanges: boolean = yield select(doesEntityViewHasChanges);

    const basePath: string = yield select(getMapModeBasePath);
    yield put(push(`${basePath}/entity/${payload.entityType}/${payload.entityId}`));

    if (hasChanges) {
      return;
    }
  }

  const isEntityMapped =
    payload.entityType &&
    [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL].includes(payload.entityType);
  const needToFocusOnPin = payload.focusOnPin && isOnMap && isEntityMapped;

  const mode: MapMode | undefined = yield select(getMapMode);

  // if we are on map and some special map mode is switched on we need to redirect user to new tab
  if (needToFocusOnPin && !!mode && payload.entityId && payload.entityType) {
    const url = `${window.location.origin}${Path.MAP}?previewEntityType=${
      payload.entityType
    }&previewEntityId=${payload.entityId}${payload.tab ? `&previewEntityTab=${payload.tab}` : ""}`;
    window.open(url, "_blank");
  } else {
    try {
      yield put(hideAnnotation());

      if (payload.entityId && payload.entityType) {
        yield put(setPinHover(`${payload.entityType}:${payload.entityId}`));
        // try to focus on pin
        if (needToFocusOnPin) {
          const orgId: Organization["id"] = yield select(getOrganizationId);
          const bigOrganization: boolean = yield select(isBigOrganization);
          const mapViewState: MapViewState = yield select(getMapViewState);
          const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
          const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);
          let focusedEntity;
          const requestPayload = {
            $filters: {
              pinLegends: MAP_ENTITY_TYPES.reduce(
                (result, entityType) => ({
                  ...result,
                  [entityType]: getColorShapeForEntity(
                    mapViewState,
                    colorPinLegends,
                    shapePinLegends,
                    entityType
                  ),
                }),
                {} as Record<
                  EntityTypesSupportedByMapsPage,
                  { color?: PinLegend["id"]; shape?: PinLegend["id"] }
                >
              ),
              entities: { [payload.entityType]: { $and: [{ id: payload.entityId }] } },
              precision: getPrecision(1, bigOrganization),
              includeGroups: true,
              includeCustomFields: true,
            },
          };

          const response: MapPinsResponse = yield callApi("fetchMapPins", orgId, requestPayload);
          if (response.data.length && isEntityPin(response.data[0])) {
            focusedEntity = response.data[0] as EntityPin;
          }
          yield put(setFocusedEntity(focusedEntity));
        }
      }
      yield put(showSidebar({ skipLoading: true }));
    } catch (error) {
      yield put(handleError({ error }));
    }
  }
}

export function* onHideMapEntityView() {
  try {
    const hasChanges: boolean = yield select(doesEntityViewHasChanges);

    const basePath: string = yield select(getMapModeBasePath);
    yield put(push(basePath));

    if (!hasChanges) {
      yield put(hideEntityView());
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onShowAnnotation({ payload }: ReturnType<typeof showAnnotation.request>) {
  try {
    const result = mapEntityIdParser(payload);
    if (!result) {
      // probably a lead pin. We don't fetch any data for lead annotations
      yield put(showAnnotation.failure());
      return;
    }

    const { entity: entityType, id: entityId } = result;

    // Try to find item in currently present record list first
    let record: MapEntity | undefined;
    const records: MapEntity[] = yield select(getRecords);
    if (Array.isArray(records) && records.length) {
      record = records.find(
        (entity) => mapEntityIdGetter(entity) === payload && entity.entity === entityType
      );
    }

    yield put(showAnnotation.success({ defaultEntity: record, entityId, entityType }));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(showAnnotation.failure());
  }
}

export function* onUpdateSelection(
  action: ReturnType<typeof addToSelection> | ReturnType<typeof removeFromSelection>
) {
  try {
    if (!action.payload) {
      return;
    }

    const isRemove = isActionOf(removeFromSelection, action);
    const sidebarVisible: boolean = yield select(isSidebarVisible);
    const selectedRecords: Set<string> = yield select(getSelectedRecords);

    const activeTool: MapTool | undefined = yield select(getMapViewTool);
    const isLassoMode = activeTool === MapTool.LASSO;

    if (action.payload instanceof Set) {
      const newSelection = isRemove
        ? new Set([
            ...Array.from(selectedRecords).filter(
              (value: string) => !(action.payload as Set<string>).has(value)
            ),
          ])
        : new Set([...selectedRecords, ...action.payload]);
      yield put(setSelection(newSelection));
    } else {
      const newSelection = isRemove
        ? new Set([
            ...Array.from(selectedRecords).filter((value: string) => action.payload !== value),
          ])
        : new Set([...selectedRecords, action.payload]);
      yield put(setSelection(newSelection));
    }

    const updatedSelectedRecords: Set<string> = yield select(getSelectedRecords);
    if (updatedSelectedRecords.size && !sidebarVisible) {
      yield put(clearRecordsList());
      yield put(showSidebar());
    } else {
      if (isLassoMode) {
        const mode: MapMode = yield select(getMapMode);
        switch (mode) {
          case MapMode.GROUPS:
            yield put(fetchGroupLassoSelection.request({ request: {} }));
            break;

          case MapMode.TERRITORIES:
            yield put(fetchTerritoryLassoSelection.request({ request: {} }));
            break;

          default:
            yield put(fetchLassoSelection.request({ request: {} }));
        }
      } else {
        yield put(resetRecordsListPagination());
        yield put(fetchRecords.request({ request: {} }));
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onHideSidebar() {
  try {
    yield put(hideEntityView());
    yield put(clearRecordsList());
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onDownloadMapRecords({ payload }: ReturnType<typeof downloadMapRecords>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const currentUser: User = yield select(getCurrentUser);

    const viewport: MapViewportState = yield select(getMapViewport);
    const mapViewState: MapViewState = yield select(getMapViewState);
    const recordsListState: MapViewState = yield select(getRecordsListState);
    const activeTool: MapTool | undefined = yield select(getMapViewTool);
    const selectedRecords: Set<string> = yield select(getSelectedRecords);
    const multiPin: MultiPin | undefined = yield select(getMultiPin);
    const searchFilter = recordsListState.search?.trim();

    const features: OrganizationMetaData["features"] = yield select(getFeatures);
    const disallowColor = features?.[Feature.DISALLOW_COLOR]?.enabled;

    const isLassoMode = activeTool === MapTool.LASSO;
    const isMultiPin = !!multiPin?.id;

    const lassoVisibleEntities: EntityTypesSupportedByMapsPage[] = yield select(
      getVisibleLassoEntityTypes
    );
    const lassoMode: LassoToolMode = yield select(getLassoMode);

    const visibleEntityTypes =
      isLassoMode && lassoMode === LassoToolMode.ADD_GROUP_PINS
        ? lassoVisibleEntities
        : mapViewState.visibleEntities;

    const mapFilters = convertMapFilterToPlatformFilterModel(
      mapViewState.filter,
      visibleEntityTypes,
      mapViewState.viewAs
    );

    const entities = mapFilters.entities as Record<
      EntityTypesSupportedByMapsPage,
      PlatformFilterModel
    >;
    Object.keys(entities).forEach((entityKey) => {
      const entity = get(mapFilters.entities, entityKey);
      entity.includeGroups = true;
    });

    if (searchFilter?.length) {
      // Inject filtering by name in records list
      if (mapFilters.entities && !Array.isArray(mapFilters.entities)) {
        const entities = mapFilters.entities as Record<
          EntityTypesSupportedByMapsPage,
          PlatformFilterModel
        >;
        Object.keys(entities).forEach((entityKey) => {
          const andCondition = get(mapFilters.entities, [entityKey, "$and"]);
          if (Array.isArray(andCondition)) {
            andCondition.push({
              name: {
                $in: searchFilter,
              },
            });
          }
        });
      }
    }

    const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
    const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);

    if (isLassoMode && selectedRecords.size === 0) {
      // If we're in lasso, but haven't selected any records for whatever reason - it makes no sense
      // to request detailed list of records from backend
      yield put(clearRecordsList());
      return;
    }

    if (isLassoMode && !isMultiPin) {
      // Inject filtering by selected IDs
      if (mapFilters.entities && !Array.isArray(mapFilters.entities)) {
        const entityIdFilter: Record<EntityTypesSupportedByMapsPage, Array<Identified["id"]>> = {
          [EntityType.COMPANY]: [],
          [EntityType.PERSON]: [],
          [EntityType.DEAL]: [],
        };
        for (const mapEntityId of selectedRecords.values()) {
          const parsed = mapEntityIdParser(mapEntityId);
          if (parsed) {
            entityIdFilter[parsed.entity as EntityTypesSupportedByMapsPage].push(parsed.id);
          }
        }

        const entities = mapFilters.entities as Record<
          EntityTypesSupportedByMapsPage,
          PlatformFilterModel
        >;
        Object.keys(entities).forEach((key) => {
          const entityKey = key as EntityTypesSupportedByMapsPage;
          if (visibleEntityTypes.includes(entityKey)) {
            const andCondition = get(mapFilters.entities, [entityKey, "$and"]);
            if (Array.isArray(andCondition) && Array.isArray(entityIdFilter[entityKey])) {
              andCondition.push({
                id: {
                  $in: entityIdFilter[entityKey],
                },
              });
            }
          }
        });
      }
    }

    const bounds = isMultiPin ? multiPin?.bounds : isLassoMode ? undefined : viewport.bounds;
    const pinLegendFilter = disallowColor
      ? {
          pinLegends: MAP_ENTITY_TYPES.reduce(
            (result, entityType) => ({
              ...result,
              [entityType]: getColorShapeForEntity(
                mapViewState,
                colorPinLegends,
                shapePinLegends,
                entityType
              ),
            }),
            {} as Record<
              EntityTypesSupportedByMapsPage,
              { color?: PinLegend["id"]; shape?: PinLegend["id"] }
            >
          ),
        }
      : {};

    let order: { order: string; point: number[]; unit: DistanceUnit } | undefined = undefined;
    if (recordsListState.sort?.length > 0) {
      const sortField = recordsListState.sort[0].field;
      const sortOrder = recordsListState.sort[0].order;

      if (sortField?.sortName === UniversalFieldName.GEOPOINT) {
        const getOrgSetting: <T = any>(settingName: string, defaultValue?: T) => T = yield select(
          getOrganizationSettingValue
        );
        const unit = getOrgSetting<DistanceUnit>(
          OrganizationSetting.DISTANCE_UNIT,
          DistanceUnit.MILE
        );

        const position: GeolocationPosition | undefined = yield select(getPosition);
        if (
          unit &&
          position?.coords?.longitude !== undefined &&
          position?.coords?.latitude !== undefined
        ) {
          order = {
            order: sortOrder === SortOrder.DESC ? "desc" : "asc",
            point: [position.coords.longitude, position.coords.latitude],
            unit,
          };
        }
      } else {
        order = convertToPlatformSortModel(recordsListState.sort);
      }
    }
    const intl = i18nService.getIntl();
    let entitiesWithResponseLimitCount = 0;
    let downloadedEntities = 0;
    for (let entityType of visibleEntityTypes) {
      const requiredEntity =
        mapFilters.entities &&
        (
          mapFilters.entities as Partial<
            Record<EntityTypesSupportedByMapsPage, PlatformFilterModel>
          >
        )[entityType];
      const checkFilters = { ...mapFilters, entities: { [entityType]: requiredEntity } };
      const requestPayload = {
        $limit: 0,
        $filters: {
          ...checkFilters,
          ...pinLegendFilter,
          bounds,
          cadence: true,
          includeCustomFields: true,
        },
        $order: order,
      };
      const columns =
        payload
          .get(entityType)
          ?.filter(({ visible }) => visible)
          .map(({ field }) => field) ?? [];
      const response: MapRecordsResponse = yield callApi("fetchPins", orgId, requestPayload);
      if (response.total > MAX_ITEMS_TO_DOWNLOAD_FILE) {
        const $filters = convertToPlatformFilterModel(
          convertMapFilterModelToFilterModelForEntity(entityType, mapViewState.filter),
          getFieldModelByEntityType(entityType).fields.map((field) => ({ field, visible: true })),
          getFieldModelByEntityType(entityType),
          true
        );
        const selectedColumns = columns.map(({ name }) => name);
        const payload = {
          name: `${
            intl
              ? getEntityTypeDisplayName(intl, entityType, {
                  lowercase: false,
                  plural: false,
                })
              : entityType
          } ${formatDate(new Date(), "Pp")}`,
          description: "",
          selectedColumns,
          selectedFilters: {
            ...$filters,
            ...pinLegendFilter,
            bounds,
            cadence: true,
            includeCustomFields: true,
          },
          tableName: entityType,
        };

        try {
          const report: Report = yield callApi("createReport", orgId, payload);
          yield callApi("generateReport", orgId, report.id, currentUser.username);
          entitiesWithResponseLimitCount += 1;
        } catch (e) {
          notification.error({
            message: intl?.formatMessage(messages.error),
            description: getErrorNotificationDescription(intl, payload.tableName),
          });
        }
      } else {
        const response: MapRecordsResponse = yield callApi("fetchPins", orgId, {
          ...requestPayload,
          $limit: MAX_ITEMS_TO_DOWNLOAD_FILE,
        });
        if (response.data.length > 0) {
          let reportEntityType;
          switch (entityType) {
            case EntityType.COMPANY:
              reportEntityType = ReportType.COMPANIES;
              break;
            case EntityType.PERSON:
              reportEntityType = ReportType.PEOPLE;
              break;
            case EntityType.DEAL:
              reportEntityType = ReportType.DEALS;
              break;
          }
          downloadEntitiesAsCsv(
            `${reportEntityType}_preview.csv`,
            entityType,
            response.data,
            columns
          );
          downloadedEntities += 1;
        }
      }
    }
    if (intl) {
      if (downloadedEntities > 0) {
        notification.success({
          message: intl.formatMessage(messages.success, {
            multiple: downloadedEntities > 1,
          }),
        });
      }
      if (entitiesWithResponseLimitCount > 0) {
        notification.warning({
          message: intl.formatMessage(messages.dataFileTooLarge, {
            multiple: entitiesWithResponseLimitCount > 1,
          }),
          description: intl.formatMessage(messages.dataFileTooLargeDescription),
        });
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onCheckCurrentPinLegends({
  payload: allPinLegends,
}: ReturnType<typeof fetchPinLegends.success>) {
  const mapViewState: MapViewState = yield select(getMapViewState);

  let needUpdate = false;
  const colorKey = { ...mapViewState.colorKey };
  const shapeKey = { ...mapViewState.shapeKey };

  // Iterate over all currently selected color and shape pin legends and check
  // if they still exist. Replace by a default pin legend if not
  MAP_ENTITY_TYPES.forEach((entityType) => {
    if (colorKey[entityType]) {
      const colorPinLegendExists = allPinLegends.some(
        ({ id, type }) => id === colorKey[entityType] && type === PinLegendType.COLOR
      );
      if (!colorPinLegendExists) {
        const defaultColorPinLegend = getDefaultColorPinLegend(allPinLegends, entityType);
        colorKey[entityType] = defaultColorPinLegend?.id;
        needUpdate = true;
      }
    }
    if (shapeKey[entityType]) {
      const shapePinLegendExists = allPinLegends.some(
        ({ id, type }) => id === shapeKey[entityType] && type === PinLegendType.SHAPE
      );
      if (!shapePinLegendExists) {
        const defaultShapePinLegend = getDefaultShapePinLegend(allPinLegends, entityType);
        colorKey[entityType] = defaultShapePinLegend?.id;
        needUpdate = true;
      }
    }
  });

  if (needUpdate) {
    yield put(applyMapViewSettings({ colorKey, shapeKey }));
  }
}

export function* mapModeSagas() {
  yield takeLatest(initializeMapMode.request, onInitializeMapMode);
  yield takeLatest(
    (action: Action) => isActionOf(fetchPins.request)(action) && !action.payload.updateOnly,
    onFetchPins
  );
  yield takeEvery(
    (action: Action) => isActionOf(fetchPins.request)(action) && !!action.payload.updateOnly,
    onFetchPins
  );
  yield takeLatest(fetchRecords.request, onFetchRecords);
  yield takeLatest(showMultiPinRecords.request, onShowMultiPinRecords);
  yield takeLatest(updateRecordsListCustomization.request, onUpdateRecordsListCustomization);
  yield takeLatest(showEntityView, onShowEntityView);
  yield takeLatest(hideMapEntityView, onHideMapEntityView);
  yield takeLatest([addToSelection, removeFromSelection], onUpdateSelection);
  yield takeLatest(hideSidebar, onHideSidebar);
  yield takeLatest(showAnnotation.request, onShowAnnotation);
  yield takeLatest(downloadMapRecords, onDownloadMapRecords);
  yield takeLatest(fetchPinLegends.success, onCheckCurrentPinLegends);
}

import { Col, Row } from "antd/es/grid";
import styles from "./GroupBadge.module.scss";
import EntityBadge from "component/EntityBadge";
import { Group } from "@mapmycustomers/shared/types/entity";
import layout from "../../../../styles/layout";

interface Props {
  group: Group;
}

const GroupBadge: React.FC<Props> = ({ group }) => {
  return (
    <Row align="middle" gutter={layout.spacerXS}>
      <Col>
        <div className={styles.groupBadgeContainer}>
          <EntityBadge entity={group} hideName />
        </div>
      </Col>
      <Col className={styles.groupName}>{group.name}</Col>
    </Row>
  );
};

export default GroupBadge;

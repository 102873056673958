import React, { useCallback } from "react";
import { getLinkExpired, getUserRequestStatus } from "../../../../store/auth";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import AuthLayout from "../AuthLayout";
import ManageUserAccessPage from "./ManageUserAccessPage";
import RequestStatusPage from "./RequestStatusPage";
import { Redirect, useLocation } from "react-router-dom";
import Path from "enum/Path";
import LinkExpiredPage from "./LinkExpiredPage";
import configService from "config/ConfigService";
import authService from "store/auth/AuthService";
import { getLegacyAppUrl } from "util/appUrl";
import UserRequestStatus from "enum/sso/UserRequestStatus";

interface Props {
  linkExpired: boolean;
  userRequestStatus?: UserRequestStatus;
}

const ManageUserAccess: React.FC<Props> = ({ userRequestStatus, linkExpired }) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const sessionCode = urlParams.get("mmc_session_id");
  const baseOldAppUrl = configService.getBaseOldAppUrl();
  const token = authService.getToken();

  const handleManageUsersClick = useCallback(() => {
    const url = getLegacyAppUrl(baseOldAppUrl, token, "/dashboard/teams");
    window.open(url, "_blank");
  }, [baseOldAppUrl, token]);

  if (!sessionCode) {
    return <Redirect to={Path.DASHBOARD} />;
  }
  return (
    <AuthLayout>
      {linkExpired ? (
        <LinkExpiredPage onManageUsersClick={handleManageUsersClick} />
      ) : userRequestStatus ? (
        <RequestStatusPage onManageUsersClick={handleManageUsersClick} status={userRequestStatus} />
      ) : (
        <ManageUserAccessPage
          onManageUsersClick={handleManageUsersClick}
          sessionCode={sessionCode}
        />
      )}
    </AuthLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  linkExpired: getLinkExpired(state),
  userRequestStatus: getUserRequestStatus(state),
});

export default connect(mapStateToProps)(ManageUserAccess);

import generatePicker from "antd/es/date-picker/generatePicker";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import getAntdPickerLocaleConfig from "./getAntdPickerLocaleConfig";
import { useConfigProvider } from "../../ConfigProvider";

let component: ReturnType<typeof generatePicker<Date>> | null = null;
let lastLocale: Locale | null = null;

const useDatePicker = (): ReturnType<typeof generatePicker<Date>> => {
  const { dateFnsLocale } = useConfigProvider();

  if (!component || lastLocale !== dateFnsLocale) {
    component = generatePicker<Date>({
      ...dateFnsGenerateConfig,
      locale: getAntdPickerLocaleConfig(dateFnsLocale),
    });
    lastLocale = dateFnsLocale;
  }
  return component;
};

export default useDatePicker;

import React from "react";
import IConfigProviderContext, {
  FormatNumberOptions,
} from "../types/IConfigProviderContext";
import defaultMessageFormatter from "./defaultMessageFormatter";
import getDecimalSeparator from "./getDecimalSeparator";
import { UserRef } from "@mapmycustomers/shared/types/User";
import enUS from "date-fns/esm/locale/en-US";

const ConfigProviderContext = React.createContext<IConfigProviderContext>({
  dateFnsLocale: enUS,
  formatMessage: defaultMessageFormatter,
  formatNumber(
    value: Parameters<Intl.NumberFormat["format"]>[0],
    opts?: FormatNumberOptions
  ): string {
    console.warn(
      `[ConfigProvider] Please provide a number formatter, currently using a default one with locales "${navigator.languages.join(
        ", "
      )}", which might be incorrect`
    );
    const intl = Intl.NumberFormat([...navigator.languages], opts);
    return intl.format(value);
  },
  getDecimalSeparator(): string {
    console.warn(
      `[ConfigProvider] Please provide decimal separator getter, currently using a default one with locale ${navigator.language}, which might be incorrect`
    );
    return getDecimalSeparator(navigator.language);
  },
  complementFileUrl(url?: string | null): string | undefined {
    return this.complementFileUrl(url);
  },
  getUserColorClassName(user: UserRef): string | undefined {
    return this.getUserColorClassName(user);
  },
});

export default ConfigProviderContext;

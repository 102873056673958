import Field, { FieldProperties } from "./Field";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import { groupsCadenceFormatter } from "./fieldUtil";
import FrequencyEntity from "../../../component/frequency/type/FrequencyEntity";
import { EntitiesSupportingGroups, Group } from "@mapmycustomers/shared/types/entity";
import FrequencyIntervalFilter, {
  FREQUENCY_NUMBER_FILTER_OPERATORS,
} from "../../filters/FrequencyIntervalFilter";
import i18nService from "../../../config/I18nService";
import { defineMessages } from "react-intl";
import hasFrequency from "../../../component/frequency/util/hasFrequency";

export const messages = defineMessages({
  daysCount: {
    id: "frequencyIntervalField.daysCount",
    defaultMessage:
      "{showName, select, true {{name}: } other {}}{count} {count, plural, one {day} other {days}}",
    description: "Days count for Frequency Interval Field",
  },
});

const formatFrequenciedEntity = (entity: FrequencyEntity, showName: boolean) =>
  i18nService.getIntl()?.formatMessage(messages.daysCount, {
    count: entity.cadenceInterval,
    showName,
    name: entity.name,
  }) ?? `${entity.cadenceInterval} ${(entity?.cadenceInterval ?? 0) > 1 ? " days" : "day"}`;

class FrequencyIntervalField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customGridProperties: {
        cellRenderer: "frequencyIntervalCellRenderer",
      },
      customFilterConfig: {
        defaultInstance: "frequencyIntervalFilter",
        filterInstances: {
          frequencyIntervalFilter: FrequencyIntervalFilter,
        },
        operators: [...FREQUENCY_NUMBER_FILTER_OPERATORS],
      },
      extraPlatformFiltersGetter: () => ({ includeGroups: true }),
      features: [FieldFeature.NON_INTEGRATION, FieldFeature.NON_IMPORT],
      type: FieldType.NUMBER,
      valueGetter: (entity: unknown) => entity,
      valueFormatter: groupsCadenceFormatter,
      ...data,
    });
  }

  hasNonEmptyValueFor(entity: unknown): boolean {
    return (
      hasFrequency(entity as FrequencyEntity) ||
      !!(entity as EntitiesSupportingGroups).groups?.some(hasFrequency)
    );
  }

  getFormattedCsvValueFor(entity: unknown) {
    return [
      ...(hasFrequency(entity as FrequencyEntity)
        ? [formatFrequenciedEntity(entity as FrequencyEntity, false)]
        : []),
      ...(((entity as EntitiesSupportingGroups).groups ?? []) as Group[])
        .filter(hasFrequency)
        .map((group) => formatFrequenciedEntity(group, true)),
    ].join("; ");
    // const sequence = getFrequencySequence(
    //   entity as FrequencyEntity,
    //   ((entity as EntitiesSupportingGroups).groups ?? []) as Group[]
    // );
    // return sequence
    //   .filter(({ cadenceInterval }) => cadenceInterval)
    //   .map(
    //     ({ cadenceInterval }) =>
    //       i18nService.getIntl()?.formatMessage(messages.daysCount, { count: cadenceInterval }) ??
    //       `${cadenceInterval} ${cadenceInterval > 1 ? " days" : "day"}`
    //   )
    //   .join("; ");
  }
}

export default FrequencyIntervalField;

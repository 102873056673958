import { Editor, Element } from "slate";
import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

const withMentions = (editor: Editor): Editor => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element: Element) => {
    return (
      Element.isElementType(element, ElementType.MENTION) || isInline(element)
    );
  };

  editor.isVoid = (element: Element) => {
    return (
      Element.isElementType(element, ElementType.MENTION) || isVoid(element)
    );
  };

  return editor;
};

export default withMentions;

import React, { useMemo } from "react";
import { EntitiesSupportingAccessScopes } from "@mapmycustomers/shared/types/entity";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import { getCurrentUserId, isCurrentUserOwner } from "store/iam";
import { useIntl } from "react-intl";
import messages from "./messages";
import EntityAccessScope from "@mapmycustomers/shared/enum/EntityAccessScope";
import User from "@mapmycustomers/shared/types/User";
import Popover from "antd/es/popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { Col, Row } from "antd/es/grid";
import styles from "./AccessReasonExposer.module.scss";
import layout from "styles/layout";

interface Props {
  currentUserId: User["id"];
  entity: EntitiesSupportingAccessScopes;
  isOwner: boolean;
}

const AccessReasonExposer: React.FC<Props> = ({
  currentUserId,
  entity: { accessScopes, accessStatus, entity, user },
  isOwner,
}) => {
  const intl = useIntl();
  const isActivity = (entity as string) === EntityType.ACTIVITY;

  const formattedMessage = useMemo(() => {
    let message;
    let action = "";
    if (isActivity) {
      if (isOwner) {
        message = messages.activityOwner;
      } else if (accessScopes?.includes(EntityAccessScope.USER_SCOPED)) {
        message = messages.activityUser;
      } else if (accessScopes?.includes(EntityAccessScope.ASSIGNEE_SCOPED)) {
        message = messages.activityAssignee;
      } else if (
        accessScopes?.includes(EntityAccessScope.TEAM_USER_SCOPED) ||
        accessScopes?.includes(EntityAccessScope.TEAM_ASSIGNEE_SCOPED)
      ) {
        message = messages.activityTeam;
      } else if (accessScopes?.includes(EntityAccessScope.PUBLIC_SCOPED)) {
        message = messages.activityPublic;
      } else if (accessScopes?.includes(EntityAccessScope.TEAM_ACTIVITY_SCOPED)) {
        message = messages.activityTeamScoped;
      } else if (accessScopes?.includes(EntityAccessScope.ACCOUNT_SHARED_ENTITY_SCOPED)) {
        message = messages.activitySharedCompanyScoped;
      } else if (accessScopes?.includes(EntityAccessScope.CONTACT_SHARED_ENTITY_SCOPED)) {
        message = messages.activitySharedPersonScoped;
      } else if (accessScopes?.includes(EntityAccessScope.DEAL_SHARED_ENTITY_SCOPED)) {
        message = messages.activitySharedDealScoped;
      }
    } else {
      if (isOwner) {
        message = messages.owner;
      } else if (accessScopes?.includes(EntityAccessScope.OWNED_OR_SHARED_SCOPED)) {
        if (user.id === currentUserId) {
          message = messages.recordOwner;
        } else {
          message = messages.notRecordOwner;
          action = accessStatus?.update
            ? intl.formatMessage(messages.edit)
            : intl.formatMessage(messages.view);
        }
      } else if (
        !accessScopes?.includes(EntityAccessScope.ALL_ACCESS_SCOPED) &&
        !accessScopes?.includes(EntityAccessScope.OWNED_OR_SHARED_SCOPED) &&
        (accessScopes?.includes(EntityAccessScope.ACCOUNT_SHARED_ENTITY_SCOPED) ||
          accessScopes?.includes(EntityAccessScope.CONTACT_SHARED_ENTITY_SCOPED) ||
          accessScopes?.includes(EntityAccessScope.DEAL_SHARED_ENTITY_SCOPED))
      ) {
        message = messages.entityShared;
      }
    }
    return message ? intl.formatMessage(message, { action, b: (text) => <b>{text}</b> }) : "";
  }, [accessScopes, accessStatus, currentUserId, intl, isActivity, isOwner, user]);

  return (
    <div>
      <Popover
        title={<div className={styles.popoverText}>{formattedMessage}</div>}
        placement="bottom"
      >
        <Row align="middle" className={styles.container} gutter={layout.spacerS}>
          <Col>
            <div className={styles.question}>
              {intl.formatMessage(isActivity ? messages.questionActivity : messages.question)}
            </div>
          </Col>
          <Col>
            <FontAwesomeIcon className={styles.icon} icon={faInfoCircle} size="sm" />
          </Col>
        </Row>
      </Popover>
    </div>
  );
};

export const mapStateToPropsActivityAnnotation = (state: RootState) => ({
  currentUserId: getCurrentUserId(state)!,
  isOwner: isCurrentUserOwner(state),
});

export default connect(mapStateToPropsActivityAnnotation)(AccessReasonExposer);

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import ActivitiesOverdueCardData from "scene/dashboard/store/cards/activitiesOverdue/ActivitiesOverdueCardData";
import Scope from "types/dashboard/Scope";
import ActivitiesOverdueCardConfiguration from "types/dashboard/cards/activitiesOverdue/ActivitiesOverdueCardConfiguration";
import DrillDownViewState from "scene/dashboard/types/DrillDownViewState";
import { DrillDownListFetcherPayload } from "scene/dashboard/types/DrillDownListFetcher";
import { Activity } from "@mapmycustomers/shared/types/entity";

export const fetchActivitiesOverdueCardData = createAction(
  "@dashboard/fetchActivitiesOverdueCardData"
)<{
  callback: (data: ActivitiesOverdueCardData) => void;
  configuration: ActivitiesOverdueCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
}>();

export const showActivitiesOverdueDrillDown = createAsyncAction(
  "@dashboard/showActivitiesOverdueCardDrillDownModal/request",
  "@dashboard/showActivitiesOverdueCardDrillDownModal/success",
  "@dashboard/showActivitiesOverdueCardDrillDownModal/failure"
)<
  {
    configuration: ActivitiesOverdueCardConfiguration;
    scope: Scope;
  },
  { viewState: DrillDownViewState },
  unknown
>();

export const hideActivitiesOverdueDrillDown = createAction(
  "@dashboard/hideActivitiesOverdueCardDrillDownModal"
)<void>();

export const fetchActivitiesOverdueCardDrillDownData = createAsyncAction(
  "@dashboard/fetchActivitiesOverdueCardDrillDownData/request",
  "@dashboard/fetchActivitiesOverdueCardDrillDownData/success",
  "@dashboard/fetchActivitiesOverdueCardDrillDownData/failure"
)<
  DrillDownListFetcherPayload<Activity>,
  {
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyActivitiesOverdueDrillDownListViewSettings = createAction(
  "@dashboard/applyActivitiesOverdueDrillDownListViewSettings"
)<Partial<DrillDownViewState>>();

export const exportActivitiesOverdueCardDrillDownData = createAction(
  "@dashboard/exportActivitiesOverdueCardData"
)<{
  configuration: ActivitiesOverdueCardConfiguration;
  scope: Scope;
}>();

export type ActivitiesOverdueCardActions = ActionType<
  | typeof fetchActivitiesOverdueCardData
  | typeof showActivitiesOverdueDrillDown
  | typeof hideActivitiesOverdueDrillDown
  | typeof fetchActivitiesOverdueCardDrillDownData
  | typeof applyActivitiesOverdueDrillDownListViewSettings
  | typeof exportActivitiesOverdueCardDrillDownData
>;

import { all, put, select, take, takeEvery } from "redux-saga/effects";
import {
  changeDuplicatePairDismission,
  checkDupeRunStatus,
  fetchDuplicates,
  hideDismissed,
  mergeDuplicates,
  positionSelectedPair,
  recalculateDuplicates,
  selectDuplicatePair,
  selectEntityType,
  setLoading,
  setSearchText,
  showDismissed,
} from "./actions";
import { getOrganizationId } from "store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import { handleError } from "store/errors/actions";
import {
  EntityType,
  EntityTypeSupportingDataMerging,
  Person,
} from "@mapmycustomers/shared/types/entity";
import {
  getDuplicates,
  getEntityType,
  getPage,
  getSearchText,
  getSelectedAsPrioritizedEntity,
  getSelectedPair,
  getSelectedPairIndex,
  isDismissedShowed,
} from "./selectors";
import { callApi } from "store/api/callApi";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { DuplicatePair, DuplicateRun } from "@mapmycustomers/shared/types/entity/Duplicate";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import Company from "@mapmycustomers/shared/types/entity/Company";
import EntityPriority from "../enum/EntityPriority";
import notification from "antd/es/notification";
import i18nService from "config/I18nService";
import DuplicateStatus from "@mapmycustomers/shared/enum/DuplicateStatus";
import { isActionOf } from "typesafe-actions";
import { initialize } from "component/createEditEntity/CreateDealModal/store/actions";
import { DUPLICATE_ACTIVE_STATUSES } from "../util/const";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { MERGE_DUPLICATES_TABLE_PAGE_SIZE } from "scene/settings/util/const";

export function* onFetchDuplicates() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityType: EntityTypeSupportingDataMerging = yield select(getEntityType);
    const showDismissed: boolean = yield select(isDismissedShowed);
    const page: number = yield select(getPage);

    let query: string = yield select(getSearchText);
    query = query.trim();
    yield put(setLoading(true));
    const $filters: PlatformFilterModel = {
      status: showDismissed ? DuplicateStatus.DISMISSED : { $in: DUPLICATE_ACTIVE_STATUSES },
    };
    if (query.length) {
      $filters.query = query;
    }

    const [response, runResponse]: [ListResponse<DuplicatePair>, DuplicateRun] = yield all([
      callApi("getEntityDuplicates", orgId, entityType, {
        $filters,
        $limit: MERGE_DUPLICATES_TABLE_PAGE_SIZE,
        $offset: page * MERGE_DUPLICATES_TABLE_PAGE_SIZE,
      }),
      callApi("getDuplicateRun", orgId),
    ]);
    const lastCalculatedDate = runResponse.updatedAt;
    yield put(
      fetchDuplicates.success({
        duplicates: response.data,
        lastCalculatedDate,
        total: response.total,
      })
    );
  } catch (error) {
    yield put(fetchDuplicates.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onCheckDupeRunStatus() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: DuplicateRun = yield callApi("getDuplicateRun", orgId);
    yield put(
      checkDupeRunStatus.success({
        lastCalculatedDate: response.updatedAt,
        lastDupeRunStatus: response.status,
      })
    );
  } catch (error) {
    yield put(checkDupeRunStatus.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onChangeDuplicatePairDismission({
  payload: { dismissed, pair },
}: ReturnType<typeof changeDuplicatePairDismission.request>) {
  try {
    yield put(positionSelectedPair());
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityType: EntityTypeSupportingDataMerging = yield select(getEntityType);
    yield callApi("updateDuplicatePair", orgId, entityType, {
      ...pair,
      status: dismissed ? DuplicateStatus.DISMISSED : DuplicateStatus.OPEN,
    });
    notification.success({
      message: dismissed
        ? i18nService.getIntl()?.formatMessage({
            id: "settings.mergeDuplicates.dismiss.notification",
            defaultMessage: "Suggested duplicate dismissed",
            description: "Settings Merge duplicates - Suggested duplicate dismissed",
          })
        : i18nService.getIntl()?.formatMessage({
            id: "settings.mergeDuplicates.undoDismission.notification",
            defaultMessage: "Dismission undone",
            description: "Settings Merge duplicates - Dismission undone",
          }),
    });
    yield put(changeDuplicatePairDismission.success());
  } catch (error) {
    yield put(changeDuplicatePairDismission.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onMergeDuplicates() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityType: EntityTypeSupportingDataMerging = yield select(getEntityType);
    const pair: DuplicatePair = yield select(getSelectedPair);
    const priority: EntityPriority = yield select(getSelectedAsPrioritizedEntity);
    yield callApi(
      "mergeDuplicates",
      orgId,
      entityType,
      pair.id,
      priority === EntityPriority.PRIMARY ? pair.primary.id : pair.secondary.id,
      priority === EntityPriority.PRIMARY ? pair.secondary.id : pair.primary.id
    );

    yield put(fetchDuplicates.request({}));
    const initializeResult: ReturnType<
      typeof fetchDuplicates.failure | typeof fetchDuplicates.success
    > = yield take([fetchDuplicates.success, fetchDuplicates.failure]);
    if (isActionOf(fetchDuplicates.failure, initializeResult)) {
      yield put(initialize.failure());
      return;
    }
    const intl = i18nService.getIntl();
    notification.info({
      description: intl?.formatMessage({
        id: "settings.mergeDuplicates.merging.info.description",
        defaultMessage:
          "The duplicate records are being merged. This action may take up to a few minutes. Once merged successfully, they will disappear from the duplicate table.",
        description: "Settings Merge duplicates - merge duplicates info description",
      }),
      message: intl?.formatMessage({
        id: "settings.mergeDuplicates.merging.info.message",
        defaultMessage: "Merging in progress",
        description: "Settings Merge duplicates - merge duplicates info description",
      }),
    });
    yield put(positionSelectedPair());
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onSelectDuplicatePair({
  payload,
}: ReturnType<typeof selectDuplicatePair.request>) {
  if (payload !== undefined) {
    try {
      const orgId: Organization["id"] = yield select(getOrganizationId);
      const entityType: EntityTypeSupportingDataMerging = yield select(getEntityType);
      const duplicates: DuplicatePair[] = yield select(getDuplicates);
      const duplicate = duplicates[payload];
      const response: ListResponse<Company | Person> = yield callApi(
        entityType === EntityType.COMPANY ? "fetchCompanies" : "fetchPeople",
        orgId,
        {
          $filters: {
            $and: [
              {
                id: {
                  [PlatformFilterOperator.CONTAINS]: [duplicate.primary.id, duplicate.secondary.id],
                },
              },
            ],
            includeCustomFields: true,
          },
        }
      );
      const primary: Company | Person | undefined = response.data.find(
        ({ id }) => id === duplicate.primary.id
      );
      const secondary: Company | Person | undefined = response.data.find(
        ({ id }) => id === duplicate.secondary.id
      );
      if (primary && secondary) {
        yield put(selectDuplicatePair.success({ primary, secondary }));
      }
    } catch (error) {
      yield put(selectDuplicatePair.failure(error));
      yield put(handleError({ error }));
    }
  }
}

export function* onPositionSelectedPair() {
  const index: number | undefined = yield select(getSelectedPairIndex);
  if (index === undefined) {
    return;
  }
  const duplicates: DuplicatePair[] = yield select(getDuplicates);
  const indexes = duplicates.map((_, i) => i);
  const newIndex = [...indexes.slice(index), ...indexes.slice(0, index - 1).reverse()].find(
    (i) => duplicates[i].status === DuplicateStatus.OPEN
  );
  yield put(selectDuplicatePair.request(newIndex === -1 ? undefined : newIndex));
}

export function* onRecalculateDuplicates() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: DuplicateRun = yield callApi("recalculateDuplicates", orgId);
    yield put(
      recalculateDuplicates.success({
        lastCalculatedDate: response.updatedAt,
        lastDupeRunStatus: response.status,
      })
    );
  } catch (error) {
    yield put(recalculateDuplicates.failure(error));
    yield put(handleError({ error }));
  }
}

export function* mergeDuplicatesSaga() {
  yield takeEvery(
    [
      changeDuplicatePairDismission.success,
      fetchDuplicates.request,
      hideDismissed,
      selectEntityType,
      setSearchText,
      showDismissed,
    ],
    onFetchDuplicates
  );
  yield takeEvery(changeDuplicatePairDismission.request, onChangeDuplicatePairDismission);
  yield takeEvery(selectDuplicatePair.request, onSelectDuplicatePair);
  yield takeEvery(mergeDuplicates, onMergeDuplicates);
  yield takeEvery(positionSelectedPair, onPositionSelectedPair);
  yield takeEvery(recalculateDuplicates.request, onRecalculateDuplicates);
  yield takeEvery(checkDupeRunStatus.request, onCheckDupeRunStatus);
}

import React from "react";
import { UserRef } from "@mapmycustomers/shared/types/User";
import AntDAvatar, { AvatarProps } from "antd/es/avatar";
import cn from "classnames";
import Tooltip from "antd/es/tooltip";
import { userDisplayName } from "@mapmycustomers/shared/util/formatters";
import getInitials from "@mapmycustomers/shared/util/ui/getInitials";
import { useConfigProvider } from "../ConfigProvider";
import colorStyles from "./AvatarColor.module.scss";

interface Props extends Omit<AvatarProps, "className"> {
  className?: string;
  tooltip?: boolean;
  user: UserRef;
}

const Avatar: React.FC<Props> = ({
  className,
  tooltip,
  user,
  ...avatarProps
}) => {
  const configProvider = useConfigProvider();
  const profilePhotoUrl = configProvider.complementFileUrl(
    user.profilePhoto?.publicURI
  );

  const nameInitials = getInitials(user);

  let colorClassName;
  if (!profilePhotoUrl) {
    colorClassName =
      colorStyles[configProvider.getUserColorClassName(user) as string];
  }

  return (
    <Tooltip title={tooltip ? userDisplayName(user) : undefined}>
      <AntDAvatar
        className={cn(colorClassName, className)}
        src={profilePhotoUrl}
        {...avatarProps}
      >
        {profilePhotoUrl ? " " : nameInitials}
      </AntDAvatar>
    </Tooltip>
  );
};

// Empty object preventing passing dispatch prop to the component
export default Avatar;

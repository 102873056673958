import Iam from "types/Iam";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import ApiError from "../api/ApiError";
import {
  Actions,
  addFavoriteFilter,
  changePassword,
  deleteProfilePhoto,
  fetchGeocodingLimit,
  fetchMe,
  findMe,
  hideChangePasswordModal,
  hideDeleteProfilePhotoModal,
  removeFavoriteFilter,
  setBigOrganization,
  showChangePasswordModal,
  showDeleteProfilePhotoModal,
  toggleFavoriteFunnel,
  togglePinnedDashboard,
  updateMe,
  updateMetadata,
  updateOrganization,
  updateOrganizationMetadata,
  updateOrganizationSetting,
  updateSetting,
  uploadProfilePhoto,
} from "./actions";
import { ActionType, createReducer } from "typesafe-actions";
import { tryLogin } from "../auth/actions";

export type IamState = {
  bigOrganization: boolean;
  changePasswordError: ApiError | undefined;
  changePasswordLoading: boolean;
  changePasswordModalVisible: boolean;
  confirmDeleteProfileModalVisible: boolean;
  deleteProfilePhotoLoading: boolean;
  error: ApiError | undefined;
  finishOnboardingError: unknown | undefined;
  finishOnboardingLoading: boolean;
  geocodingMmcLimitReached: boolean;
  geocodingOrgLimit: number;
  geocodingOrgLimitReached: boolean;
  layouts: FormLayout[];
  loading: boolean;
  me: Iam | undefined;
  meUpdating: boolean;
  organizationUpdating: boolean;
  position: GeolocationPosition | undefined;
  settingUpdating: boolean;
  uploadProfilePhotoLoading: boolean;
};

const initialState: IamState = {
  bigOrganization: false,
  changePasswordError: undefined,
  changePasswordLoading: false,
  changePasswordModalVisible: false,
  confirmDeleteProfileModalVisible: false,
  deleteProfilePhotoLoading: false,
  error: undefined,
  finishOnboardingError: undefined,
  finishOnboardingLoading: false,
  geocodingMmcLimitReached: false,
  geocodingOrgLimit: 0,
  geocodingOrgLimitReached: false,
  layouts: [],
  loading: false,
  me: undefined,
  meUpdating: false,
  organizationUpdating: false,
  position: undefined,
  settingUpdating: false,
  uploadProfilePhotoLoading: false,
};

const iam = createReducer<IamState, Actions | ActionType<typeof tryLogin>>(initialState)
  .handleAction(tryLogin.request, (state) => ({
    ...state,
    // cleanup the error, which is useful when user is re-logging in because of the
    // expired token
    error: undefined,
  }))
  .handleAction(fetchMe.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
    me: undefined,
  }))
  .handleAction(fetchMe.success, (state, action) => ({
    ...state,
    loading: false,
    me: action.payload,
  }))
  .handleAction(fetchMe.failure, (state, { payload }) => ({
    ...state,
    loading: false,
    error:
      payload instanceof ApiError ? payload : new ApiError("Unknown error, please try again later"),
  }))
  .handleAction(findMe.success, (state, action) => ({
    ...state,
    position: action.payload,
  }))
  .handleAction(changePassword.request, (state) => ({
    ...state,
    changePasswordError: undefined,
    changePasswordLoading: true,
  }))
  .handleAction(changePassword.success, (state) => ({
    ...state,
    changePasswordLoading: false,
    changePasswordModalVisible: false,
  }))
  .handleAction(changePassword.failure, (state, { payload }) => ({
    ...state,
    changePasswordError:
      payload instanceof ApiError ? payload : new ApiError("Unknown error, please try again later"),
    changePasswordLoading: false,
  }))
  .handleAction(showChangePasswordModal, (state) => ({
    ...state,
    changePasswordModalVisible: true,
  }))
  .handleAction(hideChangePasswordModal, (state) => ({
    ...state,
    changePasswordModalVisible: false,
  }))
  .handleAction(uploadProfilePhoto.request, (state) => ({
    ...state,
    uploadProfilePhotoLoading: true,
    error: undefined,
  }))
  .handleAction(uploadProfilePhoto.success, (state, { payload }) => ({
    ...state,
    uploadProfilePhotoLoading: false,
    me: state.me ? { ...state.me, ...payload } : state.me,
  }))
  .handleAction(uploadProfilePhoto.failure, (state, { payload }) => ({
    ...state,
    uploadProfilePhotoLoading: false,
    error:
      payload instanceof ApiError ? payload : new ApiError("Unknown error, please try again later"),
  }))
  .handleAction(deleteProfilePhoto.request, (state) => ({
    ...state,
    error: undefined,
    deleteProfilePhotoLoading: true,
  }))
  .handleAction(deleteProfilePhoto.success, (state, { payload }) => ({
    ...state,
    me: state.me ? { ...state.me, ...payload } : state.me,
    deleteProfilePhotoLoading: false,
    confirmDeleteProfileModalVisible: false,
  }))
  .handleAction(deleteProfilePhoto.failure, (state, { payload }) => ({
    ...state,
    error:
      payload instanceof ApiError ? payload : new ApiError("Unknown error, please try again later"),
    deleteProfilePhotoLoading: false,
    confirmDeleteProfileModalVisible: false,
  }))
  .handleAction(hideDeleteProfilePhotoModal, (state) => ({
    ...state,
    confirmDeleteProfileModalVisible: false,
  }))
  .handleAction(showDeleteProfilePhotoModal, (state) => ({
    ...state,
    confirmDeleteProfileModalVisible: true,
  }))
  .handleAction(updateSetting.request, (state, action) => ({
    ...state,
    settingUpdating: true,
  }))
  .handleAction(updateSetting.success, (state, { payload }) => {
    const settingIndex = state.me?.settings?.findIndex(({ key }) => key === payload.key);
    if (!settingIndex || settingIndex < 0) {
      // if it's a new setting, add it to the list
      return {
        ...state,
        settingUpdating: false,
        me: state.me ? { ...state.me, settings: [...state.me.settings, payload] } : state.me,
      };
    } else {
      //else update existing
      return state.me
        ? {
            ...state,
            settingUpdating: false,
            me: {
              ...state.me,
              settings: [
                ...state.me.settings.slice(0, settingIndex),
                payload,
                ...state.me.settings.slice(settingIndex + 1),
              ],
            },
          }
        : { ...state, settingUpdating: false };
    }
  })
  .handleAction(
    [
      addFavoriteFilter.success,
      removeFavoriteFilter.success,
      toggleFavoriteFunnel.success,
      togglePinnedDashboard.success,
    ],
    (state, { payload }) => ({
      ...state,
      me: state.me ? { ...state.me, ...payload } : state.me,
    })
  )
  .handleAction(updateMe.request, (state) => ({
    ...state,
    meUpdating: true,
  }))
  .handleAction(updateMe.success, (state, { payload }) => ({
    ...state,
    me: payload,
    meUpdating: false,
  }))
  .handleAction(updateMe.failure, (state) => ({
    ...state,
    meUpdating: false,
  }))
  .handleAction(updateOrganization.request, (state) => ({
    ...state,
    organizationUpdating: true,
  }))
  .handleAction(
    [updateOrganization.success, updateOrganizationSetting.success],
    (state, { payload }) => ({
      ...state,
      me: state.me ? { ...state.me, ...payload } : state.me,
      organizationUpdating: false,
    })
  )
  .handleAction(updateOrganization.failure, (state) => ({
    ...state,
    organizationUpdating: false,
  }))
  .handleAction(
    fetchGeocodingLimit.success,
    (
      state,
      { payload: { geocodingOrgLimit, geocodingMmcLimitReached, geocodingOrgLimitReached } }
    ) => ({
      ...state,
      geocodingOrgLimit,
      geocodingMmcLimitReached,
      geocodingOrgLimitReached,
    })
  )
  .handleAction(updateMetadata.request, (state) => ({ ...state, meUpdating: true }))
  .handleAction(updateMetadata.success, (state, { payload }) => ({
    ...state,
    me: state.me ? { ...state.me, metaData: { ...state.me.metaData, ...payload } } : state.me,
    meUpdating: false,
  }))
  .handleAction(updateMetadata.failure, (state) => ({ ...state, meUpdating: false }))
  .handleAction(updateOrganizationMetadata.request, (state) => ({
    ...state,
    organizationUpdating: true,
  }))
  .handleAction(updateOrganizationMetadata.success, (state, { payload }) => ({
    ...state,
    me: state.me
      ? { ...state.me, organization: { ...state.me.organization, ...payload } }
      : state.me,
    organizationUpdating: false,
  }))
  .handleAction(updateOrganizationMetadata.failure, (state) => ({
    ...state,
    organizationUpdating: false,
  }))
  .handleAction(setBigOrganization, (state, { payload }) => ({
    ...state,
    bigOrganization: payload,
  }));

export * from "./selectors";
export type IamActions = Actions;
export default iam;

import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";
import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";

const formLayoutsState = (state: RootState) => state.scene.settings.formLayouts;

export const isLoading = createSelector(formLayoutsState, ({ loading }) => loading);

export const getActiveEntityType = createSelector(
  formLayoutsState,
  ({ activeEntityType }) => activeEntityType
);

export const getLayouts = createSelector(formLayoutsState, ({ layouts }) => layouts);

export const isCreating = createSelector(
  formLayoutsState,
  ({ editedLayout }) => !!editedLayout?.isCreating
);

export const isSaving = createSelector(
  formLayoutsState,
  ({ editedLayout }) => !!editedLayout?.saving
);

export const isLayoutEditorLoading = createSelector(
  formLayoutsState,
  ({ editedLayout }) => !!editedLayout?.loading
);

export const isLayoutEditorHasChanges = createSelector(
  formLayoutsState,
  ({ editedLayout }) => !!editedLayout?.hasChanges
);

export const getOriginalLayout = createSelector(
  formLayoutsState,
  ({ editedLayout }) => editedLayout?.originalLayout
);

export const getEditedLayout = createSelector(
  formLayoutsState,
  ({ editedLayout }) => editedLayout?.layout
);

export const getEditedLayoutId = createSelector(
  formLayoutsState,
  ({ editedLayout }) => editedLayout?.layout?.id
);

export const getEditedLayoutSchema = createSelector(
  formLayoutsState,
  ({ editedLayout }) => editedLayout?.layout?.schema
);

export const getEditedLayoutSortedSchema = createSelector(getEditedLayout, (layout) =>
  layout?.schema
    ? layout.schema.sort((a: LayoutSchemaField, b: LayoutSchemaField) =>
        a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0
      )
    : []
);

export const isEditingChildLayout = createSelector(
  formLayoutsState,
  ({ editedChildLayout }) => !!editedChildLayout
);

export const getEditedChildLayoutId = createSelector(
  formLayoutsState,
  ({ editedChildLayout }) => editedChildLayout
);

export const getEditedLayoutName = createSelector(
  formLayoutsState,
  ({ editedLayout }) => editedLayout?.layout?.name
);

export const getEditedLayoutAllowAdd = createSelector(
  formLayoutsState,
  ({ editedLayout }) => !!editedLayout?.layout?.addFields
);

export const areLayoutsRolesAssociationsLoading = createSelector(
  formLayoutsState,
  ({ roles }) => roles.loading
);

export const areLayoutsRolesAssociationsSaving = createSelector(
  formLayoutsState,
  ({ roles }) => roles.saving
);

export const getLayoutsRolesAssociationsList = createSelector(
  formLayoutsState,
  ({ roles }) => roles.roles
);

export const getLayoutsRolesAssociations = createSelector(
  formLayoutsState,
  ({ roles }) => roles.rolesMap
);

export const isEditingLayoutRolesAssociation = createSelector(
  formLayoutsState,
  ({ roles }) => !!roles.editing
);

export const isActivityTypeWarningDismissed = createSelector(
  formLayoutsState,
  ({ isActivityTypeWarningDismissed }) => isActivityTypeWarningDismissed
);

export const isDealStageWarningDismissed = createSelector(
  formLayoutsState,
  ({ isDealStageWarningDismissed }) => isDealStageWarningDismissed
);

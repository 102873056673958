import LocationViewMode from "enum/LocationViewMode";
import Address from "@mapmycustomers/shared/types/Address";
import { GeoManagementState } from "@mapmycustomers/shared/types/base/Located";

export const getAddress = (formValues: any, geoManagementState?: GeoManagementState): Address => {
  let address: Object;

  if (formValues.locationViewMode === LocationViewMode.ADDRESS) {
    address = formValues.addressDetails
      ? {
          ...formValues.addressDetails,
          region: formValues.addressDetails.region,
          regionCode: formValues.addressDetails.regionCode,
          geoManagementState: geoManagementState ?? GeoManagementState.AUTOMATIC_PRESERVE_ADDRESS,
          geoPoint: null,
        }
      : {};
    // "state" in address && delete address["state"]; // only keep the "region" field
  } else {
    const lat = parseFloat(formValues.latitude);
    const lon = parseFloat(formValues.longitude);
    address =
      isNaN(lat) || isNaN(lon)
        ? {}
        : {
            address: null,
            city: null,
            region: null,
            regionCode: null,
            postalCode: null,
            country: null,
            // we don't send manual because this is a platform requirement - we need
            // to run geocoding even for such location records in order to get some
            // address details for better territories matching
            // we will send manual in case of POI and important to send geoAddress as well for manual
            geoManagementState: geoManagementState ?? GeoManagementState.AUTOMATIC_PRESERVE_ADDRESS,
            geoPoint: { type: "Point", coordinates: [lon, lat] as [number, number] },
          };
  }

  return address;
};

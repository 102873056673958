import {
  GlobalSearchActivity,
  GlobalSearchCompany,
  GlobalSearchDeal,
  GlobalSearchEntity,
  GlobalSearchGroup,
  GlobalSearchPerson,
  GlobalSearchRoute,
  GlobalSearchTerritory,
} from "types/globalSearch/GlobalSearchItem";
import { EntityType } from "@mapmycustomers/shared/enum";

export const isSearchCompany = (
  searchEntity: GlobalSearchEntity
): searchEntity is GlobalSearchCompany => searchEntity?.entity === EntityType.COMPANY;

export const isSearchPerson = (
  searchEntity: GlobalSearchEntity
): searchEntity is GlobalSearchPerson => searchEntity?.entity === EntityType.PERSON;

export const isSearchDeal = (searchEntity: GlobalSearchEntity): searchEntity is GlobalSearchDeal =>
  searchEntity?.entity === EntityType.DEAL;

export const isSearchActivity = (
  searchEntity: GlobalSearchEntity
): searchEntity is GlobalSearchActivity => searchEntity?.entity === EntityType.ACTIVITY;

export const isSearchTerritory = (
  searchEntity: GlobalSearchEntity
): searchEntity is GlobalSearchTerritory => searchEntity?.entity === EntityType.TERRITORY;

export const isSearchGroup = (
  searchEntity: GlobalSearchEntity
): searchEntity is GlobalSearchGroup =>
  !!searchEntity.entity &&
  [EntityType.COMPANY_GROUP, EntityType.PEOPLE_GROUP, EntityType.DEAL_GROUP].includes(
    searchEntity.entity
  );

export const isSearchRoute = (
  searchEntity: GlobalSearchEntity
): searchEntity is GlobalSearchRoute =>
  !!searchEntity.entity &&
  [EntityType.COMPANY_ROUTE, EntityType.PEOPLE_ROUTE].includes(searchEntity.entity);

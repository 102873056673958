import { faRoute } from "@fortawesome/free-solid-svg-icons/faRoute";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import notification from "antd/es/notification";
import Modal from "component/modal";
import React, { useCallback, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import {
  Company,
  EntityTypeSupportingRoutes,
  Person,
  Route,
} from "@mapmycustomers/shared/types/entity";
import { getEntityTypeDisplayName } from "util/ui";
import { AnalyticsService } from "util/analytic/AnalyticsService";
import { MAX_ROUTE_STOPS } from "util/consts";
import SelectField from "../input/SelectField";
import styles from "./AddToRouteModal.module.scss";
import CreateRouteModal from "./CreateRouteModal";
import {
  getRoutes,
  getSelectedRouteId,
  isAdding,
  isCreateRouteModalVisible,
  isInitializing,
} from "./store";
import {
  addToRoute,
  filterRoutes,
  initialize,
  selectRoute,
  showCreateRouteModal,
} from "./store/actions";
import itemToValueLabel from "util/ui/itemToValueLabel";
import { isGeocodeMmcLimitReached, isGeocodeOrgLimitReached } from "store/iam";
import showGeocodeLimitAlertModal from "util/geocodeLimitAlert/showGeocodeLimitAlertModal";
import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";

const messages = defineMessages({
  add: {
    id: "addToRouteModal.footer.add",
    defaultMessage: "Add to Route",
    description: "Add button in a footer of Add To Route modal",
  },
  header: {
    id: "addToRouteModal.header",
    defaultMessage: "Add {entityName} to Route",
    description: "Header of Add To Route modal",
  },
  placeholder: {
    id: "addToRouteModal.body.select.placeholder",
    defaultMessage: "Select Route",
    description: "Select Route Placeholder for Add To Route modal",
  },
  create: {
    id: "addToRouteModal.footer.create",
    defaultMessage: "+ Create New Route",
    description: "Create New Route button in a footer of Add To Route modal",
  },
  tooManyStopsError: {
    id: "addToRouteModal.error.tooManyEntities",
    defaultMessage: "You can only add {max} {max, plural, one {stop} other {stops}} to a route",
    description: "To Many Entities error message on an Add To Route modal",
  },
});

interface OwnProps {
  adding: boolean;
  addToRoute: typeof addToRoute.request;
  analyticIssuer?: AnalyticsService;
  autoAssignOnCreate?: boolean;
  createRouteModalVisible: boolean;
  entities: Array<Company> | Array<Person>;
  entityType: EntityTypeSupportingRoutes;
  isMmcGeocodeLimitReached: boolean;
  isOrgGeocodeLimitReached: boolean;
  onHide: (added: boolean) => void;
  onSearch: typeof filterRoutes.request;
  routes: Route[];
  selectedRouteId?: Route["id"];
  selectRoute: typeof selectRoute;
  showCreateRouteModal: typeof showCreateRouteModal;
  visible: boolean;
}

const AddToRouteModal: React.FC<
  OwnProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
> = ({
  adding,
  addToRoute,
  analyticIssuer,
  autoAssignOnCreate,
  createRouteModalVisible,
  entities,
  entityType,
  initialize,
  initializing,
  isMmcGeocodeLimitReached,
  isOrgGeocodeLimitReached,
  onHide,
  onSearch,
  routes,
  selectedRouteId,
  selectRoute,
  showCreateRouteModal,
  visible,
}) => {
  const intl = useIntl();

  useEffect(() => {
    if (!visible) {
      return;
    }

    // if this dialog is somehow opened with more than MAX_ROUTE_STOPS entities, show error message and close
    if (entities.length > MAX_ROUTE_STOPS) {
      notification.error({
        message: intl.formatMessage(messages.tooManyStopsError, { max: MAX_ROUTE_STOPS }),
      });
      onHide(false);
    } else {
      selectRoute(undefined);
      initialize({ entities, entityType, autoAssignOnCreate });
    }
  }, [entities, entityType, autoAssignOnCreate, initialize, intl, onHide, selectRoute, visible]);

  const handleCancelClick = useCallback(() => onHide(false), [onHide]);
  const handleCreateRouteClick = useCallback(() => {
    if (isMmcGeocodeLimitReached || isOrgGeocodeLimitReached) {
      showGeocodeLimitAlertModal(intl, isMmcGeocodeLimitReached);
    } else {
      showCreateRouteModal();
    }
    analyticIssuer?.clicked(["Add To Route", "Create New Route"]);
  }, [
    analyticIssuer,
    intl,
    isMmcGeocodeLimitReached,
    isOrgGeocodeLimitReached,
    showCreateRouteModal,
  ]);

  const handleAddToRoute = useCallback(() => {
    addToRoute({ callback: () => onHide(true), entities, entityType });
    analyticIssuer?.clicked(["Add To Route", "Add To Route"]);
  }, [addToRoute, analyticIssuer, entities, entityType, onHide]);

  const selectedRoute = routes.find(({ id }) => id === selectedRouteId);
  const isTooManyStops = selectedRoute
    ? selectedRoute.items + entities.length > MAX_ROUTE_STOPS
    : false;

  const handleRouteCreation = useCallback(() => {
    autoAssignOnCreate && onHide?.(true);
  }, [autoAssignOnCreate, onHide]);

  const handleSearch = useDebouncedCallback(
    [
      (value: string) => {
        onSearch({ entityType, query: value });
      },
      500,
    ],
    [entityType, onSearch]
  );

  return (
    <Modal
      okButtonProps={{
        disabled: !selectedRouteId || adding || isTooManyStops,
        loading: adding,
        icon: <FontAwesomeIcon icon={faRoute} />,
      }}
      okText={intl.formatMessage(messages.add)}
      onCancel={handleCancelClick}
      onOk={handleAddToRoute}
      open={visible}
      title={intl.formatMessage(messages.header, {
        entityName: getEntityTypeDisplayName(intl, entityType, {
          lowercase: false,
          accusativePlural: entities.length > 1,
        }),
      })}
    >
      <div>
        <SelectField<Route["id"]>
          dropdownMatchSelectWidth={false}
          error={
            isTooManyStops
              ? intl.formatMessage(messages.tooManyStopsError, { max: MAX_ROUTE_STOPS })
              : undefined
          }
          filterOption={(input, option) =>
            (option?.label as string).toLowerCase().includes(input.toLowerCase())
          }
          placeholder={intl.formatMessage(messages.placeholder)}
          loading={initializing}
          onChange={selectRoute}
          onSearch={handleSearch}
          options={routes.map(itemToValueLabel)}
          showSearch
          value={selectedRouteId}
        />
        <Button className={styles.createLink} onClick={handleCreateRouteClick} type="link">
          {intl.formatMessage(messages.create)}
        </Button>
      </div>
      {createRouteModalVisible && (
        <CreateRouteModal entityType={entityType} onCreated={handleRouteCreation} routes={routes} />
      )}
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  adding: isAdding(state),
  createRouteModalVisible: isCreateRouteModalVisible(state),
  initializing: isInitializing(state),
  isMmcGeocodeLimitReached: isGeocodeMmcLimitReached(state),
  isOrgGeocodeLimitReached: isGeocodeOrgLimitReached(state),
  routes: getRoutes(state),
  selectedRouteId: getSelectedRouteId(state),
});

const mapDispatchToProps = {
  initialize: initialize.request,
  addToRoute: addToRoute.request,
  onSearch: filterRoutes.request,
  selectRoute,
  showCreateRouteModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToRouteModal);

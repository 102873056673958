import MarkerShape from "@mapmycustomers/shared/enum/MarkerShape";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages<MarkerShape>({
  [MarkerShape.PIN]: {
    id: "markerShape.pin",
    defaultMessage: "Pin",
    description: "Name of the Pin marker shape",
  },
  [MarkerShape.CIRCLE]: {
    id: "markerShape.circle",
    defaultMessage: "Circle",
    description: "Name of the Circle marker shape",
  },
  [MarkerShape.SQUARE]: {
    id: "markerShape.square",
    defaultMessage: "Square",
    description: "Name of the Square marker shape",
  },
  [MarkerShape.FLAG]: {
    id: "markerShape.flag",
    defaultMessage: "Flag",
    description: "Name of the Flag marker shape",
  },
  [MarkerShape.STAR]: {
    id: "markerShape.star",
    defaultMessage: "Star",
    description: "Name of the Star marker shape",
  },
  [MarkerShape.TRIANGLE]: {
    id: "markerShape.triangle",
    defaultMessage: "Triangle",
    description: "Name of the Triangle marker shape",
  },
  [MarkerShape.HEXAGON]: {
    id: "markerShape.hexagon",
    defaultMessage: "Hexagon",
    description: "Name of the Hexagon marker shape",
  },
  [MarkerShape.HEART]: {
    id: "markerShape.heart",
    defaultMessage: "Heart",
    description: "Name of the Heart marker shape",
  },
  [MarkerShape.BOOKMARK]: {
    id: "markerShape.bookmark",
    defaultMessage: "Bookmark",
    description: "Name of the Bookmark marker shape",
  },
  [MarkerShape.LEAD]: {
    id: "markerShape.lead",
    defaultMessage: "Lead",
    description: "Name of the Lead marker shape",
  },
  [MarkerShape.LEAD_CLICKED]: {
    id: "markerShape.lead.clicked",
    defaultMessage: "Lead",
    description: "Name of the Lead marker shape",
  },
  [MarkerShape.LEAD_DUPLICATE]: {
    id: "markerShape.lead.duplicate",
    defaultMessage: "Lead",
    description: "Name of the Lead marker shape",
  },
  [MarkerShape.LEAD_DUPLICATE_CLICKED]: {
    id: "markerShape.lead.duplicate.clicked",
    defaultMessage: "Lead",
    description: "Name of the Lead marker shape",
  },
  [MarkerShape.LEAD_SELECTED]: {
    id: "markerShape.lead.selected",
    defaultMessage: "Lead",
    description: "Name of the Lead marker shape",
  },
  [MarkerShape.LEAD_HIDDEN]: {
    id: "markerShape.lead.hidden",
    defaultMessage: "Lead",
    description: "Name of the Lead hidden shape",
  },
  [MarkerShape.LEAD_HIDDEN_CLICKED]: {
    id: "markerShape.lead.hidden.clicked",
    defaultMessage: "Lead",
    description: "Name of the Lead hidden clicked shape",
  },
});

const getMarkerShapeDisplayName = (intl: IntlShape, shape: MarkerShape) =>
  intl.formatMessage(messages[shape]);

export default getMarkerShapeDisplayName;

import React from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import User from "@mapmycustomers/shared/types/User";
import ViewAsFilterComponent from "./ViewAsFilterComponent";

// fake operator, it's not really used, but we need to specify at least one
export const VIEW_AS_FILTER_OPERATORS = [FilterOperator.EQUALS];

const doesSupportValue = (value: any, operator: FilterOperator) => false;

interface UsersFilterProps extends IFilterComponentProps {}

const ViewAsFilter: IFilterInstance = {
  doesSupportValue,
  doesSupportOption: (option: FilterOption) => option === FilterOption.AVAILABLE_USERS,
  getHumanReadableDescription: (value: SimpleCondition, field: IField, options) => {
    return ""; // TBD later if needed
  },
  getComponent:
    (): React.FC<UsersFilterProps> =>
    ({ className, onChange, options, value }) => {
      const availableUsers = (options?.availableUsers as User[]) ?? [];
      const selectedUser = value.value
        ? availableUsers.find(({ id }) => id === value.value)
        : undefined;

      return (
        <ViewAsFilterComponent
          className={className}
          onChange={(user: User | undefined) => onChange?.({ ...value, value: user?.id })}
          users={availableUsers}
          value={selectedUser}
        />
      );
    },
};

export default ViewAsFilter;

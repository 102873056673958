import React from "react";
import { useIntl } from "react-intl";
import cn from "classnames";
import styles from "./Divider.module.scss";

interface Props {
  className?: string;
  designMode?: boolean;
}

const Divider: React.FC<Props> = ({ className, designMode }) => {
  const intl = useIntl();
  return (
    <div className={cn(styles.container, className, { [styles.designMode]: designMode })}>
      <span className={cn({ [styles.empty]: !designMode })}>
        {designMode ? (
          intl.formatMessage({
            defaultMessage: "Divider",
            description: "Title of Divider canvas element",
            id: "settings.formLayouts.canvas.divider",
          })
        ) : (
          <>&nbsp;</>
        )}
      </span>
    </div>
  );
};

export default Divider;

import { all, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import {
  applyForecastViewSettings,
  applyKanbanViewSettings,
  applyListViewSettings,
  downloadDealData,
  exportDateDeals,
  exportStageDeals,
  fetchForecastDateDeals,
  fetchForecastDeals,
  fetchForecastNextPeriodDeals,
  fetchFunnelStagesSummary,
  fetchKanbanDeals,
  fetchKanbanStageDeals,
  fetchList,
  initializeForecastView,
  initializeKanbanView,
  initializeListView,
  setForecastHiddenColumnIds,
  setForecastViewMode,
  setKanbanHiddenColumnIds,
  switchView,
  updateDealView,
  updateForecastByDealChanging,
  updateKanbanByDealChanging,
} from "./actions";
import { handleError } from "store/errors/actions";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import Deal from "@mapmycustomers/shared/types/entity/Deal";
import { callApi } from "store/api/callApi";
import { getOrganization } from "store/iam";
import { convertToPlatformSortModel } from "util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "util/viewModel/convertToPlatformFilterModel";
import dealFieldModel from "util/fieldModel/DealFieldModel";
import localSettings from "config/LocalSettings";
import {
  getCurrentView,
  getForecastColumnCount,
  getForecastDeals,
  getForecastView,
  getForecastViewMode,
  getForecastViewState,
  getKanbanDeals,
  getKanbanView,
  getKanbanViewState,
  getListView,
  getListViewState,
  getUpdateViewStateCallback,
  getViewMode,
} from "./selectors";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";
import Organization from "@mapmycustomers/shared/types/Organization";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import { deleteFunnel, ensureStagesFetched, updateStages } from "store/deal/actions";
import { EntityType } from "@mapmycustomers/shared/enum";
import { defineMessage } from "react-intl";
import i18nService from "config/I18nService";
import { getFunnels, getFunnelStages } from "store/deal";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { isSimpleCondition } from "util/viewModel/assert";
import ForecastViewMode from "../component/ForecastPage/enum/ForecastViewMode";
import ForecastColumnType from "../component/ForecastPage/enum/ForecastColumnType";
import getClosingDateFilterByModeAndColumns from "../component/ForecastPage/util/getClosingDateFilterByModeAndColumns";
import ForecastViewState from "../component/ForecastPage/types/ForecastViewState";
import BaseView from "./types/BaseView";
import View from "./types/BaseView";
import ViewMode from "../enum/ViewMode";
import { push } from "connected-react-router";
import Path from "enum/Path";
import FilterModel, {
  FilterCondition,
  SimpleCondition,
} from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import BoardViewState from "../type/BoardViewState";
import { isActionOf } from "typesafe-actions";
import omit from "lodash-es/omit";
import { initializeSavedFiltersForView } from "../../../store/savedFilters/actions";
import { getSelectedFilter } from "../../../store/savedFilters";
import SavedFilter from "@mapmycustomers/shared/types/viewModel/SavedFilter";
import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import ListResponseAggregation from "../../../types/viewModel/ListResponseAggregation";
import AggregatedListResponse from "../../../types/viewModel/AggregatedListResponse";
import { StagesWithDeals } from "./types/KanbanView";
import { StageSummary } from "@mapmycustomers/shared/types/entity/deals/StageSummary";

import formatDateAsColumnKey from "../component/ForecastPage/util/formatDateAsColumnKey";
import { DatesWithDeals } from "./types/ForecastView";
import { endOfMonth, endOfQuarter, startOfMonth, startOfQuarter } from "date-fns/esm";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import { parseApiDateWithUtcTz } from "util/parsers";
import uniq from "lodash-es/uniq";
import { MAX_AGGREGATION_LIMIT } from "util/consts";
import { Action } from "redux";
import { exportEntities } from "store/exportEntities/actions";
import { formatISO } from "date-fns/esm";

const DEALS_PER_COLUMN = 25;

export const LIST_VIEW_KEY = "deal/listView";
export const KANBAN_VIEW_KEY = "deal/kanbanView";
export const FORECAST_VIEW_KEY = "deal/forecastView";

const reportGeneratedSuccessfullyMessage = defineMessage({
  id: "deal.list.exportRows.success",
  defaultMessage: "This export will be sent to your email shortly.",
  description: "Success message for sending csv file",
});

const getClosingDateFilter = (
  datesWithDeals: DatesWithDeals,
  viewMode: ForecastViewMode,
  date?: Date,
  columnType?: ForecastColumnType
): FilterCondition => {
  const key = formatDateAsColumnKey(viewMode, date);

  let result: FilterCondition = { operator: FilterOperator.EMPTY, value: undefined };
  if (date) {
    const columnType = datesWithDeals[key]?.type;
    const isPastClosingDate = columnType === ForecastColumnType.PAST_CLOSING_DATE;
    if (isPastClosingDate) {
      result = {
        operator: FilterOperator.LESS_THAN,
        value: [
          (viewMode === ForecastViewMode.MONTH ? startOfMonth : startOfQuarter)(
            new Date()
          ).toISOString(),
        ],
      };
    } else {
      result = {
        operator: FilterOperator.IN_RANGE,
        value: [
          (viewMode === ForecastViewMode.MONTH ? startOfMonth : startOfQuarter)(date).toISOString(),
          (viewMode === ForecastViewMode.MONTH ? endOfMonth : endOfQuarter)(date).toISOString(),
        ],
      };
    }
  }
  return result;
};

const excludeFunnelFromFilter = (filter: FilterModel, funnelId: Funnel["id"]): FilterModel => {
  if (!filter.funnel || !isSimpleCondition(filter.funnel)) {
    return filter;
  }

  const { operator, value }: SimpleCondition = filter.funnel;
  if (operator === FilterOperator.EQUALS) {
    return value !== funnelId ? filter : omit(filter, "funnel");
  } else if (operator === FilterOperator.IN_ANY) {
    return value.length === 1 && value[0] === funnelId
      ? omit(filter, "funnel")
      : {
          ...filter,
          funnel: { ...filter.funnel, value: value.filter((id: Funnel["id"]) => id !== funnelId) },
        };
  }

  return filter;
};

export function* onInitializeListView() {
  const savedFilterGetter: (entityType: EntityType) => SavedFilter | undefined = yield select(
    getSelectedFilter
  );
  const savedFilter = savedFilterGetter(EntityType.DEAL);
  if (!savedFilter) {
    yield put(
      initializeSavedFiltersForView({ entityType: EntityType.DEAL, viewKey: LIST_VIEW_KEY })
    );
  }
  yield put(initializeListView.success());
}
export function* onInitializeForecastView() {
  yield put(ensureStagesFetched.request(undefined));
  const stagesResult: ReturnType<
    typeof ensureStagesFetched.success | typeof ensureStagesFetched.failure
  > = yield take([ensureStagesFetched.success, ensureStagesFetched.failure]);

  if (isActionOf(ensureStagesFetched.failure, stagesResult)) {
    yield put(initializeForecastView.failure());
    return;
  }
  const savedFilterGetter: (entityType: EntityType) => SavedFilter | undefined = yield select(
    getSelectedFilter
  );
  const savedFilter = savedFilterGetter(EntityType.DEAL);
  if (!savedFilter) {
    yield put(
      initializeSavedFiltersForView({ entityType: EntityType.DEAL, viewKey: FORECAST_VIEW_KEY })
    );
  }

  yield put(initializeForecastView.success());
  // now fetch initial portion of deals. Not passing any viewState, because we don't need any changes there
  yield put(fetchForecastDeals.request({ viewState: {} }));
}

export function* onInitializeKanbanView() {
  const funnels: Funnel[] = yield select(getFunnels);

  if (!funnels.length) {
    yield put(initializeKanbanView.failure());
    return;
  }

  const viewState: ViewState = yield select(getKanbanViewState);

  let funnelId: Funnel["id"] = funnels[0].id;
  // if viewState doesn't have funnel filter or if it is invalid then filter by the 1st funnel
  if (
    !viewState.filter["funnel"] ||
    !isSimpleCondition(viewState.filter.funnel) ||
    viewState.filter.funnel.operator !== FilterOperator.EQUALS
  ) {
    yield put(
      applyKanbanViewSettings({
        filter: {
          ...viewState.filter,
          funnel: { operator: FilterOperator.EQUALS, value: funnelId },
        },
      })
    );
  }

  yield put(ensureStagesFetched.request(undefined));
  const stagesResult: ReturnType<
    typeof ensureStagesFetched.success | typeof ensureStagesFetched.failure
  > = yield take([ensureStagesFetched.success, ensureStagesFetched.failure]);

  if (isActionOf(ensureStagesFetched.failure, stagesResult)) {
    yield put(initializeKanbanView.failure());
    return;
  }

  const savedFilterGetter: (entityType: EntityType) => SavedFilter | undefined = yield select(
    getSelectedFilter
  );
  const savedFilter = savedFilterGetter(EntityType.DEAL);
  if (!savedFilter) {
    yield put(
      initializeSavedFiltersForView({ entityType: EntityType.DEAL, viewKey: KANBAN_VIEW_KEY })
    );
  }
  yield put(initializeKanbanView.success());

  // now fetch initial portion of deals. Not passing any viewState, because we don't need any changes there
  yield put(fetchKanbanDeals.request({ viewState: {} }));
}

export function* onFetchList({ payload }: ReturnType<typeof fetchList.request>) {
  try {
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyListViewSettings(payload.request));
    }

    const listViewState: ViewState = yield select(getListViewState);

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(listViewState, LIST_VIEW_KEY);
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : listViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : listViewState.range.endRow - listViewState.range.startRow;

    const organization: Organization = yield select(getOrganization);
    const requestPayload = {
      $aggs: { sum: ["amount", "adjustedAmount"] },
      $offset,
      $limit,
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : listViewState.filter,
          listViewState.columns,
          dealFieldModel,
          true,
          listViewState.viewAs
        ),
      },
      $order: convertToPlatformSortModel(listViewState.sort),
    };
    const response: AggregatedListResponse<Deal> = yield callApi(
      "fetchDeals",
      organization.id,
      requestPayload
    );
    payload.dataCallback && payload.dataCallback(response);
    yield put(
      fetchList.success({
        funnelSummary: {
          openAmount: response.aggregations?.sum_amount.value ?? 0,
          adjustedAmount: response.aggregations?.sum_adjustedAmount.value ?? 0,
        },
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback && payload.failCallback();
    yield put(fetchList.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchFunnelStagesSummary({
  payload: funnelId,
}: ReturnType<typeof fetchFunnelStagesSummary.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const listViewState: ViewState = yield select(getListViewState);
    const filter = { ...listViewState.filter };

    // We need to see all stages on funnel chart
    if ("stage" in filter) {
      delete filter.stage;
    }
    const payload = {
      $filters: {
        ...convertToPlatformFilterModel(
          filter,
          listViewState.columns,
          dealFieldModel,
          true,
          listViewState.viewAs
        ),
      },
    };

    const response: ListResponse<StageSummary> = yield callApi(
      "fetchFunnelSummary",
      organization.id,
      funnelId,
      payload
    );

    yield put(fetchFunnelStagesSummary.success(response.data));
  } catch (error) {
    yield put(fetchFunnelStagesSummary.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchKanbanDeals({ payload }: ReturnType<typeof fetchKanbanDeals.request>) {
  try {
    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyKanbanViewSettings(payload.viewState));
    }

    const viewState: ViewState = yield select(getKanbanViewState);

    if (!payload.fetchOnlyWithoutFilters && !payload.updateOnly) {
      localSettings.setViewSettings(viewState, KANBAN_VIEW_KEY);
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.viewState.range
        ? payload.viewState.range.startRow
        : viewState.range.startRow;

    const requestPayload = {
      $aggs: {
        sum: ["amount", "adjustedAmount"],
        primaryGroup: { field: "dealStageId" },
        innerHits: {
          limit: DEALS_PER_COLUMN,
        },
      },
      $offset,
      $limit: 0,
      $filters: {
        includeAccessStatus: true,
        includeGroups: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : viewState.filter,
          viewState.columns,
          dealFieldModel,
          true,
          viewState.viewAs
        ),
      },
      $order: convertToPlatformSortModel(viewState.sort),
    };
    const organization: Organization = yield select(getOrganization);
    const response: AggregatedListResponse<Deal, ListResponseAggregation<Deal>[]> = yield callApi(
      "fetchDeals",
      organization.id,
      requestPayload
    );
    payload.dataCallback && payload.dataCallback(response);

    const funnelStages: Record<Funnel["id"], Stage[]> = yield select(getFunnelStages);
    const funnelId: Funnel["id"] = (viewState.filter.funnel as SimpleCondition).value;
    const closeStageIds = new Set(
      funnelStages[funnelId]
        .filter(({ type }) => [DealStageType.LOST, DealStageType.WON].includes(type))
        .map(({ id }) => id)
    );

    let groupedDeals =
      response.aggregations.reduce<StagesWithDeals>(
        (result, { key, data, doc_count, sum_adjustedAmount, sum_amount }) => ({
          ...result,
          [key]: {
            deals: data ?? [],
            total: doc_count ?? 0,
            amount: sum_amount?.value ?? 0,
            adjustedAmount: sum_adjustedAmount?.value ?? 0,
          },
        }),
        {}
      ) ?? {};

    groupedDeals = {
      ...groupedDeals,
      ...funnelStages[funnelId]
        .filter(({ id }) => !(id.toString() in groupedDeals))
        .reduce(
          (result, { id }) => ({
            ...result,
            [id]: {
              deals: [],
              total: 0,
              amount: 0,
              adjustedAmount: 0,
            },
          }),
          {}
        ),
    };

    yield put(
      fetchKanbanDeals.success({
        groupedDeals,
        funnelSummary: {
          openAmount:
            response.aggregations
              .filter(({ key }) => !closeStageIds.has(key))
              .reduce((sum, { sum_amount }) => sum + (sum_amount?.value ?? 0), 0) ?? 0,
          adjustedAmount:
            response.aggregations
              .filter(({ key }) => !closeStageIds.has(key))
              .reduce((sum, { sum_adjustedAmount }) => sum + (sum_adjustedAmount?.value ?? 0), 0) ??
            0,
        },
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback && payload.failCallback();
    yield put(fetchKanbanDeals.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchKanbanStageDeals({
  payload: { stageId, fetchAll, preventUpdate, callback },
}: ReturnType<typeof fetchKanbanStageDeals.request>) {
  try {
    const viewState: ViewState = yield select(getKanbanViewState);
    const stagesWithDeals: StagesWithDeals = yield select(getKanbanDeals);
    const $offset = fetchAll ? 0 : stagesWithDeals[stageId]?.deals.length ?? 0;

    if ($offset >= (stagesWithDeals[stageId]?.total ?? 0)) {
      yield put(
        fetchKanbanStageDeals.success({
          deals: [],
          preventUpdate,
          stageId,
          total: stagesWithDeals[stageId].total ?? 0,
        })
      );
      return;
    }

    const requestPayload = {
      $offset,
      $limit: fetchAll ? -1 : DEALS_PER_COLUMN,
      $filters: {
        includeGroups: true,
        ...convertToPlatformFilterModel(
          { ...viewState.filter, stage: { operator: FilterOperator.EQUALS, value: stageId } },
          viewState.columns,
          dealFieldModel,
          true,
          viewState.viewAs
        ),
      },
      $order: convertToPlatformSortModel(viewState.sort),
    };
    const organization: Organization = yield select(getOrganization);
    const response: ListResponse<Deal> = yield callApi(
      "fetchDeals",
      organization.id,
      requestPayload
    );
    callback?.(response.data);
    yield put(
      fetchKanbanStageDeals.success({
        deals: response.data,
        preventUpdate,
        stageId,
        total: response.total,
      })
    );
  } catch (error) {
    yield put(fetchKanbanStageDeals.failure({ stageId }));
    yield put(handleError({ error }));
  }
}

function* onSetKanbanHiddenColumnIds() {
  const viewState: BoardViewState = yield select(getKanbanViewState);
  localSettings.setViewSettings(viewState, KANBAN_VIEW_KEY);
}

export function* onUpdateKanbanByDealChanging({
  payload: deals,
}: ReturnType<typeof updateKanbanByDealChanging.request>) {
  try {
    const viewState: BoardViewState = yield select(getKanbanViewState);
    const funnelId: Funnel["id"] = (viewState.filter.funnel as SimpleCondition).value;
    const stagesWithDeals: StagesWithDeals = yield select(getKanbanDeals);
    const dealIds = new Set(deals.map(({ id }) => id));
    const stageIdsToUpdate = uniq(
      [
        // only take those deals whose funnel is not changed
        ...deals.filter(({ funnel }) => funnel.id === funnelId),
        ...Object.values(stagesWithDeals).reduce(
          (result, row) => [...result, ...row.deals.filter(({ id, funnel }) => dealIds.has(id))],
          [] as Deal[]
        ),
      ].map(({ stage }) => stage.id)
    );

    // Most probably we will have only 1 deal in action, but here we take maximum of touched stages
    const limit =
      (stageIdsToUpdate.length
        ? Math.max(...stageIdsToUpdate.map((key) => stagesWithDeals[key]?.deals.length ?? 0))
        : 0) + 1;

    const organization: Organization = yield select(getOrganization);
    let groupedDeals = {};

    const dealsTotalsPayload = {
      $aggs: {
        sum: ["amount", "adjustedAmount"],
        primaryGroup: { field: "dealStageId" },
      },
      $limit: 0,
      $filters: {
        ...convertToPlatformFilterModel(
          viewState.filter,
          viewState.columns,
          dealFieldModel,
          true,
          viewState.viewAs
        ),
      },
    };
    const requests = [callApi("fetchDeals", organization.id, dealsTotalsPayload)];

    if (stageIdsToUpdate.length) {
      const requestPayload = {
        $aggs: {
          sum: ["amount", "adjustedAmount"],
          primaryGroup: { field: "dealStageId" },
          innerHits: {
            limit: limit > MAX_AGGREGATION_LIMIT ? MAX_AGGREGATION_LIMIT : limit,
          },
        },
        $filters: {
          includeAccessStatus: true,
          includeGroups: true,
          ...convertToPlatformFilterModel(
            {
              ...viewState.filter,
              stage: { operator: FilterOperator.IN_ANY, value: stageIdsToUpdate },
            },
            viewState.columns,
            dealFieldModel,
            true,
            viewState.viewAs
          ),
        },
        $order: convertToPlatformSortModel(viewState.sort),
      };

      requests.push(callApi("fetchDeals", organization.id, requestPayload));
    }

    const [totalResponse, response]: [
      AggregatedListResponse<Deal, ListResponseAggregation<Deal>[]>,
      AggregatedListResponse<Deal, ListResponseAggregation<Deal>[]> | undefined
    ] = yield all(requests);

    if (stageIdsToUpdate.length && response) {
      const incomingStageIds = new Set(response.aggregations.map(({ key }) => key));
      const emptyStageIds = stageIdsToUpdate.filter((stageId) => !incomingStageIds.has(stageId));

      groupedDeals = {
        ...response.aggregations.reduce<StagesWithDeals>(
          (stages, { key, data, doc_count, sum_adjustedAmount, sum_amount }) => ({
            ...stages,
            [key]: {
              deals: data ?? [],
              total: doc_count ?? 0,
              amount: sum_amount.value,
              adjustedAmount: sum_adjustedAmount.value,
            },
          }),
          {}
        ),
        ...emptyStageIds.reduce<StagesWithDeals>(
          (stages, stageId) => ({
            ...stages,
            [stageId]: {
              deals: [],
              total: 0,
              amount: 0,
              adjustedAmount: 0,
            },
          }),
          {}
        ),
      };
    }

    const funnelStages: Record<Funnel["id"], Stage[]> = yield select(getFunnelStages);
    const closeStageIds = new Set(
      funnelStages[funnelId]
        .filter(({ type }) => [DealStageType.LOST, DealStageType.WON].includes(type))
        .map(({ id }) => id)
    );

    yield put(
      updateKanbanByDealChanging.success({
        groupedDeals,
        funnelSummary: {
          openAmount:
            totalResponse.aggregations
              .filter(({ key }) => !closeStageIds.has(key))
              .reduce((sum, { sum_amount }) => sum + sum_amount.value, 0) ?? 0,
          adjustedAmount:
            totalResponse.aggregations
              .filter(({ key }) => !closeStageIds.has(key))
              .reduce((sum, { sum_adjustedAmount }) => sum + sum_adjustedAmount.value, 0) ?? 0,
        },
        totalFilteredRecords: totalResponse.total,
        totalRecords: totalResponse.accessible,
      })
    );
  } catch (error) {
    yield put(updateKanbanByDealChanging.failure(deals));
    yield put(handleError({ error }));
  }
}

export function* onFetchForecastDeals({ payload }: ReturnType<typeof fetchForecastDeals.request>) {
  try {
    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyForecastViewSettings(payload.viewState));
    }

    const viewState: ForecastViewState = yield select(getForecastViewState);

    if (!payload.fetchOnlyWithoutFilters && !payload.updateOnly) {
      localSettings.setViewSettings(viewState, FORECAST_VIEW_KEY);
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.viewState.range
        ? payload.viewState.range.startRow
        : viewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.viewState.range
        ? payload.viewState.range.endRow - payload.viewState.range.startRow
        : viewState.range.endRow - viewState.range.startRow;

    const requestPayload = {
      $aggs: {
        sum: ["amount", "adjustedAmount"],
        primaryGroup: {
          field: "closingDate",
          interval: viewState.viewMode === ForecastViewMode.MONTH ? "month" : "quarter",
        },
        innerHits: {
          limit: DEALS_PER_COLUMN,
        },
      },
      $offset,
      $limit,
      $filters: {
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : viewState.filter,
          viewState.columns,
          dealFieldModel,
          true,
          viewState.viewAs
        ),
        "stage.type": {
          [PlatformFilterOperator.NOT_CONTAINS]: [DealStageType.LOST, DealStageType.WON],
        },
      },
      $order: convertToPlatformSortModel(viewState.sort),
    };
    const organization: Organization = yield select(getOrganization);

    const dealsWithNoClosingDatesRequestPayload = {
      $aggs: {
        sum: ["amount", "adjustedAmount"],
      },
      $limit: DEALS_PER_COLUMN,
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters
            ? {}
            : {
                ...viewState.filter,
                closingDate: { operator: FilterOperator.EMPTY, value: undefined },
              },
          viewState.columns,
          dealFieldModel,
          true,
          viewState.viewAs
        ),
        "stage.type": {
          [PlatformFilterOperator.NOT_CONTAINS]: [DealStageType.LOST, DealStageType.WON],
        },
      },
      $order: convertToPlatformSortModel(viewState.sort),
    };

    const dealsWithOlderClosingDatesRequestPayload = {
      $aggs: {
        sum: ["amount", "adjustedAmount"],
      },
      $limit: DEALS_PER_COLUMN,
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters
            ? {}
            : {
                ...viewState.filter,
                closingDate: {
                  operator: FilterOperator.LESS_THAN,
                  value: [
                    (viewState.viewMode === ForecastViewMode.MONTH ? startOfMonth : startOfQuarter)(
                      new Date()
                    ).toISOString(),
                  ],
                },
              },
          viewState.columns,
          dealFieldModel,
          true,
          viewState.viewAs
        ),
        "stage.type": {
          [PlatformFilterOperator.NOT_CONTAINS]: [DealStageType.LOST, DealStageType.WON],
        },
      },
      $order: convertToPlatformSortModel(viewState.sort),
    };

    const dealsTotalsPayload = {
      $aggs: {
        sum: ["amount", "adjustedAmount"],
      },
      $limit: 0,
      $filters: {
        ...convertToPlatformFilterModel(
          omit(payload.fetchOnlyWithoutFilters ? {} : { ...viewState.filter }, "closingDate"),
          viewState.columns,
          dealFieldModel,
          true,
          viewState.viewAs
        ),
        "stage.type": {
          [PlatformFilterOperator.NOT_CONTAINS]: [DealStageType.LOST, DealStageType.WON],
        },
      },
    };

    const [response, noClosingDateResponse, totalResponse, olderClosingDateResponse]: [
      AggregatedListResponse<Deal, ListResponseAggregation<Deal>[]>,
      AggregatedListResponse<Deal>,
      AggregatedListResponse<Deal>,
      AggregatedListResponse<Deal>
    ] = yield all([
      callApi("fetchDeals", organization.id, requestPayload),
      callApi("fetchDeals", organization.id, dealsWithNoClosingDatesRequestPayload),
      callApi("fetchDeals", organization.id, dealsTotalsPayload),
      callApi("fetchDeals", organization.id, dealsWithOlderClosingDatesRequestPayload),
    ]);

    payload.dataCallback?.(response);
    yield put(
      fetchForecastDeals.success({
        groupedDeals: {
          ...(response.aggregations.reduce<DatesWithDeals>(
            (stages, { key, data, doc_count, sum_adjustedAmount, sum_amount }) => {
              const date = parseApiDateWithUtcTz(key);
              return {
                ...stages,
                [formatDateAsColumnKey(viewState.viewMode, date)]: {
                  date,
                  deals: data ?? [],
                  total: doc_count ?? 0,
                  amount: sum_amount.value,
                  adjustedAmount: sum_adjustedAmount.value,
                  type: ForecastColumnType.DATED,
                },
              };
            },
            {}
          ) ?? {}),
          [ForecastColumnType.NO_CLOSING_DATE]: {
            deals: noClosingDateResponse.data,
            total: noClosingDateResponse.total,
            amount: noClosingDateResponse.aggregations?.sum_amount.value ?? 0,
            adjustedAmount: noClosingDateResponse.aggregations?.sum_adjustedAmount.value ?? 0,
            type: ForecastColumnType.NO_CLOSING_DATE,
          },
          [ForecastColumnType.PAST_CLOSING_DATE]: {
            deals: olderClosingDateResponse.data,
            total: olderClosingDateResponse.total,
            amount: olderClosingDateResponse.aggregations?.sum_amount.value ?? 0,
            adjustedAmount: olderClosingDateResponse.aggregations?.sum_adjustedAmount.value ?? 0,
            type: ForecastColumnType.PAST_CLOSING_DATE,
          },
        },
        funnelSummary: {
          openAmount: totalResponse.aggregations?.sum_amount.value ?? 0,
          adjustedAmount: totalResponse.aggregations?.sum_adjustedAmount.value ?? 0,
        },
        totalFilteredRecords: totalResponse.total,
        totalRecords: totalResponse.accessible,
      })
    );
  } catch (error) {
    payload.failCallback && payload.failCallback();
    yield put(fetchForecastDeals.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchForecastDateDeals({
  payload: { date, fetchAll, preventUpdate, callback },
}: ReturnType<typeof fetchForecastDateDeals.request>) {
  try {
    const viewState: ForecastViewState = yield select(getForecastViewState);
    const datesWithDeals: DatesWithDeals = yield select(getForecastDeals);
    const key = formatDateAsColumnKey(viewState.viewMode, date);
    const $offset = fetchAll ? 0 : datesWithDeals[key]?.deals.length ?? 0;
    if ($offset === datesWithDeals[key]?.total) {
      yield put(
        fetchForecastDateDeals.success({
          deals: [],
          date,
          preventUpdate,
        })
      );
      return;
    }
    let closingDateFilter = getClosingDateFilter(datesWithDeals, viewState.viewMode, date);
    const requestPayload = {
      $offset,
      $limit: fetchAll ? -1 : DEALS_PER_COLUMN,
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          { ...viewState.filter, closingDate: closingDateFilter },
          viewState.columns,
          dealFieldModel,
          true,
          viewState.viewAs
        ),
        "stage.type": {
          [PlatformFilterOperator.NOT_CONTAINS]: [DealStageType.LOST, DealStageType.WON],
        },
      },
      $order: convertToPlatformSortModel(viewState.sort),
    };
    const organization: Organization = yield select(getOrganization);
    const response: ListResponse<Deal> = yield callApi(
      "fetchDeals",
      organization.id,
      requestPayload
    );
    callback?.(response.data);
    yield put(
      fetchForecastDateDeals.success({
        deals: response.data,
        date,
        preventUpdate,
      })
    );
  } catch (error) {
    yield put(fetchForecastDateDeals.failure({ date }));
    yield put(handleError({ error }));
  }
}

export function* onFetchForecastNextPeriodDeals() {
  try {
    const viewState: ForecastViewState = yield select(getForecastViewState);
    const columnCount: number = yield select(getForecastColumnCount);

    const requestPayload = {
      $aggs: {
        sum: ["amount", "adjustedAmount"],
        primaryGroup: {
          field: "closingDate",
          interval: viewState.viewMode === ForecastViewMode.MONTH ? "month" : "quarter",
        },
        innerHits: {
          limit: DEALS_PER_COLUMN,
        },
      },
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          {
            ...viewState.filter,
            closingDate: getClosingDateFilterByModeAndColumns(viewState.viewMode, columnCount),
          },
          viewState.columns,
          dealFieldModel,
          true,
          viewState.viewAs
        ),
        "stage.type": { [PlatformFilterOperator.NOT_EQUAL]: DealStageType.LOST },
      },
      $order: convertToPlatformSortModel(viewState.sort),
    };

    const organization: Organization = yield select(getOrganization);
    const response: AggregatedListResponse<Deal, ListResponseAggregation<Deal>[]> = yield callApi(
      "fetchDeals",
      organization.id,
      requestPayload
    );
    yield put(
      fetchForecastNextPeriodDeals.success({
        groupedDeals:
          response.aggregations.reduce<DatesWithDeals>(
            (stages, { key, data, doc_count, sum_adjustedAmount, sum_amount }) => {
              const date = parseApiDateWithUtcTz(key);
              return {
                ...stages,
                [formatDateAsColumnKey(viewState.viewMode, date)]: {
                  date,
                  deals: data ?? [],
                  total: doc_count ?? 0,
                  amount: sum_amount.value,
                  adjustedAmount: sum_adjustedAmount.value,
                  type: ForecastColumnType.DATED,
                },
              };
            },
            {}
          ) ?? {},
      })
    );
  } catch (error) {
    yield put(fetchForecastNextPeriodDeals.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateForecastByDealChanging({
  payload: deals,
}: ReturnType<typeof updateForecastByDealChanging.request>) {
  try {
    const viewState: ForecastViewState = yield select(getForecastViewState);
    const datesWithDeals: DatesWithDeals = yield select(getForecastDeals);
    const changedDealIds = new Set(deals.map(({ id }) => id));
    const keysToUpdate: Record<string, Date | undefined> = [
      ...Object.values(datesWithDeals)
        .reduce((result: Deal[], { deals }) => [...result, ...deals], [] as Deal[])
        .filter(({ id }) => changedDealIds.has(id)),
      ...deals,
    ].reduce(
      (keys, { closingDate }) => ({
        ...keys,
        [formatDateAsColumnKey(
          viewState.viewMode,
          closingDate ? parseApiDateWithUtcTz(closingDate) : undefined
        )]: closingDate ? parseApiDateWithUtcTz(closingDate) : undefined,
      }),
      {}
    );
    const datesWithDealsKeys = Object.keys(datesWithDeals);
    const keysToUpdateArray = Object.keys(keysToUpdate);
    const filteredPastKeys = keysToUpdateArray.filter((key) => !datesWithDealsKeys.includes(key));
    const keysToUpdateSet = new Set(Object.keys(keysToUpdate));
    const definedClosingKeysToUpdateSet = new Set(keysToUpdateSet);
    definedClosingKeysToUpdateSet.delete(ForecastColumnType.NO_CLOSING_DATE);
    // Most probably we will have only 1 created deal, but here we take maximum of touched periods
    const limit =
      (keysToUpdateSet.size
        ? Math.max(
            ...Object.keys(keysToUpdate).map((key) => datesWithDeals[key]?.deals.length ?? 0)
          )
        : 0) + 1;

    const organization: Organization = yield select(getOrganization);

    let groupedDeals: DatesWithDeals = {};

    if (definedClosingKeysToUpdateSet.size > 0) {
      const requestPayload = {
        $aggs: {
          sum: ["amount", "adjustedAmount"],
          primaryGroup: {
            field: "closingDate",
            interval: viewState.viewMode === ForecastViewMode.MONTH ? "month" : "quarter",
          },
          innerHits: {
            limit: limit > MAX_AGGREGATION_LIMIT ? MAX_AGGREGATION_LIMIT : limit,
          },
        },
        $filters: {
          includeAccessStatus: true,
          ...convertToPlatformFilterModel(
            {
              ...viewState.filter,
              closingDate: {
                operator: "OR",
                conditions: [
                  ...Array.from(definedClosingKeysToUpdateSet).map((key) => ({
                    operator: FilterOperator.IN_RANGE,
                    value: [
                      formatISO(
                        (viewState.viewMode === ForecastViewMode.MONTH
                          ? startOfMonth
                          : startOfQuarter)(keysToUpdate[key] as Date),
                        { representation: "date" }
                      ),
                      formatISO(
                        (viewState.viewMode === ForecastViewMode.MONTH ? endOfMonth : endOfQuarter)(
                          keysToUpdate[key]!
                        ),
                        { representation: "date" }
                      ),
                    ],
                  })),
                ],
              },
            },
            viewState.columns,
            dealFieldModel,
            true,
            viewState.viewAs
          ),
          "stage.type": { [PlatformFilterOperator.NOT_EQUAL]: DealStageType.LOST },
        },
        $order: convertToPlatformSortModel(viewState.sort),
      };

      const response: AggregatedListResponse<Deal, ListResponseAggregation<Deal>[]> = yield callApi(
        "fetchDeals",
        organization.id,
        requestPayload
      );

      groupedDeals = {
        ...(response.aggregations.reduce<DatesWithDeals>(
          (result, { key, data, doc_count, sum_adjustedAmount, sum_amount }) => {
            const date = parseApiDateWithUtcTz(key);
            const newKey = formatDateAsColumnKey(viewState.viewMode, date);
            if (definedClosingKeysToUpdateSet.has(newKey)) {
              return {
                ...result,
                [newKey]: {
                  date,
                  deals: data ?? [],
                  total: doc_count ?? 0,
                  amount: sum_amount.value,
                  adjustedAmount: sum_adjustedAmount.value,
                  type: ForecastColumnType.DATED,
                },
              };
            }
            return result;
          },
          {}
        ) ?? {}),
      };
    }
    if (keysToUpdateSet.has(ForecastColumnType.NO_CLOSING_DATE)) {
      const dealsWithNoClosingDatesRequestPayload = {
        $aggs: {
          sum: ["amount", "adjustedAmount"],
        },
        $filters: {
          includeAccessStatus: true,
          ...convertToPlatformFilterModel(
            {
              ...viewState.filter,
              closingDate: { operator: FilterOperator.EMPTY, value: undefined },
            },
            viewState.columns,
            dealFieldModel,
            true,
            viewState.viewAs
          ),
          "stage.type": { [PlatformFilterOperator.NOT_EQUAL]: DealStageType.LOST },
        },
        $limit: limit,
        $order: convertToPlatformSortModel(viewState.sort),
      };

      const noClosingDateResponse: AggregatedListResponse<Deal> = yield callApi(
        "fetchDeals",
        organization.id,
        dealsWithNoClosingDatesRequestPayload
      );
      groupedDeals = {
        ...groupedDeals,
        [ForecastColumnType.NO_CLOSING_DATE]: {
          deals: noClosingDateResponse.data,
          total: noClosingDateResponse.total,
          amount: noClosingDateResponse.aggregations?.sum_amount.value ?? 0,
          adjustedAmount: noClosingDateResponse.aggregations?.sum_adjustedAmount.value ?? 0,
          type: ForecastColumnType.NO_CLOSING_DATE,
        },
      };
    }

    if (
      filteredPastKeys.length > 0 ||
      keysToUpdateArray.includes(ForecastColumnType.PAST_CLOSING_DATE)
    ) {
      const dealsWithPastClosingDatesRequestPayload = {
        $aggs: {
          sum: ["amount", "adjustedAmount"],
        },
        $filters: {
          includeAccessStatus: true,
          ...convertToPlatformFilterModel(
            {
              ...viewState.filter,
              closingDate: {
                operator: FilterOperator.LESS_THAN,
                value: (viewState.viewMode === ForecastViewMode.MONTH
                  ? startOfMonth
                  : startOfQuarter)(new Date()),
              },
            },
            viewState.columns,
            dealFieldModel,
            true,
            viewState.viewAs
          ),
          "stage.type": {
            [PlatformFilterOperator.NOT_CONTAINS]: [DealStageType.LOST, DealStageType.WON],
          },
        },
        $limit: DEALS_PER_COLUMN,
        $order: convertToPlatformSortModel(viewState.sort),
      };

      const pastClosingDateResponse: AggregatedListResponse<Deal> = yield callApi(
        "fetchDeals",
        organization.id,
        dealsWithPastClosingDatesRequestPayload
      );
      groupedDeals = {
        ...groupedDeals,
        [ForecastColumnType.PAST_CLOSING_DATE]: {
          deals: pastClosingDateResponse.data,
          total: pastClosingDateResponse.total,
          amount: pastClosingDateResponse.aggregations?.sum_amount.value ?? 0,
          adjustedAmount: pastClosingDateResponse.aggregations?.sum_adjustedAmount.value ?? 0,
          type: ForecastColumnType.PAST_CLOSING_DATE,
        },
      };
    }

    const dealsTotalsPayload = {
      $aggs: {
        sum: ["amount", "adjustedAmount"],
      },
      $limit: 0,
      $filters: {
        ...convertToPlatformFilterModel(
          omit(viewState.filter, "closingDate"),
          viewState.columns,
          dealFieldModel,
          true,
          viewState.viewAs
        ),
        "stage.type": {
          [PlatformFilterOperator.NOT_CONTAINS]: [DealStageType.LOST, DealStageType.WON],
        },
      },
    };

    const totalResponse: AggregatedListResponse<Deal> = yield callApi(
      "fetchDeals",
      organization.id,
      dealsTotalsPayload
    );

    yield put(
      updateForecastByDealChanging.success({
        groupedDeals,
        funnelSummary: {
          openAmount: totalResponse.aggregations?.sum_amount.value ?? 0,
          adjustedAmount: totalResponse.aggregations?.sum_adjustedAmount.value ?? 0,
        },
        totalFilteredRecords: totalResponse.total,
        totalRecords: totalResponse.accessible,
      })
    );
  } catch (error) {
    yield put(updateForecastByDealChanging.failure(deals));
    yield put(handleError({ error }));
  }
}

function* onSetForecastHiddenColumnIds() {
  const viewState: ForecastViewState = yield select(getForecastViewState);
  localSettings.setViewSettings(viewState, FORECAST_VIEW_KEY);
}

function* onSetForecastViewMode() {
  const viewMode: ForecastViewMode = yield select(getForecastViewMode);
  const { filter }: ForecastViewState = yield select(getForecastViewState);
  const updatedFilter = {
    ...filter,
    closingDate: getClosingDateFilterByModeAndColumns(viewMode),
  };
  yield put(fetchForecastDeals.request({ viewState: { filter: updatedFilter } }));
}

export function* onFunnelDeleted({ payload: funnel }: ReturnType<typeof deleteFunnel.success>) {
  const kanbanViewState: BoardViewState = yield select(getKanbanViewState);
  const listViewState: BoardViewState = yield select(getListViewState);
  const forecastViewState: BoardViewState = yield select(getForecastViewState);
  const funnels: Funnel[] = yield select(getFunnels);
  const viewMode: ViewMode = yield select(getViewMode);

  const updatedListViewFilter = excludeFunnelFromFilter(listViewState.filter, funnel.id);
  if (listViewState.filter !== updatedListViewFilter) {
    yield put(applyListViewSettings({ filter: updatedListViewFilter }));
    if (viewMode === ViewMode.LIST) {
      yield put(fetchList.request({ request: {} }));
    }
  }

  const updatedForecastViewFilter = excludeFunnelFromFilter(forecastViewState.filter, funnel.id);
  if (forecastViewState.filter !== updatedForecastViewFilter) {
    yield put(applyForecastViewSettings({ filter: updatedForecastViewFilter }));
    if (viewMode === ViewMode.FORECAST) {
      yield put(fetchForecastDeals.request({ viewState: {} }));
    }
  }
  if (
    kanbanViewState.filter.funnel &&
    isSimpleCondition(kanbanViewState.filter.funnel) &&
    (kanbanViewState.filter.funnel.value as Funnel["id"]) === funnel.id
  ) {
    yield put(
      applyKanbanViewSettings({
        filter: {
          ...kanbanViewState.filter,
          funnel: {
            operator: FilterOperator.EQUALS,
            value: funnels.filter(({ id }) => id !== funnel.id)[0].id,
          },
        },
      })
    );
    if (viewMode === ViewMode.KANBAN) {
      yield put(fetchKanbanDeals.request({ viewState: {} }));
    }
  }
}

export function* onStagesChanged({
  payload: { funnelId },
}: ReturnType<typeof updateStages.success>) {
  const kanbanViewState: BoardViewState = yield select(getKanbanViewState);
  const listViewState: BoardViewState = yield select(getListViewState);
  const forecastViewState: BoardViewState = yield select(getForecastViewState);
  const viewMode: ViewMode = yield select(getViewMode);

  const updatedListViewFilter = excludeFunnelFromFilter(listViewState.filter, funnelId);
  if (listViewState.filter !== updatedListViewFilter && viewMode === ViewMode.LIST) {
    yield put(fetchList.request({ request: {} }));
  }

  const updatedForecastViewFilter = excludeFunnelFromFilter(forecastViewState.filter, funnelId);
  if (forecastViewState.filter !== updatedForecastViewFilter && viewMode === ViewMode.FORECAST) {
    yield put(fetchForecastDeals.request({ viewState: {} }));
  }
  if (
    kanbanViewState.filter.funnel &&
    isSimpleCondition(kanbanViewState.filter.funnel) &&
    (kanbanViewState.filter.funnel.value as Funnel["id"]) === funnelId &&
    viewMode === ViewMode.KANBAN
  ) {
    yield put(fetchKanbanDeals.request({ viewState: {} }));
  }
}

export function* onDownloadListData({
  payload: { columns, viewMode },
}: ReturnType<typeof downloadDealData>) {
  const { totalFilteredRecords, viewState }: View = yield select(
    viewMode === ViewMode.LIST
      ? getListView
      : viewMode === ViewMode.KANBAN
      ? getKanbanView
      : getForecastView
  );
  let platformRequest = {};
  if (
    viewMode === ViewMode.FORECAST &&
    viewState.filter.closingDate &&
    isSimpleCondition(viewState.filter.closingDate) &&
    Array.isArray(viewState.filter.closingDate.value)
  ) {
    // On forecast view we need to remove closingDate from view state filter to get all deals,
    // not only which have closing date. Instead, we use second maximum of selected period
    // and put this to platform filter manually.
    const { closingDate, ...filter } = viewState.filter;
    viewState.filter = filter;
    platformRequest = {
      $filters: {
        $or: [
          {
            closingDate: {
              [PlatformFilterOperator.LESS_THAN_OR_EQUAL]: (closingDate as SimpleCondition)
                .value[1],
            },
          },
          { closingDate: { [PlatformFilterOperator.EQUALS]: null } },
        ],
      },
    };
  }
  yield put(
    exportEntities.request({
      entityType: EntityType.DEAL,
      exportCreationSuccessMessage: i18nService
        .getIntl()
        ?.formatMessage(reportGeneratedSuccessfullyMessage),
      platformRequest,
      total: totalFilteredRecords,
      viewState: { ...viewState, columns },
    })
  );
}

export function* onSwitchView({ payload: { from, to } }: ReturnType<typeof switchView>) {
  // transfer selected funnels between views before switching
  // first of all, get currently selected funnels
  const viewGetter: (viewMode: ViewMode) => BaseView = yield select(getCurrentView);
  const sourceView = viewGetter(from);

  const userFilter =
    sourceView.viewState.filter.user && isSimpleCondition(sourceView.viewState.filter.user)
      ? sourceView.viewState.filter.user
      : undefined;

  const funnelFilter =
    sourceView.viewState.filter.funnel && isSimpleCondition(sourceView.viewState.filter.funnel)
      ? sourceView.viewState.filter.funnel
      : undefined;

  // then create updated filter model where funnel filter is either copied from current view's filter
  // or is removed if current view has no funnel filter
  const destinationView = viewGetter(to);
  const updatedFilterModel: FilterModel = { ...destinationView.viewState.filter };
  if (funnelFilter) {
    updatedFilterModel.funnel = funnelFilter;
  } else {
    delete updatedFilterModel.funnel;
  }

  if (userFilter) {
    updatedFilterModel.user = userFilter;
  } else {
    delete updatedFilterModel.user;
  }

  switch (to) {
    case ViewMode.LIST:
    case ViewMode.FORECAST:
      // list view and forecast view only understand it when funnel filter operator is IN_ANY
      // so let's check if it is EQUALS (e.g. when switching from kanban view) and change
      if (updatedFilterModel.funnel?.operator === FilterOperator.EQUALS) {
        updatedFilterModel.funnel = {
          operator: FilterOperator.IN_ANY,
          value: [updatedFilterModel.funnel.value],
        };
      }
      yield put(
        (to === ViewMode.LIST ? applyListViewSettings : applyForecastViewSettings)({
          filter: updatedFilterModel,
          selectedSavedFilterId: sourceView.viewState.selectedSavedFilterId,
        })
      );
      break;
    case ViewMode.KANBAN:
      // Kanban view requires funnel to be present in filters and requires exactly one funnel
      // So if funnel filter is not present, we don't update kanban view's viewState and keep whatever
      // funnel was already selected there
      if (updatedFilterModel.funnel) {
        // if current funnel filter operator is IN_ANY, we take its funnel only when exactly one funnel is selected
        // also converting filter operator to EQUALS since that's the only operator kanban view understands
        if (
          updatedFilterModel.funnel.operator === FilterOperator.IN_ANY &&
          updatedFilterModel.funnel.value.length === 1
        ) {
          yield put(
            applyKanbanViewSettings({
              filter: {
                ...updatedFilterModel,
                funnel: {
                  operator: FilterOperator.EQUALS,
                  value: updatedFilterModel.funnel.value[0],
                },
              },
              selectedSavedFilterId: sourceView.viewState.selectedSavedFilterId,
            })
          );
        } else if (updatedFilterModel.funnel.operator === FilterOperator.EQUALS) {
          // when current funnel filter operator is equals, we take it as-is
          yield put(
            applyKanbanViewSettings({
              filter: updatedFilterModel,
              selectedSavedFilterId: sourceView.viewState.selectedSavedFilterId,
            })
          );
        } else {
          yield put(
            applyKanbanViewSettings({
              selectedSavedFilterId: sourceView.viewState.selectedSavedFilterId,
            })
          );
        }
      } else {
        yield put(
          applyKanbanViewSettings({
            selectedSavedFilterId: sourceView.viewState.selectedSavedFilterId,
          })
        );
      }
      break;
  }

  // then switch UI
  yield put(push(`${Path.DEAL}/${to}`));
}

export function* onUpdateDealView({ payload: { viewState } }: ReturnType<typeof updateDealView>) {
  const callback: (state: Partial<ViewState>) => void | undefined = yield select(
    getUpdateViewStateCallback
  );
  callback?.(viewState);
}

export function* onExportStageDeals({
  payload: { stageId, columns },
}: ReturnType<typeof exportStageDeals>) {
  const { viewState }: View = yield select(getKanbanView);
  const stagesWithDeals: StagesWithDeals = yield select(getKanbanDeals);

  yield put(
    exportEntities.request({
      entityType: EntityType.DEAL,
      viewState: {
        ...viewState,
        columns,
        filter: { ...viewState.filter, stage: { operator: FilterOperator.EQUALS, value: stageId } },
      },
      total: stagesWithDeals[stageId]?.total,
    })
  );
}

export function* onExportDateDeals({
  payload: { date, columns },
}: ReturnType<typeof exportDateDeals>) {
  const viewState: ForecastViewState = yield select(getForecastViewState);
  const datesWithDeals: DatesWithDeals = yield select(getForecastDeals);
  let closingDateFilter = getClosingDateFilter(datesWithDeals, viewState.viewMode, date);
  const key = formatDateAsColumnKey(viewState.viewMode, date);

  yield put(
    exportEntities.request({
      entityType: EntityType.DEAL,
      platformRequest: {
        $filters: {
          "stage.type": {
            [PlatformFilterOperator.NOT_CONTAINS]: [DealStageType.LOST, DealStageType.WON],
          },
        },
      },
      viewState: {
        ...viewState,
        columns,
        filter: { ...viewState.filter, closingDate: closingDateFilter },
      },
      total: datesWithDeals[key]?.total,
    })
  );
}

export function* dealSaga() {
  yield takeEvery(initializeListView.request, onInitializeListView);
  yield takeEvery(initializeKanbanView.request, onInitializeKanbanView);
  yield takeEvery(initializeForecastView.request, onInitializeForecastView);
  yield takeLatest(
    (action: Action) => isActionOf(fetchList.request)(action) && !action.payload.updateOnly,
    onFetchList
  );
  yield takeEvery(
    (action: Action) => isActionOf(fetchList.request)(action) && !!action.payload.updateOnly,
    onFetchList
  );
  yield takeLatest(
    (action: Action) => isActionOf(fetchKanbanDeals.request)(action) && !action.payload.updateOnly,
    onFetchKanbanDeals
  );
  yield takeEvery(
    (action: Action) => isActionOf(fetchKanbanDeals.request)(action) && !!action.payload.updateOnly,
    onFetchKanbanDeals
  );
  yield takeLatest(fetchKanbanStageDeals.request, onFetchKanbanStageDeals);
  yield takeEvery(updateKanbanByDealChanging.request, onUpdateKanbanByDealChanging);
  yield takeLatest(
    (action: Action) =>
      isActionOf(fetchForecastDeals.request)(action) && !action.payload.updateOnly,
    onFetchForecastDeals
  );
  yield takeEvery(
    (action: Action) =>
      isActionOf(fetchForecastDeals.request)(action) && !!action.payload.updateOnly,
    onFetchForecastDeals
  );
  yield takeLatest(fetchForecastDateDeals.request, onFetchForecastDateDeals);
  yield takeLatest(fetchForecastNextPeriodDeals.request, onFetchForecastNextPeriodDeals);
  yield takeEvery(updateForecastByDealChanging.request, onUpdateForecastByDealChanging);
  yield takeLatest(fetchFunnelStagesSummary.request, onFetchFunnelStagesSummary);
  yield takeEvery(downloadDealData, onDownloadListData);
  yield takeEvery(setForecastViewMode, onSetForecastViewMode);
  yield takeEvery(setForecastHiddenColumnIds, onSetForecastHiddenColumnIds);
  yield takeEvery(setKanbanHiddenColumnIds, onSetKanbanHiddenColumnIds);
  yield takeEvery(switchView, onSwitchView);
  yield takeEvery(deleteFunnel.success, onFunnelDeleted);
  yield takeEvery(updateStages.success, onStagesChanged);
  yield takeLatest(updateDealView, onUpdateDealView);
  yield takeLatest(exportStageDeals, onExportStageDeals);
  yield takeLatest(exportDateDeals, onExportDateDeals);
}

import {
  Actions,
  filterCompanies,
  filterDeals,
  filterEntitiesWithLocation,
  filterPeople,
} from "./actions";
import { createReducer } from "typesafe-actions";
import { Company, Deal, EntityWithLocation, Person } from "@mapmycustomers/shared/types/entity";

export interface EntityAssociationsState {
  companies: Company[];
  deals: Deal[];
  loading: boolean;
  located: EntityWithLocation[];
  people: Person[];
}

const initialState: EntityAssociationsState = {
  companies: [],
  deals: [],
  loading: false,
  located: [],
  people: [],
};

const entityAssociations = createReducer<EntityAssociationsState, Actions>(initialState)
  .handleAction(filterCompanies.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(filterCompanies.success, (state, action) => ({
    ...state,
    companies: action.payload,
    loading: false,
  }))
  .handleAction(filterCompanies.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(filterDeals.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(filterDeals.success, (state, action) => ({
    ...state,
    deals: action.payload,
    loading: false,
  }))
  .handleAction(filterDeals.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(filterPeople.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(filterPeople.success, (state, action) => ({
    ...state,
    loading: false,
    people: action.payload,
  }))
  .handleAction(filterPeople.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(filterEntitiesWithLocation.success, (state, action) => ({
    ...state,
    loading: false,
    located: action.payload,
  }))
  .handleAction(filterEntitiesWithLocation.failure, (state) => ({
    ...state,
    loading: false,
  }));

export * from "./actions";
export * from "./selectors";

export type EntityAssociationsActions = Actions;
export default entityAssociations;

import { useSelector } from "react-redux";
import { getFeatures } from "store/iam";
import Feature from "@mapmycustomers/shared/enum/Feature";
import { OrganizationMetaData } from "@mapmycustomers/shared/types/Organization";

const useFeatures = () => {
  const features: OrganizationMetaData["features"] = useSelector(getFeatures);
  return {
    features,
    hasFeature: (feature: Feature) => !!features?.[feature]?.enabled,
    hasAllFeatures: (...query: Feature[]) => query.every((feature) => features?.[feature]?.enabled),
  };
};

export default useFeatures;

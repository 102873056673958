import { IntlShape, defineMessages } from "react-intl";

enum UnitType {
  MINUTES = "minutes",
  DAYS = "days",
  HOURS = "hours",
  WEEKS = "weeks",
}

const unitMessages = defineMessages<UnitType>({
  [UnitType.DAYS]: {
    id: "createEditActivityModal.header.notifications.unit.days",
    defaultMessage: "days",
    description: "Days unit label",
  },
  [UnitType.HOURS]: {
    id: "createEditActivityModal.header.notifications.unit.hours",
    defaultMessage: "hours",
    description: "Hours unit label",
  },
  [UnitType.MINUTES]: {
    id: "createEditActivityModal.header.notifications.unit.minutes",
    defaultMessage: "minutes",
    description: "Minutes unit label",
  },
  [UnitType.WEEKS]: {
    id: "createEditActivityModal.header.notifications.unit.weeks",
    defaultMessage: "weeks",
    description: "Weeks unit label",
  },
});

export const getUnitTypeDisplayName = (intl: IntlShape, value: UnitType) =>
  intl.formatMessage(unitMessages[value]);

export default UnitType;

import { defineMessages, IntlShape } from "react-intl";

enum StatusOption {
  ANY = "any",
  COMPLETED = "completed",
  ERRORED = "errored",
  COMPLETED_WITH_ERRORS = "completedWithErrors",
}

const messages = defineMessages({
  [StatusOption.ANY]: {
    id: "integration.syncArchive.statusFilter.any",
    defaultMessage: "Any status",
    description: "Any option in Status filter in Integration sync archive table",
  },
  [StatusOption.COMPLETED]: {
    id: "integration.syncArchive.statusFilter.completed",
    defaultMessage: "Success",
    description: "Success option in Status filter in Integration sync archive table",
  },
  [StatusOption.ERRORED]: {
    id: "integrations.syncArchive.statusFilter.errored",
    defaultMessage: "Failed",
    description: "Failed option in Status filter in Integration sync archive table",
  },
  [StatusOption.COMPLETED_WITH_ERRORS]: {
    id: "integrations.syncArchive.statusFilter.completedWithErrors",
    defaultMessage: "Completed with errors",
    description: "Completed with errors option in Status filter in Integration sync archive table",
  },
});

export const getStatusOptionsDisplayName = (intl: IntlShape, value: StatusOption) =>
  intl.formatMessage(messages[value]);

export default StatusOption;

import { isDefined } from "../assert";

/*
 * Following https://tools.ietf.org/html/rfc4646 with small modifications (allowing both - and _ to
 * separate language and region and allowing more chars than in specification)
 */
const extractLocaleRegex = /(?<language>[a-z]{2,})(?:[-_](?<region>[a-z]{2,}|\d{3}))?/i;

/*
 * Extracts language and region parts from the locale string. Also transforms strings into a lowercase.
 * E.g. en_US -> {language: 'en', region: 'us'}, en -> {language: 'en', region: undefined}
 */
export const getLanguageRegion = (
  locale: string
): { language: string; region: string | undefined } | undefined => {
  const groups = locale.match(extractLocaleRegex)?.groups;
  if (!groups) {
    return undefined;
  }

  return {
    language: groups.language.toLowerCase(),
    region: groups.region?.toLowerCase(),
  };
};

/*
 * Converts language + region representation of locale back into a string.
 */
export const defaultFormatLocale = (
  { language, region }: { language: string; region: string | undefined },
  delimiter: string = "-"
): string => (region ? `${language}${delimiter}${region}` : language);

/*
 * For the given filenames selects a filename which matches given target locale the most. And also returns a
 * locale name for the selected file.
 * Terminology: locale = language [-region], see getLanguageRegion function for more info.
 */
const findLibraryLocale = (
  targetLocale: string,
  localeFilenames: string[],
  fallbackLocale: string,
  fallbackLocaleFilename?: string,
  formatLocale: (languageRegion: {
    language: string;
    region: string | undefined;
  }) => string = defaultFormatLocale
): { locale: string; filename: string | undefined } => {
  const targetLanguageRegion = getLanguageRegion(targetLocale);
  if (!targetLanguageRegion) {
    return { locale: fallbackLocale, filename: fallbackLocaleFilename };
  }

  const regionCandidates = localeFilenames
    .map(getLanguageRegion)
    .map((groups, index) => (groups ? { ...groups, index } : undefined))
    .filter(isDefined)
    .filter((candidate) => candidate.language === targetLanguageRegion.language);

  // we found no filenames with the language matching the target locale's language
  if (!regionCandidates.length) {
    return { locale: fallbackLocale, filename: fallbackLocaleFilename };
  }

  // if target locale has no region...
  if (!targetLanguageRegion.region) {
    // ...try to find candidate without region
    const noRegionCandidate = regionCandidates.find(({ region }) => !region);
    if (noRegionCandidate) {
      return {
        locale: formatLocale(noRegionCandidate),
        filename: localeFilenames[noRegionCandidate.index],
      };
    }

    // ...otherwise, take the very first one
    return {
      locale: formatLocale(regionCandidates[0]),
      filename: localeFilenames[regionCandidates[0].index],
    };
  }

  // now try to match a region...
  const regionCandidate = regionCandidates.find(
    ({ region }) => region === targetLanguageRegion.region
  );
  if (regionCandidate) {
    return {
      locale: formatLocale(regionCandidate),
      filename: localeFilenames[regionCandidate.index],
    };
  }

  // if no region candidate found, let's look for a candidate without region (so that
  // we'll be using 'en.js' for 'en_US' target locale when 'en_US.js' doesn't not exist.
  const noRegionCandidate = regionCandidates.find(({ region }) => !region);
  return noRegionCandidate
    ? {
        locale: formatLocale(noRegionCandidate),
        filename: localeFilenames[noRegionCandidate.index],
      }
    : { locale: fallbackLocale, filename: fallbackLocaleFilename };
};

export default findLibraryLocale;

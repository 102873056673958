import { messages as singleLineTextMessages } from "./SingleLineTextField";
import { messages as NumberFieldMessages } from "./NumberField";
import { messages as LargeTextMessages } from "./LargeTextField";

const messages = {
  ...singleLineTextMessages,
  ...NumberFieldMessages,
  ...LargeTextMessages,
} as const;

export default messages;

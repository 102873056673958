import { MapEntity } from "@mapmycustomers/shared/types/entity";

export const mapEntityIdParser = (
  mapEntityId: string
): Pick<MapEntity, "id" | "entity"> | undefined => {
  const parts = mapEntityId.split(":");
  return parts.length === 2
    ? { entity: parts[0] as MapEntity["entity"], id: parseInt(parts[1], 10) }
    : undefined;
};

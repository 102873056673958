import React, { useEffect, useRef } from "react";
import styles from "./FrequencyBox.module.scss";
import cn from "classnames";
import { RootState } from "../../../store/rootReducer";
import { getFrequencyPanelConfig } from "../../../store/frequency";
import FrequencyPreviewConfig from "../type/FrequencyPreviewConfig";
import { connect } from "react-redux";
import getGradientColorBreakPoints from "../util/getGradientColorBreakPoints";

interface Props {
  children: React.ReactNode;
  className?: string;
  config: FrequencyPreviewConfig;
}

const FrequencyBox: React.FC<Props> = ({
  className,
  config: {
    actualFrequencyCriteria: { cadenceInterval },
  },
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const [lightGreenPercentage, yellowPercentage, redPercentage] =
      getGradientColorBreakPoints(cadenceInterval);
    containerRef.current?.style.setProperty("--gradient-red-percentage", `${redPercentage}%`);
    containerRef.current?.style.setProperty("--gradient-yellow-percentage", `${yellowPercentage}%`);
    containerRef.current?.style.setProperty(
      "--gradient-lightGreen-percentage",
      `${lightGreenPercentage}%`
    );
  }, [cadenceInterval]);

  return (
    <div
      className={cn(styles.container, styles.normalGradient, {
        [styles.twoDaysGradient]: (cadenceInterval ?? 0) === 1,
      })}
      ref={containerRef}
    >
      <div className={cn(styles.contentWrapper, className)}>{children}</div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  config: getFrequencyPanelConfig(state),
});

export default connect(mapStateToProps)(FrequencyBox);

import { EntityType } from "@mapmycustomers/shared/enum";
import activityFieldModel from "./ActivityFieldModel";
import companyFieldModel from "./CompanyFieldModel";
import dealFieldModel from "./DealFieldModel";
import personFieldModel from "./PersonFieldModel";
import routeFieldModel from "./RouteFieldModel";
import leaderboardFieldModel from "./LeaderboardFieldModel";
import universalFieldsFieldModel from "./universalFieldsFieldModel";
import integrationFieldModel from "./IntegrationFieldModel";
import entityHistoryFieldModel from "./entityHistoryFieldModel";

const entityTypeToFieldModel = {
  [EntityType.ACTIVITY]: activityFieldModel,
  [EntityType.COMPANY]: companyFieldModel,
  [EntityType.DEAL]: dealFieldModel,
  [EntityType.INTEGRATION]: integrationFieldModel,
  [EntityType.PERSON]: personFieldModel,
  [EntityType.LEADERBOARD]: leaderboardFieldModel,
  [EntityType.HISTORY]: entityHistoryFieldModel,
  [EntityType.ROUTE]: routeFieldModel,
  universal: universalFieldsFieldModel,
} as const;

export type EntityTypeWithFieldModel = keyof typeof entityTypeToFieldModel;
export type CanonicalEntityTypeWithFieldModel = Exclude<EntityTypeWithFieldModel, "universal">;

const getFieldModelByEntityType = (entityType: EntityTypeWithFieldModel) =>
  entityTypeToFieldModel[entityType];

export default getFieldModelByEntityType;

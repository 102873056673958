import DealAssociation from "component/associations/DealAssociation";
import { addCompanyDeals, addPersonDeals } from "../store/actions";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import {
  Company,
  Deal,
  EntityType,
  EntityTypeSupportingPostCreationModal,
  Person,
} from "@mapmycustomers/shared/types/entity";

interface Props {
  addCompanyDeals: typeof addCompanyDeals.request;
  addPersonDeals: typeof addPersonDeals.request;
  createdRecord: Company | Person | Deal;
  entityType?: EntityTypeSupportingPostCreationModal;
  onHide: (updated?: boolean) => void;
}

const Deals: React.FC<Props> = React.memo(
  ({ addCompanyDeals, addPersonDeals, createdRecord, entityType, onHide }) => {
    const handleAddDeals = useCallback(
      (dealIds: Deal["id"][]) => {
        if (entityType === EntityType.COMPANY) {
          addCompanyDeals({ id: createdRecord.id, dealIds });
        } else if (entityType === EntityType.PERSON) {
          addPersonDeals({ id: createdRecord.id, dealIds });
        }
        onHide();
      },
      [createdRecord, addCompanyDeals, addPersonDeals, entityType, onHide]
    );

    return (
      <DealAssociation
        fixedCompany={entityType === EntityType.COMPANY ? (createdRecord as Company) : undefined}
        fixedPerson={entityType === EntityType.PERSON ? (createdRecord as Person) : undefined}
        multiselect
        onHide={onHide}
        onSelect={handleAddDeals}
      />
    );
  }
);

const mapDispatchToProps = {
  addCompanyDeals: addCompanyDeals.request,
  addPersonDeals: addPersonDeals.request,
};

export default connect(null, mapDispatchToProps)(Deals);

import ILayoutModel, { EntityTypeMap } from "@mapmycustomers/shared/types/layout/ILayoutModel";
import FormLayout, { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";
import {
  Activity,
  EntityTypesSupportingFieldCustomization,
} from "@mapmycustomers/shared/types/entity";
import { defineMessage } from "react-intl";
import i18nService from "config/I18nService";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import memoize from "fast-memoize";
import getActualLayoutSchema from "util/layout/getActualLayoutSchema";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";
import SchemaFieldType from "@mapmycustomers/shared/enum/SchemaFieldType";
import getMissingRequiredFields from "util/layout/getMissingRequiredFields";
import hasMissingRequiredFields from "util/layout/hasMissingRequiredFields";
import camelCase from "lodash-es/camelCase";

const defaultLayoutName = defineMessage({
  id: "layouts.defaultName",
  defaultMessage:
    "Default{entityType, select, accounts { Company} contacts { Person} deals { Deal} crmActivities { Activities} other {}} Layout",
  description: "Name of the default layout",
});

// TODO: do not use this artificial defaultLayout when TART-15259 is done
const getDefaultLayout = (entityType: EntityTypesSupportingFieldCustomization): FormLayout => ({
  id: 0,
  addFields: true,
  createdAt: new Date().toISOString(),
  default: false,
  entity: entityType,
  name: i18nService.formatMessage(defaultLayoutName, "Default Layout", {
    entityType: camelCase(entityType),
  }),
  schema: [
    {
      displayOrder: 1,
      field: "name",
      fieldType: SchemaFieldType.STANDARD,
      required: true,
    },
  ],
  updatedAt: new Date().toISOString(),
});

class LayoutModel<T extends EntityTypesSupportingFieldCustomization> implements ILayoutModel<T> {
  private readonly entityType: T;
  private _layouts: FormLayout[] = [];
  private _recentLayoutId?: FormLayout["id"];
  private _defaultLayoutGetter: (
    layouts: FormLayout[],
    recentLayoutId?: FormLayout["id"]
  ) => FormLayout;

  constructor(entityType: T) {
    this.entityType = entityType;

    this._defaultLayoutGetter = memoize(
      (layouts: FormLayout[], recentLayoutId?: FormLayout["id"]) => {
        if (!layouts.length) {
          return getDefaultLayout(this.entityType);
        }
        if (recentLayoutId) {
          const layout = layouts.find(({ id }) => id === recentLayoutId);
          if (layout) {
            return layout;
          }
        }
        return layouts.find((layout) => layout.default) ?? layouts[0];
      }
    );
  }

  setLayouts(layouts: FormLayout[]) {
    this._layouts = layouts;
  }

  setRecentLayoutId(layoutId: FormLayout["id"]) {
    this._recentLayoutId = layoutId;
  }

  get layouts() {
    return this._layouts;
  }

  get defaultLayout() {
    return this._defaultLayoutGetter(this._layouts, this._recentLayoutId);
  }

  // TODO: remove the entire method when TART-15259 is done
  get defaultFormLayout() {
    const layout = this._defaultLayoutGetter(this._layouts, this._recentLayoutId);
    return layout.id === 0 ? undefined : layout;
  }

  getById(layoutId: FormLayout["id"]): FormLayout | undefined {
    return this.layouts.find(({ id }) => id === layoutId);
  }

  getLayoutFor(entity: EntityTypeMap[T]): FormLayout {
    const layoutId = entity.sourceUpdated?.layoutId ?? entity.sourceCreated?.layoutId;
    return layoutId
      ? this.layouts.find(({ id }) => id === layoutId) ?? this.defaultLayout
      : this.defaultLayout;
  }

  getSchemaFor(entity: EntityTypeMap[T]): LayoutSchemaField[] {
    const layout = this.getLayoutFor(entity);
    const fieldModel = getFieldModelByEntityType(entity.entity);
    if (entity.entity === EntityType.ACTIVITY) {
      const activityType = fieldModel
        .getByName(ActivityFieldName.ACTIVITY_TYPE)!
        .getValueFor(entity) as Activity["crmActivityType"];
      return getActualLayoutSchema(layout, { activityTypeId: activityType.id });
    } else if (entity.entity === EntityType.DEAL) {
      return getActualLayoutSchema(layout, {
        funnelId: entity.funnel.id,
        stageId: entity.stage.id,
      });
    }
    return layout.schema;
  }

  getMissingRequiredFieldsFor(entity: EntityTypeMap[T]): LayoutSchemaField[] {
    const schema = this.getSchemaFor(entity);
    return getMissingRequiredFields(schema, entity);
  }

  hasMissingRequiredFieldsFor(entity: EntityTypeMap[T]): boolean {
    const schema = this.getSchemaFor(entity);
    return hasMissingRequiredFields(schema, entity);
  }
}

export default LayoutModel;

import React, { useCallback, useMemo, useState } from "react";
import { Labeled } from "@mapmycustomers/ui";
import TextWithInfo from "component/typography/TextWithInfo";
import { getLegacyAppUrl } from "util/appUrl";
import Switch from "antd/es/switch";
import SelectField from "component/input/SelectField";
import User from "@mapmycustomers/shared/types/User";
import { userDisplayName } from "util/formatters";
import { AvatarWithName } from "@mapmycustomers/ui";
import { useIntl } from "react-intl";
import configService from "config/ConfigService";
import authService from "store/auth/AuthService";
import { RootState } from "store/rootReducer";
import { getUsers } from "store/members";
import { connect } from "react-redux";
import sortBy from "lodash-es/sortBy";
import Select from "antd/es/select";
import useAnalytics from "util/contexts/useAnalytics";
import styles from "./SharingFields.module.scss";

const DEFAULT_OPTION_KEY = -1;
const Option = Select.Option;

interface Props {
  onChange?: (userIds: User["id"][]) => void;
  users: User[];
  value?: User["id"][];
}

const SharingFields: React.FC<Props> = ({ onChange, users, value }) => {
  const analytics = useAnalytics();
  const intl = useIntl();

  const sortedUsers = useMemo(() => sortBy(users, [userDisplayName]), [users]);
  const userFilterOption = (inputValue: string, option: any) => {
    const user = users.find((type) => type.id === parseInt(option?.value));
    return ((user?.fullName ?? "") + (user?.username ?? ""))
      .toLowerCase()
      .includes(inputValue.toLowerCase().trim());
  };

  const [userSelectBoxVisible, setUserSelectBoxVisibility] = useState(false);

  const handleSharingChange = useCallback(
    (checked) => {
      if (!checked) {
        onChange?.([]);
      }
      setUserSelectBoxVisibility(checked);
      if (checked) {
        analytics.clicked(["Share records in group"]);
      }
    },
    [analytics, onChange]
  );

  const handleUserSelect = useCallback(
    (userIds: User["id"][]) => {
      if (userIds.includes(DEFAULT_OPTION_KEY)) {
        onChange?.(users.map(({ id }) => id));
      } else {
        onChange?.(userIds);
      }
    },
    [onChange, users]
  );

  const baseOldAppUrl = configService.getBaseOldAppUrl();
  const token = authService.getToken();

  if (!token) {
    return null;
  }

  return (
    <>
      <Labeled
        label={
          <TextWithInfo
            info={intl.formatMessage(
              {
                id: "createGroupModal.field.shareWithGroup.tooltipText",
                defaultMessage:
                  "Any records currently in this group, or ones added in the future, will be shared with users selected. You can modify what other users can see in <link>Manage Users</link> ",
                description: "Share With Group field tooltip in the Create Group modal",
              },
              {
                link: (linkText: string) => (
                  <a
                    className={styles.manageUsersLink}
                    href={getLegacyAppUrl(baseOldAppUrl, token, "/dashboard/teams")}
                  >
                    {linkText}
                  </a>
                ),
              }
            )}
          >
            {intl.formatMessage({
              id: "createGroupModal.field.shareWithGroup",
              defaultMessage: "Share records in this group",
              description: "Share With Group field switch label in the Create Group modal",
            })}
          </TextWithInfo>
        }
        labelPosition="side"
        sideLabelSpan={20}
      >
        <Switch checked={userSelectBoxVisible} onChange={handleSharingChange} />
      </Labeled>

      {userSelectBoxVisible && (
        <SelectField<User["id"][]>
          className={styles.selectField}
          dropdownMatchSelectWidth={false}
          filterOption={userFilterOption}
          label={intl.formatMessage({
            id: "createGroupModal.userSelectField.label",
            defaultMessage: "Select users",
            description: "Field label for groups User multi select field",
          })}
          mode="multiple"
          onChange={handleUserSelect}
          value={value}
        >
          <Option key={DEFAULT_OPTION_KEY} value={DEFAULT_OPTION_KEY}>
            {intl.formatMessage({
              id: "createGroupModal.userSelectField.allUsers",
              defaultMessage: "Select all users in list",
              description: "All Users option name for groups User multi select field",
            })}
          </Option>
          )
          {sortedUsers.map((user) => (
            <Option key={user.id} label={userDisplayName(user)} value={user.id}>
              <AvatarWithName avatarClassName={styles.avatar} user={user} />
            </Option>
          ))}
        </SelectField>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: getUsers(state),
});

export default connect(mapStateToProps)(SharingFields);

import { defineMessages } from "react-intl";

const messages = defineMessages({
  acceptAllType: {
    id: "frequency.CustomFrequencySelector.acceptAllActivityTypes",
    defaultMessage: "Accept all activity types",
    description: "frequency component - custom cadence selector - Accept all activity types",
  },
  allActivityTypesStatic: {
    id: "frequency.CustomFrequencySelector.allActivityTypesStatic",
    defaultMessage: "{allActivityTypes, select, true {Yes} other {No}}",
    description: "frequency component - custom cadence selector - all Activity Types static",
  },
  select: {
    id: "frequency.CustomFrequencySelector.select",
    defaultMessage: "Select",
    description: "Placeholder for the Activity Type field",
  },
  remove: {
    id: "frequency.CustomFrequencySelector.remove",
    defaultMessage: "Remove this Frequency",
    description: "frequency component - custom cadence selector - Remove",
  },
  delete: {
    id: "frequency.CustomFrequencySelector.delete",
    defaultMessage: "Delete",
    description: "frequency component - custom cadence selector - Cancel",
  },
  cancel: {
    id: "frequency.CustomFrequencySelector.cancel",
    defaultMessage: "Cancel",
    description: "frequency component - custom cadence selector - Cancel",
  },
  setFrequency: {
    id: "frequency.CustomFrequencySelector.setFrequency",
    defaultMessage: "Set Frequency",
    description: "frequency component - custom cadence selector - Set Frequency",
  },
  lastActivityLoggedValue: {
    id: "frequency.CustomFrequencySelector.lastActivityLogged.value",
    defaultMessage: "{count} {count, plural, one {days} other {day}} ago",
    description: "frequency component - custom cadence selector - Last activity logged - value",
  },
  lastActivityLogged: {
    id: "frequency.CustomFrequencySelector.lastActivityLogged",
    defaultMessage: "Last activity logged",
    description: "frequency component - custom cadence selector - Last activity logged",
  },
  today: {
    id: "frequency.CustomFrequencySelector.lastActivityLogged.value.today",
    defaultMessage: "Today",
    description: "frequency component - custom cadence selector - Last activity logged - Today",
  },
  days: {
    id: "frequency.CustomFrequencySelector.days",
    defaultMessage: "days",
    description: "frequency component - custom cadence selector - days",
  },
  daysCount: {
    id: "frequency.CustomFrequencySelector.daysCount",
    defaultMessage: "{cadence} {cadence, plural, one {day} other {days}}",
    description: "frequency component - custom cadence selector - days count",
  },
  activityInterval: {
    id: "frequency.CustomFrequencySelector.activityInterval",
    defaultMessage: "Activity Interval",
    description: "frequency component - custom cadence selector - Activity Interval",
  },
});

export default messages;

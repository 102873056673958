import { RootState } from "../rootReducer";
import { Actions, changeLocale } from "./actions";
import { createReducer } from "typesafe-actions";
import { createSelector } from "reselect";
import localSettings from "../../config/LocalSettings";

export type LocaleState = {
  // Basically we don't need loading here. The only reason it is added is to guarantee that
  // RawIntlProvider gets an actual intl instance from i18nService, see /src/App.tsx for details.
  loading: boolean;
  locale: string;
};

const initialState: LocaleState = {
  loading: false,
  locale: localSettings.getLocale(),
};

const locale = createReducer<LocaleState, Actions>(initialState)
  .handleAction(changeLocale.request, (state) => ({ ...state, loading: true }))
  .handleAction(changeLocale.failure, (state) => ({ ...state, loading: false }))
  .handleAction(changeLocale.success, (state, action) => ({
    ...state,
    loading: false,
    locale: action.payload,
  }));

const localeState = (state: RootState) => state.locale;
export const getLocale = createSelector(localeState, ({ locale }) => locale);
export const isLoading = createSelector(localeState, ({ loading }) => loading);

export type LocaleActions = Actions;
export default locale;

import { countryFormatter } from "./fieldUtil";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Field, { FieldProperties } from "./Field";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import CountryFilter, { COUNTRY_FILTER_OPERATORS } from "util/filters/CountryFilter";

class CountryField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      type: FieldType.STRING,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.ADDRESS,
        FieldFeature.BULK_EDIT,
        FieldFeature.COUNTRY_FIELD,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      customGridProperties: {
        cellRenderer: "countryCellRenderer",
      },
      csvExportProperties: { valueFormatter: countryFormatter },
      exportColumnName: "country",
      importProperties: { name: "country", required: true },
      customFilterConfig: {
        defaultInstance: "country",
        filterInstances: {
          country: CountryFilter,
        },
        operators: [...COUNTRY_FILTER_OPERATORS],
      },
      ...data,
    });
  }
}

export default CountryField;

import Field, { FieldProperties } from "./Field";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import Cadenced from "@mapmycustomers/shared/types/base/Cadenced";

class FrequencyDaysLeft extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      exportColumnName: "cadenceDaysOut",
      type: FieldType.NUMBER,
      customGridProperties: {
        cellRenderer: "frequencyDaysLeftCellRenderer",
      },
      features: [
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_IMPORT,
      ],
      valueGetter: (entity) => (entity as Cadenced).cadenceDaysOut,
      ...data,
    });
  }
}

export default FrequencyDaysLeft;

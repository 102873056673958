import { all, fork } from "redux-saga/effects";
import { alertsSagas } from "scene/map/store/layers/alerts/sagas";
import { heatMapLayerSagas } from "scene/map/store/layers/heatMap/sagas";
import { boundariesLayerSagas } from "scene/map/store/layers/boundaries/sagas";
import { trafficLayerSagas } from "scene/map/store/layers/traffic/sagas";
import { userLocationsSagas } from "scene/map/store/layers/userLocations/sagas";

export function* layersSagas() {
  yield all([
    fork(alertsSagas),
    fork(heatMapLayerSagas),
    fork(boundariesLayerSagas),
    fork(trafficLayerSagas),
    fork(userLocationsSagas),
  ]);
}

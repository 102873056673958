import { filterCompanies, filterDeals, filterEntitiesWithLocation, filterPeople } from "./actions";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getOrganizationId } from "store/iam";
import { put, select, takeLatest } from "redux-saga/effects";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import { callApi } from "store/api/callApi";
import { handleError } from "store/errors/actions";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import nameComparator from "util/comparator/nameComparator";

export function* onFilterCompanies({ payload }: ReturnType<typeof filterCompanies.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const availableCompanies: ListResponse<Company> = yield callApi("fetchCompanies", orgId, {
      $filters: {
        includeAccessStatus: true,
        notAncestorOf: payload.isChildSelection ? payload.companyId : null,
        notDescendantOf: !payload.isChildSelection ? payload.companyId : null,
        $or: [
          {
            address: payload.query ? { $in: payload.query } : undefined,
            city: payload.query ? { $in: payload.query } : undefined,
            name: payload.query ? { $in: payload.query } : undefined,
            region: payload.query ? { $in: payload.query } : undefined,
            regionCode: payload.query ? { $in: payload.query } : undefined,
            postalCode: payload.query ? { $in: payload.query } : undefined,
          },
        ],
      },
      $limit: 100,
      $offset: 0,
      $order: payload.query ? "name" : "-updatedAt",
    });

    yield put(filterCompanies.success(availableCompanies.data));
  } catch (error) {
    yield put(filterCompanies.failure());
    yield put(handleError({ error }));
  }
}

export function* onFilterDeals({ payload }: ReturnType<typeof filterDeals.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const availableDeals: ListResponse<Deal> = yield callApi("fetchDeals", orgId, {
      $filters: {
        includeAccessStatus: true,
        name: payload.query ? { $in: payload.query } : undefined,
      },
      $limit: 100,
      $offset: 0,
      $order: payload.query ? "name" : "-updatedAt",
    });

    yield put(filterDeals.success(availableDeals.data));
  } catch (error) {
    yield put(filterDeals.failure());
    yield put(handleError({ error }));
  }
}

export function* onFilterPeople({ payload }: ReturnType<typeof filterPeople.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const availablePersons: ListResponse<Person> = yield callApi("fetchPeople", orgId, {
      $filters: {
        includeAccessStatus: true,
        $or: [
          {
            address: payload.query ? { $in: payload.query } : undefined,
            city: payload.query ? { $in: payload.query } : undefined,
            name: payload.query ? { $in: payload.query } : undefined,
            region: payload.query ? { $in: payload.query } : undefined,
            regionCode: payload.query ? { $in: payload.query } : undefined,
            postalCode: payload.query ? { $in: payload.query } : undefined,
          },
        ],
      },
      $limit: 100,
      $offset: 0,
      $order: payload.query ? "name" : "-updatedAt",
    });

    yield put(filterPeople.success(availablePersons.data));
  } catch (error) {
    yield put(filterPeople.failure());
    yield put(handleError({ error }));
  }
}

export function* onFilterEntitiesWithLocation({
  payload,
}: ReturnType<typeof filterEntitiesWithLocation.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const availableCompanies: ListResponse<Company> = yield callApi("fetchCompanies", orgId, {
      $filters: {
        includeAccessStatus: true,
        $or: [
          {
            address: payload.query ? { $in: payload.query } : undefined,
            city: payload.query ? { $in: payload.query } : undefined,
            name: payload.query ? { $in: payload.query } : undefined,
            region: payload.query ? { $in: payload.query } : undefined,
            regionCode: payload.query ? { $in: payload.query } : undefined,
            postalCode: payload.query ? { $in: payload.query } : undefined,
          },
        ],
      },
      $limit: 100,
      $offset: 0,
      $order: payload.query ? "name" : "-updatedAt",
    });

    const availablePersons: ListResponse<Person> = yield callApi("fetchPeople", orgId, {
      $filters: {
        includeAccessStatus: true,
        $or: [
          {
            address: payload.query ? { $in: payload.query } : undefined,
            city: payload.query ? { $in: payload.query } : undefined,
            name: payload.query ? { $in: payload.query } : undefined,
            region: payload.query ? { $in: payload.query } : undefined,
            regionCode: payload.query ? { $in: payload.query } : undefined,
            postalCode: payload.query ? { $in: payload.query } : undefined,
          },
        ],
      },
      $limit: 100,
      $offset: 0,
      $order: payload.query ? "name" : "-updatedAt",
    });

    const result = [...(availableCompanies.data ?? []), ...(availablePersons.data ?? [])].sort(
      nameComparator
    );

    yield put(filterEntitiesWithLocation.success(result));
  } catch (error) {
    yield put(filterEntitiesWithLocation.failure());
    yield put(handleError({ error }));
  }
}

export function* entityAssociationsSaga() {
  yield takeLatest(filterCompanies.request, onFilterCompanies);
  yield takeLatest(filterDeals.request, onFilterDeals);
  yield takeLatest(filterPeople.request, onFilterPeople);
  yield takeLatest(filterEntitiesWithLocation.request, onFilterEntitiesWithLocation);
}

import { Descendant, Text } from "slate";
import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

const convertToTextWithMentions = (value: Descendant[]): string =>
  value
    .map((element) =>
      "text" in element
        ? element.text
        : element.children
            .map((child: Descendant) => {
              if ("text" in child) {
                return (child as Text).text;
              } else if (child.type === ElementType.MENTION) {
                return `@${child.email}`;
              } else {
                return "";
              }
            })
            .join("")
    )
    .join("\n");

export default convertToTextWithMentions;

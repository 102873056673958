import React from "react";
import styles from "./NoResults.module.scss";
import { useIntl } from "react-intl";

interface Props {}

const NoResults: React.FC<Props> = () => {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {intl.formatMessage({
          id: "component.globalSearch.noResults.title",
          defaultMessage: "No results",
          description: "No results title on global search item",
        })}
      </div>
      <div className={styles.subTitle}>
        {intl.formatMessage({
          id: "component.globalSearch.noResults.subTitle",
          defaultMessage: "Your search doesn’t have any results. Please try a different search.",
          description: "No results subTitle on global search item",
        })}
      </div>
    </div>
  );
};

export default NoResults;

import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { RegistryProps } from "component/FieldGrid/utils/ComponentRegistry";
import { EntityType, Person } from "@mapmycustomers/shared/types/entity";
import ListWithOverflowItem from "component/ListWithOverflowItem";
import styles from "./CompaniesField.module.scss";
import Chip from "component/Chip";
import EntityChip from "component/EntityChip";
import { showEntityView } from "store/entityView/actions";
import { connect } from "react-redux";

interface Props extends RegistryProps {
  onShowEntityView: typeof showEntityView;
}

const CompaniesField: React.FC<Props> = ({ entity, field, onShowEntityView }) => {
  const intl = useIntl();
  const companies = field.getValueFor(entity) as Person["accounts"];

  const handleOpenCompany = useCallback(
    ({ entityType, entityId }: { entityId: number; entityType: EntityType }) => {
      onShowEntityView({ entityType, entityId });
    },
    [onShowEntityView]
  );

  const companiesOverflowRenderer = useCallback(
    (count: number) => {
      return (
        <span>
          <Chip type="light">
            {intl.formatMessage(
              {
                id: "fieldGrid.field.companies.overflowRender",
                defaultMessage: "+ {count} more",
                description: "overflow Render for frequency interval",
              },
              { count }
            )}
          </Chip>
        </span>
      );
    },
    [intl]
  );

  return companies ? (
    <ListWithOverflowItem className={styles.container} overflowRender={companiesOverflowRenderer}>
      {companies.map((company) => (
        <EntityChip
          entityId={company.id}
          entityType={EntityType.COMPANY}
          key={company.id}
          name={company.name ?? ""}
          onClick={handleOpenCompany}
          tooltip={intl.formatMessage({
            defaultMessage: "Open company",
            description: "Open company tooltip",
            id: "fieldGrid.field.companies.tooltip",
          })}
        />
      ))}
    </ListWithOverflowItem>
  ) : null;
};

const mapStateToProps = {
  onShowEntityView: showEntityView,
};

export default connect(null, mapStateToProps)(CompaniesField);

import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";
import Path from "enum/Path";

const groupModeState = (state: RootState) => state.scene.map.groupMode;

export const getGroupEntityType = createSelector(groupModeState, ({ entityType }) => entityType);

export const getGroup = createSelector(groupModeState, ({ group }) => group);
export const getCandidateGroup = createSelector(
  groupModeState,
  ({ candidateGroup }) => candidateGroup
);
export const getGroupId = createSelector(getGroup, (group) => group?.id);

export const getGroupModeBasePath = createSelector(
  getGroupEntityType,
  getGroupId,
  (groupEntityType, groupId) => {
    return `${Path.MAP}/groups/${groupEntityType}/${groupId}`;
  }
);

export const getGroupMapViewState = createSelector(groupModeState, ({ viewState }) => viewState);

export const getGroupEntries = createSelector(
  groupModeState,
  ({ categorizedMapEntries }) => categorizedMapEntries
);

export const getGroupOtherEntries = createSelector(
  groupModeState,
  ({ categorizedOtherPins }) => categorizedOtherPins
);

export const getGroupRecords = createSelector(groupModeState, ({ records }) => records);
export const areGroupRecordsLoading = createSelector(
  groupModeState,
  ({ recordsLoading }) => recordsLoading
);

export const getGroupRecordsTotalCount = createSelector(
  groupModeState,
  ({ recordsCount }) => recordsCount
);

export const getGroupShowOtherPins = createSelector(
  groupModeState,
  ({ showOtherPins }) => showOtherPins
);

export const getGroupLassoRecords = createSelector(
  groupModeState,
  ({ lassoRecords }) => lassoRecords
);
export const areGroupLassoRecordsLoading = createSelector(
  groupModeState,
  ({ lassoRecordsLoading }) => lassoRecordsLoading
);

export const isUpdatingGroup = createSelector(groupModeState, ({ updateLoading }) => updateLoading);

export const doesGroupEditFormHaveChanges = createSelector(
  groupModeState,
  ({ editFormHasChanges }) => editFormHasChanges
);

export const isDataViewLoading = createSelector(
  groupModeState,
  ({ dataViewLoading }) => dataViewLoading
);

export const getDataViewEntities = createSelector(
  groupModeState,
  ({ dataViewEntities }) => dataViewEntities
);

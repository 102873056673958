import { defineMessages, IntlShape } from "react-intl";
import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from "date-fns/esm";

enum RecapRange {
  THIS_WEEK = "thisWeek",
  LAST_WEEK = "lastWeek",
  NEXT_WEEK = "nextWeek",
  THIS_MONTH = "thisMonth",
  LAST_MONTH = "lastMonth",
  NEXT_MONTH = "nextMonth",
  THIS_QUARTER = "thisQuarter",
  LAST_QUARTER = "lastQuarter",
  NEXT_QUARTER = "nextQuarter",
  THIS_YEAR = "thisYear",
  LAST_YEAR = "lastYear",
  ALL_TIME = "allTime",
}

const messages = defineMessages({
  [RecapRange.THIS_WEEK]: {
    id: "preview.activityList.range.thisWeek",
    defaultMessage: "This Week",
    description: "This Week option in a date range in Activities list in Preview pane",
  },
  [RecapRange.LAST_WEEK]: {
    id: "preview.activityList.range.lastWeek",
    defaultMessage: "Last Week",
    description: "Last Week option in a date range in Activities list in Preview pane",
  },
  [RecapRange.NEXT_WEEK]: {
    id: "preview.activityList.range.nextWeek",
    defaultMessage: "Next Week",
    description: "Next Week option in a date range in Activities list in Preview pane",
  },
  [RecapRange.THIS_MONTH]: {
    id: "preview.activityList.range.thisMonth",
    defaultMessage: "This Month",
    description: "This Month option in a date range in Activities list in Preview pane",
  },
  [RecapRange.LAST_MONTH]: {
    id: "preview.activityList.range.lastMonth",
    defaultMessage: "Last Month",
    description: "Last Month option in a date range in Activities list in Preview pane",
  },
  [RecapRange.NEXT_MONTH]: {
    id: "preview.activityList.range.nextMonth",
    defaultMessage: "Next Month",
    description: "Next Month option in a date range in Activities list in Preview pane",
  },
  [RecapRange.THIS_QUARTER]: {
    id: "preview.activityList.range.thisQuarter",
    defaultMessage: "This Quarter",
    description: "This Quarter option in a date range in Activities list in Preview pane",
  },
  [RecapRange.LAST_QUARTER]: {
    id: "preview.activityList.range.lastQuarter",
    defaultMessage: "Last Quarter",
    description: "Last Quarter option in a date range in Activities list in Preview pane",
  },
  [RecapRange.NEXT_QUARTER]: {
    id: "preview.activityList.range.nextQuarter",
    defaultMessage: "Next Quarter",
    description: "Next Quarter option in a date range in Activities list in Preview pane",
  },
  [RecapRange.THIS_YEAR]: {
    id: "preview.activityList.range.thisYear",
    defaultMessage: "This Year",
    description: "This Year option in a date range in Activities list in Preview pane",
  },
  [RecapRange.LAST_YEAR]: {
    id: "preview.activityList.range.lastYear",
    defaultMessage: "Last Year",
    description: "Last Year option in a date range in Activities list in Preview pane",
  },
  [RecapRange.ALL_TIME]: {
    id: "preview.activityList.range.allTime",
    defaultMessage: "All Time",
    description: "All Time option in a date range in Activities list in Preview pane",
  },
});

export const getRecapRangeDisplayName = (intl: IntlShape, recapRange: RecapRange) =>
  intl.formatMessage(messages[recapRange]);

const createInterval = (startDate: Date, endDateGenerator: (date: Date) => Date): Interval => ({
  start: startDate,
  end: endDateGenerator(startDate),
});

export const recapRangeIntervalMap: Readonly<Record<RecapRange, Interval | undefined>> = {
  [RecapRange.THIS_WEEK]: createInterval(startOfWeek(Date.now()), endOfWeek),
  [RecapRange.LAST_WEEK]: createInterval(subWeeks(startOfWeek(Date.now()), 1), endOfWeek),
  [RecapRange.NEXT_WEEK]: createInterval(subWeeks(startOfWeek(Date.now()), -1), endOfWeek),
  [RecapRange.THIS_MONTH]: createInterval(startOfMonth(Date.now()), endOfMonth),
  [RecapRange.LAST_MONTH]: createInterval(subMonths(startOfMonth(Date.now()), 1), endOfMonth),
  [RecapRange.NEXT_MONTH]: createInterval(subMonths(startOfMonth(Date.now()), -1), endOfMonth),
  [RecapRange.THIS_QUARTER]: createInterval(startOfQuarter(Date.now()), endOfQuarter),
  [RecapRange.LAST_QUARTER]: createInterval(
    subQuarters(startOfQuarter(Date.now()), 1),
    endOfQuarter
  ),
  [RecapRange.NEXT_QUARTER]: createInterval(
    subQuarters(startOfQuarter(Date.now()), -1),
    endOfQuarter
  ),
  [RecapRange.THIS_YEAR]: createInterval(startOfYear(Date.now()), endOfYear),
  [RecapRange.LAST_YEAR]: createInterval(subYears(startOfYear(Date.now()), 1), endOfYear),
  [RecapRange.ALL_TIME]: undefined,
};

export const recapRangeDateFormat = defineMessages({
  [RecapRange.THIS_WEEK]: {
    id: "preview.activityList.chart.dateFormat.thisWeek",
    defaultMessage: "LL/d",
    description: "Date format in Activities section on a Preview pane",
  },
  [RecapRange.LAST_WEEK]: {
    id: "preview.activityList.chart.dateFormat.lastWeek",
    defaultMessage: "LL/d",
    description: "Date format in Activities section on a Preview pane",
  },
  [RecapRange.NEXT_WEEK]: {
    id: "preview.activityList.chart.dateFormat.nextWeek",
    defaultMessage: "LL/d",
    description: "Date format in Activities section on a Preview pane",
  },
  [RecapRange.THIS_MONTH]: {
    id: "preview.activityList.chart.dateFormat.thisMonth",
    defaultMessage: "LL/d",
    description: "Date format in Activities section on a Preview pane",
  },
  [RecapRange.LAST_MONTH]: {
    id: "preview.activityList.chart.dateFormat.lastMonth",
    defaultMessage: "LL/d",
    description: "Date format in Activities section on a Preview pane",
  },
  [RecapRange.NEXT_MONTH]: {
    id: "preview.activityList.chart.dateFormat.nextMonth",
    defaultMessage: "LL/d",
    description: "Date format in Activities section on a Preview pane",
  },
  [RecapRange.THIS_QUARTER]: {
    id: "preview.activityList.chart.dateFormat.thisQuarter",
    defaultMessage: "LL/d",
    description: "Date format in Activities section on a Preview pane",
  },
  [RecapRange.LAST_QUARTER]: {
    id: "preview.activityList.chart.dateFormat.lastQuarter",
    defaultMessage: "LL/d",
    description: "Date format in Activities section on a Preview pane",
  },
  [RecapRange.NEXT_QUARTER]: {
    id: "preview.activityList.chart.dateFormat.nextQuarter",
    defaultMessage: "LL/d",
    description: "Date format in Activities section on a Preview pane",
  },
  [RecapRange.THIS_YEAR]: {
    id: "preview.activityList.chart.dateFormat.thisYear",
    defaultMessage: "LLL",
    description: "Date format in Activities section on a Preview pane",
  },
  [RecapRange.LAST_YEAR]: {
    id: "preview.activityList.chart.dateFormat.lastYear",
    defaultMessage: "LLL",
    description: "Date format in Activities section on a Preview pane",
  },
  [RecapRange.ALL_TIME]: {
    id: "preview.activityList.chart.dateFormat.allTime",
    defaultMessage: "LLL",
    description: "Date format in Activities section on a Preview pane",
  },
});

export default RecapRange;

import React from "react";
import { useIntl } from "react-intl";
import styles from "./FrequencyPreview.module.scss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import cn from "classnames";
import layout from "../../../styles/layout";
import Button from "antd/es/button";
import Activity from "@mapmycustomers/shared/types/entity/Activity";
import hasFrequency from "../util/hasFrequency";
import FrequencyEntity from "../type/FrequencyEntity";
import messages from "./messages";

interface Props {
  applyDisabled: boolean;
  entity?: FrequencyEntity;
  hasChanges: boolean;
  isGroup?: boolean;
  lastCompletedActivity?: Activity;
  loading?: boolean;
  onApply: () => void;
  onCancel?: () => void;
  onDelete: () => void;
  readonly?: boolean;
  saveButtonText?: string;
  showSaveButtonsOnlyAfterChanges?: boolean;
}

const Footer: React.FC<Props> = ({
  applyDisabled,
  entity,
  hasChanges,
  isGroup,
  loading,
  onApply,
  onCancel,
  onDelete,
  readonly,
  saveButtonText,
  showSaveButtonsOnlyAfterChanges,
}) => {
  const intl = useIntl();

  return (
    <>
      {!readonly && (
        <div className={styles.line}>
          {!hasChanges && !isGroup && entity && hasFrequency(entity) && (
            <div className={styles.removeButtonWrapper}>
              <Button disabled={loading} danger onClick={onDelete} type="text">
                {intl.formatMessage(messages.remove)}
              </Button>
            </div>
          )}
        </div>
      )}
      {!readonly && (
        <div className={cn(styles.line, styles.bottom)}>
          {(!showSaveButtonsOnlyAfterChanges || hasChanges) && (
            <Row className={styles.changesWrapper} gutter={layout.spacerS} justify="end">
              {isGroup && (
                <Col>
                  <Button danger disabled={loading} onClick={onDelete} size="small" type="text">
                    {intl.formatMessage(messages.delete)}
                  </Button>
                </Col>
              )}
              <Col>
                <Button disabled={loading} onClick={onCancel} size="small" type="default">
                  {intl.formatMessage(messages.cancel)}
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={applyDisabled}
                  loading={loading}
                  onClick={onApply}
                  size="small"
                  type="primary"
                >
                  {saveButtonText ?? intl.formatMessage(messages.setFrequency)}
                </Button>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
};

export default Footer;

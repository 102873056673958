import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import { EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";
import MarkerShape from "@mapmycustomers/shared/enum/MarkerShape";
import { isSingleShapePinLegend } from "util/pinLegend";

const getDefaultShapePinLegend = (
  pinLegends: PinLegend[],
  entityType: EntityTypesSupportedByMapsPage
): PinLegend | undefined =>
  pinLegends.find(
    (legend) =>
      isSingleShapePinLegend(legend) &&
      legend.entity === entityType &&
      legend.shapes[0].shape === MarkerShape.SQUARE
  );

export default getDefaultShapePinLegend;

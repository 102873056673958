import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import EntitySearch from "./EntitySearch";
import TextWithInfo from "component/typography/TextWithInfo";
import { RootState } from "store/rootReducer";
import { areCompaniesLoading, getFilteredCompanies } from "./store/selectors";
import { filterCompanies } from "./store/actions";
import { EntityType } from "@mapmycustomers/shared/enum";
import Deal from "@mapmycustomers/shared/types/entity/Deal";
import Person from "@mapmycustomers/shared/types/entity/Person";
import Company from "@mapmycustomers/shared/types/entity/Company";
import { fetchCompany } from "store/company/actions";
import ChangeAssociationsPayload from "store/associations/ChangeAssociationsPayload";

export interface CompanyEntitySearchProps<T = Company> {
  allowAdd?: boolean;
  companies?: T[];
  disabled?: boolean;
  hideLabel?: boolean;
  label?: React.ReactNode;
  loading?: boolean;
  onAddClick?: () => void;
  onChange?: (companyId?: Company["id"]) => void;
  onChangeAssociatedEntities: (
    payload: Pick<ChangeAssociationsPayload, "company" | "deal" | "person">
  ) => void;
  placeholder?: string;
  required?: boolean;
  selectedCompany?: T;
  selectedDeal?: Deal;
  selectedPerson?: Person;
}

interface Props<T = Company> extends CompanyEntitySearchProps<T> {
  filteredCompanies: T[];
  isFiltering: boolean;
  onFetchCompany: typeof fetchCompany;
  onFilterCompanies: typeof filterCompanies.request;
}

const CompanyEntitySearch: React.FC<Props> = ({
  allowAdd,
  companies,
  disabled,
  filteredCompanies,
  hideLabel,
  isFiltering,
  label,
  loading,
  onAddClick,
  onChange,
  onChangeAssociatedEntities,
  onFetchCompany,
  onFilterCompanies,
  placeholder,
  required,
  selectedCompany,
  selectedDeal,
  selectedPerson,
}) => {
  const intl = useIntl();

  const [query, setQuery] = useState("");
  const handleSearch = useCallback(
    (query: string) => {
      setQuery(query);
      onFilterCompanies(query);
    },
    [onFilterCompanies, setQuery]
  );

  const handleSelect = useCallback(
    (company: Company) => {
      onChange?.(company.id);
      onFetchCompany({
        id: company.id,
        callback: (company: Company) => {
          setQuery("");
          onChangeAssociatedEntities({
            company,
            deal: selectedDeal,
            person: selectedPerson,
          });
        },
        options: { includeUsersWithAccess: true },
      });
    },
    [onChange, onFetchCompany, selectedDeal, selectedPerson, onChangeAssociatedEntities]
  );

  const handleRemove = useCallback(() => {
    onChange?.(undefined);
    onChangeAssociatedEntities({
      company: undefined,
      deal: selectedDeal,
      person: selectedPerson,
    });
  }, [onChange, selectedPerson, selectedDeal, onChangeAssociatedEntities]);

  const primaryEntities = useMemo(() => {
    const searchText = query.toLowerCase().trim();
    if (searchText.length === 0) {
      return companies;
    }
    return companies?.filter((company) => company.name.toLowerCase().includes(searchText));
  }, [query, companies]);

  return (
    <EntitySearch<Company>
      allowAdd={allowAdd}
      disabled={disabled}
      entities={filteredCompanies}
      entityType={EntityType.COMPANY}
      label={
        hideLabel ? null : (
          <TextWithInfo
            info={
              disabled
                ? intl.formatMessage({
                    id: "entitySearch.company.disabledTooltip",
                    defaultMessage: "Company cannot be changed",
                    description: "Tooltip for Company Search field when it is disabled",
                  })
                : undefined
            }
          >
            {label ??
              intl.formatMessage({
                id: "entitySearch.company",
                defaultMessage: "Company",
                description: "Title of the Company Search field",
              })}
          </TextWithInfo>
        )
      }
      loading={loading || isFiltering}
      onAddClick={onAddClick}
      onRemove={handleRemove}
      onSearch={handleSearch}
      onSelect={handleSelect}
      placeholder={
        placeholder ??
        intl.formatMessage({
          id: "entitySearch.company.placeholder",
          defaultMessage: "Type to find company",
          description: "Placeholder of the Company Search field",
        })
      }
      primaryEntities={primaryEntities}
      required={required}
      selectedEntity={selectedCompany}
      value={query}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  filteredCompanies: getFilteredCompanies(state),
  isFiltering: areCompaniesLoading(state),
});

const mapDispatchToProps = {
  onFetchCompany: fetchCompany,
  onFilterCompanies: filterCompanies.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEntitySearch);

import { all, call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { getMapSettings, getMe, getOrganizationId, getPosition } from "store/iam";
import get from "lodash-es/get";
import {
  applyMapViewSettings,
  applyRecordsListSettings,
  applyTerritoryMapViewSettings,
  cleanLassoPaths,
  clearHighlight,
  clearRecordsList,
  clearSelection,
  enableEngagementFields,
  enterLassoMode,
  enterMode,
  exitLassoMode,
  exitMode,
  fetchGroupPins,
  fetchLassoSelection,
  fetchPins,
  fetchRecords,
  fetchTerritoryPins,
  initializeMapView,
  refreshLassoMode,
  resetRecordsListPagination,
  restartSelection,
  selectMapTool,
  setAlertsSettings,
  setHeatMapSettings,
  setLassoContainsFlags,
  setMapSettings,
  setPinHover,
  setSelection,
  setSelectionEntities,
  setBoundariesSettings,
  setUserLocationsSettings,
  showSidebar,
  updateLassoClusters,
  updateMapStyle,
  updatePinDropCoordinates,
  hideAnnotation,
} from "./actions";
import { handleError } from "store/errors/actions";
import {
  getAlertsSettings,
  getExcludedRecords,
  getHeatMapSettings,
  getIncludedRecords,
  getLassoMode,
  getLegendsState,
  getMapLassoPaths,
  getMapMode,
  getMapStyle,
  getMapViewState,
  getMapViewTool,
  getRecordsListState,
  getBoundariesSettings,
  getTrafficSettings,
  getUserLocationsSettings,
  getVisibleLassoEntityTypes,
  isSidebarVisible,
} from "scene/map/store/selectors";
import Organization from "@mapmycustomers/shared/types/Organization";
import localSettings from "config/LocalSettings";
import { callApi } from "store/api/callApi";
import { mapModeSagas } from "scene/map/store/mapMode/sagas";
import { groupModeSagas } from "scene/map/store/groupMode/sagas";
import { layersSagas } from "scene/map/store/layers/sagas";
import { leadFinderSagas } from "scene/map/store/leadFinder/sagas";
import { legendsSagas } from "scene/map/store/legends/sagas";
import { territoryModeSagas } from "scene/map/store/territoryMode/sagas";
import { pinLocationSagas } from "scene/map/store/pinLocation/sagas";
import BaseMapStyle from "@mapmycustomers/shared/enum/map/BaseMapStyle";
import MapTool from "@mapmycustomers/shared/enum/map/MapTool";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import MapPersistedViewportState from "types/map/MapPersistedViewportState";
import MapRecordsListState from "types/viewModel/MapRecordsListState";
import { EntityTypesSupportedByMapsPage, MapEntity } from "@mapmycustomers/shared/types/entity";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import Located, {
  GeocodeResult,
  GeoManagementState,
} from "@mapmycustomers/shared/types/base/Located";
import GeoPath from "types/GeoPath";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { MapRecordsResponse } from "types/map";
import LassoToolMode from "scene/map/enums/LassoToolMode";
import MapMode from "scene/map/enums/MapMode";
import { mapEntityIdGetter } from "util/map/idGetters";
import { mapEntityIdParser } from "util/map/idParsers";
import convertMapFilterToPlatformFilterModel from "util/viewModel/convertMapFilterToPlatformFilterModel";
import { convertToPlatformSortModel } from "util/viewModel/convertSort";
import {
  convertCoordinatesToGeoPoint,
  convertPlatformBoundsToLatLngBounds,
} from "util/geo/GeoService";
import PersistentMapSettings from "@mapmycustomers/shared/types/persistent/PersistentMapSettings";
import { updateMetadata } from "store/iam/actions";
import Iam from "types/Iam";
import PersistentBoundariesLayerData from "@mapmycustomers/shared/types/persistent/PersistentBoundariesLayerData";
import PersistentBaseMapLayerData from "@mapmycustomers/shared/types/persistent/PersistentBaseMapLayerData";
import { setLegendsState } from "./legends/actions";
import { isActionOf } from "typesafe-actions";
import { setTrafficSettings } from "./layers/traffic/actions";
import { hideEntityView } from "store/entityView/actions";
import HeatMapLayerData from "./layers/heatMap/HeatMapLayerData";
import { updateRecordsPreviewConfiguration } from "store/recordPreview/actions";
import { getRecordPreviewConfiguration } from "store/recordPreview/selectors";
import notification from "antd/es/notification";
import i18nService from "config/I18nService";
import RecordPreviewConfiguration from "store/recordPreview/RecordPreviewConfiguration";
import { defineMessages } from "react-intl";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { openOnMapPage } from "store/map/actions";
import { push } from "connected-react-router";
import Path from "enum/Path";
import { omit } from "lodash-es";
import { selectSavedFilter } from "store/savedFilters/actions";
import { getMapViewSettings } from "store/map";
import isValidMapEntry from "util/map/isValidMapEntry";
import { getMapRecordsListSettings } from "store/map/selectors";

const messages = defineMessages({
  title: {
    id: "map.layer.engagement.turnedOn.success.enabledNotification",
    defaultMessage: "Engagement information enabled",
    description: "Engagement Turn On Success notification",
  },
});

export function* onInitializeMapView({
  payload: callback,
}: ReturnType<typeof initializeMapView.request>) {
  let viewState: Partial<MapViewState> = yield select(getMapViewSettings);

  yield put(applyMapViewSettings(viewState));

  const recordsListState: MapRecordsListState = yield select(getMapRecordsListSettings);
  if (recordsListState) {
    yield put(applyRecordsListSettings(recordsListState));
  }

  // initialize settings

  const mapSettings: Partial<PersistentMapSettings> | undefined = yield select(getMapSettings);
  yield put(setMapSettings(mapSettings));

  const legendsState: unknown | undefined = yield select(getLegendsState);
  yield put(setLegendsState(legendsState));

  const heatMapSettings: HeatMapLayerData | undefined = yield select(getHeatMapSettings);
  yield put(setHeatMapSettings(heatMapSettings));

  const boundariesSettings: PersistentBoundariesLayerData | undefined = yield select(
    getBoundariesSettings
  );
  yield put(setBoundariesSettings(boundariesSettings));

  const userLocationsSettings: PersistentBaseMapLayerData | undefined = yield select(
    getUserLocationsSettings
  );
  yield put(setUserLocationsSettings(userLocationsSettings));

  const alertsSettings: PersistentBaseMapLayerData | undefined = yield select(getAlertsSettings);
  yield put(setAlertsSettings(alertsSettings));

  const trafficSettings: PersistentBaseMapLayerData | undefined = yield select(getTrafficSettings);
  yield put(setTrafficSettings(trafficSettings));

  const viewportState: MapPersistedViewportState = yield call(localSettings.getViewportState);
  if (viewportState.center || viewportState.zoom) {
    callback(viewportState);
    yield put(initializeMapView.success());
    return;
  }

  const position: GeolocationPosition | undefined = yield select(getPosition);
  if (position) {
    const bounds = new google.maps.LatLngBounds();
    bounds.extend({ lng: position.coords.longitude, lat: position.coords.latitude });
    const center = bounds.getCenter();
    callback({ center: [center.lng(), center.lat()], zoom: 15 });
    yield put(initializeMapView.success());
    return;
  }
  callback({ center: [0, 0], zoom: 2 });

  yield put(initializeMapView.success());
}

export function* onPersistMapSettings() {
  const style: BaseMapStyle = yield select(getMapStyle);
  const me: Iam | undefined = yield select(getMe);
  const mapSettings: Partial<PersistentMapSettings> | undefined = me?.metaData?.mapSettings;
  yield put(updateMetadata.request({ mapSettings: { ...mapSettings, style } }));
}

export function* onPinDropGeocode({
  payload,
}: ReturnType<typeof updatePinDropCoordinates.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    if (payload) {
      const geoPoint = convertCoordinatesToGeoPoint(payload);
      const result: GeocodeResult = yield callApi("reverseGeocodeAddress", orgId, geoPoint);

      const geocodedLocation: Located = {
        ...result.address,
        geoAddress: result.address,
        geoPoint,
        geoSource: null,
        geoCodeType: null,
        geoStatus: null,
        geoCodedAt: null,
        geoManagementState: GeoManagementState.MANUAL,
      };

      yield put(updatePinDropCoordinates.success(geocodedLocation));
    } else {
      yield put(updatePinDropCoordinates.success(undefined));
    }
  } catch (error) {
    yield put(updatePinDropCoordinates.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onEnterLassoMode() {
  try {
    yield put(restartSelection());
    yield put(clearRecordsList());
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onExitLassoMode() {
  try {
    // Clear selected map tool
    yield put(selectMapTool(undefined));

    yield put(cleanLassoPaths());

    const mode: MapMode = yield select(getMapMode);
    if (mode !== MapMode.LEAD_FINDER) {
      yield put(restartSelection());
    }
    yield put(refreshLassoMode());
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onRefreshLassoMode() {
  try {
    const mode: MapMode = yield select(getMapMode);
    switch (mode) {
      case MapMode.GROUPS:
        yield put(fetchGroupPins.request({ request: {} }));
        break;

      case MapMode.TERRITORIES:
        yield put(fetchTerritoryPins.request({ request: {} }));
        break;

      default:
        yield put(fetchPins.request({ request: {} }));
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onUpdateLassoClusters({ payload }: ReturnType<typeof updateLassoClusters>) {
  try {
    const activeTool: MapTool | undefined = yield select(getMapViewTool);
    const isLassoMode = activeTool === MapTool.LASSO;

    let containsClusters = false;
    if (isLassoMode && payload && payload.length > 0) {
      const paths: Array<GeoPath> = yield select(getMapLassoPaths);

      if (paths.length > 0) {
        const area = new google.maps.Polygon({ paths });
        const bounds = new google.maps.LatLngBounds();

        if (area) {
          area
            .getPath()
            .forEach((item) => bounds.extend(new google.maps.LatLng(item.lat(), item.lng())));

          const matches = payload
            .filter((cluster) => isValidMapEntry(cluster) && cluster.bounds)
            .filter((cluster) => {
              const clusterBounds = convertPlatformBoundsToLatLngBounds(cluster.bounds);

              return (
                bounds.intersects(clusterBounds) ||
                google.maps.geometry.poly.containsLocation(
                  // we just ensured above that cluster.region is defined
                  { lat: cluster.region!.latitude, lng: cluster.region!.longitude },
                  area
                )
              );
            });

          containsClusters = matches.length > 0;
        }
      }
    }
    yield put(setLassoContainsFlags({ containsClusters }));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onFetchLassoSelection({
  payload,
}: ReturnType<typeof fetchLassoSelection.request>) {
  try {
    yield put(
      showSidebar({
        skipLoading: true,
      })
    );

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const mapViewState: MapViewState = yield select(getMapViewState);
    const recordsListState: MapViewState = yield select(getRecordsListState);

    const sidebarVisible: boolean = yield select(isSidebarVisible);
    const activeTool: MapTool | undefined = yield select(getMapViewTool);
    const lassoPaths: Array<GeoPath> = yield select(getMapLassoPaths);

    const lassoVisibleEntities: EntityTypesSupportedByMapsPage[] = yield select(
      getVisibleLassoEntityTypes
    );
    const lassoMode: LassoToolMode = yield select(getLassoMode);
    const isLassoMode = activeTool === MapTool.LASSO;

    const visibleEntityTypes =
      isLassoMode && lassoMode === LassoToolMode.ADD_GROUP_PINS
        ? lassoVisibleEntities
        : mapViewState.visibleEntities;

    const mapFilters = convertMapFilterToPlatformFilterModel(
      mapViewState.filter,
      visibleEntityTypes,
      mapViewState.viewAs
    );

    const hasLasso = isLassoMode && lassoPaths.length;

    if (hasLasso) {
      const multipolygon: LongLat[][] = payload.request.lasso
        ? payload.request.lasso
        : lassoPaths.map((path) =>
            path.map((item: google.maps.LatLng): LongLat => [item.lng(), item.lat()])
          );

      const requestPayload = {
        $columns: ["id"],
        $offset: 0,
        $limit: 10000,
        $filters: {
          ...mapFilters,
          includeAccessStatus: true,
          multipolygon,
        },
        $order: recordsListState.sort
          ? convertToPlatformSortModel(recordsListState.sort)
          : undefined,
      };

      const response: MapRecordsResponse = yield callApi("fetchMapPins", orgId, requestPayload);
      const responseItems = response.data ?? [];

      const excludedRecords: Set<string> = yield select(getExcludedRecords);
      const includedRecords: Set<string> = yield select(getIncludedRecords);

      const itemsWithoutExcluded = new Set(
        responseItems
          .map((entity) => mapEntityIdGetter(entity))
          .filter((value) => !excludedRecords.has(value))
      );
      const itemsWithIncluded = new Set([
        ...Array.from(itemsWithoutExcluded),
        ...Array.from(includedRecords),
      ]);
      yield put(setSelection(itemsWithIncluded));

      const selectionEntities = responseItems.filter(
        (entity) => !excludedRecords.has(mapEntityIdGetter(entity))
      );

      if (includedRecords.size) {
        // Inject filtering by manually added entity IDs
        if (mapFilters.entities && !Array.isArray(mapFilters.entities)) {
          const entityIdFilter: Array<MapEntity["id"]> = [];
          for (const mapEntityId of includedRecords.values()) {
            const parsed = mapEntityIdParser(mapEntityId);
            if (parsed) {
              entityIdFilter.push(parsed.id);
            }
          }

          const entities = mapFilters.entities as Record<
            EntityTypesSupportedByMapsPage,
            PlatformFilterModel
          >;
          Object.keys(entities).forEach((key) => {
            const entityKey = key as EntityTypesSupportedByMapsPage;
            const andCondition = get(mapFilters.entities, [entityKey, "$and"]);
            if (Array.isArray(andCondition) && Array.isArray(entityIdFilter)) {
              andCondition.push({
                id: {
                  $in: entityIdFilter,
                },
              });
            }
          });
        }

        const requestPayload = {
          $offset: 0,
          $limit: 10000,
          $filters: mapFilters,
        };

        const response: MapRecordsResponse = yield callApi("fetchMapPins", orgId, requestPayload);
        yield put(setSelectionEntities([...selectionEntities, ...(response.data ?? [])]));
      } else {
        yield put(setSelectionEntities(selectionEntities));
      }

      if (itemsWithIncluded.size && !sidebarVisible) {
        yield put(clearRecordsList());
      }
    } else {
      yield put(clearSelection());
    }

    if (sidebarVisible) {
      yield put(resetRecordsListPagination());
      yield put(fetchRecords.request({ request: {} }));
    }

    yield put(fetchLassoSelection.success());
  } catch (error) {
    yield put(fetchLassoSelection.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onEnableEngagementFields() {
  try {
    const intl = i18nService.getIntl();
    const configuration: RecordPreviewConfiguration = yield select(getRecordPreviewConfiguration);
    const updatedConfiguration = {
      ...configuration,
      private: {
        ...configuration.private,
        engagement: {
          accounts: true,
          contacts: true,
          deals: true,
        },
      },
    };

    yield put(updateRecordsPreviewConfiguration.request(updatedConfiguration));
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.title),
      });
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

// This is called when you switch between map modes.
// Because we don't have design limitation regarding when and how user can trigger
// mode change - we need to have proper cleanup upon both enter and exit events.
export function* onSwitchMode(action: ReturnType<typeof enterMode | typeof exitMode>) {
  try {
    // when entering territory mode, copy color/shapeKey from the map mode
    if (isActionOf(enterMode, action) && action.payload === MapMode.TERRITORIES) {
      const viewState: MapViewState = yield select(getMapViewState);
      yield put(
        applyTerritoryMapViewSettings({
          colorKey: viewState.colorKey,
          shapeKey: viewState.shapeKey,
        })
      );
    }

    // Clear pin hover
    yield put(setPinHover(undefined));
    // Clear annotation
    yield put(hideAnnotation());
    // Clear currently viewed entity
    yield put(hideEntityView());
    // Clear selected map tool
    yield put(selectMapTool(undefined));

    // Clear all records in sidebar, since they're very likely to be changed
    yield put(clearRecordsList());

    // Cleanup of lasso
    yield put(cleanLassoPaths());

    // Cleanup of selected and highlighted items
    yield put(clearHighlight());
    yield put(clearSelection());
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onHideEntityView() {
  yield put(setPinHover(undefined));
}

export function* onOpenOnMapPage({ payload }: ReturnType<typeof openOnMapPage>) {
  const mapViewState: MapViewState = yield select(getMapViewState);
  const updatedMapViewState = {
    ...mapViewState,
    ...payload.mapViewState,
  };

  // TODO: consider updating MapFilterButton to read savedFilter from map's viewState instead of savedFilters store
  // reset saved filters we had there before
  yield put(selectSavedFilter({ entityType: EntityType.PIN, savedFilter: undefined }));
  yield put(
    updateMetadata.request({
      mapViewSettings: omit(updatedMapViewState, "lasso"),
    })
  );
  yield take([updateMetadata.success, updateMetadata.failure]);

  if (payload.viewport) {
    yield call(localSettings.updateViewportState, payload.viewport);
  }

  if (payload.openOnNewTab) {
    const url = `${window.location.origin}${Path.MAP}`;
    yield call(window.open, url, "_blank");
  } else {
    yield put(push(Path.MAP));
  }
}

export function* defaultSagas() {
  yield takeLatest(initializeMapView.request, onInitializeMapView);
  yield takeLatest(updateMapStyle, onPersistMapSettings);
  yield takeLatest(updatePinDropCoordinates.request, onPinDropGeocode);
  yield takeLatest(fetchLassoSelection.request, onFetchLassoSelection);
  yield takeLatest(enableEngagementFields.request, onEnableEngagementFields);
  yield takeLatest(enterMode, onSwitchMode);
  yield takeLatest(exitMode, onSwitchMode);
  yield takeLatest(hideEntityView, onHideEntityView);
  yield takeLatest(updateLassoClusters, onUpdateLassoClusters);
  yield takeLatest(enterLassoMode, onEnterLassoMode);
  yield takeLatest(exitLassoMode, onExitLassoMode);
  yield takeLatest(openOnMapPage, onOpenOnMapPage);
  yield takeLatest(refreshLassoMode, onRefreshLassoMode);
}

export function* mapSaga() {
  yield all([
    fork(defaultSagas),
    fork(mapModeSagas),
    fork(groupModeSagas),
    fork(layersSagas),
    fork(leadFinderSagas),
    fork(legendsSagas),
    fork(pinLocationSagas),
    fork(territoryModeSagas),
  ]);
}

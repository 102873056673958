import React from "react";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import Section from "component/activity/ActivityAnnotation/Section";
import { Activity, EntityType } from "@mapmycustomers/shared/types/entity";
import EntityLink from "./EntityLink";

interface Props {
  activity: Activity;
}

const RelationShipsSection: React.FC<Props> = ({ activity }) => {
  if (!activity.account && !activity.contact && !activity.deal) {
    return null;
  }

  return (
    <Section icon={faLink} title={"Relationships"}>
      <EntityLink entityType={EntityType.COMPANY} entity={activity.account} />
      <EntityLink entityType={EntityType.PERSON} entity={activity.contact} />
      <EntityLink entityType={EntityType.DEAL} entity={activity.deal} />
    </Section>
  );
};

export default RelationShipsSection;

import React, { ReactNode } from "react";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import { FormattedMessage, useIntl } from "react-intl";
import { userExists } from "store/auth/actions";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";
import {
  usePhoneValidationRules,
  useRequiredValidation,
  useUsernameValidationRules,
} from "../../../utils/validation-hooks";
import { doesUserExist, isUserExistsLoading } from "store/auth";
import BaseStepProps from "../../../types/BaseStepProps";
import cn from "classnames";
import SignupField from "../../../enum/SignupField";
import commonStyles from "../../../../AuthCommon.module.scss";
import styles from "../../Steps.module.scss";
import { Link } from "react-router-dom";
import Path from "enum/Path";
import useEmailValidationStatus from "./useEmailValidationStatus";

const emailRegex = /\S+@\S+\.\S+/;

interface Props extends BaseStepProps {
  checkingIfUserExists: boolean;
  email?: string;
  doesUserExist: boolean;
  title: ReactNode;
  userExists: typeof userExists.request;
}

const ContactInfoStep: React.FC<Props> = ({
  checkingIfUserExists,
  className,
  doesUserExist,
  email,
  form,
  isValid,
  onNextClick,
  title,
  userExists,
}) => {
  const intl = useIntl();

  const checkExistingUser = useDebouncedCallback(
    [
      (e) => {
        const value = e.target.value;
        if (emailRegex.test(value)) {
          userExists({ username: value });
        }
      },
      500,
    ],
    [userExists]
  );

  const usernameValidationRules = useUsernameValidationRules(
    intl,
    doesUserExist,
    checkingIfUserExists
  );
  const requiredValidation = useRequiredValidation(intl);
  const phoneValidationRules = usePhoneValidationRules(intl);

  const emailValidationResult = useEmailValidationStatus(form, doesUserExist, checkingIfUserExists);

  return (
    <div className={cn(styles.stepContainer, className)}>
      <p className={commonStyles.description}>{title}</p>
      <Form.Item
        colon={false}
        hasFeedback
        label={intl.formatMessage({
          id: "auth.register.contactInfoStep.fullname",
          defaultMessage: "Full Name",
          description: "Title of the full name field on the register form",
        })}
        name={SignupField.FULL_NAME}
        requiredMark="optional"
        rules={requiredValidation}
      >
        <Input autoComplete="name" size="large" />
      </Form.Item>
      <Form.Item
        colon={false}
        hasFeedback
        label={intl.formatMessage({
          id: "auth.register.contactInfoStep.email",
          defaultMessage: "Email",
          description: "Title of the Email field on the register form",
        })}
        name={SignupField.EMAIL}
        requiredMark="optional"
        rules={usernameValidationRules}
        validateStatus={emailValidationResult}
      >
        <Input
          autoComplete="username"
          disabled={!!email}
          onChange={checkExistingUser}
          size="large"
        />
      </Form.Item>
      <Form.Item
        colon={false}
        hasFeedback
        label={intl.formatMessage({
          id: "auth.register.contactInfoStep.phone",
          defaultMessage: "Phone",
          description: "Title of the Phone number field on the register form",
        })}
        name={SignupField.PHONE}
        requiredMark="optional"
        rules={phoneValidationRules}
      >
        <Input autoComplete="tel" name="phone" size="large" type="tel" />
      </Form.Item>
      <Form.Item>
        <div className={styles.buttons}>
          <Button disabled={!isValid} htmlType="button" onClick={onNextClick} type="primary">
            <FormattedMessage
              id="auth.register.nextStepButton"
              defaultMessage="Next Step"
              description="Title of the Next Step button for the Register Form"
            />
          </Button>
        </div>
      </Form.Item>
      <div className={styles.backToLoginButton}>
        <FormattedMessage
          id="auth.register.backToLogin"
          defaultMessage="Already have an account? <link>Log in</link>"
          description="Title of the Back to Login Link"
          values={{
            link: (linkText: string) => <Link to={Path.LOGIN}>{linkText}</Link>,
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  checkingIfUserExists: isUserExistsLoading(state),
  doesUserExist: doesUserExist(state),
});

const mapDispatchToProps = {
  userExists: userExists.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoStep);

import React from "react";
import { EntityTypesSupportedByActivities } from "@mapmycustomers/shared/types/entity";
import Company from "@mapmycustomers/shared/types/entity/Company";
import Deal from "@mapmycustomers/shared/types/entity/Deal";
import Person from "@mapmycustomers/shared/types/entity/Person";
import AssociationResultOption from "./AssociationResultOption";
import styles from "./getSearchOptions.module.scss";

const getSearchOption = <T extends Company | Deal | Person>(
  entity: T,
  entityType: EntityTypesSupportedByActivities,
  className?: string
) => ({
  className,
  label: <AssociationResultOption entity={entity} entityType={entityType} />,
  value: `${entity.id}`,
});

const getSearchOptions = <T extends Company | Deal | Person>(
  primaryEntities: T[] | undefined,
  entities: T[] | undefined,
  entityType: EntityTypesSupportedByActivities
) => {
  const result: { label: React.ReactNode; value: string }[] = [];
  primaryEntities?.forEach((entity, index: number) => {
    result.push(
      getSearchOption(
        entity,
        entityType,
        index === primaryEntities?.length - 1 && (entities?.length ?? 0) > 0
          ? styles.lastPrimaryOption
          : undefined
      )
    );
  });
  const primaryEntitiesIdsSet = new Set(primaryEntities?.map((entity) => entity.id));
  entities
    ?.filter(({ id }) => !primaryEntitiesIdsSet.has(id))
    .forEach((entity) => {
      result.push(getSearchOption(entity, entityType));
    });
  return result;
};

export default getSearchOptions;

import { LabeledValue } from "antd/es/select";
import { useIntl } from "react-intl";
import { useMemo } from "react";
import IntervalUnit, { getIntervalUnitDisplayName } from "enum/IntervalUnit";

const useIntervalUnitOptions = (count: number): LabeledValue[] => {
  const intl = useIntl();

  return useMemo<LabeledValue[]>(() => {
    return Object.values<IntervalUnit>(IntervalUnit)
      .filter((unit) => unit !== IntervalUnit.HOUR && unit !== IntervalUnit.MINUTE)
      .map<LabeledValue>((value) => ({
        key: value,
        label: getIntervalUnitDisplayName(intl, value, count),
        value,
      }));
  }, [intl, count]);
};

export default useIntervalUnitOptions;

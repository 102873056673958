import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "auth.ssoLogin.form.title",
    defaultMessage: "Single Sign On",
    description: "Single Sign On - title",
  },
  description: {
    id: "auth.ssoLogin.form.description",
    defaultMessage:
      "SSO allows you to sign into Map My Customers using your organization’s identity provider. If you don’t know whether or not your organization is using SSO, please reach out to your admin.",
    description: "Description of the sso login card",
  },
  goBack: {
    id: "auth.ssoLogin.form.goBack",
    defaultMessage: "Go Back",
    description: "Title of the Back to Login Link",
  },
  submit: {
    id: "auth.ssoLogin.form.submit",
    defaultMessage: "Single Sign On",
    description: "Title of the sso login form button",
  },
  loading: {
    id: "auth.ssoLogin.form.submit.loading",
    defaultMessage: "Signing in...",
    description: "Title of the submit login form button after it has been pressed",
  },
  required: {
    id: "auth.ssoLogin.form.email.required",
    defaultMessage: "Please input your email",
    description: "Field is email error message for the username field",
  },
  placeholder: {
    id: "auth.ssoLogin.form.email.placeholder",
    defaultMessage: "Your work email",
    description: "Placeholder for the username field",
  },
  request: {
    id: "auth.ssoLogin.form.email.request",
    defaultMessage: "Request account",
    description: "Button text for request account",
  },
  requestSent: {
    id: "auth.ssoLogin.form.email.requestSent",
    defaultMessage: "Request sent",
    description: "Button text for request account",
  },
});

export default messages;

import compact from "lodash-es/compact";
import { createReducer } from "typesafe-actions";
import {
  Actions,
  addCompanyChildCompanies,
  addCompanyDeals,
  addCompanyParentCompany,
  addCompanyPeople,
  changeActivitiesAssignees,
  changeActivitiesFilter,
  changeActivitiesOrder,
  changeActivitiesRecapRange,
  changeActivitiesSearchQuery,
  changeActivitiesSelectedActivityTypes,
  clearAllUploadedCompanyFiles,
  createCompanyNote,
  deleteCompany,
  deleteCompanyFile,
  deleteCompanyNote,
  fetchCompany,
  fetchCompanyActivities,
  fetchCompanyActivitiesCompletedByType,
  fetchCompanyActivitiesTotal,
  fetchCompanyRoutes,
  fetchMoreDeals,
  fetchMorePeople,
  fetchPreviewData,
  postponeActivity,
  removeCompanyChildCompany,
  removeCompanyDeal,
  removeCompanyFromRoute,
  removeCompanyParentCompany,
  removeCompanyPerson,
  setActivityAutoScrolling,
  setHasChangesFlag,
  toggleCompleteActivity,
  updateActivityNote,
  updateCompany,
  updateCompanyFrequency,
  updateCompanyNote,
  uploadCompanyFiles,
} from "./actions";
import CompanyRecordData from "./CompanyRecordData";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { Activity, Route } from "@mapmycustomers/shared/types/entity";
import Note from "@mapmycustomers/shared/types/entity/common/Note";
import FileListItem from "types/FileListItem";
import { RawFile } from "@mapmycustomers/shared/types/File";
import localSettings from "config/LocalSettings";
import RecapRange from "enum/preview/RecapRange";
import User from "@mapmycustomers/shared/types/User";
import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import activityFieldModel from "util/fieldModel/ActivityFieldModel";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import SortModel from "@mapmycustomers/shared/types/viewModel/internalModel/SortModel";
import { fileCreatedAtDescComparator } from "component/preview/util/comparators";

const INITIAL_RECORD_DATA: Readonly<CompanyRecordData> = {
  activities: [],
  activitiesCompletedByType: [],
  activitiesTotal: 0,
  childCompanies: [],
  company: undefined,
  deals: [],
  files: [],
  notes: [],
  parentCompany: undefined,
  people: [],
  routes: [],
  uncompletedActivities: [],
  totalDeals: 0,
  totalPeople: 0,
};

export interface CompanyRecordState {
  activitiesCompletedByTypeLoading: boolean;
  activitiesOrder: SortModel;
  activitiesFilter: ActivityStatusOption;
  activitiesLoading: boolean;
  activitiesRecapRange: RecapRange;
  activitiesSearchQuery: string;
  activitiesSelectedActivityTypes: ActivityType[];
  activitiesSelectedAssignees: User["id"][];
  activitiesTotalLoading: boolean;
  activityAutoScrolling?: boolean;
  addCompanyChildCompaniesLoading: boolean;
  addCompanyDealsLoading: boolean;
  addCompanyParentCompanyLoading: boolean;
  addCompanyPersonsLoading: boolean;
  companyLoading: boolean;
  companyNoteCreateLoading: boolean;
  companyNoteDeleteLoadingIds: Note["id"][];
  companyNoteUpdateLoadingIds: Note["id"][];
  companyRoutesLoading: boolean;
  companyUpdateLoading: boolean;
  deleteLoading?: boolean;
  filesAdded: FileListItem[];
  filesToDeletedIds: RawFile["id"][];
  filesUploading: boolean;
  hasChanges: boolean;
  loading: boolean;
  postponeActivityLoadingIds: Activity["id"][];
  recordData: CompanyRecordData;
  removeCompanyRouteLoadingsIds: Route["id"][];
  toggleCompleteActivityLoadingIds: Activity["id"][];
  updateActivityNoteLoadingIds: Activity["id"][];
}

const initialState: CompanyRecordState = {
  activitiesCompletedByTypeLoading: false,
  activitiesFilter: ActivityStatusOption.ALL,
  activitiesOrder: [{ field: activityFieldModel.getByName("createdAt")!, order: SortOrder.DESC }],
  activitiesLoading: false,
  activitiesRecapRange: localSettings.getRecapChartRange() ?? RecapRange.THIS_YEAR,
  activitiesSearchQuery: "",
  activitiesSelectedActivityTypes: [],
  activitiesSelectedAssignees: [],
  activitiesTotalLoading: false,
  addCompanyChildCompaniesLoading: false,
  addCompanyDealsLoading: false,
  addCompanyParentCompanyLoading: false,
  addCompanyPersonsLoading: false,
  companyLoading: false,
  companyNoteCreateLoading: false,
  companyNoteDeleteLoadingIds: [],
  companyNoteUpdateLoadingIds: [],
  companyRoutesLoading: false,
  companyUpdateLoading: false,
  filesAdded: [],
  filesToDeletedIds: [],
  filesUploading: false,
  hasChanges: false,
  loading: false,
  postponeActivityLoadingIds: [],
  recordData: INITIAL_RECORD_DATA,
  removeCompanyRouteLoadingsIds: [],
  toggleCompleteActivityLoadingIds: [],
  updateActivityNoteLoadingIds: [],
};

const updateRecordData = (state: CompanyRecordState, payload: Partial<Activity>) => {
  const updateActivities = (activities: Activity[]) =>
    activities.map((item) => {
      if (payload.id === item.id) {
        return {
          ...item,
          ...payload,
        };
      }
      return item;
    });

  return {
    ...state.recordData,
    activities: updateActivities(state.recordData.activities),
    activitiesCompletedByType: updateActivities(state.recordData.activitiesCompletedByType),
  };
};

const companyRecord = createReducer<CompanyRecordState, Actions>(initialState)
  .handleAction(fetchPreviewData.request, (state) => ({
    ...state,
    hasChanges: false,
    loading: true,
    recordData: INITIAL_RECORD_DATA,
  }))
  .handleAction(fetchPreviewData.success, (state, action) => ({
    ...state,
    activitiesSelectedActivityTypes: action.payload.selectedActivityTypes,
    hasChanges: false,
    loading: false,
    recordData: action.payload.recordData,
  }))
  .handleAction(fetchPreviewData.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(fetchCompany.request, (state) => ({
    ...state,
    companyLoading: true,
  }))
  .handleAction(fetchCompany.success, (state, action) => ({
    ...state,
    companyLoading: false,
    recordData: {
      ...state.recordData,
      company: Object.assign({}, state.recordData.company, action.payload),
    },
  }))
  .handleAction(fetchCompany.failure, (state) => ({
    ...state,
    companyLoading: false,
  }))
  .handleAction(fetchCompanyActivities.request, (state) => ({
    ...state,
    activitiesLoading: true,
  }))
  .handleAction(fetchCompanyActivities.success, (state, action) => ({
    ...state,
    activitiesLoading: false,
    recordData: {
      ...state.recordData,
      activities: action.payload,
    },
  }))
  .handleAction(fetchCompanyActivities.failure, (state) => ({
    ...state,
    activitiesLoading: false,
  }))
  .handleAction(fetchCompanyActivitiesCompletedByType.request, (state) => ({
    ...state,
    activitiesCompletedByTypeLoading: true,
  }))
  .handleAction(fetchCompanyActivitiesCompletedByType.success, (state, action) => ({
    ...state,
    activitiesCompletedByTypeLoading: false,
    recordData: {
      ...state.recordData,
      activitiesCompletedByType: action.payload,
    },
  }))
  .handleAction(fetchCompanyActivitiesCompletedByType.failure, (state) => ({
    ...state,
    activitiesCompletedByTypeLoading: false,
  }))
  .handleAction(fetchCompanyActivitiesTotal.request, (state) => ({
    ...state,
    activitiesTotalLoading: true,
  }))
  .handleAction(fetchCompanyActivitiesTotal.success, (state, action) => ({
    ...state,
    activitiesTotalLoading: false,
    recordData: {
      ...state.recordData,
      activitiesTotal: action.payload,
    },
  }))
  .handleAction(fetchCompanyActivitiesTotal.failure, (state) => ({
    ...state,
    activitiesTotalLoading: false,
  }))
  .handleAction(fetchCompanyRoutes.request, (state) => ({
    ...state,
    companyRoutesLoading: true,
  }))
  .handleAction(fetchCompanyRoutes.success, (state, action) => ({
    ...state,
    companyRoutesLoading: false,
    recordData: {
      ...state.recordData,
      routes: action.payload,
    },
  }))
  .handleAction(fetchCompanyRoutes.failure, (state) => ({
    ...state,
    companyRoutesLoading: false,
  }))
  .handleAction(updateCompany.request, (state) => ({
    ...state,
    companyUpdateLoading: true,
  }))
  .handleAction(updateCompany.success, (state, action) => ({
    ...state,
    recordData: {
      ...state.recordData,
      company: { ...state.recordData.company, ...action.payload },
    },
    companyUpdateLoading: false,
  }))
  .handleAction(updateCompany.failure, (state) => ({
    ...state,
    companyUpdateLoading: false,
  }))
  .handleAction(createCompanyNote.request, (state) => ({
    ...state,
    companyNoteCreateLoading: true,
  }))
  .handleAction(createCompanyNote.success, (state, action) => ({
    ...state,
    companyNoteCreateLoading: false,
    recordData: { ...state.recordData, notes: [action.payload, ...state.recordData.notes] },
  }))
  .handleAction(createCompanyNote.failure, (state) => ({
    ...state,
    companyNoteCreateLoading: false,
  }))
  .handleAction(updateCompanyNote.request, (state, { payload }) => ({
    ...state,
    companyNoteUpdateLoadingIds: [...state.companyNoteUpdateLoadingIds, payload.id],
  }))
  .handleAction(updateCompanyNote.success, (state, action) => ({
    ...state,
    companyNoteUpdateLoadingIds: state.companyNoteUpdateLoadingIds.filter(
      (id) => id !== action.payload.id
    ),
    recordData: {
      ...state.recordData,
      notes: [
        action.payload,
        ...state.recordData.notes.filter((note) => note.id !== action.payload.id),
      ],
    },
  }))
  .handleAction(updateCompanyNote.failure, (state, action) => ({
    ...state,
    companyNoteUpdateLoadingIds: state.companyNoteUpdateLoadingIds.filter(
      (id) => id !== action.payload
    ),
  }))
  .handleAction(deleteCompanyNote.request, (state, { payload }) => ({
    ...state,
    companyNoteDeleteLoadingIds: [...state.companyNoteDeleteLoadingIds, payload.id],
  }))
  .handleAction(deleteCompanyNote.success, (state, action) => ({
    ...state,
    companyNoteDeleteLoadingIds: state.companyNoteDeleteLoadingIds.filter(
      (id) => id !== action.payload
    ),
    recordData: {
      ...state.recordData,
      notes: state.recordData.notes.filter((note) => note.id !== action.payload),
    },
  }))
  .handleAction(deleteCompanyNote.failure, (state, action) => ({
    ...state,
    companyNoteDeleteLoadingIds: state.companyNoteDeleteLoadingIds.filter(
      (id) => id !== action.payload
    ),
  }))
  .handleAction(uploadCompanyFiles.request, (state, action) => ({
    ...state,
    filesUploading: true,
  }))
  .handleAction(uploadCompanyFiles.success, (state, action) => {
    const filesUploaded = compact(action.payload.map(({ uploadedFile }) => uploadedFile));
    return {
      ...state,
      recordData: {
        ...state.recordData,
        files: [...filesUploaded, ...state.recordData.files].sort(fileCreatedAtDescComparator),
      },
      filesAdded: [...state.filesAdded, ...action.payload],
      filesUploading: false,
    };
  })
  .handleAction(uploadCompanyFiles.failure, (state) => ({
    ...state,
    filesUploading: false,
  }))
  .handleAction(deleteCompanyFile.request, (state, { payload }) => ({
    ...state,
    filesAdded: state.filesAdded.filter((file) => file.uploadedFile?.id !== payload.id),
    filesToDeletedIds: [...state.filesToDeletedIds, payload.id],
    recordData: {
      ...state.recordData,
      files: state.recordData.files.filter((file) => file.id !== payload.id),
    },
  }))
  .handleAction(deleteCompanyFile.success, (state, { payload }) => ({
    ...state,
    filesToDeletedIds: state.filesToDeletedIds.filter((id) => id !== payload.file.id),
    recordData: payload.removed
      ? state.recordData
      : {
          ...state.recordData,
          files: [...state.recordData.files, payload.file].sort(fileCreatedAtDescComparator),
        },
  }))
  .handleAction(deleteCompanyFile.failure, (state, { payload }) => ({
    ...state,
    filesToDeletedIds: state.filesToDeletedIds.filter((id) => id !== payload.id),
    recordData: {
      ...state.recordData,
      files: [...state.recordData.files, payload].sort(fileCreatedAtDescComparator),
    },
  }))
  .handleAction(clearAllUploadedCompanyFiles, (state) => ({
    ...state,
    filesAdded: [],
    filesUploading: false,
  }))
  .handleAction(addCompanyChildCompanies.request, (state) => ({
    ...state,
    addCompanyChildCompaniesLoading: true,
  }))
  .handleAction(addCompanyChildCompanies.success, (state, action) => ({
    ...state,
    addCompanyChildCompaniesLoading: false,
    recordData: {
      ...state.recordData,
      childCompanies: action.payload,
    },
  }))
  .handleAction(addCompanyChildCompanies.failure, (state) => ({
    ...state,
    addCompanyChildCompaniesLoading: false,
  }))
  .handleAction(removeCompanyChildCompany.success, (state, action) => ({
    ...state,
    recordData: {
      ...state.recordData,
      childCompanies: state.recordData.childCompanies?.filter((c) => c.id !== action.payload),
    },
  }))
  .handleAction(addCompanyParentCompany.request, (state, action) => ({
    ...state,
    addCompanyParentCompanyLoading: true,
  }))
  .handleAction(addCompanyParentCompany.success, (state, action) => ({
    ...state,
    recordData: {
      ...state.recordData,
      parentCompany: action.payload,
    },
    addCompanyParentCompanyLoading: false,
  }))
  .handleAction(addCompanyParentCompany.failure, (state, action) => ({
    ...state,
    addCompanyParentCompanyLoading: false,
  }))
  .handleAction(removeCompanyParentCompany.success, (state, action) => ({
    ...state,
    recordData: {
      ...state.recordData,
      parentCompany: undefined,
    },
  }))
  .handleAction(addCompanyPeople.request, (state) => ({
    ...state,
    addCompanyPersonsLoading: true,
  }))
  .handleAction(addCompanyPeople.success, (state, { payload }) => ({
    ...state,
    addCompanyPersonsLoading: false,
    recordData: {
      ...state.recordData,
      people: payload.people,
      totalPeople: payload.total,
    },
  }))
  .handleAction(addCompanyPeople.failure, (state) => ({
    ...state,
    addCompanyPersonsLoading: false,
  }))
  .handleAction(removeCompanyPerson.success, (state, { payload }) => ({
    ...state,
    recordData: {
      ...state.recordData,
      people: state.recordData.people?.filter(({ id }) => id !== payload),
      totalPeople: state.recordData.totalPeople > 0 ? state.recordData.totalPeople - 1 : 0,
    },
  }))
  .handleAction(addCompanyDeals.request, (state) => ({
    ...state,
    addCompanyDealsLoading: true,
  }))
  .handleAction(addCompanyDeals.success, (state, { payload }) => ({
    ...state,
    addCompanyDealsLoading: false,
    recordData: {
      ...state.recordData,
      deals: payload.deals,
      totalDeals: payload.total,
    },
  }))
  .handleAction(addCompanyDeals.failure, (state) => ({
    ...state,
    addCompanyDealsLoading: false,
  }))
  .handleAction(removeCompanyDeal.success, (state, action) => ({
    ...state,
    recordData: {
      ...state.recordData,
      deals: state.recordData.deals?.filter((c) => c.id !== action.payload),
      totalDeals: state.recordData.totalDeals > 0 ? state.recordData.totalDeals - 1 : 0,
    },
  }))
  .handleAction(removeCompanyFromRoute.request, (state, { payload }) => ({
    ...state,
    removeCompanyRouteLoadingsIds: [...state.removeCompanyRouteLoadingsIds, payload.routeId],
  }))
  .handleAction(removeCompanyFromRoute.success, (state, action) => ({
    ...state,
    recordData: {
      ...state.recordData,
      routes: state.recordData.routes?.filter((c) => c.id !== action.payload),
    },
    removeCompanyRouteLoadingsIds: state.removeCompanyRouteLoadingsIds.filter(
      (id) => id !== action.payload
    ),
  }))
  .handleAction(removeCompanyFromRoute.failure, (state, action) => ({
    ...state,
    removeCompanyRouteLoadingsIds: state.removeCompanyRouteLoadingsIds.filter(
      (id) => id !== action.payload
    ),
  }))
  .handleAction(postponeActivity.request, (state, { payload }) => ({
    ...state,
    postponeActivityLoadingIds: state.postponeActivityLoadingIds.concat(payload.id),
  }))
  .handleAction(postponeActivity.success, (state, { payload }) => ({
    ...state,
    recordData: updateRecordData(state, payload),
    postponeActivityLoadingIds: state.postponeActivityLoadingIds.filter(
      (loadingId) => loadingId !== payload.id
    ),
  }))
  .handleAction(postponeActivity.failure, (state, { payload }) => ({
    ...state,
    postponeActivityLoadingIds: state.postponeActivityLoadingIds.filter(
      (loadingId) => loadingId !== payload
    ),
  }))

  .handleAction(toggleCompleteActivity.request, (state, { payload }) => ({
    ...state,
    toggleCompleteActivityLoadingIds: state.toggleCompleteActivityLoadingIds.concat(payload.id),
  }))
  .handleAction(toggleCompleteActivity.success, (state, { payload }) => ({
    ...state,
    recordData: updateRecordData(state, payload),
    toggleCompleteActivityLoadingIds: state.toggleCompleteActivityLoadingIds.filter(
      (loadingId) => loadingId !== payload.id
    ),
  }))
  .handleAction(toggleCompleteActivity.failure, (state, { payload }) => ({
    ...state,
    toggleCompleteActivityLoadingIds: state.toggleCompleteActivityLoadingIds.filter(
      (loadingId) => loadingId !== payload
    ),
  }))
  .handleAction(updateActivityNote.request, (state, { payload }) => ({
    ...state,
    updateActivityNoteLoadingIds: state.updateActivityNoteLoadingIds.concat(payload.activity.id),
  }))
  .handleAction(updateActivityNote.success, (state, { payload }) => ({
    ...state,
    recordData: updateRecordData(state, payload),
    updateActivityNoteLoadingIds: state.updateActivityNoteLoadingIds.filter(
      (loadingId) => loadingId !== payload.id
    ),
  }))
  .handleAction(updateActivityNote.failure, (state, { payload }) => ({
    ...state,
    updateActivityNoteLoadingIds: state.updateActivityNoteLoadingIds.filter(
      (loadingId) => loadingId !== payload
    ),
  }))
  .handleAction(changeActivitiesFilter, (state, action) => ({
    ...state,
    activitiesFilter: action.payload,
  }))
  .handleAction(changeActivitiesSearchQuery, (state, action) => ({
    ...state,
    activitiesSearchQuery: action.payload,
  }))
  .handleAction(changeActivitiesRecapRange, (state, action) => ({
    ...state,
    activitiesRecapRange: action.payload,
  }))
  .handleAction(changeActivitiesSelectedActivityTypes, (state, { payload }) => ({
    ...state,
    activitiesSelectedActivityTypes: payload,
  }))
  .handleAction(changeActivitiesOrder, (state, action) => ({
    ...state,
    activitiesOrder: action.payload,
  }))
  .handleAction(changeActivitiesAssignees, (state, { payload }) => ({
    ...state,
    activitiesSelectedAssignees: payload,
  }))
  .handleAction(deleteCompany.request, (state) => ({
    ...state,
    deleteLoading: true,
    hasChanges: false,
  }))
  .handleAction([deleteCompany.success, deleteCompany.failure], (state) => ({
    ...state,
    deleteLoading: false,
  }))
  .handleAction(updateCompanyFrequency.success, (state, { payload }) => ({
    ...state,
    recordData: {
      ...state.recordData,
      company: { ...state.recordData.company, ...payload },
    },
  }))
  .handleAction(setActivityAutoScrolling, (state, { payload }) => ({
    ...state,
    activityAutoScrolling: payload,
  }))
  .handleAction(setHasChangesFlag, (state, { payload }) => ({
    ...state,
    hasChanges: payload,
  }))
  .handleAction(fetchMoreDeals.success, (state, { payload }) => ({
    ...state,
    recordData: {
      ...state.recordData,
      deals: [...state.recordData.deals, ...payload],
    },
  }))
  .handleAction(fetchMorePeople.success, (state, { payload }) => ({
    ...state,
    recordData: {
      ...state.recordData,
      people: [...state.recordData.people, ...payload],
    },
  }));

export type CompanyRecordActions = Actions;
export default companyRecord;

import { defineMessages, IntlShape } from "react-intl";
import PinLegendCreationVisibility from "@mapmycustomers/shared/enum/PinLegendCreationVisibility";

export const messages = defineMessages<PinLegendCreationVisibility>({
  [PinLegendCreationVisibility.PRIVATE]: {
    id: "pinLegendCreationVisibility.private",
    defaultMessage: "Organization owners only",
    description: "Private option for the pin legend creation visibility",
  },
  [PinLegendCreationVisibility.SHARED_WITH_MANAGERS]: {
    id: "pinLegendCreationVisibility.sharedWithManagers",
    defaultMessage: "Team managers and organization owners",
    description: "Shared With Team option for the pin legend creation visibility",
  },
  [PinLegendCreationVisibility.SHARED_WITH_ORGANIZATION]: {
    id: "pinLegendCreationVisibility.sharedWithOrganization",
    defaultMessage: "All users",
    description: "Entire Org option for the pin legend creation visibility",
  },
});

const getPinLegendCreationVisibilityDisplayName = (
  intl: IntlShape,
  visibility: PinLegendCreationVisibility
) => intl.formatMessage(messages[visibility]);

export default getPinLegendCreationVisibilityDisplayName;

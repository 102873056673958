import MmcNotificationAction from "enum/MmcNotificationAction";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBusinessTime } from "@fortawesome/free-solid-svg-icons/faBusinessTime";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import { faRoute } from "@fortawesome/free-solid-svg-icons/faRoute";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faAlarmExclamation } from "@fortawesome/pro-solid-svg-icons/faAlarmExclamation";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons/faCalendarCheck";

const getActionIcon = (action: MmcNotificationAction): IconProp | undefined => {
  if (action === MmcNotificationAction.COMPANY_GROUP_SHARED) {
    return faLayerGroup;
  }

  if (action === MmcNotificationAction.COMPANY_ROUTE_SHARED) {
    return faRoute;
  }
  if (action === MmcNotificationAction.DEAL_GROUP_SHARED) {
    return faLayerGroup;
  }
  if (action === MmcNotificationAction.DEALS_MARKED_LOST) {
    return faTimes;
  }
  if (action === MmcNotificationAction.DEALS_MARKED_WON) {
    return faCheck;
  }
  if (action === MmcNotificationAction.DEALS_ROTTING) {
    return faBusinessTime;
  }
  if (action === MmcNotificationAction.OUT_OF_CADENCE) {
    return faAlarmExclamation;
  }
  if (action === MmcNotificationAction.OVERDUE_ACTIVITIES) {
    return faExclamation;
  }
  if (action === MmcNotificationAction.PEOPLE_GROUP_SHARED) {
    return faLayerGroup;
  }
  if (action === MmcNotificationAction.PEOPLE_ROUTE_SHARED) {
    return faRoute;
  }
  if (
    [
      MmcNotificationAction.MARK_ACTIVITY_AS_DONE,
      MmcNotificationAction.ACTIVITY_STARTING,
      MmcNotificationAction.INCOMPLETE_ACTIVITY,
    ].includes(action)
  ) {
    return faCalendarCheck;
  }
};

export default getActionIcon;

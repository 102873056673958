@use "../../../../../styles/colors";
@use "../../../../../styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .suggestionHighlighted {
        background-color: colors.$mmc-soft-gray;
      }
      .suggestions {
        top: -9999px;
        left: -9999px;
        position: absolute;
        z-index: 1030;

        border-radius: 0.25rem;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        background-color: colors.$mmc-white;
      }

      .suggestion {
        padding: 0.5rem;
        max-width: 32rem;
        overflow: hidden;
      }
      .userNameWrapper {
        width: 100%;
      }

      .noResult {
        color: colors.$mmc-disabled-gray;
        max-width: 14rem;
      }
    }
  }
}

import { createReducer } from "typesafe-actions";
import {
  Actions,
  bulkUpdateField,
  cancelEditField,
  editField,
  enterArchiveMode,
  exitArchiveMode,
  setActiveEntityType,
  updateField,
  updateSchema,
  updateViewState,
} from "./actions";
import {
  EntityType,
  EntityTypesSupportingFieldCustomization,
} from "@mapmycustomers/shared/types/entity";
import SchemaField from "@mapmycustomers/shared/types/schema/SchemaField";
import CustomField from "@mapmycustomers/shared/types/customField/CustomField";
import {
  SchemaFieldTypeFilter,
  SchemaFieldTypeValues,
} from "@mapmycustomers/shared/enum/SchemaFieldType";
import {
  FieldVisibilityFilter,
  FieldVisibilityValues,
} from "@mapmycustomers/shared/enum/FieldVisibility";

export interface ManageFieldsEditedFieldFormState {
  customField?: CustomField;
  field?: SchemaField;
  loading: boolean;
}

export interface ManageFieldsViewState {
  search?: string;
  type?: SchemaFieldTypeValues;
  visibility?: FieldVisibilityValues;
}

export interface ManageFieldsState {
  activeEntityType: EntityTypesSupportingFieldCustomization;
  archive: boolean;
  editedField?: ManageFieldsEditedFieldFormState;
  loading: boolean;
  fields: SchemaField[];
  total: number;
  viewState: ManageFieldsViewState;
}

const initialState: ManageFieldsState = {
  activeEntityType: EntityType.COMPANY,
  archive: false,
  editedField: undefined,
  fields: [],
  total: 0,
  loading: false,
  viewState: {
    type: SchemaFieldTypeFilter.ALL,
    visibility: FieldVisibilityFilter.ALL,
  },
};

const manageFieldsStore = createReducer<ManageFieldsState, Actions>(initialState)
  .handleAction(setActiveEntityType, (state, { payload }) => ({
    ...state,
    activeEntityType: payload,
  }))
  .handleAction(enterArchiveMode, (state) => ({
    ...state,
    archive: true,
  }))
  .handleAction(updateViewState, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      ...payload,
    },
  }))
  .handleAction(editField.success, (state, { payload }) => ({
    ...state,
    editedField: {
      ...state.editedField,
      customField: payload.customField,
      field: payload.field,
      loading: false,
    },
  }))
  .handleAction(cancelEditField, (state) => ({
    ...state,
    editedField: undefined,
  }))
  .handleAction(updateField.request, (state) => ({
    ...state,
    editedField: {
      ...state.editedField,
      loading: true,
    },
  }))
  .handleAction([updateField.failure, updateField.success], (state) => ({
    ...state,
    editedField: {
      ...state.editedField,
      loading: false,
    },
  }))
  .handleAction(bulkUpdateField.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction([bulkUpdateField.failure, bulkUpdateField.success], (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(updateSchema.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(updateSchema.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(updateSchema.success, (state, { payload }) => ({
    ...state,
    loading: false,
    fields: payload.fields,
    total: payload.total,
  }))
  .handleAction(exitArchiveMode, (state) => ({
    ...state,
    archive: false,
  }));
export * from "./selectors";

export type ManageFieldsActions = Actions;

export default manageFieldsStore;

import { ActionType, createAsyncAction } from "typesafe-actions";
import TextSearchResult from "types/TextSearchResult";

export const doGlobalSearch = createAsyncAction(
  "@component/navbar/search/request",
  "@component/navbar/search/success",
  "@component/navbar/search/failure"
)<string, TextSearchResult[], void>();

export type Actions = ActionType<typeof doGlobalSearch>;

import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";

const entitySearchState = (state: RootState) => state.component.entitySearch;

export const getFilteredCompanies = createSelector(entitySearchState, ({ companies }) => companies);
export const areCompaniesLoading = createSelector(
  entitySearchState,
  ({ companiesLoading }) => companiesLoading
);

export const getFilteredDeals = createSelector(entitySearchState, ({ deals }) => deals);
export const areDealsLoading = createSelector(
  entitySearchState,
  ({ dealsLoading }) => dealsLoading
);

export const getFilteredPeople = createSelector(entitySearchState, ({ people }) => people);
export const arePeopleLoading = createSelector(
  entitySearchState,
  ({ peopleLoading }) => peopleLoading
);

import React, { useCallback } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import { Alert } from "@mapmycustomers/ui";
import { useIntl } from "react-intl";
import Path from "../../../../enum/Path";
import commonStyles from "../AuthCommon.module.scss";
import styles from "./LoginForm.module.scss";
import analyticsService from "../../../../util/analytic/AnalyticsService";
import Divider from "antd/es/divider";
import messages from "./messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";

const SSO_INQUIRY_LINK = "https://share.hsforms.com/1aoERY4lrQ0GOQeRlXahNAQ3is1h";

interface Props {
  className?: string;
  errorMessage?: string;
  isLoading: boolean;
  onSubmit: (values: { username: string; password: string }) => void;
  withRegistrationLink?: boolean;
  withRestorePasswordLink?: boolean;
  withWelcomeText?: boolean;
}

const LoginForm: React.FC<Props> = ({
  className,
  errorMessage,
  isLoading,
  onSubmit,
  withRegistrationLink = true,
  withRestorePasswordLink = true,
  withWelcomeText = true,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleOnForgotPassword = useCallback(
    () => analyticsService.clicked(["Forgot password"]),
    []
  );

  const handleSsoLogin = useCallback(() => analyticsService.clicked(["SSO"]), []);

  return (
    <section className={cn(commonStyles.container, className)}>
      <div className={styles.loginDiv}>
        <div className={styles.loginPage}>
          <div>
            {errorMessage && <Alert message={errorMessage} type="error" />}
            <Form form={form} id="login" layout="vertical" name="login" onFinish={onSubmit}>
              {withWelcomeText && (
                <h1 className={commonStyles.title}>{intl.formatMessage(messages.title)}</h1>
              )}
              <Form.Item
                colon={false}
                label={intl.formatMessage(messages.email)}
                name="username"
                required
                requiredMark="optional"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage(messages.emailRequired),
                  },
                ]}
              >
                <Input autoComplete="username" name="username" size="large" type="email" />
              </Form.Item>
              <Form.Item
                className={styles.passwordField}
                colon={false}
                label={intl.formatMessage(messages.password)}
                name="password"
                required
                requiredMark="optional"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage(messages.passwordRequired),
                  },
                ]}
              >
                <Input.Password
                  autoComplete="current-password"
                  name="current-password"
                  size="large"
                  spellCheck="false"
                />
              </Form.Item>
              {withRestorePasswordLink && (
                <Link
                  className={styles.forgotPasswordLink}
                  onClick={handleOnForgotPassword}
                  to={Path.FORGOT_PASSWORD}
                >
                  {intl.formatMessage(messages.forgotPasswordLink)}
                </Link>
              )}
              <Form.Item>
                <Button
                  block
                  className={styles.loginButton}
                  htmlType="submit"
                  loading={isLoading}
                  type="primary"
                >
                  {isLoading
                    ? intl.formatMessage(messages.loading)
                    : intl.formatMessage(messages.submit)}
                </Button>
              </Form.Item>
              <Divider className={styles.divider}>{intl.formatMessage(messages.divider)}</Divider>
              <div className={styles.container}>
                <Link to={Path.SSO_LOGIN}>
                  <Button
                    block
                    icon={<FontAwesomeIcon icon={faKey} />}
                    onClick={handleSsoLogin}
                    type="dashed"
                  >
                    {intl.formatMessage(messages.ssoLogin)}
                  </Button>
                </Link>
                <Button
                  className={styles.inquire}
                  href={SSO_INQUIRY_LINK}
                  target="_blank"
                  type="link"
                >
                  {intl.formatMessage(messages.inquire)}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;

import { put, select, takeEvery } from "redux-saga/effects";
import { fetchDeals } from "./actions";
import { handleError } from "store/errors/actions";
import { callApi } from "store/api/callApi";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getOrganizationId } from "store/iam";
import { Deal } from "@mapmycustomers/shared/types/entity";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { convertToPlatformFilterModel } from "util/viewModel/convertToPlatformFilterModel";
import dealFieldModel from "util/fieldModel/DealFieldModel";
import { DEFAULT_PAGE_SIZE } from "..";

export function* onFetchDeals({ payload }: ReturnType<typeof fetchDeals.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const range = payload.request.range;

    const response: ListResponse<Deal> = yield callApi("fetchDeals", orgId, {
      $filters: {
        ...convertToPlatformFilterModel(payload.request.filter ?? {}, [], dealFieldModel),
      },
      $offset: range ? range.startRow : 0,
      $limit: range ? range.endRow - range.startRow : DEFAULT_PAGE_SIZE,
    });
    yield put(fetchDeals.success({ deals: response.data, total: response.total }));
  } catch (error) {
    yield put(fetchDeals.failure());
    yield put(handleError({ error }));
  }
}

export function* dealsSaga() {
  yield takeEvery(fetchDeals.request, onFetchDeals);
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import cn from "classnames";
import { Alert } from "@mapmycustomers/ui";
import Button from "antd/es/button";
import Checkbox from "antd/es/checkbox";
import Form from "antd/es/form";
import PopConfirm from "antd/es/popconfirm";
import Select from "antd/es/select";
import { useForm } from "antd/es/form/Form";
import File from "./component/File";
import CheckInActivityAlert from "./CheckInActivityAlert";
import BaseModal from "component/modal/BaseModal";
import FormLayoutSelector from "component/FormLayoutSelector";
import FormNoLayoutAlert from "component/FormNoLayoutAlert";
import { LoadingSpinner, TextAreaWithMentions } from "@mapmycustomers/ui";
import AssigneeSearch from "component/activity/AssigneeSearch";
import ActivityNameField from "./component/ActivityNameField";
import ActivityFooter from "./component/ActivityFooter";
import FormFields from "component/FormFields";
import SubmitButton from "./component/SubmitButton";
import ConditionalFormField, {
  ConditionalFormFieldRenderProps,
} from "component/ConditionalFormField";
import VisibilitySelectField from "component/input/VisibilitySelectField";
import { useRecurIntervalTypeValueChangeHandler } from "component/createEditEntity/Activity/utils/useRecurIntervalTypeValueChangeHandler";
import { RootState } from "store/rootReducer";
import {
  getAssociationsState,
  getEditedActivity,
  getUploadedFilesList,
  hasNoAccess,
  isActivityComplete,
  isDeleting,
  isInitializing,
  isUpdating,
} from "./store";
import {
  changeAssociatedEntities,
  clearActivity,
  clearAllUploadedCreateEditActivityFiles,
  clearAssociations,
  deleteActivity,
  initializeEditActivityModal,
  updateActivity,
} from "./store/actions";
import { getActivityTypes } from "store/activity";
import { getTeams, getUsersOfEntireOrganization } from "store/members";
import { getFocusedFieldName } from "store/entityView";
import FormValues from "./types/FormValues";
import { RecurringDeleteDropdown } from "./RecurringDeleteDropdown";
import NotificationSettings from "../NotificationSettings";
import { getCurrentUser, getMe, getOrganizationSettingValue } from "store/iam";
import ActivityVisibility from "@mapmycustomers/shared/enum/activity/ActivityVisibility";
import { getActivityVisibilityNote } from "util/ui";
import OrganizationSetting from "enum/OrganizationSetting";
import ActivityCompletion from "@mapmycustomers/shared/enum/activity/ActivityCompletion";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Visibility from "@mapmycustomers/shared/enum/Visibility";
import Role from "@mapmycustomers/shared/enum/Role";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { Activity, EntityType } from "@mapmycustomers/shared/types/entity";
import Iam from "types/Iam";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import HideAction from "types/HideCallback";
import FileListItem from "types/FileListItem";
import EditActivityFocusableField from "../../activity/type/EditActivityFocusableField";
import { useIsRecurrentValueChangeHandler } from "./utils/useIsRecurrentValueChangeHandler";
import inaccessibleEntityAlertMessage from "./utils/inaccessibleEntityAlertMessage";
import getFormInitialValues from "./utils/getFormInitialValues";
import useFormChangeTracker from "util/hook/form/useFormChangeTracker";
import useErroredFieldsScroller from "util/hook/useErroredFieldsScroller";
import useMentionsValidation from "./utils/useMentionsValidation";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useSaveModeHandler from "./utils/useSaveModeHandler";
import activityFieldModel, { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";
import useUpdateCallback from "./utils/useUpdateCallback";
import useDeleteCallback from "./utils/useDeleteCallback";
import useActivityVisibilityOptions from "util/hook/useActivityVisibilityOptions";
import stringHash from "@mapmycustomers/shared/util/hash/stringHash";
import useRerenderer from "@mapmycustomers/shared/util/hook/useRerenderer";
import useActivityTypeValidationRules from "./utils/useActivityTypeValidationRules";
import useActivityTypeOptions from "util/ui/useActivityTypeOptions";
import { useRecurIntervalChangedWarningHandler } from "./utils/useRecurIntervalChangedWarningHandler";
import { useModalConfirm } from "./utils/useModalConfirm";
import fillOutRequiredFieldsMessage from "./utils/fillOutRequiredFieldsMessage";
import AnalyticsContext from "util/contexts/AnalyticsContext";
import useAnalytics from "util/contexts/useAnalytics";
import { useExtendedAnalytics } from "util/analytic/AnalyticsService";
import { addUserRecent } from "store/globalSearch/actions";
import { differenceInMinutes } from "date-fns/esm";
import isValidDate from "util/isValidDate";
import isRequiredLayoutSchemaField from "util/schema/isRequiredLayoutSchemaField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { activityLayoutModel } from "util/layout/impl";
import {
  CompanyRelationshipField,
  DealRelationshipField,
  PersonRelationshipField,
} from "component/FormFields/components/Associations";
import AssociationsState from "store/associations/AssociationsState";
import { isManager, isOwner } from "store/iam/util";
import NoAccess from "./component/NoAccess";
import styles from "./CreateEditActivityModal.module.scss";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import RelatedEntitiesMatching from "./component/RelatedEntitiesMatching";

export const messages = defineMessages({
  requiredFields: {
    id: "createActivityModal.requiredFieldAlert",
    defaultMessage: "Fill out all required fields to log activity.",
    description: "Required Field alert while logging activity",
  },
});

interface Props {
  activity?: Activity;
  activityCompleted: boolean;
  // specify activity id, rest of data is fetched by the modal itself
  activityId: Activity["id"];
  activityTypes: ActivityType[];
  addUserRecent: typeof addUserRecent;
  associations: AssociationsState;
  className?: string;
  clearActivity: typeof clearActivity;
  clearAllUploadedFiles: () => void;
  clearAssociations: typeof clearAssociations;
  currentUser: User;
  defaultCompletion: boolean;
  defaultVisibility: ActivityVisibility;
  deleteActivity: typeof deleteActivity.request;
  deleting: boolean;
  focusedFieldName?: EditActivityFocusableField;
  initialize: typeof initializeEditActivityModal.request;
  initializing: boolean;
  me: Iam;
  noAccess?: boolean;
  onChangeAssociatedEntities: typeof changeAssociatedEntities.request;
  onHide: HideAction<Activity>;
  overrideValues?: Partial<Activity>;
  requiredFieldsOnly?: boolean;
  teams: Team[];
  updateActivity: typeof updateActivity.request;
  updating: boolean;
  uploadedFiles: FileListItem[];
  users: User[];
}

const EditActivityModal: React.FC<Props> = ({
  activity,
  activityCompleted,
  activityId,
  activityTypes,
  addUserRecent,
  associations,
  className,
  clearActivity,
  clearAllUploadedFiles,
  clearAssociations,
  currentUser,
  defaultCompletion,
  defaultVisibility,
  deleteActivity,
  deleting,
  initialize,
  initializing,
  me,
  noAccess,
  onChangeAssociatedEntities,
  onHide,
  overrideValues,
  requiredFieldsOnly,
  teams,
  updateActivity,
  updating,
  uploadedFiles,
  users,
}) => {
  const intl = useIntl();
  const analyticIssuer = useExtendedAnalytics(["Edit Activity"], useAnalytics());

  const forceRender = useRerenderer();

  useEffect(() => {
    initialize({ activityId, overrideValues });
  }, [activityId, overrideValues, initialize]);

  const [formLayout, setFormLayout] = useState(activityLayoutModel.defaultFormLayout);
  useEffect(() => {
    if (activity) {
      setFormLayout(activityLayoutModel.getLayoutFor(activity));
    }
  }, [activity]);

  const [shouldCreateAnother, , , toggleCreateAnother] = useBoolean(false);
  const handleCreateAnotherChange = useCallback(() => toggleCreateAnother(), [toggleCreateAnother]);
  const visibilityOptions = useActivityVisibilityOptions(isOwner(me), isManager(me));

  const [form] = useForm<FormValues>();
  const [isFormChanged, handleFormChanges] = useFormChangeTracker(form);

  const updatedActivityCompleted = form.getFieldValue(ActivityFieldName.COMPLETED);
  const activityMarkedDone =
    !!overrideValues?.completed ||
    (updatedActivityCompleted && updatedActivityCompleted !== activityCompleted);

  const isCheckInActivity = activity?.reliability != null;
  const isCheckInActivityVerified = activity?.reliability === true;

  const teamMemberIds = users.filter(({ role }) => role.key !== Role.OWNER).map((user) => user.id);
  const isCreatedByUserInTeam = !!activity && teamMemberIds.includes(activity.user.id);
  const isAssignedToUserInTeam =
    !!activity?.assignee && teamMemberIds.includes(activity.assignee.id);
  const teamOrPublicVisibility =
    activity?.visibility === Visibility.SHARED_WITH_ORGANIZATION ||
    activity?.visibility === Visibility.SHARED_WITH_TEAM;
  const isAssignee = activity?.assignee?.id === currentUser.id;
  const isCreator = activity?.user.id === currentUser.id;
  const isAssigneeNotCreator =
    activity?.assignee?.id === currentUser.id && currentUser.id !== activity?.user.id;

  let canChangeVisibility = false;
  if (isOwner(me) || isCreator) {
    canChangeVisibility = true;
  } else if (isManager(me)) {
    // managers can change visibility if:
    // visibility is public or team
    // AND activity was created by or assigned to anybody in their team
    // BUT not when manager is assignee and creator is not on their team
    canChangeVisibility =
      teamOrPublicVisibility &&
      (isCreatedByUserInTeam || isAssignedToUserInTeam) &&
      !(isAssignee && !isCreatedByUserInTeam);
  }

  const handleHide = useCallback(
    (updated?: boolean, entity?: Activity) => {
      // we need this call because if we don't clear the activity from the store, the very next
      // time EditActivity modal is open, it'll use that previous value. Even though there will
      // be a new activityId, the old activity will be already used to initialize the form.
      clearActivity();
      clearAssociations();
      clearAllUploadedFiles();
      onHide(updated, entity);
    },
    [clearActivity, clearAssociations, clearAllUploadedFiles, onHide]
  );
  const handleCancelClick = useCallback(() => handleHide(), [handleHide]);

  const handleFormFinish = useUpdateCallback(
    activity,
    activityTypes,
    form,
    formLayout,
    activityMarkedDone,
    handleHide,
    updateActivity,
    intl,
    analyticIssuer
  );

  const handleDelete = useDeleteCallback(activity, deleteActivity, onHide, analyticIssuer);

  const [, allowedUserEmails, handleAllMentionedUsersHaveAccessToActivity] = useMentionsValidation(
    me,
    users,
    associations.associatedCompany,
    associations.associatedDeal,
    associations.associatedPerson,
    activity
  );

  const handleSave = useCallback(() => form.submit(), [form]);
  const [modalConfirm, contextHolder] = useModalConfirm(handleSave);

  const isRecurrentValueChangeHandler = useIsRecurrentValueChangeHandler(form);
  const recurIntervalTypeValueChangeHandler = useRecurIntervalTypeValueChangeHandler(form);
  const [isChangedRecurrentIntervalWarningVisible, handleRecurIntervalChange] =
    useRecurIntervalChangedWarningHandler(activity);

  const handleClickOk = useCallback(() => {
    if (isChangedRecurrentIntervalWarningVisible) {
      modalConfirm();
    } else {
      handleSave();
    }
  }, [handleSave, isChangedRecurrentIntervalWarningVisible, modalConfirm]);

  const [{ isActivityPublic, options, recurChanged, showCheckInSubmit }, saveModeHandler] =
    useSaveModeHandler(activity);

  const handleActivityChange = useCallback(
    (values: FormValues, allValues: FormValues) => {
      if (allValues.endAt && allValues.startAt && allValues.endAt < allValues.startAt) {
        form.setFieldsValue({ endAt: allValues.startAt });
      }
    },
    [form]
  );

  const handleValuesChanges = useCallback(
    (changedValues, values) => {
      [
        isRecurrentValueChangeHandler,
        recurIntervalTypeValueChangeHandler,
        saveModeHandler,
        handleRecurIntervalChange,
        handleFormChanges,
        handleAllMentionedUsersHaveAccessToActivity,
        handleActivityChange,
      ].forEach((fn) => fn(changedValues, values));
    },
    [
      isRecurrentValueChangeHandler,
      recurIntervalTypeValueChangeHandler,
      saveModeHandler,
      handleRecurIntervalChange,
      handleFormChanges,
      handleAllMentionedUsersHaveAccessToActivity,
      handleActivityChange,
    ]
  );

  const handleNonInteractiveChanges = useCallback(() => {
    const formValues = form.getFieldsValue();
    saveModeHandler(formValues, formValues);
    handleFormChanges();
    forceRender();
  }, [forceRender, form, handleFormChanges, saveModeHandler]);

  const handleIsMentionDisabled = useCallback(
    (user: User) => {
      return !allowedUserEmails?.includes(user.username) ?? true;
    },
    [allowedUserEmails]
  );

  const [formFieldsRef, handleFinishFail] = useErroredFieldsScroller();

  const submitButtonDisabled = initializing || deleting || updating || !formLayout;
  const showConfirm = !initializing && (isFormChanged || uploadedFiles.length > 0);

  const isCompleted = form.getFieldValue(ActivityFieldName.COMPLETED);
  const dateValue = form.getFieldValue(ActivityFieldName.START_AT);
  const isPastDate = isValidDate(dateValue) && differenceInMinutes(new Date(), dateValue) >= 60;

  const unavailableActivityType = useMemo(() => {
    return activityTypes
      .filter(({ active }) => active)
      .some(({ id }) => id === activity?.crmActivityType?.id)
      ? undefined
      : activity?.crmActivityType;
  }, [activity, activityTypes]);

  const activityTypeValidationRules = useActivityTypeValidationRules(intl, unavailableActivityType);
  const activityTypeOptions = useActivityTypeOptions(
    activityTypes,
    form.getFieldValue("activityTypeId"),
    useCallback(
      (type) => !type.active || (isCheckInActivity && !type.checkInEnabled),
      [isCheckInActivity]
    )
  );

  const appendAddableFieldsWithFilter = useCallback(
    (field: IField) => (activity ? field.hasNonEmptyValueFor(activity) : false),
    [activity]
  );

  useEffect(() => {
    const initialValues = getFormInitialValues(
      activity,
      currentUser,
      undefined,
      defaultCompletion,
      defaultVisibility,
      unavailableActivityType
    );
    form.setFieldsValue(initialValues);
  }, [activity, currentUser, defaultCompletion, defaultVisibility, form, unavailableActivityType]);

  useEffect(() => {
    addUserRecent({ entity: { id: activityId, type: EntityType.ACTIVITY } });
  }, [activityId, addUserRecent]);

  return (
    <AnalyticsContext.Provider value={analyticIssuer}>
      <BaseModal
        borderedHeader
        className={cn(styles.modal, className)}
        destroyOnClose
        entityTypeName="Activity"
        footer={null}
        noContentScroll
        onCancel={handleCancelClick}
        showConfirm={showConfirm}
        subtitle={
          isPastDate || initializing || !formLayout ? undefined : (
            <NotificationSettings
              disabled={updating}
              form={form}
              onChange={handleNonInteractiveChanges}
            />
          )
        }
        title={
          <>
            {intl.formatMessage({
              id: "editActivityModal.header",
              defaultMessage: "Edit Activity",
              description: "Header of Edit Activity modal",
            })}

            <FormLayoutSelector
              disabled={initializing || updating}
              entityType={EntityType.ACTIVITY}
              layout={formLayout}
              onChange={setFormLayout}
            />
          </>
        }
      >
        {initializing ? (
          <LoadingSpinner />
        ) : noAccess ? (
          <NoAccess onDismiss={handleCancelClick} />
        ) : formLayout ? (
          <div className={styles.formContainer} ref={formFieldsRef}>
            <RelatedEntitiesMatching
              associatedCompany={associations.associatedCompany}
              associatedDeal={associations.associatedDeal}
              associatedPerson={associations.associatedPerson}
            />
            <Form<FormValues>
              className={styles.form}
              form={form}
              initialValues={getFormInitialValues(
                activity,
                currentUser,
                undefined,
                defaultCompletion,
                defaultVisibility,
                unavailableActivityType
              )}
              layout="vertical"
              onFinish={handleFormFinish}
              onFinishFailed={handleFinishFail}
              onValuesChange={handleValuesChanges}
            >
              <div className={styles.content}>
                {associations.inaccessibleEntity && (
                  <Alert
                    className={styles.inaccessibleEntityAlert}
                    message={intl.formatMessage(inaccessibleEntityAlertMessage)}
                    showIcon
                    type="warning"
                  />
                )}

                {requiredFieldsOnly && (
                  <Alert
                    className={styles.inaccessibleEntityAlert}
                    message={intl.formatMessage(fillOutRequiredFieldsMessage)}
                    showIcon
                    type="warning"
                  />
                )}

                {isCheckInActivityVerified && <CheckInActivityAlert />}

                <FormFields
                  appendAddableFieldsWithFilter={appendAddableFieldsWithFilter}
                  disallowAdding={requiredFieldsOnly}
                  entityType={EntityType.ACTIVITY}
                  fileComponent={File}
                  filterFields={requiredFieldsOnly ? isRequiredLayoutSchemaField : undefined}
                  ignoreRequired={!isCompleted}
                  layout={formLayout}
                >
                  <ConditionalFormField feature={FieldFeature.ACTIVITY_TYPE_FIELD}>
                    {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                      <Form.Item
                        label={label}
                        name="activityTypeId"
                        required={required}
                        rules={activityTypeValidationRules}
                      >
                        <Select<ActivityType["id"]>
                          className={styles.activityTypeSelect}
                          disabled={disabled}
                          dropdownMatchSelectWidth={false}
                          filterOption={(inputValue, option) =>
                            (
                              activityTypes.find(
                                (type) => type.id === parseInt(`${option?.value || 0}`)
                              )?.name as string
                            )
                              ?.toLowerCase()
                              .includes(inputValue.toLowerCase().trim()) ?? false
                          }
                          placeholder={
                            unavailableActivityType?.name ??
                            intl.formatMessage({
                              id: "createEditActivityModal.field.activityType.placeholder",
                              defaultMessage: "Select",
                              description:
                                "Placeholder for the Activity Type field on the Create Activity modal",
                            })
                          }
                          showSearch
                        >
                          {isCheckInActivity && (
                            <Select.Option
                              className={styles.message}
                              disabled
                              key="Activity Types"
                              label={intl.formatMessage({
                                id: "createEditActivityModal.field.activityType.checkInTypes.message",
                                defaultMessage:
                                  "Some activity types may be disabled for Check-In activities",
                                description:
                                  "Message for the CheckIn Activity Types while editing CheckIn Activity",
                              })}
                              value={-1}
                            >
                              {intl.formatMessage({
                                id: "createEditActivityModal.field.activityType.checkInTypes.message",
                                defaultMessage:
                                  "Some activity types may be disabled for Check-In activities",
                                description:
                                  "Message for the CheckIn Activity Types while editing CheckIn Activity",
                              })}
                            </Select.Option>
                          )}
                          {activityTypeOptions.map((type) => (
                            <Select.Option
                              className={cn({ [styles.disabled]: type.disabled })}
                              disabled={type.disabled}
                              key={type.value}
                              label={type.label}
                              value={type.value}
                            >
                              {type.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.NAME}>
                    <ActivityNameField activityTypes={activityTypes} />
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.ASSIGNEE}>
                    {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                      <Form.Item
                        label={label}
                        name="assignee"
                        required={required}
                        rules={[{ required }]}
                        valuePropName="assignee"
                      >
                        <AssigneeSearch
                          allowedUserEmails={allowedUserEmails}
                          disabled={!canChangeVisibility || disabled}
                          users={users}
                        />
                      </Form.Item>
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.ACCOUNT}>
                    {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                      <CompanyRelationshipField
                        associatedCompany={associations.associatedCompany}
                        associatedDeal={associations.associatedDeal}
                        associatedPerson={associations.associatedPerson}
                        companies={associations.availableCompanies}
                        disabled={disabled}
                        field={activityFieldModel.getByName(ActivityFieldName.ACCOUNT)!}
                        loading={associations.availableLoading}
                        onChangeAssociatedEntities={onChangeAssociatedEntities}
                        required={required}
                      />
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.CONTACT}>
                    {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                      <PersonRelationshipField
                        associatedCompany={associations.associatedCompany}
                        associatedDeal={associations.associatedDeal}
                        associatedPerson={associations.associatedPerson}
                        disabled={disabled}
                        field={activityFieldModel.getByName(ActivityFieldName.CONTACT)!}
                        loading={associations.availableLoading}
                        onChangeAssociatedEntities={onChangeAssociatedEntities}
                        people={associations.availablePeople}
                        required={required}
                      />
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.DEAL}>
                    {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                      <DealRelationshipField
                        associatedCompany={associations.associatedCompany}
                        associatedDeal={associations.associatedDeal}
                        associatedPerson={associations.associatedPerson}
                        deals={associations.availableDeals}
                        disabled={disabled}
                        field={activityFieldModel.getByName(ActivityFieldName.DEAL)!}
                        loading={associations.availableLoading}
                        onChangeAssociatedEntities={onChangeAssociatedEntities}
                        required={required}
                      />
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.NOTE}>
                    {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                      <Form.Item
                        dependencies={[ActivityFieldName.NOTE, ActivityFieldName.VISIBILITY]}
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.visibility !== curValues.visibility
                        }
                      >
                        {({ getFieldValue }) => (
                          <Form.Item
                            label={label}
                            name={ActivityFieldName.NOTE}
                            required={required}
                            rules={[{ required }]}
                          >
                            <TextAreaWithMentions
                              allUsers={users}
                              disabled={disabled}
                              hideDisabledUsers
                              isMentionDisabled={handleIsMentionDisabled}
                              key={
                                getFieldValue(ActivityFieldName.VISIBILITY) +
                                stringHash(allowedUserEmails?.join() ?? "")
                              }
                              maxLength={Number.MAX_SAFE_INTEGER}
                            />
                          </Form.Item>
                        )}
                      </Form.Item>
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.VISIBILITY}>
                    {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                      <Form.Item
                        label={intl.formatMessage({
                          id: "createEditActivityModal.field.visibility",
                          defaultMessage: "Visible to",
                          description:
                            "Visibility field label in Create create edit activity modal",
                        })}
                        required={required}
                        shouldUpdate={(prev, next) =>
                          prev.visibility !== next.visibility || prev.teamIds !== next.teamIds
                        }
                      >
                        {({ getFieldValue, setFieldsValue }) => (
                          <VisibilitySelectField<ActivityVisibility>
                            disabled={disabled || !canChangeVisibility}
                            noteGetter={getActivityVisibilityNote}
                            onChange={(visibility: ActivityVisibility) =>
                              setFieldsValue({ visibility })
                            }
                            onTeamIdsChange={(teamIds: Team["id"][]) => setFieldsValue({ teamIds })}
                            options={visibilityOptions}
                            teamIds={getFieldValue("teamIds")}
                            teams={teams}
                            teamsRenderer={(node) => (
                              <Form.Item name={"teamIds"} noStyle>
                                {node}
                              </Form.Item>
                            )}
                            value={getFieldValue(ActivityFieldName.VISIBILITY)}
                            visibilityRenderer={(node) => (
                              <Form.Item name={ActivityFieldName.VISIBILITY} noStyle>
                                {node}
                              </Form.Item>
                            )}
                          />
                        )}
                      </Form.Item>
                    )}
                  </ConditionalFormField>
                </FormFields>
              </div>

              <div className={styles.footer}>
                <div className={styles.left}>
                  <ActivityFooter form={form} onChange={handleNonInteractiveChanges} />
                </div>

                <div className={styles.right}>
                  <div className={styles.actions}>
                    <div>
                      {activity?.recurInterval ? (
                        <RecurringDeleteDropdown
                          isAssigneeNotCreator={isAssigneeNotCreator}
                          onDelete={handleDelete}
                        >
                          <Button
                            className={styles.deleteButton}
                            disabled={deleting}
                            loading={deleting}
                          >
                            <FormattedMessage
                              defaultMessage="Delete"
                              description="Delete button title on Create/Edit Activity modal"
                              id="editActivityModal.footer.delete"
                            />{" "}
                            <FontAwesomeIcon icon={faAngleDown} />
                          </Button>
                        </RecurringDeleteDropdown>
                      ) : (
                        <PopConfirm
                          cancelText={intl.formatMessage({
                            id: "editActivityModal.footer.deleteActivity.confirm.no",
                            defaultMessage: "No",
                            description: "No-confirmation text for Delete activity from edit modal",
                          })}
                          okText={intl.formatMessage({
                            id: "editActivityModal.footer.deleteActivity.confirm.yes",
                            defaultMessage: "Yes, delete",
                            description:
                              "Yes-confirmation text for Delete activity from edit modal",
                          })}
                          onConfirm={() => handleDelete()}
                          title={intl.formatMessage({
                            id: "editActivityModal.footer.deleteActivity.confirm",
                            defaultMessage:
                              "Are you sure you want to permanently delete this activity?",
                            description: "Delete activity from edit modal Confirmation text",
                          })}
                        >
                          <Button
                            className={styles.deleteButton}
                            disabled={deleting}
                            loading={deleting}
                          >
                            <FormattedMessage
                              defaultMessage="Delete Activity"
                              description="Delete button title on Create/Edit Activity modal"
                              id="editActivityModal.footer.deleteActivity"
                            />
                          </Button>
                        </PopConfirm>
                      )}
                    </div>

                    <SubmitButton
                      buttonTitle={intl.formatMessage({
                        id: "editActivityModal.footer.saveChanges",
                        defaultMessage: "Save Changes",
                        description: "Save Changes button title on Edit Activity modal",
                      })}
                      disabled={submitButtonDisabled}
                      form={form}
                      isActivityPublic={isActivityPublic}
                      isAssigneeNotCreator={isAssigneeNotCreator}
                      isRecurChanged={recurChanged}
                      key="submit"
                      loading={initializing || updating}
                      onCreate={handleClickOk}
                      saveOptions={options}
                      showCheckInSubmit={showCheckInSubmit}
                    />
                    {contextHolder}
                  </div>

                  <Checkbox
                    checked={shouldCreateAnother}
                    key="createAnother"
                    onChange={handleCreateAnotherChange}
                  >
                    {intl.formatMessage({
                      id: "editActivityModal.footer.createAnother.title",
                      defaultMessage: "Create another after saving",
                      description: "Create another label on Create Activity modal",
                    })}
                  </Checkbox>
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <div className={styles.content}>
            <FormNoLayoutAlert />
          </div>
        )}
      </BaseModal>
    </AnalyticsContext.Provider>
  );
};

export const mapStateToPropsEditActivityModal = (state: RootState) => ({
  activity: getEditedActivity(state),
  activityCompleted: isActivityComplete(state),
  activityTypes: getActivityTypes(state),
  associations: getAssociationsState(state),
  currentUser: getCurrentUser(state)!,
  defaultCompletion:
    getOrganizationSettingValue(state)(OrganizationSetting.ACTIVITY_COMPLETION_STATUS) !==
    ActivityCompletion.INCOMPLETE,
  defaultVisibility: ActivityVisibility.PRIVATE as ActivityVisibility,
  deleting: isDeleting(state),
  focusedFieldName: getFocusedFieldName(state),
  initializing: isInitializing(state),
  me: getMe(state)!,
  noAccess: hasNoAccess(state),
  teams: getTeams(state),
  updating: isUpdating(state),
  uploadedFiles: getUploadedFilesList(state),
  users: getUsersOfEntireOrganization(state),
});

export const mapDispatchToPropsEditActivityModal = {
  addUserRecent,
  clearActivity,
  clearAllUploadedFiles: clearAllUploadedCreateEditActivityFiles,
  clearAssociations: clearAssociations,
  deleteActivity: deleteActivity.request,
  initialize: initializeEditActivityModal.request,
  onChangeAssociatedEntities: changeAssociatedEntities.request,
  updateActivity: updateActivity.request,
};

export default connect(
  mapStateToPropsEditActivityModal,
  mapDispatchToPropsEditActivityModal
)(EditActivityModal);

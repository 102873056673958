import React from "react";
import MmcNotification from "types/MmcNotification";
import MmcNotificationAction from "enum/MmcNotificationAction";
import styles from "../NotificationCommon.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd/es/grid";
import layout from "styles/layout";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import LongContentParagraph from "component/typography/LongContentParagraph";
import MultilineText from "component/typography/MultilineText";
import Lexer from "component/Lexer";
import { useIntl } from "react-intl";
import User from "@mapmycustomers/shared/types/User";
import { RootState } from "store/rootReducer";
import { getUsers } from "store/members";
import { connect } from "react-redux";

interface Props {
  notification: MmcNotification;
  users: User[];
}

const Title: React.FC<Props> = ({
  notification: { action, note, entity, group, route },
  users,
}) => {
  const intl = useIntl();
  const isPlural = entity && entity.length > 1;
  const entityName = entity && entity[0] ? entity[0].name : "";
  let text: React.ReactNode = entityName;

  if (action === MmcNotificationAction.ACTIVITY_LOGGED) {
    text = (
      <Row gutter={layout.spacerS}>
        <Col>
          <FontAwesomeIcon icon={faCalendarCheck} />
        </Col>
        <Col>{entityName}</Col>
      </Row>
    );
  } else if ([MmcNotificationAction.MENTION, MmcNotificationAction.NOTE_ADDED].includes(action)) {
    text = (
      <LongContentParagraph className={styles.mentionWrapper} noMargin>
        <MultilineText
          wrapLinesWith={Lexer}
          wrapperProps={{ mentionClassName: styles.mention, users }}
        >
          {note?.note ?? ""}
        </MultilineText>
      </LongContentParagraph>
    );
  } else if (
    [
      MmcNotificationAction.DEAL_GROUP_SHARED,
      MmcNotificationAction.PEOPLE_GROUP_SHARED,
      MmcNotificationAction.COMPANY_GROUP_SHARED,
    ].includes(action)
  ) {
    text = group?.name ?? "";
  } else if (
    [
      MmcNotificationAction.COMPANY_ROUTE_SHARED,
      MmcNotificationAction.PEOPLE_ROUTE_SHARED,
    ].includes(action)
  ) {
    text = route?.name ?? "";
  } else if (action === MmcNotificationAction.OUT_OF_CADENCE && isPlural) {
    text = intl.formatMessage({
      defaultMessage: "See records that are past due",
      description: "Navbar Notifications - list - notification - See records out of cadence",
      id: "navbar.notifications.list.notification.title.seeRecordsOutOfCadence",
    });
  } else if (action === MmcNotificationAction.OVERDUE_ACTIVITIES && isPlural) {
    text = intl.formatMessage({
      defaultMessage: "See overdue activities",
      description: "Navbar Notifications - list - notification - See overdue activities",
      id: "navbar.notifications.list.notification.title.seeOverdueActivities",
    });
  } else if (action === MmcNotificationAction.DEALS_ROTTING && isPlural) {
    text = intl.formatMessage({
      defaultMessage: "See rotting deals",
      description: "Navbar Notifications - list - notification - See rotting deals",
      id: "navbar.notifications.list.notification.title.seeRottingDeals",
    });
  } else if (action === MmcNotificationAction.DEALS_MARKED_WON && isPlural) {
    text = intl.formatMessage({
      defaultMessage: "See all Closed Won Deals",
      description: "Navbar Notifications - list - notification - See all Closed Won Deals",
      id: "navbar.notifications.list.notification.title.seeAllClosedWonDeals",
    });
  } else if (action === MmcNotificationAction.DEALS_MARKED_LOST && isPlural) {
    text = intl.formatMessage({
      defaultMessage: "See all Closed Lost Deals",
      description: "Navbar Notifications - list - notification - See all Closed Lost Deals",
      id: "navbar.notifications.list.notification.title.seeAllClosedLostDeals",
    });
  } else if (action === MmcNotificationAction.DEALS_STAGE_CHANGED && isPlural) {
    text = intl.formatMessage({
      defaultMessage: "See all Deals",
      description: "Navbar Notifications - list - notification - See all Deals",
      id: "navbar.notifications.list.notification.title.seeAllDeals",
    });
  } else if (action === MmcNotificationAction.MARK_ACTIVITY_AS_DONE) {
    text = intl.formatMessage({
      defaultMessage: 'Open to mark as "Done"',
      description: "Navbar Notifications - list - notification - Open to mark as Done",
      id: "navbar.notifications.list.notification.title.mark_activity_as_done",
    });
  } else if (
    [
      MmcNotificationAction.OWNED_DEALS_CLOSING,
      MmcNotificationAction.SHARED_DEALS_CLOSING,
    ].includes(action) &&
    isPlural
  ) {
    text = intl.formatMessage({
      defaultMessage: "See Deals",
      description: "Navbar Notifications - list - notification  - See Deals",
      id: "navbar.notifications.list.notification.title.seeDeals",
    });
  } else if (MmcNotificationAction.INCOMPLETE_ACTIVITY === action) {
    text = intl.formatMessage({
      defaultMessage: 'Open to mark as "Done"',
      description: "Navbar Notifications - list - notification  - Open to mark as Done",
      id: "navbar.notifications.list.notification.title.openToMarkItAsComplete",
    });
  }
  return <div className={styles.title}>{text}</div>;
};

const mapStateToProps = (state: RootState) => ({
  users: getUsers(state),
});

export default connect(mapStateToProps)(Title);

import React from "react";
import cn from "classnames";
import Tag, { TagProps } from "antd/es/tag";
import colors from "styles/_colors.module.scss";
import Named from "@mapmycustomers/shared/types/base/Named";
import Colored from "@mapmycustomers/shared/types/base/Colored";
import anyColorToHex from "util/colors/anyColorToHex";
import getSemiTransparentColor from "util/colors/getSemiTransparentColor";
import getContrastColor from "util/colors/getContrastColor";
import styles from "./EntityTag.module.scss";

interface Props extends Omit<TagProps, "style"> {
  children?: React.ReactNode;
  className?: string;
  entity: Colored & Named;
  textColor?: string;
}

const EntityTag: React.FC<Props> = ({ children, className, entity, textColor, ...props }) => {
  const backgroundColor = entity.color ? getSemiTransparentColor(entity.color) : colors.white;
  const borderColor = entity.color ? anyColorToHex(entity.color) : colors.slate;

  const color = textColor
    ? textColor
    : getContrastColor(backgroundColor) === "black"
    ? colors.slate
    : borderColor;

  return (
    <Tag
      className={cn(styles.container, className)}
      style={{ backgroundColor, borderColor, color }}
      {...props}
    >
      {children ?? entity.name}
    </Tag>
  );
};

export default EntityTag;

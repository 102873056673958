import React from "react";
import anyColorToHex from "util/colors/anyColorToHex";
import colors from "styles/_colors.module.scss";
import Named from "@mapmycustomers/shared/types/base/Named";
import Colored from "@mapmycustomers/shared/types/base/Colored";
import Badge from "antd/es/badge";

interface Props {
  className?: string;
  entity: Colored & Named;
  hideName?: boolean;
  textColor?: string;
}

const EntityBadge: React.FC<Props> = ({ className, entity, hideName, textColor }) => {
  const color = textColor ? textColor : entity.color ? anyColorToHex(entity.color) : colors.slate;

  return <Badge className={className} color={color} text={hideName ? "" : entity.name} />;
};

export default EntityBadge;

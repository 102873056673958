import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  createColorPinLegend,
  createShapePinLegend,
  deletePinLegend,
  fetchPinLegends,
  updateColorPinLegend,
  updateShapePinLegend,
} from "store/pinLegends/actions";
import { handleError } from "store/errors/actions";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import PinLegend, {
  ColorPinLegend,
  ShapePinLegend,
} from "@mapmycustomers/shared/types/map/PinLegend";
import { callApi } from "store/api/callApi";
import { getOrganizationId } from "store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";

export function* onFetchPinLegends() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<PinLegend> = yield callApi("fetchPinLegends", orgId);

    yield put(fetchPinLegends.success(response.data));
  } catch (error) {
    yield put(fetchPinLegends.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onCreateColorPinLegend({
  payload: { payload, callback },
}: ReturnType<typeof createColorPinLegend.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const pinLegend: ColorPinLegend = yield callApi("createColorPinLegend", orgId, payload);
    if (callback) {
      yield call(callback, pinLegend);
    }
    yield put(createColorPinLegend.success(pinLegend));
  } catch (error) {
    yield put(createColorPinLegend.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateColorPinLegend({
  payload: { payload, callback },
}: ReturnType<typeof updateColorPinLegend.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const pinLegend: ColorPinLegend = yield callApi("updateColorPinLegend", orgId, payload);
    if (callback) {
      yield call(callback, pinLegend);
    }
    yield put(updateColorPinLegend.success(pinLegend));
  } catch (error) {
    yield put(updateColorPinLegend.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onCreateShapePinLegend({
  payload: { payload, callback },
}: ReturnType<typeof createShapePinLegend.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const pinLegend: ShapePinLegend = yield callApi("createShapePinLegend", orgId, payload);
    if (callback) {
      yield call(callback, pinLegend);
    }
    yield put(createShapePinLegend.success(pinLegend));
  } catch (error) {
    yield put(createShapePinLegend.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateShapePinLegend({
  payload: { payload, callback },
}: ReturnType<typeof updateShapePinLegend.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const pinLegend: ShapePinLegend = yield callApi("updateShapePinLegend", orgId, payload);
    if (callback) {
      yield call(callback, pinLegend);
    }
    yield put(updateShapePinLegend.success(pinLegend));
  } catch (error) {
    yield put(updateShapePinLegend.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onDeleteColorPinLegend({
  payload: { pinLegendId, callback },
}: ReturnType<typeof deletePinLegend.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("deletePinLegend", orgId, pinLegendId);
    if (callback) {
      yield call(callback);
    }
    yield put(deletePinLegend.success(pinLegendId));
  } catch (error) {
    yield put(deletePinLegend.failure(error));
    yield put(handleError({ error }));
  }
}

export function* pinLegendsSagas() {
  yield takeLatest(fetchPinLegends.request, onFetchPinLegends);
  yield takeEvery(createColorPinLegend.request, onCreateColorPinLegend);
  yield takeEvery(updateColorPinLegend.request, onUpdateColorPinLegend);
  yield takeEvery(createShapePinLegend.request, onCreateShapePinLegend);
  yield takeEvery(updateShapePinLegend.request, onUpdateShapePinLegend);
  yield takeEvery(deletePinLegend.request, onDeleteColorPinLegend);
}

import React from "react";
import { UserRef } from "@mapmycustomers/shared/types/User";
import Avatar from "./index";
import { getUserDisplayNameWithInfo } from "./util/getUserDisplayNameWithInfo";
import cn from "classnames";
import Tooltip from "antd/es/tooltip";
import styles from "./AvatarCommon.module.scss";
import { useConfigProvider } from "../ConfigProvider";

interface Props {
  avatarClassName?: string;
  className?: string;
  size?: "s" | "xs";
  user: UserRef;
}

const AvatarWithName: React.FC<Props> = ({
  avatarClassName,
  className,
  size,
  user,
}) => {
  const configProvider = useConfigProvider();

  return (
    <div
      className={cn(
        styles.container,
        { [styles.deleted]: !!user.deletedAt || user.deactivated },
        styles[`size_${size}`],
        className
      )}
    >
      <Avatar
        className={cn(styles.avatar, avatarClassName)}
        tooltip
        user={user}
      />
      <Tooltip title={getUserDisplayNameWithInfo(configProvider, user)}>
        <span className={styles.name}>
          {getUserDisplayNameWithInfo(configProvider, user)}
        </span>
      </Tooltip>
    </div>
  );
};

export default AvatarWithName;

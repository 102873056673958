import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import Modal from "component/modal";
import layout from "../../../styles/layout";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Activities from "./component/Activities";
import Frequencies from "./component/Frequencies";
import { RootState } from "store/rootReducer";
import { getFrequencyModalAnalyticsPath, getFrequencyModalConfig } from "store/frequency";
import { hideFrequencyModal, initFrequencyModal } from "store/frequency/actions";
import { connect } from "react-redux";
import EntityBlock from "../EntityBlock";
import FrequencyEntity from "../type/FrequencyEntity";
import { subscribe, unsubscribe } from "store/uiSync/actions";
import { useExtendedAnalytics } from "util/analytic/AnalyticsService";
import AnalyticsContext from "util/contexts/AnalyticsContext";
import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";
import styles from "./FrequencyModal.module.scss";

interface Props {
  analyticsPath?: string[];
  entity: FrequencyEntity;
  onHideFrequencyModal: typeof hideFrequencyModal;
  onInitFrequencyModal: typeof initFrequencyModal.request;
  subscribe: typeof subscribe;
  unsubscribe: typeof unsubscribe;
}

const FrequencyModal: React.FC<Props> = ({
  analyticsPath,
  entity,
  onHideFrequencyModal,
  onInitFrequencyModal,
  subscribe,
  unsubscribe,
}) => {
  const intl = useIntl();

  const analytics = useExtendedAnalytics([...(analyticsPath ?? []), "Manage Frequencies"]);

  useEffect(() => {
    analytics.clicked([]);
  }, [analytics]);

  useEffect(() => {
    const listener = {
      onUpdate: () => onInitFrequencyModal({ entity }),
    };

    subscribe({ entityTypes: [entity.entity], listener });

    return () => {
      unsubscribe({ entityTypes: [entity.entity], listener });
    };
  }, [entity, onInitFrequencyModal, subscribe, unsubscribe]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      <Modal
        className={styles.container}
        footer={null}
        modalStyle="fancy"
        onCancel={() => onHideFrequencyModal()}
        title={
          <Row gutter={layout.spacerXS}>
            <Col>
              {intl.formatMessage({
                id: "frequency.frequencyModal.title",
                defaultMessage: "Manage Frequencies",
                description: "frequency component - frequency modal - title",
              })}
            </Col>
            <Col>
              <EntityBlock entity={entity} />
            </Col>
          </Row>
        }
        type={
          entity.cadenceStatus === FrequencyStatus.PAST_DUE
            ? "error"
            : entity.cadenceStatus === FrequencyStatus.UPCOMING
            ? "warning"
            : entity.cadenceStatus === FrequencyStatus.UP_TO_DATE
            ? "success"
            : "disabled"
        }
        visible
        width="1024px"
      >
        <Row gutter={layout.spacerM}>
          <Col span={14}>
            <Activities />
          </Col>
          <Col span={10}>
            <Frequencies />
          </Col>
        </Row>
      </Modal>
    </AnalyticsContext.Provider>
  );
};

const mapStateToProps = (state: RootState) => ({
  analyticsPath: getFrequencyModalAnalyticsPath(state),
  entity: getFrequencyModalConfig(state).entity,
});

const mapDispatchToProps = {
  onHideFrequencyModal: hideFrequencyModal,
  onInitFrequencyModal: initFrequencyModal.request,
  subscribe,
  unsubscribe,
};

export default connect(mapStateToProps, mapDispatchToProps)(FrequencyModal);

import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import FeaturedMap, { Feature } from "component/map/FeaturedMap";
import defaultIdGetter from "util/defaultIdGetter";
import styles from "./LocationMiniMap.module.scss";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import Located from "@mapmycustomers/shared/types/base/Located";
import Colored from "@mapmycustomers/shared/types/base/Colored";
import { EntityTypesWithLocation } from "@mapmycustomers/shared/types/entity";
import MarkerShape from "@mapmycustomers/shared/enum/MarkerShape";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { v4 as uuidv4 } from "uuid";

export interface LocationMiniMapEntity extends Identified, Colored, Located {
  entity?: EntityTypesWithLocation;
  shape?: MarkerShape;
}

interface Props {
  before?: ReactNode;
  children?: ReactElement<{ map: google.maps.Map }>;
  className?: string;
  coordinates: LongLat;
  mapOptions?: google.maps.MapOptions;
  onMouseUp?: (item: Identified, event: google.maps.MapMouseEvent) => void;
  styleGetter?: () => google.maps.Data.StyleOptions;
}

const isDifferent = (a: Identified, b: Identified) => a.id !== b.id;

const LocationMiniMap: React.FC<Props> = ({
  before,
  children,
  className,
  mapOptions,
  onMouseUp,
  coordinates,
  styleGetter,
}) => {
  const coordinatesPair = useMemo(
    () => ({
      lat: coordinates[1],
      lng: coordinates[0],
    }),
    [coordinates]
  );
  const geometryGetter = useCallback(
    () => new google.maps.Data.Point(coordinatesPair),
    [coordinatesPair]
  );

  const items: Identified[] = useMemo(
    () => [{ id: parseInt(uuidv4()) }],
    // Need to generate a new id each time when we provide new coordinates
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coordinates]
  );

  const [map, setMap] = useState<google.maps.Map | undefined>();

  let element = children ? React.Children.only(children) : null;
  if (element && map) {
    element = React.cloneElement(element, { map });
  }
  useEffect(() => {
    map?.setCenter(coordinatesPair);
  }, [coordinatesPair, map]);

  return (
    <section className={cn(styles.container, className)}>
      {before}

      <FeaturedMap
        className={styles.mapContainer}
        initialOptions={{
          center: coordinatesPair,
          clickableIcons: false,
          controlSize: 32,
          disableDefaultUI: true,
          gestureHandling: "cooperative",
          zoom: 16,
          ...mapOptions,
        }}
        mapClassName={styles.map}
        onInitialized={setMap}
      >
        <Feature<Identified>
          geometryGetter={geometryGetter}
          idGetter={defaultIdGetter}
          isDifferent={isDifferent}
          items={items}
          onMouseUp={onMouseUp}
          styleGetter={styleGetter}
          type="activities-pins"
        />
      </FeaturedMap>
      {element}
    </section>
  );
};

export default LocationMiniMap;

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";
import TextType from "@mapmycustomers/shared/enum/textEditor/TextType";
import TextStyleType from "@mapmycustomers/shared/enum/textEditor/TextStyleType";

export const LIST_TYPES: ElementType[] = [
  ElementType.NUMBERED_LIST,
  ElementType.BULLETED_LIST,
];

export const REMOVABLE_TEXT_TYPES: string[] = [
  TextStyleType.COLOR,
  TextStyleType.FONT,
  TextType.BOLD,
  TextType.UNDERLINE,
  TextType.ITALIC,
  TextType.STRIKE,
];

export const INDENT_MAXIMUM = 3;

export const SUPPORTED_FILE_EXTENSIONS = ["image/jpeg", "image/png"];

import { call, select, takeLatest } from "redux-saga/effects";
import { fetchAnnotationData } from "./actions";
import { getOrganizationId } from "store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import { callApi } from "store/api/callApi";
import { MapEntity } from "@mapmycustomers/shared/types/entity";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import { getMapViewSettings } from "store/map";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

function* getFetchPayload(entityType: MapEntity["entity"], entityId: MapEntity["id"]) {
  const mapViewState: MapViewState = yield select(getMapViewSettings);
  return {
    $filters: {
      pinLegends: {
        [entityType]: {
          color: mapViewState.colorKey![entityType],
          shape: mapViewState.shapeKey![entityType],
        },
      },
      entities: {
        [entityType]: {
          includeGroups: true,
          includeTerritories: true,
          $and: [{ id: entityId }],
        },
      },
      cadence: true,
      includeUsersWithAccess: true,
      includeAccessStatus: true,
      includeCustomFields: true,
    },
    $limit: 1,
  };
}

export function* onFetchAnnotationPreview({
  payload: { entityType, entityId, callback, failureCallback },
}: ReturnType<typeof fetchAnnotationData>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<MapEntity> = yield callApi(
      "fetchPins",
      orgId,
      yield call(getFetchPayload, entityType, entityId)
    );
    yield call(callback, response.data?.[0]);
  } catch (error) {
    yield call(failureCallback);
  }
}

export function* entityAnnotationSagas() {
  yield takeLatest(fetchAnnotationData, onFetchAnnotationPreview);
}

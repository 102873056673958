import Button from "antd/es/button";
import { IntlShape } from "react-intl";
import styles from "./getSuccessNotificationNode.module.scss";

const getSuccessNotificationNode = (
  intl: IntlShape | undefined,
  message: string,
  onViewClick?: () => void
) => (
  <div className={styles.container}>
    <span>{message}</span>
    {onViewClick && (
      <Button onClick={onViewClick}>
        {intl?.formatMessage({
          id: "createEntity.notification.success.link",
          defaultMessage: "View",
          description: "Link for any creating entity modal to view result",
        }) ?? "View"}
      </Button>
    )}
  </div>
);

export default getSuccessNotificationNode;

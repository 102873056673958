import React, { ReactNode, useEffect } from "react";
import Button, { ButtonProps } from "antd/es/button";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import ButtonType from "enum/ButtonType";
import styles from "./RoundButton.module.scss";
import loggingService from "util/logging";

interface Props extends Omit<ButtonProps, "type"> {
  active?: boolean;
  bordered?: boolean;
  children?: ReactNode;
  className?: string;
  hasMenu?: boolean;
  leftIcon?: ReactNode;
  type?: ButtonType;
  rightIcon?: ReactNode;
}

const RoundButton: React.FC<Props> = ({
  active,
  bordered = true,
  children,
  className,
  hasMenu,
  leftIcon,
  type = ButtonType.PRIMARY,
  rightIcon,
  ...antDButtonProps
}) => {
  useEffect(() => {
    if (rightIcon && hasMenu) {
      loggingService.warning(
        "Both rightIcon and hasMenu props were specified. " +
          "It is recommended to specify just one. Prop 'rightIcon' will always take precedence."
      );
    }
  }, [rightIcon, hasMenu]);
  return (
    <Button
      {...antDButtonProps}
      className={cn(
        styles.container,
        styles[type],
        { [styles.active]: active, [styles.bordered]: bordered },
        className
      )}
      shape="round"
    >
      {leftIcon}
      {children}
      {rightIcon}
      {!rightIcon && hasMenu && <FontAwesomeIcon icon={faAngleDown} />}
    </Button>
  );
};

export default RoundButton;

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import Company from "@mapmycustomers/shared/types/entity/Company";
import { ListFetcherPayload } from "types/viewModel/ListFetcher";
import ListRequest from "@mapmycustomers/shared/types/viewModel/internalModel/ListRequest";
import ColumnModel from "@mapmycustomers/shared/types/viewModel/internalModel/ColumnModel";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

export const initializeListView = createAsyncAction(
  "companyList/initialize/request",
  "companyList/initialize/success",
  "companyList/initialize/failure"
)<void, void, unknown>();

export const fetchList = createAsyncAction(
  "companyList/fetch/request",
  "companyList/fetch/success",
  "companyList/fetch/failure"
)<ListFetcherPayload<Company>, { totalFilteredRecords: number; totalRecords: number }, unknown>();

export const downloadList = createAction("companyList/downloadList")<{
  columns: ColumnModel<IField>;
}>();

export const applyListViewSettings = createAction("companyList/applyListViewSettings")<
  Partial<ListRequest>
>();

export const setOfferedFilters = createAction("companyList/setOfferedFilter")<
  Partial<FilterModel> | undefined
>();

export type Actions = ActionType<
  | typeof applyListViewSettings
  | typeof downloadList
  | typeof fetchList
  | typeof initializeListView
  | typeof setOfferedFilters
>;

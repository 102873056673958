import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { MultiPin } from "types/map";
import RangeModel from "@mapmycustomers/shared/types/viewModel/internalModel/RangeModel";
import SortModel from "@mapmycustomers/shared/types/viewModel/internalModel/SortModel";
import { AreaSearchQuery } from "types/filters/AreaSearchQuery";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import {
  EntitiesSupportingMultiPin,
  EntityTypesSupportingMultiPin,
} from "@mapmycustomers/shared/types/map/types";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

export const showMultiPinRecords = createAsyncAction(
  "@dashboard/showMultiPinRecords/request",
  "@dashboard/showMultiPinRecords/success",
  "@dashboard/showMultiPinRecords/failure"
)<
  {
    multiPin: MultiPin;
    platformFilterModel?: PlatformFilterModel;
    visibleEntities: EntityTypesSupportingMultiPin[];
  },
  {
    address?: string;
  },
  unknown
>();

export const hideMultiPinRecords = createAction("@dashboard/hideMultiPinRecords")<void>();

export const fetchMultiPinRecords = createAsyncAction(
  "@dashboard/fetchMultiPinRecords/request",
  "@dashboard/fetchMultiPinRecords/success",
  "@dashboard/fetchMultiPinRecords/failure"
)<
  {
    request: {
      range?: RangeModel;
      search?: string;
    };
  },
  ListResponse<EntitiesSupportingMultiPin>,
  unknown
>();

export const applyRecordsListSettings = createAction("@dashboard/applyMultiPinRecordsSettings")<{
  sort?: SortModel;
}>();

export const updateMultiPinRecordsSorting = createAction(
  "@dashboard/updateMultiPinRecordsSorting"
)<{
  sort: SortModel;
  query?: AreaSearchQuery;
}>();

export const downloadMultiPinRecords = createAction("@dashboard/downloadMultiPinRecords")<{}>();

export type MultiPinRecordsActions = ActionType<
  | typeof showMultiPinRecords
  | typeof hideMultiPinRecords
  | typeof fetchMultiPinRecords
  | typeof applyRecordsListSettings
  | typeof updateMultiPinRecordsSorting
  | typeof downloadMultiPinRecords
>;

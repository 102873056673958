import { endOfDay, parseISO } from "date-fns/esm";
import DateRange from "../types/DateRange";
import isValidDate from "../../../util/isValidDate";
import dateRangeTypeToDateRange from "./dateRangeTypeToDateRange";
import DateRangeType from "../enums/DateRangeType";

const rangeToDateRange = (range?: string): DateRange | undefined => {
  const dateRange = dateRangeTypeToDateRange(range as DateRangeType);
  if (dateRange) {
    return dateRange;
  }

  if (range) {
    let dates = range.split("_");
    if (dates.length) {
      const range = {
        startDate: parseISO(dates[0]),
        endDate: parseISO(dates[1]),
      };
      if (isValidDate(range.startDate) && isValidDate(range.endDate)) {
        return { ...range, endDate: endOfDay(range.endDate) };
      }
    }
  }
};

export default rangeToDateRange;

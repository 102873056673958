import { RootState } from "../rootReducer";
import { Actions, changeTheme } from "./actions";
import { createReducer } from "typesafe-actions";
import { createSelector } from "reselect";
import Theme from "../../enum/Theme";
import localSettings from "../../config/LocalSettings";

export type ThemeState = {
  anyThemeLoaded: boolean; // to avoid FOUC issues: https://en.wikipedia.org/wiki/Flash_of_unstyled_content
  loading: boolean;
  theme: Theme;
};

const initialState: ThemeState = {
  anyThemeLoaded: false,
  loading: false,
  theme: localSettings.getTheme(),
};

const theme = createReducer<ThemeState, Actions>(initialState)
  .handleAction(changeTheme.request, (state) => ({ ...state, loading: true }))
  .handleAction(changeTheme.success, (state, action) => ({
    ...state,
    anyThemeLoaded: true,
    loading: false,
    theme: action.payload,
  }));

const themeState = (state: RootState) => state.theme;
export const isAnyThemeLoaded = createSelector(themeState, ({ anyThemeLoaded }) => anyThemeLoaded);
export const getLoading = createSelector(themeState, ({ loading }) => loading);
export const getTheme = createSelector(themeState, ({ theme }) => theme);

export type ThemeActions = Actions;
export default theme;

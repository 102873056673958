import MarkerSize from "@mapmycustomers/shared/enum/MarkerSize";
import { EntityPin } from "types/map";
import { EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";
import MarkerShape from "@mapmycustomers/shared/enum/MarkerShape";
import MarkerEffect from "enum/MarkerEffect";
import ColorDefinition from "./types/ColorDefinition";
import defaultColorGetter from "./defaultColorGetter";
import getSvgForShape from "./getSvgForShape";
import { AdditionalMarkerIcons, default as getSvgForIcon } from "./getSvgForIcon";
import svgToDataUrl from "util/map/svgToDataUrl";
import { getScaledSizeByMarker } from "./getScaledSize";
import isFunction from "lodash-es/isFunction";
import getSvgForBadge from "util/badge/getSvgForBadge";
import getResultingSvg from "./getResultingSvg";
import getSvgEffect from "./getSvgEffect";
import AlertBadgesType from "enum/map/AlertBadgesType";
import Size from "types/map/Size";
import { PIN_ZINDEX } from "util/map/consts";

interface StyleArguments {
  areBadgesVisible?: boolean;
  colorGetter?: (entityPin: EntityPin) => ColorDefinition;
  draggable?: boolean | ((entityPin: EntityPin) => boolean);
  effect?: MarkerEffect | ((entityPin: EntityPin) => MarkerEffect);
  icon?: (
    entityPin: EntityPin
  ) => EntityTypesSupportedByMapsPage | AdditionalMarkerIcons | undefined;
  markerSize: MarkerSize;
  shape: MarkerShape | ((entityPin: EntityPin) => MarkerShape);
  showEntityIcon?: boolean;
  showMultiPinIcon?: boolean;
  strokeColorGetter?: (entityPin: EntityPin) => string | undefined;
  visibleBadges?: AlertBadgesType[];
  zIndex?: number;
}

const defaultEntityStyleGetter = ({
  areBadgesVisible,
  colorGetter = defaultColorGetter,
  draggable,
  effect = MarkerEffect.SHADOW,
  icon,
  markerSize,
  shape,
  showEntityIcon,
  showMultiPinIcon,
  strokeColorGetter,
  visibleBadges,
  zIndex = PIN_ZINDEX,
}: StyleArguments): ((item: EntityPin) => google.maps.Data.StyleOptions) => {
  return (entityPin: EntityPin) => {
    const colorDef = colorGetter(entityPin);

    const isDraggabble = isFunction(draggable) ? draggable(entityPin) : draggable;
    const markerEffect = isFunction(effect) ? effect(entityPin) : effect;
    const markerShape = isFunction(shape) ? shape(entityPin) : shape;
    const shapeStrokeColor = strokeColorGetter
      ? strokeColorGetter(entityPin) ?? colorDef.color
      : colorDef.color;

    const defaultIcon = showMultiPinIcon
      ? "multipin"
      : showEntityIcon
      ? entityPin.data.entity
      : undefined;
    const iconSvg = getSvgForIcon(
      markerShape,
      isFunction(icon) ? icon(entityPin) : defaultIcon,
      colorDef.color
    );
    const [badgeSvg] = areBadgesVisible
      ? getSvgForBadge(entityPin, visibleBadges)
      : ["", [0, 0] as Size];

    const { shapeSvg, shapeSize, shapeAnchor, badgeAnchor, badgeShapeIncreaser } = getSvgForShape(
      markerShape,
      colorDef.fillColor,
      shapeStrokeColor
    );
    const shapeEffect = getSvgEffect(markerEffect, shapeSize);

    const { svg, size, anchor } = getResultingSvg(
      shapeSvg,
      shapeSize,
      shapeAnchor,
      shapeEffect,
      iconSvg,
      badgeSvg,
      badgeAnchor,
      badgeShapeIncreaser
    );

    return {
      draggable: isDraggabble,
      icon: {
        url: svgToDataUrl(svg),
        scaledSize: new google.maps.Size(...getScaledSizeByMarker(size, markerSize)),
        anchor: new google.maps.Point(...getScaledSizeByMarker(anchor, markerSize)),
      },
      zIndex,
    };
  };
};

export default defaultEntityStyleGetter;

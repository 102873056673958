import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Field, { FieldProperties } from "./Field";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";

class UniqueIdField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      type: FieldType.STRING,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.DETAILS,
      ],
      ...data,
    });
  }

  getFormattedCsvValueFor(entity: unknown) {
    const formattedValue = super.getFormattedCsvValueFor(entity);
    return `'${formattedValue}`;
  }
}

export default UniqueIdField;

import React, { useMemo } from "react";
import ViewableFileItems from "./ViewableFileItems";
import NonViewableFileItems from "./NonViewableFileItems";
import { RawFile } from "@mapmycustomers/shared/types/File";
import isMediaFile from "component/FilePreview/isMediaFile";
import { useIntl } from "react-intl";
import Section from "component/preview/components/Section";
import partition from "lodash-es/partition";
import AccessStatus from "@mapmycustomers/shared/types/entity/common/AccessStatus";
import styles from "./FilesContent.module.scss";

interface Props {
  accessStatus?: AccessStatus;
  canDelete?: boolean;
  files: RawFile[];
  onDelete: (file: RawFile) => void;
  onDownload: (file: RawFile) => void;
  onFetchThumbnail?: (payload: { fileId: RawFile["id"]; callback: (blob: Blob) => void }) => void;
  size?: "default" | "small";
}

const FilesContent: React.FC<Props> = ({
  accessStatus,
  canDelete = true,
  files,
  onDelete,
  onDownload,
  onFetchThumbnail,
  size = "default",
}) => {
  const intl = useIntl();

  const [viewableFiles, nonViewableFiles] = useMemo(() => partition(files, isMediaFile), [files]);

  return (
    <>
      {viewableFiles.length > 0 && (
        <Section
          className={styles.section}
          headerClassName={styles.header}
          title={intl.formatMessage({
            id: "filesContent.files.section.media",
            defaultMessage: "MEDIA",
            description: "Media files section on files on record pane",
          })}
        >
          <ViewableFileItems
            accessStatus={accessStatus}
            canDelete={canDelete}
            files={viewableFiles}
            onDelete={onDelete}
            onDownload={onDownload}
            onFetchThumbnail={onFetchThumbnail}
            size={size}
          />
        </Section>
      )}
      {nonViewableFiles.length > 0 && (
        <Section
          className={styles.section}
          headerClassName={styles.header}
          title={intl.formatMessage({
            id: "filesContent.files.section.otherFiles",
            defaultMessage: "OTHER FILES",
            description: "Other files section on files on record pane",
          })}
        >
          <NonViewableFileItems
            accessStatus={accessStatus}
            canDelete={canDelete}
            nonViewableFiles={nonViewableFiles}
            onDelete={onDelete}
            onDownload={onDownload}
            size={size}
          />
        </Section>
      )}
    </>
  );
};

export default FilesContent;

import { PrimitiveType } from "../types/IConfigProviderContext";
import messages, { MessageKey } from "../messages";

// a very primitive message formatter, it doesn't support any tags
const defaultMessageFormatter = (
  messageId: MessageKey,
  values?: Record<string, PrimitiveType>
): string => {
  console.warn(
    `[ConfigProvider] Please provide translation for message [${messageId}], currently using a default one`
  );
  let message: string = messages[messageId];
  if (values) {
    message = message.replace(/{([^}]+)}/g, (_, p) =>
      p in values ? String(values[p]) : ""
    );
  }
  return message;
};

export default defaultMessageFormatter;

import { IntlShape } from "react-intl";
import { useMemo } from "react";
import { FormInstance } from "antd/es/form/hooks/useForm";
import { RuleObject } from "rc-field-form/lib/interface";
import LocationViewMode from "enum/LocationViewMode";

export const useAddressFieldValidationRules = (intl: IntlShape, form: FormInstance) =>
  useMemo(
    () => [
      {
        validator: (rule: RuleObject) => {
          const { latitude, locationViewMode, longitude, addressDetails } = form.getFieldsValue();

          switch (locationViewMode) {
            case LocationViewMode.ADDRESS:
              const hasAddress =
                addressDetails &&
                (addressDetails.address?.trim() ||
                  addressDetails.city?.trim() ||
                  addressDetails.country?.trim() ||
                  addressDetails.countryCode?.trim() ||
                  addressDetails.region?.trim() ||
                  addressDetails.regionCode?.trim() ||
                  addressDetails.postalCode?.trim());

              if (hasAddress) {
                return Promise.resolve();
              }

              return Promise.reject(
                intl.formatMessage({
                  id: "validation.address.message",
                  defaultMessage: "Please enter valid address",
                  description: "Validation message for coordinates fields - address",
                })
              );

            case LocationViewMode.COORDINATES:
              if (!isNaN(latitude) && !isNaN(longitude)) {
                const latitudeParsed = Math.abs(parseFloat(latitude));
                const longitudeParsed = Math.abs(parseFloat(longitude));
                if (latitudeParsed <= 90 && longitudeParsed <= 180) {
                  return Promise.resolve();
                }
              }
              return Promise.reject(
                intl.formatMessage({
                  id: "validation.coordinates.message",
                  defaultMessage: "Please enter valid coordinates",
                  description: "Validation message for coordinates fields - latitude and longitude",
                })
              );
          }

          return Promise.resolve();
        },
      },
    ],
    [intl, form]
  );

export const useAddressFieldInputValidationRules = (intl: IntlShape, form: FormInstance) =>
  useMemo(
    () => [
      {
        validator: (rule: RuleObject) => {
          const { latitude, locationViewMode, longitude } = form.getFieldsValue();
          let error = false;

          switch (locationViewMode) {
            case LocationViewMode.COORDINATES:
              if ((latitude && isNaN(latitude)) || (longitude && isNaN(longitude))) {
                error = true;
              } else if (!isNaN(latitude) && !isNaN(longitude)) {
                const latitudeParsed = Math.abs(parseFloat(latitude));
                const longitudeParsed = Math.abs(parseFloat(longitude));
                if (latitudeParsed <= 90 && longitudeParsed <= 180) {
                  return Promise.resolve();
                } else {
                  error = true;
                }
              }

              if (error) {
                return Promise.reject(
                  intl.formatMessage({
                    id: "validation.coordinates.message",
                    defaultMessage: "Please enter valid coordinates",
                    description:
                      "Validation message for coordinates fields - latitude and longitude",
                  })
                );
              }

              return Promise.resolve();
          }

          return Promise.resolve();
        },
      },
    ],
    [intl, form]
  );

import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const componentState = (state: RootState) => state.component.entityAssociations;

export const isLoading = createSelector(componentState, ({ loading }) => loading);
export const getFilteredCompanies = createSelector(componentState, ({ companies }) => companies);
export const getFilteredDeals = createSelector(componentState, ({ deals }) => deals);
export const getFilteredPeople = createSelector(componentState, ({ people }) => people);
export const getFilteredLocatedEntities = createSelector(componentState, ({ located }) => located);

import React from "react";
import styles from "./HelpText.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popover from "antd/es/popover";
import { faCircleQuestion } from "@fortawesome/pro-duotone-svg-icons/faCircleQuestion";

interface Props {
  info: React.ReactNode;
  text?: string;
}

const HelpText: React.FC<Props> = ({ text, info }) => (
  <Popover content={info} destroyTooltipOnHide placement="bottom">
    <div className={styles.container}>
      <FontAwesomeIcon className={styles.icon} icon={faCircleQuestion} />
      {text && <span className={styles.text}>{text}</span>}
    </div>
  </Popover>
);

export default HelpText;

import loggingService from "util/logging";

// precision for maps endpoint based on current zoom level
const precisionByZoomLevel: Record<number, number> = {
  0: 1,
  1: 2,
  2: 2,
  3: 4,
  4: 4,
  5: 6,
  6: 7,
  7: 8,
  8: 12,
  9: 13,
  10: 14,
  11: 15,
  12: 16,
  13: 17,
  14: 18,
  15: 19,
  16: 29,
  17: 29,
  18: 29,
  19: 29,
  20: 29,
  21: 29,
  22: 29,
  23: 29,
  24: 29,
  25: 29,
  26: 29,
  27: 29,
  28: 29,
  29: 29,
  30: 29,
};

const precisionByZoomLevelBig: Record<number, number> = {
  0: 1,
  1: 2,
  2: 2,
  3: 4,
  4: 4,
  5: 6,
  6: 7,
  7: 8,
  8: 9,
  9: 10,
  10: 10,
  11: 11,
  12: 12,
  13: 13,
  14: 14,
  15: 15,
  16: 19,
  17: 20,
  18: 29,
  19: 29,
  20: 29,
  21: 29,
  22: 29,
  23: 29,
  24: 29,
  25: 29,
  26: 29,
  27: 29,
  28: 29,
  29: 29,
  30: 29,
};

const getPrecision = (zoom: number, isBigOrganization: boolean): number => {
  const zoomLevels = isBigOrganization ? precisionByZoomLevelBig : precisionByZoomLevel;
  const result = zoomLevels[zoom] ?? zoom;
  loggingService.debug("getPrecision", { zoom, isBigOrganization, precision: result });
  return result;
};

export default getPrecision;

import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import {
  AreaCondition,
  Condition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";

export const isMetaField = (field: IField): boolean =>
  field.hasAnyFeature(
    FieldFeature.GROUP_FIELD,
    FieldFeature.GEO_POINT_FIELD,
    FieldFeature.ROUTE_FIELD,
    FieldFeature.TEAM_FIELD,
    FieldFeature.TERRITORY_FIELD
  );

export const isInRangeCondition = (condition: Condition) =>
  Object.keys(condition).length === 2 &&
  PlatformFilterOperator.GREATER_THAN_OR_EQUAL in condition &&
  PlatformFilterOperator.LESS_THAN_OR_EQUAL in condition;

export const isInAreaCondition = (
  condition: Condition | AreaCondition
): condition is AreaCondition =>
  Object.keys(condition).length >= 2 &&
  "radius" in condition &&
  ("point" in condition || "entity" in condition);

export const isEmptyCondition = (condition: Condition) =>
  Object.keys(condition).length === 1 && Object.values(condition)[0] === null;

// Legacy condition is the one which has more than one operator
// Technically range and area conditions are also legacy conditions, but we treat them differently
export const isLegacyCondition = (condition: Condition) => Object.keys(condition).length > 1;

export const isIntervalCondition = (condition: Condition) =>
  Object.keys(condition).length === 1 && PlatformFilterOperator.INTERVAL in condition;

import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";

const activityAnnotationState = (state: RootState) => state.component.activityAnnotation;

export const isAnnotationDataLoading = createSelector(
  activityAnnotationState,
  ({ loading }) => loading
);

export const hasNoAccess = createSelector(
  activityAnnotationState,
  ({ hasNoAccess }) => hasNoAccess
);

export const getAnnotationData = createSelector(
  activityAnnotationState,
  ({ annotationData }) => annotationData
);

export const isToggleCompleteLoading = createSelector(
  activityAnnotationState,
  ({ toggleCompleteLoading }) => toggleCompleteLoading
);

export const isPostponeLoading = createSelector(
  activityAnnotationState,
  ({ postponeLoading }) => postponeLoading
);

export const areFilesUploading = createSelector(
  activityAnnotationState,
  ({ filesUploading }) => filesUploading
);

export const getActivity = createSelector(
  activityAnnotationState,
  ({ annotationData }) => annotationData?.activity
);

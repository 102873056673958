import React from "react";
import { useIntl } from "react-intl";
import styles from "./FrequencyBlock.module.scss";
import FrequencyPreview from "../../../FrequencyPreview";
import FrequencyCollapsePanel from "../../../FrequencyCollapsePanel";
import { RootState } from "../../../../../store/rootReducer";
import {
  getFrequencyModalConfig,
  getLastFrequencyActivity,
  isFrequencyModalLoading,
} from "../../../../../store/frequency";
import { connect } from "react-redux";
import Activity from "@mapmycustomers/shared/types/entity/Activity";
import EntityBlock from "../../../EntityBlock";
import layout from "../../../../../styles/layout";
import Row from "antd/es/row";
import Col from "antd/es/col";
import FrequencyEntity from "../../../type/FrequencyEntity";
import useToggleBooleanAfterTimeout from "../../../../../util/hook/useToggleBooleanAfterTimeout";

interface Props {
  entity: FrequencyEntity;
  lastFrequencyActivity?: Activity;
  loading: boolean;
  onCancel: () => void;
}

const NewFrequency: React.FC<Props> = ({ entity, lastFrequencyActivity, loading, onCancel }) => {
  const intl = useIntl();
  const showLoading = useToggleBooleanAfterTimeout(1000, false, loading, true);

  return (
    <div className={styles.container}>
      <FrequencyCollapsePanel
        header={
          <Row align="middle" gutter={layout.spacerM}>
            <Col>
              {intl.formatMessage({
                id: "frequency.frequencyModal.frequencies.newFrequency.title",
                defaultMessage: "Specific to:",
                description:
                  "frequency component - frequency modal - frequencies - new frequency - title",
              })}
            </Col>
            <Col>
              <EntityBlock entity={entity} />
            </Col>
          </Row>
        }
        isOpenByDefault
      >
        <FrequencyPreview
          entity={entity}
          lastCompletedActivity={lastFrequencyActivity}
          loading={showLoading}
          onCancel={onCancel}
          showSaveButtonsOnlyAfterChanges
        />
      </FrequencyCollapsePanel>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  entity: getFrequencyModalConfig(state).entity,
  lastFrequencyActivity: getLastFrequencyActivity(state),
  loading: isFrequencyModalLoading(state),
});

export default connect(mapStateToProps)(NewFrequency);

import { Activity } from "@mapmycustomers/shared/types/entity";
import FormValues from "../types/FormValues";
import ActivityRecurringUnit from "@mapmycustomers/shared/enum/activity/ActivityRecurringUnit";

export const parseActivityInterval = (
  interval?: Activity["recurInterval"]
): { value: number; unit: ActivityRecurringUnit } | undefined => {
  const split = interval?.split(" ");
  if (!split) {
    return;
  }
  let canonicalUnit = ActivityRecurringUnit.DAY;
  [ActivityRecurringUnit.MONTH, ActivityRecurringUnit.WEEK, ActivityRecurringUnit.DAY].forEach(
    (unit) => {
      // converting "days" to "day", and so on
      if (split[1].startsWith(unit)) {
        canonicalUnit = unit;
      }
    }
  );
  return {
    value: parseInt(split[0]),
    unit: canonicalUnit,
  };
};

export const stringifyActivityInterval = (interval: FormValues["recurInterval"]) =>
  interval?.value && interval?.unit ? [interval.value, interval.unit].join(" ").trim() : undefined;

@import "../../../../node_modules/spinthatshit/src/loaders";
@import "../styles/colors";

:root {
  --color: #{$mmc-primary-100};
  --message-font-size: 0.9rem;
}

:global {
  body[class*="theme-"].theme {
    :local {
      .loader {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        position: relative;
        margin: auto;
        width: fit-content;
      }

      .loaderGlobal {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        justify-content: center;
      }

      .loaderInline {
        display: inline-flex;
      }

      .spinner {
        @include loader01(40px, var(--color), 6px);
      }

      .miniSpinner {
        @include loader01(16px, var(--color), 2px);
      }

      .microSpinner {
        @include loader01(10px, var(--color), 2px);
      }

      .message {
        font-size: var(--message-font-size);
        margin-top: calc(var(--message-font-size) / 2);
        color: var(--color);
        text-align: center;
      }
    }
  }
}

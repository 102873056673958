import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "auth.manageUserAccess.title",
    defaultMessage: "Manage User Access",
    description: "Manage User Access - title",
  },
  requestFrom: {
    id: "auth.manageUserAccess.requestFrom",
    defaultMessage: "This request is from:",
    description: "Manage User Access - request from",
  },
  requestType: {
    id: "auth.manageUserAccess.requestType",
    defaultMessage: "This user would like to:",
    description: "Manage User Access - request type",
  },
  request: {
    id: "auth.manageUserAccess.request",
    defaultMessage: "Join your organization via SSO",
    description: "Manage User Access - request",
  },
  requestedOn: {
    id: "auth.manageUserAccess.requestedOn",
    defaultMessage: "Requested on",
    description: "Manage User Access - requested on title",
  },
  disclamer: {
    id: "auth.manageUserAccess.disclamer",
    defaultMessage:
      "By approving, this user will receive access to Map My Customers. They won't be automatically assigned to a team, but they'll receive notification of their account creation and can sign in immediately. Approving this user will utilize one of your remaining {count} out of {total} {total, plural, one {license} other {licenses}}.",
    description: "Manage User Access - disclamer",
  },
  approve: {
    id: "auth.manageUserAccess.approve",
    defaultMessage: "Approve",
    description: "Manage User Access - approve button",
  },
  deny: {
    id: "auth.manageUserAccess.deny",
    defaultMessage: "Deny",
    description: "Manage User Access - deny button",
  },
  manageUsers: {
    id: "auth.manageUserAccess.manageUsers",
    defaultMessage: "Manage Users",
    description: "Manage User Access - manage users",
  },
  purchaseMoreSeats: {
    id: "auth.manageUserAccess.purchaseMoreSeats",
    defaultMessage: "Purchase more seats",
    description: "Manage User Access - Purchase more seats",
  },
  home: {
    id: "auth.manageUserAccess.home",
    defaultMessage: "Open Home",
    description: "Manage User Access - home button",
  },
  approvalSuccessTitle: {
    id: "auth.manageUserAccess.request.approved.title",
    defaultMessage: "User has been approved!",
    description: "Manage User Access - request approval title",
  },
  approvalSuccessMessage: {
    id: "auth.manageUserAccess.request.approved.message",
    defaultMessage:
      "They will shortly receive an email with instructions on how to get started in Map My Customers. Please add the user to the appropriate team.",
    description: "Manage User Access - request approval message",
  },
  requestDeniedTitle: {
    id: "auth.manageUserAccess.request.denied.title",
    defaultMessage: "User has been denied",
    description: "Manage User Access - request denied title",
  },
  requestDeniedMessage: {
    id: "auth.manageUserAccess.request.denied.message",
    defaultMessage: "This user has not been granted access to Map My Customers. ",
    description: "Manage User Access - request denied message",
  },
  seatsRemaining: {
    id: "auth.manageUserAccess.seatsRemaining",
    defaultMessage:
      "You {approved, select, true {now} other {still}} have {count} out of {total} {count, plural, one {seat} other {seats}} remaining",
    description: "Manage User Access - request denied message",
  },
  noSeatsRemainingTitle: {
    id: "auth.manageUserAccess.noSeatsRemaining.title",
    defaultMessage: "No more seats available",
    description: "Manage User Access - no seats remaining title",
  },
  noSeatsRemainingMessage: {
    id: "auth.manageUserAccess.noSeatsRemaining.message",
    defaultMessage:
      "This user requested access but since then you’ve run out of seats. Please contact support to add more.",
    description: "Manage User Access - no seats remaining message",
  },
  invalidLinkTitle: {
    id: "auth.manageUserAccess.invalidLink.title",
    defaultMessage: "Link no longer valid",
    description: "Manage User Access - Link no longer valid title",
  },
  invalidLinkMessage: {
    id: "auth.manageUserAccess.invalidLink.message",
    defaultMessage:
      "We only store pending SSO requests for 7 days. If user does not already exist, please manually add the user by following the button below and notify them to sign into SSO.",
    description: "Manage User Access - Link no longer valid message",
  },
});

export default messages;

import { shouldPolyfill as shouldPolyfillLocale } from "@formatjs/intl-locale/should-polyfill";
import { shouldPolyfill as shouldPolyfillCanonicalLocales } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { shouldPolyfill as shouldPolyfillPlurals } from "@formatjs/intl-pluralrules/should-polyfill";
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";

async function polyfill(locale?: string) {
  if (shouldPolyfillLocale()) {
    await import("@formatjs/intl-locale/polyfill");
  }

  if (shouldPolyfillCanonicalLocales()) {
    await import("@formatjs/intl-getcanonicallocales/polyfill");
  }

  if (shouldPolyfillPlurals()) {
    await import("@formatjs/intl-pluralrules/polyfill");
    // not supporting all locales really:
    // @ts-ignore
    await import("@formatjs/intl-pluralrules/locale-data/en");
  }

  polyfillCountryFlagEmojis();
}

export default polyfill;

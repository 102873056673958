import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "dataView.title",
    defaultMessage: "Data View",
    description: "Data View - title",
  },
  totalCount: {
    id: "dataView.totalCount",
    defaultMessage: "{count} {count, plural, one {Record} other {Records}} in Total",
    description: "Data View - total count",
  },
  entitiesCount: {
    id: "dataView.entitiesCount",
    defaultMessage: "{count} {entityName}",
    description: "Data View - entity count",
  },
  teamBreakdown: {
    id: "dataView.teamBreakdown",
    defaultMessage: "Team Breakdown",
    description: "Data View - Team Breakdown",
  },
  noTeam: {
    id: "dataView.noTeam",
    defaultMessage: "No team",
    description: "Data View - No team",
  },
  allUsers: {
    id: "dataView.allUsers",
    defaultMessage: "All Users",
    description: "Data View - All Users",
  },
  multipleTeams: {
    id: "dataView.multipleTeams",
    defaultMessage: "Multiple Teams",
    description: "Data View - Multiple Teams",
  },
  allTeams: {
    id: "dataView.allTeams",
    defaultMessage: "All Teams",
    description: "Data View - All Teams",
  },
  noUsers: {
    id: "dataView.noUsers",
    defaultMessage: "No Users Selected",
    description: "Data View - No Users Selected",
  },
  multipleUsersOrTeams: {
    id: "dataView.multipleUsersOrTeams",
    defaultMessage: "Multiple Users or Teams",
    description: "Data View - Multiple Users or Teams",
  },
  none: {
    id: "dataView.none",
    defaultMessage: "None",
    description: "Data View - None",
  },
  totalCountMayExceedNumber: {
    id: "dataView.totalCountMayExceedNumber",
    defaultMessage: "Total count may exceed number of records",
    description: "Data View - Total count may exceed number of records",
  },
  totalCountMayExceedNumberInfo: {
    id: "dataView.totalCountMayExceedNumber.info",
    defaultMessage:
      "{entityType, select, accounts {Companies} contacts {People} other {Deals}} may have multiple options selected for this field, so the total count may exceed the number of {entityType, select, accounts {Companies} contacts {People} other {Deals}} you have selected.",
    description: "Data View - Info of Total count may exceed number of records",
  },
  funnels: {
    id: "dataView.totalCountMayExceedNumber.funnels",
    defaultMessage: "Funnels",
    description: "Data View - Funnels",
  },
  showMore: {
    id: "dataView.totalCountMayExceedNumber.showMore",
    defaultMessage: "Show More",
    description: "Data View - Show More",
  },
  showLess: {
    id: "dataView.totalCountMayExceedNumber.showLess",
    defaultMessage: "Show Less",
    description: "Data View - Show Less",
  },
  viewPercent: {
    id: "dataView.totalCountMayExceedNumber.viewPercent",
    defaultMessage: "View Percent",
    description: "Data View - View Percent",
  },
  viewGross: {
    id: "dataView.totalCountMayExceedNumber.viewGross",
    defaultMessage: "View Gross",
    description: "Data View - View Gross",
  },
  export: {
    id: "dataView.totalCountMayExceedNumber.export",
    defaultMessage: "Export",
    description: "Data View - export",
  },
  changeDataSource: {
    id: "dataView.totalCountMayExceedNumber.changeDataSource",
    defaultMessage: "Change Data Source",
    description: "Data View - Change Data Source",
  },
  noData: {
    id: "dataView.noData",
    defaultMessage: "No Data",
    description: "Data View - No Data",
  },
  noDataDescription: {
    id: "dataView.noDataDescription",
    defaultMessage: "Please select different user(s) or another field",
    description: "Data View - Please select different user(s) or another field",
  },
  fileDownloaded: {
    id: "dataView.fileDownloaded",
    defaultMessage: "File Downloaded",
    description: "Data View - File Downloaded",
  },
  fileDownloadedError: {
    id: "dataView.fileDownloadedError",
    defaultMessage: "Download Error",
    description: "Data View - Download Error",
  },
  noEntitiesSelected: {
    id: "dataView.noEntitiesSelected",
    defaultMessage: "No {entityName} Selected",
    description: "Data View - No entities Selected",
  },
  tooltipTotal: {
    id: "dataView.tooltipTotal",
    defaultMessage: "Total",
    description: "Data View - chart tooltip total",
  },
  tooltipTotalPercentage: {
    id: "dataView.tooltipTotalPercentage",
    defaultMessage: "Total %",
    description: "Data View - chart tooltip total percentage",
  },
  tooltipAverageValue: {
    id: "dataView.tooltipAverageValue",
    defaultMessage: "Average Value",
    description: "Data View - chart tooltip average value",
  },
  tooltipAverageValuePercentage: {
    id: "dataView.tooltipAverageValuePercentage",
    defaultMessage: "Average Value %",
    description: "Data View - chart tooltip average percentage",
  },
  unknown: {
    id: "dataView.unknown",
    defaultMessage: "Unknown",
    description: "Data View - Unknown field",
  },
  records: {
    id: "dataView.records",
    defaultMessage: "Records",
    description: "Data View - Records",
  },
  territory: {
    id: "dataView.territory",
    defaultMessage: "Territory",
    description: "Data View - Territory",
  },
  noRecords: {
    id: "dataView.noRecords.title",
    defaultMessage: "No records in selected areas",
    description: "Data View - No records",
  },
  noRecordDescription: {
    id: "dataView.noRecords.description",
    defaultMessage: "If filters are applied, try removing them to display more pins on the map. ",
    description: "Data View - No records description",
  },
});

export default messages;

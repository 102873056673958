import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Field, { FieldProperties } from "./Field";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import { parseApiDate } from "util/parsers";
import { dateTimeFormatter } from "./fieldUtil";
import { get } from "lodash-es";
import { csvDateTimeFormatter } from "util/formatters";

const dateParser = (entity: unknown, field: IField) =>
  parseApiDate(get(entity, field.name) as string);

class DateTimeField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      type: FieldType.DATE_TIME,
      csvExportProperties: { valueFormatter: csvDateTimeFormatter },
      features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE],
      valueGetter: dateParser,
      valueFormatter: dateTimeFormatter,
      ...data,
    });
  }
}

export default DateTimeField;

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import Scope from "types/dashboard/Scope";
import CheckInsCardConfiguration from "types/dashboard/cards/checkIns/CheckInsCardConfiguration";
import CheckInsCardData from "scene/dashboard/store/cards/checkIns/CheckInsCardData";
import DrillDownViewState from "scene/dashboard/types/DrillDownViewState";
import { DrillDownListFetcherPayload } from "scene/dashboard/types/DrillDownListFetcher";
import { Activity } from "@mapmycustomers/shared/types/entity";
import DateRange from "@mapmycustomers/shared/types/range/DateRange";

export const fetchCheckInsCardData = createAction("@dashboard/fetchCheckInsCardData")<{
  callback: (data: CheckInsCardData) => void;
  configuration: CheckInsCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
}>();

export const showCheckInsDrillDown = createAsyncAction(
  "@dashboard/showCheckInsCardDrillDownModal/request",
  "@dashboard/showCheckInsCardDrillDownModal/success",
  "@dashboard/showCheckInsCardDrillDownModal/failure"
)<
  {
    configuration: CheckInsCardConfiguration;
    customDateRange?: DateRange;
    customReliability?: boolean;
    scope: Scope;
  },
  { viewState: DrillDownViewState },
  unknown
>();

export const hideCheckInsDrillDown = createAction(
  "@dashboard/hideCheckInsCardDrillDownModal"
)<void>();

export const fetchCheckInsCardDrillDownData = createAsyncAction(
  "@dashboard/fetchCheckInsCardDrillDownData/request",
  "@dashboard/fetchCheckInsCardDrillDownData/success",
  "@dashboard/fetchCheckInsCardDrillDownData/failure"
)<
  DrillDownListFetcherPayload<Activity>,
  {
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyCheckInsDrillDownListViewSettings = createAction(
  "@dashboard/applyCheckInsDrillDownListViewSettings"
)<Partial<DrillDownViewState>>();

export const exportCheckInsCardDrillDownData = createAction("@dashboard/exportCheckInsCardData")<{
  configuration: CheckInsCardConfiguration;
  scope: Scope;
}>();

export type CheckInsCardActions = ActionType<
  | typeof fetchCheckInsCardData
  | typeof showCheckInsDrillDown
  | typeof hideCheckInsDrillDown
  | typeof fetchCheckInsCardDrillDownData
  | typeof applyCheckInsDrillDownListViewSettings
  | typeof exportCheckInsCardDrillDownData
>;

import { ActionType, createAction } from "typesafe-actions";
import { Company } from "@mapmycustomers/shared/types/entity";
import ApiService from "store/api/ApiService";

// WARNING! Only use this action when you can't get company from the store
export const fetchCompany = createAction("@company/fetchCompany")<{
  id: Company["id"];
  options?: Parameters<ApiService["fetchCompany"]>[2];
  callback: (company: Company) => void;
  failureCallback?: () => void;
}>();

export const fetchCompanies = createAction("@company/fetchCompanies")<{
  ids: Company["id"][];
  callback: (companies: Company[]) => void;
  failureCallback?: () => void;
}>();

export type Actions = ActionType<typeof fetchCompany>;

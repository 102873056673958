import { AnyEntity } from "@mapmycustomers/shared/types/entity";
import ViewContext from "./ViewContext";
import { useContext } from "react";
import IViewContext from "../types/IViewContext";

const useView = <T = AnyEntity>(): IViewContext<T> => {
  return useContext(ViewContext) as unknown as IViewContext<T>;
};

export default useView;

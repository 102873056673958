import { defineMessages } from "react-intl";

const messages = defineMessages({
  findMeTooltip: {
    id: "createRouteForm.findMe.tooltip",
    defaultMessage: "Find my location",
    description: "Find my location button in a Create Route form",
  },
});

export default messages;

import { ActionType, isActionOf } from "typesafe-actions";
import { all, call, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { getCurrentUser, getOrganizationId, isBigOrganization } from "store/iam";
import { callApi } from "store/api/callApi";
import Organization from "@mapmycustomers/shared/types/Organization";
import {
  cleanLassoPaths,
  clearRecordsList,
  clearSelection,
  enterLassoMode,
  enterMode,
  exitMode,
  fetchGroup,
  fetchPins,
  resetRecordsListPagination,
  selectMapTool,
  setHighlight,
  setSelection,
  setSelectionEntities,
  showSidebar,
  updateLassoClusters,
  updateViewportState,
} from "scene/map/store/actions";
import {
  applyGroupFilters,
  applyGroupsMapViewSettings,
  bulkAddPinsToGroup,
  bulkRemovePinsFromGroup,
  downloadGroupRecords,
  enterGroupMode,
  exitGroupLassoMode,
  exitGroupMode,
  fetchDataViewEntities,
  fetchGroupLassoSelection,
  fetchGroupOtherPins,
  fetchGroupPins,
  fetchGroupRecords,
  initializeGroupsMode,
  setGroupShowOtherPins,
  updateGroup,
  updateGroupSharing,
} from "scene/map/store/groupMode/actions";
import localSettings from "config/LocalSettings";
import convertViewportToPersist from "scene/map/utils/convertViewportToPersist";
import {
  doesGroupEditFormHaveChanges,
  getCandidateGroup,
  getExcludedRecords,
  getGroupEntries,
  getGroupId,
  getGroupShowOtherPins,
  getIncludedRecords,
  getMapLassoPaths,
  getMapMode,
  getMapViewport,
  getMapViewTool,
  getMultiPin,
  getMultiPinGroupMode,
  getSelectedRecords,
  isSidebarVisible,
} from "scene/map/store/selectors";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import { convertToPlatformSortModel } from "util/viewModel/convertSort";
import categorizeMapEntries from "util/map/categorizeMapEnties";
import { getGroupEntityType, getGroupMapViewState } from "scene/map/store/groupMode/selectors";
import { push } from "connected-react-router";
import Path from "enum/Path";
import {
  EntitiesSupportingGroups,
  EntityTypesSupportedByMapsPage,
  EntityTypeSupportingGroups,
  Group,
  MapEntity,
} from "@mapmycustomers/shared/types/entity";
import { CategorizedMapEntries, MapEntry, MapRecordsResponse, MultiPin } from "types/map";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import MapViewportState from "types/map/MapViewportState";
import GeoPath from "types/GeoPath";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import convertMapFilterToPlatformFilterModel from "util/viewModel/convertMapFilterToPlatformFilterModel";
import { mapEntityIdGetter } from "util/map/idGetters";
import { convertRegionToLatLngBounds } from "util/geo/GeoService";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import {
  reloadGroups,
  updateGroup as updateGroupGlobal,
  updateGroupSharing as updateGroupSharingGlobal,
} from "store/groups/actions";
import getPrecision from "scene/map/utils/getPrecision";
import MapTool from "@mapmycustomers/shared/enum/map/MapTool";
import MapMode from "scene/map/enums/MapMode";
import MultiPinGroupMode from "scene/map/enums/MultiPinGroupMode";
import i18nService from "config/I18nService";
import { defineMessages } from "react-intl";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { getEntityTypeDisplayName } from "util/ui";
import notification from "antd/es/notification";
import { Action } from "redux";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { mapEntityIdParser } from "util/map/idParsers";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import get from "lodash-es/get";
import { handleError } from "store/errors/actions";
import isValidMapEntry from "util/map/isValidMapEntry";
import ReportType from "enum/ReportType";
import { MAX_ITEMS_TO_DOWNLOAD_FILE } from "store/exportEntities/const";
import { formatDate } from "util/formatters";
import User from "@mapmycustomers/shared/types/User";
import getErrorNotificationDescription from "scene/map/utils/getErrorNotificationDescription";
import downloadEntitiesAsCsv from "util/file/downloadEntitiesAsCsv";
import { convertToPlatformFilterModel } from "util/viewModel/convertToPlatformFilterModel";
import convertMapFilterModelToFilterModelForEntity from "store/savedFilters/convertMapFilterModelToFilterModel";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import Report from "types/Report";

const messages = defineMessages({
  addedToGroup: {
    id: "map.groups.notifications.addedToGroup",
    defaultMessage: "{count, plural, one {{type}} other {{count} {type}}} successfully added",
    description: "Added to group - notification message",
  },
  removedFromGroup: {
    id: "map.groups.notifications.removedFromGroup",
    defaultMessage: "{count, plural, one {{type}} other {{count} {type}}} successfully removed",
    description: "Removed from group - notification message",
  },
  dataFileTooLarge: {
    id: "map.groups.dataFileTooLarge.warning",
    defaultMessage: "Data file too large to download right now",
    description: "Message shown when sample companies are added",
  },
  dataFileTooLargeDescription: {
    id: "map.groups.dataFileTooLarge.warning.description",
    defaultMessage:
      "Your export has been queued for processing and will be sent to your email once finished.",
    description: "Message shown when sample people are added",
  },
  success: {
    id: "map.groups.success",
    defaultMessage: "File Downloaded",
    description: "Message shown when sample people are added",
  },
  error: {
    id: "map.groups.error.message",
    defaultMessage: "Download Error",
    description: "Message shown when download fail",
  },
});

function* onEnterGroupMode({ payload }: ReturnType<typeof enterGroupMode>) {
  const url = `${Path.MAP}/groups/${payload.entityType}/${payload.groupId}`;
  yield put(push(url));
}

function* onExitGroupMode({ payload: forceExit }: ReturnType<typeof exitGroupMode.request>) {
  yield put(push(Path.MAP));

  const hasChanges: boolean = yield select(doesGroupEditFormHaveChanges);
  // only exit from group mode immediately if there are no changes in group or
  // if we're forced to do that
  if (!hasChanges || forceExit) {
    yield put(exitGroupLassoMode());
    yield put(exitGroupMode.success());
    yield put(exitMode());
  }
}

function* onInitializeGroupsMode({ payload }: ReturnType<typeof initializeGroupsMode.request>) {
  const { callback, entityType, groupId } = payload;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const $filters = {
      entities: {
        [entityType]: {
          groups: { $any: [groupId] },
        },
      },
    };

    yield put(enterMode(MapMode.GROUPS));

    const candidateGroup: Group | undefined = yield select(getCandidateGroup);

    // fetch group itself and pins to detect its bounds
    const [group, pins]: [Group, ListResponse<MapEntry>] = yield all([
      candidateGroup?.id === groupId
        ? Promise.resolve(candidateGroup)
        : callApi("fetchGroup", orgId, entityType, groupId),
      callApi("fetchMapPins", orgId, {
        $filters: {
          ...$filters,
          precision: 5,
          precisionThreshold: 100,
        },
        $limit: 100,
      }),
    ]);

    const bounds = new google.maps.LatLngBounds();
    pins.data.filter(isValidMapEntry).forEach((pin) => {
      const pinBound = convertRegionToLatLngBounds(pin.region!);
      bounds.extend(pinBound.getSouthWest());
      bounds.extend(pinBound.getNorthEast());
    });
    callback?.(bounds);

    yield put(initializeGroupsMode.success({ entityType, group }));
  } catch (e) {
    yield put(initializeGroupsMode.failure());
    yield put(handleError({ error: e }));
  }
}

export function* onFetchGroupPins({ payload }: ReturnType<typeof fetchGroupPins.request>) {
  try {
    if (payload.viewport) {
      yield put(updateViewportState(payload.viewport));
      yield call(localSettings.updateViewportState, convertViewportToPersist(payload.viewport));
    }
    yield put(applyGroupsMapViewSettings(payload.request));
    const viewport: MapViewportState = yield select(getMapViewport);
    const mapViewState: MapViewState = yield select(getGroupMapViewState);
    const entityType: EntityTypeSupportingGroups | undefined = yield select(getGroupEntityType);

    if (payload.updateOnly || !entityType) {
      return;
    }

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const bigOrganization: boolean = yield select(isBigOrganization);
    const requestPayload = {
      $filters: {
        precision: getPrecision(viewport.zoom ?? 1, bigOrganization),
        precisionThreshold: 1000,
        bounds: viewport.bounds,
        includeAccessStatus: true,
        includeCustomFields: true,
        ...convertMapFilterToPlatformFilterModel(
          mapViewState.filter,
          [entityType],
          mapViewState.viewAs
        ),
      },
    };
    const response: ListResponse<MapEntry> = yield callApi("fetchMapPins", orgId, requestPayload);
    yield put(
      fetchGroupPins.success({
        ...categorizeMapEntries(response.data),
        pinsCount: response.total,
      })
    );

    // Also refresh records list
    yield put(fetchGroupRecords.request({}));

    yield put(fetchGroupOtherPins.request({}));

    // Refresh status of clusters which are within lasso selection
    const pins: CategorizedMapEntries = yield select(getGroupEntries);
    yield put(updateLassoClusters(pins.clusters));
  } catch (error) {
    yield put(fetchGroupPins.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchGroupOtherPins() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const viewport: MapViewportState = yield select(getMapViewport);
    const mapViewState: MapViewState = yield select(getGroupMapViewState);
    const entityType: EntityTypeSupportingGroups = yield select(getGroupEntityType);
    const groupId: Group["id"] = yield select(getGroupId);
    const showOtherPins: boolean = yield select(getGroupShowOtherPins);

    if (!showOtherPins) {
      yield put(
        fetchGroupOtherPins.success({
          clusters: [],
          multiPins: [],
          entities: [],
        })
      );
      return;
    }

    const filter = {
      ...mapViewState.filter,
      [entityType]: {
        ...mapViewState.filter[entityType],
        groups: {
          operator: FilterOperator.NOT_IN,
          value: [groupId],
        },
      },
    };

    const bigOrganization: boolean = yield select(isBigOrganization);
    const requestPayload = {
      $filters: {
        precision: getPrecision(viewport.zoom ?? 1, bigOrganization),
        precisionThreshold: 1000,
        bounds: viewport.bounds,
        includeAccessStatus: true,
        includeCustomFields: true,
        ...convertMapFilterToPlatformFilterModel(filter, [entityType], mapViewState.viewAs),
      },
    };

    const response: ListResponse<MapEntry> = yield callApi("fetchMapPins", orgId, requestPayload);
    yield put(
      fetchGroupOtherPins.success({
        ...categorizeMapEntries(response.data),
      })
    );
  } catch (error) {
    yield put(fetchGroupOtherPins.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchGroupRecords() {
  try {
    const entityType: EntityTypeSupportingGroups = yield select(getGroupEntityType);
    const mapViewState: MapViewState = yield select(getGroupMapViewState);
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const groupId: Group["id"] = yield select(getGroupId);
    const multiPin: MultiPin | undefined = yield select(getMultiPin);
    const multiPinGroupMode: MultiPinGroupMode | undefined = yield select(getMultiPinGroupMode);
    const isMultiPin = !!multiPin?.id;
    const activeTool: MapTool | undefined = yield select(getMapViewTool);
    const isLassoMode = activeTool === MapTool.LASSO;
    const selectedRecords: Set<string> = yield select(getSelectedRecords);
    const showOtherPins: boolean = yield select(getGroupShowOtherPins);

    const $offset = mapViewState.range.startRow;
    const $limit = mapViewState.range.endRow - mapViewState.range.startRow;

    // we know filters for this entityType is defined because there must be at least
    // one filter - by groupId
    const filters = { ...mapViewState.filter[entityType]! };

    if (isMultiPin && multiPinGroupMode === MultiPinGroupMode.OUTSIDE_GROUP) {
      filters.groups = {
        operator: FilterOperator.NOT_IN,
        value: [groupId],
      };
    } else if (isLassoMode && showOtherPins) {
      delete filters.groups;
    }

    const searchFilter = mapViewState.search?.trim();
    if (searchFilter?.length) {
      // we override filter for name even if one is set in main Filters of the group mode
      filters.name = {
        operator: FilterOperator.CONTAINS,
        value: searchFilter,
      };
    }

    const bounds = isMultiPin ? multiPin.bounds : undefined;
    const mapFilters = convertMapFilterToPlatformFilterModel(
      { [entityType]: filters, universal: mapViewState.filter.universal },
      [entityType],
      mapViewState.viewAs
    );

    if (isLassoMode && selectedRecords.size === 0) {
      // If we're in lasso, but haven't selected any records for whatever reason - it makes no sense
      // to request detailed list of records from backend
      yield put(
        fetchGroupRecords.success({
          records: [],
          recordsCount: 0,
        })
      );
      return;
    }

    if (isLassoMode) {
      // Inject filtering by selected IDs
      if (mapFilters.entities && !Array.isArray(mapFilters.entities)) {
        const entityIdFilter: Array<MapEntity["id"]> = [];
        for (const mapEntityId of selectedRecords.values()) {
          const parsed = mapEntityIdParser(mapEntityId);
          if (parsed) {
            entityIdFilter.push(parsed.id);
          }
        }

        const entities = mapFilters.entities as Record<
          EntityTypesSupportedByMapsPage,
          PlatformFilterModel
        >;
        Object.keys(entities).forEach((key) => {
          const entityKey = key as EntityTypesSupportedByMapsPage;
          if (entityType === entityKey) {
            const andCondition = get(mapFilters.entities, [entityKey, "$and"]);
            if (Array.isArray(andCondition) && Array.isArray(entityIdFilter)) {
              andCondition.push({
                id: {
                  $in: entityIdFilter,
                },
              });
            }
          }
        });
      }
    }

    const requestPayload = {
      $offset,
      $limit,
      $filters: {
        ...mapFilters,
        bounds,
        includeAccessStatus: true,
      },
      $order: convertToPlatformSortModel(mapViewState.sort),
    };

    const response: ListResponse<MapEntity> = yield callApi("fetchMapPins", orgId, requestPayload);
    yield put(
      fetchGroupRecords.success({
        records: response.data,
        recordsCount: response.total,
      })
    );
  } catch (error) {
    yield put(fetchGroupRecords.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateGroup({ payload }: ReturnType<typeof updateGroup.request>) {
  try {
    const { group, onSuccess, noNotification } = payload;
    const entityType: EntityTypeSupportingGroups = yield select(getGroupEntityType);

    yield put(
      updateGroupGlobal.request({
        entityType,
        group,
        onSuccess: (_, group) => onSuccess?.(group),
        noNotification,
      })
    );

    const result: {
      success?: ActionType<typeof updateGroupGlobal.success>;
      failure?: ActionType<typeof updateGroupGlobal.failure>;
    } = yield race({
      success: take(updateGroupGlobal.success),
      failure: take(updateGroupGlobal.failure),
    });

    if (result.failure) {
      yield put(updateGroup.failure());
      return;
    }

    yield put(updateGroup.success(result.success!.payload.group));
  } catch (error) {
    yield put(updateGroup.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchGroup({
  payload: { groupId, entityType },
}: ReturnType<typeof fetchGroup.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const group: Group = yield callApi("fetchGroup", orgId, entityType, groupId);
    yield put(fetchGroup.success(group));
  } catch (error) {
    yield put(fetchGroup.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateGroupSharing({ payload }: ReturnType<typeof updateGroupSharing.request>) {
  try {
    const { group, onSuccess, userIdsToShareWith } = payload;
    const entityType: EntityTypeSupportingGroups = yield select(getGroupEntityType);

    yield put(
      updateGroupSharingGlobal.request({
        entityType,
        group,
        onSuccess: (_, group) => onSuccess?.(group),
        userIdsToShareWith,
      })
    );

    const result: {
      success?: ActionType<typeof updateGroupSharingGlobal.success>;
      failure?: ActionType<typeof updateGroupSharingGlobal.failure>;
    } = yield race({
      success: take(updateGroupSharingGlobal.success),
      failure: take(updateGroupSharingGlobal.failure),
    });

    if (result.failure) {
      yield put(updateGroupSharing.failure());
      return;
    }

    yield put(updateGroupSharing.success(result.success!.payload.group));
  } catch (error) {
    yield put(updateGroupSharing.failure());
    yield put(handleError({ error }));
  }
}

export function* onBulkAddPinsToGroup({ payload }: ReturnType<typeof bulkAddPinsToGroup.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const groupId: Group["id"] = yield select(getGroupId);
    const entityType: EntityTypeSupportingGroups = yield select(getGroupEntityType);

    const entities = payload.entities
      .filter((entity) => entity.entity === entityType)
      .map(({ id }) => id);

    if (entities.length) {
      yield callApi("addToGroup", orgId, groupId, entityType, entities);

      yield put(fetchGroupPins.request({ request: {} }));

      const activeTool: MapTool | undefined = yield select(getMapViewTool);
      const lassoPaths: Array<GeoPath> = yield select(getMapLassoPaths);
      const isLassoMode = activeTool === MapTool.LASSO && lassoPaths.length > 0;

      if (isLassoMode) {
        yield put(fetchGroupLassoSelection.request({ request: {} }));
      }

      yield put(reloadGroups({ entityType }));

      const intl = i18nService.getIntl();
      if (intl) {
        notification.success({
          message: intl.formatMessage(messages.addedToGroup, {
            count: entities.length,
            type: getEntityTypeDisplayName(intl, entityType, {
              lowercase: false,
              plural: entities.length > 1,
            }),
          }),
        });
      }
    }
  } catch (error) {
    yield put(updateGroupSharing.failure());
    yield put(handleError({ error }));
  }
}

export function* onBulkRemovePinsFromGroup({
  payload,
}: ReturnType<typeof bulkRemovePinsFromGroup.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const groupId: Group["id"] = yield select(getGroupId);
    const entityType: EntityTypeSupportingGroups = yield select(getGroupEntityType);

    const entities = payload.entities
      .filter((entity) => entity.entity === entityType)
      .map(({ id }) => id);

    if (entities.length) {
      yield callApi("deleteFromGroup", orgId, groupId, entityType, entities);

      yield put(fetchGroupPins.request({ request: {} }));

      const activeTool: MapTool | undefined = yield select(getMapViewTool);
      const lassoPaths: Array<GeoPath> = yield select(getMapLassoPaths);
      const isLassoMode = activeTool === MapTool.LASSO && lassoPaths.length > 0;

      if (isLassoMode) {
        yield put(fetchGroupLassoSelection.request({ request: {} }));
      }

      yield put(reloadGroups({ entityType }));

      const intl = i18nService.getIntl();
      if (intl) {
        notification.success({
          message: intl.formatMessage(messages.removedFromGroup, {
            count: entities.length,
            type: getEntityTypeDisplayName(intl, entityType, {
              lowercase: false,
              plural: entities.length > 1,
            }),
          }),
        });
      }
    }
  } catch (error) {
    yield put(updateGroupSharing.failure());
    yield put(handleError({ error }));
  }
}

export function* onExitGroupLassoMode() {
  try {
    yield put(setHighlight(new Set()));
    yield put(cleanLassoPaths());
    yield put(selectMapTool(undefined));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onFetchGroupLassoSelection({
  payload,
}: ReturnType<typeof fetchGroupLassoSelection.request>) {
  try {
    yield put(showSidebar());

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityType: EntityTypeSupportingGroups = yield select(getGroupEntityType);
    const mapViewState: MapViewState = yield select(getGroupMapViewState);

    const sidebarVisible: boolean = yield select(isSidebarVisible);
    const activeTool: MapTool | undefined = yield select(getMapViewTool);
    const lassoPaths: Array<GeoPath> = yield select(getMapLassoPaths);
    const showOtherPins: boolean = yield select(getGroupShowOtherPins);

    const isLassoMode = activeTool === MapTool.LASSO && lassoPaths.length > 0;

    if (isLassoMode && mapViewState.filter[entityType]) {
      const ignoreGroupsFilter = showOtherPins
        ? {
            groups: {},
          }
        : undefined;

      const multipolygon: LongLat[][] = payload.request.lasso
        ? payload.request.lasso
        : lassoPaths.map((path) =>
            path.map((item: google.maps.LatLng): LongLat => [item.lng(), item.lat()])
          );

      const mapFilters = convertMapFilterToPlatformFilterModel(
        {
          [entityType]: {
            ...mapViewState.filter[entityType],
            ...ignoreGroupsFilter,
          },
          universal: mapViewState.filter.universal,
        },
        [entityType],
        mapViewState.viewAs
      );

      const requestPayload = {
        $offset: 0,
        $limit: 10000,
        $filters: {
          ...mapFilters,
          includeAccessStatus: true,
          multipolygon,
        },
        $order: convertToPlatformSortModel(mapViewState.sort),
      };

      const response: MapRecordsResponse = yield callApi("fetchMapPins", orgId, requestPayload);

      const excludedRecords: Set<string> = yield select(getExcludedRecords);
      const includedRecords: Set<string> = yield select(getIncludedRecords);

      const responseItems = response.data ?? [];
      const itemsWithoutExcluded = new Set(
        responseItems
          .map((entity) => mapEntityIdGetter(entity))
          .filter((value) => !excludedRecords.has(value))
      );
      const itemsWithIncluded = new Set([
        ...Array.from(itemsWithoutExcluded),
        ...Array.from(includedRecords),
      ]);
      yield put(setSelection(itemsWithIncluded));

      const selectionEntities = responseItems.filter(
        (entity) => !excludedRecords.has(mapEntityIdGetter(entity))
      );
      yield put(setSelectionEntities(selectionEntities));

      if (itemsWithIncluded.size && !sidebarVisible) {
        yield put(clearRecordsList());
      }

      yield put(fetchGroupLassoSelection.success({ records: response.data }));
    } else {
      yield put(clearSelection());
      yield put(fetchGroupLassoSelection.success({ records: [] }));
    }

    if (sidebarVisible) {
      yield put(resetRecordsListPagination());
      yield put(fetchGroupRecords.request({}));
    }
  } catch (error) {
    yield put(fetchGroupLassoSelection.failure({ error }));
    yield put(handleError({ error }));
  }
}

function* onFetchDataViewEntities() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const groupId: Group["id"] = yield select(getGroupId);
    const entityType: EntityTypeSupportingGroups = yield select(getGroupEntityType);

    const $filters = {
      groups: { [PlatformFilterOperator.GROUP_IN_ANY]: [groupId] },
    };

    // fetch group itself and pins to detect its bounds
    const response: ListResponse<EntitiesSupportingGroups> = yield callApi(
      entityType === EntityType.COMPANY
        ? "fetchCompanies"
        : entityType === EntityType.PERSON
        ? "fetchPeople"
        : "fetchDeals",
      orgId,
      { $columns: ["id"], $filters, $limit: 10000 }
    );

    const dataViewEntities = response.data.map(({ id }) => ({ id, entity: entityType }));

    yield put(fetchDataViewEntities.success(dataViewEntities));
  } catch (e) {
    yield put(fetchDataViewEntities.failure(e));
    yield put(handleError({ error: e }));
  }
}

export function* onApplyGroupFilters() {
  try {
    const groupId: Group["id"] = yield select(getGroupId);
    const entityType: EntityTypeSupportingGroups = yield select(getGroupEntityType);

    const $filter = {
      [entityType]: {
        groups: {
          operator: FilterOperator.IN_ANY,
          value: [groupId],
        },
      },
    };

    yield put(
      fetchPins.request({
        request: {
          filter: $filter,
          visibleEntities: [entityType],
        },
        updateOnly: true,
      })
    );

    yield put(exitGroupMode.request());
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onSetGroupShowOtherPins() {
  try {
    const activeTool: MapTool | undefined = yield select(getMapViewTool);
    const lassoPaths: Array<GeoPath> = yield select(getMapLassoPaths);
    const isLassoMode = activeTool === MapTool.LASSO && lassoPaths.length > 0;

    if (isLassoMode) {
      yield put(fetchGroupLassoSelection.request({ request: {} }));
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onEnterGroupLassoMode() {
  try {
    const mode: MapMode = yield select(getMapMode);
    if (mode === MapMode.GROUPS) {
      yield put(
        fetchGroupRecords.success({
          records: [],
          recordsCount: 0,
        })
      );
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onDownloadGroupRecords({ payload }: ReturnType<typeof downloadGroupRecords>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const intl = i18nService.getIntl();
    const entityType: EntityTypeSupportingGroups = yield select(getGroupEntityType);
    const mapViewState: MapViewState = yield select(getGroupMapViewState);
    const selectedColumns = payload.filter(({ visible }) => visible).map(({ field }) => field);
    const columnNames = selectedColumns.map(({ name }) => name);
    const multiPin: MultiPin | undefined = yield select(getMultiPin);
    const isMultiPin = !!multiPin?.id;
    const filters = { ...mapViewState.filter[entityType]! };
    const bounds = isMultiPin ? multiPin.bounds : undefined;
    const mapFilters = convertMapFilterToPlatformFilterModel(
      { [entityType]: filters, universal: mapViewState.filter.universal },
      [entityType],
      mapViewState.viewAs
    );

    const requestPayload = {
      $limit: 0,
      $filters: {
        ...mapFilters,
        bounds,
        includeCustomFields: true,
      },
      $order: convertToPlatformSortModel(mapViewState.sort),
    };
    const response: MapRecordsResponse = yield callApi("fetchPins", orgId, requestPayload);

    if (response.total > MAX_ITEMS_TO_DOWNLOAD_FILE) {
      const currentUser: User = yield select(getCurrentUser);
      const $filters = convertToPlatformFilterModel(
        convertMapFilterModelToFilterModelForEntity(entityType, mapViewState.filter),
        getFieldModelByEntityType(entityType).fields.map((field) => ({ field, visible: true })),
        getFieldModelByEntityType(entityType),
        true
      );
      const payload = {
        name: `${entityType} ${formatDate(new Date(), "Pp")}`,
        description: "",
        selectedColumns: columnNames,
        selectedFilters: {
          ...$filters,
          includeCustomFields: true,
        },
        tableName: entityType,
      };

      try {
        const report: Report = yield callApi("createReport", orgId, payload);
        yield callApi("generateReport", orgId, report.id, currentUser.username);
      } catch (e) {
        notification.error({
          message: intl?.formatMessage(messages.error),
          description: getErrorNotificationDescription(intl, payload.tableName),
        });
      }
      if (intl) {
        notification.warning({
          message: intl.formatMessage(messages.dataFileTooLarge),
          description: intl.formatMessage(messages.dataFileTooLargeDescription),
        });
      }
    } else {
      const response: MapRecordsResponse = yield callApi("fetchPins", orgId, {
        ...requestPayload,
        $limit: MAX_ITEMS_TO_DOWNLOAD_FILE,
      });
      if (response.data.length > 0) {
        let reportEntityType;
        switch (entityType) {
          case EntityType.COMPANY:
            reportEntityType = ReportType.COMPANIES;
            break;
          case EntityType.PERSON:
            reportEntityType = ReportType.PEOPLE;
            break;
          case EntityType.DEAL:
            reportEntityType = ReportType.DEALS;
            break;
        }
        downloadEntitiesAsCsv(
          `${reportEntityType}_preview.csv`,
          entityType,
          response.data,
          selectedColumns
        );
        if (intl) {
          notification.success({
            message: intl.formatMessage(messages.success),
          });
        }
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* groupModeSagas() {
  yield takeLatest(enterGroupMode, onEnterGroupMode);
  yield takeLatest(exitGroupMode.request, onExitGroupMode);
  yield takeLatest(initializeGroupsMode.request, onInitializeGroupsMode);
  yield takeLatest(
    (action: Action) => isActionOf(fetchGroupPins.request)(action) && !action.payload.updateOnly,
    onFetchGroupPins
  );
  yield takeEvery(
    (action: Action) => isActionOf(fetchGroupPins.request)(action) && !!action.payload.updateOnly,
    onFetchGroupPins
  );
  yield takeLatest(setGroupShowOtherPins, onSetGroupShowOtherPins);
  yield takeLatest(fetchGroupOtherPins.request, onFetchGroupOtherPins);
  yield takeLatest(fetchGroupRecords.request, onFetchGroupRecords);
  yield takeLatest(fetchGroupLassoSelection.request, onFetchGroupLassoSelection);
  yield takeLatest(enterLassoMode, onEnterGroupLassoMode);
  yield takeLatest(exitGroupLassoMode, onExitGroupLassoMode);
  yield takeLatest(applyGroupFilters, onApplyGroupFilters);
  yield takeLatest(updateGroup.request, onUpdateGroup);
  yield takeLatest(updateGroupSharing.request, onUpdateGroupSharing);
  yield takeLatest(bulkAddPinsToGroup.request, onBulkAddPinsToGroup);
  yield takeLatest(bulkRemovePinsFromGroup.request, onBulkRemovePinsFromGroup);
  yield takeLatest(fetchDataViewEntities.request, onFetchDataViewEntities);
  yield takeLatest(fetchGroup.request, onFetchGroup);
  yield takeLatest(downloadGroupRecords, onDownloadGroupRecords);
}

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Field, { FieldProperties } from "./Field";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import ViewAsFilter, { VIEW_AS_FILTER_OPERATORS } from "util/filters/ViewAsFilter";

class ViewAsField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      type: FieldType.ID,
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.VIEW_AS_FIELD,
        // this is an artificial field, just for filtering, thus we hide it from everywhere:
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.FILTERABLE_ON_MAP,
      ],
      customFilterConfig: {
        defaultInstance: "viewAs",
        filterInstances: {
          viewAs: ViewAsFilter,
        },
        operators: VIEW_AS_FILTER_OPERATORS,
      },
      ...data,
    });
  }
}

export default ViewAsField;

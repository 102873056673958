import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import { Column } from "@mapmycustomers/shared/types/viewModel/internalModel/ColumnModel";
import { isCustomField } from "util/fieldModel/impl/assert";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import Report from "types/Report";
import { PREVIEW_LIMIT } from "./consts";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";
import { convertFromPlatformFilterModel } from "util/viewModel/convertFromPlatformFilterModel";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

const isExportField = (field: IField) => !field.hasFeature(FieldFeature.NON_EXPORT_VIEW);

const getDefaultReportPreviewViewState = (report: Partial<Report>): ViewState => {
  const fieldModel = getFieldModelByEntityType(report.tableName!);
  const columns = fieldModel.fields
    .filter(isExportField)
    .map<Column>((field) => ({
      field,
      visible:
        (isCustomField(field) && report.customFields!.includes(field.customFieldData.id)) ||
        report.selectedColumns!.includes(field.exportColumnName),
    }))
    .sort(
      (column1, column2) =>
        (report.columnsOrder?.indexOf(column1.field.exportColumnName) ?? Number.MAX_SAFE_INTEGER) -
        (report.columnsOrder?.indexOf(column2.field.exportColumnName) ?? Number.MAX_SAFE_INTEGER)
    );

  const sortField = fieldModel.getByName(report.selectedOrder?.field ?? "updatedAt");
  const sortOrder = report.selectedOrder?.order ?? SortOrder.DESC;

  return {
    columns,
    filter: report.selectedFilters
      ? convertFromPlatformFilterModel(report.selectedFilters, fieldModel, isExportField)
      : {},
    range: { startRow: 0, endRow: PREVIEW_LIMIT },
    sort: sortField ? [{ field: sortField, order: sortOrder }] : [],
    viewAs: undefined,
  };
};

export default getDefaultReportPreviewViewState;

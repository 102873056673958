import SavedFilterVisibility from "@mapmycustomers/shared/enum/SavedFilterVisibility";
import { defineMessages, IntlShape } from "react-intl";

export const visibilityNotes = defineMessages<SavedFilterVisibility>({
  [SavedFilterVisibility.PRIVATE]: {
    id: "savedFilterVisibility.private.note",
    defaultMessage: "Only you will be able to apply this saved filter.",
    description: "Visibility note for Private VISIBILITY TYPE option",
  },
  [SavedFilterVisibility.SHARED_WITH_TEAM]: {
    id: "savedFilterVisibility.sharedWithTeam.note",
    defaultMessage:
      "Only you and the users on the team(s) above will be able to apply this saved filter.",
    description: "Visibility note for Shared with team VISIBILITY TYPE option",
  },
  [SavedFilterVisibility.SHARED_WITH_ORGANIZATION]: {
    id: "savedFilterVisibility.sharedWithOrganization.note",
    defaultMessage:
      "Users on any team, regardless of role, will be able to apply this saved filter.",
    description: "Visibility note for Shared with ORGANIZATION VISIBILITY TYPE option",
  },
});

const getSavedFilterVisibilityNote = (
  intl: IntlShape,
  savedFilterVisibility: SavedFilterVisibility
) => intl.formatMessage(visibilityNotes[savedFilterVisibility]);

export default getSavedFilterVisibilityNote;

import Dashboard from "types/dashboard/Dashboard";

export const convertFromRawDashboard = (dashboard: Dashboard) => ({
  ...dashboard,
  // Stored as null on platform side, converting to undefined for internal usage
  teamId: dashboard.teamId || undefined,
  userId: dashboard.userId || undefined,
  // providing cards array because this field might be absent in new dashboards
  settings: {
    ...(dashboard.settings ?? {}),
    cards: dashboard.settings?.cards ?? [],
  },
});

export const convertToRawDashboard = (dashboard: Dashboard) => ({
  ...dashboard,
  // Stored as null on platform side
  teamId: dashboard.teamId || null,
  userId: dashboard.userId || null,
});

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Field, { FieldProperties } from "./Field";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import type { UserRef } from "@mapmycustomers/shared/types/User";
import { csvUserFullNameFormatter, userDisplayName } from "util/formatters";
import UsersFilter, { USERS_FILTER_OPERATORS } from "util/filters/UsersFilter";
import type FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import type IFieldModel from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import { isDefined } from "util/assert";
import i18nService from "config/I18nService";
import { defineMessage } from "react-intl";

const incompatibleFilterMessage = defineMessage({
  id: "userField.incompatibleFilter",
  defaultMessage: "Cannot filter by {field} when {incompatibleField} filter applied",
  description:
    "Tooltip shown when user field filter is disabled because there's some incompatible filter",
});

export const userFormatter = (entity: unknown, user: unknown) =>
  user ? userDisplayName(user as UserRef) : "";

class UserField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      type: FieldType.OBJECT,
      filterType: FieldType.ID,
      features: [FieldFeature.FILTERABLE, FieldFeature.SUPPORTS_SET_FILTER],
      valueFormatter: userFormatter,
      customFilterConfig: {
        defaultInstance: "user",
        filterInstances: {
          user: UsersFilter,
        },
        operators: [...USERS_FILTER_OPERATORS],
      },
      customGridProperties: {
        cellRenderer: "userCellRenderer",
      },
      csvExportProperties: { valueFormatter: csvUserFullNameFormatter },
      ...data,
    });
  }

  isFilterEnabled(currentFilters: Partial<FilterModel>, fieldModel: IFieldModel) {
    if (!this.hasFeature(FieldFeature.OWNER_FIELD)) {
      return true;
    }

    const fieldFilterNames = Object.keys(currentFilters).filter(
      (fieldFilterName) => currentFilters[fieldFilterName] !== undefined
    );
    const incompatibleField = fieldFilterNames
      .map((name) => fieldModel.getByFilterName(name))
      .filter(isDefined)
      .find((field) => field.hasFeature(FieldFeature.TEAM_FIELD));

    if (!incompatibleField) {
      return true;
    }

    return i18nService.formatMessage(
      incompatibleFilterMessage,
      `Cannot filter by ${this.displayName} when ${incompatibleField.displayName} filter applied`,
      {
        field: this.displayName,
        incompatibleField: incompatibleField.displayName,
      }
    );
  }
}

export default UserField;

import React from "react";
import { RenderElementProps } from "slate-react/dist/components/editable";
import cn from "classnames";
import styles from "./Mention.module.scss";
import { MentionElement } from "type/slate-types";

const Mention: React.FC<RenderElementProps> = ({
  attributes,
  children,
  element,
}) => {
  const { name, disabled } = element as MentionElement;

  return (
    <span
      className={cn(styles.mention, { [styles.mentionDisabled]: disabled })}
      contentEditable={false}
      {...attributes}
    >
      {name}
      {children}
    </span>
  );
};

export default Mention;

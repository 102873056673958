import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import User from "@mapmycustomers/shared/types/User";
import Location from "@mapmycustomers/shared/types/Location";
import PersistentBaseMapLayerData from "@mapmycustomers/shared/types/persistent/PersistentBaseMapLayerData";
import LocationGroup from "types/map/LocationGroup";

export const initUserLocations = createAction("@userLocations/initUserLocations")<{
  disabledUserIds: User["id"][];
  hiddenUserIds: User["id"][];
}>();

export const setDisabledUserIds = createAction("@userLocations/setDisabledUserIds")<User["id"][]>();

export const setHiddenUserIds = createAction("@userLocations/setHiddenUserIds")<User["id"][]>();

export const selectUser = createAction("@userLocations/selectUser")<User | undefined>();
export const selectUserById = createAction("@userLocations/selectUserById")<
  User["id"] | undefined
>();

export const initSideBar = createAsyncAction(
  "@userLocations/initSideBar/request",
  "@userLocations/initSideBar/success",
  "@userLocations/initSideBar/failure"
)<
  void,
  {
    lastLocationWithoutPeriod?: Location;
  },
  unknown
>();

export const fetchLocations = createAsyncAction(
  "@userLocations/fetchLocations/request",
  "@userLocations/fetchLocations/success",
  "@userLocations/fetchLocations/failure"
)<
  { daysOffset: number },
  {
    firstLocationId?: Location["id"];
    locationGroups: LocationGroup[];
  },
  unknown
>();

export const highlightLocationGroupId = createAction("@userLocations/highlightLocationGroupId")<
  LocationGroup["id"] | undefined
>();

export const setUserLocationsSettings = createAction("@userLocations/setUserLocationsSettings")<
  PersistentBaseMapLayerData | undefined
>();

export type UserLocationActions = ActionType<
  | typeof fetchLocations
  | typeof initSideBar
  | typeof initUserLocations
  | typeof highlightLocationGroupId
  | typeof selectUser
  | typeof setDisabledUserIds
  | typeof setHiddenUserIds
  | typeof setUserLocationsSettings
>;

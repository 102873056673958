import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { EntityTypeSupportingDataMerging } from "@mapmycustomers/shared/types/entity";
import { DuplicableEntity, DuplicatePair } from "@mapmycustomers/shared/types/entity/Duplicate";
import DuplicateRunStatus from "@mapmycustomers/shared/enum/DuplicateRunStatus";

export const selectEntityType = createAction(
  "settings/mergeDuplicates/selectEntityType"
)<EntityTypeSupportingDataMerging>();

export const showDismissed = createAction("settings/mergeDuplicates/showDismissed")<void>();

export const hideDismissed = createAction("settings/mergeDuplicates/hideDismissed")<void>();

export const setLoading = createAction("settings/mergeDuplicates/setLoading")<boolean>();

export const setSearchText = createAction("settings/mergeDuplicates/setSearchText")<string>();

export const changeDuplicatePairDismission = createAsyncAction(
  "settings/mergeDuplicates/changeDuplicatePairDismission/request",
  "settings/mergeDuplicates/changeDuplicatePairDismission/success",
  "settings/mergeDuplicates/changeDuplicatePairDismission/failure"
)<{ dismissed: boolean; pair: DuplicatePair }, void, unknown>();

export const fetchDuplicates = createAsyncAction(
  "settings/mergeDuplicates/fetchDuplicates/request",
  "settings/mergeDuplicates/fetchDuplicates/success",
  "settings/mergeDuplicates/fetchDuplicates/failure"
)<
  { page?: number },
  { duplicates: DuplicatePair[]; lastCalculatedDate: string | undefined; total: number },
  unknown
>();

export const selectDuplicatePair = createAsyncAction(
  "settings/mergeDuplicates/selectDuplicatePair/request",
  "settings/mergeDuplicates/selectDuplicatePair/success",
  "settings/mergeDuplicates/selectDuplicatePair/failure"
)<number | undefined, { primary: DuplicableEntity; secondary: DuplicableEntity }, unknown>();

export const mergeDuplicates = createAction("settings/mergeDuplicates/mergeDuplicates")<void>();

export const positionSelectedPair = createAction(
  "settings/mergeDuplicates/positionSelectedPair"
)<void>();

export const prioritizePrimaryEntity = createAction(
  "settings/mergeDuplicates/selectPrimaryEntity"
)<void>();

export const prioritizeSecondaryEntity = createAction(
  "settings/mergeDuplicates/selectSecondaryEntity"
)<void>();

export const recalculateDuplicates = createAsyncAction(
  "settings/mergeDuplicates/recalculateDuplicates/request",
  "settings/mergeDuplicates/recalculateDuplicates/success",
  "settings/mergeDuplicates/recalculateDuplicates/failure"
)<void, { lastCalculatedDate: string; lastDupeRunStatus: DuplicateRunStatus }, unknown>();

export const checkDupeRunStatus = createAsyncAction(
  "settings/mergeDuplicates/checkDupeRunStatus/request",
  "settings/mergeDuplicates/checkDupeRunStatus/success",
  "settings/mergeDuplicates/checkDupeRunStatus/failure"
)<void, { lastCalculatedDate: string; lastDupeRunStatus: DuplicateRunStatus }, unknown>();

export type Actions = ActionType<
  | typeof changeDuplicatePairDismission
  | typeof checkDupeRunStatus
  | typeof fetchDuplicates
  | typeof hideDismissed
  | typeof mergeDuplicates
  | typeof positionSelectedPair
  | typeof prioritizePrimaryEntity
  | typeof prioritizeSecondaryEntity
  | typeof recalculateDuplicates
  | typeof selectDuplicatePair
  | typeof selectEntityType
  | typeof setLoading
  | typeof setSearchText
  | typeof showDismissed
>;

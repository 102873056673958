import React from "react";
import styles from "./Activities.module.scss";
import PreviousActivities from "./PreviousActivities";
import NextActivities from "./NextActivities";

interface Props {}

const Activities: React.FC<Props> = () => {
  return (
    <div className={styles.container}>
      <PreviousActivities />
      <NextActivities />
    </div>
  );
};

export default Activities;

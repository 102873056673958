import { createReducer } from "typesafe-actions";
import { RootState } from "../rootReducer";
import ApiService from "./ApiService";
import { Actions, setApi, setReadonlyApi } from "./actions";
import { createSelector } from "reselect";

export type ApiState = {
  apiService: ApiService | undefined;
  readonlyApiService: ApiService | undefined;
};

const initialState: ApiState = {
  apiService: undefined,
  readonlyApiService: undefined,
};

const api = createReducer<ApiState, Actions>(initialState)
  .handleAction(setApi, (state, action) => ({ ...state, apiService: action.payload }))
  .handleAction(setReadonlyApi, (state, action) => ({
    ...state,
    readonlyApiService: action.payload,
  }));

const apiState = (state: RootState) => state.api;
export const getApiService = createSelector(apiState, ({ apiService }) => apiService);
export const getReadonlyApiService = createSelector(
  apiState,
  ({ readonlyApiService }) => readonlyApiService
);

export type ApiActions = Actions;
export default api;

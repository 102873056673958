@use "../../styles/colors";

.actionContainer {
  position: absolute;
  bottom: 0;
  left: 1rem;
}

.togglerButton {
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.active {
  background-color: colors.$mmc-primary-010;
  color: colors.$mmc-primary-100;
}

.link {
  color: colors.$mmc-primary-100;
  font-weight: bold;
}

import PinLegendVisibility from "@mapmycustomers/shared/enum/PinLegendVisibility";
import { defineMessages, IntlShape } from "react-intl";

export const visibilityNotes = defineMessages<PinLegendVisibility>({
  [PinLegendVisibility.PRIVATE]: {
    id: "pinLegendVisibility.private.note",
    defaultMessage: "Only you can apply this legend.",
    description: "Visibility note for Private VISIBILITY TYPE option",
  },
  [PinLegendVisibility.SHARED_WITH_TEAM]: {
    id: "pinLegendVisibility.sharedWithTeam.note",
    defaultMessage: "Users of only the team(s) above can apply this legend.",
    description: "Visibility note for Shared with team VISIBILITY TYPE option",
  },
  [PinLegendVisibility.SHARED_WITH_ORGANIZATION]: {
    id: "pinLegendVisibility.sharedWithOrganization.note",
    defaultMessage: "Users on any team, regardless of role, can apply this legend.",
    description: "Visibility note for Shared with ORGANIZATION VISIBILITY TYPE option",
  },
});

const getPinLegendVisibilityNote = (intl: IntlShape, pinLegendVisibility: PinLegendVisibility) =>
  intl.formatMessage(visibilityNotes[pinLegendVisibility]);

export default getPinLegendVisibilityNote;

import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const componentState = (state: RootState) => state.component.postCreationModal;

export const isPostCreationModalVisible = createSelector(componentState, ({ visible }) => visible);

export const getPrefilledValues = createSelector(
  componentState,
  ({ prefilledValues }) => prefilledValues
);

export const getEntityType = createSelector(componentState, ({ entityType }) => entityType);

import Field, { FieldProperties } from "./Field";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import i18nService from "config/I18nService";
import { defineMessages } from "react-intl";
import RottingDaysOutFilter, {
  ROTTING_DAYS_OUT_FILTER_OPERATORS,
} from "util/filters/RottingDaysOutFilter";

export const messages = defineMessages({
  daysRotting: {
    id: "rottingDaysOutField.daysRotting",
    defaultMessage: "{days} {days, plural, one {day} other {days}}",
    description: "Rotting days message for the given entity",
  },
  daysUntilRotting: {
    id: "rottingDaysOutField.daysUntilRotting",
    defaultMessage: "{days} {days, plural, one {day} other {days}} until rotting",
    description: "Days until rotting message for the given entity",
  },
});

class RottingDaysOutField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "rotting",
        filterInstances: { rotting: RottingDaysOutFilter },
        operators: ROTTING_DAYS_OUT_FILTER_OPERATORS,
      },
      customGridProperties: {
        cellRenderer: "rottingCellRenderer",
      },
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.ENGAGEMENT,
        FieldFeature.ROTTING_DAYS_OUT_FIELD,
        FieldFeature.DETAILS,
      ],
      type: FieldType.NUMBER,
      valueFormatter: (entity: unknown, value: unknown) => {
        const intl = i18nService.getIntl();
        if (!intl) {
          return String(value); // returning value as string is better than returning nothing
        }
        return (value as number) > 0
          ? intl.formatMessage(messages.daysRotting, { days: value as number })
          : intl.formatMessage(messages.daysUntilRotting, { days: -(value as number) });
      },
      ...data,
    });
  }
}

export default RottingDaysOutField;

import { AlgorithmInput, AlgorithmOutput } from "../types/algorithm";
import Cluster from "../types/Cluster";
import {
  distanceBetweenPoints,
  extendBoundsToPaddedViewport,
  filterMarkersToPaddedViewport,
} from "../utils";
import AbstractViewportAlgorithm, { ViewportAlgorithmOptions } from "./AbstractViewportAlgorithm";
import ClusterImpl from "util/clustering/ClusterImpl";
import { convertLatLngToLongLat, convertLongLatToLatLngLiteral } from "util/geo/GeoService";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";

export interface GridOptions extends ViewportAlgorithmOptions {
  gridSize?: number;
  /**
   * Max distance between cluster center and point in meters.
   * @default 10000
   */
  maxDistance?: number;
}

/**
 * The default Grid algorithm historically used in Google Maps marker
 * clustering.
 *
 * The Grid algorithm does not implement caching and markers may flash as the
 * viewport changes. Instead, use {@link SuperClusterAlgorithm}.
 */
export class GridAlgorithm<T> extends AbstractViewportAlgorithm<T> {
  protected gridSize: number;
  protected maxDistance: number;
  protected clusters: ClusterImpl<T>[] = [];
  protected previousZoomLevel = -1;

  constructor({ maxDistance = 40000, gridSize = 40, ...options }: GridOptions) {
    super(options);

    this.maxDistance = maxDistance;
    this.gridSize = gridSize;
  }

  public calculate({ items, coordinateGetter, map }: AlgorithmInput<T>): AlgorithmOutput<T> {
    const currentZoomLevel = map.getZoom()!;
    let changed = false;
    if (this.previousZoomLevel >= this.maxZoom && currentZoomLevel >= this.maxZoom) {
      // still at or beyond maxZoom, no change
    } else {
      changed = this.previousZoomLevel !== currentZoomLevel;
    }
    this.previousZoomLevel = currentZoomLevel;
    if (currentZoomLevel >= this.maxZoom) {
      return { clusters: this.noop({ items, coordinateGetter }), changed };
    }

    return {
      clusters: this.cluster({
        items: filterMarkersToPaddedViewport(map, items, coordinateGetter, this.viewportPadding),
        coordinateGetter,
        map,
      }),
    };
  }

  protected cluster({ items, coordinateGetter, map }: AlgorithmInput<T>): Cluster<T>[] {
    this.clusters = [];
    for (const item of items) {
      this.addToClosestCluster(item, coordinateGetter, map);
    }

    return this.clusters;
  }

  protected addToClosestCluster(
    item: T,
    coordinateGetter: (item: T) => LongLat,
    map: google.maps.Map
  ): void {
    let maxDistance = this.maxDistance; // Some large number
    let cluster: ClusterImpl<T> | undefined;

    for (let i = 0; i < this.clusters.length; i++) {
      const candidate = this.clusters[i];
      const distance = distanceBetweenPoints(
        convertLatLngToLongLat(candidate.bounds.getCenter()),
        coordinateGetter(item)
      );

      if (distance < maxDistance) {
        maxDistance = distance;
        cluster = candidate;
      }
    }

    if (
      cluster &&
      extendBoundsToPaddedViewport(map, cluster.bounds, this.gridSize).contains(
        convertLongLatToLatLngLiteral(coordinateGetter(item))
      )
    ) {
      cluster.push(item);
    } else {
      const cluster = new ClusterImpl<T>([item], coordinateGetter);
      this.clusters.push(cluster);
    }
  }
}

export default GridAlgorithm;

import React from "react";
import { getError, isLoading, isSignedIn } from "../../../../store/auth";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import { Redirect } from "react-router-dom";
import Path from "../../../../enum/Path";
import LoginForm from "./LoginForm";
import { tryLogin } from "../../../../store/auth/actions";
import ApiError from "../../../../store/api/ApiError";
import AuthLayout from "../AuthLayout";
import useAuthErrorMessage from "../../utils/useAuthErrorMessage";

interface Props {
  authenticate: typeof tryLogin.request;
  error?: ApiError;
  isLoading: boolean;
  isSignedIn: boolean | undefined;
}

export const LoginPage: React.FC<Props> = ({ authenticate, error, isLoading, isSignedIn }) => {
  const message = useAuthErrorMessage(error);

  // go to root to proceed when already signed in
  if (isSignedIn) {
    return <Redirect to={Path.ROOT} />;
  }

  return (
    <AuthLayout>
      <LoginForm errorMessage={message} isLoading={isLoading} onSubmit={authenticate} />
    </AuthLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: getError(state),
  isLoading: isLoading(state),
  isSignedIn: isSignedIn(state),
});

const mapDispatchToProps = {
  authenticate: tryLogin.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

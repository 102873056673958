import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";

const dashboardState = (state: RootState) => state.scene.dashboard;

export const isLoading = createSelector(dashboardState, ({ loading }) => loading);
export const getScope = createSelector(dashboardState, ({ scope }) => scope);
export const getEventsFilters = createSelector(
  dashboardState,
  ({ eventsFilters }) => eventsFilters
);
export const getBoards = createSelector(dashboardState, ({ boards }) => boards);
export const getEvents = createSelector(dashboardState, ({ events }) => events);
export const getEventsTotal = createSelector(dashboardState, ({ eventsTotal }) => eventsTotal);
export const getOldestEventDate = createSelector(
  dashboardState,
  ({ oldestEventDate }) => oldestEventDate
);

// Other selectors
export * from "./stackRank/selectors";

import React from "react";
import commonStyles from "../AuthCommon.module.scss";
import styles from "./LinkExpiredPage.module.scss";
import { useIntl } from "react-intl";
import messages from "./messages";
import Button from "antd/es/button";
import { Link } from "react-router-dom";
import Path from "enum/Path";

interface Props {
  onManageUsersClick: () => void;
}

const LinkExpiredPage: React.FC<Props> = ({ onManageUsersClick }) => {
  const intl = useIntl();

  return (
    <section className={commonStyles.container}>
      <div className={styles.container}>
        <h1 className={commonStyles.title}>{intl.formatMessage(messages.invalidLinkTitle)}</h1>
        <span className={styles.description}>
          {intl.formatMessage(messages.invalidLinkMessage)}
        </span>
        <div className={styles.buttonContainer}>
          <Link to={Path.DASHBOARD}>
            <Button type="default">{intl.formatMessage(messages.home)}</Button>
          </Link>
          <Button onClick={onManageUsersClick} type="primary">
            {intl.formatMessage(messages.manageUsers)}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default LinkExpiredPage;

import React, { useCallback, useState } from "react";
import User from "@mapmycustomers/shared/types/User";
import Popover from "antd/es/popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/pro-solid-svg-icons/faAddressCard";
import { faCopy } from "@fortawesome/pro-solid-svg-icons/faCopy";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import Button from "antd/es/button";
import { defineMessages, useIntl } from "react-intl";
import { TextField } from "@mapmycustomers/ui";
import Tooltip from "antd/es/tooltip";
import parsePhoneNumber from "util/parsers/parsePhoneNumber";
import cn from "classnames";
import styles from "./ContactInfo.module.scss";

const messages = defineMessages({
  call: {
    id: "dashboard.header.member.contact.call",
    defaultMessage: "Call",
    description:
      "Tooltip for the Call button on contact information card on the member's Header on the Dashboard page",
  },
  copy: {
    id: "dashboard.header.member.contact.copy",
    defaultMessage: "{copied, select, true {Copied} other {Copy to clipboard}}",
    description:
      "Tooltip for the Copy button on contact information card on the member's Header on the Dashboard page",
  },
  sendEmail: {
    id: "dashboard.header.member.contact.sendEmail",
    defaultMessage: "Send Email",
    description:
      "Tooltip for the Send Email button on contact information card on the member's Header on the Dashboard page",
  },
  title: {
    id: "dashboard.header.member.contact",
    defaultMessage: "Contact Information",
    description:
      "Title of the contact information card on the member's Header on the Dashboard page",
  },
});

interface Props {
  size?: "small";
  user: User;
}

const ContactInfo: React.FC<Props> = ({ size, user }) => {
  const intl = useIntl();
  const email = user.username;
  const phoneNumber = parsePhoneNumber(user.phone || undefined);
  const [copied, setCopied] = useState(false);

  const handleCopyEmail = useCallback(async () => {
    await navigator.clipboard.writeText(email);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [email]);

  const handleCopyPhoneNumber = useCallback(async () => {
    if (!phoneNumber) {
      return;
    }
    await navigator.clipboard.writeText(phoneNumber.label);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [phoneNumber]);

  return (
    <Popover
      content={
        <div className={styles.content}>
          {phoneNumber && (
            <TextField
              readOnly
              suffix={
                <div className={styles.actions}>
                  <Tooltip title={intl.formatMessage(messages.call)}>
                    <Button
                      href={phoneNumber.link}
                      icon={<FontAwesomeIcon icon={faPhone} />}
                      type="text"
                    />
                  </Tooltip>
                  <Tooltip title={intl.formatMessage(messages.copy, { copied })}>
                    <Button
                      icon={<FontAwesomeIcon icon={faCopy} />}
                      onClick={handleCopyPhoneNumber}
                      type="text"
                    />
                  </Tooltip>
                </div>
              }
              value={phoneNumber.label}
            />
          )}
          <TextField
            readOnly
            suffix={
              <div className={styles.actions}>
                <Tooltip title={intl.formatMessage(messages.sendEmail)}>
                  <Button
                    href={`mailto:${email}`}
                    icon={<FontAwesomeIcon icon={faEnvelope} />}
                    type="text"
                  />
                </Tooltip>
                <Tooltip title={intl.formatMessage(messages.copy, { copied })}>
                  <Button
                    icon={<FontAwesomeIcon icon={faCopy} />}
                    onClick={handleCopyEmail}
                    type="text"
                  />
                </Tooltip>
              </div>
            }
            value={email}
          />
        </div>
      }
      placement="bottomLeft"
      title={intl.formatMessage(messages.title)}
      trigger={["hover"]}
      zIndex={1051} // Above user preview
    >
      <Button
        className={cn(styles.button, { [styles.small]: size === "small" })}
        icon={<FontAwesomeIcon icon={faAddressCard} />}
        type="text"
      />
    </Popover>
  );
};

export default ContactInfo;

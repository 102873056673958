import { ActionType, createAsyncAction } from "typesafe-actions";
import { Company, Deal, EntityWithLocation, Person } from "@mapmycustomers/shared/types/entity";

export const filterCompanies = createAsyncAction(
  "@component/entityAssociations/filterCompanies/request",
  "@component/entityAssociations/filterCompanies/success",
  "@component/entityAssociations/filterCompanies/failure"
)<
  {
    companyId?: Company["id"];
    isChildSelection?: boolean;
    query: string;
  },
  Company[],
  void
>();

export const filterDeals = createAsyncAction(
  "@component/entityAssociations/filterDeals/request",
  "@component/entityAssociations/filterDeals/success",
  "@component/entityAssociations/filterDeals/failure"
)<
  {
    query: string;
  },
  Deal[],
  void
>();

export const filterPeople = createAsyncAction(
  "@component/entityAssociations/filterPeople/request",
  "@component/entityAssociations/filterPeople/success",
  "@component/entityAssociations/filterPeople/failure"
)<
  {
    query: string;
  },
  Person[],
  void
>();

export const filterEntitiesWithLocation = createAsyncAction(
  "@component/entityAssociations/filterEntitiesWithLocation/request",
  "@component/entityAssociations/filterEntitiesWithLocation/success",
  "@component/entityAssociations/filterEntitiesWithLocation/failure"
)<
  {
    query: string;
  },
  EntityWithLocation[],
  void
>();

export type Actions = ActionType<
  | typeof filterCompanies
  | typeof filterDeals
  | typeof filterEntitiesWithLocation
  | typeof filterPeople
>;

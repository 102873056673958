import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";
import React, { ComponentType, ReactNode, useCallback } from "react";
import getFieldMatcher from "component/FormFields/utils/getFieldMatcher";
import {
  EntityType,
  EntityTypesSupportingCompanyAssociation,
  EntityTypesSupportingFieldCustomization,
  EntityTypeSupportingGroups,
} from "@mapmycustomers/shared/types/entity";
import { ParentCompanyField } from "component/FormFields/components/ParentCompanyField";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Form from "antd/es/form";
import getCustomFieldsValidationRules from "component/input/utils/getCustomFieldsValidationRules";
import CustomFieldComponent from "component/CustomField";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";
import { CompanyFieldName } from "util/fieldModel/CompanyFieldModel";
import { DealFieldName } from "util/fieldModel/DealFieldModel";
import { PersonFieldName } from "util/fieldModel/PersonFieldModel";
import { TextField } from "@mapmycustomers/ui";
import CircleColorPicker from "component/CircleColorPicker";
import Tag from "antd/es/tag";
import { formatDate } from "util/formatters";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import DatePicker from "component/input/DatePicker";
import CustomField from "@mapmycustomers/shared/types/customField/CustomField";
import { IntlShape, useIntl } from "react-intl";
import { isCustomField } from "util/fieldModel/impl/assert";
import loggingService from "util/logging";
import PersonNameField from "component/FormFields/components/PersonNameField";
import AddressField from "component/FormFields/components/AddressField";
import GroupsField from "component/FormFields/components/GroupsField";
import CurrencyInput from "component/input/CurrencyInput";
import { NumberField } from "@mapmycustomers/ui";
import { RawFile } from "@mapmycustomers/shared/types/File";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import styles from "component/FormFields/FormFields.module.scss";
import Tooltip from "antd/es/tooltip";
import Row from "antd/es/row";
import Col from "antd/es/col";
import CalculatedFieldInfoIcon from "../../CalculatedFieldInfoIcon";
import CustomFieldType from "@mapmycustomers/shared/types/customField/CustomField";
import layout from "../../../styles/layout";

export const createFormCustomFieldNamePathGetter = (customField: CustomField) => [
  "customFields",
  String(customField.id),
];
export const editFormCustomFieldNamePathGetter = (customField: CustomField) => [
  "customFields",
  customField.esKey,
];

const fileFieldValidator = (intl: IntlShape) => (_: any, value: unknown) => {
  if (Array.isArray(value) && value.length) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error(
      intl.formatMessage({
        id: "formFields.files.validator.required",
        defaultMessage: "File is required. Please upload at least one file.",
        description: "An error message to appear when files are not provided",
      })
    )
  );
};

const getCustomFieldLabel = (field: CustomFieldType) => {
  if (field.isCalculated) {
    return (
      <Row align="middle" gutter={layout.spacerXS}>
        <Col>{field.displayName}</Col>
        <Col>
          <CalculatedFieldInfoIcon customField={field} />
        </Col>
      </Row>
    );
  }
  return field.displayName;
};

interface Options {
  customFieldNamePathGetter?: (customField: CustomField) => string | string[];
  fileComponent?: ComponentType<{
    disabled?: boolean;
    onChange?: (uploadedFiles: RawFile[]) => void;
    required?: boolean;
  }>;
  ignoreRequired?: boolean;
  isCreateForm?: boolean;
}

const useFormFieldRender = (
  entityType: EntityTypesSupportingFieldCustomization,
  children: ReactNode | ReactNode[],
  actualSchema: LayoutSchemaField[],
  options?: Options
) => {
  const intl = useIntl();

  return useCallback(
    (
      field: IField,
      schemaField: LayoutSchemaField,
      readOnly: boolean = false,
      isVariant: boolean = false
    ) => {
      const required =
        (schemaField.required ||
          // we assume field is required via isSystemRequired only if it's not a relationship field
          (field.isSystemRequired && !field.hasFeature(FieldFeature.RELATIONSHIPS))) &&
        !readOnly &&
        options?.ignoreRequired !== true;

      const conditionalField = React.Children.toArray(children).find(getFieldMatcher(field));
      if (conditionalField) {
        return React.cloneElement(conditionalField, {
          ...conditionalField.props,
          disabled: readOnly,
          label: field.displayName,
          required,
          variant: isVariant,
        });
      }

      // we assume that these fields are always conditional fields
      if (
        schemaField.field === "accountId" ||
        schemaField.field === "contactId" ||
        schemaField.field === "dealId"
      ) {
        loggingService.error(
          "FormFields doesn't process relationship fields, add them as a ConditionalFormField",
          { field: schemaField.field }
        );
        return null;
      }

      // if (field.hasFeature(FieldFeature.FREQUENCY_INTERVAL) && !options?.isCreateForm) {
      if (field.hasFeature(FieldFeature.FREQUENCY_INTERVAL)) {
        // normally, users can edit frequency interval at the top of the preview pane
        // so we don't need to display in the form itself
        // UPD: until TART-15669 is done, removing the isCreateForm condition
        return null;
      }

      if (schemaField.field === "parentAccountId") {
        return (
          <ParentCompanyField
            disabled={readOnly}
            entityType={entityType as EntityTypesSupportingCompanyAssociation}
            required={required}
          />
        );
      }

      // lastName is a connected field, so they both are rendered here
      if (schemaField.field === "firstName") {
        return (
          <PersonNameField actualSchema={actualSchema} disabled={readOnly} required={required} />
        );
      }

      if (schemaField.field === "files") {
        const FileComponent = options?.fileComponent;
        return FileComponent ? (
          <Form.Item
            label={undefined}
            name={field.name}
            required={required}
            rules={required ? [{ validator: fileFieldValidator(intl) }] : []}
          >
            <FileComponent disabled={readOnly} required={required} />
          </Form.Item>
        ) : null;
      }

      if (field.hasFeature(FieldFeature.ADDRESS)) {
        return <AddressField disabled={readOnly} required={required} />;
      }

      if (isCustomField(field)) {
        const definition = field.customFieldData;
        const namePathGetter =
          options?.customFieldNamePathGetter ?? editFormCustomFieldNamePathGetter;
        return (
          <Form.Item
            label={getCustomFieldLabel(definition)}
            name={namePathGetter(definition)}
            required={required}
            rules={required ? getCustomFieldsValidationRules(intl, definition) : undefined}
          >
            <CustomFieldComponent
              definition={definition}
              disabled={readOnly}
              entityType={entityType}
            />
          </Form.Item>
        );
      }

      switch (field.name) {
        case ActivityFieldName.NAME:
        case CompanyFieldName.NAME:
        case DealFieldName.NAME:
        case PersonFieldName.NAME:
          const nameField = getFieldModelByEntityType(entityType)?.getByName(
            entityType === EntityType.PERSON ? PersonFieldName.FIRST_NAME : "name"
          );
          const nameReadable = nameField && nameField.isReadable;
          return (
            <Tooltip
              title={intl.formatMessage({
                id: "formFields.name.masked.tooltip",
                defaultMessage: "Protected Information",
                description: "Tooltip to show when name field is masked",
              })}
              trigger={nameReadable ? [] : ["hover"]}
            >
              <Form.Item
                label={field.displayName}
                name={field.name}
                required={required}
                rules={[{ required }]}
              >
                <TextField
                  disabled={readOnly || !nameReadable}
                  suffix={nameReadable ? undefined : <FontAwesomeIcon icon={faLock} />}
                />
              </Form.Item>
            </Tooltip>
          );

        case CompanyFieldName.WEBSITE:
        case CompanyFieldName.EMAIL:
        case PersonFieldName.EMAIL:
          return (
            <Form.Item
              label={field.displayName}
              name={field.name}
              required={required}
              rules={[{ required }]}
            >
              <TextField disabled={readOnly} />
            </Form.Item>
          );
      }

      if (field.hasFeature(FieldFeature.COLOR_FIELD)) {
        return (
          <Form.Item
            label={field.displayName}
            name={field.name}
            required={required}
            rules={[{ required }]}
          >
            <CircleColorPicker disabled={readOnly} />
          </Form.Item>
        );
      }

      if (field.hasFeature(FieldFeature.GROUP_FIELD)) {
        return (
          <GroupsField
            disabled={readOnly}
            // we know that if GROUP_FIELD is available for some entityType, it must be EntityTypeSupportingGroups
            entityType={entityType as EntityTypeSupportingGroups}
            field={field}
            required={required}
          />
        );
      }

      const isReadOnlyDateField = ["createdAt", "updatedAt"].includes(field.name);
      if (isReadOnlyDateField) {
        return <Tag>{formatDate(Date.now(), "PPPpp")}</Tag>;
      }

      if ([FieldType.DATE, FieldType.DATE_TIME].includes(field.type)) {
        return (
          <Form.Item
            label={field.displayName}
            name={field.name}
            required={required}
            rules={[{ required }]}
          >
            <DatePicker className={styles.full} disabled={readOnly} />
          </Form.Item>
        );
      }

      if (field.hasFeature(FieldFeature.MONETARY_VALUE)) {
        return (
          <Form.Item
            label={field.displayName}
            name={field.name}
            required={required}
            rules={[{ required }]}
          >
            <CurrencyInput disabled={readOnly} />
          </Form.Item>
        );
      }

      if (field.type === FieldType.NUMBER) {
        const numericOptions = field.formProperties?.numericOptions;
        return (
          <Form.Item
            label={field.displayName}
            name={field.name}
            required={required}
            rules={[{ required }]}
          >
            <NumberField disabled={readOnly} inputClassName={styles.full} {...numericOptions} />
          </Form.Item>
        );
      }

      // Not specifically covered field rendered as generic text input
      return (
        <Form.Item
          label={field.displayName}
          name={field.name}
          required={required}
          rules={[{ required }]}
        >
          <TextField disabled={readOnly} />
        </Form.Item>
      );
    },
    [
      options?.ignoreRequired,
      options?.fileComponent,
      options?.customFieldNamePathGetter,
      children,
      actualSchema,
      intl,
      entityType,
    ]
  );
};

export default useFormFieldRender;

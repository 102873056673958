import React, { useCallback } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Popconfirm from "antd/es/popconfirm";
import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";
import { RawFile } from "@mapmycustomers/shared/types/File";
import { stopEvents } from "util/browser";
import AccessStatus from "@mapmycustomers/shared/types/entity/common/AccessStatus";
import styles from "./FileRow.module.scss";
import { FileIcon, FileIconProps } from "@mapmycustomers/ui";
import cn from "classnames";

interface Props extends Pick<FileIconProps, "size"> {
  accessStatus?: AccessStatus;
  canDelete?: boolean;
  className?: string;
  file: RawFile;
  onClick: (file: RawFile) => void;
  onDelete: (file: RawFile) => void;
  onDownload: (file: RawFile) => void;
}

const FileRow: React.FC<Props> = ({
  accessStatus,
  canDelete,
  className,
  file,
  onClick,
  onDelete,
  onDownload,
  size = "default",
}) => {
  const intl = useIntl();
  const handleClick = useCallback(() => onClick(file), [file, onClick]);
  const handleDownload = useCallback(() => onDownload(file), [file, onDownload]);
  const handleDelete = useCallback(() => onDelete(file), [file, onDelete]);

  return (
    <div
      className={cn(styles.file, className, {
        [styles.large]: size === "default",
        [styles.small]: size === "small",
      })}
      onClick={handleClick}
    >
      <div className={styles.title}>
        <FileIcon className={styles.fileIcon} file={file} size={size} />
        <div className={styles.name}>
          <Tooltip title={file.name}>{file.name}</Tooltip>
        </div>
      </div>
      <div className={styles.actions} onClick={stopEvents}>
        <Button icon={<FontAwesomeIcon icon={faDownload} />} onClick={handleDownload} type="text" />
        <Tooltip
          placement="left"
          title={intl.formatMessage({
            id: "component.fileRow.delete.disallowed",
            defaultMessage:
              "You do not have permission to use this feature. Please contact your admin to request a change.",
            description: "A tooltip to show when user has no rights to delete a file",
          })}
          trigger={canDelete ? [] : ["hover"]}
        >
          <Popconfirm
            cancelText={intl.formatMessage({
              id: "component.fileRow.deleteConfirmation.cancel",
              defaultMessage: "Cancel",
              description: "Cancel-confirmation text for other files section",
            })}
            disabled={!accessStatus?.delete || !canDelete}
            okText={intl.formatMessage({
              id: "component.fileRow.deleteConfirmation.yes",
              defaultMessage: "Yes",
              description: "Yes-confirmation text for other files section",
            })}
            onConfirm={handleDelete}
            title={intl.formatMessage({
              id: "component.fileRow.delete",
              defaultMessage: "Delete file?",
              description: "Delete-confirmation text for other files section",
            })}
          >
            <Button
              danger
              disabled={!accessStatus?.delete || !canDelete}
              icon={<FontAwesomeIcon icon={faTrash} />}
              type="link"
            />
          </Popconfirm>
        </Tooltip>
      </div>
    </div>
  );
};

export default FileRow;

import { ActionType, createAsyncAction } from "typesafe-actions";
import { Deal } from "@mapmycustomers/shared/types/entity";
import ListRequest from "@mapmycustomers/shared/types/viewModel/internalModel/ListRequest";
import { OnlyRequiredFields } from "@mapmycustomers/shared/util/ts";

export const fetchDeals = createAsyncAction(
  "@component/previewDealsListModal/fetchDeals/request",
  "@component/previewDealsListModal/fetchDeals/success",
  "@component/previewDealsListModal/fetchDeals/failure"
)<
  {
    request: OnlyRequiredFields<ListRequest, "filter" & "range">;
  },
  {
    total: number;
    deals: Deal[];
  },
  void
>();

export type Actions = ActionType<typeof fetchDeals>;

import ImportStatus from "@mapmycustomers/shared/enum/imports/ImportStatus";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  [ImportStatus.ERRORED]: {
    id: "importStatus.errored",
    description: "ERRORED import",
    defaultMessage: "Errored",
  },
  [ImportStatus.COMPLETED]: {
    id: "importStatus.completed",
    description: "COMPLETED import",
    defaultMessage: "Completed",
  },
  [ImportStatus.QUEUED]: {
    id: "importStatus.queued",
    description: "QUEUED import",
    defaultMessage: "Queued",
  },
  [ImportStatus.RUNNING]: {
    defaultMessage: "Running",
    description: "RUNNING import",
    id: "importStatus.running",
  },
});

const getImportStatusDisplayName = (intl: IntlShape, status: ImportStatus): string =>
  intl.formatMessage(messages[status]);

export default getImportStatusDisplayName;

enum ActivityStatus {
  COMPLETED = "completed",
  OVERDUE = "overdue",
  UPCOMING = "upcoming",
  UNKNOWN = "unknown",
}

export type TodaySupportedByRepActivityStatus =
  | ActivityStatus.COMPLETED
  | ActivityStatus.UPCOMING
  | ActivityStatus.OVERDUE;

export default ActivityStatus;

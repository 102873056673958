import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import cn from "classnames";
import "./LargeTextField.scss";
import AntDTextArea, {
  TextAreaProps as AntdTextAreaProps,
  TextAreaRef,
} from "antd/es/input/TextArea";
import Labeled, { LabeledFieldProps } from "../../Labeled";
import { bem } from "@react-md/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { useConfigProvider } from "../../../ConfigProvider";
import Footer from "../Footer";
import { LARGE_TEXT_LENGTH_LIMIT } from "@mapmycustomers/shared/util/consts";

export const messages = {
  "ui.inlineInput.largeTextField.unknown": "Unknown",
};

interface InlineLargeTextProps
  extends Omit<AntdTextAreaProps, "onChange" | "allowClear" | "rows">,
    Omit<LabeledFieldProps, "children"> {
  caption?: string;
  disabled?: boolean;
  onChange?: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const block = bem("mmc-inline-large-text-field");

const LargeTextField: React.FC<InlineLargeTextProps> = ({
  caption,
  className,
  disabled,
  label,
  labelClassName,
  labelPosition = "side",
  maxLength = LARGE_TEXT_LENGTH_LIMIT,
  onChange,
  required,
  style,
  value,
  ...props
}) => {
  const configProvider = useConfigProvider();
  const [inlineValue, setInlineValue] = useState<string | undefined>(value);

  const [editing, startEditing, cancelEditing] = useBoolean();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) =>
      setInlineValue(event.target.value),
    []
  );

  const handleSave = useCallback(() => {
    cancelEditing();
    onChange?.(inlineValue ?? "");
  }, [inlineValue, cancelEditing, onChange]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === "Escape") {
        event.stopPropagation();
        cancelEditing();
      } else if (event.key === "Enter") {
        event.stopPropagation();
        handleSave();
      }
    },
    [cancelEditing, handleSave]
  );

  const inputRef = useRef<TextAreaRef>(null);
  const handleStartEditing = useCallback(() => {
    if (disabled) {
      return;
    }
    setInlineValue(value);
    startEditing();
    // can't focus hidden field, need to wait until it will be displayed
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [disabled, setInlineValue, startEditing, value]);

  const handleBlur = useCallback(
    (e) => {
      // To prevent blur action when cancel button is clicked as we save text onblur
      if (
        e.relatedTarget &&
        e.relatedTarget.classList.contains("mmc-js-skip-save-on-blur")
      ) {
        e.preventDefault();
        cancelEditing();
        return;
      }
      handleSave();
    },
    [handleSave]
  );

  return (
    <div className={cn(block({ disabled: !!disabled }), className)}>
      <Labeled
        label={<div className={block("label-item")}>{label}</div>}
        labelClassName={cn(block("label"), labelClassName)}
        labelPosition={labelPosition}
        extra={
          disabled ? (
            <FontAwesomeIcon className={block("lock")} icon={faLock} />
          ) : undefined
        }
        required={required}
      >
        {editing ? (
          <AntDTextArea
            allowClear
            autoSize
            className={block("input")}
            disabled={disabled}
            maxLength={maxLength}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            prefix=""
            ref={inputRef}
            required={required}
            style={{ maxHeight: 500, resize: "none", ...style }}
            size="large"
            value={inlineValue}
            {...props}
          />
        ) : (
          <div className={block("value")} onClick={handleStartEditing}>
            {value ?? (
              <span className={block("unknown")}>
                {configProvider.formatMessage(
                  "ui.inlineInput.largeTextField.unknown"
                )}
              </span>
            )}
          </div>
        )}
      </Labeled>
      <Footer
        caption={caption}
        disabled={disabled}
        editing={editing}
        onCancel={cancelEditing}
        onSave={handleSave}
      />
    </div>
  );
};

export default LargeTextField;

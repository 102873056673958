import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Field, { FieldProperties } from "./Field";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import LossReasonFilter, { LOSS_REASON_FILTER_OPERATORS } from "../../filters/LossReason";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import EmptyFilter from "../../filters/EmptyFilter";

class LossReasonField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      valueGetter: ["dealLossReason", "name"],
      type: FieldType.STRING,
      platformFilterName: "dealLossReasonId",
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.DETAILS,
        FieldFeature.SUPPORTS_SET_FILTER,
      ],
      importProperties: {
        name: "dealLossReason",
      },
      customFilterConfig: {
        defaultInstance: "lossReason",
        filterInstances: {
          lossReason: LossReasonFilter,
          empty: EmptyFilter,
        },
        operators: [
          ...LOSS_REASON_FILTER_OPERATORS,
          { operator: FilterOperator.EMPTY, instance: "empty" },
          { operator: FilterOperator.NOT_EMPTY, instance: "empty" },
        ],
      },
      ...data,
    });
  }
}

export default LossReasonField;

import { MapEntry } from "types/map";

const defaultMapEntryGeometryGetter = (
  entry: MapEntry
): Exclude<google.maps.Data.FeatureOptions["geometry"], undefined> =>
  new google.maps.Data.Point({
    // we guarantee that the entries we call is function for will always have region defined
    lat: entry.region!.latitude,
    lng: entry.region!.longitude,
  });

export default defaultMapEntryGeometryGetter;

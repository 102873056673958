import { Actions, subscribe, UiSyncListener, unsubscribe } from "./actions";
import { createReducer } from "typesafe-actions";
import { EntityType } from "@mapmycustomers/shared/enum";

export type UiSyncState = {
  listeners: Partial<Record<EntityType, UiSyncListener[]>>;
};

const initialState: UiSyncState = {
  listeners: {},
};

const uiSync = createReducer<UiSyncState, Actions>(initialState)
  .handleAction(subscribe, (state, action) => ({
    ...state,
    listeners: action.payload.entityTypes.reduce(
      (result, entityType) => ({
        ...result,
        [entityType]: [...(result[entityType] ?? []), action.payload.listener],
      }),
      state.listeners
    ),
  }))
  .handleAction(unsubscribe, (state, action) => ({
    ...state,
    listeners: action.payload.entityTypes.reduce(
      (result, entityType) => ({
        ...result,
        [entityType]: (result[entityType] ?? []).filter(
          (listener) => listener !== action.payload.listener
        ),
      }),
      state.listeners
    ),
  }));

export * from "./selectors";
export type UiSyncActions = Actions;
export default uiSync;

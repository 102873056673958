import React, { ReactNode, useCallback } from "react";
import styles from "./FrequencyCollapsePanel.module.scss";
import cn from "classnames";
import ModernCollapse, { CollapsePanel } from "../../Collapse/ModernCollapse/ModernCollapse";

const PANEL_KEY = "frequency-collapse";

interface Props {
  children: ReactNode;
  className?: string;
  header: ReactNode;
  isOpenByDefault?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

const FrequencyCollapsePanel: React.FC<Props> = ({
  children,
  className,
  header,
  isOpenByDefault,
  onClose,
  onOpen,
}) => {
  const handleChange = useCallback(
    (key: string | string[]) => {
      if (Array.isArray(key) && key.length) {
        onOpen?.();
      } else {
        onClose?.();
      }
    },
    [onClose, onOpen]
  );
  return (
    <ModernCollapse
      className={cn(styles.container, className)}
      defaultActiveKey={isOpenByDefault ? PANEL_KEY : undefined}
      onChange={handleChange}
    >
      <CollapsePanel key={PANEL_KEY} header={header}>
        <div className={styles.content}>{children}</div>
      </CollapsePanel>
    </ModernCollapse>
  );
};

export default FrequencyCollapsePanel;

import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const componentState = (state: RootState) => state.component.addToRouteModal;

export const isAdding = createSelector(
  componentState,
  ({ addToRouteLoading }) => addToRouteLoading
);
export const isCreating = createSelector(componentState, ({ createLoading }) => createLoading);
export const isInitializing = createSelector(
  componentState,
  ({ initializeLoading }) => initializeLoading
);
export const getRoutes = createSelector(componentState, ({ routes }) => routes);
export const getSelectedRouteId = createSelector(
  componentState,
  ({ selectedRouteId }) => selectedRouteId
);

export const isCreateRouteModalVisible = createSelector(
  componentState,
  ({ createRouteModalVisible }) => createRouteModalVisible
);

export const getEntities = createSelector(componentState, ({ entities }) => entities);

export const getAutoAssignOnCreate = createSelector(
  componentState,
  ({ autoAssignOnCreate }) => autoAssignOnCreate
);

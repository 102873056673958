import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  [ActivityStatusOption.ALL]: {
    id: "activityStatusOption.all",
    description: "Activity Status Option all",
    defaultMessage: "Any Status",
  },
  [ActivityStatusOption.COMPLETED]: {
    id: "activityStatusOption.completed",
    description: "Activity Status Option Done",
    defaultMessage: "Done",
  },
  [ActivityStatusOption.INCOMPLETE]: {
    id: "activityStatusOption.incomplete",
    description: "Activity Status Option Not yet done",
    defaultMessage: "Not yet done",
  },
  [ActivityStatusOption.OVERDUE]: {
    id: "activityStatusOption.overdue",
    description: "Activity Status Option Overdue",
    defaultMessage: "Overdue",
  },
  [ActivityStatusOption.UPCOMING]: {
    id: "activityStatusOption.upcoming",
    description: "Activity Status Option Upcoming",
    defaultMessage: "Upcoming",
  },
  [ActivityStatusOption.NOT_SCHEDULED]: {
    id: "activityStatusOption.notScheduled",
    description: "Activity Status Option Not Scheduled",
    defaultMessage: "Not Scheduled",
  },
});

const getActivityStatusOptionDisplayName = (
  intl: IntlShape,
  activityStatus: ActivityStatusOption
) => {
  return intl.formatMessage(messages[activityStatus]);
};

export default getActivityStatusOptionDisplayName;

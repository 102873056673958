import { RootState } from "../rootReducer";
import { createSelector } from "reselect";
import memoize from "fast-memoize";
import { stateFieldBaseName } from "./util";
import { EntityTypeSupportingGroups } from "@mapmycustomers/shared/types/entity";
import Group from "@mapmycustomers/shared/types/entity/Group";

const groupsState = (state: RootState) => state.groups;
export const isAnythingLoading = createSelector(
  groupsState,
  (state) => state.companyGroupsLoading || state.dealGroupsLoading || state.peopleGroupsLoading
);
export const getAnyError = createSelector(
  groupsState,
  (state) => state.companyGroupsError || state.dealGroupsError || state.peopleGroupsError
);

export const getCompanyGroups = createSelector(groupsState, ({ companyGroups }) => companyGroups);
export const getDealGroups = createSelector(groupsState, ({ dealGroups }) => dealGroups);
export const getPeopleGroups = createSelector(groupsState, ({ peopleGroups }) => peopleGroups);
// same as selectors above, but accepts the entityType argument
export const getGroupsForEntity = createSelector(groupsState, (state) =>
  memoize(
    (entityType: EntityTypeSupportingGroups): Group[] => state[stateFieldBaseName[entityType]]
  )
);

export const isCreatingGroup = createSelector(
  groupsState,
  ({ createGroupLoading }) => createGroupLoading
);

export const isUpdating = createSelector(
  groupsState,
  ({ updateEntitiesLoading }) => updateEntitiesLoading
);

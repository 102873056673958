import { put, select, takeLatest } from "redux-saga/effects";
import get from "lodash-es/get";
import {
  applyRecordsListSettings,
  fetchMultiPinRecords,
  showMultiPinRecords,
  updateMultiPinRecordsSorting,
} from "./actions";
import { handleError } from "store/errors/actions";
import {
  getMultiPin,
  getMultiPinLocationQuery,
  getMultiPinRecordsListFilter,
  getMultiPinRecordsListState,
  getPlatformFilterModel,
  getVisibleMultiPinEntities,
} from "./selectors";
import { getAllColorLegends, getAllShapeLegends } from "store/pinLegends";
import { getOrganizationId, getOrganizationSettingValue } from "store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import { convertToPlatformSortModel } from "util/viewModel/convertSort";
import { callApi } from "store/api/callApi";
import { MultiPin } from "types/map";
import {
  EntitiesSupportingMultiPin,
  EntityTypesSupportedByMapsPage,
  EntityTypesSupportingMultiPin,
  MapPersistedRecordListConfiguration,
} from "@mapmycustomers/shared/types/map/types";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import convertMapFilterToPlatformFilterModel from "util/viewModel/convertMapFilterToPlatformFilterModel";
import { MAP_ENTITY_TYPES } from "util/map/consts";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import DistanceUnit from "enum/DistanceUnit";
import OrganizationSetting from "enum/OrganizationSetting";
import { UniversalFieldName } from "util/fieldModel/universalFieldsFieldModel";
import { updateMetadata } from "store/iam/actions";
import getColorShapeForEntity from "util/map/markerStyles/getColorShapeForEntity";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import { AreaSearchQuery } from "types/filters/AreaSearchQuery";
import {
  isAddressAreaSearchQuery,
  isCoordinatesAreaSearchQuery,
  isEntityAreaSearchQuery,
} from "component/input/AreaSearchInput/utils/assert";
import isNumber from "lodash-es/isNumber";
import { getMapViewSettings } from "store/map";
import MapFilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/MapFilterModel";
import { getMapRecordsListSettings } from "store/map/selectors";
import type MapRecordsListState from "types/viewModel/MapRecordsListState"; // const MAX_ITEMS_TO_DOWNLOAD_FILE = 10000;
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import deepmerge from "deepmerge";

// const MAX_ITEMS_TO_DOWNLOAD_FILE = 10000;

// const messages = defineMessages({
//   dataFileTooLarge: {
//     id: "map.recordList.exportFiles.dataFileTooLarge.warning",
//     defaultMessage:
//       "Data {multiple, select, true {files} other {file}} too large to download right now",
//     description: "Message shown when sample companies are added",
//   },
//   dataFileTooLargeDescription: {
//     id: "map.recordList.exportFiles.dataFileTooLarge.warning.description",
//     defaultMessage:
//       "Your export has been queued for processing and will be sent to your email once finished.",
//     description: "Message shown when sample people are added",
//   },
//   success: {
//     id: "map.recordList.exportFiles.success",
//     defaultMessage: "{multiple, select, true {Files} other {File}} Downloaded",
//     description: "Message shown when sample people are added",
//   },
//   error: {
//     id: "map.recordList.exportFiles.error.message",
//     defaultMessage: "Download Error",
//     description: "Message shown when download fail",
//   },
//   errorDescription: {
//     id: "map.recordList.exportFiles.error.description",
//     defaultMessage:
//       "There was a problem downloading your {entityType} data. Please try again. If this problem persists, please contact support@mapmycustomers.me.",
//     description: "Description shown when download fail",
//   },
// });

export function* onFetchRecords({ payload }: ReturnType<typeof fetchMultiPinRecords.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const mapViewState: MapViewState = yield select(getMapViewSettings);
    const recordsListState: MapViewState = yield select(getMultiPinRecordsListState);
    const multiPin: MultiPin | undefined = yield select(getMultiPin);
    const searchFilter: string | undefined = yield select(getMultiPinRecordsListFilter);
    const platformFilterModel: PlatformFilterModel | undefined = yield select(
      getPlatformFilterModel
    );
    // mapRecordsListSettings

    const visibleEntityTypes: EntityTypesSupportingMultiPin[] = yield select(
      getVisibleMultiPinEntities
    );

    const $offset = payload.request.range
      ? payload.request.range.startRow
      : recordsListState.range.startRow;
    const $limit = payload.request.range
      ? payload.request.range.endRow - payload.request.range.startRow
      : recordsListState.range.endRow - recordsListState.range.startRow;

    if (!multiPin) {
      yield put(
        fetchMultiPinRecords.success({
          accessible: 0,
          count: 0,
          data: [],
          limit: $limit,
          offset: 0,
          total: 0,
        })
      );
      return;
    }
    const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
    const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);

    const bounds = multiPin.bounds;
    const isActivityOnly =
      visibleEntityTypes.length === 1 && visibleEntityTypes[0] === EntityType.ACTIVITY;

    let mapFilters: PlatformFilterModel = {};
    let pinLegendFilter: PlatformFilterModel = {};

    let order;
    if (recordsListState.sort?.length > 0) {
      const sortField = recordsListState.sort[0].field;
      const sortOrder = recordsListState.sort[0].order;

      if (sortField?.sortName === UniversalFieldName.GEOPOINT) {
        const getOrgSetting: <T = any>(settingName: string, defaultValue?: T) => T = yield select(
          getOrganizationSettingValue
        );
        const unit = getOrgSetting<DistanceUnit>(
          OrganizationSetting.DISTANCE_UNIT,
          DistanceUnit.MILE
        );
        const query: AreaSearchQuery = yield select(getMultiPinLocationQuery);
        let coordinates: LongLat | undefined;
        if (isCoordinatesAreaSearchQuery(query) || isAddressAreaSearchQuery(query)) {
          coordinates = query.coordinates;
        } else if (isEntityAreaSearchQuery(query) && query.entity.geoPoint?.coordinates) {
          coordinates = query.entity.geoPoint?.coordinates;
        }
        if (coordinates) {
          order = {
            order: sortOrder === SortOrder.DESC ? "desc" : "asc",
            point: coordinates,
            unit,
          };
        }
      } else {
        order = convertToPlatformSortModel(recordsListState.sort);
      }
    }

    if (isActivityOnly) {
      const requestPayload = {
        $offset,
        $limit,
        $filters: deepmerge(
          {
            bounds,
            cadence: true,
            includeAccessStatus: true,
            includeCustomFields: true,
          },
          platformFilterModel ?? {}
        ),
        $order: order,
      };

      const response: ListResponse<EntitiesSupportingMultiPin> = yield callApi(
        "fetchActivities",
        orgId,
        requestPayload
      );
      yield put(fetchMultiPinRecords.success(response));
    } else {
      mapFilters = convertMapFilterToPlatformFilterModel(
        {} as MapFilterModel,
        visibleEntityTypes as EntityTypesSupportedByMapsPage[],
        undefined
      );
      const entities = mapFilters.entities as Record<
        EntityTypesSupportingMultiPin,
        PlatformFilterModel
      >;
      Object.keys(entities).forEach((entityKey) => {
        const entity = get(mapFilters.entities, entityKey);
        entity.includeGroups = true;
      });
      if (searchFilter?.length) {
        // Inject filtering by name in records list
        Object.keys(entities).forEach((entityKey) => {
          const andCondition = get(mapFilters.entities, [entityKey, "$and"]);
          if (Array.isArray(andCondition)) {
            andCondition.push({
              name: {
                $in: searchFilter,
              },
            });
          }
        });
      }
      pinLegendFilter = {
        pinLegends: MAP_ENTITY_TYPES.reduce(
          (result, entityType) => ({
            ...result,
            [entityType]: getColorShapeForEntity(
              mapViewState,
              colorPinLegends,
              shapePinLegends,
              entityType
            ),
          }),
          {} as Record<
            EntityTypesSupportedByMapsPage,
            { color?: PinLegend["id"]; shape?: PinLegend["id"] }
          >
        ),
      };
      const requestPayload = {
        $offset,
        $limit,
        $filters: {
          ...mapFilters,
          ...pinLegendFilter,
          bounds,
          cadence: true,
          includeAccessStatus: true,
          includeCustomFields: true,
        },
        $order: order,
      };

      const response: ListResponse<EntitiesSupportingMultiPin> = yield callApi(
        "fetchMapPins",
        orgId,
        requestPayload
      );
      yield put(fetchMultiPinRecords.success(response));
    }
  } catch (error) {
    yield put(fetchMultiPinRecords.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onShowMultiPinRecords({
  payload,
}: ReturnType<typeof showMultiPinRecords.request>) {
  try {
    const recordsListState: MapRecordsListState | undefined = yield select(
      getMapRecordsListSettings
    );
    if (recordsListState?.sort) {
      yield put(applyRecordsListSettings({ sort: recordsListState.sort }));
    }

    let address;

    if (
      isNumber(payload.multiPin.region?.longitude) &&
      isNumber(payload.multiPin.region?.latitude)
    ) {
      const orgId: Organization["id"] = yield select(getOrganizationId);

      const result: GeocodeResult = yield callApi("reverseGeocodeAddress", orgId, {
        type: "Point",
        coordinates: [payload.multiPin.region!.longitude, payload.multiPin.region!.latitude],
      });
      address = result.address.formattedAddress ?? "";
    }

    yield put(showMultiPinRecords.success({ address }));
  } catch (error) {
    yield put(showMultiPinRecords.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateRecordsListCustomization({
  payload,
}: ReturnType<typeof updateMultiPinRecordsSorting>) {
  try {
    const recordsListState: MapViewState = yield select(getMultiPinRecordsListState);

    const recordListConfig: MapPersistedRecordListConfiguration = {
      sort: {
        field: payload.sort?.[0]?.field.name ?? recordsListState.sort?.[0]?.field.name,
        order: payload.sort?.[0]?.order ?? recordsListState.sort?.[0]?.order,
      },
    };

    yield put(updateMetadata.request({ mapRecordsListSettings: recordListConfig }));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

// export function* onDownloadMapRecords() {
//   try {
//     const orgId: Organization["id"] = yield select(getOrganizationId);
//
//     const viewport: MapViewportState = yield select(getMapViewport);
//     const mapViewState: MapViewState = yield select(getMapViewState);
//     const recordsListState: MapViewState = yield select(getMultiPinRecordsListState);
//     const activeTool: MapTool | undefined = yield select(getMapViewTool);
//     const selectedRecords: Set<string> = yield select(getSelectedRecords);
//     const multiPin: MultiPin | undefined = yield select(getMultiPin);
//     const searchFilter = recordsListState.search?.trim();
//
//     const features: OrganizationMetaData["features"] = yield select(getFeatures);
//     const disallowColor = features?.[Feature.DISALLOW_COLOR]?.enabled;
//
//     const isLassoMode = activeTool === MapTool.LASSO;
//     const isMultiPin = !!multiPin?.id;
//
//     const lassoVisibleEntities: EntityTypesSupportingMultiPin[] = yield select(
//       getVisibleLassoEntityTypes
//     );
//     const lassoMode: LassoToolMode = yield select(getLassoMode);
//
//     const visibleEntityTypes =
//       isLassoMode && lassoMode === LassoToolMode.ADD_GROUP_PINS
//         ? lassoVisibleEntities
//         : mapViewState.visibleEntities;
//
//     const mapFilters = convertMapFilterToPlatformFilterModel(
//       mapViewState.filter,
//       visibleEntityTypes,
//       mapViewState.viewAs
//     );
//
//     const entities = mapFilters.entities as Record<
//       EntityTypesSupportingMultiPin,
//       PlatformFilterModel
//     >;
//     Object.keys(entities).forEach((entityKey) => {
//       const entity = get(mapFilters.entities, entityKey);
//       entity.includeGroups = true;
//     });
//
//     if (searchFilter?.length) {
//       // Inject filtering by name in records list
//       if (mapFilters.entities && !Array.isArray(mapFilters.entities)) {
//         const entities = mapFilters.entities as Record<
//           EntityTypesSupportingMultiPin,
//           PlatformFilterModel
//         >;
//         Object.keys(entities).forEach((entityKey) => {
//           const andCondition = get(mapFilters.entities, [entityKey, "$and"]);
//           if (Array.isArray(andCondition)) {
//             andCondition.push({
//               name: {
//                 $in: searchFilter,
//               },
//             });
//           }
//         });
//       }
//     }
//
//     const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
//     const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);
//
//     if (isLassoMode && selectedRecords.size === 0) {
//       // If we're in lasso, but haven't selected any records for whatever reason - it makes no sense
//       // to request detailed list of records from backend
//       yield put(clearRecordsList());
//       return;
//     }
//
//     if (isLassoMode && !isMultiPin) {
//       // Inject filtering by selected IDs
//       if (mapFilters.entities && !Array.isArray(mapFilters.entities)) {
//         const entityIdFilter: Record<EntityTypesSupportingMultiPin, Array<Identified["id"]>> = {
//           [EntityType.COMPANY]: [],
//           [EntityType.PERSON]: [],
//           [EntityType.DEAL]: [],
//         };
//         for (const mapEntityId of selectedRecords.values()) {
//           const parsed = mapEntityIdParser(mapEntityId);
//           if (parsed) {
//             entityIdFilter[parsed.entity as EntityTypesSupportingMultiPin].push(parsed.id);
//           }
//         }
//
//         const entities = mapFilters.entities as Record<
//           EntityTypesSupportingMultiPin,
//           PlatformFilterModel
//         >;
//         Object.keys(entities).forEach((key) => {
//           const entityKey = key as EntityTypesSupportingMultiPin;
//           if (visibleEntityTypes.includes(entityKey)) {
//             const andCondition = get(mapFilters.entities, [entityKey, "$and"]);
//             if (Array.isArray(andCondition) && Array.isArray(entityIdFilter[entityKey])) {
//               andCondition.push({
//                 id: {
//                   $in: entityIdFilter[entityKey],
//                 },
//               });
//             }
//           }
//         });
//       }
//     }
//
//     const bounds = isMultiPin ? multiPin?.bounds : isLassoMode ? undefined : viewport.bounds;
//     const pinLegendFilter = disallowColor
//       ? {
//           pinLegends: MAP_ENTITY_TYPES.reduce(
//             (result, entityType) => ({
//               ...result,
//               [entityType]: getColorShapeForEntity(
//                 mapViewState,
//                 colorPinLegends,
//                 shapePinLegends,
//                 entityType
//               ),
//             }),
//             {} as Record<
//               EntityTypesSupportingMultiPin,
//               { color?: PinLegend["id"]; shape?: PinLegend["id"] }
//             >
//           ),
//         }
//       : {};
//
//     let order: { order: string; point: number[]; unit: DistanceUnit } | undefined = undefined;
//     if (recordsListState.sort?.length > 0) {
//       const sortField = recordsListState.sort[0].field;
//       const sortOrder = recordsListState.sort[0].order;
//
//       if (sortField?.sortName === UniversalFieldName.GEOPOINT) {
//         const getOrgSetting: <T = any>(settingName: string, defaultValue?: T) => T = yield select(
//           getOrganizationSetting
//         );
//         const unit = getOrgSetting<DistanceUnit>(
//           OrganizationSetting.DISTANCE_UNIT,
//           DistanceUnit.MILE
//         );
//
//         const position: GeolocationPosition | undefined = yield select(getPosition);
//         if (
//           unit &&
//           position?.coords?.longitude !== undefined &&
//           position?.coords?.latitude !== undefined
//         ) {
//           order = {
//             order: sortOrder === SortOrder.DESC ? "desc" : "asc",
//             point: [position.coords.longitude, position.coords.latitude],
//             unit,
//           };
//         }
//       } else {
//         order = convertToPlatformSortModel(recordsListState.sort);
//       }
//     }
//     const intl = i18nService.getIntl();
//     const responses: MapRecordsResponse[] = yield all(
//       visibleEntityTypes.map((entityType) => {
//         const requiredEntity =
//           mapFilters.entities &&
//           (
//             mapFilters.entities as Partial<
//               Record<EntityTypesSupportingMultiPin, PlatformFilterModel>
//             >
//           )[entityType];
//         const checkFilters = { ...mapFilters, entities: { [entityType]: requiredEntity } };
//
//         const requestPayload = {
//           $limit: 0,
//           $filters: {
//             ...checkFilters,
//             ...pinLegendFilter,
//             bounds,
//             cadence: true,
//             includeCustomFields: true,
//           },
//           $order: order,
//         };
//
//         if (entityType === EntityType.COMPANY) {
//           return callApi("fetchMapPins", orgId, requestPayload);
//         } else if (entityType === EntityType.PERSON) {
//           return callApi("fetchMapPins", orgId, requestPayload);
//         } else if (entityType === EntityType.DEAL) {
//           return callApi("fetchMapPins", orgId, requestPayload);
//         } else {
//           return Promise.resolve({
//             accessible: 0,
//             count: 0,
//             data: [],
//             limit: 0,
//             offset: 0,
//             total: 0,
//           });
//         }
//       })
//     );
//
//     if (responses.some((response) => response.total > MAX_ITEMS_TO_DOWNLOAD_FILE)) {
//       const currentUser: User = yield select(getCurrentUser);
//       const visibleEntitiesPayload = visibleEntityTypes.map((entityType) => {
//         const $filters = convertToPlatformFilterModel(
//           convertMapFilterModelToFilterModelForEntity(entityType, mapViewState.filter),
//           getFieldModelByEntityType(entityType).fields.map((field) => ({ field, visible: true })),
//           getFieldModelByEntityType(entityType),
//           true
//         );
//
//         const payload = {
//           name: `${entityType} ${formatDate(new Date(), "Pp")}`,
//           description: "",
//           selectedColumns: getFieldModelByEntityType(entityType).fields.map((field) => field.name),
//           selectedFilters: {
//             ...$filters,
//             ...pinLegendFilter,
//             bounds,
//             cadence: true,
//             includeCustomFields: true,
//           },
//           tableName: entityType,
//         };
//         return payload;
//       });
//
//       for (let payload of visibleEntitiesPayload) {
//         try {
//           const report: Report = yield callApi("createReport", orgId, payload);
//           yield callApi("generateReport", orgId, report.id, currentUser.username);
//         } catch (e) {
//           notification.error({
//             message: intl?.formatMessage(messages.error),
//             description: getErrorNotificationDescription(intl, payload.tableName),
//           });
//         }
//       }
//       if (intl) {
//         notification.warning({
//           message: intl.formatMessage(messages.dataFileTooLarge, {
//             multiple: visibleEntityTypes.length > 1,
//           }),
//           description: intl.formatMessage(messages.dataFileTooLargeDescription),
//         });
//       }
//     } else {
//       for (let item of visibleEntityTypes) {
//         const requiredEntity =
//           mapFilters?.entities &&
//           (
//             mapFilters.entities as Partial<
//               Record<EntityTypesSupportingMultiPin, PlatformFilterModel>
//             >
//           )[item];
//         const checkFilters = { ...mapFilters, entities: { [item]: requiredEntity } };
//         const $limit = 10000;
//         const requestPayload = {
//           $limit,
//           $filters: {
//             ...checkFilters,
//             ...pinLegendFilter,
//             bounds,
//             cadence: true,
//             includeCustomFields: true,
//           },
//           $order: order,
//         };
//         const response: MapRecordsResponse = yield callApi("fetchMapPins", orgId, requestPayload);
//
//         if (item === EntityType.COMPANY) {
//           downloadEntityPreviewsAsCsv(response.data, ReportType.COMPANIES);
//         } else if (item === EntityType.PERSON) {
//           downloadEntityPreviewsAsCsv(response.data, ReportType.PEOPLE);
//         } else if (item === EntityType.DEAL) {
//           downloadEntityPreviewsAsCsv(response.data, ReportType.DEALS);
//         }
//       }
//       if (intl) {
//         notification.success({
//           message: intl.formatMessage(messages.success, {
//             multiple: visibleEntityTypes.length > 1,
//           }),
//         });
//       }
//     }
//   } catch (error) {
//     yield put(handleError({ error }));
//   }
// }

export function* multiPinSagas() {
  yield takeLatest(fetchMultiPinRecords.request, onFetchRecords);
  yield takeLatest(showMultiPinRecords.request, onShowMultiPinRecords);
  yield takeLatest(updateMultiPinRecordsSorting, onUpdateRecordsListCustomization);
  // yield takeLatest(downloadMultiPinRecords, onDownloadMapRecords);
}

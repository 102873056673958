import FrequencyActivityAction from "@mapmycustomers/shared/enum/frequency/FrequencyActivityAction";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  [FrequencyActivityAction.SCHEDULE_ACTIVITY]: {
    id: "frequencyActivityAction.scheduleActivity",
    defaultMessage: "Schedule Activity",
    description: "Frequency Activity Action - Schedule Activity",
  },
  [FrequencyActivityAction.ADD_TO_ROUTE]: {
    id: "frequencyActivityAction.addToRoute",
    defaultMessage: "Add to Route",
    description: "Frequency Activity Action - Add to Route",
  },
  [FrequencyActivityAction.VIEW_ACTIVITY_HISTORY]: {
    id: "frequencyActivityAction.viewActivityHistory",
    defaultMessage: "Activity History",
    description: "Frequency Activity Action - View Activity History",
  },
});

const getFrequencyActivityActionDisplayName = (intl: IntlShape, action: FrequencyActivityAction) =>
  intl.formatMessage(messages[action]);

export default getFrequencyActivityActionDisplayName;

import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";

const dealState = (state: RootState) => state.deal;

export const getFunnels = createSelector(dealState, ({ funnels }) => funnels);
export const getFunnelStages = createSelector(dealState, ({ funnelStages }) => funnelStages);

export const isCreatingFunnel = createSelector(
  dealState,
  ({ createFunnelLoading }) => createFunnelLoading
);
export const isUpdatingFunnel = createSelector(
  dealState,
  ({ updateFunnelLoading }) => updateFunnelLoading
);
export const isDeletingFunnel = createSelector(
  dealState,
  ({ deleteFunnelLoading }) => deleteFunnelLoading
);

export const isCreatingStage = createSelector(
  dealState,
  ({ createStageLoading }) => createStageLoading
);
export const isUpdatingStage = createSelector(
  dealState,
  ({ updateStageLoading }) => updateStageLoading
);
export const isDeletingStage = createSelector(
  dealState,
  ({ deleteStageLoading }) => deleteStageLoading
);

export const isUpdatingDeal = createSelector(
  dealState,
  ({ updateDealLoading }) => updateDealLoading
);

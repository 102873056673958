import React from "react";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import { isGlobalSearchVisible } from "store/globalSearch/selectors";
import GlobalSearchModal from "./GlobalSearchModal";

interface Props {
  visible: boolean;
}

const GlobalSearch: React.FC<Props> = ({ visible }) => (visible ? <GlobalSearchModal /> : null);

const mapStateToProps = (state: RootState) => ({
  visible: isGlobalSearchVisible(state),
});

export default connect(mapStateToProps)(GlobalSearch);

import React from "react";
import Select from "antd/es/select";
import { Labeled } from "@mapmycustomers/ui";
import { useIntl } from "react-intl";
import { SizeType } from "antd/es/config-provider/SizeContext";
import styles from "./CommonStyles.module.scss";

interface Props {
  onChange: (value: number) => void;
  size?: SizeType;
  value: number;
}

const AllottedTime: React.FC<Props> = ({ onChange, size, value }) => {
  const intl = useIntl();

  return (
    <Labeled
      label={intl.formatMessage({
        id: "createRouteForm.field.allottedTime",
        defaultMessage: "* Time allotted for each stop",
        description: "Allotted Time field on the Create Route form",
      })}
    >
      <Select<number>
        className={styles.fullWidth}
        dropdownMatchSelectWidth={false}
        onChange={onChange}
        size={size}
        value={value}
      >
        <Select.Option value={0}>
          {intl.formatMessage({
            id: "createRouteForm.field.allottedTime.option.doNotSet",
            defaultMessage: "Do Not Set",
            description: "Do Not Set option of the Allotted Time field on the Create Route form",
          })}
        </Select.Option>
        <Select.Option value={300}>
          {intl.formatMessage({
            id: "createRouteForm.field.allottedTime.option.minutes5",
            defaultMessage: "5 Minutes",
            description: "5 Minutes option of the Allotted Time field on the Create Route form",
          })}
        </Select.Option>
        <Select.Option value={900}>
          {intl.formatMessage({
            id: "createRouteForm.field.allottedTime.option.minutes15",
            defaultMessage: "15 Minutes",
            description: "15 Minutes option of the Allotted Time field on the Create Route form",
          })}
        </Select.Option>
        <Select.Option value={1800}>
          {intl.formatMessage({
            id: "createRouteForm.field.allottedTime.option.minutes30",
            defaultMessage: "30 Minutes",
            description: "30 Minutes option of the Allotted Time field on the Create Route form",
          })}
        </Select.Option>
        <Select.Option value={2700}>
          {intl.formatMessage({
            id: "createRouteForm.field.allottedTime.option.minutes45",
            defaultMessage: "45 Minutes",
            description: "45 Minutes option of the Allotted Time field on the Create Route form",
          })}
        </Select.Option>
        <Select.Option value={3600}>
          {intl.formatMessage({
            id: "createRouteForm.field.allottedTime.option.minutes60",
            defaultMessage: "60 Minutes",
            description: "60 Minutes option of the Allotted Time field on the Create Route form",
          })}
        </Select.Option>
      </Select>
    </Labeled>
  );
};

export default AllottedTime;

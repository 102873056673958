import React, { ComponentType, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";
import FormLayout, { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";
import Divider from "component/FormFields/components/Divider";
import AddFieldButton from "scene/settings/component/FormLayouts/component/AddFieldButton";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import SchemaFieldType from "@mapmycustomers/shared/enum/SchemaFieldType";
import useFormFieldRender, {
  createFormCustomFieldNamePathGetter,
  editFormCustomFieldNamePathGetter,
} from "component/FormFields/utils/useFormFieldRender";
import loggingService from "util/logging";
import useSchema from "component/FormFields/utils/useSchema";
import getAddableFields from "component/FormFields/utils/getAddableFields";
import convertFieldToLayoutSchemaField from "component/FormFields/utils/convertFieldToLayoutSchemaField";
import useAddedFieldsSchema from "component/FormFields/utils/useAddedFieldsSchema";
import { displayNameComparator } from "util/comparator";
import isFunction from "lodash-es/isFunction";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import useDefaultValues from "./utils/useDefaultValues";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import { getFunnelStages } from "store/deal";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import styles from "./FormFields.module.scss";

const noFilter = () => true;

interface Props {
  appendAddableFieldsWithFilter?: (field: IField) => boolean;
  autoFocus?: boolean;
  canAddField?: (field: IField) => boolean;
  children?: ReactNode | ReactNode[];
  className?: string;
  disallowAdding?: boolean;
  entityType: EntityTypesSupportingFieldCustomization;
  fileComponent?: ComponentType<{ disabled?: boolean; required?: boolean }>;
  filterFields?: (schemaField: LayoutSchemaField) => boolean;
  funnelStages: Record<Funnel["id"], Stage[]>;
  ignoreRequired?: boolean;
  isCreateForm?: boolean;
  layout: FormLayout;
  onAddField?: (field: LayoutSchemaField) => void;
  rowClassName?: ((schemaField?: LayoutSchemaField) => string | undefined) | string;
}

const FormFields: React.FC<Props> = ({
  appendAddableFieldsWithFilter,
  autoFocus = true,
  canAddField,
  children,
  className,
  disallowAdding,
  entityType,
  fileComponent,
  filterFields = noFilter,
  funnelStages,
  ignoreRequired,
  isCreateForm,
  layout,
  onAddField,
  rowClassName,
}) => {
  // fields user manually added to the form
  const [addedFields, setAddedFields] = useState<LayoutSchemaField[]>([]);
  const handleAddField = useCallback(
    (newField: LayoutSchemaField) => {
      setAddedFields((fields) => [
        ...fields.filter((existing) => existing.field !== newField.field),
        newField,
      ]);
      onAddField?.(newField);
    },
    [onAddField]
  );

  const formRef = useRef<HTMLDivElement>(null);
  const form = useFormInstance();

  useEffect(() => {
    if (autoFocus && formRef.current) {
      const focusable = formRef.current.querySelectorAll(
        'input:not(:disabled), select:not(:disabled), textarea:not(:disabled), [tabindex]:not([tabindex="-1"]):not(:disabled)'
      );

      (focusable?.[0] as HTMLElement)?.focus();
    }
  }, [autoFocus, formRef]);

  const [actualSchema, childLayout, isVariant] = useSchema(
    entityType,
    layout,
    filterFields,
    funnelStages,
    isCreateForm
  );
  const addedFieldsSchema = useAddedFieldsSchema(
    entityType,
    actualSchema,
    addedFields,
    filterFields,
    isCreateForm
  );
  useEffect(() => {
    if (appendAddableFieldsWithFilter) {
      setAddedFields(
        getAddableFields(entityType, actualSchema, isCreateForm ?? false)
          .filter(appendAddableFieldsWithFilter)
          .sort(displayNameComparator)
          .map(convertFieldToLayoutSchemaField)
      );
    }
  }, [actualSchema, appendAddableFieldsWithFilter, entityType, isCreateForm]);

  const fieldModel = getFieldModelByEntityType(entityType);
  const fieldRenderer = useFormFieldRender(entityType, children, actualSchema, {
    customFieldNamePathGetter: isCreateForm
      ? createFormCustomFieldNamePathGetter
      : editFormCustomFieldNamePathGetter,
    fileComponent,
    ignoreRequired,
    isCreateForm,
  });

  const renderField = useCallback(
    (schemaField) => {
      const rowKey = `${schemaField.fieldType}.${schemaField.field}.${
        // displayOrder may change if user manually adds new fields, so we don't take it into
        // account for fields, just for dividers to keep fields' key persistent
        schemaField.fieldType === SchemaFieldType.DIVIDER ? schemaField.displayOrder : ""
      }`;
      let component: JSX.Element | null = <Divider />;
      let fullWidth = true;

      if (schemaField.fieldType !== SchemaFieldType.DIVIDER) {
        const field = fieldModel.getByPlatformName(schemaField.field);
        if (!field) {
          loggingService.error("Unknown schema field found, skipped", { schemaField });
          return null;
        }

        // such fields are rendered with some other fields. E.g. person's lastName
        // is rendered along with person's firstName, so we don't need to show
        // it separately.
        if (field.hasFeature(FieldFeature.FORM_RENDERED_WITH_OTHER_FIELD)) {
          return null;
        }

        // Read-only fields should be still allowed in create forms
        const readOnly = !field?.isEditable && !isCreateForm;
        component = fieldRenderer(field, schemaField, readOnly, isVariant);
        fullWidth = !!field.formProperties?.fullWidth;
      }

      const className = isFunction(rowClassName) ? rowClassName(schemaField) : rowClassName;

      return (
        <div className={cn(styles.row, { [styles.full]: fullWidth }, className)} key={rowKey}>
          {component}
        </div>
      );
    },
    [fieldModel, fieldRenderer, isCreateForm, rowClassName, isVariant]
  );

  useDefaultValues(actualSchema, childLayout, isCreateForm, fieldModel, form, funnelStages);

  return (
    <div className={cn(styles.container, className)} ref={formRef}>
      {actualSchema.map(renderField)}
      {addedFieldsSchema.length > 0 && (
        <div
          className={cn(styles.row, isFunction(rowClassName) ? rowClassName() : rowClassName)}
          key="__addedFieldsDivider"
        >
          <Divider />
        </div>
      )}
      {addedFieldsSchema.map(renderField)}
      {!disallowAdding && (
        <AddFieldButton
          canAddField={canAddField}
          entityType={entityType}
          layout={layout}
          onAddField={handleAddField}
          schema={actualSchema}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  funnelStages: getFunnelStages(state),
});

export default connect(mapStateToProps)(FormFields);

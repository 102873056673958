import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchPerson } from "./actions";
import { callApi } from "store/api/callApi";
import { getOrganizationId } from "store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import { handleError } from "store/errors/actions";
import { Person } from "@mapmycustomers/shared/types/entity";

function* onFetchPerson({ payload }: ReturnType<typeof fetchPerson>) {
  const { id, options = {}, callback, failureCallback } = payload;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const person: Person = yield callApi("fetchPerson", orgId, id, options);
    yield call(callback, person);
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

export function* personSaga() {
  yield takeEvery(fetchPerson, onFetchPerson);
}

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { Territory } from "@mapmycustomers/shared/types/entity";
import PersistentBoundariesLayerData from "@mapmycustomers/shared/types/persistent/PersistentBoundariesLayerData";
import BoundaryType from "./types/BoundaryType";
import { DemographicDataConfiguration, RegionDemographicData } from "./types/BoundariesLayerData";
import Bucket from "./types/Bucket";
import { TerritoryLevel } from "@mapmycustomers/shared";
import DemographicData from "scene/map/types/DemographicData";
import CountryCode from "@mapmycustomers/shared/enum/CountryCode";

export const initializeTerritories = createAsyncAction(
  "map/boundaries/initializeTerritories/request",
  "map/boundaries/initializeTerritories/success",
  "map/boundaries/initializeTerritories/failure"
)<void, void, void>();

export const ensureDemographicDataLoaded = createAsyncAction(
  "map/boundaries/ensureDemographicDataLoaded/request",
  "map/boundaries/ensureDemographicDataLoaded/success",
  "map/boundaries/ensureDemographicDataLoaded/failure"
)<
  void,
  { demographicData?: RegionDemographicData; demographyDataCountryCode?: CountryCode },
  void
>();

export const setHighlightedTerritoryId = createAction("map/boundaries/setHighlightedTerritoryId")<
  Territory["id"] | undefined
>();

export const setBoundariesSettings = createAction("map/boundaries/setBoundariesSettings")<
  PersistentBoundariesLayerData | undefined
>();

export const setBoundaryType = createAction("map/boundaries/setBoundaryType")<BoundaryType>();
export const setZoomLevel = createAction("map/boundaries/setZoomLevel")<TerritoryLevel>();

export const toggleVisualizeDemography = createAction(
  "map/boundaries/toggleVisualizeDemography"
)<void>();

export const calculateBuckets = createAsyncAction(
  "map/boundaries/calculateBuckets/request",
  "map/boundaries/calculateBuckets/success",
  "map/boundaries/calculateBuckets/failure"
)<void, Bucket[], void>();

export const toggleBucketVisibility = createAction("map/boundaries/toggleBucketVisibility")<{
  id: Bucket["id"];
}>();

export const setVisibleRegions = createAction("map/boundaries/setVisibleRegions")<
  DemographicData["code"][]
>();

export const updateDemographicDataConfiguration = createAction(
  "map/boundaries/updateDemographicDataConfiguration"
)<Partial<DemographicDataConfiguration>>();

export type BoundariesActions = ActionType<
  | typeof calculateBuckets
  | typeof ensureDemographicDataLoaded
  | typeof initializeTerritories
  | typeof setBoundariesSettings
  | typeof setBoundaryType
  | typeof setHighlightedTerritoryId
  | typeof setVisibleRegions
  | typeof setZoomLevel
  | typeof toggleBucketVisibility
  | typeof toggleVisualizeDemography
  | typeof updateDemographicDataConfiguration
>;

import { FilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import PlatformFilterModel, {
  PlatformFilterCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { isCombinedCondition, isCombinedPlatformCondition } from "util/viewModel/assert";
import { getRegularFieldPlatformConditionValue } from "util/viewModel/convertToPlatformFilterModel";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { getRegularFieldConditionValue } from "util/viewModel/convertFromPlatformFilterModel";
import invariant from "tiny-invariant";
import BaseLocationField from "util/fieldModel/impl/BaseLocationField";
import { FieldProperties } from "util/fieldModel/impl/Field";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";

class MapLocationField extends BaseLocationField {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      features: [
        FieldFeature.ADDRESS,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.GEO_POINT_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.STANDARD,
      ],
      platformFilterName: "area",
      valueGetter: ["geoPoint", "coordinates"],
      ...data,
    });
  }

  convertToPlatformCondition(filterCondition: FilterCondition): PlatformFilterCondition {
    if (isCombinedCondition(filterCondition)) {
      return {}; // combined condition is not supported
    } else {
      if (
        filterCondition.operator === FilterOperator.EMPTY ||
        filterCondition.operator === FilterOperator.NOT_EMPTY
      ) {
        return {
          mapped: filterCondition.operator !== FilterOperator.EMPTY,
        };
      } else {
        return {
          [this._platformFilterName]: getRegularFieldPlatformConditionValue(this, filterCondition),
        };
      }
    }
  }

  convertFromPlatformCondition(
    filterCondition: PlatformFilterCondition
  ): FilterCondition | undefined {
    if (isCombinedPlatformCondition(filterCondition)) {
      return super.convertFromPlatformCondition(filterCondition);
    } else {
      if ("mapped" in filterCondition) {
        return {
          operator: filterCondition.mapped ? FilterOperator.NOT_EMPTY : FilterOperator.EMPTY,
          value: undefined,
        };
      }

      // wrapping condition into an object with filterName is a little hack to help getRegularFieldConditionValue
      // because this is what it normally expects. But since Location is a meta-field, condition for it comes
      // unwrapped, so we need to wrap it.
      return getRegularFieldConditionValue(this, { [this.platformFilterName]: filterCondition });
    }
  }

  isPresentInPlatformCondition(condition: Record<string, unknown>): boolean {
    return this.platformFilterName in condition || "mapped" in condition;
  }

  getFilterConditionForMetaField(
    platformFilterModel: PlatformFilterModel
  ): PlatformFilterCondition {
    if (this.platformFilterName in platformFilterModel) {
      return super.getFilterConditionForMetaField(platformFilterModel);
    } else if ("mapped" in platformFilterModel) {
      return { mapped: platformFilterModel.mapped };
    }
    invariant(false, `Invalid filter condition for ${this.name} field.`);
  }
}

export default MapLocationField;

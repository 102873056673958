import ActivityCompletion from "@mapmycustomers/shared/enum/activity/ActivityCompletion";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  [ActivityCompletion.COMPLETE]: {
    id: "ActivityCompletion.complete",
    defaultMessage: '"Done"',
    description: "Label for default activity completion as Done",
  },
  [ActivityCompletion.INCOMPLETE]: {
    id: "ActivityCompletion.incomplete",
    defaultMessage: '"Not yet done"',
    description: "Label for default activity completion as Not yet done",
  },
});

const getActivityCompletionDisplayName = (
  intl: IntlShape,
  activityCompletion: ActivityCompletion
) => intl.formatMessage(messages[activityCompletion]);

export default getActivityCompletionDisplayName;

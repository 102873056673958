import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";
import memoize from "fast-memoize";
import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import SchemaField from "@mapmycustomers/shared/types/schema/SchemaField";

const schemaState = (state: RootState) => state.schema;

export const getEntitySchemaFields = createSelector(schemaState, (state) =>
  memoize(
    (entityType: EntityTypesSupportingFieldCustomization): SchemaField[] =>
      state.entities[entityType]
  )
);

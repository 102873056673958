import {
  Condition,
  PlatformCombinedCondition,
  PlatformFilterCondition,
  PlatformSimpleCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";

const createRegionCondition = (
  field: IField,
  platformCondition: PlatformFilterCondition
): PlatformCombinedCondition => {
  const simpleCondition = platformCondition as PlatformSimpleCondition;

  const condition = simpleCondition[field.platformFilterName] as Condition;
  const isNegativeCondition =
    condition.hasOwnProperty(PlatformFilterOperator.NOT_CONTAINS) ||
    condition.hasOwnProperty(PlatformFilterOperator.NOT_EQUAL);

  return {
    [isNegativeCondition ? "$and" : "$or"]: [
      {
        region: condition,
      },
      {
        regionCode: condition,
      },
    ],
  };
};

export default createRegionCondition;

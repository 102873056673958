import type { LocalSettings } from "config/LocalSettings";
import localSettings from "config/LocalSettings";
import RecordsSourceFilter from "scene/home/types/RecordsSourceFilter";
import RecordsOutOfCadenceFilter from "scene/home/types/RecordsOutOfCadenceFilter";
import { DealsRottingFilter } from "scene/home/types/DealsRottingFilter";

class HomeSettings {
  settings: LocalSettings;

  private readonly ENGAGEMENT_CARD_STATE = "__ENGAGEMENT_CARD_STATE__";
  private readonly RECORD_SOURCE_CARD_STATE = "__RECORD_SOURCE_CARD_STATE__";
  private readonly DEALS_ROTTING_CARD_STATE = "__DEALS_ROTTING_CARD_STATE__";

  constructor(settings: LocalSettings) {
    this.settings = settings;
  }

  getRecordsSourceCardState = (): RecordsSourceFilter | undefined => {
    const json = this.settings.getValue(this.RECORD_SOURCE_CARD_STATE);
    try {
      return json && JSON.parse(json as string);
    } catch {}
  };

  setRecordsSourceCardState = (state: RecordsSourceFilter) => {
    this.settings.setValue(this.RECORD_SOURCE_CARD_STATE, JSON.stringify(state));
  };

  getEngagementCardState = (): RecordsOutOfCadenceFilter | undefined => {
    const json = this.settings.getValue(this.ENGAGEMENT_CARD_STATE);
    try {
      return json && JSON.parse(json as string);
    } catch {}
  };

  setEngagementCardState = (state: RecordsOutOfCadenceFilter) => {
    this.settings.setValue(this.ENGAGEMENT_CARD_STATE, JSON.stringify(state));
  };

  getDealsRottingCardState = (): DealsRottingFilter | undefined => {
    const json = this.settings.getValue(this.DEALS_ROTTING_CARD_STATE);
    try {
      return json && JSON.parse(json as string);
    } catch {}
  };

  setDealsRottingCardState = (state: DealsRottingFilter) => {
    this.settings.setValue(this.DEALS_ROTTING_CARD_STATE, JSON.stringify(state));
  };
}

const homeSettings = new HomeSettings(localSettings);

export default homeSettings;

import { IntlShape } from "react-intl";
import { formatEmailLink } from "util/formatters";
import { EntityType } from "@mapmycustomers/shared/enum";
import { getEntityTypeAnalyticsName } from "util/ui";

const getErrorNotificationDescription = (
  intl: IntlShape | undefined,
  entityType: EntityType.COMPANY | EntityType.DEAL | EntityType.PERSON,
  lowercase: boolean = false
) => (
  <div>
    {intl?.formatMessage(
      {
        id: "mapMode.exportFiles.error.description1",
        defaultMessage:
          "There was a problem downloading your {entityType} data. Please try again. If this problem persists, please contact <link>support@mapmycustomers.me</link>.",
        description: "Description shown when download fail",
      },
      {
        entityType: getEntityTypeAnalyticsName(entityType, lowercase, true),

        link: (linkText: string) => (
          <a rel="noopener noreferrer" target="_blank" href={formatEmailLink(linkText)}>
            {linkText}
          </a>
        ),
      }
    )}
  </div>
);

export default getErrorNotificationDescription;

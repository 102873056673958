import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const activitiesOverdueState = (state: RootState) => state.scene.dashboard.activitiesOverdueState;

export const getActivitiesOverdueDrillDownViewState = createSelector(
  activitiesOverdueState,
  ({ viewState }) => viewState
);

export const getActivitiesOverdueDrillDownTotalFilteredRecords = createSelector(
  activitiesOverdueState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getActivitiesOverdueDrillDownTotalRecords = createSelector(
  activitiesOverdueState,
  ({ totalRecords }) => totalRecords
);

export const getActivitiesOverdueDrillDownConfiguration = createSelector(
  activitiesOverdueState,
  ({ configuration }) => configuration
);

export const isActivitiesOverdueDrillDownLoading = createSelector(
  activitiesOverdueState,
  ({ loading }) => loading
);

import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import AutoComplete from "antd/es/auto-complete";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import Tooltip from "antd/es/tooltip";
import useFindMyLocation from "util/hook/useFindMyLocation";
import { findMeTooltipMessage, placeholderMessage } from "../utils/messages";
import { ErrorRow, LoadingSpinner } from "@mapmycustomers/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLocationArrow } from "@fortawesome/pro-duotone-svg-icons/faCircleLocationArrow";
import styles from "./GoogleAutoCompleteAddress.module.scss";
import Input, { InputRef } from "antd/es/input";
import useStateWithDebouncedListener from "util/hook/useStateWithDebouncedListener";
import Address from "@mapmycustomers/shared/types/Address";
import useGoogleAutoCompleteOptions, {
  MANUAL_ADDRESS_VALUE,
  NO_RESULT_VALUE,
} from "./useGoogleAutoCompleteOptions";
import usePlaceDetailsGetter from "../utils/usePlacesService";
import usePlacePredictionsGetter from "../utils/usePlacePredictionsGetter";
import { getFormattedAddressForUi } from "util/formatters";
import { reverseGeocodeAddress } from "store/location/actions";
import { connect } from "react-redux";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import cn from "classnames";

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

interface Props {
  allowClear?: boolean;
  className?: string;
  disabled?: boolean;
  disableFindMe?: boolean;
  onChange?: (address?: Address) => void;
  onEnterManually?: (value: string) => void;
  onPressEnter?: () => void;
  onReverseGeocodeAddress: typeof reverseGeocodeAddress;
  placeholder?: string;
  placePredictionTypes?: string[];
  ref?: React.Ref<InputRef>;
  value?: Address;
}

const GoogleAutoCompleteAddress = forwardRef<InputRef, Props>(
  (
    {
      allowClear,
      className,
      disabled,
      disableFindMe,
      onChange,
      onEnterManually,
      onPressEnter,
      onReverseGeocodeAddress,
      placeholder,
      placePredictionTypes,
      value,
    },
    ref
  ) => {
    const intl = useIntl();

    const [placeList, setPlacesList] = useState<AutocompletePrediction[]>([]);
    const [placeListLoading, startLoading, stopLoading] = useBoolean();

    // Text field state + search listener to find suggestions
    const [placePredictionsGetter] = usePlacePredictionsGetter(placePredictionTypes);
    const [fieldValue, setFieldValue, setFieldValueWithoutSearch] = useStateWithDebouncedListener(
      [
        useCallback(
          async (value: string) => {
            try {
              startLoading();
              const places = await placePredictionsGetter(value);
              setPlacesList(places);
            } finally {
              stopLoading();
            }
          },
          [placePredictionsGetter, startLoading, stopLoading]
        ),
        100,
      ],
      ""
    );
    useEffect(() => {
      setFieldValueWithoutSearch(getFormattedAddressForUi(value));
    }, [setFieldValueWithoutSearch, value]);

    // Get address by place
    const [placeDetailsGetter] = usePlaceDetailsGetter();
    const selectPlace = useCallback(
      async (placeId: string) => {
        const address = await placeDetailsGetter(placeId);
        setFieldValueWithoutSearch(getFormattedAddressForUi(address));
        onChange?.(address);
      },
      [onChange, placeDetailsGetter, setFieldValueWithoutSearch]
    );

    // Handle clicking on any option in a dropdown
    const handleSelect = useCallback(
      (placeId: string) => {
        if ([NO_RESULT_VALUE, MANUAL_ADDRESS_VALUE].includes(placeId)) {
          placeId === MANUAL_ADDRESS_VALUE && onEnterManually?.(fieldValue);
          setFieldValue("");
          return;
        }
        const place = placeList.find(({ place_id }) => place_id === placeId);
        if (place) {
          setFieldValue(place.description);
        }
        selectPlace(placeId);
      },
      [fieldValue, onEnterManually, placeList, selectPlace, setFieldValue]
    );

    // Find Me functionality:
    const [, , findMeLoading, findMeError, handleFindMyLocation, geocodingResult] =
      useFindMyLocation(intl, onReverseGeocodeAddress);
    const lastGeocodingResult = useRef<GeocodeResult>();
    useEffect(() => {
      if (lastGeocodingResult.current !== geocodingResult) {
        lastGeocodingResult.current = geocodingResult;
        setPlacesList([]);
        onChange?.(geocodingResult?.address);
      }
    }, [geocodingResult, onChange]);

    const options = useGoogleAutoCompleteOptions(placeList, !!onEnterManually);

    const checkTextAndFindPlaceIfNeeded = useCallback(async () => {
      if (getFormattedAddressForUi(value) !== fieldValue) {
        const places = await placePredictionsGetter(fieldValue);
        if (places.length > 0) {
          selectPlace(places[0].place_id);
        } else if (allowClear) {
          onChange?.(undefined);
        }
      }
    }, [allowClear, fieldValue, placePredictionsGetter, onChange, selectPlace, value]);

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === "Escape") {
          setFieldValueWithoutSearch(getFormattedAddressForUi(value));
        } else if (event.key === "Enter") {
          checkTextAndFindPlaceIfNeeded();
        }
      },
      [checkTextAndFindPlaceIfNeeded, setFieldValueWithoutSearch, value]
    );

    const handleClear = useCallback(() => {
      onChange?.(undefined);
      setFieldValueWithoutSearch("");
    }, [onChange, setFieldValueWithoutSearch]);

    return (
      <AutoComplete
        className={cn(styles.container, className)}
        disabled={disabled}
        dropdownMatchSelectWidth={false}
        onBlur={disabled ? undefined : checkTextAndFindPlaceIfNeeded}
        onChange={disabled ? undefined : setFieldValue}
        onClear={disabled ? undefined : handleClear}
        onKeyDown={disabled ? undefined : handleKeyDown}
        onSelect={disabled ? undefined : handleSelect}
        options={options}
        showSearch={!disabled}
        value={fieldValue}
      >
        <Input
          allowClear={allowClear && !disabled}
          disabled={disabled}
          onPressEnter={onPressEnter}
          placeholder={disabled ? undefined : placeholder ?? intl.formatMessage(placeholderMessage)}
          ref={ref}
          suffix={
            findMeLoading || placeListLoading ? (
              <LoadingSpinner mini />
            ) : disableFindMe ? undefined : (
              <Tooltip title={intl.formatMessage(findMeTooltipMessage)}>
                <FontAwesomeIcon
                  className={styles.icon}
                  icon={faCircleLocationArrow}
                  onClick={handleFindMyLocation}
                  size="xl"
                />
              </Tooltip>
            )
          }
        />
        {findMeError && <ErrorRow>{findMeError}</ErrorRow>}
      </AutoComplete>
    );
  }
);

const mapDispatchToProps = {
  onReverseGeocodeAddress: reverseGeocodeAddress,
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  GoogleAutoCompleteAddress
);

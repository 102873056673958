import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import * as styles from "./ListWithOverflowItem.module.scss";
import stringHash from "@mapmycustomers/shared/util/hash/stringHash";

interface ListWithOverflowItemProps {
  children: Array<ReactElement>;
  className?: string;
  overflowRender: (hiddenItemsCount: number) => ReactNode;
}

const ListWithOverflowItem: FC<ListWithOverflowItemProps> = ({
  children,
  className,
  overflowRender,
}) => {
  const listElement = useRef<HTMLDivElement>(null);
  const [hiddenItemsMap, setHiddenItemsMap] = useState<Record<string, boolean>>(
    {}
  );

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      setHiddenItemsMap((previous) =>
        entries.reduce(
          (result, entry) => ({
            ...result,
            // we know that key is defined since we set it ourselves in this component
            [(entry.target as HTMLElement).dataset.key!]: !entry.isIntersecting,
          }),
          previous
        )
      );
    },
    []
  );

  const key = stringHash(
    React.Children.map(
      children,
      (child: ReactElement, index) => child.key ?? index
    )?.join("_") ?? ""
  );

  useEffect(() => {
    if (!listElement.current) {
      return;
    }

    const observer = new IntersectionObserver(handleIntersection, {
      root: listElement.current,
      threshold: 1,
    });

    Array.from(listElement.current.children).forEach((item) => {
      if ((item as HTMLElement).dataset.key !== undefined) {
        observer.observe(item);
      }
    });
    return () => {
      observer.disconnect();
      setHiddenItemsMap({});
    };
  }, [handleIntersection, key]);

  const hiddenItemsCount = useMemo(
    () => Object.values(hiddenItemsMap).filter((value) => value).length,
    [hiddenItemsMap]
  );

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.list} ref={listElement}>
        {React.Children.map(children, (child: ReactElement, index) => {
          return React.cloneElement(child, {
            className: cn(child.props.className, {
              [styles.visible]: !hiddenItemsMap[child.key ?? index],
              [styles.invisible]: hiddenItemsMap[child.key ?? index],
            }),
            "data-key": child.key ?? index,
          });
        })}
      </div>

      {hiddenItemsCount ? overflowRender(hiddenItemsCount) : null}
    </div>
  );
};

export default ListWithOverflowItem;

import { defineMessages } from "react-intl";
import RecurringIntervalType from "../enum/RecurringIntervalType";

export const recurringIntervalMessages = defineMessages<RecurringIntervalType>({
  [RecurringIntervalType.MONTHLY]: {
    id: "createEditActivityModal.recurringInterval.monthly",
    defaultMessage: "Every month",
    description:
      "Every month selector option for Advanced Options Popover of the Create Activity modal",
  },
  [RecurringIntervalType.WEEKLY]: {
    id: "createEditActivityModal.recurringInterval.weekly",
    defaultMessage: "Every week",
    description:
      "Every week selector option for Advanced Options Popover of the Create Activity modal",
  },
  [RecurringIntervalType.DAILY]: {
    id: "createEditActivityModal.recurringInterval.daily",
    defaultMessage: "Every day",
    description:
      "Every day selector option for Advanced Options Popover of the Create Activity modal",
  },
  [RecurringIntervalType.CUSTOM]: {
    id: "createEditActivityModal.recurringInterval.custom",
    defaultMessage: "Custom",
    description: "Custom selector option for Advanced Options Popover of the Create Activity modal",
  },
  [RecurringIntervalType.NEVER]: {
    id: "createEditActivityModal.advancedOptionsPopover.never",
    defaultMessage: "Never",
    description: "Never selector option for Advanced Options Popover of the Create Activity modal",
  },
});

export const messages = defineMessages({
  title: {
    id: "createEditActivityModal.advancedOptionsPopover.title",
    defaultMessage: "Advanced options",
    description: "Advanced Options Popover Title of the Create Activity modal",
  },
  isAllDayActivity: {
    id: "createEditActivityModal.advancedOptionsPopover.isAllDayActivity",
    defaultMessage: "All-day activity?",
    description: "All-day activity label in Advanced Options Popover of the Create Activity modal",
  },
  repeats: {
    id: "createEditActivityModal.advancedOptionsPopover.repeats",
    defaultMessage: "Repeats?",
    description: "All-day activity label in Advanced Options Popover of the Create Activity modal",
  },
  repeatEvery: {
    id: "createEditActivityModal.advancedOptionsPopover.repeatEvery",
    defaultMessage: "Repeat every",
    description:
      "Label for custom interval fields for Advanced Options Popover of the Create Activity modal",
  },
});

import ActivityListType from "@mapmycustomers/shared/enum/activity/ActivityListType";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  [ActivityListType.COMPLETED_ACTIVITIES]: {
    id: "activityListType.completedActivities",
    defaultMessage: "Activities done",
    description: "Completed activities status of the activity list type",
  },
  [ActivityListType.INCOMPLETE_ACTIVITIES]: {
    id: "activityListType.incompleteActivities",
    defaultMessage: "Not yet done Activities",
    description: "Incomplete activities status of the activity list type",
  },
});

const getActivityListTypeDisplayName = (intl: IntlShape, activityListType: ActivityListType) =>
  intl.formatMessage(messages[activityListType]);

export default getActivityListTypeDisplayName;

import color from "color";
import ColorEnum, { knownColors } from "@mapmycustomers/shared/enum/Color";
import colorNameToHex from "./colorNameToHex";

interface GetContrastColor {
  (sourceColor: string): "black" | "white";
  (sourceColor: ColorEnum): "black" | "white";
}

const getContrastColor: GetContrastColor = (sourceColor: unknown): "black" | "white" => {
  if (knownColors.includes(sourceColor as ColorEnum)) {
    sourceColor = colorNameToHex(sourceColor as ColorEnum);
  }
  // Darken just a tiny bit to make it select "white" on what I consider a dark background :)
  return color(sourceColor as string)
    .darken(0.15)
    .isLight()
    ? "black"
    : "white";
};

export default getContrastColor;

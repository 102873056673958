import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import { faPen } from "@fortawesome/pro-solid-svg-icons/faPen";
import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import cn from "classnames";
import colors from "../styles/_colors.module.scss";
import styles from "./ColorCircle.module.scss";
import anyColorToHex from "@mapmycustomers/shared/util/color/anyColorToHex";
import getContrastColor from "@mapmycustomers/shared/util/color/getContrastColor";
import { useConfigProvider } from "../ConfigProvider";

interface ColorCircleProps {
  className?: string;
  disabled?: boolean;
  value?: AnyColor;
}

const ColorCircle: React.FC<ColorCircleProps> = ({
  className,
  disabled,
  value,
}) => {
  const configProvider = useConfigProvider();

  const bgColor = value ? anyColorToHex(value) : "inherit";
  const fgColor = value
    ? getContrastColor(bgColor) === "white"
      ? colors.white
      : colors.slate
    : "inherit";

  return (
    <div
      className={cn(styles.color, className)}
      style={{
        backgroundColor: bgColor,
        borderColor: fgColor,
      }}
    >
      {!disabled ? (
        <Tooltip
          title={configProvider.formatMessage("ui.colorComponents.changeColor")}
        >
          <FontAwesomeIcon
            className={styles.icon}
            icon={faPen}
            style={{
              color: fgColor,
            }}
          />
        </Tooltip>
      ) : undefined}
    </div>
  );
};

export default ColorCircle;

import Path from "enum/Path";
import Theme, { isValidTheme } from "enum/Theme";
import browserLocale from "browser-locale";
import type IFieldModel from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import type IField from "@mapmycustomers/shared/types/fieldModel/IField";
import type ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";
import type Organization from "@mapmycustomers/shared/types/Organization";
import type { OrganizationMetaData } from "@mapmycustomers/shared/types/Organization";
// TODO: move all types from "scene/home" somewhere in "types" folder to make 'em generic
import loggingService from "util/logging";
import type LeaderboardViewState from "types/viewModel/LeaderboardViewState";
import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import type MapPersistedViewportState from "types/map/MapPersistedViewportState";
import isNumber from "lodash-es/isNumber.js";
import isInteger from "lodash-es/isInteger.js";
import { isNotEmpty } from "util/assert";
import { DEFAULT_SIDEBAR_WIDTH } from "util/ui/getDefaultSidebarWidth";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";
import type DataViewParametersState from "types/dataView/DataViewParametersState";
import RecordPaneTab from "enum/preview/RecordPaneTab";
import RecapRange from "enum/preview/RecapRange";
// TODO: eliminate imports below this comment, we should NOT import from scene or component files
import consts from "component/preview/styles/_consts.module.scss";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { EntityTypeSupportingDataMerging } from "@mapmycustomers/shared/types/entity";
import { TodaySupportedByRepActivityStatus } from "@mapmycustomers/shared/enum/activity/ActivityStatus";
import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import { Reminder } from "@mapmycustomers/shared/types/entity/Activity";

export const MAX_RECENT_FIELDS = 5;

const isFieldDefined = <T extends { field: IField | undefined }>(
  record: T
): record is T & { field: IField } => !!record.field;

/**
 * This is the storage of data (auth token and settings) which is persisted
 * when user reloads the page.
 * Everything except redirect location is stored in the localStorage. For auth
 * localStorage is better than sessionStorage because you can open same website
 * in a new tab not authenticating again.
 */
export class LocalSettings {
  // authentication token
  private readonly AUTH_TOKEN = "__MMC_TOKEN__";

  // a location to redirect user to after he logs in
  private readonly REDIRECT_LOCATION = "__LOCATION__";

  // preferred user's locale
  private readonly LOCALE = "__LOCALE__";

  // preferred user's theme
  private readonly THEME = "__THEME__";

  // view settings prefix
  private readonly VIEW_PREFIX = "__VIEW__";

  // unit system: SI or Imperial
  private readonly SI = "__IS_SI_UNITS__";

  // indicates whether user allows the site to remind to grant geolocation permission
  private readonly SHOULD_ASK_GEOLOCATION_PERMISSION = "__SHOULD_ASK_GEO_PERM__";

  // whether we opened website from mobile app or not
  private readonly MOBILE_APP_VIEW = "__MOBILE_APP_VIEW__";

  // selected recap chart range
  private readonly RECAP_CHART_RANGE = "__MMC__RECAP_CHART_RANGE__";

  // selected activity types in today chart
  private readonly TODAY_ACTIVITY_CHART_TYPE_STATUS = "__TODAY_ACTIVITY_CHART_TYPE_STATUS__";

  // selected activity status in today chart for reps
  private readonly TODAY_ACTIVITY_STATUS_REP = "__TODAY_ACTIVITY_STATUS_REP__";

  // whether we dismissed time offset alert
  private readonly TIME_OFFSET_ALERT_DISMISSED = "__TIME_OFFSET_ALERT_DISMISSED__";

  // whether user dismissed team member board alert
  private readonly TEAM_MEMBER_BOARD_ALERT_DISMISSED = "__TEAM_BOARD_ALERT_DISMISSED__";

  // an organization id of user was logged in, need this to check whether to add view settings or clear
  private readonly ORGANIZATION_ID = "__ORGANIZATION_ID__";

  // don't show export confirmation modal for some entity
  private readonly EXPORT_LIST_DATA_CONFIRMATION_PREFIX = "__EXPORT_LIST_DATA_CONFIRMATION__";

  // currency settings
  private readonly CURRENCY = "__CURRENCY__";

  // don't show delete confirmation modal for some entity
  private readonly DELETE_ENTITY_CONFIRMATION_PREFIX = "__DELETE_ENTITY_CONFIRMATION__";

  // tabs order for some entity record view
  private readonly RECORD_PANE_TABS_ORDER_PREFIX = "__RECORD_PANE_TABS_ORDER__";

  // record view drawer width
  private readonly RECORD_PANE_WIDTHS = "__RECORD_PANE_WIDTHS__";

  // sign-upSource segment event
  private readonly SIGNUP_COMPLETED = "__SIGNUP_COMPLETED__";

  // company column list on merge duplicate pane
  private readonly MERGE_DUPLICATES_COMPANY_COLUMN_LIST = "__MERGE_DUPLICATES__companyColumns";

  // people column list on merge duplicate pane
  private readonly MERGE_DUPLICATES_PEOPLE_COLUMN_LIST = "__MERGE_DUPLICATES__peopleColumns";

  //  leaderboard view state
  private readonly LEADERBOARD_VIEW_STATE = "__LEADERBOARD_VIEW_STATE__";

  // leaderboard column list
  private readonly LEADERBOARD_METRICS_CARD_ORDER = "__LEADERBOARD_METRICS_CARD_ORDER__";

  // most recent map viewport
  private readonly MAP_VIEWPORT = "__MMC_MAP_VIEWPORT__";

  // any global map settings
  private readonly MAP_SETTINGS = "__MMC_MAP_SETTINGS__";

  // an ability to override web features
  private readonly FEATURES_OVERRIDE = "__MMC_FEATURES_OVERRIDE__";

  private readonly CUMUL_PROMOTION_VISIBILITY = "__CUMUL_PROMOTION_VISIBILITY__";

  // a prefix for the map layer settings
  private readonly MAP_LAYER_PREFIX = "__MMC_MAP_LAYER__";

  // follow up fields
  private readonly FOLLOW_UP_FIELDS = "__FOLLOW_UP_FIELDS__";

  // Default notify preferences for create activity form
  private readonly ACTIVITY_CREATION_NOTIFICATION_PREFERENCES =
    "__ACTIVITY_CREATION_NOTIFICATION_PREFERENCES__";

  private readonly MAP_LEGEND = "__MMC_MAP_LEGEND__";

  // store state of data view pane
  private readonly DATA_VIEW_STATE = "__DATA_VIEW_STATE__";

  private readonly FILTER_FIELDS_USAGE = "__MMC_FILTER_FIELDS_USAGE__";

  private readonly SKELETON_KEY_USED = "__SKELETON_KEY_USED__";

  private readonly AUTH_REFRESH_TOKEN = "__AUTH_REFRESH_TOKEN__";

  private readonly AUTH_ANONYMOUS_FLAG = "__AUTH_ANONYMOUS_FLAG__";

  private readonly EXPORT_ENTITY_FIELDS = "__EXPORT_ENTITY_FIELDS__";

  private readonly PREVENT_PINNING_GROUPS_AND_ROUTES_ON_MAP_PAGE =
    "__PREVENT_PINNING_GROUPS_AND_ROUTES_ON_MAP_PAGE__";

  private readonly IS_ENGAGEMENT_ALERT_DISMISSED = "__IS_ENGAGEMENT_ALERT_DISMISSED__";

  private readonly GLOBAL_SEARCH_QUERY = "__GLOBAL_SEARCH_QUERY__";

  private readonly AREA_FILTER_CUSTOM_DISTANCES = "__AREA_FILTER_CUSTOM_DISTANCES__";

  private readonly FREQUENCY_PANEL_OPENED = "__FREQUENCY_PANEL_OPENED__";

  private readonly COLLAPSE_MENU_STATE = "__COLLAPSE_MENU_STATE__";

  getValue = (name: string): unknown => {
    return localStorage.getItem(name);
  };

  setValue = (name: string, value: string) => {
    localStorage.setItem(name, value);
  };

  getAuthToken = (): string | undefined => {
    return localStorage.getItem(this.AUTH_TOKEN) || undefined;
  };

  setAuthToken = (token: string | undefined) => {
    if (!token) {
      this.resetAuthToken();
    } else {
      localStorage.setItem(this.AUTH_TOKEN, token);
    }
  };

  hasAuthToken = (): boolean => {
    const token = this.getAuthToken();
    return !!token && token.length > 0;
  };

  resetAuthToken = () => {
    localStorage.removeItem(this.AUTH_TOKEN);
  };

  getLocation = (): string => {
    // Dummy now, should return a location saved on saveLocation or a default page
    return sessionStorage.getItem(this.REDIRECT_LOCATION) || Path.DASHBOARD;
  };

  setLocation = (location: string) => {
    if (!location) {
      this.resetLocation();
    } else {
      sessionStorage.setItem(this.REDIRECT_LOCATION, location);
    }
  };

  resetLocation = () => {
    sessionStorage.removeItem(this.REDIRECT_LOCATION);
  };

  getLocale = (): string => {
    return localStorage.getItem(this.LOCALE) || browserLocale();
  };

  setLocale = (locale: string) => {
    localStorage.setItem(this.LOCALE, locale);
  };

  resetLocale = () => {
    localStorage.removeItem(this.LOCALE);
  };

  getLeaderboardViewState = (): LeaderboardViewState | undefined => {
    const json = localStorage.getItem(this.LEADERBOARD_VIEW_STATE);
    if (!json) {
      return undefined;
    }
    const viewState: LeaderboardViewState = JSON.parse(json);
    // both these start and end fields can be strings after parsing json
    // converting them back in Dates here
    if (typeof viewState.dateRange.startDate === "string") {
      viewState.dateRange.startDate = new Date(viewState.dateRange.startDate);
    }
    if (typeof viewState.dateRange.endDate === "string") {
      viewState.dateRange.endDate = new Date(viewState.dateRange.endDate);
    }
    return viewState;
  };

  setLeaderboardViewState = (viewState: LeaderboardViewState) => {
    localStorage.setItem(this.LEADERBOARD_VIEW_STATE, JSON.stringify(viewState));
  };

  getTheme = (): Theme => {
    const theme = localStorage.getItem(this.THEME);
    return isValidTheme(theme) ? theme : Theme.LIGHT;
  };

  setTheme = (theme: Theme) => {
    localStorage.setItem(this.THEME, theme);
  };

  getViewSettings = (
    viewKey: string,
    fieldModel: IFieldModel,
    defaultViewState?: ViewState
  ): ViewState => {
    loggingService.debug("read view settings from local storage", { viewKey });
    const viewSettingsJson = localStorage.getItem(this.VIEW_PREFIX + viewKey);
    const defaultListViewState = defaultViewState ?? fieldModel.getDefaultListViewState();
    if (!viewSettingsJson) {
      return defaultListViewState;
    }

    try {
      // parse JSON converting every field name into a field instance
      const viewSettings: ViewState<IField | undefined> = JSON.parse(
        viewSettingsJson,
        (key, value) => (key === "field" ? fieldModel.getByName(value) : value)
      );
      // now remove all fields which we failed to convert. This is not an error technically
      // because we could change the field model, but user can still have fields from an old
      // model stored on his side.
      viewSettings.columns = viewSettings.columns.filter(isFieldDefined);
      viewSettings.sort = viewSettings.sort.filter(isFieldDefined);

      // Now need to check if there are any new fields which were not saved in the storage.
      // E.g. somebody in the org has added custom fields. But since these new custom fields
      // are not in the storage yet, we'll miss them in a result.
      // First, find names of all restored columns. Next, append those columns which are not in
      // that list. Then sort columns.
      const restoredColumnsNames = new Set<string>(
        (viewSettings as ViewState<IField>).columns.map((column) => column.field.name)
      );
      viewSettings.columns = (viewSettings as ViewState<IField>).columns.concat(
        defaultListViewState.columns
          .filter((column) => !restoredColumnsNames.has(column.field.name))
          .map((column) => ({ ...column, visible: false })) // hide such fields by default
      );

      // Cleanup filters
      // 1. remove fields which don't exist. Here's a thing about custom fields: getViewSettings method
      // MUST NOT be called until custom fields are fetched and added to field models. Typically, we call
      // getViewSettings in initializeApp.success action handler, so we're fine.
      // 2. rename fields when they have a new name. This requirement appeared when we switched custom fields'
      // name field to be esKey instead of id.
      if (viewSettings.filter) {
        Object.keys(viewSettings.filter).forEach((fieldFilterName) => {
          const field = fieldModel.getByFilterName(fieldFilterName);
          if (!field) {
            delete viewSettings.filter[fieldFilterName];
          } else if (field.filterName !== fieldFilterName) {
            viewSettings.filter[field.filterName] = viewSettings.filter[fieldFilterName];
            delete viewSettings.filter[fieldFilterName];
          }
        });
      }

      return viewSettings as ViewState<IField>;
    } catch (e) {
      // Should not normally happen, but still better to catch potential SyntaxError
      return defaultListViewState;
    }
  };

  setViewSettings = (viewSettings: ViewState, viewKey: string) => {
    loggingService.debug("write view settings to local storage", { viewKey, viewSettings });
    // convert all Field instances to field names when saving
    const viewSettingsJson = JSON.stringify(viewSettings, (key, value) =>
      key === "field" ? (value as IField).name : value
    );
    localStorage.setItem(this.VIEW_PREFIX + viewKey, viewSettingsJson);
  };

  resetViewSettings = (): void => {
    for (let key in localStorage) {
      if (key.startsWith(this.VIEW_PREFIX)) {
        localStorage.removeItem(key);
      }
    }
  };

  doesUseSiUnits = (): boolean => {
    try {
      const useSiUnits: unknown = JSON.parse(sessionStorage.getItem(this.SI) ?? "");
      // better cast to boolean before returning, because the external
      // value might have been modified by a user
      return !!useSiUnits;
    } catch (e) {
      return false;
    }
  };

  setSiUnitsUsage = (useSiUnits: boolean) => {
    sessionStorage.setItem(this.SI, JSON.stringify(useSiUnits));
  };

  getCurrencyCode = (): string => sessionStorage.getItem(this.CURRENCY) ?? "USD";

  setCurrencyCode = (currency: string) => {
    sessionStorage.setItem(this.CURRENCY, currency);
  };

  setLocationReminderAllowed = (val: boolean) => {
    localStorage.setItem(this.SHOULD_ASK_GEOLOCATION_PERMISSION, val ? "1" : "0");
  };

  isLocationReminderAllowed = (): boolean => {
    return localStorage.getItem(this.SHOULD_ASK_GEOLOCATION_PERMISSION) !== "0";
  };

  wasOpenedFromMobileApp = (): boolean => {
    try {
      const isMobileApp: unknown = JSON.parse(sessionStorage.getItem(this.MOBILE_APP_VIEW) ?? "");
      // better cast to boolean before returning, because the external
      // value might have been modified by a user
      return !!isMobileApp;
    } catch (e) {
      return false;
    }
  };

  setOpenedFromMobileApp = (isMobileApp: boolean) => {
    sessionStorage.setItem(this.MOBILE_APP_VIEW, JSON.stringify(isMobileApp));
  };

  getRecapChartRange = (): RecapRange | undefined => {
    return (localStorage.getItem(this.RECAP_CHART_RANGE) as RecapRange) || undefined;
  };

  setRecapChartRange = (recapRange?: RecapRange) => {
    if (!recapRange) {
      this.resetRecapChartRange();
    } else {
      localStorage.setItem(this.RECAP_CHART_RANGE, recapRange);
    }
  };

  resetRecapChartRange = () => {
    localStorage.removeItem(this.RECAP_CHART_RANGE);
  };

  getTodaysActivityChartTypeStatus = (): ActivityStatusOption | undefined => {
    return localStorage.getItem(this.TODAY_ACTIVITY_CHART_TYPE_STATUS) as
      | ActivityStatusOption
      | undefined;
  };

  setTodaysActivityChartTypeStatus = (newValue: ActivityStatusOption) => {
    localStorage.setItem(this.TODAY_ACTIVITY_CHART_TYPE_STATUS, newValue);
  };

  getTodayActivityStatusRep = (): TodaySupportedByRepActivityStatus | undefined => {
    return localStorage.getItem(this.TODAY_ACTIVITY_STATUS_REP) as
      | TodaySupportedByRepActivityStatus
      | undefined;
  };

  setTodayActivityStatusRep = (newValue: TodaySupportedByRepActivityStatus) => {
    localStorage.setItem(this.TODAY_ACTIVITY_STATUS_REP, newValue);
  };

  getTimeOffsetAlertDismissed = (): boolean => {
    return localStorage.getItem(this.TIME_OFFSET_ALERT_DISMISSED) === "true";
  };

  setTimeOffsetAlertDismissed = (newValue: boolean) => {
    localStorage.setItem(this.TIME_OFFSET_ALERT_DISMISSED, newValue.toString());
  };

  getCumulPromotionCardVisibility = (): boolean => {
    return localStorage.getItem(this.CUMUL_PROMOTION_VISIBILITY) === "true";
  };

  setCumulPromotionCardVisibility = (visible: boolean) => {
    localStorage.setItem(this.CUMUL_PROMOTION_VISIBILITY, JSON.stringify(visible));
  };

  getOrganizationId = (): Organization["id"] | undefined => {
    const id = localStorage.getItem(this.ORGANIZATION_ID);
    return id ? +id : undefined;
  };

  setOrganizationId = (organizationId: Organization["id"]) => {
    localStorage.setItem(this.ORGANIZATION_ID, organizationId.toString());
  };

  setDontShowExportListDataConfirmation = (entityType: EntityType) => {
    localStorage.setItem(`${this.EXPORT_LIST_DATA_CONFIRMATION_PREFIX}${entityType}`, "1");
  };

  getDontShowExportListDataConfirmation = (entityType: EntityType): boolean => {
    return !!localStorage.getItem(`${this.EXPORT_LIST_DATA_CONFIRMATION_PREFIX}${entityType}`);
  };

  setDontShowDeleteEntityConfirmation = (entityType: EntityType) => {
    localStorage.setItem(`${this.DELETE_ENTITY_CONFIRMATION_PREFIX}${entityType}`, "1");
  };

  getDontShowDeleteEntityConfirmation = (entityType: EntityType): boolean => {
    return !!localStorage.getItem(`${this.DELETE_ENTITY_CONFIRMATION_PREFIX}${entityType}`);
  };

  resetDontShowDeleteEntityConfirmation = (): void => {
    for (let key in localStorage) {
      if (key.startsWith(this.DELETE_ENTITY_CONFIRMATION_PREFIX)) {
        localStorage.removeItem(key);
      }
    }
  };

  setRecordTabsOrder = (entityType: EntityType, tabsOrdered: Array<RecordPaneTab>) => {
    localStorage.setItem(
      `${this.RECORD_PANE_TABS_ORDER_PREFIX}${entityType}`,
      JSON.stringify(tabsOrdered)
    );
  };

  getRecordTabsOrder = (entityType: EntityType): Array<RecordPaneTab> => {
    const tabsOrdered = localStorage.getItem(`${this.RECORD_PANE_TABS_ORDER_PREFIX}${entityType}`);
    return tabsOrdered ? JSON.parse(tabsOrdered) : Object.values(RecordPaneTab);
  };

  setRecordPaneWidth = (
    width: number,
    key: string = "default",
    minWidth: number = DEFAULT_SIDEBAR_WIDTH,
    maxWidth: number = parseInt(consts.maxPaneWidth, 10)
  ) => {
    const savedWidths = localStorage.getItem(this.RECORD_PANE_WIDTHS);
    try {
      const boundedWidth = Math.min(Math.max(width, minWidth), maxWidth);
      localStorage.setItem(
        this.RECORD_PANE_WIDTHS,
        JSON.stringify({
          ...(savedWidths ? JSON.parse(savedWidths) : {}),
          [key]: boundedWidth,
        })
      );
    } catch (e) {
      loggingService.error(`Failed to parse record pane width`, savedWidths);
    }
  };

  getRecordPaneWidth = (key: string = "default"): number => {
    const savedWidths = localStorage.getItem(this.RECORD_PANE_WIDTHS);

    try {
      if (savedWidths) {
        return JSON.parse(savedWidths)[key] ?? DEFAULT_SIDEBAR_WIDTH;
      }
    } catch (e) {
      loggingService.error(`Failed to parse record pane width`, savedWidths);
    }
    return DEFAULT_SIDEBAR_WIDTH;
  };

  setSignupCompleted = (signupCompleted: boolean) => {
    localStorage.setItem(this.SIGNUP_COMPLETED, JSON.stringify(signupCompleted));
  };

  getSignupCompleted = (): boolean => {
    return localStorage.getItem(this.SIGNUP_COMPLETED) === "true";
  };

  setEngagementInfoAlertDismissed = (engagementInfoAlert: boolean) => {
    localStorage.setItem(this.IS_ENGAGEMENT_ALERT_DISMISSED, JSON.stringify(engagementInfoAlert));
  };

  getEngagementInfoAlertDismissed = (): boolean => {
    return localStorage.getItem(this.IS_ENGAGEMENT_ALERT_DISMISSED) === "true";
  };

  isTeamMemberBoardAlertDismissed = (): boolean => {
    return localStorage.getItem(this.TEAM_MEMBER_BOARD_ALERT_DISMISSED) === "true";
  };

  setTeamMemberBoardAlertDismissed = (newValue: boolean) => {
    localStorage.setItem(this.TEAM_MEMBER_BOARD_ALERT_DISMISSED, newValue.toString());
  };

  setFollowUpFields = (fields: ActivityFieldName[]) => {
    localStorage.setItem(this.FOLLOW_UP_FIELDS, JSON.stringify(fields));
  };

  getFollowUpFields = (): ActivityFieldName[] => {
    const fieldsValue = localStorage.getItem(this.FOLLOW_UP_FIELDS);
    return fieldsValue
      ? JSON.parse(fieldsValue)
      : [
          ActivityFieldName.NAME,
          ActivityFieldName.ACTIVITY_TYPE,
          ActivityFieldName.ACCOUNT,
          ActivityFieldName.CONTACT,
          ActivityFieldName.DEAL,
        ];
  };

  getMergingDuplicatePaneColumns = (
    entityType: EntityTypeSupportingDataMerging
  ): string[] | undefined => {
    const columns = localStorage.getItem(
      entityType === EntityType.PERSON
        ? this.MERGE_DUPLICATES_PEOPLE_COLUMN_LIST
        : this.MERGE_DUPLICATES_COMPANY_COLUMN_LIST
    );
    return columns ? JSON.parse(columns) : undefined;
  };

  setMergingDuplicatePaneColumns = (
    entityType: EntityTypeSupportingDataMerging,
    columns: string[]
  ) => {
    localStorage.setItem(
      entityType === EntityType.PERSON
        ? this.MERGE_DUPLICATES_PEOPLE_COLUMN_LIST
        : this.MERGE_DUPLICATES_COMPANY_COLUMN_LIST,
      JSON.stringify(columns)
    );
  };

  wasSkeletonKeyUsed = (): boolean => {
    return localStorage.getItem(this.SKELETON_KEY_USED) === "1";
  };

  setSkeletonKeyUsed = (used: boolean) => {
    localStorage.setItem(this.SKELETON_KEY_USED, used ? "1" : "0");
  };

  getLeaderBoardMetricsCardOrder = (): LeaderboardMetricFieldName[] | undefined => {
    const columns = localStorage.getItem(this.LEADERBOARD_METRICS_CARD_ORDER);
    return columns ? JSON.parse(columns).filter(isNotEmpty) : undefined;
  };

  setLeaderBoardMetricsCardOrder = (order: LeaderboardMetricFieldName[]) => {
    localStorage.setItem(this.LEADERBOARD_METRICS_CARD_ORDER, JSON.stringify(order));
  };

  getViewportState = (): MapPersistedViewportState => {
    try {
      const json = localStorage.getItem(this.MAP_VIEWPORT);
      const state: MapPersistedViewportState = json ? JSON.parse(json) : {};
      return state
        ? {
            center:
              Array.isArray(state.center) &&
              state.center.length === 2 &&
              state.center.every(isNumber)
                ? state.center
                : undefined,
            zoom: isInteger(state.zoom) && state.zoom! > 0 ? state.zoom : undefined,
          }
        : {};
    } catch (e) {
      return {};
    }
  };

  // allows partial updates
  updateViewportState = (state: MapPersistedViewportState) => {
    const currentState = this.getViewportState();
    localStorage.setItem(this.MAP_VIEWPORT, JSON.stringify({ ...currentState, ...state }));
  };

  resetViewportState = () => {
    localStorage.removeItem(this.MAP_VIEWPORT);
  };

  hasFeaturesOverride = (): boolean => !!localStorage.getItem(this.FEATURES_OVERRIDE);
  getFeaturesOverride = (): OrganizationMetaData["features"] => {
    try {
      return JSON.parse(localStorage.getItem(this.FEATURES_OVERRIDE) || "{}");
    } catch (e) {
      return {};
    }
  };

  setActivityCreationNotifyPreferences = (reminders: Reminder[]) => {
    localStorage.setItem(
      this.ACTIVITY_CREATION_NOTIFICATION_PREFERENCES,
      JSON.stringify(reminders)
    );
  };

  getActivityCreationNotifyPreferences = (): Reminder[] | undefined => {
    const result = localStorage.getItem(this.ACTIVITY_CREATION_NOTIFICATION_PREFERENCES);
    if (result) {
      return JSON.parse(result) as Reminder[];
    }
    return undefined;
  };

  getLegendsState = (): undefined | unknown => {
    const value = localStorage.getItem(this.MAP_LEGEND);
    if (!value) {
      return;
    }
    try {
      return JSON.parse(value);
    } catch (e) {
      loggingService.error("Failed to read saved legends config", value);
    }
  };

  resetActivityCreationNotifyPreferences = () => {
    localStorage.removeItem(this.ACTIVITY_CREATION_NOTIFICATION_PREFERENCES);
  };

  getFieldsUsage = (
    context: string,
    entityType: "universal" | EntityType
  ): { frequencies: Record<IField["name"], number>; recentlyUsed: IField["name"][] } => {
    const statsJson = localStorage.getItem(this.FILTER_FIELDS_USAGE);
    let recentlyUsed: IField["name"][] = [];
    let frequencies: Record<IField["name"], number> = {};
    if (!statsJson) {
      return { frequencies, recentlyUsed };
    }

    try {
      const stats = JSON.parse(statsJson);
      if (!stats?.[context] || !stats?.[context]?.[entityType]) {
        return { frequencies, recentlyUsed };
      }
      const specificStats = stats[context][entityType];
      return {
        frequencies: { ...frequencies, ...specificStats.frequencies },
        recentlyUsed: specificStats.recentlyUsed,
      };
    } catch (error) {
      loggingService.error("Failed to read field usage setting", { context, entityType, error });
      return { frequencies, recentlyUsed };
    }
  };

  updateFieldUsage = (context: string, entityType: "universal" | EntityType, usedField: IField) => {
    const fieldsUsage = this.getFieldsUsage(context, entityType);
    // only keep most recent MAX_RECENT_FIELDS fields
    fieldsUsage.recentlyUsed = [
      // ensure that usedField will be added to the end and won't get twice in this list
      ...fieldsUsage.recentlyUsed.filter((fieldName) => fieldName !== usedField.name),
      usedField.name,
    ].slice(-MAX_RECENT_FIELDS);
    fieldsUsage.frequencies[usedField.name] = (fieldsUsage.frequencies[usedField.name] ?? 0) + 1;

    const statsJson = localStorage.getItem(this.FILTER_FIELDS_USAGE) ?? "{}";
    try {
      const stats = JSON.parse(statsJson);
      if (!stats[context]) {
        stats[context] = {};
      }
      stats[context][entityType] = fieldsUsage;
      localStorage.setItem(this.FILTER_FIELDS_USAGE, JSON.stringify(stats));
    } catch (error) {
      loggingService.error("Failed to save field usage setting", {
        context,
        entityType,
        error,
        usedField: usedField.name,
      });
    }
  };

  setDataViewParametersState = (state: DataViewParametersState) => {
    localStorage.setItem(this.DATA_VIEW_STATE, JSON.stringify(state));
  };

  getDataViewParametersState = (): DataViewParametersState | undefined => {
    const value = localStorage.getItem(this.DATA_VIEW_STATE);
    if (!value) {
      return;
    }
    try {
      return JSON.parse(value);
    } catch (e) {}
  };

  getAuthRefreshToken = (): string | undefined => {
    return localStorage.getItem(this.AUTH_REFRESH_TOKEN) ?? undefined;
  };

  setAuthRefreshToken = (newValue: string) => {
    localStorage.setItem(this.AUTH_REFRESH_TOKEN, newValue);
  };

  setAnonymousFlag = () => {
    localStorage.setItem(this.AUTH_ANONYMOUS_FLAG, "true");
  };

  isAnonymousFlagRaised = (): boolean => {
    return !!localStorage.getItem(this.AUTH_ANONYMOUS_FLAG);
  };

  removeAnonymousFlag = () => {
    localStorage.removeItem(this.AUTH_ANONYMOUS_FLAG);
  };

  getExportEntityFields = (key: string): IField["name"][] | undefined => {
    const configuration: null | string = localStorage.getItem(this.EXPORT_ENTITY_FIELDS);
    if (configuration) {
      return JSON.parse(configuration)[key];
    }
    return undefined;
  };

  setExportEntityFields = (key: string, fieldNames: IField["name"][]) => {
    const configuration: null | string = localStorage.getItem(this.EXPORT_ENTITY_FIELDS);
    localStorage.setItem(
      this.EXPORT_ENTITY_FIELDS,
      JSON.stringify({ ...(configuration ? JSON.parse(configuration) : {}), [key]: fieldNames })
    );
  };

  shouldPreventPinningGroupsAndRoutesOnMapPage = (): boolean => {
    return !!localStorage.getItem(this.PREVENT_PINNING_GROUPS_AND_ROUTES_ON_MAP_PAGE);
  };

  preventPinningGroupsAndRoutesOnMapPage = () => {
    localStorage.setItem(this.PREVENT_PINNING_GROUPS_AND_ROUTES_ON_MAP_PAGE, "1");
  };

  getGlobalSearchQuery = (): string | undefined => {
    try {
      const item: null | string = localStorage.getItem(this.GLOBAL_SEARCH_QUERY);
      if (item) {
        const config = JSON.parse(item) as { query: string; timestamp: number };
        if (Date.now() - config.timestamp < 3 * 60 * 1000) {
          return config.query;
        }
        return undefined;
      }
      return undefined;
    } catch (e) {
      return undefined;
    }
  };

  setGlobalSearchQuery = (query: string) => {
    localStorage.setItem(
      this.GLOBAL_SEARCH_QUERY,
      JSON.stringify({ query, timestamp: Date.now() })
    );
  };

  getAreaFilterCustomDistances = (): number[] => {
    const distances: null | string = localStorage.getItem(this.AREA_FILTER_CUSTOM_DISTANCES);
    try {
      if (distances) {
        return JSON.parse(distances);
      }
    } catch (e) {
      // do nothing
    }
    return [];
  };

  setAreaFilterCustomDistances = (distances: number[]) => {
    localStorage.setItem(this.AREA_FILTER_CUSTOM_DISTANCES, JSON.stringify(distances));
  };

  getFrequencyPanelOpenState = (): boolean => {
    const state: null | string = localStorage.getItem(this.FREQUENCY_PANEL_OPENED);
    if (state === "0") {
      return false;
    }
    return true;
  };

  setFrequencyPanelOpenState = (open: boolean) => {
    localStorage.setItem(this.FREQUENCY_PANEL_OPENED, open ? "1" : "0");
  };

  getCollapseMenuState = (): Record<string, string | string[]> => {
    const state: null | string = localStorage.getItem(this.COLLAPSE_MENU_STATE);
    try {
      if (state) {
        return JSON.parse(state);
      }
    } catch (e) {
      // do nothing
    }
    return {};
  };

  getCollapseMenuStateByKey = (menuKey: string): string | string[] => {
    const state: Record<string, string | string[]> = this.getCollapseMenuState();
    return state[menuKey] ?? [];
  };

  setCollapseMenuStateByKey = (menuKey: string, openKeys: string | string[]) => {
    const currentState: Record<string, string | string[]> = this.getCollapseMenuState() ?? {};
    currentState[menuKey] = openKeys;
    localStorage.setItem(this.COLLAPSE_MENU_STATE, JSON.stringify(currentState));
  };
}

const localSettings = new LocalSettings();
export default localSettings;

import { defineMessages, IntlShape } from "react-intl";

enum ViewMode {
  LIST = "list",
  KANBAN = "kanban",
  FORECAST = "forecast",
}

const messages = defineMessages<ViewMode>({
  [ViewMode.LIST]: {
    id: "deals.viewMode.list",
    defaultMessage: "List view",
    description: "Deals view mode - list",
  },
  [ViewMode.KANBAN]: {
    id: "deals.viewMode.kanban",
    defaultMessage: "Board view",
    description: "Deals view mode - kanban",
  },
  [ViewMode.FORECAST]: {
    id: "deals.viewMode.forecast",
    defaultMessage: "Forecast view",
    description: "Deals view mode - forecast",
  },
});

export const getViewModeDisplayName = (intl: IntlShape, viewMode: ViewMode) =>
  intl.formatMessage(messages[viewMode]);

export default ViewMode;

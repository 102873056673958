import { default as inlineMessages } from "./inline/messages";
import { messages as mentionsDropdownMessages } from "./richtext/component/mention/MentionsDropdown";
import { default as editableTextMessages } from "./EditableText/messages";

const messages = {
  "ui.textField.error.limit": "Character limit reached",
  ...inlineMessages,
  ...mentionsDropdownMessages,
  ...editableTextMessages,
} as const;

export default messages;

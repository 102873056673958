import {
  Actions,
  hideEntityView,
  setEntityTypesWithExternalPreview,
  showEntityView,
} from "./actions";
import { createReducer } from "typesafe-actions";
import { RootState } from "../rootReducer";
import { createSelector } from "reselect";
import { AnyEntity, EntityType } from "@mapmycustomers/shared/types/entity";
import EditActivityFocusableField from "../../component/activity/type/EditActivityFocusableField";

export interface EntityViewState {
  edit?: boolean;
  editValues?: Partial<AnyEntity>;
  entityTypesWithExternalPreview: EntityType[];
  elementId?: string;
  entityId?: AnyEntity["id"];
  entityType?: EntityType;
  focusedFieldName?: EditActivityFocusableField;
  requiredFieldsOnly?: boolean;
  zIndex?: number;
}

const initialState: EntityViewState = {
  entityTypesWithExternalPreview: [],
};

const entityView = createReducer<EntityViewState, Actions>(initialState)
  .handleAction(
    showEntityView,
    (
      state,
      {
        payload: {
          edit,
          editValues,
          entityType,
          entityId,
          elementId,
          focusedFieldName,
          requiredFieldsOnly,
          zIndex,
        },
      }
    ) => ({
      ...state,
      edit,
      editValues,
      elementId,
      entityId,
      entityType,
      focusedFieldName,
      requiredFieldsOnly,
      zIndex,
    })
  )
  .handleAction(hideEntityView, (state) => ({
    ...state,
    entityType: undefined,
    entityId: undefined,
  }))
  .handleAction(setEntityTypesWithExternalPreview, (state, { payload }) => ({
    ...state,
    entityTypesWithExternalPreview: payload,
  }));

const entityViewState = (state: RootState) => state.entityView;

export const getEntityView = createSelector(entityViewState, (entityView) => entityView);

export const getEntityViewEntityType = createSelector(
  entityViewState,
  ({ entityType }) => entityType
);

export const getEntityViewEntityId = createSelector(entityViewState, ({ entityId }) => entityId);

export const isEntityViewVisible = createSelector(
  entityViewState,
  ({ entityId, entityType }) => !!entityId && !!entityType
);

export const shouldEditPreviewedEntity = createSelector(entityViewState, ({ edit }) => edit);

export const getEditValues = createSelector(entityViewState, ({ editValues }) => editValues);

export const shouldEditRequiredFieldsOnly = createSelector(
  entityViewState,
  ({ requiredFieldsOnly }) => !!requiredFieldsOnly
);

export const getPreviewPaneZIndex = createSelector(entityViewState, ({ zIndex }) => zIndex);

export const getEntityViewElement = createSelector(entityViewState, ({ elementId }) => elementId);

export const getFocusedFieldName = createSelector(
  entityViewState,
  ({ focusedFieldName }) => focusedFieldName
);
export const getEntityTypesWithExternalPreview = createSelector(
  entityViewState,
  ({ entityTypesWithExternalPreview }) => entityTypesWithExternalPreview
);

export type EntityViewActions = Actions;
export default entityView;

import { UploadFile } from "antd/es/upload/interface";
import { useMemo } from "react";
import File from "@mapmycustomers/shared/types/File";
import { complementFileUrl } from "util/file";

const useProfilePhotoFileList = (isUploading: boolean, profilePhoto?: File): UploadFile[] =>
  useMemo(() => {
    if (isUploading) {
      return [
        {
          uid: "profilePhoto",
          status: "uploading",
          name: "",
          type: "",
          size: 0,
        },
      ];
    }
    if (profilePhoto?.publicURI) {
      return [
        {
          uid: "profilePhoto",
          status: "done",
          name: profilePhoto.name,
          url: complementFileUrl(profilePhoto.publicURI),
          type: profilePhoto.contentType,
          size: profilePhoto.contentLength ?? 0,
        },
      ];
    }
    return [];
  }, [isUploading, profilePhoto]);

export default useProfilePhotoFileList;

import { ActionType, createAction } from "typesafe-actions";
import Legend from "scene/map/enums/Legend";

export const toggleLegendVisibility = createAction("map/legends/toggleVisibility")<{
  name: Legend;
  visible: boolean;
}>();

export const toggleLegendExpandedState = createAction("map/legends/toggleExpandedState")<{
  expanded: boolean;
  name: Legend;
}>();

export const setLegendsState = createAction("map/legends/setLegendsState")<unknown | undefined>();

export type LegendsActions = ActionType<
  typeof setLegendsState | typeof toggleLegendVisibility | typeof toggleLegendExpandedState
>;

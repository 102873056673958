import { AnalyticsService } from "../analytic/AnalyticsService";
import useChangeTracking from "@mapmycustomers/shared/util/hook/useChangeTracking";

const useModalVisibilityAnalyticEffect = (
  modalName: string,
  visibility: boolean,
  analyticIssuer?: AnalyticsService
) => {
  useChangeTracking(() => {
    if (visibility) {
      analyticIssuer?.using([modalName]);
    } else {
      analyticIssuer?.clicked([modalName, "Close"]);
    }
  }, [analyticIssuer, modalName, visibility]);
};

export default useModalVisibilityAnalyticEffect;

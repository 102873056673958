import notification from "antd/es/notification";
import isEqual from "lodash-es/isEqual";
import { all, call, delay, put, race, select, takeEvery, takeLatest } from "redux-saga/effects";
import { defineMessages } from "react-intl";
import {
  addDealParentCompany,
  addDealParentPerson,
  changeActivitiesRecapRange,
  changeActivitiesSelectedActivityTypes,
  changeAssociatedEntities,
  createDealNote,
  deleteDeal,
  deleteDealFile,
  deleteDealNote,
  downloadDealFile,
  fetchDeal,
  fetchDealActivities,
  fetchDealActivitiesCompletedByType,
  fetchDealActivitiesTotal,
  fetchPreviewData,
  fetchThumbnail,
  goToCalendarSettings,
  postponeActivity,
  relateEntities,
  removeDealParentCompany,
  removeDealParentPerson,
  toggleCompleteActivity,
  updateActivityNote,
  updateDeal,
  updateDealFrequency,
  updateDealNote,
  uploadDealFiles,
} from "./actions";
import { handleError } from "store/errors/actions";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { RawFile } from "@mapmycustomers/shared/types/File";
import { callApi } from "store/api/callApi";
import { getActivityTypes } from "store/activity";
import { getCurrentUser, getOrganization, getOrganizationId } from "store/iam";
import { Activity, Company, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import Note from "@mapmycustomers/shared/types/entity/common/Note";
import Organization from "@mapmycustomers/shared/types/Organization";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import FileListItem from "types/FileListItem";
import localSettings from "config/LocalSettings";
import { allSettled, SettleResult } from "util/effects";
import { downloadFileByUrl } from "util/file";
import { recapRangeIntervalMap } from "enum/preview/RecapRange";
import { notifyAboutChanges } from "store/uiSync/actions";
import { getDeal, getRecordData } from "./selectors";
import DealRecordData from "./DealRecordData";
import getSuccessNotificationNode from "../../../createEditEntity/util/getSuccessNotificationNode";
import i18nService from "config/I18nService";
import getStartRange from "component/preview/util/getStartRange";
import Path from "enum/Path";
import SettingPath from "enum/settings/SettingPath";
import { push } from "connected-react-router";
import User from "@mapmycustomers/shared/types/User";
import { updateMetadata } from "store/iam/actions";
import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import { convertToPlatformSortModel } from "util/viewModel/convertSort";
import getFileRemovedNotificationNode from "component/preview/util/getFileRemovedNotificationNode";
import { NOTIFICATION_DURATION_WITH_ACTION } from "util/consts";
import getYourDownloadWillStartShortlyNode from "component/preview/util/getYourDownloadWillStartShortlyNode";
import { activityLayoutModel } from "util/layout/impl";
import omit from "lodash-es/omit";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import dealFieldModel, { DealFieldName } from "util/fieldModel/DealFieldModel";
import getNextAssociationsState, {
  NextAssociationsState,
} from "store/associations/getNextAssociationsState";
import loggingService from "util/logging";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";

const messages = defineMessages({
  postponeSuccess: {
    id: "dealRecordPane.activities.postpone.success",
    defaultMessage: "Activity postponed successfully",
    description: "Activity postpone success message",
  },
  toggleCompleteSuccess: {
    id: "dealRecordPane.activities.toggleComplete.success",
    defaultMessage: 'Activity{completed, select, true {} other { is no longer}} marked as "Done"',
    description: "Activity toggle complete success message",
  },
  deleteSuccess: {
    id: "dealRecordPane.delete.success",
    defaultMessage: "Deal Deleted Successfully",
    description: "Deal deleted success message",
  },
  addCompanySuccess: {
    id: "dealRecordPane.addCompany.success",
    defaultMessage: "Deal successfully related to Company",
    description: "Deal successfully related to Company message",
  },
  addPersonSuccess: {
    id: "dealRecordPane.addPerson.success",
    defaultMessage: "Deal successfully related to Person",
    description: "Deal successfully related to Person message",
  },
});

export function* onFetchPreviewData({
  payload: dealId,
}: ReturnType<typeof fetchPreviewData.request>) {
  try {
    const organization: Organization = yield select(getOrganization);

    const deal: Deal = yield callApi("fetchDeal", organization.id, dealId, {
      includeAccessStatus: true,
      includeCustomFields: true,
      includeGroups: true,
      includeFiles: true,
      includeTerritories: true,
      includeUsersWithAccess: true,
    });

    const notesField: IField | undefined = yield call(
      [dealFieldModel, dealFieldModel.getByName],
      DealFieldName.NOTES
    );

    const [notes, activitiesResponse, uncompletedActivitiesResponse]: [
      ListResponse<Note>,
      ListResponse<Activity>,
      ListResponse<Activity>
    ] = yield all([
      notesField?.isReadable
        ? callApi("fetchNotes", EntityType.DEAL, deal.id, { $limit: 1000, $order: "-updatedAt" })
        : { data: [] },
      callApi("fetchActivities", organization.id, {
        $filters: {
          includeDealSharedActivities: true,
          $and: [
            {
              dealId,
            },
          ],
        },
        $columns: ["id"],
        $limit: 0,
      }),
      callApi("fetchActivities", organization.id, {
        $filters: {
          includeAccessStatus: true,
          includeDealSharedActivities: true,
          $and: [
            {
              dealId,
              completed: false,
            },
          ],
        },
        $columns: ["id", "startAt"],
        $order: "startAt",
        $limit: 1000,
      }),
    ]);

    const [companyParent, personParent]: [
      SettleResult<Company | undefined>,
      SettleResult<Person | undefined>
    ] = yield allSettled([
      deal.account
        ? callApi("fetchCompany", organization.id, deal.account.id, {
            includeAccessStatus: true,
            includeCustomFields: true,
            includeGroups: true,
            includeTerritories: true,
          })
        : undefined,
      deal.contact
        ? callApi("fetchPerson", organization.id, deal.contact.id, {
            includeAccessStatus: true,
            includeCustomFields: true,
            includeGroups: true,
            includeTerritories: true,
          })
        : undefined,
    ]);

    const user: User = yield select(getCurrentUser);

    // get stored selected activity types ids
    const activityTypes: ActivityType[] = yield select(getActivityTypes);
    const selectedActivityTypesIds = user?.metaData?.recapChartTypesIds;

    // find corresponding activity types
    let selectedActivityTypes: ActivityType[] = selectedActivityTypesIds
      ? activityTypes.filter(({ id }) => selectedActivityTypesIds.includes(id))
      : activityTypes;

    const associations: NextAssociationsState = yield call(getNextAssociationsState, {
      company: !companyParent.error ? companyParent.result : deal.account,
      person: !personParent.error ? personParent.result : deal.contact,
      entityType: EntityType.DEAL,
    });

    yield put(
      fetchPreviewData.success({
        recordData: {
          activities: [],
          activitiesCompletedByType: [],
          activitiesTotal: activitiesResponse.total,
          associations: {
            ...associations,
            associatedCompany: !companyParent.error ? companyParent.result : deal.account,
            associatedPerson: !personParent.error ? personParent.result : deal.contact,
          },
          deal,
          files: deal.files ?? [],
          notes: notes.data,
          uncompletedActivities: uncompletedActivitiesResponse.data,
        },
        selectedActivityTypes,
      })
    );
  } catch (error) {
    yield put(fetchPreviewData.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchDealActivities({
  payload: { activityTypes, deal, filter, recapRange, search, selectedAssignees, order },
}: ReturnType<typeof fetchDealActivities.request>) {
  try {
    const recapInterval = recapRangeIntervalMap[recapRange];
    const organization: Organization = yield select(getOrganization);
    const activitiesResponse: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organization.id,
      {
        $filters: {
          includeAccessStatus: true,
          includeDealSharedActivities: true,
          includeCustomFields: true,
          $and: [
            {
              dealId: deal.id,
              "crmActivityType.id": { $in: activityTypes.map(({ id }) => id) },
              completed:
                filter === ActivityStatusOption.COMPLETED
                  ? true
                  : filter === ActivityStatusOption.OVERDUE
                  ? false
                  : undefined,
              name: search ? { $in: search.trim() } : undefined,
              startAt: getStartRange(recapInterval, filter),
              ...(selectedAssignees.length ? { assigneeId: { $in: selectedAssignees } } : {}),
            },
          ],
        },
        $limit: 1000,
        $order: convertToPlatformSortModel(order),
      }
    );
    yield put(fetchDealActivities.success(activitiesResponse.data));
  } catch (error) {
    yield put(fetchDealActivities.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchDealActivitiesCompletedByType({
  payload: { activityTypes, deal, filter, recapRange, selectedAssignees, order },
}: ReturnType<typeof fetchDealActivitiesCompletedByType.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const recapInterval = recapRangeIntervalMap[recapRange];

    const activitiesResponse: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organization.id,
      {
        $filters: {
          includeAccessStatus: true,
          $and: [
            {
              dealId: deal.id,
              "crmActivityType.id": { $in: activityTypes.map(({ id }) => id) },
              startAt: getStartRange(recapInterval, filter),
              ...(selectedAssignees.length ? { assigneeId: { $in: selectedAssignees } } : {}),
            },
          ],
          includeDealSharedActivities: true,
          includeCustomFields: true,
        },
        $limit: 1000,
        $order: convertToPlatformSortModel(order),
      }
    );
    yield put(fetchDealActivitiesCompletedByType.success(activitiesResponse.data));
  } catch (error) {
    yield put(fetchDealActivitiesCompletedByType.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchThumbnail({ payload }: ReturnType<typeof fetchThumbnail>) {
  try {
    const org: Organization = yield select(getOrganization);
    const deal: Deal = yield select(getDeal);
    const fileData: Blob = yield callApi(
      "fetchFile",
      org.id,
      payload.fileId,
      false,
      true,
      EntityType.DEAL,
      deal.id,
      { responseType: "blob" }
    );
    yield call(payload.callback, fileData);
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onFetchDealActivitiesTotal({
  payload,
}: ReturnType<typeof fetchDealActivitiesTotal.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const activitiesResponse: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organization.id,
      {
        $filters: {
          includeDealSharedActivities: true,
          $and: [
            {
              dealId: payload.id,
            },
          ],
        },
        $columns: ["id"],
        $limit: 0,
      }
    );
    yield put(fetchDealActivitiesTotal.success(activitiesResponse.total));
  } catch (error) {
    yield put(fetchDealActivitiesTotal.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchDeal({ payload: deal }: ReturnType<typeof fetchDeal.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const response: Deal = yield callApi("fetchDeal", organization.id, deal.id, {
      includeAccessStatus: true,
      includeGroups: true,
      includeTerritories: true,
    });
    yield put(fetchDeal.success(response));
  } catch (error) {
    yield put(fetchDeal.failure());
    yield put(handleError({ error }));
  }
}

function* onAddDealParentCompany({
  payload: { id, parentCompanyId },
}: ReturnType<typeof addDealParentCompany.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    const [deal, parentCompany]: [Deal, Company] = yield all([
      callApi("updateDeal", org.id, undefined, {
        id,
        account: { id: parentCompanyId },
      }),
      callApi("fetchCompany", org.id, parentCompanyId, { includeAccessStatus: true }),
    ]);

    notification.success({
      message: i18nService.formatMessage(
        messages.addCompanySuccess,
        "Deal successfully related to Company"
      ),
    });

    yield put(addDealParentCompany.success(parentCompany));
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: [deal] }));
  } catch (error) {
    yield put(addDealParentCompany.failure());
    yield put(handleError({ error }));
  }
}

function* onRemoveDealParentCompany({
  payload,
}: ReturnType<typeof removeDealParentCompany.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    const deal: Deal = yield callApi("updateDeal", org.id, undefined, {
      id: payload,
      account: null,
    });

    yield put(removeDealParentCompany.success(payload));
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: [deal] }));
  } catch (error) {
    yield put(removeDealParentCompany.failure(payload));
    yield put(handleError({ error }));
  }
}

function* onAddDealParentPerson({
  payload: { id, parentPersonId },
}: ReturnType<typeof addDealParentPerson.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    const [deal, parentPerson]: [Deal, Person] = yield all([
      callApi("updateDeal", org.id, undefined, {
        id,
        contact: { id: parentPersonId },
      }),
      callApi("fetchPerson", org.id, parentPersonId, { includeAccessStatus: true }),
    ]);

    notification.success({
      message: i18nService.formatMessage(
        messages.addPersonSuccess,
        "Deal successfully related to Person"
      ),
    });

    yield put(addDealParentPerson.success(parentPerson));
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: [deal] }));
  } catch (error) {
    yield put(addDealParentPerson.failure());
    yield put(handleError({ error }));
  }
}

function* onRemoveDealParentPerson({ payload }: ReturnType<typeof removeDealParentPerson.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    const deal: Deal = yield callApi("updateDeal", org.id, undefined, {
      id: payload,
      contact: null,
    });

    yield put(removeDealParentPerson.success(payload));
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: [deal] }));
  } catch (error) {
    yield put(removeDealParentPerson.failure(payload));
    yield put(handleError({ error }));
  }
}

export function* onUpdateDeal({
  payload: { deal, customFields, callback, groupIdsToAdd, groupIdsToRemove, layoutId },
}: ReturnType<typeof updateDeal.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const recordData: DealRecordData = yield select(getRecordData);

    const readOnlyFields = new Set(
      dealFieldModel.fields
        .filter((field) => !field.isEditable || field.hasFeature(FieldFeature.CALCULATED_FIELD))
        .map((field) => field.name)
    );

    const changedCustomFields = Object.values(customFields ?? {}).filter((customField) => {
      if (!customField) {
        return false;
      }
      if (readOnlyFields.has(customField.esKey)) {
        return false;
      }

      const oldCustomField = recordData.deal?.customFields?.find(
        ({ customField: { id } }) => id === customField.customField.id
      );
      return !oldCustomField || !isEqual(oldCustomField.value, customField.value);
    });

    const strippedDeal = readOnlyFields.size
      ? // implement proper fields deletion later
        (omit(deal, Array.from(readOnlyFields)) as Identified)
      : deal;
    yield callApi("updateDeal", org.id, layoutId, strippedDeal);

    if (changedCustomFields.length) {
      if (layoutId) {
        yield callApi(
          "upsertCustomFieldsValues",
          true,
          org.id,
          layoutId,
          EntityType.DEAL,
          deal.id,
          changedCustomFields
        );
      } else {
        loggingService.error(
          `Tried to update deal's (#${deal.id}) custom fields without providing layoutId`
        );
      }
    }

    const dealIds: Deal["id"][] = [deal.id];
    yield all([
      ...(groupIdsToAdd ?? []).map((groupId) =>
        callApi("addToGroup", org.id, groupId, EntityType.DEAL, dealIds)
      ),
      ...(groupIdsToRemove ?? []).map((groupId) =>
        callApi("deleteFromGroup", org.id, groupId, EntityType.DEAL, dealIds)
      ),
    ]);

    const updatedDeal: Deal = yield callApi("fetchDeal", org.id, deal.id, {
      includeAccessStatus: true,
      includeCustomFields: true,
      includeGroups: true,
      includeTerritories: true,
    });
    yield put(updateDeal.success(updatedDeal));
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: [updatedDeal] }));
    callback?.(updatedDeal);
  } catch (error) {
    yield put(updateDeal.failure());
    yield put(handleError({ error }));
  }
}

export function* onDeleteDeal() {
  try {
    const org: Organization = yield select(getOrganization);
    const deal: Deal = yield select(getDeal);
    yield callApi("deleteDeal", org.id, deal.id);
    yield put(deleteDeal.success());
    notification.success({
      message: i18nService.formatMessage(messages.deleteSuccess, "Deal Deleted Successfully"),
    });
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, deletedIds: [deal.id] }));
  } catch (error) {
    yield put(deleteDeal.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreateDealNote({ payload }: ReturnType<typeof createDealNote.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const deal: Deal = yield select(getDeal);
    const note: Note = yield callApi("createNote", org.id, EntityType.DEAL, deal.id, payload);
    yield put(createDealNote.success(note));
    yield put(
      notifyAboutChanges({
        entityType: EntityType.DEAL,
        updated: [{ ...deal, notes: [...(deal.notes ?? []), note] }],
      })
    );
  } catch (error) {
    yield put(createDealNote.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateDealNote({ payload }: ReturnType<typeof updateDealNote.request>) {
  try {
    const deal: Deal = yield select(getDeal);
    const updatedNote: Note = yield callApi("updateNote", EntityType.DEAL, deal.id, payload);
    yield put(updateDealNote.success(updatedNote));
    yield put(
      notifyAboutChanges({
        entityType: EntityType.DEAL,
        updated: [
          {
            ...deal,
            notes: (deal.notes ?? []).map((note) =>
              note.id === updatedNote.id ? updatedNote : note
            ),
          },
        ],
      })
    );
  } catch (error) {
    yield put(updateDealNote.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onUploadDealFiles({ payload }: ReturnType<typeof uploadDealFiles.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const deal: Deal = yield select(getDeal);
    const responses: SettleResult<RawFile>[] = yield allSettled(
      payload.files.map((file) =>
        callApi("createFile", org.id, file, false, EntityType.DEAL, deal.id)
      )
    );
    const fileList: FileListItem[] = responses.map((response, index) => ({
      file: payload.files[index],
      uploading: false,
      ...(response.error
        ? { errored: true, errorMessage: String(response.result) }
        : { errored: false, uploadedFile: response.result }),
    }));
    payload.callback?.(fileList);
    yield put(uploadDealFiles.success(fileList));
  } catch (error) {
    payload.callback?.(payload.files.map((file) => ({ file, uploading: false, errored: true })));
    yield put(uploadDealFiles.failure());
    yield put(handleError({ error }));
  }
}

export function* onDeleteDealFile({ payload }: ReturnType<typeof deleteDealFile.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const deal: Deal = yield select(getDeal);

    const notificationKey = `file_removal_${payload.id}`;
    const cancelled = new Promise<true>((resolve) => {
      notification.info({
        key: notificationKey,
        message: getFileRemovedNotificationNode(i18nService.getIntl(), payload, () => {
          resolve(true);
          notification.close(notificationKey);
        }),
        duration: NOTIFICATION_DURATION_WITH_ACTION,
      });
    });

    let markWindowClosed = (flag: true) => {};
    const windowClosed = new Promise<boolean>((resolve) => {
      markWindowClosed = resolve; // I need to "extract" it from here, so that I can call removeEventListener later
    });
    const documentListener = () => {
      if (document.visibilityState === "hidden") {
        markWindowClosed(true);
        notification.close(notificationKey);
      }
    };
    document.addEventListener("visibilitychange", documentListener);
    const windowListener = (event: BeforeUnloadEvent) => markWindowClosed(true);
    window.addEventListener("beforeunload", windowListener);

    const result: { delete?: true; cancelled?: Promise<true>; windowClosed?: Promise<true> } =
      yield race({
        delete: delay(NOTIFICATION_DURATION_WITH_ACTION * 1000 /* since it is in seconds */),
        cancelled,
        windowClosed,
      });

    window.removeEventListener("beforeunload", windowListener);
    document.removeEventListener("visibilitychange", documentListener);

    if (result.delete || result.windowClosed) {
      yield callApi("deleteEntityFile", org.id, EntityType.DEAL, deal.id, payload.id);
      yield put(deleteDealFile.success({ file: payload, removed: true }));
    } else {
      yield put(deleteDealFile.success({ file: payload, removed: false }));
    }
  } catch (error) {
    yield put(deleteDealFile.failure(payload));
    yield put(handleError({ error }));
  }
}

export function* onDownloadDealFile({ payload: file }: ReturnType<typeof downloadDealFile>) {
  try {
    const org: Organization = yield select(getOrganization);
    const deal: Deal = yield select(getDeal);

    const key = `download_file_${file.id}`;
    const timeout = setTimeout(() => {
      notification.info({
        key,
        message: getYourDownloadWillStartShortlyNode(i18nService.getIntl(), file.name),
        duration: 0, // don't close automatically, we'll close it ourselves
      });
    }, 2000);

    const fileBlob: Blob = yield callApi(
      "fetchFile",
      org.id,
      file.id,
      true,
      false,
      EntityType.DEAL,
      deal.id,
      { responseType: "blob", timeout: 0 }
    );
    clearTimeout(timeout);
    notification.close(key);

    yield call(downloadFileByUrl, window.URL.createObjectURL(fileBlob), file.name);
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onDeleteDealNote({ payload }: ReturnType<typeof deleteDealNote.request>) {
  try {
    const deal: Deal = yield select(getDeal);
    yield callApi("deleteNote", EntityType.DEAL, deal.id, payload);
    yield put(deleteDealNote.success(payload.id));
    yield put(
      notifyAboutChanges({
        entityType: EntityType.DEAL,
        updated: [
          {
            ...deal,
            notes: (deal.notes ?? []).filter((note) => note.id !== payload.id),
          },
        ],
      })
    );
  } catch (error) {
    yield put(deleteDealNote.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onPostponeActivity({ payload }: ReturnType<typeof postponeActivity.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload).id;

    const updatedActivity: Activity = yield callApi(
      "updateActivity",
      organization.id,
      layoutId,
      payload
    );
    yield put(postponeActivity.success(updatedActivity));

    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(messages.postponeSuccess, "Activity postponed successfully")
      ),
    });
  } catch (error) {
    yield put(postponeActivity.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onToggleCompleteActivity({
  payload,
}: ReturnType<typeof toggleCompleteActivity.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload).id;

    const response: Activity = yield callApi("updateActivity", organization.id, layoutId, {
      ...payload,
      completed: !payload.completed,
    });
    yield put(toggleCompleteActivity.success(response));

    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(
          messages.toggleCompleteSuccess,
          `Activity marked as "${response.completed ? "Done" : "Not yet done"}"`,
          { completed: response.completed }
        )
      ),
    });
  } catch (error) {
    yield put(toggleCompleteActivity.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onUpdateActivityNote({ payload }: ReturnType<typeof updateActivityNote.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload.activity).id;

    const response: Activity = yield callApi(
      "updateActivity",
      organization.id,
      layoutId,
      payload.activity
    );
    yield put(
      updateActivityNote.success({
        id: response.id,
        note: response.note,
      })
    );
    payload.onSuccess();
  } catch (error) {
    yield put(updateActivityNote.failure(payload.activity.id));
    yield put(handleError({ error }));
  }
}

export function* onChangeActivitiesRecapRange({
  payload: recapRange,
}: ReturnType<typeof changeActivitiesRecapRange>) {
  yield call(localSettings.setRecapChartRange, recapRange);
}

export function* onChangeActivitiesSelectedActivityTypes({
  payload: activityTypes,
}: ReturnType<typeof changeActivitiesSelectedActivityTypes>) {
  yield put(updateMetadata.request({ recapChartTypesIds: activityTypes.map(({ id }) => id) }));
}

export function* onGoToCalendarSettings() {
  yield put(push(`${Path.SETTINGS}/${SettingPath.PERSONAL_CALENDAR_SYNC}`));
}

export function* onChangeAssociatedEntity({
  payload,
}: ReturnType<typeof changeAssociatedEntities.request>) {
  try {
    const associationsState: NextAssociationsState = yield call(getNextAssociationsState, {
      ...payload,
      entityType: EntityType.DEAL,
    });

    yield put(changeAssociatedEntities.success(associationsState));
  } catch (error) {
    yield put(changeAssociatedEntities.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateDealFrequency({
  payload: { dealId },
}: ReturnType<typeof updateDealFrequency.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const deal: Deal = yield callApi("fetchDeal", orgId, dealId, {
      includeAccessStatus: true,
      includeCustomFields: true,
      includeGroups: true,
      includeTerritories: true,
      includeUsersWithAccess: true,
    });
    yield put(updateDealFrequency.success(deal));
  } catch (error) {
    yield put(updateDealFrequency.failure());
    yield put(handleError({ error }));
  }
}

export function* onRelateEntities({
  payload: {
    associatedCompany,
    associatedPerson,
    failureCallback,
    isPersonCorrectlyRelatedToCompany,
    successCallback,
  },
}: ReturnType<typeof relateEntities.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    let person: Person | undefined;
    if (!isPersonCorrectlyRelatedToCompany && associatedPerson && associatedCompany) {
      person = yield callApi("updatePerson", orgId, undefined, {
        id: associatedPerson.id,
        account: { id: associatedCompany.id },
      });
    }
    successCallback?.();
    yield put(relateEntities.success({ person }));
  } catch (error) {
    failureCallback?.();
    yield put(relateEntities.failure());
    yield put(handleError({ error }));
  }
}

export function* dealRecordSaga() {
  yield takeEvery(addDealParentCompany.request, onAddDealParentCompany);
  yield takeEvery(addDealParentPerson.request, onAddDealParentPerson);
  yield takeEvery(createDealNote.request, onCreateDealNote);
  yield takeLatest(changeAssociatedEntities.request, onChangeAssociatedEntity);
  yield takeEvery(deleteDeal.request, onDeleteDeal);
  yield takeEvery(deleteDealFile.request, onDeleteDealFile);
  yield takeEvery(deleteDealNote.request, onDeleteDealNote);
  yield takeEvery(downloadDealFile, onDownloadDealFile);
  yield takeLatest(fetchDealActivities.request, onFetchDealActivities);
  yield takeLatest(
    fetchDealActivitiesCompletedByType.request,
    onFetchDealActivitiesCompletedByType
  );
  yield takeLatest(fetchPreviewData.request, onFetchPreviewData);
  yield takeLatest(fetchDealActivitiesTotal.request, onFetchDealActivitiesTotal);
  yield takeLatest(fetchDeal.request, onFetchDeal);
  yield takeEvery(postponeActivity.request, onPostponeActivity);
  yield takeEvery(removeDealParentCompany.request, onRemoveDealParentCompany);
  yield takeEvery(removeDealParentPerson.request, onRemoveDealParentPerson);
  yield takeEvery(toggleCompleteActivity.request, onToggleCompleteActivity);
  yield takeEvery(updateActivityNote.request, onUpdateActivityNote);
  yield takeEvery(updateDeal.request, onUpdateDeal);
  yield takeEvery(uploadDealFiles.request, onUploadDealFiles);
  yield takeEvery(updateDealNote.request, onUpdateDealNote);
  yield takeEvery(changeActivitiesRecapRange, onChangeActivitiesRecapRange);
  yield takeEvery(changeActivitiesSelectedActivityTypes, onChangeActivitiesSelectedActivityTypes);
  yield takeEvery(goToCalendarSettings, onGoToCalendarSettings);
  yield takeEvery(fetchThumbnail, onFetchThumbnail);
  yield takeEvery(updateDealFrequency.request, onUpdateDealFrequency);
  yield takeEvery(relateEntities.request, onRelateEntities);
}

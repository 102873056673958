import React, { useCallback, useMemo, useRef } from "react";
import Select from "antd/es/select";
import { EntityTypeSupportingGroups, Group } from "@mapmycustomers/shared/types/entity";
import { useIntl } from "react-intl";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { CreateGroupModal } from "component/createEditEntity/Group";
import defaultSelectOptionMapper from "util/mappers/defaultSelectOptionMapper";
import useGroupTagRender from "component/EntityTag/useGroupTagRender";
import { AnalyticsService } from "util/analytic/AnalyticsService";
import useChangeTracking from "@mapmycustomers/shared/util/hook/useChangeTracking";
import useModalVisibilityAnalyticEffect from "util/hook/useModalVisibilityAnalyticEffect";
import defaultFilterOption from "../input/utils/defaultFilterOption";
import ButtonLink from "component/ButtonLink";
import cn from "classnames";
import styles from "./GroupsSelection.module.scss";

interface Props {
  analyticIssuer?: AnalyticsService;
  className?: string;
  disabled?: boolean;
  entityType: EntityTypeSupportingGroups;
  groups: Group[];
  onChange?: (groupIds: Set<Group["id"]>) => void;
  selectedGroupIds?: Set<Group["id"]>;
}

const GroupsSelection: React.FC<Props> = ({
  analyticIssuer,
  className,
  disabled,
  entityType,
  groups,
  onChange,
  selectedGroupIds,
}) => {
  const intl = useIntl();
  const container = useRef<HTMLDivElement>(null);

  const groupOptions = useMemo(
    () => groups.sort((a, b) => a.name.localeCompare(b.name)).map(defaultSelectOptionMapper),
    [groups]
  );

  const handleGroupSelectionChange = useCallback(
    (checkedValues: Array<Group["id"]>) => {
      onChange?.(new Set(checkedValues));
      analyticIssuer?.clicked(["Change Groups"], { groupIds: checkedValues });
    },
    [analyticIssuer, onChange]
  );

  const [newGroupModalVisible, showNewGroupModal, hideNewGroupModal] = useBoolean();
  useModalVisibilityAnalyticEffect("Create New Group", newGroupModalVisible, analyticIssuer);

  const handleHideCreateGroupModel = useCallback(() => hideNewGroupModal(), [hideNewGroupModal]);
  const handleCreateGroup = useCallback(
    (group: Group) => {
      const newSelectedGroupIds = new Set(selectedGroupIds);
      newSelectedGroupIds.add(group.id);
      onChange?.(newSelectedGroupIds);
      analyticIssuer?.clicked(["Create New Group", "Save"], { group });
    },
    [analyticIssuer, onChange, selectedGroupIds]
  );

  useChangeTracking(
    () => analyticIssuer?.clicked(["Create New Group", ...(newGroupModalVisible ? [] : ["Close"])]),
    [analyticIssuer, newGroupModalVisible]
  );

  return (
    <>
      <div className={cn(styles.container, className)} ref={container}>
        <Select<Array<Group["id"]>>
          className={styles.select}
          disabled={disabled}
          filterOption={defaultFilterOption}
          mode="multiple"
          onChange={handleGroupSelectionChange}
          options={groupOptions}
          placeholder={intl.formatMessage({
            id: "groupsSelection.select.placeholder",
            defaultMessage: "Click or type to select groups",
            description: "Placeholder displayed in a Group select field",
          })}
          tagRender={useGroupTagRender(groups)}
          value={selectedGroupIds ? Array.from(selectedGroupIds) : []}
        />
        <ButtonLink disabled={disabled} onClick={showNewGroupModal}>
          {intl.formatMessage({
            id: "groupsSelection.newGroup.button",
            defaultMessage: "+ Create New Group",
            description: "Create new group button in a Group select field",
          })}
        </ButtonLink>
      </div>
      <CreateGroupModal
        entityType={entityType}
        onCreate={handleCreateGroup}
        onHide={handleHideCreateGroupModel}
        visible={newGroupModalVisible}
      />
    </>
  );
};

export default GroupsSelection;

import LocationViewMode from "enum/LocationViewMode";
import Color from "@mapmycustomers/shared/enum/Color";
import { Company } from "@mapmycustomers/shared/types/entity";

const getInitialValues = (initialValues?: Partial<Company>) => {
  const result = initialValues
    ? {
        color: Color.BLACK,
        name: initialValues.name,
        phone: initialValues.phone,
        website: initialValues.website,
      }
    : {};
  return initialValues
    ? initialValues.address || initialValues.city || !initialValues.geoPoint
      ? {
          ...result,
          addressDetails: {
            address: initialValues.address,
            city: initialValues.city,
            region: initialValues.region,
            regionCode: initialValues.regionCode,
            postalCode: initialValues.postalCode,
            countryCode: initialValues.countryCode,
          },
          locationViewMode: LocationViewMode.ADDRESS,
        }
      : {
          ...result,
          longitude: initialValues.geoPoint.coordinates[0] ?? "",
          latitude: initialValues.geoPoint.coordinates[1] ?? "",
          locationViewMode: LocationViewMode.COORDINATES,
        }
    : {
        // default location view mode is "address"
        locationViewMode: LocationViewMode.ADDRESS,
      };
};

export default getInitialValues;

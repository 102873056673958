import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const componentState = (state: RootState) => state.component.editStageModal;

export const getOriginalDeal = createSelector(componentState, ({ originalDeal }) => originalDeal);
export const isLoading = createSelector(componentState, ({ loading }) => loading);

export const getAssociationsState = createSelector(
  componentState,
  ({ associations }) => associations
);

export const getUploadedFilesList = createSelector(componentState, ({ filesAdded }) => filesAdded);

export const getUploadedFileListIds = createSelector(getUploadedFilesList, (filesAdded) =>
  filesAdded
    .filter(({ uploadedFile }) => !!uploadedFile)
    .map(({ uploadedFile }) => uploadedFile!.id)
);

export const isFilesUploading = createSelector(
  componentState,
  ({ filesUploading }) => filesUploading
);

export const isFilesDeleting = createSelector(componentState, ({ filesDeleting }) => filesDeleting);

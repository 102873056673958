import { useCallback, useRef } from "react";

// it works pretty much like callback methods in class components: i.e. it is still the same
// method, but it can see changed props
const useDynamicCallback = <T extends Function>(callback: T) => {
  const ref = useRef<T>(callback);
  ref.current = callback;
  return useCallback(
    // must be a `function` to correctly pass `this`
    function (this: any, ...args) {
      return ref.current.apply(this, args);
    },
    []
  );
};

export default useDynamicCallback;

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { Territory } from "@mapmycustomers/shared/types/entity";
import ListRequest from "@mapmycustomers/shared/types/viewModel/internalModel/ListRequest";
import User from "@mapmycustomers/shared/types/User";
import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import AreaType from "@mapmycustomers/shared/types/territory/AreaType";
import { GeoMultiPolygon, GeoPolygon } from "@mapmycustomers/shared/types/shapes";

export const fetchAllTerritories = createAsyncAction(
  "@territories/fetchAll/request",
  "@territories/fetchAll/success",
  "@territories/fetchAll/failure"
)<{ force: boolean } | void, void, void>();

export const fetchTerritories = createAsyncAction(
  "@territories/fetch/request",
  "@territories/fetch/success",
  "@territories/fetch/failure"
)<{ request?: Partial<ListRequest> }, { territories: Territory[]; total: number }, unknown>();

export const createTerritory = createAsyncAction(
  "@territories/create/request",
  "@territories/create/success",
  "@territories/create/failure"
)<
  {
    territory: {
      color?: AnyColor;
      coordinates: LongLat[][];
      type?: AreaType | "points";
      adm1: string[];
      adm2: string[];
      adm3: string[];
      hidden?: boolean;
      name: string;
      opacity: number;
    };
    shape?: GeoPolygon | GeoMultiPolygon;
    onSuccess: (newTerritory: Territory) => void;
    userIdsToShareWith?: User["id"][];
  },
  { territory: Territory },
  unknown
>();

export const updateTerritory = createAsyncAction(
  "@territories/update/request",
  "@territories/update/success",
  "@territories/update/failure"
)<
  {
    onSuccess?: (updatedTerritory: Territory) => void;
    territory: Territory;
    shape?: GeoPolygon | GeoMultiPolygon;
  },
  { territory: Territory },
  void
>();

export const deleteTerritory = createAsyncAction(
  "@territories/delete/request",
  "@territories/delete/success",
  "@territories/delete/failure"
)<
  {
    territoryId: Territory["id"];
    onSuccess?: (territoryId: Territory["id"]) => void;
  },
  { territoryId: Territory["id"] },
  unknown
>();

export const updateTerritorySharing = createAsyncAction(
  "@territories/updateSharing/request",
  "@territories/updateSharing/success",
  "@territories/updateSharing/failure"
)<
  {
    territory: Pick<Territory, "id" | "userIds">;
    onSuccess?: (updatedTerritory: Territory) => void;
    userIdsToShareWith: User["id"][];
  },
  { territory: Territory },
  unknown
>();

export const hideTerritories = createAction("@territories/hideTerritories")<Territory["id"][]>();

export const showTerritories = createAction("@territories/showTerritories")<Territory["id"][]>();

export type Actions = ActionType<
  | typeof fetchAllTerritories
  | typeof fetchTerritories
  | typeof createTerritory
  | typeof updateTerritory
  | typeof deleteTerritory
  | typeof updateTerritorySharing
  | typeof hideTerritories
  | typeof showTerritories
>;

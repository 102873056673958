import MarkerEffect from "enum/MarkerEffect";
import Size from "types/map/Size";
import EffectProperty from "./types/EffectProperty";

const shapeEffects: Record<MarkerEffect, (size: Size) => string> = {
  [MarkerEffect.NONE]: () => "",

  [MarkerEffect.OUTLINE]: (size) =>
    `<filter id="outline" x="0" y="0" width="${size[0]}" height="${size[1]}" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="r1"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="2"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0941176 0 0 0 0 0.564706 0 0 0 0 1 0 0 0 0.5 0"/>
      <feBlend mode="normal" in2="r1" result="r2"/>
      <feBlend mode="normal" in="SourceGraphic" in2="r2" result="shape"/>
    </filter>`,

  [MarkerEffect.SHADOW]: (size) =>
    `<filter id="shadow" x="0" y="0" width="${size[0]}" height="${size[1]}" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="r1"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="r1" result="r2"/>
      <feBlend mode="normal" in="SourceGraphic" in2="r2" result="shape"/>
    </filter>`,
};

const getSvgEffect = (effect: MarkerEffect, size: Size): EffectProperty => {
  if (effect !== MarkerEffect.NONE && shapeEffects.hasOwnProperty(effect)) {
    return {
      attributes: `filter="url(#${effect})"`,
      filter: shapeEffects[effect](size),
    };
  }

  return {
    attributes: "",
    filter: "",
  };
};

export default getSvgEffect;

import { loggedIn } from "store/auth/actions";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import authService from "store/auth/AuthService";
import { RootState } from "store/rootReducer";
import { isSignedIn } from "store/auth";

type Props = React.ComponentProps<typeof Route> &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const AuthRoute = ({ isSignedIn, loggedIn, ...routeProps }: Props) => {
  const hasToken = authService.hasToken();

  useEffect(() => {
    if (hasToken && isSignedIn === false) {
      // has token in AuthService, but not in the store, perform loggedIn procedure
      loggedIn({ token: authService.getToken() });
    }
  }, [hasToken, isSignedIn, loggedIn]);

  return <Route {...routeProps} />;
};

const mapStateToProps = (state: RootState) => ({
  isSignedIn: isSignedIn(state),
});

const mapDispatchToProps = {
  loggedIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);

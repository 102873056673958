import { Descendant } from "slate";
import { userDisplayName } from "@mapmycustomers/shared/util/formatters";
import User from "@mapmycustomers/shared/types/User";
import IsMentionDisabled from "../type/IsMentionDisabled";
import { MENTIONS_REGEXP } from "./const";
import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";

const parseTextWithMentions = (
  value: string,
  users: User[],
  isMentionDisabled?: IsMentionDisabled
): Descendant[] =>
  value.split("\n").map((text) => {
    const parts = Array.from(text.matchAll(MENTIONS_REGEXP)) as {
      groups: { mention?: string; phone?: string };
      index: number;
    }[];
    let currentIndex = 0;
    const children: Descendant[] = [];
    parts.forEach(({ groups: { mention, phone }, index }) => {
      const length = (mention ?? phone)?.length ?? (0 as number);
      if (index > currentIndex) {
        children.push({ text: text.substring(currentIndex, index) });
      }
      if (mention) {
        const mentionEmail = mention.slice(1);
        const foundUser = users.find(
          ({ username }) => username === mentionEmail
        );
        if (foundUser) {
          children.push({
            type: ElementType.MENTION,
            email: foundUser.username,
            disabled: isMentionDisabled ? isMentionDisabled(foundUser) : false,
            name: userDisplayName(foundUser),
            children: [{ text: "" }],
          });
        } else {
          children.push({ text: mention });
        }
      } else if (phone) {
        children.push({ text: phone });
      }
      currentIndex = index + length;
    });

    if (text.length === 0) {
      children.push({ text: "" });
    } else if (currentIndex < text.length) {
      children.push({ text: text.substring(currentIndex, text.length) });
    }

    return {
      type: ElementType.PARAGRAPH,
      children,
    };
  });

export default parseTextWithMentions;

import { defineMessages } from "react-intl";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import i18nService from "config/I18nService";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { getFilterOperatorDisplayName } from "util/ui";

const messages = defineMessages({
  ONE_VALUE: {
    id: "filters._optionsFilter.description.oneValue",
    defaultMessage: "{fieldName} is {value1}",
    description:
      "Human-readable description for any option-like filter when only one value is selected",
  },
  ANY_TWO_VALUES: {
    id: "filters._optionsFilter.description.twoValues.any",
    defaultMessage: "{fieldName} is {value1} or {value2}",
    description:
      "Human-readable description for IN_ANY condition for any option-like filter when two values are selected",
  },
  ALL_TWO_VALUES: {
    id: "filters._optionsFilter.description.twoValues.all",
    defaultMessage: "{fieldName} has {value1} and {value2}",
    description:
      "Human-readable description for IN_ALL condition for any option-like filter when two values are selected",
  },
  ANY_THREE_VALUES: {
    id: "filters._optionsFilter.description.threeValues.any",
    defaultMessage: "{fieldName} is {value1}, {value2}, or {value3}",
    description:
      "Human-readable description for IN_ANY condition for any option-like filter when three values are selected",
  },
  ALL_THREE_VALUES: {
    id: "filters._optionsFilter.description.threeValues.all",
    defaultMessage: "{fieldName} has {value1}, {value2}, and {value3}",
    description:
      "Human-readable description for IN_ALL condition for any option-like filter when three values are selected",
  },
  DEFAULT_ANY: {
    id: "filters._optionsFilter.description.defaultAny",
    defaultMessage: "{fieldName} is any of {values}",
    description: "Human-readable description for IN_ANY condition for any option-like filter",
  },
  DEFAULT_ALL: {
    id: "filters._optionsFilter.description.defaultAll",
    defaultMessage: "{fieldName} has all of {values}",
    description: "Human-readable description for IN_ALL condition for any option-like filter",
  },
});

type DescriptionValues = {
  fieldName: string;
  values?: string;
  value1?: string;
  value2?: string;
  value3?: string;
};

export type OptionFilterOperator = FilterOperator.IN_ALL | FilterOperator.IN_ANY;

const getHumanReadableDescriptionForOptionsFilter = (
  field: IField,
  operator: OptionFilterOperator,
  values: string[]
): string => {
  const intl = i18nService.getIntl();

  // in a very unlikely event of no intl available, return a default string
  if (!intl) {
    const formattedOperator = intl ? getFilterOperatorDisplayName(intl, operator, field) : operator;
    return `${field.displayName} ${formattedOperator} ${values
      .map((value) => String(value))
      .join(", ")}`;
  }

  const formatValues: DescriptionValues = {
    fieldName: field.displayName,
  };
  let message = operator === FilterOperator.IN_ALL ? messages.DEFAULT_ALL : messages.DEFAULT_ANY;
  if (values.length === 1) {
    message = messages.ONE_VALUE;
    formatValues.value1 = values[0];
  } else if (values.length === 2) {
    message =
      operator === FilterOperator.IN_ALL ? messages.ALL_TWO_VALUES : messages.ANY_TWO_VALUES;
    formatValues.value1 = values[0];
    formatValues.value2 = values[1];
  } else if (values.length === 3) {
    message =
      operator === FilterOperator.IN_ALL ? messages.ALL_THREE_VALUES : messages.ANY_THREE_VALUES;
    formatValues.value1 = values[0];
    formatValues.value2 = values[1];
    formatValues.value3 = values[2];
  } else {
    formatValues.values = values.join(", ");
  }

  return intl.formatMessage(message, formatValues);
};

export default getHumanReadableDescriptionForOptionsFilter;

import FieldModel from "./impl/FieldModel";
import DateTimeField from "./impl/DateTimeField";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { createField } from "./impl/fieldModelUtil";
import NameField from "./impl/NameField";
import { defineMessages } from "react-intl";
import UserField from "./impl/UserField";

export enum TerritoryFieldName {
  NAME = "name",
  COLOR = "color",
  UPDATED_AT = "updatedAt",
  USER = "user",
}

const displayNames = defineMessages<TerritoryFieldName>({
  [TerritoryFieldName.NAME]: {
    id: "territory.field.name",
    defaultMessage: "Name",
    description: "Name field of territory",
  },
  [TerritoryFieldName.COLOR]: {
    id: "territory.field.color",
    defaultMessage: "Color",
    description: "Color field of territory",
  },
  [TerritoryFieldName.UPDATED_AT]: {
    id: "territory.field.updatedAt",
    defaultMessage: "Date Last Modified",
    description: "Modified At field of territory",
  },
  [TerritoryFieldName.USER]: {
    id: "territory.field.user",
    defaultMessage: "Creator",
    description: "Creator of territory",
  },
});

const territoryFieldModel = new FieldModel(
  [
    new NameField({
      name: TerritoryFieldName.NAME,
      displayName: displayNames[TerritoryFieldName.NAME],
    }),
    {
      name: TerritoryFieldName.COLOR,
      displayName: displayNames[TerritoryFieldName.COLOR],
      type: FieldType.STRING,
      features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE],
      customGridProperties: {
        cellRenderer: "colorCellRenderer",
      },
    },
    new DateTimeField({
      name: TerritoryFieldName.UPDATED_AT,
      displayName: displayNames[TerritoryFieldName.UPDATED_AT],
    }),
    new UserField({
      name: TerritoryFieldName.USER,
      displayName: displayNames[TerritoryFieldName.USER],
      platformFilterName: "userId",
      platformName: "userId",
      features: [FieldFeature.FILTERABLE],
    }),
  ].map(createField)
);

export default territoryFieldModel;

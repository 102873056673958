import switchOffNotificationSetting from "./switchOffNotificationSetting";
import notificationManager from "antd/es/notification";
import ButtonLink from "component/ButtonLink";
import React from "react";
import { updateSetting } from "store/iam/actions";
import Organization from "@mapmycustomers/shared/types/Organization";
import User from "@mapmycustomers/shared/types/User";
import Setting from "@mapmycustomers/shared/types/Setting";
import { IntlShape } from "react-intl";
import MmcNotificationAction from "enum/MmcNotificationAction";

const optOutSetting = (
  intl: IntlShape,
  updateSettingAction: typeof updateSetting.request,
  organizationId: Organization["id"],
  currentUserId: User["id"],
  setting: Setting,
  action: MmcNotificationAction,
  onGoToSettings: () => void
) => {
  updateSettingAction({
    orgId: organizationId,
    userId: currentUserId,
    setting: {
      ...setting,
      value: switchOffNotificationSetting(setting.value, action),
    },
    callback: () => {
      notificationManager.success({
        message: intl.formatMessage({
          defaultMessage: "Opted out of notifications of this type",
          description:
            "Navbar Notifications - list - notification - Opted out of notifications - title",
          id: "navbar.notifications.list.notification.optedOutOfNotifications.title",
        }),
        description: intl.formatMessage(
          {
            defaultMessage:
              "Please note you may still receive other alerts. Manage all notifications from your <a>notification center preferences</a>.",
            description:
              "Navbar Notifications - list - notification - Opted out of notifications - description",
            id: "navbar.notifications.list.notification.optedOutOfNotifications.description",
          },
          { a: (text) => <ButtonLink onClick={onGoToSettings}>{text}</ButtonLink> }
        ),
      });
    },
  });
};

export default optOutSetting;

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import {
  Activity,
  EntityType,
  EntityTypeSupportingSourceTracking,
  Group,
  MapEntity,
} from "@mapmycustomers/shared/types/entity";
import Company from "@mapmycustomers/shared/types/entity/Company";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import User from "@mapmycustomers/shared/types/User";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import { StageSummary } from "@mapmycustomers/shared/types/entity/deals/StageSummary";
import Statistics from "types/Statistics";
import OpenDealsCardData from "../types/OpenDealsCardData";
import DailyChangeChartType from "scene/reports/components/Activity/components/DailyChange/DailyChangeChartType";
import DailyChangeData from "scene/reports/components/Activity/components/DailyChange/DailyChangeData";
import ActivityStatus from "@mapmycustomers/shared/enum/activity/ActivityStatus";
import { SupportingGroupsRecordStats } from "../types/RecordsOutOfCadence";
import RecordsOutOfCadenceFilter from "../types/RecordsOutOfCadenceFilter";
import { DealsRottingFilter } from "../types/DealsRottingFilter";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import SourceSummary from "types/source/SourceSummary";
import RecordsSourceFilter from "../types/RecordsSourceFilter";
import { RecordsStats } from "../types/RecordsStats";
import { GroupByType } from "scene/reports/components/Overview/components/Engagement/enum/GroupByType";
import { SupportedSources } from "enum/SourceType";
import Team from "@mapmycustomers/shared/types/Team";
import { EntityTypeSupportingCadence } from "@mapmycustomers/shared/types/entity/activities/OutOfCadenceEntity";
import { ActivityCount } from "scene/home/component/Cards/util";
import ActivityTypeWithCount from "@mapmycustomers/shared/types/entity/activities/ActivityTypeWithCount";
import { DealsGroupBy } from "../types/DealGroupBy";
import { DealsGroupByType } from "../enum/DealsGroupByType";

export const initialize = createAsyncAction(
  "home/initialize/request",
  "home/initialize/success",
  "home/initialize/failure"
)<
  void,
  {
    hasActivities: boolean;
    dealsCount: number;
    hasCompanies: boolean;
    recordsSourceFilter: RecordsSourceFilter;
    recordsOutOfCadenceFilter: RecordsOutOfCadenceFilter;
    dealsRottingFilter: DealsRottingFilter;
  },
  unknown
>();

export const fetchTodayActivitiesData = createAsyncAction(
  "home/fetchTodayActivitiesData/request",
  "home/fetchTodayActivitiesData/success",
  "home/fetchTodayActivitiesData/failure"
)<
  {
    assigneeIds: Array<User["id"]>;
    status: ActivityStatusOption;
    activityTypes: Array<ActivityType>;
    date: Date;
  },
  Activity[],
  unknown
>();

export const fetchActivityByRepData = createAsyncAction(
  "home/fetchActivityByRepData/request",
  "home/fetchActivityByRepData/success",
  "home/fetchActivityByRepData/failure"
)<
  {
    assigneeIds: Array<User["id"]>;
    activityTypeIds: Array<ActivityType["id"]>;
    dateRange: string;
  },
  Record<User["id"], ActivityCount[]>,
  unknown
>();

export const fetchActivityByTypesData = createAsyncAction(
  "home/fetchActivityByTypesData/request",
  "home/fetchActivityByTypesData/success",
  "home/fetchActivityByTypesData/failure"
)<
  {
    assigneeIds: Array<User["id"]>;
    crmActivityTypeIds: Array<ActivityType["id"]>;
    dateRange: string;
  },
  ActivityTypeWithCount[],
  unknown
>();

export const fetchSelectedActivityByTypesData = createAsyncAction(
  "home/fetchSelectedActivityByTypesData/request",
  "home/fetchSelectedActivityByTypesData/success",
  "home/fetchSelectedActivityByTypesData/failure"
)<
  {
    assigneeIds: Array<User["id"]>;
    crmActivityTypeIds: ActivityType["id"];
    dateRange: string;
    page?: number;
  },
  { activities: Activity[]; totalRecords: number },
  unknown
>();

export const fetchTodayActivitiesDataForReps = createAsyncAction(
  "home/fetchTodayActivitiesDataForReps/request",
  "home/fetchTodayActivitiesDataForReps/success",
  "home/fetchTodayActivitiesDataForReps/failure"
)<
  {
    status: ActivityStatus;
    dateRange: string;
  },
  Activity[],
  unknown
>();

export const fetchFunnelSummary = createAsyncAction(
  "home/fetchFunnelSummary/request",
  "home/fetchFunnelSummary/success",
  "home/fetchFunnelSummary/failure"
)<{ funnelId: Funnel["id"]; selectedUserIds: User["id"][] }, StageSummary[], unknown>();

export const fetchRecordsBySourceSummary = createAsyncAction(
  "home/fetchRecordsBySourceSummary/request",
  "home/fetchRecordsBySourceSummary/success",
  "home/fetchRecordsBySourceSummary/failure"
)<
  {
    entityTypes: EntityTypeSupportingSourceTracking[];
    userId: Array<User["id"]>;
    dateRange: string;
  },
  SourceSummary[],
  unknown
>();

export const fetchRecordsBySourceForSelectedType = createAsyncAction(
  "home/fetchRecordsBySourceForSelectedType/request",
  "home/fetchRecordsBySourceForSelectedType/success",
  "home/fetchRecordsBySourceForSelectedType/failure"
)<
  {
    entityTypes: Exclude<EntityTypeSupportingSourceTracking, EntityType.ACTIVITY>[];
    userId: Array<User["id"]>;
    dateRange: string;
    sourceType: SupportedSources;
    page?: number;
  },
  { recordsBySourceList: MapEntity[]; totalRecords: number },
  unknown
>();

export const fetchRecentCompanies = createAsyncAction(
  "home/fetchRecentCompanies/request",
  "home/fetchRecentCompanies/success",
  "home/fetchRecentCompanies/failure"
)<{ usersIds: User["id"][]; limit: number }, Company[], unknown>();

export const fetchCompaniesCount = createAsyncAction(
  "home/fetchCompaniesCount/request",
  "home/fetchCompaniesCount/success",
  "home/fetchCompaniesCount/failure"
)<void, boolean, unknown>();

export const fetchOpenDealsData = createAsyncAction(
  "home/fetchOpenDealsData/request",
  "home/fetchOpenDealsData/success",
  "home/fetchOpenDealsData/failure"
)<{ usersIds: User["id"][]; funnelId: Funnel["id"] }, OpenDealsCardData, void>();

export const fetchActivityOverview = createAsyncAction(
  "home/fetchActivityOverview/request",
  "home/fetchActivityOverview/>success",
  "home/fetchActivityOverview/>failure"
)<{ usersIds: User["id"][]; dateRange: string }, Statistics["values"], unknown>();

export const fetchDailyChangeData = createAsyncAction(
  "home/fetchDailyChangeData/request",
  "home/fetchDailyChangeData/success",
  "home/fetchDailyChangeData/failure"
)<
  {
    chartType: DailyChangeChartType;
    dateRange: string;
    usersIds?: User["id"][];
  },
  DailyChangeData,
  unknown
>();

export const setRecordSourceFilter = createAction(
  "home/setRecordSourceFilter"
)<RecordsSourceFilter>();

export const setRecordsOutOfCadenceFilter = createAction(
  "home/setRecordsOutOfCadenceFilter"
)<RecordsOutOfCadenceFilter>();

export const fetchRecordsOutOfCadence = createAsyncAction(
  "home/fetchRecordsOutOfCadence/request",
  "home/fetchRecordsOutOfCadence/success",
  "home/fetchRecordsOutOfCadence/failure"
)<
  {
    groupIds?: Group["id"][];
    teamIds?: Team["id"][];
    userIds?: User["id"][];
  },
  SupportingGroupsRecordStats,
  unknown
>();

export const setDealsRottingFilter = createAction(
  "home/setDealsRottingFilter"
)<DealsRottingFilter>();

export const fetchDealsRotting = createAsyncAction(
  "home/fetchDealsRotting/request",
  "home/fetchDealsRotting/success",
  "home/fetchDealsRotting/failure"
)<
  {
    dealsGroupByType: DealsGroupByType;
  },
  {
    dealsRottingStats: Record<DealsGroupBy["id"], RecordsStats>;
    stagesStats: Record<Funnel["id"], Record<Stage["name"], RecordsStats>>;
  },
  unknown
>();

export const fetchEngagementRecordsList = createAsyncAction(
  "home/fetchEngagementRecordsList/request",
  "home/fetchEngagementRecordsList/success",
  "home/fetchEngagementRecordsList/failure"
)<
  {
    id: Group["id"] | User["id"] | Team["id"];
    recordTypes: EntityTypeSupportingCadence[];
    type: GroupByType;
    page?: number;
  },
  { records: MapEntity[]; totalRecords: number },
  unknown
>();

export type Actions = ActionType<
  | typeof fetchActivityByRepData
  | typeof fetchActivityByTypesData
  | typeof fetchActivityOverview
  | typeof fetchCompaniesCount
  | typeof fetchDailyChangeData
  | typeof fetchDealsRotting
  | typeof fetchEngagementRecordsList
  | typeof fetchFunnelSummary
  | typeof fetchOpenDealsData
  | typeof fetchRecentCompanies
  | typeof fetchRecordsBySourceForSelectedType
  | typeof fetchRecordsBySourceSummary
  | typeof fetchRecordsOutOfCadence
  | typeof fetchSelectedActivityByTypesData
  | typeof fetchTodayActivitiesData
  | typeof fetchTodayActivitiesDataForReps
  | typeof initialize
  | typeof setDealsRottingFilter
  | typeof setRecordSourceFilter
  | typeof setRecordsOutOfCadenceFilter
>;

import React from "react";
import { getError, isForgotPasswordLoading, isForgotPasswordSuccess, isSignedIn } from "store/auth";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import Path from "enum/Path";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { forgotPassword } from "store/auth/actions";
import ApiError from "store/api/ApiError";
import { defineMessage, useIntl } from "react-intl";
import ForgotPasswordSuccessScreen from "./ForgotPasswordSuccessScreen";
import AuthLayout from "../AuthLayout";
import getErrorMessage from "util/errorHandling/getErrorMessage";

const genericErrorMessage = defineMessage({
  id: "auth.forgotPassword.error.unknown",
  defaultMessage: "Server issue, please try again later",
  description: "A generic forgot Password failed error message text",
});

interface Props {
  error?: ApiError;
  forgotPassword: typeof forgotPassword.request;
  isForgotPasswordSuccess: boolean;
  isLoading: boolean;
  isSignedIn: boolean | undefined;
}

export const ForgotPasswordPage: React.FC<Props> = ({
  error,
  forgotPassword,
  isForgotPasswordSuccess,
  isLoading,
  isSignedIn,
}) => {
  const intl = useIntl();
  // go to home to proceed when already signed in
  if (isSignedIn) {
    return <Redirect to={Path.DASHBOARD} />;
  }

  let message = undefined;
  if (error) {
    message = getErrorMessage(error, {}, genericErrorMessage);
    if (message.startsWith("Invalid grant")) {
      message = intl.formatMessage({
        id: "auth.forgotPassword.error.invalidCredentials",
        defaultMessage: "This username entered is incorrect, please try again",
        description: "An invalid credentials error message text",
      });
    } else {
      message = intl.formatMessage(genericErrorMessage);
    }
  }

  return (
    <AuthLayout>
      {isForgotPasswordSuccess ? (
        <ForgotPasswordSuccessScreen />
      ) : (
        <ForgotPasswordForm
          errorMessage={message}
          isLoading={isLoading}
          onResetPassword={forgotPassword}
        />
      )}
    </AuthLayout>
  );
};

const mapStateToProps = (state: RootState) => ({
  error: getError(state),
  isLoading: isForgotPasswordLoading(state),
  isForgotPasswordSuccess: isForgotPasswordSuccess(state),
  isSignedIn: isSignedIn(state),
});

const mapDispatchToProps = {
  forgotPassword: forgotPassword.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import Tier from "@mapmycustomers/shared/types/plans/Tier";
import { Stripe, Token } from "@stripe/stripe-js";
import Plan from "@mapmycustomers/shared/types/Plan";
import CurrentPlan from "@mapmycustomers/shared/types/plans/CurrentPlan";

export const initialize = createAsyncAction(
  "billing/initialize/request",
  "billing/initialize/success",
  "billing/initialize/failure"
)<void, { currentPlan: CurrentPlan; licenses: number; stripe: Stripe; tiers: Tier[] }, unknown>();

export const upgrade = createAsyncAction(
  "billing/upgrade/request",
  "billing/upgrade/success",
  "billing/upgrade/failure"
)<{ token: Token; planId: Plan["id"]; licenses: number }, {}, unknown>();

export const hideCheckoutModal = createAction("billing/hideCheckoutModal")<void>();
export const resetSuccessState = createAction("billing/resetSuccessState")<void>();
export const showCheckoutModal = createAction("billing/showCheckoutModal")<void>();
export const setAnnual = createAction("billing/setAnnual")<boolean>();
export const setLicenses = createAction("billing/setLicenses")<number>();

export type Actions = ActionType<
  | typeof initialize
  | typeof upgrade
  | typeof resetSuccessState
  | typeof setAnnual
  | typeof setLicenses
  | typeof showCheckoutModal
  | typeof hideCheckoutModal
>;

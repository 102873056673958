import Plan from "@mapmycustomers/shared/types/Plan";
import Tier from "@mapmycustomers/shared/types/plans/Tier";
import SubscriptionInterval from "@mapmycustomers/shared/enum/SubscriptionInterval";
import { Range } from "@mapmycustomers/shared/types/plans/Range";

export const isInRange = ([from, to]: Range, value: number) =>
  value >= from && value <= (to ?? Number.POSITIVE_INFINITY);

export const findTierByIntervalAndLicenses = (
  tiers: Tier[],
  interval: SubscriptionInterval,
  licenses: number
) => {
  let tier: Tier | undefined;
  const possibleTiers = tiers.filter(({ licenseLimit }) => isInRange(licenseLimit, licenses));
  if (possibleTiers.length === 1) {
    tier = possibleTiers[0];
  } else {
    tier = possibleTiers.find((tier) => tier.interval === interval && !tier.isTrial);
    if (!tier && interval === SubscriptionInterval.MONTHLY) {
      // try to find the best matching annual tier then
      tier = possibleTiers.find(
        (tier) => tier.interval === SubscriptionInterval.ANNUAL && !tier.isTrial
      );
    }
    if (!tier) {
      // if still not found, then it's probably a trial tier
      tier = possibleTiers.find((tier) => tier.isTrial);
    }
  }
  return tier;
};

export const convertPlanToTier = (plan: Plan): Tier => ({
  id: plan.id,
  name: plan.name,
  interval: plan.interval === 1 ? SubscriptionInterval.MONTHLY : SubscriptionInterval.ANNUAL,
  price: { currency: "USD", value: parseFloat(plan.price) },
  isTrial: plan.trialing,
  licenseLimit: plan.metaData?.licenseLimit ?? [0],
});

export const convertToTiers = (plans: Plan[]): Tier[] =>
  plans.filter(({ metaData }) => !!metaData).map(convertPlanToTier);

import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import "antd/es/date-picker/style/index";
import generateRangePicker from "antd/es/date-picker/generatePicker/generateRangePicker";
import antdPickerLocaleConfig from "./utils/antdPickerLocaleConfig";

const RangePicker = generateRangePicker<Date>({
  ...dateFnsGenerateConfig,
  locale: antdPickerLocaleConfig,
});

export default RangePicker;

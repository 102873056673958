import { CustomFieldPayload } from "@mapmycustomers/shared/types/customField/CustomField";
import CustomFieldDataType from "@mapmycustomers/shared/enum/CustomFieldDataType";

export const NEW_CUSTOM_FIELD: CustomFieldPayload = {
  crmPropertyKey: "",
  currencyId: null,
  dataType: CustomFieldDataType.TEXT,
  displayName: "",
  displayOrder: 1,
  displayType: null,
  esKey: "",
  options: [{ displayName: "", value: 1, displayOrder: 1 }],
  placeholder: "",
};

export const MERGE_DUPLICATES_TABLE_PAGE_SIZE = 10;

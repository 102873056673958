import { ActionType, createAsyncAction } from "typesafe-actions";
import Theme from "../../enum/Theme";

export const changeTheme = createAsyncAction(
  "@theme/change/request",
  "@theme/change/success",
  "@theme/change/failure"
)<Theme, Theme, Error>();

export type Actions = ActionType<typeof changeTheme>;

import React, { useCallback, useEffect, useRef } from "react";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import styles from "./NotificationList.module.scss";
import { useIntl } from "react-intl";
import MmcNotification from "types/MmcNotification";
import Notification from "./component/Notification";
import Row from "antd/es/row";
import Col from "antd/es/col";
import ButtonLink from "component/ButtonLink";
import { showEntityView } from "store/entityView/actions";
import { fetchNotifications, markNotificationsAsRead } from "store/notification/actions";
import {
  getNotificationTotalCount,
  getNotificationUnreadCount,
  isNotificationLoading,
} from "store/notification";
import NoNotification from "./component/NoNotification";
import { LoadingSpinner } from "@mapmycustomers/ui";
import useAnalytics from "util/contexts/useAnalytics";
import getGroupHeaderTitle from "./component/util/getGroupHeaderTitle";

const LOAD_ON_SCROLL_THRESHOLD = 200;

interface Props {
  fetchNotifications: typeof fetchNotifications.request;
  loading: boolean;
  markNotificationsAsRead: typeof markNotificationsAsRead.request;
  notifications: MmcNotification[];
  onGoToSettings: () => void;
  showEntityView: typeof showEntityView;
  showMarkAllRead: boolean;
  total: number;
}

const NotificationList: React.FC<Props> = ({
  fetchNotifications,
  loading,
  markNotificationsAsRead,
  notifications,
  onGoToSettings,
  showMarkAllRead,
  total,
}) => {
  const intl = useIntl();
  const analyticsIssuer = useAnalytics();
  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(
    ({ target }) => {
      const { scrollHeight, clientHeight, scrollTop } = target;
      if (
        scrollHeight - clientHeight - scrollTop < LOAD_ON_SCROLL_THRESHOLD &&
        !loading &&
        total > notifications.length
      ) {
        fetchNotifications({});
      }
    },
    [fetchNotifications, loading, notifications, total]
  );

  const handleMarkAllAsRead = useCallback(() => {
    markNotificationsAsRead();
    analyticsIssuer.clicked(["Mark all read"]);
  }, [analyticsIssuer, markNotificationsAsRead]);

  const getGroupHeader = useCallback(
    (i) => {
      const title = getGroupHeaderTitle(intl, notifications[i].updatedAt);
      const previousTitle = i > 0 ? getGroupHeaderTitle(intl, notifications[i - 1].updatedAt) : "";
      if (title !== previousTitle) {
        return (
          <Row align="middle" className={styles.groupHeader} justify="space-between">
            <Col className={styles.groupHeaderTitle}>{title}</Col>
            <Col>
              {i === 0 &&
                (showMarkAllRead ? (
                  <ButtonLink onClick={() => handleMarkAllAsRead()}>
                    {intl.formatMessage({
                      defaultMessage: "Mark all read",
                      description: "Navbar Notifications - list - group header - mark all read",
                      id: "navbar.notifications.list.groupHeader.markAllRead",
                    })}
                  </ButtonLink>
                ) : (
                  <div className={styles.upToDate}>
                    {intl.formatMessage({
                      defaultMessage: "Up to date",
                      description: "Navbar Notifications - list - group header - Up to date",
                      id: "navbar.notifications.list.groupHeader.upToDate",
                    })}
                  </div>
                ))}
            </Col>
          </Row>
        );
      }
    },
    [intl, handleMarkAllAsRead, notifications, showMarkAllRead]
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  return (
    <div className={styles.container} onScroll={handleScroll} ref={ref} tabIndex={-1}>
      {notifications.length > 0 &&
        notifications.map((notification, i) => (
          <div key={notification.id}>
            {getGroupHeader(i)}
            <Notification
              notification={notification}
              onGoToSettings={onGoToSettings}
              tabIndex={i + 1}
            />
          </div>
        ))}
      {notifications.length === 0 && !loading && <NoNotification onGoToSettings={onGoToSettings} />}
      {loading && <LoadingSpinner />}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  showMarkAllRead: getNotificationUnreadCount(state) > 0,
  loading: isNotificationLoading(state),
  total: getNotificationTotalCount(state),
});

const mapDispatchToProps = {
  fetchNotifications: fetchNotifications.request,
  markNotificationsAsRead: markNotificationsAsRead.request,
  showEntityView,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);

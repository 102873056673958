import React, { forwardRef } from "react";
import styles from "./Chip.module.scss";
import cn from "classnames";
import Tag, { TagProps } from "antd/es/tag";

export interface ChipProps extends TagProps {
  clickable?: boolean;
  icon?: React.ReactNode;
  iconPosition?: "start" | "end";
  inline?: boolean;
  noPadding?: boolean;
  type?: "light" | "label" | "closable";
}

const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      children,
      className,
      clickable,
      icon,
      iconPosition = "start",
      inline,
      noPadding,
      onClick,
      type = "label",
      ...restProps
    },
    ref
  ) => {
    const cursorPointer = clickable ?? !!onClick;

    return (
      <Tag
        {...restProps}
        className={cn(
          styles.container,
          styles[type],
          {
            [styles.pointer]: cursorPointer,
            [styles.inline]: inline,
            [styles.noPadding]: noPadding,
          },
          className
        )}
        onClick={onClick}
        ref={ref}
      >
        {iconPosition === "start" && icon}
        {children}
        {iconPosition === "end" && icon}
      </Tag>
    );
  }
);

export default Chip;

@use "../../../styles/colors";

.container {
  display: flex;
  gap: 1rem;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.fileContainer {
  width: calc(50% - 1rem);
  background-color: colors.$mmc-soft-gray;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: colors.$mmc-soft-slate;
}

.fileIcon {
  width: 1.25rem;
  height: 1.25rem;
  line-height: 0.75rem;
  background: colors.$mmc-primary-100;
  svg {
    height: 0.625rem;
  }
}

.downloadIcon {
  color: colors.$mmc-primary-075;
  cursor: pointer;
}

.deleteIcon {
  cursor: pointer;
}

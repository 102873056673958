import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import cn from "classnames";
import { isBefore, startOfToday } from "date-fns/esm";
import Button from "antd/es/button";
import Popover from "antd/es/popover";
import Tooltip from "antd/es/tooltip";
import { FormInstance } from "antd/es/form/hooks/useForm";
import FormValues from "../Activity/types/FormValues";
import useRerenderer from "@mapmycustomers/shared/util/hook/useRerenderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faBellSlash } from "@fortawesome/free-solid-svg-icons/faBellSlash";
import styles from "./NotificationSettings.module.scss";
import messages from "./messages";
import NotificationItem from "./NotificationItem";
import { Reminder } from "@mapmycustomers/shared/types/entity/Activity";
import ActivityRemindType from "@mapmycustomers/shared/enum/activity/ActivityRemindType";
import {
  ACTIVITY_DEFAULT_REMIND_TIME,
  MINUTES_IN_DAY,
  MINUTES_IN_HOUR,
  MINUTES_IN_WEEK,
} from "util/consts";
import { useWatch } from "antd/es/form/Form";

const MAX_ALLOWED_REMINDERS = 5;

interface Props {
  disabled?: boolean;
  form: FormInstance<FormValues>;
  loading?: boolean;
  onChange?: () => void;
}

const NotificationSettings: React.FC<Props> = ({ disabled, form, loading, onChange }) => {
  const intl = useIntl();
  const forceRender = useRerenderer();
  const [popoverVisible, setPopoverVisibility] = useState(false);
  const dateValue = form.getFieldValue("startAt");
  const isValidDate = dateValue && Date.parse(dateValue.toString());
  const isPastDate = isValidDate && isBefore(dateValue, startOfToday());
  const reminders: Reminder[] | undefined = useWatch("reminders", { form, preserve: true });

  const handleAdd = useCallback(() => {
    const updatedReminders = [...(reminders ?? [])];
    updatedReminders.push({
      remindBeforeMinutes: ACTIVITY_DEFAULT_REMIND_TIME,
      remindVia: ActivityRemindType.PUSH,
    });
    form.setFieldValue("reminders", updatedReminders);
    onChange?.();
    forceRender();
  }, [forceRender, form, onChange, reminders]);

  const handleVisibilityChange = useCallback(
    (visible: boolean) => setPopoverVisibility(visible),
    [setPopoverVisibility]
  );
  const getParentElement = useCallback((trigger: HTMLElement) => trigger.parentElement!, []);
  const isDisabled = !!disabled || (reminders && reminders.length < 1) || isPastDate;

  const handleRemove = useCallback(
    (index: number) => {
      const updatedReminders = reminders?.toSpliced(index, 1);
      if (reminders && reminders.length < 1) {
        handleVisibilityChange(false);
      }
      form.setFieldValue("reminders", updatedReminders);
      onChange?.();
      forceRender();
    },
    [forceRender, form, handleVisibilityChange, onChange, reminders]
  );

  const handleChange = useCallback(
    (index: number, newReminder: Reminder) => {
      form.setFieldValue("reminders", reminders?.toSpliced(index, 1, newReminder));
      onChange?.();
      forceRender();
    },
    [forceRender, form, onChange, reminders]
  );

  const title = useMemo(() => {
    let title = isPastDate
      ? intl.formatMessage(messages.pastDate)
      : isDisabled
      ? intl.formatMessage(messages.disabled)
      : "";

    const sameTimeForNotifications =
      reminders &&
      reminders.length > 0 &&
      reminders.every(
        (reminder) => reminder.remindBeforeMinutes === reminders[0].remindBeforeMinutes
      );
    if (sameTimeForNotifications) {
      const remindBeforeMinutes = reminders[0].remindBeforeMinutes;
      if (!isDisabled && !isNaN(remindBeforeMinutes) && remindBeforeMinutes > 0) {
        if (remindBeforeMinutes % MINUTES_IN_WEEK === 0) {
          title = intl.formatMessage(messages.weeks, {
            num: Math.round(remindBeforeMinutes / MINUTES_IN_WEEK),
          });
        } else if (remindBeforeMinutes % MINUTES_IN_DAY === 0) {
          title = intl.formatMessage(messages.days, {
            num: Math.round(remindBeforeMinutes / MINUTES_IN_DAY),
          });
        } else if (remindBeforeMinutes % MINUTES_IN_HOUR === 0) {
          title = intl.formatMessage(messages.hours, {
            num: Math.round(remindBeforeMinutes / MINUTES_IN_HOUR),
          });
        } else {
          title = intl.formatMessage(messages.minutes, { num: remindBeforeMinutes });
        }
      }
    } else if (reminders && reminders.length > 1) {
      title = intl.formatMessage(messages.multiple);
    }
    return title;
  }, [intl, isDisabled, isPastDate, reminders]);

  return (
    <div className={styles.container}>
      <Popover
        content={
          <div className={styles.content}>
            <div className={styles.title}>{intl.formatMessage(messages.title)}</div>
            <div className={styles.list}>
              {reminders?.map((reminder: Reminder, index) => (
                <NotificationItem
                  index={index}
                  key={index}
                  onChange={handleChange}
                  onRemove={handleRemove}
                  reminder={reminder}
                />
              ))}
            </div>
            {reminders && reminders.length < MAX_ALLOWED_REMINDERS ? (
              <div>
                <Button className={styles.addBtn} onClick={handleAdd} type="primary">
                  {intl.formatMessage(messages.add)}
                </Button>
              </div>
            ) : null}
          </div>
        }
        onOpenChange={handleVisibilityChange}
        open={popoverVisible}
        overlayClassName={styles.popover}
        placement={"bottomRight"}
        trigger={isPastDate ? [] : "click"}
      >
        <Tooltip
          getTooltipContainer={getParentElement}
          overlayClassName={styles.tooltip}
          title={isPastDate || loading ? null : intl.formatMessage(messages.tooltip)}
          trigger={loading ? [] : ["hover"]}
        >
          <Button
            className={cn(styles.btn, {
              [styles.btnDisabled]: isDisabled,
              [styles.past]: isPastDate,
            })}
            disabled={isPastDate || disabled}
            loading={loading}
          >
            {!loading && (
              <span className={styles.title}>
                <FontAwesomeIcon
                  className={styles.icon}
                  icon={isDisabled && !isPastDate ? faBellSlash : faBell}
                />
                <span>{title}</span>
              </span>
            )}
          </Button>
        </Tooltip>
      </Popover>
    </div>
  );
};

export default NotificationSettings;

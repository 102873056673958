import { EntityType } from "@mapmycustomers/shared/enum";
import Report from "types/Report";
import getColumnsVisibleByDefault from "../../../util/fieldModel/getColumnsVisibleByDefault";

export const PREVIEW_LIMIT = 100;

export const NEW_REPORT: Readonly<Partial<Report>> = {
  id: 0,
  name: "",
  description: "",
  tableName: EntityType.COMPANY,
  customFields: [],
  selectedColumns: getColumnsVisibleByDefault(EntityType.COMPANY).map((field) => field.name),
  selectedFilters: {},
};

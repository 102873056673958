import React from "react";
import { TextField, TextFieldProps } from "@mapmycustomers/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { parsePhoneNumber } from "util/parsers";
import styles from "./TextFieldWithLink.module.scss";

const PhoneTextField: React.FC<TextFieldProps> = (props) => {
  const value = props.value ? String(props.value) : "";
  const parsedPhoneNumber = parsePhoneNumber(value);
  const isValidPhone = !!parsedPhoneNumber;

  return (
    <TextField
      {...props}
      suffix={
        <a href={parsedPhoneNumber?.link}>
          {isValidPhone && (
            <div className={styles.textFieldIcon}>
              <FontAwesomeIcon className={styles.iconColor} icon={faPhone} />
            </div>
          )}
        </a>
      }
    />
  );
};

export default PhoneTextField;

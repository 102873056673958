import { EntityPin, PinData } from "types/map";
import Size from "types/map/Size";
import AlertBadgesType from "../../enum/map/AlertBadgesType";
import {
  ENTITY_BADGE_ALERT_COLOR,
  ENTITY_BADGE_INFO_COLOR,
  ENTITY_BADGE_WARNING_COLOR,
} from "./const";
import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";
import { differenceInDays } from "date-fns/esm";
import { parseApiDate } from "../parsers";

const getEntityColor = (entity: PinData, visibleBadges?: AlertBadgesType[]): string | undefined => {
  if (entity.cadenceStatus === FrequencyStatus.PAST_DUE) {
    if (!visibleBadges || visibleBadges.includes(AlertBadgesType.OVERDUE)) {
      return ENTITY_BADGE_ALERT_COLOR;
    }
    return undefined;
  }
  if (
    entity.upcomingActivity?.startAt &&
    !entity.upcomingActivity?.completed &&
    differenceInDays(parseApiDate(entity.upcomingActivity.startAt), Date.now()) <= 7
  ) {
    if (!visibleBadges || visibleBadges.includes(AlertBadgesType.UPCOMING_ACTIVITY)) {
      return ENTITY_BADGE_INFO_COLOR;
    }
    return undefined;
  }
  if (entity.cadenceStatus === FrequencyStatus.UPCOMING) {
    if (!visibleBadges || visibleBadges.includes(AlertBadgesType.OUT_OF_CADENCE_SOON)) {
      return ENTITY_BADGE_WARNING_COLOR;
    }
    return undefined;
  }
  return undefined;
};

const getShadowMatrixValuesByColor = (color: string): string => {
  if (color === ENTITY_BADGE_ALERT_COLOR) {
    return "0 0 0 0 0.960784 0 0 0 0 0.133333 0 0 0 0 0.176471 0 0 0 0.5 0";
  }
  if (color === ENTITY_BADGE_WARNING_COLOR) {
    return "0 0 0 0 0.980392 0 0 0 0 0.678431 0 0 0 0 0.0784314 0 0 0 0.5 0";
  }
  if (color === ENTITY_BADGE_INFO_COLOR) {
    return "0 0 0 0 0.0941176 0 0 0 0 0.564706 0 0 0 0 1 0 0 0 0.5 0";
  }
  return "";
};

export const getBadgeSvgWithColor = (color: string): string => `
      <g filter="url(#filter0_d_7021_48706)">
        <circle cx="15" cy="15" r="11" fill="white"/>
        <circle cx="15" cy="15" r="10.5" stroke="white"/>
      </g>
      <g transform="translate(6, 6)">
        <path d="M18 9C18 13.9717 13.9702 18 9 18C4.02979 18 0 13.9717 0 9C0 4.03124 4.02979 0 9 0C13.9702 0 18 4.03124 18 9ZM9 10.8145C8.07804 10.8145 7.33065 11.5619 7.33065 12.4839C7.33065 13.4058 8.07804 14.1532 9 14.1532C9.92196 14.1532 10.6694 13.4058 10.6694 12.4839C10.6694 11.5619 9.92196 10.8145 9 10.8145ZM7.41509 4.81406L7.68429 9.74954C7.69689 9.98049 7.88785 10.1613 8.11913 10.1613H9.88087C10.1122 10.1613 10.3031 9.98049 10.3157 9.74954L10.5849 4.81406C10.5985 4.5646 10.3999 4.35484 10.1501 4.35484H7.84989C7.60006 4.35484 7.40148 4.5646 7.41509 4.81406Z" fill="${color}"/>
      </g>
      <defs>
        <filter id="filter0_d_7021_48706" x="0" y="0" width="30" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="2"/>
          <feColorMatrix type="matrix" values="${getShadowMatrixValuesByColor(color)}"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7021_48706"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7021_48706" result="shape"/>
        </filter>
      </defs>
  `;

const getSvgForBadge = (
  entityPin: EntityPin,
  visibleBadges?: AlertBadgesType[]
): [string, Size] => {
  const color = getEntityColor(entityPin.data, visibleBadges);
  if (!color) {
    return ["", [0, 0]];
  }

  return [getBadgeSvgWithColor(color), [30, 30]];
};

export default getSvgForBadge;

import { RootState } from "../rootReducer";
import { createSelector } from "reselect";
import memoize from "fast-memoize";
import {
  EntityType,
  EntityTypesSupportingSavedFilters,
  MapFilterEntityType,
} from "@mapmycustomers/shared/types/entity";
import SavedFilter, { MapSavedFilter } from "@mapmycustomers/shared/types/viewModel/SavedFilter";
import { getFavoriteFilters } from "../iam";
import { isEmpty } from "lodash-es";

// saved filter is suitable if it's type matches given entityType or if it's a Pin SavedFilter and it has
// rules for the given entityType or universal rules and no other rules
const isSuitableForEntityType =
  (entityType: EntityTypesSupportingSavedFilters) =>
  (savedFilter: SavedFilter): boolean => {
    if (entityType === EntityType.ACTIVITY || entityType === EntityType.PIN) {
      return savedFilter.type === entityType;
    }
    // this IF should never be true anymore, since platform doesn't support any other types for SavedFilters
    // except Activity and Pin, but I'm still keeping it here for safety reasons
    if (savedFilter.type !== EntityType.PIN) {
      return false;
    }
    const filters = (savedFilter as MapSavedFilter).filters;
    const usedEntityTypes = (Object.keys(filters) as MapFilterEntityType[]).filter(
      (entityType) => !isEmpty(filters[entityType])
    );
    return (
      (usedEntityTypes.length === 2 &&
        usedEntityTypes.includes("universal") &&
        usedEntityTypes.includes(entityType)) ||
      (usedEntityTypes.length === 1 &&
        (usedEntityTypes.includes("universal") || usedEntityTypes.includes(entityType)))
    );
  };

const savedFiltersState = (state: RootState) => state.savedFilters;

export const getSavedFilters = createSelector(savedFiltersState, (state) =>
  memoize((entityType: EntityTypesSupportingSavedFilters): SavedFilter[] => {
    const filters = state[entityType === EntityType.ACTIVITY ? entityType : EntityType.PIN].list;
    if (entityType === EntityType.ACTIVITY || entityType === EntityType.PIN) {
      return filters;
    }
    const isSuitable = isSuitableForEntityType(entityType);
    return filters.filter(isSuitable);
  })
);

export const getFavoriteSavedFilters = createSelector(
  savedFiltersState,
  getFavoriteFilters,
  (state, favoriteFilterIds) =>
    memoize((entityType: EntityTypesSupportingSavedFilters): SavedFilter[] => {
      const isSuitable = isSuitableForEntityType(entityType);
      return state[entityType === EntityType.ACTIVITY ? entityType : EntityType.PIN].list.filter(
        (filter) => favoriteFilterIds.includes(filter.id) && isSuitable(filter)
      );
    })
);

export const isSavedFiltersLoad = createSelector(savedFiltersState, (state) =>
  memoize(
    (entityType: EntityTypesSupportingSavedFilters): boolean =>
      state[entityType === EntityType.ACTIVITY ? entityType : EntityType.PIN].loading
  )
);

export const getSelectedFilter = createSelector(
  savedFiltersState,
  (state) =>
    (entityType: EntityTypesSupportingSavedFilters): SavedFilter | undefined =>
      state[entityType].selectedFilter
);

export const isSavedFilterCreateLoading = createSelector(
  savedFiltersState,
  ({ createLoading }) => createLoading
);

export const isSavedFilterUpdateLoading = createSelector(
  savedFiltersState,
  ({ updateLoading }) => updateLoading
);

import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Row from "antd/es/row";
import Col from "antd/es/col";
import GoogleAutoCompleteAddress from "component/input/Address/components/GoogleAutoCompleteAddress";
import ManualAddress from "component/input/Address/ManualAddress";
import { NumberField } from "@mapmycustomers/ui";
import ButtonLink from "../ButtonLink";
import Address from "@mapmycustomers/shared/types/Address";
import GeoPoint from "@mapmycustomers/shared/types/shapes/GeoPoint";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { getFormattedAddressForUi } from "util/formatters";
import { COORDINATES_REGEX } from "util/regexp";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import layout from "styles/layout";
import styles from "./AddressField.module.scss";

interface Props {
  className?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
}

const AddressField: React.FC<Props> = ({ className, disabled, onChange, placeholder, value }) => {
  const intl = useIntl();

  const [isCoordinatesMode, setCoordinatesMode, unSetCoordinatesMode, toggleCoordinatesMode] =
    useBoolean(COORDINATES_REGEX.test(value ?? ""));
  const [coordinates, setCoordinates] = useState<GeoPoint["coordinates"]>([0, 0]);
  const [isManualAddress, switchToManualAddress, , toggleManualAddress] = useBoolean();
  const [manualAddress, setManualAddress] = useState<Address>();

  useEffect(() => {
    if (COORDINATES_REGEX.test(value ?? "")) {
      setCoordinatesMode();
      const groups = (value ?? "").match(COORDINATES_REGEX)?.groups;
      if (groups) {
        setCoordinates([
          parseFloat(groups?.lon?.replace(",", ".")) ?? 0,
          parseFloat(groups?.lat?.replace(",", ".")) ?? 0,
        ]);
      }
    } else {
      unSetCoordinatesMode();
      setCoordinates([0, 0]);
    }
  }, [setCoordinatesMode, setCoordinates, unSetCoordinatesMode, value]);

  const handleSetLatitude = useCallback(
    (value?: number) => {
      setCoordinates(([long]) => {
        onChange?.(`${value ?? 0},${long}`);
        return [long, value ?? 0];
      });
    },
    [onChange, setCoordinates]
  );

  const handleSetLongitude = useCallback(
    (value?: number) => {
      setCoordinates(([, lng]) => {
        onChange?.(`${lng},${value ?? 0}`);
        return [value ?? 0, lng];
      });
    },
    [onChange, setCoordinates]
  );

  const handleChangeAutoComplete = useCallback(
    (address?: Address) => {
      setManualAddress(address);
      onChange?.(getFormattedAddressForUi(address));
    },
    [onChange, setManualAddress]
  );

  const handleEnterManual = useDynamicCallback(() => switchToManualAddress());

  const handleManualChangeAddress = useCallback(
    (address?: Address) => {
      const updatedManualAddress = {
        ...manualAddress,
        ...address,
      };

      setManualAddress(updatedManualAddress);
      onChange?.(getFormattedAddressForUi(address));
    },
    [manualAddress, onChange, setManualAddress]
  );

  return (
    <div className={className}>
      {isCoordinatesMode ? (
        <Row gutter={layout.spacerS}>
          <Col span={12}>
            <NumberField
              disabled={disabled}
              inputClassName={styles.coordinate}
              max={90}
              min={-90}
              onChange={handleSetLatitude}
              placeholder={intl.formatMessage({
                id: "component.customField.address.latitude",
                defaultMessage: "Latitude",
                description: "Latitude field label at address custom field",
              })}
              value={coordinates[1]}
            />
          </Col>

          <Col span={12}>
            <NumberField
              disabled={disabled}
              inputClassName={styles.coordinate}
              max={180}
              min={-180}
              onChange={handleSetLongitude}
              placeholder={intl.formatMessage({
                id: "component.customField.address.longitude",
                defaultMessage: "Longitude",
                description: "Longitude field label at address custom field",
              })}
              value={coordinates[0]}
            />
          </Col>
        </Row>
      ) : isManualAddress ? (
        <ManualAddress onChange={handleManualChangeAddress} value={manualAddress} />
      ) : (
        <GoogleAutoCompleteAddress
          disabled={disabled}
          onChange={handleChangeAutoComplete}
          onEnterManually={handleEnterManual}
          value={{ address: value ?? "" }}
        />
      )}

      <Row align="middle" gutter={layout.spacerM} justify="space-between">
        <Col>
          {isManualAddress && (
            <ButtonLink className={styles.searchManualButton} onClick={toggleManualAddress}>
              {intl.formatMessage({
                id: "component.customField.address.searchGoogle",
                defaultMessage: "Search with Google",
                description: "Search with Google button for auto-complete address custom field",
              })}
            </ButtonLink>
          )}
        </Col>
        <Col>
          <ButtonLink onClick={toggleCoordinatesMode}>
            {isCoordinatesMode
              ? intl.formatMessage({
                  id: "component.customField.address.switchToAddress",
                  defaultMessage: "Set location via street address",
                  description: "`Set location via street address` button at address custom field",
                })
              : intl.formatMessage({
                  id: "component.customField.address.switchToCoordinates",
                  defaultMessage: "Set location via coordinates",
                  description: "`Set location via coordinates` button at address custom field",
                })}
          </ButtonLink>
        </Col>
      </Row>
    </div>
  );
};

export default AddressField;

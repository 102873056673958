import { useEffect } from "react";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import doubleClickDetector from "./doubleClickDetector";

type Map = google.maps.Map;

interface DataHandlerMap<T extends Map> {
  addfeature: (this: T, event: google.maps.Data.AddFeatureEvent) => void;
  click: (this: T, event: google.maps.Data.MouseEvent) => void;
  contextmenu: (this: T, event: google.maps.Data.MouseEvent) => void;
  dblclick: (this: T, event: google.maps.Data.MouseEvent) => void;
  mousedown: (this: T, event: google.maps.Data.MouseEvent) => void;
  mousemove: (this: T, event: google.maps.Data.MouseEvent) => void;
  mouseout: (this: T, event: google.maps.Data.MouseEvent) => void;
  mouseover: (this: T, event: google.maps.Data.MouseEvent) => void;
  mouseup: (this: T, event: google.maps.Data.MouseEvent) => void;
  removefeature: (this: T, event: google.maps.Data.RemoveFeatureEvent) => void;
  removeproperty: (this: T, event: google.maps.Data.RemovePropertyEvent) => void;
  setgeometry: (this: T, event: google.maps.Data.SetGeometryEvent) => void;
  setproperty: (this: T, event: google.maps.Data.SetPropertyEvent) => void;
}

const useMapDataEventHandler = <M extends Map, N extends keyof DataHandlerMap<M>>(
  map: M | undefined,
  eventName: N,
  handler: DataHandlerMap<M>[N]
) => {
  const internalHandler = useDynamicCallback(function (this: M, ...args: any) {
    if (eventName === "click") {
      doubleClickDetector.handleSingleClick(() => {
        (handler as Function).apply(this, args);
      });
      return;
    } else if (eventName === "dblclick") {
      doubleClickDetector.handleDoubleClick();
    }
    (handler as Function).apply(this, args);
  });

  useEffect(() => {
    if (map) {
      const listener = map.data.addListener(eventName, internalHandler);
      return () => {
        listener.remove();
      };
    }
  }, [eventName, internalHandler, map]);
};

export default useMapDataEventHandler;

import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import { IHumanReadableFilterConfig } from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import TextFilter, { TEXT_FILTER_OPERATORS } from "util/filters/TextFilter";
import EmptyFilter from "./EmptyFilter";
import NumberFilter, { NUMBER_FILTER_OPERATORS } from "./NumberFilter";
import NumberRangeFilter from "./NumberRangeFilter";
import DateFilter, { DATE_FILTER_OPERATORS } from "./DateFilter";
import DateRangeFilter from "./DateRangeFilter";
import { BOOLEAN_FILTER_OPERATORS } from "./BooleanFilter";
import IntervalFilter from "./IntervalFilter";

// assumes that "empty" instance is included
export const DEFAULT_EMPTINESS_OPERATOR = [
  { operator: FilterOperator.EMPTY, instance: "empty" },
  { operator: FilterOperator.NOT_EMPTY, instance: "empty" },
];

const defaultFilters: Partial<Record<FieldType, IHumanReadableFilterConfig>> = {
  [FieldType.STRING]: {
    defaultInstance: "text",
    filterInstances: {
      text: TextFilter,
      empty: EmptyFilter,
    },
    operators: [...TEXT_FILTER_OPERATORS, ...DEFAULT_EMPTINESS_OPERATOR],
  },
  [FieldType.NUMBER]: {
    defaultInstance: "number",
    filterInstances: {
      number: NumberFilter,
      numberRange: NumberRangeFilter,
      empty: EmptyFilter,
    },
    operators: [
      ...NUMBER_FILTER_OPERATORS,
      { operator: FilterOperator.IN_RANGE, instance: "numberRange" },
      ...DEFAULT_EMPTINESS_OPERATOR,
    ],
  },
  [FieldType.DATE_TIME]: {
    defaultInstance: "date",
    filterInstances: {
      date: DateFilter,
      dateRange: DateRangeFilter,
      interval: IntervalFilter,
    },
    operators: [
      ...DATE_FILTER_OPERATORS,
      { operator: FilterOperator.IN_RANGE, instance: "dateRange" },
      { operator: FilterOperator.INTERVAL_BEFORE, instance: "interval" },
      { operator: FilterOperator.INTERVAL_AFTER, instance: "interval" },
    ],
  },
  [FieldType.DATE]: {
    defaultInstance: "date",
    filterInstances: {
      date: DateFilter,
      dateRange: DateRangeFilter,
      interval: IntervalFilter,
    },
    operators: [
      ...DATE_FILTER_OPERATORS,
      { operator: FilterOperator.IN_RANGE, instance: "dateRange" },
      { operator: FilterOperator.INTERVAL_BEFORE, instance: "interval" },
      { operator: FilterOperator.INTERVAL_AFTER, instance: "interval" },
    ],
  },
  [FieldType.BOOLEAN]: {
    defaultInstance: "boolean",
    filterInstances: {
      boolean: EmptyFilter,
    },
    operators: BOOLEAN_FILTER_OPERATORS,
  },
};

export default defaultFilters;

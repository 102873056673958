import { FormInstance } from "antd";
import LocationViewMode from "enum/LocationViewMode";
import { Company } from "@mapmycustomers/shared/types/entity";
import locatedToAddress from "util/locatedToAddress";

export const inheritAddressFromCompany = (form: FormInstance<any>, inheritAddressFrom: Company) => {
  form.setFieldsValue({
    addressDetails:
      inheritAddressFrom.address !== undefined ? locatedToAddress(inheritAddressFrom) : undefined,
    longitude: inheritAddressFrom.geoPoint?.coordinates[0]?.toString(),
    latitude: inheritAddressFrom.geoPoint?.coordinates[1]?.toString(),
    locationViewMode:
      inheritAddressFrom.geoPoint &&
      !inheritAddressFrom.address &&
      !inheritAddressFrom.region &&
      !inheritAddressFrom.postalCode
        ? LocationViewMode.COORDINATES
        : LocationViewMode.ADDRESS,
  });
};

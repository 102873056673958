import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Alert } from "@mapmycustomers/ui";
import styles from "./RelatedEntitiesMatching.module.scss";
import { Company, Person } from "@mapmycustomers/shared/types/entity";
import { messages } from "./messages";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { LoadingSpinner } from "@mapmycustomers/ui";
import ButtonLink from "component/ButtonLink";

interface Props {
  associatedCompany?: Company;
  associatedPerson?: Person;
  isEntityRelating: boolean;
  relateEntities: (payload: {
    associatedCompany?: Company;
    associatedPerson?: Person;
    failureCallback?: () => void;
    isPersonCorrectlyRelatedToCompany: boolean;
    successCallback?: () => void;
  }) => void;
}

const RelatedEntitiesMatching: React.FC<Props> = ({
  associatedCompany,
  associatedPerson,
  isEntityRelating,
  relateEntities,
}) => {
  const intl = useIntl();

  const [error, setError, unsetError] = useBoolean();
  const [success, setSuccess, unsetSuccess] = useBoolean();
  const [visible, show, hide] = useBoolean();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [savedCompanyId, setSavedCompanyId] = useState<Company["id"] | undefined>();
  const [savedPersonId, setSavedPersonId] = useState<Person["id"] | undefined>();

  const isPersonCorrectlyRelatedToCompany =
    !associatedCompany ||
    !associatedPerson ||
    (associatedPerson?.accounts ?? []).some(({ id }) => associatedCompany?.id === id);

  useEffect(() => {
    if (savedCompanyId !== associatedCompany?.id || savedPersonId !== associatedPerson?.id) {
      unsetSuccess();
      unsetError();
    }
    setSavedCompanyId(associatedCompany?.id);
    setSavedPersonId(associatedPerson?.id);
  }, [
    associatedCompany?.id,
    associatedPerson?.id,
    savedCompanyId,
    savedPersonId,
    unsetError,
    unsetSuccess,
  ]);

  const message = useMemo(() => {
    if (!isPersonCorrectlyRelatedToCompany) {
      return intl.formatMessage(messages.companyPersonWrongRelation, { error });
    }
    if (success) {
      return successMessage;
    }
    return "";
  }, [error, intl, isPersonCorrectlyRelatedToCompany, success, successMessage]);

  useEffect(() => {
    if (!isPersonCorrectlyRelatedToCompany) {
      show();
      setSuccessMessage(intl.formatMessage(messages.companyPersonWrongRelationRelated));
    } else {
      hide();
    }
    if (success) {
      show();
    }
  }, [error, hide, intl, isPersonCorrectlyRelatedToCompany, show, success]);

  const handleRelateEntities = useCallback(() => {
    relateEntities({
      associatedCompany,
      associatedPerson,
      failureCallback: () => {
        setError();
        unsetSuccess();
      },
      isPersonCorrectlyRelatedToCompany,
      successCallback: () => {
        unsetError();
        setSuccess();
      },
    });
  }, [
    associatedCompany,
    associatedPerson,
    isPersonCorrectlyRelatedToCompany,
    relateEntities,
    setError,
    setSuccess,
    unsetError,
    unsetSuccess,
  ]);

  if (!visible) {
    return null;
  }

  return (
    <Alert
      action={
        isEntityRelating ? (
          <LoadingSpinner micro />
        ) : success ? undefined : (
          <ButtonLink onClick={handleRelateEntities}>
            {error
              ? intl.formatMessage(messages.tryAgain)
              : intl.formatMessage(messages.relateRecords)}
          </ButtonLink>
        )
      }
      className={styles.container}
      closable={success}
      message={message}
      onClose={hide}
      showIcon
      type={success ? "success" : error ? "error" : "warning"}
    />
  );
};

export default RelatedEntitiesMatching;

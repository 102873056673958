import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { downloadEntities, fetchData } from "./actions";
import {
  EntitiesSupportingDataView,
  EntityType,
  EntityTypesSupportedByMapsPage,
  EntityTypeSupportingDataView,
  EntityTypeSupportingGroups,
  Group,
  MapEntity,
  Route,
} from "@mapmycustomers/shared/types/entity";
import {
  getDataMode,
  getEntityType,
  getField,
  getFilter,
  getParams,
  getSelectedUserIds,
} from "./selectors";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { callApi } from "store/api/callApi";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getOrganizationId } from "store/iam";
import { handleError } from "store/errors/actions";
import { getTeams, getUsers } from "store/members";
import User from "@mapmycustomers/shared/types/User";
import AggregatedListResponse from "types/viewModel/AggregatedListResponse";
import ListResponseAggregation from "types/viewModel/ListResponseAggregation";
import {
  FunnelDataRecord,
  GroupDataRecord,
  StageDataRecord,
  UserDataRecord,
} from "../type/DataRecord";
import DataViewMode from "../../../enum/DataViewMode";
import isGroupedField from "../util/isGroupedField";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { getFunnels } from "store/deal";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import { getGroupsForEntity } from "../../../store/groups";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { NO_TEAM_ID } from "../util/const";
import Team from "@mapmycustomers/shared/types/Team";
import { EMPTY_AGGREGATION_KEY } from "../../../util/consts";
import sortByValue from "../util/sortByValue";
import { userDisplayName } from "util/formatters";
import DataViewParametersState from "../../../types/dataView/DataViewParametersState";
import LocalSettings from "config/LocalSettings";
import downloadEntitiesAsCsv from "../../../util/file/downloadEntitiesAsCsv";
import i18nService from "../../../config/I18nService";
import { getEntityTypeDisplayName } from "util/ui";
import notification from "antd/es/notification";
import messages from "../messages";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import Named from "@mapmycustomers/shared/types/base/Named";
import getFieldModelByEntityType from "../../../util/fieldModel/getByEntityType";
import deepmerge from "deepmerge";

type IdToNameMap = Record<Identified["id"], Named["name"]>;

function* onSaveSettings() {
  const params: DataViewParametersState = yield select(getParams);
  LocalSettings.setDataViewParametersState(params);
}

function* onDownloadEntities() {
  const entityType: EntityTypeSupportingDataView = yield select(getEntityType);
  const orgId: Organization["id"] = yield select(getOrganizationId);
  const filter: PlatformFilterModel = yield select(getFilter);

  let $filters: PlatformFilterModel = deepmerge(
    {
      entities: {
        [entityType]: {
          includeGroups: true,
          includeRoutes: true,
        },
      },
      includeCustomFields: true,
    },
    filter
  );
  // delete all entities from filter except selected one
  $filters.entities = {
    [entityType]: (
      $filters.entities as Partial<Record<EntityTypesSupportedByMapsPage, PlatformFilterModel>>
    )[entityType],
  };

  try {
    const response: ListResponse<MapEntity> = yield callApi("fetchPins", orgId, {
      $filters,
      $limit: 10000,
    });

    const intl = i18nService.getIntl();

    downloadEntitiesAsCsv(
      `${
        intl
          ? getEntityTypeDisplayName(intl, entityType, { lowercase: true, plural: true })
          : entityType
      }_selection`,
      entityType,
      response.data,
      getFieldModelByEntityType(entityType).fields.filter(
        (field) => !field.hasFeature(FieldFeature.NON_EXPORT_VIEW)
      )
    );
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.fileDownloaded),
      });
    }
    yield put(downloadEntities.success());
  } catch (error) {
    yield put(downloadEntities.failure(error));
    yield put(handleError({ error }));
    const intl = i18nService.getIntl();
    if (intl) {
      notification.error({
        message: intl.formatMessage(messages.fileDownloadedError),
      });
    }
  }
}

function* onFetchData() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityType: EntityTypeSupportingDataView = yield select(getEntityType);
    const filter: PlatformFilterModel = yield select(getFilter);
    const users: User[] = yield select(getUsers);
    const selectedUsersIds: User["id"][] | undefined = yield select(getSelectedUserIds);

    const field: IField | undefined = yield select(getField);
    const dataMode: DataViewMode = yield select(getDataMode);
    const funnels: Funnel[] = yield select(getFunnels);

    if (field) {
      let $filters: PlatformFilterModel = deepmerge(
        {
          entities: {
            [entityType]: {
              includeGroups: field.hasFeature(FieldFeature.GROUP_FIELD),
              includeRoutes: field.hasFeature(FieldFeature.ROUTE_FIELD),
            },
          },
        },
        filter
      );

      const userData: UserDataRecord[] = [];
      let groupData: GroupDataRecord[] = [];
      let funnelData: FunnelDataRecord[] = [];
      let totalValue = 0;

      let aggMode: DataViewMode | string | undefined = dataMode;
      let aggValue = [field.name];
      let primaryGroupField = "user.id";
      const groupedField = isGroupedField(field);
      if (groupedField && !field.hasFeature(FieldFeature.TEAM_FIELD)) {
        aggMode = "sum";
        aggValue = ["id"];
        primaryGroupField = field.platformFilterName;
        if (field.hasFeature(FieldFeature.FUNNEL_FIELD)) {
          primaryGroupField = "dealStageId";
        } else if (field.hasFeature(FieldFeature.GROUP_FIELD)) {
          primaryGroupField = "groups";
        } else if (field.hasFeature(FieldFeature.ROUTE_FIELD)) {
          primaryGroupField = "routes";
        } else if (field.hasFeature(FieldFeature.SOURCE_FIELD)) {
          primaryGroupField = "sourceCreated.clientType";
        }
      }
      const $aggs = {
        [aggMode]: aggValue,
        primaryGroup: { field: primaryGroupField },
      };

      if (groupedField && selectedUsersIds) {
        if (selectedUsersIds.length === 0) {
          yield put(fetchData.failure(undefined));
          // no need to throw some error.
          return false;
        } else {
          $filters = { ...$filters, userId: { $in: selectedUsersIds } };
        }
      }
      (
        [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL] as EntityTypesSupportedByMapsPage[]
      ).forEach((type) => {
        if (type !== entityType) {
          // delete all entities from filter except selected one
          delete (
            $filters.entities as Partial<
              Record<EntityTypesSupportedByMapsPage, PlatformFilterModel>
            >
          )[type];
        }
      });

      const requestPayload = {
        $aggs,
        $filters,
        $limit: 10000,
      };
      const response: AggregatedListResponse<
        EntitiesSupportingDataView,
        ListResponseAggregation<EntitiesSupportingDataView>[]
      > = yield callApi("fetchPins", orgId, requestPayload);

      const stageValues: Record<Stage["id"], number> = {};
      let nameById: IdToNameMap = {};
      const teamToValue: Map<Team["id"], number> = new Map();

      if (field.hasFeature(FieldFeature.GROUP_FIELD)) {
        const groupSelector: (entityType: EntityTypeSupportingGroups) => Group[] = yield select(
          getGroupsForEntity
        );
        const groups: Group[] = groupSelector(entityType);
        nameById = groups.reduce<IdToNameMap>(
          (result, group) => ({ ...result, [group.id]: group.name }),
          {}
        );
      } else if (field.hasFeature(FieldFeature.ROUTE_FIELD)) {
        const routeResponse: ListResponse<Route> = yield callApi(
          entityType === EntityType.COMPANY ? "fetchCompanyRoutes" : "fetchPeopleRoutes",
          orgId,
          { $limit: 9999 }
        );
        nameById = routeResponse.data.reduce<IdToNameMap>(
          (result, route) => ({ ...result, [route.id]: route.name }),
          {}
        );
      } else if (field.hasFeature(FieldFeature.OWNER_FIELD)) {
        nameById = users.reduce<IdToNameMap>(
          (result, user) => ({ ...result, [user.id]: userDisplayName(user) }),
          {}
        );
      } else if (field.hasFeature(FieldFeature.TEAM_FIELD)) {
        const teams: Team[] = yield select(getTeams);
        nameById = teams.reduce<IdToNameMap>(
          (result, team) => ({ ...result, [team.id]: team.name }),
          {}
        );
      }
      response.aggregations.forEach((record) => {
        let value = 0;
        if (groupedField && record.doc_count !== null) {
          value = record.doc_count as number;
          if (field.hasFeature(FieldFeature.FUNNEL_FIELD)) {
            stageValues[record.key as Stage["id"]] = value;
          } else if (field.hasFeature(FieldFeature.TEAM_FIELD)) {
            const user = users.find(({ id }) => id === record.key);
            if (user) {
              if (user.teams.length) {
                user.teams.forEach((team) => {
                  teamToValue.set(team.id, (teamToValue.get(team.id) ?? 0) + value);
                });
              } else {
                teamToValue.set(NO_TEAM_ID, (teamToValue.get(NO_TEAM_ID) ?? 0) + value);
              }
            }
          } else {
            const name = nameById[record.key] ?? record.key;
            groupData.push({
              entity: name,
              value,
            });
          }
        } else {
          // this value can be undefined
          if (record[`${aggMode}_${field.name}`].value) {
            value = record[`${aggMode}_${field.name}`].value as number;
            const user = users.find(({ id }) => id === record.key);
            if (user) {
              userData.push({
                entity: user,
                value,
              });
            }
          }
        }
        totalValue += value;
      });
      const aggregationsWithDefinedValueCount = response.aggregations.filter(
        (record) => !!record[`${aggMode}_${field.name}`]?.value
      ).length;
      if (dataMode === DataViewMode.AVG && aggregationsWithDefinedValueCount > 0) {
        totalValue = totalValue / aggregationsWithDefinedValueCount;
      }
      if (field.hasFeature(FieldFeature.FUNNEL_FIELD)) {
        funnels.forEach((funnel) => {
          let value = 0;
          const stages: StageDataRecord[] = [];
          funnel.stages.forEach((stage) => {
            const stageValue = stageValues[stage.id] ?? 0;
            value += stageValues[stage.id] ?? 0;
            stages.push({
              entity: stage,
              value: stageValue,
            });
          });
          funnelData.push({
            funnel,
            stages: sortByValue(stages) as StageDataRecord[],
            value,
          });
        });
        funnelData = sortByValue(funnelData.filter(({ value }) => value > 0)) as FunnelDataRecord[];
      }
      if (field.hasFeature(FieldFeature.TEAM_FIELD)) {
        teamToValue.forEach((value, key) => {
          groupData.push({
            entity: key === NO_TEAM_ID ? EMPTY_AGGREGATION_KEY : nameById[key] ?? "",
            value,
          });
        });
      }
      groupData = sortByValue(groupData.filter(({ value }) => value > 0)) as GroupDataRecord[];

      yield put(
        fetchData.success({
          funnelData,
          groupData,
          totalValue,
          userData,
        })
      );
      yield onSaveSettings();
    } else {
      throw new Error(`Field is not defined`);
    }
  } catch (error) {
    yield put(fetchData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* dataViewSaga() {
  yield takeLatest(fetchData.request, onFetchData);
  yield takeEvery(downloadEntities.request, onDownloadEntities);
}

import FormValues from "../types/FormValues";
import { useMemo } from "react";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import FrequencyCriteria from "../../../../types/frequncy/FrequencyCriteria";
import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/es/form/hooks/useForm";
import doesActivityMeetFrequencyCriteria from "util/frequency/doesActivityMeetFrequencyCriteria";

const useFrequencyCriteria = (
  startAt: Date,
  form: FormInstance<FormValues>,
  initialActivityTypeId?: ActivityType["id"],
  frequencyCriteria?: FrequencyCriteria
): boolean => {
  const date: Date = useWatch("startAt", form) ?? startAt;
  const activityTypeId: ActivityType["id"] | undefined =
    useWatch("activityTypeId", form) ?? initialActivityTypeId;

  return useMemo(
    () => doesActivityMeetFrequencyCriteria(date, activityTypeId, frequencyCriteria),
    [date, activityTypeId, frequencyCriteria]
  );
};

export default useFrequencyCriteria;

import Dashboard from "types/dashboard/Dashboard";
import DashboardCard from "types/dashboard/DashboardCard";
import Scope from "types/dashboard/Scope";
import { ActionType, createAsyncAction } from "typesafe-actions";
import { CardData } from "./saga";

export const fetchDashboards = createAsyncAction(
  "@component/userPreviewCard/fetchDashboards/request",
  "@component/userPreviewCard/fetchDashboards/success",
  "@component/userPreviewCard/fetchDashboards/failure"
)<void, Dashboard[], void>();

export const fetchCards = createAsyncAction(
  "@component/userPreviewCard/fetchCards/request",
  "@component/userPreviewCard/fetchCards/success",
  "@component/userPreviewCard/fetchCards/failure"
)<Scope, Record<number, DashboardCard["id"] | undefined>, void>();

export const updateCards = createAsyncAction(
  "@component/userPreviewCard/updateCards/request",
  "@component/userPreviewCard/updateCards/success",
  "@component/userPreviewCard/updateCards/failure"
)<{ scope: Scope; cardIds: Record<number, DashboardCard["id"] | undefined> }, void, void>();

export const fetchCardData = createAsyncAction(
  "@component/userPreviewCard/fetchCardData/request",
  "@component/userPreviewCard/fetchCardData/success",
  "@component/userPreviewCard/fetchCardData/failure"
)<
  {
    card: DashboardCard;
    callback: (data: CardData) => void;
    failureCallback?: () => void;
    scope: Scope;
  },
  { id: string; data: CardData },
  void
>();

export type Actions = ActionType<
  typeof fetchDashboards | typeof fetchCards | typeof updateCards | typeof fetchCardData
>;

import colors from "styles/_colors.module.scss";
import { LASSO_ZINDEX } from "util/map/consts";

export const MAX_LASSO_PATHS = 5;
export const MIN_LASSO_POINTS = 4;

export const LASSO_POLYLINE_OPTIONS = {
  clickable: false,
  strokeColor: colors.primary100,
  strokeOpacity: 1,
  strokeWeight: 4,
  zIndex: LASSO_ZINDEX,
};

export const LASSO_POLYGON_OPTIONS = {
  clickable: false,
  fillColor: colors.primary100,
  fillOpacity: 0.1,
  strokeColor: colors.primary100,
  strokeOpacity: 1,
  strokeWeight: 4,
  zIndex: LASSO_ZINDEX,
};

import React, { useCallback } from "react";
import styles from "./Item.module.scss";
import cn from "classnames";
import Icon from "./Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnDownLeft } from "@fortawesome/pro-solid-svg-icons/faArrowTurnDownLeft";
import Title from "./Title";
import GlobalSearchItem from "../../../../types/globalSearch/GlobalSearchItem";
import SubTitle from "./SubTitle";
import GlobalSearchItemType from "../../../../enum/GlobalSearchItemType";
import LeadFinderSearch from "../LeadFinderSearch";

interface Props {
  index: number;
  isSelected: boolean;
  onClick: (item: GlobalSearchItem) => void;
  onSetSelected: (index: number) => void;
  item: GlobalSearchItem;
}

const Item: React.FC<Props> = ({ index, isSelected, onClick, onSetSelected, item }) => {
  const handleClick = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  const handleMouserMove = useCallback(() => {
    onSetSelected(index);
  }, [index, onSetSelected]);

  if (item.type === GlobalSearchItemType.LEAD_FINDER) {
    return (
      <LeadFinderSearch
        onMouseMove={handleMouserMove}
        onClick={handleClick}
        isSelected={isSelected}
      />
    );
  }
  return (
    <div
      className={cn(styles.container, { [styles.selected]: isSelected })}
      data-global-search-index={index}
      onClick={handleClick}
      onMouseMove={handleMouserMove}
    >
      <div className={styles.mainPartWrapper}>
        <div>
          <Icon item={item} />
        </div>
        <div>
          <Title item={item} />
          <SubTitle item={item} />
        </div>
      </div>
      {isSelected && (
        <div>
          <FontAwesomeIcon className={styles.enterIcon} icon={faArrowTurnDownLeft} />
        </div>
      )}
    </div>
  );
};

export default Item;

import { DealsRottingStats } from "../types/DealsRottingStats";
import { DealsGroupByType } from "../enum/DealsGroupByType";

export const createInitialDealsRotting = (): DealsRottingStats => {
  const groupByTypes = Object.keys(DealsGroupByType) as DealsGroupByType[];

  return Object.fromEntries(
    groupByTypes.map((entityType) => [entityType, {}])
  ) as DealsRottingStats;
};

import { Dispatch, useState } from "react";
import { isFunction } from "lodash-es";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";

const useStateWithTransformer = <BasicState, UpdatedState = BasicState>(
  initialState: BasicState | (() => BasicState),
  transformer: (value: UpdatedState) => BasicState
): [BasicState, Dispatch<UpdatedState | ((prevState: BasicState) => UpdatedState)>] => {
  const [state, setState] = useState<BasicState>(initialState);

  const handleSetState = useDynamicCallback(
    (value: UpdatedState | ((prevState: BasicState) => UpdatedState)) => {
      setState((prevState) => {
        const updatedState: UpdatedState = isFunction(value) ? value(prevState) : value;
        return transformer(updatedState);
      });
    }
  );

  return [state, handleSetState];
};

export default useStateWithTransformer;

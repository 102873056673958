import ParticipantStatus from "@mapmycustomers/shared/enum/ParticipantStatus";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  [ParticipantStatus.YES]: {
    id: "participantStatus.accepted",
    defaultMessage: "Accepted",
    description: "Participant status label for accepted",
  },
  [ParticipantStatus.NO]: {
    id: "participantStatus.denied",
    defaultMessage: "Denied",
    description: "Participant status label for denied",
  },
  [ParticipantStatus.MAYBE]: {
    id: "participantStatus.maybe",
    defaultMessage: "Maybe",
    description: "Participant status label for maybe",
  },
  [ParticipantStatus.NOREPLY]: {
    id: "participantStatus.notAccepted",
    defaultMessage: "Not accepted",
    description: "Participant status label for not accepted",
  },
});

const getParticipantStatusDisplayName = (intl: IntlShape, participantStatus: ParticipantStatus) =>
  intl.formatMessage(messages[participantStatus]);

export default getParticipantStatusDisplayName;

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Field, { FieldProperties } from "./Field";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import HistoryFieldFilter, { HISTORY_FIELD_FILTER_OPERATORS } from "../../filters/HistoryField";

class HistoryField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      type: FieldType.STRING,
      features: [FieldFeature.FILTERABLE],
      platformFilterName: "updatedFields",
      customFilterConfig: {
        defaultInstance: "historyField",
        filterInstances: {
          historyField: HistoryFieldFilter,
        },
        operators: HISTORY_FIELD_FILTER_OPERATORS,
      },
      ...data,
    });
  }
}

export default HistoryField;

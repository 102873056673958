import ILogger from "./ILogger";
import LogLevel from "./LogLevel";

export default class ConsoleLogger implements ILogger {
  private debug = console.debug.bind(window.console);
  private info = console.info.bind(window.console);
  private warn = console.warn.bind(window.console);
  private error = console.error.bind(window.console);

  log(logLevel: LogLevel, message: string, payload?: any) {
    switch (logLevel) {
      case LogLevel.DEBUG:
        payload ? this.debug(message, payload) : this.debug(message);
        break;
      case LogLevel.INFO:
        payload ? this.info(message, payload) : this.info(message);
        break;
      case LogLevel.WARNING:
        payload ? this.warn(message, payload) : this.warn(message);
        break;
      case LogLevel.ERROR:
        payload ? this.error(message, payload) : this.error(message);
        break;
    }
  }
}

import React, { useCallback } from "react";
import DealEntitySearch, {
  DealEntitySearchProps,
} from "component/input/EntitySearch/DealEntitySearch";
import Form from "antd/es/form";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { useIntl } from "react-intl";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import CreateDealModal from "component/createEditEntity/CreateDealModal";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import cn from "classnames";
import ChangeAssociationsPayload from "store/associations/ChangeAssociationsPayload";
import styles from "./CommonRelationshipField.module.scss";

interface Props extends Pick<DealEntitySearchProps, "allowAdd" | "disabled"> {
  associatedCompany?: Company;
  associatedDeal?: Deal;
  associatedPerson?: Person;
  deals?: Deal[];
  field: IField;
  fixedCompany?: Company;
  fixedPerson?: Person;
  loading?: boolean;
  onChangeAssociatedEntities: (payload: Omit<ChangeAssociationsPayload, "entityType">) => void;
  required?: boolean;
}

const DealRelationshipField: React.FC<Props> = ({
  allowAdd,
  associatedCompany,
  associatedDeal,
  associatedPerson,
  deals,
  disabled,
  field,
  fixedCompany,
  fixedPerson,
  loading,
  onChangeAssociatedEntities,
  required,
}) => {
  const intl = useIntl();
  const form = useFormInstance();

  const [createNewDealVisible, showCreateDealModal, hideCreateDealModal] = useBoolean();

  const handleHideCreateDealModal = useDynamicCallback((newDeal?: Deal) => {
    if (newDeal) {
      const company = newDeal.account;
      const person = newDeal.contact;

      form.setFieldsValue({ companyId: company?.id, dealId: newDeal?.id, personId: person?.id });
      onChangeAssociatedEntities({
        assignee: form.getFieldValue("assignee"),
        company,
        deal: newDeal,
        person,
      });
    }
    hideCreateDealModal();
  });

  const canAdd = allowAdd ?? !disabled;

  const handleChangeAssociatedEntities = useCallback(
    (payload: Omit<ChangeAssociationsPayload, "entityType">) => {
      form.setFieldsValue({ companyId: payload.company?.id, personId: payload.person?.id });
      onChangeAssociatedEntities(payload);
    },
    [form, onChangeAssociatedEntities]
  );

  return (
    <>
      <Form.Item
        className={cn({ [styles.shiftToTop]: canAdd })}
        label={field.displayName}
        name="dealId"
        required={required}
        rules={[{ required }]}
      >
        <DealEntitySearch
          allowAdd={canAdd}
          deals={deals}
          disabled={disabled}
          hideLabel
          loading={loading}
          onAddClick={showCreateDealModal}
          onChangeAssociatedEntities={handleChangeAssociatedEntities}
          selectedCompany={associatedCompany}
          selectedDeal={associatedDeal}
          selectedPerson={associatedPerson}
        />
      </Form.Item>

      {createNewDealVisible && (
        <CreateDealModal
          fixedCompany={
            (fixedPerson?.accounts?.[0] as Company) ?? fixedCompany ?? associatedCompany
          }
          fixedPerson={fixedPerson ?? associatedPerson}
          okText={intl.formatMessage({
            id: "associations.deals.addNewDealModal.okButton",
            defaultMessage: "Create and Add Deal",
            description:
              "Create button title on Create Deal modal when called from Select Deals modal",
          })}
          onHide={handleHideCreateDealModal}
        />
      )}
    </>
  );
};

export default DealRelationshipField;

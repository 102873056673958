import { createReducer } from "typesafe-actions";
import {
  Actions,
  downloadEntities,
  fetchData,
  initializeDataView,
  selectDataDisplayType,
  selectDataMode,
  selectEntityType,
  selectField,
  selectFunnelId,
  selectUsers,
} from "./actions";
import {
  EntityType,
  EntityTypeSupportingDataView,
  Group,
  Territory,
} from "@mapmycustomers/shared/types/entity";
import getDefaultFieldName from "../util/getDefaultFieldName";
import { FunnelDataRecord, GroupDataRecord, UserDataRecord } from "../type/DataRecord";
import DataViewMode from "../../../enum/DataViewMode";
import DataViewParametersState, {
  DataViewEntityTypeField,
} from "types/dataView/DataViewParametersState";
import LocalSettings from "config/LocalSettings";
import DataViewDisplayType from "../../../enum/DataViewDisplayType";
import DataViewEntity from "../type/DataViewEntity";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import SmartTerritoryConfig from "@mapmycustomers/shared/types/territory/SmartTerritoryConfig";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

const DEFAULT_DATA_VIEW_PARAMETERS_STATE: DataViewParametersState = {
  field: [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL].reduce(
    (result, type) => ({
      ...result,
      [type]: getDefaultFieldName(type as EntityTypeSupportingDataView),
    }),
    {}
  ) as DataViewEntityTypeField,
  dataMode: DataViewMode.SUM,
  dataDisplayType: DataViewDisplayType.GROSS,
};

export interface DataViewState {
  downloading: boolean;
  entityGroupIds?: Group["id"][];
  entityType: EntityTypeSupportingDataView;
  filter?: PlatformFilterModel;
  funnelData: FunnelDataRecord[];
  groupData: GroupDataRecord[];
  loading: boolean;
  multipolygon?: LongLat[][];
  params: DataViewParametersState;
  records?: DataViewEntity[];
  smartTerritoryConfig?: SmartTerritoryConfig;
  territoryIds?: Territory["id"][];
  totalValue: number;
  userData: UserDataRecord[];
}

export const dataViewInitialState: DataViewState = {
  downloading: false,
  entityType: EntityType.COMPANY,
  funnelData: [],
  groupData: [],
  loading: false,
  params: { ...DEFAULT_DATA_VIEW_PARAMETERS_STATE, ...LocalSettings.getDataViewParametersState() },
  totalValue: 0,
  userData: [],
};

const dataView = createReducer<DataViewState, Actions>(dataViewInitialState)
  .handleAction(selectEntityType, (state, { payload }) => ({
    ...state,
    entityType: payload,
    field: getDefaultFieldName(payload),
  }))
  .handleAction(fetchData.request, (state) => ({
    ...state,
    loading: true,
    totalValue: 0,
  }))
  .handleAction(
    fetchData.success,
    (state, { payload: { userData, groupData, funnelData, totalValue } }) => {
      let funnelId = funnelData[0]?.funnel.id;
      const funnelIds = new Set(funnelData.map(({ funnel: { id } }) => id));
      if (state.params.funnelId && funnelIds.has(state.params.funnelId)) {
        funnelId = state.params.funnelId;
      }
      return {
        ...state,
        loading: false,
        userData,
        groupData,
        funnelData,
        totalValue,
        params: {
          ...state.params,
          funnelId,
        },
      };
    }
  )
  .handleAction(fetchData.failure, (state) => ({
    ...state,
    loading: false,
    groupData: [],
    userData: [],
    funnelData: [],
    totalValue: 0,
  }))
  .handleAction(selectField, (state, { payload }) => ({
    ...state,
    params: {
      ...state.params,
      field: {
        ...state.params.field,
        [state.entityType]: payload,
      },
    },
  }))
  .handleAction(selectDataMode, (state, { payload }) => ({
    ...state,
    params: {
      ...state.params,
      dataMode: payload,
    },
  }))
  .handleAction(selectFunnelId, (state, { payload }) => ({
    ...state,
    params: {
      ...state.params,
      funnelId: payload,
    },
  }))
  .handleAction(selectDataDisplayType, (state, { payload }) => ({
    ...state,
    params: {
      ...state.params,
      dataDisplayType: payload,
    },
  }))
  .handleAction(selectUsers, (state, { payload }) => ({
    ...state,
    params: {
      ...state.params,
      selectedUserIds: payload,
    },
  }))
  .handleAction(
    initializeDataView,
    (state, { payload: { entityTypeRecordsCount, filter, users } }) => {
      const entityType =
        (entityTypeRecordsCount[EntityType.COMPANY] ?? 0) > 0
          ? EntityType.COMPANY
          : (entityTypeRecordsCount[EntityType.PERSON] ?? 0) > 0
          ? EntityType.PERSON
          : EntityType.DEAL;
      return {
        ...state,
        entityType,
        field: getDefaultFieldName(entityType),
        filter,
        selectedUsers: users,
      };
    }
  )
  .handleAction(downloadEntities.request, (state, { payload }) => ({
    ...state,
    downloading: true,
  }))
  .handleAction([downloadEntities.success, downloadEntities.failure], (state, { payload }) => ({
    ...state,
    downloading: false,
  }));

export type DataViewActions = Actions;

export default dataView;

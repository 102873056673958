import FieldModel from "./impl/FieldModel";
import DateTimeField from "./impl/DateTimeField";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { createField } from "./impl/fieldModelUtil";
import NameField from "./impl/NameField";
import { defineMessages } from "react-intl";

export enum GroupFieldName {
  NAME = "name",
  COLOR = "color",
  UPDATED_AT = "updatedAt",
}

const displayNames = defineMessages<GroupFieldName>({
  [GroupFieldName.NAME]: {
    id: "group.field.name",
    defaultMessage: "Name",
    description: "Name field of group",
  },
  [GroupFieldName.COLOR]: {
    id: "group.field.color",
    defaultMessage: "Color",
    description: "Color field of group",
  },
  [GroupFieldName.UPDATED_AT]: {
    id: "group.field.updatedAt",
    defaultMessage: "Date Last Modified",
    description: "Modified At field of group",
  },
});

const groupFieldModel = new FieldModel(
  [
    new NameField({
      name: GroupFieldName.NAME,
      displayName: displayNames[GroupFieldName.NAME],
    }),
    {
      name: GroupFieldName.COLOR,
      displayName: displayNames[GroupFieldName.COLOR],
      type: FieldType.STRING,
      features: [FieldFeature.SORTABLE, FieldFeature.FILTERABLE],
      customGridProperties: {
        cellRenderer: "colorCellRenderer",
      },
    },
    new DateTimeField({
      name: GroupFieldName.UPDATED_AT,
      displayName: displayNames[GroupFieldName.UPDATED_AT],
    }),
  ].map(createField)
);

export default groupFieldModel;

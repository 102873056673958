import { ActionType, createAsyncAction } from "typesafe-actions";
import DealLossReason from "@mapmycustomers/shared/types/entity/deals/DealLossReason";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import { OnlyRequiredFields } from "@mapmycustomers/shared/util/ts";

export const fetchDealLossReasons = createAsyncAction(
  "@dealLossReasons/fetchAll/request",
  "@dealLossReasons/fetchAll/success",
  "@dealLossReasons/fetchAll/failure"
)<Funnel["id"] | undefined | void, DealLossReason[], void>();

export const createDealLossReason = createAsyncAction(
  "@dealLossReasons/create/request",
  "@dealLossReasons/create/success",
  "@dealLossReasons/create/failure"
)<
  {
    reason: OnlyRequiredFields<Pick<DealLossReason, "name" | "funnelId" | "displayOrder">, "name">;
    callback?: (reason: DealLossReason) => void;
  },
  DealLossReason,
  void
>();

export const deleteDealLossReason = createAsyncAction(
  "@dealLossReasons/delete/request",
  "@dealLossReasons/delete/success",
  "@dealLossReasons/delete/failure"
)<DealLossReason["id"], DealLossReason["id"], void>();

export const updateDealLossReason = createAsyncAction(
  "@dealLossReasons/updateDealLossReason/request",
  "@dealLossReasons/updateDealLossReason/success",
  "@dealLossReasons/updateDealLossReason/failure"
)<DealLossReason, DealLossReason, void>();

export type Actions = ActionType<
  | typeof createDealLossReason
  | typeof deleteDealLossReason
  | typeof fetchDealLossReasons
  | typeof updateDealLossReason
>;

import React from "react";
import { Activity } from "@mapmycustomers/shared/types/entity";
import { Avatar } from "@mapmycustomers/ui";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import styles from "./ActivityAssignee.module.scss";
import UserPreviewCard from "component/UserPreviewCard";

interface Props {
  activity: Activity;
  avatarClassName?: string;
  className?: string;
}

const ActivityAssignee: React.FC<Props> = ({ activity, avatarClassName, className }) => {
  return (
    <div className={cn(styles.container, className)}>
      {activity.assignee ? (
        activity.assignee.id === activity.user.id ? (
          <UserPreviewCard userId={activity.user.id}>
            <Avatar className={cn(styles.avatar, avatarClassName)} tooltip user={activity.user} />
          </UserPreviewCard>
        ) : (
          <div className={styles.differentAssigneeContainer}>
            <UserPreviewCard userId={activity.user.id}>
              <Avatar className={cn(styles.avatar, avatarClassName)} tooltip user={activity.user} />
            </UserPreviewCard>
            <FontAwesomeIcon className={styles.chevron} icon={faChevronRight} />
            <UserPreviewCard userId={activity.assignee.id}>
              <Avatar
                className={cn(styles.avatar, avatarClassName)}
                tooltip
                user={activity.assignee}
              />
            </UserPreviewCard>
          </div>
        )
      ) : (
        <Avatar className={styles.avatar} tooltip user={activity.user} />
      )}
    </div>
  );
};

export default ActivityAssignee;

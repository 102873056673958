import { call, put, select, takeEvery } from "redux-saga/effects";
import { geocodeAddress, reverseGeocodeAddress } from "./actions";
import { handleError } from "store/errors/actions";
import { callApi } from "store/api/callApi";
import Organization from "@mapmycustomers/shared/types/Organization";
import { GeocodeResult } from "@mapmycustomers/shared/types/base/Located";
import { getOrganizationId } from "store/iam";
import { convertLongLatToGeoPoint } from "util/geo/GeoService";

export function* onGeocodeAddress({ payload }: ReturnType<typeof geocodeAddress>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: GeocodeResult = yield callApi("geocodeAddress", orgId, payload.address);
    yield call(payload.callback, response);
  } catch (error) {
    if (payload.failureCallback) {
      yield call(payload.failureCallback);
    }
    yield put(handleError({ error }));
  }
}

export function* onReverseGeocodeAddress({ payload }: ReturnType<typeof reverseGeocodeAddress>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const result: GeocodeResult = yield callApi(
      "reverseGeocodeAddress",
      orgId,
      convertLongLatToGeoPoint(payload.coordinates)
    );
    yield call(payload.callback, result);
  } catch (error) {
    if (payload.failureCallback) {
      yield call(payload.failureCallback);
    }
    yield put(handleError({ error }));
  }
}

export function* locationSaga() {
  yield takeEvery(geocodeAddress, onGeocodeAddress);
  yield takeEvery(reverseGeocodeAddress, onReverseGeocodeAddress);
}

import React, { ComponentType, ReactNode } from "react";
import cn from "classnames";
import styles from "component/map/FeaturedMap/essentials/controls/Interactive.module.scss";

interface Props {
  children: ReactNode;
  className?: string;
  id?: string;
  tag?: ComponentType<{ className?: string; id?: string }> | keyof JSX.IntrinsicElements;
}

const Interactive: React.FC<Props> = ({ children, className, id, tag: Tag = "div" }) => (
  <Tag className={cn(styles.container, className)} id={id}>
    {children}
  </Tag>
);

export default Interactive;

import React, { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import FrequencyCriteria from "types/frequncy/FrequencyCriteria";
import { Alert } from "@mapmycustomers/ui";
import styles from "./FrequencyCriteriaField.module.scss";
import ButtonLink from "../../../../ButtonLink";
import { formatDate } from "../../../../../util/formatters";
import { RootState } from "../../../../../store/rootReducer";
import { getActivityTypes } from "../../../../../store/activity";
import { connect } from "react-redux";
import Chip from "../../../../Chip";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import ActivityTypeIcon from "../../../../activity/ActivityTypeIcon";
import Divider from "../../../../FormFields/components/Divider";
import Dropdown from "antd/es/dropdown";

const messages = defineMessages({
  doesntMeet: {
    id: "createActivityModal.frequencyCriteria.doesntMeet",
    defaultMessage: "Does not meet Frequency Criteria",
    description: "Create activity modal - Does not meet Frequency Criteria",
  },
  willNotMeet: {
    id: "createActivityModal.frequencyCriteria.willNotMeet",
    defaultMessage: "Will not meet frequency criteria",
    description: "Create activity modal - Will not meet Frequency Criteria",
  },
  doesMeet: {
    id: "createActivityModal.frequencyCriteria.doesMeet",
    defaultMessage: "Meets Frequency Criteria",
    description: "Create activity modal - Meets Frequency Criteria",
  },
  willMeet: {
    id: "createActivityModal.frequencyCriteria.willMeet",
    defaultMessage: "Will meet frequency criteria",
    description: "Create activity modal - Will meet Frequency Criteria",
  },
  doesntMeetDescription: {
    id: "createActivityModal.frequencyCriteria.doesntMeetDescription",
    defaultMessage:
      "This activity does not meet the required criteria, and will not change your status when logged. Please review the criteria below to adjust your activity.",
    description: "Create activity modal - Does not meet Frequency Criteria Description",
  },
  willNotMeetDescription: {
    id: "createActivityModal.frequencyCriteria.willNotMeetDescription",
    defaultMessage:
      "This activity does not meet the required criteria, and will not change your status if marked done on its scheduled date. Please review the criteria below to adjust your activity.",
    description: "Create activity modal - Will not meet Frequency Criteria Description",
  },
  doesMeetDescription: {
    id: "createActivityModal.frequencyCriteria.doesMeetDescription",
    defaultMessage:
      "This activity meets the required criteria and will be marked done upon creation.",
    description: "Create activity modal - Meets Frequency Criteria Description",
  },
  willMeetDescription: {
    id: "createActivityModal.frequencyCriteria.willMeetDescription",
    defaultMessage:
      "This activity meets the required criteria if it is marked done on or before its scheduled date.",
    description: "Create activity modal - Will meet Frequency Criteria Description",
  },
  reviewCriteria: {
    id: "createActivityModal.frequencyCriteria.reviewCriteria",
    defaultMessage: "Review Criteria",
    description: "Create activity modal - Review Criteria",
  },
  info1: {
    id: "createActivityModal.frequencyCriteria.info1",
    defaultMessage: "Activity must be <b>marked done</b> on or before:",
    description: "Create activity modal - info part1",
  },
  info2: {
    id: "createActivityModal.frequencyCriteria.info2",
    defaultMessage: "and the type is one of:",
    description: "Create activity modal - info part2",
  },
});

interface Props {
  activityTypes: ActivityType[];
  doesActivityMeetCriteria: boolean;
  frequencyCriteria?: FrequencyCriteria;
  isCompleted: boolean;
}

const FrequencyCriteriaField: React.FC<Props> = ({
  activityTypes,
  doesActivityMeetCriteria,
  frequencyCriteria,
  isCompleted,
}) => {
  const intl = useIntl();

  const allowedActivityTypes = useMemo(
    () =>
      frequencyCriteria?.activityTypesIds
        ? activityTypes.filter(({ id }) => frequencyCriteria?.activityTypesIds?.includes(id))
        : activityTypes,
    [activityTypes, frequencyCriteria?.activityTypesIds]
  );

  if (!frequencyCriteria) {
    return null;
  }
  return (
    <Alert
      action={
        <Dropdown
          overlay={
            <div className={styles.info}>
              <div className={styles.description}>
                {doesActivityMeetCriteria
                  ? isCompleted
                    ? intl.formatMessage(messages.doesMeetDescription)
                    : intl.formatMessage(messages.willMeetDescription)
                  : isCompleted
                  ? intl.formatMessage(messages.doesntMeetDescription)
                  : intl.formatMessage(messages.willNotMeetDescription)}
              </div>
              <Divider className={styles.divider} />
              <div>{intl.formatMessage(messages.info1, { b: (text) => <b>{text}</b> })}</div>
              <div className={styles.criteriaDate}>
                {formatDate(frequencyCriteria.dueDate, "PPPP")}
              </div>
              {frequencyCriteria.activityTypesIds &&
                frequencyCriteria.activityTypesIds.length > 0 && (
                  <>
                    <div>{intl.formatMessage(messages.info2)}</div>
                    <div className={styles.activityTypes}>
                      {allowedActivityTypes.map((type) => (
                        <Chip
                          icon={
                            <ActivityTypeIcon
                              activityType={type}
                              activityTypeIconClassName={styles.activityTypeIcon}
                            />
                          }
                        >
                          {type.name}
                        </Chip>
                      ))}
                    </div>
                  </>
                )}
            </div>
          }
          placement="bottomLeft"
          trigger={["hover"]}
        >
          <ButtonLink>{intl.formatMessage(messages.reviewCriteria)}</ButtonLink>
        </Dropdown>
      }
      className={styles.container}
      message={
        <div className={styles.message}>
          {doesActivityMeetCriteria
            ? isCompleted
              ? intl.formatMessage(messages.doesMeet)
              : intl.formatMessage(messages.willMeet)
            : isCompleted
            ? intl.formatMessage(messages.doesntMeet)
            : intl.formatMessage(messages.willNotMeet)}
        </div>
      }
      showIcon
      type={doesActivityMeetCriteria ? "success" : "warning"}
    />
  );
};

export const mapStateToProps = (state: RootState) => ({
  activityTypes: getActivityTypes(state),
});

export default connect(mapStateToProps)(FrequencyCriteriaField);

import { createReducer } from "typesafe-actions";
import { Actions, fetchDeals } from "./actions";
import { Deal } from "@mapmycustomers/shared/types/entity";

export interface DealListModalState {
  deals: Deal[];
  loading: boolean;
  total: number;
}

const initialState: DealListModalState = {
  deals: [],
  loading: false,
  total: 0,
};

const dealListModal = createReducer<DealListModalState, Actions>(initialState)
  .handleAction(fetchDeals.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchDeals.success, (state, { payload }) => ({
    ...state,
    loading: false,
    ...payload,
  }))
  .handleAction(fetchDeals.failure, (state) => ({
    ...state,
    loading: false,
  }));

export * from "./selectors";
export type DealListModalActions = Actions;
export default dealListModal;

enum SchemaFieldType {
  CUSTOM = "custom-field",
  DIVIDER = "divider",
  FILE = "file",
  GROUP = "group",
  NOTE = "note",
  STANDARD = "standard",
}

export enum SchemaFieldTypeFilter {
  ALL = "all",
  REQUIRED = "required",
}

export type SchemaFieldTypeValues = SchemaFieldTypeFilter | SchemaFieldType;

export const schemaFieldTypeOptions = [
  SchemaFieldType.STANDARD,
  SchemaFieldType.CUSTOM,
];

export const schemaFieldTypeFilterOptions = [
  SchemaFieldTypeFilter.ALL,
  SchemaFieldTypeFilter.REQUIRED,
  ...schemaFieldTypeOptions,
];

export default SchemaFieldType;

import Button from "antd/es/button";
import { IntlShape } from "react-intl";
import { RawFile } from "@mapmycustomers/shared/types/File";
import styles from "./CommonNotificationStyles.module.scss";

const getFileRemovedNotificationNode = (
  intl: IntlShape | undefined,
  file: RawFile,
  onUndoClick?: (file: RawFile) => void
) => (
  <div className={styles.container}>
    <span>
      {intl?.formatMessage(
        {
          id: "previewPane.notification.fileRemoved",
          defaultMessage: "{name} removed",
          description: "A notification about file removal in preview panes",
        },
        { name: file.name }
      ) ?? `${file.name} removed`}
    </span>
    {onUndoClick && (
      <Button onClick={() => onUndoClick(file)}>
        {intl?.formatMessage({
          id: "previewPane.notification.fileRemoved.undo",
          defaultMessage: "Undo",
          description: "Undo file removal button for the preview panes",
        }) ?? "Undo"}
      </Button>
    )}
  </div>
);

export default getFileRemovedNotificationNode;

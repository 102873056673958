import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import Input from "antd/es/input";
import Form, { Rule } from "antd/es/form";
import { TextField } from "@mapmycustomers/ui";
import ButtonLink from "component/ButtonLink";
import AutoCompleteAddress from "component/input/Address/AutoCompleteAddress";
import LocationViewMode from "enum/LocationViewMode";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import styles from "./AddressFormItem.module.scss";

interface Props {
  disabled?: boolean;
  required?: boolean;
  rules?: Rule[];
}

const AddressFormItem: React.FC<Props> = ({ disabled, required, rules }) => {
  const intl = useIntl();
  const form = useFormInstance();

  const handleChangeLocationViewMode = useCallback(() => {
    const locationViewMode: LocationViewMode =
      form.getFieldValue("locationViewMode") === LocationViewMode.ADDRESS
        ? LocationViewMode.COORDINATES
        : LocationViewMode.ADDRESS;

    form.setFieldsValue({
      locationViewMode,
    });
  }, [form]);

  const shouldUpdateItem = useCallback(
    (prevValues, curValues) =>
      prevValues.locationViewMode !== curValues.locationViewMode ||
      prevValues.latitude !== curValues.latitude ||
      prevValues.longitude !== curValues.longitude,
    []
  );

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdateItem}>
      {(form) => {
        const isLocationMode =
          form.getFieldValue("locationViewMode") === LocationViewMode.COORDINATES;

        return (
          <div className={styles.container}>
            <div className={styles.left}>
              <Form.Item hidden name="locationViewMode">
                <Input />
              </Form.Item>

              {!isLocationMode ? (
                <Form.Item
                  label={intl.formatMessage({
                    id: "createEntity.details.address",
                    defaultMessage: "Address",
                    description: "Company details address title",
                  })}
                  name="addressDetails"
                  required={required}
                  rules={rules}
                >
                  <AutoCompleteAddress className={styles.address} disabled={disabled} />
                </Form.Item>
              ) : (
                <div className={styles.coordinatesFields}>
                  <Form.Item
                    label={intl.formatMessage({
                      id: "createEntity.details.latitude",
                      defaultMessage: "Latitude",
                      description: "Latitude field label at Create Modal",
                    })}
                    name="latitude"
                    required={required}
                    rules={rules}
                  >
                    <TextField disabled={disabled} />
                  </Form.Item>

                  <Form.Item
                    label={intl.formatMessage({
                      id: "createEntity.details.longitude",
                      defaultMessage: "Longitude",
                      description: "Longitude field label at Create Modal",
                    })}
                    name="longitude"
                    required={required}
                    rules={rules}
                  >
                    <TextField disabled={disabled} />
                  </Form.Item>
                </div>
              )}
            </div>

            {!disabled && (
              <div className={styles.right}>
                <ButtonLink className={styles.modeBtn} onClick={handleChangeLocationViewMode}>
                  {isLocationMode
                    ? intl.formatMessage({
                        id: "createEntity.details.location.new.useAddress",
                        defaultMessage: "Set via street address",
                        description: "`Set location via street address` button at Create Modal",
                      })
                    : intl.formatMessage({
                        id: "createEntity.details.location.new.useCoordinates",
                        defaultMessage: "Set via coordinates",
                        description: "`Set location via coordinates` button at Create Modal",
                      })}
                </ButtonLink>
              </div>
            )}
          </div>
        );
      }}
    </Form.Item>
  );
};

export default AddressFormItem;

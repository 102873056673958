import { DependencyList, useEffect, useMemo } from "react";
import debounce from "lodash-es/debounce";
import useDynamicCallback from "./useDynamicCallback";

const useDebouncedCallback = (
  debounceOptions: Parameters<typeof debounce>,
  deps?: DependencyList
) => {
  const [callback, ...opts] = debounceOptions;
  const internalCallback = useDynamicCallback(callback); // this allows using debounced callback without nested useCallback
  const debouncedCallback = useMemo(
    () => debounce(internalCallback, ...opts),
    [...opts, ...(deps ?? [])] // eslint-disable-line
  );
  // TODO: check that the following works and cancel is called for the prev instance of debouncedCallback
  // cancel a pending invocation of the debounced function when dependencies change
  useEffect(
    () => () => debouncedCallback.cancel(),
    [debouncedCallback, ...(deps ?? [])] // eslint-disable-line
  );
  return debouncedCallback;
};

export default useDebouncedCallback;

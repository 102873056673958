import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Field, { FieldProperties } from "./Field";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import ActivityTypesFilter, {
  ACTIVITY_TYPES_FILTER_OPERATORS,
} from "util/filters/ActivityTypesFilter";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";

class ActivityTypeField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "activityType",
        filterInstances: {
          activityType: ActivityTypesFilter,
        },
        operators: [...ACTIVITY_TYPES_FILTER_OPERATORS],
      },
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.ACTIVITY_TYPE_FIELD,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.REQUIRED,
      ],
      platformName: "crmActivityTypeId",
      platformFilterName: "crmActivityTypeId",
      importProperties: { name: "crmActivityTypeName", required: true },
      type: FieldType.STRING,
      filterType: FieldType.ID,
      valueGetter: "crmActivityType",
      valueFormatter: (_, value: unknown) => (value as Pick<ActivityType, "id" | "name">).name,
      customGridProperties: {
        cellRenderer: "activityTypeCellRenderer",
      },
      ...data,
    });
  }
}

export default ActivityTypeField;

import React from "react";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import Color from "@mapmycustomers/shared/enum/Color";
import defaultFilterOption from "component/input/utils/defaultFilterOption";
import Select from "antd/es/select";
import cn from "classnames";
import { useIntl } from "react-intl";
import capitalize from "lodash-es/capitalize";
import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import EntityBadge from "component/EntityBadge";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import getHumanReadableDescriptionForOptionsFilter, {
  OptionFilterOperator,
} from "./getHumanReadableDescriptionForOptionsFilter";
import styles from "./ColorFilter.module.scss";

export const COLOR_FILTER_OPERATORS = [FilterOperator.IN_ANY];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every((item) => typeof item === "string") &&
  COLOR_FILTER_OPERATORS.includes(operator);

interface ColorFilterProps extends IFilterComponentProps {}

const ColorFilter: IFilterInstance = {
  doesSupportValue,
  doesSupportOption: (option: FilterOption) => option === FilterOption.AVAILABLE_COLORS,
  getHumanReadableDescription: (value: SimpleCondition, field: IField) =>
    doesSupportValue(value.value, value.operator)
      ? getHumanReadableDescriptionForOptionsFilter(
          field,
          value.operator as OptionFilterOperator,
          value.value.map(capitalize)
        )
      : undefined,
  getComponent:
    (): React.FC<ColorFilterProps> =>
    ({ className, onChange, options, value }) => {
      const intl = useIntl();

      const colors = (options?.availableColors ?? []) as Color[];

      return (
        <Select<Color[]>
          className={cn(styles.container, className)}
          filterOption={defaultFilterOption}
          mode="multiple"
          onChange={(colors: Color[]) => onChange?.({ ...value, value: colors })}
          placeholder={intl.formatMessage({
            id: "filters.color.select.placeholder",
            defaultMessage: "Click or type to select colors",
            description: "Placeholder displayed in ColorFilter's select field",
          })}
          value={Array.isArray(value.value) ? value.value : []}
        >
          {colors.map((color) => (
            <Select.Option key={color} label={color} value={color}>
              <EntityBadge entity={{ color, name: capitalize(color) }} />
            </Select.Option>
          ))}
        </Select>
      );
    },
};

export default ColorFilter;

import { ActionType, createAsyncAction } from "typesafe-actions";
import Person from "@mapmycustomers/shared/types/entity/Person";
import Company from "@mapmycustomers/shared/types/entity/Company";
import Deal from "@mapmycustomers/shared/types/entity/Deal";

export const filterCompanies = createAsyncAction(
  "entitySearch/filterCompanies/request",
  "entitySearch/filterCompanies/success",
  "entitySearch/filterCompanies/failure"
)<string, Company[], void>();

export const filterPeople = createAsyncAction(
  "entitySearch/filterPeople/request",
  "entitySearch/filterPeople/success",
  "entitySearch/filterPeople/failure"
)<string, Person[], void>();

export const filterDeals = createAsyncAction(
  "entitySearch/filterDeals/request",
  "entitySearch/filterDeals/success",
  "entitySearch/filterDeals/failure"
)<string, Deal[], void>();

export type Actions = ActionType<typeof filterCompanies | typeof filterPeople | typeof filterDeals>;

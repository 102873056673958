import { ckmeans, equalIntervalBreaks, quantile } from "simple-statistics";
import ClassificationMethod from "../types/ClassificationMethod";
import Bucket from "../types/Bucket";
import { color, scaleLinear } from "d3";
import { HexColor } from "@mapmycustomers/shared/types/AnyColor";
import Currency from "@mapmycustomers/shared/types/Currency";

const calculateBucketsForMethod = (
  classificationMethod: ClassificationMethod,
  bucketCount: number,
  currencyId: Currency["id"] | undefined,
  data: number[],
  theme: {
    startColor: HexColor;
    endColor: HexColor;
  }
) => {
  const colorFn = scaleLinear([0, bucketCount - 1], [theme.startColor, theme.endColor]);

  if (
    [ClassificationMethod.EQUAL_INTERVAL, ClassificationMethod.QUANTILE].includes(
      classificationMethod
    )
  ) {
    let breaks: number[] = [];
    if (classificationMethod === ClassificationMethod.EQUAL_INTERVAL) {
      breaks = equalIntervalBreaks(data, bucketCount);
      breaks.shift();
    } else {
      const quantiles = Array.from(
        { length: bucketCount - 1 },
        (_, i) => (i + 1) * (1 / bucketCount)
      );
      breaks = quantile(data, quantiles);
    }
    if (breaks.length > 0) {
      breaks.map((value) => Math.floor(value));

      const result: Bucket[] = breaks.reduce((result: Bucket[], item: number, index: number) => {
        const start = result.length > 0 ? breaks[index - 1] : 0;
        result.push({
          id: index,
          color: color(colorFn(index))?.formatHex() as HexColor,
          currencyId,
          range: [Math.floor(start), Math.floor(item)],
          visible: true,
        });
        return result;
      }, []);

      if (classificationMethod === ClassificationMethod.QUANTILE && breaks.length > 0) {
        result.push({
          id: breaks.length,
          color: color(colorFn(breaks.length))?.formatHex() as HexColor,
          currencyId,
          range: [Math.floor(breaks[breaks.length - 1])],
          visible: true,
        });
      }
      return result;
    }
    return [];
  } else {
    const buckets = ckmeans(data, bucketCount);
    const result: Bucket[] = buckets.reduce((result: Bucket[], bucket: number[], index: number) => {
      const start = bucket[0];
      const end = bucket.length > 1 ? bucket[bucket.length - 1] : undefined;
      result.push({
        id: index,
        color: color(colorFn(index))?.formatHex() as HexColor,
        currencyId,
        range: end ? [start, end] : [start],
        visible: true,
      });
      return result;
    }, []);
    return result;
  }
};

export default calculateBucketsForMethod;

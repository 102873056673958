import { AlgorithmInput, AlgorithmOptions, AlgorithmOutput } from "util/clustering/types/algorithm";
import Cluster from "../types/Cluster";
import AbstractAlgorithm from "./AbstractAlgorithm";
import { filterMarkersToPaddedViewport } from "util/clustering/utils";

export interface ViewportAlgorithmOptions extends AlgorithmOptions {
  /**
   * The number of pixels to extend beyond the viewport bounds when filtering
   * markers prior to clustering.
   */
  viewportPadding?: number;
}

/**
 * Abstract viewport algorithm proves a class to filter markers by a padded
 * viewport. This is a common optimization.
 *
 * @hidden
 */
export abstract class AbstractViewportAlgorithm<T> extends AbstractAlgorithm<T> {
  protected viewportPadding = 60;

  constructor({ viewportPadding = 60, ...options }: ViewportAlgorithmOptions) {
    super(options);
    this.viewportPadding = viewportPadding;
  }
  public calculate({ items, coordinateGetter, map }: AlgorithmInput<T>): AlgorithmOutput<T> {
    if ((map.getZoom() ?? 0) >= this.maxZoom) {
      return {
        clusters: this.noop({ items, coordinateGetter }),
        changed: false,
      };
    }

    return {
      clusters: this.cluster({
        items: filterMarkersToPaddedViewport(map, items, coordinateGetter, this.viewportPadding),
        coordinateGetter,
        map,
      }),
    };
  }
  protected abstract cluster({ items, map }: AlgorithmInput<T>): Cluster<T>[];
}

export default AbstractViewportAlgorithm;

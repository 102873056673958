import React, { ReactNode, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ButtonLink from "component/ButtonLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import styles from "./useGoogleAutoCompleteOptions.module.scss";

export const NO_RESULT_VALUE = "no_result";
export const MANUAL_ADDRESS_VALUE = "manual_address";

const useGoogleAutoCompleteOptions = (
  suggestions: google.maps.places.AutocompletePrediction[],
  showManual?: boolean
) => {
  const intl = useIntl();
  return useMemo(() => {
    const result: { label: ReactNode; value: string; disabled?: boolean }[] = suggestions.map(
      (item) => ({
        label: (
          <div className={styles.placeOption}>
            <FontAwesomeIcon className={styles.markerIcon} icon={faMapMarkerAlt} />
            <span className={styles.placeDescription}>{item.description}</span>
          </div>
        ),
        value: item.place_id,
      })
    );

    if (!suggestions.length) {
      result.push({
        value: NO_RESULT_VALUE,
        label: (
          <div className={styles.noResults}>
            <FormattedMessage
              id="autoComplete.address.noResult"
              defaultMessage="No results"
              description="Manual Address Button"
            />
          </div>
        ),
        disabled: true,
      });
    }

    if (showManual) {
      result.push({
        value: MANUAL_ADDRESS_VALUE,
        label: (
          <ButtonLink className={styles.enterManuallyButton}>
            {intl.formatMessage({
              id: "autoComplete.address.manualButton",
              defaultMessage: "Enter address manually",
              description: "Manual Address Button",
            })}
          </ButtonLink>
        ),
      });
    }

    return result;
  }, [intl, showManual, suggestions]);
};

export default useGoogleAutoCompleteOptions;

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import {
  AreaFilterCondition,
  CombinedCondition,
  FilterCondition,
  SimpleCondition,
} from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import {
  AgGridCombinedCondition,
  AgGridCondition,
  AgGridSimpleCondition,
  AreaFilterCondition as AgGridAreaCondition,
  EmptyCondition,
  GroupFilterCondition,
} from "../../types/viewModel/agGridModel/AgGridFilterModel";
import {
  PlatformCombinedCondition,
  PlatformFilterCondition,
  PlatformSimpleCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

export const isAgGridCombinedCondition = (
  condition: AgGridCondition
): condition is AgGridCombinedCondition<any, any> =>
  condition && "operator" in condition && "condition1" in condition && "condition2" in condition;

export const isAgGridSimpleCondition = (
  condition: AgGridCondition
): condition is AgGridSimpleCondition => !isAgGridCombinedCondition(condition);

export const isAgGridEmptyCondition = (condition: AgGridCondition): condition is EmptyCondition =>
  isAgGridSimpleCondition(condition) && condition.filterType === "emptiness";

export const isCombinedCondition = (condition: FilterCondition): condition is CombinedCondition =>
  "conditions" in condition;

export const isSimpleCondition = (condition: FilterCondition): condition is SimpleCondition =>
  !isCombinedCondition(condition);

export const isCombinedPlatformCondition = (
  condition: PlatformFilterCondition
): condition is PlatformCombinedCondition => "$and" in condition || "$or" in condition;

export const isSimplePlatformCondition = (
  condition: PlatformFilterCondition
): condition is PlatformSimpleCondition => !isCombinedPlatformCondition(condition);

export const isGroupFilterCondition = (
  condition: AgGridCondition
): condition is GroupFilterCondition =>
  condition && (condition as GroupFilterCondition).filterType === "groupsFilter";

export const isAgGridAreaFilterCondition = (
  condition: AgGridCondition
): condition is AgGridAreaCondition =>
  condition && (condition as AgGridAreaCondition).filterType === "areaFilter";

export const isAreaFilterCondition = (
  condition: SimpleCondition
): condition is AreaFilterCondition =>
  condition &&
  condition.operator === FilterOperator.IN_AREA &&
  condition.value &&
  typeof condition.value === "object" &&
  (("latitude" in condition.value &&
    "longitude" in condition.value &&
    "distanceInMeters" in condition.value) ||
    ("distanceInMeters" in condition.value && "entity" in condition.value));

import ElementAlignment from "@mapmycustomers/shared/enum/textEditor/ElementAlignment";
import { CSSProperties } from "react";

type AlignElementType =
  | ElementAlignment.ALIGN_LEFT
  | ElementAlignment.ALIGN_CENTER
  | ElementAlignment.ALIGN_RIGHT
  | ElementAlignment.ALIGN_JUSTIFY;

export const cssAlignMap: Record<AlignElementType, CSSProperties["textAlign"]> =
  {
    [ElementAlignment.ALIGN_LEFT]: "left",
    [ElementAlignment.ALIGN_CENTER]: "center",
    [ElementAlignment.ALIGN_RIGHT]: "right",
    [ElementAlignment.ALIGN_JUSTIFY]: "justify",
  };

export default AlignElementType;

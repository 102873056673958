import React from "react";
import IMapContext from "../types/IMapContext";

const MapContext = React.createContext<IMapContext>({
  // A bit hacky way, but we know that the map will be defined in a
  // MapContext.Provider. Don't want to make context value optional
  map: undefined as unknown as google.maps.Map,
  registerStylingFunction: () => {},
  unregisterStylingFunction: () => {},
});

export default MapContext;

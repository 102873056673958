import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { useWatch } from "antd/es/form/Form";
import { useMemo } from "react";
import {
  EntityType,
  EntityTypesSupportingFieldCustomization,
} from "@mapmycustomers/shared/types/entity";
import { isDefined } from "util/assert";
import getFieldModelByEntityType from "util/fieldModel/getByEntityType";
import FormLayout, {
  ChildLayout,
  LayoutSchemaField,
} from "@mapmycustomers/shared/types/layout/FormLayout";
import SchemaFieldType from "@mapmycustomers/shared/enum/SchemaFieldType";
import getChildLayout from "util/layout/getChildLayout";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";

const useSchema = (
  entityType: EntityTypesSupportingFieldCustomization,
  layout: FormLayout,
  filterFields: (schemaField: LayoutSchemaField) => boolean,
  funnelStages: Record<Funnel["id"], Stage[]>,
  isCreateForm?: boolean
): [
  actualSchema: LayoutSchemaField[],
  childLayout: ChildLayout | undefined,
  isVariant: boolean
] => {
  const form = useFormInstance();
  const stageId = useWatch("stageId", form);
  const crmActivityTypeId = useWatch("activityTypeId", form);

  const funnelIdString = Object.keys(funnelStages).find((funnelId) =>
    funnelStages[parseInt(funnelId) as Funnel["id"]].some(({ id }) => id === stageId)
  );

  return useMemo(() => {
    const childLayout = getChildLayout(
      layout,
      entityType === EntityType.DEAL && isDefined(funnelIdString) && isDefined(stageId)
        ? { funnelId: parseInt(funnelIdString), stageId }
        : entityType === EntityType.ACTIVITY && isDefined(crmActivityTypeId)
        ? { activityTypeId: crmActivityTypeId }
        : undefined
    );
    const schema = childLayout?.schema ?? layout.schema;
    const isVariant = !!childLayout;
    const fieldModel = getFieldModelByEntityType(entityType);

    return [
      schema.filter(filterFields).filter((schemaField) => {
        const field = fieldModel.getByPlatformName(schemaField.field);
        return (
          // interestingly, it is possible to have field in the layout, but archived
          !field?.isArchived &&
          (schemaField.fieldType === SchemaFieldType.DIVIDER ||
            // show only editable fields in create form (or non-editable too if they're required)
            // show all fields in edit form
            (isCreateForm && (field?.isEditable || schemaField.required)) ||
            (!isCreateForm && field?.isReadable))
        );
      }),
      childLayout,
      isVariant,
    ];
  }, [crmActivityTypeId, entityType, filterFields, funnelIdString, isCreateForm, layout, stageId]);
};

export default useSchema;

import { createReducer } from "typesafe-actions";
import {
  Actions,
  applyCalendarViewSettings,
  fetchCalendarActivities,
  fetchNearbyEntities,
  fetchOutOfCadenceEntities,
  findActivities,
  initializeCalendarView,
  moveResizeActivity,
  postponeActivity,
  updateActivityInList,
} from "./actions";
import { Actions as AppActions, initializeApp } from "store/app/actions";
import localSettings from "config/LocalSettings";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import activityFieldModel from "util/fieldModel/ActivityFieldModel";
import { Activity, MapEntity } from "@mapmycustomers/shared/types/entity";
import { updateActivity } from "component/createEditEntity/Activity/store/actions";
import { CreateEditActivityModalActions } from "component/createEditEntity/Activity/store";
import { CALENDAR_VIEW_STATE } from "./saga";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import CalendarViewState from "component/view/CalendarView/types/CalendarViewState";
import CalendarViewMode from "enum/CalendarViewMode";
import ActivitySuggestion from "@mapmycustomers/shared/types/entity/ActivitySuggestion";
import { getWeekStart } from "util/dates";
import { OutOfCadenceEntity } from "@mapmycustomers/shared/types/entity/activities/OutOfCadenceEntity";
import MapEntityWithActivity from "../type/MapEntityWithActivity";

const DEFAULT_CALENDAR_VIEW_STATE: CalendarViewState = {
  columns: [],
  filter: {
    startAt: {
      operator: FilterOperator.ON,
      value: getWeekStart(Date.now()),
    },
  },
  range: { startRow: 0, endRow: 9999 },
  selectedSavedFilterId: undefined,
  sort: [{ field: activityFieldModel.getByName("startAt")!, order: SortOrder.ASC }],
  viewAs: undefined,
  viewMode: CalendarViewMode.WEEK,
};

export interface CalendarState {
  activities: Activity[];
  loading?: boolean;
  mapEntities: MapEntityWithActivity[];
  nearbyEntities: MapEntity[];
  nearbyEntitiesLoading?: boolean;
  outOfCadenceEntities: OutOfCadenceEntity[];
  outOfCadenceEntitiesLoading?: boolean;
  postponeLoading?: boolean;
  searchResults: Activity[];
  searchResultsLoading?: boolean;
  suggestions: ActivitySuggestion[];
  toggleCompleteLoading?: boolean;
  totalFilteredRecords: number;
  totalRecords: number;
  viewState: CalendarViewState;
}

const initialState: CalendarState = {
  activities: [],
  loading: false,
  mapEntities: [],
  nearbyEntities: [],
  nearbyEntitiesLoading: false,
  outOfCadenceEntities: [],
  outOfCadenceEntitiesLoading: false,
  postponeLoading: false,
  searchResults: [],
  searchResultsLoading: false,
  suggestions: [],
  toggleCompleteLoading: false,
  totalFilteredRecords: 0,
  totalRecords: 0,
  viewState: DEFAULT_CALENDAR_VIEW_STATE,
};

const activityCalendar = createReducer<
  CalendarState,
  Actions | AppActions | CreateEditActivityModalActions
>(initialState)
  // TODO: change this to catch fetchCustomFields.success for activity entity type
  // to update view state every time custom fields are modified
  // load view settings after app initialization to ensure custom fields are fetched
  // OR not change, but add one more action
  .handleAction(initializeApp.success, (state) => ({
    ...state,
    viewState: {
      ...state.viewState,
      ...DEFAULT_CALENDAR_VIEW_STATE,
      ...localSettings.getViewSettings(
        CALENDAR_VIEW_STATE,
        activityFieldModel,
        DEFAULT_CALENDAR_VIEW_STATE
      ),
    },
  }))
  .handleAction(initializeCalendarView.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyCalendarViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      range: payload.range ?? state.viewState.range,
      sort: payload.sort ?? state.viewState.sort,
      filter: payload.filter ?? state.viewState.filter,
      // only update selectedSavedFilterId when it is explicitly present in a payload (even when it is `undefined`)
      selectedSavedFilterId:
        "selectedSavedFilterId" in payload
          ? payload.selectedSavedFilterId
          : state.viewState.selectedSavedFilterId,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
      viewMode: payload.viewMode ?? state.viewState.viewMode,
    },
  }))
  .handleAction(fetchCalendarActivities.request, (state, { payload: { updateOnly } }) => ({
    ...state,
    loading: !updateOnly,
  }))
  .handleAction(fetchCalendarActivities.success, (state, { payload }) => ({
    ...state,
    activities: payload.activities,
    mapEntities: payload.mapEntities,
    loading: false,
    suggestions: payload.suggestions,
    totalFilteredRecords: payload.totalFilteredRecords,
    totalRecords: payload.totalRecords,
  }))
  .handleAction(fetchCalendarActivities.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(updateActivity.success, (state, { payload: activity }) => {
    const activities = state.activities;
    const updatedActivityIndex = activities.findIndex(({ id }) => id === activity.id);
    if (updatedActivityIndex < 0) {
      return state;
    }
    return {
      ...state,
      activities: [
        ...activities.slice(0, updatedActivityIndex),
        activity,
        ...activities.slice(updatedActivityIndex + 1),
      ],
    };
  })
  .handleAction(updateActivityInList, (state, { payload }) => ({
    ...state,
    activities: state.activities.map((activity) =>
      activity.id === payload.id ? { ...activity, ...payload } : activity
    ),
    toggleCompleteLoading: false,
  }))
  .handleAction(postponeActivity.request, (state) => ({
    ...state,
    postponeLoading: true,
  }))
  .handleAction(postponeActivity.success, (state, { payload }) => ({
    ...state,
    activities: state.activities.map((activity) =>
      activity.id === payload.id ? { ...activity, ...payload } : activity
    ),
    postponeLoading: false,
  }))
  .handleAction(postponeActivity.failure, (state) => ({
    ...state,
    postponeLoading: false,
  }))
  .handleAction(moveResizeActivity.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(moveResizeActivity.success, (state, { payload }) => ({
    ...state,
    activities: state.activities.map((activity) =>
      activity.id === payload.id ? { ...activity, ...payload } : activity
    ),
    loading: false,
  }))
  .handleAction(moveResizeActivity.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(fetchNearbyEntities.request, (state) => ({
    ...state,
    nearbyEntitiesLoading: true,
  }))
  .handleAction(fetchNearbyEntities.success, (state, { payload }) => ({
    ...state,
    nearbyEntities: payload,
    nearbyEntitiesLoading: false,
  }))
  .handleAction(fetchNearbyEntities.failure, (state) => ({
    ...state,
    nearbyEntitiesLoading: false,
  }))
  .handleAction(fetchOutOfCadenceEntities.request, (state) => ({
    ...state,
    outOfCadenceEntitiesLoading: true,
  }))
  .handleAction(fetchOutOfCadenceEntities.success, (state, { payload }) => ({
    ...state,
    outOfCadenceEntities: payload,
    outOfCadenceEntitiesLoading: false,
  }))
  .handleAction(fetchOutOfCadenceEntities.failure, (state) => ({
    ...state,
    outOfCadenceEntitiesLoading: false,
  }))
  .handleAction(findActivities.request, (state) => ({
    ...state,
    searchResultsLoading: true,
  }))
  .handleAction(findActivities.success, (state, { payload }) => ({
    ...state,
    searchResults: payload,
    searchResultsLoading: false,
  }))
  .handleAction(findActivities.failure, (state) => ({
    ...state,
    searchResultsLoading: false,
  }));

export * from "./selectors";
export type CalendarActions = Actions;
export default activityCalendar;

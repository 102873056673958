import { combineReducers } from "redux";
import activityAnnotation, { ActivityAnnotationActions } from "./activity/ActivityAnnotation/store";
import activityInfoModal, { ActivityInfoModalActions } from "./activity/ActivityInfoModal/store";
import addToRouteModal, { AddToRouteModalActions } from "./AddToRouteModal/store";
import addNoteModal, { AddNoteModalActions } from "./createEditEntity/AddNoteModal/store";
import companyRecord, { CompanyRecordActions } from "./preview/CompanyRecordPane/store/";
import createCompanyModal, {
  CreateCompanyModalActions,
} from "./createEditEntity/CreateCompanyModal/store";
import createEditActivityModal, {
  CreateEditActivityModalActions,
} from "./createEditEntity/Activity/store";
import createDealModal, { CreateDealModalActions } from "./createEditEntity/CreateDealModal/store";
import createPersonModal, {
  CreatePersonModalActions,
} from "./createEditEntity/CreatePersonModal/store";
import dataView, { DataViewActions } from "./DataView/store";
import dealListModal, { DealListModalActions } from "./PreviewDealsListModal/store";
import dealRecord, { DealRecordActions } from "./preview/DealRecordPane/store/";
import editStageModal, { EditStageModalActions } from "./createEditEntity/EditStageModal/store";
import entityAssociations, { EntityAssociationsActions } from "./associations/store";
import entityHistory, { EntityHistoryActions } from "./EntityHistoryModal/store";
import { EntityAnnotationActions } from "./EntityAnnotation/store/index";
import entitySearch, { EntitySearchActions } from "./input/EntitySearch/store";
import filePreview, { FilePreviewActions } from "./FilePreview/store";
import multiPin from "./map/MultiPinRecordListDrawer/store";
import navigationBar, { NavigationBarActions } from "./NavigationBar/store";
import personRecord, { PersonRecordActions } from "./preview/PersonRecordPane/store/";
import postCreationModal, { PostCreationModalActions } from "component/PostCreationModal/store";
import selectBar, { SelectBarActions } from "./view/SelectBar/store";
import { MultiPinRecordsActions } from "./map/MultiPinRecordListDrawer/store/actions";
import userLocationRequest from "./Layout/components/UserLocationRequest/store";
import { UserLocationRequestActions } from "./Layout/components/UserLocationRequest/actions";
import userPreviewCard, { UserPreviewCardActions } from "./UserPreviewCard/store";

const componentReducer = combineReducers({
  activityAnnotation,
  activityInfoModal,
  addNoteModal,
  addToRouteModal,
  companyRecord,
  createCompanyModal,
  createDealModal,
  createEditActivityModal,
  createPersonModal,
  dataView,
  dealListModal,
  dealRecord,
  editStageModal,
  entityAssociations,
  entityHistory,
  entitySearch,
  filePreview,
  multiPin,
  navigationBar,
  personRecord,
  postCreationModal,
  selectBar,
  userLocationRequest,
  userPreviewCard,
});

export type ComponentActions =
  | ActivityAnnotationActions
  | ActivityInfoModalActions
  | AddNoteModalActions
  | AddToRouteModalActions
  | CompanyRecordActions
  | CreateCompanyModalActions
  | CreateDealModalActions
  | CreateEditActivityModalActions
  | CreatePersonModalActions
  | DataViewActions
  | DealListModalActions
  | DealRecordActions
  | EditStageModalActions
  | EntityAnnotationActions
  | EntityAssociationsActions
  | EntityHistoryActions
  | EntitySearchActions
  | FilePreviewActions
  | MultiPinRecordsActions
  | NavigationBarActions
  | PersonRecordActions
  | PostCreationModalActions
  | SelectBarActions
  | UserLocationRequestActions
  | UserPreviewCardActions;

export default componentReducer;

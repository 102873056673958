import CustomFieldDataType from "@mapmycustomers/shared/enum/CustomFieldDataType";

import addressImage from "assets/customFields/cf-address.svg";
import dateImage from "assets/customFields/cf-date.svg";
import monetaryImage from "assets/customFields/cf-monetary.svg";
import multiImage from "assets/customFields/cf-multi.svg";
import numericImage from "assets/customFields/cf-numeric.svg";
import phoneImage from "assets/customFields/cf-phone.svg";
import singleImage from "assets/customFields/cf-single.svg";
import textImage from "assets/customFields/cf-text.svg";
import timeImage from "assets/customFields/cf-time.svg";

import addressDescriptionImage from "assets/customFields/preview/address.jpg";
import dateDescriptionImage from "assets/customFields/preview/date.jpg";
import monetaryDescriptionImage from "assets/customFields/preview/monetary.jpg";
import multiDescriptionImage from "assets/customFields/preview/multi-select.jpg";
import numericDescriptionImage from "assets/customFields/preview/numeric.jpg";
import phoneDescriptionImage from "assets/customFields/preview/phone.jpg";
import singleDescriptionImage from "assets/customFields/preview/single-select.jpg";
import textDescriptionImage from "assets/customFields/preview/text.jpg";
import timeDescriptionImage from "assets/customFields/preview/time.jpg";
import largeTextDescriptionImage from "assets/customFields/preview/large-text.jpg";
import { defineMessages, IntlShape } from "react-intl";

const customFieldDataTypeToImage: Record<CustomFieldDataType, string | undefined> = {
  [CustomFieldDataType.ADDRESS]: addressImage,
  [CustomFieldDataType.BOOLEAN]: undefined,
  [CustomFieldDataType.DATE]: dateImage,
  [CustomFieldDataType.DECIMAL]: numericImage,
  [CustomFieldDataType.INTEGER]: numericImage,
  [CustomFieldDataType.LARGE_TEXT]: textImage,
  [CustomFieldDataType.MONETARY]: monetaryImage,
  [CustomFieldDataType.MULTI_OPTION]: multiImage,
  [CustomFieldDataType.PHONE]: phoneImage,
  [CustomFieldDataType.SINGLE_OPTION]: singleImage,
  [CustomFieldDataType.TEXT]: textImage,
  [CustomFieldDataType.TIME]: timeImage,
};

const customFieldDataTypeToPreviewImage: Record<CustomFieldDataType, string | undefined> = {
  [CustomFieldDataType.ADDRESS]: addressDescriptionImage,
  [CustomFieldDataType.BOOLEAN]: undefined,
  [CustomFieldDataType.DATE]: dateDescriptionImage,
  [CustomFieldDataType.DECIMAL]: numericDescriptionImage,
  [CustomFieldDataType.INTEGER]: numericDescriptionImage,
  [CustomFieldDataType.LARGE_TEXT]: largeTextDescriptionImage,
  [CustomFieldDataType.MONETARY]: monetaryDescriptionImage,
  [CustomFieldDataType.MULTI_OPTION]: multiDescriptionImage,
  [CustomFieldDataType.PHONE]: phoneDescriptionImage,
  [CustomFieldDataType.SINGLE_OPTION]: singleDescriptionImage,
  [CustomFieldDataType.TEXT]: textDescriptionImage,
  [CustomFieldDataType.TIME]: timeDescriptionImage,
};

export const getCustomFieldDataTypeImage = (type: CustomFieldDataType): string | undefined =>
  customFieldDataTypeToImage[type];

export const getCustomFieldDataTypePreviewImage = (type: CustomFieldDataType): string | undefined =>
  customFieldDataTypeToPreviewImage[type];

const messages = defineMessages({
  [CustomFieldDataType.ADDRESS]: {
    id: "customFieldDataType.address",
    description: "Name of custom field data type - address",
    defaultMessage: "Address",
  },
  [CustomFieldDataType.BOOLEAN]: {
    id: "customFieldDataType.boolean",
    description: "Name of custom field data type - boolean",
    defaultMessage: "Boolean",
  },
  [CustomFieldDataType.DATE]: {
    id: "customFieldDataType.date",
    description: "Name of custom field data type - date",
    defaultMessage: "Date",
  },
  [CustomFieldDataType.DECIMAL]: {
    id: "customFieldDataType.decimal",
    description: "Name of custom field data type - decimal",
    defaultMessage: "Numeric",
  },
  [CustomFieldDataType.INTEGER]: {
    id: "customFieldDataType.integer",
    description: "Name of custom field data type - integer",
    defaultMessage: "Integer",
  },
  [CustomFieldDataType.LARGE_TEXT]: {
    id: "customFieldDataType.largeText",
    description: "Name of custom field data type - large text",
    defaultMessage: "Large text",
  },
  [CustomFieldDataType.MONETARY]: {
    id: "customFieldDataType.monetary",
    description: "Name of custom field data type - monetary",
    defaultMessage: "Monetary",
  },
  [CustomFieldDataType.MULTI_OPTION]: {
    id: "customFieldDataType.multiOption",
    description: "Name of custom field data type - multi option",
    defaultMessage: "Multi option",
  },
  [CustomFieldDataType.PHONE]: {
    id: "customFieldDataType.phone",
    description: "Name of custom field data type - phone",
    defaultMessage: "Phone",
  },
  [CustomFieldDataType.SINGLE_OPTION]: {
    id: "customFieldDataType.singleOption",
    description: "Name of custom field data type - single option",
    defaultMessage: "Single option",
  },
  [CustomFieldDataType.TEXT]: {
    id: "customFieldDataType.text",
    description: "Name of custom field data type - text",
    defaultMessage: "Text",
  },
  [CustomFieldDataType.TIME]: {
    id: "customFieldDataType.time",
    description: "Name of custom field data type - time",
    defaultMessage: "Time",
  },
});

const descriptionMessages = defineMessages({
  [CustomFieldDataType.ADDRESS]: {
    id: "customFieldDataType.address.description",
    description: "Description of custom field data type - address",
    defaultMessage:
      "Address fields allow you to <b>enter a location</b> using Google autocomplete, manual entry or via coordinate pairs",
  },
  [CustomFieldDataType.BOOLEAN]: {
    id: "customFieldDataType.boolean.description",
    description: "Description of custom field data type - boolean",
    defaultMessage: "Boolean",
  },
  [CustomFieldDataType.DATE]: {
    id: "customFieldDataType.date.description",
    description: "Description of custom field data type - date",
    defaultMessage: "Date fields allow you to <b>pick a single date</b> from a dropdown calendar",
  },
  [CustomFieldDataType.INTEGER]: {
    id: "customFieldDataType.integer.description",
    description: "Description of custom field data type - integer",
    defaultMessage: "Decimal",
  },
  [CustomFieldDataType.DECIMAL]: {
    id: "customFieldDataType.decimal.description",
    description: "Description of custom field data type - decimal",
    defaultMessage:
      "Numeric fields allow you to enter up to <b>255 numerals (decimals allowed)</b> in a fixed-height field",
  },
  [CustomFieldDataType.LARGE_TEXT]: {
    id: "customFieldDataType.largeText.description",
    description: "Description of custom field data type - large text",
    defaultMessage:
      "Large text fields allow you to enter up to <b>4000 characters</b> in an expandable field",
  },
  [CustomFieldDataType.MONETARY]: {
    id: "customFieldDataType.monetary.description",
    description: "Description of custom field data type - monetary",
    defaultMessage:
      "Monetary fields allow you to select the currency and the number amount in a fixed-height field",
  },
  [CustomFieldDataType.MULTI_OPTION]: {
    id: "customFieldDataType.multiOption.description",
    description: "Description of custom field data type - multi option",
    defaultMessage:
      "Multi-option fields allow you to create several options, and your team can <b>pick multiple from the list</b>",
  },
  [CustomFieldDataType.PHONE]: {
    id: "customFieldDataType.phone.description",
    description: "Description of custom field data type - phone",
    defaultMessage:
      "Phone fields allow you to enter up to <b>255 characters</b> in a fixed-height field",
  },
  [CustomFieldDataType.SINGLE_OPTION]: {
    id: "customFieldDataType.singleOption.description",
    description: "Description of custom field data type - single option",
    defaultMessage:
      "Single-option fields allow you to create several options, but your team will only be able to <b>pick one</b>",
  },
  [CustomFieldDataType.TEXT]: {
    id: "customFieldDataType.text.description",
    description: "Description of custom field data type - text",
    defaultMessage:
      "Text fields allow you to enter up to <b>255 characters</b> in a fixed-height field",
  },
  [CustomFieldDataType.TIME]: {
    id: "customFieldDataType.time.description",
    description: "Description of custom field data type - time",
    defaultMessage:
      "Time fields allow you to <b>pick a single time</b> from a dropdown time picker",
  },
});

export const getCustomFieldDataTypeDisplayName = (intl: IntlShape, type: CustomFieldDataType) =>
  intl.formatMessage(messages[type]);

export const getCustomFieldDataTypeDescription = (intl: IntlShape, type: CustomFieldDataType) =>
  intl.formatMessage(descriptionMessages[type], { b: (text) => <b>{text}</b> });

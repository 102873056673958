import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import styles from "./NextActivities.module.scss";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { RootState } from "store/rootReducer";
import { getActivityTypes } from "store/activity";
import { connect } from "react-redux";
import ActivityTypeIcon from "component/activity/ActivityTypeIcon";
import ListWithOverflowItem from "../../../../../ListWithOverflowItem";
import Chip from "../../../../../Chip";
import Tooltip from "antd/es/tooltip";

interface Props {
  activityTypeIds?: ActivityType["id"][];
  activityTypes: ActivityType[];
}

const EntityTypes: React.FC<Props> = ({ activityTypeIds, activityTypes }) => {
  const intl = useIntl();

  const getTypeChip = useCallback(
    (type: ActivityType) => (
      <Chip
        className={styles.activityType}
        icon={<ActivityTypeIcon className={styles.activityTypeIcon} activityType={type} />}
        key={type.id}
        type="light"
      >
        <span>{type.name}</span>
      </Chip>
    ),
    []
  );

  const showedActivityTypes: ActivityType[] = useMemo(
    () =>
      activityTypeIds
        ? [...activityTypes.filter(({ id }) => activityTypeIds.includes(id))].sort((a, b) =>
            a.name.localeCompare(b.name)
          )
        : [],
    [activityTypeIds, activityTypes]
  );

  const items = useMemo(
    () => [
      <span>
        {intl.formatMessage({
          id: "frequency.frequencyModal.activities.next.suggestion.typeList",
          defaultMessage: "Schedule a",
          description:
            "frequency component - frequency modal - next activities - suggestion type list",
        })}
      </span>,
      ...showedActivityTypes.map(getTypeChip),
    ],
    [intl, getTypeChip, showedActivityTypes]
  );

  const activityTypeOverflowRender = useCallback(
    (count: number) => (
      <Tooltip
        title={
          <div className={styles.overflowTooltip}>
            {showedActivityTypes.slice(showedActivityTypes.length - count).map(getTypeChip)}
          </div>
        }
      >
        <div className={styles.activityTypeOverflow}>
          {intl.formatMessage(
            {
              id: "frequency.frequencyModal.activities.next.activityTypeOverflow.text",
              defaultMessage: "or + {count} more",
              description:
                "frequency component - frequency modal - next activities - activity type overflow - count",
            },
            { count }
          )}
        </div>
      </Tooltip>
    ),
    [getTypeChip, intl, showedActivityTypes]
  );

  return (
    <div>
      {activityTypeIds?.length ? (
        <ListWithOverflowItem
          className={styles.activityTypeList}
          overflowRender={activityTypeOverflowRender}
        >
          {items}
        </ListWithOverflowItem>
      ) : (
        <div>
          {intl.formatMessage({
            id: "frequency.frequencyModal.activities.next.suggestion.anyActivity",
            defaultMessage: "Schedule any activity",
            description: "frequency component - frequency modal - next activities - any activity",
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  activityTypes: getActivityTypes(state),
});

export default connect(mapStateToProps)(EntityTypes);

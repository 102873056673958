import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { Flow as JsFlow } from "@frigade/js";
import {
  Flow,
  FlowType,
  FrigadeAnnouncement,
  FrigadeBanner,
  FrigadeChecklist,
  FrigadeForm,
  FrigadeNPSSurvey,
  FrigadeTour,
  StepData,
  useFlowOpens,
  useFlows,
  useUser,
} from "@frigade/react";
import { RootState } from "store/rootReducer";
import { getMe } from "store/iam";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import configService from "config/ConfigService";
import { ErrorBoundary } from "@sentry/react";
import { userDisplayName } from "util/formatters";
import Iam from "types/Iam";
import cn from "classnames";
import colors from "styles/_colors.module.scss";
import authService from "store/auth/AuthService";
import { useHistory } from "react-router-dom";
import { getLegacyAppUrl } from "util/appUrl";
import { History } from "history";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import frigadeService from "util/frigade/frigadeService";
import useRerenderer from "@mapmycustomers/shared/util/hook/useRerenderer";
import { showRandomRecord } from "store/entityView/actions";
import styles from "./FrigadeRouter.module.scss";

const handleLink = (
  history: History,
  link: string | undefined,
  legacyLink: string | undefined,
  target: string | undefined
) => {
  if (link) {
    if (target !== "_blank") {
      history.push(link);
    } else {
      window.open(history.createHref({ pathname: link }), "_blank");
    }
  } else if (legacyLink) {
    const baseOldAppUrl = configService.getBaseOldAppUrl();
    const token = authService.getToken();
    const href = getLegacyAppUrl(baseOldAppUrl, token!, legacyLink);
    if (target !== "_blank") {
      window.location.href = href;
    } else {
      window.open(href, "_blank");
    }
  }
};

const initializedSteps: Record<StepData["id"], boolean> = {};

interface Props {
  me: Iam;
  onShowRandomRecord?: () => void;
}

const FrigadeRouter: React.FC<Props> = ({ me, onShowRandomRecord }) => {
  const location = useLocation();

  const visibility = frigadeService.flowsVisibility;
  const rerender = useRerenderer();
  useEffect(() => {
    frigadeService.subscribeOnVisibilityChanges(rerender);
    return () => {
      frigadeService.unsubscribeFromVisibilityChanges(rerender);
    };
  }, [rerender]);

  const [activeFlow, setActiveFlow] = useState<Flow["slug"] | undefined>(() => {
    const visibleFlow = Object.keys(visibility).find((key) => visibility[key]);
    if (visibleFlow) {
      return visibleFlow as Flow["slug"];
    }
  });
  console.debug("frigade: active flow", activeFlow);

  const {
    getAllFlows,
    getFlow,
    getCurrentStepIndex,
    getStepStatus,
    getFlowSteps,
    getFlowStatus,
    markStepCompleted,
    markStepStarted,
    refresh,
    isFlowAvailableToUser,
    markFlowNotStarted,
  } = useFlows();

  frigadeService.reactReload = refresh;

  const { hasOpenModals } = useFlowOpens();

  const flows = getAllFlows();

  const initializeStep = useCallback(
    (step: StepData | undefined, flow: Flow) => {
      if (!step) {
        return;
      }

      if (initializedSteps[step.id]) {
        return;
      }
      initializedSteps[step.id] = true;

      // marking it unprocessed, so that user can open this step again later,
      // e.g. when they restart the entire tooltip flow
      setTimeout(() => {
        initializedSteps[step.id] = false;
      }, 1000);

      console.group("frigade: initializing current step", step, "in flow", flow);

      if (step.props?.center !== undefined) {
        console.debug(
          "frigade: setCentered to the next flow's current step",
          step.props?.center ?? false
        );
      }
      setCentered(step.props?.center ?? false);

      if (step && step.props?.waitForEventOn && step.props?.eventType) {
        // if we don't subscribe via timeout, this listener can be triggered via previous step's
        // click, if both steps are about the same or nested elements
        setTimeout(() => {
          const element: HTMLElement = document.querySelector(step.props.waitForEventOn);
          console.debug(
            "frigade: waiting for event",
            step.props.eventType,
            " on ",
            element,
            `(${step.props.waitForEventOn})`
          );
          element?.addEventListener(
            step.props.eventType,
            async () => {
              console.debug("frigade: event happened", step!.props.waitForEventOn, flow);
              console.debug("frigade: calling step.complete for step", step);
              await markStepCompleted(flow.slug, step!.id);

              if (step.props?.switchToFlow) {
                console.debug(
                  `frigade: switchToFlow found, switching from ${flow.slug} to ${step.props.switchToFlow}`
                );
                frigadeService.hideFlow(flow.slug);
                if (step.props?.startFromTheBeginning) {
                  await markFlowNotStarted(step.props.switchToFlow);
                }
                frigadeService.showFlow(step.props.switchToFlow);
                setActiveFlow(step.props.switchToFlow);

                const targetFlow = getFlow(step.props.switchToFlow);
                if (targetFlow) {
                  const steps = getFlowSteps(targetFlow.slug);
                  const currentStepIndex = getCurrentStepIndex(targetFlow.slug);
                  initializeStep(steps[currentStepIndex], targetFlow);
                }
              } else {
                const flowSteps = getFlowSteps(flow.slug);
                const currentStepIndex = flowSteps.findIndex(({ id }) => id === step.id);
                const stepAfter = flowSteps[currentStepIndex + 1];
                if (stepAfter) {
                  console.debug(
                    "frigade: next step found, calling stepAfter.start() for step",
                    stepAfter
                  );
                  await markStepStarted(flow.slug, stepAfter.id);
                  initializeStep(stepAfter, flow);
                }
              }
            },
            { once: true }
          );
        }, 100);
      }

      console.groupEnd();
    },
    [
      getCurrentStepIndex,
      getFlow,
      getFlowSteps,
      markStepCompleted,
      markStepStarted,
      markFlowNotStarted,
    ]
  );

  const [centered, setCentered] = useState(false);

  const currentStepIndex = activeFlow ? getCurrentStepIndex(activeFlow) : -1;
  const currentStep =
    activeFlow && currentStepIndex >= 0 ? getFlowSteps(activeFlow)[currentStepIndex] : undefined;

  const currentStepStatus =
    activeFlow && currentStep ? getStepStatus(activeFlow, currentStep.id) : undefined;

  console.debug(
    "frigade: current step: ",
    currentStepIndex,
    currentStep,
    "status=",
    currentStepStatus,
    "flow=",
    activeFlow
  );

  const callInitialize = useDynamicCallback((step: StepData, flowId: Flow["slug"]) => {
    const flow = getFlow(flowId);
    if (flow) {
      initializeStep(step, flow);
    }
  });

  useEffect(
    () => {
      if (currentStepStatus === "STARTED_STEP") {
        callInitialize(currentStep, activeFlow);
      }
    },
    // We don't want to include currentStep here, since it's changed on every re-render
    // But we're pretty sure it will be a correct one even if not in deps, because
    // it will be updated at least when the status changes to STARTED
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFlow, currentStep?.id, currentStepStatus, callInitialize]
  );

  const frigade = frigadeService.frigade!;

  const { addPropertiesToUser, trackEventForUser } = useUser();
  frigadeService.trackFn = trackEventForUser;

  useEffect(() => {
    const listener = () => {
      console.debug("frigade: gained focus again");
      addPropertiesToUser({ currentRoute: window.location.pathname });
    };
    document.addEventListener("visibilitychange", listener);
    return () => {
      document.removeEventListener("visibilitychange", listener);
    };
  }, [addPropertiesToUser]);

  useEffect(
    () => {
      console.debug("frigade: user changed, name=", userDisplayName(me));
      addPropertiesToUser({
        currentRoute: location.pathname,
        orgId: me.organization.id,
        name: userDisplayName(me),
        firstName: (me.fullName ?? "").split(/\s+/)[0] ?? me.username,
        roleKey: me.role.key,
        leadGen: me.organization.addOnServices.leadGen,
      }).then(() => {
        refresh();
      });
    },
    // We use me in userDisplayName, but we listed all fields we actually use from the me var
    // So deps are full enough
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addPropertiesToUser, location.pathname, me.organization.id, me.fullName, me.username]
  );

  const stepCompleteProcessed = useRef<Record<StepData["id"], boolean>>({});
  const handleCompleteStep = useDynamicCallback(
    (flowId: Flow["slug"]) => async (step: StepData, index: number, nextStep?: StepData) => {
      if (stepCompleteProcessed.current[step.id]) {
        return true;
      }
      stepCompleteProcessed.current[step.id] = true;

      // marking it unprocessed, so that user can click on the button again
      setTimeout(() => {
        stepCompleteProcessed.current[step.id] = false;
      }, 1000);

      const currentFlow = getFlow(flowId);
      console.debug(
        `frigade: onStepCompletion: flow ${flowId}, step=`,
        step,
        "nextStep: ",
        nextStep
      );

      if (step.props?.click) {
        const element = document.querySelector(step.props.click);
        setTimeout(() => element.click(), 0);
      }

      if (step.props?.openRandomRecord) {
        onShowRandomRecord?.();
      }

      if (step.props?.switchToFlow) {
        console.debug(
          `frigade: switchToFlow found, switching from ${flowId} to ${step.props.switchToFlow}`
        );
        frigadeService.hideFlow("" + flowId);
        if (step.props?.startFromTheBeginning) {
          await markFlowNotStarted(step.props.switchToFlow);
        }
        frigadeService.showFlow(step.props.switchToFlow);
        setActiveFlow(step.props.switchToFlow);

        const targetFlow = getFlow(step.props.switchToFlow);
        if (targetFlow) {
          const currentStepIndex = getCurrentStepIndex(targetFlow.slug);
          const nextStep = getFlowSteps(targetFlow.slug)[currentStepIndex];
          initializeStep(nextStep, targetFlow);
        }
      } else {
        const steps = getFlowSteps(currentFlow.slug);
        const nextFlowStep = nextStep ? steps.find(({ id }) => id === nextStep.id) : undefined;
        initializeStep(nextFlowStep, currentFlow);
      }
      return true;
    }
  );

  useEffect(() => {
    const callback = (flow: JsFlow, previousFlow?: JsFlow) => {
      const stepBefore = previousFlow?.getCurrentStep();
      const stepAfter = flow.getCurrentStep();

      if (!stepBefore?.$state.completed && stepAfter.$state.completed) {
        console.debug(`fridage: State: step completed (${stepAfter.id}), calling refresh`);
        refresh();
      }

      console.debug(`fridage: onStateChange, flowId ${flow.id}:`, stepBefore, " -> ", stepAfter);
    };

    frigade.onStateChange(callback);
    return () => {
      frigade.removeStateChangeHandler(callback);
    };
  }, [frigade, refresh]);

  const history = useHistory();
  const handleButtonClick = useCallback(
    (flowId: Flow["slug"], isAnnouncement?: boolean) =>
      (
        step: StepData,
        index?: number,
        cta?: "primary" | "secondary" | "link" | "back" | "collapse" | "expand"
      ) => {
        const props = step.props;

        let result = false;

        console.debug("frigade: handle button click step=", step, cta, isAnnouncement);
        if (cta === "primary" && (props?.primaryLink || props?.primaryLegacyLink)) {
          handleLink(history, props.primaryLink, props.primaryLegacyLink, props.primaryTarget);
          if (props.primaryLinkCompleteAfterClick) {
            markStepCompleted(flowId, step.id);
          }
        } else if (cta === "secondary" && (props?.secondaryLink || props?.secondaryLegacyLink)) {
          handleLink(
            history,
            props.secondaryLink,
            props.secondaryLegacyLink,
            props.secondaryTarget
          );
          if (props.secondaryLinkCompleteAfterClick) {
            markStepCompleted(flowId, step.id);
          }
        }

        if (isAnnouncement) {
          // should have the nextStep data too, but announcements usually consist of one step only,
          // so this should be ok
          handleCompleteStep(flowId)(step, index);
          result = true;
        }

        return result;
      },
    [history, markStepCompleted, handleCompleteStep]
  );

  const appearance = useMemo(
    () => ({
      colorPrimary: colors.primary100,
      colorBackground: colors.white,
      colorText: colors.slate,
      colorTextOnPrimaryBackground: colors.slate,
      colorTextSecondary: colors.softSlate,
      fontSize: "0.875rem",
      styleOverrides: {
        checklistTitle: {
          fontSize: "1.25rem",
          fontWeight: "600",
          lineHeight: "1.75",
        },
        checklistSubtitle: {
          color: colors.softSlate,
          fontWeight: "normal",
        },
        checklistStepSubtitle: {
          color: colors.softSlate,
        },
        checklistStepImage: {
          "max-height": "341px",
          "max-width": "100%",
          width: "auto",
          display: "block",
          margin: "0 auto 1rem",
        },
        button: cn("ant-btn", "ant-btn-lg"),
        buttonSecondary: cn("ant-btn", "ant-btn-lg"),
        ctaContainer: {
          gap: "0.5rem",
        },
      },
    }),
    []
  );

  const customVariables = useMemo(
    () => ({
      name: userDisplayName(me),
      firstName: (me.fullName ?? "").split(/\s+/)[0] ?? me.username,
    }),
    [me]
  );

  const handleSetVisible = useCallback(
    (flowId: Flow["slug"]) => (visible: boolean) => {
      console.debug("frigade: setVisible", visible, "called for flow", flowId);
      if (visible) {
        frigadeService.showFlow(flowId);
        // markFlowNotStarted(flowId);
      } else {
        frigadeService.hideFlow(flowId);
      }
      // setOpenFlowState(flowId, visible);
    },
    []
  );

  const handleDismiss = useCallback((flowId: Flow["slug"]) => {
    console.debug("frigade: onDismiss called for flow", flowId);
    frigadeService.hideFlow(flowId);
  }, []);

  // Potential problems:
  // 1. using two frigade instances
  // 2. using empty default visibilities
  // 3. multiple flows rendered at the same time?

  console.debug("frigade: flow visibilities", visibility);
  const availableFlows = flows.filter(
    (flow) => flow.type !== FlowType.CUSTOM && isFlowAvailableToUser(flow.slug)
  );
  console.debug(
    "frigade: flows=",
    flows,
    "availableFlows=",
    availableFlows,
    availableFlows.map(({ slug }) => hasOpenModals(slug))
  );

  return (
    <ErrorBoundary>
      {availableFlows.map((flow) => {
        let visible = visibility[flow.slug] ?? getFlowStatus(flow.slug) !== "COMPLETED_FLOW";
        if (flow.data) {
          try {
            const json = JSON.parse(flow.data);
            if (json?.props?.trigger === "manual") {
              visible = visibility[flow.slug];
            }
          } catch {
            // do nothing
          }
        }

        switch (flow.type) {
          case FlowType.CHECKLIST:
            return (
              <FrigadeChecklist
                appearance={appearance}
                className={styles.component}
                customVariables={customVariables}
                flowId={flow.slug}
                key={flow.slug}
                type="modal"
                onButtonClick={handleButtonClick(flow.slug)}
                onStepCompletion={handleCompleteStep(flow.slug)}
                setVisible={handleSetVisible(flow.slug)}
                visible={visible}
              />
            );
          case FlowType.TOUR:
            return (
              <FrigadeTour
                appearance={appearance}
                className={cn(styles.component, {
                  [styles.center]: centered, //flow.getCurrentStep().props?.center,
                })}
                customVariables={customVariables}
                flowId={flow.slug}
                key={flow.slug}
                onButtonClick={handleButtonClick(flow.slug)}
                onStepCompletion={handleCompleteStep(flow.slug)}
                visible={visible}
              />
            );
          // In fact, they use the "SURVEY" string instead of "NPS_SURVEY"
          // @ts-ignore
          case "SURVEY":
          case FlowType.NPS_SURVEY:
            return visible ? (
              <FrigadeNPSSurvey
                appearance={appearance}
                className={styles.component}
                customVariables={customVariables}
                flowId={flow.slug}
                onStepCompletion={handleCompleteStep(flow.slug)}
                onDismiss={() => handleDismiss(flow.slug)}
                type="modal"
                key={flow.slug}
              />
            ) : null;
          case FlowType.FORM:
            return (
              <FrigadeForm
                appearance={appearance}
                className={styles.component}
                customVariables={customVariables}
                flowId={flow.slug}
                key={flow.slug}
                type="modal"
                onStepCompletion={handleCompleteStep(flow.id)}
                onDismiss={() => handleDismiss(flow.slug)}
                visible={visible}
              />
            );
          case FlowType.ANNOUNCEMENT:
            return (
              <FrigadeAnnouncement
                appearance={appearance}
                className={styles.component}
                customVariables={customVariables}
                dismissible
                flowId={flow.slug}
                key={flow.slug}
                onButtonClick={handleButtonClick(flow.slug, true)}
                onStepCompletion={handleCompleteStep(flow.slug)}
                onDismiss={() => handleDismiss(flow.slug)}
              />
            );
          case FlowType.BANNER:
            return (
              <FrigadeBanner
                appearance={appearance}
                className={styles.component}
                customVariables={customVariables}
                flowId={flow.slug}
                key={flow.slug}
                onStepCompletion={handleCompleteStep(flow.id)}
                onDismiss={() => handleDismiss(flow.slug)}
              />
            );
          default:
            return null;
        }
      })}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: RootState) => ({
  me: getMe(state)!,
});

const mapDispatchToProps = {
  onShowRandomRecord: showRandomRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(FrigadeRouter));

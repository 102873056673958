import User from "@mapmycustomers/shared/types/User";
import { Descendant, Editor, Transforms } from "slate";
import { userDisplayName } from "@mapmycustomers/shared/util/formatters";
import IsMentionDisabled from "../type/IsMentionDisabled";
import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";
import { MentionElement } from "type/slate-types";

const insertMention = (
  editor: Editor,
  user: User,
  isMentionDisabled?: IsMentionDisabled
) => {
  const insert: Descendant[] = [
    {
      children: [{ text: "" }],
      disabled: isMentionDisabled ? isMentionDisabled(user) : false,
      email: user.username ?? "",
      name: user ? userDisplayName(user) : "",
      type: ElementType.MENTION,
    } as MentionElement,
    { text: " " },
  ];
  Transforms.insertNodes(editor, insert, { select: true });
  Transforms.move(editor, { edge: "end" });
};

export default insertMention;

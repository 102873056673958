enum ElementType {
  PARAGRAPH = "paragraph",
  MENTION = "mention",
  NUMBERED_LIST = "numbered-list",
  BULLETED_LIST = "bulleted-list",
  LIST_ITEM = "list-item",
  INDENT = "indent",
}

export default ElementType;

import React from "react";
import outSoon from "assets/frequency/status/outSoon.svg";
import upToDate from "assets/frequency/status/upToDate.svg";
import noActivity from "assets/frequency/status/noActivity.svg";
import overdue from "assets/frequency/status/overdue.svg";
import defaultIcon from "assets/frequency/status/default.svg";
import styles from "./FrequencyIcon.module.scss";
import cn from "classnames";
import FrequencyStatus from "@mapmycustomers/shared/enum/frequency/FrequencyStatus";

interface Props {
  size?: "tiny" | "small" | "big" | "xl";
  status?: FrequencyStatus;
}

const FrequencyIcon: React.FC<Props> = ({ size = "small", status }) => (
  <img
    alt="frequency-status"
    src={
      status === FrequencyStatus.UPCOMING
        ? outSoon
        : status === FrequencyStatus.PAST_DUE
        ? overdue
        : status === FrequencyStatus.UP_TO_DATE
        ? upToDate
        : status === FrequencyStatus.UNKNOWN
        ? noActivity
        : defaultIcon
    }
    className={cn(styles.container, {
      [styles.l]: size === "big",
      [styles.s]: size === "tiny",
      [styles.xl]: size === "xl",
    })}
  />
);

export default FrequencyIcon;

import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import SelectField from "component/input/SelectField";
import { NumberField } from "@mapmycustomers/ui";
import Currency from "@mapmycustomers/shared/types/Currency";
import { RootState } from "store/rootReducer";
import { connect } from "react-redux";
import { getCurrencies } from "store/referenceData";
import { Labeled, LabeledFieldProps } from "@mapmycustomers/ui";
import { isDefined } from "util/assert";
import { getOrganizationSettingValue } from "store/iam";
import OrganizationSetting from "enum/OrganizationSetting";
import createNumberFieldFormatter from "component/input/createNumberFieldFormatter";
import styles from "./CurrencyInput.module.scss";

interface Props extends Omit<LabeledFieldProps, "children"> {
  currencies: Currency[];
  defaultCurrencyCode?: string;
  disabled?: boolean;
  onChange?: (value: number) => void;
  placeholder?: string;
  readOnly?: boolean;
  value?: number;
}

const CurrencyInput = memo<Props>(
  ({
    className,
    currencies,
    defaultCurrencyCode,
    disabled,
    onChange,
    placeholder,
    readOnly,
    value,
    ...labeledProps
  }) => {
    const intl = useIntl();

    const amountFormatter = useMemo(
      () =>
        createNumberFieldFormatter(intl, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        }),
      [intl]
    );

    const defaultCurrency = useMemo(
      () => currencies.find((currency) => currency.code === defaultCurrencyCode),
      [currencies, defaultCurrencyCode]
    );
    const [amount, setAmount] = useState<number | undefined>(value);
    useEffect(() => {
      setAmount(value);
    }, [value, defaultCurrency]);

    const handleChange = useCallback(
      (amount?: number) => {
        if (isDefined(amount) && onChange) {
          onChange(amount);
        }
      },
      [onChange]
    );

    const handleAmountChange = useCallback(
      (amount?: number) => {
        setAmount(amount);
        handleChange(amount);
      },
      [handleChange, setAmount]
    );

    const currencyOptions = useMemo(
      () => currencies.map(({ id, code }) => ({ label: code, value: id })),
      [currencies]
    );

    const MAX_MONEY_NUMERIC_LIMIT = 9223372036854775807; // platform limit

    return (
      <Labeled {...labeledProps}>
        <div className={styles.inputContainer}>
          <SelectField
            className={styles.currencyField}
            disabled
            dropdownMatchSelectWidth={false}
            options={currencyOptions}
            value={defaultCurrency?.id}
          />
          <NumberField
            className={styles.amountContainer}
            disabled={disabled}
            formatter={amountFormatter}
            inputClassName={styles.amountField}
            max={MAX_MONEY_NUMERIC_LIMIT}
            min={0}
            onChange={handleAmountChange}
            placeholder={placeholder}
            precision={2}
            readOnly={readOnly}
            value={amount}
          />
        </div>
      </Labeled>
    );
  }
);

const mapStateToProps = (state: RootState) => ({
  currencies: getCurrencies(state),
  defaultCurrencyCode: getOrganizationSettingValue(state)(OrganizationSetting.CURRENCY),
});

export default connect(mapStateToProps)(CurrencyInput);

import { defineMessage } from "react-intl";

export const findMeTooltipMessage = defineMessage({
  id: "autoCompleteAddress.findMe.tooltip",
  defaultMessage: "Find my location",
  description: "Find my location button in a Auto Complete Address input",
});

export const placeholderMessage = defineMessage({
  id: "autoCompleteAddress.placeholder",
  defaultMessage: "Type to search",
  description: "Placeholder text for Auto Complete Address input",
});

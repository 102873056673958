import React, { useCallback, useEffect } from "react";
import commonStyles from "../AuthCommon.module.scss";
import styles from "./ManageUserAccess.module.scss";
import { useIntl } from "react-intl";
import messages from "./messages";
import Button from "antd/es/button";
import { Alert } from "@mapmycustomers/ui";
import { fetchUserDetailsSso, updateUserStatus } from "store/auth/actions";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import { getUserDetails } from "store/auth";
import UserDetails from "types/auth/UserDetails";
import { parseApiDate } from "util/parsers";
import { formatDate } from "util/formatters";
import UserRequestStatus from "enum/sso/UserRequestStatus";

const PURCHASE_SEATS_LINK = "https://share.hsforms.com/1aoERY4lrQ0GOQeRlXahNAQ3is1h";

interface Props {
  onFetchUserDetailsSso: typeof fetchUserDetailsSso.request;
  onManageUsersClick: () => void;
  onUpdateUserStatus: typeof updateUserStatus.request;
  sessionCode: string;
  userDetails?: UserDetails;
}

const ManageUserAccessPage: React.FC<Props> = ({
  onFetchUserDetailsSso,
  onManageUsersClick,
  onUpdateUserStatus,
  sessionCode,
  userDetails,
}) => {
  const intl = useIntl();

  const handleUserStatusUpdate = useCallback(
    (status: UserRequestStatus) => {
      if (userDetails?.email) {
        onUpdateUserStatus({ username: userDetails.email, sessionCode, status });
      }
    },
    [onUpdateUserStatus, sessionCode, userDetails]
  );

  useEffect(() => {
    if (sessionCode) {
      onFetchUserDetailsSso(sessionCode);
    }
  }, [onFetchUserDetailsSso, sessionCode]);

  if (!userDetails) {
    return null;
  }

  const {
    email,
    requestDate,
    org: { availableLicences, totalLicences },
  } = userDetails;
  const requestedDate = formatDate(parseApiDate(requestDate), "PPPP");

  return (
    <section className={commonStyles.container}>
      <div className={styles.loginDiv}>
        <div className={styles.loginPage}>
          <div className={styles.container}>
            <h1 className={commonStyles.title}>{intl.formatMessage(messages.title)}</h1>
            <div className={styles.dataContainer}>
              <span className={styles.requestTitle}>
                {intl.formatMessage(messages.requestFrom)}
              </span>
              <span className={styles.username}>{email}</span>
            </div>
            <div className={styles.dataContainer}>
              <span className={styles.requestTitle}>
                {intl.formatMessage(messages.requestType)}
              </span>
              <span className={styles.requestData}>{intl.formatMessage(messages.request)}</span>
            </div>
            <div className={styles.dataContainer}>
              <span className={styles.requestTitle}>
                {intl.formatMessage(messages.requestedOn)}
              </span>
              <span className={styles.requestData}>{requestedDate}</span>
            </div>
            {availableLicences > 0 ? (
              <p className={styles.description}>
                {intl.formatMessage(messages.disclamer, {
                  count: availableLicences,
                  total: totalLicences,
                })}
              </p>
            ) : (
              <Alert
                className={styles.alert}
                description={intl.formatMessage(messages.noSeatsRemainingMessage)}
                message={intl.formatMessage(messages.noSeatsRemainingTitle)}
                showIcon
                type="warning"
              />
            )}
            <div className={styles.buttonContainer}>
              <div className={styles.row}>
                <Button
                  className={styles.button}
                  onClick={() => handleUserStatusUpdate(UserRequestStatus.REJECTED)}
                  size="large"
                  type="default"
                >
                  {intl.formatMessage(messages.deny)}
                </Button>
                {availableLicences > 0 ? (
                  <Button
                    className={styles.button}
                    onClick={() => handleUserStatusUpdate(UserRequestStatus.APPROVED)}
                    size="large"
                    type="primary"
                  >
                    {intl.formatMessage(messages.approve)}
                  </Button>
                ) : (
                  <Button
                    className={styles.button}
                    href={PURCHASE_SEATS_LINK}
                    size="large"
                    target="_blank"
                    type="primary"
                  >
                    {intl.formatMessage(messages.purchaseMoreSeats)}
                  </Button>
                )}
              </div>
              {availableLicences < 1 && (
                <Button
                  className={styles.button}
                  onClick={onManageUsersClick}
                  size="large"
                  type="link"
                >
                  {intl.formatMessage(messages.manageUsers)}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: getUserDetails(state),
});

const mapDispatchToProps = {
  onFetchUserDetailsSso: fetchUserDetailsSso.request,
  onUpdateUserStatus: updateUserStatus.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserAccessPage);

import { Company, Group, Person } from "@mapmycustomers/shared/types/entity";
import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import { PersonPayload } from "store/api/ApiService";
import { CompanyRef } from "@mapmycustomers/shared/types/entity/Company";
import { RawFile } from "@mapmycustomers/shared/types/File";
import FileListItem from "types/FileListItem";
import { DuplicateEntity } from "@mapmycustomers/shared/types/entity/Duplicate";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";

export const showGlobalCreatePersonModal = createAction("@component/globalCreatePersonModal/show")<{
  showPostCreatePrompt?: boolean;
} | void>();
export const hideGlobalCreatePersonModal = createAction(
  "@component/globalCreatePersonModal/hide"
)<void>();

export const initialize = createAsyncAction(
  "@component/createPersonModal/show/request",
  "@component/createPersonModal/show/success",
  "@component/createPersonModal/show/failure"
)<
  { fixedCompany?: CompanyRef },
  {
    parentCompany?: Company;
  } | void,
  void
>();

export const createPerson = createAsyncAction(
  "@component/createPersonModal/createPerson/request",
  "@component/createPersonModal/createPerson/success",
  "@component/createPersonModal/createPerson/failure"
)<
  {
    callback?: (newPerson: Person) => void;
    checkDuplicates?: boolean;
    customFieldsValues: CustomFieldValue[];
    groupsIdsToAdd: Array<Group["id"]>;
    layoutId: FormLayout["id"];
    person: PersonPayload;
  },
  Person,
  { duplicates?: DuplicateEntity[] }
>();

export const resetParentCompany = createAction(
  "@component/createPersonModal/resetParentCompany"
)<void>();

export const uploadPersonFiles = createAsyncAction(
  "@component/createPersonModal/uploadPersonFiles/request",
  "@component/createPersonModal/uploadPersonFiles/success",
  "@component/createPersonModal/uploadPersonFiles/failure"
)<
  {
    files: (Blob | File)[];
    callback?: (filesList: FileListItem[]) => void;
  },
  FileListItem[],
  void
>();

export const removePersonFile = createAsyncAction(
  "@component/createPersonModal/removePersonFile/request",
  "@component/createPersonModal/removePersonFile/success",
  "@component/createPersonModal/removePersonFile/failure"
)<RawFile, RawFile["id"], void>();

export const clearAllUploadedPersonFiles = createAction(
  "@component/createPersonModal/clearAllUploadedPersonFiles"
)<void>();

export type Actions = ActionType<
  | typeof showGlobalCreatePersonModal
  | typeof hideGlobalCreatePersonModal
  | typeof initialize
  | typeof createPerson
  | typeof resetParentCompany
  | typeof uploadPersonFiles
  | typeof removePersonFile
  | typeof clearAllUploadedPersonFiles
>;

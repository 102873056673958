import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import styles from "./FrequencyPreview.module.scss";
import Row from "antd/es/row";
import Col from "antd/es/col";
import layout from "../../../styles/layout";
import messages from "./messages";
import Switch from "antd/es/switch";
import ActivityTypeIcon from "../../activity/ActivityTypeIcon";
import Select from "antd/es/select";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import useActivityTypeOptions from "../../../util/ui/useActivityTypeOptions";
import { RootState } from "../../../store/rootReducer";
import { getActivityTypes } from "../../../store/activity";
import { connect } from "react-redux";
import activityTypeTagRender from "../../../util/ui/activityTypeTagRender";
import defaultFilterOption from "../../input/utils/defaultFilterOption";

interface Props {
  activityTypes: ActivityType[];
  allActivityTypes: boolean;
  onSetActivityTypes: (types: ActivityType["id"][]) => void;
  onSetAllActivityTypes: (allActivityTypes: boolean) => void;
  readonly?: boolean;
  selectedActivityTypeIds: ActivityType["id"][];
}

const ActivityTypes: React.FC<Props> = ({
  activityTypes,
  allActivityTypes,
  onSetActivityTypes,
  onSetAllActivityTypes,
  readonly,
  selectedActivityTypeIds,
}) => {
  const intl = useIntl();

  const activityTypeOptions = useActivityTypeOptions(
    activityTypes,
    selectedActivityTypeIds,
    useCallback((type) => !type.active, [])
  );

  const selectedActivityTypes = useMemo(
    () => activityTypes.filter(({ id }) => selectedActivityTypeIds.includes(id)),
    [activityTypes, selectedActivityTypeIds]
  );

  return (
    <>
      <Row align="middle" className={styles.line} justify="space-between">
        <Col>{intl.formatMessage(messages.acceptAllType)}</Col>
        <Col>
          {readonly ? (
            <span className={styles.staticValue}>
              {intl.formatMessage(messages.allActivityTypesStatic, { allActivityTypes })}
            </span>
          ) : (
            <Switch checked={allActivityTypes} onChange={onSetAllActivityTypes} />
          )}
        </Col>
      </Row>
      {!allActivityTypes &&
        (readonly ? (
          <div className={styles.readonlyActivityTypes}>
            {selectedActivityTypes.map((type) => (
              <Row gutter={layout.spacerXS}>
                <Col>
                  <ActivityTypeIcon
                    activityType={type}
                    className={styles.readonlyActivityTypeIcon}
                  />
                </Col>
                <Col>{type.name}</Col>
              </Row>
            ))}
          </div>
        ) : (
          <div className={styles.line}>
            <Select<ActivityType["id"][]>
              className={styles.activityTypes}
              dropdownMatchSelectWidth={false}
              filterOption={defaultFilterOption}
              mode="multiple"
              onChange={onSetActivityTypes}
              options={activityTypeOptions}
              placeholder={intl.formatMessage(messages.select)}
              tagRender={activityTypeTagRender}
              value={selectedActivityTypeIds}
            />
          </div>
        ))}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  activityTypes: getActivityTypes(state),
});

export default connect(mapStateToProps)(ActivityTypes);

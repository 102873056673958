import getInitials from "@mapmycustomers/shared/util/ui/getInitials";
import User from "@mapmycustomers/shared/types/User";
import { userDisplayName } from "@mapmycustomers/shared/util/formatters";
import ContactInfo from "component/ContactInfo/ContactInfo";
import LastActivityChip from "component/LastActivityChip";
import { faUsers } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chip from "component/Chip";
import styles from "./Content.module.scss";
import cn from "classnames";

interface Props {
  logoUrl?: string;
  user: User;
}
const Content: React.FC<Props> = ({ logoUrl, user }) => {
  const initials = getInitials(user);

  return (
    <div className={styles.content}>
      <div
        className={cn(styles.imageContainer, {
          [styles.noImage]: !logoUrl,
        })}
      >
        {logoUrl ? <img className={styles.image} src={logoUrl} alt={logoUrl} /> : initials}
      </div>
      <div className={styles.info}>
        <div className={styles.title}>
          <span className={styles.name}>{userDisplayName(user)}</span>
          <ContactInfo size="small" user={user} />
        </div>
        <div className={styles.chips}>
          <LastActivityChip lastActivity={user.lastActivity} userId={user.id} />
          {user.teams.map((team) => (
            <Chip className={styles.chip} icon={<FontAwesomeIcon icon={faUsers} />} key={team.id}>
              {team.name}
            </Chip>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Content;

import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Feature } from "component/map/FeaturedMap/index";
import MapTooltip from "scene/map/components/MapTooltip";
import { RootState } from "store/rootReducer";
import { getMapSettings } from "store/iam";
import MarkerSize from "@mapmycustomers/shared/enum/MarkerSize";
import MarkerShape from "@mapmycustomers/shared/enum/MarkerShape";
import MarkerEffect from "enum/MarkerEffect";
import { mapPinIdGetter } from "util/map/idGetters";
import { EntityPin } from "types/map";
import { getEntityTypeAnalyticsName } from "util/ui";
import useAnalytics from "util/contexts/useAnalytics";
import defaultMapEntryGeometryGetter from "util/map/defaultMapEntryGeometryGetter";
import defaultEntityStyleGetter from "util/map/markerStyles/defaultEntityStyleGetter";
import loggingService from "util/logging";
import typedIdGetter from "util/typedIdGetter";
import { PIN_ZINDEX } from "util/map/consts";
import isValidMapEntry from "util/map/isValidMapEntry";
import { AnyEntityId, Company, Person } from "@mapmycustomers/shared/types/entity";

const isDifferent = (a: EntityPin, b: EntityPin) =>
  a.id !== b.id ||
  a.data.entity !== b.data.entity ||
  a.data.color !== b.data.color ||
  a.data.shape !== b.data.shape ||
  a.sumAmount !== b.sumAmount ||
  a.data.outOfCadence !== b.data.outOfCadence ||
  a.data.overdueActivitiesCount !== b.data.overdueActivitiesCount ||
  a.data.cadenceDaysOut !== b.data.cadenceDaysOut ||
  (a.data as Company | Person)?.upcomingActivity?.startAt !==
    (b.data as Company | Person)?.upcomingActivity?.startAt ||
  Math.abs(a.region!.latitude - b.region!.latitude) > Number.EPSILON ||
  Math.abs(a.region!.longitude - b.region!.longitude) > Number.EPSILON;

interface Props {
  annotatedPinId?: string;
  entityPins: EntityPin[];
  focusedEntityId?: AnyEntityId;
  mapSettings: {
    markerSize: MarkerSize;
    showEntityIcons: boolean;
  };
  onClick?: (entity: EntityPin) => void;
  onDoubleClick?: (entity: EntityPin) => void;
  styleGetter?: (item: EntityPin) => google.maps.Data.StyleOptions;
  type?: string;
  zIndex?: number;
}

const EntityFeature: React.FC<Props> = ({
  annotatedPinId,
  entityPins,
  focusedEntityId,
  mapSettings,
  onClick,
  onDoubleClick,
  styleGetter,
  type = "entities",
  zIndex = PIN_ZINDEX,
}) => {
  const analytics = useAnalytics();

  const [hoveredPinId, setHoveredPinId] = useState<string | undefined>(undefined);

  const handleEntityClick = useCallback(
    (entity: EntityPin, event: google.maps.MapMouseEvent) => {
      event.stop();
      loggingService.debug("entity clicked", { entity, event });
      onClick?.(entity);
      analytics.clicked([`${getEntityTypeAnalyticsName(entity.data.entity, false, true)} Pin`]);
    },
    [analytics, onClick]
  );

  const handleEntityDoubleClick = useCallback(
    (entity: EntityPin, event: google.maps.MapMouseEvent) => {
      loggingService.debug("entity double clicked", { entity, event });
      onDoubleClick?.(entity);
      analytics.clicked([`${getEntityTypeAnalyticsName(entity.data.entity, false, true)} Pin`]);
    },
    [analytics, onDoubleClick]
  );

  const handleEntityMouseOver = useCallback(
    (pin: EntityPin) => setHoveredPinId(mapPinIdGetter(pin)),
    []
  );
  const handleEntityMouseLeave = useCallback(() => setHoveredPinId(undefined), []);

  const hoveredPin: EntityPin | undefined = useMemo(
    () =>
      hoveredPinId
        ? entityPins.find((pin) => {
            const pinId = mapPinIdGetter(pin);
            return pinId === hoveredPinId && pinId !== annotatedPinId;
          })
        : undefined,
    [annotatedPinId, entityPins, hoveredPinId]
  );
  const hoveredPinPosition = useMemo(
    () =>
      hoveredPin?.region
        ? { lat: hoveredPin.region.latitude, lng: hoveredPin.region.longitude }
        : undefined,
    [hoveredPin]
  );

  const entityEffectGetter = useMemo(
    () => (entityPin: EntityPin) =>
      annotatedPinId === mapPinIdGetter(entityPin) ? MarkerEffect.OUTLINE : MarkerEffect.SHADOW,
    [annotatedPinId]
  );

  const entityIconGetter = useMemo(
    () => (entityPin: EntityPin) => {
      const icon = mapSettings.showEntityIcons ? entityPin.data.entity : undefined;
      return focusedEntityId === entityPin.data.id ? "selected" : icon;
    },
    [focusedEntityId, mapSettings.showEntityIcons]
  );

  const entityPinStyleGetter = useMemo(() => {
    return defaultEntityStyleGetter({
      effect: entityEffectGetter,
      icon: entityIconGetter,
      markerSize: mapSettings.markerSize,
      shape: (entityPin: EntityPin) => entityPin.data.shape ?? MarkerShape.SQUARE,
      showEntityIcon: mapSettings.showEntityIcons,
      zIndex,
    });
  }, [entityEffectGetter, entityIconGetter, mapSettings, zIndex]);

  return (
    <>
      <Feature<EntityPin>
        geometryGetter={defaultMapEntryGeometryGetter}
        idGetter={typedIdGetter}
        isDifferent={isDifferent}
        items={entityPins.filter(isValidMapEntry)}
        onClick={handleEntityClick}
        onDoubleClick={handleEntityDoubleClick}
        onMouseLeave={handleEntityMouseLeave}
        onMouseOver={handleEntityMouseOver}
        styleGetter={styleGetter ?? entityPinStyleGetter}
        type={type}
      />
      {hoveredPin && <MapTooltip position={hoveredPinPosition} tooltip={hoveredPin.data.name} />}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  mapSettings: getMapSettings(state),
});

export default connect(mapStateToProps)(EntityFeature);

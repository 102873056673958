import { put, select, takeLatest, takeLeading } from "redux-saga/effects";
import {
  createCalendarEventForDealClosingDate,
  createNylas,
  deleteNylas,
  fetchCalendars,
  initializeNylas,
  updateNylas,
} from "./actions";
import { handleError } from "../errors/actions";
import { callApi } from "../api/callApi";
import { getNylasInfo } from "./selectors";
import NylasInfo, { CreateNylasEventPayload } from "types/nylas/NylasInfo";
import type Calendar from "nylas/lib/models/calendar";
import { defineMessage } from "react-intl";
import i18nService from "../../config/I18nService";
import notification from "antd/es/notification";

const successfullyCreatedEventForDealClosingDateMessage = defineMessage({
  id: "nylas.createEventForDealClosingDate.success",
  defaultMessage: "Event created",
  description: "Event was successfully created for the given deal",
});

export function* onInitializeNylas() {
  try {
    const response: NylasInfo | "" = yield callApi("fetchNylasInfo");
    yield put(initializeNylas.success({ info: response || undefined }));
  } catch (error) {
    yield put(initializeNylas.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreateNylas({
  payload: { nylas, callback },
}: ReturnType<typeof createNylas.request>) {
  try {
    const response: NylasInfo = yield callApi("createNylas", nylas);
    callback?.(response);
    yield put(createNylas.success());
    yield put(initializeNylas.request());
  } catch (error) {
    yield put(createNylas.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateNylas({
  payload: { nylas, callback },
}: ReturnType<typeof updateNylas.request>) {
  try {
    const response: NylasInfo = yield callApi("updateNylas", nylas);
    callback?.(response);
    yield put(updateNylas.success(response));
    yield put(initializeNylas.request());
  } catch (error) {
    yield put(updateNylas.failure());
    yield put(handleError({ error }));
  }
}

export function* onDeleteNylas({
  payload: { nylasId, callback },
}: ReturnType<typeof deleteNylas.request>) {
  try {
    yield callApi("deleteNylas", nylasId);
    callback?.();
    yield put(deleteNylas.success());
  } catch (error) {
    yield put(deleteNylas.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchCalendars() {
  try {
    const nylasInfo: NylasInfo = yield select(getNylasInfo);
    const calendars: Calendar[] = yield callApi("fetchCalendars", nylasInfo.id);
    yield put(fetchCalendars.success(calendars));
  } catch (error) {
    yield put(fetchCalendars.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreateCalendarEventForDealClosingDate({
  payload,
}: ReturnType<typeof createCalendarEventForDealClosingDate.request>) {
  try {
    const { deal, eventDateTime, callback } = payload;

    const nylasInfo: NylasInfo = yield select(getNylasInfo);

    const requestPayload: CreateNylasEventPayload = {
      name: `"${deal.name}" Deal close date`,
      closingDate: eventDateTime.toISOString(),
    };

    yield callApi("createNylasEvent", nylasInfo.id, requestPayload);

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(successfullyCreatedEventForDealClosingDateMessage),
      });
    }
    yield put(createCalendarEventForDealClosingDate.success());
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(createCalendarEventForDealClosingDate.failure());
    yield put(handleError({ error }));
  }
}

export function* nylasSaga() {
  yield takeLeading(initializeNylas.request, onInitializeNylas);
  yield takeLeading(createNylas.request, onCreateNylas);
  yield takeLeading(deleteNylas.request, onDeleteNylas);
  yield takeLeading(updateNylas.request, onUpdateNylas);
  yield takeLatest(fetchCalendars.request, onFetchCalendars);
  yield takeLatest(
    createCalendarEventForDealClosingDate.request,
    onCreateCalendarEventForDealClosingDate
  );
}

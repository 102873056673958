import { createReducer } from "typesafe-actions";
import { Actions, fetchPreview } from "./actions";

export interface FilePreviewState {
  filePreview?: Blob;
  filePreviewLoading: boolean;
}

const initialState: FilePreviewState = {
  filePreviewLoading: false,
};

const filePreview = createReducer<FilePreviewState, Actions>(initialState)
  .handleAction(fetchPreview.request, (state, { payload }) => ({
    ...state,
    filePreviewLoading: true,
  }))
  .handleAction(fetchPreview.success, (state, { payload }) => ({
    ...state,
    filePreview: payload,
    filePreviewLoading: false,
  }))
  .handleAction(fetchPreview.failure, (state) => ({
    ...state,
    filePreviewLoading: false,
  }));

export * from "./selectors";
export type FilePreviewActions = Actions;
export default filePreview;

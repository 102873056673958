import {
  Actions,
  createCalendarEventForDealClosingDate,
  createNylas,
  deleteNylas,
  fetchCalendars,
  initializeNylas,
  updateNylas,
} from "./actions";
import { createReducer } from "typesafe-actions";
import type Calendar from "nylas/lib/models/calendar";
import NylasInfo from "../../types/nylas/NylasInfo";

export type NylasState = {
  calendars: Calendar[];
  calendarsLoading: boolean;
  createCalendarEventForDealClosingDateLoading: boolean;
  creating: boolean;
  deleting: boolean;
  emailNylasInfo: NylasInfo | undefined;
  initializing: boolean;
  nylasInfo: NylasInfo | undefined;
  updating: boolean;
};

const initialState: NylasState = {
  calendars: [],
  calendarsLoading: false,
  createCalendarEventForDealClosingDateLoading: false,
  creating: false,
  deleting: false,
  emailNylasInfo: undefined,
  initializing: false,
  nylasInfo: undefined,
  updating: false,
};

const nylas = createReducer<NylasState, Actions>(initialState)
  .handleAction(initializeNylas.request, (state) => ({
    ...state,
    initializing: true,
  }))
  .handleAction(initializeNylas.success, (state, action) => ({
    ...state,
    initializing: false,
    nylasInfo: action.payload.info,
  }))
  .handleAction(initializeNylas.failure, (state) => ({
    ...state,
    initializing: false,
  }))
  .handleAction(fetchCalendars.request, (state) => ({
    ...state,
    calendarsLoading: true,
  }))
  .handleAction(fetchCalendars.success, (state, action) => ({
    ...state,
    calendars: action.payload,
    calendarsLoading: false,
  }))
  .handleAction(fetchCalendars.failure, (state) => ({
    ...state,
    calendarsLoading: false,
  }))
  .handleAction(createCalendarEventForDealClosingDate.request, (state) => ({
    ...state,
    createCalendarEventForDealClosingDateLoading: true,
  }))
  .handleAction(createCalendarEventForDealClosingDate.success, (state) => ({
    ...state,
    createCalendarEventForDealClosingDateLoading: false,
  }))
  .handleAction(createCalendarEventForDealClosingDate.failure, (state) => ({
    ...state,
    createCalendarEventForDealClosingDateLoading: false,
  }))
  .handleAction(createNylas.request, (state) => ({
    ...state,
    creating: true,
  }))
  .handleAction(createNylas.success, (state, { payload }) => ({
    ...state,
    creating: false,
  }))
  .handleAction(createNylas.failure, (state) => ({
    ...state,
    creating: false,
  }))
  .handleAction(deleteNylas.request, (state) => ({
    ...state,
    deleting: true,
  }))
  .handleAction(deleteNylas.success, (state) => ({
    ...state,
    nylasInfo: undefined,
    deleting: false,
  }))
  .handleAction(deleteNylas.failure, (state) => ({
    ...state,
    deleting: false,
  }))
  .handleAction(updateNylas.request, (state) => ({
    ...state,
    updating: true,
  }))
  .handleAction(updateNylas.success, (state, { payload }) => ({
    ...state,
    nylasInfo: payload,
    updating: false,
  }))
  .handleAction(updateNylas.failure, (state) => ({
    ...state,
    updating: false,
  }));

export * from "./selectors";
export type NylasActions = Actions;
export default nylas;

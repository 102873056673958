import { defineMessages, IntlShape } from "react-intl";
import activityIcon from "assets/associations/activity.svg";
import companyIcon from "assets/associations/company.svg";
import childCompanyIcon from "assets/associations/childCompany.svg";
import dealIcon from "assets/associations/deal.svg";
import groupIcon from "assets/associations/group.svg";
import personIcon from "assets/associations/person.svg";
import routeIcon from "assets/associations/route.svg";
import messages from "../messages";
import { Company, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";

enum PostCreationAssociationTypes {
  ACTIVITY = "activity",
  CHILD_COMPANY = "childCompany",
  ROUTE = "route",
  PERSON = "person",
  DEAL = "deal",
  CHANGE_COMPANIES = "changeCompanies",
  CHANGE_COMPANY = "changeCompany",
  CHANGE_PARENT_COMPANY = "changeParentCompany",
  CHANGE_PERSON = "changePerson",
  COMPANIES = "companies",
  COMPANY = "company",
  GROUP = "group",
  PARENT_COMPANY = "parentCompany",
}

export const associationTypes = [
  PostCreationAssociationTypes.ACTIVITY,
  PostCreationAssociationTypes.CHILD_COMPANY,
  PostCreationAssociationTypes.COMPANY,
  PostCreationAssociationTypes.COMPANIES,
  PostCreationAssociationTypes.DEAL,
  PostCreationAssociationTypes.GROUP,
  PostCreationAssociationTypes.PARENT_COMPANY,
  PostCreationAssociationTypes.PERSON,
  PostCreationAssociationTypes.ROUTE,
  PostCreationAssociationTypes.CHANGE_COMPANY,
  PostCreationAssociationTypes.CHANGE_COMPANIES,
  PostCreationAssociationTypes.CHANGE_PERSON,
  PostCreationAssociationTypes.CHANGE_PARENT_COMPANY,
];

const icon = {
  [PostCreationAssociationTypes.ACTIVITY]: activityIcon,
  [PostCreationAssociationTypes.CHANGE_COMPANIES]: companyIcon,
  [PostCreationAssociationTypes.CHANGE_COMPANY]: companyIcon,
  [PostCreationAssociationTypes.CHANGE_PARENT_COMPANY]: companyIcon,
  [PostCreationAssociationTypes.CHANGE_PERSON]: personIcon,
  [PostCreationAssociationTypes.CHILD_COMPANY]: childCompanyIcon,
  [PostCreationAssociationTypes.COMPANIES]: companyIcon,
  [PostCreationAssociationTypes.COMPANY]: companyIcon,
  [PostCreationAssociationTypes.DEAL]: dealIcon,
  [PostCreationAssociationTypes.GROUP]: groupIcon,
  [PostCreationAssociationTypes.PARENT_COMPANY]: companyIcon,
  [PostCreationAssociationTypes.PERSON]: personIcon,
  [PostCreationAssociationTypes.ROUTE]: routeIcon,
};

const text = defineMessages({
  [PostCreationAssociationTypes.ACTIVITY]: messages.addActivity,
  [PostCreationAssociationTypes.CHANGE_COMPANIES]: messages.changeCompanies,
  [PostCreationAssociationTypes.CHANGE_COMPANY]: messages.changeCompany,
  [PostCreationAssociationTypes.CHANGE_PARENT_COMPANY]: messages.changeParentCompany,
  [PostCreationAssociationTypes.CHANGE_PERSON]: messages.changePerson,
  [PostCreationAssociationTypes.CHILD_COMPANY]: messages.addChildCompany,
  [PostCreationAssociationTypes.COMPANIES]: messages.addCompanies,
  [PostCreationAssociationTypes.COMPANY]: messages.addCompany,
  [PostCreationAssociationTypes.DEAL]: messages.addDeal,
  [PostCreationAssociationTypes.GROUP]: messages.addGroup,
  [PostCreationAssociationTypes.PARENT_COMPANY]: messages.addParentCompany,
  [PostCreationAssociationTypes.PERSON]: messages.addPerson,
  [PostCreationAssociationTypes.ROUTE]: messages.addRoute,
});

export const getCardsParams = (
  intl: IntlShape,
  type: PostCreationAssociationTypes,
  entityType: EntityType,
  prefilledValues: Company | Person | Deal
) => {
  let messageToShow;
  const prefilledPerson =
    entityType === EntityType.PERSON ? (prefilledValues as Person) : undefined;
  switch (type) {
    case PostCreationAssociationTypes.CHILD_COMPANY:
      messageToShow = entityType === EntityType.COMPANY && intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.PARENT_COMPANY:
      messageToShow =
        entityType === EntityType.COMPANY &&
        !(prefilledValues as Company).parentAccount &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.COMPANIES:
      messageToShow =
        entityType === EntityType.PERSON &&
        (!prefilledPerson?.accounts || prefilledPerson?.accounts.length === 0) &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.COMPANY:
      messageToShow =
        entityType === EntityType.DEAL &&
        (prefilledValues as Deal).account &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.CHANGE_PARENT_COMPANY:
      messageToShow =
        entityType === EntityType.COMPANY &&
        (prefilledValues as Company).parentAccount &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.CHANGE_COMPANIES:
      messageToShow =
        entityType === EntityType.PERSON &&
        prefilledPerson?.accounts &&
        prefilledPerson.accounts.length > 0 &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.CHANGE_COMPANY:
      messageToShow =
        entityType === EntityType.DEAL &&
        (prefilledValues as Deal).account &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.PERSON:
      messageToShow =
        entityType !== EntityType.PERSON &&
        !(prefilledValues as Deal).contact &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.CHANGE_PERSON:
      messageToShow =
        entityType === EntityType.DEAL &&
        (prefilledValues as Deal).contact &&
        intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.DEAL:
      messageToShow = entityType !== EntityType.DEAL && intl.formatMessage(text[type]);
      break;
    case PostCreationAssociationTypes.ROUTE:
      messageToShow =
        entityType !== EntityType.DEAL &&
        (prefilledValues as Company | Person).address &&
        intl.formatMessage(text[type]);
      break;
    default:
      messageToShow = intl.formatMessage(text[type]);
  }

  const iconToShow = icon[type];
  const cardToShow = !!messageToShow;
  return { messageToShow, cardToShow, iconToShow };
};
export default PostCreationAssociationTypes;

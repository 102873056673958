import {
  changeAssociatedEntities,
  clearAllUploadedCreateEditActivityFiles,
  createActivity,
  deleteActivity,
  downloadActivityFile,
  fetchFilePreview,
  initializeActivityModal,
  initializeEditActivityModal,
  relateEntities,
  removeCreateEditActivityFile,
  updateActivity,
  uploadCreateEditActivityFiles,
} from "./actions";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getCurrentUser, getOrganization, getOrganizationId } from "store/iam";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { Activity, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import { callApi } from "store/api/callApi";
import { handleError } from "store/errors/actions";
import { defineMessage, defineMessages } from "react-intl";
import notification from "antd/es/notification";
import getSuccessNotificationNode from "../../util/getSuccessNotificationNode";
import i18nService from "config/I18nService";
import { extendNavbarAnalyticWithMenuKey } from "util/analytic/navbarAnalytics";
import User from "@mapmycustomers/shared/types/User";
import File, { RawFile } from "@mapmycustomers/shared/types/File";
import { ActivityPayload } from "store/api/ApiService";
import { notifyAboutChanges } from "store/uiSync/actions";
import { getUploadedFileListIds } from "../store";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import { allSettled, SettleResult } from "util/effects";
import FileListItem from "types/FileListItem";
import { downloadFileByUrl } from "util/file";
import { getAssociatedFiles, getEditedActivity } from "./selectors";
import { isApiError } from "util/assert";
import omit from "lodash-es/omit";
import activityFieldModel from "util/fieldModel/ActivityFieldModel";
import getNextAssociationsState, {
  NextAssociationsState,
} from "store/associations/getNextAssociationsState";
import { notifyActivityRelatedEntities } from "store/activity/actions";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";

const successLoggedMessage = defineMessage({
  id: "createEditActivityModal.logged.success",
  defaultMessage: "Activity logged successfully",
  description: "Activity logged success message",
});

const successUpdatedMessage = defineMessage({
  id: "createEditActivityModal.update.success",
  defaultMessage: "Activity updated successfully",
  description: "Activity updated success message",
});

const successDeletedMessage = defineMessage({
  id: "createEditActivityModal.delete.success",
  defaultMessage: "Activity deleted successfully",
  description: "Activity deleted success message",
});

const validationErrorMessages = defineMessages({
  v0204: {
    id: "createEditActivityModal.requiredCF.error",
    defaultMessage: "Please fill in all required custom fields before saving.",
    description: "Reuired custom field error message",
  },
});

export function* onInitializeActivityModal({
  payload,
}: ReturnType<typeof initializeActivityModal.request>) {
  try {
    const currentUser: User = yield select(getCurrentUser);

    const associationsState: NextAssociationsState = yield call(getNextAssociationsState, {
      assignee: currentUser,
      company: payload.fixedCompany,
      deal: payload.fixedDeal,
      entityType: EntityType.ACTIVITY,
      person: payload.fixedPerson,
    });

    yield put(initializeActivityModal.success(associationsState));
  } catch (error) {
    yield put(initializeActivityModal.failure());
    yield put(handleError({ error }));
  }
}

export function* onInitializeEditActivityModal({
  payload,
}: ReturnType<typeof initializeEditActivityModal.request>) {
  try {
    const { activityId, overrideValues } = payload;
    const org: Organization = yield select(getOrganization);

    let activity: Activity = yield callApi("fetchActivity", org.id, activityId, {
      includeAccessStatus: true,
      includeCustomFields: true,
      includeFiles: true,
      includeUsersWithAccess: true,
    });
    const activityCompleted = activity.completed;
    if (overrideValues) {
      activity = { ...activity, ...overrideValues };
    }

    yield put(
      initializeEditActivityModal.success({ activity, activityCompleted, files: activity.files })
    );

    yield put(
      initializeActivityModal.request({
        fixedCompany: activity.account,
        fixedDeal: activity.deal,
        fixedPerson: activity.contact,
      })
    );
  } catch (error) {
    const noAccess = isApiError(error) && error.status === 404;
    yield put(initializeEditActivityModal.failure(noAccess));
    // removed temporary
    // https://mapmycustomers.atlassian.net/browse/TART-7125?atlOrigin=eyJpIjoiZjNkZWViNjI5YzJhNDBkNGFhNTcxMTk3MjE4OWNhN2IiLCJwIjoiamlyYS1zbGFjay1pbnQifQ
    // yield put(handleError({ error }));
  }
}

export function* onChangeAssociatedEntity({
  payload,
}: ReturnType<typeof changeAssociatedEntities.request>) {
  try {
    const currentUser: User = yield select(getCurrentUser);

    const associationsState: NextAssociationsState = yield call(getNextAssociationsState, {
      ...payload,
      assignee: payload.assignee ?? currentUser,
      entityType: EntityType.ACTIVITY,
    });
    yield put(changeAssociatedEntities.success(associationsState));
  } catch (error) {
    yield put(changeAssociatedEntities.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreateActivity({ payload }: ReturnType<typeof createActivity.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const { activity, callback, customFieldsValues, layoutId } = payload;
    const fileIds: File["id"][] = yield select(getUploadedFileListIds);

    const activityToSend: ActivityPayload = {
      ...activity,
      files: fileIds.length > 0 ? [...fileIds] : undefined,
    };
    const newActivity: Activity = yield callApi("createActivity", org.id, layoutId, activityToSend);

    if (customFieldsValues.length) {
      yield callApi(
        "upsertCustomFieldsValues",
        false,
        org.id,
        layoutId,
        EntityType.ACTIVITY,
        newActivity.id,
        customFieldsValues
      );
    }

    callback?.(true, newActivity);
    extendNavbarAnalyticWithMenuKey("addActivity").clicked(["Create Activity"]);
    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(successLoggedMessage, "Activity logged successfully")
      ),
    });

    yield put(notifyAboutChanges({ added: [newActivity], entityType: EntityType.ACTIVITY }));
    yield put(notifyActivityRelatedEntities(newActivity));

    yield put(clearAllUploadedCreateEditActivityFiles());

    yield put(createActivity.success());
  } catch (error) {
    yield put(createActivity.failure());
    yield put(handleError({ error, messageByErrorCodeMap: validationErrorMessages }));
  }
}

export function* onUpdateActivity({ payload }: ReturnType<typeof updateActivity.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    const { activity, callback, customFieldsValues, layoutId, updateType } = payload;
    const fileIds: File["id"][] = yield select(getUploadedFileListIds);

    const readOnlyFields: string[] = activityFieldModel.fields
      .filter((field) => !field.isEditable || field.hasFeature(FieldFeature.CALCULATED_FIELD))
      .map((field) => field.platformName);

    const strippedActivity = readOnlyFields.length
      ? (omit(activity, readOnlyFields) as Identified)
      : activity;
    const strippedCustomFieldsValues = readOnlyFields.length
      ? customFieldsValues.filter((cf) => !readOnlyFields.includes(cf.esKey))
      : customFieldsValues;

    const activityToSend: Partial<ActivityPayload> & Identified = {
      ...strippedActivity,
      files: fileIds.length > 0 ? [...fileIds] : undefined,
    };
    yield callApi(
      "updateActivity",
      org.id,
      layoutId,
      activityToSend,
      updateType
        ? {
            updateRecurring: updateType,
          }
        : undefined
    );
    if (strippedCustomFieldsValues.length) {
      yield callApi(
        "upsertCustomFieldsValues",
        true,
        org.id,
        layoutId,
        EntityType.ACTIVITY,
        activity.id,
        strippedCustomFieldsValues
      );
    }

    // we can't use response from updateActivity, since it doesn't include updated custom fields
    // because custom fields are updated in a separate request
    const updatedActivity: Activity = yield callApi("fetchActivity", org.id, activity.id, {
      includeAccessStatus: true,
      includeCustomFields: true,
    });

    callback?.(true, updatedActivity);

    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(successUpdatedMessage, "Activity updated successfully")
      ),
    });

    yield put(notifyAboutChanges({ entityType: EntityType.ACTIVITY, updated: [updatedActivity] }));
    yield put(notifyActivityRelatedEntities(updatedActivity));

    yield put(clearAllUploadedCreateEditActivityFiles());

    yield put(updateActivity.success(updatedActivity));
  } catch (error) {
    yield put(updateActivity.failure());
    yield put(handleError({ error }));
  }
}

export function* onDeleteActivity({ payload }: ReturnType<typeof deleteActivity.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const { activityId, callback } = payload;
    yield callApi("deleteActivity", org.id, activityId, payload.deleteType);
    callback?.();
    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(successDeletedMessage, "Activity deleted successfully")
      ),
    });

    yield put(notifyAboutChanges({ deletedIds: [activityId], entityType: EntityType.ACTIVITY }));
    yield put(deleteActivity.success());
  } catch (error) {
    yield put(deleteActivity.failure());
    yield put(handleError({ error }));
  }
}

export function* onUploadCreateEditActivityFiles({
  payload,
}: ReturnType<typeof uploadCreateEditActivityFiles.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const responses: SettleResult<RawFile>[] = yield allSettled(
      payload.files.map((file) => callApi("createFile", org.id, file))
    );
    const fileList: FileListItem[] = responses.map((response, index) => ({
      file: payload.files[index],
      uploading: false,
      ...(response.error
        ? { errored: true, errorMessage: String(response.result) }
        : { errored: false, uploadedFile: response.result }),
    }));
    payload.callback?.(fileList);
    yield put(uploadCreateEditActivityFiles.success(fileList));
  } catch (error) {
    payload.callback?.(payload.files.map((file) => ({ errored: true, file, uploading: false })));
    yield put(uploadCreateEditActivityFiles.failure());
    yield put(handleError({ error }));
  }
}

export function* onRemoveCreateEditActivityFile({
  payload,
}: ReturnType<typeof removeCreateEditActivityFile.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const activity: Activity = yield select(getEditedActivity);
    const associatedFiles: RawFile[] = yield select(getAssociatedFiles);
    if (associatedFiles.find(({ id }) => id === payload.id)) {
      yield callApi("deleteEntityFile", org.id, EntityType.ACTIVITY, activity.id, payload.id);
    } else {
      yield callApi("deleteFile", org.id, payload.id);
    }
    yield put(removeCreateEditActivityFile.success(payload.id));
  } catch (error) {
    yield put(removeCreateEditActivityFile.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchFilePreview({
  payload: fileId,
}: ReturnType<typeof fetchFilePreview.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const activity: Activity = yield select(getEditedActivity);
    const fileData: Blob = yield callApi(
      "fetchFile",
      org.id,
      fileId,
      false,
      true,
      EntityType.ACTIVITY,
      activity.id,
      { responseType: "blob" }
    );
    yield put(fetchFilePreview.success(fileData));
  } catch (error) {
    yield put(fetchFilePreview.failure());
    yield put(handleError({ error }));
  }
}

export function* onRelateEntities({
  payload: {
    associatedCompany,
    associatedDeal,
    associatedPerson,
    failureCallback,
    isDealCorrectlyRelatedToCompany,
    isDealCorrectlyRelatedToPerson,
    isPersonCorrectlyRelatedToCompany,
    successCallback,
  },
}: ReturnType<typeof relateEntities.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    let person: Person | undefined;
    let deal: Deal | undefined;
    if (!isPersonCorrectlyRelatedToCompany && associatedPerson && associatedCompany) {
      person = yield callApi("updatePerson", orgId, undefined, {
        id: associatedPerson.id,
        account: { id: associatedCompany.id },
      });
    }
    if (!(isDealCorrectlyRelatedToCompany && isDealCorrectlyRelatedToPerson) && associatedDeal) {
      deal = yield callApi("updateDeal", orgId, undefined, {
        id: associatedDeal.id,
        ...(!isDealCorrectlyRelatedToCompany && associatedCompany
          ? { account: { id: associatedCompany.id } }
          : {}),
        ...(!isDealCorrectlyRelatedToPerson && associatedPerson
          ? { contact: { id: associatedPerson.id } }
          : {}),
      });
    }
    successCallback?.();
    yield put(relateEntities.success({ deal, person }));
  } catch (error) {
    failureCallback?.();
    yield put(relateEntities.failure());
    yield put(handleError({ error }));
  }
}

export function* onDownloadActivityFile({
  payload: file,
}: ReturnType<typeof downloadActivityFile>) {
  try {
    const org: Organization = yield select(getOrganization);
    const activity: Activity = yield select(getEditedActivity);
    const fileBlob: Blob = yield callApi(
      "fetchFile",
      org.id,
      file.id,
      true,
      false,
      EntityType.ACTIVITY,
      activity.id,
      { responseType: "blob" }
    );

    yield call(downloadFileByUrl, window.URL.createObjectURL(fileBlob), file.name);
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* createEditActivityModalSaga() {
  yield takeLatest(initializeActivityModal.request, onInitializeActivityModal);
  yield takeLatest(initializeEditActivityModal.request, onInitializeEditActivityModal);
  yield takeLatest(changeAssociatedEntities.request, onChangeAssociatedEntity);
  yield takeLatest(createActivity.request, onCreateActivity);
  yield takeLatest(updateActivity.request, onUpdateActivity);
  yield takeLatest(deleteActivity.request, onDeleteActivity);
  yield takeEvery(uploadCreateEditActivityFiles.request, onUploadCreateEditActivityFiles);
  yield takeEvery(removeCreateEditActivityFile.request, onRemoveCreateEditActivityFile);
  yield takeEvery(fetchFilePreview.request, onFetchFilePreview);
  yield takeEvery(downloadActivityFile, onDownloadActivityFile);
  yield takeEvery(relateEntities.request, onRelateEntities);
}

@use "../../../styles/colors";

//TODO: remove this file
.togglerButton {
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.active {
  background-color: colors.$mmc-primary-010;
  color: colors.$mmc-primary-100;
}

import { parseApiDate } from "../parsers";
import ActivityStatus from "@mapmycustomers/shared/enum/activity/ActivityStatus";
import Activity from "@mapmycustomers/shared/types/entity/Activity";
import { addMinutes, getHours, getMinutes, getSeconds, set } from "date-fns/esm";
import PostponedDateGetter from "@mapmycustomers/shared/types/entity/activities/PostponedDateGetter";
import getActivityStatus from "./getActivityStatus";

const postponeActivity = (activity: Activity, postponedDateGetter: PostponedDateGetter) => {
  const currentStartAt = parseApiDate(activity.startAt ?? "");
  const updatedStartAt = postponedDateGetter(
    getActivityStatus(activity) === ActivityStatus.OVERDUE
      ? set(Date.now(), {
          hours: getHours(currentStartAt),
          minutes: getMinutes(currentStartAt),
          seconds: getSeconds(currentStartAt),
        })
      : currentStartAt
  );

  return {
    ...activity,
    startAt: updatedStartAt.toISOString(),
    endAt: activity.duration ? addMinutes(updatedStartAt, activity.duration).toISOString() : null,
  };
};

export default postponeActivity;

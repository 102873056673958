import { IntlShape } from "react-intl";
import { useMemo } from "react";
import { RuleObject } from "rc-field-form/lib/interface";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";

const useActivityTypeValidationRules = (
  intl: IntlShape,
  unavailableActivityType?: Pick<ActivityType, "id" | "name">
) =>
  useMemo(
    () => [
      {
        validator: (rule: RuleObject, value?: ActivityType["id"]) => {
          if (!value && unavailableActivityType) {
            return Promise.reject(
              intl.formatMessage({
                id: "createEditActivityModal.field.activityType.deletedType",
                defaultMessage:
                  "Previous type has been deleted or inactive. Please select a new type.",
                description:
                  "Activity Type field on the Edit Activity modal error - type is deleted",
              })
            );
          }
          return Promise.resolve();
        },
      },
      {
        required: !unavailableActivityType,
      },
    ],
    [intl, unavailableActivityType]
  );

export default useActivityTypeValidationRules;

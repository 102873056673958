import { ActionType, createAction } from "typesafe-actions";
import { Person } from "@mapmycustomers/shared/types/entity";
import ApiService from "store/api/ApiService";

// WARNING! Only use this action when you can't get person from the store
export const fetchPerson = createAction("@person/fetchPerson")<{
  id: Person["id"];
  options?: Parameters<ApiService["fetchPerson"]>[2];
  callback: (person: Person) => void;
  failureCallback?: () => void;
}>();

export type Actions = ActionType<typeof fetchPerson>;

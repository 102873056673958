import React from "react";
import ActivityTypeIcon from "component/activity/ActivityTypeIcon";
import SectionLayout from "../SectionLayout";
import { Activity, Company, Person } from "@mapmycustomers/shared/types/entity";
import Tooltip from "antd/es/tooltip";
import AddressLine from "./AddressLine";
import styles from "./TopSection.module.scss";
import ActivityDate from "component/activity/ActivityDate";
import ActivityAssignee from "component/activity/ActivityAssignee";

interface Props {
  activity: Activity;
  addressRecord: Company | Person | undefined;
  className?: string;
}

const TopSection: React.FC<Props> = ({ activity, addressRecord, className }) => {
  return (
    <SectionLayout
      className={className}
      left={<ActivityTypeIcon activityType={activity.crmActivityType} size="md" />}
    >
      <Tooltip mouseEnterDelay={1} title={activity.name}>
        <div className={styles.title}>{activity.name}</div>
      </Tooltip>
      <AddressLine addressRecord={addressRecord} />
      <div className={styles.dateAssignee}>
        <ActivityDate activity={activity} />
        <ActivityAssignee activity={activity} />
      </div>
    </SectionLayout>
  );
};

export default TopSection;

import { createReducer } from "typesafe-actions";
import { Actions, updateActivity } from "./actions";

export interface ActivityInfoModalState {
  loading: boolean;
}

const initialState: ActivityInfoModalState = {
  loading: false,
};

const activityInfoModal = createReducer<ActivityInfoModalState, Actions>(initialState)
  .handleAction(updateActivity.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(updateActivity.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(updateActivity.failure, (state, action) => ({
    ...state,
    loading: false,
  }));

export * from "./selectors";
export type ActivityInfoModalActions = Actions;
export default activityInfoModal;

import { call, takeLatest } from "redux-saga/effects";
import { hideInSession } from "./actions";
import localSettings from "../../../../config/LocalSettings";

function* onHideInSession() {
  yield call(localSettings.setLocationReminderAllowed, false);
}

export function* userLocationRequestSaga() {
  yield takeLatest(hideInSession, onHideInSession);
}

import CreateActivityModal from "component/createEditEntity/Activity/CreateActivityModal";
import { isCreateActivityModalVisible } from "component/createEditEntity/Activity/store";
import { hideGlobalCreateActivityModal } from "component/createEditEntity/Activity/store/actions";
import CreateCompanyModal from "component/createEditEntity/CreateCompanyModal";
import { isCreateCompanyModalVisible } from "component/createEditEntity/CreateCompanyModal/store";
import { hideGlobalCreateCompanyModal } from "component/createEditEntity/CreateCompanyModal/store/actions";
import CreateDealModal from "component/createEditEntity/CreateDealModal";
import { isCreateDealModalVisible } from "component/createEditEntity/CreateDealModal/store";
import { hideGlobalCreateDealModal } from "component/createEditEntity/CreateDealModal/store/actions";
import CreatePersonModal from "component/createEditEntity/CreatePersonModal";
import { isCreatePersonModalVisible } from "component/createEditEntity/CreatePersonModal/store";
import { hideGlobalCreatePersonModal } from "component/createEditEntity/CreatePersonModal/store/actions";
import PostCreationModal from "component/PostCreationModal";
import { isPostCreationModalVisible } from "component/PostCreationModal/store";
import { hideGlobalPostCreationModal } from "component/PostCreationModal/store/actions";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "store/rootReducer";

interface Props {
  createActivityModalVisible: boolean;
  createCompanyModalVisible: boolean;
  createDealModalVisible: boolean;
  createPersonModalVisible: boolean;
  onHideCreateActivityModal: () => void;
  onHideCreateCompanyModal: () => void;
  onHideCreateDealModal: () => void;
  onHideCreatePersonModal: () => void;
  onHideGlobalPostCreationModal: () => void;
  postCreationModalVisible: boolean;
}

const GlobalCreateEntityModals: React.FC<Props> = ({
  createActivityModalVisible,
  createCompanyModalVisible,
  createDealModalVisible,
  createPersonModalVisible,
  onHideCreateActivityModal,
  onHideCreateCompanyModal,
  onHideCreateDealModal,
  onHideCreatePersonModal,
  onHideGlobalPostCreationModal,
  postCreationModalVisible,
}) => {
  return (
    <div>
      {createActivityModalVisible && <CreateActivityModal onHide={onHideCreateActivityModal} />}
      {createCompanyModalVisible && <CreateCompanyModal onHide={onHideCreateCompanyModal} />}
      {createDealModalVisible && <CreateDealModal onHide={onHideCreateDealModal} />}
      {createPersonModalVisible && <CreatePersonModal onHide={onHideCreatePersonModal} />}
      {postCreationModalVisible && <PostCreationModal onHide={onHideGlobalPostCreationModal} />}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  createActivityModalVisible: isCreateActivityModalVisible(state),
  createCompanyModalVisible: isCreateCompanyModalVisible(state),
  createDealModalVisible: isCreateDealModalVisible(state),
  createPersonModalVisible: isCreatePersonModalVisible(state),
  postCreationModalVisible: isPostCreationModalVisible(state),
});

const mapDispatchToProps = {
  onHideCreateActivityModal: hideGlobalCreateActivityModal,
  onHideCreateCompanyModal: hideGlobalCreateCompanyModal,
  onHideCreateDealModal: hideGlobalCreateDealModal,
  onHideCreatePersonModal: hideGlobalCreatePersonModal,
  onHideGlobalPostCreationModal: hideGlobalPostCreationModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalCreateEntityModals);

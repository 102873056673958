import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  bulkAddEntitiesToGroups,
  bulkAddEntitiesToRoutes,
  bulkRemoveEntitiesFromGroups,
  bulkRemoveEntitiesFromRoutes,
  deleteEntities,
  filterRoutes,
  initializeRouteModal,
  updateEntities,
} from "./actions";
import { defineMessages } from "react-intl";
import notification from "antd/es/notification";
import {
  Company,
  Deal,
  EntitiesSupportingBulkEdit,
  EntitiesSupportingRoutes,
  EntityTypesSupportingBulkEdit,
  EntityTypeSupportingCustomFields,
  Person,
  Route,
} from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import { GeoManagementState } from "@mapmycustomers/shared/types/base/Located";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { getEntityTypeDisplayName } from "util/ui";
import { getOrganizationId } from "store/iam";
import { reloadGroups } from "store/groups/actions";
import { callApi } from "store/api/callApi";
import i18nService from "config/I18nService";
import { handleError } from "store/errors/actions";
import { DealFieldName } from "util/fieldModel/DealFieldModel";
import { isCustomField } from "util/fieldModel/impl/assert";
import { notifyAboutChanges } from "store/uiSync/actions";
import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import uniq from "lodash-es/uniq";
import { MAX_ROUTE_STOPS } from "util/consts";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import formatCountryName from "util/countries/formatCountryName";
import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import getLayoutModelByEntityType from "util/layout/impl";

const messages = defineMessages({
  successfullyDeleted: {
    id: "selectBar.delete.success",
    defaultMessage: "{entityName} Deleted",
    description: "Entities are deleted successfully from the Select Bar",
  },
  successfullyUpdated: {
    id: "selectBar.update.success",
    defaultMessage: "{count} {entityName} updated",
    description: "Entities are updated successfully from the Bulk Edit Modal",
  },
  successfullyAddedToGroups: {
    id: "selectBar.groups.add.success",
    defaultMessage: "{count} {entityName} added to group(s)",
    description: "Entities are added successfully into the groups using select bar",
  },
  successfullyDeletedFromGroups: {
    id: "selectBar.groups.delete.success",
    defaultMessage: "{count} {entityName} deleted from group(s)",
    description: "Entities are deleted successfully from the groups using select bar",
  },
  successfullyAddedToRoutes: {
    id: "selectBar.routes.add.success",
    defaultMessage:
      "{count} {entityName} added to{routeCount, plural, one {} other { {routeCount}}} {routeCount, plural, one {route} other {routes}}",
    description: "Entities are added successfully into the routes using select bar",
  },
  successfullyDeletedFromRoutes: {
    id: "selectBar.routes.delete.success",
    defaultMessage:
      "{count} {entityName} deleted from {routeCount, plural, one {route} other {routes}}",
    description: "Entities are deleted successfully from the routes using select bar",
  },
  failedTitle: {
    id: "selectBar.routes.add.error.title",
    defaultMessage: "Unable to add to “{routeName}”",
    description: "Title of a notification to show when failed to bulk add to route",
  },
  failedTooManyStops: {
    id: "selectBar.routes.add.error.tooManyStops",
    defaultMessage:
      "There are too many existing stops to build the route. Please remove stops before attempting to add new ones.",
    description:
      "An error message to appear when trying to bulk add entities to route, but there will be too many stops after that",
  },
  failedToBuild: {
    id: "selectBar.routes.add.error.cantBuild",
    defaultMessage:
      "We were unable to construct a route across the given terrain. Please double check that all intended stops can be navigated to.",
    description:
      "An error message to appear when trying to bulk add entities to route, but resulting route is not possible to build",
  },
});

export function* onDeleteEntities({
  payload: { entityIds, entityType, onSuccess },
}: ReturnType<typeof deleteEntities.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    yield callApi("deleteEntities", orgId, entityType, entityIds);

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.successfullyDeleted, {
          count: entityIds.length,
          entityName: getEntityTypeDisplayName(intl, entityType, {
            lowercase: false,
            plural: entityIds.length > 1,
          }),
        }),
      });
    }

    yield put(deleteEntities.success());
    onSuccess();
  } catch (error) {
    yield put(deleteEntities.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateEntities({
  payload: { field, value, entities, entityType, onSuccess },
}: ReturnType<typeof updateEntities.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const intl = i18nService.getIntl();

    // since the bulk edit endpoints don't return full payloads back, we're simulating update to
    // pass it into notifyAboutChanges
    let updatedEntities: EntitiesSupportingBulkEdit[] = [];

    const layoutModel = getLayoutModelByEntityType(entityType);

    if (isCustomField(field)) {
      yield callApi(
        "bulkUpsertCustomFieldsValues",
        true,
        orgId,
        entityType as EntityTypeSupportingCustomFields,
        entities.map((entity) => ({
          id: entity.id,
          // We know that the layoutModel we received has same EntityType as entities
          // @ts-ignore
          layoutId: layoutModel.getLayoutFor(entity).id,
        })),
        [value as CustomFieldValue]
      );

      updatedEntities = entities.map((entity) => ({
        ...entity,
        customFields: (entity.customFields ?? []).map((oldValue) =>
          oldValue.esKey === field.name
            ? { ...oldValue, value: (value as CustomFieldValue).value }
            : oldValue
        ),
      }));
    } else {
      yield callApi(
        "updateEntities",
        orgId,
        entityType,
        entities.map(({ geoAddress, id }) => {
          let updatedEntity: Partial<Company | Deal | Person> = { id };
          if (field.hasFeature(FieldFeature.BULK_ADDRESS)) {
            if (typeof value === "object" && value) {
              updatedEntity = {
                ...updatedEntity,
                geoManagementState: GeoManagementState.AUTOMATIC_PRESERVE_ADDRESS,
                geoPoint: null,
                ...value,
              };

              updatedEntities = entities.map((entity) => ({
                ...entity,
                geoManagementState: GeoManagementState.AUTOMATIC_PRESERVE_ADDRESS,
                geoPoint: null,
                ...value,
              }));
            }
          } else if (
            field.name === DealFieldName.FUNNEL ||
            field.hasFeature(FieldFeature.FREQUENCY_INTERVAL)
          ) {
            if (typeof value === "object" && value) {
              updatedEntity = {
                ...updatedEntity,
                ...value,
              };
              updatedEntities = entities.map((entity) => ({ ...entity, ...value }));
            }
          } else {
            const updatedEntityRow = { [field.name]: value };
            // Ignoring because we're kinda sure that the field passed in this action is always
            // related to the given entityType. Hence, this expression is valid.
            // @ts-ignore
            updatedEntity[field.name] = value;
            if (field.hasFeature(FieldFeature.COUNTRY_FIELD) && intl) {
              const countryName = formatCountryName(intl, value as CountryCode);
              updatedEntityRow.country = countryName;
              // since we're now processing the country field, we know that such field exists on the updatedEntity object
              // @ts-ignore
              updatedEntity.country = countryName;
              updatedEntity.geoManagementState = GeoManagementState.MANUAL;
              updatedEntity.geoAddress = {
                ...geoAddress,
                country: updatedEntityRow.country,
                countryCode: updatedEntityRow.countryCode,
              };
            }

            updatedEntities = entities.map((entity) => ({ ...entity, ...updatedEntityRow }));
          }

          return updatedEntity;
        })
      );
    }

    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.successfullyUpdated, {
          count: entities.length,
          entityName: getEntityTypeDisplayName(intl, entityType, {
            lowercase: false,
            plural: entities.length > 1,
          }),
        }),
      });
    }

    yield put(updateEntities.success());
    yield put(notifyAboutChanges({ entityType, updated: updatedEntities }));
    onSuccess();
  } catch (error) {
    yield put(updateEntities.failure());
    yield put(handleError({ error }));
  }
}

export function* onBulkAddEntitiesToGroups({
  payload: { entities, entityType, groupIds, onSuccess },
}: ReturnType<typeof bulkAddEntitiesToGroups.request>) {
  const filteredEntities = entities.filter(({ entity }) => entity === entityType);
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield all(
      groupIds.map((groupId) =>
        callApi(
          "addToGroup",
          orgId,
          groupId,
          entityType,
          filteredEntities.map(({ id }) => id)
        )
      )
    );

    yield put(reloadGroups({ entityType }));

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.successfullyAddedToGroups, {
          count: filteredEntities.length,
          entityName: getEntityTypeDisplayName(intl, entityType, {
            lowercase: false,
            plural: filteredEntities.length > 1,
          }),
        }),
      });
    }

    yield put(bulkAddEntitiesToGroups.success());

    onSuccess();
  } catch (error) {
    yield put(bulkAddEntitiesToGroups.failure());
    yield put(handleError({ error }));
  }
}

export function* onBulkRemoveEntitiesFromGroups({
  payload: { entities, entityType, groupIds, onSuccess },
}: ReturnType<typeof bulkRemoveEntitiesFromGroups.request>) {
  const filteredEntities = entities.filter(({ entity }) => entity === entityType);
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield all(
      groupIds.map((groupId) =>
        callApi(
          "deleteFromGroup",
          orgId,
          groupId,
          entityType,
          filteredEntities.map(({ id }) => id)
        )
      )
    );

    yield put(reloadGroups({ entityType }));

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.successfullyDeletedFromGroups, {
          count: filteredEntities.length,
          entityName: getEntityTypeDisplayName(intl, entityType, {
            lowercase: false,
            plural: filteredEntities.length > 1,
          }),
        }),
      });
    }

    yield put(bulkRemoveEntitiesFromGroups.success());

    onSuccess();
  } catch (error) {
    yield put(bulkRemoveEntitiesFromGroups.failure());
    yield put(handleError({ error }));
  }
}

type BulkAddResult = { success: boolean; message?: string; routeName?: string };

function* getEntityIdsForRoutes(
  entities: EntitiesSupportingBulkEdit[],
  entityType: EntityTypesSupportingBulkEdit,
  includeDeals: boolean
) {
  const orgId: Organization["id"] = yield select(getOrganizationId);
  const entityIds = new Set<EntitiesSupportingRoutes["id"]>();
  const dealIds: Deal["id"][] = [];
  entities.forEach((entity) => {
    if (entity.entity === entityType) {
      entityIds.add(entity.id);
    }
    if (includeDeals && entity.entity === EntityType.DEAL) {
      dealIds.push(entity.id);
    }
  });
  if (dealIds.length) {
    const dealResponse: ListResponse<Deal> = yield callApi("fetchDeals", orgId, {
      $filters: { $and: [{ id: { $in: dealIds } }] },
    });
    dealResponse.data.forEach((deal) => {
      if (entityType === EntityType.COMPANY && deal.account) {
        entityIds.add(deal.account.id);
      } else if (entityType === EntityType.PERSON && deal.contact) {
        entityIds.add(deal.contact.id);
      }
    });
  }
  return Array.from(entityIds);
}

export function* onBulkAddEntitiesToRoutes({
  payload: { entities, entityType, routeIds, onSuccess, includeDeals },
}: ReturnType<typeof bulkAddEntitiesToRoutes.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityIds: EntitiesSupportingRoutes["id"][] = yield getEntityIdsForRoutes(
      entities,
      entityType,
      includeDeals
    );
    const results: BulkAddResult[] = yield all(
      routeIds.map(function* (routeId: Route["id"]): Generator<unknown, BulkAddResult, any> {
        const route: Route =
          entityType === EntityType.COMPANY
            ? yield callApi("fetchCompanyRoute", orgId, routeId, true, {
                includeAccessStatus: true,
              })
            : yield callApi("fetchPeopleRoute", orgId, routeId, true, {
                includeAccessStatus: true,
              });

        const previewPayload = {
          routeType: route.routeDetail.type,
          startGeoPoint: route.routeDetail.startGeoPoint,
          endGeoPoint: route.routeDetail.endGeoPoint,
          routeAccounts:
            entityType === EntityType.COMPANY
              ? // using uniq to preserve stops order
                uniq([...(route.routeAccounts ?? []).map(({ id }) => id), ...entityIds])
              : undefined,
          routeContacts:
            entityType === EntityType.PERSON
              ? // using uniq to preserve stops order
                uniq([...(route.routeContacts ?? []).map(({ id }) => id), ...entityIds])
              : undefined,
        };

        const updatedEntityIds = previewPayload.routeAccounts ?? previewPayload.routeContacts;
        if (updatedEntityIds!.length > MAX_ROUTE_STOPS) {
          return {
            success: false,
            message: i18nService.formatMessage(messages.failedTooManyStops),
            routeName: route.name,
          };
        }

        try {
          yield callApi("buildRoute", orgId, entityType, previewPayload);
        } catch (e) {
          return {
            success: false,
            message: i18nService.formatMessage(messages.failedToBuild),
            routeName: route.name,
          };
        }

        yield callApi(
          "updateRouteStops",
          orgId,
          entityType,
          routeId,
          entityIds,
          route.routeDetail.allottedTime
        );

        return { success: true };
      })
    );

    const failedResults = results.filter(({ success }) => !success);

    const intl = i18nService.getIntl();
    if (intl) {
      if (failedResults.length < results.length) {
        notification.success({
          message: intl.formatMessage(messages.successfullyAddedToRoutes, {
            count: entities.length,
            entityName: getEntityTypeDisplayName(intl, entityType, {
              lowercase: false,
              plural: entities.length > 1,
            }),
            routeCount: results.length - failedResults.length,
          }),
        });
      }

      failedResults.forEach(({ message, routeName }) =>
        notification.error({
          message: i18nService.formatMessage(messages.failedTitle, "", {
            routeName: routeName!,
          }),
          description: message,
        })
      );
    }

    yield put(bulkAddEntitiesToRoutes.success());

    onSuccess();
  } catch (error) {
    yield put(bulkAddEntitiesToRoutes.failure());
    yield put(handleError({ error }));
  }
}

export function* onBulkRemoveEntitiesFromRoutes({
  payload: { entities, entityType, routeIds, onSuccess, includeDeals },
}: ReturnType<typeof bulkRemoveEntitiesFromRoutes.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityIds: EntitiesSupportingRoutes["id"][] = yield getEntityIdsForRoutes(
      entities,
      entityType,
      includeDeals
    );
    yield all(
      routeIds.map((routeId) =>
        callApi(
          entityType === EntityType.COMPANY ? "removeCompaniesFromRoute" : "removePeopleFromRoute",
          orgId,
          routeId,
          entityIds
        )
      )
    );

    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        message: intl.formatMessage(messages.successfullyDeletedFromRoutes, {
          count: entities.length,
          entityName: getEntityTypeDisplayName(intl, entityType, {
            lowercase: false,
            plural: entities.length > 1,
          }),
          routeCount: routeIds.length,
        }),
      });
    }

    yield put(bulkRemoveEntitiesFromRoutes.success());

    onSuccess();
  } catch (error) {
    yield put(bulkRemoveEntitiesFromRoutes.failure());
    yield put(handleError({ error }));
  }
}

export function* onInitializeRouteModal({
  payload: { entities, entityType, includeDeals, callback },
}: ReturnType<typeof initializeRouteModal.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityIds: EntitiesSupportingRoutes["id"][] = yield getEntityIdsForRoutes(
      entities,
      entityType,
      includeDeals
    );

    const allRoutesResponse: ListResponse<Route> =
      entityType === EntityType.COMPANY
        ? yield callApi("fetchCompanyRoutes", orgId, {
            $filters: {
              includeAccessStatus: true,
            },
            $limit: 1000,
            $order: ["name"],
          })
        : yield callApi("fetchPeopleRoutes", orgId, {
            $filters: {
              includeAccessStatus: true,
            },
            $limit: 1000,
            $order: ["name"],
          });

    const response: ListResponse<Route> = yield entityType === EntityType.COMPANY
      ? callApi("fetchCompanyRoutes", orgId, {
          $filters: {
            $and: [
              {
                accountId: { $in: entityIds },
              },
            ],
          },
        })
      : callApi("fetchPeopleRoutes", orgId, {
          $filters: {
            $and: [
              {
                contactId: { $in: entityIds },
              },
            ],
          },
        });
    if (callback) {
      yield call(callback);
    }
    yield put(
      initializeRouteModal.success({
        existingRoutesIds: response.data.map(({ id }) => id),
        routes: allRoutesResponse.data.filter(({ accessStatus }) => accessStatus.update),
        totalRoutes: allRoutesResponse.accessible,
      })
    );
  } catch (error) {
    yield put(initializeRouteModal.failure());
    yield put(handleError({ error }));
  }
}

export function* onFilterRoutes({ payload }: ReturnType<typeof filterRoutes.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const routes: ListResponse<Route> = yield callApi(
      payload.entityType === EntityType.COMPANY ? "fetchCompanyRoutes" : "fetchPeopleRoutes",
      orgId,
      {
        $limit: 100,
        $order: ["name"],
        $filters: {
          includeAccessStatus: true,
          name: payload.query ? { $in: payload.query } : undefined,
        },
      }
    );
    yield put(filterRoutes.success(routes.data));
  } catch (error) {
    yield put(filterRoutes.failure());
    yield put(handleError({ error }));
  }
}

export function* selectBarSaga() {
  yield takeLatest(deleteEntities.request, onDeleteEntities);
  yield takeLatest(bulkAddEntitiesToGroups.request, onBulkAddEntitiesToGroups);
  yield takeLatest(bulkRemoveEntitiesFromGroups.request, onBulkRemoveEntitiesFromGroups);
  yield takeLatest(bulkAddEntitiesToRoutes.request, onBulkAddEntitiesToRoutes);
  yield takeLatest(bulkRemoveEntitiesFromRoutes.request, onBulkRemoveEntitiesFromRoutes);
  yield takeLatest(updateEntities.request, onUpdateEntities);
  yield takeLatest(initializeRouteModal.request, onInitializeRouteModal);
  yield takeLatest(filterRoutes.request, onFilterRoutes);
}

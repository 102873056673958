import { defineMessages, IntlShape } from "react-intl";

enum AlertBadgesType {
  OVERDUE = "overdue",
  OUT_OF_CADENCE_SOON = "outOfCadenceSoon",
  UPCOMING_ACTIVITY = "upcomingActivity",
}

export const messages = defineMessages({
  [AlertBadgesType.OVERDUE]: {
    id: "map.layer.alertBadges.overdueActivities",
    defaultMessage: "Attention Required",
    description: "Alert badges legend - Overdue Activities",
  },
  [AlertBadgesType.OUT_OF_CADENCE_SOON]: {
    id: "map.layer.alertBadges.cadenceUpcoming",
    defaultMessage: "Frequency Upcoming",
    description: "Alert badges legend - Frequency Upcoming",
  },
  [AlertBadgesType.UPCOMING_ACTIVITY]: {
    id: "map.layer.alertBadges.nextActivityScheduled",
    defaultMessage: "Activity Soon",
    description: "Alert badges legend - Next Activity Scheduled",
  },
});

export const descriptionMessages = defineMessages({
  [AlertBadgesType.OVERDUE]: {
    id: "map.layer.alertBadges.overdueActivities.description",
    defaultMessage: "Record has an overdue activity or its frequency status is “Past Due”",
    description: "Alert badges legend description - Overdue Activities",
  },
  [AlertBadgesType.OUT_OF_CADENCE_SOON]: {
    id: "map.layer.alertBadges.cadenceUpcoming.description",
    defaultMessage: "Record’s frequency status is “Upcoming”",
    description: "Alert badges legend description - frequency Upcoming",
  },
  [AlertBadgesType.UPCOMING_ACTIVITY]: {
    id: "map.layer.alertBadges.nextActivityScheduled.description",
    defaultMessage: "Record has an activity scheduled in the next week",
    description: "Alert badges legend description - Next Activity Scheduled",
  },
});

export const getAlertBadgesTypeName = (intl: IntlShape, type: AlertBadgesType) =>
  intl.formatMessage(messages[type]);

export const getAlertBadgesTypeDescription = (intl: IntlShape, type: AlertBadgesType) =>
  intl.formatMessage(descriptionMessages[type]);

export default AlertBadgesType;

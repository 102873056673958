import React, { CSSProperties, ReactNode, useCallback, useEffect, useState } from "react";
import MapContent from "./MapContent";
import cn from "classnames";
import styles from "./FeaturedMap.module.scss";

interface Props {
  className?: string;
  children: ReactNode;
  initialOptions: google.maps.MapOptions;
  mapClassName?: string;
  onInitialized?: (map: google.maps.Map) => void;
  style?: CSSProperties;
}

const FeaturedMap: React.FC<Props> = ({
  className,
  children,
  initialOptions,
  mapClassName,
  onInitialized,
  style,
}) => {
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  // not just have a map, but map is drawn and has bounds
  const [initialized, setInitialized] = useState<boolean>(false);

  const initializeMap = useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        setMap((oldMap) => {
          // only create new map if not created already
          if (oldMap) {
            return oldMap;
          }
          const map = new google.maps.Map(ref, initialOptions);
          // we wait for the very first idle event to consider map loaded
          // it's when you can call getBounds and other methods
          google.maps.event.addListenerOnce(map, "idle", function () {
            setInitialized(true);
            onInitialized?.(map);
          });
          return map;
        });
      } else {
        setMap(undefined);
        setInitialized(false);
      }
    },
    // disable deps check, we don't want to rebuild the map when the `initialOptions` prop change
    // (or any other prop, e.g. onInitialized)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    return () => {
      // There's a huge problem with destroying Google Maps.
      // In short: there's no official way to do that (this sucks).
      // However, it looks like releasing an instance itself, plus
      // removing all listeners should help JS GC to clean it up.
      // Hence, removing all listeners...
      // Additional info: https://stackoverflow.com/q/10485582/5346779
      map && google.maps.event.clearInstanceListeners(map);
    };
  }, [map]);

  return (
    <div className={cn(styles.container, className)} style={style}>
      <div className={cn(styles.map, mapClassName)} ref={initializeMap} />
      {map && initialized && (
        <MapContent className={styles.overlay} map={map}>
          {children}
        </MapContent>
      )}
    </div>
  );
};

export default FeaturedMap;

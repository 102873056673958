import ActivityStatus from "@mapmycustomers/shared/enum/activity/ActivityStatus";
import { parseApiDate } from "util/parsers";
import isValidDate from "util/isValidDate";
import { isPast } from "date-fns/esm";
import { Activity } from "@mapmycustomers/shared/types/entity";

const getActivityStatus = (activity: Activity): ActivityStatus => {
  if (activity.completed) {
    return ActivityStatus.COMPLETED;
  }
  const startAt = parseApiDate(activity.startAt ?? "");
  if (!isValidDate(startAt)) {
    return ActivityStatus.UNKNOWN;
  }
  return isPast(startAt) ? ActivityStatus.OVERDUE : ActivityStatus.UPCOMING;
};

export default getActivityStatus;

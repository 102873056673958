import AreaSearchMode from "enum/AreaSearchMode";
import pick from "lodash-es/pick";
import { defineMessages, IntlShape } from "react-intl";

type GeoPointSortType =
  | AreaSearchMode.CURRENT_USER_LOCATION
  | AreaSearchMode.ENTITY
  | AreaSearchMode.ADDRESS;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const GeoPointSortType = pick(AreaSearchMode, ["CURRENT_USER_LOCATION", "ENTITY", "ADDRESS"]);

export const messages = defineMessages<GeoPointSortType>({
  [GeoPointSortType.CURRENT_USER_LOCATION]: {
    id: "recordList.customization.sort.type.geoPoint.currentLocation",
    defaultMessage: "Closest to my current location",
    description: "Your current location label",
  },
  [GeoPointSortType.ENTITY]: {
    id: "recordList.customization.sort.type.geoPoint.entity",
    defaultMessage: "Closest to a specific Company or Person",
    description: "Your current location label",
  },
  [GeoPointSortType.ADDRESS]: {
    id: "recordList.customization.sort.type.geoPoint.address",
    defaultMessage: "Closest to a specific address",
    description: "Your current location label",
  },
});

export const getGeopointSortTypeDisplayName = (
  intl: IntlShape,
  geoPointSortType: GeoPointSortType
) => intl.formatMessage(messages[geoPointSortType]);

export default GeoPointSortType;

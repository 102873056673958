import Iam from "../../../types/Iam";
import CurrentPlan from "@mapmycustomers/shared/types/plans/CurrentPlan";
import { parseApiDate } from "util/parsers";
import { convertPlanToTier } from "./tier";
import hasTrialExpired from "../../../util/hasTrialExpired";

const buildCurrentPlan = (me: Iam): CurrentPlan => {
  const { plan } = me.organization;
  let nextPaymentDate = new Date(); // now, will be used for current paid plans
  if (plan.trialing) {
    nextPaymentDate = parseApiDate(me.organization.trialExpiresAt);
  } else if (plan.nextPaymentDate) {
    nextPaymentDate = parseApiDate(plan.nextPaymentDate);
  }
  return {
    isOldPlan: !(plan.metaData && plan.metaData.licenseLimit),
    isExpired: hasTrialExpired(me.organization) ?? false,
    licenses: plan.trialing ? 0 : plan.quantity,
    nextPaymentDate,
    tier: convertPlanToTier(plan),
  };
};

export default buildCurrentPlan;

import commonStyles from "./AssociationResultOption.module.scss";
import {
  Company,
  Deal,
  EntityType,
  EntityTypesSupportedByActivities,
  Person,
} from "@mapmycustomers/shared/types/entity";
import Button from "antd/es/button";
import EntityBadge from "component/EntityBadge";

interface Props<T> {
  entity: T;
  entityType: EntityTypesSupportedByActivities;
}

const AssociationResultOption = <T extends Company | Deal | Person>({
  entity,
  entityType,
}: Props<T>) => {
  return (
    <Button className={commonStyles.container} type="link">
      <span>{entity.name}</span>
      <span className={commonStyles.associationInfo}>
        {entityType === EntityType.COMPANY || entityType === EntityType.PERSON ? (
          (entity as Company | Person).address ? (
            (entity as Company | Person).address
          ) : (
            "No Address"
          )
        ) : (
          <span>
            {" "}
            <EntityBadge
              className={commonStyles.funnelName}
              entity={(entity as Deal).stage}
              key={(entity as Deal).stage.id}
            />
            {" • "}
            <span>{(entity as Deal).funnel.name}</span>
          </span>
        )}
      </span>
    </Button>
  );
};

export default AssociationResultOption;

import { IntlShape } from "react-intl";
import CustomFieldType from "@mapmycustomers/shared/types/customField/CustomField";
import { RuleObject } from "rc-field-form/lib/interface";
import CustomFieldDataType from "@mapmycustomers/shared/enum/CustomFieldDataType";

const getCustomFieldsValidationRules = (intl: IntlShape, definition: CustomFieldType) => [
  {
    required: true,
    validator: (_: RuleObject, value: any) => {
      let hasValue = true;
      if (
        definition.dataType === CustomFieldDataType.TEXT ||
        definition.dataType === CustomFieldDataType.LARGE_TEXT ||
        definition.dataType === CustomFieldDataType.PHONE ||
        definition.dataType === CustomFieldDataType.ADDRESS ||
        definition.dataType === CustomFieldDataType.DATE ||
        definition.dataType === CustomFieldDataType.TIME
      ) {
        hasValue = !!value?.value[0]?.trim();
      } else if (definition.dataType === CustomFieldDataType.SINGLE_OPTION) {
        hasValue = value?.value !== undefined;
      } else if (definition.dataType === CustomFieldDataType.MULTI_OPTION) {
        hasValue = (value?.value?.length ?? 0) > 0;
      } else if (
        definition.dataType === CustomFieldDataType.INTEGER ||
        definition.dataType === CustomFieldDataType.DECIMAL
      ) {
        hasValue = !!value?.value?.length && value.value[0] !== undefined;
      } else if (definition.dataType === CustomFieldDataType.MONETARY) {
        hasValue = !!value?.value[0];
      }

      if (hasValue) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error(
          intl.formatMessage(
            {
              id: "customField.validation.error.message",
              defaultMessage: "Please enter {fieldName}",
              description: "Custom field validation error message",
            },
            {
              fieldName: definition.displayName,
            }
          )
        )
      );
    },
  },
];

export default getCustomFieldsValidationRules;

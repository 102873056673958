import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { EntityWithLocation } from "@mapmycustomers/shared/types/entity";
import Located from "@mapmycustomers/shared/types/base/Located";

export const enterPinLocationMode = createAction("map/enterPinLocationMode")<EntityWithLocation>();
export const exitPinLocationMode = createAction("map/exitPinLocationMode")<void>();

export const geocodePinLocation = createAsyncAction(
  "map/geocodePinLocation/request",
  "map/geocodePinLocation/success",
  "map/geocodePinLocation/failure"
)<
  GeolocationCoordinates,
  {
    addressChanged?: boolean;
    located?: Located;
  },
  void
>();

export const updatePinLocation = createAsyncAction(
  "map/updatePinLocation/request",
  "map/updatePinLocation/success",
  "map/updatePinLocation/failure"
)<{ exitMode: boolean } | void, void, void>();

export type PinLocationActions = ActionType<
  | typeof enterPinLocationMode
  | typeof exitPinLocationMode
  | typeof geocodePinLocation
  | typeof updatePinLocation
>;

import React from "react";
import PhoneNumber from "component/field/PhoneNumber";
import { RegistryProps } from "component/FieldGrid/utils/ComponentRegistry";

const PhoneNumberField: React.FC<RegistryProps> = ({ entity, field }) => {
  const value = field.getValueFor(entity) as string;
  return <PhoneNumber value={value} />;
};

export default PhoneNumberField;

import BaseMapStyle from "@mapmycustomers/shared/enum/map/BaseMapStyle";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages<BaseMapStyle>({
  [BaseMapStyle.STANDARD]: {
    id: "baseMapStyle.standard",
    defaultMessage: "Standard",
    description: "Standard base map style (roadmap)",
  },
  [BaseMapStyle.MUTED]: {
    id: "baseMapStyle.muted",
    defaultMessage: "Muted",
    description: "Muted base map style",
  },
  [BaseMapStyle.GRAY]: {
    id: "baseMapStyle.gray",
    defaultMessage: "Gray",
    description: "Gray base map style",
  },
  [BaseMapStyle.MIDNIGHT]: {
    id: "baseMapStyle.midnight",
    defaultMessage: "Midnight",
    description: "Midnight base map style",
  },
  [BaseMapStyle.HOPPER]: {
    id: "baseMapStyle.hopper",
    defaultMessage: "Hopper",
    description: "Hopper base map style",
  },
  [BaseMapStyle.ASSASSIAN_CREED]: {
    id: "baseMapStyle.assassiaCreed",
    defaultMessage: "Assassian Creed",
    description: "Assassian Creed base map style",
  },
  [BaseMapStyle.SUBTLE_GRAY]: {
    id: "baseMapStyle.subtleGray",
    defaultMessage: "Subtle Gray",
    description: "Subtle gray base map style",
  },
  [BaseMapStyle.TRIPPITY]: {
    id: "baseMapStyle.trippity",
    defaultMessage: "Trippity",
    description: "Trippity base map style",
  },
  [BaseMapStyle.NIGHT]: {
    id: "baseMapStyle.night",
    defaultMessage: "Night",
    description: "Night base map style",
  },
  [BaseMapStyle.SATELLITE]: {
    id: "baseMapStyle.satellite",
    defaultMessage: "Satellite",
    description: "Satellite base map style",
  },
  [BaseMapStyle.HYBRID]: {
    id: "baseMapStyle.hybrid",
    defaultMessage: "Hybrid",
    description: "Hybrid base map style",
  },
});

const getBaseMapStyleDisplayName = (intl: IntlShape, style: BaseMapStyle) =>
  intl.formatMessage(messages[style]);

export default getBaseMapStyleDisplayName;

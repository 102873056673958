import { UserRef } from "@mapmycustomers/shared/types/User";
import { userDisplayName } from "@mapmycustomers/shared/util/formatters";
import IConfigProviderContext from "ConfigProvider/types/IConfigProviderContext";

export const messages = {
  "ui.avatar.userDisplayNameWithInfo":
    "{username}{isDeleted, select, true { (Deleted)} other {}}{isDeactivated, select, true { (Deactivated)} other {}}",
};

export const getUserDisplayNameWithInfo = (
  configProvider: IConfigProviderContext,
  user: UserRef
) =>
  configProvider.formatMessage("ui.avatar.userDisplayNameWithInfo", {
    username: userDisplayName(user),
    isDeleted: !!user.deletedAt,
    isDeactivated: !!user.deactivated,
  });

import React, { useCallback } from "react";
import AuthLayout from "../AuthLayout";
import styles from "./LinkExpiredPage.module.scss";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Path from "enum/Path";
import Button from "antd/es/button";
import { resetErrors } from "store/auth/actions";
import { connect } from "react-redux";

interface Props {
  onResetErrors: typeof resetErrors;
}

export const LinkExpiredPage: React.FC<Props> = ({ onResetErrors }) => {
  const intl = useIntl();

  const handleClick = useCallback(() => {
    onResetErrors();
  }, [onResetErrors]);

  return (
    <AuthLayout>
      <div className={styles.container}>
        <p className={styles.title}>
          {intl.formatMessage({
            id: "auth.forgotPassword.linkExpired.title",
            defaultMessage: "Link Expired",
            description: "Title of link expired page",
          })}
        </p>
        <p className={styles.description}>
          {intl.formatMessage({
            id: "auth.forgotPassword.linkExpired.description",
            defaultMessage: "The password request has expired.",
            description: "Title of link expired page",
          })}
        </p>
        <Link to={Path.FORGOT_PASSWORD}>
          <Button onClick={handleClick} type="primary">
            {intl.formatMessage({
              id: "auth.forgotPassword.linkExpired.button.title",
              defaultMessage: "Send another request",
              description: "Title of send another request button",
            })}
          </Button>
        </Link>
      </div>
    </AuthLayout>
  );
};

const mapDispatchToProps = {
  onResetErrors: resetErrors,
};

export default connect(null, mapDispatchToProps)(LinkExpiredPage);

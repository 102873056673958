import SchemaFieldType, {
  SchemaFieldTypeFilter,
  SchemaFieldTypeValues,
} from "@mapmycustomers/shared/enum/SchemaFieldType";
import { defineMessages, IntlShape } from "react-intl";

export const schemaFieldTypeFilterNames = defineMessages<SchemaFieldTypeValues>({
  [SchemaFieldTypeFilter.ALL]: {
    id: "schemaFieldTypeFilter.all",
    defaultMessage: "All Field Types",
    description: "Entity field type filter - All Field Types",
  },
  [SchemaFieldTypeFilter.REQUIRED]: {
    id: "schemaFieldTypeFilter.required",
    defaultMessage: "System Required",
    description: "Schema field type filter - System Required",
  },
  [SchemaFieldType.STANDARD]: {
    id: "schemaFieldTypeFilter.standard",
    defaultMessage: "Standard",
    description: "Schema field type filter - Standard",
  },
  [SchemaFieldType.CUSTOM]: {
    id: "schemaFieldTypeFilter.custom",
    defaultMessage: "Custom",
    description: "Schema field type filter - Custom",
  },
  [SchemaFieldType.FILE]: {
    id: "entityFieldTypeFilter.file",
    defaultMessage: "Files",
    description: "Schema field type filter - File",
  },
  [SchemaFieldType.GROUP]: {
    id: "schemaFieldTypeFilter.group",
    defaultMessage: "Groups",
    description: "Schema field type filter - Group",
  },
  [SchemaFieldType.NOTE]: {
    id: "schemaFieldTypeFilter.note",
    defaultMessage: "Notes",
    description: "Schema field type filter - Note",
  },
  [SchemaFieldType.DIVIDER]: {
    id: "schemaFieldTypeFilter.divider",
    defaultMessage: "Divider",
    description: "Schema field type filter - Divider",
  },
});

const getSchemaFieldTypeFilterName = (intl: IntlShape, filter: SchemaFieldTypeValues) =>
  intl.formatMessage(schemaFieldTypeFilterNames[filter]);

export default getSchemaFieldTypeFilterName;

import React, { useCallback } from "react";
import CompanyEntitySearch, {
  CompanyEntitySearchProps,
} from "component/input/EntitySearch/CompanyEntitySearch";
import Form from "antd/es/form";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import CreateCompanyModal from "component/createEditEntity/CreateCompanyModal";
import { useIntl } from "react-intl";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import cn from "classnames";
import ChangeAssociationsPayload from "store/associations/ChangeAssociationsPayload";
import styles from "./CommonRelationshipField.module.scss";

interface Props extends Pick<CompanyEntitySearchProps, "allowAdd" | "disabled"> {
  associatedCompany?: Company;
  associatedDeal?: Deal;
  associatedPerson?: Person;
  companies?: Company[];
  field: IField;
  loading?: boolean;
  onChangeAssociatedEntities: (payload: Omit<ChangeAssociationsPayload, "entityType">) => void;
  required?: boolean;
}

const CompanyRelationshipField: React.FC<Props> = ({
  allowAdd,
  associatedCompany,
  associatedDeal,
  associatedPerson,
  companies,
  disabled,
  field,
  loading,
  onChangeAssociatedEntities,
  required,
}) => {
  const intl = useIntl();
  const form = useFormInstance();

  const [createNewCompanyVisible, showCreateCompanyModal, hideCreateCompanyModal] = useBoolean();

  const handleHideCreateCompanyModal = useDynamicCallback((newCompany?: Company) => {
    if (newCompany) {
      const person = (associatedPerson?.accounts ?? []).some(({ id }) => newCompany?.id === id)
        ? associatedPerson
        : undefined;
      const deal = associatedDeal?.account?.id === newCompany.id ? associatedDeal : undefined;
      form.setFieldsValue({ companyId: newCompany?.id, dealId: deal?.id, personId: person?.id });
      onChangeAssociatedEntities({
        assignee: form.getFieldValue("assignee"),
        company: newCompany,
        deal,
        person,
      });
    }
    hideCreateCompanyModal();
  });

  const canAdd = allowAdd ?? !disabled;

  const handleChangeAssociatedEntities = useCallback(
    (payload: Omit<ChangeAssociationsPayload, "entityType">) => {
      form.setFieldsValue({ dealId: payload.deal?.id, personId: payload.person?.id });
      onChangeAssociatedEntities(payload);
    },
    [form, onChangeAssociatedEntities]
  );

  return (
    <>
      <Form.Item
        className={cn({ [styles.shiftToTop]: canAdd })}
        label={field.displayName}
        name="companyId"
        required={required}
        rules={[{ required }]}
      >
        <CompanyEntitySearch
          allowAdd={canAdd}
          companies={companies}
          disabled={disabled}
          hideLabel
          loading={loading}
          onAddClick={showCreateCompanyModal}
          onChangeAssociatedEntities={handleChangeAssociatedEntities}
          selectedCompany={associatedCompany}
          selectedDeal={associatedDeal}
          selectedPerson={associatedPerson}
        />
      </Form.Item>

      {createNewCompanyVisible && (
        <CreateCompanyModal
          okText={intl.formatMessage({
            id: "associations.companies.addNewCompanyModal.okButton",
            defaultMessage: "Create and Add Company",
            description:
              "Create button title on Create Company modal when called from Select Companies modal",
          })}
          onHide={handleHideCreateCompanyModal}
        />
      )}
    </>
  );
};

export default CompanyRelationshipField;

import { fork, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { showEntityView, showRandomRecord } from "./actions";
import { channel } from "redux-saga";
import { cancelled } from "redux-saga-test-plan/matchers";
import { push } from "connected-react-router";
import { handleError } from "store/errors/actions";
import { callApi } from "store/api/callApi";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { Company } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import { getOrganizationId } from "store/iam";

export let showEntityChannel = channel();

export function* onPreview(): Generator {
  showEntityChannel = channel();
  try {
    while (true) {
      const showEntityAction = yield take(showEntityChannel);
      yield put(showEntityAction as ReturnType<typeof showEntityView>);
    }
  } finally {
    if (yield cancelled()) {
      showEntityChannel.close();
    }
  }
}

export function* onShowEntityView({ payload: { tab } }: ReturnType<typeof showEntityView>) {
  if (tab) {
    yield put(push(`#${tab}`));
  }
}

export function* onShowRandomRecord() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<Company> = yield callApi("fetchCompanies", orgId, {
      $limit: 1,
      $order: "-modifiedAt",
    });

    if (response.data.length) {
      const company = response.data[0];
      yield put(showEntityView({ entityId: company.id, entityType: company.entity }));
    }
  } catch (error) {
    handleError({ error });
  }
}

export function* entityViewSaga() {
  yield fork(onPreview);
  yield takeEvery(showEntityView, onShowEntityView);
  yield takeLatest(showRandomRecord, onShowRandomRecord);
}

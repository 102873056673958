import React from "react";
import { connect } from "react-redux";
import { getFrequencyPanelConfig } from "../../../../../store/frequency";
import FrequencyPreviewConfig from "../../../type/FrequencyPreviewConfig";
import FrequencySelector from "../../../FrequencySelector";
import { RootState } from "store/rootReducer";
import FrequencyInfo from "../FrequencyInfo";
import styles from "./FrequencySelectorWithConfig.module.scss";
import Cadenced from "@mapmycustomers/shared/types/base/Cadenced";
import useFrequencyContext from "../../util/useFrequencyContext";

interface Props {
  config: FrequencyPreviewConfig;
  onChange: (cadence: Partial<Cadenced>) => void;
}

const FrequencySelectorWithConfig: React.FC<Props> = ({
  config: { entity, isGroup },
  onChange,
}) => {
  const { isEntityFrequencyUpdating } = useFrequencyContext();

  return (
    <div className={styles.container}>
      <FrequencySelector
        entity={entity}
        frequencyIntervalValue={entity.cadenceInterval ?? undefined}
        isGroup={isGroup}
        onChange={onChange}
        updating={isEntityFrequencyUpdating}
      />
      <FrequencyInfo />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  config: getFrequencyPanelConfig(state),
});

export default connect(mapStateToProps)(FrequencySelectorWithConfig);

import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import displayOrderComparator from "./displayOrderComparator";

const typeOrderMap: Record<DealStageType, number> = {
  [DealStageType.DEFAULT]: 0,
  [DealStageType.WON]: 1,
  [DealStageType.LOST]: 2,
};

// order by type according to typeOrderMap or by display order if types are equal
const stageComparator = (a: Stage, b: Stage) =>
  typeOrderMap[a.type] - typeOrderMap[b.type] || displayOrderComparator(a, b);

export default stageComparator;

import { startOfDay, startOfMonth } from "date-fns/esm";
import {
  dayGranularityDateFormatter,
  minusDays,
  monthGranularityDateFormatter,
  SimpleRangeType,
  weekGranularityDateFormatter,
} from "./dateUtils";
import { getWeekStart } from "util/dates";

const SIMPLE_DATE_RANGE: SimpleRangeType[] = [
  {
    label: "Last 7 Days",
    getStartDate: minusDays(7),
    dateRounder: startOfDay,
    dateFormatter: dayGranularityDateFormatter,
    granularity: "day",
  },
  {
    label: "Last 30 Days",
    getStartDate: minusDays(30),
    dateRounder: getWeekStart,
    dateFormatter: weekGranularityDateFormatter,
    granularity: "week",
  },
  {
    label: "Last 90 Days",
    getStartDate: minusDays(90),
    dateRounder: startOfMonth,
    dateFormatter: monthGranularityDateFormatter,
    granularity: "month",
  },
];

export default SIMPLE_DATE_RANGE;

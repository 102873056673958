import { ActionType, createAsyncAction } from "typesafe-actions";
import Scope from "types/dashboard/Scope";
import Dashboard from "types/dashboard/Dashboard";
import { ActivitiesLoggedCardActions } from "scene/dashboard/store/cards/activitiesLogged/actions";
import { NewRecordsCardActions } from "./cards/newRecords/actions";
import { NoContactInCardActions } from "./cards/noContactIn/actions";
import { OutOfFrequencyCardActions } from "./cards/outOfFrequency/actions";
import { RecordsPastDueCardActions } from "scene/dashboard/store/cards/recordsPastDue/actions";
import { ActivitiesOverdueCardActions } from "scene/dashboard/store/cards/activitiesOverdue/actions";
import { CheckInsCardActions } from "scene/dashboard/store/cards/checkIns/actions";
import { StackRankActions } from "./stackRank/actions";
import ViewType from "scene/dashboard/enum/ViewType";
import TimelineEvent from "types/events/TimelineEvent";
import TimelineEventType from "scene/dashboard/enum/TimelineEventType";
import TimelineViewFilter from "scene/dashboard/enum/TimelineViewFilter";
import DashboardCard from "types/dashboard/DashboardCard";
import CardConfiguration, { GoalConfiguration } from "types/dashboard/CardConfiguration";

export * from "./cards/activitiesLogged/actions";
export * from "./cards/activitiesOverdue/actions";
export * from "./cards/checkIns/actions";
export * from "./cards/newRecords/actions";
export * from "./cards/noContactIn/actions";
export * from "./cards/outOfFrequency/actions";
export * from "./cards/recordsPastDue/actions";
export * from "./stackRank/actions";

export const initialize = createAsyncAction(
  "@dashboard/initialize/request",
  "@dashboard/initialize/success",
  "@dashboard/initialize/failure"
)<void, void, void>();

export const changeScope = createAsyncAction(
  "@dashboard/changeScope/request",
  "@dashboard/changeScope/success",
  "@dashboard/changeScope/failure"
)<
  { scope: Scope; view: ViewType },
  { view: ViewType; boards?: Dashboard[]; events?: TimelineEvent[]; oldestEventDate?: Date },
  void
>();

export const fetchTimelineEvents = createAsyncAction(
  "@dashboard/fetchTimelineEvents/request",
  "@dashboard/fetchTimelineEvents/success",
  "@dashboard/fetchTimelineEvents/failure"
)<
  {
    scope: Scope;
    eventTypes?: TimelineEventType[];
    startDate?: Date;
    query?: string;
    viewFilter?: TimelineViewFilter;
    onComplete?: () => void;
  },
  { append?: boolean; events: TimelineEvent[]; total?: number },
  unknown
>();

export const addCard = createAsyncAction(
  "@dashboard/addCard/request",
  "@dashboard/addCard/success",
  "@dashboard/addCard/failure"
)<{ card: DashboardCard; scope: Scope }, Dashboard, void>();

export const saveCard = createAsyncAction(
  "@dashboard/saveCard/request",
  "@dashboard/saveCard/success",
  "@dashboard/saveCard/failure"
)<
  {
    cardId: DashboardCard["id"];
    configuration: CardConfiguration<any, any, any>;
    scope: Scope;
    teamGoalConfiguration?: GoalConfiguration;
    updatePreferencesOverrides: boolean;
    updateGoalOverrides: boolean;
    updateMetaData?: Pick<DashboardCard, "updatedAt" | "updatedBy">;
  },
  Dashboard[],
  void
>();

export const saveDashboardLayout = createAsyncAction(
  "@dashboard/saveDashboardLayout/request",
  "@dashboard/saveDashboardLayout/success",
  "@dashboard/saveDashboardLayout/failure"
)<
  { cardPositions: Record<DashboardCard["id"], Pick<DashboardCard, "x" | "y">>; scope: Scope },
  Dashboard,
  void
>();

export const saveDashboardSettings = createAsyncAction(
  "@dashboard/saveDashboardSettings/request",
  "@dashboard/saveDashboardSettings/success",
  "@dashboard/saveDashboardSettings/failure"
)<
  {
    settings: Partial<Omit<Dashboard["settings"], "cards" | "overrides">>;
    scope: Scope;
  },
  Dashboard,
  void
>();

export const removeCard = createAsyncAction(
  "@dashboard/removeCard/request",
  "@dashboard/removeCard/success",
  "@dashboard/removeCard/failure"
)<{ cardId: DashboardCard["id"]; scope: Scope }, Dashboard[], void>();

export type Actions =
  | ActionType<
      | typeof initialize
      | typeof changeScope
      | typeof fetchTimelineEvents
      | typeof addCard
      | typeof saveCard
      | typeof saveDashboardLayout
      | typeof saveDashboardSettings
      | typeof removeCard
    >
  | ActivitiesLoggedCardActions
  | ActivitiesOverdueCardActions
  | CheckInsCardActions
  | NewRecordsCardActions
  | NoContactInCardActions
  | OutOfFrequencyCardActions
  | RecordsPastDueCardActions
  | StackRankActions;

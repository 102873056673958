import { Editor, Range, Selection } from "slate";

const findMention = (
  selection: Selection,
  editor: Editor
): { target: Range; search: string } | undefined => {
  if (selection === null) {
    return;
  }

  const [start] = Range.edges(selection);

  const wordBefore = Editor.before(editor, start, { unit: "word" });
  const before = wordBefore && Editor.before(editor, wordBefore);
  const beforeRange = before && Editor.range(editor, before, start);
  const beforeText = beforeRange && Editor.string(editor, beforeRange);
  const beforeMatch =
    beforeText &&
    beforeText.match(/^@([\w+-.!#$%&'*/=?^_`{|}~"(),:;<>@[\]()]+)$/);

  const after = Editor.after(editor, start);
  const afterRange = Editor.range(editor, start, after);
  const afterText = Editor.string(editor, afterRange);
  const afterMatch = afterText.match(/^(\s|$)/);

  if (beforeMatch && afterMatch) {
    return { target: beforeRange, search: beforeMatch[1] };
  }
};

export default findMention;

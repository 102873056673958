import React, { FC, ReactNode, useMemo } from "react";
import type { IntlShape } from "react-intl";
import IConfigProviderContext, {
  FormatNumberOptions,
  PrimitiveType,
} from "./types/IConfigProviderContext";
import getDecimalSeparator from "./utils/getDecimalSeparator";
import ConfigProviderContext from "./utils/ConfigProviderContext";
import { MessageKey } from "ConfigProvider/messages";
import defaultMessageFormatter from "./utils/defaultMessageFormatter";
import { UserRef } from "@mapmycustomers/shared/types/User";

export interface ConfigProviderProps
  extends Pick<
    IConfigProviderContext,
    | "dateFnsLocale"
    | "formatMessage"
    | "complementFileUrl"
    | "getUserColorClassName"
  > {
  children: ReactNode;
  intl: IntlShape;
}

const ConfigProvider: FC<ConfigProviderProps> = ({
  children,
  complementFileUrl,
  dateFnsLocale,
  getUserColorClassName,
  intl,
  formatMessage,
}) => {
  const value: IConfigProviderContext = useMemo(
    () => ({
      dateFnsLocale,
      formatMessage(
        messageId: MessageKey,
        values?: Record<string, PrimitiveType>
      ) {
        const message = formatMessage(messageId, values);
        if (!message) {
          return defaultMessageFormatter(messageId, values);
        }
        return message;
      },
      formatNumber(
        value: Parameters<Intl.NumberFormat["format"]>[0],
        opts?: FormatNumberOptions
      ): string {
        return intl.formatNumber(value, opts);
      },
      getDecimalSeparator(): string {
        return getDecimalSeparator(intl.locale);
      },
      complementFileUrl(url?: string | null): string | undefined {
        return complementFileUrl(url);
      },
      getUserColorClassName(user: UserRef): string | undefined {
        return getUserColorClassName(user);
      },
    }),
    [intl, formatMessage]
  );

  return (
    <ConfigProviderContext.Provider value={value}>
      {children}
    </ConfigProviderContext.Provider>
  );
};

export default ConfigProvider;

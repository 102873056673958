import useMap from "./useMap";
import useGenericMapEventHandler from "util/hook/map/useMapEventHandler";
import MapHandlerMap from "types/map/MapHandlerMap";

type Map = google.maps.Map;

const useMapEventHandler = <N extends keyof MapHandlerMap<Map>>(
  eventName: N,
  handler: MapHandlerMap<Map>[N]
) => {
  const { map } = useMap();
  useGenericMapEventHandler(map, eventName, handler);
};

export default useMapEventHandler;

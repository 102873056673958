import { createReducer } from "typesafe-actions";
import { EntityType } from "@mapmycustomers/shared/enum";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { MAP_RECORDS_PAGE_SIZE } from "scene/map/utils/consts";
import TerritoryModeState from "scene/map/store/territoryMode/TerritoryModeState";
import {
  applyTerritoryMapViewSettings,
  countPinsInTerritory,
  enterTerritoryMode,
  exitTerritoryLassoMode,
  exitTerritoryMode,
  fetchTerritoryLassoSelection,
  fetchTerritoryPins,
  fetchTerritoryRecords,
  initializeTerritoryMode,
  setDataViewData,
  setTerritoryEditFormHasChanges,
  setTerritoryMode,
  TerritoryModeActions,
  toggleDataViewVisibility,
  updateDataViewEntityTypeCount,
  updateTerritory,
  updateTerritorySharing,
} from "scene/map/store/territoryMode/actions";

export const territoryModeInitialState: TerritoryModeState = {
  categorizedMapEntries: {
    clusters: [],
    multiPins: [],
    entities: [],
  },
  // for pins outside a territory:
  categorizedExternalMapEntries: {
    clusters: [],
    multiPins: [],
    entities: [],
  },
  dataView: {
    entityTypeCount: {},
    loading: false,
    visible: false,
  },
  editFormHasChanges: false,
  lassoRecords: [],
  lassoRecordsLoading: false,
  loading: false,
  mode: "view",
  pinsCount: 0, // used by territory pins
  pinsInBoundsCount: 0,
  pinsInBoundsCountLoading: false,
  records: [],
  recordsCount: 0, // used by records list
  recordsLoading: false,
  viewState: {
    columns: [],
    filter: {},
    range: { startRow: 0, endRow: MAP_RECORDS_PAGE_SIZE }, // used by records list
    sidebarVisible: false,
    search: "", // used by records list
    showOther: false,
    sort: [], // used by records list
    tool: undefined,
    viewAs: undefined,
    visibleEntities: [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL], // doesn't mean anything in territories mode
  },
};

const territory = createReducer<TerritoryModeState, TerritoryModeActions>(territoryModeInitialState)
  .handleAction(enterTerritoryMode, (state, { payload }) => ({
    ...state,
    // remember suggested territory if it was specified, it'll be used in initializeTerritoryMode
    territoryCandidate: payload.territory,
  }))
  .handleAction(exitTerritoryMode.success, (state) => ({
    ...state,
    territory: undefined, // to removing territory highlighting in map mode
  }))
  .handleAction(initializeTerritoryMode.request, (state, { payload }) => ({
    ...state,
    mode: payload.territoryId === undefined ? "create" : "view",
  }))
  .handleAction(initializeTerritoryMode.success, (state, { payload }) => ({
    ...state,
    categorizedMapEntries: territoryModeInitialState.categorizedMapEntries,
    editFormHasChanges: false,
    mode: !payload.territory ? "create" : "view",
    // When territory is set already (probably via enterTerritoryMode), we don't replace that value.
    // This change specifically was made to handle territory creation better when terr was shared
    // with users. Since this information is not applied immediately, we needed a way to fake
    // userIds field and then preserve it in store.
    territory: state.territory?.id === payload.territory?.id ? state.territory : payload.territory,
    viewState: {
      ...state.viewState,
      filter: {
        ...(payload.territory
          ? {
              universal: {
                ...(state.viewState.filter?.universal ?? {}),
                territories: { operator: FilterOperator.IN_ANY, value: [payload.territory.id] },
              },
            }
          : // we create new territory, let's keep filters (we copied them from map view)
            state.viewState.filter),
      },
      // we create new territory, let's keep filters (we copied them from map view)
      selectedSavedFilterId: !payload.territory ? state.viewState.selectedSavedFilterId : undefined,
    },
  }))
  .handleAction(fetchTerritoryPins.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(applyTerritoryMapViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      colorKey: { ...(state.viewState.colorKey ?? {}), ...payload.colorKey },
      shapeKey: { ...(state.viewState.shapeKey ?? {}), ...payload.shapeKey },
      range: payload.range ?? state.viewState.range,
      sort: payload.sort ?? state.viewState.sort,
      filter: payload.filter
        ? {
            ...state.viewState.filter,
            ...payload.filter,
          }
        : state.viewState.filter,
      columns: payload.columns ?? state.viewState.columns,
      showOther: payload.showOther ?? state.viewState.showOther,
      visibleEntities: payload.visibleEntities ?? state.viewState.visibleEntities,
      // only update selectedSavedFilterId when it is explicitly present in a payload (even when it is `undefined`)
      selectedSavedFilterId:
        "selectedSavedFilterId" in payload
          ? payload.selectedSavedFilterId
          : state.viewState.selectedSavedFilterId,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
    },
  }))
  .handleAction(
    fetchTerritoryPins.success,
    (state, { payload: { externalEntries, pinsCount, ...mapEntries } }) => ({
      ...state,
      loading: false,
      categorizedMapEntries: mapEntries,
      categorizedExternalMapEntries: externalEntries,
      pinsCount,
    })
  )
  .handleAction(fetchTerritoryPins.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(fetchTerritoryRecords.request, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      range: payload.range ?? state.viewState.range,
      search: payload.search !== undefined ? payload.search : state.viewState.search,
      sort: payload.sort ?? state.viewState.sort,
    },
  }))
  .handleAction(exitTerritoryLassoMode, (state) => ({
    ...state,
    lassoRecords: territoryModeInitialState.lassoRecords,
    lassoRecordsLoading: false,
  }))
  .handleAction(fetchTerritoryLassoSelection.request, (state) => ({
    ...state,
    lassoRecords: [],
    lassoRecordsLoading: true,
  }))
  .handleAction(fetchTerritoryLassoSelection.success, (state, { payload: { records } }) => ({
    ...state,
    lassoRecords: records,
    lassoRecordsLoading: false,
  }))
  .handleAction(fetchTerritoryLassoSelection.failure, (state) => ({
    ...state,
    lassoRecordsLoading: false,
  }))
  .handleAction(fetchTerritoryRecords.success, (state, { payload: { records, recordsCount } }) => ({
    ...state,
    records,
    recordsCount,
  }))
  .handleAction([updateTerritory.request, updateTerritorySharing.request], (state) => ({
    ...state,
    updateLoading: true,
  }))
  .handleAction(
    [updateTerritory.success, updateTerritorySharing.success],
    (state, { payload }) => ({
      ...state,
      editFormHasChanges: false,
      territory: payload,
      updateLoading: false,
    })
  )
  .handleAction([updateTerritory.failure, updateTerritorySharing.failure], (state) => ({
    ...state,
    updateLoading: false,
  }))
  .handleAction(setTerritoryEditFormHasChanges, (state, { payload }) => ({
    ...state,
    editFormHasChanges: payload,
  }))
  .handleAction(countPinsInTerritory.request, (state) => ({
    ...state,
    pinsInBoundsCountLoading: true,
  }))
  .handleAction(countPinsInTerritory.success, (state, { payload }) => ({
    ...state,
    pinsInBoundsCount: payload,
    pinsInBoundsCountLoading: false,
  }))
  .handleAction(countPinsInTerritory.failure, (state) => ({
    ...state,
    pinsInBoundsCount: 0,
    pinsInBoundsCountLoading: false,
  }))
  .handleAction(setTerritoryMode, (state, { payload }) => ({
    ...state,
    editFormHasChanges: false, // clearly, if we were allowed to switch modes, there are no form changes
    mode: payload,
  }))
  .handleAction(updateDataViewEntityTypeCount.request, (state) => ({
    ...state,
    dataView: {
      ...state.dataView,
      loading: true,
    },
  }))
  .handleAction(
    updateDataViewEntityTypeCount.success,
    (state, { payload: { entityTypeCount } }) => ({
      ...state,
      dataView: {
        ...state.dataView,
        entityTypeCount,
        loading: false,
      },
    })
  )
  .handleAction(updateDataViewEntityTypeCount.failure, (state) => ({
    ...state,
    dataView: {
      ...state.dataView,
      loading: false,
    },
  }))
  .handleAction(setDataViewData, (state, { payload }) => ({
    ...state,
    dataView: {
      ...state.dataView,
      data: payload,
    },
  }))
  .handleAction(toggleDataViewVisibility, (state) => ({
    ...state,
    dataView: {
      ...state.dataView,
      visible: !state.dataView.visible,
    },
  }));

export default territory;

import Iam from "../../types/Iam";
import { useMemo } from "react";
import globalFrigadeService, { FrigadeService } from "util/frigade/frigadeService";

export const useExtendedAnalytics = (
  key: string | string[],
  analyticIssuer?: AnalyticsService,
  frigadeService?: FrigadeService
): AnalyticsService =>
  useMemo(
    () =>
      analyticIssuer
        ? analyticIssuer.extend(key)
        : new AnalyticsService(key, frigadeService ?? globalFrigadeService),
    // we do have all deps in deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analyticIssuer, ...(Array.isArray(key) ? key : [key])]
  );

export class AnalyticsService {
  keyPath: string[] = [];
  static traits: { orgId?: number } = {};
  private _frigadeService: FrigadeService | undefined;

  constructor(keyPath?: string | string[], frigadeService: FrigadeService = globalFrigadeService) {
    if (keyPath) {
      this.keyPath = Array.isArray(keyPath) ? keyPath : [keyPath];
    }
    this._frigadeService = frigadeService;
  }

  extend = (keyPath: string | string[]) =>
    new AnalyticsService(
      [...this.keyPath, ...(Array.isArray(keyPath) ? keyPath : [keyPath])],
      this._frigadeService
    );

  callSegmentMethod = (method: keyof SegmentAnalytics.AnalyticsJS, ...parameters: any[]) => {
    if (
      window.analytics &&
      window.analytics[method] &&
      typeof window.analytics[method] === "function"
    ) {
      // @ts-ignore
      window.analytics[method](...parameters, {
        traits: AnalyticsService.traits,
        groupId: AnalyticsService.traits.orgId,
      });
    }
    if (process.env.REACT_APP_ANALYTICS_TRACKING_DEBUG) {
      console.debug(
        `Analytics [${method}]: ${parameters.length > 0 ? parameters[0] : ""}`,
        parameters.length > 1 ? parameters[1] : undefined
      );
    }
  };

  identify = (user: Iam) => {
    const traits = {
      createdAt: user.createdAt,
      crmType: user.organization.metaData?.crm,
      deactivated: user.deactivated,
      email: user.username,
      features: user.organization.metaData?.features,
      fullName: user.fullName,
      marketingEnabled: user.marketingEnabled,
      maxNumberOfUserSeats: user.organization.plan.quantity,
      numFieldReps: user.organization.metaData?.numFieldReps ?? user.organization.numFieldReps,
      orgId: user.organization.id,
      orgName: user.organization.name,
      phone: user.phone,
      planID: user.organization.plan.id,
      planName: user.organization.plan.name,
      planPrice: user.organization.plan.price,
      renewalDate: user.organization.plan.nextPaymentDate,
      role: user.role.name,
      addOnServices: user.organization.addOnServices,
      teamSize: user.organization.teamSize,
      trialExpiresAt: user.organization.trialExpiresAt,
      userId: user.id,
      "sign-upSource": user["sign-upSource"],
    };
    this.callSegmentMethod("identify", user.id, traits);
    AnalyticsService.traits = traits;
  };

  group = (user: Iam) => {
    this.callSegmentMethod("group", user.organization.id, {
      domain: user.organization.domain,
      name: user.organization.name,
      planID: user.organization.plan.id,
      planName: user.organization.plan.name,
      planPrice: user.organization.plan.price,
      renewalDate: user.organization.plan.nextPaymentDate,
      trialExpiresAt: user.organization.trialExpiresAt,
    });
  };

  page = () => {
    this.callSegmentMethod("page");
  };

  track = (event: string, properties = {}) => {
    const finalProperties = { ...properties, groupId: AnalyticsService.traits.orgId };
    this.callSegmentMethod("track", event, finalProperties);
    this._frigadeService?.trackEvent(event, finalProperties);
  };

  getEventName = (event: string | string[]) =>
    [...this.keyPath, ...(Array.isArray(event) ? event : event.trim().length ? [event] : [])].join(
      " -> "
    );

  initiated = (event: string | string[] = "", properties = {}) => {
    this.track(`Initiated: ${this.getEventName(event)}`, properties);
  };

  completed = (event: string | string[] = "", properties = {}) => {
    this.track(`Completed: ${this.getEventName(event)}`, properties);
  };

  cancelled = (event: string | string[] = "", properties = {}) => {
    this.track(`Cancelled: ${this.getEventName(event)}`, properties);
  };

  failed = (event: string = "", properties = {}) => {
    this.track(`Failed: ${event}`, properties);
  };

  feature = (featurePath: string[], properties = {}) => {
    this.track(`Feature: ${this.getEventName(featurePath)}`, properties);
  };

  clicked = (featurePath: string[], properties = {}) => {
    this.track(`Clicked: ${this.getEventName(featurePath)}`, properties);
  };

  using = (featurePath: string[], properties = {}) => {
    this.track(`Using: ${this.getEventName(featurePath)}`, properties);
  };

  entityAdded = (entityType: string, entity: {}) => {
    this.track(`Entity added: ${entityType}`, entity);
  };

  entityUpdated = (entityType: string, entity: {}) => {
    this.track(`Entity updated: ${entityType}`, entity);
  };

  entityDeleted = (entityType: string, entity: {}) => {
    this.track(`Entity deleted: ${entityType}`, entity);
  };
}

const analyticsService = new AnalyticsService([], globalFrigadeService);

export default analyticsService;

import { RootState } from "store/rootReducer";
import { createSelector } from "reselect";

export const pinLocationState = (state: RootState) => state.scene.map.pinLocation;

export const getPinLocationAddress = createSelector(pinLocationState, ({ address }) => address);

export const getPinLocationEntity = createSelector(pinLocationState, ({ entity }) => entity);

export const getPinLocationEntityId = createSelector(pinLocationState, ({ entityId }) => entityId);

export const getPinLocationLocated = createSelector(pinLocationState, ({ located }) => located);

export const isPinLocationActive = createSelector(pinLocationState, ({ entity }) => !!entity);

export const isPinLocationLoading = createSelector(pinLocationState, ({ loading }) => loading);

export const isPinAddressChanged = createSelector(
  pinLocationState,
  ({ addressChanged }) => addressChanged
);

export const isPinCoordinatesChanged = createSelector(
  pinLocationState,
  ({ coordinatesChanged }) => coordinatesChanged
);

export const getPinLocationPoints = createSelector(
  pinLocationState,
  ({ coordinates }) => coordinates
);

import { connect } from "react-redux";
import { RootState } from "store/rootReducer";
import FileUploader from "component/FileUploader";
import {
  clearAllUploadedCompanyFiles,
  removeCompanyFile,
  uploadCompanyFiles,
} from "../store/actions";
import { isFilesUploading } from "../store/selectors";

const mapStateToProps = (state: RootState) => ({
  fileUploading: isFilesUploading(state),
});

const mapDispatchToProps = {
  clearAllUploadedFiles: clearAllUploadedCompanyFiles,
  onRemoveFile: removeCompanyFile.request,
  onFileUpload: uploadCompanyFiles.request,
};

const File = connect(mapStateToProps, mapDispatchToProps)(FileUploader);
export default File;

import ReportType from "../../../../enum/ReportType";
import ExportType from "./ExportType";
import { isDefined } from "util/assert";
import getHeaderRow from "./getHeaderRow";
import invariant from "tiny-invariant";
import { AnyEntity } from "@mapmycustomers/shared/types/entity";
import getDataRowFromEntity from "./getDataRowFromEntity";
import User from "@mapmycustomers/shared/types/User";
import formatUserTeamsById from "./formatUserTemsById";

const downloadEntityPreviewsAsCsv = (
  entities: AnyEntity[],
  reportType: ReportType,
  users?: User[]
) => {
  if (!entities.length) {
    return; // nothing to export
  }

  let exportType: ExportType;
  if (reportType === ReportType.DEALS) {
    exportType = ExportType.DEALS;
  } else if (reportType === ReportType.ACTIVITY) {
    exportType = ExportType.ACTIVITIES;
  } else if (reportType === ReportType.COMPANIES) {
    exportType = ExportType.COMPANIES;
  } else if (reportType === ReportType.PEOPLE) {
    exportType = ExportType.PEOPLE;
  } else if (reportType === ReportType.ROUTES) {
    exportType = ExportType.ROUTES;
  } else {
    invariant(false, `Unsupported report type: ${reportType}`);
  }
  const userTeamsById = formatUserTeamsById(users);

  const dataRows = entities
    .map((entity) => getDataRowFromEntity(exportType, entity, userTeamsById))
    .filter(isDefined);
  const headerRow = getHeaderRow(exportType);
  const csvText = [headerRow.join(","), ...dataRows.map((row) => row.join(","))].join("\n");

  const blob = new Blob([csvText], { type: "text/plain" });
  const fileName = `${reportType}_preview.csv`;

  const hiddenElement = window.document.createElement("a");
  hiddenElement.href = window.URL.createObjectURL(blob);
  hiddenElement.download = fileName;
  document.body.appendChild(hiddenElement);
  hiddenElement.click();
  document.body.removeChild(hiddenElement);
};

export default downloadEntityPreviewsAsCsv;

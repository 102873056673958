import React, { SyntheticEvent, useCallback } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import styles from "./Actions.module.scss";
import cn from "classnames";
import MmcNotification from "types/MmcNotification";
import Tooltip from "antd/es/tooltip";
import { updateNotificationReadStatus } from "store/notification/actions";
import { stopEvents } from "util/browser";

interface Props {
  hovered: boolean;
  notification: MmcNotification;
  updateNotificationReadStatus: typeof updateNotificationReadStatus.request;
}

const ReadController: React.FC<Props> = ({
  hovered,
  notification,
  updateNotificationReadStatus,
}) => {
  const intl = useIntl();

  const handleChangeReadStatus = useCallback(
    (event: SyntheticEvent<HTMLElement>) => {
      stopEvents(event);
      updateNotificationReadStatus({
        id: notification.id,
        readStatus: !notification.readStatus,
      });
    },
    [notification, updateNotificationReadStatus]
  );

  return (
    <Tooltip
      title={
        notification.readStatus
          ? intl.formatMessage({
              defaultMessage: "Mark as unread",
              description: "Navbar Notifications - list - notification - Mark as unread",
              id: "navbar.notifications.list.notification.markAsUnread",
            })
          : intl.formatMessage({
              defaultMessage: "Mark as read",
              description: "Navbar Notifications - list - notification - Mark as read",
              id: "navbar.notifications.list.notification.markAsRead",
            })
      }
    >
      <div
        className={cn(styles.readController, {
          [styles.readControllerHovered]: hovered,
          [styles.unread]: !notification.readStatus,
        })}
        onClick={handleChangeReadStatus}
      />
    </Tooltip>
  );
};

const mapDispatchToProps = {
  updateNotificationReadStatus: updateNotificationReadStatus.request,
};

export default connect(null, mapDispatchToProps)(ReadController);

import React from "react";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import { RegistryProps } from "component/FieldGrid/utils/ComponentRegistry";
import { Route } from "@mapmycustomers/shared/types/entity";

const RoutesField: React.FC<RegistryProps> = ({ entity, field }) => {
  const routes = (field.getValueFor(entity) as Pick<Route, "id" | "name">[]) ?? [];

  return (
    <div>
      <Tooltip title={field.getFormattedValueFor(entity)}>
        {routes.map((route) => (
          <Tag key={route.id}>{route.name}</Tag>
        ))}
      </Tooltip>
    </div>
  );
};

export default RoutesField;

import Visibility from "./Visibility";
import pick from "lodash-es/pick";

type PinLegendVisibility =
  | Visibility.PRIVATE
  | Visibility.SHARED_WITH_TEAM
  | Visibility.SHARED_WITH_ORGANIZATION;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const PinLegendVisibility = pick(Visibility, [
  "PRIVATE",
  "SHARED_WITH_TEAM",
  "SHARED_WITH_ORGANIZATION",
]);

export default PinLegendVisibility;

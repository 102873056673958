import React, { useCallback } from "react";
import cn from "classnames";
import Form from "antd/es/form";
import { FormInstance } from "antd/es/form/hooks/useForm";
import ActivityCompleted from "../ActivityCompleted";
import ActivityTime from "../ActivityTime";
import ActivityDate from "../ActivityDate";
import ActivityEndDate from "../ActivityEndDate";
import ActivityRepeat from "../ActivityRepeat";
import FormValues from "../../types/FormValues";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import Input from "antd/es/input";
import styles from "./ActivityFooter.module.scss";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";

interface Props {
  creating?: boolean;
  form: FormInstance<FormValues>;
  onChange?: () => void;
}

const ActivityFooter: React.FC<Props> = ({ creating, form, onChange }) => {
  const handleChange = useCallback(() => {
    onChange?.();
  }, [onChange]);

  const dateValue = form.getFieldValue(ActivityFieldName.START_AT);
  const endDateValue = form.getFieldValue(ActivityFieldName.END_AT);
  const isValidDate = dateValue && Date.parse(dateValue.toString());
  const isValidEndDate = endDateValue && Date.parse(endDateValue.toString());

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={cn(styles.icon, styles.top)}>
          <FontAwesomeIcon icon={faClock} />
        </div>

        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.startAt !== curValues.startAt || prevValues.endAt !== curValues.endAt
          }
          noStyle
        >
          {({ getFieldValue }) => {
            const dateValue = getFieldValue(ActivityFieldName.START_AT);
            const isValidDate = dateValue && Date.parse(dateValue.toString());

            return (
              <div className={styles.group}>
                <ActivityDate form={form} onChange={handleChange} />
                {isValidDate && (
                  <>
                    <ActivityTime form={form} onChange={handleChange} />
                    {isValidEndDate && <ActivityEndDate form={form} onChange={handleChange} />}
                  </>
                )}
              </div>
            );
          }}
        </Form.Item>
      </div>

      {isValidDate && <ActivityRepeat form={form} onChange={handleChange} />}

      <ActivityCompleted creating={creating} form={form} onChange={handleChange} />

      <Form.Item name="recurIntervalType" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="isRecurrent" hidden>
        <Input />
      </Form.Item>
    </div>
  );
};

export default ActivityFooter;

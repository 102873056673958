import React, { useCallback, useEffect, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import cn from "classnames";
import Button from "antd/es/button";
import Form from "antd/es/form";
import { FormInstance } from "antd/es/form/hooks/useForm";
import Tooltip from "antd/es/tooltip";
import Checkbox from "component/input/Checkbox";
import TimePicker from "component/input/TimePicker";
import ActivityEndTime from "./components/ActivityEndTime";
import FormValues from "../../types/FormValues";
import { SHOULD_USE_12_HOUR_FORMAT } from "util/consts";
import useRerenderer from "@mapmycustomers/shared/util/hook/useRerenderer";
import styles from "./ActivityTime.module.scss";
import { addMinutes, differenceInMinutes } from "date-fns/esm";
import { formatDate } from "util/formatters";
import isValidDate from "util/isValidDate";
import { ActivityFieldName } from "util/fieldModel/ActivityFieldModel";

interface Props {
  form: FormInstance<FormValues>;
  onChange?: () => void;
}

const messages = defineMessages({
  allDay: {
    id: "createActivityModal.footer.scheduleActivity.allDay",
    defaultMessage: "All Day",
    description: "All Day",
  },
  changeTime: {
    id: "createActivityModal.footer.scheduleActivity.changeTime",
    defaultMessage: "Change Time",
    description: "Change Time",
  },
});

const ActivityTime: React.FC<Props> = ({ form, onChange }) => {
  const intl = useIntl();
  const forceRender = useRerenderer();

  const open = useRef<boolean>(false);

  const startDate = form.getFieldValue(ActivityFieldName.START_AT);
  const startDateValue = isValidDate(startDate) ? startDate : undefined;
  const endDate = form.getFieldValue(ActivityFieldName.END_AT);
  const endDateValue = isValidDate(endDate) ? endDate : undefined;
  const duration =
    endDateValue && startDateValue ? differenceInMinutes(endDateValue, startDateValue) : undefined;

  const [startOpen, setStartOpen] = useState(false);
  const [endOpen, setEndOpen] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const isAllDay = !!form.getFieldValue("allDay");

  const handleChange = useCallback(
    (value) => {
      if (isAllDay) {
        form.setFieldsValue({ allDay: false });
      } else {
        if (duration && value) {
          form.setFieldsValue({ endAt: addMinutes(value, duration) });
        }
      }

      onChange?.();
    },
    [duration, form, isAllDay, onChange]
  );

  const handleStartOpenChanged = useCallback(
    (value: boolean) => {
      if (value) {
        open.current = true;
      }
      setTooltipVisible(false);
      setStartOpen(value);
    },
    [open, setStartOpen, setTooltipVisible]
  );

  const handleStartClick = useCallback(() => {
    open.current = true;
    setTooltipVisible(false);
    setStartOpen(true);
  }, []);

  const handleAllDayChange = useCallback(
    (value) => {
      if (value) {
        form.setFieldsValue({
          allDay: value,
          endAt: undefined,
        });

        setStartOpen(false);

        onChange?.();
      } else {
        form.setFieldsValue({ allDay: value });

        onChange?.();
      }
    },
    [form, onChange]
  );

  const handleLostFocus = useCallback(() => {
    if (!open.current) {
      open.current = false;
      forceRender();
    }
  }, [forceRender, open]);

  useEffect(() => {
    if (endOpen || startOpen) return;
    open.current = false;
    setTimeout(handleLostFocus, 500);
  }, [endOpen, handleLostFocus, open, startOpen]);

  const handleEndOpenChanged = useCallback(
    (value: boolean) => {
      if (value) {
        open.current = true;
      }
      setEndOpen(value);
    },
    [setEndOpen]
  );

  const timeFormat = SHOULD_USE_12_HOUR_FORMAT ? "hh:mm a" : "HH:mm";
  const timeFormatPicker = SHOULD_USE_12_HOUR_FORMAT ? "hh:mm" : "HH:mm";
  const startDateFormatted = isAllDay
    ? intl.formatMessage(messages.allDay)
    : startDateValue
    ? formatDate(startDateValue, timeFormat)
    : "";

  return (
    <div className={styles.container}>
      <div className={cn(styles.group, { [styles.active]: open.current })}>
        <div>
          <Tooltip
            destroyTooltipOnHide
            mouseLeaveDelay={0}
            onOpenChange={setTooltipVisible}
            open={tooltipVisible}
            overlayClassName={styles.tooltip}
            placement="top"
            title={intl.formatMessage(messages.changeTime)}
            trigger={startOpen ? [] : ["hover"]}
          >
            <Form.Item name={ActivityFieldName.START_AT} noStyle>
              <TimePicker
                allowClear={false}
                bordered={false}
                defaultValue={startDateValue}
                dropdownClassName={styles.panel}
                format={timeFormatPicker}
                inputReadOnly
                minuteStep={5}
                onChange={handleChange}
                onOpenChange={handleStartOpenChanged}
                open={startOpen}
                placeholder=""
                renderExtraFooter={() => (
                  <Checkbox
                    className={styles.checkbox}
                    checked={isAllDay}
                    onChange={handleAllDayChange}
                  >
                    {intl.formatMessage(messages.allDay)}
                  </Checkbox>
                )}
                showSecond={false}
                suffixIcon={<></>}
                use12Hours={SHOULD_USE_12_HOUR_FORMAT}
              />
            </Form.Item>

            <Button
              className={cn(styles.btn, { [styles.active]: startOpen })}
              onClick={handleStartClick}
            >
              {startDateFormatted}
            </Button>
          </Tooltip>
        </div>

        {!isAllDay && (open.current || isValidDate(endDate)) && (
          <ActivityEndTime
            defaultValue={endDateValue ?? startDateValue}
            form={form}
            onChange={onChange}
            onOpenChange={handleEndOpenChanged}
          />
        )}
      </div>
    </div>
  );
};

export default ActivityTime;

import { defineMessages } from "react-intl";

export const messages = defineMessages({
  companyDealWrongRelation: {
    id: "activity.relatedEntitiesMatching.companyDealWrongRelation",
    defaultMessage:
      "The company and deal you’ve selected {error, select, true {couldn't be} other {are not}} related to each other.",
    description: "Error message when the selected deal is not associated with the selected company",
  },
  companyDealWrongRelationRelated: {
    id: "activity.relatedEntitiesMatching.companyDealWrongRelationRelated",
    defaultMessage: "The company and deal you’ve selected are now related to each other.",
    description:
      "Related message when the selected deal is not associated with the selected company",
  },
  companyPersonDealWrongRelation: {
    id: "activity.relatedEntitiesMatching.companyPersonDealWrongRelation",
    defaultMessage:
      "The company, person, and deal you’ve selected {error, select, true {couldn't be} other {are not}} all related to each other.",
    description:
      "Error message when the selected person is not associated with the selected company",
  },
  companyPersonDealWrongRelationRelated: {
    id: "activity.relatedEntitiesMatching.companyPersonDealWrongRelationRelated",
    defaultMessage: "The company, person and deal you’ve selected are now related to each other.",
    description:
      "Related message when the selected person is not associated with the selected company",
  },
  companyPersonWrongRelation: {
    id: "activity.relatedEntitiesMatching.companyPersonWrongRelation",
    defaultMessage:
      "The company and person you’ve selected {error, select, true {couldn't be} other {are not}} related to each other.",
    description:
      "Error message when the selected person is not associated with the selected company",
  },
  companyPersonWrongRelationRelated: {
    id: "activity.relatedEntitiesMatching.companyPersonWrongRelationRelated",
    defaultMessage: "The company and person you’ve selected are now related to each other.",
    description:
      "Related message when the selected person is not associated with the selected company",
  },
  personDealWrongRelation: {
    id: "activity.relatedEntitiesMatching.personDealWrongRelation",
    defaultMessage:
      "The person and deal you’ve selected {error, select, true {couldn't be} other {are not}} related to each other.",
    description: "Error message when the selected deal is not associated with the selected person",
  },
  personDealWrongRelationRelated: {
    id: "activity.relatedEntitiesMatching.personDealWrongRelationRelated",
    defaultMessage: "The person and deal you’ve selected are now related to each other.",
    description:
      "Related message when the selected deal is not associated with the selected person",
  },
  relateRecords: {
    id: "activity.relatedEntitiesMatching.relateRecords",
    defaultMessage: "Relate records",
    description: "Button label to relate records",
  },
  tryAgain: {
    id: "activity.relatedEntitiesMatching.tryAgain",
    defaultMessage: "Try again",
    description: "Button label to try again",
  },
});

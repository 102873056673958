import React from "react";
import cn from "classnames";
import AntDAvatar, { AvatarProps } from "antd/es/avatar";
import Tooltip from "antd/es/tooltip";
import colorStyles from "./AvatarColor.module.scss";
import Team from "@mapmycustomers/shared/types/Team";
import { DEFAULT_AVATAR_COLORS } from "@mapmycustomers/shared/util/color/const";

const teamIdToColorIndex = new Map<Team["id"], number>();

interface Props extends Omit<AvatarProps, "className"> {
  className?: string;
  randomColor?: boolean;
  team: Pick<Team, "id" | "name">;
  tooltip?: boolean;
}

const TeamAvatar: React.FC<Props> = ({
  className,
  randomColor,
  team,
  tooltip,
  ...avatarProps
}) => {
  const nameInitials = team.name.trim().substring(0, 1).toUpperCase();

  let colorClassName;
  if (randomColor) {
    if (!teamIdToColorIndex.has(team.id)) {
      teamIdToColorIndex.set(
        team.id,
        Math.round(Math.random() * (DEFAULT_AVATAR_COLORS.length - 1))
      );
    }
    colorClassName =
      colorStyles[DEFAULT_AVATAR_COLORS[teamIdToColorIndex.get(team.id)!]];
  } else {
    colorClassName = colorStyles["grey"];
  }

  return (
    <Tooltip title={tooltip ? team.name : undefined}>
      <AntDAvatar className={cn(colorClassName, className)} {...avatarProps}>
        {nameInitials}
      </AntDAvatar>
    </Tooltip>
  );
};

export default TeamAvatar;

import { endOfDay, endOfToday, startOfDay, startOfToday } from "date-fns/esm";
import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import ApiError from "store/api/ApiError";
import { callApi } from "store/api/callApi";
import { getFunnelStages } from "store/deal";
import { handleError } from "store/errors/actions";
import { getCurrentUser, getOrganization, getOrganizationId, isCurrentUserMember } from "store/iam";
import { PREVIEW_MODAL_LIMIT } from "util/consts";
import {
  Activity,
  EntityTypesSupportedByMapsPage,
  MapEntity,
  Person,
} from "@mapmycustomers/shared/types/entity";
import Company from "@mapmycustomers/shared/types/entity/Company";
import Deal from "@mapmycustomers/shared/types/entity/Deal";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import { StageSummary } from "@mapmycustomers/shared/types/entity/deals/StageSummary";
import Organization from "@mapmycustomers/shared/types/Organization";
import SourceSummary from "types/source/SourceSummary";
import Statistics from "types/Statistics";
import User from "@mapmycustomers/shared/types/User";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import PlatformFilterModel, {
  PlatformFilterCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import DailyChangeChartType from "scene/reports/components/Activity/components/DailyChange/DailyChangeChartType";
import { DealsGroupByType } from "../enum/DealsGroupByType";
import { GroupByType } from "scene/reports/components/Overview/components/Engagement/enum/GroupByType";
import { getActivityRangeStartDate } from "../util/activityDateRange";
import getStartDate from "../util/getStartDate";
import getGranularity from "scene/home/util/getGranularity";
import {
  fetchActivityByRepData,
  fetchActivityByTypesData,
  fetchActivityOverview,
  fetchDailyChangeData,
  fetchDealsRotting,
  fetchEngagementRecordsList,
  fetchFunnelSummary,
  fetchOpenDealsData,
  fetchRecentCompanies,
  fetchRecordsBySourceForSelectedType,
  fetchRecordsBySourceSummary,
  fetchRecordsOutOfCadence,
  fetchSelectedActivityByTypesData,
  fetchTodayActivitiesData,
  fetchTodayActivitiesDataForReps,
  initialize,
  setDealsRottingFilter,
  setRecordSourceFilter,
  setRecordsOutOfCadenceFilter,
} from "./actions";

import { initialState } from "./HomeState";
import { getRecordsOutOfCadenceFilter } from "./selectors";
import type RecordsOutOfCadenceFilter from "scene/home/types/RecordsOutOfCadenceFilter";
import type RecordsSourceFilter from "scene/home/types/RecordsSourceFilter";
import homeSettings from "scene/home/util/homeSettings";
import type { DealsRottingFilter } from "scene/home/types/DealsRottingFilter";
import ActivityStatus from "@mapmycustomers/shared/enum/activity/ActivityStatus";
import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import { doesWeekStartFromMonday, getLocalTimeZone } from "util/dates";
import AggregatedListResponse from "types/viewModel/AggregatedListResponse";
import { ActivityCount } from "scene/home/component/Cards/util";
import { parseApiDate } from "util/parsers";
import isValidDate from "util/isValidDate";
import ListResponseAggregation from "../../../types/viewModel/ListResponseAggregation";
import { SupportingGroupsRecordStats } from "../types/RecordsOutOfCadence";
import { getActivityTypes } from "store/activity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import getActivityTypeWithCount from "util/activity/getActivityTypeWithCount";
import calculateNumberOfItemsPerDay from "./calculateNumberOfItemsPerDay";
import { DealsGroupBy } from "../types/DealGroupBy";
import { RecordsStats } from "../types/RecordsStats";

type ActivitiesByRepSecondaryItem = { key_as_string: string; doc_count: number };
type RevenueClosedWonDealsByDate = {
  key_as_string: string;
  doc_count: number;
  sum_amount: { value: number };
};

export function* onInitialize() {
  try {
    const member: boolean = yield select(isCurrentUserMember);
    const organization: Organization = yield select(getOrganization);
    const [anyActivitiesResponse, dealsCountResponse, accountsCountResponse]: [
      ListResponse<Activity>,
      ListResponse<Deal>,
      ListResponse<Company>
    ] = yield all([
      callApi("fetchActivities", organization.id, {
        $limit: 0,
        $columns: ["id"],
      }),
      callApi("fetchDeals", organization.id, {
        $limit: 0,
        $columns: ["id"],
      }),
      callApi("fetchCompanies", organization.id, {
        $limit: 0,
        $columns: ["id"],
      }),
    ]);

    const dealsRottingFilter: DealsRottingFilter =
      (yield call([homeSettings, homeSettings.getDealsRottingCardState])) ??
      initialState.dealsRottingFilter;
    if (
      member &&
      [DealsGroupByType.USER, DealsGroupByType.TEAM].includes(dealsRottingFilter.groupByType)
    ) {
      dealsRottingFilter.groupByType = DealsGroupByType.FUNNEL;
    }

    const recordsOutOfCadenceFilter: RecordsOutOfCadenceFilter =
      (yield call([homeSettings, homeSettings.getEngagementCardState])) ??
      initialState.recordsOutOfCadenceFilter;
    const recordsSourceFilter: RecordsSourceFilter =
      (yield call([homeSettings, homeSettings.getRecordsSourceCardState])) ??
      initialState.recordsSourceFilter;

    yield put(
      initialize.success({
        hasActivities: anyActivitiesResponse.count > 0,
        dealsCount: dealsCountResponse.total,
        hasCompanies: accountsCountResponse.count > 0,
        recordsOutOfCadenceFilter,
        dealsRottingFilter,
        recordsSourceFilter,
      })
    );
  } catch (error) {
    yield put(initialize.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchTodayActivitiesData({
  payload,
}: ReturnType<typeof fetchTodayActivitiesData.request>) {
  try {
    const { assigneeIds, status, activityTypes, date } = payload;
    const organization: Organization = yield select(getOrganization);
    let completed;
    let order = "-updatedAt";
    if (status === ActivityStatusOption.COMPLETED) {
      completed = true;
      order = "-completedAt";
    } else if (status === ActivityStatusOption.INCOMPLETE) {
      completed = false;
      order = "-startAt";
    }

    const response: ListResponse<Activity> = yield callApi("fetchActivities", organization.id, {
      $filters: {
        completed,
        includeAccessStatus: true,
        $and: [
          {
            assigneeId: { $in: assigneeIds },
            "crmActivityType.id": { $in: activityTypes.map(({ id }) => id) },
            startAt: {
              $gte: startOfDay(new Date(date)).toISOString(),
              $lte: endOfDay(new Date(date)).toISOString(),
            },
          },
        ],
      },
      $order: order,
      $columns: [
        "crmActivityType",
        "account",
        "completed",
        "completedAt",
        "contact",
        "deal",
        "id",
        "name",
        "startAt",
        "user",
      ],
    });

    yield put(fetchTodayActivitiesData.success(response.data));
  } catch (error) {
    yield put(fetchTodayActivitiesData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchTodayActivitiesDataForReps({
  payload,
}: ReturnType<typeof fetchTodayActivitiesDataForReps.request>) {
  try {
    const { status, dateRange } = payload;
    const organization: Organization = yield select(getOrganization);
    const me: User = yield select(getCurrentUser);
    const completed = status === ActivityStatus.COMPLETED;
    let dateFilter: PlatformFilterCondition = {
      startAt: {
        $gte: startOfToday().toISOString(),
        $lte: getActivityRangeStartDate(dateRange)?.toISOString(),
      },
    };
    if (status === ActivityStatus.COMPLETED) {
      dateFilter = {
        completedAt: {
          $gte: getActivityRangeStartDate(dateRange)?.toISOString(),
          $lte: endOfToday().toISOString(),
        },
      };
    }
    if (status === ActivityStatus.OVERDUE) {
      dateFilter = {
        startAt: {
          $gte: getActivityRangeStartDate(dateRange)?.toISOString(),
          $lte: new Date().toISOString(),
        },
      };
    }

    const response: ListResponse<Activity> = yield callApi("fetchActivities", organization.id, {
      $filters: {
        completed,
        includeAccessStatus: true,
        viewAs: me.id,
        $and: [dateFilter],
      },
      $limit: 999,
      $order: completed ? "-completedAt" : "startAt",
      $columns: [
        "crmActivityType",
        "id",
        "name",
        "note",
        "assignee",
        "user",
        "startAt",
        "completed",
        "completedAt",
        "account",
        "contact",
        "deal",
      ],
    });

    yield put(fetchTodayActivitiesDataForReps.success(response.data));
  } catch (error) {
    yield put(fetchTodayActivitiesDataForReps.failure(error));
  }
}

export function* onFetchActivityByRepData({
  payload,
}: ReturnType<typeof fetchActivityByRepData.request>) {
  try {
    const { assigneeIds, activityTypeIds, dateRange } = payload;
    const organization: Organization = yield select(getOrganization);
    const granularity = getGranularity(dateRange);

    const response: AggregatedListResponse<
      Activity,
      { key: User["id"]; secondary: ActivitiesByRepSecondaryItem[] }[]
    > = yield callApi("fetchActivities", organization.id, {
      $aggs: {
        primaryGroup: { field: "assignee.id" },
        secondaryGroup: {
          field: "startAt",
          interval: granularity ?? "day",
          timezone: getLocalTimeZone(),
          offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
        },
      },
      $filters: {
        includeAccessStatus: true,
        $and: [
          {
            assigneeId: { $in: assigneeIds },
            "crmActivityType.id": { $in: activityTypeIds },
            startAt: {
              $gte: getStartDate(dateRange)?.toISOString(),
              $lte: endOfToday().toISOString(),
            },
          },
        ],
      },
      $limit: 0,
    });

    const userIdToActivityCountMap: Record<User["id"], ActivityCount[]> =
      response.aggregations.reduce((result, primaryItem) => {
        const dates = primaryItem.secondary
          .map<ActivityCount>((secondaryItem: { key_as_string: string; doc_count: number }) => ({
            date: parseApiDate(secondaryItem.key_as_string),
            count: secondaryItem.doc_count,
          }))
          .filter(({ date, count }) => isValidDate(date));

        return Object.assign(result, { [primaryItem.key]: dates });
      }, {});

    yield put(fetchActivityByRepData.success(userIdToActivityCountMap));
  } catch (error) {
    yield put(fetchActivityByRepData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchActivityByTypesData({
  payload,
}: ReturnType<typeof fetchActivityByTypesData.request>) {
  try {
    const { assigneeIds, crmActivityTypeIds, dateRange } = payload;
    const organization: Organization = yield select(getOrganization);
    const response: AggregatedListResponse<Activity, ListResponseAggregation<Activity>[]> =
      yield callApi("fetchActivities", organization.id, {
        $filters: {
          completed: true,
          $and: [
            {
              assigneeId: { $in: assigneeIds },
              "crmActivityType.id": { $in: crmActivityTypeIds },
              startAt: {
                $gte: getStartDate(dateRange)?.toISOString(),
                $lte: endOfToday().toISOString(),
              },
            },
          ],
        },
        $aggs: { primaryGroup: { field: "crmActivityTypeId" } },
        $limit: 0,
      });
    const activityTypes: ActivityType[] = yield select(getActivityTypes);
    const activityTypesWithCount = getActivityTypeWithCount(activityTypes, response.aggregations);
    yield put(fetchActivityByTypesData.success(activityTypesWithCount));
  } catch (error) {
    yield put(fetchActivityByTypesData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchSelectedActivityByTypesData({
  payload,
}: ReturnType<typeof fetchSelectedActivityByTypesData.request>) {
  try {
    const { assigneeIds, crmActivityTypeIds, dateRange } = payload;
    const organization: Organization = yield select(getOrganization);
    const response: ListResponse<Activity> = yield callApi("fetchActivities", organization.id, {
      $filters: {
        completed: true,
        $and: [
          {
            assigneeId: { $in: assigneeIds },
            "crmActivityType.id": crmActivityTypeIds,
            startAt: {
              $gte: getStartDate(dateRange)?.toISOString(),
              $lte: endOfToday().toISOString(),
            },
          },
        ],
      },
      $order: "-startAt",
      $limit: PREVIEW_MODAL_LIMIT,
      $offset: ((payload.page ?? 1) - 1) * PREVIEW_MODAL_LIMIT,
    });
    yield put(
      fetchSelectedActivityByTypesData.success({
        activities: response.data,
        totalRecords: response.total,
      })
    );
  } catch (error) {
    yield put(fetchSelectedActivityByTypesData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchRecordsBySourceSummary({
  payload: { entityTypes, userId, dateRange },
}: ReturnType<typeof fetchRecordsBySourceSummary.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: AggregatedListResponse<MapEntity, SourceSummary[]> = yield callApi(
      "fetchPins",
      orgId,
      {
        $aggs: {
          primaryGroup: { field: "sourceCreated.clientType" },
        },
        $filters: {
          entities: entityTypes,
          $and: [
            {
              userId: { $in: userId },
              createdAt: {
                $gte: getStartDate(dateRange)?.toISOString(),
                $lte: endOfToday().toISOString(),
              },
            },
          ],
        },
        $limit: 0,
      }
    );
    yield put(fetchRecordsBySourceSummary.success(response.aggregations));
  } catch (error) {
    yield put(fetchRecordsBySourceSummary.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchOpenDealsData({ payload }: ReturnType<typeof fetchOpenDealsData.request>) {
  try {
    const { funnelId, usersIds } = payload;
    const organization: Organization = yield select(getOrganization);

    const funnelStages: Record<Funnel["id"], Stage[]> = yield select(getFunnelStages);
    const stages: Stage[] = funnelStages[funnelId] ?? [];

    // platform only supports filtering by stage name, not by stage type
    // so we fetch stages first, find names of closed ones and use them in a deals filter below
    const closedStagesNames = stages
      .filter(({ type }) => [DealStageType.WON, DealStageType.LOST].includes(type))
      .map(({ name }) => name);

    const dealsResponse: ListResponse<Deal> = yield callApi("fetchDeals", organization.id, {
      $filters: {
        includeAccessStatus: true,
        userId: { $in: usersIds },
        mapped: true, // only fetch mappable deals
        $and: [
          {
            funnelId,
            // only fetch open deals:
            stage: { $nin: closedStagesNames },
          },
        ],
      },
      $order: "-createdAt",
      $limit: 1000,
    });

    yield put(
      fetchOpenDealsData.success({
        deals: dealsResponse.data,
      })
    );
  } catch (error) {
    yield put(fetchOpenDealsData.failure());
  }
}

export function* onFetchActivityOverview({
  payload,
}: ReturnType<typeof fetchActivityOverview.request>) {
  try {
    const { usersIds, dateRange } = payload;
    const organization: Organization = yield select(getOrganization);
    const response: ListResponse<Statistics> = yield callApi("fetchStatistics", organization.id, {
      userId: usersIds && usersIds.length ? { $in: usersIds } : undefined,
      summary: "all",
      date: { $gte: getStartDate(dateRange)?.toISOString(), $lte: endOfToday().toISOString() },
    });

    yield put(fetchActivityOverview.success(response.data.length ? response.data[0].values : {}));
  } catch (error) {
    yield put(fetchActivityOverview.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchFunnelSummary({ payload }: ReturnType<typeof fetchFunnelSummary.request>) {
  try {
    const { selectedUserIds } = payload;
    const organization: Organization = yield select(getOrganization);
    const response: ListResponse<StageSummary> = yield callApi(
      "fetchAllFunnelSummary",
      organization.id,
      payload.funnelId,
      {
        $filters: {
          userId: { $in: selectedUserIds },
        },
      }
    );

    yield put(fetchFunnelSummary.success(response.data.filter(({ totalValue }) => totalValue > 0)));
  } catch (error) {
    yield put(fetchFunnelSummary.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchRecentCompanies({
  payload,
}: ReturnType<typeof fetchRecentCompanies.request>) {
  try {
    const { usersIds, limit } = payload;
    const organization: Organization = yield select(getOrganization);
    const response: ListResponse<Company> = yield callApi("fetchCompanies", organization.id, {
      $filters: {
        includeAccessStatus: true,
        mapped: true,
        userId: usersIds && usersIds.length ? { $in: usersIds } : undefined,
      },
      $order: "-updatedAt",
      $limit: limit,
      $columns: [
        "id",
        "name",
        "color",
        "geoPoint",
        "address",
        "city",
        "region",
        "website",
        "phone",
        "numEmployees",
        "annualRevenue",
      ],
    });

    yield put(fetchRecentCompanies.success(response.data));
  } catch (error) {
    yield put(fetchRecentCompanies.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchDailyChangeData({
  payload,
}: ReturnType<typeof fetchDailyChangeData.request>) {
  try {
    const { chartType, usersIds, dateRange } = payload;
    const organization: Organization = yield select(getOrganization);

    if (chartType === DailyChangeChartType.COMPLETED_ACTIVITIES) {
      const response: AggregatedListResponse<Activity, ListResponseAggregation<Activity>[]> =
        yield callApi("fetchActivities", organization.id, {
          $aggs: {
            primaryGroup: { field: "completedAt", interval: "day", timezone: getLocalTimeZone() },
          },
          $filters: {
            completed: true,
            includeAccessStatus: true,
            userId: usersIds && usersIds.length ? { $in: usersIds } : undefined,
            $and: [
              {
                completedAt: {
                  $gte: getStartDate(dateRange)?.toISOString(),
                  $lte: endOfToday().toISOString(),
                },
              },
            ],
          },
          $limit: 0,
        });
      const completedActivities = calculateNumberOfItemsPerDay(
        response.aggregations,
        "key",
        "doc_count"
      );
      yield put(fetchDailyChangeData.success(completedActivities));
    } else if (chartType === DailyChangeChartType.COMPANIES_ADDED) {
      const response: AggregatedListResponse<Company, ListResponseAggregation<Company>[]> =
        yield callApi("fetchCompanies", organization.id, {
          $aggs: {
            primaryGroup: { field: "createdAt", interval: "day", timezone: getLocalTimeZone() },
          },
          $filters: {
            includeAccessStatus: true,
            userId: usersIds && usersIds.length ? { $in: usersIds } : undefined,
            $and: [
              {
                createdAt: {
                  $gte: getStartDate(dateRange)?.toISOString(),
                  $lte: endOfToday().toISOString(),
                },
              },
            ],
          },
          $limit: 0,
        });
      const companiesAdded = calculateNumberOfItemsPerDay(
        response.aggregations,
        "key",
        "doc_count"
      );
      yield put(fetchDailyChangeData.success(companiesAdded));
    } else if (chartType === DailyChangeChartType.PEOPLE_ADDED) {
      const response: AggregatedListResponse<Person, ListResponseAggregation<Person>[]> =
        yield callApi("fetchPeople", organization.id, {
          $aggs: {
            primaryGroup: { field: "createdAt", interval: "day", timezone: getLocalTimeZone() },
          },
          $filters: {
            includeAccessStatus: true,
            userId: usersIds && usersIds.length ? { $in: usersIds } : undefined,
            $and: [
              {
                createdAt: {
                  $gte: getStartDate(dateRange)?.toISOString(),
                  $lte: endOfToday().toISOString(),
                },
              },
            ],
          },
          $limit: 0,
        });
      const peopleAdded = calculateNumberOfItemsPerDay(response.aggregations, "key", "doc_count");
      yield put(fetchDailyChangeData.success(peopleAdded));
    } else if (chartType === DailyChangeChartType.DEALS_ADDED) {
      const response: AggregatedListResponse<Deal, ListResponseAggregation<Deal>[]> = yield callApi(
        "fetchDeals",
        organization.id,
        {
          $aggs: {
            primaryGroup: { field: "createdAt", interval: "day", timezone: getLocalTimeZone() },
          },
          $filters: {
            includeAccessStatus: true,
            userId: usersIds && usersIds.length ? { $in: usersIds } : undefined,
            $and: [
              {
                createdAt: {
                  $gte: getStartDate(dateRange)?.toISOString(),
                  $lte: endOfToday().toISOString(),
                },
              },
            ],
          },
          $limit: 0,
        }
      );
      const dealsAdded = calculateNumberOfItemsPerDay(response.aggregations, "key", "doc_count");
      yield put(fetchDailyChangeData.success(dealsAdded));
    } else if (chartType === DailyChangeChartType.REVENUE_CLOSED_WON) {
      const response: AggregatedListResponse<Deal, RevenueClosedWonDealsByDate[]> = yield callApi(
        "fetchDeals",
        organization.id,
        {
          $aggs: {
            primaryGroup: { field: "closingDate", interval: "day", timezone: getLocalTimeZone() },
            sum: ["amount"],
          },
          $filters: {
            includeAccessStatus: true,
            userId: usersIds && usersIds.length ? { $in: usersIds } : undefined,
            $and: [
              {
                closingDate: {
                  $gte: getStartDate(dateRange)?.toISOString(),
                  $lte: endOfToday().toISOString(),
                },
                "stage.type": DealStageType.WON,
              },
            ],
          },
          $limit: 0,
        }
      );
      const revenueClosedWon = calculateNumberOfItemsPerDay(
        response.aggregations,
        "key_as_string",
        "sum_amount.value"
      );
      yield put(fetchDailyChangeData.success(revenueClosedWon));
    } else {
      yield put(fetchDailyChangeData.failure(new ApiError("Invalid chart type")));
    }
  } catch (error) {
    yield put(fetchDailyChangeData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchRecordsOutOfCadence({
  payload: { groupIds, teamIds, userIds },
}: ReturnType<typeof fetchRecordsOutOfCadence.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const { groupByType, recordTypes }: RecordsOutOfCadenceFilter = yield select(
      getRecordsOutOfCadenceFilter
    );

    const [primaryGroupField, ids] =
      groupByType === GroupByType.GROUP
        ? ["groups", groupIds]
        : groupByType === GroupByType.TEAM
        ? ["teams", teamIds]
        : ["userId", userIds];

    const aggregationPayload = {
      $aggs: {
        count: ["id"],
        primaryGroup: { field: primaryGroupField },
      },
      $limit: 0,
      $filters: {
        ...(groupByType === GroupByType.USER && ids ? { userId: { $in: ids } } : {}),
        ...(groupByType === GroupByType.TEAM && ids ? { teamId: { $in: ids } } : {}),
        entities: recordTypes.reduce((result, entityType) => {
          return {
            ...result,
            [entityType]: {
              includeGroups: true,
              ...(groupByType === GroupByType.GROUP && ids ? { groups: { $any: ids } } : {}),
            },
          };
        }, {}),
      },
    };

    const aggregationPayloadWithCadence = {
      ...aggregationPayload,
      $filters: {
        ...aggregationPayload.$filters,
        $and: [{ cadenceDaysOut: { $gt: 0 } }],
      },
    };

    const [response, responseWithCadence]: [
      AggregatedListResponse<any, ListResponseAggregation<any>[]>,
      AggregatedListResponse<any, ListResponseAggregation<any>[]>
    ] = yield all([
      callApi("fetchPins", organization.id, aggregationPayload),
      callApi("fetchPins", organization.id, aggregationPayloadWithCadence),
    ]);
    const result: SupportingGroupsRecordStats = {};
    const idSet = new Set(ids ?? []);
    responseWithCadence.aggregations.forEach((row: ListResponseAggregation<any>) => {
      if (idSet.has(parseInt(row.key, 10))) {
        result[row.key] = { total: 0, relevant: row.doc_count ?? 0 };
      }
    });
    response.aggregations.forEach((row: ListResponseAggregation<any>) => {
      if (result[row.key]) {
        result[row.key] = { ...result[row.key], total: row.doc_count ?? 0 };
      }
    });
    yield put(fetchRecordsOutOfCadence.success(result));
  } catch (error) {
    yield put(fetchRecordsOutOfCadence.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchDealsRotting({ payload }: ReturnType<typeof fetchDealsRotting.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    let $aggs;
    if (payload.dealsGroupByType === DealsGroupByType.FUNNEL) {
      $aggs = {
        primaryGroup: { field: "funnel.name" },
        innerHits: { columns: ["funnel.id", "stage.id"], limit: 1 },
        secondaryGroup: { field: "stage.name" },
      };
    } else if (payload.dealsGroupByType === DealsGroupByType.USER) {
      $aggs = {
        primaryGroup: { field: "user.id" },
        innerHits: { columns: ["user.username"], limit: 1 },
      };
    } else {
      $aggs = {
        primaryGroup: { field: "teams" },
      };
    }
    const dealsAggregationResponse: AggregatedListResponse<Deal> = yield callApi(
      "fetchDeals",
      organization.id,
      {
        $aggs,
        $limit: 0,
      }
    );
    const dealsAggregationMap: Record<string, number> =
      dealsAggregationResponse.aggregations.reduce(
        (result: Record<string, number>, record: ListResponseAggregation<Deal>) => {
          return {
            ...result,
            [record.key]: record.doc_count,
          };
        },
        {}
      );
    const rottingDealsAggregationResponse: AggregatedListResponse<Deal> = yield callApi(
      "fetchDeals",
      organization.id,
      {
        $filters: {
          $and: [{ rottingDaysOut: { $gt: 0 } }],
        },
        $aggs,
        $limit: 0,
      }
    );

    const dealsRottingStats: Record<DealsGroupBy["id"], RecordsStats> =
      rottingDealsAggregationResponse.aggregations.reduce(
        (
          result: Record<DealsGroupBy["id"], RecordsStats>,
          record: ListResponseAggregation<Deal>
        ) => {
          let id;
          if (payload.dealsGroupByType === DealsGroupByType.FUNNEL) {
            id = record.secondary[0].data[0].funnel.id;
          } else {
            id = record.key;
          }
          return {
            ...result,
            [id]: {
              relevant: record.doc_count,
              total: dealsAggregationMap[record.key],
            },
          };
        },
        {}
      );

    let rottingStagesStats: Record<DealsGroupBy["id"], Record<Stage["name"], RecordsStats>> = {};
    if (payload.dealsGroupByType === DealsGroupByType.FUNNEL) {
      const stagesStats: Record<
        string,
        Record<Stage["name"], number>
      > = dealsAggregationResponse.aggregations.reduce(
        (
          result: Record<string, Record<Stage["name"], number>>,
          record: ListResponseAggregation<Deal>
        ) => {
          const stagesMap: Record<Stage["name"], number> = record.secondary.reduce(
            (result: Record<Stage["name"], number>, record: ListResponseAggregation<Deal>) => {
              return {
                [record.key]: record.doc_count,
                ...result,
              };
            },
            {}
          );
          return {
            ...result,
            [record.key]: stagesMap,
          };
        },
        {}
      );

      rottingStagesStats = rottingDealsAggregationResponse.aggregations.reduce(
        (
          result: Record<DealsGroupBy["id"], Record<Stage["name"], RecordsStats>>,
          funnel: ListResponseAggregation<Deal>
        ) => {
          const stagesMap: Record<Stage["name"], RecordsStats> = funnel.secondary.reduce(
            (result: Record<Stage["name"], RecordsStats>, stage: ListResponseAggregation<Deal>) => {
              return {
                [stage.key]: {
                  total: stagesStats[funnel.key][stage.key],
                  relevant: stage.doc_count,
                },
                ...result,
              };
            },
            {}
          );
          return {
            ...result,
            [funnel.secondary[0].data[0].funnel.id]: stagesMap,
          };
        },
        {}
      );
    }

    yield put(
      fetchDealsRotting.success({
        dealsRottingStats,
        stagesStats: rottingStagesStats,
      })
    );
  } catch (error) {
    yield put(fetchDealsRotting.failure(error));
    yield put(handleError({ error }));
  }
}

function* onFilterUpdate({
  payload: updatedFilter,
}: ReturnType<typeof setRecordsOutOfCadenceFilter>) {
  yield call([homeSettings, homeSettings.setEngagementCardState], updatedFilter);
}

function* onRecordsSourceFilterUpdate({
  payload: updatedFilter,
}: ReturnType<typeof setRecordSourceFilter>) {
  yield call([homeSettings, homeSettings.setRecordsSourceCardState], updatedFilter);
}

function* onDealsRottingFilterUpdate({
  payload: updatedFilter,
}: ReturnType<typeof setDealsRottingFilter>) {
  yield call([homeSettings, homeSettings.setDealsRottingCardState], updatedFilter);
}

export function* onFetchEngagementRecordsList({
  payload,
}: ReturnType<typeof fetchEngagementRecordsList.request>) {
  try {
    const entitiesFilters: Partial<Record<EntityTypesSupportedByMapsPage, PlatformFilterModel>> =
      {};
    // each entity gets a group filter, whereas it is only valid for one of them (whose type matches group type)
    // this is ok to proceed with this per Platform team, because Platform will simply ignore invalid filters

    payload.recordTypes.forEach((entityType) => {
      if (payload.type === GroupByType.GROUP) {
        entitiesFilters[entityType] = { includeGroups: true, groups: { $any: [payload.id] } };
      } else {
        entitiesFilters[entityType] = { includeGroups: true };
      }
    });

    const organization: Organization = yield select(getOrganization);
    let idFilter: {};
    if (payload.type === GroupByType.USER) {
      idFilter = {
        userId: { $in: payload.id },
        entities: entitiesFilters,
        cadenceDaysOut: { $gt: 0 },
      };
    } else if (payload.type === GroupByType.TEAM) {
      idFilter = {
        teamId: { $in: payload.id },
        entities: entitiesFilters,
        cadenceDaysOut: { $gt: 0 },
      };
    } else {
      idFilter = { entities: entitiesFilters, cadenceDaysOut: { $gt: 0 } };
    }

    const params = {
      $filters: idFilter,
      $limit: PREVIEW_MODAL_LIMIT,
      $offset: ((payload.page ?? 1) - 1) * PREVIEW_MODAL_LIMIT,
    };
    const recordsResponse: ListResponse<MapEntity> = yield callApi(
      "fetchPins",
      organization.id,
      params
    );
    yield put(
      fetchEngagementRecordsList.success({
        records: recordsResponse.data,
        totalRecords: recordsResponse.total,
      })
    );
  } catch (error) {
    yield put(fetchEngagementRecordsList.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onFetchRecordsBySourceForSelectedType({
  payload,
}: ReturnType<typeof fetchRecordsBySourceForSelectedType.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const filter = {
      entities: payload.entityTypes,
      source: payload.sourceType,
      $and: [
        {
          userId: { $in: payload.userId },
          createdAt: {
            $gte: getStartDate(payload.dateRange)?.toISOString(),
            $lte: endOfToday().toISOString(),
          },
        },
      ],
    };

    const params = {
      $filters: filter,
      $limit: PREVIEW_MODAL_LIMIT,
      $offset: ((payload.page ?? 1) - 1) * PREVIEW_MODAL_LIMIT,
    };
    const recordsResponse: ListResponse<MapEntity> = yield callApi(
      "fetchPins",
      organization.id,
      params
    );
    yield put(
      fetchRecordsBySourceForSelectedType.success({
        recordsBySourceList: recordsResponse.data,
        totalRecords: recordsResponse.total,
      })
    );
  } catch (error) {
    yield put(fetchRecordsBySourceForSelectedType.failure(error));
    yield put(handleError({ error }));
  }
}

export function* homeSaga() {
  yield takeLatest(initialize.request, onInitialize);
  yield takeLatest(fetchRecentCompanies.request, onFetchRecentCompanies);
  yield takeLatest(fetchTodayActivitiesData.request, onFetchTodayActivitiesData);
  yield takeLatest(fetchTodayActivitiesDataForReps.request, onFetchTodayActivitiesDataForReps);
  yield takeLatest(fetchActivityByRepData.request, onFetchActivityByRepData);
  yield takeLatest(fetchActivityByTypesData.request, onFetchActivityByTypesData);
  yield takeLatest(fetchSelectedActivityByTypesData.request, onFetchSelectedActivityByTypesData);
  yield takeLatest(fetchRecordsBySourceSummary.request, onFetchRecordsBySourceSummary);
  yield takeLatest(fetchActivityOverview.request, onFetchActivityOverview);
  yield takeLatest(fetchDailyChangeData.request, onFetchDailyChangeData);
  yield takeLatest(fetchFunnelSummary.request, onFetchFunnelSummary);
  yield takeLatest(fetchOpenDealsData.request, onFetchOpenDealsData);
  yield takeLatest(fetchRecordsOutOfCadence.request, onFetchRecordsOutOfCadence);
  yield takeLatest(setRecordSourceFilter, onRecordsSourceFilterUpdate);
  yield takeLatest(setRecordsOutOfCadenceFilter, onFilterUpdate);
  yield takeLatest(fetchDealsRotting.request, onFetchDealsRotting);
  yield takeLatest(setDealsRottingFilter, onDealsRottingFilterUpdate);
  yield takeLatest(fetchEngagementRecordsList.request, onFetchEngagementRecordsList);
  yield takeLatest(
    fetchRecordsBySourceForSelectedType.request,
    onFetchRecordsBySourceForSelectedType
  );
}

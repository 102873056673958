import {
  Actions,
  bulkUpdateActivityType,
  createActivityType,
  deleteActivityType,
  fetchActivityTypes,
  updateActivityType,
} from "./actions";
import { createReducer } from "typesafe-actions";
import { RootState } from "../rootReducer";
import { createSelector } from "reselect";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { Activity } from "@mapmycustomers/shared/types/entity";

export interface ActivityState {
  activitiesOfActivityType: Record<ActivityType["id"], Activity[]>;
  activityTypes: ActivityType[];
  isActivityTypeCreating: boolean;
  activityTypeDeletingIds: ActivityType["id"][];
  activityTypeFetchingActivitiesIds: ActivityType["id"][];
  activityTypeUpdatingIds: ActivityType["id"][];
  isBulkActivityTypeUpdating: boolean;
}

const initialState: ActivityState = {
  activitiesOfActivityType: [],
  activityTypes: [],
  isActivityTypeCreating: false,
  activityTypeDeletingIds: [],
  activityTypeFetchingActivitiesIds: [],
  activityTypeUpdatingIds: [],
  isBulkActivityTypeUpdating: false,
};

const activity = createReducer<ActivityState, Actions>(initialState)
  .handleAction(fetchActivityTypes.success, (state, { payload }) => ({
    ...state,
    activityTypes: payload,
  }))
  .handleAction(createActivityType.request, (state) => ({
    ...state,
    isActivityTypeCreating: true,
  }))
  .handleAction(createActivityType.success, (state, { payload }) => ({
    ...state,
    isActivityTypeCreating: false,
    activityTypes: state.activityTypes.concat(payload),
  }))
  .handleAction(createActivityType.failure, (state) => ({
    ...state,
    isActivityTypeCreating: false,
  }))
  .handleAction(updateActivityType.request, (state, { payload }) => ({
    ...state,
    activityTypeUpdatingIds: state.activityTypeUpdatingIds.concat(payload.id),
  }))
  .handleAction(updateActivityType.success, (state, { payload }) => ({
    ...state,
    activityTypeUpdatingIds: state.activityTypeUpdatingIds.filter((id) => id !== payload.id),
    activityTypes: state.activityTypes.map((activityType) => {
      if (activityType.id === payload.id) {
        return {
          ...activityType,
          ...payload,
        };
      }

      return activityType;
    }),
  }))
  .handleAction(updateActivityType.failure, (state, { payload }) => ({
    ...state,
    activityTypeUpdatingIds: state.activityTypeUpdatingIds.filter((id) => id !== payload),
  }))
  .handleAction(deleteActivityType.request, (state, { payload }) => ({
    ...state,
    activityTypeDeletingIds: state.activityTypeDeletingIds.concat(payload),
  }))
  .handleAction(deleteActivityType.success, (state, { payload }) => ({
    ...state,
    activityTypeDeletingIds: state.activityTypeDeletingIds.filter((id) => id !== payload),
    activityTypes: state.activityTypes.filter((activityType) => activityType.id !== payload),
  }))
  .handleAction(deleteActivityType.failure, (state, { payload }) => ({
    ...state,
    activityTypeDeletingIds: state.activityTypeDeletingIds.filter((id) => id !== payload),
  }))
  .handleAction(bulkUpdateActivityType.request, (state, { payload }) => ({
    ...state,
    isBulkActivityTypeUpdating: true,
  }))
  .handleAction(bulkUpdateActivityType.success, (state, { payload }) => ({
    ...state,
    isBulkActivityTypeUpdating: false,
    activityTypes: state.activityTypes.map((activityType) => {
      const updatedActivityType = payload.find((at) => at.id === activityType.id);
      if (updatedActivityType) {
        return {
          ...activityType,
          ...updatedActivityType,
        };
      }

      return activityType;
    }),
  }))
  .handleAction(bulkUpdateActivityType.failure, (state, { payload }) => ({
    ...state,
    isBulkActivityTypeUpdating: false,
  }));

const activityState = (state: RootState) => state.activity;

export const getActivitiesOfActivityType = createSelector(
  activityState,
  ({ activitiesOfActivityType }) => activitiesOfActivityType
);

export const getActiveActivityTypes = createSelector(activityState, ({ activityTypes }) =>
  activityTypes.filter((activityType) => activityType.active)
);

export const getActivityTypes = createSelector(activityState, ({ activityTypes }) => activityTypes);

export const getIsActivityTypeCreating = createSelector(
  activityState,
  ({ isActivityTypeCreating }) => isActivityTypeCreating
);
export const getActivityTypeDeletingIds = createSelector(
  activityState,
  ({ activityTypeDeletingIds }) => activityTypeDeletingIds
);
export const getActivityTypeFetchingActivitiesIds = createSelector(
  activityState,
  ({ activityTypeFetchingActivitiesIds }) => activityTypeFetchingActivitiesIds
);
export const getActivityTypeUpdatingIds = createSelector(
  activityState,
  ({ activityTypeUpdatingIds }) => activityTypeUpdatingIds
);
export const getIsBulkActivityTypeUpdating = createSelector(
  activityState,
  ({ isBulkActivityTypeUpdating }) => isBulkActivityTypeUpdating
);

export type ActivityActions = Actions;
export default activity;

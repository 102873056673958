import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const stackRankState = (state: RootState) => state.scene.dashboard.stackRank;

export const getStackRankViewState = createSelector(stackRankState, ({ viewState }) => viewState);

export const isPerformanceDataLoading = createSelector(
  stackRankState,
  ({ performanceDataLoading }) => performanceDataLoading
);
export const getPerformanceData = createSelector(
  stackRankState,
  ({ performanceData }) => performanceData
);

export const isDrillDownVisible = createSelector(
  stackRankState,
  ({ drillDownVisible }) => drillDownVisible
);
export const getDrillDownViewState = createSelector(
  stackRankState,
  ({ drillDownViewState }) => drillDownViewState
);
export const isDrillDownLoading = createSelector(
  stackRankState,
  ({ drillDownLoading }) => drillDownLoading
);
export const getDrillDownData = createSelector(
  stackRankState,
  ({ drillDownData }) => drillDownData
);

export const isUserDrillDownLoading = createSelector(
  stackRankState,
  ({ userMetricDrillDownViewState }) => !!userMetricDrillDownViewState
);
export const getUserDrillDownViewState = createSelector(
  stackRankState,
  ({ userMetricDrillDownViewState }) => userMetricDrillDownViewState
);
export const getUserDrillDownTotalFilteredRecords = createSelector(
  stackRankState,
  ({ totalUserDrillDownFilteredRecords }) => totalUserDrillDownFilteredRecords
);

import { defineMessages, IntlShape } from "react-intl";
import AreaType from "@mapmycustomers/shared/types/territory/AreaType";
import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import TerritoryLevel from "@mapmycustomers/shared/enum/TerritoryLevel";
import { MessageDescriptor } from "@formatjs/intl/src/types";

const nameMessages = defineMessages<AreaType>({
  [`${CountryCode["AUSTRALIA"]}_${TerritoryLevel.ADM1}`]: {
    id: "areaTypes.au-adm1",
    defaultMessage: "Australian States",
  },
  [`${CountryCode["AUSTRALIA"]}_${TerritoryLevel.ADM2}`]: {
    id: "areaTypes.au-adm2",
    defaultMessage: "Australian Local Government Areas",
  },
  [`${CountryCode["AUSTRALIA"]}_${TerritoryLevel.ADM3}`]: {
    id: "areaTypes.au-adm3",
    defaultMessage: "Australian Zip Code Areas (2023)",
  },
  [`${CountryCode["CANADA"]}_${TerritoryLevel.ADM1}`]: {
    id: "areaTypes.ca-adm1",
    defaultMessage: "Canadian Provinces",
  },
  [`${CountryCode["CANADA"]}_${TerritoryLevel.ADM2}`]: {
    id: "areaTypes.ca-adm2",
    defaultMessage: "Canadian Districts",
  },
  [`${CountryCode["CANADA"]}_${TerritoryLevel.ADM3}`]: {
    id: "areaTypes.ca-adm3",
    defaultMessage: "Canadian Zip Code Areas (2023)",
  },
  [`${CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]}_${TerritoryLevel.ADM1}`]:
    {
      id: "areaTypes.uk-adm1",
      defaultMessage: "UK Countries",
    },
  [`${CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]}_${TerritoryLevel.ADM2}`]:
    {
      id: "areaTypes.uk-adm2",
      defaultMessage: "UK Census Districts",
    },
  [`${CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]}_${TerritoryLevel.ADM3}`]:
    {
      id: "areaTypes.uk-adm3",
      defaultMessage: "UK Postal Codes",
    },
  [`${CountryCode["UNITED STATES OF AMERICA"]}_${TerritoryLevel.ADM1}`]: {
    id: "areaTypes.us-adm1",
    defaultMessage: "US States",
  },
  [`${CountryCode["UNITED STATES OF AMERICA"]}_${TerritoryLevel.ADM2}`]: {
    id: "areaTypes.us-adm2",
    defaultMessage: "US Counties",
  },
  [`${CountryCode["UNITED STATES OF AMERICA"]}_${TerritoryLevel.ADM3}`]: {
    id: "areaTypes.us-adm3",
    defaultMessage: "US Zip Code Areas (2023)",
  },
} as Record<AreaType, MessageDescriptor>);

export const getAreaTypeDisplayName = (intl: IntlShape, areaType: AreaType) =>
  areaType in nameMessages ? intl.formatMessage(nameMessages[areaType]) : areaType;

const descriptionMessages = defineMessages<AreaType>({
  [`${CountryCode["AUSTRALIA"]}_${TerritoryLevel.ADM1}`]: {
    id: "areaTypes.au-adm1.description",
    defaultMessage: "Territory bounds are saved using Australian States.",
  },
  [`${CountryCode["AUSTRALIA"]}_${TerritoryLevel.ADM2}`]: {
    id: "areaTypes.au-adm2.description",
    defaultMessage:
      "Territory bounds are saved using Australian Local Government Area borders. However, you can still add LGAs in bulk by selecting its parent State.",
  },
  [`${CountryCode["AUSTRALIA"]}_${TerritoryLevel.ADM3}`]: {
    id: "areaTypes.au-adm3.description",
    defaultMessage:
      "Territory bounds are saved using Australian Postal Code borders. However, you can still add Postal Codes in bulk by selecting its parent Local Government Area and/or State.",
  },
  [`${CountryCode["CANADA"]}_${TerritoryLevel.ADM1}`]: {
    id: "areaTypes.ca-adm1.description",
    defaultMessage: "Territory bounds are saved using Canadian Provinces.",
  },
  [`${CountryCode["CANADA"]}_${TerritoryLevel.ADM2}`]: {
    id: "areaTypes.ca-adm2.description",
    defaultMessage:
      "Territory bounds are saved using Canadian County borders. However, you can still add Counties in bulk by selecting its parent Province.",
  },
  [`${CountryCode["CANADA"]}_${TerritoryLevel.ADM3}`]: {
    id: "areaTypes.ca-adm3.description",
    defaultMessage:
      "Territory bounds are saved using Canadian Postal Code borders. However, you can still add Postal Codes in bulk by selecting its parent County and/or Province.",
  },
  [`${CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]}_${TerritoryLevel.ADM1}`]:
    {
      id: "areaTypes.uk-adm1.description",
      defaultMessage: "Territory bounds are saved using UK Countries.",
    },
  [`${CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]}_${TerritoryLevel.ADM2}`]:
    {
      id: "areaTypes.uk-adm2.description",
      defaultMessage:
        "Territory bounds are saved using UK Census District borders. However, you can still add Census Districts in bulk by selecting its parent Country.",
    },
  [`${CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]}_${TerritoryLevel.ADM3}`]:
    {
      id: "areaTypes.uk-adm3.description",
      defaultMessage:
        "Territory bounds are saved using UK Postal Code borders. However, you can still add Postal Codes in bulk by selecting its parent Census District and/or Country.",
    },
  [`${CountryCode["UNITED STATES OF AMERICA"]}_${TerritoryLevel.ADM1}`]: {
    id: "areaTypes.us-adm1.description",
    defaultMessage: "Territory bounds are saved using US State borders.",
  },
  [`${CountryCode["UNITED STATES OF AMERICA"]}_${TerritoryLevel.ADM2}`]: {
    id: "areaTypes.us-adm2.description",
    defaultMessage:
      "Territory bounds are saved using US County borders. However, you can still add Counties in bulk by selecting its parent State.",
  },
  [`${CountryCode["UNITED STATES OF AMERICA"]}_${TerritoryLevel.ADM3}`]: {
    id: "areaTypes.us-adm3.description",
    defaultMessage:
      "Territory bounds are saved using Zip Code borders. However, you can still add Zip Codes in bulk by selecting its parent County and/or State.",
  },
} as Record<AreaType, MessageDescriptor>);

export const getAreaTypeDescription = (intl: IntlShape, areaType: AreaType) =>
  areaType in descriptionMessages ? intl.formatMessage(descriptionMessages[areaType]) : areaType;

const captionMessages = defineMessages<AreaType>({
  [`${CountryCode["AUSTRALIA"]}_${TerritoryLevel.ADM1}` as AreaType]: {
    id: "areaTypes.au-adm1.caption",
    defaultMessage: "Selected States",
  },
  [`${CountryCode["AUSTRALIA"]}_${TerritoryLevel.ADM2}`]: {
    id: "areaTypes.au-adm2.caption",
    defaultMessage: "Selected LGAs",
  },
  [`${CountryCode["AUSTRALIA"]}_${TerritoryLevel.ADM3}`]: {
    id: "areaTypes.au-adm3.caption",
    defaultMessage: "Selected Zip Codes",
  },
  [`${CountryCode["CANADA"]}_${TerritoryLevel.ADM1}`]: {
    id: "areaTypes.ca-adm1.caption",
    defaultMessage: "Selected Provinces",
  },
  [`${CountryCode["CANADA"]}_${TerritoryLevel.ADM2}`]: {
    id: "areaTypes.ca-adm2.caption",
    defaultMessage: "Selected Districts",
  },
  [`${CountryCode["CANADA"]}_${TerritoryLevel.ADM3}`]: {
    id: "areaTypes.ca-adm3.caption",
    defaultMessage: "Selected Zip Codes",
  },
  [`${CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]}_${TerritoryLevel.ADM1}`]:
    {
      id: "areaTypes.uk-adm1.caption",
      defaultMessage: "Selected Countries",
    },
  [`${CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]}_${TerritoryLevel.ADM2}`]:
    {
      id: "areaTypes.uk-adm2.caption",
      defaultMessage: "Selected Districts",
    },
  [`${CountryCode["UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND"]}_${TerritoryLevel.ADM3}`]:
    {
      id: "areaTypes.uk-adm3.caption",
      defaultMessage: "Selected Postal Codes",
    },
  [`${CountryCode["UNITED STATES OF AMERICA"]}_${TerritoryLevel.ADM1}`]: {
    id: "areaTypes.us-adm1.caption",
    defaultMessage: "Selected States",
  },
  [`${CountryCode["UNITED STATES OF AMERICA"]}_${TerritoryLevel.ADM2}`]: {
    id: "areaTypes.us-adm2.caption",
    defaultMessage: "Selected Counties",
  },
  [`${CountryCode["UNITED STATES OF AMERICA"]}_${TerritoryLevel.ADM3}`]: {
    id: "areaTypes.us-adm3.caption",
    defaultMessage: "Selected Zip Codes",
  },
} as Record<AreaType, MessageDescriptor>);

export const getAreaTypeCaption = (intl: IntlShape, areaType: AreaType) =>
  areaType in captionMessages ? intl.formatMessage(captionMessages[areaType]) : areaType;

import React, { ReactNode } from "react";
import cn from "classnames";
import invariant from "tiny-invariant";
import styles from "./LoadingSpinner.module.scss";

interface Props {
  children?: ReactNode;
  className?: string;
  global?: boolean;
  inline?: boolean;
  micro?: boolean;
  mini?: boolean;
}

const LoadingSpinner: React.FC<Props> = ({
  className,
  children,
  global,
  inline,
  micro,
  mini,
}) => {
  invariant(
    !inline || !global,
    "Can't use both inline and global props, they're mutually exclusive"
  );

  return (
    <div
      className={cn(styles.loader, className, {
        [styles.loaderGlobal]: global,
        [styles.loaderInline]: inline,
      })}
    >
      <div
        className={cn(
          styles.spinner,
          { [styles.miniSpinner]: mini },
          { [styles.microSpinner]: micro }
        )}
      />
      {children && <div className={styles.message}>{children}</div>}
    </div>
  );
};

export default LoadingSpinner;

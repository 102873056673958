import { EntityType } from "@mapmycustomers/shared/enum";
import Path from "enum/Path";
import { IEntityLinkGetter } from "./EntityLinkGetterContext";

const defaultEntityLinkGetter: IEntityLinkGetter = (...args): string => {
  const [entityType, entityId] = args;
  switch (entityType) {
    case EntityType.COMPANY:
      return `${Path.COMPANY}/${entityId}`;
    case EntityType.DEAL:
      return `${Path.DEAL}/list/${entityId}`;
    case EntityType.PERSON:
      return `${Path.PEOPLE}/${entityId}`;
  }
};

export const entityLinkGetterForIframes: IEntityLinkGetter = (...args): string => {
  const [entityType, entityId, options] = args;

  if (!options?.noIframe) {
    switch (entityType) {
      case EntityType.COMPANY:
        return `${Path.WEB_1}${Path.COMPANY}/${options?.edit ? "edit" : "view"}/${entityId}`;
      case EntityType.DEAL:
        return `${Path.WEB_1}/deals/${options?.edit ? "edit" : "view"}/${entityId}`;
      case EntityType.PERSON:
        return `${Path.WEB_1}${Path.PEOPLE}/${options?.edit ? "edit" : "view"}/${entityId}`;
    }
  } else {
    return defaultEntityLinkGetter(entityType, entityId, options);
  }
};

export default defaultEntityLinkGetter;

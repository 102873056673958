import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { EntityTypesSupportingSavedFilters } from "@mapmycustomers/shared/types/entity";
import SavedFilter, {
  CreateSavedFilterPayload,
} from "@mapmycustomers/shared/types/viewModel/SavedFilter";
import ColumnModel from "@mapmycustomers/shared/types/viewModel/internalModel/ColumnModel";

export const fetchAllSavedFilters = createAsyncAction(
  "@savedFilters/fetchAllSavedFilters/request",
  "@savedFilters/fetchAllSavedFilters/success",
  "@savedFilters/fetchAllSavedFilters/failure"
)<void, void, void>();

export const fetchSavedFilters = createAsyncAction(
  "@savedFilters/fetchSavedFilters/request",
  "@savedFilters/fetchSavedFilters/success",
  "@savedFilters/fetchSavedFilters/failure"
)<
  {
    entityType: EntityTypesSupportingSavedFilters;
    // initialization should only be true when called when app loads, in other cases just skip it
    initialization?: boolean;
  },
  { entityType: EntityTypesSupportingSavedFilters; savedFilters: SavedFilter[] },
  EntityTypesSupportingSavedFilters
>();

export const selectSavedFilter = createAction("@savedFilters/selectSavedFilter")<{
  entityType: EntityTypesSupportingSavedFilters;
  savedFilter: SavedFilter | undefined;
  // mostly used when app initializes, in other cases skip it
  skipUrlUpdate?: boolean;
}>();

export const createSavedFilter = createAsyncAction(
  "@savedFilters/create/request",
  "@savedFilters/create/success",
  "@savedFilters/create/failure"
)<
  {
    columns: ColumnModel;
    favorite: boolean;
    filter: CreateSavedFilterPayload;
    callback?: (filter: SavedFilter) => void;
  },
  SavedFilter,
  void
>();

// this should be used for all SavedFilters except maps
export const updateSavedFilter = createAsyncAction(
  "@savedFilters/update/request",
  "@savedFilters/update/success",
  "@savedFilters/update/failure"
)<
  {
    columns: ColumnModel;
    favorite?: boolean;
    filter: SavedFilter;
    preventUpdateSavedFilter?: boolean;
    callback?: (filter: SavedFilter) => void;
  },
  SavedFilter,
  void
>();

export const deleteSavedFilter = createAsyncAction(
  "@savedFilters/delete/request",
  "@savedFilters/delete/success",
  "@savedFilters/delete/failure"
)<SavedFilter, void, void>();

export const initializeSavedFiltersForView = createAction("@savedFilters/initializeForView")<{
  entityType: EntityTypesSupportingSavedFilters;
  viewKey: string; // entityType is not enough when there are several views per entity (e.g. Kanban/List/Forecast views for deals)
}>();

export type Actions = ActionType<
  | typeof createSavedFilter
  | typeof deleteSavedFilter
  | typeof fetchAllSavedFilters
  | typeof fetchSavedFilters
  | typeof selectSavedFilter
  | typeof updateSavedFilter
>;

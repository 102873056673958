import { Activity } from "@mapmycustomers/shared/types/entity";
import { FormProps } from "antd/es/form";
import FormValues from "../types/FormValues";
import { useCallback, useMemo, useState } from "react";
import SaveOption from "../types/SaveOption";
import ActivityVisibility from "@mapmycustomers/shared/enum/activity/ActivityVisibility";
import { parseApiDate } from "util/parsers";
import { differenceInCalendarDays } from "date-fns/esm";
import SaveModeStatus from "../types/SaveModeStatus";
import RecurringIntervalType from "../enum/RecurringIntervalType";
import { stringifyActivityInterval } from "component/createEditEntity/Activity/utils/activityInterval";

const useSaveModeHandler = (
  activity?: Activity
): [SaveModeStatus, NonNullable<FormProps<FormValues>["onValuesChange"]>] => {
  const [options, setOptions] = useState<SaveOption[]>([]);
  const [recurChanged, setRecurChanged] = useState<boolean>(false);
  const [isActivityPublic, setIsActivityPublic] = useState<boolean>(
    activity?.visibility === ActivityVisibility.SHARED_WITH_ORGANIZATION
  );
  const [areAssociationFieldsChanged, setAssociationFieldsChanged] = useState<boolean>(false);
  const [areCheckInFieldsChanged, setCheckInFieldsChanged] = useState(false);

  const isCheckInActivityVerified = useMemo(() => activity?.reliability === true, [activity]);

  const showCheckInSubmit = useMemo(
    () => isCheckInActivityVerified && (areAssociationFieldsChanged || areCheckInFieldsChanged),
    [areAssociationFieldsChanged, areCheckInFieldsChanged, isCheckInActivityVerified]
  );

  const handleChange = useCallback<NonNullable<FormProps<FormValues>["onValuesChange"]>>(
    (_, allFields) => {
      const options = [];
      let isRecurActivity =
        allFields.recurIntervalType &&
        allFields.recurIntervalType !== RecurringIntervalType.NEVER &&
        activity?.recurInterval &&
        activity?.recurInterval !== RecurringIntervalType.NEVER;

      let isRecurIntervalChanged =
        !!allFields.recurInterval &&
        stringifyActivityInterval(allFields.recurInterval) !== activity?.recurInterval;

      let startAtDateIsChanged = false;
      let differenceStartAtInDays = 0;
      if (allFields.startAt) {
        startAtDateIsChanged = true;
        if (activity?.startAt) {
          const parsedActivityStartAt = parseApiDate(activity.startAt);
          differenceStartAtInDays = differenceInCalendarDays(
            allFields.startAt,
            parsedActivityStartAt
          );
          startAtDateIsChanged = allFields.startAt?.getTime() !== parsedActivityStartAt?.getTime();
        }
      }

      const differenceStartAtInDaysMoreThan1Day = differenceStartAtInDays > 1;
      if (isRecurActivity) {
        if (differenceStartAtInDaysMoreThan1Day || isRecurIntervalChanged) {
          if (differenceStartAtInDaysMoreThan1Day && !isRecurIntervalChanged) {
            // When the start time is moved by a day or more than a day.
            options.push(SaveOption.JUST_THIS_ACTIVITY, SaveOption.THIS_AND_FEATURE_ACTIVITIES);
          } else if (!differenceStartAtInDaysMoreThan1Day && isRecurIntervalChanged) {
            // When the recurring interval is changed.
            options.push(SaveOption.ALL_ACTIVITIES, SaveOption.THIS_AND_FEATURE_ACTIVITIES);
          } else {
            options.push(SaveOption.THIS_AND_FEATURE_ACTIVITIES);
          }
        } else if (
          !startAtDateIsChanged ||
          (startAtDateIsChanged && differenceStartAtInDays === 0)
        ) {
          // When only time is changed in star time and not the date
          // OR
          // When any other field is changed
          options.push(
            SaveOption.JUST_THIS_ACTIVITY,
            SaveOption.ALL_ACTIVITIES,
            SaveOption.THIS_AND_FEATURE_ACTIVITIES
          );
        }
      }

      setRecurChanged(differenceStartAtInDaysMoreThan1Day && isRecurIntervalChanged);
      setOptions(options);
      setIsActivityPublic(allFields.visibility === ActivityVisibility.SHARED_WITH_ORGANIZATION);
      setAssociationFieldsChanged(
        activity?.account?.id !== allFields.companyId ||
          activity?.contact?.id !== allFields.personId ||
          activity?.deal?.id !== allFields.dealId
      );
      const isCompletedChanged = allFields.completed !== activity?.completed;
      const isEndAtChanged =
        //activity?.endAt can be null so need !=
        allFields.endAt?.toISOString() != activity?.endAt; // eslint-disable-line eqeqeq
      const isStartAtChanged = allFields.startAt?.toISOString() !== activity?.startAt;
      setCheckInFieldsChanged(isCompletedChanged || isStartAtChanged || isEndAtChanged);
    },
    [activity]
  );
  return [{ options, recurChanged, isActivityPublic, showCheckInSubmit }, handleChange];
};

export default useSaveModeHandler;

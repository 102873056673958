@use "../styles/colors";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: colors.$mmc-white;
  border: 1px solid colors.$mmc-accent-gray;
  border-radius: 0.5rem;
}

.color {
  flex-shrink: 0;
  background-color: colors.$mmc-soft-gray;
  padding: 1rem 1.25rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.opacity {
  flex: 1;
  padding: 0 0.75rem;
}

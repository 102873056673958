import React from "react";
import styles from "../NotificationCommon.module.scss";
import MmcNotification from "types/MmcNotification";
import MmcNotificationAction from "enum/MmcNotificationAction";
import { Avatar } from "@mapmycustomers/ui";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EntityTypeIcon from "component/EntityTypeIcon";
import getActionIcon from "../util/getActionIcon";
import dealStage from "assets/icons/deal-stage-dark.svg";

const DANGER_NOTIFICATION_ACTIONS: MmcNotificationAction[] = [
  MmcNotificationAction.OUT_OF_CADENCE,
  MmcNotificationAction.OVERDUE_ACTIVITIES,
  MmcNotificationAction.DEALS_MARKED_LOST,
];

const WARNING_NOTIFICATION_ACTIONS: MmcNotificationAction[] = [MmcNotificationAction.DEALS_ROTTING];
const SUCCESS_NOTIFICATION_ACTIONS: MmcNotificationAction[] = [
  MmcNotificationAction.DEALS_MARKED_WON,
];

interface Props {
  notification: MmcNotification;
}

const Icon: React.FC<Props> = ({ notification: { action, updatedBy, entity } }) => {
  if (
    [
      MmcNotificationAction.ACTIVITY_LOGGED,
      MmcNotificationAction.NOTE_ADDED,
      MmcNotificationAction.ACTIVITY_ASSIGNED,
      MmcNotificationAction.MENTION,
      MmcNotificationAction.ACTIVITY_TIME_CHANGED,
    ].includes(action)
  ) {
    return updatedBy ? <Avatar className={styles.actionType} user={updatedBy} /> : null;
  }
  if (
    [
      MmcNotificationAction.DEALS_STAGE_CHANGED,
      MmcNotificationAction.SHARED_DEALS_CLOSING,
      MmcNotificationAction.OWNED_DEALS_CLOSING,
    ].includes(action)
  ) {
    return (
      <div className={cn(styles.actionType, styles.iconContainer)}>
        <img alt="deal-stage-icon" src={dealStage} />
      </div>
    );
  }

  const icon = getActionIcon(action);
  let actionType;
  if (icon) {
    actionType = (
      <FontAwesomeIcon
        className={cn(styles.icon, {
          [styles.activityIcon]: [
            MmcNotificationAction.ACTIVITY_STARTING,
            MmcNotificationAction.ACTIVITY_TIME_CHANGED,
            MmcNotificationAction.INCOMPLETE_ACTIVITY,
            MmcNotificationAction.MARK_ACTIVITY_AS_DONE,
          ].includes(action),
        })}
        icon={icon}
      />
    );
  } else if (action === MmcNotificationAction.OWNERSHIP_CHANGED && entity && entity.length > 0) {
    actionType = <EntityTypeIcon entityType={entity[0].type} />;
  }

  return actionType ? (
    <div
      className={cn(styles.actionType, styles.iconContainer, {
        [styles.iconContainerDanger]: DANGER_NOTIFICATION_ACTIONS.includes(action),
        [styles.iconContainerSuccess]: SUCCESS_NOTIFICATION_ACTIONS.includes(action),
        [styles.iconContainerWarning]: WARNING_NOTIFICATION_ACTIONS.includes(action),
      })}
    >
      {actionType}
    </div>
  ) : null;
};

export default Icon;

import { createSelector } from "reselect";
import { RootState } from "store/rootReducer";

const componentState = (state: RootState) => state.component.createEditActivityModal;

export const isCreating = createSelector(componentState, ({ createLoading }) => createLoading);
export const isInitializing = createSelector(
  componentState,
  ({ initializeLoading }) => initializeLoading
);

export const getEditedActivity = createSelector(
  componentState,
  ({ editedActivity }) => editedActivity
);

export const getAssociationsState = createSelector(
  componentState,
  ({ associations }) => associations
);

export const getPrefilledValues = createSelector(
  componentState,
  ({ prefilledValues }) => prefilledValues
);

export const isUpdating = createSelector(componentState, ({ updateLoading }) => updateLoading);
export const isDeleting = createSelector(componentState, ({ deleteLoading }) => deleteLoading);

export const isCreateActivityModalVisible = createSelector(
  componentState,
  ({ visible }) => visible
);

export const isCloningActivity = createSelector(componentState, ({ cloning }) => cloning);

export const getUploadedFilesList = createSelector(componentState, ({ filesAdded }) => filesAdded);

export const getUploadedFileListIds = createSelector(getUploadedFilesList, (filesAdded) =>
  filesAdded
    .filter(({ uploadedFile }) => !!uploadedFile)
    .map(({ uploadedFile }) => uploadedFile!.id)
);

export const isFilesUploading = createSelector(
  componentState,
  ({ filesUploading }) => filesUploading
);

export const getAssociatedFiles = createSelector(
  componentState,
  ({ associatedFiles }) => associatedFiles
);

export const isFilePreviewLoading = createSelector(
  componentState,
  ({ filePreviewLoading }) => filePreviewLoading
);
export const getFilePreview = createSelector(componentState, ({ filePreview }) => filePreview);
export const getFilePreviewId = createSelector(
  componentState,
  ({ filePreviewId }) => filePreviewId
);

export const hasNoAccess = createSelector(componentState, ({ noAccess }) => noAccess);

export const isEntityRelating = createSelector(
  componentState,
  ({ entityRelating }) => entityRelating
);

export const isActivityComplete = createSelector(
  componentState,
  ({ activityCompleted }) => activityCompleted
);

import React from "react";
import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import Dropdown, { DropDownProps } from "antd/es/dropdown";
import ColorPanel, { ColorPanelProps } from "./ColorPanel";
import styles from "./ColorDropdown.module.scss";
import ColorCircle from "./ColorCircleComponent";

interface ColorDropdownProps extends Omit<DropDownProps, "overlay"> {
  colorCircleClassName?: string;
  colorPanelProps?: Omit<ColorPanelProps, "onChange" | "value">;
  disabled?: boolean;
  onChange?: (color: AnyColor) => void;
  onOpacityChange?: (opacity: number) => void;
  opacity?: number;
  placement?:
    | "topLeft"
    | "topCenter"
    | "topRight"
    | "bottomLeft"
    | "bottomCenter"
    | "bottomRight"
    | "top"
    | "bottom";
  value?: AnyColor;
}

const ColorDropdown: React.FC<ColorDropdownProps> = ({
  children,
  colorCircleClassName,
  colorPanelProps,
  disabled,
  onChange,
  onOpacityChange,
  opacity,
  placement,
  value,
  ...props
}) => {
  return (
    <Dropdown
      overlay={
        <ColorPanel
          className={styles.colorPanel}
          onChange={onChange}
          onOpacityChange={onOpacityChange}
          opacity={opacity}
          value={value}
          {...colorPanelProps}
        />
      }
      disabled={disabled}
      placement={placement ?? "bottomLeft"}
      trigger={["click"]}
      {...props}
    >
      {children ?? (
        <div>
          <ColorCircle
            className={colorCircleClassName}
            disabled={disabled}
            value={value}
          />
        </div>
      )}
    </Dropdown>
  );
};

export default ColorDropdown;

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";
import { LoggedActivityMapPayload } from "../../component/frequency/type/LoggedActivityMap.d";
import Activity from "@mapmycustomers/shared/types/entity/Activity";
import FrequencyPreviewConfig from "../../component/frequency/type/FrequencyPreviewConfig";
import FrequencyEntity from "../../component/frequency/type/FrequencyEntity";

export const setFrequencyPanelConfig = createAction(
  "frequency/setFrequencyPanelConfig"
)<FrequencyPreviewConfig>();

export const initFrequencyModal = createAsyncAction(
  "frequency/initFrequencyModal/request",
  "frequency/initFrequencyModal/success",
  "frequency/initFrequencyModal/failure"
)<
  { entity: FrequencyEntity; analyticsPath?: string[] },
  { config: FrequencyPreviewConfig; lastFrequencyActivity: Activity | undefined },
  void
>();

export const hideFrequencyModal = createAction("frequency/hideFrequencyModal")<void>();

export const clearLoggedActivities = createAction("frequency/clearLoggedActivities")<void>();

export const fetchLoggedActivities = createAsyncAction(
  "frequency/fetchLoggedActivities/request",
  "frequency/fetchLoggedActivities/success",
  "frequency/fetchLoggedActivities/failure"
)<{ dates: Date[] }, { loggedActivities: LoggedActivityMapPayload }, void>();

export const clearPreviousActivities = createAction("frequency/clearPreviousActivities")<void>();

export const fetchPreviousActivities = createAsyncAction(
  "frequency/fetchPreviousActivities/request",
  "frequency/fetchPreviousActivities/success",
  "frequency/fetchPreviousActivities/failure"
)<{ offset: number }, { previousActivities: Activity[]; previousActivitiesTotal: number }, void>();

export const donePendingActivity = createAsyncAction(
  "frequency/donePendingActivity/request",
  "frequency/donePendingActivity/success",
  "frequency/donePendingActivity/failure"
)<Activity | undefined, void, void>();

export const initFrequencyPanel = createAsyncAction(
  "frequency/initFrequencyPanel/request",
  "frequency/initFrequencyPanel/success",
  "frequency/initFrequencyPanel/failure"
)<void, { pendingActivity: Activity | undefined }, void>();

export const updateEntityFrequency = createAsyncAction(
  "frequency/updateEntityFrequency/request",
  "frequency/updateEntityFrequency/success",
  "frequency/updateEntityFrequency/failure"
)<{ entity: FrequencyEntity; callback?: () => void }, void, void>();

export type Actions = ActionType<
  | typeof clearLoggedActivities
  | typeof clearPreviousActivities
  | typeof donePendingActivity
  | typeof fetchLoggedActivities
  | typeof fetchPreviousActivities
  | typeof hideFrequencyModal
  | typeof initFrequencyModal
  | typeof initFrequencyPanel
  | typeof setFrequencyPanelConfig
  | typeof updateEntityFrequency
>;

import React, { useCallback } from "react";
import AntdCheckbox, { CheckboxChangeEvent, CheckboxProps } from "antd/es/checkbox";

export interface Props extends Omit<CheckboxProps, "onChange"> {
  onChange?: (checked: boolean, event: CheckboxChangeEvent) => void;
}

const Checkbox: React.FC<Props> = ({ onChange, ...props }) => {
  const handleChange = useCallback((e) => onChange && onChange(e.target.checked, e), [onChange]);

  return <AntdCheckbox {...props} onChange={handleChange} />;
};

export default Checkbox;

import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchCompanies, fetchCompany } from "./actions";
import { callApi } from "store/api/callApi";
import { getOrganizationId } from "store/iam";
import Organization from "@mapmycustomers/shared/types/Organization";
import { handleError } from "store/errors/actions";
import { Company } from "@mapmycustomers/shared/types/entity";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

function* onFetchCompany({ payload }: ReturnType<typeof fetchCompany>) {
  const { id, options = {}, callback, failureCallback } = payload;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const company: Company = yield callApi("fetchCompany", orgId, id, options);
    yield call(callback, company);
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onFetchCompanies({ payload }: ReturnType<typeof fetchCompanies>) {
  const { ids, callback, failureCallback } = payload;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const companiesResponse: ListResponse<Company> = yield callApi("fetchCompanies", orgId, {
      $columns: ["id", "name", "geoPoint", "address", "region", "postalCode"],
      $filters: {
        $and: [
          {
            id: { $in: ids },
          },
        ],
      },
      $limit: 1000,
      $order: "name",
    });
    yield call(callback, companiesResponse.data);
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

export function* companySaga() {
  yield takeEvery(fetchCompany, onFetchCompany);
  yield takeEvery(fetchCompanies, onFetchCompanies);
}

import {
  applyActivitiesOverdueDrillDownListViewSettings,
  fetchActivitiesOverdueCardDrillDownData,
  hideActivitiesOverdueDrillDown,
  ActivitiesOverdueCardActions,
  showActivitiesOverdueDrillDown,
} from "./actions";
import { createReducer } from "typesafe-actions";
import { DEFAULT_PAGE_SIZE } from "util/consts";
import ActivitiesOverdueCardConfiguration from "types/dashboard/cards/activitiesOverdue/ActivitiesOverdueCardConfiguration";
import DrillDownViewState from "scene/dashboard/types/DrillDownViewState";

export interface ActivitiesOverdueCardState {
  configuration?: ActivitiesOverdueCardConfiguration; // also works as a "visible" trigger, visible if set
  loading?: boolean;
  totalFilteredRecords: number;
  totalRecords: number;
  viewState: DrillDownViewState;
}

export const activitiesOverdueInitialState: ActivitiesOverdueCardState = {
  totalFilteredRecords: 0,
  totalRecords: 0,
  viewState: {
    columns: [],
    filter: {},
    range: { startRow: 0, endRow: DEFAULT_PAGE_SIZE },
    sort: [],
    viewAs: undefined,
  },
};

const activitiesOverdueState = createReducer<
  ActivitiesOverdueCardState,
  ActivitiesOverdueCardActions
>(activitiesOverdueInitialState)
  .handleAction(showActivitiesOverdueDrillDown.request, (state, { payload }) => ({
    ...state,
    configuration: payload.configuration,
  }))
  .handleAction(showActivitiesOverdueDrillDown.success, (state, { payload }) => ({
    ...state,
    viewState: payload.viewState,
  }))
  .handleAction(hideActivitiesOverdueDrillDown, (state) => ({ ...state, configuration: undefined }))
  .handleAction(fetchActivitiesOverdueCardDrillDownData.request, (state, { payload }) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchActivitiesOverdueCardDrillDownData.success, (state, { payload }) => ({
    ...state,
    loading: false,
    totalFilteredRecords: payload.totalFilteredRecords,
    totalRecords: payload.totalRecords,
  }))
  .handleAction(fetchActivitiesOverdueCardDrillDownData.failure, (state, { payload }) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyActivitiesOverdueDrillDownListViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      range: payload.range ?? state.viewState.range,
      sort: payload.sort ?? state.viewState.sort,
      filter: payload.filter ?? state.viewState.filter,
      columns: payload.columns ?? state.viewState.columns,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
    },
  }));

export default activitiesOverdueState;

import React from "react";
import cn from "classnames";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons/faCheckDouble";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons/faCommentDots";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faGlass } from "@fortawesome/pro-light-svg-icons/faGlass";
import { faMailbox } from "@fortawesome/pro-light-svg-icons/faMailbox";
import Tooltip, { TooltipPropsWithTitle } from "antd/es/tooltip";
import styles from "./ActivityTypeIcon.module.scss";
import getActivityTypeAbbreviation from "./utils/getActivityTypeAbbreviation";

const defaultActivityNameFormatting = {
  Call: {
    className: styles.call,
    icon: faPhone,
  },
  Email: {
    className: styles.email,
    icon: faMailbox,
  },
  Lunch: {
    className: styles.lunch,
    icon: faGlass,
  },
  Meeting: {
    className: styles.meeting,
    icon: faUserPlus,
  },
  Task: {
    className: styles.task,
    icon: faCheckDouble,
  },
  Text: {
    className: styles.text,
    icon: faCommentDots,
  },
  Visit: {
    className: styles.visit,
    icon: faUser,
  },
} as const;

type DefaultActivityType = keyof typeof defaultActivityNameFormatting;

interface Props {
  activityType: Pick<ActivityType, "id" | "name">;
  activityTypeIconClassName?: string;
  className?: string;
  size?: "sm" | "md" | "lg";
  tooltipProps?: Omit<TooltipPropsWithTitle, "title"> | false;
}

const ActivityTypeIcon: React.FC<Props> = ({
  activityType,
  activityTypeIconClassName,
  className: externalClassName,
  size = "md",
  tooltipProps,
}) => {
  const isDefaultActivityType = activityType.name in defaultActivityNameFormatting;
  const { className, icon } = isDefaultActivityType
    ? defaultActivityNameFormatting[activityType.name as DefaultActivityType]
    : { className: styles.default, icon: faCalendar };

  const iconContent = (
    <div
      className={cn(
        styles.activityTypeIcon,
        styles[size],
        className,
        activityTypeIconClassName,
        externalClassName
      )}
    >
      {isDefaultActivityType ? (
        <FontAwesomeIcon icon={icon} />
      ) : (
        getActivityTypeAbbreviation(activityType.name)
      )}
    </div>
  );

  return tooltipProps !== false ? (
    <Tooltip title={activityType.name} {...tooltipProps}>
      {iconContent}
    </Tooltip>
  ) : (
    iconContent
  );
};

export default ActivityTypeIcon;
